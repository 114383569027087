import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const DoneIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} id="uuid-6e1c8abd-06b2-43ff-b221-0a6a28447dc7" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <g fill={color || '#FFF'}>
        <path d="m15.6442,1.8356c.0084,0,.0174-.0011.0258-.0012v-.0209l-.0258.0221Z" />
        <path d="m34.3978,1.8614c-.0009.0031-.0009.0063-.0017.0094.0055.0004.011.0012.0165.0016l-.0148-.011Z" />
        <path d="m49.7831,9.1094c-1.1119-5.1006-6.148-8.3341-11.2486-7.2222v.6999c0,1.5435.0295,3.0906,0,4.6378.0093.1174.0088.2355-.0015.3528-.1025,1.1673-1.132,2.0305-2.2993,1.928-1.1674-.1025-2.0305-1.1319-1.928-2.2993V2.3661c.0153-.1675.0461-.3331.0904-.4953-.2782-.0226-.5563-.0683-.8345-.0683h-17.1806c-.2453,0-.4797.0298-.711.032v5.3977c.0011.0187.0019.0374.0025.0562.0367,1.1835-.8929,2.1726-2.0764,2.2094-.0291-.0002-.0582-.001-.0873-.0024-1.1929-.057-2.1136-1.0702-2.0566-2.2631-.0295-1.8087-.0295-3.6136-.0295-5.4186v.0184c-1.8269-.1967-3.6747-.0238-5.4334.5083C1.9292,3.8558-.5249,7.997.0954,12.2862h49.8326c.1315-1.0595.0824-2.1336-.145-3.1768Z" />
      </g>
      <path fill={color || '#FFF'} d="m49.9459,16.6366H.0357v22.8203c.0142.7272.1243,1.4493.3279,2.1476,1.1235,4.0632,4.8737,6.8359,9.0876,6.7191,5.1792.0184,10.3622,0,15.5414,0h15.5414c4.214.1168,7.9642-2.6559,9.0876-6.7191.2036-.6982.3138-1.4203.3279-2.1476.0195-6.3212.0195-12.6411,0-18.9598v-3.8605h-.0037Zm-10.962,10.4691l-.4971.6455c-1.8252,2.3691-3.6494,4.7363-5.5088,7.082-1.0742,1.2686-2.6768,1.9434-4.2979,1.8252-1.166-.0186-2.3379-.4668-3.2559-1.2568-1.332-1.0498-2.6992-2.125-4.0645-3.1953-.0225-.0176-.043-.0342-.0625-.0488-.0137.0176-.0283.0361-.0439.0557l-5.584,7.2246c-.4844.7793-1.3848,1.2617-2.3535,1.2617-.1807,0-.3633-.0166-.5469-.0518-1.1787-.1338-2.1533-1.1084-2.2979-2.3584-.0771-.749.1016-1.498.501-2.123,1.9834-2.6621,3.9961-5.2764,6.0234-7.8291.041-.0547.0967-.124.1553-.1924,1.8662-2.2383,5.2188-2.54,7.4648-.665.8691.6611,1.7588,1.3662,2.6484,2.0703l1.4277,1.127c.0488.0381.0918.0713.1299.0996.0293-.0361.0625-.0781.0986-.126,1.7617-2.2881,3.5322-4.5723,5.3105-6.8516.3398-.5283.8496-.9346,1.4424-1.1475h.001c.7217-.2598,1.498-.2188,2.1895.1074.6914.3281,1.2139.9053,1.4717,1.625.3848.8486.2607,1.9189-.3516,2.7217Z" />
    </svg>
  );
};

export default DoneIcon;
