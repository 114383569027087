import React, { useEffect, useState } from 'react';
import {
  Button,
  Paper,
  Snackbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Edit as EditIcon,
  Close as CloseIcon,
  ChevronRight,
  ChevronLeft,
} from '@mui/icons-material';
import { PageHeader } from '../../../../components/PageHeader';
import { usePromise } from '../../../../services/promise.hook';
import { getCLAMatches, postCLAMatch } from './cla.actions.jsx';
import ClientSearch from '../../../Reports/client-search.component.jsx';
import { update, evolve, isEmpty } from 'ramda';
import { birthdayFormatter } from '../../../../services/utilities.js';

const options = [
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 75, label: '75' },
  { value: 100, label: '100' },
];

const MatchItem = ({ sked, matchedSked, cla, setState }) => {
  const f = matchedSked ? matchedSked : sked[0];
  const [search, setSearch] = useState(false);
  return (
    <TableRow>
      <TableCell>
        {cla.firstName + ' ' + cla.lastName}
        <br />
        {cla.email}
        <br />
        {cla.phone}
        <br />
        {birthdayFormatter(cla.birthday)}
      </TableCell>
      {f ?
        <TableCell>
          {search ?
            <ClientSearch
              showEmail
              showPhone
              clients={[]}
              setClients={(c) => {
                setState({ cla, sked: c([]) });
                setSearch(false);
              }} />
            :
            <div style={{ width: '300px' }}>
              {f.firstName + ' ' + f.lastName}
              <br />
              {f.email}
              <br />
              {f.phone}
              <br />
              {f.birthday}
            </div>}
        </TableCell>
        :
        <TableCell>
          {search ?
            <ClientSearch
              showEmail
              showPhone
              clients={[]}
              setClients={(c) => {
                setState({ cla, sked: c([]) });
                setSearch(false);
              }} />
            :
            'NO MATCH'}
        </TableCell>}
      <TableCell>
        {search ?
          <IconButton aria-label="edit apt" onClick={() => setSearch(false)}>
            <CloseIcon />
          </IconButton>
          :
          <IconButton aria-label="edit apt" onClick={() => setSearch(true)}>
            <EditIcon />
          </IconButton>}
      </TableCell>
    </TableRow>
  );
};


const useStyles = makeStyles(() => ({
  tableContainer: {
    marginTop: '20px',
    marginLeft: '-20px',
    marginRight: '-20px',
    width: 'auto',
    '& table tr td': {
      fontSize: '13px'
    },
    '& table tr th': {
      fontSize: '13px'
    },
    '& .MuiTableCell-sizeSmall:last-child': {
      paddingRight: '20px'
    },
    '& .MuiTableCell-sizeSmall:first-child': {
      paddingLeft: '20px'
    }
  }
}));

const CLAMatchPage = () => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);
  const [isEdited, setIsEdited] = useState(false);
  const classes = useStyles();
  const matchGetState = usePromise(getCLAMatches, []);
  const matchPostState = usePromise(postCLAMatch, []);

  useEffect(() => {
    matchGetState.invoke({ page, perPage });
  }, [page, perPage]);


  const [isSaveSnackbarOpen, setIsSaveSnackbarOpen] = React.useState(false);
  const closeSaveSnackBar = () => setIsSaveSnackbarOpen(false);
  //const showSaveSucess = () => setIsSaveSnackbarOpen(true);

  const post = () => {
    const data = matchGetState.data
      .filter(({ sked }) => !isEmpty(sked))
      .map(({ sked, cla }) => ({
        cla: cla.patientId,
        sked: sked[0].id,
      }));
    matchPostState.invoke(data).then(() => {
      setIsSaveSnackbarOpen(true);
    });
  };

  // this is here for testing purposes, I'll remove it one day
  // console.log(matchGetState.data.filter(({sked}) => !isEmpty(sked)));
  return (
    <div style={{ margin: '20px', marginBottom: '300px' }}>
      <Button
        href={'#/settings/cla'}
        startIcon={<ChevronLeft />}>
        Back
      </Button>
      <PageHeader variant="h6" style={{ marginBottom: '20px' }}>
        CLA Match Patients
      </PageHeader>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isSaveSnackbarOpen}
        autoHideDuration={5e3}
        onClose={closeSaveSnackBar}
      >
        <div>
          <Alert style={{ fontSize: '16px' }} severity="success">Saved Matches</Alert>
        </div>
      </Snackbar>

      <Paper style={{ padding: '20px', paddingBottom: '50px' }}>
        {matchGetState.loading ? <div className='loader' /> : <TableContainer className={classes.tableContainer}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell >
                  CLA Patient
                </TableCell>
                <TableCell>
                  SKED Client
                </TableCell>
                <TableCell>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {matchGetState.data.map(
                ({ matchedSked, sked, cla }, ind) =>
                  <MatchItem
                    key={cla.patientId}
                    cla={cla}
                    matchedSked={matchedSked}
                    sked={sked}
                    setState={(match) => {
                      setIsEdited(true);
                      matchGetState.setState(evolve({
                        data: update(ind, match),
                      }, matchGetState));
                    }}
                  />)}
            </TableBody>
          </Table>
        </TableContainer>}
        {!matchGetState.loading &&
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '20px',
          }}>
            <div>
              <Button
                variant="contained"
                type="submit"
                disabled={!isEdited || matchPostState.loading}
                color="primary"
                onClick={post}>
                Save
              </Button>
            </div>
            <div>
              <FormControl style={{ width: '100px' }}>
                <InputLabel id="limit-label">Items per Page</InputLabel>
                <Select
                  labelId="limit-label"
                  id="limit-search"
                  value={perPage}
                  onChange={(e) => setPerPage(e.target.value)}
                >
                  {options.map(opt =>
                    <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                  )}
                </Select>
              </FormControl>
              <IconButton
                disabled={page === 1 || isEdited}
                aria-label="edit apt"
                onClick={() => setPage(page - 1)}>
                <ChevronLeft size="large" style={{ color: '#008BCF' }} />
              </IconButton>
              {isEdited ?
                <Tooltip
                  title='Please save this page before continuing'
                  position='top'
                  arrow>
                  <span>
                    <IconButton
                      aria-label="edit apt"
                      onClick={() => setPage(page + 1)}
                      disabled={isEdited}>
                      <ChevronRight size="large" style={{ color: '#008BCF' }} />
                    </IconButton>
                  </span>
                </Tooltip>
                :
                <IconButton
                  aria-label="edit apt"
                  onClick={() => setPage(page + 1)}>
                  <ChevronRight size="large" style={{ color: '#008BCF' }} />
                </IconButton>}
            </div>
          </div>}
      </Paper>
    </div>
  );
};

export default CLAMatchPage;
