import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import {
  TextField, Button, InputAdornment, CircularProgress,
  Select, FormControl, MenuItem, InputLabel, IconButton,
  Checkbox, ListItemText, Paper, Divider, Menu, ListItemIcon, Tooltip,
  Switch, Typography, Dialog, DialogTitle, DialogContent,
  DialogActions, FormHelperText, ListSubheader,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { outputLocation } from '../../services/utilities.js';
import { PopupTemplate, popupWithCancel, popup } from '../../services/Popup.js';
import {
  prop, includes, ifElse, always, T, pipe, cond, has, update as Rupdate,
  remove, adjust, evolve, assoc, filter, isEmpty, map, nth, pathOr,
  equals, values, append, flatten, identity, head, keys, prepend,
  splitEvery, addIndex, isNil, assocPath, forEachObjIndexed, any,
  groupBy, propOr, type,
} from 'ramda';
import { Row } from '../../components/PageHeader';
import { useTitle } from '../../services/useTitle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import RuleIcon from '@mui/icons-material/CheckBox';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  format, parse, tzParse, tzParseFormat,
} from '../../services/joda.js';
import {
  LocalDate, LocalTime, ZoneOffset, ZoneId, ZonedDateTime,
} from '@js-joda/core';
import { usePromise } from '../../services/promise.hook';
import api from '../../services/api.js';
import { FeatureDisabled } from '../../components/feature-disabled.component';
import {
  TriggerRenderData, GlobalTriggerData, Filter, AutomationsState, FilterRenderData,
  GlobalFilterData, ActionRenderData, GlobalActionData, SendMsgGetAction, Automation,
  GetTrigger, TriggerData, Trigger, GetAction, GetFilter, DefaultList, FilterData,
  ActionData, Action, DefaultMessage, DefaultForm, CurrentState, AutomationsStateSubFilters,
  ChangedSource, TriggerRenderTypes, FilterRenderTypes,
  ActionRenderTypes, ChangedSourceNpp, IndividualDummy, IndividualClientListGetAction,
  IndividualAppointmentUpdateTrigger, ChangedSourceAdmin, ChangedSourceWebmodule,
  ChangedSourceApp, ChangedSourceEHR, ASendMsgGetAction, AClientListGetAction,
  GetFilterClientCombined, AMakeFormGetAction, DateTimeFilter, SectionHeadersMap,
  AApptListGetAction, IndividualApptListGetAction, TimeUnit, InnerDateTimeFilter, InputType,
  PathOrId, ActionDataSendMessage, Predicate, FilterHasAppointment, List,
} from './automation-types';
import { Message } from '../ClientLists/clientlist-types';
import * as rawActions from './automations.actions';
import {
  ClientListSearch, MessageSearch, NppSearch, FormSearch, ApptListSearch
} from './components/client-list-search.component';
import MessageDialog from './components/message-dialog.component';
import { AppointmentTypeAutocomplete } from '../CalendarV2/components/AppointmentTypeSelect.component';
import { getProfessionals } from '../Professionals/professionals.actions.jsx';
import AutomationTemplateDialog from '../Templates/automation-template-dialog.component';
import { History } from 'history';
import { useSelector } from '../../reducers';
import Header from '../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import FilterSelect from '../../components/FilterSelect/FilterSelect.component';
import Loading from '../../components/Loading/Loading.component';
import TriggerIcon from '../../icons/Trigger.icon';
import EnabledIcon from '../../icons/Enabled.icon';
import { AddDialog, Folder, Resource } from '../../components/Folders/folders.component';
import ContactSupport from '@mui/icons-material/ContactSupport';

const mapWithIndex = addIndex(map);
const isEmptyOrNil = (d: any) => isNil(d) || isEmpty(d);

export const options = [
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 75, label: '75' },
  { value: 100, label: '100' },
];

export const enabledOptions = [
  { value: null, name: 'Any' },
  { value: true, name: 'Only Enabled' },
  { value: false, name: 'Only Disabled' },
];

const directionalOptions = [
  'in the future',
  'in the past',
  'exactly'
];

const SmallerTextField = withStyles({
  root: {
    width: 'fit-content',
    minWidth: '150px',
  }
})(TextField);

const NumberTextField = withStyles({
  root: {
    width: '55px',
  }
})(TextField);

const TimeTextField = withStyles({
  root: {
    width: 'fit-content',
    minWidth: '100px',
  }
})(TextField);

const useStyles = makeStyles(() => ({
  pageControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '5px'
  },
  pageArrows: {
    marginLeft: '0px',
    marginRight: '0px',
    display: 'flex',
    alignItems: 'center',
  },
  headerRow: {
    marginBottom: '10px',
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterRow: {
    marginBottom: '10px',
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  progessSection: {
    marginTop: '20px',
    marginBottom: '20px',
    padding: '20px'
  },
  // progessHeader: {
  //   display: 'flex',
  //   flexDirection: 'row'
  // },
  stepsContainer: {
    maxHeight: 0,
    overflow: 'hidden',
    transition: 'max-height 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  wordsContainer: {
    '& b': {
      marginLeft: '3px',
    },
    '& a': {
      marginLeft: '3px',
      marginRight: '3px',
    },
    '& i': {
      marginLeft: '3px',
      marginRight: '5px',
    }
  },
  selectTextContainer: {
    marginTop: '4px',
    marginBottom: '4px',
  },
  selectText: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: '1.5',
    letterSpacing: '0.00938em',
  },
  textField: {
    '& input': {
      height: '32px',
      fontSize: '16px',
    },
  },
  timeUnit: {
    marginRight: 7,
  },
  marginForErrors: {
    marginBottom: 'calc(3px + 20px)',
  },
  variable: {
    marginRight: '3px',
    color: '#008BCF',
  },
  templateContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'unset',
    margin: '20px',
  },
  templateBox: {
    padding: '20px',
    margin: '10px',
    width: '200px',
    minHeight: '150px',
    cursor: 'pointer',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#008BCF36',
    }
  },
  headerTitles: {
    fontSize: '16px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  pathListItem: {
    cursor: 'pointer',
  },
  templatePathContainer: {
    margin: '0px 10px',
  },
}));

const timeUnitToString = (tu: TimeUnit | string) => {
  if (!tu) {
    return '';
  }
  /* assuming that the string is a parsable date */
  if (typeof tu === 'string') {
    return `${format(parse(tu, 'd'), 'MM/dd/yyyy')}`;
  }
  const { days, months, years } = tu;
  const ss = (prop: string, v: number, includeComma = false) => {
    return `${v > 0 && v || ''} ${v > 0 && prop || ''}${v > 1 && 's' || ''}${v > 0 && includeComma && ', ' || ''}`;
  };
  return `${ss('day', days, months > 0 || years > 0)}${ss('month', months, years > 0)}${ss('year', years, false)}`;
};

const toNumber = (tu: TimeUnit) => {
  const c = (s: string | number) => Number(s);
  return ({
    days: c(tu.days),
    months: c(tu.months),
    years: c(tu.years),
  });
};

const sources: string[] = [
  'Any source',
  'Admin',
  'App',
  'Any New Patient Portal',
  'EHR',
  'New Patient Portal',
  'Webmodule'
];
const campaignChangeOptions: string[] = [
  'has client added',
  'has client removed',
  'has client unsubscribe',
];
const apptListChangeOptions: string[] = [
  'has appointment added',
  'has appointment removed',
];

const campaignChangeActionOptions: string[] = [
  'add client to',
  'remove client from',
  'unsubscribe client from',
];
const apptListChangeActionOptions: string[] = [
  'add appointment to',
  'remove appointment from',
];
const apptUpdateTriggerChangeTypeOptions: string[] = [
  'is updated',
  'is added',
  'is rescheduled',
  'is canceled',
  'is arrived',
  'is confirmed'
];
const autoQueries: string[] = [
  'Appointments',
  'Clients',
];
const dateTimeFilters: string[] = [
  'on',
  'exactly',
  'in',
  'between',
  'greater than',
  'greater than or equal to',
  'less than',
  'less than or equal to',
  'anytime',
];
const apptVerbs: string[] = [
  'is of type',
  'is a type in',
  'has status of',
  'is on',
  'was',
  'is in',
  'is between',
  'is greater than',
  'is greater than or equal to',
  'was less than',
  'was less than or equal to',
  'is the',
  'is repeatedly the',
];
const clientVerbs: string[] = [
  'has status of',
  'is in',
  'was added to',
  'was sent',
  'was sent any message in',
  'was sent any message below;',
  'has appointment',
  'last appointment'
];
const statusTags: string[] = [
  'Scheduled',
  'Canceled',
  'Arrived',
  'Missed',
  'Rescheduled',
  'Deleted'
];
export const clientStatusTags: string[] = [
  'Active',
  'Inactive',
  'Inert',
];
const timeUnitOptions: string[] = [
  'minute',
  'hour',
  'day',
  'week',
];
const clientStatuses: string[] = [
  'added',
  'marked as inactive',
  'marked as inert',
];
const pathOrIdList: string[] = [
  '{{item}}', 'a {{item}} in', 'a {{item}} anywhere in'
];
interface EmptyDate {
  Date: string;
  Ago: TimeUnit;
  InFuture: TimeUnit;
  BetweenDatesStart: TimeUnit;
  BetweenDatesEnd: TimeUnit;
  GreaterThan: TimeUnit;
  LessThan: TimeUnit;
  GreaterThanEqual: TimeUnit;
  LessThanEqual: TimeUnit;
}
const emptyTimeUnit = {
  days: 0, months: 0, years: 0,
};
const emptyDate: EmptyDate = {
  Date: null, // Maybe Date String
  Ago: emptyTimeUnit,
  InFuture: emptyTimeUnit,
  BetweenDatesStart: emptyTimeUnit,
  BetweenDatesEnd: emptyTimeUnit,
  GreaterThan: emptyTimeUnit,
  LessThan: emptyTimeUnit,
  GreaterThanEqual: emptyTimeUnit,
  LessThanEqual: emptyTimeUnit,
};
const triggerRenderData: TriggerRenderData = {
  '': {
    nothing: {
      type: 'select',
      options: ['has something happen...']
    },
  },
  Appointment: {
    changeType: {
      type: 'select',
      options: apptUpdateTriggerChangeTypeOptions,
      multiple: true,
      placeholder: 'is changed...',
      hasError: ({ data }) => isEmpty(data.changeType),
    },
    source: {
      type: 'select',
      options: sources,
      multiple: true,
      placeholder: 'someplace...',
      prefix: 'from',
      hasError: ({ data }) => isEmpty(data.source),
    },
    nppId: {
      type: 'NPP',
      multiple: true,
      showIf: ({ data, state }) => {
        return !state.isTemplate && includes('New Patient Portal', data.source);
      },
      hasError: ({ data }) => isEmpty(data.nppId),
    },
  },
  'Appointment List': {
    isPath: {
      type: 'select',
      options: pathOrIdList.map((t) => t.replace('{{item}}', 'appointment list')),
      hasError: ({ state }) => {
        const f = state.triggerData;
        if (has('isPath', f)) {
          return isEmpty(f.isPath);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        console.log(data);
        return true;
      },
    },
    path: {
      type: 'text',
      prefix: '',
      placeholder: 'spark.0.apptTypes',
      hasError: ({ state }) => {
        const f = state.triggerData;
        if (has('path', f)) {
          return isEmpty(f.path);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return data.isPath !== 'appointment list';
      },
    },
    apptList: {
      type: 'ApptList',
      hasError: ({ data }) => !pathOr(false, ['apptList', 'listId'], data),
      showIf: ({ state, data }) => !state.isTemplate && data.isPath === 'appointment list',
    },
    change: {
      type: 'select',
      options: apptListChangeOptions,
      hasError: ({ data }) => isEmpty(data.change),
    },

  },
  'Appointment Time': {
    beforeAfter: {
      type: 'select',
      options: ['is in', 'was'],
      hasError: ({ data }) => isEmpty(data.beforeAfter),
    },
    time: {
      type: 'number',
      hasError: ({ data }) => data.time < 0,
    },
    timeUnit: {
      type: 'select',
      prefix: ' ',
      options: timeUnitOptions,
      hasError: ({ data }) => isEmpty(data.timeUnit),
      showIf: ({ data }) => {
        return data.beforeAfter === 'is in';
      },
    },
    timeUnit2: {
      type: 'select',
      prefix: ' ',
      postfix: ' ago',
      options: timeUnitOptions,
      hasError: ({ data }) => isEmpty(data.timeUnit),
      showIf: ({ data }) => {
        return data.beforeAfter === 'was';
      },
    }
  },
  Campaign: {
    isPath: {
      type: 'select',
      options: pathOrIdList.map((t) => t.replace('{{item}}', 'client list')),
      hasError: ({ state }) => {
        const f = state.triggerData;
        if (has('isPath', f)) {
          return isEmpty(f.isPath);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        console.log(data);
        return true;
      },
    },
    path: {
      type: 'text',
      prefix: '',
      placeholder: 'spark.0.',
      hasError: ({ state }) => {
        const f = state.triggerData;
        if (has('path', f)) {
          return isEmpty(f.path);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return data.isPath !== 'client list';
      },
    },
    campaign: {
      type: 'Campaign',
      hasError: ({ data }) => !pathOr(false, ['campaign', 'listId'], data),
      showIf: ({ state, data }) => !state.isTemplate &&
        data.isPath === 'client list',
    },
    change: {
      type: 'select',
      options: campaignChangeOptions,
      hasError: ({ data }) => isEmpty(data.change),
    },
  },
  Client: {
    changeType: {
      type: 'select',
      options: clientStatuses,
      multiple: true,
      placeholder: '...',
      prefix: 'is ',
      hasError: ({ data }) => isEmpty(data.changeType),
    },
    source: {
      type: 'select',
      options: sources,
      multiple: true,
      placeholder: 'someplace...',
      prefix: 'from',
      hasError: ({ data }) => isEmpty(data.source),
    },
    nppId: {
      type: 'NPP',
      multiple: true,
      showIf: ({ data, state }) => {
        return !state.isTemplate && includes('New Patient Portal', data.source);
      },
      hasError: ({ data }) => isEmpty(data.nppId),
    }
  },
  'Message Received': {
    text: {
      type: 'text-array',
      prefix: 'is',
      placeholder: 'a word or phrase',
    }
  },
  'Message Sent': {
    message: {
      type: 'Message',
      hasError: ({ data }) =>
        pathOr(0, ['message', 'msgId'], data) === 0,
      showIf: ({ state }) => !state.isTemplate,
    },
  },
  Time: {
    time: {
      type: 'time',
      prefix: 'is',
      hasError: ({ data }) => isEmptyOrNil(data.time),
    },
    query: {
      type: 'select',
      prefix: 'select all',
      options: autoQueries,
      placeholder: 'something...',
      hasError: ({ data }) => isEmpty(data.query),
      isError: ({ state, value }) => {
        if (value === 'Appointments') {
          const apptFilters = state.filters.filter(({ filter }) => filter === 'appointment');
          if (isEmpty(apptFilters)) {
            return {
              title: 'Error: required field missing!',
              msg: 'When selecting by appointments, you must include at least 1 filter on which appointments to select!'
            };
          }
        }
        if (value === 'Clients') {
          const clientFilters = state.filters.filter(({ filter }) => filter === 'client');
          if (isEmpty(clientFilters)) {
            return {
              title: 'Error: required field missing!',
              msg: 'When selecting by clients, you must include at least 1 filter on which client to select!'
            };
          }
        }
        return false;
      },
    },
  },
  'Date & Time': {
    datetime: {
      type: 'datetime',
      prefix: 'is',
      postfix: '',
      hasError: ({ data }) => isEmptyOrNil(data.datetime),
    },
    query: {
      type: 'select',
      prefix: 'select all',
      options: autoQueries,
      placeholder: 'something...',
      hasError: ({ data }) => isEmpty(data.query),
      isError: ({ state, value }) => {
        if (value === 'Appointments') {
          const apptFilters = state.filters.filter(({ filter }) => filter === 'appointment');
          if (isEmpty(apptFilters)) {
            return {
              title: 'Error: required field missing!',
              msg: 'When selecting by appointments, you must include at least 1 filter on which appointments to select!'
            };
          }
        }
        return false;
      },
    },
  },
  'Form': {
    form: {
      type: 'Form',
      hasError: ({ data }) => isEmptyOrNil(data.form),
      postfix: ' is submitted',
    },
  },
};
const triggerData: GlobalTriggerData = {
  '': {
    nothing: 'has something happen...',
  },
  Appointment: {
    changeType: [], // [ChangeTypes]
    source: [], // [Source]
    nppId: [], // [NppId]
  },
  'Appointment Time': {
    timeUnit: timeUnitOptions[0],
    timeUnit2: timeUnitOptions[0],
    time: '0',
    beforeAfter: 'is in',
  },
  'Appointment List': {
    apptList: {
      listId: null,
      listName: '',
    },
    change: '', // ClientListChange
    isPath: pathOrIdList[0].replace('{{item}}', 'appointment list'),
    path: '',
  },
  Client: {
    changeType: [],
    source: [],
    nppId: [],
  },
  Campaign: {
    campaign: {
      listId: null,
      listName: '',
    },
    change: '', // ClientListChange
    isPath: pathOrIdList[0].replace('{{item}}', 'client list'),
    path: 'spark.0.',
  },
  'Message Received': {
    text: [''] // [String]
  },
  'Message Sent': {
    message: {
      msgId: 0,
      name: '',
    }
  },
  Time: {
    time: '', // Time String
    query: '', // AutoQuery
  },
  'Date & Time': {
    datetime: '', // DateTime String
    query: '', // AutoQuery
  },
  Form: {
    form: {
      formId: null,
      name: '',
    }
  }
};
export const triggers = keys(triggerData);
const filterRenderData: FilterRenderData = {
  '': {
    type: 'Nothing',
    filter: {
      type: 'filter-select',
      hasError: ({ state, index }) => isEmpty(state.filters[index].filter),
    }
  },
  appointment: {
    type: 'Appointment',
    filter: {
      type: 'filter-select',
      hasError: ({ state, index }) => isEmpty(state.filters[index].filter),
    },
    verb: {
      type: 'select',
      options: apptVerbs,
      sectionHeaders: {
        0: 'Appointment',
        3: 'Appointment Time',
        11: 'Number of Appointments'
      },
      hasError: ({ state, index }) => {
        const f = state.filters[index].filterData;
        if (has('verb', f)) {
          return isEmpty(f.verb);
        }
        return true;
      },
      onChange: ({ state, value, index }) => {
        const obj = cond([
          [equals(apptVerbs[0]), () => ({
            type: 'Appointment' as const,
            verb: value,
            appointmentTypeIds: [],
            ...emptyDate,
            thanDirection: undefined,
            thanDirection1: undefined,
            status: undefined,
            time: undefined,
            offset: undefined,
            repeat: false,
            campaign: undefined,
            numberOfAppointments: undefined,
          })],
          [equals(apptVerbs[1]), () => ({
            type: 'Appointment' as const,
            verb: value,
            appointmentTypeIds: [],
            ...emptyDate,
            thanDirection: undefined,
            thanDirection1: undefined,
            status: undefined,
            time: undefined,
            offset: undefined,
            repeat: false,
            campaign: undefined,
            numberOfAppointments: undefined,
            path: 'spark.0.apptTypes',
          })],
          [equals(apptVerbs[2]), () => ({
            type: 'Appointment' as const,
            verb: value,
            status: [],
            appointmentTypeIds: undefined,
            ...emptyDate,
            thanDirection: undefined,
            thanDirection1: undefined,
            time: undefined,
            offset: undefined,
            repeat: false,
            campaign: undefined,
            numberOfAppointments: undefined,
          })],
          [equals(apptVerbs[11]), () => ({
            type: 'Appointment' as const,
            verb: value,
            status: [],
            appointmentTypeIds: [],
            ...emptyDate,
            thanDirection: undefined,
            thanDirection1: undefined,
            time: undefined,
            offset: 0,
            repeat: false,
            campaign: undefined,
            numberOfAppointments: 0,
          })],
          [equals(apptVerbs[12]), () => ({
            type: 'Appointment' as const,
            verb: value,
            status: [],
            appointmentTypeIds: [],
            ...emptyDate,
            thanDirection: undefined,
            thanDirection1: undefined,
            time: undefined,
            offset: 0,
            repeat: true,
            campaign: undefined,
            numberOfAppointments: 0,
          })],
          [T, () => ({
            type: 'Appointment' as const,
            verb: value,
            ...emptyDate,
            thanDirection: undefined,
            thanDirection1: undefined,
            appointmentTypeIds: [],
            time: undefined,
            status: undefined,
            offset: undefined,
            repeat: false,
            campaign: undefined,
            numberOfAppointments: undefined,
          })]
        ])(value);
        return ({
          filters: adjust(index, evolve({
            filterData: always<FilterData>(obj),
          }), state.filters),
        });
      },
    },
    numberOfAppointments: {
      type: 'number',
      postfix: 'appointment of the type  ',
      hasError: ({ state, index }) => {
        const f = state.filters[index].filterData;
        if (has('numberOfAppointments', f)) {
          return isNil(f.numberOfAppointments);
        }
        return false;
      },
      showIf: ({ data }) => {
        return data.verb === apptVerbs[11] || data.verb === apptVerbs[12];
      }
    },
    appointmentTypeIds: {
      type: 'AppointmentType',
      multiple: true,
      style: { marginLeft: '10px' },
      hasError: ({ state, index }) => {
        const f = state.filters[index].filterData;
        if (has('appointmentTypeIds', f)) {
          return isEmpty(f.appointmentTypeIds);
        }
        return false;
      },
      showIf: ({ data, state }) => {
        return !state.isTemplate && (data.verb === apptVerbs[0] ||
          data.verb === apptVerbs[11] ||
          data.verb === apptVerbs[12]);
      },
    },
    status: {
      type: 'select',
      options: statusTags,
      multiple: true,
      placeholder: 'anything',
      hasError: ({ state, index }) => {
        const f = state.filters[index].filterData;
        if (has('status', f)) {
          return isEmpty(f.status);
        }
        return false;
      },
      showIf: ({ data }) => {
        return data.verb === apptVerbs[2];
      }
    },
    Date: {
      type: 'date',
      postfix: '',
      hasError: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('Date', f)) {
          return isEmptyOrNil(f.Date);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return data.verb === apptVerbs[3];
      }
    }, // Maybe Date String
    Ago: {
      type: 'TimeUnit',
      postfix: 'ago',
      hasError: ({ state, index }) => {
        const f = state.filters[index].filterData;
        if (has('Ago', f)) {
          return isNil(f.Ago);
        }
        return false;
      },
      showIf: ({ data }) => {
        return data.verb === apptVerbs[4];
      }
    },
    InFuture: {
      type: 'TimeUnit',
      postfix: '',
      hasError: ({ state, index }) => {
        const f = state.filters[index].filterData;
        if (has('InFuture', f)) {
          return isNil(f.InFuture);
        }
        return false;
      },
      showIf: ({ data }) => {
        return data.verb === apptVerbs[5];
      }
    },
    BetweenDatesStart: {
      type: 'TimeUnit',
      changeType: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('thanDirection', f)) {
          return f.thanDirection === directionalOptions[2] ?
            'date' : 'TimeUnit';
        }
        return 'TimeUnit';
      },
      hasError: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('BetweenDatesStart', f)) {
          isEmptyOrNil(f.BetweenDatesStart);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return data.verb === apptVerbs[6];
      }
    },
    GreaterThan: {
      type: 'TimeUnit',
      changeType: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('thanDirection', f)) {
          return f.thanDirection === directionalOptions[2] ?
            'date' : 'TimeUnit';
        }
        return 'TimeUnit';
      },
      hasError: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('GreaterThan', f)) {
          return isNil(f.GreaterThan);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return data.verb === apptVerbs[7];
      }
    },
    GreaterThanEqual: {
      type: 'TimeUnit',
      changeType: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('thanDirection', f)) {
          return f.thanDirection === directionalOptions[2] ?
            'date' : 'TimeUnit';
        }
        return 'TimeUnit';
      },
      hasError: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('GreaterThanEqual', f)) {
          return isNil(f.GreaterThanEqual);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return data.verb === apptVerbs[8];
      }
    },
    LessThan: {
      type: 'TimeUnit',
      changeType: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('thanDirection', f)) {
          return f.thanDirection === directionalOptions[2] ?
            'date' : 'TimeUnit';
        }
        return 'TimeUnit';
      },
      hasError: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('LessThan', f)) {
          return isNil(f.LessThan);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return data.verb === apptVerbs[9];
      }
    },
    LessThanEqual: {
      type: 'TimeUnit',
      changeType: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('thanDirection', f)) {
          return f.thanDirection === directionalOptions[2] ?
            'date' : 'TimeUnit';
        }
        return 'TimeUnit';
      },
      hasError: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('LessThanEqual', f)) {
          return isNil(f.LessThanEqual);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return data.verb === apptVerbs[10];
      }
    },
    thanDirection: {
      type: 'select',
      options: directionalOptions,
      postfix: 'and',
      placeholder: 'space...',
      hasError: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('thanDirection', f)) {
          return isNil(f.thanDirection);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return data.verb === apptVerbs[7] ||
          data.verb === apptVerbs[8] ||
          data.verb === apptVerbs[9] ||
          data.verb === apptVerbs[10] ||
          data.verb === apptVerbs[6];
      }
    },
    BetweenDatesEnd: {
      type: 'TimeUnit',
      prefix: ' ',
      changeType: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('thanDirection1', f)) {
          return f.thanDirection1 === directionalOptions[2] ?
            'date' : 'TimeUnit';
        }
        return 'TimeUnit';
      },
      hasError: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('BetweenDatesEnd', f)) {
          isEmptyOrNil(f.BetweenDatesEnd);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return data.verb === apptVerbs[6];
      },
    },
    thanDirection1: {
      type: 'select',
      options: directionalOptions,
      prefix: ' ',
      placeholder: 'space...',
      hasError: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('thanDirection1', f)) {
          return isNil(f.thanDirection1);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return data.verb === apptVerbs[6];
      }
    },
    offset: {
      type: 'number',
      prefix: ' offset by appointments ',
      hasError: ({ state, index }) => {
        const f = state.filters[index].filterData;
        if (has('offset', f)) {
          return isNil(f.offset);
        }
        return false;
      },
      showIf: ({ data }) => {
        return data.verb === apptVerbs[11];
      }
    },
    campaign: {
      type: 'Campaign',
      prefix: 'in campaign ',
      hasError: ({ state, index }) =>
        pathOr(0, ['filters', index, 'filterData', 'campaign', 'listId'], state) === 0,
      showIf: ({ data, state }) => {
        return !state.isTemplate && (data.verb === apptVerbs[11] || data.verb === apptVerbs[12]);
      }
    },
    path: {
      type: 'text',
      prefix: '',
      placeholder: 'spark.0.apptTypes',
      hasError: ({ state, index }) => {
        const f = state.filters[index].filterData;
        if (has('path', f)) {
          return isEmpty(f.path);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return data.verb === apptVerbs[1];
      },
    },
  },
  client: {
    type: 'Client',
    filter: {
      type: 'filter-select',
      hasError: ({ state, index }) => isEmpty(state.filters[index].filter),
    },
    verb: {
      type: 'select',
      options: clientVerbs,
      hasError: ({ state, index }) => {
        const f = state.filters[index].filterData;
        if (has('verb', f)) {
          return isEmpty(f.verb);
        }
        return false;
      },
      onChange: ({ state, value, index, defaultCampaign }) => {
        const obj = cond([
          [equals<string>('has status of'), () => ({
            type: 'Client' as const,
            verb: value,
            status: 'Active',
            campaign: undefined,
            message: undefined,
            added: undefined,
            ...emptyDate,
            thanDirection: undefined,
            thanDirection1: undefined,
            appointmentTypeIds: [],
            statuses: undefined,
            isPath: pathOrIdList[0].replace('{{item}}', 'client list'),
          })],
          [equals('is in'), () => ({
            type: 'Client' as const,
            verb: value,
            status: undefined,
            campaign: defaultCampaign ? {
              listId: defaultCampaign.id,
              listName: defaultCampaign.name,
            } : {
              listId: null,
              listName: '',
            },
            message: undefined,
            added: undefined,
            ...emptyDate,
            thanDirection: undefined,
            thanDirection1: undefined,
            appointmentTypeIds: [],
            statuses: undefined,
            isPath: pathOrIdList[0].replace('{{item}}', 'client list'),
          })],
          [equals('was added to'), () => ({
            type: 'Client' as const,
            verb: value,
            status: undefined,
            campaign: defaultCampaign ? {
              listId: defaultCampaign.id,
              listName: defaultCampaign.name,
            } : {
              listId: null,
              listName: '',
            },
            message: undefined,
            added: 'on',
            ...emptyDate,
            thanDirection: undefined,
            thanDirection1: undefined,
            appointmentTypeIds: [],
            statuses: undefined,
            isPath: pathOrIdList[0].replace('{{item}}', 'client list'),
          })],
          [equals('was sent'), () => ({
            type: 'Client' as const,
            verb: value,
            status: undefined,
            campaign: undefined,
            message: {
              msgId: 0,
              name: '',
            },
            added: 'on',
            ...emptyDate,
            thanDirection: undefined,
            thanDirection1: undefined,
            appointmentTypeIds: [],
            statuses: undefined,
            isPath: pathOrIdList[0].replace('{{item}}', 'client list'),
          })],
          [equals('was sent any message in'), () => ({
            type: 'Client' as const,
            verb: value,
            status: undefined,
            campaign: undefined,
            message: {
              msgId: 0,
              name: '',
            },
            added: 'on',
            ...emptyDate,
            thanDirection: undefined,
            thanDirection1: undefined,
            appointmentTypeIds: [],
            statuses: undefined,
            path: 'spark.0.msgs',
            isPath: pathOrIdList[0].replace('{{item}}', 'client list'),
          })],
          [equals('was sent any message in'), () => ({
            type: 'Client' as const,
            verb: value,
            status: undefined,
            campaign: undefined,
            message: {
              msgId: 0,
              name: '',
            },
            added: 'on',
            ...emptyDate,
            thanDirection: undefined,
            thanDirection1: undefined,
            appointmentTypeIds: [],
            statuses: undefined,
            path: 'spark.0.msgs',
            isPath: pathOrIdList[0].replace('{{item}}', 'client list'),
          })],
          [equals('has appointment'), () => ({
            type: 'Client' as const,
            verb: value,
            status: undefined,
            campaign: undefined,
            message: {
              msgId: 0,
              name: '',
            },
            added: 'on',
            ...emptyDate,
            thanDirection: undefined,
            thanDirection1: undefined,
            appointmentTypeIds: [],
            statuses: [],
            isPath: pathOrIdList[0].replace('{{item}}', 'client list'),
          })],
          [equals('last appointment'), () => ({
            type: 'Client' as const,
            verb: value,
            status: undefined,
            campaign: undefined,
            message: {
              msgId: 0,
              name: '',
            },
            added: 'on',
            ...emptyDate,
            thanDirection: undefined,
            thanDirection1: undefined,
            appointmentTypeIds: [],
            statuses: [],
            path: 'spark.0.apptTypes',
            isPath: pathOrIdList[0].replace('{{item}}', 'client list'),
          })]
        ])(value);
        return ({
          filters: adjust(index, evolve({
            filterData: always(obj),
          }), state.filters),
        });
      },
    },
    status: {
      type: 'select',
      options: clientStatusTags,
      placeholder: 'anything',
      hasError: ({ state, index }) => {
        const f = state.filters[index].filterData;
        if (has('status', f)) {
          return isEmpty(f.status);
        }
        return false;
      },
      showIf: ({ data }) => {
        return data.verb === 'has status of';
      }
    },
    isPath: {
      type: 'select',
      options: pathOrIdList.map((t) => t.replace('{{item}}', 'client list')),
      hasError: ({ state, index }) => {
        const f = state.filters[index].filterData;
        if (has('isPath', f)) {
          return isEmpty(f.isPath);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return data.verb === 'was added to' ||
          data.verb === 'is in';
      },
    },
    path: {
      type: 'text',
      prefix: '',
      placeholder: 'spark.0.apptTypes',
      hasError: ({ state, index }) => {
        const f = state.filters[index].filterData;
        if (has('path', f)) {
          return isEmpty(f.path);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return data.verb === 'last appointment' ||
          data.verb === 'was sent any message in' ||
          data.verb === 'was sent any message below' ||
          data.isPath !== 'client list';
      },
    },
    campaign: {
      type: 'Campaign',
      hasError: ({ state, index }) =>
        pathOr(0, ['filters', index, 'filterData', 'campaign', 'listId'], state) === 0,
      showIf: ({ data, state }) => {
        return !state.isTemplate && (data.verb === 'is in' || data.verb === 'was added to')
        && data.isPath === 'client list';
      }
    },
    message: {
      type: 'Message',
      hasError: ({ state, index }) =>
        pathOr(0, ['filters', index, 'filterData', 'message', 'msgId'], state) === 0,
      showIf: ({ data, state }) => {
        return !state.isTemplate && data.verb === 'was sent';
      }
    },
    appointmentTypeIds: {
      type: 'AppointmentType',
      multiple: true,
      prefix: 'of type',
      hasError: ({ state, index }) => {
        const f = state.filters[index].filterData;
        if (has('appointmentTypeIds', f)) {
          return isEmpty(f.appointmentTypeIds);
        }
        return false;
      },
      showIf: ({ data, state }) => {
        return !state.isTemplate && data.verb === 'has appointment';
      },
    },
    statuses: {
      type: 'select',
      multiple: true,
      options: statusTags,
      placeholder: 'anything',
      prefix: 'and has status of',
      showIf: ({ data }) => {
        return data.verb === 'has appointment';
      }
    },
    added: {
      type: 'select',
      options: dateTimeFilters,
      placeholder: 'anytime',
      hasError: ({ state, index }) => {
        const f = state.filters[index].filterData;
        if (has('added', f) && f.verb !== 'was sent') {
          return isEmpty(f.added);
        }
        return false;
      },
      showIf: ({ data }) => {
        return data.verb === 'was added to' ||
               data.verb === 'was sent' ||
               data.verb === 'has appointment' ||
               data.verb === 'last appointment';
      }
    },
    Date: {
      type: 'date',
      postfix: '',
      hasError: ({ state, index }) => {
        const f = state.filters[index].filterData;
        if (has('Date', f)) {
          return isEmptyOrNil(f.Date);
        }
        return false;
      },
      showIf: ({ data }) => {
        return (data.verb === 'was added to' ||
                data.verb === 'was sent' ||
                data.verb === 'has appointment' ||
                data.verb === 'last appointment') &&
          data.added === dateTimeFilters[0];
      }
    },
    Ago: {
      type: 'TimeUnit',
      postfix: ' ago',
      hasError: ({ state, index }) => {
        const f = state.filters[index].filterData;
        if (has('Ago', f)) {
          return isNil(f.Ago);
        }
        return false;
      },
      showIf: ({ data }) => {
        return (data.verb === 'was added to' ||
                data.verb === 'was sent' ||
                data.verb === 'has appointment' ||
                data.verb === 'last appointment') &&
          data.added === dateTimeFilters[1];
      }
    },
    InFuture: {
      type: 'TimeUnit',
      postfix: '',
      hasError: ({ state, index }) => {
        const f = state.filters[index].filterData;
        if (has('InFuture', f)) {
          return isNil(f.InFuture);
        }
        return false;
      },
      showIf: ({ data }) => {
        return (data.verb === 'was added to' ||
                data.verb === 'was sent' ||
                data.verb === 'has appointment' ||
                data.verb === 'last appointment') &&
          data.added === dateTimeFilters[2];
      }
    },
    BetweenDatesStart: {
      type: 'TimeUnit',
      changeType: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('thanDirection', f)) {
          return f.thanDirection === directionalOptions[2] ?
            'date' : 'TimeUnit';
        }
        return 'TimeUnit';
      },
      hasError: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('BetweenDatesStart', f)) {
          return isEmptyOrNil(f.BetweenDatesStart);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return (data.verb === 'was added to' ||
                data.verb === 'was sent' ||
                data.verb === 'has appointment' ||
                data.verb === 'last appointment') &&
          data.added === dateTimeFilters[3];
      }
    },
    GreaterThan: {
      type: 'TimeUnit',
      changeType: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('thanDirection', f)) {
          return f.thanDirection === directionalOptions[2] ?
            'date' : 'TimeUnit';
        }
        return 'TimeUnit';
      },
      hasError: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('GreaterThan', f)) {
          return isEmptyOrNil(f.GreaterThan);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return (data.verb === 'was added to' ||
                data.verb === 'was sent' ||
                data.verb === 'has appointment' ||
                data.verb === 'last appointment') &&
          data.added === dateTimeFilters[4];
      }
    },
    GreaterThanEqual: {
      type: 'TimeUnit',
      changeType: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('thanDirection', f)) {
          return f.thanDirection === directionalOptions[2] ?
            'date' : 'TimeUnit';
        }
        return 'TimeUnit';
      },
      hasError: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('GreaterThanEqual', f)) {
          return isEmptyOrNil(f.GreaterThanEqual);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return (data.verb === 'was added to' ||
                data.verb === 'was sent' ||
                data.verb === 'has appointment' ||
                data.verb === 'last appointment') &&
          data.added === dateTimeFilters[5];
      }
    },
    LessThan: {
      type: 'TimeUnit',
      changeType: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('thanDirection', f)) {
          return f.thanDirection === directionalOptions[2] ?
            'date' : 'TimeUnit';
        }
        return 'TimeUnit';
      },
      hasError: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('LessThan', f)) {
          return isEmptyOrNil(f.LessThan);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return (data.verb === 'was added to' ||
                data.verb === 'was sent' ||
                data.verb === 'has appointment' ||
                data.verb === 'last appointment') &&
          data.added === dateTimeFilters[6];
      }
    },
    LessThanEqual: {
      type: 'TimeUnit',
      changeType: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('thanDirection', f)) {
          return f.thanDirection === directionalOptions[2] ?
            'date' : 'TimeUnit';
        }
        return 'TimeUnit';
      },
      hasError: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('LessThanEqual', f)) {
          return isEmptyOrNil(f.LessThanEqual);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return (data.verb === 'was added to' ||
                data.verb === 'was sent' ||
                data.verb === 'has appointment' ||
                data.verb === 'last appointment') &&
          data.added === dateTimeFilters[7];
      }
    },
    thanDirection: {
      type: 'select',
      options: directionalOptions,
      placeholder: 'space...',
      hasError: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('thanDirection', f)) {
          return isNil(f.thanDirection);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return ((data.verb === 'was added to' ||
                data.verb === 'was sent' ||
                data.verb === 'has appointment' ||
                data.verb === 'last appointment') &&
          (data.added === dateTimeFilters[3] ||
           data.added === dateTimeFilters[4]) ||
           data.added === dateTimeFilters[5]);
      }
    },
    BetweenDatesEnd: {
      type: 'TimeUnit',
      postfix: 'and',
      changeType: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('thanDirection1', f)) {
          return f.thanDirection1 === directionalOptions[2] ?
            'date' : 'TimeUnit';
        }
        return 'TimeUnit';
      },
      hasError: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('BetweenDatesEnd', f)) {
          return isEmptyOrNil(f.BetweenDatesEnd);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return (data.verb === 'was added to' ||
                data.verb === 'was sent' ||
                data.verb === 'has appointment' ||
                data.verb === 'last appointment') &&
          data.added === dateTimeFilters[3];
      }
    },
    thanDirection1: {
      type: 'select',
      options: directionalOptions,
      prefix: ' ',
      placeholder: 'space...',
      hasError: ({ state, index }: CurrentState) => {
        const f = state.filters[index].filterData;
        if (has('thanDirection1', f)) {
          return isNil(f.thanDirection1);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return ((data.verb === 'was added to' ||
                data.verb === 'was sent' ||
                data.verb === 'has appointment' ||
                data.verb === 'last appointment') &&
          data.added === dateTimeFilters[3]);
      }
    },
  },
};
const filterData: GlobalFilterData = {
  '': {
    type: 'Nothing',
    text: '',
  },
  appointment: {
    type: 'Appointment',
    status: [],
    verb: 'is of type',
    time: 'on', // Maybe DateTimeFilterName
    appointmentTypeIds: [],
    ...emptyDate,
    thanDirection: 'in the future',
    thanDirection1: 'in the future',
    offset: undefined,
    repeat: false,
    campaign: undefined,
    numberOfAppointments: undefined,
  },
  client: {
    type: 'Client',
    verb: 'has status of',
    status: 'Active',
    campaign: {
      listId: null,
      listName: '',
    },
    message: {
      msgId: 0,
      name: '',
    },
    added: 'on',
    ...emptyDate,
    thanDirection: 'in the future',
    thanDirection1: 'in the future',
    appointmentTypeIds: [],
    statuses: [],
    path: 'spark.0.apptTypes',
    isPath: pathOrIdList[0].replace('{{item}}', 'client list'),
  },
};
const filters = keys(filterData);
const actionRenderData: ActionRenderData = {
  '': {
    action: {
      type: 'action-select',
      hasError: ({ state, index }) => isEmpty(state.actions[index].action),
    },
  },
  Appointment: {
    scope: {
      type: 'select',
      options: ['this', 'next', 'today\'s'],
      prefix: 'mark',
      placeholder: 'some',
      hasError: ({ state, index }) => {
        const f = state.actions[index].actionData;
        if (has('scope', f)) {
          return isEmpty(f.scope);
        }
        return false;
      },
    },
    action: {
      type: 'action-select',
      hasError: ({ state, index }) => isEmpty(state.actions[index].action),
    },
    status: {
      type: 'select',
      options: ['Arrived', 'Missed', 'Confirmed'],
      prefix: 'as',
      placeholder: 'something...',
      hasError: ({ state, index }) => {
        const f = state.actions[index].actionData;
        if (has('status', f)) {
          return isEmpty(f.status);
        }
        return false;
      },
    },
  },
  'Appointment List': {
    change: {
      type: 'select',
      options: apptListChangeActionOptions,
      placeholder: 'do something to...',
      hasError: ({ state, index }) => {
        const f = state.actions[index].actionData;
        if (has('change', f)) {
          return isEmpty(f.change);
        }
        return false;
      },
    },
    action: {
      type: 'action-select',
      hasError: ({ state, index }) => isEmpty(state.actions[index].action),
    },
    isPath: {
      type: 'select',
      options: pathOrIdList.map((t) => t.replace('{{item}}', 'appointment list')),
      hasError: ({ state, index }) => {
        const f = state.actions[index].actionData;
        if (has('isPath', f)) {
          return isEmpty(f.isPath);
        }
        return false;
      },
      showIf: ({ state }: CurrentState) => {
        return !state.isTemplate;
      },
    },
    path: {
      type: 'text',
      prefix: '',
      placeholder: 'spark.0.apptTypes',
      hasError: ({ state, index }) => {
        const f = state.actions[index].actionData;
        if (has('path', f)) {
          return isEmpty(f.path);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return data?.isPath !== 'appointment list';
      },
    },
    apptList: {
      type: 'ApptList',
      hasError: ({ state, index }) =>
        pathOr(0, ['actions', index, 'actionData', 'apptList', 'listId'], state) === 0,
      showIf: ({ state, data }) => !state.isTemplate && data?.isPath === 'appointment list',
    },
  },
  Campaign: {
    change: {
      type: 'select',
      options: campaignChangeActionOptions,
      placeholder: 'do something to...',
      hasError: ({ state, index }) => {
        const f = state.actions[index].actionData;
        if (has('change', f)) {
          return isEmpty(f.change);
        }
        return false;
      },
    },
    action: {
      type: 'action-select',
      hasError: ({ state, index }) => isEmpty(state.actions[index].action),
    },
    isPath: {
      type: 'select',
      options: pathOrIdList.map((t) => t.replace('{{item}}', 'client list')),
      hasError: ({ state, index }) => {
        const f = state.actions[index].actionData;
        if (has('isPath', f)) {
          return isEmpty(f.isPath);
        }
        return false;
      },
      showIf: ({ state }: CurrentState) => {
        return !state.isTemplate;
      },
    },
    path: {
      type: 'text',
      prefix: '',
      placeholder: 'spark.0.apptTypes',
      hasError: ({ state, index }) => {
        const f = state.actions[index].actionData;
        if (has('path', f)) {
          return isEmpty(f.path);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return data.isPath !== 'client list';
      },
    },
    campaign: {
      type: 'Campaign',
      hasError: ({ state, index }) =>
        pathOr(0, ['actions', index, 'actionData', 'campaign', 'listId'], state) === 0,
      showIf: ({ state, data }) => !state.isTemplate && data.isPath === 'client list',
    },
  },
  'Send Form': {
    action: {
      type: 'action-select',
      hasError: ({ state, index }) => isEmpty(state.actions[index].action),
    },
    form: {
      type: 'Form',
      hasError: ({ state, index }) =>
        pathOr(0, ['actions', index, 'actionData', 'form', 'formId'], state) === 0,
      showIf: ({ state }) => !state.isTemplate,
    }
  },
  'Send Message': {
    action: {
      type: 'action-select',
      hasError: ({ state, index }) => isEmpty(state.actions[index].action),
    },
    isPath: {
      type: 'select',
      options: pathOrIdList.map((t) => t.replace('{{item}}', 'message')),
      hasError: ({ state, index }) => {
        const f = state.actions[index].actionData;
        if (has('isPath', f)) {
          return isEmpty(f.isPath);
        }
        return false;
      },
      showIf: ({ state }: CurrentState) => {
        return !state.isTemplate;
      },
    },
    path: {
      type: 'text',
      prefix: '',
      placeholder: 'spark.0.',
      hasError: ({ state, index }) => {
        const f = state.actions[index].actionData;
        if (has('path', f)) {
          return isEmpty(f.path);
        }
        return false;
      },
      showIf: ({ data }: CurrentState) => {
        return data.isPath !== 'message';
      },
    },
    message: {
      type: 'Message',
      hasError: ({ state, index }) =>
        pathOr(0, ['actions', index, 'actionData', 'message', 'msgId'], state) === 0,
      showIf: ({ state, data }) => !state.isTemplate && data.isPath === 'message',
    }
  },
};
const actionData: GlobalActionData = {
  '': {
    type: 'Nothing',
    text: '',
  },
  Appointment: {
    type: 'Appointment',
    status: '',
    scope: 'this'
  },
  'Appointment List': {
    type: 'ApptList',
    change: '',
    apptList: {
      listId: null,
      listName: '',
    },
    isPath: pathOrIdList[1].replace('{{item}}', 'appointment list'),
    path: '',
  },
  Campaign: {
    type: 'Campaign',
    change: '',
    campaign: {
      listId: null,
      listName: '',
    },
    isPath: pathOrIdList[1].replace('{{item}}', 'client list'),
    path: '',
  },
  'Send Form': {
    type: 'Form',
    form: {
      formId: 0,
      name: '',
    },
  },
  'Send Message': {
    type: 'SendMessage',
    message: {
      msgId: 0,
      name: '',
    },
    isPath: pathOrIdList[0].replace('{{item}}', 'message'),
    path: '',
  },
};
const actions = keys(actionData);
const triggerTo = (defaultCampaign: DefaultList, trigger: GetTrigger) => cond([
  [has('Dummy'), () => ({
    trigger: '',
    triggerData: {
      nothing: ''
    },
  })],
  [has('AppointmentList'), ({ AppointmentList }): Trigger => {
    let isPath = pathOrIdList[0].replace('{{item}}', 'appointment list');
    let path = undefined;
    if (AppointmentList.listId?.PathExact) {
      isPath = pathOrIdList[1].replace('{{item}}', 'appointment list');
      path = AppointmentList.listId.PathExact.join('.');
    } else if (AppointmentList.listId?.PathRelative) {
      isPath = pathOrIdList[2].replace('{{item}}', 'appointment list');
      path = AppointmentList.listId.PathRelative.join('.');
    }
    return ({
      trigger: 'Appointment List',
      triggerData: {
        change: cond([
          [equals('Added'), () => apptListChangeOptions[0]],
          [equals('Removed'), () => apptListChangeOptions[1]],
        ])(AppointmentList.change),
        apptList: {
          ...AppointmentList.listId.Id,
        },
        isPath,
        path,
      }
    });
  }],
  [has('ClientList'), ({ ClientList }): Trigger => {
    const change = pipe(
      prop('change'),
      cond([
        [has('Removed'), always(campaignChangeOptions[1])],
        [has('Unsubscribed'), always(campaignChangeOptions[2])],
        [has('Added'), always(campaignChangeOptions[0])],
      ])
    )(ClientList);
    const defcl = {
      ...defaultCampaign,
      listId: defaultCampaign?.id,
      listName: defaultCampaign?.name,
    };
    let cl = isNil(ClientList.listId) && defaultCampaign ? defcl : ClientList.listId.Id;
    let isPath = pathOrIdList[0].replace('{{item}}', 'client list');
    let path = undefined;
    if (ClientList.listId.PathExact) {
      isPath = pathOrIdList[1].replace('{{item}}', 'client list');
      path = ClientList.listId.PathExact.join('.');
      cl = defcl;
    } else if (ClientList.listId.PathRelative) {
      isPath = pathOrIdList[2].replace('{{item}}', 'client list');
      path = ClientList.listId.PathRelative.join('.');
      cl = defcl;
    }
    return ({
      trigger: 'Campaign',
      triggerData: {
        change,
        campaign: {
          ...cl,
        },
        isPath,
        path,
      }
    });
  }],
  [has('MessageReceived'), ({ MessageReceived }) => {
    return ({
      trigger: 'Message Received',
      triggerData: {
        text: MessageReceived,
      },
    });
  }],
  [has('SentMsg'), ({ SentMsg }) => {
    return ({
      trigger: 'Message Sent',
      triggerData: {
        message: SentMsg,
      },
    });
  }],
  [has('ApptUpdated'), ({ ApptUpdated }): Trigger => {
    const {
      source,
      changeType,
    } = ApptUpdated;
    const nppId: number[] =
      source ?
        source.filter(has('Npp')).map(({ Npp }: ChangedSourceNpp) => Npp)
        : [];
    return ({
      trigger: 'Appointment',
      triggerData: {
        changeType: ifElse(
          isNil,
          always(['is updated']),
          map(
            cond([
              [equals('Added'), always(apptUpdateTriggerChangeTypeOptions[1])],
              [equals('Rescheduled'), always(apptUpdateTriggerChangeTypeOptions[2])],
              [equals('ChangedCancled'), always(apptUpdateTriggerChangeTypeOptions[3])],
              [equals('ChangedArrived'), always(apptUpdateTriggerChangeTypeOptions[4])],
              [equals('ChangedConfirmed'), always(apptUpdateTriggerChangeTypeOptions[5])],
            ])
          )
        )(changeType),
        source: ifElse(
          isNil,
          always([sources[0]]),
          map(
            cond([
              [has('AnyNpp'), always(sources[3])],
              [has('Npp'), always(sources[5])],
              [T, pipe(keys, nth(0))]
            ])
          )
        )(source),
        nppId,
      },
    });
  }],
  [has('Client'), ({ Client }) => {
    const {
      source,
      changeType,
    } = Client;
    const nppId: number[] =
      source ?
        source.filter(has('Npp')).map(({ Npp }: ChangedSourceNpp) => Npp)
        : [];
    return ({
      trigger: 'Client',
      triggerData: {
        changeType: changeType.map(
          cond([
            [equals('Added'), () => clientStatuses[0]],
            [equals('Inactive'), () => clientStatuses[1]],
            [equals('Inert'), () => clientStatuses[2]],
          ])),
        source: ifElse(
          isNil,
          always([sources[0]]),
          map(
            cond([
              [has('AnyNpp'), always(sources[3])],
              [has('Npp'), always(sources[5])],
              [T, pipe(keys, nth(0))]
            ])
          )
        )(source),
        nppId,
      },
    });
  }],
  [has('DateTime'), ({ DateTime }) => ({
    trigger: 'Date & Time',
    triggerData: {
      datetime: DateTime.datetime.slice(0, -1),
      query: head(keys<keyof string[]>(DateTime.query)),
    },
  })],
  [has('Time'), ({ Time }) => ({
    trigger: 'Time',
    triggerData: {
      time: Time.time,
      query: head(keys<keyof string[]>(Time.query)),
    },
  })],
  [has('FormSubmitted'), ({ FormSubmitted }) => {
    return ({
      trigger: 'Form',
      triggerData: {
        form: {
          ...FormSubmitted,
        }
      }
    });
  }],
  [has('ApptTime'), ({ ApptTime }) => {
    const [time, timeUnit] = cond([
      [has('Minute'), ({ Minute }) => [Minute, 'minute']],
      [has('Hour'), ({ Hour }) => [Hour, 'hour']],
      [has('Day'), ({ Day }) => [Day, 'day']],
      [has('Week'), ({ Week }) => [Week, 'week']],
    ])(ApptTime.time);
    return ({
      trigger: 'Appointment Time',
      triggerData: {
        time,
        timeUnit,
        timeUnit2: timeUnit,
        beforeAfter: ApptTime.beforeAfter === 'Before' ? 'is in' : 'was',
      }
    });
  }],
  [T, () => ({
    trigger: '',
    triggerData: {
      nothing: ''
    },
  })]
])(trigger);

const actionTo = (defaultCampaign: DefaultList) => (action: GetAction) =>
  cond([
    [has('SendMessage'), ({ SendMessage }) => {
      const message = SendMessage?.msgId?.Id;
      let isPath = pathOrIdList[0].replace('{{item}}', 'message');
      let path = 'spark.0.';
      if (SendMessage?.msgId?.Path) {
        isPath = pathOrIdList[1].replace('{{item}}', 'message');
        path = SendMessage.msgId.Path.join('.');
      }
      return {
        action: 'Send Message',
        actionData: {
          text: 'send',
          message,
          isPath,
          path,
        },
      };
    }],
    [has('MakeForm'), ({ MakeForm }) => {
      return ({
        action: 'Send Form',
        actionData: {
          text: 'send',
          form: MakeForm
        },
      });
    }],
    [has('MakeForm'), ({ MakeForm }) => {
      return ({
        action: 'Send Form',
        actionData: {
          text: 'send',
          form: MakeForm
        }
      });
    }],
    [has('ClientList'), ({ ClientList }) => {
      const changeType = head(keys<keyof string[]>(ClientList.change)).toLowerCase();
      const change = campaignChangeActionOptions.find((c) => includes(changeType, c));
      const defcl = {
        listId: defaultCampaign?.id,
        listName: defaultCampaign?.name,
      };
      let cl = isNil(ClientList.listId) && defaultCampaign ? defcl : ClientList.listId.Id;
      let isPath = pathOrIdList[0].replace('{{item}}', 'client list');
      let path = 'spark.0.';
      if (ClientList.listId.Path) {
        isPath = pathOrIdList[1].replace('{{item}}', 'client list');
        path = ClientList.listId.Path.join('.');
        cl = defcl;
      }
      return ({
        action: 'Campaign',
        actionData: {
          campaign: {
            ...cl
          },
          change,
          isPath,
          path,
        },
      });
    }],
    [has('AppointmentList'), ({ AppointmentList }) => {
      let isPath = pathOrIdList[0].replace('{{item}}', 'appointment list');
      let path = undefined;
      if (AppointmentList.listId?.Path) {
        isPath = pathOrIdList[1].replace('{{item}}', 'appointment list');
        path = AppointmentList.listId.Path.join('.');
      }
      return ({
        action: 'Appointment List',
        actionData: {
          apptList: {
            ...AppointmentList.listId.Id,
          },
          change: cond([
            [equals('Add'), () => apptListChangeActionOptions[0]],
            [equals('Remove'), () => apptListChangeActionOptions[1]],
          ])(AppointmentList.change),
          isPath,
          path,
        },
      });
    }],
    [has('UpdateAppt'), ({ UpdateAppt }) => {
      const {
        scope,
        status,
        updateConfirmed,
      } = UpdateAppt;
      return ({
        action: 'Appointment',
        actionData: {
          status: updateConfirmed ? 'Confirmed' : status,
          scope: cond([
            [equals('Trigger'), always('this')],
            [equals('Next'), always('next')],
            [equals('Today'), always('today\'s')],
          ])(scope)
        },
      });
    }],
    [T, always({
      action: '',
      actionData: {},
    })]
  ])(action);

const dirCond = (to: 'to' | 'from', dir: string) => {
  if (to === 'to') {
    return cond([
      [equals(directionalOptions[0]), always('InFuture')],
      [equals(directionalOptions[1]), always('Ago')],
      [equals(directionalOptions[2]), always('Date')],
    ])(dir);
  } else {
    return cond([
      [equals('InFuture'), always(directionalOptions[0])],
      [equals('Ago'), always(directionalOptions[1])],
      [equals('Date'), always(directionalOptions[2])],
    ])(dir);
  }
};

/* `idx` is the starting index */
const convertTime =
  (verbs: string[], idx: number) => cond([
    [has('EqualTo'), (e: DateTimeFilter) =>
      cond([
        [has('Date'), (t: InnerDateTimeFilter) => [
          verbs[idx], t, undefined, undefined
        ]],
        [has('Ago'), (t: InnerDateTimeFilter) => [
          verbs[idx + 1], t, undefined, undefined,
        ]],
        [has('InFuture'), (t: InnerDateTimeFilter) => [
          verbs[idx + 2], t, undefined, undefined,
        ]],
      ])(e.EqualTo)],
    [has('Between'), (e: DateTimeFilter) => {
      const dir1 = keys(e.Between.start)[0];
      const val1 = e.Between.start[dir1];
      const pretty1 = dirCond('from', dir1);
      const dir2 = keys(e.Between.end)[0];
      const val2 = e.Between.end[dir2];
      const pretty2 = dirCond('from', dir2);
      return [
        verbs[idx + 3],
        { BetweenDatesStart: val1, BetweenDatesEnd: val2 },
        pretty1,
        pretty2
      ];
    }],
    [has('GreaterThan'), (t: DateTimeFilter) => {
      const dir = keys(t.GreaterThan)[0];
      const val = { GreaterThan: t.GreaterThan[dir] as TimeUnit };
      const pretty = dirCond('from', dir);
      return [verbs[idx + 4], val, pretty, undefined];
    }],
    [has('GreaterThanEqual'), (t: DateTimeFilter) => {
      const dir = keys(t.GreaterThanEqual)[0];
      const val = { GreaterThanEqual: t.GreaterThanEqual[dir] as TimeUnit };
      const pretty = dirCond('from', dir);
      return [verbs[idx + 5], val, pretty, undefined];
    }],
    [has('LessThan'), (t: DateTimeFilter) => {
      const dir = keys(t.LessThan)[0];
      const val = { LessThan: t.LessThan[dir] as TimeUnit };
      const pretty = dirCond('from', dir);
      return [verbs[idx + 6], val, pretty, undefined];
    }],
    [has('LessThanEqual'), (t: DateTimeFilter) => {
      const dir = keys(t.LessThanEqual)[0];
      const val = { LessThanEqual: t.LessThanEqual[dir] as TimeUnit };
      const pretty = dirCond('from', dir);
      return [verbs[idx + 7], val, pretty, undefined];
    }],
    [T, always([undefined, {}, undefined, undefined])]
  ]);

const filterTo = (defaultCampaign: DefaultList, filterData: GetFilter) => {
  let data: FilterData;
  const isTrue = filterData.isTrue;
  if (has('Appointment', filterData.predicate)) {
    const innerData = filterData.predicate.Appointment;
    if (has('Status', innerData)) {
      data = ({
        type: 'Appointment',
        appointmentTypeIds: undefined,
        status: innerData.Status as string[],
        verb: apptVerbs[2],
        ...emptyDate,
        thanDirection: undefined,
        thanDirection1: undefined,
        time: undefined,
        offset: undefined,
        repeat: false,
        campaign: undefined,
        numberOfAppointments: undefined,
      });
    } else if (has('Time', innerData)) {
      const [time0, time1, time2, time3] = convertTime(
        apptVerbs, 3
      )(innerData.Time);
      data = ({
        type: 'Appointment',
        status: undefined,
        appointmentTypeIds: undefined,
        time: time0 as string,
        ...emptyDate,
        ...time1,
        thanDirection: time2 as string,
        thanDirection1: time3 as string,
        verb: String(time0),
        offset: undefined,
        repeat: false,
        campaign: undefined,
        numberOfAppointments: undefined,
      });
    } else if (has('AppointmentTypeIds', innerData)) {
      const appointmentTypeIds =
        isNil(innerData.AppointmentTypeIds) ?
          [0] :
          innerData.AppointmentTypeIds as number[];
      data = ({
        type: 'Appointment',
        status: undefined,
        appointmentTypeIds,
        verb: apptVerbs[0],
        time: undefined,
        ...emptyDate,
        thanDirection: undefined,
        thanDirection1: undefined,
        offset: undefined,
        repeat: false,
        campaign: undefined,
        numberOfAppointments: undefined,
      });
    } else if (has('AppointmentType', innerData)) {
      // TODO: support the Id part
      const apt = innerData.AppointmentType as unknown as PathOrId;
      const path = (apt.PathExact || apt.PathRelative || []).join('.');
      data = ({
        type: 'Appointment',
        status: undefined,
        appointmentTypeIds: [],
        verb: apptVerbs[1],
        time: undefined,
        ...emptyDate,
        thanDirection: undefined,
        thanDirection1: undefined,
        offset: undefined,
        repeat: false,
        campaign: undefined,
        numberOfAppointments: undefined,
        path,
      });
    } else if (has('ClientList', innerData)) {
      const cl = innerData.ClientList;
      const list = isNil(cl.listId) && defaultCampaign ? {
        listId: defaultCampaign.id,
        listName: defaultCampaign.name,
      } : cl;
      const appointmentTypeIds =
        isNil(cl.appointmentTypes) ?
          [0] :
          cl.appointmentTypes as number[];
      data = ({
        type: 'Appointment',
        status: undefined,
        appointmentTypeIds,
        verb: cl.repeat ? apptVerbs[12] : apptVerbs[11],
        time: undefined,
        ...emptyDate,
        thanDirection: undefined,
        thanDirection1: undefined,
        offset: cl.offset,
        repeat: false,
        campaign: {
          listId: list.listId,
          listName: list.listName,
        },
        numberOfAppointments: cl.numberOfAppointments,
      });
    } else {
      data = ({
        type: 'Appointment',
        status: undefined,
        appointmentTypeIds: undefined,
        time: undefined,
        ...emptyDate,
        thanDirection: undefined,
        thanDirection1: undefined,
        verb: undefined,
        offset: undefined,
        repeat: false,
        campaign: undefined,
        numberOfAppointments: undefined,
      });
    }
  } else if (has('Client', filterData.predicate)) {
    const innerData = filterData.predicate.Client;
    if (has('Status', innerData)) {
      data = ({
        type: 'Client',
        status: typeof innerData.Status === 'string' ? innerData.Status : innerData.Status.status as string,
        verb: 'has status of',
        ...emptyDate,
        thanDirection: undefined,
        thanDirection1: undefined,
        campaign: undefined,
        message: undefined,
        added: undefined,
        appointmentTypeIds: [],
        statuses: undefined,
        isPath: pathOrIdList[0].replace('{{item}}', 'client list'),
      });
    } else if (has('ClientList', innerData)) {
      if (has('ClientList', innerData)) {
        const clientList: any = innerData.ClientList;
        const [added0, added1, added2, added3] = convertTime(
          dateTimeFilters, 0
        )(clientList.added);
        const verb = added0 ? 'was added to' : 'is in';
        const defcl = {
          ...defaultCampaign,
          listId: defaultCampaign?.id,
          listName: defaultCampaign?.name,
        };
        let cl = isNil(clientList.listId) && defaultCampaign ? defcl : clientList.listId.Id;
        let isPath = pathOrIdList[0].replace('{{item}}', 'client list');
        let path = undefined;
        if (clientList.listId.PathExact) {
          isPath = pathOrIdList[1].replace('{{item}}', 'client list');
          path = clientList.listId.PathExact.join('.');
          cl = defcl;
        } else if (clientList.listId.PathRelative) {
          isPath = pathOrIdList[2].replace('{{item}}', 'client list');
          path = clientList.listId.PathRelative.join('.');
          cl = defcl;
        }
        data = ({
          type: 'Client',
          campaign: {
            ...cl
          },
          verb,
          added: added0 as string,
          ...emptyDate,
          ...added1,
          thanDirection: added2 as string,
          thanDirection1: added3 as string,
          message: undefined,
          status: undefined,
          appointmentTypeIds: [],
          statuses: undefined,
          isPath,
          path,
        });
      } else {
        data = ({
          type: 'Client',
          verb: undefined,
          status: undefined,
          campaign: undefined,
          message: undefined,
          added: undefined,
          ...emptyDate,
          thanDirection: undefined,
          thanDirection1: undefined,
          appointmentTypeIds: [],
          statuses: undefined,
          isPath: pathOrIdList[0].replace('{{item}}', 'client list'),
        });
      }
    } else if (has('SentMsg', innerData)) {
      if (has('SentMsg', innerData)) {
        const sentMsg: any = innerData.SentMsg;
        const pathOrId: any = sentMsg.msg;
        const [added0, added1, added2, added3] = convertTime(
          dateTimeFilters, 0
        )(sentMsg.sentAt);
        let verb = 'was sent';
        let path = '';
        if (pathOrId.PathRelative) {
          verb = 'was sent any message below';
          path = pathOrId.PathRelative.join('.');
        } else if (pathOrId.PathExact) {
          verb = 'was sent any message in';
          path = pathOrId.PathRelative.join('.');
        }
        data = ({
          type: 'Client',
          message: 'Id' in pathOrId ? {
            ...pathOrId.Id as Message
          } : {
            msgId: 0, name: '',
          },
          verb,
          added: isNil(added0) ? 'anytime' : added0 as string,
          ...emptyDate,
          ...added1,
          thanDirection: added2 as string,
          thanDirection1: added3 as string,
          status: undefined,
          campaign: undefined,
          appointmentTypeIds: [],
          statuses: undefined,
          path,
          isPath: pathOrIdList[0].replace('{{item}}', 'client list'),
        });
      } else {
        data = ({
          type: 'Client',
          verb: undefined,
          status: undefined,
          campaign: undefined,
          message: undefined,
          added: undefined,
          ...emptyDate,
          thanDirection: undefined,
          thanDirection1: undefined,
          appointmentTypeIds: [],
          statuses: undefined,
          isPath: pathOrIdList[0].replace('{{item}}', 'client list'),
        });
      }
    } else if (has('HasAppointment', innerData)) {
      // TODO: support the Path* types
      const hasAppointment: any = innerData.HasAppointment;
      const [added0, added1, added2, added3] = convertTime(
        dateTimeFilters, 0
      )(hasAppointment.time);
      const apt = hasAppointment.appointmentTypes as PathOrId;
      const appointmentTypeIds = apt?.Id as number[] || [0];
      const status = hasAppointment.status || [];
      data = ({
        type: 'Client',
        verb: 'has appointment',
        status,
        campaign: undefined,
        message: undefined,
        added: added0 as string,
        ...emptyDate,
        ...added1,
        thanDirection: added2 as string,
        thanDirection1: added3 as string,
        appointmentTypeIds,
        statuses: status,
        isPath: pathOrIdList[0].replace('{{item}}', 'client list'),
      });
    } else if (has('LastAppointment', innerData)) {
      const lastAppointment: any = innerData.LastAppointment;
      const [added0, added1, added2, added3] = convertTime(
        dateTimeFilters, 0
      )(lastAppointment.time);
      const path =
        (lastAppointment.appointmentTypes?.PathExact ||
         lastAppointment.appointmentTypes?.PathRelative || []).join('.');
      console.log(path, lastAppointment);
      data = ({
        type: 'Client',
        verb: 'last appointment',
        status,
        campaign: undefined,
        message: undefined,
        added: added0 as string,
        ...emptyDate,
        ...added1,
        thanDirection: added2 as string,
        thanDirection1: added3 as string,
        appointmentTypeIds: [],
        statuses: [],
        path,
        isPath: pathOrIdList[0].replace('{{item}}', 'client list'),
      });
    } else {
      data = ({
        type: 'Client',
        verb: undefined,
        status: undefined,
        campaign: undefined,
        message: undefined,
        added: undefined,
        ...emptyDate,
        thanDirection: undefined,
        thanDirection1: undefined,
        appointmentTypeIds: [],
        statuses: undefined,
        path: undefined,
        isPath: pathOrIdList[0].replace('{{item}}', 'client list'),
      });
    }
  }
  const filter = has('Appointment', filterData.predicate) ? 'appointment' : 'client';
  return {
    filter,
    filterData: data,
    isTrue,
  };
};

/*const calculateRemainingFilters = (state: AutomationsState) => {
  return pipe(
    cond([
      [equals('Dummy'), () => []],
      [equals('ClientList'), () => ['client']],
      [equals('MessageReceived'), () => ['client']],
      [equals('ApptUpdated'), () => ['client', 'appointment']],
      [equals('Time'), () => ['client', 'appointment']],
      [equals('DateTime'), () => ['client', 'appointment']],
      [T, always([])]
    ]),
    filter((f) => !state.filters.find((inner) => {
      return inner.filter === f;
    })),
    length
  )(state.trigger);
}; */

interface RenderOptionsProps {
  state: AutomationsState,
  update: (data: any) => void;
  classes: any;
  type: string;
  index?: number;
  types: any[];
  npps: any[];
  professionals: any[];
  defaultCampaign?: DefaultList;
  isAdmin?: boolean;
}

interface LocalRenderData {
  renderData: TriggerRenderData | FilterRenderData | ActionRenderData;
  data: TriggerData | FilterData | ActionData;
  target: keyof (TriggerRenderData | FilterRenderData | ActionRenderData);
  dataName: 'triggerData' | 'actions' | 'filters';
  innerDataName?: keyof (Action | Filter);
  blackList?: (value: string) => string[];
}

interface AllRenderData {
  type?: string;
  changeType?: (data: CurrentState) => InputType['type'];
  options?: string[];
  onChange?: (data: CurrentState) => AutomationsStateSubFilters;
  showIf?: (data: CurrentState) => boolean;
  multiple?: boolean;
  placeholder?: string;
  prefix?: string | ((data: CurrentState) => string);
  postfix?: string;
  hasError?: (data: CurrentState) => boolean;
  sectionHeaders?: SectionHeadersMap;
  style?: any;
}

const RenderOptions = ({
  state,
  update,
  classes,
  type,
  index,
  types,
  professionals,
  npps,
  defaultCampaign,
  isAdmin,
}: RenderOptionsProps): JSX.Element => {
  const {
    renderData,
    target,
    data,
    dataName,
    innerDataName,
  }: LocalRenderData = cond([
    [equals('trigger'), () => ({
      renderData: triggerRenderData,
      target: state.trigger,
      data: state.triggerData,
      dataName: 'triggerData',
    })],
    [equals('action'), () => ({
      renderData: actionRenderData,
      target: state.actions[index].action,
      data: state.actions[index].actionData,
      dataName: 'actions',
      innerDataName: 'actionData',
    })],
    [equals('filter'), () => ({
      renderData: filterRenderData,
      target: state.filters[index].filter,
      data: state.filters[index].filterData,
      dataName: 'filters',
      innerDataName: 'filterData',
      blackList: (value: string) => pipe(
        filter(({ filter }) => filter === value),
        map(pathOr('', ['filterData', 'verb']))
      )(state.filters),
    })],
    [T, () => ({
      renderData: undefined,
      target: undefined,
      data: undefined,
      dataName: undefined,
    })]
  ])(type);

  const { tz } = useSelector((state) => ({
    tz: state.login.office.timezone,
  }));

  return mapWithIndex(({
    type, options, onChange, showIf, multiple,
    placeholder, prefix, postfix, hasError,
    sectionHeaders, style, changeType,
  }: AllRenderData, idx: number) => {
    const name = keys(renderData[target])[idx];
    let show = true;
    if (showIf) {
      show = showIf({ data, state, index });
    }
    const renderedPlaceholder = placeholder ? placeholder : 'has something happen...';
    if (changeType) {
      type = changeType({ data, state, index });
    }
    let error = false;
    if (hasError) {
      error = hasError({ state, data, index });
    }
    const handleUpdate = (value: any) => {
      if (dataName === 'triggerData') {
        const newData = {
          ...data,
          [name]: value,
        };
        update({
          [dataName]: newData,
        });
      } else {
        const s = state[dataName];
        const newData = adjust(index, (obj: Filter | Action) => ({
          ...obj,
          [innerDataName]: {
            ...obj[innerDataName] as FilterData | ActionData,
            [name]: value
          },
        }), s);
        update({
          [dataName]: newData,
        });
      }
    };
    return show &&
      (<div
        key={idx}
        style={{
          display: 'flex',
          alignItems: 'center',
        }}>
        {prefix &&
          <div
            className={classes.marginForErrors}
            style={{ marginRight: '10px' }}>
            {typeof prefix === 'string' ? prefix : prefix({ data, state })}
          </div>}
        {cond([
          [equals('action-select'), () => (
            <FormControl error={error}>
              <Select
                value={target}
                displayEmpty
                style={{ marginRight: '10px' }}
                renderValue={(selected) => {
                  if (isEmpty(selected)) {
                    return (
                      <div className={classes.selectTextContainer}>
                        <em className={classes.selectText}>something...</em>
                      </div>
                    );
                  } else {
                    return (
                      <div className={classes.selectTextContainer}>
                        <span className={classes.selectText}>{selected}</span>
                      </div>
                    );
                  }
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value: string = e.target.value;
                  const newData = actionData[value as keyof GlobalActionData];
                  const campaign = defaultCampaign ? {
                    listId: defaultCampaign.id,
                    listName: defaultCampaign.name,
                  } : null;
                  const newActionData = cond([
                    [equals('Nothing'), () => ({
                      type: 'Nothing',
                      ...newData,
                    })],
                    [equals('Appointment'), () => ({
                      type: 'Appointment',
                      ...newData,
                      campaign,
                    })],
                    [equals('Campaign'), () => ({
                      type: 'Nothing',
                      ...newData,
                      campaign,
                    })],
                    [equals('SendMessage'), () => ({
                      type: 'Nothing',
                      ...newData,
                      campaign,
                    })],
                  ])(newData.type);
                  update({
                    actions: Rupdate(index, {
                      action: value,
                      actionData: newActionData,
                    })(state.actions)
                  });
                }}
              >
                {actions.map((v) => (
                  <MenuItem key={v} value={v}>
                    <ListItemText primary={v} />
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{error ? 'Required' : ' '}</FormHelperText>
            </FormControl>
          )],
          [equals('filter-select'), () => (
            <FormControl error={error}>
              <Select
                value={target}
                displayEmpty
                style={{ marginRight: '10px' }}
                renderValue={(selected) => {
                  if (isEmpty(selected)) {
                    return (
                      <div className={classes.selectTextContainer}>
                        <em className={classes.selectText}>something...</em>
                      </div>
                    );
                  } else {
                    return (
                      <div className={classes.selectTextContainer}>
                        <span className={classes.selectText}>{selected}</span>
                      </div>
                    );
                  }
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  const f = filterData[value as keyof GlobalFilterData];
                  const isTrue = state.filters[index]?.isTrue;
                  const campaign = defaultCampaign ? {
                    listId: defaultCampaign.id,
                    listName: defaultCampaign.name,
                  } : null;
                  const defaultFilterData =
                    cond([
                      [equals('Nothing'), () => ({
                        type: 'Nothing',
                        ...f,
                      })],
                      [equals('Appointment'), () => ({
                        type: 'Appointment',
                        ...f,
                      })],
                      [equals('Client'), () => ({
                        type: 'Client',
                        ...f,
                        campaign,
                      })]
                    ])(f.type);
                  update({
                    filters: Rupdate(index, {
                      isTrue,
                      filter: value,
                      filterData: defaultFilterData,
                    })(state.filters)
                  });
                }}
              >
                {filters.map((v) => {
                  return (
                    <MenuItem key={v} value={v}>
                      <ListItemText primary={v} />
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{error ? 'Required' : ' '}</FormHelperText>
            </FormControl>
          )],
          [equals('select'), () => (
            <FormControl error={error}>
              <Select
                key={idx}
                value={data && data[name]}
                displayEmpty
                multiple={multiple}
                disabled={dataName === 'triggerData' && !isAdmin}
                renderValue={(selected: string[]) => {
                  if (isEmpty(selected)) {
                    return (
                      <div className={classes.selectTextContainer}>
                        <em className={classes.selectText}>{renderedPlaceholder}</em>
                      </div>
                    );
                  } else if (multiple) {
                    return (
                      <div className={classes.selectTextContainer}>
                        <span className={classes.selectText}>{selected.join(', ')}</span>
                      </div>
                    );
                  } else {
                    return (
                      <div className={classes.selectTextContainer}>
                        <span className={classes.selectText}>{selected}</span>
                      </div>
                    );
                  }
                }}
                style={{ marginRight: '10px' }}
                onChange={(e) => {
                  const value = e.target.value;
                  if (onChange) {
                    update(onChange({
                      state,
                      data,
                      value,
                      index,
                      defaultCampaign,
                    }));
                  } else {
                    handleUpdate(value);
                  }
                }}
              >
                {options.map((v: string, idx: number) => {
                  let header: JSX.Element = null;
                  if (sectionHeaders && sectionHeaders[idx]) {
                    header = (
                      <ListSubheader>{sectionHeaders[idx]}</ListSubheader>
                    );
                  }
                  return [
                    header,
                    (
                      <MenuItem
                        key={v}
                        value={v}
                      >
                        {multiple && <Checkbox checked={includes(v, data[name])} />}
                        <ListItemText primary={v} />
                      </MenuItem>
                    )];
                })}
              </Select>
              <FormHelperText>{error ? 'Required' : ' '}</FormHelperText>
            </FormControl>
          )],
          [equals('text'), () => (
            <SmallerTextField
              className={classes.textField}
              type='text'
              value={(data && data[name]) || ''}
              placeholder={placeholder}
              error={error}
              helperText={error ? 'Required' : ' '}
              onChange={(e) => {
                const value = e.target.value;
                if (onChange) {
                  onChange({ state, data, value });
                } else {
                  handleUpdate(value);
                }
              }}
            />
          )],
          [equals('number'), () => (
            <NumberTextField
              className={classes.textField}
              type='number'
              value={data[name]}
              placeholder={placeholder}
              error={error}
              helperText={error ? 'Required' : ' '}
              onChange={(e) => {
                const value = e.target.value;
                if (onChange) {
                  onChange({ state, data, value });
                } else {
                  handleUpdate(value);
                }
              }}
            />
          )],
          [equals('time'), () => (
            <TimeTextField
              type='time'
              className={classes.textField}
              value={(data[name] as string).substring(0, 5)}
              error={error}
              helperText={error ? 'Required' : ' '}
              onChange={(e) => {
                const value = e.target.value;
                if (onChange) {
                  onChange({ state, data, value });
                } else {
                  handleUpdate(value);
                }
              }} />
          )],
          [equals('date'), () => (
            <TimeTextField
              type='date'
              className={classes.textField}
              value={data[name]}
              error={error}
              helperText={error ? 'Required' : ' '}
              onChange={(e) => {
                const value = e.target.value;
                if (onChange) {
                  onChange({ state, data, value });
                } else {
                  handleUpdate(value);
                }
              }} />
          )],
          [equals('TimeUnit'), () => {
            const localOnChange = (prop: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
              const newUnit = e.target.value;
              const value = {
                ...(data[name] as TimeUnit),
                [prop]: newUnit,
              };
              if (onChange) {
                onChange({ state, data, value });
              } else {
                handleUpdate(value);
              }
            };
            return (
              <>
                <NumberTextField
                  className={`${classes.textField} ${classes.timeUnit}`}
                  type='number'
                  value={(data[name] as TimeUnit).days}
                  placeholder={placeholder}
                  error={error}
                  helperText={error ? 'Required' : ' '}
                  label='Days'
                  onChange={localOnChange('days')}
                />
                <NumberTextField
                  className={`${classes.textField} ${classes.timeUnit}`}
                  type='number'
                  value={(data[name] as TimeUnit).months}
                  placeholder={placeholder}
                  error={error}
                  helperText={error ? 'Required' : ' '}
                  label='Months'
                  onChange={localOnChange('months')}
                />
                <NumberTextField
                  className={`${classes.textField}`}
                  type='number'
                  value={(data[name] as TimeUnit).years}
                  placeholder={placeholder}
                  error={error}
                  helperText={error ? 'Required' : ' '}
                  label='Years'
                  onChange={localOnChange('years')}
                />
              </>
            );
          }],
          [equals('datetime'), () => {
            const mod = data[name] && tzParse(
              data[name] +
                ((data[name] as string).length < 19 ? ':00Z' : 'Z'),
              tz
            );
            const fmod = mod && format(mod, 'yyyy-MM-dd\'T\'HH:mm:ss');
            return (
              <TimeTextField
                type='datetime-local'
                className={classes.textField}
                value={fmod}
                error={error}
                helperText={error ? 'Required' : ' '}
                onChange={(e) => {
                  const value = e.target.value;
                  const [date, time] = value.split('T');
                  const fpar = ZonedDateTime
                    .of(
                      LocalDate.parse(date),
                      LocalTime.parse(time),
                      ZoneId.of(tz)
                    ).withZoneSameInstant(ZoneOffset.UTC);
                  const p = format(fpar, 'yyyy-MM-dd\'T\'HH:mm:ss');
                  if (onChange) {
                    onChange({ value: p, state, data });
                  } else {
                    handleUpdate(p);
                  }
                }} />
            );
          }],
          [equals('text-array'), () => {
            const v = data[name] as string[];
            return (
              <>
                {v.map((val, i) => (
                  <div
                    key={i}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <SmallerTextField
                      className={classes.textField}
                      type='text'
                      value={val}
                      placeholder={placeholder}
                      error={error || isEmpty(v)}
                      helperText={(error || isEmpty(v)) ? 'Required' : ' '}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (onChange) {
                          onChange({ value, state, data });
                        } else {
                          handleUpdate(Rupdate(i, value, data[name]));
                        }
                      }}
                      InputProps={{
                        endAdornment: v.length > 1 && (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Remove Value"
                              onClick={() => {
                                handleUpdate(remove(i, 1, data[name]));
                              }}
                              edge="end"
                            >
                              <DeleteIcon fontSize='small' />
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                    {i + 1 < v.length &&
                      <div style={{
                        marginLeft: '10px',
                        marginRight: '10px'
                      }}>
                        or
                      </div>}
                  </div>
                ))}
                <Tooltip arrow title='Add value'>
                  <IconButton onClick={() => {
                    handleUpdate(append('', data[name]));
                  }}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </>
            );
          }],
          [equals('Campaign'), () => (
            <ClientListSearch
              clientLists={has('campaign', data) ? data.campaign as unknown as DefaultList : {} as DefaultList}
              setClientLists={({ id, name }: DefaultList) => {
                handleUpdate({
                  listId: id,
                  listName: name,
                });
              }}
              clearClientLists={() => {
                handleUpdate({
                  listId: undefined,
                  listName: '',
                });
              }}
              style={{ marginRight: '10px' }}
              isCampaign
              error={error}
            />
          )],
          [equals('ApptList'), () => (
            <ApptListSearch
              apptLists={has('apptList', data) ? data.apptList as unknown as DefaultList : {} as DefaultList}
              setApptLists={({ id, name }: DefaultList) => {
                handleUpdate({
                  listId: id,
                  listName: name,
                });
              }}
              clearApptLists={() => {
                handleUpdate({
                  listId: undefined,
                  listName: '',
                });
              }}
              style={{ marginRight: '10px' }}
              error={error}
            />
          )],
          [equals('Message'), () => (
            <MessageSearch
              messages={has('message', data) ? data.message : {} as Message}
              setMessages={({ id, name }: DefaultMessage) =>
                handleUpdate({
                  msgId: id,
                  name,
                })}
              clearMessages={() =>
                handleUpdate({
                  msgId: 0,
                  name: '',
                })
              }
              style={{ marginRight: '10px' }}
              error={error}
            />
          )],
          [equals('AppointmentType'), () => (
            <AppointmentTypeAutocomplete
              value={has('appointmentTypeIds', data) ? data.appointmentTypeIds : []}
              width='max-content'
              maxWidth='calc(100vw - 700px)' // idk if the 700px is right for all screens
              minWidth='150px'
              disableCloseOnSelect
              noneIsAll={true}
              onChange={({ target }: any) =>
                handleUpdate(target.ids)
              }
              types={types}
              professionals={professionals}
              id={`apt-type-${index}`}
              multiple={multiple}
              includeErrors
              error={error}
              style={style}
            />
          )],
          [equals('NPP'), () => (
            <NppSearch
              value={has('nppId', data) ? data.nppId : []}
              onChange={({ target }: { target: { value: number | number[] } }) =>
                handleUpdate(target.value)
              }
              id={`apt-type-${index}`}
              style={{ marginRight: '10px' }}
              npps={npps}
              multiple={multiple}
              error={error}
            />
          )],
          [equals('Form'), () => {
            return (
              <FormSearch
                value={has('form', data) ? data.form : null}
                setForms={({ id, name }: DefaultForm) =>
                  handleUpdate({
                    formId: id,
                    name,
                  })
                }
                clearForms={() =>
                  handleUpdate({
                    formId: 0,
                    name: '',
                  })
                }
                id={`form-${index}`}
                style={{ marginRight: '10px' }}
                error={error}
              />
            );
          }],
        ])(type)}
        {postfix &&
          <div
            className={classes.marginForErrors}
            style={{ marginLeft: '10px' }}>
            {postfix}
          </div>}
      </div>);
  }, values(renderData[target]));
};

const clientListChange = cond([
  [has('Removed'), always('is removed from ')],
  [has('Unsubscribed'), always('unsubscribes from ')],
  [has('Added'), always('is added to ')],
]);
const apptListChange = cond([
  [equals('Removed'), always('is removed from ')],
  [equals('Added'), always('is added to ')],
]);
const clientListAction = cond([
  [has('Remove'), always('remove client from ')],
  [has('Add'), always('add client to ')],
  [has('Unsubscribe'), always('unsubscribe client from ')],
]);
const apptListAction = cond([
  [equals('Remove'), always(`${apptListChangeActionOptions[1]} `)],
  [equals('Add'), always(`${apptListChangeActionOptions[0]} `)],
]);

interface CreateWordsProps {
  automation: Automation;
  types?: any[];
  npps?: any[];
  openMessage?: (msg: SendMsgGetAction, i: number) => void;
  classes: any;
  tz: string;
}

interface Filties {
  Appointment?: GetFilter[];
  Client?: GetFilter[];
}

const createWords = ({
  automation, types, npps,
  openMessage, classes, tz,
}: CreateWordsProps): (string | JSX.Element)[] => {
  const triggerWords: (string | JSX.Element)[] = cond([
    [has('Dummy'), () => ['When something happens to target']],
    [has('ClientList'), ({ ClientList }) => {
      let clientOrCampaign;
      if (ClientList.listId.PathExact) {
        clientOrCampaign = <span>&nbsp;a {ClientList.isCampaign ? 'campaign ' : 'client list '}
          at <b>{ClientList.listId.PathExact.join('.')} </b>&nbsp;</span>;
      } else if (ClientList.listId.PathRelative) {
        clientOrCampaign = <span>&nbsp;any {ClientList.isCampaign ? 'campaign ' : 'client list '}
        at <b>{ClientList.listId.PathRelative.join('.')} </b>&nbsp;</span>;
      } else if (ClientList.listId.Id) {
        clientOrCampaign = ClientList.isCampaign ?
          <Tooltip key={`t-target-list-${ClientList.listId.Id.listId}`} arrow title='Campaign'>
            <span>
              <Link
                key={`target-list-${ClientList.listId.Id.listId}`}
                to={`/campaign/${ClientList.listId.Id.listId}`}>
                {ClientList.listId.Id.listName}
              </Link>
            </span>
          </Tooltip> :
          <Tooltip key={`t-target-list-${ClientList.listId.Id.listId}`} arrow title='Client List'>
            <span>
              <Link
                key={`target-list-${ClientList.listId.Id.listId}`}
                to={`/client-list/${ClientList.listId.Id.listId}`}>
                {ClientList.listId.Id.listName}
              </Link>
            </span>
          </Tooltip>;
      }
      return ([
        'When client ',
        <b key={`change-${ClientList.listId}`}>{clientListChange(ClientList.change)}</b>,
        !ClientList.listId &&
        <b className={classes.variable} key='blank-campaign-trigger'>[campaign]</b>,
        ClientList.listId && clientOrCampaign,
      ]);
    }],
    [has('AppointmentList'), ({ AppointmentList }) => {
      let appt;
      if (AppointmentList.listId.PathExact) {
        appt = <span>&nbsp;an appointment list at <b>{AppointmentList.listId.PathExact.join('.')} </b>&nbsp;</span>;
      } else if (AppointmentList.listId.PathRelative) {
        appt = <span>&nbsp;any appointment list
        inside <b>{AppointmentList.listId.PathRelative.join('.')} </b>&nbsp;</span>;
      } else {
        appt = <Tooltip key={`t-target-appt-list-${AppointmentList.listId.Id.listId}`} arrow title='Appointment List'>
          <span>
            <Link
              key={`target-appt-list-${AppointmentList.listId.Id.listId}`}
              to={`/appointment-list/${AppointmentList.listId.Id.listId}`}>
              {AppointmentList.listId.Id.listName}
            </Link>
          </span>
        </Tooltip>;
      }
      return ([
        'When appointment ',
        <b key={`change-${AppointmentList.listId}`}>{apptListChange(AppointmentList.change)}</b>,
        !AppointmentList.listId &&
        <b className={classes.variable} key='blank-campaign-trigger'>[appointment list]</b>,
        appt
      ]);
    }],
    [has('MessageReceived'), ({ MessageReceived }) => ([
      'When message received is ',
      MessageReceived.map((msg: string, idx: number) => (
        <div
          key={`em-em-${msg}-${idx}`}
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <i style={{ marginRight: '6px' }}>"{msg}"</i>
          {idx + 1 < MessageReceived.length &&
            <div>
              or
            </div>
          }
        </div>
      )),
    ])],
    [has('SentMsg'), ({ SentMsg }) => ([
      'When ',
      isEmpty(SentMsg) ?
        <b className={classes.variable} key='blank-message-trigger'>[message]</b>
        :
        <Tooltip key={`t-target-msg-${(SentMsg.msgId as PathOrId).Id}`} arrow title='Message'>
          <span>
            <Link
              key={`target-msg-${(SentMsg.msgId as PathOrId).Id}`}
              to={`/automation/view/${(SentMsg.msgId as PathOrId).Id}`}>
              {SentMsg.name}
            </Link>
          </span>
        </Tooltip>,
      'is sent',
    ])],
    [has('ApptUpdated'), ({ ApptUpdated }) => ([
      'When an appointment is ',
      <b key='trigger-appt-updated' style={{ marginRight: '6px' }}>{
        ifElse(
          isNil,
          always(['Updated']),
          map(
            cond([
              [equals('ChangedArrived'), always('Arrived')],
              [equals('ChangedCanceled'), always('Canceled')],
              [equals('ChangedConfirmed'), always('Confirmed')],
              [T, identity]
            ])
          )
        )(ApptUpdated.changeType).join(' or ')
      }</b>,
      ' from ',
      (
        <div
          key='sources'
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '3px',
          }}>
          {!ApptUpdated.source &&
            <div
              key={`em-em-${0}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '3px',
              }}
            >
              any source
            </div>}
          {ApptUpdated.source &&
            ApptUpdated.source.map((s: ChangedSource, idx: number) => {
              const v = keys(s)[0];
              const pretty = cond([
                [equals('AnyNpp'), always(<i>Any New Patient Portal</i>)],
                [equals('Npp'), () => {
                  const npp = npps &&
                    npps.find(({ newPatientPortalSettingsId }) => {
                      if (has('Npp', s)) {
                        return newPatientPortalSettingsId === s.Npp;
                      }
                      return false;
                    });
                  return npp &&
                    <Tooltip arrow title='New Patient Portal'>
                      <span>
                        <Link to={`/settings/new-patient-portal/${npp.newPatientPortalSettingsId}`}>
                          {npp.name}
                        </Link>
                      </span>
                    </Tooltip>;
                }],
                [T, (v) => <i>{v}</i>]
              ])(v);
              return (
                <div
                  key={`em-em-${idx}`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {pretty}
                  {idx + 1 < ApptUpdated.source.length &&
                    <div>
                      ,
                    </div>
                  }
                </div>
              );
            })}
        </div>),
    ])],
    [has('Client'), ({ Client }) => ([
      'When an client is ',
      <b key='trigger-client' style={{ marginRight: '6px' }}>{
        map(
          cond([
            [equals('Added'), always('added')],
            [equals('Inactive'), always('marked as inactive')],
            [equals('Inert'), always('marked as inert')],
            [T, identity]
          ]),
          Client.changeType).join(' or ')
      }</b>,
      ' from ',
      (
        <div
          key='sources'
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: '3px',
          }}>
          {!Client.source &&
            <div
              key={`em-em-${0}`}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '3px',
              }}
            >
              any source
            </div>}
          {Client.source &&
            Client.source.map((s: ChangedSource, idx: number) => {
              const v = keys(s)[0];
              const pretty = cond([
                [equals('AnyNpp'), always(<i>Any New Patient Portal</i>)],
                [equals('Npp'), () => {
                  const npp = npps &&
                    npps.find(({ newPatientPortalSettingsId }) => {
                      if (has('Npp', s)) {
                        return newPatientPortalSettingsId === s.Npp;
                      }
                      return false;
                    });
                  return npp &&
                    <Tooltip arrow title='New Patient Portal'>
                      <span>
                        <Link to={`/settings/new-patient-portal/${npp.newPatientPortalSettingsId}`}>
                          {npp.name}
                        </Link>
                      </span>
                    </Tooltip>;
                }],
                [T, (v) => <i>{v}</i>]
              ])(v);
              return (
                <div
                  key={`em-em-${idx}`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {pretty}
                  {idx + 1 < Client.source.length &&
                    <div>
                      ,
                    </div>
                  }
                </div>
              );
            })}
        </div>),
    ])],
    [has('Time'), ({ Time }) => ([
      'At ',
      <b key={`b-${Time.time}`} style={{ marginRight: '3px' }}>{format(parse(Time.time, 't'), 'h:mm a')}</b>,
      ' select all ',
      <i key={`i-${Time.time}`} style={{ marginRight: '6px' }}>{keys(Time.query)[0]}</i>,
    ])],
    [has('DateTime'), ({ DateTime }) => ([
      'On ',
      <b key={`b-${DateTime.datetime}`} style={{ marginRight: '3px' }}>
        {DateTime.datetime && tzParseFormat(DateTime.datetime, tz, 'MM/dd/yyyy h:mm a')}
      </b>,
      ' select all ',
      <i style={{ marginRight: '6px' }}>{keys(DateTime.query)[0]}</i>,
    ])],
    [has('FormSubmitted'), ({ FormSubmitted }) => ([
      'When form ',
      (
        <Tooltip arrow title='Form'>
          <span>
            <Link
              key={`target-list-${FormSubmitted.formId}`}
              to={`/campaign/${FormSubmitted.formId}`}>
              {FormSubmitted.name}
            </Link>
          </span>
        </Tooltip>
      ),
      'is submitted ',
    ])],
    [has('ApptTime'), ({ ApptTime }) => ([
      'When an appointment time ',
      <b key='trigger-appt-time' style={{ marginRight: '6px' }}>
        {ApptTime.beforeAfter === 'Before' ? 'is in ' : 'was '}
        {cond([
          [has('Minute'), ({ Minute }) => Minute + ' minute' + (Minute === 1 ? ' ' : 's ')],
          [has('Hour'), ({ Hour }) => Hour + ' hour' + (Hour === 1 ? ' ' : 's ')],
          [has('Day'), ({ Day }) => Day + ' day' + (Day === 1 ? ' ' : 's ')],
          [has('Week'), ({ Week }) => Week + ' week' + (Week === 1 ? ' ' : 's ')],
        ])(ApptTime.time)}
      </b>,
      ApptTime.beforeAfter === 'After' && 'ago '
    ])],
    [T, always([])]
  ])(automation.trigger);
  const actions = isEmpty(automation.actions) ? [{} as GetAction] : automation.actions;
  const actionWordsList: (string | JSX.Element)[][] = actions
    .map((a: GetAction, i: number): (string | JSX.Element)[] => {
      return cond([
        [has('Dummy'), () => ([
          'do something else',
        ])],
        [has('SendMessage'), ({ SendMessage }) => {
          const path = SendMessage?.msgId?.Path;
          return ([
            <b key={`prefix-send-${path || SendMessage}-${i}`}>send</b>,
            (path ? <span style={{ marginLeft: 3 }}>message at</span> : ''),
            isEmpty(SendMessage) ?
              <b className={classes.variable} key='blank-message-action'>[message]</b>
              :
              <Tooltip key={`t-target-message-${SendMessage}`} arrow title='Message'>
                {path ?
                  <b key={`bold-path-${i}`}>{path.join('.')}</b>
                  :
                  <span>
                    <a key={`target-message-${SendMessage?.msgId?.Id?.msgId}`}
                      onClick={() => openMessage(SendMessage, i)}>
                      {SendMessage?.msgId?.Id?.name}
                    </a>
                  </span>}
              </Tooltip>,
          ]);
        }],
        [has('MakeForm'), ({ MakeForm }) => ([
          <b style={{ marginRight: '3px' }} key={`prefix-send-${MakeForm}`}>send</b>,
          'form',
          isEmpty(MakeForm) ?
            <b className={classes.variable} key='blank-message-action'>[form]</b>
            :
            <Tooltip key={`t-target-message-${MakeForm}`} arrow title='Form'>
              <span>
                <Link
                  key={`target-list-${MakeForm.formId}`}
                  to={`/forms-edit/${MakeForm.formId}`}>
                  {MakeForm.name}
                </Link>
              </span>
            </Tooltip>,
        ])],
        [has('MakeForm'), ({ MakeForm }) => ([
          <b style={{ marginRight: '3px' }} key={`prefix-send-${MakeForm}`}>send</b>,
          'form',
          isEmpty(MakeForm) ?
            <b className={classes.variable} key='blank-message-action'>[form]</b>
            :
            <Tooltip key={`t-target-message-${MakeForm}`} arrow title='Form'>
              <span>
                <Link
                  key={`target-list-${MakeForm.formId}`}
                  to={`/forms-edit/${MakeForm.formId}`}>
                  {MakeForm.name}
                </Link>
              </span>
            </Tooltip>,
        ])],
        [has('ClientList'), ({ ClientList }) => {
          let cl;
          let key;
          if (ClientList.listId.Path) {
            key = ClientList.listId.Path.join('.');
            cl = <span>&nbsp;a client list at <b>{key} </b>&nbsp;</span>;
          } else if (ClientList.listId.Id) {
            key = ClientList.listId.Id.listId;
            cl = <Tooltip arrow title='Campaign'>
              <span>
                <Link
                  key={`target-list-${key}`}
                  to={`/campaign/${key}`}>
                  {ClientList.listId.Id.listName}
                </Link>
              </span>
            </Tooltip>;
          }

          return ([
            <b key={`prefix-change-${key}`}>{clientListAction(ClientList.change)}</b>,
            ClientList.listId ? cl
              :
              <b className={classes.variable} key='blank-campaign-action'>[campaign]</b>,
          ]);
        }],
        [has('AppointmentList'), ({ AppointmentList }) => {
          let al;
          if (AppointmentList.listId.Path) {
            al = <span>&nbsp;a appointment list at <b>{AppointmentList.listId.Path.join('.')} </b>&nbsp;</span>;
          } else if (AppointmentList.listId.Id) {
            al = <Tooltip arrow title='Appointment List'>
              <span>
                <Link
                  key={`target-appt-list-${AppointmentList.listId.Id}`}
                  to={`/appointment-list/${AppointmentList.listId.Id}`}>
                  {AppointmentList.listId.Id?.listName}
                </Link>
              </span>
            </Tooltip>;
          }
          console.log(AppointmentList);
          return ([
            <b key={`prefix-change-apt-list-${AppointmentList.listId.Id}`}>{apptListAction(AppointmentList.change)}</b>,
            al ? al
              :
              <b className={classes.variable} key='blank-campaign-action'>[appt list]</b>,
          ]);
        }],
        [has('UpdateAppt'), ({ UpdateAppt }) => {
          const prefix = UpdateAppt.status ? 'update status of ' : 'mark ';
          const connector = UpdateAppt.status ? 'to ' : 'as ';
          const next = cond([
            [equals('Trigger'), always('this appointment')],
            [equals('Next'), always('next appointment')],
            [equals('Today'), always('today\'s appointment')],
          ])(UpdateAppt.scope);
          const act = UpdateAppt.status ? UpdateAppt.status : 'confirmed';
          return [
            <b key={`prefex-change-${prefix}`}>{prefix}</b>,
            <i key={`target-list-${next}`}>{next}</i>,
            connector,
            <b key={`action-list-${act}`}>{act}</b>,
          ];
        }],
        [T, always(['do nothing.'])]
      ])(a);
    });
  const convertApptFilterToWord = (gf: GetFilter) => {
    const v = has('Appointment', gf.predicate) && gf.predicate.Appointment;
    const Has = gf.isTrue ? 'has' : 'does not have';
    const is = gf.isTrue ? 'is' : 'is not';
    const was = gf.isTrue ? 'was' : 'was not';
    if (isEmpty(v)) {
      return [];
    }
    if (has('Status', v)) {
      const status = isNil(v.Status) || isEmpty(v.Status) ? [] : [
        `${Has} status of `,
        v.Status.map((s: string, idx: number) => (
          <div
            key={`status-${idx}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '3px',
              marginRight: idx + 1 === v.Status.length ? '3px' : 'unset',
            }}
          >
            <i>{s}</i>
            {idx + 1 < v.Status.length &&
              <div>
                ,
              </div>
            }
          </div>
        )),
      ];
      return status;
    } else if (has('AppointmentTypeIds', v)) {
      const ts =
        has('AppointmentTypeIds', v) &&
          (!isNil(v.AppointmentTypeIds) || !isEmpty(v.AppointmentTypeIds)) ?
          [
            `${is} of type `,
            (v.AppointmentTypeIds as number[]).map((typeId, idx) => {
              const type = types.find(({ id }) => typeId === id);
              if (type) {
                return (
                  <div
                    key={`apt-type-em-em-${idx}`}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <i>{type.internalName}</i>
                    {idx + 1 < (v.AppointmentTypeIds as number[]).length &&
                      <div>
                        ,
                      </div>
                    }
                  </div>
                );
              }
              return '';
            })
          ] : [];
      return ts;
    } else if (has('AppointmentType', v)) {
      const apt = v.AppointmentType as unknown as PathOrId;
      const path = (apt.PathExact || apt.PathRelative || []).join('.');
      return [
        `appointment type ${is} in  `,
        <b key='s-filter-appttype-path' style={{ marginRight: 3 }}>
          {path}
        </b>,
      ];
    } else if (has('Time', v)) {
      const convertTime = (value: DateTimeFilter) => cond([
        [has('EqualTo'), (e: DateTimeFilter) =>
          cond([
            [has('Date'), ({ Date }) => ([
              `date ${is} `,
              <i>{format(parse(Date, 'd'), 'MM/dd/yyyy')}</i>,
            ])],
            [has('Ago'), ({ Ago }) => ([
              `${was} `,
              <i>{`${timeUnitToString(Ago)} ago`}</i>,
            ])],
            [has('InFuture'), ({ InFuture }) => ([
              `${is} in `,
              <i>{`${timeUnitToString(InFuture)}`}</i>,
            ])]
          ])(e.EqualTo)],
        [has('Between'), ({ Between }) => {
          const dir1 = keys(Between.start)[0];
          const pretty1 = dirCond('from', dir1 as string);
          const dir2 = keys(Between.end)[0];
          const pretty2 = dirCond('from', dir2 as string);
          return [
            `${is} between `,
            <i>{timeUnitToString(Between.start[dir1])} {pretty1} and {timeUnitToString(Between.end[dir2])} {pretty2}</i>,
          ];
        }],
        [has('GreaterThan'), ({ GreaterThan }) => {
          const dir = keys(GreaterThan)[0];
          const pretty = dirCond('from', dir as string);
          return ([
            `${is} greater than `,
            <i>{`${timeUnitToString(GreaterThan[dir])} ${pretty}`}</i>,
          ]);
        }],
        [has('GreaterThanEqual'), ({ GreaterThanEqual }) => {
          const dir = keys(GreaterThanEqual)[0];
          const pretty = dirCond('from', dir as string);
          return ([
            `${is} greater than or equal to `,
            <i>{`${timeUnitToString(GreaterThanEqual[dir])} ${pretty}`}</i>,
          ]);
        }],
        [has('LessThan'), ({ LessThan }) => {
          const dir = keys(LessThan)[0];
          const pretty = dirCond('from', dir as string);
          return ([
            `${was} less than `,
            <i>{`${timeUnitToString(LessThan[dir])} ${pretty}`}</i>,
          ]);
        }],
        [has('LessThanEqual'), ({ LessThanEqual }) => {
          const dir = keys(LessThanEqual)[0];
          const pretty = dirCond('from', dir as string);
          return ([
            `${was} less than or equal to `,
            <i>{`${timeUnitToString(LessThanEqual[dir])} ${pretty}`}</i>,
          ]);
        }]
      ])(value);
      const time =
        has('Time', v) &&
          (!isNil(v.Time) || !isEmpty(v.Time)) ?
          convertTime(v.Time)
          : [];
      return time;
    } else if (has('ClientList', v)) {
      const cl = v.ClientList;
      let clist;
      if (typeof cl.listId === 'object' ) {
        if (cl.listId.PathExact) {
          clist = <span>&nbsp;a client at <b>{cl.listId.PathExact.join('.')} </b>&nbsp;</span>;
        } else if (cl.listId.PathRelative) {
          clist = <span>&nbsp;any client list
        inside <b>{cl.listId.PathRelative.join('.')} </b>&nbsp;</span>;
        } else if (cl.listId.Id && type(cl.listId.Id) === 'Object') {
          clist = <Tooltip arrow title='Campaign'>
            <span>
              <Link
                key={`target-list-${(cl.listId.Id as List).listId}`}
                to={`/campaign/${(cl.listId.Id as List).listId}`}>
                {(cl.listId.Id as List).listName}
              </Link>
            </span>
          </Tooltip>;
        }
      } else {
        clist = <Tooltip arrow title='Campaign'>
          <span>
            <Link
              key={`target-list-${cl.listId}`}
              to={`/campaign/${cl.listId}`}>
              {cl.listId}
            </Link>
          </span>
        </Tooltip>;
      }
      const ts = has('appointmentTypes', cl) &&
        (!isNil(cl.appointmentTypes) || !isEmpty(cl.appointmentTypes)) ?
        (cl.appointmentTypes as number[]).map((typeId, idx) => {
          const type = types.find(({ id }) => typeId === id);
          if (type) {
            return (
              <div
                key={`apt-type-em-em-${idx}`}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <i>{type.internalName}</i>
                {idx + 1 < (cl.appointmentTypes as number[]).length &&
                  <div>
                    ,
                  </div>
                }
              </div>
            );
          }
          return '';
        }) : '';
      const postfix = cond([
        [equals(1), () => 'st'],
        [equals(2), () => 'nd'],
        [equals(3), () => 'rd'],
        [T, () => 'th'],
      ])(cl.numberOfAppointments);
      const campaign =
        cl.listId ?
          clist
          :
          <b className={classes.variable} key='blank-campaign-action'>[campaign]</b>;
      return [
        cl.repeat ? `${is} repeatedly the ` : `${is} the `,
        String(cl.numberOfAppointments),
        postfix,
        ' appointment of type ',
        ts,
        cl.repeat && 'offset by ',
        cl.repeat && String(cl.offset),
        ' appointments in campaign',
        campaign,
      ];
    }
    //       <b style={{marginRight: '3px'}}>appointment</b>,
    return [];
  };
  const convertClientFilterToWord = (gf: GetFilter) => {
    const v: GetFilterClientCombined = has('Client', gf.predicate) && gf.predicate.Client;
    const Has = gf.isTrue ? 'has' : 'does not have';
    const is = gf.isTrue ? 'is' : 'is not';
    const was = gf.isTrue ? 'was' : 'was not';
    const will = gf.isTrue ? 'will' : 'won\'t';
    if (isEmpty(v)) {
      return [];
    }
    if (has('Status', v)) {
      return [
        `${Has} status `,
        <i>{typeof v.Status === 'string' ? v.Status : v.Status.status}</i>,
      ];
    } else if (has('ClientList', v)) {
      const cl = v.ClientList;
      let clist;
      if (typeof cl.listId === 'object' ) {
        if (cl.listId.PathExact) {
          clist = <span>&nbsp;a client list at <b>{cl.listId.PathExact.join('.')} </b>&nbsp;</span>;
        } else if (cl.listId.PathRelative) {
          clist = <span>&nbsp;any client list
        inside <b>{cl.listId.PathRelative.join('.')} </b>&nbsp;</span>;
        } else if (cl.listId.Id && typeof cl.listId.Id === 'object') {
          clist = <Tooltip arrow title='Campaign'>
            <span>
              <Link
                key={`target-list-${(cl.listId.Id as List).listId}`}
                to={`/campaign/${(cl.listId.Id as List).listId}`}>
                {(cl.listId.Id as List).listName}
              </Link>
            </span>
          </Tooltip>;
        }
      } else {
        clist = <Tooltip arrow title='Campaign'>
          <span>
            <Link
              key={`target-list-${cl.listId}`}
              to={`/campaign/${cl.listId}`}>
              {cl.listId}
            </Link>
          </span>
        </Tooltip>;
      }
      const campaign =
        cl.listId ?
          clist
          :
          <b className={classes.variable} key='blank-campaign-action'>[campaign]</b>;
      if (pathOr(false, ['ClientList', 'added'], v)) {
        return cond([
          [has('EqualTo'), (e: DateTimeFilter) =>
            cond([
              [has('Date'), ({ Date }) => ([
                `${was} added to `,
                campaign,
                ' on ',
                <i key='c-filter-ondate-i'>{format(parse(Date, 'd'), 'MM/dd/yyyy')}</i>,
              ])],
              [has('Ago'), ({ Ago }) => ([
                `${was} added to `,
                campaign,
                <i key='c-filter-daysago-i'>{`${timeUnitToString(Ago)} ago`}</i>,
              ])],
              [has('InFuture'), ({ InFuture }) => ([
                `${will} be added to `,
                campaign,
                <i key='c-filter-daysinfutureg-i'>{`${timeUnitToString(InFuture)}`}</i>,
              ])]
            ])(e.EqualTo)],
          [has('Between'), ({ Between }) => {
            const dir1 = keys(Between.start)[0];
            const pretty1 = dirCond('from', dir1 as string);
            const dir2 = keys(Between.end)[0];
            const pretty2 = dirCond('from', dir2 as string);
            return [
              `${was} added to `,
              campaign,
              'between ',
              <i key='c-filter-betweendates-i'>{timeUnitToString(Between.start[dir1])} {pretty1} and {timeUnitToString(Between.end[dir2])} {pretty2}</i>,
            ];
          }],
          [has('GreaterThan'), ({ GreaterThan }) => {
            const dir = keys(GreaterThan)[0];
            const pretty = dirCond('from', dir as string);
            return ([
              `${was} added to `,
              campaign,
              <i>{`greater than ${timeUnitToString(GreaterThan[dir])} ${pretty}`}</i>,
            ]);
          }],
          [has('GreaterThanEqual'), ({ GreaterThanEqual }) => {
            const dir = keys(GreaterThanEqual)[0];
            const pretty = dirCond('from', dir as string);
            return ([
              `${was} added to `,
              campaign,
              <i>{`greater than or equal to ${timeUnitToString(GreaterThanEqual[dir])} ${pretty}`}</i>,
            ]);
          }],
          [has('LessThan'), ({ LessThan }) => {
            const dir = keys(LessThan)[0];
            const pretty = dirCond('from', dir as string);
            return ([
              `${was} added to `,
              campaign,
              <i>{`less than ${timeUnitToString(LessThan[dir])} ${pretty}`}</i>,
            ]);
          }],
          [has('LessThanEqual'), ({ LessThanEqual }) => {
            const dir = keys(LessThanEqual)[0];
            const pretty = dirCond('from', dir as string);
            return ([
              `${was} added to `,
              campaign,
              <i>{`less than or equal to ${timeUnitToString(LessThanEqual[dir])} ${pretty}`}</i>,
            ]);
          }]
        ])(pathOr({}, ['ClientList', 'added'], v));
      }
      return [
        `${is} in`,
        campaign
      ];
    } else if (has('SentMsg', v)) {
      let sentMsg: JSX.Element | (JSX.Element | string)[] =
        <b className={classes.variable} key='blank-filter-action'>[message]</b>;
      if (v.SentMsg.msg.Id) {
        const id = v.SentMsg.msg.Id;
        sentMsg = (
          <Tooltip key={`t-target-msg-${id}`} arrow title='Message'>
            <span>
              <Link
                key={`target-msg-${id}`}
                to={`/automation/view/${id}`}>
                {(v.SentMsg as Message).name}
              </Link>
            </span>
          </Tooltip>
        );
      } else if (v.SentMsg.msg.PathExact || v.SentMsg.msg.PathRelative) {
        const inbelow = v.SentMsg.msg.PathExact ? 'in' : 'below';
        const path = v.SentMsg.msg.PathExact || v.SentMsg.msg.PathRelative;
        sentMsg = [
          ` any message ${inbelow} `,
          <b key='s-filter-appttype-path' style={{ marginRight: 3 }}>
            {path.join('.')}
          </b>,
        ];
      }
      if (pathOr(false, ['SentMsg', 'sentAt'], v)) {
        return cond([
          [equals('EqualTo'), (e: DateTimeFilter) =>
            cond([
              [has('Date'), ({ Date }) => ([
                `${was} sent `,
                sentMsg,
                ' on ',
                <i key='s-filter-ondate-i'>{format(parse(Date, 'd'), 'MM/dd/yyyy')}</i>,
              ])],
              [has('Ago'), ({ Ago }) => ([
                `${was} sent `,
                sentMsg,
                <i key='s-filter-daysago-i'>{`${timeUnitToString(Ago)} ago`}</i>,
              ])],
              [has('InFuture'), ({ InFuture }) => ([
                `${will} be sent `,
                sentMsg,
                <i key='s-filter-daysinfuture-i'>{`in ${timeUnitToString(InFuture)}`}</i>,
              ])]
            ])(e.EqualTo)],
          [has('Between'), ({ Between }) => {
            const dir1 = keys(Between.start)[0];
            const pretty1 = dirCond('from', dir1 as string);
            const dir2 = keys(Between.end)[0];
            const pretty2 = dirCond('from', dir2 as string);
            return ([
              `${was} sent `,
              sentMsg,
              'between ',
              <i key='s-filter-betweendates-i'>{timeUnitToString(Between.start[dir1])} {pretty1} and {timeUnitToString(Between.end[dir2])} {pretty2}</i>,
            ]);
          }],
          [has('GreaterThan'), ({ GreaterThan }) => {
            const dir = keys(GreaterThan)[0];
            const pretty = dirCond('from', dir as string);
            return ([
              `${will} be sent `,
              sentMsg,
              <i>{`greater than ${timeUnitToString(GreaterThan[dir])} ${pretty}`}</i>,
            ]);
          }],
          [has('GreaterThanEqual'), ({ GreaterThanEqual }) => {
            const dir = keys(GreaterThanEqual)[0];
            const pretty = dirCond('from', dir as string);
            return ([
              `${will} be sent `,
              sentMsg,
              <i>{`greater than or equal to ${timeUnitToString(GreaterThanEqual[dir])} ${pretty}`}</i>,
            ]);
          }],
          [has('LessThan'), ({ LessThan }) => {
            const dir = keys(LessThan)[0];
            const pretty = dirCond('from', dir as string);
            return ([
              `${was} sent to `,
              sentMsg,
              <i>{`less than ${timeUnitToString(LessThan[dir])} ${pretty}`}</i>,
            ]);
          }],
          [has('LessThanEqual'), ({ LessThanEqual }) => {
            const dir = keys(LessThanEqual)[0];
            const pretty = dirCond('from', dir as string);
            return ([
              `${was} sent to `,
              sentMsg,
              <i>{`less than or equal to ${timeUnitToString(LessThanEqual[dir])} ${pretty}`}</i>,
            ]);
          }]
        ])(pathOr({}, ['sentMsg', 'sentAt'], v));
      }
      return [
        `${was} sent`,
        sentMsg,
      ];
    } else if (has('HasAppointment', v)) {
      const hasAppt = v.HasAppointment as FilterHasAppointment;
      const apt = hasAppt.appointmentTypes as PathOrId;
      const ts =
        has('appointmentTypes', hasAppt) &&
        !isNil(apt?.Id) && !isEmpty(apt?.Id) &&
        [
          'of type ',
          (apt?.Id as number[] || []).map((typeId, idx) => {
            const type = types.find(({ id }) => typeId === id);
            if (type) {
              return (
                <div
                  key={`apt-type-em-em-${idx}`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <i>{type.internalName}</i>
                  {idx + 1 < (apt.Id as number[] || []).length &&
                    <div>
                      ,
                    </div>
                  }
                </div>
              );
            }
            return '';
          })
        ];
      const status = !isNil(hasAppt.status) && !isEmpty(hasAppt.status) && [
        'that has status of ',
        hasAppt.status.map((s: string, idx: number) => (
          <div
            key={`status-${idx}`}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '3px',
              marginRight: idx + 1 === hasAppt.status.length ? '3px' : 'unset',
            }}
          >
            <i>{s}</i>
            {idx + 1 < hasAppt.status.length &&
              <div>
                ,
              </div>
            }
          </div>
        )),
      ];
      const time = pathOr(false, ['HasAppointment', 'time'], v) && cond([
        [has('EqualTo'), (e: DateTimeFilter) =>
          cond([
            [has('Date'), ({ Date }) => ([
              ' on ',
              <i key='s-filter-ondate-i'>{format(parse(Date, 'd'), 'MM/dd/yyyy')}</i>,
            ])],
            [has('Ago'), ({ Ago }) => ([
              <i key='s-filter-daysago-i'>{`${timeUnitToString(Ago)} ago`}</i>,
            ])],
            [has('InFuture'), ({ InFuture }) => ([
              <i key='s-filter-daysinfuture-i'>{`${timeUnitToString(InFuture)}`}</i>,
            ])]
          ])(e.EqualTo)],
        [has('Between'), ({ Between }) => {
          const dir1 = keys(Between.start)[0];
          const pretty1 = dirCond('from', dir1 as string);
          const dir2 = keys(Between.end)[0];
          const pretty2 = dirCond('from', dir2 as string);
          return ([
            'between ',
            <i key='s-filter-betweendates-i'>{timeUnitToString(Between.start[dir1])} {pretty1} and {timeUnitToString(Between.end[dir2])} {pretty2}</i>,
          ]);
        }],
        [has('GreaterThan'), ({ GreaterThan }) => {
          const dir = keys(GreaterThan)[0];
          const pretty = dirCond('from', dir as string);
          return ([
            <i>{`greater than ${timeUnitToString(GreaterThan[dir])} ${pretty}`}</i>,
          ]);
        }],
        [has('GreaterThanEqual'), ({ GreaterThanEqual }) => {
          const dir = keys(GreaterThanEqual)[0];
          const pretty = dirCond('from', dir as string);
          return ([
            <i>{`greater than or equal to ${timeUnitToString(GreaterThanEqual[dir])} ${pretty}`}</i>,
          ]);
        }],
        [has('LessThan'), ({ LessThan }) => {
          const dir = keys(LessThan)[0];
          const pretty = dirCond('from', dir as string);
          return ([
            <i>{`less than ${timeUnitToString(LessThan[dir])} ${pretty}`}</i>,
          ]);
        }],
        [has('LessThanEqual'), ({ LessThanEqual }) => {
          const dir = keys(LessThanEqual)[0];
          const pretty = dirCond('from', dir as string);
          return ([
            <i>{`less than or equal to ${timeUnitToString(LessThanEqual[dir])} ${pretty}`}</i>,
          ]);
        }]
      ])(pathOr({}, ['HasAppointment', 'time'], v));
      return prepend(' has appointment ', [
        ts,
        status,
        time ? time : '',
      ].filter((a) => a).flatMap((a) => ['and ', a]).slice(1));
    } else if (has('LastAppointment', v)) {
      const lastAppt = v.LastAppointment;
      const ts =
        has('appointmentTypes', lastAppt) &&
        !isEmpty(lastAppt.appointmentTypes) &&
        [
          'appointment type is in  ',
          <b key='s-filter-appttype-path' style={{ marginRight: 3 }}>
            {(lastAppt.appointmentTypes?.PathExact ||
              lastAppt.appointmentTypes?.PathRelative || []).join('.')}
          </b>,
        ];
      const time = pathOr(false, ['LastAppointment', 'time'], v) && cond([
        [has('EqualTo'), (e: DateTimeFilter) =>
          cond([
            [has('Date'), ({ Date }) => ([
              ' on ',
              <i key='s-filter-ondate-i'>{format(parse(Date, 'd'), 'MM/dd/yyyy')}</i>,
            ])],
            [has('Ago'), ({ Ago }) => ([
              <i key='s-filter-daysago-i'>{`${timeUnitToString(Ago)} ago`}</i>,
            ])],
            [has('InFuture'), ({ InFuture }) => ([
              <i key='s-filter-daysinfuture-i'>{`${timeUnitToString(InFuture)}`}</i>,
            ])]
          ])(e.EqualTo)],
        [has('Between'), ({ Between }) => {
          const dir1 = keys(Between.start)[0];
          const pretty1 = dirCond('from', dir1 as string);
          const dir2 = keys(Between.end)[0];
          const pretty2 = dirCond('from', dir2 as string);
          return ([
            'between ',
            <i key='s-filter-betweendates-i'>{timeUnitToString(Between.start[dir1])} {pretty1} and {timeUnitToString(Between.end[dir2])} {pretty2}</i>,
          ]);
        }],
        [has('GreaterThan'), ({ GreaterThan }) => {
          const dir = keys(GreaterThan)[0];
          const pretty = dirCond('from', dir as string);
          return ([
            <i>{`greater than ${timeUnitToString(GreaterThan[dir])} ${pretty}`}</i>,
          ]);
        }],
        [has('GreaterThanEqual'), ({ GreaterThanEqual }) => {
          const dir = keys(GreaterThanEqual)[0];
          const pretty = dirCond('from', dir as string);
          return ([
            <i>{`greater than or equal to ${timeUnitToString(GreaterThanEqual[dir])} ${pretty}`}</i>,
          ]);
        }],
        [has('LessThan'), ({ LessThan }) => {
          const dir = keys(LessThan)[0];
          const pretty = dirCond('from', dir as string);
          return ([
            <i>{`less than ${timeUnitToString(LessThan[dir])} ${pretty}`}</i>,
          ]);
        }],
        [has('LessThanEqual'), ({ LessThanEqual }) => {
          const dir = keys(LessThanEqual)[0];
          const pretty = dirCond('from', dir as string);
          return ([
            <i>{`less than or equal ${timeUnitToString(LessThanEqual[dir])} ${pretty}`}</i>,
          ]);
        }]
      ])(pathOr({}, ['LastAppointment', 'time'], v));
      return prepend(` last appointment ${is} `, [
        ts,
        time ? time : '',
      ].filter((a) => a).flatMap((a) => ['and ', a]).slice(1));
    }
    return [];
  };
  const filties: Filties = groupBy((filt) => {
    if (has('Appointment', filt.predicate)) {
      return 'Appointment';
    }
    return 'Client';
  }, automation.filters || []);
  const apptFilties = filties.Appointment?.map((a) => has('Appointment', a.predicate) &&
    a);
  const listableAppt = apptFilties?.map(convertApptFilterToWord).flatMap((a) => ['and ', a]).slice(1);
  const apptFilterWords =
    listableAppt ?
      flatten(prepend(['if', <b style={{ marginRight: '3px' }}>appointment</b>,], listableAppt))
      :
      [];

  const clientFilties = filties.Client?.map((a) =>
    has('Client', a.predicate) && a);
  const listableClient = clientFilties?.map(convertClientFilterToWord).flatMap((a) => ['and ', a]).slice(1);
  const clientFilterWords =
    listableClient ?
      flatten(prepend(['if', <b style={{ marginRight: '3px' }}>client</b>,], listableClient))
      :
      [];
  const allFilterWords = (isEmpty(apptFilterWords) ? [] : prepend('and ', apptFilterWords))
    .concat(isEmpty(clientFilterWords) ? [] : prepend('and ', clientFilterWords));
  const filterWords = flatten(allFilterWords);
  const delayAndTransition = automation.delay ? ` wait ${automation.delay} minutes then ` : ' then ';
  const splitActions = splitEvery(1, actionWordsList);
  const actionWords = flatten(splitActions.flatMap((a) => [', ', a]).slice(1));
  const delayAndFilters = append(delayAndTransition, filterWords);
  return triggerWords.concat(delayAndFilters).concat(actionWords);
};

const errorCheck = (state: AutomationsState) => {
  const mainErrors = pipe(
    flatten,
    any(isEmpty)
  )([
    state.trigger,
    map(prop('filter'), state.filters),
    map(prop('action'), state.actions)
  ]);
  const exhaustiveCheck = (
    render: TriggerRenderTypes | FilterRenderTypes | ActionRenderTypes,
    data: TriggerData | FilterData | ActionData,
    index = 0
  ) => pipe(
    keys,
    map((key: string) => {
      const rd: any = render[key as keyof (TriggerRenderTypes | FilterRenderTypes | ActionRenderTypes)];
      if (rd) {
        let errorResult = false;
        if (has('hasError', rd) && rd.hasError && typeof rd.hasError === 'function') {
          errorResult = rd.hasError({
            data: state.triggerData,
            state,
            index,
          });
        }
        if (has('showIf', rd) && rd.showIf && typeof rd.showIf === 'function') {
          return rd.showIf({ data, state }) && errorResult;
        }
        return errorResult;
      }
      return false;
    }),
    any(identity)
  )(data);
  const triggerErrors = exhaustiveCheck(
    triggerRenderData[state.trigger as keyof TriggerRenderData],
    state.triggerData
  );
  const filterCheck = state.filters.map((filt: Filter, index: number): boolean => {
    return exhaustiveCheck(filterRenderData[filt.filter as keyof FilterRenderData], filt.filterData, index);
  });
  const filterErrors = any(identity, filterCheck);
  const actionsCheck = state.actions.map((act: Action, index: number): boolean => {
    return exhaustiveCheck(actionRenderData[act.action as keyof ActionRenderData], act.actionData, index);
  });
  const actionsErrors = any(identity, actionsCheck);
  return mainErrors || triggerErrors || filterErrors || actionsErrors;
};

interface IfSelectProps {
  bool: boolean;
  lupdate: (n: object) => void;
  state: AutomationsState;
  index: number;
}

const IfSelect = ({ bool, lupdate, state, index }: IfSelectProps) => {
  const value: string = useMemo(() => {
    return bool ? 'if' : 'if not';
  }, [bool]);
  return (
    <Select
      value={value}
      variant='standard'
      displayEmpty
      required
      style={{ marginLeft: 10 }}
      onChange={(e) => {
        const strValue = e.target.value;
        const value = strValue === 'if';
        lupdate({
          filters: adjust(index, assoc('isTrue', value), state.filters)
        });
      }}
    >
      {['if', 'if not'].map((v) => (
        <MenuItem key={v} value={v}>
          <ListItemText primary={v} />
        </MenuItem>
      ))}
    </Select>
  );
};

interface AutomationItemProps {
  automation: Automation;
  removeAutomation?: () => void;
  updateAutomation: (auto: Automation) => void;
  openMessage?: (msg: SendMsgGetAction, idx: number) => void,
  types?: any[],
  professionals?: any[],
  npps?: any[],
  defaultCampaign?: DefaultList,
  externalSave?: (auto: Automation) => void,
  cantSave?: (state: AutomationsState) => string,
  noSave?: boolean;
  disabledActions?: boolean;
  disabledActionsMessage?: string;
  forceSave?: boolean;
  justSentMsgId?: number,
  isTemplate?: boolean;
  onCancel?: () => void;
  isAdmin?: boolean;
  externalEnabled?: boolean;
  saveIsUpdate?: boolean; /* This will make the onSave function just run `updateAutomation` */
  hideCancel?: boolean;
  isHidden?: boolean;
}

export const AutomationItem = ({
  automation,
  removeAutomation,
  updateAutomation,
  openMessage,
  types,
  professionals,
  npps,
  defaultCampaign = null,
  externalSave,
  cantSave,
  noSave = false,
  disabledActions = false,
  disabledActionsMessage,
  forceSave = false,
  justSentMsgId,
  isTemplate,
  onCancel = null,
  externalEnabled = undefined,
  saveIsUpdate = false,
  hideCancel = false,
  isAdmin,
  isHidden = false,
}: AutomationItemProps) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(false);
  const [edit, setEdit] = useState(automation.isEdit);
  const [state, setState] = useState<AutomationsState>({
    trigger: '',
    triggerData: triggerData[''],
    deletedActions: [],
    enabled: automation.enabled,
    filter: '',
    filters: [{
      isTrue: true,
      filter: '',
      filterData: {
        type: 'Nothing',
        text: '',
      },
    }],
    actions: [{
      action: '',
      actionData: {
        type: 'Nothing',
        text: '',
      },
    }],
    delay: null,
    hidden: isHidden,
    isTemplate,
    comment: '',
  });
  const update = (data: any) => {
    setState({
      ...state,
      ...data,
    });
  };
  useEffect(() => {
    const trig = triggerTo(defaultCampaign, automation.trigger);
    const acts = isEmpty(automation.actions) ?
      [{ action: '', actionData: { type: 'Nothing', text: '' } }]
      :
      map(actionTo(defaultCampaign), automation.actions);
    const filts = automation.filters ? automation
      .filters
      .map((filt): Filter => filterTo(defaultCampaign, filt))
      .filter(({ filterData }) => filterData) : [];
    update({
      ...trig,
      actions: acts,
      enabled: automation.enabled,
      filters: filts,
      delay: automation.delay,
      hidden: automation.hidden,
      comment: automation.comment,
    });
  }, [automation, edit]);

  const automationSaveState = usePromise(rawActions.saveAutomation, {});
  const toggleState = usePromise(rawActions.toggleAutomation, {});
  const deleteAutomationState = usePromise(rawActions.deleteAutomation, {});

  const {
    id,
  } = automation;

  const { tz } = useSelector((state) => ({
    tz: state.login.office.timezone,
  }));

  const words = useMemo(() => {
    return createWords({
      automation, types, npps, openMessage, classes, tz,
    });
  }, [automation, types, npps]);

  useEffect(() => {
    /*
       Normally you could use the `edit` state to determine this but when
       creating an automation message the `isEdit` flag gets messed up.
     */
    if (externalEnabled === undefined)
      return;
    if (state.trigger === '') {
      const trig = triggerTo(defaultCampaign, automation.trigger);
      const acts = isEmpty(automation.actions) ?
        [{ action: '', actionData: { type: 'Nothing', text: '' } }]
        :
        map(actionTo(defaultCampaign), automation.actions);
      const filts = automation.filters ? automation
        .filters
        .map((filt): Filter => filterTo(defaultCampaign, filt))
        .filter(({ filterData }) => filterData) : [];
      update({
        ...trig,
        actions: acts,
        filters: filts,
        delay: automation.delay,
        hidden: automation.hidden,
        enabled: externalEnabled,
        comment: automation.comment,
      });
    } else {
      update({ enabled: externalEnabled });
    }
  }, [externalEnabled]);

  const onSave = () => {
    const {
      trigger,
      triggerData,
      actions,
      deletedActions,
      enabled,
      filters,
      delay,
      hidden,
      comment,
    } = state;

    let hasError = false;

    forEachObjIndexed((value, key) => {
      const { isError } = pathOr({ isError: null }, [trigger, key], triggerRenderData);
      if (isError) {
        const err = isError({ state, value });
        if (err) {
          popup(err.title, err.msg);
          hasError = true;
        }
      }
    })(triggerData);

    if (hasError) {
      return;
    }
    const dummy: IndividualDummy = { Dummy: [] };
    const trig = cond<any[], GetTrigger>([
      [equals<string>(''), (): IndividualDummy => dummy],
      [equals<string>('Campaign'), (): IndividualClientListGetAction | IndividualDummy => {
        if (has('change', triggerData) && has('campaign', triggerData)) {
          const prop = cond([
            [equals(campaignChangeOptions[0]), always('Added')],
            [equals(campaignChangeOptions[1]), always('Removed')],
            [equals(campaignChangeOptions[2]), always('Unsubscribed')],
          ])(triggerData.change);
          const clists = pathOrIdList.map(p => p.replace('{{item}}', 'client list'));
          const listId = cond([
            [equals(clists[0]), (): PathOrId => ({ Id: triggerData.campaign?.listId as number })],
            [equals(clists[1]), (): PathOrId => ({ PathExact: triggerData.path.split('.') })],
            [equals(clists[2]), (): PathOrId => ({ PathRelative: triggerData.path.split('.') })],
            [T, (): PathOrId => ({ Id: triggerData.campaign?.listId as number })]
          ])(triggerData.isPath);
          return ({
            ClientList: {
              change: {
                [prop]: []
              },
              listId,
              listName: pathOr(undefined, ['campaign', 'listName'], actionData),
            },
          });
        }
        return dummy;
      }],
      [equals<string>('Client List'), (): IndividualClientListGetAction | IndividualDummy => {
        if (has('change', triggerData) && has('campaign', triggerData)) {
          const prop = cond([
            [equals(campaignChangeOptions[0]), always('Added')],
            [equals(campaignChangeOptions[1]), always('Removed')],
            [equals(campaignChangeOptions[2]), always('Unsubscribed')],
          ])(triggerData.change);
          const clists = pathOrIdList.map(p => p.replace('{{item}}', 'client list'));
          const listId = cond([
            [equals(clists[0]), (): PathOrId => ({ Id: triggerData.campaign?.listId as number })],
            [equals(clists[1]), (): PathOrId => ({ PathExact: triggerData.path.split('.') })],
            [equals(clists[2]), (): PathOrId => ({ PathRelative: triggerData.path.split('.') })],
            [T, (): PathOrId => ({ Id: triggerData.campaign?.listId as number })]
          ])(triggerData.isPath);
          return ({
            ClientList: {
              change: {
                [prop]: []
              },
              listId,
              listName: pathOr(undefined, ['campaign', 'listName'], actionData),
            },
          });
        }
        return dummy;
      }],
      [equals<string>('Appointment List'), (): IndividualApptListGetAction | IndividualDummy => {
        if (has('change', triggerData) && has('apptList', triggerData)) {
          const change = cond([
            [equals(apptListChangeOptions[0]), always('Added')],
            [equals(apptListChangeOptions[1]), always('Removed')],
          ])(triggerData.change);
          const alists = pathOrIdList.map(p => p.replace('{{item}}', 'appointment list'));
          const listId = cond([
            [equals(alists[0]), (): PathOrId => ({ Id: triggerData.apptList?.listId as number })],
            [equals(alists[1]), (): PathOrId => ({ PathExact: triggerData.path.split('.') })],
            [equals(alists[2]), (): PathOrId => ({ PathRelative: triggerData.path.split('.') })],
            [T, (): PathOrId => ({ Id: triggerData.apptList?.listId as number })]
          ])(triggerData.isPath);
          return ({
            AppointmentList: {
              change,
              listId,
              listName: pathOr(undefined, ['apptList', 'listName'], actionData),
            },
          });
        }
        return dummy;
      }],
      [equals('Message Received'), () => ({
        MessageReceived: has('text', triggerData) ? triggerData.text.map((v) => v.trim()) : [],
      })],
      [equals('Message Sent'), () => ({
        SentMsg: pipe(
          pathOr([], ['message', 'msgId']),
          ifElse(
            equals(0),
            always(undefined),
            identity
          ),
        )(triggerData),
      })],
      [equals('Appointment Time'), () => {
        if (has('time', triggerData) &&
          (has('timeUnit', triggerData) || has('timeUnit2', triggerData)) &&
          has('beforeAfter', triggerData)) {
          const beforeAfter = triggerData.beforeAfter === 'was' ? 'After' : 'Before';
          const upperUnit =
            beforeAfter === 'Before' ?
              triggerData.timeUnit.charAt(0).toUpperCase() + triggerData.timeUnit.slice(1)
              :
              triggerData.timeUnit2.charAt(0).toUpperCase() + triggerData.timeUnit2.slice(1);

          return ({
            ApptTime: {
              time: {
                [upperUnit]: Number(triggerData.time),
              },
              beforeAfter,
            },
          });
        }
        return dummy;
      }],
      [equals('Appointment'), (): IndividualAppointmentUpdateTrigger | IndividualDummy => {
        if (has('source', triggerData)) {
          const source =
            includes(sources[0], triggerData.source) ?
              [] :
              flatten(
                triggerData.source.map((s: keyof ChangedSource) => {
                  if (includes('New', s)) {
                    if (includes('Any', s)) {
                      return { AnyNpp: [] as [] };
                    } else {
                      return has('nppId', triggerData) ?
                        triggerData.nppId.map((id) => ({ Npp: id }))
                        :
                        { AnyNpp: [] as [] };
                    }
                  }
                  return cond<any[], ChangedSource>([
                    [equals<string>('Admin'), (): ChangedSourceAdmin => ({ Admin: [] as [] })],
                    [equals('Webmodule'), (): ChangedSourceWebmodule => ({ Webmodule: [] as [] })],
                    [equals('App'), (): ChangedSourceApp => ({ App: [] as [] })],
                    [equals('EHR'), (): ChangedSourceEHR => ({ EHR: [] as [] })],
                  ])(s);
                })
              );
          return ({
            ApptUpdated: {
              source,
              changeType: ifElse(
                includes('is updated'),
                always<string[]>([]),
                map(
                  cond([
                    [includes('added'), always('Added')],
                    [includes('rescheduled'), always('Rescheduled')],
                    [includes('canceled'), always('ChangedCanceled')],
                    [includes('arrived'), always('ChangedArrived')],
                    [includes('confirmed'), always('ChangedConfirmed')],
                  ])
                )
              )(triggerData.changeType),
            },
          });
        }
        return dummy;
      }],
      [equals('Date & Time'), () => {
        if (has('datetime', triggerData)) {
          return ({
            DateTime: {
              datetime: triggerData.datetime + 'Z',
              query: {
                [triggerData.query]: [],
              },
            }
          });
        }
        return dummy;
      }],
      [equals('Time'), () => {
        if (has('time', triggerData) && has('query', triggerData)) {
          return ({
            Time: {
              time: triggerData.time,
              query: {
                [triggerData.query]: [],
              },
            }
          });
        }
        return dummy;
      }],
      [equals('Form'), () => {
        if (has('form', triggerData)) {
          return ({
            FormSubmitted: triggerData.form.formId,
          });
        }
        return dummy;
      }],
      [equals('Client'), () => {
        if (has('changeType', triggerData) && has('source', triggerData)) {
          const source =
            includes(sources[0], triggerData.source) ?
              [] :
              flatten(
                triggerData.source.map((s: keyof ChangedSource) => {
                  if (includes('New', s)) {
                    if (includes('Any', s)) {
                      return { AnyNpp: [] as [] };
                    } else {
                      return has('nppId', triggerData) ?
                        triggerData.nppId.map((id) => ({ Npp: id }))
                        :
                        { AnyNpp: [] as [] };
                    }
                  }
                  return cond<any[], ChangedSource>([
                    [equals<string>('Admin'), (): ChangedSourceAdmin => ({ Admin: [] as [] })],
                    [equals('Webmodule'), (): ChangedSourceWebmodule => ({ Webmodule: [] as [] })],
                    [equals('App'), (): ChangedSourceApp => ({ App: [] as [] })],
                    [equals('EHR'), (): ChangedSourceEHR => ({ EHR: [] as [] })],
                  ])(s);
                })
              );
          return ({
            Client: {
              changeType: triggerData.changeType.map(
                cond([
                  [includes('added'), always('Added')],
                  [includes('inactive'), always('Inactive')],
                  [includes('inert'), always('Inert')],
                ])) as string[],
              source,
            }
          });
        }
        return dummy;
      }],
    ])(trigger);
    const clists = pathOrIdList.map(p => p.replace('{{item}}', 'client list'));
    const acts = actions.map((act) => {
      const {
        action,
        actionData,
      } = act;
      let listId: PathOrId = null;
      if (has('campaign', actionData)) {
        listId = cond([
          [equals(clists[0]), (): PathOrId => ({ Id: actionData.campaign?.listId as number })],
          [equals(clists[1]), (): PathOrId => ({ Path: actionData.path.split('.') })],
          [T, (): PathOrId => ({ Id: actionData.campaign?.listId as number })]
        ])(actionData.isPath);
      }
      return cond<any[], GetAction>([
        [equals<string>('Send Message'), (): ASendMsgGetAction => {
          const {
            msgId
          } = propOr({ msgId: undefined, name: '' }, 'message', actionData) as Message;
          const { path, isPath } = actionData as ActionDataSendMessage;
          const msgs = pathOrIdList.map(p => p.replace('{{item}}', 'message'));
          console.log(actionData, msgId, isPath);
          if (isTemplate) {
            return ({
              SendMessage: [],
            });
          }
          if (isPath === msgs[0] && msgId && typeof msgId === 'number') {
            return ({
              SendMessage: {
                Id: msgId,
                name: undefined,
                msgId: undefined,
              },
            });
          } else if (isPath === msgs[1]) {
            return ({
              SendMessage: {
                Path: path.split('.'),
                msgId: undefined,
                name: undefined,
              },
            });
          }
          return ({
            SendMessage: justSentMsgId ? {
              Id: justSentMsgId, name: undefined, msgId: undefined,
            } : undefined,
          });
        }],
        [equals<string>('Send Form'), (): AMakeFormGetAction => {
          const hasForm = pathOr(false, ['form', 'formId'], actionData);
          if (hasForm) {
            return ({
              MakeForm: hasForm,
            });
          }
        }],
        [equals('Campaign'), (): AClientListGetAction => {
          if (has('change', actionData)) {
            const change = cond([
              [equals(campaignChangeActionOptions[0]), always('Add')],
              [equals(campaignChangeActionOptions[1]), always('Remove')],
              [equals(campaignChangeActionOptions[2]), always('Unsubscribe')],
            ])(actionData.change);
            return ({
              ClientList: {
                change: {
                  [change]: [] as [],
                },
                listId,
                listName: pathOr(undefined, ['campaign', 'listName'], actionData),
              },
            });
          }
          return;
        }],
        [equals('Client List'), (): AClientListGetAction => {
          if (has('change', actionData)) {
            const change = cond([
              [equals(campaignChangeActionOptions[0]), always('Add')],
              [equals(campaignChangeActionOptions[1]), always('Remove')],
              [equals(campaignChangeActionOptions[2]), always('Unsubscribe')],
            ])(actionData.change);
            return ({
              ClientList: {
                change: {
                  [change]: [] as [],
                },
                listId,
                listName: pathOr(undefined, ['campaign', 'listName'], actionData),
              },
            });
          }
          return;
        }],
        [equals('Appointment List'), (): AApptListGetAction => {
          if (has('change', actionData)) {
            const change = cond([
              [equals(apptListChangeActionOptions[0]), always('Add')],
              [equals(apptListChangeActionOptions[1]), always('Remove')],
            ])(actionData.change);
            const alists = pathOrIdList.map(p => p.replace('{{item}}', 'appointment list'));
            const mListId = pathOr(undefined, ['apptList', 'listId'], actionData);
            const listId = cond([
              [equals(alists[0]), (): PathOrId => ({ Id: mListId })],
              [equals(alists[1]), (): PathOrId => ({ Path: actionData.path.split('.') })],
              [T, (): PathOrId => ({ Id: mListId })]
            ])(actionData.isPath);
            return ({
              AppointmentList: {
                change,
                listId,
                listName: pathOr(undefined, ['apptList', 'listName'], actionData),
              },
            });
          }
          return;
        }],
        [equals('Appointment'), () => {
          if (has('scope', actionData)) {
            const scope: string = cond([
              [equals('this'), always('Trigger')],
              [equals('next'), always('Next')],
              [equals('today\'s'), always('Today')],
            ])(actionData.scope);
            return ({
              UpdateAppt: {
                status: actionData.status === 'Confirmed' ? undefined : actionData.status,
                updateConfirmed: actionData.status === 'Confirmed',
                scope,
              },
            });
          }
        }],
      ])(action);
    });
    const filter: GetFilter[] = filters.map(({ filterData, isTrue }): GetFilter => {
      if (filterData.type === 'Appointment') {
        const dir = dirCond('to', filterData.thanDirection);
        const dir1 = dirCond('to', filterData.thanDirection1);
        const predicate = cond([
          [equals('is of type'), () => {
            return {
              Appointment: {
                AppointmentTypeIds: filterData.appointmentTypeIds.filter((id) => id !== 0),
              },
            } as Predicate;
          }],
          [equals('is a type in'), () => {
            return {
              Appointment: {
                AppointmentTypes: {
                  Id: filterData.path.split('.'),
                },
              },
            } as Predicate;
          }],
          [equals('has status of'), () => {
            return {
              Appointment: {
                Status: filterData.status,
              },
            } as Predicate;
          }],
          [equals('is on'), () => {
            return {
              Appointment: {
                Time: {
                  EqualTo: {
                    Date: filterData.Date,
                  },
                },
              },
            } as Predicate;
          }],
          [equals('was'), () => {
            return {
              Appointment: {
                Time: {
                  EqualTo: {
                    Ago: toNumber(filterData.Ago),
                  },
                },
              },
            } as Predicate;
          }],
          [equals('is in'), () => {
            return {
              Appointment: {
                Time: {
                  EqualTo: {
                    InFuture: toNumber(filterData.InFuture),
                  },
                },
              },
            } as Predicate;
          }],
          [equals('is between'), () => {
            return {
              Appointment: {
                Time: {
                  Between: {
                    start: {
                      [dir]: typeof filterData.BetweenDatesStart === 'string' ?
                        filterData.BetweenDatesStart :
                        toNumber(filterData.BetweenDatesStart),
                    },
                    end: {
                      [dir1]: typeof filterData.BetweenDatesEnd === 'string' ?
                        filterData.BetweenDatesEnd :
                        toNumber(filterData.BetweenDatesEnd),
                    }
                  },
                }
              },
            } as Predicate;
          }],
          [equals('is greater than'), () => {
            return {
              Appointment: {
                Time: {
                  GreaterThan: {
                    [dir]: typeof filterData.GreaterThan === 'string' ?
                      filterData.GreaterThan :
                      toNumber(filterData.GreaterThan),
                  },
                }
              },
            } as Predicate;
          }],
          [equals('is greater than or equal to'), () => {
            return {
              Appointment: {
                Time: {
                  GreaterThanEqual: {
                    [dir]: typeof filterData.GreaterThanEqual === 'string' ?
                      filterData.GreaterThanEqual :
                      toNumber(filterData.GreaterThanEqual),
                  },
                }
              },
            } as Predicate;
          }],
          [equals('was less than'), () => {
            return {
              Appointment: {
                Time: {
                  LessThan: {
                    [dir]: typeof filterData.LessThan === 'string' ?
                      filterData.LessThan :
                      toNumber(filterData.LessThan),
                  },
                }
              },
            } as Predicate;
          }],
          [equals('was less than or equal to'), () => {
            return {
              Appointment: {
                Time: {
                  LessThanEqual: {
                    [dir]: typeof filterData.LessThanEqual === 'string' ?
                      filterData.LessThanEqual :
                      toNumber(filterData.LessThanEqual),
                  },
                }
              },
            } as Predicate;
          }],
          [equals('is the'), () => {
            return {
              Appointment: {
                ClientList: {
                  appointmentTypes: filterData.appointmentTypeIds.filter((id) => id !== 0),
                  offset: Number(filterData.offset),
                  repeat: false,
                  listId: filterData.campaign?.listId,
                  listName: filterData.campaign?.listName,
                  numberOfAppointments: Number(filterData.numberOfAppointments),
                },
              },
            } as Predicate;
          }],
          [equals('is repeatedly the'), () => {
            return {
              Appointment: {
                ClientList: {
                  appointmentTypes: filterData.appointmentTypeIds.filter((id) => id !== 0),
                  offset: Number(filterData.offset),
                  repeat: true,
                  listId: filterData.campaign?.listId,
                  listName: filterData.campaign?.listName,
                  numberOfAppointments: Number(filterData.numberOfAppointments),
                },
              },
            } as Predicate;
          }],
        ])(filterData.verb) as Predicate;
        return {
          isTrue,
          predicate,
        };
      } else if (filterData.type === 'Client') {
        const dir = dirCond('to', filterData.thanDirection);
        const dir1 = dirCond('to', filterData.thanDirection1);
        const clists = pathOrIdList.map(p => p.replace('{{item}}', 'client list'));
        const listId = cond([
          [equals(clists[0]), (): PathOrId => ({ Id: filterData.campaign?.listId as number })],
          [equals(clists[1]), (): PathOrId => ({ PathExact: filterData.path.split('.') })],
          [equals(clists[2]), (): PathOrId => ({ PathRelative: filterData.path.split('.') })],
          [T, (): PathOrId => ({ Id: filterData.campaign?.listId as number })]
        ])(filterData.isPath);
        const predicate = cond([
          [equals('has status of'), () => {
            return {
              Client: {
                Status: filterData.status,
              }
            } as Predicate;
          }],
          [equals('is in'), () => {
            return {
              Client: {
                ClientList: {
                  listId,
                  isCampaign: undefined,
                  listName: filterData.campaign?.listName,
                },
              }
            } as Predicate;
          }],
          [equals('was added to'), () => {
            return {
              Client: {
                ClientList: {
                  listId,
                  listName: filterData.campaign?.listName,
                  isCampaign: undefined,
                  added: cond([
                    [equals<string>('on'), (): DateTimeFilter => ({
                      EqualTo: {
                        Date: filterData.Date,
                      },
                    })],
                    [equals('exactly'), () => ({
                      EqualTo: {
                        Ago: toNumber(filterData.Ago),
                      }
                    })],
                    [equals('in'), () => ({
                      EqualTo: {
                        InFuture: toNumber(filterData.InFuture),
                      },
                    })],
                    [equals('between'), () => ({
                      Between: {
                        start: {
                          [dir]: typeof filterData.BetweenDatesStart === 'string' ?
                            filterData.BetweenDatesStart :
                            toNumber(filterData.BetweenDatesStart),
                        },
                        end: {
                          [dir1]: typeof filterData.BetweenDatesEnd === 'string' ?
                            filterData.BetweenDatesEnd :
                            toNumber(filterData.BetweenDatesEnd),
                        }
                      },
                    })],
                    [equals('greater than'), () => ({
                      GreaterThan: {
                        [dir]: typeof filterData.GreaterThan === 'string' ?
                          filterData.GreaterThan :
                          toNumber(filterData.GreaterThan),
                      },
                    })],
                    [equals('greater than or equal to'), () => ({
                      GreaterThanEqual: {
                        [dir]: typeof filterData.GreaterThanEqual === 'string' ?
                          filterData.GreaterThanEqual :
                          toNumber(filterData.GreaterThanEqual),
                      },
                    })],
                    [equals('less than'), () => ({
                      LessThan: {
                        [dir]: typeof filterData.LessThan === 'string' ?
                          filterData.LessThan :
                          toNumber(filterData.LessThan),
                      },
                    })],
                    [equals('less than or equal to'), () => ({
                      LessThanEqual: {
                        [dir]: typeof filterData.LessThanEqual === 'string' ?
                          filterData.LessThanEqual :
                          toNumber(filterData.LessThanEqual),
                      },
                    })]
                  ])(filterData.added),
                },
              }
            } as Predicate;
          }],
          [equals('was sent'), () => {
            return {
              Client: {
                SentMsg: {
                  ...filterData.message,
                  msgId: {
                    Id: filterData.message.msgId,
                  } as PathOrId,
                },
              }
            } as Predicate;
          }],
          [equals('was sent any message in'), () => {
            return {
              Client: {
                SentMsg: {
                  ...filterData.message,
                  msgId: {
                    Path: filterData.path.split('.'),
                  } as PathOrId,
                },
              }
            } as Predicate;
          }],
          [equals('has appointment'), () => {
            return {
              Client: {
                HasAppointment: {
                  appointmentTypes: {
                    Id: filterData.appointmentTypeIds.filter((id) => id !== 0),
                  },
                  status: filterData.statuses,
                  exists: true,
                  time: cond([
                    [equals<string>('on'), (): DateTimeFilter => ({
                      EqualTo: {
                        Date: filterData.Date,
                      },
                    })],
                    [equals('exactly'), () => ({
                      EqualTo: {
                        Ago: toNumber(filterData.Ago),
                      },
                    })],
                    [equals('in'), () => ({
                      EqualTo: {
                        InFuture: toNumber(filterData.InFuture),
                      },
                    })],
                    [equals('between'), () => ({
                      Between: {
                        start: {
                          [dir]: typeof filterData.BetweenDatesStart === 'string' ?
                            filterData.BetweenDatesStart :
                            toNumber(filterData.BetweenDatesStart),
                        },
                        end: {
                          [dir1]: typeof filterData.BetweenDatesEnd === 'string' ?
                            filterData.BetweenDatesEnd :
                            toNumber(filterData.BetweenDatesEnd),
                        }
                      },
                    })],
                    [equals('greater than'), () => ({
                      GreaterThan: {
                        [dir]: typeof filterData.GreaterThan === 'string' ?
                          filterData.GreaterThan :
                          toNumber(filterData.GreaterThan),
                      },
                    })],
                    [equals('greater than or equal to'), () => ({
                      GreaterThanEqual: {
                        [dir]: typeof filterData.GreaterThanEqual === 'string' ?
                          filterData.GreaterThanEqual :
                          toNumber(filterData.GreaterThanEqual),
                      },
                    })],
                    [equals('less than'), () => ({
                      LessThan: {
                        [dir]: typeof filterData.LessThan === 'string' ?
                          filterData.LessThan :
                          toNumber(filterData.LessThan),
                      },
                    })],
                    [equals('less than or equal to'), () => ({
                      LessThanEqual: {
                        [dir]: typeof filterData.LessThanEqual === 'string' ?
                          filterData.LessThanEqual :
                          toNumber(filterData.LessThanEqual),
                      },
                    })]
                  ])(filterData.added),
                }
              }
            } as Predicate;
          }],
          [equals('last appointment'), () => {
            return {
              Client: {
                LastAppointment: {
                  appointmentTypes: {
                    PathRelative: filterData.path.split('.'),
                  },
                  time: cond([
                    [equals<string>('on'), (): DateTimeFilter => ({
                      EqualTo: {
                        Date: filterData.Date,
                      },
                    })],
                    [equals('exactly'), () => ({
                      EqualTo: {
                        Ago: toNumber(filterData.Ago),
                      },
                    })],
                    [equals('in'), () => ({
                      EqualTo: {
                        InFuture: toNumber(filterData.InFuture),
                      },
                    })],
                    [equals('between'), () => ({
                      Between: {
                        start: {
                          [dir]: typeof filterData.BetweenDatesStart === 'string' ?
                            filterData.BetweenDatesStart :
                            toNumber(filterData.BetweenDatesStart),
                        },
                        end: {
                          [dir1]: typeof filterData.BetweenDatesEnd === 'string' ?
                            filterData.BetweenDatesEnd :
                            toNumber(filterData.BetweenDatesEnd),
                        }
                      },
                    })],
                    [equals('greater than'), () => ({
                      GreaterThan: {
                        [dir]: typeof filterData.GreaterThan === 'string' ?
                          filterData.GreaterThan :
                          toNumber(filterData.GreaterThan),
                      },
                    })],
                    [equals('greater than or equal to'), () => ({
                      GreaterThanEqual: {
                        [dir]: typeof filterData.GreaterThanEqual === 'string' ?
                          filterData.GreaterThanEqual :
                          toNumber(filterData.GreaterThanEqual),
                      },
                    })],
                    [equals('less than'), () => ({
                      LessThan: {
                        [dir]: typeof filterData.LessThan === 'string' ?
                          filterData.LessThan :
                          toNumber(filterData.LessThan),
                      },
                    })],
                    [equals('less than or equal to'), () => ({
                      LessThanEqual: {
                        [dir]: typeof filterData.LessThanEqual === 'string' ?
                          filterData.LessThanEqual :
                          toNumber(filterData.LessThanEqual),
                      },
                    })]
                  ])(filterData.added),
                }
              }
            } as Predicate;
          }],
        ])(filterData.verb);
        return {
          isTrue,
          predicate,
        };
      }
      return { isTrue: true, predicate: { Dummy: [] as [] } };
    });
    console.log(filter);
    const newAuto = {
      ...automation,
      deleteActions: deletedActions,
      actions: acts,
      trigger: trig,
      enabled,
      filters: filter,
      delay,
      hidden,
      isTemplate,
      comment: comment ? comment.trim() : '',
    };
    if (saveIsUpdate) {
      return updateAutomation(newAuto);
    }
    return automationSaveState.invoke(newAuto).then((auto) => {
      setEdit(false);
      updateAutomation(auto);
      update({
        deletedActions: [],
      });
      if (externalSave) {
        externalSave(auto);
      }
    });
  };

  const toggle = (enabled: boolean) => {
    return toggleState.invoke({
      enabled, id: automation.id,
    }).catch(() => {
      update({ enabled: !enabled });
    });
  };

  const handleDelete = () => {
    popupWithCancel(
      'Are you sure you want to delete?',
      'This action cannot be undone!',
      () => {
        if (automation.id === 0) {
          return removeAutomation();
        }
        const auto = {
          ...automation,
          isTemplate,
        };
        return deleteAutomationState.invoke(auto).then(() => {
          removeAutomation();
        });
      },
      'danger'
    );
  };

  const toggleEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    update({ enabled: checked });
    toggle(checked);
    //     onSave(checked);
  };

  const hasFilters = !has('Dummy', state.trigger);
  //  TODO: this was here for a reason but it seems to just cause the
  //        add filter icon button to disappear when you don't want it
  //        to. The todo is to figure out why it's here.
  //  const remainingFilters = calculateRemainingFilters(state);
  const unmetRequirement = cantSave && cantSave(state);

  useEffect(() => {
    if (forceSave) {
      onSave();
    }
  }, [forceSave]);

  return (
    <Paper key={id} className={classes.progessSection}>
      {edit &&
        <div style={{
          display: 'flex',
          justifyContent: !unmetRequirement ? 'flex-end' : 'space-between',
          alignItems: 'center',
        }}>
          {unmetRequirement &&
            <p style={{ color: 'red', margin: 'unset' }}>
              {unmetRequirement}
            </p>}
          <div>
            {!noSave &&
              <>
                {automationSaveState.loading ?
                  <CircularProgress />
                  :
                  <Button
                    variant='contained'
                    disabled={Boolean(unmetRequirement || errorCheck(state))}
                    onClick={() => onSave()} >
                    Save
                  </Button>}
              </>}
            &nbsp;
            {!hideCancel &&
              <Button
                variant='outlined'
                onClick={() => {
                  setEdit(false);
                  if (id === 0) {
                    removeAutomation();
                  }
                  if (onCancel) {
                    onCancel();
                  }
                }} >
                Cancel
              </Button>}
          </div>
        </div>}
      <Row style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        {!edit &&
          <Checkbox
            checked={Boolean(selected)}
            onChange={(e) => {
              const checked = e.target.checked;
              setSelected(checked);
            }}
          />}
        {edit ?
          <div style={{ width: '100%' }}>
            When
            <div style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
              alignItems: 'center',
              minHeight: '45px',
            }}>
              <FormControl error={state.trigger === ''}>
                <Select
                  value={state.trigger}
                  displayEmpty
                  required
                  disabled={!isAdmin}
                  renderValue={(selected) => {
                    if (isEmpty(selected)) {
                      return (
                        <div className={classes.selectTextContainer}>
                          <em className={classes.selectText}>Something...</em>
                        </div>
                      );
                    } else {
                      return (
                        <div className={classes.selectTextContainer}>
                          <span className={classes.selectText}>{selected}</span>
                        </div>
                      );
                    }
                  }}
                  style={{ marginRight: '10px' }}
                  onChange={(e) => {
                    const value = e.target.value;
                    const d = triggerData[value as keyof GlobalTriggerData];
                    update({
                      trigger: value,
                      triggerData: {
                        ...d,
                        campaign: defaultCampaign ? {
                          listId: defaultCampaign.id,
                          listName: defaultCampaign.name,
                        } : null,
                      },
                    });
                  }}
                >
                  {triggers.map((v) => (
                    <MenuItem key={v} value={v}>
                      <ListItemText primary={v} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{state.trigger === '' ? 'Required' : ' '}</FormHelperText>
              </FormControl>
              <RenderOptions
                state={state}
                update={update}
                classes={classes}
                type={'trigger'}
                types={types}
                professionals={professionals}
                npps={npps}
                isAdmin={isAdmin}
              />
            </div>
            {isEmpty(state.filters) && hasFilters &&
              <Button
                startIcon={<AddIcon />}
                onClick={() => {
                  update({
                    filters: append({
                      isTrue: true,
                      filter: '',
                      filterData: {
                        type: 'Nothing',
                        text: '',
                      }
                    }, state.filters),
                  });
                }}>
                Add filter
              </Button>
            }
            {!isEmpty(state.filters) && hasFilters &&
              <>
                <IfSelect
                  bool={state.filters[0].isTrue}
                  lupdate={update}
                  state={state}
                  index={0}
                />
                <br />
                { /* .flatMap((a) => [', ', a]).slice(1) ≅ R.intersperse(',') */
                  state.filters.map((filt, idx) => {
                    return (
                      <div
                        key={`filters-${idx}`}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                        }}>
                        <div style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          minHeight: '45px',
                        }}>
                          <RenderOptions
                            state={state}
                            update={update}
                            classes={classes}
                            type={'filter'}
                            index={idx}
                            types={types}
                            npps={npps}
                            professionals={professionals}
                            defaultCampaign={defaultCampaign}
                          />
                        </div>
                        <div>
                          {idx + 1 === state.filters.length && //remainingFilters !== 1 &&
                            <Tooltip arrow title='Add filter'>
                              <IconButton onClick={() => {
                                update({
                                  filters: append({
                                    isTrue: true,
                                    filter: '',
                                    filterData: {
                                      type: 'Nothing',
                                      text: '',
                                    }
                                  }, state.filters),
                                });
                              }}>
                                <AddIcon />
                              </IconButton>
                            </Tooltip>}
                          <Tooltip arrow title='Delete Filter'>
                            <IconButton onClick={() => {
                              update(evolve({
                                filters: remove(idx, 1),
                              }, state));
                            }}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  }).flatMap((a, i) => [
                    <div key={`filter-item-if-${i}`}>
                    and only
                      <IfSelect
                        bool={state.filters[i].isTrue}
                        lupdate={update}
                        state={state}
                        index={i}
                      />
                    </div>, a]).slice(1)}
              </>
            }
            {isNil(state.delay) &&
              <Button
                startIcon={<AddIcon />}
                onClick={() => {
                  update({
                    delay: 0,
                  });
                }}>
                Add delay
              </Button>
            }
            {!isNil(state.delay) &&
              <div>
                wait
                <br />
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                    <NumberTextField
                      className={classes.textField}
                      type='number'
                      value={state.delay}
                      placeholder={'minutes'}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        if (value > 0 && value < 20) {
                          update({
                            delay: value,
                          });
                        }
                      }}
                    />
                    &nbsp;
                    minutes
                  </div>
                  <Tooltip arrow title='Remove Delay'>
                    <IconButton onClick={() => {
                      update({ delay: null });
                    }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            }
            <br />
            then
            <br />
            {disabledActions ?
              disabledActionsMessage
              : state.actions.map((action: Action, idx: number) => {
                return (
                  <div
                    key={`actions-${idx}`}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      width: '100%',
                    }}>
                    <div style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      minHeight: '45px',
                    }}>
                      <RenderOptions
                        state={state}
                        update={update}
                        classes={classes}
                        type={'action'}
                        index={idx}
                        types={types}
                        npps={npps}
                        professionals={professionals}
                        defaultCampaign={defaultCampaign}
                      />
                    </div>
                    <div>
                      {idx + 1 === state.actions.length &&
                        <Tooltip arrow title='Add action'>
                          <IconButton onClick={() => {

                            update({
                              actions: append({
                                action: '',
                                actionData: {
                                  type: 'Nothing',
                                  text: 'send',
                                }
                              }, state.actions),
                            });
                          }}>
                            <AddIcon />
                          </IconButton>
                        </Tooltip>}
                      {state.actions.length > 1 &&
                        <Tooltip arrow title='Delete Action'>
                          <IconButton onClick={() => {
                            update(evolve({
                              actions: remove(idx, 1),
                              deletedActions: action.id ? append(action.id) : identity,
                            }, state));
                          }}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>}
                    </div>
                  </div>
                );
              }).flatMap((a) => [<div>and then</div>, a]).slice(1)}
          </div>
          :
          <div
            className={classes.wordsContainer}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              fontSize: '16px',
              flexWrap: 'wrap',
              marginRight: '15px',
            }}>
            {words}
            {isTemplate && automation.comment &&
              <Tooltip arrow title={automation.comment}>
                <ContactSupport style={{ fontSize: '20px' }} />
              </Tooltip>}
          </div>}
        {(!edit && deleteAutomationState.loading) &&
          <Loading loading vh={45} />}
        {!edit && !deleteAutomationState.loading &&
          <div style={{ display: 'flex' }}>
            {!edit &&
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                <Typography>Off</Typography>
                <Switch
                  color="primary"
                  checked={state.enabled}
                  onChange={toggleEnabled}
                />
                <Typography>On</Typography>
              </div>}
            <IconButton
              onClick={() => setEdit(!edit)} >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={handleDelete}
            >
              <DeleteIcon />
            </IconButton>
          </div>}
      </Row>
      {edit && isTemplate &&
        <TextField
          label='Comment'
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => update({ comment: e.target.value })}
          value={state.comment}
          style={{ width: '100%' }}
        />
      }
    </Paper>
  );
};

type AutomationPathPair = [path: Folder, automation: Automation[]];

interface AutomationTemplateItemProps {
  automation: Automation;
  automations: AutomationPathPair[];
  inDialog: boolean;
  updateAutomations: (newAutoList: AutomationPathPair, idx: number, addToNoFolder?: Automation) => void;
  replaceAutomations?: (newAutoList: AutomationPathPair, idx: number) => void;
  onSelect: (auto: Automation) => void;
  path: AutomationPathPair;
  pathIndex: number;
  automationIndex: number;

}

interface CreateFolder {
  path: string[];
  resource: Resource;
}

const createPath = (newPath: CreateFolder): Promise<Folder> => {
  return api.post('templates/paths', newPath);
};

const removePath = (path: string[]): Promise<void> => {
  const pathString = path.join('.');
  return api.delete(`templates/paths/path/${pathString}`, { data: {} });
};

const AutomationTemplateItem = ({
  automation,
  automations,
  inDialog,
  updateAutomations,
  replaceAutomations,
  onSelect,
  path,
  pathIndex,
  automationIndex,
}: AutomationTemplateItemProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [folderSelectAnchorEl, setFolderSelectAnchorEl] = useState(null);
  const [selectedAuto, setSelectedAuto] = useState(null);
  const [selectedAutomationIdx, setSelectedAutomationIdx] = useState(null);
  const [open, setOpen] = useState(false);
  const [inFolders, setInFolders] = useState([]);
  const [edit, setEdit] = useState(automation.isEdit);
  const createState = usePromise(createPath, null);
  const removeState = usePromise(removePath, null);
  const deleteAutomationState = usePromise(rawActions.deleteAutomation, {});
  const {
    id,
  } = automation;

  const { tz } = useSelector((state) => ({
    tz: state.login.office.timezone,
  }));

  const words = useMemo(() => {
    return createWords({ automation, classes, tz });
  }, [automation]);

  const selectHandler = (
    e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLDivElement, MouseEvent>,
    auto: Automation,
    autoIndex: number
  ) => {
    if (inDialog) {
      onSelect(auto);
    } else {
      setInFolders(pipe(
        filter(([_, autos]) => {
          return autos.find(({ id }: Automation) => auto.id === id);
        }),
        map(([f, _]) => f.pathId),
      )(automations));
      setAnchorEl(e.currentTarget);
      setSelectedAuto(auto);
      setSelectedAutomationIdx(autoIndex);
    }
  };

  const handleFolderSelect = (idx: number, pathPair: AutomationPathPair, isRemove: boolean) => {
    const path = pathPair[0].path.concat([`auto_${selectedAuto.id}`]);
    if (isRemove) {
      removeState.invoke(path).then(() => {
        const newAutosList = remove(selectedAutomationIdx, 1, pathPair[1]);
        const newPathPair: any = Rupdate(1, newAutosList, pathPair);
        updateAutomations(newPathPair, idx, inFolders.length - 1 === 0 && selectedAuto);
        setAnchorEl(null);
        setFolderSelectAnchorEl(null);
      });
    } else {
      const createPath = {
        path,
        resource: {
          Automation: selectedAuto.id,
        },
      };
      createState.invoke(createPath).then((newPath) => {
        const newAutosList: Automation[] = append({ ...selectedAuto, pathId: newPath.pathId }, pathPair[1]);
        const newPathPair: any = Rupdate(1, newAutosList, pathPair);
        updateAutomations(newPathPair, idx);
        setAnchorEl(null);
        setFolderSelectAnchorEl(null);
      });
    }
  };

  const deleteTemplate = () => {
    setAnchorEl(null);
    const p = path[0].path && path[0].path.concat([`auto_${selectedAuto.id}`]);
    Promise.all([
      p && removeState.invoke(p),
      deleteAutomationState.invoke({ ...selectedAuto, isTemplate: true })
    ]).then(() => {
      const p = automations.find(([{ pathId }]) => pathId === path[0].pathId);
      const idx = automations.findIndex(([{ pathId }]) => pathId === path[0].pathId);
      const newAutosList = remove(selectedAutomationIdx, 1, p[1]);
      const newPathPair: any = Rupdate(1, newAutosList, p);
      replaceAutomations(newPathPair, idx);
      setAnchorEl(null);
      setFolderSelectAnchorEl(null);
    });
  };

  return (
    <div>
      <AddDialog
        open={open}
        onClose={() => {
          {/* updateAutomations([label, [selectedAuto]], null); */ }
          setOpen(false);
        }}
        folder={{
          path: ['tmp', 'auto'],
          pathId: 0,
          resource: {
            Automation: selectedAuto?.id,
          },
        }}
        template
      />
      {edit ?
        <AutomationItem
          key={id}
          automation={{ ...automation, isEdit: true }}
          isTemplate
          isAdmin
          onCancel={() => {
            setSelectedAuto(null);
            setEdit(false);
          }}
          updateAutomation={(auto) => {
            const newAutosList = Rupdate(automationIndex, auto, path[1]);
            const newAuto: any = Rupdate(1, newAutosList, path);
            updateAutomations(
              newAuto,
              pathIndex,
              path[0].pathId === 0 && auto
            );
            setSelectedAuto(null);
            setEdit(false);
          }}
        /> :
        <Paper
          key={id}
          className={classes.templateBox}
          onClick={(e) => selectHandler(e, automation, automationIndex)}
        >
          <div
            className={classes.wordsContainer}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              fontSize: '16px',
              flexWrap: 'wrap',
              marginRight: '15px',
            }}>
            {words}
          </div>
        </Paper>}
      <Menu
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => {
          setAnchorEl(null);
          setEdit(true);
        }}>
          <ListItemIcon>
            <EditIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary='Edit Template' />
        </MenuItem>
        <MenuItem onClick={() => setAnchorEl(null)}>
          <ListItemIcon>
            <FileCopyIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary='Copy Template' />
        </MenuItem>
        <MenuItem onClick={deleteTemplate}>
          <ListItemIcon>
            <DeleteIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary='Delete Template' />
        </MenuItem>
        <Divider />
        <MenuItem onClick={(e) => {
          setFolderSelectAnchorEl(e.currentTarget);
        }}>
          <ListItemIcon>
            <RuleIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary='Select Folders' />
          <ListItemIcon style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
            <ArrowRightIcon fontSize="large" />
          </ListItemIcon>
        </MenuItem>
      </Menu>
      <Menu
        style={{ width: 'auto' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        id="swap-menu"
        anchorEl={folderSelectAnchorEl}
        open={Boolean(folderSelectAnchorEl)}
        onClose={() => setFolderSelectAnchorEl(null)}>
        <MenuItem
          onClick={() => {
            setFolderSelectAnchorEl(null);
            setAnchorEl(null);
            setOpen(true);
          }}>
          <ListItemIcon>
            <AddIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary='New Folder' />
        </MenuItem>
        {automations &&
          [<Divider />,
            automations.map((pathPair, idx: number) => {
              const [f, _] = pathPair;
              const name = pathOr('No Folder', ['resource', 'PathLabel', 'label'], f);
              const isIn = includes(f.pathId, inFolders);
              if (name !== 'No Folder') {
                return (
                  <MenuItem
                    key={name}
                    onClick={() => handleFolderSelect(idx, pathPair, isIn)}
                  >
                    <Checkbox
                      defaultChecked={isIn}
                    />
                    <ListItemText>{name}</ListItemText>
                  </MenuItem>
                );
              }
              return null;
            })
          ]}
      </Menu>
    </div>
  );
};

interface AutomationsTemplateListProps {
  automations: AutomationPathPair[];
  inDialog: boolean;
  onSelect: (auto: Automation) => void;
  updateAutomations: (newAutoList: AutomationPathPair, idx: number, addToNoFolder?: Automation) => void;
  replaceAutomations?: (newAutoList: AutomationPathPair, idx: number) => void;
  initFolder: Folder;
  initFolderRef: any;
}

export const AutomationsTemplateList = ({
  automations,
  inDialog,
  onSelect,
  updateAutomations,
  replaceAutomations,
  initFolder,
  initFolderRef,
}: AutomationsTemplateListProps) => {
  const classes = useStyles();

  return (
    <div style={{ height: inDialog ? 'calc(100vh - 350px)' : '80vh' }}>
      {/* <PopupTemplate/> */}
      {automations &&
        automations.map((pathPair, idx) => {
          const [path, autos] = pathPair;
          return (
            <div id={`path-${path.pathId}`}
              key={path.pathId}
              className='path-header'
              ref={initFolder?.pathId === path.pathId ? initFolderRef : null}
            >
              <Row>
                <div style={{
                  width: '10%',
                  borderBottom: 'gray solid 1px',
                }}></div>
                &nbsp;
                <div className={classes.headerTitles}>
                  {pathOr('No Folder', ['resource', 'PathLabel', 'label'], path)}
                </div>
                &nbsp;
                <div style={{
                  width: '100%',
                  borderBottom: 'gray solid 1px',
                }}></div>
              </Row>
              <div className={classes.templateContainer}>
                {autos.map((auto, index) => {
                  return (
                    <AutomationTemplateItem
                      key={auto.id}
                      automation={auto}
                      automations={automations}
                      inDialog={inDialog}
                      updateAutomations={updateAutomations}
                      replaceAutomations={replaceAutomations}
                      onSelect={onSelect}
                      path={pathPair}
                      pathIndex={idx}
                      automationIndex={index}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

interface AutomationsListProps {
  isTemplate?: boolean;
  automations: Automation[];
  defaultCampaign?: DefaultList;
  removeAutomation?: (idx: number, id?: number) => void;
  updateAutomation?: (idx: number, auto: Automation) => void;
  onSave: (idx: number) => (auto: Automation) => void;
  cantSave?: (idx: number) => (state: AutomationsState) => string;
  noSave?: boolean;
  disabledActions?: boolean;
  disabledActionsMessage?: string;
  forceSave?: boolean;
  enabled?: boolean;
  saveIsUpdate?: boolean;
  hideCancel?: boolean;
  hideAutomations?: boolean;
}

export const AutomationsList = ({
  isTemplate,
  automations,
  defaultCampaign,
  removeAutomation,
  updateAutomation,
  onSave,
  cantSave,
  noSave = false,
  disabledActions = false,
  disabledActionsMessage,
  forceSave = false,
  enabled,
  saveIsUpdate = false,
  hideCancel = false,
  hideAutomations = false,
}: AutomationsListProps) => {
  const dispatch = useDispatch();
  const actions = bindActionCreators(rawActions, dispatch);
  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false);
  const [message, setMessage] = useState({} as Message);
  const [selectedAutomationIdx, setselectedAutomationIdx] = useState(null);

  const {
    office,
    professionals,
    types,
    npps,
    justSentMsgId,
    isAdmin,
  } = useSelector((state) => ({
    office: state.login.office,
    hasFeature: true, // includes('Campaigns', pathOr([], ['login', 'features'], state))
    professionals: state.professionals.professionals,
    types: state.professionals.types,
    justSentMsgId: isTemplate ? state.templateEdit.justSentMsgId : state.editRecurring.justSentMsgId,
    npps: state.automations.npps,
    isAdmin: state.login.admin,
  }));

  useEffect(() => {
    if (!isTemplate) {
      dispatch(getProfessionals({ history, ehrSystem: office.ehrSystem }));
      actions.getNpps();
    }
  }, []);

  return (
    <div style={{ overflow: 'unset', margin: '20px' }}>
      <PopupTemplate />
      <div>
        <MessageDialog
          isOpen={isMessageDialogOpen}
          onClose={() => setIsMessageDialogOpen(false)}
          message={message}
          onSave={(msg: Message) => {
            const e: any = evolve({
              actions: adjust(
                selectedAutomationIdx.actionIdx,
                assocPath(['action', 'SendMessage', 'name'], msg.name))
            });
            actions.automationsPatch({
              automations: adjust(selectedAutomationIdx.autoIdx, e, automations),
            });
          }}
        />
        <div>
          {automations &&
            automations.map((auto, idx) => (
              <AutomationItem
                key={auto.id}
                automation={auto}
                externalEnabled={enabled}
                professionals={professionals}
                types={types}
                npps={npps}
                defaultCampaign={defaultCampaign}
                externalSave={onSave && onSave(idx)}
                cantSave={cantSave && cantSave(idx)}
                noSave={noSave}
                forceSave={forceSave}
                disabledActions={disabledActions}
                disabledActionsMessage={disabledActionsMessage}
                justSentMsgId={justSentMsgId}
                isTemplate={isTemplate}
                isAdmin={isAdmin}
                saveIsUpdate={saveIsUpdate}
                hideCancel={hideCancel}
                isHidden={hideAutomations}
                removeAutomation={() => {
                  if (removeAutomation) {
                    removeAutomation(idx, auto.id);
                  } else {
                    actions.automationsPatch({
                      automations: remove(idx, 1, automations),
                    });
                  }
                }}
                updateAutomation={(auto) => {
                  if (updateAutomation) {
                    updateAutomation(idx, auto);
                  } else {
                    actions.automationsPatch({
                      automations: Rupdate(idx, auto, automations),
                    });
                  }
                }}
                openMessage={(msg, actionIdx) => {
                  if (typeof msg?.msgId !== 'number') {
                    setMessage(msg as Message);
                  }
                  setselectedAutomationIdx({
                    autoIdx: idx,
                    actionIdx,
                  });
                  setIsMessageDialogOpen(true);
                }}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

interface GetAutomationsForMessageProps {
  message: DefaultMessage;
  makeNewAutomation: boolean;
  template?: boolean;
  campaignId?: number;
}

interface MessageAutomationState {
  data: any[];
  page?: number;
  perPage?: number;
  totalCount?: number;
  totalPages?: number;
}

const getAutomationsForMessage = ({
  message,
  makeNewAutomation,
  template,
  campaignId,
}: GetAutomationsForMessageProps) => {
  if (template) {
    const path = `tmp.cl.cmp_${campaignId}.msg_${message.id}`;
    return api.post('automations/template/query', {
      page: 1,
      perPage: 100,
      query: {
        path: {
          Exists: {
            isDirectDescendant: path.split('.'),
          },
        },
      },
    });
  }
  return api.post('automations/query', {
    page: 1,
    perPage: 100,
    query: {
      action: {
        msgId: message.id,
      }
    },
  }).then((automations) => {
    if (isEmpty(automations.data) && makeNewAutomation) {
      return ({
        ...automations,
        data: [{
          actions: [{
            action: {
              SendMessage: {
                msgId: message.id,
                name: message.name,
              }
            },
            id: 0
          }],
          enabled: false,
          id: 0,
          delay: null,
          isEdit: true,
          hidden: true,
          filter: {
            appointment: null,
            client: null
          },
          trigger: {
            Dummy: [],
          }
        }],
      });
    }
    return automations;
  });
};

const defaultAutomation = (message: DefaultMessage): Automation => ({
  actions: [{
    SendMessage: {
      msgId: message.id,
      name: message.name,
    }
  }],
  enabled: false,
  id: 0,
  delay: null,
  isEdit: true,
  hidden: true,
  filters: [],
  trigger: {
    Dummy: [],
  },
  comment: '',
});

interface AutomationDialogProps {
  isOpen: boolean;
  onClose: () => void;
  message: DefaultMessage;
  defaultCampaign: DefaultList;
  makeNewAutomation?: boolean;
  template?: boolean;
  campaignId?: number;
  hideAutomations?: boolean;
}

export const AutomationDialog = ({
  isOpen,
  onClose,
  message,
  defaultCampaign,
  makeNewAutomation = false,
  template,
  campaignId,
  hideAutomations = false,
}: AutomationDialogProps) => {
  const messageAutomationsState = usePromise<
    GetAutomationsForMessageProps, MessageAutomationState
  >(getAutomationsForMessage, { data: [] as any[] });

  const { isAdmin } = useSelector((state) => ({
    isAdmin: pathOr(false, ['login', 'admin'], state),
  }));

  useEffect(() => {
    if (message) {
      messageAutomationsState.invoke({
        message, makeNewAutomation, template, campaignId,
      });
    }
  }, [message, isOpen]);

  const automations = messageAutomationsState.data.data;

  const newAutomation = () => {
    messageAutomationsState.setState({
      ...messageAutomationsState,
      data: {
        ...messageAutomationsState.data,
        data: prepend(defaultAutomation(message), automations),
      }
    });
  };

  const updateAutomation = (idx: number) => (automation: Automation) => {
    messageAutomationsState.setState({
      ...messageAutomationsState,
      data: {
        ...messageAutomationsState.data,
        data: Rupdate(idx, automation, automations),
      }
    });
  };

  const removeAutomation = (idx: number) => {
    messageAutomationsState.setState({
      ...messageAutomationsState,
      data: {
        ...messageAutomationsState.data,
        data: remove(idx, 1, automations),
      }
    });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
          Automation
          {isAdmin &&
            <Button
              variant='contained'
              onClick={newAutomation}
              style={{ fontSize: '14px' }}
            >
              New Automation
            </Button>}
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <Row>
          {messageAutomationsState.loading && <Loading loading vh={45} />}
        </Row>
        {isEmpty(automations) && !messageAutomationsState.loading &&
          <div>
            There are no automations using this message!
          </div>
        }
        {!isEmpty(automations) && !messageAutomationsState.loading &&
          <AutomationsList
            automations={automations}
            defaultCampaign={defaultCampaign}
            disabledActions
            disabledActionsMessage={`send ${message.name}.`}
            removeAutomation={removeAutomation}
            onSave={updateAutomation}
            isTemplate={template}
            hideAutomations={hideAutomations}
          />}
      </DialogContent>
      <DialogActions style={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}>
        <Button
          onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface AutomationsProps {
  history: History,
}

const Automations = ({ history }: AutomationsProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const actions = bindActionCreators(rawActions, dispatch);
  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false);
  const [message, setMessage] = useState({} as Message);
  const [selectedAutomationIdx, setselectedAutomationIdx] = useState(null);
  const [query, setQuery] = useState({ enabled: null, trigger: '' });
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);

  useTitle('Automations');

  const {
    office, hasFeature, busy, automations, professionals, types, npps, isAdmin
  } = useSelector((state) => ({
    ...state.automations,
    office: state.login.office,
    hasFeature: true, // includes('Campaigns', pathOr([], ['login', 'features'], state))
    professionals: state.professionals.professionals,
    types: state.professionals.types,
    isAdmin: state.login.admin,
  }));

  useEffect(() => {
    dispatch(getProfessionals({ history, ehrSystem: office.ehrSystem }));
    actions.getNpps();
  }, []);

  useEffect(() => {
    if (!isEmpty(types))
      actions.getAutomations({ page: 1 });
  }, [types]);

  useEffect(() => {
    actions.getAutomations({ query, perPage: automations.perPage });
  }, [query]);

  if (!hasFeature) {
    return (
      <FeatureDisabled title="Automations">
        <p>
          Automations are the next sliced bread, or something.
        </p>
      </FeatureDisabled>
    );
  }

  const addPage = (num: number) => () => {
    const page = automations.page + num;
    actions.getAutomations({ page, query, perPage: automations.perPage });
  };

  const onPerPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    actions.getAutomations({ perPage: Number(value), query });
  };

  return (
    <>
      <Header
        title='Automations'
        leftIcons={[
          <FilterSelect
            title='Enabled'
            Icon={EnabledIcon}
            items={enabledOptions.map(item => item.name)}
            selected={enabledOptions.find(item => item.value === query.enabled).name}
            setSelected={(value) => {
              const item = enabledOptions.find(item => item.name === value);
              setQuery({ ...query, enabled: item.value });
            }}
          />,
          <FilterSelect
            title='Trigger'
            noneText='All'
            Icon={TriggerIcon}
            items={triggers.map(item => item === '' ? 'All' : item)}
            selected={query.trigger || 'All'}
            setSelected={(value) => {
              setQuery({ ...query, trigger: value === 'All' ? '' : value });
            }}
          />
        ]}
        rightIcons={[
          <HeaderButton title='Add Automation' Icon={AddIcon} onClick={() => actions.newAutomation()} />
        ]}
        onlyIconsWidth={560}
      />
      <div
        style={{
          padding: '20px',
          paddingTop: 0,
          overflowY: 'auto',
        }}>
        <div style={{ paddingBottom: 30 }}>
          {/* <HelpDialog */}
          {/*   pageId={ehrSystem === 'None' ? "clients-tab-sas" : "clients-tab"} */}
          {/*   handleClose={helpState.handleClose} */}
          {/*   open={helpState.isOpen} /> */}
          <PopupTemplate />
          <MessageDialog
            isOpen={isMessageDialogOpen}
            onClose={() => setIsMessageDialogOpen(false)}
            message={message}
            onSave={(msg: DefaultMessage) => {
              const e = evolve({
                actions: adjust(
                  selectedAutomationIdx.actionIdx,
                  assocPath(['action', 'SendMessage', 'name'], msg.name))
              });
              actions.automationsPatch({
                automations: adjust(selectedAutomationIdx.autoIdx, e, automations),
              });
            }}
          />
          <AutomationTemplateDialog
            open={openTemplateDialog}
            onClose={() => setOpenTemplateDialog(false)}
            onSelect={(auto) => {
              setOpenTemplateDialog(false);
              actions.automationsPatch({
                automations: evolve({
                  data: prepend({ ...auto, isEdit: true, id: 0, enabled: false })
                }, automations),
              });
            }}
          />
          {busy && <Loading loading vh={45} />}
          <div>
            {automations.data &&
            automations.data.map((auto: Automation, idx: number) => (
              <AutomationItem
                key={auto.id}
                automation={auto}
                professionals={professionals}
                types={types}
                npps={npps}
                isAdmin={isAdmin}
                removeAutomation={() => {
                  actions.automationsPatch({
                    automations: evolve({
                      data: remove(idx, 1)
                    }, automations),
                  });
                }}
                updateAutomation={(auto) => {
                  actions.automationsPatch({
                    automations: evolve({
                      data: Rupdate(idx, auto)
                    }, automations),
                  });
                }}
                openMessage={(msg, actionIdx) => {
                  if (typeof msg?.msgId !== 'number') {
                    setMessage(msg as Message);
                  }
                  setselectedAutomationIdx({
                    autoIdx: idx,
                    actionIdx,
                  });
                  setIsMessageDialogOpen(true);
                }}
              />
            ))}
            {!isEmpty(automations.data) &&
            <div className={classes.pageControls}>
              <FormControl style={{ width: '100px' }}>
                <InputLabel id="limit-label">Items per Page</InputLabel>
                <Select
                  labelId="limit-label"
                  id="limit-search"
                  value={automations.perPage}
                  onChange={onPerPageChange}
                >
                  {options.map(opt =>
                    <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                  )}
                </Select>
              </FormControl>
              {busy && <Loading loading vh={45} />}
              <div className={classes.pageArrows}>
                <div style={{ marginRight: '5px' }}>
                  {outputLocation(
                    automations.page,
                    automations.perPage,
                    automations.totalCount,
                    automations.data ? automations.data.length : 0,
                  )}
                </div>
                <IconButton
                  disabled={automations.page === 1}
                  onClick={addPage(-1)} >
                  <ChevronLeftIcon />
                </IconButton>
                <IconButton
                  disabled={automations.page === automations.totalPages}
                  onClick={addPage(1)} >
                  <ChevronRightIcon />
                </IconButton>
              </div>
            </div>}
          </div>
        </div>
        <Menu
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          id="long-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(null)}>
          <MenuItem onClick={() => {
            setMenuAnchorEl(null);
            actions.newAutomation();
          }}>
            <ListItemIcon>
              <AddIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary='Create New' />
          </MenuItem>
          <MenuItem onClick={() => {
            setMenuAnchorEl(null);
            setOpenTemplateDialog(true);
          }}>
            <ListItemIcon>
              <FileCopyIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary='From Template' />
          </MenuItem>
        </Menu>
      </div>
    </>
  );
};

export default Automations;
