import React from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton, Menu, MenuItem } from '@mui/material';
import
KeyboardDoubleArrowRight
  from '@mui/icons-material/KeyboardDoubleArrowRight';
import
KeyboardDoubleArrowLeft
  from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { range } from 'ramda';
import { LocalDate } from '@js-joda/core';
import { format } from '../services/joda.js';

export const useStyles = makeStyles(() => ({
  year: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  yearText: {
    fontSize: 18,
    margin: '0px 5px',
    cursor: 'pointer',
    borderRadius: 5,
    backgroundColor: 'white',
    padding: '5px 10px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto',
    columnGap: 5,
  },
  month: {
    padding: '5px 10px',
    backgroundColor: 'white',
    borderRadius: 5,
    cursor: 'pointer',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
  },
}));

interface MonthPickerProps {
  value: string;
  onChange: (s: string) => void;
}

const months = [
  { value: '01', label: 'Jan' }, { value: '02', label: 'Feb' },
  { value: '03', label: 'Mar' }, { value: '04', label: 'Apr' },
  { value: '05', label: 'May' }, { value: '06', label: 'Jun' },
  { value: '07', label: 'Jul' }, { value: '08', label: 'Aug' },
  { value: '09', label: 'Sep' }, { value: '10', label: 'Oct' },
  { value: '11', label: 'Nov' }, { value: '12', label: 'Dec' },
];

const nYear = Number(format(LocalDate.now(), 'yyyy'));
const years = range(nYear - 10, nYear).concat(range(nYear, nYear + 10));

const day = '01';
const MonthPicker = ({ value, onChange }: MonthPickerProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [year, month] = React.useMemo(() => {
    return value.split('-');
  }, [value]);

  const handleChange = (m: string) => {
    onChange(`${year}-${m}-${day}`);
  };

  const handleYearChange = (y: number) => {
    setAnchorEl(null);
    onChange(`${y}-${month}-${day}`);
  };

  const addYear = (num: number) => {
    const newyear = Number(year) + num;
    onChange(`${newyear}-${month}-${day}`);
  };

  return (
    <div>
      <div className={classes.year}>
        <IconButton onClick={() => addYear(-1)}>
          <KeyboardDoubleArrowLeft style={{ fontSize: 16 }}/>
        </IconButton>
        <div
          onClick={(e) => setAnchorEl(e.currentTarget)}
          className={classes.yearText}>
          {year}
        </div>
        <IconButton onClick={() => addYear(1)}>
          <KeyboardDoubleArrowRight style={{ fontSize: 16 }} />
        </IconButton>
      </div>
      <div className={classes.grid}>
        {months.map((m) => {
          return (
            <div
              className={classes.month}
              onClick={() => handleChange(m.value)}
              style={{
                backgroundColor: month === m.value ?
                  '#d6f3d5' : undefined,
              }}>
              {m.label}
            </div>
          );
        })}
      </div>
      <Menu
        style={{ width: 'auto' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
        transformOrigin={{ horizontal: 'center', vertical: 'center' }}
        id="swap-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        {years.map((y) => {
          return (
            <MenuItem
              style={String(y) === year ? {
                backgroundColor: '#d6f3d5',
              } : {}}
              onClick={() => handleYearChange(y)}>
              {y}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default MonthPicker;
