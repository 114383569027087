import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@mui/styles';
import {
  TextField, Button, Paper, CircularProgress, Snackbar, MenuItem,
  Alert,
} from '@mui/material';
import api, { skedApi } from '../../../../services/api.js';
import {
  merge, path, pathOr, includes, isEmpty, pipe, values, any,
} from 'ramda';
import { PageHeader } from '../../../../components/PageHeader';
import ConfirmationDialog from '../../../../components/ConfirmationDialog/ConfirmationDialog.component';
import Header from '../../../../components/PageHeader/PageHeader.component';
import { usePromise } from '../../../../services/promise.hook';
import { countries } from '../../../../services/globals.js';
import { LOGIN_PATCH } from '../../../../actionTypes';
import { PopupTemplate } from '../../../../services/Popup.js';
import { useSelector } from '../../../../reducers';

const config = {
  headers: {
    'Authorization': skedApi.defaults.headers.common.Authorization,
  },
};

const getSMS = () => api.get('office/sms-number');

const getTCR = () => api.get('tcr');
const getTax = () => api.get('tcr/tax').catch(() => {
  return {
    companyName: '',
    country: 'US',
    street: '',
    city: '',
    state: '',
    zipcode: '',
    ein: '',
  };
});
const buildBrand = () => api.post('tcr/build/brand', {});
const buildCampaign = (data) => api.post('tcr/build/campaign', data);

const getAddress = (officeId) => api.get(`office/${officeId}/address`);
const updateCampaign = (data) => axios.put(`${process.env.API_URL}/tcr/update/campaign`, data, config);
const deleteCampaign = (officeId) => axios.delete(`${process.env.API_URL}/tcr/delete/campaign/${officeId}`, config);

const addNumberToCampaign = (officeId) => skedApi({
  method: 'POST',
  url: 'office/sms-number/campaign',
  data: { officeId },
  headers: {
    'X-As-Office': null
  }
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',
    paddingBottom: 30,
    overflowY: 'auto',
  },
  formControl: {
    marginTop: 8,
    marginBottom: 8
  },
  paperRoot: {
    padding: '40px',
    marginBottom: '10px',
  },
  buttonLabel: {
    textTransform: 'none',
    fontWeight: 'normal'
  },
  error: {
    color: theme.palette.error.main,
    marginTop: '20px'
  },
  taxInformationHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
// TODO: ASK TO YANNICK, GET BRAND ID, AND AFTER UPDATE CampaignID the get /tcr is returning 500

const SMSNumber = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const office = useSelector(path(['login', 'office']));
  const isAdmin = useSelector(path(['login', 'admin']));
  const smsState = usePromise(getSMS, {});
  const tcrState = usePromise(getTCR, {});
  const taxState = usePromise(getTax, {});
  const addressState = usePromise(getAddress, {});
  const brandState = usePromise(buildBrand, {});
  const campaignState = usePromise(buildCampaign, {});
  const updateState = usePromise(updateCampaign, {});
  const deleteState = usePromise(deleteCampaign, {});
  const numberState = usePromise(addNumberToCampaign, {});


  const [state, setState] = useState({
    loading: false,
    data: '',
    errorMessage: '',
    showSnackBar: false,
    snackbarMessage: '',
    campaignInfo: null // for showing success message
  });
  const [iscreate, setIscreate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const update = data => setState(s => merge(s, data));
  const updateTax = data => taxState.setState({ data: merge(taxState.data, data) });
  const [brandId, setBrandId] = useState('');
  const [campaignId, setCampaignId] = useState('');

  const handleClose = () => {
    update({ showSnackBar: false });
  };

  const getTcr = () => {
    tcrState.invoke().then(d => {
      update({ campaignInfo: d.campaignInfo });
      setCampaignId(d.campaignInfo?.campaignId || '');
      if (d.campaignInfo?.brandId) {
        setBrandId(d.campaignInfo?.brandId);
      } else {
        setBrandId(d.brandId);
      }
    });
  };

  useEffect(() => {
    smsState.invoke();
    taxState.invoke();
    getTcr();
  }, [office]);

  const onSubmitHandler = e => {
    e.preventDefault();
    update({ loading: true, errorMessage: '' });
    skedApi({
      method: 'PUT',
      url: 'office/sms-number',
      data: { phone: smsState.data.number, officeId: office.id },
      headers: {
        'X-As-Office': null
      }
    }).then(() => {
      update({ loading: false, showSnackBar: true, snackbarMessage: 'Successfully saved number.' });
    }).catch(e => {
      update({ loading: false, errorMessage: e.message });
    });
  };

  const onSubmitTaxHandler = e => {
    e.preventDefault();
    update({ loading: true, errorMessage: '' });
    return api.post('tcr/tax', taxState.data).then((tax) => {
      update({ loading: false, showSnackBar: true, snackbarMessage: 'Successfully saved tax information.' });
      dispatch({
        type: LOGIN_PATCH,
        data: {
          tax,
        },
      });
      setTimeout(() => {
        history.goBack();
      }, 1000);
    }).catch(e => {
      update({ loading: false, errorMessage: e.message });
    });
  };

  const invokeAddress = () => {
    addressState.invoke(office.id).then((add) => {
      const address = {
        ...add,
        zipcode: add.zipCode,
      };
      updateTax({ ...address, companyName: office.name });
    });
  };

  const einError = !isEmpty(pathOr('', ['data', 'ein'], taxState)) &&
    pathOr('', ['data', 'ein'], taxState).length < 10 ||
    pathOr('', ['data', 'ein'], taxState).length > 10;

  return (
    <>
      <Header title='SMS Legal Settings' />
      <div className={classes.root} >
        <PopupTemplate />
        {isAdmin &&
          <Paper className={classes.paperRoot}>
            <PageHeader align="left" variant="h5" style={{ marginBottom: '15px' }}>
              SMS Number
            </PageHeader>
            <form onSubmit={onSubmitHandler}>
              <div className={classes.formControl}>
                <TextField
                  fullWidth
                  id="sms-number"
                  label="SMS Number"
                  type="text"
                  helperText="The number that Bandwidth will send from"
                  value={pathOr('', ['data', 'number'], smsState)}
                  onChange={e => {
                    const number = e.target.value;
                    smsState.setState({ data: { number } });
                  }}
                />
              </div>
              <Button
                variant="contained"
                type="submit"
                classes={{ label: classes.buttonLabel }}
                disabled={smsState.loading}
                color="primary">
                {smsState.loading || state.loading ? 'Loading...' : 'Save'}
              </Button>
            </form>
            {smsState.loading || state.loading && <CircularProgress />}
            {smsState.errorMessage && <p className={classes.error}>{smsState.errorMessage}</p>}
            {state.errorMessage && <p className={classes.error}>{state.errorMessage}</p>}
          </Paper>}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={state.showSnackBar}
          autoHideDuration={5e3}
          onClose={handleClose}
        >
          <div>
            <Alert
              style={{ fontSize: '16px' }}
              severity="success">
              {state.snackbarMessage}
            </Alert>
          </div>
        </Snackbar>
        <Paper className={classes.paperRoot}>
          <div className={classes.taxInformationHeader}>
            <PageHeader align="left" variant="h5" style={{ marginBottom: '15px' }}>
              Tax Information
            </PageHeader>
            <Button
              variant="contained"
              classes={{ label: classes.buttonLabel }}
              disabled={addressState.loading}
              color="primary"
              onClick={() => invokeAddress()}>
              {addressState.loading ? 'Loading...' : 'Auto-fill with office address'}
            </Button>
          </div>
          <p style={{ width: '75%' }}>
            <i>
              Due to updated SMS Messaging compliance regulations, SKED is now required to submit your office’s tax information to ensure deliverability. Please enter in your Legal Company Name, Tax Address (the address you are using for tax purposes), and EIN to ensure SMS messages are delivered successfully.
            </i>
          </p>
          <form onSubmit={onSubmitTaxHandler}>
            <div className={classes.formControl}>
              <TextField
                fullWidth
                id="company-name"
                label="Legal Company Name"
                type="text"
                helperText="The name of the office for tax purposes."
                value={pathOr('', ['data', 'companyName'], taxState)}
                onChange={e => updateTax({ companyName: e.target.value })}
              />
            </div>
            <div className={classes.formControl}>
              <TextField
                fullWidth
                select
                id="country"
                label="Country"
                type="text"
                value={pathOr('', ['data', 'country'], taxState)}
                onChange={e => updateTax({ country: e.target.value })}
                helperText=""
              >
                {countries.map((country) => {
                  return (
                    <MenuItem key={country.value} value={country.value}>
                      {country.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
            <div className={classes.formControl}>
              <TextField
                fullWidth
                id="address"
                label="Street Address"
                type="text"
                helperText="The address of your office for tax purposes."
                value={pathOr('', ['data', 'street'], taxState)}
                onChange={e => updateTax({ street: e.target.value })}
              />
            </div>
            <div className={classes.formControl}>
              <TextField
                fullWidth
                id="city"
                label="City"
                type="text"
                value={pathOr('', ['data', 'city'], taxState)}
                helperText=''
                onChange={e => updateTax({ city: e.target.value })}
              />
            </div>
            <div className={classes.formControl}>
              <TextField
                fullWidth
                id="state"
                label="State"
                type="text"
                value={pathOr('', ['data', 'state'], taxState)}
                helperText='Must be the 2 letter abbreviation'
                error={pathOr('', ['data', 'state'], taxState).length > 2}
                onChange={e => updateTax({ state: e.target.value })}
              />
            </div>
            <div className={classes.formControl}>
              <TextField
                fullWidth
                id="zip"
                label="Zip Code"
                type="text"
                value={pathOr('', ['data', 'zipcode'], taxState)}
                helperText=''
                onChange={e => updateTax({ zipcode: e.target.value })}
              />
            </div>
            <div className={classes.formControl}>
              <TextField
                fullWidth
                id="ein"
                label="EIN"
                type="text"
                helperText={einError ? 'Invalid EIN' : 'Employer Identification Number'}
                placeholder='xx-xxxxxxx'
                value={pathOr('', ['data', 'ein'], taxState)}
                error={einError}
                onChange={e => {
                  const value = e.target.value;
                  if (value.length === 2 && pathOr('', ['data', 'ein'], taxState).length === 1) {
                    updateTax({ ein: value + '-' });
                  } else {
                    updateTax({ ein: value });
                  }
                }}
              />
            </div>
            <Button
              variant="contained"
              type="submit"
              classes={{ label: classes.buttonLabel }}
              disabled={taxState.loading || isEmpty(taxState.data) || pipe(values, any(isEmpty))(taxState.data)}
              color="primary">
              {taxState.loading ? 'Loading...' : 'Save'}
            </Button>
          </form>
          {taxState.loading && <CircularProgress />}
          {taxState.errorMessage && !includes('404', taxState.errorMessage) &&
            <p className={classes.error}>{taxState.errorMessage}</p>}
          {state.errorMessage && <p className={classes.error}>{state.errorMessage}</p>}
        </Paper>
        <ConfirmationDialog
          title="Delete Campaign"
          text="Are you sure you want to delete this Campaign ID?"
          confirmText="Delete"
          cancel={() => setOpenDelete(false)}
          confirm={() => {
            setOpenDelete(false);
            deleteState.invoke(office.id).then(() => {
              getTcr();
              update({ loading: false, showSnackBar: true, snackbarMessage: 'Successfully deleted Campaign ID.' });
            });
          }}
          open={openDelete}
          showCancel
        />
        {isAdmin &&
          <Paper className={classes.paperRoot}>
            <PageHeader align="left" variant="h5" style={{ marginBottom: '15px' }}>
              Brand & Campaign
            </PageHeader>
            {((tcrState.data.hasBrand && tcrState.data?.brandId) || iscreate) && (
              <div>
                <div className={classes.formControl}>
                  <TextField
                    fullWidth
                    label="Brand ID"
                    type="text"
                    value={brandId}
                    helperText=''
                    onChange={e => setBrandId(e.target.value)}
                  />
                </div>
                <div className={classes.formControl}>
                  <TextField
                    fullWidth
                    label="Campaign ID"
                    type="text"
                    value={campaignId}
                    helperText=''
                    onChange={e => setCampaignId(e.target.value)}
                  />
                </div>
                <div>
                  <Button
                    variant="contained"
                    type="button"
                    classes={{ label: classes.buttonLabel }}
                    disabled={!brandId}
                    color="primary"
                    onClick={() => updateState.invoke({ officeId: office.id, campaignId: campaignId ? campaignId : undefined, brandId: brandId ? brandId : undefined })
                      .then(() => {
                        setIscreate(false);
                        getTcr();
                        update({ loading: false, showSnackBar: true, snackbarMessage: 'Successfully saved brand ID and campaign ID.' });
                      })}
                  >
                    {updateState.loading ? 'Loading...' : 'Save'}
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    variant={iscreate ? 'outlined' : 'contained'}
                    type="button"
                    classes={{ label: classes.buttonLabel }}
                    disabled={deleteState.loading}
                    color="error"
                    onClick={() => iscreate ? setIscreate(false) : setOpenDelete(true)}
                  >
                    {deleteState.loading ? 'Loading...' : `${iscreate ? 'Cancel' : 'Delete campaign'}`}
                  </Button>
                </div>
                {updateState.errorMessage && <p className={classes.error}>{updateState.errorMessage}</p>}
                {deleteState.errorMessage && <p className={classes.error}>{deleteState.errorMessage}</p>}
              </div>
            )}
            <br />
            <div style={{
              display: 'flex',
            }}>
              <br />
              {!iscreate && (
                <>
                  <div style={{ marginRight: '10px' }}>
                    <Button
                      variant="contained"
                      type="submit"
                      classes={{ label: classes.buttonLabel }}
                      disabled={brandState.loading || pathOr(false, ['data', 'hasBrand'])(tcrState)}
                      color="primary"
                      onClick={() => brandState.invoke().then(() => getTcr())}
                    >
                      {brandState.loading ? 'Loading...' : 'Create brand'}
                    </Button>
                    <br />
                    {pathOr(false, ['data', 'hasBrand'])(tcrState) && <p>✅ Brand Created!</p>}
                    {brandState.loading && <CircularProgress />}
                    {brandState.errorMessage && <p className={classes.error}>{brandState.errorMessage}</p>}
                  </div>
                  &nbsp;&nbsp;
                  {!tcrState.data.hasBrand && (
                    <div style={{ marginRight: '10px' }}>
                      <Button
                        variant="contained"
                        type="submit"
                        classes={{ label: classes.buttonLabel }}
                        disabled={brandState.loading || pathOr(false, ['data', 'hasBrand'])(tcrState)}
                        color="primary"
                        onClick={() => setIscreate(true)}
                      >
                        {brandState.loading ? 'Loading...' : 'Update campaign'}
                      </Button>
                      <br />
                    </div>
                  )}
                </>
              )}
              {tcrState.data.hasBrand &&
                <div style={{ display: 'flex', marginRight: '10px' }}>
                  <div style={{ marginRight: '10px' }}>
                    <Button
                      variant="contained"
                      type="submit"
                      classes={{ label: classes.buttonLabel }}
                      disabled={
                        campaignState.loading
                        ||
                        'None' === pathOr('None', ['data', 'campaignInfo', 'usecase'], tcrState)
                        ||
                        (state.campaignInfo && state.campaignInfo.usecase !== 'None')
                      }
                      color="primary"
                      onClick={() => campaignState.invoke({
                        useCase: pathOr('', ['data', 'campaignInfo', 'usecase'], tcrState)
                      }).then(() => getTcr()).catch(() => getTcr())}
                    >
                      {campaignState.loading ? 'Loading...' : 'Create campaign'}
                    </Button>
                    <br />
                    {state.campaignInfo && state.campaignInfo.usecase !== 'None' &&
                      <p>✅ Campaign Created!</p>}
                    {campaignState.loading && <CircularProgress />}
                    {campaignState.errorMessage && <p className={classes.error}>{campaignState.errorMessage}</p>}
                  </div>
                  &nbsp;&nbsp;
                  <TextField
                    select
                    id="campaign"
                    label="Campaign"
                    type="text"
                    value={pathOr('None', ['data', 'campaignInfo', 'usecase'], tcrState)}
                    disabled={state.campaignInfo && state.campaignInfo.usecase !== 'None'}
                    style={{ width: '145px', marginTop: -7 }}
                    onChange={(e) => {
                      const newUseCase = { campaignInfo: { usecase: e.target.value } };
                      tcrState.setState({ data: merge(tcrState.data, newUseCase) });
                    }}>
                    {['None', 'LowMixed', 'Mixed'].map((c) => {
                      return (
                        <MenuItem key={c} value={c}>
                          {c}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              }
              {state.campaignInfo && state.campaignInfo.usecase !== 'None' &&
                <div style={{
                  marginRight: '10px',
                  marginBottom: numberState.errorMessage ? 'unset' : '30px',
                }}>
                  <Button
                    variant="contained"
                    type="submit"
                    classes={{ label: classes.buttonLabel }}
                    disabled={numberState.loading}
                    color="primary"
                    onClick={() => numberState.invoke(office.id).then(() => {
                      update({
                        loading: false,
                        showSnackBar: true,
                        snackbarMessage: 'Number added to campaign!'
                      });
                    }).catch(e => {
                      update({
                        loading: false,
                        errorMessage: e.message
                      });
                    })}
                  >
                    {numberState.loading ? 'Loading...' : 'Add bandwidth number to campaign'}
                  </Button>
                  <br />
                  {numberState.loading && <CircularProgress />}
                  {numberState.errorMessage && <p className={classes.error}>{numberState.errorMessage}</p>}
                </div>
              }
            </div>
          </Paper>}
      </div>
    </>
  );
};

export default SMSNumber;
