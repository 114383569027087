import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
  isEmpty, pipe, ifElse, has, identity, assoc, map,
  prop, propOr, dissoc, pathOr, includes, filter,
} from 'ramda';
import { usePromise } from '../../../services/promise.hook';
import api from '../../../services/api.js';
import {
  MessageComposer,
  clientPlaceholders,
  officePlaceholders,
  removePlaceholders,
} from '../../../components/create-message.component';
import OneLineEditor from '../../../components/Editor/components/OneLineEditor.component';
import { loadingMessage } from '../../RecurringMessage/routes/RecurringEdit/recurring-edit.actions';
import { EditMessage, EmailEditHTML, EmailEditPlainText, Message } from '../../ClientLists/clientlist-types';

export const makeMessage = (data: EditMessage) => {
  const {
    email,
    sms,
    push,
    name,
  } = data;
  const validEmail = email && ((email.subject !== '' && (email as EmailEditPlainText).body !== '') ||
    (email.subject !== '' && (email as EmailEditHTML).html !== ''))
    ?
    pipe(
      ifElse(
        has('attachmentIds'),
        identity,
        assoc('attachmentIds', map(prop('attachmentId'), propOr([], 'attachments', email))),
      ),
      dissoc('attachments')
    )(email)
    :
    null;
  const validSms = sms && sms.body !== ''
    ?
    pipe(
      ifElse(
        has('attachmentIds'),
        identity,
        assoc('attachmentIds', map(prop('attachmentId'), propOr([], 'attachments', sms))),
      ),
      dissoc('attachments')
    )(sms)
    :
    null;
  const validPush = push && (push.subject !== '' && push.body !== '')
    ?
    pipe(
      ifElse(
        has('attachmentIds'),
        identity,
        assoc('attachmentIds', map(prop('attachmentId'), propOr([], 'attachments', push))),
      ),
      dissoc('attachments')
    )(push)
    :
    null;
  return ({
    isDeleted: false,
    isTemplate: false,
    messageType: {
      Automation: [] as string[],
    },
    name,
    isEnabled: true,
    email: validEmail,
    sms: validSms,
    push: validPush,
  });
};

const postOrPutMessage = (msg: { id: number }) => {
  if (msg.id) {
    return api.put(`message/${msg.id}`, msg);
  } else {
    return api.post('message', msg);
  }
};

type MessageDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  message: Message;
  onSave: (m: Message) => void;
}

export default function MessageDialog({
  isOpen,
  onClose,
  message,
  onSave,
}: MessageDialogProps) {
  const [busy, setBusy] = useState(false);
  const [state, setState] = useState<EditMessage>({
    name: '',
    email: {
      subject: '',
      html: null,
      body: '',
    },
    sms: {
      body: '',
    },
    subject: {
      subject: '',
      body: '',
    }
  } as unknown as EditMessage);
  const [_SMSError, setSMSError] = React.useState(false);

  useEffect(() => {
    if (message.msgId) {
      setBusy(true);
      api.get(`message/${message.msgId}`).then((msg) => {
        loadingMessage(msg, 'edit').then((m) => {
          setBusy(false);
          setState({
            ...m,
            name: m.messageName,
          } as unknown as EditMessage);
        });
      });
    }
  }, [isOpen]);

  const {
    features,
  } = useSelector((state) => ({
    features: pathOr([], ['login', 'features'])(state),
  }));

  const saveMessageState = usePromise(postOrPutMessage, {});

  const saveMessage = () => {
    const msg = {
      ...makeMessage(state),
      id: message && message.msgId,
    };
    saveMessageState.invoke(msg).then((m) => {
      onSave(m);
      onClose();
    });
  };

  const defaultEmail = {
    subject: '',
    body: '',
    html: includes('HtmlEmail', features) ? '' : null,
    shouldFrame: true,
    attachments: [] as number[],
  };
  const defaultSms = {
    body: '',
    attachments: [] as number[],
  };
  const defaultPush = {
    subject: '',
    body: '',
    attachments: [] as number[],
  };
  const clientHolders =
    filter((holder) => removePlaceholders(features, holder))(clientPlaceholders);
  const officeHolders =
    filter((holder) => removePlaceholders(features, holder))(officePlaceholders);
  const placeholders = [
    { title: 'Client', placeholders: clientHolders },
    { title: 'Office', placeholders: officeHolders },
    // (includes('Appointment', trigger))
    //   &&
    //   {title: 'Appointment', placeholders: apptPlaceholders}
  ];
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>
        {isEmpty(message) ? 'Create Automation Message' : 'Edit Automation Message'}
      </DialogTitle>
      <DialogContent dividers>
        {busy && <CircularProgress />}
        <h4>Message Name</h4>
        <OneLineEditor
          title='Message Name'
          name={state.name}
          messagesPatch={(d) => setState({ ...state, name: d })}
          required
        />

        <h4>Compose Message</h4>
        <MessageComposer
          features={features}
          isSMS={Boolean(pathOr(false, ['sms', 'body'], state))}
          isEmail={Boolean(pathOr(false, ['email', 'subject'], state))}
          isPush={Boolean(pathOr(false, ['push', 'body'], state))}
          email={propOr(defaultEmail, 'email', state)}
          sms={propOr(defaultSms, 'sms', state)}
          push={propOr(defaultPush, 'push', state)}
          actions={{
            messageMassPatch: (data) => {
              setState({ ...state, ...data } as EditMessage);
            },
            messagesPatch: (prop, data) => {
              setState({ ...state, [prop]: data });
            }
          }}
          placeholders={placeholders}
          setSMSError={setSMSError}
        />
      </DialogContent>
      <DialogActions style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
        {saveMessageState.loading ?
          <CircularProgress />
          :
          <div>
            <Button
              variant="contained"
              onClick={saveMessage}
              style={{ marginRight: '10px' }}
            >
              Save
            </Button>
          </div>}
        <Button
          onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
