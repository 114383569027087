import React, { useEffect, useState, useRef } from 'react';

import {
  FormControl,
  IconButton,
  Popover,
  Grid,
  TextField,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import FilterHeader from '../FilterHeader/FilterHeader.component';
import HeaderButton from '../HeaderButton/HeaderButton.component';
import { useStyles } from './filterText.styles';

let searchTimeout: NodeJS.Timeout = null;

type FilterTextProps = {
  onChange: (value: string) => void;
  timeOut?: number;
  value?: string,
  title?: string;
  headerTitle?: string;
  placeholder?: string;
  helperText?: string;
  children?: Element;
  Icon?: ({ color, className }: { color?: string, className?: string }) => JSX.Element;
  onlyIcon?: boolean;
  noSearchIcon?: boolean;
  minWidth?: number;
  horizontalMenu?: 'right' | 'left' | 'center',
  noReset?: boolean;
  saveTitle?: string;
  onSave?: (value: string) => void;
  className?: string;
  saveClassName?: string;
  resetClassName?: string;
  inputClassName?: string;
}

const FilterText = ({
  value, onChange, title, horizontalMenu = 'right', headerTitle,
  placeholder, children, helperText, Icon, minWidth, onlyIcon,
  timeOut, noSearchIcon, noReset, saveTitle, onSave, className,
  saveClassName, resetClassName, inputClassName
}: FilterTextProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [localValue, setLocalValue] = useState(value || '');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnChange = (value: string) => {
    setLocalValue(value);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      if (onChange)
        onChange(value);
    }, timeOut || 0);
  };

  const handleSave = () => {
    if (onChange)
      onChange(localValue);
  };

  const handleReset = () => {
    if (onChange)
      onChange('');
    setLocalValue('');
  };

  useEffect(() => {
    if (anchorEl) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [anchorEl, inputRef]);

  useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  const save = () => {
    onSave(localValue);
    setAnchorEl(null);
    setLocalValue('');
  };

  return (
    <Grid>
      <Grid marginRight={0.5}>
        {title ? (
          <HeaderButton
            title={title || 'Appt. Confirmed'}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            Icon={Icon || SearchIcon}
            active={!!value?.length}
            type="filter"
            onlyIcon={onlyIcon}
            className={className}
          />
        ) : (
          <IconButton
            onClick={(event) => setAnchorEl(event.currentTarget)}
            className={`${classes.button} ${value?.length ? classes.active : ''} ${className}`}
          >
            <SearchIcon className={classes.icon} />
          </IconButton>

        )}
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: horizontalMenu,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: horizontalMenu,
        }}
      >
        <Grid minWidth={minWidth || 280}>
          <FilterHeader
            title={headerTitle || title || 'Search'}
            reset={handleReset}
            resetDisabled={!localValue.length}
            saveClassName={saveClassName}
            resetClassName={resetClassName}
            noReset={noReset}
            saveTitle={saveTitle}
            save={onSave && save}
          />
          <Grid padding={2}>
            <FormControl variant="outlined" className={classes.formControl}>
              {children ? children : (
                <TextField
                  value={localValue}
                  inputRef={inputRef}
                  onChange={event => handleOnChange(event.target.value)}
                  size='small'
                  helperText={helperText}
                  variant='outlined'
                  placeholder={placeholder}
                  className={classes.input}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      handleSave();
                    }
                  }}
                  InputProps={{
                    className: inputClassName,
                    startAdornment: noSearchIcon ? null : (
                      <InputAdornment position="start">
                        <SearchIcon style={{ fontSize: 16 }} />
                      </InputAdornment>
                    ),
                    endAdornment: localValue.length ? <InputAdornment position='end'>
                      <IconButton className={classes.closeButton} onClick={handleReset}>
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment> : null,
                  }}
                />
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
};

export default FilterText;
