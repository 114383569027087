import React from 'react';
import { match as Match } from 'react-router-dom';
import ViewMessage from '../../components/AutomationBasedMessage/view-message.component';
import { includes } from 'ramda';
import { History } from 'history';

interface MatchParams {
  id?: string;
}

interface Props {
  match: Match<MatchParams>;
  history: History;
}

const ViewMessagePage = ({
  match, history
}: Props) => {
  const msgId = match?.params?.id ? Number(match.params.id) : 0;
  const type = includes('time-based', match.path) ? 'time-based' : 'smart-reply';

  return (
    <ViewMessage
      messageId={msgId}
      history={history}
      messageType={'AutomationBaseMessage'}
      type={type}
    />
  );
};

export default ViewMessagePage;
