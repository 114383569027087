import React, { useState } from 'react';

import { Button, Grid, Popover } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { useStyles } from './modalTabs.styles';

type Item = {
  value: string;
  label: string;
  className?: string;
  subTabs?: Props;
}

type Props = {
  tabs: Item[];
  value: string;
  setTab: (value: string) => void;
}

const ModalTabs = ({ tabs = [], value, setTab }: Props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const selected = tabs.find(item => item?.value === value) || {} as Item;

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleSelect = (tab: Item) => {
    setTab(tab.value);
    setAnchorEl(null);
  };

  return (
    <Grid className={classes.root}>
      <Button
        onClick={(event) => setAnchorEl(event.currentTarget)}
        variant={'outlined'}
        className={classes.customButtom}
        endIcon={<KeyboardArrowDownIcon />}
        color="primary"
      >
        {selected.label}
      </Button>
      {!!selected.subTabs?.tabs.length && (
        <>
          <Grid marginX={0.5} display="flex" alignItems="center">
            <KeyboardArrowRightIcon style={{ fontSize: 20 }} />
          </Grid>
          <ModalTabs tabs={selected.subTabs.tabs} value={selected.subTabs.value} setTab={selected.subTabs.setTab} />
        </>
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid className={classes.grid} display="flex" alignItems="center" maxWidth={550} flexWrap="wrap">
          {tabs.map((tab) => {
            if (tab) {
              return (
                <Grid key={tab.value} className={classes.item} onClick={() => handleSelect(tab)}>
                  {tab.label}
                </Grid>
              );
            }
            return null;
          })}
        </Grid>
      </Popover>
    </Grid>
  );
};

export default ModalTabs;
