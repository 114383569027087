import React from 'react';
import { DragLayer } from 'react-dnd';
import { ItemTypes } from './constants.jsx';
import TypeDrag from './type-drag.component';

type FieldDragLayerProps = {
  item: object;
  itemType: string;
  initialOffset: {
    x: number;
    y: number;
  };
  currentOffset: {
    x: number;
    y: number;
  };
  isDragging: boolean;
};

const getFieldStyle = (isDragging: boolean) => ({
  width: 300,
  maxWidth: 300,
  opacity: isDragging ? 0.8 : 1,
});

const getItemStyles = (props: FieldDragLayerProps) => {
  const { currentOffset } = props;
  if (!currentOffset) {
    return {
      display: 'none',
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
};

const collect = (monitor: any) => ({
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
});

const FieldDragLayer = (props: FieldDragLayerProps) => {
  const { item, itemType, isDragging } = props;

  const renderItem = (type: string, item: any) => {
    switch (type) {
      case ItemTypes.TYPE:
        return (
          <div>
            {item.selecteds &&
              item.selecteds.map((type: any) => (
                <TypeDrag
                  key={type.id}
                  type={type}
                  selected
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  handleSeletion={() => { }}
                />
              ))}
          </div>
        );
      default:
        return null;
    }
  };

  if (!isDragging) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <div style={getItemStyles(props)}>
        <div style={getFieldStyle(props.isDragging)}>
          {renderItem(itemType, item)}
        </div>
      </div>
    </div>
  );
};

const dragLayer = DragLayer;
export default dragLayer(collect)(FieldDragLayer);
