import React, { useState, useEffect } from 'react';
import Modal from '../../Modal/Modal.component';
import HeaderButton from '../../HeaderButton/HeaderButton.component';
import { TextField } from '../../components.component';

interface LinkerProps {
  open: boolean;
  onClose: () => void;
  defaultUrl?: string;
  promptText: boolean;
  save: (url: string, text: string) => void;
}

const Linker = ({ open, onClose, save, defaultUrl = '', promptText }: LinkerProps) => {
  const [url, setUrl] = useState(defaultUrl);
  const [text, setText] = useState('');

  useEffect(() => {
    setUrl(defaultUrl);
  }, [defaultUrl]);

  const handleClose = () => {
    setUrl('');
    setText('');
    onClose();
  };

  const onSave = () => {
    save(url, text);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title='Insert Link'
      className='sked-test-html-editor-link-insert-modal'
      size='xs'
      buttons={[
        <HeaderButton
          onClick={onSave}
          title='Insert'
          color='primary'
          disabled={url.trim() === ''}
          className='sked-test-html-editor-link-insert-button-delete'
        />
      ]}
    >
      <div style={{ width: 400 }}>
        <TextField
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          fullWidth
          label='URL'
          placeholder='https://mywebsite.com/nice/webpage.html'
          InputLabelProps={{ shrink: true }}
        />
        {promptText &&
        <>
          <br/>
          <br/>
          <TextField
            value={text}
            onChange={(e) => setText(e.target.value)}
            fullWidth
            label='Text'
            placeholder='Click here!'
            InputLabelProps={{ shrink: true }}
          />
        </>}
      </div>
    </Modal>
  );
};

export default Linker;
