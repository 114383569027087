import { createSelector } from 'reselect';
import { propOr } from 'ramda';

const scheduleSelector = state => state.schedule;

export const busySelector = createSelector(
  scheduleSelector,
  propOr(false, 'busy')
);

export const professionalsSelector = createSelector(
  scheduleSelector,
  propOr([], 'professionals')
);

export const schedulesSelector = createSelector(
  scheduleSelector,
  propOr([], 'schedules')
);

export const originalSelector = createSelector(
  scheduleSelector,
  propOr([], 'original')
);

export const officeHoursSelector = createSelector(
  scheduleSelector,
  propOr({}, 'officeHours')
);

export const errorSelector = createSelector(
  scheduleSelector,
  propOr({}, 'error')
);

export const successSelector = createSelector(
  scheduleSelector,
  propOr(false, 'success')
);


export const hoursSelector = createSelector(
  scheduleSelector,
  propOr([], 'hours')
);

export const specialHoursSelector = createSelector(
  scheduleSelector,
  propOr([], 'specialHours')
);

export const editIdSelector = createSelector(
  scheduleSelector,
  propOr(0, 'editId')
);

export const editScheduleSelector = createSelector(
  schedulesSelector,
  editIdSelector,
  (schedules, editId) => schedules.find(({ id }) => editId === id)
);

export const editHoursSelector = createSelector(
  scheduleSelector,
  propOr(false, 'editHours')
);

export const editNumberOfAppointmentsSelector = createSelector(
  scheduleSelector,
  propOr(false, 'editNumberOfAppointments')
);

export const editSlotLengthSelector = createSelector(
  scheduleSelector,
  propOr('', 'editSlotLength')
);

export const snackbarTextSelector = createSelector(
  scheduleSelector,
  propOr('', 'snackbarText')
);

export const popupSelector = createSelector(
  scheduleSelector,
  propOr([], 'updatedNumberOfAppointments')
);
