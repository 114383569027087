import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid, Typography, CircularProgress, TableContainer,
  Table, TableHead, TableRow, TableCell, TableBody, Checkbox,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import { Client } from '../Clients/clients.types';
import { useTypeahead } from '../../services/typeahead.hook.js';
import api from '../../services/api.js';
import { SearchClients } from '../CalendarV2/components/scheduler.component';
import {
  makeNewSub, CreateSubmission, fetchFormList, makeNewOneTimeToken,
  getSettingsForm, saveSettings, GlobalSettings
} from './intake.service';
import { Form, Submission } from './ServerTypes';
import { usePromise } from '../../services/promise.hook';
import {
  pipe, prop, split, tail, join, omit, merge, __,
} from 'ramda';
import {
  DateTimeFormatter, LocalDate,
} from '@js-joda/core';
import { Locale } from '@js-joda/locale_en-us';
import { checkPinAndLock } from './lock.component';
import { patch } from '../Login/login.actions';
import { encryptKey } from '../../services/utilities';
import { PopupTemplate } from '../../services/Popup.js';
import Modal from '../../components/Modal/Modal.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';

const format = DateTimeFormatter.ofPattern('M/d/yyyy').withLocale(Locale.US);

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  table: {
    width: '100%',
    fontSize: '16px'
  }
}));

type FirstStep = 'searchClients' | 'findForm' | 'accept';

interface DialogProps {
  isOpen: boolean;
  close: () => void;
  firstStep?: FirstStep;
}

const SubmissionDialog = ({
  isOpen,
  close,
  firstStep = 'searchClients',
}: DialogProps) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [step, setStep] = useState(firstStep);
  const [client, setClient] = useState<Client>(undefined);
  const [clearSearch, setClearSearch] = useState(false);
  const [form, setForm] = useState(undefined);
  const [oneTimeToken, setOneTimeToken] = useState(null);
  const [locked, setLocked] = useState<string>(null);
  const [w, setWindow] = useState<Window>(null);
  const [subId, setSubId] = useState<number>(null);

  const formState = usePromise<void, Form[]>(fetchFormList, [] as Form[]);
  const newSubState = usePromise<CreateSubmission, Submission>(makeNewSub, null);
  const getSettingsState = usePromise<void, GlobalSettings>(getSettingsForm, null); const saveState = usePromise<GlobalSettings, GlobalSettings>(saveSettings, null);
  const newOneTimeState = usePromise<number, string>(makeNewOneTimeToken, null);

  const {
    state: clients,
    onChange,
    setState,
  } = useTypeahead((query: any) => {
    return Promise.resolve().then(() => {
      const birthday = query.birthday &&
        query.birthday.length >= 8 ?
        LocalDate.parse(query.birthday, format).toString() :
        undefined;
      const lastName = query.lastName.split(' ')[0];
      const firstName = pipe(
        prop('lastName'),
        split(' '),
        tail,
        join(' '),
        s => !s ? undefined : s
      )(query);
      const q = pipe(
        q => !q.phone ? omit(['phone'], q) : q,
        merge(__, {
          birthday,
          lastName,
          firstName
        })
      )(query);
      return api.post(
        'client/query',
        {
          page: 1,
          perPage: 25,
          query: {
            ...q,
            isLead: false,
            sortBy: [{
              direction: 'Asc',
              field: 'LastName'
            }, {
              direction: 'Asc',
              field: 'FirstName'
            }]
          }
        }
      ).then(result => {
        return Promise.resolve(result);
      });
    });
  });

  const noNext = React.useMemo(() => {
    if (step === 'searchClients') {
      return client === undefined;
    } else {
      return form === undefined;
    }
  }, [step, client, form]);

  React.useEffect(() => {
    if (step === 'findForm') {
      formState.invoke();
      getSettingsState.invoke();
    }
  }, [step]);

  React.useEffect(() => {
    if (!isOpen) {
      setStep(firstStep);
      setClient(undefined);
      setClearSearch(false);
      setForm(undefined);
      setOneTimeToken(null);
      setLocked(null);
      setWindow(null);
      setState((s) => ({
        ...s,
        data: undefined,
      }));
    }
  }, [isOpen]);

  const nextPage = () => {
    if (step === 'searchClients')
      setStep('findForm');
    else {
      newSubState.invoke({ formId: form, clientId: client.id }).then((sub) => {
        setSubId(sub.subId);
        checkPinAndLock({
          subId: sub.subId,
          openWindow: true,
          saveState,
          newOneTimeState,
          setOneTimeToken,
          setLocked,
          w,
          setWindow,
          getSettingsState,
        });
      });
    }
  };

  const previousPage = () => {
    if (step === 'findForm')
      setStep('searchClients');
  };

  useEffect(() => {
    if (locked) {
      encryptKey(locked);
      dispatch(patch({ locked: true }));
    }
  }, [locked]);

  useEffect(() => {
    if (oneTimeToken) {
      const url = `${process.env.PORTAL_URL}/intake-office?token=${oneTimeToken}&id=${subId}`;
      w.location = url;
      w.focus();
    }
  }, [oneTimeToken]);

  return (
    <Modal
      open={isOpen}
      onClose={() => close()}
      title='New Submission'
      className='sked-test-forms-submissions-add-modal'
      buttons={[
        step !== 'searchClients' && 
          <HeaderButton
            borderSolid
            onClick={previousPage}
            title="Back"
            className='sked-test-forms-submissions-add-modal-button-back'
          />,
        <HeaderButton
          disabled={noNext}
          color='primary'
          Icon={step === 'findForm' ? OpenInNewIcon : undefined}
          iconStyle={{ marginRight: -4, color: '#FFF' }}
          onClick={nextPage}
          title={step === 'findForm' ? 'Open' : 'Next'}
          className='sked-test-forms-submissions-add-modal-button-next'
        />
      ]}
    >
      <div>
        <PopupTemplate />
        {step === 'searchClients' &&
          <SearchClients
            ehrSystem={'Platinum'} // disable the add client
            setStep={setStep}
            classes={classes}
            selectClients={(clis: Client[]) => setClient(clis[0])}
            selectClient={setClient}
            onChange={onChange}
            setClients={(clis: any) => console.log(clis)}
            clients={clients}
            shouldClearSearch={clearSearch}
            setClearSearch={setClearSearch}
            isSelectOne
            isInDialog
          />}
        {step === 'findForm' &&
          <Grid container spacing={1}>
            <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography component="h5" style={{ marginTop: '0', marginRight: '10px' }}>
                Search Forms
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {formState.loading && <CircularProgress />}
              {!formState.loading &&
              <Grid marginX={-2}>
                <TableContainer>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">&nbsp;</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Description</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {formState.data.map(({ name, description, id }: Form) => (
                        <TableRow key={id}>
                          <TableCell>
                            <Checkbox
                              checked={id === form}
                              onChange={() => setForm(id)}
                              icon={<RadioButtonUnchecked />}
                              checkedIcon={<RadioButtonChecked />}
                            />
                          </TableCell>
                          <TableCell>{name}</TableCell>
                          <TableCell>{description}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>}
            </Grid>
          </Grid>}
      </div>
    </Modal>
  );
};

export default SubmissionDialog;
