import React from 'react';
import { TextField } from '@mui/material';
import { popupWithCustoms } from '../../services/Popup.js';
import { GlobalSettings } from './intake.service';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';

interface CheckPinProps {
  subId: number,
  openWindow: boolean,
  saveState: any,
  newOneTimeState: any,
  setOneTimeToken: (s: string) => void,
  setLocked: (s: string) => void,
  w: Window,
  setWindow: (w: Window) => void,
  getSettingsState: any,
}

export const checkPinAndLock = ({
  subId,
  openWindow,
  saveState,
  newOneTimeState,
  setOneTimeToken,
  setLocked,
  getSettingsState,
  setWindow,
}: CheckPinProps) => {
  if (getSettingsState.data && getSettingsState.data.pin) {
    if (openWindow) {
      const win = window.open('about:blank');
      win.focus();
      setWindow(win);
    }
    newOneTimeState.invoke(subId).then((token: string) => {
      setOneTimeToken(token);
      setLocked(getSettingsState.data.pin);
    });
  } else {
    popupWithCustoms({
      title: 'Create an Admin PIN',
      className: 'sked-test-create-admin-pin-modal',
      customContent: (t: any) => {
        const {
          setState,
          state,
        } = t;
        const error = state?.pin?.length < 4 || state?.pin?.length > 8;
        return (
          <div>
            When allowing a patient to fill out a form in the office, the Admin will become locked to prevent them from gaining access to your appointment and patient information. You'll need to enter your personal Admin PIN to regain access to the Admin.
            <br />
            <TextField
              label='Admin PIN'
              value={state.pin}
              onChange={(e) => setState({ pin: e.target.value })}
              error={error}
              helperText='Between 4-8 characters'
            />
          </div>
        );
      },
      customButtons: (t: any) => {
        const { state, close, } = t;
        const error = state?.pin?.length < 4 || state?.pin?.length > 8;
        return (
          <>
            <HeaderButton
              onClick={() => {
                close();
              }}
              borderSolid
              title="Back"
              className='sked-test-create-admin-pin-modal-button-back'
            />
            <HeaderButton
              color='primary'
              disabled={!state.pin || error}
              onClick={() => {
                const win = window.open('about:blank');
                setWindow(win);
                saveState.invoke(state).then(({ pin }: GlobalSettings) => {
                  newOneTimeState.invoke(subId).then((token: string) => {
                    setOneTimeToken(token);
                    setLocked(pin);
                    close();
                  });
                });
              }}
              title="Save & Continue"
              className='sked-test-create-admin-pin-modal-button-save'
            />
          </>
        );
      },
    });
  }
};