import React from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import './messages-new.css';
import * as rawActions from './messages-new.actions';
import * as R from 'ramda';
import { resetMessages } from '../../messages.actions';
import CreateMessage from '../../../../components/create-message.component';

const MessagesNew = ({ match, history, location }) => {
  const dispatch = useDispatch();
  const actions = bindActionCreators(rawActions, dispatch);
  const props = useSelector((state) => ({
    ...state.newMessage,
    office: R.pathOr({}, ['login', 'office'])(state),
    features: R.pathOr([], ['login', 'features'])(state),
    isCopied: R.pathOr(false, ['messages', 'state'])(state) === 'COPY',
  }));

  return (
    <CreateMessage
      {...props}
      messageType='OneTime'
      urlId={match.params.id}
      actions={actions}
      resetMessages={() => dispatch(resetMessages())}
      history={history}
      location={location}
    />
  );
};

export default MessagesNew;
