import * as at from '../../../../actionTypes';
import api, { skedApi } from '../../../../services/api.js';
import { remoteAction } from '../../../../services/actionService.js';
import { popup } from '../../../../services/Popup.js';
import axios from 'axios';
import { etd } from '../../../../services/utilities.js';
import { isEmpty, evolve } from 'ramda';

export const defaultEmailSubject = (name) => `👍👎 How would you rate your experience at ${name}?`;
export const defaultEmailHeader = 'How was your experience?';
export const defaultEmailBody = 'We appreciate your time and highly value your feedback. Please click below to rate your experience.';
export const defaultMainPage = (name) => `Did you have a great experience at ${name}?`;
export const defaultNoHeader = 'We\'re sorry to let you down 😞';
export const defaultNoBody = 'Please tell us how to improve so we can better serve you.';
export const defaultEndHeader = 'Thank You!';
export const defaultEndBody = 'Your feedback is valuable to us.';
export const defaultSmsMsg = (name) => `Please click the link to share your experience with ${name}.`;


export const initialize = () => remoteAction({
  type: at.SETTINGS_REVIEWS_INITIALIZE,
  action: () => Promise.all([
    api.get('officeReviewSettings'),
    api.get('appointmentType'),
    api.get('professional'),
    api.get('officeReviewSettings/custom')
  ].map(etd)).catch(() => null),
});

export const getReviewSettings = initialize;

export const editForm = (field, value) => ({
  type: at.SETTINGS_REVIEWS_EDIT_FORM,
  data: {
    field,
    value,
  },
});

export const save = (image) => (dispatch, getState) => {
  // const formData = new FormData();
  // formData.append('image', image, image.name);
  const config = image ? {
    headers: {
      'Content-Type': image.type,
      'Authorization': skedApi.defaults.headers.common.Authorization,
      'X-As-Office': skedApi.defaults.headers.common['X-As-Office'],
    },
  } : null;
  const {
    facebookUrl,
    googleUrl,
    forwardSetting,
    daysToResend,
    minutesToWait,
    isEnabled,
    selectedTypes,
    some,
    mainPage,
    emailPage,
    noPage,
    smsMsg,
    endPage,
  } = getState().settingsReviews;

  const office = getState().login.office;

  const custom = api.post('officeReviewSettings/custom', {
    mainPage: isEmpty(mainPage) ? null : mainPage,
    emailPage: isEmpty(emailPage.subject) &&
      isEmpty(emailPage.header) &&
      isEmpty(emailPage.body)
      ? null : evolve({
        subject: (s) => {
          if (isEmpty(s)) {
            return defaultEmailSubject(office.name);
          }
          return s;
        },
        header: (h) => {
          if (isEmpty(h)) {
            return defaultEmailHeader;
          }
          return h;
        },
        body: (b) => {
          if (isEmpty(b)) {
            return defaultEmailBody;
          }
          return b;
        }
      }, emailPage),
    noPage: isEmpty(noPage.header) &&
      isEmpty(noPage.body)
      ? null : evolve({
        header: (h) => {
          if (isEmpty(h)) {
            return defaultNoHeader;
          }
          return h;
        },
        body: (b) => {
          if (isEmpty(b)) {
            return defaultNoBody;
          }
          return b;
        },
      }, noPage),
    smsMsg: isEmpty(smsMsg) ? null : smsMsg,
    endPage: isEmpty(endPage.header) &&
      isEmpty(endPage.body)
      ? null : evolve({
        header: (h) => {
          if (isEmpty(h)) {
            return defaultEndHeader;
          }
          return h;
        },
        body: (b) => {
          if (isEmpty(b)) {
            return defaultEndBody;
          }
          return b;
        },
      }, endPage),
  });

  const requests = image ? [
    api.post('officeReviewSettings', {
      facebookUrl: facebookUrl === '' ? undefined : facebookUrl,
      googleUrl: googleUrl === '' ? undefined : googleUrl,
      forwardSetting: forwardSetting === 'Other' ? 'Facebook' : forwardSetting,
      daysToResend: Number(daysToResend),
      minutesToWait: Number(minutesToWait),
      isEnabled,
      appointmentTypes: {
        [some]: some === 'All' ? [] : selectedTypes,
      }
    }),
    custom,
    axios.post(
      `${process.env.API_URL}/officeReviewSettings/image`,
      image,
      config)
  ] : [
    api.post('officeReviewSettings', {
      facebookUrl: facebookUrl === '' ? undefined : facebookUrl,
      googleUrl: googleUrl === '' ? undefined : googleUrl,
      forwardSetting: forwardSetting === 'Other' ? 'Facebook' : forwardSetting,
      daysToResend: Number(daysToResend),
      minutesToWait: Number(minutesToWait),
      isEnabled,
      appointmentTypes: {
        [some]: some === 'All' ? [] : selectedTypes,
      }
    }),
    custom
  ];
  return remoteAction({
    type: at.SETTINGS_REVIEWS_SAVE,
    action: () => Promise.all(requests).then(() => {
      popup('Success!', 'Settings saved!');
    })
  });
};

export const closeSnackbar = () => ({
  type: at.SETTINGS_REVIEWS_CLOSE_SNACKBAR,
});

export const unmount = () => ({
  type: at.SETTINGS_REVIEWS_UNMOUNT,
});
