import { Conditional } from './ServerTypes';

export interface SimpleWidget {
  id: number;
  //parentId: number;
  label: string;
  type: 'SimpleWidget',
  questionType: SimpleWidgetType; //String enum

  placeholder?: string;
  helperText?: string;
  required?: boolean;
  forOffice: boolean,
  //order: number;
  clientField?: string; //string enum
  width?: number; //1-12
  isEdit?: boolean;
  officeNote?: string;
}

export type SimpleWidgetType = SimpleWidgetShortText |
  SimpleWidgetLongText |
  SimpleWidgetRadio |
  SimpleWidgetCheckbox |
  SimpleWidgetCheckboxSingle |
  SimpleWidgetDropdown |
  SimpleWidgetSpacer |
  SimpleWidgetEmail |
  SimpleWidgetDate |
  SimpleWidgetDropdown |
  SimpleWidgetNumber |
  SimpleWidgetInstruction;

export interface SimpleWidgetInstruction {
  type: 'SectionInstruction'
}

export interface SimpleWidgetShortText {
  type: 'ShortText'
}

export interface SimpleWidgetLongText {
  type: 'LongText'
}

export interface SimpleWidgetNumber {
  type: 'Number'
}

export interface SimpleWidgetRadio {
  type: 'Radio';
  direction?: 'row' | 'column';
  options: string[];
}

export interface SimpleWidgetDropdown {
  type: 'Dropdown';
  direction?: 'row' | 'column';
  options: string[];
}

export interface SimpleWidgetCheckbox {
  type: 'Checkbox';
  direction?: 'row' | 'column';
  options: string[];
}

export interface SimpleWidgetCheckboxSingle {
  type: 'CheckboxSingle';
}

export interface SimpleWidgetSpacer {
  type: 'Spacer';
}

export interface SimpleWidgetEmail {
  type: 'Email';
}

export interface SimpleWidgetDate {
  type: 'Date';
}

export interface ComplexWidget {
  type: 'ComplexWidget';
  id: number;
  typeData: ComplexWidgetType; //Union Type
  width: number;
  required?: boolean;
  forOffice: boolean;
  order?: number;
  officeNote?: string;
  label?: string; // only used for adding conditionals
}

export type ComplexWidgetType = ComplexWidgetRadioGrid |
  ComplexWidgetSignature |
  ComplexWidgetFileUpload |
  ComplexWidgetConsentPapers |
  ComplexWidgetDrawablePicture |
  ComplexWidgetFlexibleTable |
  ComplexWidgetFixedTable;

export interface ComplexWidgetRadioGrid {
  type: 'RadioGrid';
  columnLabels: string[];
  rowLabels: string[];
}

export interface ComplexWidgetFlexibleTable {
  type: 'FlexibleTable';
  inputs: SimpleWidget[];
  defaultRowCount: number;
}

export interface ComplexWidgetFixedTable {
  type: 'FixedTable';
  rows: FixedTableRows;
  inputs: SimpleWidget[];
}

export interface FixedTableRows {
  header: string;
  labels: string[];
}


export interface ComplexWidgetFileUpload {
  type: 'FileUpload';
  name: string;
  description: string;
}

export interface ComplexWidgetSignature {
  type: 'Signature';
}

export interface ComplexWidgetConsentPapers {
  type: 'ConsentPapers';
  name: string;
  body: string;
}


export interface ConsentPaper {
  name: string;
  body: number;
}

export interface ComplexWidgetDrawablePicture {
  type: 'DrawablePicture';
  pictureUrl: string;
  options: PictureOptions[];
}

export interface PictureOptions {
  label: string;
  color: string;
}


export interface Header {
  id: number;
  type: 'Header';
  size: number;
  text: string;
  width: number;
  isEdit?: boolean;
}

export interface Container {
  id: number;
  type: 'Container';
  sectionId?: number;
  children: SimpleWidget[];
}

export type SectionChild = Instruction | ComplexWidget | Header | SimpleWidget;

export interface Section {
  id: number;
  type: 'Section' | 'PrivateSection';
  name?: string;
  description?: string;
  count?: number;
  children: SectionChild[]
  isEdit?: boolean;
}


export interface Instruction {
  id: number;
  type: 'Instruction';
  text: string;
  width: number;
  isEdit?: boolean;
}

export interface PageBreak {
  id: number;
  type: 'PageBreak';
  width: number;
}


export type FormChild = Header | Instruction | Section | PageBreak;

export interface IntakeForm {
  id: number;
  name: string;
  description: string;
  sendNotification?: boolean;
  children: FormChild[];
  logoUrl: string;
  pdf?: number;
  logoAlignment: 'Left' | 'Right' | 'Center';
  info: string;
  formId: number;
  conditionals: Conditional[];
}

export const defaultPictureUrl = 'https://s3.amazonaws.com/sked-v2/uploads/Dev%20%22int%22/office/119/images/5d3dcb603868bf9fc93498aefa090d3bc46da6938e14dee39c7e86090759517e/front%20back%20diagram.jpg';

export interface IndexAndConds {
  index: number,
  conditionals: Conditional[];
}
