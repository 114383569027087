import React from 'react';

type Props = {
  color?: string;
  barColor?: string;
  className?: string;
};

const FormatHighlightIcon = ({ color, barColor, className }: Props) => {
  return (
    <svg id="formathighlight" className={className} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 24 24">
      <rect style={{ strokeWidth: 0 }} fill={barColor || color || '#000'} x="2" y="20" width="20" height="4"/>
      <g id="_39.Highlighter" data-name="39.Highlighter">
        <path style={{ strokeWidth: 0 }} fill={color || '#000'} d="M4.6,16.5l2.7.9,1-1-1.8-1.8-1.9,1.9Z"/>
        <path style={{ strokeWidth: 0 }} fill={color || '#000'} d="M8,9.9c-.3.3-.4.7-.3,1l.4,1.2-1.4,1.4,2.6,2.6,1.4-1.4,1.2.4c.4.1.8,0,1-.3l1-1.1-4.7-4.7-1.1,1Z"/>
        <path d="M19,5.5l-1.7-1.7c-.6-.6-1.5-.6-2.1,0l-5.4,4.6,4.6,4.6,4.6-5.4c.5-.6.5-1.5,0-2.1h0Z"/>
      </g>
    </svg>
  );
};

export default FormatHighlightIcon;
