import api from '../../services/api.js';
import { Appointment } from '../Appointments/appointments.types';

export interface AppointmentWithArrival extends Appointment {
  arrived: string;
}

export type ApptQuery = {
  listId: number;
  page: number;
  perPage: number;
  query: {
    after: string;
    before: string;
    appointmentTypeIds?: number[];
    professionalIds?: number[];
    clientIds?: number[];
    order: string;
  };
}

export interface ApptData {
  page: number;
  totalPages: number;
  totalCount: number;
  data: Appointment[];
}

export const getAppts = (q: ApptQuery) => {
  const {
    page, perPage, query, listId,
  } = q;
  return api.post('list/appointment/appointments/query', {
    page,
    perPage,
    query: {
      listId,
      appointment: query,
    }
  }).then((data) => ({
    ...data,
    data: data.data
      .map(({ appointment, created }: {
        appointment: Appointment, created: string
       }) => ({ ...appointment, arrived: created }))
      .filter((a: AppointmentWithArrival) => Boolean(a)),
  }));
};

export const getList = ({ listPath }: { listPath: string }) => {
  return api.get(`paths/path/spark.0.${listPath}`).then(({ resource }) => {
    return resource.AppointmentList.id;
  });
};

export const removeAppt = ({ listId, apptId }: { listId: number, apptId: number }) => {
  return api.delete(`list/appointment/${listId}/appointments/${apptId}`, { data: {} });
};
