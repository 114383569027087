import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  logContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 54,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: -18,
    pointerEvents: 'none',
  },
  image: {
    width: 110,
  }
}));
