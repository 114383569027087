import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  contentBanner: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentText: {
    fontWeight: 400,
    fontSize: 15,
    padding: 5,
    paddingLeft: 10,
    flex: 1,

    '& a': {
      textDecoration: 'underline',
      color: 'currentColor',
    },
  },
  closeIcon: {
    cursor: 'pointer',
    marginRight: 20,
    fontSize: 18,
  },
}));
