import { merge, pick, cond, equals, always, T, identity, pathOr } from 'ramda';
import api from '../../services/api.js';
import * as at from '../../actionTypes';
import { popup } from '../../services/Popup.js';
import { remoteAction } from '../../services/actionService.js';

export const regPatch = ({ type, data }) => ({
  type,
  data,
});

const regMerge = (data) => ({
  type: at.REG_PATCH,
  data,
});

const createStripeToken = ({ stripe, card, data }) => dispatch => dispatch(remoteAction({
  type: at.REG_CREATE_STRIPE_TOKEN,
  action: () => stripe.createToken(card, data)
})).catch(e => {
  popup('Error!', 'Failed to submit card information.');
  throw e;
});

const register = (data, isSpark) => (dispatch) =>
  dispatch(remoteAction({
    type: at.REG_REGISTER,
    action: () => api.post(`/register/office/${isSpark ? 'spark' : 'sked'}`, data)
  })).catch((error) => {
    console.log('made it to the error');
    const errorMessage = pathOr('Something went wrong! Please reach out to our support team and they will assist you.', ['response', 'data'], error);
    popup('Error!', errorMessage);
    return error;
  });

export const submitForm = ({ step3Data, stripe, card, isSpark }) => (dispatch, getStore) => {
  dispatch(regMerge(step3Data));
  const regStore = merge(getStore().registration, step3Data);
  const billingAddress = regStore.isBillingAddressDifferent ? regStore.billingAddress : regStore.address;

  const stripeData = {
    'name': regStore.cardName,
    'address_line1': billingAddress.street,
    'address_line2': '',
    'address_city': billingAddress.city,
    'address_state': billingAddress.state,
    'address_zip': billingAddress.zipCode,
    'address_country': billingAddress.country,
  };

  return dispatch(createStripeToken({
    stripe,
    card,
    data: stripeData
  })).then(() => {
    const reg = getStore().registration;
    const office = merge(pick([
      'email',
      'stripeToken',
      'notificationEmail',
      'password',
      'officeName',
      'timezone',
      'phone',
      'address',
      'cardName',
      'plan',
      'referrer',
      'policy',
      'valuables',
    ], reg), {
      billingAddress: reg.isBillingAddressDifferent ? reg.billingAddress : null,
      promo: reg.hasPromo ? reg.promo : null,
      doctorContact: {
        name: reg.doctorName,
        phone: reg.doctorPhone
      },
      ehrSystem: cond([
        [equals('Platinum'), always('PlatinumApi')],
        [equals('None/Other'), always('None')],
        [T, identity]
      ])(reg.ehrSystem),
    });
    return dispatch(register(office, isSpark));
  });
};
