import * as React from 'react';
import { Typography } from '@mui/material';
import { SimpleWidget } from '../intake-types';
import {
  TextInputComponent,
  NumberInputComponent,
  CheckboxInputComponent,
  CheckboxSingleInputComponent
} from './TextInput.component';

interface SimpleWidgetProps {
  data: SimpleWidget;
  noCtx?: boolean;
  isPrivate?: boolean;
}

const SimpleWigetComponent = ({ data, noCtx, isPrivate }: SimpleWidgetProps) => {
  const inputType = data.questionType.type;

  if (inputType === 'ShortText' ||
    inputType === 'LongText' ||
    inputType === 'Dropdown' ||
    inputType === 'Date' ||
    inputType === 'Radio' ||
    inputType === 'Email'
  ) {
    return (
      <TextInputComponent
        id={data.id}
        type={data.questionType}
        label={data.label}
        required={data.required}
        placeholder={data.placeholder}
        noCtx={noCtx}
        isPrivate={isPrivate}
      />
    );
  }
  if (data.questionType.type === 'Number') {
    return (
      <NumberInputComponent
        id={data.id}
        type={data.questionType}
        label={data.label}
        required={data.required}
        placeholder={data.placeholder}
        noCtx={noCtx}
        isPrivate={isPrivate}
      />
    );
  }
  if (data.questionType.type === 'Checkbox') {
    return (
      <CheckboxInputComponent
        id={data.id}
        type={data.questionType}
        label={data.label}
        required={data.required}
        placeholder={data.placeholder}
        noCtx={noCtx}
        isPrivate={isPrivate}
      />
    );
  }
  if (data.questionType.type === 'CheckboxSingle') {
    return (
      <CheckboxSingleInputComponent
        id={data.id}
        type={data.questionType}
        label={data.label}
        required={data.required}
        placeholder={data.placeholder}
        noCtx={noCtx}
        isPrivate={isPrivate}
      />
    );
  }
  if (data.questionType.type === 'Spacer') {
    return <Typography variant="body1" component="p" gutterBottom>
      {data.label}
    </Typography>;
  }
  if (data.questionType.type === 'SectionInstruction') {
    return (
      <div dangerouslySetInnerHTML={{ __html: data.label }} />
    );
  }
};

interface ContainerProps {
  data: SimpleWidget;
  noCtx?: boolean;
  isPrivate?: boolean;
}

export const ContainerComponent = ({ data, noCtx, isPrivate }: ContainerProps) => {
  return (
    <SimpleWigetComponent
      data={data}
      noCtx={noCtx}
      isPrivate={isPrivate}
    />
  );
};
