import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const SourceIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <g fill={color || '#FFF'} id="source" transform="translate(-2 22) rotate(-90)">
        <path id="Path_8318" data-name="Path 8318" d="M19.82,4.18C17.8,2.16,13.32,2,12,2s-5.8.16-7.82,2.18S2,10.68,2,12s.16,5.8,2.18,7.82S10.68,22,12,22s5.8-.16,7.82-2.18S22,13.32,22,12,21.84,6.2,19.82,4.18ZM14,18H10a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2Zm1.71-6.29-3,3a1,1,0,0,1-1.42,0l-3-3a1,1,0,0,1,1.42-1.42L11,11.59V7a1,1,0,0,1,2,0v4.59l1.29-1.3a1,1,0,1,1,1.42,1.42Z" fill={color || '#4d4d4d'} />
      </g>
    </svg>
  );
};

export default SourceIcon;
