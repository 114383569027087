import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  CircularProgress,
  Grid,
  FormControl,
  FormLabel,
  TextField,
  Divider,
  IconButton,
  Button,
  Typography,
  OutlinedInput,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import * as R from 'ramda';
import api, { skedApi } from '../../../../services/api';
import { PopupTemplate, popup } from '../../../../services/Popup';
import Modal from '../../../../components/Modal/Modal.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import HeaderTabs from '../../../../components/HeaderTabs/HeaderTabs.component';
import { isValidDate } from '../../../../services/joda';
import { Client } from '../../clients.types';
import { Professional, AppointmentType, ProType } from '../../../Appointments/appointments.types';
import { Tag } from '../../../Tags/tags.types';
import { useStyles } from './addClients.styles';

interface IClient extends Client {
  errors: string[];
  defaultAppointmentType?: number;
}

const mapIndexed = R.addIndex(R.map);

const defaultClient = {
  id: 0,
  firstName: '',
  lastName: '',
  birthday: '',
  email: '',
  phone: '',
  isLead: false,
  defaultAppointmentType: undefined,
  errors: [],
} as IClient;

type Props = {
  open: boolean;
  onClose: () => void;
  save: () => void;
  admin: boolean;
}

type State = {
  status?: string;
  clients?: IClient[],
  proTypes?: ProType[],
  defaultAptType?: number;
  defaultAppointmentType?: number;
  type?: string;
  csvDefaultType?: number,
  csv?: { type: string, name: string },
  busy?: boolean;
  campaigns?: Tag[],
  tagIds?: number[],
}

const AddClients = (props: Props) => {
  const classes = useStyles();
  const [state, setState] = useState<State>({
    status: 'INIT',
    clients: [defaultClient],
    proTypes: [],
    defaultAptType: undefined,
    type: 'MANUAL', // #{MANUAL CSV}
    csvDefaultType: undefined,
    csv: undefined,
    busy: false,
    campaigns: [],
    tagIds: [],
  });

  const getProsAndTypes = () => {
    return Promise.all<[Promise<Professional[]>, Promise<AppointmentType[]>]>([api.get('professional'), api.get('appointmentType')])
      .then(([pros, types]) => {
        const proTypes = R.pipe(
          R.filter(({ isHidden }) => !isHidden),
          (params: Professional[]) => R.sortBy(R.prop('lastName'))(params),
          R.map((pro) => {
            const ts = R.pipe(
              R.filter(({ professionalId }) => pro.id === professionalId),
              (params: AppointmentType[]) => R.sortBy(R.prop('internalName'))(params)
            )(types);
            const newPro = R.merge(pro, {
              id: undefined, // to prevent mis-selection when a type has the same id.
              professionalId: pro.id
            });
            return [newPro, ts];
          }),
          R.flatten
        )(pros) as ProType[];
        update(null, { proTypes, status: 'EDIT' });
      }).catch((error) => {
        console.log(error);
      });
  };

  const getCSVType = () => {
    api.get('settings/genesis/defaultAppointmentType').then(([type]) => {
      if (type) {
        update(null, { csvDefaultType: type.id });
      }
    });
  };

  const getCampaigns = () => {
    api.get('client/tag').then((campaigns) => {
      update(null, { campaigns });
    });
  };

  useEffect(() => {
    if (state.status === 'INIT' && props.open) {
      getProsAndTypes();
      getCSVType();
      getCampaigns();
    }
  }, [state.status, props.open]);

  const update = (idx: number, data: State) => {
    if (idx !== null) {
      const newState = R.evolve({
        clients: (clients) => {
          const newStuff = R.adjust(idx, R.mergeLeft(data), clients);
          return newStuff;
        },
      }, state) as State;
      setState(R.merge(state, newState));
    } else {
      setState(R.merge(state, data));
    }
  };
  
  const updateClient = (idx: number, data: object) => {
    const newState = R.evolve({
      clients: (clients) => {
        const newStuff = R.adjust(idx, R.mergeLeft(data), clients);
        return newStuff;
      },
    }, state) as State;
    setState(R.merge(state, newState));
  };

  const typeSelect = (defaultAppointmentType: number, idx: number = null) => {
    return (
      <Select
        size='small'
        value={defaultAppointmentType === undefined ? '' : defaultAppointmentType}
        autoWidth
        className={classes.select}
        style={{ borderRadius: 10, minWidth: 142 }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          PaperProps: { style: { maxHeight: 350 } }
        }}
        onChange={(e) => {
          const defaultType = Number(e.target.value);
          const data = {
            defaultAppointmentType: defaultType,
          };
          if (!idx && idx !== 0) {
            const { professionalId } = R.find(R.propEq('id', defaultType))(state.proTypes) as ProType;
            update(null, { busy: true });
            Promise.all([
              api.put('/settings/genesis/default/professional', {
                professionalId,
              }),
              api.put('/settings/genesis/defaultAppointmentType', {
                appointmentTypeId: defaultType
              })
            ]).then(() => {
              update(
                null,
                {
                  busy: false,
                  csvDefaultType: defaultType
                });
            });
          } else if (state.defaultAptType) {
            update(idx, data);
          } else {
            update(
              null,
              {
                clients: R.append(
                  R.merge(defaultClient, { defaultAppointmentType: defaultType }),
                  R.adjust(idx, R.mergeLeft(data), state.clients)),
                defaultAptType: defaultType
              });
          }
        }}
        onFocus={() => {
          if (idx === state.clients.length - 1 && state.defaultAptType)
            update(
              null,
              {
                clients: R.append(
                  R.merge(defaultClient, { defaultAppointmentType: state.defaultAptType }),
                  state.clients)
              });
        }}
      >
        {R.map((proOrType: ProType) => {
          if (proOrType.firstName) {
            return (
              <MenuItem
                key={proOrType.professionalId}
                value={proOrType.professionalId}
                disabled={true}>
                {proOrType.displayFirstName + ' ' + proOrType.displayLastName}
              </MenuItem>
            );
          } else {
            return (
              <MenuItem key={proOrType.id} value={proOrType.id}>
                {proOrType.internalName + ' (' + proOrType.name + ')'}
              </MenuItem>
            );
          }
        })(state.proTypes)}
      </Select>
    );
  };

  const campaignSelect = () => {
    const showSelectedTags = (v: number[]) => {
      return v.map((id: number) => {
        const tag = state.campaigns.find(R.propEq('tagId', id)) || {} as Tag;
        return tag.tag;
      }).join(', ');
    };

    return (
      <Select
        labelId="tag-label"
        multiple
        className={classes.select}
        input={<OutlinedInput size='small' />}
        displayEmpty={true}
        renderValue={showSelectedTags}
        variant='outlined'
        value={state.tagIds}
        style={{
          minWidth: '200px',
          borderRadius: 10,
          marginTop: 8,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          PaperProps: { style: { maxHeight: 350 } }
        }}
        onChange={e => update(null, { tagIds: e.target.value as number[] })}>
        {state.campaigns?.length ? (
          state.campaigns.map(({ tagId, tag }: Tag) => (
            <MenuItem key={tagId} value={tagId}>
              <Checkbox checked={state.tagIds.indexOf(tagId) > -1} />
              <ListItemText primary={tag} />
            </MenuItem>
          ))
        ) : (
          <MenuItem value="" disabled>
            <em>No Options</em>
          </MenuItem>
        )}
      </Select>
    );
  };

  const clientItem = (client: IClient, idx: number) => {
    const {
      firstName,
      lastName,
      birthday,
      email,
      phone,
      defaultAppointmentType,
      errors,
    } = client;

    return (
      <>
        {idx > 0 && <Grid key={idx} marginTop={1.5} xs={12}><Divider /></Grid>}
        <Grid marginTop={1} key={idx} className={classes.gridContainer}>
          <Grid className={classes.gridItem}>
            <FormControl variant="outlined" className={classes.formControl}>
              <FormLabel className={classes.label}>First name</FormLabel>
              <TextField
                size='small'
                className={classes.input}
                placeholder='First name'
                value={firstName}
                error={R.includes('firstName', errors)}
                onChange={(e) => updateClient(idx, { firstName: e.target.value })}
                variant='outlined'
                onBlur={() => {
                  if (firstName === '') {
                    updateClient(idx, { errors: R.append('firstName', errors) });
                  } else {
                    updateClient(idx, { errors: R.without(['firstName'], errors) });
                  }
                }}
                required
              />
            </FormControl>
          </Grid>
          <Grid className={classes.gridItem}>
            <FormControl variant="outlined" className={classes.formControl}>
              <FormLabel className={classes.label}>Last name</FormLabel>
              <TextField
                variant='outlined'
                className={classes.input}
                size='small'
                value={lastName}
                error={R.includes('lastName', errors)}
                placeholder='Last name'
                onChange={(e) => updateClient(idx, { lastName: e.target.value })}
                onBlur={() => {
                  if (lastName === '') {
                    updateClient(idx, { errors: R.append('lastName', errors) });
                  } else {
                    updateClient(idx, { errors: R.without(['lastName'], errors) });
                  }
                }}
                required
              />
            </FormControl>
          </Grid>
          <Grid className={classes.gridItem}>
            <FormControl variant="outlined" className={classes.formControl}>
              <FormLabel className={classes.label}>Birthday</FormLabel>
              <TextField
                variant='outlined'
                className={classes.input}
                size='small'
                value={birthday}
                error={R.includes('birthday', errors)}
                placeholder='MM/DD/YYYY'
                onChange={(e) => {
                  const origLen = R.length(birthday);
                  const len = R.length(e.target.value);
                  if ((origLen === 3 && len === 2) || (origLen === 6 && len === 5)) {
                    updateClient(idx, { birthday: e.target.value });
                  } else if (len === 2 || len === 5) {
                    updateClient(idx, { birthday: e.target.value + '/' });
                  } else {
                    updateClient(idx, { birthday: e.target.value });
                  }
                }}
                onBlur={() => {
                  if (birthday === '' || isValidDate(birthday)) {
                    updateClient(idx, { errors: R.without(['birthday'], errors) });
                  } else {
                    updateClient(idx, { errors: R.append('birthday', errors) });
                  }
                }}
                required
              />
            </FormControl>
          </Grid>
          <Grid className={classes.gridItem}>
            <FormControl variant="outlined" className={classes.formControl}>
              <FormLabel className={classes.label}>Email</FormLabel>
              <TextField
                variant='outlined'
                className={classes.input}
                size='small'
                value={email}
                error={R.includes('email', errors)}
                placeholder='Email'
                type="email"
                onChange={(e) => updateClient(idx, { email: e.target.value })}
                onBlur={() => {
                  let isValid = email === '';
                  if (!isValid) {
                    const hasAt = R.split('@', email);
                    if (R.length(hasAt) <= 1) {
                      isValid = false;
                    } else {
                      const hasCom = R.split('.', hasAt[1]);
                      if (R.length(hasCom) <= 1) {
                        isValid = false;
                      } else {
                        isValid = true;
                      }
                    }
                  }
                  if (isValid) {
                    updateClient(idx, { errors: R.without(['email'], errors) });
                  } else {
                    updateClient(idx, { errors: R.append('email', errors) });
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid className={classes.gridItem}>
            <FormControl variant="outlined" className={classes.formControl}>
              <FormLabel className={classes.label}>Phone</FormLabel>
              <TextField
                variant='outlined'
                className={classes.input}
                size='small'
                value={phone}
                error={R.includes('phone', errors)}
                placeholder='Phone'
                onChange={(e) => updateClient(idx, { phone: e.target.value })}
                onBlur={() => {
                  const len = R.length(phone);
                  const isValid = len >= 7 || phone === '';
                  if (isValid) {
                    updateClient(idx, { errors: R.without(['phone'], errors) });
                  } else {
                    updateClient(idx, { errors: R.append('phone', errors) });
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid className={classes.gridItem}>
            <FormControl variant="outlined" className={classes.formControl}>
              <Grid display="flex" alignItems="center" justifyContent="space-between">
                <FormLabel className={classes.label}>Default Appt Type</FormLabel>
                {idx !== 0 && <IconButton
                  size="small"
                  title="Remove line"
                  style={{ padding: 3, marginTop: -3 }}
                  onClick={() => update(null, { clients: R.remove(idx, idx, state.clients) })}>
                  <DeleteIcon style={{ fontSize: 20 }} />
                </IconButton>}
              </Grid>
              {typeSelect(defaultAppointmentType, idx)}
            </FormControl>
          </Grid>
        </Grid>
      </>
    );
  };

  const disabled = R.reduce((acc, item) => {
    return acc && R.isEmpty(item.errors);
  }, true, state.clients);

  return (
    <Modal
      title='Add Clients'
      size="xs"
      maxWidth={1100}
      open={props.open}
      className='sked-test-add-clients-modal'
      onClose={() => {
        props.onClose();
        update(null, {
          clients: [defaultClient],
          status: 'INIT',
          defaultAptType: undefined,
          csvDefaultType: undefined,
          type: 'MANUAL',
        });
      }}
      buttons={[
        state.busy && <CircularProgress size={18} style={{ marginRight: 5 }} />,
        state.type === 'MANUAL' &&
          <HeaderButton
            title='Add'
            Icon={AddIcon}
            iconStyle={{ marginRight: -8 }}
            borderSolid
            className='sked-test-add-clients-modal-button-add-line'
            onClick={() => update(
              null,
              {
                clients: R.append(
                  R.merge(defaultClient, { defaultAppointmentType: state.defaultAptType }),
                  state.clients)
              })}
          />,
        <HeaderButton
          title='Save'
          color='primary'
          disabled={state.type === 'MANUAL' ?
            (!disabled || !state.clients[0]?.firstName || !state.clients[0]?.lastName) || !state.clients[0]?.defaultAppointmentType
            :
            (!state.csv || !state.csvDefaultType)
          }
          className='sked-test-add-clients-modal-button-save'
          onClick={() => {
            //TODO move this out of the rendering part
            const {
              clients,
              type,
              csv,
              tagIds,
            } = state;
            if (type === 'CSV') {
              const tags = tagIds.length ? `?tags=${tagIds.join('%2C')}` : '';
              const config = {
                headers: {
                  /* 'content-type': 'multipart/form-data', */
                  'Content-Type': csv.type,
                  'Authorization': skedApi.defaults.headers.common.Authorization,
                  'X-As-Office': skedApi.defaults.headers.common['X-As-Office'],
                },
              };
              const formData = new FormData();
              formData.append('clients', csv as unknown as Blob);
              axios.post(`${process.env.API_URL}/client/clientcsv${tags}`, formData, config)
                .then(() => {
                  props.save(); // update page
                  props.onClose();
                  update(null, {
                    clients: [defaultClient],
                    status: 'INIT',
                    defaultAptType: undefined,
                    csv: undefined,
                    csvDefaultType: undefined,
                    type: 'MANUAL',
                  });
                }).catch((error) => {
                  console.log(error);
                  popup('Error!', 'Failed to parse CSV with error: ' + error.response.data);
                });
            } else {
              const requests = R.pipe(
                R.filter(({ firstName, lastName }) => firstName && lastName),
                R.map((d: IClient) => {
                  const [month, day, year] = d.birthday.split('/');
                  const dateString = year + '-' + month + '-' + day;
                  const client = R.merge(
                    d,
                    {
                      email: d.email === '' ? undefined : d.email,
                      phone: d.phone === '' ? undefined : d.phone.replace(/[{()}]|-|\s/g, ''),
                      birthday: d.birthday === '' ? undefined : dateString,
                      defaultAppiontmentType: d.defaultAppointmentType ?
                        d.defaultAppointmentType : d.defaultApptiontmentType,
                      status: 'Active',
                      metadata: {},
                      isLead: false,
                    });
                  return api.post('client', client);
                }),
              )(clients);
              Promise.all(requests).then(() => {
                props.save(); // update page
                update(null, { clients: [defaultClient], status: 'INIT', defaultAptType: undefined });
                setTimeout(() => {
                  props.onClose();
                }, 10);
              }).catch((error) => {
                console.log(error);
                popup('Error!', 'Failed to add clients. Verify that all email and phone numbers are valid!');
              });
            }
          }}
        />
      ]}
    >
      <div>
        <PopupTemplate />
        {props.admin &&
            <Grid display="flex" alignItems="center">
              <Typography className={classes.importLabel}>Import Method: </Typography>
              <HeaderTabs
                value={state.type}
                setTab={(value) => update(null, { type: value })}
                tabs={[
                  { label: 'Manual', value: 'MANUAL', className: 'sked-test-clients-tab-manual' },
                  { label: 'CSV', value: 'CSV', className: 'sked-test-clients-tab-svg' },
                ]}
              />
            </Grid>
        }
        {R.cond([
          [R.equals('MANUAL'), () => (
            <div style={{ paddingBottom: 10 }}>
              <p>
                To make this process as fast as possible, use the "Tab" key to jump from input field to input field. Once you reach the last field (Default Appointment Type), a new row will appear and you can use the "Tab" key to jump down to it. Once set, the default appointment type will be used automatically. When entering the birthday, the forward slashes will automatically fill. When adding in a phone number that is from a different country, make sure to add the country code .i.e +1 for the US.
              </p>
              {mapIndexed((d: IClient, idx: number) => clientItem(d, idx), state.clients)}
            </div>
          )],
          [R.equals('CSV'), () => (
            <div>
              <p>
                In order to easily upload large amounts of clients from an outside source, you can use this CSV file upload. The format of the CSV file must be `firstname,lastname,email,phone,birthday(MM-DD-YYYY)` (i.e `John,Doe,example@sked.life,1234567890,07-15-1994`) and it must NOT contain the column names. Before you upload, you must select the default appointment type for the clients being uploaded.
              </p>
              <label>
                  Default Appointment Type:
                  &nbsp;
                {typeSelect(state.csvDefaultType)}
              </label>
              <br />
              <label>
                  Tags:
                  &nbsp;
                {campaignSelect()}
              </label>
              <br />
              <br />
              <Grid display="flex" alignItems="center">
                <Button
                  variant='contained'
                  color='primary'
                  aria-label='Upload Attachment'
                  component='label'
                >
                    Choose file
                  <input hidden type="file" onChange={(e) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      console.log(e.target.result);
                    };
                    reader.readAsDataURL(e.target.files[0]);
                    update(null, { csv: e.target.files[0] });
                  }} />
                </Button>
                <Grid marginLeft="15px" display="flex" alignItems="center">
                  {state.csv && (
                    <>
                      <span>{state.csv.name}</span>
                        &nbsp;
                      <IconButton
                        title='Remove'
                        size='small'
                        onClick={() => update(null, { csv: null })}
                      >
                        <DeleteIcon style={{ fontSize: 22 }} />
                      </IconButton>
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
          )],
          [R.T, () => (<>I sure could use some clients!!!</>)]
        ])(state.type)}
      </div>
    </Modal>
  );
};

export default AddClients;
