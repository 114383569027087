import React, { useState, useEffect } from 'react';
import {
  TableHead, TableBody, TableRow,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './reviews.actions.jsx';
import { PopupTemplate } from '../../services/Popup.js';
import {
  has, cond, T, pathOr, range, dropLast, includes,
} from 'ramda';
import {
  tzParseFormat, now,
} from '../../services/joda.js';
import {
  LocalDateTime,
} from '@js-joda/core';
import ViewReview from './view-review-dialog.component.jsx';
import { caseType } from '../../services/utilities.js';
import ClientEditDialog from '../Clients/components/client-dialog/client-dialog.component.jsx';
import { FeatureDisabled } from '../../components/feature-disabled.component';
import Header from '../../components/PageHeader/PageHeader.component';
import HeaderTabs from '../../components/HeaderTabs/HeaderTabs.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import Loading from '../../components/Loading/Loading.component';
import GearIcon from '../../icons/Settings.icon';
import { TableContainer, HeaderCell, BodyCell } from '../../components/CustomTable';
import { GoogleIntegrationDialog } from '../Settings/routes/Reviews/reviews.component.jsx';
import { useTitle } from '../../services/useTitle.js';

const full = 'FULL';
const empty = 'EMPTY';

const loadStars = (star, i) => {
  if (star === full) {
    return (
      <StarIcon key={i} style={{ fontSize: 18 }} />
    );
  }
  return (
    <StarBorderIcon key={i} style={{ fontSize: 18 }} />
  );
};

export const makeStars = (rating) => {
  const stars = [empty, empty, empty, empty, empty];
  const fulls = range(0, rating);

  const currentStars = stars.map((nothing, index) => {
    if (fulls[index] !== undefined) {
      return full;
    }
    return empty;
  });

  return currentStars.map(loadStars);
};


function Reviews({
  reviews,
  actions,
  tz,
  lastRead,
  office,
  isEnabled,
  isActive,
  history,
  headerHeight,
}) {
  const [open, setOpen] = useState(null);
  const [review, setReview] = useState(null);
  const [tab, setTab] = useState('reviews');
  useEffect(() => {
    if (isEnabled) {
      actions.getReviews({ page: 1, perPage: 25, query: [] });
      actions.getSent({ page: 1, perPage: 25, query: [] });
      actions.getReviewSettings();
    }
  }, [office, isEnabled]);

  const lastReadDate = lastRead ? LocalDateTime.parse(dropLast(1, lastRead)) : now('datetime');

  useTitle('Reviews');

  const onClick = (request) => () => {
    actions.selectClient(() => setOpen('CLIENT'), request.client);
  };

  if (!isEnabled) {
    return (
      <FeatureDisabled title="Reviews">
        <p>
          Automatically send patients review requests that are easy to navigate to boost your Google or Facebook reviews.
        </p>
      </FeatureDisabled>
    );
  }
  if (!isActive) {
    return (
      <>
        <Header
          title='Reviews'
          pageId='reviews'
          rightIcons={[
            <HeaderButton
              Icon={GearIcon}
              className='sked-test-reviews-settings'
              onClick={() => history.push('/settings/reviews')}
              onlyIcon
            />
          ]}
        />
        <div style={{ margin: '20px' }}>
          <p>
            Automatically send patients review requests that are easy to navigate to boost your Google or Facebook reviews.
          </p>
          <p><a href="#/settings/reviews">Click here</a> to set up reviews.</p>
        </div>
      </>
    );
  }
  return (
    <>
      <Header
        title='Reviews'
        pageId='reviews'
        leftIcons={[
          <HeaderTabs
            value={tab}
            setTab={(value) => setTab(value)}
            tabs={[
              { label: 'Reviews', value: 'reviews', className: 'sked-test-reviews-reviews' },
              { label: 'Requests', value: 'requests', className: 'sked-test-reviews-requests' },
            ]}
          />,
        ]}
        rightIcons={[
          tab === 'reviews' ?
            <HeaderButton className='sked-test-reviews-mark-all-as-read' onClick={() => actions.updateLastRead()} borderSolid title='Mark all as Read' />
            : <div />,
          <HeaderButton className='sked-test-reviews-settings' Icon={GearIcon} onClick={() => history.push('/settings/reviews')} onlyIcon />
        ]}
        breakPoints={[
          {
            width: 538,
            mobileItems: [1]
          }
        ]}
      />
      <div style={{ paddingTop: 0, overflowY: 'unset' }}>
        <PopupTemplate />
        <div style={{ paddingBottom: '0px' }}>
          <GoogleIntegrationDialog
            open={open === 'GOOGLE'}
            onClose={() => setOpen(null)}
          />
          <ClientEditDialog
            open={open === 'CLIENT'}
            onClose={() => actions.backToReview(() => {
              setOpen(null);
              setReview(null);
            })}
            back={actions.back}
            from={'REVIEWS'}
            gotoClient={actions.back} />
          <ViewReview
            open={open === 'REVIEW'}
            onClose={() => {
              setOpen(null);
              setReview(null);
            }}
            review={review}
            tz={tz}
            from={'REVIEWS'}
            gotoClient={actions.selectClientDialog}
            back={() => actions.back()}
          />
          <div hidden={tab !== 'reviews'}>
            {reviews.busy ?
              <Loading loading />
              :
              <div style={{ margin: '10px 20px 0px', marginTop: 0 }}>
                <TableContainer
                  fullPage
                  style={{ marginLeft: -20, marginRight: -20 }}
                  maxHeight={`calc(100vh - ${headerHeight}px - 45px)`}
                  pagination
                  paginationData={{
                    addPage: (v) => actions.getReviews({
                      perPage: reviews.perPage,
                      page: reviews.page + v,
                      query: [],
                    }),
                    currentLength: reviews.reviews.length,
                    page: reviews.page,
                    perPage: reviews.perPage,
                    totalCount: reviews.totalCount,
                    totalPages: reviews.totalPages,
                    setPerPage: (value) => {
                      actions.getReviews({
                        perPage: value,
                        page: reviews.page,
                        query: [],
                      });
                    }
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <HeaderCell fixed>Name</HeaderCell>
                      <HeaderCell>Experience</HeaderCell>
                      <HeaderCell>Rating</HeaderCell>
                      <HeaderCell>Comment</HeaderCell>
                      <HeaderCell>Created</HeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reviews.reviews.map((review) => {
                      const exp = cond([
                        [has('ExperienceGood'), () => ({ exp: 'Good' })],
                        [has('ExperienceBadOnly'), () => ({ exp: 'Bad' })],
                        [T, () => {
                          const comment =
                            pathOr(null, ['feedback', 'ExperienceBadFeedback', 'comment'])(review);

                          return ({
                            exp: 'Bad',
                            rating: pathOr(null, ['feedback', 'ExperienceBadFeedback', 'rating'])(review),
                            comment: comment && (comment.length > 50) ? comment.slice(0, 51) + '...' : comment,
                          });
                        }],
                      ])(review.feedback);
                      const createDate = LocalDateTime.parse(dropLast(1, review.created));
                      const bold = lastReadDate.isBefore(createDate);
                      return (
                        <TableRow
                          key={review.created}
                          style={exp.rating ? { cursor: 'pointer' } : {}}
                          onClick={() => {
                            if (exp.rating) {
                              setOpen('REVIEW');
                              setReview(review);
                            }
                          }}
                        >
                          <BodyCell fixed>
                            <a
                              style={bold ? { fontWeight: 'bold' } : {}}
                              onClick={() => {
                                if (exp.rating) {
                                  setOpen('REVIEW');
                                  setReview(review);
                                } else {
                                  actions.selectClient(
                                    () => {
                                      setOpen('CLIENT');
                                    },
                                    review.client);
                                }
                              }}>
                              {review.client.firstName} {review.client.lastName} {caseType(review.client)}
                              <br />
                            </a>
                          </BodyCell>
                          <BodyCell style={bold ? { fontWeight: 'bold' } : {}}>
                            {exp.exp}
                          </BodyCell>
                          <BodyCell nowrap style={bold ? { fontWeight: 'bold' } : {}}>
                            {exp.rating ? makeStars(exp.rating) : null}
                          </BodyCell>
                          <BodyCell style={bold ? { fontWeight: 'bold' } : {}}>
                            {exp.comment ? exp.comment : null}
                          </BodyCell>
                          <BodyCell nowrap style={bold ? { fontWeight: 'bold' } : {}}>
                            {tzParseFormat(review.created, tz, 'MM/dd/yyyy h:mm a')}
                          </BodyCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </TableContainer>
              </div>
            }
          </div>
          <div hidden={tab !== 'requests'}>
            {reviews.busy ?
              <Loading loading />
              :
              <div style={{ margin: '10px 20px 0px', marginTop: 0 }}>
                <TableContainer
                  style={{ marginLeft: -20, marginRight: -20 }}
                  maxHeight={`calc(100vh - ${headerHeight}px - 45px)`}
                  pagination
                  paginationData={{
                    addPage: (v) => actions.getSent({
                      perPage: reviews.sentPerPage,
                      page: reviews.sentPage + v,
                      query: [],
                    }),
                    currentLength: reviews.requests.length,
                    page: reviews.sentPage,
                    totalCount: reviews.sentTotalCount,
                    totalPages: reviews.sentTotalPages,
                    perPage: reviews.sentPerPage,
                    setPerPage: (value) => {
                      actions.getSent({
                        perPage: value,
                        page: reviews.sentPage,
                        query: [],
                      });
                    }
                  }}
                >
                  <TableHead>
                    <TableRow>
                      <HeaderCell>Name</HeaderCell>
                      <HeaderCell>Sent</HeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reviews.requests.map((request) => {
                      return (
                        <TableRow
                          key={request.created}
                        /* style={{cursor: 'pointer'}} */
                        /* onClick={() => actions.selectClient(openClient, review.client)} */
                        >
                          <BodyCell>
                            <a onClick={onClick(request)}>
                              {request.firstName} {request.lastName}
                            </a>
                          </BodyCell>
                          <BodyCell>
                            {tzParseFormat(request.created, tz, 'MM/dd/yyyy h:mm a')}
                          </BodyCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </TableContainer>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = state => ({
  reviews: state.reviews,
  lastRead: state.settingsReviews.lastRead,
  tz: pathOr(null, ['login', 'office', 'timezone'])(state),
  office: pathOr(null, ['login', 'office'])(state),
  isEnabled: includes('Reviews', pathOr([], ['login', 'features'], state)),
  isActive: state.settingsReviews.isEnabled,
  headerHeight: state.login.headerHeight,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
