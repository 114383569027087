import React, { ComponentProps } from 'react';

const SettingsIcon = ({ color, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg id="uuid-307c6a21-ebec-45f4-94b4-cf1d8f956c9c" {...props} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill={color || '#6c6e77'}>
      <path d="m17.5093,48.7076c-.3694-.0808-.7537-.1207-1.106-.2488-2.6646-.9689-5.0796-2.379-7.2603-4.1872-1.0813-.8966-1.3649-2.2829-.7833-3.6817.9478-2.2798.7316-4.4519-.6954-6.4569-1.0535-1.4801-2.5201-2.351-4.3341-2.601-1.9535-.2693-2.8667-1.2496-3.1263-3.2822-.3326-2.6038-.2563-5.1903.215-7.7703.2729-1.4938,1.3061-2.4759,2.848-2.6772,3.4893-.4556,5.8883-3.2176,5.7764-6.7232-.0217-.6793-.1996-1.3724-.4151-2.0229-.5363-1.6186-.1868-2.8683,1.1625-3.9073,1.9642-1.5124,4.1071-2.7026,6.4233-3.5829,1.4107-.5361,2.6728-.2399,3.657.9206,1.3052,1.539,2.9333,2.4255,4.9576,2.4766,2.0634.0521,3.7604-.7592,5.1538-2.2825.4105-.4487.9161-.8519,1.4529-1.1333.7012-.3676,1.4752-.3029,2.2232-.0275,2.6448.9738,5.0493,2.365,7.215,4.1651,1.1579.9625,1.4662,2.1776.9608,3.6458-1.3032,3.7857.7971,7.6561,4.6731,8.6073.3357.0824.6858.1173,1.0118.2263,1.3827.4623,1.9658,1.5162,2.1834,2.89.4305,2.7184.3936,5.422-.1091,8.125-.2493,1.3403-1.296,2.3592-2.6645,2.5659-4.3262.6536-6.7528,4.7832-5.2222,8.8873.5395,1.4466.2242,2.6937-.9672,3.6844-1.9745,1.6421-4.1616,2.9358-6.5431,3.8935-1.3411.5393-2.6955.1448-3.6262-1.0144-2.3846-2.97-6.3362-3.5082-9.2414-1.2217-.5238.4123-.9521.9568-1.3804,1.4767-.6301.7649-1.4536,1.1983-2.4391,1.2564Zm15.8331-23.7185c.0183-4.5425-3.7208-8.3327-8.2341-8.3468-4.6439-.0145-8.4214,3.6754-8.4414,8.2455-.0202,4.6278,3.6888,8.4111,8.2619,8.4273,4.6447.0165,8.395-3.6948,8.4136-8.3261Z" />
    </svg>
  );
};

export default SettingsIcon;
