import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 55px - 46px)',
    overflow: 'hidden',
    position: 'relative',
  },
  pageHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '18px',
    marginBottom: '10px'
  },
  scroll: {
    overflowY: 'auto',
    '@media print': {
      overflowY: 'visible'
    },
  },
  headerDate: {
    width: 132,

    '& input': {
      padding: '4px 8px',
      width: 114,
      fontSize: 14,
    }
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  dateHeader: {
    alignSelf: 'center',
    fontWeight: 'bold',
    fontSize: '18px',
    marginLeft: '15px',
    justifyContent: 'center'
  },
  select: {
    '& .MuiSelect-select': {
      padding: '4px 8px',
      minWidth: 42,
      paddingLeft: 10,
    }
  },
}));
