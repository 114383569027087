import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FormControl, InputLabel, Select, MenuItem, CircularProgress,
  Checkbox, ListItemText, Input, Popover, ListSubheader,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { pathOr, propEq, defaultTo, prop, pipe } from 'ramda';
import { LocalDate, DateTimeFormatter } from '@js-joda/core';
import HashtagIcon from '../../icons/Hashtag.icon';
import { useTitle } from '../../services/useTitle';
import Header from '../../components/PageHeader/PageHeader.component';
import { Row } from '../../components/PageHeader';

import { TimeRangeSelector } from './components/time-range-selector.jsx';
import { StatBox } from './components/StatBox.jsx';
import { fetchOverviewData, getOverviewSettings, saveOverviewSettings } from './overview.actions.js';
import { getProfessionals } from '../Professionals/professionals.actions.jsx';
import FilterApptTypes from '../../components/FilterApptTypes/FilterApptTypes.component';
import FilterStartEndDate from '../../components/FilterStartEndDate/FilterStartEndDate.component';
import FilterSelect from '../../components/FilterSelect/FilterSelect.component';

import { Locale } from '@js-joda/locale_en-us';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '20px',
    overflowY: 'auto',
  },
  pageHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '10px',
    width: '100%'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    minWidth: 120,
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  grid: {
    marginTop: '20px',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gridGap: '2rem'
  },
  groupLabel: {
    fontSize: '12px',
    fontWeight: 'bolder',
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.74)'
  },
  date: {
    width: 160,
  },
  selectItem: {
    whiteSpace: 'normal'
  },
}));

const ApptTypeSelector = ({
  classes,
  pros,
  value,
  handleChange,
  renderNewTypes,
  label,
  id
}) => {
  return <FormControl variant="standard" className={classes.formControl} style={{ minWidth: '170px', width: '48%', maxWidth: 320 }}>
    <InputLabel id={`${id}-label`}>{label}</InputLabel>
    <Select
      labelId={`${id}-label`}
      id={id}
      multiple
      value={value}
      onChange={handleChange}
      input={<Input />}
      renderValue={renderNewTypes}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        getContentAnchorEl: null,
        PaperProps: {
          style: {
            maxHeight: 500,
            maxWidth: 260,
          },
        },
      }}
    >
      {pros.map(p => ([
        <ListSubheader className={classes.groupLabel} key={p.id}>{p.name}</ListSubheader>,
        p.types.map(t => (
          <MenuItem key={t.id} value={t.id} className={classes.selectItem}>
            <Checkbox checked={value.indexOf(t.id) > -1} />
            <ListItemText primary={`(${t.internalName}) ${t.name}`} />
          </MenuItem>
        ))
      ]))}
    </Select>
  </FormControl>;
};

const dateFormat = DateTimeFormatter.ofPattern('MM/dd/YYYY').withLocale(Locale.US);

const formatDate = d => LocalDate.parse(d).format(dateFormat);

const emptyObject = {};

const nestTypesInPros = state => {
  const pros = pathOr([], ['professionals', 'professionals'], state);
  const types = pathOr([], ['professionals', 'types'], state);

  return pros.filter(({ isHidden }) => !isHidden).map(pro => {
    const proTypes = types.filter(({ professionalId }) => professionalId === pro.id);
    return {
      id: pro.id,
      name: `${pro.displayFirstName} ${pro.displayLastName}`,
      types: proTypes
    };
  });

};

let searchTimeout = null;

export default function OverviewReport() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    data,
    dateRange: dr,
    errorMessage,
    loading,
    types,
    settingsErrorMessage,
    adjustmentApptTyIds,
    newPatientApptTyIds,
    officeId,
    pros,
    proState,
  } = useSelector(state => ({
    ...state.overviewReport,
    officeId: pathOr(0, ['login', 'office', 'id'], state),
    types: pathOr([], ['professionals', 'types'], state),
    pros: nestTypesInPros(state),
    proState: state.professionals,
  }));
  const [dateRange, setDateRange] = useState({
    start: LocalDate.now().toString(),
    end: LocalDate.now().plusDays(1).toString()
  });
  const [period, setPeriod] = useState('ThisWeek');
  const [compareAs, setCompareAs] = useState('percentage');

  const [newTypes, setNewTypes] = useState([]);

  const [adjustTypes, setAdjustTypes] = useState([]);

  // When new settings load in from redux,
  // change the local state to include them
  useEffect(() => {
    setAdjustTypes(adjustmentApptTyIds);
    setNewTypes(newPatientApptTyIds);
  }, [adjustmentApptTyIds, newPatientApptTyIds]);

  const saveSettings = ({ newTypes, adjustTypes }) => {
    console.log('save settings', { newTypes, adjustTypes });
    dispatch(saveOverviewSettings({
      newApptTys: newTypes,
      adjustmentApptTys: adjustTypes
    }));
  };

  const handleAdjChange = (event) => {
    const v = event.target.value;
    setAdjustTypes(v);
    saveSettings({ newTypes, adjustTypes: v });
  };

  const handleChange = (event) => {
    const v = event.target.value;
    setNewTypes(v);
    saveSettings({ newTypes: v, adjustTypes });
  };

  const renderNewTypes = selected => selected.map(pipe(
    id => types.find(propEq('id', id)),
    defaultTo(emptyObject),
    prop('name')
  )).join(', ');

  const selectDate = prop => newDate => {
    if (!newDate || newDate instanceof Error) {
      return;
    }
    setPeriod('Custom');
    setDateRange(s => ({ ...s, [prop]: newDate.toString() }));
  };

  useTitle('Office Stats Report');

  useEffect(() => {
    dispatch(getProfessionals());
    dispatch(getOverviewSettings());
  }, [officeId]);


  useEffect(() => {
    setDateRange({ start: dr.startDay, end: dr.endDay });
  }, [dr.startDay, dr.endDay]);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    
    if (newTypes.length > 0 && adjustTypes.length > 0) {
      searchTimeout = setTimeout(() => {
        dispatch(fetchOverviewData({
          newApptTys: newTypes,
          period,
          startDay: dateRange.start,
          endDay: dateRange.end,
          adjustmentApptTys: adjustTypes
        }));
      }, 350);
    }
  }, [period, dateRange.start, dateRange.end, newTypes, adjustTypes]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const filteredProfessionals = proState
    .professionals
    .filter(p => !p.isHidden);

  return (
    <>
      <Header
        title='Office Stats Report'
        leftIcons={[
          <>&nbsp;&nbsp;</>,
          <FilterStartEndDate
            dateRange={dateRange}
            setDateRange={(value) => {
              setPeriod('Custom');
              setDateRange(value);
            }}
          />,
          <FilterApptTypes
            allTypes={proState.types}
            title="New Patient Types"
            setApptTypes={(apptTypes) => setNewTypes(apptTypes.map(t => t.id))}
            professionals={filteredProfessionals}
            selectedTypes={proState.types.filter(t => newTypes.find(value => value === t.id))}
            save={({ types: apptTypes }) => saveSettings({ newTypes: apptTypes.map(t => t.id), adjustTypes })}
          />,
          <FilterApptTypes
            allTypes={proState.types}
            title="Regular Adjustment Types"
            setApptTypes={(apptTypes) => setAdjustTypes(apptTypes.map(t => t.id))}
            professionals={filteredProfessionals}
            selectedTypes={proState.types.filter(t => adjustTypes.find(value => value === t.id))}
            save={({ types: apptTypes }) => saveSettings({ adjustTypes: apptTypes.map(t => t.id), newTypes })}
          />,
          <FilterSelect
            items={['Percentage', 'Value']}
            selected={compareAs === 'value' ? 'Value' : 'Percentage'}
            setSelected={(value) => setCompareAs(value === 'Value' ? 'value' : 'percentage')}
            Icon={HashtagIcon}
            title='Compare As'
            noneText='Percentage'
            horizontalMenu='left'
          />
        ]}
        rightIcons={[
          <TimeRangeSelector value={period} onChange={setPeriod} isNew />
        ]}
        onlyIconsWidth={1000}
      />
      <div
        className={classes.root}
        style={{ paddingTop: 20 }}>
        <Row>
          <span style={{ opacity: 0.8 }}>
            Starts on {formatDate(dateRange.start)} and ends on {formatDate(dateRange.end)}
          </span>
          &nbsp;&nbsp;
          {loading && <CircularProgress size={20} />}
        </Row>
        <Row>
          {errorMessage && <p>{errorMessage}</p>}
          {settingsErrorMessage && <p>{settingsErrorMessage}</p>}
        </Row>
        <div className={classes.grid}>
          {data.map(({ name, value, change, previous }) => (
            <StatBox key={name} name={name} value={value} change={change} previous={previous} changeAs={compareAs} />
          ))}
        </div>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div style={{ padding: '20px' }}>
            <Row>
              <FormControl variant="standard" className={classes.formControl}>
                <TextField
                  className={classes.date}
                  label="Start Date"
                  value={LocalDate.parse(dateRange.start)}
                  onChange={(event) => selectDate('start')(event.target.value)}
                  type='date'
                  variant='standard'
                />
              </FormControl>
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              <FormControl className={classes.formControl}>
                <TextField
                  className={classes.date}
                  label="End Date"
                  value={LocalDate.parse(dateRange.end)}
                  onChange={(event) => selectDate('end')(event.target.value)}
                  type='date'
                  variant='standard'
                />
              </FormControl>
              &nbsp;&nbsp;
              &nbsp;&nbsp;
              <FormControl variant="standard" className={classes.formControl}>
                <InputLabel shrink htmlFor="change-type-select" id="change-type-label">Compare As</InputLabel>
                <Select
                  labelId='change-type-label'
                  value={compareAs}
                  onChange={e => setCompareAs(e.target.value)}
                  id='change-type-select'>
                  <MenuItem value='percentage'>Percentage</MenuItem>
                  <MenuItem value='value'>Value</MenuItem>
                </Select>
              </FormControl>
            </Row>

            <Row>
              <ApptTypeSelector
                classes={classes}
                label="New Patient Types"
                value={newTypes}
                handleChange={handleChange}
                renderNewTypes={renderNewTypes}
                pros={pros}
                id="new-type-select-box" />
              &nbsp;
              <ApptTypeSelector
                classes={classes}
                label="Regular Adjustment Types"
                value={adjustTypes}
                handleChange={handleAdjChange}
                renderNewTypes={renderNewTypes}
                pros={pros}
                id="adj-type-select-box" />
            </Row>
          </div>
        </Popover>
      </div>
    </>
  );
}
