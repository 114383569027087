import React, { useEffect, useState } from 'react';
import { format } from '../../../services/joda.js';
import {
  LocalTime,
  LocalDate,
} from '@js-joda/core';
import {
  Paper,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  FormHelperText,
  TextField,
  Menu,
  ListItemIcon,
  DialogContentText,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ScheduleIcon from '@mui/icons-material/Schedule';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CopyIcon from '@mui/icons-material/FileCopy';
import { EditTimes } from '../../../components/hours';
import Modal from '../../../components/Modal/Modal.component';
import HeaderButton from '../../../components/HeaderButton/HeaderButton.component';
import {
  find, pipe, ifElse,
  propEq, assoc, identity,
  propOr, isEmpty, tail
} from 'ramda';

const showTime = time => {
  return format(LocalTime.parse(time), 'h:mm a');
};

export const ShowGroupedSpecialHours = ({
  edit,
  remove,
  hoursGroup,
  locations = [],
}) => {
  const location = find(
    pipe(
      ifElse(
        propEq('id', 'main'),
        assoc('id', null),
        identity
      ),
      propEq('id', hoursGroup.locationId),
    ),
    locations
  );

  const hours_array = hoursGroup.group;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [actionId, setActionId] = React.useState(null);

  const handleMenuClick = (id = 0) => (event) => {
    setAnchorEl(event.currentTarget);
    setActionId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    edit(actionId);
    setActionId(null);
    handleMenuClose();
  };

  const handleCopy = () => {
    sessionStorage.setItem('single-day-hours', JSON.stringify(hoursGroup.hours));
    handleMenuClose();
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleStartDelete = () => {
    handleMenuClose();
    handleClickOpen();
  };

  const handleFinalDelete = () => {
    remove(actionId);
    setActionId(null);
    setOpen(false);
  };

  const sortedHours = hoursGroup.hours;

  return (
    <Paper style={{ padding: '20px', marginTop: '20px', marginBottom: '20px' }}>
      <div style={{ fontSize: '14px', marginBottom: '10px' }} className="hours-header">
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
          <Typography variant="h4">{hoursGroup.name}</Typography>
          {propOr(null, 'name', location)}
        </div>
        <Typography variant="body1" style={{ color: '#888' }}>{hoursGroup.internalName}</Typography>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <List style={{ marginLeft: '-20px', marginRight: '40px' }}>
          {hours_array.map((hours, i) => (
            <ListItem key={i} aria-label="edit">
              <div style={{ color: '#888', display: 'flex', minWidth: '56px' }}>
                {i === 0 ? <DateRangeIcon /> : null}
              </div>
              <ListItemText primary={format(LocalDate.parse(hours.date), 'MMM d yyyy')} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="actions" onClick={handleMenuClick(hours.id)}>
                  <MoreVertIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <List>
          {sortedHours.length === 0 &&
            <ListItem>
              <ListItemAvatar style={{ color: '#888', display: 'flex' }}>
                <ScheduleIcon />
              </ListItemAvatar>
              <ListItemText primary={hoursGroup.isEvent ? 'All day' : 'Closed'} />
            </ListItem>
          }
          {sortedHours.length > 0 && sortedHours.map((hour, i) => (
            <ListItem key={i}>
              <div style={{ color: '#888', display: 'flex', minWidth: '56px' }}>
                {i === 0 ? <ScheduleIcon /> : null}
              </div>
              <ListItemText primary={`${showTime(hour.open)} - ${showTime(hour.close)}`} />
            </ListItem>
          ))}
        </List>
      </div>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <ListItem button aria-label="edit" onClick={handleEdit}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary='Edit' />
        </ListItem>
        <ListItem button aria-label="copy" onClick={handleCopy}>
          <ListItemIcon>
            <CopyIcon />
          </ListItemIcon>
          <ListItemText primary='Copy hours' />
        </ListItem>
        <ListItem button aria-label="delete" onClick={handleStartDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary='Delete' />
        </ListItem>
      </Menu>


      <Modal
        open={open}
        onClose={handleClose}
        size='xs'
        title='Confirm delete'
        className='sked-test-special-office-hours-delete-modal'
        buttons={[
          <HeaderButton
            title='Delete'
            onClick={handleFinalDelete}
            color='danger'
            className='sked-test-special-office-hours-delete-modal-button-delete'
          />
        ]}
      >
        <div>
          <DialogContentText id="alert-dialog-description">
            Do you want to delete this special office hour date?
          </DialogContentText>
        </div>
      </Modal>
    </Paper>
  );
};


let timeout = null;
let nameTimeout = null;

export const EditSpecialHours = ({
  classes,
  isEvent = false,
  eventPatch,
  hours,
  timeChanged,
  removeOpenPushed,
  closed,
  add,
  dateChanged,
  dateToChanged,
  nameChanged,
  internalNameChanged,
  locationChanged,
  hasLocations = false,
  locations = [],
}) => {
  const [innerInternal, setInnerInternal] = useState(hours.internalName);
  const [innerName, setInnerName] = useState(hours.name);

  useEffect(() => {
    eventPatch(isEvent);
  }, [isEvent]);
  useEffect(() => {
    setInnerInternal(hours.internalName);
  }, [hours.internalName]);
  useEffect(() => {
    setInnerName(hours.name);
  }, [hours.name]);

  return (
    <div className={classes.editHoursRoot}>
      <div style={{ flexGrow: 1 }}>
        {hasLocations && !isEmpty(location) &&
          <div className={classes.formControl}>
            <FormControl style={{ minWidth: 256 }}>
              <InputLabel id="location-label">Location</InputLabel>
              <Select
                labelId="location-select-label"
                id="provider-simple-select"
                value={hours.locationId ? hours.locationId : ''}
                error={!hours.locationId}
                onChange={e => {
                  const v = e.target.value;
                  locationChanged(v === '' ? undefined : v);
                }}
              >
                {tail(locations).map(({ id, name }) =>
                  <MenuItem key={id} value={id}>{name}</MenuItem>
                )}
              </Select>
              {!hours.locationId &&
                <FormHelperText style={{ color: '#d32f2f' }}>
                  Required
                </FormHelperText>}
            </FormControl>
          </div>}
        <div className={classes.formControl}>
          <TextField
            value={innerInternal || ''}
            label="Internal Name"
            helperText="For office use only"
            onChange={(e) => {
              const value = e.target.value;
              setInnerInternal(value);
              if (timeout) {
                clearTimeout(timeout);
              }
              timeout = setTimeout(() => {
                internalNameChanged(value);
              }, 400);
            }}
          />
        </div>
        <div className={classes.formControl}>
          <TextField
            value={innerName || ''}
            label="Brief Reason"
            maxLength="30"
            onChange={(e) => {
              const value = e.target.value;
              setInnerName(value);
              if (nameTimeout) {
                clearTimeout(nameTimeout);
              }
              nameTimeout = setTimeout(() => {
                nameChanged(value);
              }, 400);
            }}
          />
          <FormHelperText>Your practice members will see this in the SKED App</FormHelperText>
        </div>
        <div className={classes.formControl}>
          <TextField
            type='date'
            label={hours.id ? 'Date' : 'Start Date'}
            value={hours.date || ''}
            onChange={(e) => {
              dateChanged(e.target.value);
              if (hours.date === hours.dateTo)
                dateToChanged(e.target.value);
            }} />
        </div>
        {!hours.id && <div className={classes.formControl}>
          <TextField
            type='date'
            label="End Date"
            value={hours.dateTo}
            placeholder='mm / dd / yyyy'
            onChange={(e) => dateToChanged(e.target.value)} />
        </div>}
      </div>
      <div style={{ marginTop: '20px' }}>
        <Typography variant="h5">Hours</Typography>

        <EditTimes
          times={hours.hours}
          isEvent={isEvent}
          add={add}
          closed={closed}
          timeChanged={timeChanged}
          removeOpenPushed={removeOpenPushed}
        />
      </div>
    </div>
  );
};
