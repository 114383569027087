import React, { ComponentProps } from 'react';

const RocketShipIcon = ({ color, ...props }: ComponentProps<'svg'>) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} width="30.827" height="29.486" viewBox="0 0 30.827 29.486">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_7022" data-name="Rectangle 7022" width="30.828" height="29.486" fill={color || '#fafafa'}/>
        </clipPath>
      </defs>
      <g id="Group_17901" data-name="Group 17901" transform="translate(0 0)">
        <g id="Group_7764" data-name="Group 7764" transform="translate(0 0)" clip-path="url(#clip-path)">
          <path id="Path_6007" data-name="Path 6007" d="M7.967,26.134c.223.385.43.713.6,1.059.042.085-.015.241-.065.346a8.874,8.874,0,0,0-.958,2.831,3.107,3.107,0,0,0,.034.841.722.722,0,0,0,.689.657,6.233,6.233,0,0,0,1.567-.125,12.172,12.172,0,0,0,3.379-1.519c-4.637-3.266-6.531-7.584-4.676-13.051a10.1,10.1,0,0,1,8.835-7.032,10.444,10.444,0,0,1,9.247,3.439c-.008.037-.015.074-.023.112a1.413,1.413,0,0,1-.345,0c-1.025-.27-2.05-.539-3.068-.83a.725.725,0,0,0-.8.2c-.679.691-1.369,1.371-2.052,2.059a.586.586,0,0,0-.005.973c.633.636,1.27,1.267,1.9,1.9-.665.862-.665.862.02,1.662-.161.236-.335.472-.489.719A6.979,6.979,0,0,0,20.7,24.024a.71.71,0,0,1-.239.551,42.413,42.413,0,0,1-6.713,5.308q-.179.114-.355.232c-.017.011-.023.037-.055.091l1.132.576a14.121,14.121,0,0,1-5.426,2.27A2.267,2.267,0,0,1,6.387,30.36a12.258,12.258,0,0,1,1.58-4.226" transform="translate(-3.844 -6.071)" fill={color || '#fafafa'}/>
          <path id="Path_6008" data-name="Path 6008" d="M52.651,24.521l-4-4c.068-.074.151-.168.239-.257,1.512-1.513,3.029-3.022,4.535-4.54a9.232,9.232,0,0,1,6.2-2.8c.142-.009.284-.008.425-.007a1.277,1.277,0,0,1,.163.023,7.919,7.919,0,0,1-.317,2.69,9.123,9.123,0,0,1-2.384,4.038q-2.379,2.384-4.764,4.764c-.033.033-.07.061-.1.089m3.558-5.867a1.742,1.742,0,0,0,1.732-1.732,1.7,1.7,0,0,0-1.706-1.673,1.726,1.726,0,0,0-1.728,1.7,1.7,1.7,0,0,0,1.7,1.71" transform="translate(-29.407 -7.807)" fill={color || '#fafafa'}/>
          <path id="Path_6009" data-name="Path 6009" d="M27.011,51.553c.474-.326.939-.641,1.4-.965a47.076,47.076,0,0,0,5.377-4.436.65.65,0,0,1,.352-.191,7.033,7.033,0,0,0,4.524-1.5c.289.181.564.481.854.5.27.014.555-.276.936-.489.188.208.455.473.679.77.059.078.023.267-.024.383a10.768,10.768,0,0,1-14.013,6,.617.617,0,0,1-.084-.061" transform="translate(-16.326 -26.872)" fill={color || '#fafafa'}/>
          <path id="Path_6010" data-name="Path 6010" d="M49.02,38.8A5.67,5.67,0,0,1,45.5,39.975a5.815,5.815,0,0,1,1.209-3.519L49.02,38.8" transform="translate(-27.504 -22.035)" fill={color || '#fafafa'}/>
          <path id="Path_6011" data-name="Path 6011" d="M48.628,20.768,46.17,23.236l-1.58-1.58c.473-.472.968-.972,1.472-1.462a.307.307,0,0,1,.249-.063c.768.2,1.533.419,2.317.637" transform="translate(-26.951 -12.164)" fill={color || '#fafafa'}/>
          <path id="Path_6012" data-name="Path 6012" d="M64.522,40.2l-1.57-1.566L65.4,36.2c.033.1.086.229.124.366.162.589.314,1.18.483,1.767a.44.44,0,0,1-.12.5c-.466.448-.913.915-1.36,1.368" transform="translate(-38.05 -21.88)" fill={color || '#fafafa'}/>
          <path id="Path_6013" data-name="Path 6013" d="M1.15,41.592a1.134,1.134,0,1,1-.032,2.267,1.134,1.134,0,1,1,.032-2.267" transform="translate(0 -25.139)" fill={color || '#fafafa'}/>
          <path id="Path_6014" data-name="Path 6014" d="M6.969,14.1a.566.566,0,1,1-.571-.551.574.574,0,0,1,.571.551" transform="translate(-3.528 -8.188)" fill={color || '#fafafa'}/>
          <path id="Path_6015" data-name="Path 6015" d="M49.4.574A.566.566,0,1,1,48.265.54.566.566,0,0,1,49.4.574" transform="translate(-29.172 0)" fill={color || '#fafafa'}/>
          <path id="Path_6016" data-name="Path 6016" d="M66.548,52.2a.566.566,0,1,1-.569-.553.574.574,0,0,1,.569.553" transform="translate(-39.54 -31.219)" fill={color || '#fafafa'}/>
          <path id="Path_6017" data-name="Path 6017" d="M52.664,72.23a.566.566,0,1,1-.57-.551.574.574,0,0,1,.57.551" transform="translate(-31.148 -43.325)" fill={color || '#fafafa'}/>
          <path id="Path_6018" data-name="Path 6018" d="M67.539,22.221a.592.592,0,0,1-.563.574.551.551,0,0,1-.561-.558.539.539,0,0,1,.548-.564.564.564,0,0,1,.577.549" transform="translate(-40.142 -13.099)" fill={color || '#fafafa'}/>
        </g>
      </g>
    </svg>
  );
};

export default RocketShipIcon;
