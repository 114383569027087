import React, { useState } from 'react';
import {
  TextField,
  TableBody,
  TableRow,
} from '@mui/material';
import * as R from 'ramda';
import { TableContainer, BodyCell } from '../../../components/CustomTable';
import Modal from '../../../components/Modal/Modal.component';
import HeaderButton from '../../../components/HeaderButton/HeaderButton.component';

const AddProfessional = (props) => {
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
  });

  const update = (data) => {
    setState(R.merge(state, data));
  };

  const table = ({
    firstName,
    lastName,
  }) => {
    return (
      <TableContainer noHover style={{ overflowY: 'auto' }}>
        <TableBody>
          <TableRow key={0}>
            <BodyCell><b>First Name:</b></BodyCell>
            <BodyCell>
              <TextField
                value={firstName}
                style={{ width: '100%' }}
                onChange={(e) => update({ firstName: e.target.value })}
                label="First Name"
              />
            </BodyCell>
          </TableRow>
          <TableRow key={1}>
            <BodyCell><b>Last Name:</b></BodyCell>
            <BodyCell>
              <TextField
                value={lastName}
                style={{ width: '100%' }}
                onChange={(e) => update({ lastName: e.target.value })}
                label="Last Name"
              />
            </BodyCell>
          </TableRow>
        </TableBody>
      </TableContainer>
    );
  };

  const disabled = !R.reduce((acc, item) => acc && !R.isEmpty(item), true, R.values(state));
  
  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.onClose();
        update({ firstName: '', lastName: '' });
      }}
      title='Add professional'
      className='sked-test-appt-types-add-professional-modal'
      buttons={[
        <HeaderButton
          title='Save'
          color='primary'
          disabled={disabled}
          className='sked-test-appt-types-add-professional-modal-button-save'
          onClick={() => {
            props.save(state);
            props.onClose();
            update({ firstName: '', lastName: '' });
          }}
        />
      ]}
    >
      <div>
        {table(state)}
      </div>
    </Modal>
  );
};

export default AddProfessional;
