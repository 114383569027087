import React from 'react';

type Props = {
  color?: string;
};

const StampIcon = ({ color }: Props) => {
  return (
    <svg id="uuid-6278b6c7-aee8-4b67-8034-b7835f22ae02" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <g id="uuid-d0b6d8de-9902-42ff-9f38-617a01034f59" data-name="Group 8821" fill={color || '#FFF'}>
        <rect id="uuid-115a4be9-868f-4117-b8e0-833abd5de246" data-name="Rectangle 2834" x=".0089" y="31.7873" width="49.9822" height="18.2127" />
        <rect id="uuid-b2e045e2-3156-48af-8fae-c6326cce1a0a" data-name="Rectangle 2835" x=".0089" y="24.4649" width="49.9802" height="4.3462" />
        <path id="uuid-5e84f03c-b934-43b1-9f9a-780c52fc908c" data-name="Path 4669" d="m18.6505,6.3376C18.6592,2.8287,21.5109-.0088,25.0198,0c3.5089.0088,6.3464,2.8604,6.3376,6.3694-.0088,3.5027-2.8507,6.3376-6.3535,6.3376-3.5089,0-6.3535-2.8445-6.3535-6.3535,0-.0053,0-.0106,0-.0159" />
        <path id="uuid-b72dd6ff-7da5-4e23-b34c-926dd943c848" data-name="Path 4670" d="m36.7242,21.4788H13.2957c-.0331-.9749.2121-1.939.7068-2.7797,1.0039-1.9125,3.0026-3.0933,5.1622-3.0497,3.9431-.0298,7.8843-.0159,11.8274,0,3.0326.0595,5.5243,2.412,5.7579,5.4362v.3971" />
      </g>
    </svg>
  );
};

export default StampIcon;
