import { useState, useEffect } from 'react';
import { BehaviorSubject, of, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap, catchError } from 'rxjs/operators';

export function useTypeahead(promise, defaultData, defaultInput = '') {
  const [state, setState] = useState({
    data: defaultData,
    loading: false,
    errorMessage: '',
    noResults: false
  });

  const [subject, setSubject] = useState(null);

  useEffect(() => {
    if (subject === null) {
      const sub = new BehaviorSubject(defaultInput);
      setSubject(sub);
    } else {
      const observable = subject.pipe(
        filter(term => !!term),
        distinctUntilChanged(),
        debounceTime(200),
        switchMap(term =>
          merge(
            of({ loading: true, errorMessage: '', noResults: false }),
            Promise.resolve(promise(term))
              .then(data => ({ data, loading: false, noResults: data.length === 0 }))
              .catch(e => ({
                loading: false,
                errorMessage: 'An application error occured: ' + e.message
              }))
          )
        ),
        catchError(e => ({
          loading: false,
          errorMessage: 'An application error occured: ' + e.message
        }))
      ).subscribe(newState => {
        setState(s => Object.assign({}, s, newState));
      }, e => {
        console.error(e);
      });

      return () => {
        observable.unsubscribe();
        subject.unsubscribe();
      };
    }
  }, [subject]);

  const onChange = str => {
    if (subject) {
      return subject.next(str);
    }
  };

  return {
    ...state,
    state,
    setState,
    onChange,
    invoke: onChange
  };
}
