import * as at from '../../../../actionTypes';
import * as R from 'ramda';
import {
  now,
  format,
} from '../../../../services/joda.js';
import {
  LocalDate,
} from '@js-joda/core';

const init = {
  busy: false,
  aptBusy: false,
  aptsBusy: false,
  msgBusy: false,
  state: 'CLIENT',
  client: {},
  appointments: [],
  group: [],
  groupId: undefined,
  groupState: 'GROUP',
  messages: [],
  threadMessages: [],
  optoutList: [],
  messageThreadBody: '',
  selectedMessage: {},
  selectedAppointment: {},
  startDate: format(now('date'), 'yyyy-MM-dd'),
  endDate: format(now('date').plusMonths(1), 'yyyy-MM-dd'),
  page: 1,
  messagePage: 1,
  filter: 'All',
  snackbar: false,
  memo: '',
  tab: 'apts',
  frequencies: [],
  newFrequency: { number: '0', unit: 'Week' },
  tags: [],
  isSending: false,
  app: null,
};

const createFrequency = (freqs) => R.pipe(
  R.map(R.cond([
    [R.has('error'), R.always({ number: '1', unit: 'Week' })],
    [R.T, (f) => {
      const number = f.number;
      if (R.has('Num', number)) {
        return R.mergeRight(f, { number: R.prop('Num', number) });
      } else {
        const num = R.prop('EveryOther', number);
        const str = num === 1 ? 'Every Other' : 'Every ' + num;
        console.log(str);
        return R.mergeRight(f, { number: str });
      }
    }]
  ])),
  R.sort(R.ascend(R.prop('startDate')))
)(freqs);

const currentFreq = R.pipe(
  R.filter(
    (freq) =>
      now('date').isAfter(LocalDate.parse(freq.startDate))),
  R.sort(R.descend(R.prop('startDate'))),
  R.head
);


export default (clientDialog = init, action) => {
  switch (action.type) {
    case at.CLIENT_PATCH:
      return R.merge(clientDialog, action.data);
    case at.CLIENT_RESET:
      return R.merge(clientDialog, init);
    case at.CLIENT_REMOTE_GET:
      if (action.state === 'REQUEST') {
        return R.merge(clientDialog, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const frequencies = createFrequency(R.pathOr({ error: '' }, ['data', 'freq', 'data'])(action));
        return R.merge(clientDialog, {
          busy: false,
          client: R.pathOr({}, ['data', 'client'])(action),
          group: R.pathOr([], ['data', 'group', 'data'])(action),
          groupId: action.data.groupId,
          appointments: R.pathOr([], ['data', 'appointments', 'data', 'data'])(action),
          messages: R.pathOr([], ['data', 'messages', 'data', 'data'])(action),
          threadMessages: R.pathOr([], ['data', 'threadMessages'])(action),
          optoutList: R.pathOr([], ['data', 'optoutList'])(action),
          messageTotalPages: R.pathOr([], ['data', 'messages', 'data', 'totalPages'])(action),
          messagePage: 1,
          frequencies,
          currentFreq: currentFreq(frequencies),
          tags: R.has('error')(action.data.tags) ? [] : R.sortBy(R.prop('tag'))(action.data.tags.data),
          app: R.has('error')(action.data.appInfo) ? null : action.data.appInfo.data,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(clientDialog, {
          busy: false,
        });
      }
      return clientDialog;
    case at.CLIENT_REMOTE_APTS_GET:
      if (action.state === 'REQUEST') {
        return R.merge(clientDialog, {
          aptsBusy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(clientDialog, {
          appointments: R.pathOr([], ['data', 'appointments'])(action),
          snackbar: !R.isNil(action.data.msg),
          memo: action.data.msg ? action.data.msg : '',
          aptsBusy: false,
        });
      }
      return clientDialog;
    case at.CLIENT_REMOTE_POST_MESSAGE: {
      const dataLens = R.lensPath(['data', 'data']);
      if (action.state === 'REQUEST') {
        return R.evolve({
          threadMessages: R.over(dataLens, R.prepend(action.params)),
          messageThreadBody: R.always(''),
          isSending: R.T,
        }, clientDialog);
      } else if (action.state === 'RESPONSE') {
        return R.evolve({
          threadMessages: R.pipe(
            R.over(dataLens, R.tail),
            R.over(dataLens, R.prepend(action.data.msg)),
          ),
          messageThreadBody: R.always(''),
          isSending: R.F,
        }, clientDialog);
      } else if (action.state === 'ERROR') {
        return R.merge(clientDialog, {
          aptsBusy: false,
        });
      }
      return clientDialog;
    }
    case at.CLIENT_REMOTE_APT_GET:
      if (action.state === 'REQUEST') {
        return R.merge(clientDialog, {
          state: 'VIEW_APT',
          aptBusy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(clientDialog, {
          selectedAppointment: action.data.appointment,
          aptBusy: false,
        });
      }
      return clientDialog;
    case at.CLIENT_REMOTE_MESSAGES_GET:
      if (action.state === 'REQUEST') {
        return R.merge(clientDialog, {
          msgBusy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(clientDialog, {
          messages: action.data.messages,
          messageTotalPages: action.data.messageTotalPages,
          messagePage: action.data.page,
          msgBusy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(clientDialog, {
          msgBusy: false,
        });
      }
      return clientDialog;
    case at.CLIENT_CLIENT_PATCH: {
      const stuff = R.evolve({
        client: (c) => R.merge(c, action.data),
      }, clientDialog);
      return stuff;
    }
    case at.CLIENT_FREQ_ADD: {
      // Find the next available startDate (to prevent duplicates)
      let newDate = format(now('date'), 'yyyy-MM-dd');
      while (R.find(R.propEq('startDate', newDate), clientDialog.frequencies)) {
        newDate = format(LocalDate.parse(newDate).plusDays(1), 'yyyy-MM-dd');
      }
      const stuff = R.evolve({
        frequencies: R.append({
          startDate: newDate,
          number: 1,
          unit: 'Week',
          clientId: clientDialog.client.id,
        })
      }, clientDialog);
      return stuff;
    }
    case at.CLIENT_FREQ_PATCH: {
      const stuff = R.evolve({
        frequencies: R.adjust(action.data.index, R.mergeLeft(action.data.data)),
      }, clientDialog);
      return stuff;
    }
    case at.CLIENT_FREQ_ORDER: {
      const stuff = R.evolve({
        frequencies: R.sort(R.ascend(R.prop('startDate'))),
      }, clientDialog);
      return stuff;
    }
    case at.CLIENT_FREQ_REMOVE: {
      const stuff = R.evolve({
        frequencies: R.remove(action.data, 1),
      }, clientDialog);
      return stuff;
    }
    case at.SKED_CANCEL_APT:
      return R.evolve({
        appointments: R.filter(({ id }) => id !== action.data.id),
        snackbar: R.T,
      }, clientDialog);
    case at.CLIENT_PUT_FREQ:
      if (action.state === 'REQUEST') {
        return R.merge(clientDialog, {
          aptsBusy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const freq = action.data;
        const frequencies = createFrequency(freq);
        return R.merge(clientDialog, {
          frequencies,
          currentFreq: currentFreq(frequencies),
          aptsBusy: false,
          snackbar: true,
          memo: 'Updated client frequency'
        });
      } else if (action.state === 'ERROR') {
        return R.merge(clientDialog, {
          aptsBusy: false,
        });
      }
      return clientDialog;
    case at.CLIENT_REMOTE_CREATE_GROUP:
      if (action.state === 'REQUEST') {
        return R.merge(clientDialog, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const {
          groupId,
          groupState,
        } = action.data;
        return R.merge(clientDialog, {
          groupId,
          groupState,
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(clientDialog, {
          busy: false,
        });
      }
      return clientDialog;
    case at.CLIENT_REMOTE_TAG_DELETE:
      if (action.state === 'REQUEST') {
        return clientDialog;
      } else if (action.state === 'RESPONSE') {
        return R.merge(clientDialog, {
          tags: R.without([action.data], clientDialog.tags),
        });
      } else if (action.state === 'ERROR') {
        return clientDialog;
      }
      return clientDialog;
    case at.CLIENT_REMOTE_ADD_TAG_TO_CLIENT_POST:
      if (action.state === 'REQUEST') {
        return clientDialog;
      } else if (action.state === 'RESPONSE') {
        return R.merge(clientDialog, {
          tags: R.prepend(action.data, clientDialog.tags),
        });
      } else if (action.state === 'ERROR') {
        return clientDialog;
      }
      return clientDialog;
    default:
      return clientDialog;
  }
};
