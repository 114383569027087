import React from 'react';
import {
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  FormHelperText,
  TableBody,
  IconButton,
  Input,
  ClickAwayListener,
  Menu,
  MenuItem,
  Checkbox,
  FormControlLabel,
  TextField
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SaveIcon from '@mui/icons-material/Save';
import CachedIcon from '@mui/icons-material/Cached';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { merge, assocPath, assoc, pipe, map, identity as I } from 'ramda';
import { LocalDate, LocalTime, DateTimeFormatter } from '@js-joda/core';
import { Locale } from '@js-joda/locale_en-us';

import { Row, Spacer } from '../../../components/PageHeader';
import { usePromise } from '../../../services/promise.hook';
import {
  getBlocks, postBlocks, putBlock, deleteBlock
} from '../blocks.api.js';
import { requiredValidation, initForm, getValue, getFormObject, submitForm, getFirstErrorOrDefault, updateValue } from '../../../services/form-validation.js';
import { parseTime } from '../../../services/parseTime.service.js';

const timeValidation = (prop, f) => {
  const value = getValue(prop, f);
  const p = parseTime(value);
  const error = p.error;
  if (!error) {
    return { valid: true };
  }
  return {
    valid: false,
    message: error
  };
};

const endGreaterThanStartValidation = (startField = 'start') => (prop, f) => {
  const value = getValue(prop, f);
  const otherValue = getValue(startField, f);

  const startTime = parseTime(otherValue);
  const endTime = parseTime(value);

  if (endTime.error || startTime.error) {
    return {
      valid: false,
      message: endTime.error ? 'The time is not valid' : 'The start time is not valid'
    };
  }
  if (endTime.parsed >= startTime.parsed) {
    return { valid: true };
  }
  return {
    valid: false,
    message: 'End time should be greater than the start time'
  };
};

const formObject = {
  date: {
    default: LocalDate.now(),
    validation: [
      requiredValidation('A date is required.')
    ]
  },
  includeStart: {
    default: false,
    validation: []
  },
  start: {
    default: '12 pm',
    validation: [
      requiredValidation('A start time is required'),
      timeValidation
    ]
  },
  includeEnd: {
    default: true,
    validation: []
  },
  end: {
    default: '1 pm',
    validation: [
      requiredValidation('An end time is required'),
      endGreaterThanStartValidation('start')
    ]
  }
};

const formatToApi = (data, scheduleId) => {
  console.log('data to format', data);
  const date = data.date.toString();
  const start = parseTime(data.start).parsed;
  const end = parseTime(data.end).parsed;
  return {
    blockId: data.blockId,
    date,
    scheduleId: Number(scheduleId),
    startTime: data.includeStart ? {
      Include: start
    } : {
      Exclude: start
    },
    endTime: data.includeEnd ? {
      Include: end
    } : {
      Exclude: end
    }
  };
};

const BlockToAdd = ({ blocksPost, blocksGet, scheduleId, onClick }) => {
  const [blockToAdd, setBlockToAdd] = React.useState(initForm(formObject));
  const setTime = prop => value => setBlockToAdd(assocPath([prop, 'value'], value));
  const time = blockToAdd;


  const submitBlockToAdd = (e) => {
    e.preventDefault();
    const validatedForm = submitForm(blockToAdd);
    setBlockToAdd(validatedForm);
    if (!validatedForm.__form.isValid) {
      console.log('form not valid');
      return;
    }
    const data = getFormObject(validatedForm);
    const dataFormated = formatToApi(data, scheduleId);
    blocksPost.invoke(dataFormated).then(() => {
      return blocksGet.invoke(scheduleId);
    });
  };
  return (<TableRow onClick={onClick}>
    <TableCell>
      <TextField
        label=""
        value={time.date.value}
        onChange={(event) => setTime('date')(event.target.value)}
        type='date'
        variant='standard'
        style={{ width: '130px' }}
      />
    </TableCell>

    <TableCell>
      <Checkbox
        style={{ marginLeft: '-9px' }}
        onChange={e => setTime('includeStart')(e.target.checked)}
        checked={time.includeStart.value}
        inputProps={{ 'aria-label': 'can schedule on time' }}
      />
      <Input
        style={{ width: '125px' }}
        error={!time.start.isValid}
        value={time.start.value}
        onChange={e => setTime('start')(e.target.value)} />
      {getFirstErrorOrDefault('start', time) && <FormHelperText color="error">
        {getFirstErrorOrDefault('start', time)}
      </FormHelperText>}
    </TableCell>

    <TableCell>
      <Checkbox
        style={{ marginLeft: '-9px' }}
        onChange={e => setTime('includeEnd')(e.target.checked)}
        checked={time.includeEnd.value}
        inputProps={{ 'aria-label': 'can schedule on time' }}
      />
      <Input
        style={{ width: '125px' }}
        error={!time.end.isValid}
        value={time.end.value}
        onChange={e => setTime('end')(e.target.value)} />
      {getFirstErrorOrDefault('end', time) && <FormHelperText color="error">
        {getFirstErrorOrDefault('end', time)}
      </FormHelperText>}
    </TableCell>

    <TableCell>
      <IconButton
        disabled={blocksPost.loading}
        type="button" onClick={submitBlockToAdd} aria-label="add block">
        <AddIcon />
      </IconButton>
      {blocksPost.errorMessage}
    </TableCell>
  </TableRow>
  );
};

const dateFormat = DateTimeFormatter.ofPattern('M/d/yyyy').withLocale(Locale.US);
const timeFormat = DateTimeFormatter.ofPattern('h:mm a').withLocale(Locale.US);

const Block = ({
  time,
  canEdit,
  startEditBlock,
  stopEdit,
  blockPut,
  blocksGet,
  openMenu
}) => {
  const formattedBlock = React.useMemo(() => {
    return {
      start: LocalTime
        .parse(time.startTime.Include || time.startTime.Exclude)
        .format(timeFormat),
      end: LocalTime
        .parse(time.endTime.Include || time.endTime.Exclude)
        .format(timeFormat),
      date: LocalDate.parse(time.date).format(dateFormat)
    };
  }, [time]);

  const makeForm = () => initForm({
    date: {
      default: LocalDate.parse(time.date),
      validation: [
        requiredValidation('A date is required.')
      ]
    },
    includeStart: {
      default: !!time.startTime.Include
    },
    start: {
      default: LocalTime
        .parse(time.startTime.Include || time.startTime.Exclude)
        .format(timeFormat),
      validation: [
        requiredValidation('A start time is required'),
        timeValidation
      ]
    },
    includeEnd: {
      default: !!time.endTime.Include
    },
    end: {
      default: LocalTime
        .parse(time.endTime.Include || time.endTime.Exclude)
        .format(timeFormat),
      validation: [
        requiredValidation('An end time is required'),
        endGreaterThanStartValidation('start')
      ]
    }
  });

  const [form, setForm] = React.useState(makeForm());

  // Reset the changes made in a previous edit if any
  React.useEffect(() => {
    if (canEdit === true) {
      setForm(makeForm());
    }
  }, [canEdit]);

  const updateCb = e => e.target.value;
  const update = (prop = '', cb = updateCb) => e => setForm(updateValue(prop, cb(e)));

  const submitBlockToUpdate = (e) => {
    e.preventDefault();
    const validatedForm = submitForm(form);
    setForm(validatedForm);
    if (!validatedForm.__form.isValid) {
      console.log('form not valid');
      return;
    }
    const data = getFormObject(validatedForm);
    const dataFormated = merge(
      formatToApi(data, time.scheduleId),
      { blockId: time.blockId }
    );
    blockPut.invoke(dataFormated).then(() => {
      console.log('schedule id', time.scheduleId);
      return blocksGet.invoke(time.scheduleId);
    }).then(() => {
      stopEdit();
    });
  };

  return (
    <TableRow>
      <TableCell onClick={startEditBlock}>
        {!canEdit && formattedBlock.date}
        {canEdit && <TextField
          label=""
          value={getValue('date', form)}
          onChange={(event) => update('date', I)(event.target.value)}
          type='date'
          variant='standard'
          style={{ width: '130px' }}
        />}
      </TableCell>

      <TableCell onClick={startEditBlock}>
        {!canEdit && <div style={{ display: 'flex', alignItems: 'center' }}>
          {time.startTime.Exclude ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon />}
          <div>{formattedBlock.start}</div>
        </div>}
        {canEdit && <>
          <Checkbox
            style={{ marginLeft: '-9px' }}
            onChange={update('includeStart', e => e.target.checked)}
            checked={getValue('includeStart', form)}
            inputProps={{ 'aria-label': 'can schedule on time' }}
          />
          <Input
            style={{ width: '125px' }}
            error={!form.start.isValid}
            value={getValue('start', form)}
            onChange={update('start')}
          />
          {getFirstErrorOrDefault('start', form) && <FormHelperText color="error">
            {getFirstErrorOrDefault('start', form)}
          </FormHelperText>}
        </>}

      </TableCell>

      <TableCell onClick={startEditBlock}>
        {!canEdit && <div style={{ display: 'flex', alignItems: 'center' }}>
          {time.endTime.Exclude ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon />}
          <span>{formattedBlock.end}</span>
        </div>}
        {canEdit && <>
          <Checkbox
            style={{ marginLeft: '-9px' }}
            onChange={update('includeEnd', e => e.target.checked)}
            checked={getValue('includeEnd', form)}
            inputProps={{ 'aria-label': 'can schedule on time' }}
          />
          <Input
            style={{ width: '125px' }}
            error={!form.end.isValid}
            value={getValue('end', form)}
            onChange={update('end')}
          />
          <FormHelperText color="error">{blockPut.errorMessage}</FormHelperText>
        </>}
      </TableCell>


      <TableCell>
        {!canEdit && <IconButton onClick={openMenu} aria-label="options">
          <MoreVertIcon />
        </IconButton>}
        {canEdit && <>
          <IconButton disabled={blockPut.loading} onClick={submitBlockToUpdate} aria-label="save">
            {blockPut.loading ? <CachedIcon className="sked-spin" /> : <SaveIcon />}
          </IconButton>
        </>}
      </TableCell>
    </TableRow>
  );
};

function Blocks({ id }) {

  const blocksGet = usePromise(getBlocks, []);
  const blocksPost = usePromise(postBlocks, null);
  const blockPut = usePromise(putBlock, null);
  const blockDelete = usePromise(deleteBlock, null);

  const [editBlockMap, setEditBlockMap] = React.useState({});
  const [canViewPast, setCanViewPast] = React.useState(false);

  const visibleBlocks = React.useMemo(() => {
    const today = LocalDate.now().toString();
    return blocksGet.data.filter(b => b.date >= today || canViewPast);
  }, [blocksGet.data, canViewPast]);

  const startEditBlock = blockId => setEditBlockMap(pipe(
    map(() => false),
    assoc(blockId, true)
  ));

  const stopEdit = () => {
    startEditBlock(-1);
  };


  React.useEffect(() => {
    blocksGet.invoke(id);
  }, [id]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuBlockId, setMenuBlockId] = React.useState(null);

  const openMenu = (event, blockId) => {
    setAnchorEl(event.currentTarget);
    setMenuBlockId(blockId);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleEditBlockMenuClick = () => {
    closeMenu();

    //Hack to get around the click away
    //event that closes all the edits...
    setTimeout(() => {
      startEditBlock(menuBlockId);
    }, 50);
    setMenuBlockId(null);
  };

  const handleDeleteBlockMenuClick = () => {
    blockDelete.invoke(menuBlockId).then(() => {
      closeMenu();
      setMenuBlockId(null);
      return blocksGet.invoke(id);
    });
  };

  return (
    <div>
      <Row>
        <Spacer />
        {(blocksGet.loading || blocksPost.loading || blockDelete.loading) && <CircularProgress />}
        <Spacer />
      </Row>
      <Row>
        {blocksGet.errorMessage}
        {blockDelete.errorMessage}
      </Row>

      <div>
        <FormControlLabel
          control={<Checkbox checked={canViewPast} onChange={e => setCanViewPast(e.target.checked)} name="canViewPast" />}
          label="View Past Blocks"
        />
        <FormHelperText>Times like 8 am, 9:15pm, 15:00, 10pm are all valid.</FormHelperText>
        <FormHelperText>Checking the box next to the time means appointments can be scheduled on the time.</FormHelperText>
        <TableContainer>
          <Table aria-label="hours entry table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Start</TableCell>
                <TableCell>End</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <ClickAwayListener onClickAway={stopEdit}>
              <TableBody>
                <BlockToAdd onClick={stopEdit} blocksPost={blocksPost} scheduleId={id} blocksGet={blocksGet} />

                {visibleBlocks.map((time, index) =>
                  <Block
                    key={index}
                    time={time}
                    blockPut={blockPut}
                    blocksGet={blocksGet}
                    stopEdit={stopEdit}
                    canEdit={editBlockMap[time.blockId]}
                    openMenu={(e) => openMenu(e, time.blockId)}
                    startEditBlock={() => startEditBlock(time.blockId)} />
                )}
              </TableBody>
            </ClickAwayListener>
          </Table>
        </TableContainer>
      </div>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
      >
        <MenuItem disabled={blockDelete.loading} onClick={handleEditBlockMenuClick}>
          Edit
        </MenuItem>
        <MenuItem disabled={blockDelete.loading} onClick={handleDeleteBlockMenuClick}>
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}

export default Blocks;
