import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import HelpIcon from '@mui/icons-material/Help';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import HeaderButton from '../../../../../components/HeaderButton/HeaderButton.component';
import { useStyles } from './addButton.styles';
import {
  Popover, Grid, Typography, Paper, Button,
} from '@mui/material';
import Modal from '../../../../../components/Modal/Modal.component';

const calendarUrl = 'https://sked-v2.s3.amazonaws.com/images/calendar-preview.png';
const formUrl = 'https://sked-v2.s3.amazonaws.com/images/form-preview.png';
const arrowUrl = 'https://sked-v2.s3.amazonaws.com/images/hand-drawn-arrow.png';

const ExplainGraphic = ({ type }: {type: string}) => {
  const classes = useStyles();
  const [first, second] = React.useMemo(() => {
    return type === 'New' ?
      ['Calendar First', 'Form Second'] :
      ['Form First', 'Calendar Second'];
  }, [type]);
  return (
    <div className={classes.graphicGrid}>
      <div className={classes.graphicHeader} style={{
        justifyContent: 'space-between'
      }}>
        <b>{first}</b>
        <img className={classes.arrow} src={arrowUrl} height={25}/>
      </div>
      <div className={classes.graphicHeader}>
        {second}
      </div>
      <Paper className={classes.graphicPaper}>
        <img
          src={type === 'New' ? calendarUrl : formUrl}
          className={classes.image}/>
      </Paper>
      <Paper className={classes.graphicPaper}>
        <img
          src={type === 'New' ? formUrl : calendarUrl}
          className={classes.image}/>
      </Paper>
    </div>
  );
};

type AddButtonProps = {
  save: (type: string) => void;
  onlyIcon: boolean,
}

const AddButton = ({ save, onlyIcon }: AddButtonProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState<string>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (type: string) => () => {
    save(type);
  };

  const swap = (type: string) => () => {
    setSelected(type);
  };

  const openModal = () => {
    setSelected('New');
  };

  const closeModal = () => {
    setSelected(null);
  };

  return (
    <>
      <Grid>
        <Grid marginRight={0.5}>
          <HeaderButton
            title='Add'
            Icon={AddIcon}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            onlyIcon={onlyIcon}
            type='hide'
            className='sked-test-npp-add-button'
          />
        </Grid>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          classes={{ paper: classes.paper }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Grid minWidth={200} padding={1}>
            <Typography
              onClick={handleSelect('New')}
              className={classes.inputLabel}
            >
            New Patient Portal
            </Typography>
            <Typography
              onClick={handleSelect('Existing')}
              className={classes.inputLabel}
              style={{ marginBottom: 0 }}
            >
            Existing Patient Portal
            </Typography>
            <Typography
              onClick={openModal}
              className={classes.inputLabel + ' ' + classes.help}
            >
              <HelpIcon className={classes.helpIcon}/>
              How to pick?
            </Typography>
          </Grid>
        </Popover>
      </Grid>
      <Modal
        open={Boolean(selected)}
        onClose={closeModal}
        title='Pick Patient Portal Type'
        className={'sked-test-npp-linking-modal'}
        innerClassName={classes.mobileModal}
        maxWidth={1000}
        buttons={[
          <HeaderButton
            title='Continue'
            onClick={handleSelect(selected)}
            color="primary"
            className={
              'sked-test-npp-new-modal-button-continue ' +
            classes.continue
            }
          />
        ]}
      >
        <div className={classes.macroGrid}>
          <div
            onClick={swap('New')}
            className={selected === 'New' ? classes.chosen : '' + classes.selectable}>
            <Typography
              className={classes.headers}
            >
              <b>New Patient</b> <i>Portal</i>
            </Typography>
            <ExplainGraphic
              type='New'
            />
            <Typography className={classes.mainDesc}>
            New patients are more likely to schedule their appointments
            if they see the calendar first.
            </Typography>
            <div className={classes.proCons}>
              <NorthIcon className={classes.icons} />
              <Typography>
                <b>Better conversation rates </b> (% likelihood patients
            complete scheduling)
              </Typography>
            </div>
            <div className={classes.proCons}>
              <SouthIcon className={classes.icons} />
              <Typography>
              Some returning patients may need to
                <b> reselect an appointment time </b>
              due to account restrictions.
              </Typography>
            </div>
            <Button
              variant='contained'
              color='primary'
              className={classes.mobileButton}
              onClick={handleSelect('New')}
            >
              Create a New Patient Portal
            </Button>
          </div>
          <div
            onClick={swap('Existing')}
            className={selected === 'Existing' ? classes.chosen : classes.selectable}
          >
            <Typography
              className={classes.headers}>
              <b>Existing Patient</b> <i>Portal</i>
            </Typography>
            <ExplainGraphic
              type='Existing'
            />
            <Typography className={classes.mainDesc}>
              Existing patients find it easier to book when
              they log in first to view available times that
              match their preferences/restrictions.
            </Typography>
            <div className={classes.proCons}>
              <NorthIcon className={classes.icons} />
              <Typography>
                Makes it <b> less frustrating for returning patients </b>
                because they will never have to select an appointment time twice.
              </Typography>
            </div>
            <div className={classes.proCons}>
              <SouthIcon className={classes.icons} />
              <Typography>
                Less effective for high-converting marketing campaigns
              </Typography>
            </div>
            <Button
              variant='contained'
              color='primary'
              className={classes.mobileButton}
              onClick={handleSelect('Existing')}
            >
              Create an Existing Patient Portal
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddButton;
