import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { History } from 'history';
import {
  TextField, CircularProgress, Tooltip, FormControl, NativeSelect, Grid,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import ThumbsUpIcon from '@mui/icons-material/ThumbUp';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ErrorIcon from '@mui/icons-material/DisabledByDefault';
import ArrowUpIcon from '@mui/icons-material/ArrowUpward';
import {
  tzParseFormat, tzParse,
} from '../../services/joda.js';
import * as R from 'ramda';
import {
  setQueryParam, pickColor, caseType
} from '../../services/utilities.js';
import ClientEditDialog from '../Clients/components/client-dialog/client-dialog.component.jsx';
import InboxChat from '../Messages/Inbox/InboxChat/InboxChat.component';
import MessageThread from '../Messages/routes/MessagesThread/messages-thread.component.jsx';
import api from '../../services/api.js';
import { PopupTemplate, popup } from '../../services/Popup.js';
import { TimeSelectBusyness } from '../../routes/CalendarV2/components/time-select.jsx';
import { LocalDateTime, ZoneId, ZoneOffset } from '@js-joda/core';
import EditLeadDialog from '../Leads/components/EditLead/EditLead.component';
import ConvertLeadDialog from '../Leads/components/Convert/Convert.component';
import {
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  HeaderCell,
  BodyCell,
} from '../../components/CustomTable';
import Modal from '../../components/Modal/Modal.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import { useSelector } from '../../reducers';
import {
  Appointment, ProType, AppointmentType, Professional,
  StatusObject, AptData, Change,
} from './appointments.types';
import { Client } from '../Clients/clients.types';

const ThumbsUpIconColored = withStyles({
  colorPrimary: {
    color: 'green',
  },
})(ThumbsUpIcon);

const defaultCancelText = 'Canceled via the SKED Admin.';

const isConfirmed = (confirmed: string, tz: string) => {
  if (!confirmed) {
    return null;
  }
  const time = tzParseFormat(confirmed, tz, 'yyyy-MM-dd h:mm a');
  return (
    <Tooltip arrow title={'Confirmed: ' + time}>
      <ThumbsUpIconColored
        fontSize='small'
        color='primary'
      />
    </Tooltip>
  );
};

type ClientAction = (c?: Client) => void;

type Props = {
  appointment: Appointment;
  back?: () => void;
  busy?: boolean;
  cancel?: () => void;
  from: string;
  openedFrom?: string;
  gotoClient?: ClientAction;
  history: History;
  onClose?: () => void;
  resked?: (appt: Appointment) => void;
  open: boolean;
  proTypes?: ProType[];
  tz?: string;
  state?: string;
  isLead?: boolean;
}

type State = {
  state?: string;
  reason?: string;
  busy?: boolean;
  appointment?: Appointment,
  proTypes?: ProType[];
  appointmentType?: AppointmentType;
  professional?: Professional,
  selectedLead?: Client,
  leadToConvert?: Client,
}

const Appointments = (props: Props) => {
  const [state, setState] = useState<State>({
    state: 'APT',
    reason: '',
    busy: false,
    appointment: {} as Appointment,
    proTypes: [],
    appointmentType: {} as AppointmentType, // if `this.props.appointment` lacks `appointmentType`, put it here
    professional: {} as Professional,
    selectedLead: null,
    leadToConvert: null,
  });

  const {
    features,
    timeIntervals,
  } = useSelector((s) => ({
    features: R.pathOr([], ['login', 'features'])(s),
    timeIntervals: s.calendarv2.localInterval || R.pathOr(15, ['login', 'ehrSettings', 'blockLength'])(s),
  }));

  useEffect(() => {
    update({
      state: 'APT',
    });
  }, []);

  useEffect(() => {
    update({
      state: props.state
    });
  }, [props.state]);

  useEffect(() => {
    const id = R.propOr(false, 'id', props.appointment);
    if (id) {
      if (props.openedFrom === 'CLIENT') {
        setQueryParam('apt2', id);
      } else {
        setQueryParam('apt1', id);
      }
    }
    if (props.appointment) {
      api.get(`appointmentType/${props.appointment.appointmentTypeId}`).then((appointmentType) => {
        api.get(`professional/${appointmentType.professionalId}`).then((professional) => {
          update({ appointmentType, professional });
        });
      });
    }
  }, [props.appointment?.id]);

  const changeState = (newState: string) => {
    setState({
      ...state,
      state: newState,
    });
  };

  const update = (data: State) => {
    setState(R.merge(state, data));
  };

  const close = () => {
    update({
      state: 'APT',
      reason: '',
      busy: false,
      appointment: {} as Appointment,
    });
    props.onClose();
  };

  const cancelApt = () => {
    const data = {
      reason: R.isEmpty(state.reason) ? defaultCancelText : state.reason,
      source: 'Admin',
    };
    const id = R.pathOr(0, ['appointment', 'id'])(props);
    update({ busy: true });
    api.delete(`appointment/${id}`, { data }).then(() => {
      if (props.cancel) {
        props.cancel();
      }
      close();
    }).catch((error) => {
      console.error(error);
      popup('Error!', 'There was an error when attempting to cancel this appointment.');
      update({ state: 'APT' });
      close();
    });
  };

  const getProsAndTypes = () => {
    update({
      busy: true,
      state: 'RESKED',
    });
    return Promise.all<[Promise<Professional[]>, Promise<AppointmentType[]>]>(
      [api.get('professional'), api.get('appointmentType')]
    ).then(([pros, types]) => {
      const proTypes = R.pipe(
        R.filter(({ isHidden }) => !isHidden),
        (params: Professional[]) => R.sortBy(R.prop('lastName'))(params),
        R.map((pro: Professional) => {
          const ts = R.pipe(
            R.filter(({ professionalId }) => pro.id === professionalId),
            (params: Professional[]) => R.sortBy(R.prop('internalName'))(params)
          )(types);
          const newPro = R.merge(pro, {
            id: undefined, // to prevent mis-selection when a type has the same id.
            professionalId: pro.id
          });
          return [newPro, ts];
        }),
        R.flatten
      )(pros);
      const time = tzParse(props.appointment.time, props.tz);
      const date = tzParseFormat(props.appointment.time, props.tz, 'yyyy-MM-dd');
      const justTime = tzParseFormat(props.appointment.time, props.tz, 'HH:mm');
      update({
        busy: false,
        proTypes: proTypes as unknown as ProType[],
        appointment: R.merge(props.appointment, { date, time, justTime }) as unknown as Appointment,
        state: 'RESKED',
      });
    }).catch((error) => {
      console.error(error);
      popup('Error!', 'Unable to reschedule appointment!');
    });
  };

  const saveApt = (newApt: Appointment = null) => {
    const apt = newApt ? newApt : state.appointment;
    let time = apt.time;
    if (!newApt) {
      time = LocalDateTime
        .parse(apt.date + 'T' + apt.justTime)
        .atZone(ZoneId.of(props.tz))
        .withZoneSameInstant(ZoneOffset.UTC)
        .toString();
    }
    const data = R.merge(
      apt,
      {
        source: 'Admin',
        time,
      });
    update({ busy: true });
    api.put(`appointment/${apt.id}`, data)
      .then((a) => {
        api.get(`appointmentType/${a.appointmentTypeId}`)
          .then((aType) => {
            api.get(`appointment/${a.id}/log`)
              .then((data) => {
                const newApt = R.merge(
                  a,
                  R.merge(
                    apt,
                    {
                      appointmentType: aType,
                      aptData: R.reverse(R.sortBy(R.prop('changedAt'))(data)),
                      time: a.time,
                    }));
                if (props.resked) {
                  props.resked(newApt as Appointment);
                }
                update({
                  busy: false,
                  state: 'APT',
                  appointment: newApt as Appointment,
                });
              });
          });
      })
      .catch((error) => {
        console.error(error);
        popup('Error!', 'Failed to save appointment.');
      });
  };

  const decipherStatus = (status: StatusObject) => {
    if (status) {
      return R.cond([
        [R.has('Scheduled'), R.always('Scheduled')],
        [R.has('Canceled'), R.always('Canceled')],
        [R.has('Arrived'), R.always('Arrived')],
        [R.has('Rescheduled'), R.always('Rescheduled')],
        [R.has('Deleted'), R.always('Deleted')],
        [R.T, R.always('Missed')]
      ])(status);
    }
    return null;
  };

  const bookingStatus = (status: object) => {
    return R.cond([
      [R.has('Confirmed'), () => (
        <Tooltip arrow title='Confirmed'>
          <CheckBoxIcon style={{ color: 'green', fontSize: 16 }} />
        </Tooltip>
      )],
      [R.has('Pending'), () => (
        <Tooltip arrow title='Pending'>
          <MoreHorizIcon style={{ color: 'gray', fontSize: 16 }} />
        </Tooltip>
      )],
      [R.T, (status) => {
        const title = R.cond([
          [R.has('OverScheduled'), R.always('Over Scheduled Error. A rescheduled is needed.')],
          [R.T, R.always('Unknown Error. Contact support for help!')]
        ])(status);
        return (
          <Tooltip arrow title={title}>
            <ErrorIcon style={{ color: 'red', fontSize: 16 }} />
          </Tooltip>
        );
      }]
    ])(status);
  };

  const displayAction = (apt: AptData) => {
    if (apt.change) {
      return R.cond([
        [R.has('Added'), (change: Change) => ({ action: 'Scheduled', update: change.Added })],
        [R.has('Scheduled'), () => ({ action: 'Scheduled', update: '' })],
        [R.has('OldAdded'), R.always({ action: 'Scheduled', update: '' })],
        [R.has('TimeUpdated'), (change: Change) => ({ action: 'Rescheduled', update: change.TimeUpdated })],
        [R.has('MarkedAsMissed'), R.always({ action: 'Missed', update: '' })],
        [R.has('MarkedAsArrived'), R.always({ action: 'Arrived', update: '' })],
        [R.has('Deleted'), R.always({ action: 'Deleted', update: '' })],
        [R.T, R.always({ action: 'Canceled', update: '' })],
      ])(apt.change);
    }
    return null;
  };

  const openClientDialog = (client: Client, from: string, action: ClientAction) => {
    if (from === 'CLIENT') {
      return action();
    } else if (from === 'CLIENT_MSG') {
      return null;
    }
    if (client.isLead) {
      update({ selectedLead: client });
      return;
    }
    action(client);
    changeState('CLIENT');
    return null;
  };

  const table = (apt: Appointment, tz: string, from: string, gotoClient: ClientAction, isLead: boolean) => {
    const appointment = apt.appointmentType === null ? {
      ...apt,
      appointmentType: state.appointmentType
    } : apt;
    return (
      <TableContainer>
        <TableBody>
          <TableRow>
            <BodyCell style={{ width: '40%' }}><b>Date:</b></BodyCell>
            <BodyCell>{tzParseFormat(appointment.time, tz, 'M/d/yyyy')}</BodyCell>
          </TableRow>
          <TableRow>
            <BodyCell style={{ width: '40%' }}><b>Time:</b></BodyCell>
            <BodyCell>{tzParseFormat(appointment.time, tz, 'h:mm a')}</BodyCell>
          </TableRow>
          <TableRow>
            <BodyCell style={{ width: '40%' }}><b>{isLead ? 'Lead:' : 'Client:'}</b></BodyCell>
            <BodyCell>
              <a
                onClick={() => {
                  if (props.openedFrom === 'CLIENT') {
                    setQueryParam('apt2', undefined);
                  }
                  openClientDialog(appointment.client, from, gotoClient);
                }}>
                {appointment.client.firstName} {appointment.client.lastName} {caseType(appointment.client)}
              </a>
            </BodyCell>
          </TableRow>
          <TableRow>
            <BodyCell style={{ width: '40%' }}><b>Type:</b></BodyCell>
            <BodyCell>
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '100px',
                    backgroundColor: pickColor(appointment.appointmentType.color),
                    marginRight: '5px',
                  }}>
                </div>
                {appointment.appointmentType.internalName}
                {!R.isEmpty(state.professional) && !!state.professional &&
                  ' with ' + state.professional.firstName + ' ' + state.professional.lastName}
              </div>
            </BodyCell>
          </TableRow>
          <TableRow>
            <BodyCell style={{ width: '40%' }}><b>Status:</b></BodyCell>
            <BodyCell>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {decipherStatus(appointment.status)}
                &nbsp;
                {bookingStatus(appointment.bookingStatus)}
                &nbsp;
                {isConfirmed(appointment.confirmed, tz)}
              </div>
            </BodyCell>
          </TableRow>
          {R.propOr(false, 'Canceled')(appointment.status) ?
            <TableRow>
              <BodyCell style={{ width: '40%' }}><b>Reason:</b></BodyCell>
              <BodyCell>{appointment.status.Canceled}</BodyCell>
            </TableRow>
            :
            null
          }
        </TableBody>
      </TableContainer>
    );
  };

  const historyItem = (apt: AptData, tz: string) => {
    const { action, update } = displayAction(apt);
    return (
      <TableRow key={apt.id}>
        <BodyCell>
          {action}
        </BodyCell>
        <BodyCell>
          {update === '' ? '' : tzParseFormat(update, tz, 'M/d/yyyy @ h:mm a')}
        </BodyCell>
        <BodyCell>
          {R.cond([
            [R.equals('Npp'), R.always('Patient Portal')],
            [R.T, R.identity]
          ])(apt.source)}
        </BodyCell>
        <BodyCell>
          {tzParseFormat(apt.changedAt, tz, 'M/d/yyyy @ h:mm a')}
        </BodyCell>
      </TableRow>
    );
  };

  const history = (aptData: AptData[] = [], tz: string) => {
    const sorted = R.reverse(R.sortBy(R.prop('changedAt'))(aptData));
    return (
      <Grid marginX={-2}>
        <TableContainer style={{ maxHeight: 200, minHeight: 100, overflow: 'auto' }}>
          <TableHead>
            <TableRow>
              <HeaderCell>
                <div style={{ display: 'flex' }}>
                  <ArrowUpIcon style={{ color: 'gray', fontSize: 14 }} />
                  Action
                </div>
              </HeaderCell>
              <HeaderCell>
                Update
              </HeaderCell>
              <HeaderCell>
                Source
              </HeaderCell>
              <HeaderCell>
                Action Time
              </HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sorted.map((apt) => historyItem(apt, tz))}
          </TableBody>
        </TableContainer>
      </Grid>
    );
  };

  const client = R.pathOr({}, ['appointment', 'client'], props) as Client;
  const hasNewChat = R.includes('NewSMSThreads', features);

  return R.cond([
    [R.equals('MESSAGE'), () => {
      return (
        <Modal
          title={`Message ${client.firstName} ${client.lastName} ${caseType(client)}`}
          open={props.open}
          className='sked-test-appt-details-modal-send-message'
          onClose={() => {
            if (props.openedFrom === 'CLIENT') {
              setQueryParam('apt2', undefined);
            } else {
              setQueryParam('apt1', undefined);
            }
            close();
            changeState('APT');
          }}
          buttons={[
            <HeaderButton
              borderSolid
              onClick={() => changeState('APT')}
              title="Back"
              className='sked-test-appt-details-modal-send-message-button-back'
            />
          ]}
        >
          {client.id && !props.busy && <div>
            {hasNewChat ?
              <InboxChat clientId={client.id} fullPage={false} /> :
              <MessageThread match={{ params: { id: client.id } }} embedded={true} />}
          </div>}
          {props.busy && <div>
            <CircularProgress style={{ margin: '20px auto' }} />
          </div>}
        </Modal>
      );
    }],
    [R.equals('CANCEL'), () => {
      return (
        <Modal
          title='Cancel Appointment'
          open={props.open}
          maxWidth={700}
          className='sked-test-appt-details-modal-cancel-appt'
          onClose={() => {
            if (props.openedFrom === 'CLIENT') {
              setQueryParam('apt2', undefined);
            } else {
              setQueryParam('apt1', undefined);
            }
            close();
            changeState('APT');
          }}
          buttons={[
            <HeaderButton
              onClick={() => changeState('APT')}
              title="Back"
              borderSolid
              className='sked-test-appt-details-modal-cancel-appt-button-back'
            />,
            <HeaderButton
              onClick={() => cancelApt()}
              title="Submit"
              color='danger'
              className='sked-test-appt-details-modal-cancel-appt-button-submit'
            />,
          ]}
        >
          <div>
            <PopupTemplate />
            {state.busy ? <div className="loader"></div> :
              <TextField
                id="body"
                style={{ width: '100%' }}
                minRows={3}
                maxRows={8}
                value={state.reason}
                onChange={(e) => update({ reason: e.target.value })}
                label="Reason for canceling"
                multiline
                variant='outlined'
                placeholder={defaultCancelText}
              />
            }
          </div>
        </Modal>
      );
    }],
    [R.equals('RESKED'), () => {
      const {
        appointment,
        proTypes,
      } = state;

      return (
        <Modal
          open={props.open}
          title='Reschedule Appointment'
          className='sked-test-appt-details-modal-reschedule-appt'
          onClose={() => {
            close();
            changeState('APT');
          }}
          showMoreWidth={450}
          buttons={[
            <HeaderButton
              borderSolid
              onClick={() => changeState('APT')}
              title="Back"
              className='sked-test-appt-details-modal-reschedule-appt-button-back'
            />,
            <HeaderButton
              disabled={!state.appointment.time}
              onClick={() => saveApt()}
              title="Reschedule"
              color='primary'
              className='sked-test-appt-details-modal-reschedule-appt-button-reschedule'
            />
          ]}
        >
          <div>
            <PopupTemplate />
            {state.busy ? <div className="loader"></div> :
              <TableContainer noHover>
                <TableRow>
                  <BodyCell>
                    Date
                  </BodyCell>
                  <BodyCell>
                    Time
                  </BodyCell>
                  <BodyCell>
                    Type
                  </BodyCell>
                </TableRow>
                <TableBody>
                  <TableRow key={appointment.id}>
                    <BodyCell>
                      <TextField
                        style={{ width: 130 }}
                        value={appointment.date}
                        onChange={(event) => {
                          update({ appointment: R.merge(appointment, { date: event.target.value }) });
                        }}
                        type='date'
                        variant='standard'
                      />
                    </BodyCell>
                    <BodyCell>
                      {appointment.time &&
                          <TimeSelectBusyness
                            appointmentTypeId={appointment.appointmentTypeId}
                            date={appointment.date}
                            interval={timeIntervals}
                            id={1}
                            value={appointment.justTime}
                            onChange={(justTime: string) => {
                              update({ appointment: R.merge(appointment, { justTime }) });
                            }}
                          />
                      }
                    </BodyCell>
                    <BodyCell>
                      {
                        <FormControl fullWidth>
                          <NativeSelect
                            value={appointment.appointmentTypeId}
                            // defaultValue={apt.appointmentTypeId}
                            variant='standard'
                            inputProps={{
                              name: 'type',
                              id: 'uncontrolled-native',
                            }}
                            onChange={(e) => {
                              const newId = Number(e.target.value);
                              update({
                                appointment:
                                  R.merge(
                                    appointment,
                                    {
                                      appointmentTypeId: newId,
                                      appointmentType: R.pipe(
                                        R.filter(({ id }) => id === newId),
                                        R.head
                                      )(proTypes),
                                    }
                                  ) as Appointment
                              });
                            }}>
                            {R.map((proOrType: ProType) => {
                              if (proOrType.firstName) {
                                return (
                                  <option
                                    key={proOrType.professionalId}
                                    value={proOrType.professionalId}
                                    disabled={true}>
                                    {proOrType.displayFirstName + ' ' + proOrType.displayLastName}
                                  </option>
                                );
                              } else {
                                return (
                                  <option key={proOrType.id} value={proOrType.id}>
                                    {proOrType.internalName + ' (' + proOrType.name + ')'}
                                  </option>
                                );
                              }
                            })(proTypes)}
                          </NativeSelect>
                        </FormControl>
                      }
                    </BodyCell>
                  </TableRow>
                </TableBody>
              </TableContainer>}
          </div>
        </Modal>
      );
    }],
    [R.T, () => {
      const updateStatus = R.pipe(
        R.pathOr({}, ['appointment', 'status']),
        R.ifElse(
          R.has('Missed'),
          R.always('Scheduled'),
          R.always('Missed'),
        )
      )(props);
      return (
        <Modal
          open={props.open}
          title={client.isLead ? 'Appointment request' : 'Appointment details'}
          className='sked-test-appt-details-modal'
          onClose={() => {
            if (props.openedFrom === 'CLIENT') {
              setQueryParam('apt2', undefined);
              props.back();
            } else {
              setQueryParam('apt1', undefined);
              close();
            }
          }}
          showMoreWidth={(R.includes('Calendar', features) || R.includes('NewCalendar', features)) ? 716 : 455}
          buttons={[
            <HeaderButton
              title='Send Message'
              borderSolid
              onClick={() => changeState('MESSAGE')}
              className='sked-test-appt-details-modal-button-send-message'
            />,
            (R.includes('Calendar', features) ||
                R.includes('NewCalendar', features)) &&
              <HeaderButton
                borderSolid
                onClick={() => {
                  update({ appointment: props.appointment });
                  if (props.proTypes) {
                    const date = tzParseFormat(props.appointment.time, props.tz, 'yyyy-MM-dd');
                    const justTime = tzParseFormat(props.appointment.time, props.tz, 'HH:mm');
                    update({
                      appointment: R.merge(props.appointment, { justTime, date }),
                      proTypes: props.proTypes,
                      state: 'RESKED',
                    });
                  } else {
                    getProsAndTypes();
                  }
                }}
                title="Reschedule"
                className='sked-test-appt-details-modal-button-reschedule'
              />,
            (R.includes('Calendar', features) ||
              R.includes('NewCalendar', features)) &&
              <HeaderButton
                borderSolid
                disabled={state.busy}
                onClick={() => {
                  const newApt = R.merge(props.appointment, { status: { [updateStatus]: [] } });
                  saveApt(newApt);
                }}
                title={state.busy ?
                  'Updating...'
                  :
                  `Mark as ${updateStatus}`}
                className='sked-test-appt-details-modal-button-mark-as-missed'
              />,
            (R.includes('Calendar', features) ||
              R.includes('NewCalendar', features)) &&
              <HeaderButton
                color='danger'
                onClick={() => changeState('CANCEL')}
                title="Cancel appt"
                className='sked-test-appt-details-modal-button-cancel-appt'
              />
          ]}
        >
          {state.state === 'SEND_MESSAGE' &&
            <Redirect to='/messages' />
          }
          <div>
            <ClientEditDialog
              open={state.state === 'CLIENT'}
              back={props.back}
              from={props.from}
              history={props.history}
              onClose={() => changeState('APT')} />
            <EditLeadDialog
              open={!!state.selectedLead}
              close={() => update({ selectedLead: null })}
              lead={state.selectedLead}
              convertClick={() => {
                update({
                  leadToConvert: state.selectedLead,
                  selectedLead: null
                });
              }}
              onUpdate={props.back}
            />
            <ConvertLeadDialog
              open={!!state.leadToConvert}
              lead={state.leadToConvert}
              close={() => update({ leadToConvert: null })}
              onConvert={() => {
                update({ leadToConvert: null });
                props.back();
              }}
            />
            {props.busy === true && <div className="loader"></div>}
            {props.busy !== true &&
              <div>
                {R.propOr(undefined, 'client')(props.appointment) !== undefined &&
                  table(
                    state.appointment?.time ? state.appointment : props.appointment,
                    props.tz,
                    props.openedFrom,
                    props.openedFrom === 'CLIENT' ? props.back : props.gotoClient,
                    props.isLead || client.isLead,
                  )
                }
                {R.propOr(undefined, 'client')(props.appointment) !== undefined &&
                  history(state.appointment?.aptData ? state.appointment?.aptData : props.appointment.aptData, props.tz)
                }
              </div>
            }
          </div>
        </Modal>
      );
    }]
  ])(state.state);
};

export default Appointments;
