import React, { useState } from 'react';

import { useStyles } from './saveButton.styles';

import { Button, Grid, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

type SaveButtonProps = {
  onClick?: () => void;
  reset?: () => void;
  title?: string,
  disabled?: boolean;
  saveAsDisabled?: boolean;
  disableOptions?: boolean;
  saveAsOptions?: {
    label: string;
    onClick: () => void;
    className?: string;
  }[];
  className?: string;
}

const SaveAsButton = ({
  onClick, disabled, title, saveAsOptions, disableOptions, saveAsDisabled,
  className = '',
}: SaveButtonProps) => {
  const classes = useStyles();
  const [saveAsMenuAnchorEl, setSaveAsMenuAnchorEl] = useState(null);

  return (
    <Grid display="flex" alignItems="center">
      <Button
        onClick={(event) => setSaveAsMenuAnchorEl(event.currentTarget)}
        variant='contained'
        className={
          `${classes.saveAsButton} ${classes.arrowButton} sked-test-save-as-button-dropdown`
        }
        disabled={saveAsDisabled || disableOptions}
        style={disabled ? { boxShadow: 'none' } : {}}
        color="primary">
        <KeyboardArrowDownIcon />
      </Button>
      <Button
        onClick={onClick}
        variant='contained'
        className={
          `${classes.saveAsButton} ${className}`
        }
        disabled={disabled}
        color="primary">
        {title || 'Save'}
      </Button>
      <Menu
        open={Boolean(saveAsMenuAnchorEl)}
        anchorEl={saveAsMenuAnchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        classes={{ paper: classes.paper }}
        onClose={() => setSaveAsMenuAnchorEl(null)}>
        {saveAsOptions.map(item => {
          if (item.label) {
            return (
              <MenuItem
                key={item.label}
                disabled={disableOptions}
                className={
                  `${classes.selectItem} ${item.className || ''}`
                }
                onClick={item.onClick}>
                {item.label}
              </MenuItem>
            );
          }
          return null;
        })}
      </Menu>
    </Grid>
  );
};

export const SaveAsExample = () => {
  const classes = useStyles();
  return (
    <Button
      className={`${classes.saveAsButton} ${classes.arrowButton}`}
      variant='contained'
      style={{ height: 23, minWidth: 24, pointerEvents: 'none', boxShadow: 'none' }}
      color="primary">
      <KeyboardArrowDownIcon />
    </Button>

  );
};

export default SaveAsButton;
