import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  TextField,
  Menu,
  MenuItem,
  Snackbar,
  Tooltip,
  TableBody,
  TableRow,
  TableHead,
  Grid,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as R from 'ramda';
import { PopupTemplate, popup, popupWithCancel } from '../../../services/Popup.js';
import api from '../../../services/api.js';
import {
  format,
  now,
  timeConvert,
  isBetween,
} from '../../../services/joda.js';
import {
  LocalDate,
  LocalTime,
  LocalDateTime,
} from '@js-joda/core';
import Blocks from './blocks.component.jsx';
import ProBlocks from './pro-blocks.component.jsx';
import ScheduleGroup from './edit-group-schedules.component.jsx';
import { CopyPasteMenu } from '../../../components/CopyPaste';
import { TableContainer, HeaderCell, BodyCell } from '../../../components/CustomTable';
import Modal from '../../../components/Modal/Modal.component';
import HeaderButton from '../../../components/HeaderButton/HeaderButton.component';
import HeaderTabs from '../../../components/HeaderTabs/HeaderTabs.component';
import SelectLocation from './select-location';

const emptyObject = {};

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

// deleteHours :: Day -> Int -> Promise ()
const deleteHours = ({ date, id }) =>
  api.delete(`schedule/${id}/hours`, { data: { date } });

const deleteSpecialHours = ({ start, id }) =>
  api.delete(`schedule/${id}/hours/special`, { data: { start } });

const saveSpecial = (id, data, oldStart = false) => {
  const mergedData = R.merge(data, { scheduleId: id });
  if (R.has('scheduleId', data)) {
    return api.delete(`schedule/${id}/hours/special`, { data: { start: oldStart ? oldStart : data.start } })
      .then(() => {
        return api.post('schedule/hours/special', mergedData);
      });
  } else {
    return api.post('schedule/hours/special', mergedData);
  }
};

// hasHours :: Obj Hours -> Bool
const hasHours = (hours) => R.pipe(
  R.filter((day) => !R.isEmpty(R.prop(day, hours))),
  R.isEmpty,
  R.not
)(days);


const decipherTime = (times) => {
  if (times.length === 0) {
    return 'CLOSED';
  }
  return R.map(({ open, close }) => {
    const openTime = format(LocalTime.parse(open), 'hh:mm a');
    const closeTime = format(LocalTime.parse(close), 'hh:mm a');
    return (
      <div key={open + close}>
        {openTime + '-' + closeTime}
      </div>
    );
  })(times);
};

const formatTimes = (h) => R.pipe(
  R.split(':'),
  R.adjust(1, R.split(' ')),
  R.flatten,
)(format(LocalTime.parse(h), 'h:mm a'));


const formatEditTimes = ({ open, close, numberOfAppointments }) => ({
  open: formatTimes(open),
  close: formatTimes(close),
  numberOfAppointments,
});

const initState = {
  status: 'INIT',
  busy: false,
  hours: [],
  currentHours: {},
  currentHoursType: 'current', // Either 'current' 'future'
  editting: null, // Either Null Day
  editHours: [],
  edited: false,
  specialHours: [],
  specialCurrentHours: {},
  specialHoursCachedName: '',
  tab: 'hours',
  specialHoursState: 'LIST', // Either 'LIST' 'EDIT',
  duplicate: undefined,
  anchorEl: null,
  showDatePicker: false,
  affected: [],
  schedules: [],
  deletedAffected: [],
  copiedHours: false,
  pastedCopied: false,
  openSelectDate: false,
  needSave: false,
  otherAnchorEl: null,
};

const SelectDate = ({ open, onClose, currentHoursStart, save, state }) => {
  const [value, setValue] = React.useState(LocalDate.parse(currentHoursStart));
  const onSelect = () => {
    const date = value;
    const currentHours = R.merge(
      state.currentHours,
      { date });
    if (R.equals(date, state.currentHours.date)) {
      save(currentHours, null);
    } else if (R.equals(1, R.length(state.hours))) {
      save(currentHours, null);
    } else {
      const currentFutureDate = R.pipe(
        R.last,
        R.prop('date')
      )(state.hours);
      save(currentHours, currentFutureDate);
    }
    onClose();
  };

  return (
    <Modal
      size="xs"
      open={open}
      onClose={onClose}
      title='Select the date'
      className='sked-test-provider-schedule-select-date-modal'
      buttons={[
        <HeaderButton
          title='Save'
          color='primary'
          onClick={onSelect}
          lassName='sked-test-provider-schedule-select-date-modal-button-save'
        />
      ]}
    >
      <div>
        <TextField
          label="Choose Day"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          type='date'
          variant='standard'
        />
      </div>
    </Modal>
  );
};


const EditHours = (props) => {

  const [state, setState] = useState(initState);
  const specialBasic = {
    oneDay: false,
    start: format(now('date'), 'yyyy-MM-dd'),
    end: format(now('date'), 'yyyy-MM-dd'),
    name: '',
    hours: {
      sunday: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
    }
  };
  const basic = {
    date: format(now('date'), 'yyyy-MM-dd'),
    hours: {
      sunday: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
    }
  };

  useEffect(() => {
    if (state.status === 'INIT' && props.open) {
      if (R.isEmpty(props.hours)) {
        if (R.isEmpty(props.specialHours)) {
          update({
            hours: [basic],
            currentHours: basic,
            specialHours: [],
            specialCurrentHours: specialBasic,
            status: 'EDIT',
            affected: props.affected,
            schedules: props.schedules,
            tab: props.tab,
          });
        } else {
          const specialCurrentHours = R.head(props.specialHours);
          update({
            hours: [basic],
            currentHours: basic,
            specialHours: props.specialHours,
            specialCurrentHours,
            specialHoursCachedName: specialCurrentHours.name,
            status: 'EDIT',
            affected: props.affected,
            schedules: props.schedules,
            tab: props.tab,
          });
        }
      } else if (R.isEmpty(props.specialHours)) {
        update({
          hours: props.hours,
          currentHours: R.head(props.hours),
          specialHours: [],
          specialCurrentHours: specialBasic,
          status: 'EDIT',
          affected: props.affected,
          schedules: props.schedules,
          tab: props.tab,
        });
      } else {
        const specialCurrentHours = R.head(props.specialHours);
        update({
          hours: props.hours,
          currentHours: R.head(props.hours),
          specialHours: props.specialHours,
          specialCurrentHours,
          specialHoursCachedName: specialCurrentHours.name,
          status: 'EDIT',
          affected: props.affected,
          schedules: props.schedules,
          tab: props.tab,
        });
      }
    } else if (!props.open && state.status !== 'INIT') {
      update({
        status: 'INIT',
        busy: false,
        hours: [],
        currentHours: {},
        specialHours: [],
        specialCurrentHours: {},
        currentHoursType: 'current', // Either 'current' 'future'
        editting: null, // Either Null Day
        editHours: [],
        edited: false,
        affected: [],
        schedules: [],
      });
    }
  }, [props.open, state.status]);

  const update = (data) => {
    setState(R.merge(state, data));
  };

  const optionItem = (hour, ind) => {
    const formattedDate = format(LocalDate.parse(hour.date), 'M-dd-yyyy');
    const currentHours =
      R.find(R.propEq('date', hour.date))(state.hours);
    const isCurrent = R.equals('current', state.currentHoursType);
    const currentHoursType = isCurrent ? 'future' : 'current';
    const tableStyle = { borderBottom: 'unset', fontSize: '16px' };
    return ind === 0 ? (
      <tr key={ind}>
        <td style={tableStyle}>
          Current
        </td>
        <td style={tableStyle}>
          <a
            style={{ margin: '10px' }}
            onClick={() => {
              if (!isCurrent)
                update({ currentHours, currentHoursType });
            }}>
            edit
          </a>
        </td>
      </tr>
    ) : (
      <tr key={ind}>
        <td style={tableStyle}>
          {formattedDate}
        </td>
        <td style={tableStyle}>
          <a
            style={{ margin: '10px' }}
            onClick={() => {
              if (isCurrent)
                update({ currentHours, currentHoursType });
            }}>
            edit
          </a>
        </td>
        <td style={{ borderBottom: 'unset' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'white',
              height: '15px',
              width: '15px',
              border: '1px solid gray',
              borderRadius: '.25rem',
              fontSize: 'small',
              cursor: 'pointer',
            }}
            onClick={() => {
              const dHours = ({ d, id }) =>
                deleteHours({ date: d, id }).then(() => {
                  const hours = R.remove(ind, 1, state.hours);
                  if (R.equals('current', state.currentHoursType)) {
                    update({ hours });
                  } else {
                    const currentHoursType = 'current';
                    update({ hours, currentHours: R.head(hours), currentHoursType });
                  }
                });
              popupWithCancel(
                'Verification',
                'Are you sure you want to remove these hours?',
                () => dHours({ d: hour.date, id: props.id }),
                'danger'
              );
            }}
          >
            X
          </div>
        </td>
      </tr>
    );
  };

  const editPatch = (index, pos, prop, value) => {
    const updatePos = (p) => {
      const newTime = R.pipe(
        R.nth(index),
        R.prop(prop),
        R.update(p, value)
      )(state.editHours);
      return R.adjust(index, R.merge(R.__, { [prop]: newTime }))(state.editHours);
    };
    const editHours = R.cond([
      [R.equals('hour'), () => updatePos(0)],
      [R.equals('min'), () => updatePos(1)],
      [R.equals('med'), () => updatePos(2)],
      [R.equals('delete'), () => R.remove(index, 1, state.editHours)],
      [R.equals('new'), () => R.append(value, state.editHours)],
      [R.T, () => R.adjust(index, R.merge(R.__, { [prop]: value }))(state.editHours)]
    ])(pos);
    setState(R.merge(state, { editHours }));
  };

  const timeRows = (times, num, index) => {
    return (
      <tr key={index}>
        {times.map((a, ind) => (
          <td key={ind} style={{ borderBottom: 'unset' }} valign='middle'>
            <div style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}>
              <input
                type="text"
                value={a.time[0]}
                style={{
                  width: '20px',
                  marginRight: '2px',
                  border: '1px solid gray',
                  borderRadius: 3,
                  paddingTop: 2,
                  paddingLeft: 4,
                }}
                onChange={(e) => {
                  editPatch(index, 'hour', a.type, e.target.value);
                }}
              />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                :
              </div>
              <input
                type="text"
                value={a.time[1]}
                style={{
                  width: '20px',
                  marginLeft: '2px',
                  border: '1px solid gray',
                  borderRadius: 3,
                  paddingTop: 2,
                }}
                onChange={(e) => {
                  editPatch(index, 'min', a.type, e.target.value);
                }}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '20px',
                  marginLeft: '2px',
                  border: '1px solid gray',
                  color: 'black',
                  cursor: 'pointer',
                  paddingRight: 3,
                  paddingLeft: 3,
                  borderRadius: 3,
                }}
                onClick={() => {
                  if (a.time[2] === 'PM') {
                    editPatch(index, 'med', a.type, 'AM');
                  } else {
                    editPatch(index, 'med', a.type, 'PM');
                  }
                }}
              >
                {a.time[2]}
              </div>
            </div>
          </td>
        ))}
        <td style={{ borderBottom: 'unset' }} valign='middle'>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}>
            <input
              type="number"
              value={num}
              style={{ width: '30px', marginRight: '5px' }}
              onChange={(e) => {
                const value = Number(e.target.value);
                if (value >= 0) {
                  editPatch(index, 'num', 'numberOfAppointments', value);
                }
              }}
            />
          </div>
        </td>
        <td style={{ borderBottom: 'unset' }} valign='middle'>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'white',
                height: '20px',
                width: '20px',
                border: '1px solid gray',
                borderRadius: '.25rem',
                fontSize: 'large',
                cursor: 'pointer',
              }}
              onClick={() => {
                editPatch(index, 'delete');
              }}
            >
              X
            </div>
          </div>
        </td>
      </tr>
    );
  };

  const hoursModify = (hours) => {
    const headerStyle = {
      color: '#008BCF',
      fontSize: 'medium',
      textAlign: 'left',
    };
    return hours.length === 0 ? 'CLOSED' :
      (
        <table style={{ width: '100%' }}>
          <thead>
            <tr key={hours}>
              <th style={headerStyle}>
                Start
              </th>
              <th style={headerStyle}>
                End
              </th>
              <th style={headerStyle}>
                Max
              </th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {hours.map(({ open, close, numberOfAppointments }, index) => {
              return timeRows([{ type: 'open', time: open }, { type: 'close', time: close }], numberOfAppointments, index);
            })}
          </tbody>
        </table>
      );
  };

  const tempSave = (next = false) => {
    const newHours = R.pipe(
      R.sortBy(R.prop('open')),
      R.map(({ open, close, numberOfAppointments }) => ({
        open: format(LocalTime.parse(timeConvert(open)), 'HH:mm:ss'),
        close: format(LocalTime.parse(timeConvert(close)), 'HH:mm:ss'),
        numberOfAppointments,
      })))(state.editHours);
    const currentHours = R.evolve({
      hours: R.merge(R.__, { [state.editting]: newHours })
    }, state.currentHours);
    if (!state.edited) {
      const edited = !R.equals(newHours, R.prop(state.editting, state.currentHours.hours));
      next ?
        update(R.merge({ currentHours, edited }, next))
        :
        update({ editting: null, editHours: [], currentHours, edited });
    } else {
      next ?
        update(R.merge({ currentHours }, next))
        :
        update({ editting: null, editHours: [], currentHours });
    }
  };

  const specialTempSave = (next = false) => {
    const newHours = R.pipe(
      R.sortBy(R.prop('open')),
      R.map(({ open, close, numberOfAppointments }) => ({
        open: format(LocalTime.parse(timeConvert(open)), 'HH:mm:ss'),
        close: format(LocalTime.parse(timeConvert(close)), 'HH:mm:ss'),
        numberOfAppointments,
      })))(state.editHours);
    const specialCurrentHours = state.editting ? R.evolve({
      hours: R.merge(R.__, { [state.editting]: newHours })
    }, state.specialCurrentHours) : state.specialCurrentHours;
    if (!state.edited) {
      const edited = !R.equals(newHours, R.prop(state.editting, state.specialCurrentHours.hours));
      next ?
        update(R.merge({ specialCurrentHours, edited }, next))
        :
        update({ editting: null, editHours: [], specialCurrentHours, edited });
    } else {
      next ?
        update(R.merge({ specialCurrentHours }, next))
        :
        update({ editting: null, editHours: [], specialCurrentHours });
    }
  };

  const dayItem = (prop, day, hours) => {
    const capFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
    const isEditting = R.equals(state.editting, day);
    const newSet = {
      open: ['12', '00', 'PM'],
      close: ['12', '00', 'PM'],
      numberOfAppointments: props.defaultMax,
    };

    const onChange = () => {
      R.cond([
        [R.equals(day), () => prop === 'special' ? specialTempSave() : tempSave()],
        [R.T, () =>
          prop === 'special' ?
            specialTempSave({
              editting: day,
              editHours: R.pipe(
                R.sortBy(R.prop('open')),
                R.map(formatEditTimes)
              )(hours)
            }) :
            tempSave({
              editting: day,
              editHours: R.pipe(
                R.sortBy(R.prop('open')),
                R.map(formatEditTimes)
              )(hours)
            })]
      ])(state.editting);
    };

    return (
      <TableRow key={day}>
        <BodyCell
          valign='middle'
          style={{ height: '10%' }}>
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={isEditting}
              style={{ marginRight: 8 }}
              onChange={onChange}
            />
            {capFirstLetter(day)}
          </label>
        </BodyCell>
        {isEditting ?
          <BodyCell style={{ height: '10%' }}>
            {decipherTime(hours)}
            <br />
            {hoursModify(state.editHours)}
            <div style={{
              display: 'flex',
              alignItems: 'center',
            }}>
              <HeaderButton
                onClick={() => editPatch(null, 'new', null, newSet)}
                borderSolid
                title="Add new hours"
              />
              <HeaderButton
                onClick={() => update({
                  editHours: R.pipe(
                    R.sortBy(R.prop('open')),
                    R.map(formatEditTimes)
                  )(hours)
                })}
                borderSolid
                title='Revert'
              />
              <HeaderButton
                onClick={onChange}
                color='primary'
                title="Add"
              />
              <CopyPasteMenu
                onCopy={() => {
                  if (R.isEmpty(state.editHours)) {
                    sessionStorage.setItem('single-day-hours', JSON.stringify(hours));
                    update({ copiedHours: true });
                  } else {
                    const newHours = R.pipe(
                      R.sortBy(R.prop('open')),
                      R.map(({ open, close, numberOfAppointments }) => ({
                        open: format(LocalTime.parse(timeConvert(open)), 'HH:mm:ss'),
                        close: format(LocalTime.parse(timeConvert(close)), 'HH:mm:ss'),
                        numberOfAppointments,
                      })))(state.editHours);
                    sessionStorage.setItem('single-day-hours', JSON.stringify(newHours));
                    update({ copiedHours: true });
                  }
                }}
                onPaste={() => {
                  const hours = JSON.parse(sessionStorage.getItem('single-day-hours'));
                  update({
                    ...state,
                    edited: true,
                    editHours: R.pipe(
                      R.sortBy(R.prop('open')),
                      R.map((hour) => {
                        if (R.has('numberOfAppointments', hour)) {
                          return formatEditTimes(hour);
                        }
                        return formatEditTimes(R.assoc('numberOfAppointments', props.defaultMax, hour));
                      })
                    )(hours),
                    pastedHours: true,
                  });
                }}
                hoursType='single-day-hours'
                style={{ fontSize: '2rem' }}
              />
            </div>
          </BodyCell>
          :
          <BodyCell
            style={{ cursor: 'pointer', height: '10%' }}
            onClick={() => R.cond([
              [R.equals(day), () => prop === 'special' ? specialTempSave() : tempSave()],
              [R.T, () =>
                prop === 'special' ?
                  specialTempSave({
                    editting: day,
                    editHours: R.pipe(
                      R.sortBy(R.prop('open')),
                      R.map(formatEditTimes)
                    )(hours)
                  }) :
                  tempSave({
                    editting: day,
                    editHours: R.pipe(
                      R.sortBy(R.prop('open')),
                      R.map(formatEditTimes)
                    )(hours)
                  })]
            ])(state.editting)}>
            {decipherTime(hours)}
          </BodyCell>}
        <BodyCell
          valign='top'
          style={{
            height: '10%',
            cursor: 'pointer'
          }}
          onClick={() => R.cond([
            [R.equals(day), () => prop === 'special' ? specialTempSave() : tempSave()],
            [R.T, () =>
              prop === 'special' ?
                specialTempSave({
                  editting: day,
                  editHours: R.pipe(
                    R.sortBy(R.prop('open')),
                    R.map(formatEditTimes)
                  )(hours)
                }) :
                tempSave({
                  editting: day,
                  editHours: R.pipe(
                    R.sortBy(R.prop('open')),
                    R.map(formatEditTimes)
                  )(hours)
                })]
          ])(state.editting)}>
          {hours.length === 0 && <div></div>}
          {hours.map(({ numberOfAppointments }, i) => (
            <div key={i}>
              {numberOfAppointments}
            </div>
          ))}
        </BodyCell>
      </TableRow>
    );
  };

  const table = (prop, hours) => {
    return (
      <Grid marginX={-2}>
        <TableContainer noHover style={{ overflowY: 'auto' }}>
          <TableHead>
            <TableRow key={prop}>
              <HeaderCell style={{ color: 'black' }}>Day of Week</HeaderCell>
              <HeaderCell style={{ color: 'black' }}>Hours</HeaderCell>
              <HeaderCell style={{ color: 'black' }}>
                <div style={{ display: 'flex' }}>
                  Max
                  <Tooltip
                    title={`Max number of appointments per ${props.slotLength} minutes`}
                    placement="top"
                    arrow>
                    <InfoIcon style={{ fontSize: '14px', marginLeft: 8 }} />
                  </Tooltip>
                </div>
              </HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {days.map((day) =>
              dayItem(
                prop,
                day,
                R.pipe(
                  R.propOr([], day),
                  R.sortBy(R.prop('open'))
                )(hours)))}
          </TableBody>
        </TableContainer>
      </Grid>
    );
  };

  const saveSpecialHours = () => {
    new Promise((res) => {
      if (!R.isNil(state.editting)) {
        specialTempSave();
        res();
      } else {
        res();
      }
    }).then(() => {
      saveSpecial(props.id, state.specialCurrentHours, state.originalStart)
        .then((newHours) => {
          const specialHours = R.pipe(
            R.filter((hour) => {
              return !now('datetime').isAfter(LocalDateTime.parse(hour.end + 'T23:59:59'));
            }),
            R.sortBy(
              R.prop('start'))
          )(newHours);
          update({
            specialHours,
            edited: false,
            specialHoursState: 'LIST',
          });
        });
    });
  };

  const saveHours = () => {
    new Promise((res) => {
      if (!R.isNil(state.editting)) {
        tempSave();
        res();
      } else {
        res();
      }
    }).then(() => {
      if (hasHours(R.propOr(basic, 'hours')(state.currentHours))) {
        tempSave();
        props.save(state.currentHours);
      } else {
        popup('Warning!', 'You cannot create all-closed hours!');
      }
    });
  };

  const saveHoursChangeDate = () => {
    new Promise((res) => {
      if (!R.isNil(state.editting)) {
        tempSave();
        res();
      } else {
        res();
      }
    }).then(() => {
      if (hasHours(R.propOr(basic, 'hours')(state.currentHours))) {
        update({ openSelectDate: true });
        /* props.save(state.currentHours);             */
      } else {
        popup('Warning!', 'You cannot create all-closed hours!');
      }
    });
  };

  const saveOther = () => {
    update({ needSave: true });
  };

  const onSaved = () => {
    update({ needSave: false });
  };

  const buttonLogic = () => {
    if (R.equals('special', state.tab)) {
      if (state.edited)
        return [
          <HeaderButton
            key='1'
            color='primary'
            disabled={!state.specialCurrentHours.name || !R.isNil(state.duplicate)}
            onClick={saveSpecialHours}
            title="Save"
            className='sked-test-provider-schedule-view-current-hours-modal-button-save'
          />
        ];
    }
    if (R.equals('other', state.tab)) {
      return [
        <HeaderButton
          title='Add'
          borderSolid
          Icon={KeyboardArrowDownIcon}
          iconStyle={{ marginRight: -6 }}
          onClick={(e) => update({ otherAnchorEl: e.currentTarget })}
          className='sked-test-provider-schedule-view-current-hours-modal-button-add'
        />,
        <HeaderButton
          key='2'
          color='primary'
          onClick={saveOther}
          title="Save"
          className='sked-test-provider-schedule-view-current-hours-modal-button-save'
        />
      ];
    }
    if (R.isEmpty(props.hours) && state.edited) {
      return [
        <HeaderButton
          key='2'
          color='primary'
          onClick={saveHours}
          // disabled={!hasHours(R.propOr(basic, 'hours')(state.currentHours))}
          title="Save"
          className='sked-test-provider-schedule-view-current-hours-modal-button-save'
        />
      ];
    } else if (R.equals('future', state.currentHoursType)) {
      return [
        <HeaderButton
          key='3'
          color='primary'
          onClick={saveHours}
          // disabled={!hasHours(R.propOr(basic, 'hours')(state.currentHours))}
          title="Save"
          marginRight={5}
          className='sked-test-provider-schedule-view-current-hours-modal-button-save'
        />,
        <HeaderButton
          key='4'
          color='primary'
          onClick={saveHoursChangeDate}
          title="Change Start Date & Save"
          className='sked-test-provider-schedule-view-current-hours-modal-button-save'
        />
      ];
    } else if (state.edited) {
      return [
        <HeaderButton
          key='5'
          color='primary'
          onClick={saveHours}
          title="Start Now"
          // disabled={!hasHours(R.propOr(basic, 'hours')(state.currentHours))}
          marginRight={5}
          className='sked-test-provider-schedule-view-current-hours-modal-button-start-now'
        />,
        <HeaderButton
          key='6'
          borderSolid
          onClick={saveHoursChangeDate}
          // disabled={!hasHours(R.propOr(basic, 'hours')(state.currentHours))}
          title="Start Later"
          className='sked-test-provider-schedule-view-current-hours-modal-button-start-later'
        />
      ];
    }
    return null;
  };

  const duplicateChecker = (Start, End = null) => {
    return R.pipe(
      R.map(({ start, end, name }) => {
        const dupStart = LocalDate.parse(start);
        const dupEnd = LocalDate.parse(end);
        const currentStart = LocalDate.parse(Start);
        const startBetween = isBetween(currentStart, dupStart, dupEnd, '[]');
        if (name === state.specialHoursCachedName) {
          return false;
        } else if (state.specialCurrentHours.oneDay) {
          if (startBetween) {
            return {
              overlapStart: currentStart,
            };
          } else {
            return false;
          }
        } else {
          const currentEnd = End ? LocalDate.parse(End) : now('date');
          if (startBetween) {
            return {
              overlapStart: currentStart,
              overlapEnd: dupEnd,
            };
          } else if (isBetween(currentEnd, dupStart, dupEnd, '[]')) {
            return {
              overlapStart: dupStart,
              overlapEnd: currentEnd,
            };
          } else {
            return false;
          }
        }
      }),
      R.filter((a) => a),
      R.head,
    )(state.specialHours);
  };

  const specialHoursEdit = () => {
    const currentHours = R.path(['specialCurrentHours', 'start'], state);
    const oneDay = R.path(['specialCurrentHours', 'oneDay'], state);
    const d = currentHours ? format(LocalDate.parse(currentHours), 'eeee').toLowerCase() : '';
    const duplicate = state.duplicate;

    return oneDay ?
      (
        <div>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <TextField
              name="name"
              label="Name"
              value={state.specialCurrentHours.name}
              style={{ width: '25%' }}
              onChange={(e) =>
                update({
                  edited: true,
                  specialCurrentHours:
                    R.merge(
                      state.specialCurrentHours,
                      { name: e.target.value })
                })
              } />
            <TextField
              type='date'
              locale="en-US"
              name="date"
              label="Date"
              value={state.specialCurrentHours.start}
              style={{ width: '25%' }}
              onChange={(e) => {
                const start = e.target.value;
                try {
                  LocalDate.parse(start);
                } catch (e) {
                  return '';
                }
                const specialCurrentHours = R.evolve({
                  start: R.always(start),
                  end: R.always(start),
                  hours: R.assoc(d, []),
                }, state.specialCurrentHours);
                return update({
                  specialCurrentHours,
                  edited: true,
                  originalStart: state.specialCurrentHours.start,
                  duplicate: duplicateChecker(start),
                });
              }}
            />
          </div>
          {duplicate &&
            <p style={{ fontWeight: 'bold', color: 'red' }}>
              You already have special hours set on {format(duplicate.overlapStart, 'MM/dd/yyyy')}!
            </p>}
          <Grid marginX={-2}>
            <TableContainer noHover style={{ overflowY: 'auto' }}>
              <TableHead>
                <TableRow key={'onedaymore'}>
                  <HeaderCell style={{ color: 'black' }}>Day of Week</HeaderCell>
                  <HeaderCell style={{ color: 'black' }}>Hours</HeaderCell>
                  <HeaderCell style={{ color: 'black' }}>
                    <div style={{ display: 'flex' }}>
                      Max
                      <Tooltip
                        title={`Max number of appointments per ${props.slotLength} minutes`}
                        placement="top"
                        arrow>
                        <InfoIcon style={{ fontSize: 14, marginLeft: 8 }} />
                      </Tooltip>
                    </div>
                  </HeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dayItem(
                  'special',
                  d,
                  R.propOr([], d)(state.specialCurrentHours.hours))}
              </TableBody>
            </TableContainer>
          </Grid>
          {state.specialCurrentHours.scheduleId &&
            <HeaderButton
              onClick={() => {
                const dHours = ({ d, id }) =>
                  deleteSpecialHours({ start: d, id }).then((hours) => {
                    const specialHours = R.pipe(
                      R.filter((hour) => {
                        const end = typeof hour.end === 'string' ? LocalDate.parse(hour.end) : hour.end;
                        return !now('date').isAfter(end);
                      }),
                      R.sortBy(
                        R.prop('start'))
                    )(hours);
                    update({
                      specialHours,
                      edited: false,
                      specialCurrentHours: {},
                      specialHoursState: 'LIST',
                    });
                  });
                popupWithCancel(
                  'Verification',
                  'Are you sure you want to remove these hours?',
                  () => dHours({ d: state.specialCurrentHours.start, id: props.id }),
                  'danger'
                );
              }}
              borderSolid
              title="Remove Hours"
            />}
        </div>
      ) :
      (
        <div>
          <div style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <TextField
              name="name"
              label="Name"
              value={state.specialCurrentHours.name}
              style={{ width: '25%' }}
              onChange={(e) =>
                update({
                  edited: true,
                  specialCurrentHours:
                    R.merge(
                      state.specialCurrentHours,
                      { name: e.target.value })
                })
              } />
            <TextField
              type='date'
              name="date"
              label="Start Date"
              value={state.specialCurrentHours.start}
              style={{ width: '25%' }}
              onChange={(e) => {
                const start = e.target.value;
                const specialCurrentHours = R.merge(
                  state.specialCurrentHours,
                  { start });
                update({
                  specialCurrentHours,
                  edited: true,
                  originalStart: state.specialCurrentHours.start,
                  duplicate: duplicateChecker(start, state.specialCurrentHours.end),
                });
              }}
            />
            <TextField
              type='date'
              name="date"
              label="End Date"
              value={state.specialCurrentHours.end}
              style={{ width: '25%' }}
              onChange={(e) => {
                const end = e.target.value;
                const specialCurrentHours = R.merge(
                  state.specialCurrentHours,
                  { end });
                update({
                  specialCurrentHours,
                  edited: true,
                  duplicate: duplicateChecker(state.specialCurrentHours.start, end),
                });
              }}
            />
          </div>
          {duplicate &&
            (duplicate.overlapStart.equals(duplicate.overlapEnd) ?
              <p style={{ fontWeight: 'bold', color: 'red' }}>
                You already have special hours set on {format(duplicate.overlapStart, 'MM/dd/yyyy')}!
              </p>
              :
              <p style={{ fontWeight: 'bold', color: 'red' }}>
                You already have special hours set from {format(duplicate.overlapStart, 'MM/dd/yyyy')} to {format(duplicate.overlapEnd, 'MM/dd/yyyy')}!
              </p>)
          }
          {table('special', R.propOr([], 'hours')(state.specialCurrentHours))}
          {state.specialCurrentHours.scheduleId &&
            <HeaderButton
              onClick={() => {
                const dHours = ({ d, id }) =>
                  deleteSpecialHours({ start: d, id }).then((hours) => {
                    const specialHours = R.pipe(
                      R.filter((hour) => {
                        const end = typeof hour.end === 'string' ? LocalDate.parse(hour.end) : hour.end;
                        return !now('date').isAfter(end);
                      }),
                      R.sortBy(
                        R.prop('start'))
                    )(hours);
                    update({
                      specialHours,
                      edited: false,
                      specialCurrentHours: {},
                      specialHoursState: 'LIST',
                    });
                  });
                popupWithCancel(
                  'Verification',
                  'Are you sure you want to remove these hours?',
                  () => dHours({ d: state.specialCurrentHours.start, id: props.id }),
                  'danger'
                );
              }}
              title="Remove Hours"
              borderSolid
            />}
        </div>
      );
  };

  const specialHoursList = () => {
    const listItem = (hour) => (
      <TableRow
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (hour.start === hour.end) {
            update({
              specialCurrentHours: R.merge(hour, { oneDay: true }),
              specialHoursState: 'EDIT',
              specialHoursCachedName: hour.name,
            });
          } else {
            update({
              specialCurrentHours: hour,
              specialHoursState: 'EDIT',
              specialHoursCachedName: hour.name,
            });
          }
        }}
        key={hour.name}>
        <BodyCell>
          {hour.name}
        </BodyCell>
        <BodyCell>
          {format(LocalDate.parse(hour.start), 'M/dd/yyyy')}
        </BodyCell>
        <BodyCell>
          {format(LocalDate.parse(hour.end), 'M/dd/yyyy')}
        </BodyCell>
      </TableRow>
    );
    return (
      <div>
        <div style={{
          margin: '10px'
        }}>
          <Menu
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            id="long-menu"
            anchorEl={state.anchorEl}
            open={Boolean(state.anchorEl) && state.tab !== 'other'}
            onClose={() => update({ anchorEl: null })}>
            <MenuItem
              onClick={() => {
                update({
                  specialCurrentHours: R.merge(specialBasic, { oneDay: true }),
                  specialHoursState: 'EDIT',
                  duplicate: duplicateChecker(specialBasic.start),
                  anchorEl: null
                });
              }}>
              Single Day
            </MenuItem>
            <MenuItem
              onClick={() => {
                update({
                  specialCurrentHours: specialBasic,
                  specialHoursState: 'EDIT',
                  duplicate: duplicateChecker(specialBasic.start, specialBasic.end),
                  anchorEl: null
                });
              }}>
              Multiple Days
            </MenuItem>
          </Menu>
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          {R.isEmpty(state.specialHours) ?
            'No special hours set' :
            <Grid marginX={-2}>
              <TableContainer noHover>
                <TableHead>
                  <TableRow>
                    <HeaderCell>
                      Name
                    </HeaderCell>
                    <HeaderCell>
                      Start
                    </HeaderCell>
                    <HeaderCell>
                      End
                    </HeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.specialHours.map(listItem)}
                </TableBody>
              </TableContainer>
            </Grid>
          }
        </div>
      </div>
    );
  };

  const showActions = () => {
    const extraButtons = buttonLogic();
    if (R.isNil(extraButtons)) {
      return null;
    }
    return (extraButtons);
  };

  const currentHoursStart = R.propOr('2020-01-01', 'date')(state.currentHours);
  const name = props.scheduleToEdit ? props.scheduleToEdit.name : '';
  let title = 'Viewing Current Hours for ' + name;
  if (R.equals('special', state.tab)) {
    if (R.equals('LIST', state.specialHoursState)) {
      title = 'Viewing List of Special Hours - ' + name;
    } else {
      title = 'Viewing Special Hours That Start on ' +
          format(LocalDate.parse((state.specialCurrentHours || emptyObject).start), 'M/dd/yyyy') +
          ' - ' + name;
    }
  } else if (R.equals('blocks', state.tab)) {
    title = 'Blocks for ' + name;
  } else {
    if (R.equals('current', state.currentHoursType)) {
      title = 'Viewing Current Hours for ' + name;
    } else {
      title = 'Viewing Hours for ' + name + ' That Start on ' +
          format(LocalDate.parse(state.currentHours.date), 'M/dd/yyyy');
    }
  }
  const [onCopy, onPaste] = R.cond([
    [R.equals('hours'), R.always([
      () => {
        sessionStorage.setItem('hours', JSON.stringify(state.currentHours.hours));
        update({ copiedHours: true });
      },
      () => {
        const hours = JSON.parse(sessionStorage.getItem('hours'));
        update({
          ...state,
          edited: true,
          editHours: R.pipe(
            R.sortBy(R.prop('open')),
            R.map(formatEditTimes)
          )(hours),
          currentHours: {
            ...state.currentHours,
            hours: R.mapObjIndexed((day) => {
              return R.map((hour) => {
                if (R.has('numberOfAppointments', hour)) {
                  return hour;
                }
                return R.assoc('numberOfAppointments', props.defaultMax, hour);
              })(day);
            })(hours),
          },
          pastedHours: true,
        });
      }
    ])],
    [R.equals('special'), R.always([
      () => {
        sessionStorage.setItem('hours', JSON.stringify(state.specialCurrentHours.hours));
        update({ copiedHours: true });
      },
      () => {
        const hours = JSON.parse(sessionStorage.getItem('hours'));
        update({
          ...state,
          edited: true,
          editHours: R.pipe(
            R.sortBy(R.prop('open')),
            R.map(formatEditTimes)
          )(hours),
          specialCurrentHours: {
            ...state.specialCurrentHours,
            hours: R.mapObjIndexed((day) => {
              return R.map((hour) => {
                if (R.has('numberOfAppointments', hour)) {
                  return hour;
                }
                return R.assoc('numberOfAppointments', props.defaultMax, hour);
              })(day);
            })(hours),
          },
          pastedHours: true,
        });
      }
    ])],
    [R.T, R.always([null, null])]
  ])(state.tab);
  return (
    <Modal
      open={props.open}
      size='md'
      onClose={() => {
        update(initState);
        props.onClose();
      }}
      title={title}
      className='sked-test-provider-schedule-view-current-hours-modal'
      leftComponents={[
        <HeaderTabs
          value={state.tab}
          tabs={[
            { label: 'Hours', value: 'hours', className: 'sked-test-provider-schedule-view-current-hours-modal-tab-hours' },
            { label: 'Special hours', value: 'special', className: 'sked-test-provider-schedule-view-current-hours-modal-tab-special-hours' },
            R.includes('BlocksGenesis', props.features) && { label: 'Genesis Blocks', value: 'pro-blocks', className: 'sked-test-provider-schedule-view-current-hours-modal-tab-block' },
            props.hasLinkedScheduleFeature && { label: 'Other Schedules', value: 'other', className: 'sked-test-provider-schedule-view-current-hours-modal-tab-other' }
          ]}
          setTab={(val) => {
            if (val === 'hours') {
              update({
                tab: val,
                editting: null,
                editHours: {},
                specialHoursState: 'LIST',
                edited: false,
                currentHours: R.head(state.hours),
              });
            } else {
              update({
                tab: val,
                editting: null,
                specialHoursState: 'LIST',
                editHours: {},
                edited: false,
                specialCurrentHours: R.head(state.specialHours),
              });
            }
          }}
        />,
      ]}
      buttons={[
        <Grid marginLeft="8px" />,
        state.tab === 'special' && R.cond([
          [R.equals('LIST'), () => <HeaderButton
            title='Add'
            borderSolid
            Icon={AddIcon}
            iconStyle={{ marginRight: -6 }}
            onClick={(e) => update({ anchorEl: e.currentTarget })}
            className='sked-test-provider-schedule-view-current-hours-modal-button-add'
          />],
          [R.equals('EDIT'), () => <HeaderButton
            title='Back'
            borderSolid
            className='sked-test-provider-schedule-view-current-hours-modal-button-back'
            onClick={() => update({
              specialHoursState: 'LIST',
              editting: null,
              editHours: {},
              edited: false,
              specialCurrentHours: R.head(state.specialHours),
            })}
          />],
        ])(state.specialHoursState),
        showActions(),
        onCopy && (state.tab === 'special' ? state.specialHoursState === 'EDIT' : true) &&
          <CopyPasteMenu
            onCopy={onCopy}
            onPaste={onPaste}
            hoursType='hours'
          />
      ]}
    >
      <div>
        <PopupTemplate />
        <Snackbar
          open={state.copiedHours}
          message='Hours Copied!'
          autoHideDuration={4000}
          onClose={() => update({ copiedHours: false })} />
        <Snackbar
          open={state.pastedHours}
          message='Hours Pasted!'
          autoHideDuration={4000}
          onClose={() => update({ pastedHours: false })} />
        <div hidden={state.tab !== 'hours'}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {R.gt(R.length(state.hours), 1) &&
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '50%'
                }}>
                  <table>
                    <tbody>
                      {state.hours.map((hour, ind) => optionItem(hour, ind))}
                    </tbody>
                  </table>
                </div>}
            <div style={{ zIndex: state.showDatePicker ? 1000 : -1, height: '1px' }}>
              <SelectDate
                state={state}
                save={props.save}
                currentHoursStart={currentHoursStart}
                open={state.openSelectDate}
                onClose={() => update({ openSelectDate: false })}
              />
            </div>
          </div>
          {table('hours', R.propOr([], 'hours')(state.currentHours))}
          <SelectLocation
            basic={basic}
            defaultMax={props.defaultMax}
            hasLocations={props.hasLocations}
            locations={props.locations}
            office={props.office}
            officeHours={props.officeHours}
            update={update}
            currentHours={state.hours}
          />
        </div>
        <div hidden={state.tab !== 'special'}>
          {state.tab === 'special' && R.cond([
            [R.equals('LIST'), () => specialHoursList()],
            [R.equals('EDIT'), () => specialHoursEdit()],
          ])(state.specialHoursState)}
        </div>
        <div hidden={state.tab !== 'other'}>
          <ScheduleGroup
            onCancel={() => {
              update(initState);
              props.onClose();
            }}
            id={props.id}
            scheduleToEdit={props.scheduleToEdit}
            schedules={state.schedules}
            needSave={state.needSave}
            onSaved={onSaved}
            anchorEl={state.otherAnchorEl}
            setAnchorEl={(val) => update({ otherAnchorEl: val })}
          />
        </div>
        <div hidden={state.tab !== 'blocks'}>
          <Blocks id={props.id} />
        </div>
        { // Only load when tab is active in-case there's a lot
          // of pro blocks saved - don't want to slow down the rest of the
          // pop up
          state.tab === 'pro-blocks' && <div>
            <ProBlocks id={props.id} />
          </div>}
      </div>
    </Modal>
  );
};

export default EditHours;
