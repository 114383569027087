import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  content: {
    overflowY: 'visible',
  },
  formTags: {
    width: 'fit-content',
    minWidth: 210,
  },
  formControl: {
    width: '100%',
  },
  inputLabel: {
    fontSize: 14,
    marginBottom: 5,
    fontWeight: 500,
  },
  input: {
    width: '100%',

    '& .MuiInputBase-root': {
      borderRadius: 10
    },
    '& .MuiAutocomplete-input': {
      minWidth: '78px !important',
    }
  },
  select: {
    width: '100%',
    borderRadius: 10,

    '& .MuiSelect-select': {
      width: '100%',
      minWidth: 42,
      paddingLeft: 10,
    },
    '& .MuiAutocomplete-input': {
      padding: '8.5px 14px',
    }
  },
  nofitifations: {
    minWidth: 128,
  },
  comboOptions: {
    fontSize: 12,
    marginLeft: 0,
    paddingLeft: 0,
  },
  container: {
    display: 'grid',
    gridGap: '6px', /* adjust the gap to your preference */
    gridTemplateColumns: 'repeat(auto-fit, minmax(170px, 1fr))'
  },
  importLabel: {
    fontSize: 16,
    marginRight: 12,
    fontWeight: 500,
    color: '#555555',
  }
}));
