export const countries = [
  { value: 'Canada', label: 'Canada' },
  { value: 'UK', label: 'UK' },
  { value: 'US', label: 'US' },
  { value: 'AW', label: 'Aruba' },
  { value: 'MX', label: 'Mexico' },
];
export const timezones = [
  // Canada
  { country: 'Canada', value: 'America/Halifax', label: 'Atlantic' },
  { country: 'Canada', value: 'America/St_Johns', label: 'Newfoundland' },
  { country: 'Canada', value: 'America/Toronto', label: 'Eastern' },
  { country: 'Canada', value: 'America/Winnipeg', label: 'Central' },
  { country: 'Canada', value: 'America/Edmonton', label: 'Mountain' },
  { country: 'Canada', value: 'America/Regina', label: 'Saskatchewan' },
  { country: 'Canada', value: 'America/Vancouver', label: 'Pacific' },
  { country: 'Canada', value: 'America/Whitehorse', label: 'Yukon' },
  // UK
  { country: 'UK', value: 'Europe/London', label: 'London' },
  // USA
  { country: 'US', value: 'America/New_York', label: 'Eastern' },
  { country: 'US', value: 'America/Chicago', label: 'Central' },
  { country: 'US', value: 'America/Denver', label: 'Mountain' },
  { country: 'US', value: 'America/Phoenix', label: 'Arizona' },
  { country: 'US', value: 'America/Los_Angeles', label: 'Pacific' },
  { country: 'US', value: 'America/Anchorage', label: 'Alaska' },
  { country: 'US', value: 'Pacific/Honolulu', label: 'Hawaii–Aleutian' },
  { country: 'US', value: 'America/Puerto_Rico', label: 'Puerto Rico' },
  // International
  { country: 'AW', value: 'Atlantic/Bermuda', label: 'Atlantic' },

  { country: 'MX', value: 'America/Tijuana', label: 'Tijuana' },
  { country: 'MX', value: 'America/Hermosillo', label: 'Hermosillo' },
  { country: 'MX', value: 'America/Mazatlan', label: 'Mazatlan' },
  { country: 'MX', value: 'America/Mexico_City', label: 'Mexico_City' },
  { country: 'MX', value: 'America/Cancun', label: 'Cancun' },
];
