import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    margin: '10px 0 0 20px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowX: 'hidden',
  },
}));
