import api from '../../services/api.js';
import { sortWith, ascend, prop } from 'ramda';

export const getProBlocks = scheduleId => {
  return api.get(`/schedule/${scheduleId}/block/professional`).then(sortWith([
    ascend(prop('date')),
    ascend(t => t.startTime.Include || t.startTime.Exclude)
  ]));
};

export const getBlocks = scheduleId => {
  return api.get(`/schedule/${scheduleId}/block`).then(sortWith([
    ascend(prop('date')),
    ascend(t => t.startTime.Include || t.startTime.Exclude)
  ]));
};

export const postBlocks = body => {
  return api.post('/schedule/block', body);
};

export const putBlock = (body) => {
  return api.put(`/schedule/block/${body.blockId}`, body);
};

export const deleteBlock = (blockId) => {
  return api.delete(`/schedule/block/${blockId}`);
};
