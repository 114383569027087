import React, { useEffect, useState } from 'react';
import { History } from 'history';

import {
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  FormControl,
  DialogActions,
  IconButton,
  TextField,
  Grid,
  TableHead,
  TableRow,
  TableBody,
  Popover,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  SelectChangeEvent
} from '@mui/material';
import { HexColorPicker } from 'react-colorful';
import Add from '@mui/icons-material/Add';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import Attachment from '@mui/icons-material/AttachFile';
import Colorize from '@mui/icons-material/Colorize';

import { LocalDate, ZonedDateTime } from '@js-joda/core';

import { usePromise } from '../../../services/promise.hook';
import { useCheckIsAdmin } from '../../../services/checkIsAdmin.hook';

import api from '../../../services/api';
import { popup, PopupTemplate } from '../../../services/Popup.js';
import { format } from '../../../services/joda.js';
import { useSelector } from '../../../reducers';

import BackdropLoading from '../../../components/BackdropLoading/backdropLoading.component';
import BannerPreview from '../../../components/BannerPreview/bannerPreview.component';
import { TableContainer, HeaderCell, BodyCell } from '../../../components/CustomTable';

import { Banner } from './banners.types';
import { useStyles } from './banners.styles';

const DEFAULT_COLOR = '#7F80E3';
const DEFAULT_TEXT_COLOR = '#ffffff';

interface File extends Blob {
  readonly lastModified: number;
  readonly name: string;
}

interface Attachment {
  attachmentName: string;
  attachmentId: number;
  attachmentUrl: string;
  attachmentMimeType: string;
}

const uploadFile = async (file: File): Promise<Attachment> => {
  const formData = new FormData();
  formData.append('attachment', file, file.name);
  const result = await api.post(
    `${process.env.API_URL}/attachment/upload`,
    formData
  );
  return result.officeAttachments[0];
};

const BannersPage = ({ history }: { history: History }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState('');
  const [color, setColor] = useState(DEFAULT_COLOR);
  const [textColor, setTextColor] = useState(DEFAULT_TEXT_COLOR);
  const [link, setLink] = useState('');
  const [linkText, setLinkText] = useState('');
  const [align, setAlign] = useState('left');
  const [priority, setPriority] = useState(0);
  const [banners, setBanners] = useState<Banner[]>([]);
  const [idToRemove, setIdToRemove] = useState<number>();
  const [selectedId, setSelectedId] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [colorPiker, setColorPiker] = useState('');
  const [showActives, setShowActive] = useState(true);
  const [isFile, setIsFile] = useState<number>(0);
  const [dateRange, setDateRange] = React.useState({
    startAt: LocalDate.now().toString(),
    endAt: LocalDate.now().toString(),
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [file, setFile] = React.useState<File>(null);
  const fileState = usePromise<File, Attachment>(uploadFile, null);

  const sessionStatus = useSelector(state => state.session.status);
  const admin = useSelector(state => state.login.admin);

  const handleFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFile(event.target.files[0]);
  };

  const handleOpenColorPiker =
    (piker: string) => (event: React.MouseEvent<HTMLDivElement>) => {
      setColorPiker(piker);
      setAnchorEl(event.currentTarget);
    };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedId(null);
    setDescription('');
    setAlign('left');
    setFile(null);
    setIsFile(0);
    setColor(DEFAULT_COLOR);
    setPriority(0);
    setTextColor(DEFAULT_TEXT_COLOR);
    setLink('');
    setLinkText('');
    setDateRange({
      startAt: LocalDate.now().toString(),
      endAt: LocalDate.now().toString(),
    });
  };

  const parseDescription = (bannerDescripton: string) => {
    try {
      const response = JSON.parse(bannerDescripton);
      return {
        description: response.description || '',
        align: response.align || 'left',
      };
    } catch {
      return {
        description: bannerDescripton || '',
        align: 'left',
      };
    }
  };

  const handleEdit = (banner: Banner) => {
    setSelectedId(banner.bannerId);
    setColor(banner.backgroundColor);
    setTextColor(banner.color);
    setPriority(banner.priority);
    setLink(banner.link ? banner.link.url : '');
    setLinkText(banner.link ? banner.link.text : '');
    const response = parseDescription(banner.description);
    setDescription(response.description);
    setAlign(response.align);
    setDateRange({
      startAt: format(ZonedDateTime.parse(banner.startAt), 'YYYY-MM-dd'),
      endAt: format(ZonedDateTime.parse(banner.endAt), 'YYYY-MM-dd'),
    });
    setOpen(true);
  };

  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(event.target.value);
  };

  const handleChangeColor = (color: string) => {
    if (colorPiker === 'color') {
      setColor(color);
    } else {
      setTextColor(color);
    }
  };

  const handleChangePriority = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setPriority(Number(event.target.value));
  };

  const selectDate = (prop: string) => (newDate: string) => {
    if (!newDate) {
      return;
    }
    if (prop === 'startAt') {
      if (ZonedDateTime.parse(`${newDate}T00:00:00Z`).isAfter(ZonedDateTime.parse(`${dateRange.endAt}T23:59:00Z`))) {
        setDateRange({
          startAt: newDate.toString(),
          endAt: newDate.toString(),
        });
        return;
      }
    } else {
      if (ZonedDateTime.parse(`${newDate}T23:59:00Z`).isBefore(ZonedDateTime.parse(`${dateRange.startAt}T00:00:00Z`))) {
        setDateRange({
          startAt: newDate.toString(),
          endAt: newDate.toString(),
        });
        return;
      }
    }
    setDateRange((s) => ({ ...s, [prop]: newDate.toString() }));
  };

  const compare = (itemA: Banner, itemB: Banner) => {
    if (itemA.priority < itemB.priority) {
      return 1;
    }
    if (itemA.priority > itemB.priority) {
      return -1;
    }
    return 0;
  };

  const getBanners = async () => {
    setLoading(true);
    try {
      const data: Banner[] = await api.get('banner');
      if (showActives) {
        const filtered = data.filter((banner) => {
          return (
            ZonedDateTime.parse(banner.startAt).isBefore(ZonedDateTime.now()) &&
            ZonedDateTime.parse(banner.endAt).isAfter(ZonedDateTime.now())
          );
        });
        setBanners(filtered.sort(compare));
      } else {
        setBanners(data.sort(compare));
      }
    } catch (error) {
      popup('Error!', 'Failed to get banners!');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = async (id: number) => {
    setLoading(true);
    try {
      setIdToRemove(null);
      await api.delete(`banner/${id}`);
      await getBanners();
    } catch (error) {
      console.error(error);
      popup('Error!', 'Failed to remove banner!');
      setLoading(false);
    }
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    let linkUrl = link;

    if (file) {
      try {
        setOpen(false);
        const result = await fileState.invoke(file);
        linkUrl = result.attachmentUrl;
      } catch (error) {
        console.error(error);
        setLoading(false);
        handleClose();
        popup('Error!', 'Failed to upload file!');
        return;
      }
    }

    const newDescription = JSON.stringify({ align, description });

    try {
      const body = {
        priority: Number(priority),
        backgroundColor: color,
        color: textColor,
        link: {
          url: linkUrl,
          text: linkText,
        },
        startAt: `${dateRange.startAt}T00:00:00Z`,
        endAt: `${dateRange.endAt}T23:59:00Z`,
        description: newDescription,
      };

      handleClose();

      if (selectedId) {
        await api.put(`banner/${selectedId}`, body);
      } else {
        await api.post('banner', body);
      }
      await getBanners();
    } catch (error) {
      console.error(error);
      setLoading(false);
      popup('Error!', 'Failed to save banner!');
    }
  };

  useCheckIsAdmin(admin, sessionStatus, history);

  useEffect(() => {
    if (sessionStatus === 'AUTHENTICATED') {
      getBanners();
    }
  }, [showActives, sessionStatus]);

  return (
    <Paper className={classes.container}>
      <div className={classes.header}>
        <h3>Banners</h3>
        <div>
          <FormControlLabel
            control={
              <Switch
                checked={showActives}
                onChange={(event) => setShowActive(event.target.checked)}
                color='primary'
              />
            }
            label='Only Active'
          />
          &nbsp;
          <Button
            variant='contained'
            startIcon={<Add />}
            onClick={() => setOpen(true)}
          >
            Create
          </Button>
        </div>
      </div>
      <PopupTemplate />
      <TableContainer>
        <TableHead>
          <TableRow>
            <HeaderCell>Description</HeaderCell>
            <HeaderCell>Start At</HeaderCell>
            <HeaderCell>End At</HeaderCell>
            <HeaderCell>Priority</HeaderCell>
            <HeaderCell>Created By</HeaderCell>
            <HeaderCell align='center'>Actions</HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {banners.map((banner) => {
            const response = parseDescription(banner.description);
            return (
              <TableRow key={banner.bannerId}>
                <BodyCell
                  title={response.description}
                  style={{ paddingLeft: 5 }}
                >
                  <BannerPreview
                    color={banner.backgroundColor}
                    textColor={banner.color}
                    text={response.description}
                    align={response.align}
                    linkText={banner.link ? banner.link.text : ''}
                    link={banner.link ? banner.link.url : ''}
                  />
                </BodyCell>
                <BodyCell>
                  {format(
                    ZonedDateTime.parse(banner.startAt),
                    'EE, MM/dd/yyyy hh:mm a'
                  )}
                </BodyCell>
                <BodyCell>
                  {format(
                    ZonedDateTime.parse(banner.endAt),
                    'EE, MM/dd/yyyy hh:mm a'
                  )}
                </BodyCell>
                <BodyCell>{banner.priority}</BodyCell>
                <BodyCell>{banner.createdByEmail}</BodyCell>
                <BodyCell align='center' style={{ paddingRight: 5 }}>
                  <div>
                    <IconButton
                      title='Edit'
                      color='default'
                      size='medium'
                      onClick={() => handleEdit(banner)}
                    >
                      <Edit fontSize='inherit' />
                    </IconButton>
                    <IconButton
                      title='Remove'
                      color='error'
                      size='medium'
                      onClick={() => setIdToRemove(banner.bannerId)}
                    >
                      <Delete fontSize='inherit' />
                    </IconButton>
                  </div>
                </BodyCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TableContainer>

      <Dialog fullWidth maxWidth='md' open={open} onClose={handleClose}>
        <DialogTitle>
          {selectedId ? `Edit Banner ${selectedId}` : 'Create New Banner'}
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form role='form' onSubmit={handleSave}>
          <DialogContent className={classes.dialogContent}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <TextField
                  label='Description'
                  fullWidth
                  multiline
                  value={description}
                  onChange={handleChangeDescription}
                  maxRows={3}
                  placeholder='banner description'
                  variant='outlined'
                  required
                />
              </Grid>
              <Grid item xs={2}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel id='select-outlined-label'>Text align</InputLabel>
                  <Select
                    labelId='select-outlined-label'
                    value={align}
                    fullWidth
                    onChange={(event: SelectChangeEvent<string>) =>
                      setAlign(event.target.value)
                    }
                    label='Text align'
                  >
                    <MenuItem value='left'>Left</MenuItem>
                    <MenuItem value='center'>Center</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel id='demo-simple-select-outlined-label'>
                    Link or File
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-outlined-label'
                    id='demo-simple-select-outlined'
                    value={isFile}
                    fullWidth
                    onChange={(event: SelectChangeEvent<number>) =>
                      setIsFile(Number(event.target.value))
                    }
                    label='Link or File'
                  >
                    <MenuItem value={0}>Link</MenuItem>
                    <MenuItem value={1}>File</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {isFile ? (
                <Grid item xs={5}>
                  {file ? (
                    <Grid className={classes.labelContent}>
                      <span>{file.name}</span>
                      <IconButton
                        title='Remove'
                        color='warning'
                        size='small'
                        onClick={() => setFile(null)}
                      >
                        <Delete fontSize='medium' />
                      </IconButton>
                    </Grid>
                  ) : (
                    <Grid display="flex" alignItems="center" columnGap={2}>
                      <InputLabel id='button-label'>Choose a file</InputLabel>
                      <Button
                        variant='outlined'
                        color='primary'
                        aria-label='Upload Attachment'
                        startIcon={<Attachment />}
                        component='label'
                      >
                        Upload
                        <input
                          onChange={handleFileInput}
                          hidden
                          accept='image/*, .pdf'
                          type='file'
                        />
                      </Button>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid item xs={5}>
                  <TextField
                    label='Link'
                    variant='outlined'
                    value={link}
                    required={!!linkText}
                    placeholder='https://your-link.com'
                    onChange={(event) => setLink(event.target.value)}
                  />
                </Grid>
              )}
              <Grid item xs={5}>
                <TextField
                  label={isFile ? 'File link text' : 'Link text'}
                  variant='outlined'
                  value={linkText}
                  placeholder='click here!'
                  required={!!link || !!file}
                  onChange={(event) => setLinkText(event.target.value)}
                />
              </Grid>
              <Grid item xs={2} className={classes.relative}>
                <TextField
                  label='Color'
                  variant='outlined'
                  value={color}
                  required
                  onChange={(event) => setColor(event.target.value)}
                />
                <div
                  className={classes.color}
                  onClick={handleOpenColorPiker('color')}
                >
                  <Colorize />
                </div>
              </Grid>
              <Grid item xs={2} className={classes.relative}>
                <TextField
                  label='Text Color'
                  variant='outlined'
                  value={textColor}
                  required
                  onChange={(event) => setTextColor(event.target.value)}
                />
                <div
                  className={classes.color}
                  onClick={handleOpenColorPiker('textColor')}
                >
                  <Colorize />
                </div>
              </Grid>
              <Grid item xs={2}>
                <TextField
                  label='Priority'
                  variant='outlined'
                  type='number'
                  value={priority}
                  onChange={handleChangePriority}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl variant='outlined' className={classes.date}>
                  <TextField
                    label="Start At"
                    variant='outlined'
                    value={LocalDate.parse(dateRange.startAt)}
                    onChange={(event) => selectDate('startAt')(event.target.value)}
                    type='date'
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl variant='outlined' className={classes.date}>
                  <TextField
                    label="End At"
                    variant='outlined'
                    value={LocalDate.parse(dateRange.endAt)}
                    onChange={(event) => selectDate('endAt')(event.target.value)}
                    type='date'
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div style={{ padding: '10px' }}>
                <HexColorPicker
                  color={colorPiker === 'color' ? color : textColor}
                  onChange={handleChangeColor}
                />
              </div>
            </Popover>
            <BannerPreview
              color={color}
              textColor={textColor}
              text={description}
              linkText={linkText}
              link={isFile ? '' : link}
              headerTitle='preview'
              marginTop={10}
              align={align}
            />
          </DialogContent>
          <DialogActions style={{ padding: '20px' }}>
            <Button
              onClick={handleClose}
              disabled={loading}
              variant='outlined'
              color='primary'
            >
              Cancel
            </Button>
            <Button
              type='submit'
              disabled={loading}
              variant='contained'
              color='primary'
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth='xs'
        open={!!idToRemove}
        onClose={() => setIdToRemove(null)}
      >
        <DialogTitle>
          Remove Banner {idToRemove}
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={() => setIdToRemove(null)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText>
            Do you really want to remove modal {idToRemove}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: '20px' }}>
          <Button
            onClick={() => setIdToRemove(null)}
            disabled={loading}
            variant='outlined'
            color='primary'
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleRemove(idToRemove)}
            disabled={loading}
            variant='contained'
            color='error'
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
      <BackdropLoading loading={loading} />
    </Paper>
  );
};

export default BannersPage;
