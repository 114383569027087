import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  firstContent: {
    paddingBottom: 5,
  },
  scrollX: {
    display: 'flex',
    width: '100%',
    overflowX: 'scroll',

    '&::-webkit-scrollbar': {
      height: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(206, 206, 206)',
      borderRadius: '8px',

      '&:hover': {
        backgroundColor: 'gray',
        borderRadius: '8px',
      },
    },
  },
}));
