import React, { useState } from 'react';

import {
  MenuItem,
  Popover,
  Grid,
  Typography,
  FormControl,
  Select
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

import FilterHeader from '../../../../components/FilterHeader/FilterHeader.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import { useStyles } from './filterFields.styles';

type FilterFieldsProps = {
  setNameDisplay: (value: string) => void;
  nameDisplay: string,
  onlyIcon?: boolean;
  className?: string;
}

const FilterFields = ({ nameDisplay, setNameDisplay, onlyIcon }: FilterFieldsProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value: string) => {
    setNameDisplay(value);
    handleClose();
  };

  const handleReset = () => {
    setNameDisplay('abbr_l');
    handleClose();
  };

  return (
    <Grid>
      <Grid marginRight={0.5}>
        <HeaderButton
          title='Field'
          Icon={VisibilityIcon}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          active={nameDisplay !== 'abbr_l'}
          type='filter'
          onlyIcon={onlyIcon}
          className='sked-test-calendar-display-fields'
        />
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid minWidth={300}>
          <FilterHeader
            title='Display Fields'
            reset={handleReset}
            resetDisabled={nameDisplay === 'abbr_l'}
            saveClassName='sked-test-calendar-display-fields-save-button'
            resetClassName='sked-test-calendar-display-fields-reset-button'
          />
          <Grid padding={2}>
            <FormControl variant="outlined" fullWidth>
              <Typography className={classes.inputLabel}>Name Display</Typography>
              <Select
                size='small'
                value={nameDisplay}
                className={classes.selected}
                onChange={(event) => handleSelect(event.target.value)}
                inputProps={{ className: 'sked-test-calendar-display-fields-selection' }}
              >
                <MenuItem className='sked-test-calendar-display-fields-abbreviate-first-name' value='abbr_f'>Abbreviate First Name</MenuItem>
                <MenuItem className='sked-test-calendar-display-fields-abbreviate-last-name' value='abbr_l'>Abbreviate Last Name</MenuItem>
                <MenuItem className='sked-test-calendar-display-fields-abbreviate-first-last-name' value='abbr_fl'>Abbreviate First and Last Name</MenuItem>
                <MenuItem className='sked-test-calendar-display-fields-show-first-last-name' value='abbr_none'>Show First and Last Name</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
};

export default FilterFields;
