import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Menu,
  MenuItem,
  IconButton,
  TextField,
  CircularProgress,
  Select,
  FormHelperText
} from '@mui/material';
import { withStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import UndoIcon from '@mui/icons-material/Undo';
import * as R from 'ramda';
import { usePromise } from '../../../services/promise.hook';
import { getScheduleLinks, saveScheduleLinks } from '../schedule.actions';

const SmallTextField = withStyles({
  root: {
    width: '64px',

    '& input': {
      paddingRight: 5,
    }
  },
})(TextField);

const GroupItem = ({
  schedules,
  onUpdate,
  aff
}) => {
  const {
    numberBlocked,
    numberScheduled,
    scheduleId
  } = aff;
  const otherSchedule = R.find(R.propEq('id', scheduleId), schedules);

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      marginBottom: 5,
      textDecoration: aff.isDeleted ? 'line-through' : 'auto',
    }}>
      <SmallTextField
        variant="outlined"
        size="small"
        disabled={aff.isDeleted}
        type='number'
        value={numberScheduled}
        onChange={(e) => onUpdate('numberScheduled', Number(e.target.value))} />
      &nbsp;
      appointment(s) in the
      <i style={{
        marginLeft: '5px',
        marginRight: '5px',
      }}>
        {R.propOr('', 'name', otherSchedule)}
      </i>
      schedule block off
      &nbsp;
      <SmallTextField
        variant="outlined"
        size="small"
        disabled={aff.isDeleted}
        type='number'
        value={numberBlocked}
        onChange={(e) => onUpdate('numberBlocked', Number(e.target.value))} />
      &nbsp;
      appointments in this schedule
      &nbsp;
      {aff.isDeleted ?
        <IconButton
          aria-label="Undo"
          size='small'
          color="inherit"
          onClick={() => {
            onUpdate('isDeleted', false);
          }}>
          <UndoIcon />
        </IconButton> :
        <IconButton
          aria-label="Delete"
          size='small'
          color="inherit"
          onClick={() => {
            onUpdate('isDeleted', true);
          }}>
          <DeleteIcon />
        </IconButton>}
    </div>
  );
};

export default function ScheduleGroup({
  id,
  schedules,
  scheduleToEdit,
  needSave = null,
  onSaved,
  anchorEl = null,
  setAnchorEl,
}) {
  const dispatch = useDispatch();
  const [newLinks, setNewLinks] = useState([]);
  const [links, setLinks] = useState([]);

  const listState = usePromise(getScheduleLinks, []);
  const savedState = usePromise(saveScheduleLinks, []);

  const [rounding, setRounding] = useState(scheduleToEdit.roundAvailable);

  useEffect(() => {
    listState.invoke(id).then(setLinks);
  }, []);

  useEffect(() => {
    if (needSave) {
      onSaveHandler();
      setTimeout(() => {
        onSaved();
      }, 200);
    }
  }, [needSave]);

  const onSaveHandler = () => {
    const newSchedule = {
      ...scheduleToEdit,
      roundAvailable: rounding
    };
    return savedState.invoke({ links, newLinks, schedule: newSchedule, dispatch }).then(() => {
      setNewLinks([]);
      return listState.invoke(id).then(setLinks);
    });
  };

  const combinedList = links.concat(newLinks);
  const showableSchedules = schedules.filter(({ id }) =>
    !combinedList.some(link => link.affectedScheduleId === Number(id))
  );

  const onUpdateExisting = idx => (prop, value) => {
    const lense = R.compose(
      R.lensIndex(idx),
      R.lensProp(prop)
    );

    setLinks(R.set(lense, value, links));
  };

  const onUpdateNew = idx => (prop, value) => {
    const lense = R.compose(
      R.lensIndex(idx),
      R.lensProp(prop)
    );

    setNewLinks(R.set(lense, value, newLinks));
  };

  const handleClickNewItem = sched => () => {
    const dAffected = {
      scheduleId: sched.id,
      affectedScheduleId: id,
      numberBlocked: 1,
      numberScheduled: 1,
      isNew: true
    };
    setNewLinks(n => n.concat([dAffected]));
    setAnchorEl(null);
  };

  if (listState.loading || savedState.loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <div style={{ margin: '10px' }}>
        <Menu
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          id="long-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}>
          {showableSchedules.map((sched) => (
            <MenuItem onClick={handleClickNewItem(sched)}>
              {sched.name}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <div style={{ margin: '10px' }}>
        <Select
          id="Schedule-rounding"
          variant='standard'
          value={rounding}
          style={{ minWidth: 64 }}
          onChange={e => setRounding(e.target.value)}
        >
          <MenuItem value='Down'>Some</MenuItem>
          <MenuItem value='Up'>All</MenuItem>
        </Select>
        <FormHelperText>
          Select whether all or some appointments in other schedules will block one slot in this schedule.
        </FormHelperText>
      </div>
      <div style={{ margin: '10px' }}>
        {links.map((link, i) => <GroupItem
          onUpdate={onUpdateExisting(i)}
          schedules={schedules}
          aff={link}
          key={link.affectedScheduleId}
        />)}
        {newLinks.map((link, i) => <GroupItem
          onUpdate={onUpdateNew(i)}
          schedules={schedules}
          aff={link}
          key={link.affectedScheduleId}
        />)}
      </div>
    </div>
  );
}
