import * as at from '../../../../actionTypes';
import * as R from 'ramda';
import {
  now,
} from '../../../../services/joda.js';

const init = {
  state: 'CREATE',
  id: null,
  busy: false,
  filter: 'DateRange',
  selectedClients: [],
  selectedLeads: [],
  selectedApts: [],
  clients: {
    data: [],
    page: 1,
    totalCount: 0,
    totalPages: 1,
  },
  leads: {
    data: [],
    page: 1,
    totalCount: 0,
    totalPages: 1,
  },
  typeStatus: 'Some',
  types: [],
  professionals: [],
  selectedTypes: [],
  selectedPro: {},
  startDate: now('date'),
  endDate: now('date').plusDays(7),
  isEmail: false,
  isSMS: false,
  isPush: false,
  scheduledFor: 'now',
  messageName: '',
  numberOfClients: 0,
  numberOfLeads: 0,
  // [BEGIN] plan == skedOld only
  subject: '',
  body: '',
  // [END]
  email: {
    subject: '',
    body: '',
    html: null,
    attachments: [],
    shouldFrame: true,
  },
  sms: {
    body: '',
    attachments: [],
  },
  push: {
    subject: '',
    body: '',
  },
  tags: [],
  operator: 'And', // #{And Or}
  attachments: [],
  isTemplate: false,
  status: 'LOAD',
  campaign: undefined,
};

export default function reducer(newMessage = init, action) {
  switch (action.type) {
    case at.NEW_MESSAGE_PATCH: {
      return R.merge(newMessage, action.data);
    }
    case at.NEW_MESSAGE_ADD_TYPE: {
      if (action.data.type.name === 'All') {
        return R.evolve({
          selectedTypes: R.pipe(
            R.pathOr([], ['selectedPro', 'types']),
            R.tail,
            R.filter((val) => !R.find(R.propEq('id', val.id))(newMessage.selectedTypes)),
            R.concat
          )(newMessage),
        }, newMessage);
      } else if (R.pipe(
        R.propOr([], 'selectedTypes'),
        R.find(R.propEq('id', action.data.type.id)))(newMessage)) {
        return newMessage;
      }
      return R.evolve({
        selectedTypes: R.concat([action.data.type])
      }, newMessage);
    }
    case at.NEW_MESSAGE_ADD_TYPES: {
      return R.evolve({
        selectedTypes: R.pipe(
          R.filter((val) => !R.find(R.propEq('id', val.id))(newMessage.selectedTypes)),
          R.concat)(action.data.selectedTypes),
        typeStatus: () => action.data.typeStatus,
      }, newMessage);
    }
    case at.NEW_MESSAGE_REMOVE_TYPE: {
      return R.evolve({
        selectedTypes: R.remove(action.data.index, 1),
        typeStatus: () => 'Some',
      }, newMessage);
    }
    case at.NEW_MESSAGE_REMOTE_GET_TYPES:
      if (action.state === 'REQUEST') {
        return R.merge(newMessage, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const {
          types,
          professionals,
        } = action.data;
        return R.merge(newMessage, {
          types,
          professionals,
          state: 'CREATE',
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(newMessage, {
          busy: false,
        });
      }
      return newMessage;
    case at.NEW_MESSAGE_REMOTE_GET_CLIENTS:
      if (action.state === 'REQUEST') {
        return R.merge(newMessage, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(newMessage, {
          clients: action.data,
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(newMessage, {
          busy: action.isCancel,
        });
      }
      return newMessage;
    case at.NEW_MESSAGE_REMOTE_GET_LEADS:
      if (action.state === 'REQUEST') {
        return R.merge(newMessage, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(newMessage, {
          leads: action.data,
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(newMessage, {
          busy: action.isCancel,
        });
      }
      return newMessage;
    case at.NEW_MESSAGE_ADD_CLIENT: {
      if (R.find(R.propEq('id', action.data.client.id))(R.propOr([], 'selectedClients')(newMessage))) {
        return newMessage;
      }
      return R.evolve({
        selectedClients: R.concat([action.data.client]),
      }, newMessage);
    }
    case at.NEW_MESSAGE_ADD_LEAD: {
      if (R.find(R.propEq('id', action.data.lead.id))(R.propOr([], 'selectedLeads')(newMessage))) {
        return newMessage;
      }
      return R.evolve({
        selectedLeads: R.concat([action.data.lead]),
      }, newMessage);
    }
    case at.NEW_MESSAGE_REMOVE_CLIENT: {
      return R.evolve({
        selectedClients: R.remove(action.data.index, 1)
      }, newMessage);
    }
    case at.NEW_MESSAGE_REMOVE_LEAD: {
      return R.evolve({
        selectedLeads: R.remove(action.data.index, 1)
      }, newMessage);
    }
    case at.NEW_MESSAGE_SEND:
      if (action.state === 'REQUEST') {
        return R.merge(newMessage, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(newMessage, {
          state: 'SENT',
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(newMessage, {
          busy: false,
        });
      }
      return newMessage;
    case at.NEW_MESSAGE_RESET:
      return R.merge(newMessage, init);
    case at.NEW_MESSAGE_REMOTE_GET:
      if (action.state === 'REQUEST') {
        return R.merge(newMessage, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(newMessage, R.merge(action.data, { busy: false, status: 'CHECK_ERRORS' }));
      } else if (action.state === 'ERROR') {
        return R.merge(newMessage, {
          busy: false,
        });
      }
      return newMessage;
    default:
      return newMessage;
  }
}
