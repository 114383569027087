import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  Button,
  Tooltip,
  Paper,
  IconButton
} from '@mui/material';
import Check from '@mui/icons-material/Check';
import MoreHoriz from '@mui/icons-material/MoreHoriz';
import Error from '@mui/icons-material/Error';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import * as rawActions from './messages-thread.actions.jsx';
import * as R from 'ramda';
import {
  tzParseFormat,
} from '../../../../services/joda.js';
import
ClientEditDialog
  from '../../../Clients/components/client-dialog/client-dialog.component.jsx';
import { outputLocation, caseType } from '../../../../services/utilities.js';
import { PopupTemplate, popup } from '../../../../services/Popup.js';
import { ThreadEditor } from '../../../../components/Editor/editor.component';
import PerPage from '../../../../components/PerPage/PerPage.component';
import { usePromise } from '../../../../services/promise.hook';
import { MmsItem } from '../../Inbox/InboxChatItem/ChatItem.component';

const Thread = ({ message, tz, phis }) => {
  const toOffice = R.pathOr(false, ['to', 'ToOffice'])(message);
  const attachments = R.pathOr([], ['smsData', 'attachments'], message);
  const mmsAttachments = React.useMemo(() => {
    const msgPhi = R.pathOr([], ['smsData', 'phi'], message);
    return msgPhi.map((mp) => {
      return phis.find((p) => p.phiId === mp.phiId);
    }).filter((p) => p) || [];
  }, [message, phis]);
  return (
    <div key={message.msgId} style={{ borderBottom: 'solid', borderBottomWidth: 'thin', marginRight: '5px', marginTop: '10px' }}>
      <div style={{ paddingBottom: '18px' }}>
        <div style={{ float: 'left', fontSize: 15 }}>
          <b>{toOffice ? message.client.firstName : 'You'}</b>
        </div>
        <div style={{ fontSize: '14px', float: 'right' }}>
          {tzParseFormat(message.created, tz, 'MM/dd/yyyy h:mm a')}
        </div>
      </div>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}>
        <pre
          style={{
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            fontFamily: 'sans-serif',
            backgroundColor: 'white',
            padding: '0px',
            width: '80%',
            fontSize: 14,
          }}>
          {R.pathOr('', ['smsData', 'body'], message)}
        </pre>
        {R.prop('smsStatus', message) &&
          <Tooltip title={R.prop('smsStatus', message)} placement='top' arrow>
            {R.cond([
              [R.equals('Failed'), R.always(<Error style={{ color: 'red' }} />)],
              [R.equals('Pending'), R.always(<MoreHoriz />)],
              [R.equals('Sent'), R.always(<Check style={{ color: 'green' }} />)],
              [R.equals('Delivered'), R.always(<Check style={{ color: 'green' }} />)],
              [R.T, R.always('')],
            ])(R.propOr('', 'smsStatus', message))}
          </Tooltip>}
      </div>
      {!R.isEmpty(attachments) &&
        attachments.map(({ attachmentName, attachmentUrl }) => (
          <>
            <a
              style={{
                color: toOffice ? 'unset' : 'white',
                textDecoration: 'underline',
              }}
              href={attachmentUrl}
              target='_blank'>
              {attachmentName}
            </a>
            <br />
          </>
        ))}
      {mmsAttachments.map((mms) => (
        <MmsItem mms={mms} />
      ))}
    </div>
  );
};

const Wrapper = ({ embedded, children }) => embedded ? <div>{children}</div> : <Paper style={{ margin: '10px 0px', padding: '20px' }}>{children}</Paper>;


const MessageThread = ({
  busy,
  state,
  tz,
  messages,
  page,
  perPage,
  urlId,
  totalCount,
  totalPages,
  history,
  client,
  isSending,
  isOptedOutTwoWay,
  actions,
  embedded,
  read,
  marginBottom = 100,
}) => {
  const [emoji, setEmoji] = React.useState(false);
  const [body, setBody] = React.useState('');
  const getPhiState = usePromise(rawActions.getPhis, []);

  React.useEffect(() => {
    if (read) {
      actions.loadMessage({
        clientId: Number(urlId),
        shouldRead: !embedded,
      });
    }
  }, [urlId, embedded, read]);

  React.useEffect(() => {
    getPhiState.invoke(messages);
  }, [messages]);

  const sendMessage = () => {
    const messageBody = body;
    setBody('');
    if (body.length === 0) {
      popup('Warning!', 'You must fill in the body before sending.');
    } else {
      actions.sendMessage({ text: messageBody }, Number(urlId), perPage).then(() => {
        console.log('done sending message...');
      });
    }
  };
  return (
    <div
      className="messages"
      style={{ marginBottom }}>
      {!embedded && <div>
        <PopupTemplate />
        <h3 style={{ height: '30px' }}>
          <div className="title">Message Thread</div>
          <div className="search">
          </div>
        </h3>
        <NavLink style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }} to="/inbox" onClick={() => actions.messageThreadPatch({ body: '', messages: [] })}>
          <ChevronLeftIcon />
          Back
        </NavLink>
        <ClientEditDialog
          open={state === 'CLIENT_SELECT'}
          onClose={actions.backToMessage}
          back={actions.back}
          from={'MESSAGE'}
          history={history}
          gotoClient={actions.back} />
      </div>}
      {busy && <div className="loader"></div>}
      {!busy &&
        <Wrapper embedded={embedded}>
          <div>
            {!embedded &&
              <h3 style={{ marginLeft: '0px', marginBottom: '-20px' }}>
                Message Thread with
                {client ?
                  <a onClick={() => actions.selectClient(client)}>
                    {' ' + client.firstName + ' ' +
                      client.lastName + ' ' +
                      caseType(client)}
                    <br />
                  </a>
                  :
                  <a onClick={() => actions.selectClient(R.head(messages).client)}>
                    {' ' + R.pathOr(null, ['client', 'firstName'])(R.head(messages)) + ' ' +
                      R.pathOr(null, ['client', 'lastName'])(R.head(messages)) + ' ' +
                      caseType(R.propOr(undefined, 'client', R.head(messages)))}
                    <br />
                  </a>}
              </h3>}
            <div style={{ width: '100%', paddingBottom: '50px' }}>
              <ThreadEditor
                body={body}
                disabled={!R.propOr(false, 'allowSMS')(client) || isOptedOutTwoWay}
                messagesPatch={(b) => setBody(b)}
                patch={(key, value) => setEmoji(value)}
                emoji={emoji}
              />
              <div>
                <Button
                  variant='contained'
                  style={{ width: '100%' }}
                  disabled={isSending || isOptedOutTwoWay || body.trim() === ''}
                  onClick={sendMessage}>
                  Send
                </Button>
              </div>
              <div style={{ overflowY: 'auto' }}>
                {messages.map((message) => (
                  <Thread
                    message={message}
                    tz={tz}
                    phis={getPhiState.data}
                  />))}
              </div>
            </div>
          </div>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '-10px'
          }}>
            <PerPage
              perPage={perPage}
              setPerPage={(value) => {
                actions.loadMessage({
                  clientId: Number(urlId),
                  perPage: value,
                  page,
                });
              }}
            />
            <div
              style={{
                marginLeft: '10px',
                display: 'flex',
                alignItems: 'center',
              }}>
              <div style={{ marginRight: '5px' }}>
                {outputLocation(page, perPage, totalCount, messages.length)}
              </div>
              <IconButton
                disabled={page === 1}
                onClick={() => actions.loadMessage({
                  clientId: Number(urlId),
                  perPage,
                  page: page - 1,
                })}
              >
                <ArrowLeftIcon />
              </IconButton>
              <IconButton
                disabled={page === totalPages}
                onClick={() => actions.loadMessage({
                  clientId: Number(urlId),
                  perPage,
                  page: page + 1,
                })}
              >
                <ArrowRightIcon />
              </IconButton>
            </div>
          </div>
        </Wrapper>}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => R.merge(
  R.propOr({}, 'messageThread')(state),
  {
    isOptedOutTwoWay: R.includes('TwoWayMessages', R.pathOr([], ['messageThread', 'clientOptOut', 'optouts'], state)),
    urlId: ownProps.match.params.id,
    tz: R.pathOr({}, ['login', 'office', 'timezone'])(state),
    unread: undefined,
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(rawActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageThread);
