import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { TextField, Button, Paper, Autocomplete, TableRow, TableHead, TableBody } from '@mui/material';

import { useSelector } from '../../reducers';

import { setSyncerVersion } from '../../routes/Settings/routes/Integration/integration.actions';

import { PageHeader } from '../page-header.component';
import { TableContainer, HeaderCell, BodyCell } from '../CustomTable';

import { useStyles } from './updateSyncer.styles';

type SyncerVersionType = {
  status: string;
  checksum: string;
  url: string;
  name: string;
  ehrSystem: string;
  id: number;
  description: string;
};

const UpdateSyncer = ({ ehrSystem }: { ehrSystem: string }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const syncerVersion: SyncerVersionType = useSelector(
    (state) => state.integration.syncerVersion
  );
  const syncerVersionAll: SyncerVersionType[] = useSelector(
    (state) => state.integration.syncerVersionAll || []
  );
  const syncVersions = React.useMemo(() => {
    return syncerVersionAll.filter((version) => {
      return version.ehrSystem === ehrSystem;
    });
  }, [syncerVersionAll, ehrSystem]);

  const officeId = useSelector((state) => state.login.office?.id);

  const [selected, SetSelected] = useState<SyncerVersionType>();

  const handleSave = () => {
    dispatch(setSyncerVersion({ officeId, currentName: selected.id }));
  };

  useEffect(() => {
    if (syncerVersion) {
      SetSelected(syncerVersion);
    }
  }, [syncerVersion]);

  return (
    <Paper className={classes.container}>
      <PageHeader variant='h6'>Update Syncer</PageHeader>
      <TableContainer noHover>
        <TableHead>
          <TableRow>
            <HeaderCell>Option</HeaderCell>
            <HeaderCell>Value</HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <BodyCell>Syncer Version</BodyCell>
            <BodyCell>
              <Autocomplete
                options={syncVersions}
                getOptionLabel={(option) =>
                  `${option.id} - ${option.description} - ${option.name} - ${option.status}`
                }
                fullWidth
                value={
                  selected && syncVersions
                    ? syncVersions.find((el) => selected?.id === el.id)
                    : null
                }
                onChange={(_, newValue: SyncerVersionType) => {
                  SetSelected(newValue);
                }}
                renderInput={(params: any) => (
                  <TextField {...params} placeholder='Syncer Version' />
                )}
              />
            </BodyCell>
          </TableRow>
        </TableBody>
      </TableContainer>
      <Button variant='contained' onClick={handleSave}>
        Save
      </Button>
    </Paper>
  );
};

export default UpdateSyncer;
