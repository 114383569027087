import * as at from '../../../../actionTypes';
import { remoteAction } from '../../../../services/actionService.js';
import api from '../../../../services/api.js';
import * as R from 'ramda';
import { popup } from '../../../../services/Popup.js';
import { getEHRSettings } from '../../../Login/login.actions';
import axios from 'axios';

const authAxios = async ({ method = 'GET', route, body }) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('sked-session')).access_token,
  };
  const url = `${process.env.API_URL}/${route}`;
  const { data } = await axios({ method, url, data: body, headers });

  return data;
};

export const getChiroInt = (history, admin) => remoteAction({
  type: at.INTEGRATION_REMOTE_GET,
  action: () => {
    const promises = [
      api.get('appointmentType'),
      api.get('professional'),
      api.get('/settings/chirotouch/defaultAppointmentType'),
      api.get('settings/chirotouch'),
      api.get('version'),
    ];
    if (admin) {
      promises.push(authAxios({ route: 'version/all' }));
    }
    return Promise.all(promises)
      .then(([
        types,
        professionals,
        defaults,
        { arriveType, groupBy, registrationMethod, syncColors },
        syncerVersion,
        syncerVersionAll
      ]) => {
        const newList = R.map(
          (professional) => {
            const pickedTypes = R.reverse(R.filter(
              (type) =>
                type.professionalId === professional.id
            )(types));
            const defaultType =
              R.filter(
                (def) =>
                  def.professionalId === professional.id)(defaults);
            return professional.isHidden ? [] : {
              id: professional.id,
              name: professional.displayFirstName + ' ' + professional.displayLastName,
              isHidden: professional.isHidden,
              default: R.head(defaultType),
              types: pickedTypes,
            };
          })(professionals);
        return {
          newList: R.flatten(newList),
          groupBy: groupBy === 'Native' ? 'Linked Accounts' : groupBy,
          arriveType: arriveType === 'CheckedIn' ? 'Checked In' : arriveType,
          registrationMethod,
          syncColors,
          syncerVersion,
          syncerVersionAll: syncerVersionAll || []
        };
      })
      .catch((error) => {
        if (error.response) {
          console.error(error);
          popup('Error!', 'Failed to get data.');
        } else {
          history.push('/offline');
        }
        return null;
      });
  }
});


export const getPlatInt = (history, admin) => remoteAction({
  type: at.INTEGRATION_REMOTE_GET,
  action: () => {
    const promises = [
      api.get('settings/platinum'),
      api.get('professional'),
      api.get('professional/mapped'),
      api.get('version'),
      admin ? authAxios({ route: 'version/all' }) : null,
    ];
    return Promise.all(promises).then(([
      settings,
      pros,
      mappedPros,
      syncerVersion,
      syncerVersionAll
    ]) => {
      return R.merge(settings, {
        pros,
        mappedPros,
        syncerVersion,
        syncerVersionAll,
      });
    }).catch((error) => {
      if (error.response) {
        console.error(error);
        popup('Error!', 'Failed to get data.');
      } else {
        history.push('/offline');
      }
      return null;
    });
  },
});

export const getGenInt = (history, system = 'genesis') => remoteAction({
  type: at.INTEGRATION_REMOTE_GET,
  action: () => api.get('professional').then((professionals) => {
    if (R.isEmpty(professionals) && system === 'genesis') {
      return axios.get(
        `${process.env.API_URL}/settings/genesis/practices`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('sked-session')).access_token,
            'X-As-Office': undefined,
          }
        },
      ).then(({ data }) => {
        return {
          offices: R.sort(R.ascend(R.prop('name')))(data),
          status: 'OFFICES'
        };
      });
    } else {
      return Promise.all([
        api.get('appointmentType'),
        api.get(`/settings/${system}/defaultAppointmentType`),
        api.get(`/settings/${system}/default/professional`),
      ]).then(([types, defaults, defPros]) => {
        const newList = R.pipe(
          R.map(
            (professional) => {
              const pickedTypes = R.pipe(
                R.filter(
                  (type) =>
                    type.professionalId === professional.id
                ),
                R.reverse,
                R.sort(R.ascend(R.prop('internalName'))))(types);
              const defaultType =
                R.filter(
                  (def) =>
                    def.professionalId === professional.id)(defaults);
              return professional.isHidden ? [] : {
                id: professional.id,
                name: professional.displayFirstName + ' ' + professional.displayLastName,
                isHidden: professional.isHidden,
                default: R.head(defaultType),
                types: pickedTypes,
              };
            }),
          R.sort(R.ascend(R.prop('name'))))(professionals);
        return {
          status: 'REGISTERED',
          newList: R.flatten(newList),
          defaultPro: defPros.defaultProfessional,
          pros: R.pipe(
            R.map((pro) => R.merge(pro, { name: pro.displayFirstName + ' ' + pro.displayLastName })),
            R.sort(R.ascend(R.prop('name')))
          )(professionals),
        };
      });
    }
  }).catch((error) => {
    if (error.response) {
      console.error(error.response);
      if (error.response.status === 403)
        popup('Error!', 'SKED support needs to register you! Contact them at info@sked.life');
      else
        popup('Error!', 'Failed to get data.');
    } else {
      history.push('/offline');
    }
    return null;
  }),
});

export const integrationPatch = ({ index, type }) => (dispatch) =>
  dispatch({
    type: at.INTEGRATION_PATCH,
    data: {
      index,
      type,
    }
  });

export const integrationMiscPatch = (prop, value) => ({
  type: at.INTEGRATION_MISC_PATCH,
  data: {
    [prop]: value,
  }
});

export const setSyncerVersion = ({ officeId, currentName }) => remoteAction({
  type: at.INTEGRATION_REMOTE_POST,
  action: async () => {
    try {
      await authAxios({ method: 'POST', route: `version/${officeId}/set`, body: { currentName } });
    } catch (error) {
      popup('Error!', 'Failed to set Syncer Version.');
      console.error(error);
    }
  }
});

export const save = (ftypes, system, pro = null, key = null) => {
  const types = R.filter((t) => !R.isEmpty(t.types))(ftypes);
  const reqs = types
    .map((type) => {
      return api.put(
        `/settings/${system}/defaultAppointmentType`,
        { appointmentTypeId: R.pathOr(null, ['default', 'id'])(type) });
    })
    .concat([key && api.post('settings/genesis/fileUpload', { key })])
    .filter(v => v);
  return remoteAction({
    type: at.INTEGRATION_REMOTE_PUT,
    action: () => Promise.all(reqs).then(() => {
      if ((system === 'genesis' || system === 'chirohd') && pro)
        api.put(
          `/settings/${system}/default/professional`, { professionalId: pro.id }
        );
    }).catch((error) => {
      popup('Error!', 'Failed to update types.');
      console.error(error);
      return null;
    }),
  });
};

export const saveMisc = (data, system) => {
  const action = () => api.put(`/settings/${system.toLowerCase()}`, data).catch((error) => {
    popup('Error!', 'Failed to update misc data.');
    console.log(error);
    throw error;
  });
  return remoteAction({
    type: at.INTEGRATION_REMOTE_PUT,
    action
  });
};

export const saveEHRSettings = (data, system) => (dispatch) => {
  const action = () => api.put(`/settings/${system.toLowerCase()}`, data).then(() => {
    dispatch(getEHRSettings(system));
  }).catch((error) => {
    popup('Error!', 'Failed to update misc data.');
    throw error;
  });
  return dispatch(remoteAction({
    type: at.INTEGRATION_REMOTE_PUT,
    action
  }));
};


export const addPro = ({ firstName, lastName, url }) => remoteAction({
  type: at.INTEGRATION_REMOTE_POST,
  action: () =>
    api.post('professional', { firstName, lastName, slotWidth: 5 }).then(({ id }) =>
      api.put(`/settings/genesis/professional/${id}`, { url })
    ).catch((error) => {
      popup('Error!', 'Failed to add professional.');
      console.log(error);
      return null;
    }),
});

export const mappedPatch = (mapped) => ({
  type: at.INTEGRATION_MAPPED_PATCH,
  data: {
    mapped
  }
});

export const savePros = (mappedPros) => {
  const data = R.map((pro) => {
    return {
      fromPro: pro.fromPro.id,
      toPro: pro.toPro.id,
      mappingFlow: pro.mappingFlow ? pro.mappingFlow : 'ClientsOnly'
    };
  })(mappedPros);
  return remoteAction({
    type: at.INTEGRATION_REMOTE_POST,
    action: () =>
      api.post('professional/mapped', data)
        .catch((error) => {
          popup('Error!', 'Failed to update misc data.');
          console.log(error);
          return null;
        }),
  });
};

export const updatePro = ({ firstName, lastName, id, url, slotWidth }) => remoteAction({
  type: at.INTEGRATION_REMOTE_PUT_GEN,
  action: () =>
    api.put(`professional/${id}`, { firstName, lastName, slotWidth }).then((pro) => {
      if (url !== '')
        api.put(`/settings/genesis/professional/${id}`, { url });
      return pro;
    }).catch((error) => {
      popup('Error!', 'Failed to add professional.');
      console.log(error);
      return null;
    }),
});

export const updateToPlatinumAPI = ({ officeId, clinicId }) => {
  return remoteAction({
    type: at.INTEGRATION_REMOTE_POST,
    action: () =>
      axios.put(
        `${process.env.API_URL}/settings/platinum/clinicId/${officeId}`,
        { clinicId },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('sked-session')).access_token,
            'X-As-Office': undefined,
          }
        }
      )
        .catch((error) => {
          popup('Error!', 'Failed to trigger platinum API update.');
          console.log(error);
          return null;
        }),
  });
};

export const resync = () => remoteAction({
  type: at.INTEGRATION_REMOTE_PUT,
  action: () =>
    api.post('/settings/genesis/initial-sync', {}).catch((error) => {
      popup('Error!', 'Failed to Re-Sync.');
      console.log(error);
      return null;
    }),
});

export const selectOffice = (data) => remoteAction({
  type: at.INTEGRATION_REMOTE_POST_SELECT_OFFICE,
  action: () =>
    axios.post(
      `${process.env.API_URL}/settings/genesis/practice`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('sked-session')).access_token,
          'X-As-Office': undefined,
        }
      }
    ).then(() => {
      return Promise.all([
        api.get('appointmentType'),
        api.get('professional'),
        api.get('/settings/genesis/defaultAppointmentType'),
        api.get('/settings/genesis/default/professional')
      ]).then(([types, professionals, defaults, defPros]) => {
        const newList = R.pipe(
          R.map(
            (professional) => {
              const pickedTypes = R.pipe(
                R.filter(
                  (type) =>
                    type.professionalId === professional.id
                ),
                R.reverse,
                R.sort(R.ascend(R.prop('internalName'))))(types);
              const defaultType =
                R.filter(
                  (def) =>
                    def.professionalId === professional.id)(defaults);
              return professional.isHidden ? [] : {
                id: professional.id,
                name: professional.displayFirstName + ' ' + professional.displayLastName,
                isHidden: professional.isHidden,
                default: R.head(defaultType),
                types: pickedTypes,
              };
            }),
          R.sort(R.ascend(R.prop('name'))))(professionals);
        return {
          status: 'REGISTERED',
          newList: R.flatten(newList),
          defaultPro: defPros.defaultProfessional,
          pros: R.pipe(
            R.map((pro) => R.merge(pro, { name: pro.displayFirstName + ' ' + pro.displayLastName })),
            R.sort(R.ascend(R.prop('name')))
          )(professionals)
        };
      });
    }),
});

export const getNoneInt = () => remoteAction({
  type: at.INTEGRATION_REMOTE_GET,
  action: () => api.get('/settings/none'),
});

export const getData = ({
  ehrSystem,
  history,
  dispatch,
  admin,
}) => R.cond([
  [R.equals('PlatinumApi'), () => dispatch(getPlatInt(history, admin))],
  [R.equals('Platinum'), () => dispatch(getPlatInt(history, admin))],
  [R.equals('ChiroTouch'), () => dispatch(getChiroInt(history, admin))],
  [R.equals('Genesis'), () => dispatch(getGenInt(history))],
  [R.equals('None'), () => dispatch(getNoneInt(history))],
  [R.equals('ChiroHD'), () => dispatch(getGenInt(history, 'chirohd'))],
  [R.T, () => R.always(null)]
])(ehrSystem);
