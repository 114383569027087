import {
  Answer, IntakeAnswer, AnswerSimple, TableRowMap, TableRow, TableValue,
  AnswerTableRow, RadioMap, AnswerDocumentUpload,
} from './answer-types';
import { has, isNil, path, isEmpty, all } from 'ramda';
import api, { skedApi } from '../../services/api.js';
import SignaturePad from 'signature_pad';
import axios from 'axios';

const errorCatch = (): any => {
  return null;
};

const dataURLtoBlob = (dataurl: string): Blob => {
  const arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    n = n - 1;
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const convertToServerAnswerList = (answers: IntakeAnswer[], subId: number): Promise<Answer[]> => {
  return Promise.all(
    answers
      .filter(({ value }) => !isNil(value))
      .map(convertToServerAnswer(subId))
  ).then((maybeNullAnswers) => maybeNullAnswers.filter((a) => a));
};

const convertToServerAnswer = (subId: number) => (answer: IntakeAnswer): Promise<Answer> => {
  if (answer.type === 'ShortText') {
    return Promise.resolve({
      questionId: answer.id,
      subId,
      question: null,
      answer: {
        SimpleQuestion: {
          ShortText: answer.value
        }
      }
    });
  }
  if (answer.type === 'LongText') {
    return Promise.resolve({
      questionId: answer.id,
      subId,
      question: null,
      answer: {
        SimpleQuestion: {
          LongText: answer.value
        }
      }
    });
  }
  if (answer.type === 'Number') {
    return Promise.resolve({
      questionId: answer.id,
      subId,
      question: null,
      answer: {
        SimpleQuestion: {
          NumberQuestion: answer.value
        }
      }
    });
  }
  if (answer.type === 'Date') {
    return Promise.resolve({
      questionId: answer.id,
      subId,
      question: null,
      answer: {
        SimpleQuestion: {
          DateQuestion: answer.value
        }
      }
    });
  }
  if (answer.type === 'Email') {
    return Promise.resolve({
      questionId: answer.id,
      subId,
      question: null,
      answer: {
        SimpleQuestion: {
          EmailQuestion: answer.value
        }
      }
    });
  }
  if (answer.type === 'Checkbox') {
    return Promise.resolve({
      questionId: answer.id,
      subId,
      question: null,
      answer: {
        SimpleQuestion: {
          CheckboxOptions: {
            checked: answer.value,
          }
        }
      }
    });
  }
  if (answer.type === 'CheckboxSingle') {
    return Promise.resolve({
      questionId: answer.id,
      subId,
      question: null,
      answer: {
        SimpleQuestion: {
          SingleCheckbox: answer.value,
        }
      }
    });
  }
  if (answer.type === 'Radio') {
    return Promise.resolve({
      questionId: answer.id,
      subId,
      question: null,
      answer: {
        SimpleQuestion: {
          RadioOptions: answer.value,
        }
      }
    });
  }
  if (answer.type === 'Dropdown') {
    return Promise.resolve({
      questionId: answer.id,
      subId,
      question: null,
      answer: {
        SimpleQuestion: {
          DropdownOptions: answer.value,
        }
      }
    });
  }
  if (answer.type === 'RadioGrid') {
    return Promise.resolve({
      questionId: answer.id,
      subId,
      question: null,
      answer: {
        RadioGrid: {
          answers: Object.values(answer.value),
        }
      }
    });
  }
  if (answer.type === 'FixedTable') {
    return Promise.resolve({
      questionId: answer.id,
      subId,
      question: null,
      answer: {
        FixedTable: {
          answers: Object.values(answer.value).map((row: TableRow) => {
            return ({
              columns: Object.values(row.value).map((v: TableValue) => {
                if (typeof v === 'string') {
                  return ({
                    ShortText: v,
                  });
                }
                if (typeof v === 'number') {
                  return ({
                    NumberQuestion: v,
                  });
                }
                if (typeof v === 'boolean') {
                  return ({
                    SingleCheckbox: v,
                  });
                }
                if (typeof v === 'object') {
                  return ({
                    CheckboxOptions: {
                      checked: v,
                    },
                  });
                }

                // TODO: This is probably wrong but for now just to make things happy
                if (isNil(v)) {
                  return ({
                    ShortText: '',
                  });
                }
              }),
            });
          }),
        }
      }
    });
  }
  if (answer.type === 'DocumentUpload') {
    if (all(has('url'), answer.value)) {
      return Promise.resolve({
        questionId: answer.id,
        subId,
        question: null,
        answer: {
          DocumentUpload: {
            documents: answer.value.map(({ url, phiId })=> ({ url, phiId })),
          }
        }
      });
    }
    return Promise.all(answer.value.map((v) => {
      if (isNil(v)) {
        return null;
      }
      if (v.phiId) {
        return Promise.resolve({ data: [{ phiId: v.phiId, fileName: v.name }] });
      }
      const file = typeof v === 'string' ? new File([v], v) : v;
      const formData = new FormData();
      formData.append('file', file);
      return axios.post(
        `${process.env.API_URL}/form/submission/upload-phi/${subId}`,
        formData,
        {
          headers: {
            Authorization: skedApi.defaults.headers.common.Authorization,
          },
        }
      );
    })).then((rawDatas) => {
      return isEmpty(rawDatas) ? null : {
        questionId: answer.id,
        subId,
        question: null,
        answer: {
          DocumentUpload: {
            documents: rawDatas.map(({ data }) => data[0]),
          },
        }
      };
    }).catch(errorCatch);
  }
  if (answer.type === 'Draw') {
    if (typeof answer.value === 'string') {
      return Promise.resolve(null);
    }
    const {
      data,
      height,
      width,
      phiId,
    } = answer.value;
    if (phiId) {
      return Promise.resolve(null);
    }
    const newCanvas = document.createElement('canvas');
    newCanvas.setAttribute('width', String(width));
    newCanvas.setAttribute('height', String(height));
    const sigPad = new SignaturePad(newCanvas, {
      maxWidth: width,
    });
    sigPad.fromData(data);
    const dataUrl = sigPad.toDataURL('image/png');
    const blob = dataURLtoBlob(dataUrl);
    const formData = new FormData();
    formData.append('file', blob);
    return axios.post(
      `${process.env.API_URL}/form/submission/upload-phi/${subId}`,
      formData,
      {
        headers: {
          Authorization: skedApi.defaults.headers.common.Authorization,
        },
      }
    ).then(({ data }) => {
      return ({
        questionId: answer.id,
        subId,
        question: null,
        answer: {
          DocumentUpload: {
            documents: [data[0]],
          },
        }
      });
    }).catch(errorCatch);
  }
  if (answer.type === 'Signature') {
    const now = new Date();
    const stringMonth = String(now.getMonth() + 1);
    const stringDate = String(now.getDate());
    const month = stringMonth.length === 1 ? '0' + stringMonth : stringMonth;
    const d = stringDate.length === 1 ? '0' + stringDate : stringDate;
    const date = `${now.getFullYear()}-${month}-${d}`;
    if (typeof answer.value === 'string') {
      return Promise.resolve({
        questionId: answer.id,
        subId,
        question: null,
        answer: {
          Signature: {
            date,
            signature: {
              SignName: answer.value,
            },
            metadata: answer.metadata,
          }
        }
      });
    }
    const {
      data,
      height,
      width,
    } = answer.value;
    if (path(['value', 'url'], answer)) {
      return Promise.resolve({
        questionId: answer.id,
        subId,
        question: null,
        answer: {
          Signature: {
            date,
            signature: {
              Signature: {
                url: path(['value', 'url'], answer),
                phiId: path(['value', 'phiId'], answer),
                fileName: path(['value', 'fileName'], answer),
              },
            },
            metadata: answer.metadata,
          }
        }
      });
    }
    if (isNil(data)) {
      return Promise.resolve(null);
    }
    const newCanvas = document.createElement('canvas');
    newCanvas.setAttribute('width', String(width));
    newCanvas.setAttribute('height', String(height));
    const sigPad = new SignaturePad(newCanvas, {
      maxWidth: width,
    });
    console.log(answer.value);
    sigPad.fromData(data);
    const dataUrl = sigPad.toDataURL('image/png');
    const blob = dataURLtoBlob(dataUrl);
    const formData = new FormData();
    formData.append('file', blob);
    return axios.post(
      `${process.env.API_URL}/form/submission/upload-phi/${subId}`,
      formData,
      {
        headers: {
          Authorization: skedApi.defaults.headers.common.Authorization,
        },
      }
    ).then(({ data }) => {
      return ({
        questionId: answer.id,
        subId,
        answer: {
          Signature: {
            date,
            signature: {
              Signature: data[0],
            },
            metadata: answer.metadata,
          }
        }
      });
    }).catch(errorCatch);
  }
  if (answer.type === 'DocumentAgreement') {
    const now = new Date();
    const date = `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`;
    if (typeof answer.value === 'string') {
      return Promise.resolve({
        questionId: answer.id,
        subId,
        question: null,
        answer: {
          Signature: {
            date,
            signature: {
              SignName: answer.value,
            },
            metadata: answer.metadata,
          }
        }
      });
    }
    const {
      data,
      height,
      width,
    } = answer.value;
    const newCanvas = document.createElement('canvas');
    newCanvas.setAttribute('width', String(width));
    newCanvas.setAttribute('height', String(height));
    const sigPad = new SignaturePad(newCanvas, {
      maxWidth: width,
    });
    sigPad.fromData(data);
    const dataUrl = sigPad.toDataURL('image/png');
    const blob = dataURLtoBlob(dataUrl);
    const formData = new FormData();
    formData.append('file', blob);
    return axios.post(
      `${process.env.API_URL}/form/submission/upload-phi/${subId}`,
      formData,
      {
        headers: {
          Authorization: skedApi.defaults.headers.common.Authorization,
        },
      }
    ).then(({ data }) => {
      return ({
        questionId: answer.id,
        subId,
        answer: {
          Signature: {
            date,
            signature: {
              Signature: data[0],
            },
            metadata: answer.metadata,
          }
        }
      });
    }).catch(errorCatch);
  }
};

const convertServerToFrontEnd = (a: Answer): IntakeAnswer => {
  const id = a.questionId;
  const v = a.answer;
  const officeNote = a.officeNote;
  if (v.SimpleQuestion) {
    if (v.SimpleQuestion.ShortText !== undefined) {
      return {
        id,
        type: 'ShortText',
        value: v.SimpleQuestion.ShortText,
        officeNote,
      };
    }
    if (v.SimpleQuestion.LongText !== undefined) {
      return {
        id,
        type: 'LongText',
        value: v.SimpleQuestion.LongText,
        officeNote,
      };
    }
    if (v.SimpleQuestion.DateQuestion !== undefined) {
      return {
        id,
        type: 'Date',
        value: v.SimpleQuestion.DateQuestion,
        officeNote,
      };
    }
    if (v.SimpleQuestion.EmailQuestion !== undefined) {
      return {
        id,
        type: 'Email',
        value: v.SimpleQuestion.EmailQuestion,
        officeNote,
      };
    }
    if (v.SimpleQuestion.DropdownOptions !== undefined) {
      return {
        id,
        type: 'Dropdown',
        value: v.SimpleQuestion.DropdownOptions,
        officeNote,
      };
    }
    if (v.SimpleQuestion.RadioOptions !== undefined) {
      return {
        id,
        type: 'Radio',
        value: v.SimpleQuestion.RadioOptions,
        officeNote,
      };
    }
    if (v.SimpleQuestion.NumberQuestion !== undefined) {
      return {
        id,
        type: 'Number',
        value: v.SimpleQuestion.NumberQuestion,
        officeNote,
      };
    }
    if (v.SimpleQuestion.CheckboxOptions !== undefined) {
      return {
        id,
        type: 'Checkbox',
        value: v.SimpleQuestion.CheckboxOptions.checked,
        officeNote,
      };
    }
    if (v.SimpleQuestion.SingleCheckbox !== undefined) {
      return {
        id,
        type: 'CheckboxSingle',
        value: v.SimpleQuestion.SingleCheckbox,
        officeNote,
      };
    }
  }
  if (v.FixedTable) {
    return {
      id,
      type: 'FixedTable',
      officeNote,
      value: v.FixedTable.answers.reduce((acc, fa, i) => ({
        ...acc,
        [i]: {
          id: i,
          value: parseColumns(fa)
        }
      }), {})
    };
  }
  if (v.FlexibleTable) {
    return {
      id,
      type: 'FixedTable',
      officeNote,
      value: v.FlexibleTable.answers.reduce((acc, fa, i) => ({
        ...acc,
        [i]: {
          id: i,
          value: parseColumns(fa)
        }
      }), {})
    };
  }
  if (v.RadioGrid) {
    const value: RadioMap = {};
    v.RadioGrid.answers.forEach((e, i) => {
      value[`${i}`] = e;
    });
    return {
      id,
      type: 'RadioGrid',
      officeNote,
      value,
    };
  }
  if (v.Signature) {
    if (v.Signature.signature.SignName) {
      return ({
        id,
        type: 'Signature',
        value: v.Signature.signature.SignName,
        metadata: v.Signature.metadata,
      });
    }
    return ({
      id,
      type: 'Signature',
      value: {
        data: [],
        ...v.Signature.signature.Signature,
      },
      metadata: v.Signature.metadata,
    });
  }
  if (v.DocumentUpload) {
    if (a.question?.Picture) {
      return {
        id,
        type: 'Draw',
        officeNote,
        value: v.DocumentUpload.documents.map((d) => {
          return ({
            ...d,
            data: [] as any[],
          });
        })[0],
        // metadata: v.DocumentUpload.metadata,
      };
    }
    return {
      id,
      type: 'DocumentUpload',
      officeNote,
      value: v.DocumentUpload.documents.map((u: AnswerDocumentUpload) => ({
        ...u,
        lastModified: undefined,
        name: u.fileName,
        size: undefined,
        type: undefined,
        arrayBuffer: undefined,
        slice: undefined,
        stream: undefined,
        text: undefined,
      })),
      // metadata: v.DocumentUpload.metadata,
    };
  }

  //default
  return {
    id,
    type: 'ShortText',
    value: '',
    officeNote,
  };
};

const parseColumns = ({ columns }: AnswerTableRow): TableRowMap => {
  return columns.reduce((acc: TableRowMap, ta: AnswerSimple, j: number) => {
    return {
      ...acc,
      [j]: parseTableAnswer(ta)
    };
  }, {});
};

const parseTableAnswer = (ta: AnswerSimple): TableValue => {
  if (ta.ShortText) {
    return ta.ShortText;
  }
  if (ta.NumberQuestion) {
    return ta.NumberQuestion;
  }
  if (has('SingleCheckbox', ta)) {
    return ta.SingleCheckbox;
  }
  if (has('CheckboxOptions', ta)) {
    return ta.CheckboxOptions.checked;
  }
  return '';
};


export const convertToIntakeAnswers = (answers: Answer[]): IntakeAnswer[] => {
  return answers.map(convertServerToFrontEnd);
};

export interface SaveAnswersProps {
  answers: Answer[];
  subId: number;
}

export const saveAnswers = ({ answers, subId }: SaveAnswersProps): Promise<void> => {
  const newAnswers = answers.map((a) => ({
    ...a,
    subId,
  }));
  return api.put(`form/submission/answers/${subId}`, newAnswers).then(() => {
    return;
  }).catch(errorCatch);
};
