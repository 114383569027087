import React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import {
  cond, equals, T, pathOr, includes, merge, pipe, assoc,
} from 'ramda';
import * as actions from './integration.actions.jsx';
import {
  MenuItem, TextField, TableHead, TableBody, TableRow,
} from '@mui/material';
import { PlatIntegrationTemplate } from './platinum.jsx';
import { ChiroIntegrationTemplate } from './chirotouch.jsx';
import { GenIntegrationTemplate } from './genesis.jsx';
import { NoneIntegrationTemplate } from './none.jsx';
import { ChiroHDIntegrationPage } from './chirohd-component.jsx';
import { useSelector } from '../../../../reducers';
import { TableContainer, HeaderCell, BodyCell } from '../../../../components/CustomTable';

import '../Business/business.css';

const anotherTypeItem = (type, edit) => (
  <MenuItem
    key={type.id}
    onClick={() => edit({ type })}
    value={type.id}>
    {type.name}
  </MenuItem>
);

const typeItem = (type, edit) => (
  <TableRow key={type.id}>
    <BodyCell>
      {type.name}
    </BodyCell>
    <BodyCell>
      <TextField
        select
        value={pathOr(null, ['default', 'id'])(type)}>
        {type.types.map(
          (type) =>
            anotherTypeItem(type,
              edit))}
      </TextField>
    </BodyCell>
  </TableRow>
);

const table = (types, edit) => (
  <TableContainer noHover>
    <TableHead>
      <TableRow>
        <HeaderCell>
          Professional Name
        </HeaderCell>
        <HeaderCell>
          Default Appointment Type
        </HeaderCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {types.map((type, index) => typeItem(type, pipe(assoc('index', index), edit)))}
    </TableBody>
  </TableContainer>
);

const Integration = () => {
  const dispatch = useDispatch();
  const rawProps = useSelector((state) => ({
    ...state.integration,
    office: pathOr(null, ['login', 'office'])(state),
    admin: state.login.admin,
    hasCTArrivalOptions: includes('CTArrivalOptions', pathOr(null, ['login', 'features'], state)),
    features: pathOr([], ['login', 'features'])(state),
  }));
  const actionProps = merge(
    bindActionCreators(actions, dispatch),
    {
      getData: ({ history, ehrSystem, admin }) => actions.getData({ dispatch, history, ehrSystem, admin }),
      saveEHRSettings: (d, s) => dispatch(actions.saveEHRSettings(d, s))
    }
  );
  const props = merge(rawProps, actionProps);

  React.useEffect(() => {
    props.getData({
      history: props.history,
      ehrSystem: props.office.ehrSystem,
      admin: props.admin,
    });
  }, [props.office]);

  React.useEffect(() => {
    if (props.state === 'UPDATED') {
      props.getData({
        history: props.history,
        ehrSystem: props.office.ehrSystem,
        admin: props.admin,
      });
    }
  }, [props.state]);

  const { ehrSystem } = props.office;

  return (
    <div style={{ overflowY: 'auto' }}>
      {cond([
        [equals('Platinum'), () => PlatIntegrationTemplate(props)],
        [equals('ChiroTouch'), () => ChiroIntegrationTemplate(props, table)],
        [equals('Genesis'), () => GenIntegrationTemplate(props, table)],
        [equals('None'), () => NoneIntegrationTemplate(props)],
        [equals('ChiroHD'), () =>
          <ChiroHDIntegrationPage
            table={table}
            {...props}
          />],
        [T, () => PlatIntegrationTemplate(props)]
      ])(ehrSystem)}
    </div>
  );
};

export default Integration;
