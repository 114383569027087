import React from 'react';
import { Switch, Route } from 'react-router';
import { OverviewPage } from './Routes/Overview/overview.page.jsx';

const TutorialsRoutes = ({ match }) => {
  return (
    <Switch>
      <Route path={match.url} component={OverviewPage} />
    </Switch>
  );
};

export default TutorialsRoutes;
