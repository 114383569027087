import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    overflowY: 'scroll',
  },
  chip: {
    marginTop: 8,
    marginRight: 8,
    fontSize: 12,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& button': {
      marginRight: 5,
    },
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    maxHeight: '240px',
  },
}));