import { makeStyles } from '@mui/styles';

export const style = {
  base: {
    color: '#282E38',
    fontFamily: 'Poppins, "Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#afafaf'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
};

export const useStyles = makeStyles(() => ({
  root: {
    padding: 40,
    paddingTop: 30,
    overflowY: 'auto',
    background: '#FFF',
    boxSizing: 'border-box',
  },
  content: {
    width: '100%',
    maxWidth: 1000,
  },
  info: {
    fontSize: 16,
    color: '#3b3a3a',
    marginBottom: 5,

    '& span': {
      fontWeight: 600,
    }
  },
  sparkText: {
    color: '#FA531B',
    fontWeight: 700,
    fontSize: 46,
    lineHeight: '50px',
    marginTop: 8,
    marginBottom: 10,
  },
  title: {
    fontWeight: 600,
    color: '#3b3a3a',
    fontSize: 17,
    marginTop: 15,
    marginBottom: 15,
  },
  icon: {
    width: 14,
    height: 14,

    '& path': {
      fill: '#FFF'
    }
  },
  image: {
    maxWidth: '100%',
    maxHeight: '560px',
    objectFit: 'contain',
  },
  button: {
    color: '#FFF',
    background: '#FA531B',
    borderRadius: 4,
    marginTop: 15,
    marginBottom: 15,

    '&:hover': {
      background: '#FA531B',
    },

    '& svg': {
      marginRight: 7,
    }
  },
  list: {
    paddingLeft: 25,
  },
  dateContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 80,
  },
  date: {
    fontSize: 46,
    lineHeight: '48px',
    fontWeight: 700,
    color: '#3b3a3a',
  },
}));
