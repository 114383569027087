import React, { ComponentProps } from 'react';

const CalendarViewIcon = ({ color, className, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg className={className} {...props} xmlns="http://www.w3.org/2000/svg" width="14.547" height="13.555" viewBox="0 0 14.547 13.555">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_11880" data-name="Rectangle 11880" width="14.547" height="13.555" fill={color || '#4d4d4d'}/>
        </clipPath>
      </defs>
      <g id="Group_15486" data-name="Group 15486" transform="translate(0 0)" clipPath="url(#clip-path)">
        <path id="Path_8827" data-name="Path 8827" d="M10.01.029a.834.834,0,0,0-.027.147c0,.47,0,.939,0,1.408a.617.617,0,1,0,1.231.005c.009-.45,0-.9,0-1.35v-.2a2.751,2.751,0,0,1,3.316,3.026H.027A2.685,2.685,0,0,1,1.744.172,3.987,3.987,0,0,1,3.323.02l0-.005c0,.525,0,1.051.009,1.577a.63.63,0,0,0,.629.66.624.624,0,0,0,.6-.66c0-.526,0-1.051,0-1.577L4.552.021c.07,0,.141-.009.211-.009q2.5,0,5,0c.082,0,.165.014.248.021Z" transform="translate(0 0)" fill={color || '#4d4d4d'}/>
        <path id="Path_8828" data-name="Path 8828" d="M14.538,17.592H.012v1.123h0q0,2.759,0,5.518a2.4,2.4,0,0,0,.1.625,2.667,2.667,0,0,0,2.644,1.956c1.508.005,3.015,0,4.523,0s3.015,0,4.523,0a2.667,2.667,0,0,0,2.644-1.956,2.4,2.4,0,0,0,.1-.625q.009-2.759,0-5.518h0V17.592Zm-1.366,4.021H9.953V19.188h3.218Z" transform="translate(-0.009 -13.263)" fill={color || '#4d4d4d'}/>
      </g>
    </svg>
  );
};

export default CalendarViewIcon;
