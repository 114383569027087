import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    width: '241px',
    color: 'black',
    marginTop: '10px',
    marginBottom: '10px',
    cursor: 'pointer',
    border: '1px solid rgb(191, 191, 191)',
    borderRadius: '50px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  input: {
    width: '170px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  name: {
    padding: '5px 10px 5px 0px',
    width: '170px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  chip: {
    width: '20px',
    height: '20px',
    borderRadius: '100px',
    cursor: 'pointer',
    margin: '5px 5px 5px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.26)',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.4)'
    },
  },
}));
