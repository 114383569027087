import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  selected: {
    '& td': {
      backgroundColor: 'rgb(237 245 251)',
    },
    '&:hover': {
      '& td': {
        backgroundColor: 'rgb(0 139 207 / 15%) !important',
      },
    }
  },
  hasSelect: {
    '& th:first-child': {
      padding: '5px 0px 5px 8px !important',
      borderRight: 'none',
    },
    '& td:first-child': {
      padding: '6px 0px 6px 8px',
    },
    '& th:nth-child(2)': {
      borderRight: 'none',
    },
  },
  fixed: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    padding: '0 !important',
  },
  border: {
    padding: '6px 16px 6px 11px',
    maxWidth: '400px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '200px',
    },
  },
}));
