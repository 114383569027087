import React from 'react';
import { useSelector } from 'react-redux';
import {
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  FormControlLabel
} from '@mui/material';

import { LocalDate, LocalTime, DateTimeFormatter } from '@js-joda/core';
import { Locale } from '@js-joda/locale_en-us';
import { pathOr } from 'ramda';

import { Row, Spacer } from '../../../components/PageHeader';
import { usePromise } from '../../../services/promise.hook';
import { getProBlocks } from '../blocks.api.js';

const dateFormat = DateTimeFormatter.ofPattern('M/d/yyyy').withLocale(Locale.US);
const timeFormat = DateTimeFormatter.ofPattern('h:mm a').withLocale(Locale.US);

const Block = ({
  block
}) => {
  const formattedBlock = React.useMemo(() => {
    return {
      start: LocalTime
        .parse(block.startTime.Include || block.startTime.Exclude)
        .format(timeFormat),
      end: LocalTime
        .parse(block.endTime.Include || block.endTime.Exclude)
        .format(timeFormat),
      date: LocalDate.parse(block.date).format(dateFormat),
      name: block.professional.firstName + ' ' + block.professional.lastName
    };
  }, [block]);

  return (
    <TableRow>
      <TableCell>
        {formattedBlock.name}
      </TableCell>
      <TableCell>
        {formattedBlock.date}
      </TableCell>

      <TableCell>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>{formattedBlock.start}</div>
        </div>
      </TableCell>

      <TableCell>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{formattedBlock.end}</span>
        </div>
      </TableCell>
    </TableRow>
  );
};

const defaultPro = {
  firstName: '',
  lastName: ''
};

function ProBlocks({ id }) {

  const blocksGet = usePromise(getProBlocks, []);

  const [canViewPast, setCanViewPast] = React.useState(false);

  const { professionals } = useSelector(s => ({
    professionals: pathOr([], ['schedule', 'professionals'], s)
  }));

  const visibleBlocks = React.useMemo(() => {
    const today = LocalDate.now().toString();
    return blocksGet.data
      .filter(b => b.date >= today || canViewPast)
      .map(b => ({
        ...b,
        professional: professionals.find(({ id }) => b.proId === id) || defaultPro
      }));
  }, [blocksGet.data, canViewPast, professionals]);

  React.useEffect(() => {
    blocksGet.invoke(id);
  }, [id]);

  return (
    <div>
      <Row>
        <Spacer />
        {blocksGet.loading && <CircularProgress />}
        <Spacer />
      </Row>
      <Row>
        {blocksGet.errorMessage}
      </Row>

      <div>
        <FormControlLabel
          control={<Checkbox checked={canViewPast} onChange={e => setCanViewPast(e.target.checked)} name="canViewPast" />}
          label="View Past Blocks"
        />
        <TableContainer>
          <Table aria-label="hours entry table" size="small">
            <TableHead>
              <TableRow>
                <TableCell>Professional</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Start</TableCell>
                <TableCell>End</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleBlocks.map((block, index) => <Block key={index} block={block} />)}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default ProBlocks;
