import * as at from '../../actionTypes';
import * as R from 'ramda';

const init = {
  busyTags: false,
  busyClients: false,
  busySelectedClients: false,
  allTags: [],
  tags: [],
  selectedTags: [],
  clients: {
    page: 1,
    totalCount: 0,
    totalPages: 1,
    data: [],
    search: '',
  },
  leads: {
    page: 1,
    totalCount: 0,
    totalPages: 1,
    data: [],
    search: '',
  },
  selectedClients: {
    page: 1,
    totalCount: 0,
    totalPages: 1,
    data: [],
  },
  selectedLeads: {
    page: 1,
    totalCount: 0,
    totalPages: 1,
    data: [],
  },
};

export default (tags = init, action) => {
  switch (action.type) {
    case at.TAGS_PATCH: {
      return R.merge(tags, action.data);
    }
    case at.TAGS_REMOTE_GET:
      if (action.state === 'REQUEST') {
        return R.merge(tags, {
          busyTags: true,
        });
      } else if (action.state === 'RESPONSE') {
        const allTags = R.sortBy(R.prop('tag'), action.data);
        return R.merge(tags, {
          tags: allTags,
          allTags,
          busyTags: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(tags, {
          busyTags: false,
        });
      }
      return tags;
    case at.TAGS_REMOTE_CLIENTS_GET:
      if (action.state === 'REQUEST') {
        return R.merge(tags, {
          busyClients: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(tags, {
          clients: {
            data: action.data.data,
            page: action.data.page,
            totalCount: action.data.totalCount,
            totalPages: action.data.totalPages,
            search: action.data.search,
          },
          busyClients: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(tags, {
          busyClients: action.isCancel,
        });
      }
      return tags;
    case at.TAGS_REMOTE_LEADS_GET:
      if (action.state === 'REQUEST') {
        return R.merge(tags, {
          busyClients: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(tags, {
          leads: {
            data: action.data.data,
            page: action.data.page,
            totalCount: action.data.totalCount,
            totalPages: action.data.totalPages,
            search: action.data.search,
          },
          busyClients: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(tags, {
          busyClients: action.isCancel,
        });
      }
      return tags;
    case at.TAGS_REMOTE_TAGGED_CLIENTS_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(tags, {
          busySelectedClients: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(tags, {
          selectedClients: {
            data: action.data.data,
            page: action.data.page,
            totalCount: action.data.totalCount,
            totalPages: action.data.totalPages,
            hide: action.data.hide ? R.isEmpty(action.data.data) : false,
            search: action.data.search,
          },
          busySelectedClients: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(tags, {
          busySelectedClients: false,
        });
      }
      return tags;
    }
    case at.TAGS_REMOTE_TAGGED_LEADS_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(tags, {
          busySelectedClients: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(tags, {
          selectedLeads: {
            data: action.data.data,
            page: action.data.page,
            totalCount: action.data.totalCount,
            totalPages: action.data.totalPages,
            hide: action.data.hide ? R.isEmpty(action.data.data) : false,
            search: action.data.search,
          },
          busySelectedClients: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(tags, {
          busySelectedClients: false,
        });
      }
      return tags;
    }
    case at.TAGS_REMOTE_ADD_TAGS_POST: {
      if (action.state === 'REQUEST') {
        return R.merge(tags, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const allTags = R.sortBy(R.prop('tag'), R.concat(action.data, tags.tags));
        return R.merge(tags, {
          tags: allTags,
          allTags,
          selectedTags: R.concat(action.data, tags.selectedTags),
          selectedClients: {
            page: 1,
            totalCount: 0,
            totalPages: 1,
            data: [],
            hide: true,
          },
          selectedLeads: {
            page: 1,
            totalCount: 0,
            totalPages: 1,
            data: [],
            hide: true,
          },
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(tags, {
          busy: false,
        });
      }
      return tags;
    }
    case at.TAGS_REMOTE_TAG_DELETE: {
      if (action.state === 'REQUEST') {
        return tags;
      } else if (action.state === 'RESPONSE') {
        const allTags = R.filter(({ tagId }) => tagId !== action.data)(tags.tags);
        return R.merge(tags, {
          tags: allTags,
          allTags,
          selectedTags: R.filter(({ tagId }) => tagId !== action.data)(tags.selectedTags),
        });
      } else if (action.state === 'ERROR') {
        return R.merge(tags, {
          busy: false,
        });
      }
      return tags;
    }
    case at.TAGS_REMOTE_ADD_TAG_TO_CLIENT_POST: {
      if (action.state === 'REQUEST') {
        return tags;
      } else if (action.state === 'RESPONSE') {
        if (action.data) {
          const newData = R.prepend(action.data, tags.selectedClients.data);
          return R.merge(tags, {
            selectedClients: {
              ...tags.selectedClients,
              data: newData,
              totalCount: tags.selectedClients.totalCount + 1,
              hide: R.isEmpty(newData),
            },
          });
        } else {
          return tags;
        }
      } else if (action.state === 'ERROR') {
        return R.merge(tags, {
          busySelectedClients: false,
        });
      }
      return tags;
    }
    case at.TAGS_REMOTE_ADD_TAG_TO_LEAD_POST: {
      if (action.state === 'REQUEST') {
        return tags;
      } else if (action.state === 'RESPONSE') {
        if (action.data) {
          const newData = R.prepend(action.data, tags.selectedLeads.data);
          return R.merge(tags, {
            selectedLeads: {
              ...tags.selectedLeads,
              data: newData,
              totalCount: tags.selectedLeads.totalCount + 1,
              hide: R.isEmpty(newData),
            },
          });
        } else {
          return tags;
        }
      } else if (action.state === 'ERROR') {
        return R.merge(tags, {
          busySelectedClients: false,
        });
      }
      return tags;
    }
    case at.TAGS_REMOTE_REMVOE_TAG_TO_CLIENT_DELETE: {
      if (action.state === 'REQUEST') {
        return tags;
      } else if (action.state === 'RESPONSE') {
        const newData = R.without([action.data], tags.selectedClients.data);
        return R.merge(tags, {
          selectedClients: {
            ...tags.selectedClients,
            data: newData,
            totalCount: tags.selectedClients.totalCount - 1,
            hide: R.isEmpty(newData),
          },
        });
      } else if (action.state === 'ERROR') {
        return R.merge(tags, {
          busySelectedClients: false,
        });
      }
      return tags;
    }
    case at.TAGS_REMOTE_REMVOE_TAG_TO_LEAD_DELETE: {
      if (action.state === 'REQUEST') {
        return tags;
      } else if (action.state === 'RESPONSE') {
        const newData = R.without([action.data], tags.selectedLeads.data);
        return R.merge(tags, {
          selectedLeads: {
            ...tags.selectedLeads,
            data: newData,
            totalCount: tags.selectedLeads.totalCount - 1,
            hide: R.isEmpty(newData),
          },
        });
      } else if (action.state === 'ERROR') {
        return R.merge(tags, {
          busySelectedLeads: false,
        });
      }
      return tags;
    }
    case at.TAGS_SORT_TAGS: {
      return R.merge(tags, {
        tags: R.filter(({ tag }) => R.includes(action.data.toLowerCase(), tag.toLowerCase()))(tags.allTags),
      });
    }
    case at.TAGS_REMOTE_UPDATE_TAG_PUT: {
      if (action.state === 'REQUEST') {
        return tags;
      } else if (action.state === 'RESPONSE') {
        return R.evolve({
          tags: R.map((t) => {
            if (t.tagId === action.data.tagId) {
              return action.data;
            } else {
              return t;
            }
          }),
          selectedTags: R.map((t) => {
            if (t.tagId === action.data.tagId) {
              return action.data;
            } else {
              return t;
            }
          })
        }, tags);
      } else if (action.state === 'ERROR') {
        return R.merge(tags, {
          busySelectedClients: false,
        });
      }
      return tags;
    }
    case at.TAGS_INIT: {
      return init;
    }
    default:
      return tags;
  }
};
