import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  pagination: {
    borderTop: '#e3e3e3 solid 1px',
    borderBottom: '#e3e3e3 solid 1px',
    backgroundColor: 'white',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    padding: 2,
    zIndex: 1,
    fontSize: 15,

    '@media print': {
      display: 'none',
    },
  },
  chevrons: {
    fontSize: 16,
    color: 'black',
  },
  disabled: {
    color: 'lightgray',
  },
}));
