import * as React from 'react';
import {
  Radio,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from '@mui/material';
import { ComplexWidgetRadioGrid } from '../intake-types';
import { useAnswerContext } from '../answers.context';
import { RadioMap, RadioGridAnswer } from '../answer-types';

interface RadioGridProps {
  id: number;
  data: ComplexWidgetRadioGrid,
  required: boolean,
  noCtx?: boolean;
  isPrivate?: boolean;
}

const defaultAnswer: RadioGridAnswer = {
  id: 0,
  type: 'RadioGrid',
  value: {}
};

export const RadioGridComponent = ({ id, data, noCtx, isPrivate }: RadioGridProps) => {
  const ctx = useAnswerContext();
  const answer = noCtx ? defaultAnswer : (ctx.getIntakeValue(id) as RadioGridAnswer)
    || defaultAnswer;
  const value = answer.value;
  let disabled = !noCtx || !isPrivate;
  if (isPrivate) {
    disabled = false;
  }
  const setValue = (v: RadioMap) => {
    ctx.setValue(id, {
      id,
      type: 'RadioGrid',
      value: v
    });
  };

  const handleChange = (row: number, v: string) => () => {
    setValue({
      ...value,
      [row]: v
    });
  };

  const isChecked = (row: number, v: string) => {
    return value[row] === v;
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>&nbsp;</TableCell>
            {data.columnLabels.map(c => (
              <TableCell key={c} align="center">{c}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.rowLabels.map((r, idx) =>
            <TableRow key={idx}>
              <TableCell>{r}</TableCell>
              {value && data.columnLabels.map(c => (
                <TableCell key={c} align="center">
                  <Radio
                    checked={isChecked(idx, c)}
                    disabled={disabled}
                    onChange={handleChange(idx, c)}
                    onBlur={() => !disabled && ctx.autoSave()}
                  />
                </TableCell>
              ))}
              {!value && data.columnLabels.map(c => (
                <TableCell key={c} align="center">
                  <Radio
                    disabled={disabled}
                  />
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
