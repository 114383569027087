import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const LocationlIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} id="uuid-c8ad3a99-5298-4283-aae5-cad5d08fd53d" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <g fill={color || '#FFF'} id="uuid-5be45bf3-1e38-4ad9-a4fe-7aac86372433" data-name="Group 8239">
        <path id="uuid-4d420ab4-47e7-4a72-a149-d6bdea73fee8" data-name="Path 6245" d="m25.0129,50c-2.2241-3.4612-4.3975-6.8437-6.5709-10.2261-2.7498-4.2738-5.5165-8.5335-8.2467-12.8185-1.9744-2.9932-2.9345-6.5417-2.7386-10.1221.2038-4.7221,2.3251-9.1566,5.8736-12.2787C16.4739,1.6779,20.5666.0594,24.8274.0081c2.8193-.0892,5.6125.5633,8.1005,1.8923,4.8158,2.4505,8.2373,6.9793,9.2786,12.2815.9635,4.6235-.0166,9.4398-2.7105,13.319-4.4312,6.8887-8.8643,13.7764-13.2993,20.6631-.3852.5989-.776,1.1922-1.1978,1.836m8.8175-32.4104c-.0308-4.6888-3.6977-8.5477-8.3789-8.8175-4.894-.1748-9.0168,3.622-9.2449,8.5138-.1123,4.8217,3.6198,8.8635,8.4351,9.1352,4.8734.2003,8.9865-3.588,9.1868-8.4614.005-.1224.0075-.2449.0074-.3674" />
      </g>
    </svg>
  );
};

export default LocationlIcon;
