import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const ClockIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
      <defs>
        <clipPath id="clip-path-clock-boi">
          <rect id="Rectangle_16578" data-name="Rectangle 16578" width="19" height="19" transform="translate(-0.397 -0.396)" fill={color || '#3696df'}/>
        </clipPath>
      </defs>
      <g id="Group_18134" data-name="Group 18134" transform="translate(0.397 0.396)">
        <g id="Group_18134-2" data-name="Group 18134" transform="translate(0 0)" clip-path="url(#clip-path)">
          <path id="Path_10457" data-name="Path 10457" d="M9.222,18.465a9.232,9.232,0,1,1,9.243-9.218,9.231,9.231,0,0,1-9.243,9.218M9.229,1.808a7.424,7.424,0,1,0,7.424,7.447A7.442,7.442,0,0,0,9.229,1.808" transform="translate(0 0)" fill={color || '#3696df'}/>
          <path id="Path_10458" data-name="Path 10458" d="M7.949,14.754c.178,0,.346,0,.513,0,.38-.005.629-.227.62-.552a.57.57,0,0,0-.621-.54c-.167,0-.334,0-.5,0a6.293,6.293,0,0,1,5.67-5.7c0,.166,0,.331,0,.495a.545.545,0,1,0,1.086,0c0-.173,0-.346,0-.52a6.294,6.294,0,0,1,5.8,5.654c-.21,0-.42-.005-.628,0a.559.559,0,0,0-.553.551.539.539,0,0,0,.544.535c.162.006.325,0,.487,0h.177a6.307,6.307,0,0,1-5.753,5.851c0-.215,0-.429,0-.643a.544.544,0,1,0-1.085,0c0,.215,0,.429,0,.645a6.321,6.321,0,0,1-5.754-5.775m6.285-1.271c-.057-.07-.094-.125-.14-.171q-1.292-1.294-2.585-2.587a.567.567,0,0,0-.6-.183.547.547,0,0,0-.178.952q1.533,1.537,3.07,3.07a.561.561,0,0,0,.894,0q.7-.694,1.389-1.389c.131-.132.267-.26.391-.4a.514.514,0,0,0,.107-.553.527.527,0,0,0-.444-.35.568.568,0,0,0-.5.2c-.456.461-.914.92-1.406,1.414" transform="translate(-5.013 -5.004)" fill={color || '#3696df'}/>
        </g>
      </g>
    </svg>
  );
};

export default ClockIcon;
