import * as at from '../../actionTypes';
import api, { skedApi } from '../../services/api.js';
import axios, { AxiosResponse } from 'axios';
import { remoteAction } from '../../services/actionService.js';
import { popup } from '../../services/Popup.js';
import * as R from 'ramda';
import { Office } from '../Login/login.reducer';
import { Affiliate } from './admin.reducer';

type EmailOrIdType = number | string;

export const getUserClient = (emailOrId: EmailOrIdType) => {
  const numEmail = Number(emailOrId);
  const body = numEmail ? { userId: numEmail } : { email: emailOrId };
  return remoteAction({
    type: at.ADMIN_GET_CLIENT,
    action: () => api.post('user/userClient', body)
      .then((client) => {
        return Promise.all([
          api.get(`office/${client.officeId}`),
          api.get(`client/app/${client.id}`)
        ]).then(([office, loginMethods]) => {
          return R.mergeAll([client, office, loginMethods]);
        });
      }).catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          popup('Error!', 'Unauthorized! Go to Select Office page and return.');
        } else {
          popup('Error!', 'Could not get the client!');
        }
        return {
          state: 'ERROR',
        };
      }),
  });
};

export type AdminPatchData = {
  state?: string;
  affiliate?: {
    id?: number;
    name: string;
    description: string;
    isHidden: boolean;
    created?: string;
  },
  selectedOffices?: Office[],
}

export const adminPatch = (data: AdminPatchData) => ({
  type: at.ADMIN_PATCH,
  data,
});

type ResetUserProps = {
  emailOrId: EmailOrIdType;
  newEmail: string;
  facebook: boolean;
  google: boolean;
  apple: boolean;
}

export const resetUser = ({
  emailOrId,
  newEmail,
  facebook,
  google,
  apple,
}: ResetUserProps) => {
  const numEmail = Number(emailOrId);
  const body = numEmail ? { userId: numEmail } : { email: emailOrId };
  const newE = newEmail === '' ? undefined : newEmail;
  const bodyWithThirdParty = R.merge(body, { facebook, google, apple, newEmail: newE });
  return remoteAction({
    type: at.ADMIN_DELETE_CLIENT,
    action: () => api.delete('user', { data: bodyWithThirdParty }).then(() => {
      if (newEmail) {
        popup('Success!', 'User\'s email reset to: ' + newEmail);
      } else {
        popup('Success!', 'User has been reset!');
      }
      return {
        state: 'NOT_ERROR',
      };
    }).catch((error) => {
      console.error(error);
      if (error.response.status === 404) {
        popup('Error!', 'This client doesn\'t have a user!');
      } else if (error.response.status === 400) {
        popup('Error!', error.response.data);
      }
      return {
        state: 'ERROR',
      };
    }),
  });
};

export const getAffiliateGroups = () => remoteAction({
  type: at.ADMIN_GET_AFFILIATES,
  action: () => api.get('affiliates'),
});

export const selectOffices = (offices: Office[]) => ({
  type: at.ADMIN_SELECT_OFFICES_FOR_AFFILIATE,
  data: offices,
});

export const removeOffices = (offices: Office[]) => ({
  type: at.ADMIN_REMOVE_OFFICES_FOR_AFFILIATE,
  data: offices,
});

export const saveAffiliateGroup = (affiliate: Affiliate, offices: Office[]) => remoteAction({
  type: at.ADMIN_SAVE_AFFILIATE,
  action: () => (affiliate.id ?
    api.put('affiliates/' + affiliate.id, affiliate)
    :
    api.post('affiliates', affiliate))
    .then((newAff) => {
      const id = affiliate.id ? affiliate.id : newAff.id;
      return api.post(`affiliates/${id}/office`, { ids: R.map(R.prop('id'), offices) })
        .then(() => {
          return api.get('affiliates');
        });
    }),
});

export const deleteAffiliateGroup = (id: number) => remoteAction({
  type: at.ADMIN_DELETE_AFFILIATE,
  action: () => api.delete('affiliates/' + id).then(() => id),
});

export const getOffices = (id: number, allOffices: Office[]) => remoteAction({
  type: at.ADMIN_GET_AFFILIATE_OFFICES,
  action: () => api.get('affiliates/' + id + '/office')
    .then((ids) => {
      const offices = R.map((oId) => R.find(R.propEq('id', oId))(allOffices), ids);
      return offices;
    }),
});

type ResetOfficeUsers = {
  emailOrId: EmailOrIdType
}

export const resetOfficeUsers = ({
  emailOrId,
}: ResetOfficeUsers) => {
  return remoteAction({
    type: at.ADMIN_DELETE_CLIENT,
    action: () => api.delete(`office/${emailOrId}/reset-all-users`).then(() => {
      popup('Success!', 'Office users have been totally destroyed!');
      return {
        state: 'NOT_ERROR',
      };
    }).catch((error) => {
      console.error(error);
      if (error.response.status === 404) {
        popup('Error!', 'This office doesn\'t exist!');
      } else if (error.response.status === 400) {
        popup('Error!', error.response.data);
      }
      return {
        state: 'ERROR',
      };
    }),
  });
};

export const disableAllMessages = (
  officeId: number
): Promise<void> => {
  return api.post('message/toggleAll', {
    officeId, toggleTo: false, dummy: []
  });
};

export const disableAllAutoresponders = (
  officeId: number
): Promise<void> => {
  return api.post(`autoresponder/toggleAll?officeId=${officeId}`, {
    enabled: false,
  });
};


export const disableAllApptTypes = (
  officeId: number
): Promise<void> => {
  return api.post('appointmentType/toggleAll', {
    officeId, toggleTo: false, dummy: []
  });
};

export const disableAllSchedules = (
  officeId: number
): Promise<void> => {
  return api.post('schedule/toggleAll', {
    officeId, toggleTo: 0, dummy: []
  });
};

export const disableAllOfficeUsers = (
  officeId: number
): Promise<void> => {
  return api.post(`user/officeCancellation?officeId=${officeId}`, {});
};

export const disableAllReviews = (
  officeId: number
): Promise<void> => {
  const config = {
    headers: {
      'Authorization': skedApi.defaults.headers.common.Authorization,
      'X-As-Office': officeId,
    },
  };
  return axios.post(
    `${process.env.API_URL}/officeReviewSettings`,
    {
      isEnabled: false,
      appointmentTypes: { Some: [] },
      forwardSetting: 'Google',
      daysToResend: 1,
      minutesToWait: 1,
    },
    config
  );
};

export const disableAllGeneralSettings = (
  officeId: number
): Promise<[AxiosResponse<any, any>, AxiosResponse<any, any>, AxiosResponse<any, any>]> => {
  const config = {
    headers: {
      'Authorization': skedApi.defaults.headers.common.Authorization,
      'X-As-Office': officeId,
    },
  };
  return Promise.all([
    axios.put(`${process.env.API_URL}/office/notifications`, {
      timeSetting: 'Never', dummy: []
    }, config),
    axios.put(`${process.env.API_URL}/office/settings`, {
      sendTextNotifications: false,
      geofenceSetting: 'Off',
    }, config),
    axios.delete(`${process.env.API_URL}/office/clear-verified-email`, config)
  ]);
};

export const disableAllNPPs = (
  officeId: number
): Promise<void> => {
  return api.delete(`${process.env.API_URL}/settings/npp/all?officeId=${officeId}`, {});
};

export const disableLWM = (
  officeId: number,
): Promise<void> => {
  const config = {
    headers: {
      'Authorization': skedApi.defaults.headers.common.Authorization,
      'X-As-Office': officeId,
    },
  };
  return axios.put(`${process.env.API_URL}/settings/webmodule`, {
    appointmentTypes: [],
    newClient: [],
  }, config);
};
