import React from 'react';
import {
  Grid,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Input,
  IconButton,
  InputProps,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { AppointmentTypeAutocomplete } from './AppointmentTypeSelect.component';
import { compose, lensIndex, lensProp, set, append, remove, assocPath, pipe, over } from 'ramda';
import * as formService from '../../../services/form-validation.js';
import { Professional, AppointmentType } from '../../Appointments/appointments.types';

const formatNumber = (text: string) => {
  const pattern = '__/__/____';

  const formattedObject = pattern.split('').reduce((acc, character) => {
    if (acc.remainingText.length === 0) {
      return acc;
    }

    if (character !== '_') {
      return {
        formattedText: acc.formattedText + character,
        remainingText: acc.remainingText
      };
    }

    const [head, ...tail] = acc.remainingText;

    return {
      formattedText: acc.formattedText + head,
      remainingText: tail
    };
  }, {
    formattedText: '',
    remainingText: text.split('')
  });

  return formattedObject.formattedText;
};

const DateInput = ({ onChange, value, ...params }: InputProps) => {
  const [text, setText] = React.useState<string>(value as string);
  const inputRef = React.useRef();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let formattedNumber = '';

    if (onChange) e.persist();

    // Does exceed default 8 digit date limit
    if (value.replace(/\D/g, '').length > 8) {
      return;
    }

    // ie hack
    e.preventDefault();

    if (value.length > 0) {
      const inputNumber = value.replace(/\D/g, '');

      formattedNumber = formatNumber(inputNumber);
    }

    // caret position is suppose to fix deleteing issues
    // const caretPosition = e.target.selectionStart;
    const oldFormattedText = text;
    const diff = oldFormattedText.length - formattedNumber.length;

    if (diff === 0) {
      return;
    }

    setText(formattedNumber);
    if (onChange) {
      e.target.value = formattedNumber;
      onChange(e);
    }
  };

  return <Input
    {...params}
    inputRef={inputRef}
    value={text}
    onChange={handleInput}
    placeholder="MM/DD/YYYY" />;
};

const emptyObject = { defaultAppointmentTypeId: 0 };
const mkDefaultClient = ({
  defaultAppointmentTypeId = 0
} = emptyObject) => formService.initForm({
  key: {
    default: new Date().getTime(),
  },
  firstName: {
    default: '',
    validation: [
      formService.requiredValidation('required')
    ]
  },
  lastName: {
    default: '',
    validation: [
      formService.requiredValidation('required')
    ]
  },
  email: {
    default: '',
    validation: [
      formService.requiredValidation('required'),
      formService.emailValidation('not a valid email')
    ]
  },
  birthday: {
    default: '',
    validation: [
      formService.requiredValidation('required')
    ]
  },
  phone: {
    default: ''
  },
  defaultAppointmentTypeId: {
    default: defaultAppointmentTypeId,
    validation: [
      formService.requiredValidation('required')
    ]
  }
});

type DefaultItem = {
  default: string;
  validation: object[];
  value?: string;
  isValid?: boolean;
}

type DefaultClient = {
  key?: DefaultItem;
  firstName: DefaultItem;
  lastName: DefaultItem;
  defaultAppointmentTypeId: DefaultItem;
  email: DefaultItem;
  birthday: DefaultItem;
  phone: DefaultItem;
}

type Props = {
  classes: { tableContainer?: string },
  types: AppointmentType[];
  professionals: Professional[];
  clients: DefaultClient[];
  setClients: (c: DefaultClient[]) => void;
  addNewLine: boolean;
  setAddNewLine: (v: boolean) => void;
}

export const AddClientsInline = ({
  classes,
  types = [],
  professionals = [],
  clients,
  setClients,
  addNewLine = false,
  setAddNewLine,
}: Props) => {

  const setDefaultAppointmentTypes = (value: number | string, prop: string) => (clients: DefaultClient[]) => {
    if (prop !== 'defaultAppointmentTypeId') {
      return clients;
    }
    return clients.map(c => {
      if (c.defaultAppointmentTypeId.value) {
        return c;
      }
      console.log('set appointment type for', c, value);
      return assocPath(['defaultAppointmentTypeId', 'value'], value, c);
    });
  };

  const updateField = (prop: string, i: number) => (e: { target: { value?: number | string, ids?: number[] }}) => {
    const value = e.target.value;
    const clientValueLens = compose(
      lensIndex(i),
      lensProp(prop as never),
      lensProp('value')
    );
    const clientObjLens = lensIndex(i);
    setClients(pipe(
      set(clientValueLens, value),
      setDefaultAppointmentTypes(value, prop),
      over(clientObjLens, formService.validateField(prop))
    ) as unknown as DefaultClient[]);
  };

  const removeItem = (i = 0) => {
    if (clients.length > 1) {
      setClients(remove(i, 1, clients));
    }
  };

  const handleBlur = (prop: string, i: number) => () => {
    setClients(pipe(
      over(lensIndex(i), formService.touchField(prop)),
      over(lensIndex(i), formService.validateField(prop))
    ) as unknown as DefaultClient[]);
  };

  React.useEffect(() => {
    if (clients.length === 0) {
      const newClient = mkDefaultClient();
      setClients(append(newClient, clients));
    }
  }, [clients, setClients]);

  React.useEffect(() => {
    if (addNewLine) {
      const newClient = mkDefaultClient();
      setClients(append(newClient, clients));
      setAddNewLine(false);
    }
  }, [addNewLine]);

  return (<Grid container spacing={1}>
    <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Typography fontWeight={500} fontSize={18} style={{ marginTop: '0', marginRight: '10px' }}>Add Clients</Typography>
    </Grid>

    <Grid item xs={12}>
      <TableContainer className={classes.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Birthday</TableCell>
              <TableCell>Default Appt Type</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map((c, i) => (
              <TableRow key={c.key.value}>
                <TableCell>
                  <Input
                    onBlur={handleBlur('firstName', i)}
                    value={c.firstName.value}
                    error={!c.firstName.isValid}
                    onChange={updateField('firstName', i)}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    onBlur={handleBlur('lastName', i)}
                    value={c.lastName.value}
                    error={!c.lastName.isValid}
                    onChange={updateField('lastName', i)}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    onBlur={handleBlur('email', i)}
                    value={c.email.value}
                    error={!c.email.isValid}
                    onChange={updateField('email', i)}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    onBlur={handleBlur('phone', i)}
                    value={c.phone.value}
                    onChange={updateField('phone', i)}
                  />
                </TableCell>
                <TableCell>
                  <DateInput
                    onBlur={handleBlur('birthday', i)}
                    value={c.birthday.value}
                    error={!c.birthday.isValid}
                    placeholder="MM/DD/YYYY"
                    onChange={updateField('birthday', i)}
                  />
                </TableCell>
                <TableCell>
                  <AppointmentTypeAutocomplete
                    label={false}
                    value={c.defaultAppointmentTypeId.value as unknown as number}
                    error={!c.defaultAppointmentTypeId.isValid}
                    onChange={updateField('defaultAppointmentTypeId', i)}
                    types={types}
                    professionals={professionals}
                    id={`apt-type-${i}`}
                    maxWidth={undefined}
                    minWidth={undefined}
                    includeErrors={undefined}
                  />
                </TableCell>
                <TableCell>
                  <IconButton size="small" onClick={() => removeItem(i)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  </Grid>);
};
