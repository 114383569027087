import React from 'react';
import {
  Button,
  Paper,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { PageHeader, Div } from '../../../../components/PageHeader';
import api from '../../../../services/api.js';

export const Section = withStyles({
  root: {
    marginTop: '10px',
    marginBottom: '10px',
    paddingBottom: '10px',
  }
})(Div);

export const Grouping = () => {
  const [allowGrouping, setAllowGrouping] = React.useState(false);
  const [state, setState] = React.useState({
    busy: false,
    error: ''
  });
  React.useEffect(() => {
    setState({ busy: true, error: '' });
    api.get('office/settings').then(data => {
      setState({ busy: false, error: '' });
      setAllowGrouping(data.allowGrouping);
    }).catch(e => {
      setState({ busy: false, error: e.message });
    });
  }, []);

  const save = (e) => {
    e.preventDefault();
    setState({ busy: true, error: '' });
    api.put('office/settings', { allowGrouping }).then(() => {
      setState({ busy: false, error: '' });
    }).catch(e => {
      setState({ busy: false, error: e.message });
    });
  };
  return (
    <Paper style={{ padding: '20px', marginTop: '10px' }}>
      <Section>
        <PageHeader variant='h6'>
          Grouping
        </PageHeader>
        {state.busy ? <CircularProgress /> :
          <>
            <FormControlLabel
              control={<Checkbox checked={allowGrouping} onChange={(e) => setAllowGrouping(e.target.checked)} name="allowGrouping" />}
              label="Enabled"
            />
            <FormHelperText>
              Disabling this will turn off grouping for your office.  <br />
              If re-enabling, it may take up to 1 hour for groups to reappear.
            </FormHelperText>
          </>}
      </Section>
      <Button
        variant='contained'
        disabled={state.busy}
        onClick={save}>
        Save
      </Button>
    </Paper>
  );
};
