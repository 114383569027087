import React, { useState } from 'react';
import * as R from 'ramda';

import Icon from '../../icons/Location.icon';

import FilterHeader from '../FilterHeader/FilterHeader.component';
import HeaderButton from '../HeaderButton/HeaderButton.component';

import { useStyles } from './filterLocations.styles';

import {
  MenuItem,
  Popover,
  Grid,
  ListItemText,
  Radio
} from '@mui/material';

type Location = {
  id: number;
  name: string;
}

type FilterLocationsProps = {
  save?: ({ locationId }: { locationId: number }) => void;
  setSelectedLocationId: (value: number) => void;
  allLocations: Location[],
  selectedLocationId: number;
  hasLocations?: boolean;
  onlyIcon?: boolean;
  className?: string;
  saveClassName?: string;
  resetClassName?: string;
}

const FilterLocations = ({
  selectedLocationId,
  setSelectedLocationId,
  save,
  allLocations,
  hasLocations,
  onlyIcon,
  className,
  saveClassName,
  resetClassName,
}: FilterLocationsProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const locations = allLocations.map(item => item.name === 'All' ? { ...item, name: 'All Locations' } : item);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    setSelectedLocationId(0);
    handleClose();
  };

  const handleSelected = (value: number) => {
    setSelectedLocationId(value);
    save?.({ locationId: value });
    handleClose();
  };

  if (!hasLocations || R.isEmpty(locations)) {
    return null;
  }

  return (
    <Grid>
      <Grid marginRight={0.5}>
        <HeaderButton
          title='Location'
          onClick={(event) => setAnchorEl(event.currentTarget)}
          Icon={Icon}
          active={!!selectedLocationId}
          type='filter'
          onlyIcon={onlyIcon}
          className={className}
        />
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid minWidth={280}>
          <FilterHeader
            title='Location'
            reset={handleReset}
            resetDisabled={!selectedLocationId}
            saveClassName={saveClassName}
            resetClassName={resetClassName}
          />
          <Grid padding={1} paddingBottom={1}>
            {locations.map(location => (
              <MenuItem
                key={location.id}
                onClick={() => handleSelected(location.id)}
                className={location.id === (selectedLocationId || 0) ? classes.selected : classes.selectItem}
                value={location.id || 0}
              >
                <Radio checked={location.id === (selectedLocationId || 0)} />
                <ListItemText className={classes.selectFontSize} primary={location.name} />
              </MenuItem>
            ))}
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
};

export default FilterLocations;
