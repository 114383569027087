import React, { useEffect } from 'react';
import { TextField, Typography, Autocomplete } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { pipe, range, map } from 'ramda';
import { LocalTime, DateTimeFormatter, ZonedDateTime, LocalDateTime } from '@js-joda/core';
import { Locale } from '@js-joda/locale_en-us';
import { usePromise } from '../../../services/promise.hook';
import api from '../../../services/api.js';

const timeFormat = DateTimeFormatter.ofPattern('h:mm a').withLocale(Locale.US);

const MyTextField = withStyles({
  root: {
    width: '120px',
    minWidth: '120px',
  },
})(TextField);

const useStyles = makeStyles({
  option: {
    fontSize: 15,

    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  pointer: {
    cursor: 'pointer',
    alignSelf: 'center',
    paddingTop: 6,
    paddingBottom: 6,
    width: '100%',
    textAlign: 'center',

    '&:hover': {
      background: 'rgba(0,0,0, 0.05)'
    }
  },
  selected: {
    background: 'rgba(0, 139, 207, 0.08)'
  }
});

const filterOptions = (options, { inputValue = '' }) => {
  const searchInput = inputValue.toLowerCase();
  return options.filter(({ label = '', search = '' }) =>
    label.toLowerCase().indexOf(searchInput) !== -1 ||
    search.toLowerCase().indexOf(searchInput) !== -1
  );
};

const defaultValueObject = { value: '08:00', label: '', search: '' };

const getBusyness = ({ date, appointmentType, clients = 1 }) => {
  return api.post('/hours/available/busyness', { date, appointmentType, clients }).then(data => {
    return data.map(({ time, busy }) => {
      const localTime = LocalDateTime
        .parse(time)
        .toLocalTime()
        .toString();
      return {
        time,
        localTime,
        busy
      };
    });
  });
};

export function TimeSelectBusyness({ interval = 15, onChange, value, id, appointmentTypeId, date, label = 'Choose time' }) {
  const busynessState = usePromise(getBusyness, []);
  useEffect(() => {
    busynessState.invoke({ date, appointmentType: appointmentTypeId });
  }, [date, appointmentTypeId]);

  return <TimeSelect interval={interval} value={value} onChange={onChange} id={id} busyness={busynessState.data} label={label} />;
}


const getBusyColor = state => {
  if (state === 'NotBusy') {
    return '#4caf50';
  }
  if (state === 'Busy') {
    return '#ff9800';
  }
  if (state === 'VeryBusy') {
    return '#f44336';
  }
  if (state === 'No') {
    return '#AAA';
  }
  return '#000';
};

export function TimeSelect({ interval = 15, onChange, value, id, busyness, loading, label = '' }) {
  const classes = useStyles();

  const getBusyLabel = (t) => {
    if (!busyness) {
      return '';
    }
    if (loading) {
      return '...';
    }
    const busyState = busyness.find(b => b.localTime === t.toString());

    if (!busyState) {
      return 'No';
    }
    return busyState.busy;
  };

  const times = React.useMemo(() => {
    const num = 60 / interval * 24;
    const start = LocalTime.MIN;
    return pipe(
      range(0),
      map(i => {
        const t = start.plusMinutes(i * interval);
        return {
          value: t.toString(),
          label: t.format(timeFormat),
          busy: getBusyLabel(t),
          color: getBusyColor(getBusyLabel(t)),
          search: t.format(timeFormat).replace(':', '')
        };
      })
    )(num);
  }, [interval, busyness]);

  const valueObj = React.useMemo(() => {
    return times.find(t => t.value === value) || defaultValueObject;
  }, [value, times]);

  return (
    <Autocomplete
      id={`time-select-${id}`}
      style={{ width: 'auto' }}
      options={times}
      value={valueObj}
      onChange={(_, newValue) => {
        if (newValue.length) {
          onChange(newValue && newValue.value);
        }
      }}
      classes={{ option: classes.option }}
      autoHighlight
      disableClearable
      onOpen={() => {
        setTimeout(() => {
          const optionEl = document.querySelector(
            `[data-name="${value}"]`
          );
          optionEl?.scrollIntoView();
        }, 1);
      }}
      getOptionLabel={(option) => option.label || ''}
      renderOption={(_, option) => {
        return (
          <Typography
            key={option.value}
            style={{ color: getBusyColor(option.busy) }}
            noWrap
            className={`${classes.pointer} ${option.value === value ? classes.selected : ''}`}
            data-name={option.value}
            onClick={() => {
              onChange(option && option.value);
              const items = document.getElementsByClassName('MuiAutocomplete-popupIndicatorOpen');
              items[0]?.click();
            }}
          >
            {option.label || ''}
          </Typography>
        );
      }}
      filterOptions={filterOptions}
      getOptionSelected={(o, v) => o.value === v.value}
      renderInput={(params) => (
        <MyTextField
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            className: 'sked-test-scheduler-new-appointment-time',
            autoComplete: 'new-password', // disable native autocomplete and autofill
          }}
        />
      )}
    />
  );
}

//Takes a zoned date time as an value input and onChange will output a date
export const TimeSelectZonedDateTime = ({ interval, value, onChange, id }) => {
  const timeValue = React.useMemo(() => {
    if (value) {
      return value.toLocalTime().toString();
    } else {
      return '08:00';
    }
  }, [value]);

  const onTimeSelectChange = (timeValue = '08:00') => {
    const zoneId = value.zone();
    const date = value.toLocalDate();

    try {
      const zonedDateTime = ZonedDateTime.of(
        date,
        LocalTime.parse(timeValue),
        zoneId
      );
      onChange(zonedDateTime);
    } catch (e) {
      console.log('error updating the time');
    }
  };

  return <TimeSelect interval={interval} value={timeValue} onChange={onTimeSelectChange} id={id} />;
};

