import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const CalendarIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} id="uuid-253e7cc4-6d33-4c76-8637-db1c4de5c02b" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <g id="uuid-e02bbc2b-d66f-4be4-b764-86276cb5afd1" data-name="Group 6423" fill={color || 'rgba(0, 0, 0, 0.6)'}>
        <path id="uuid-e47d901b-8173-4290-a2b6-cb45b0120893" data-name="Path 4907" d="m23.6992,42.4283c-.1795,0-.321.0242-.466.0242H7.9499c-3.5444.0927-6.6956-2.2425-7.6383-5.6605-.1719-.5879-.2647-1.1962-.2761-1.8086C.0148,28.7188.0355,22.4508.0355,16.1828c0-.1277,0-.2554.0276-.4107h41.988v3.2479c-6.2753-1.8565-13.0436.3795-16.9781,5.6088-4.1279,5.0631-4.6782,12.157-1.3806,17.7962" />
        <path id="uuid-d2682d8b-4941-42b6-8db0-8011b1eb4982" data-name="Path 4908" d="m25.2179,34.4518c-.0056-6.8434,5.5375-12.3956,12.3809-12.4012,6.8434-.0056,12.3956,5.5375,12.4012,12.3809.0056,6.8434-5.5375,12.3956-12.3809,12.4012-.0264,0-.0528,0-.0792-.0002-6.8171-.0266-12.3278-5.5635-12.322-12.3807m14.172-1.781c0-.6627.0207-1.2736,0-1.8776-.0162-.9836-.8267-1.7679-1.8103-1.7517-.9836.0162-1.7679.8267-1.7517,1.8103v3.5654c-.0407.9656.709,1.7814,1.6746,1.8221.047.002.094.0021.1409.0003h3.3411c.9855.0613,1.8342-.6879,1.8955-1.6734.0613-.9855-.6879-1.8342-1.6734-1.8955-.074-.0046-.1481-.0046-.2221,0h-1.6015" />
        <path id="uuid-85882fae-9290-4f90-acec-7827df75cab6" data-name="Path 4909" d="m28.9525,3.3362c-.0379.139-.0633.281-.0759.4245v4.0728c-.075.9827.6608,1.8401,1.6434,1.9151.9827.075,1.8401-.6608,1.9151-1.6434.0069-.0904.0069-.1812,0-.2717.0104-1.284.0104-2.5852.0104-3.8899v-.5868c4.2806-.9482,8.5193,1.7533,9.4675,6.0338.1977.8925.2398,1.8124.1243,2.7193H.0769C-.4328,8.5035,1.6325,5.0274,5.0437,3.7503c1.4774-.4505,3.0303-.5995,4.5664-.4383h0c0,1.5187,0,3.0408.0242,4.5595-.0477,1.0054.7287,1.859,1.7341,1.9067.0283.0013.0566.002.0849.002.9946-.0287,1.7777-.8583,1.749-1.853-.0005-.0186-.0014-.0372-.0025-.0557V3.3085l-.0345.0069c.2036,0,.4073-.0276.6109-.0276h14.4723c.2382,0,.4763.038.7145.0587l-.0104-.0104Z" />
      </g>
    </svg>
  );
};

export default CalendarIcon;
