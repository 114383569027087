import React, { useState } from 'react';
import {
  MenuItem,
  TextField,
  Snackbar,
  Checkbox,
  Button,
  Paper,
  FormControlLabel,
  Select,
  FormHelperText,
  TableHead,
  TableBody,
  TableRow,
} from '@mui/material';
import { PopupTemplate } from '../../../../services/Popup.js';
import { PageHeader } from '../../../../components/PageHeader';
import Header from '../../../../components/PageHeader/PageHeader.component';
import Loading from '../../../../components/Loading/Loading.component';
import { TableContainer, HeaderCell, BodyCell } from '../../../../components/CustomTable';
import { Grouping } from './Grouping.component.jsx';
import UpdateSyncer from '../../../../components/UpdateSyncer/updateSyncer.component';
import * as R from 'ramda';

const appointmentLengthOptions = [
  { label: '10 minutes', value: 10 },
  { label: '15 minutes', value: 15 }
];


const proMenu = (p, pro, mapped, patch) => {
  const newMap = {
    fromPro: pro,
    toPro: p
  };
  return (
    <MenuItem
      key={p.id}
      onClick={() => patch(newMap)}
      value={p.id}>
      {p.firstName + ' ' + p.lastName}
    </MenuItem>
  );
};

const proItems = (pro, mapped, pros, patch) => {
  const mappedPro = R.pipe(
    R.filter(({ fromPro }) => fromPro.id === pro.id),
    R.head
  )(mapped);
  return (
    <TableRow key={pro.id}>
      <BodyCell>
        {pro.displayFirstName + ' ' + pro.displayLastName}
      </BodyCell>
      <BodyCell>
        <TextField
          select
          value={mappedPro === undefined ? pro.id : mappedPro.toPro.id}>
          {pros.map((p) => proMenu(p, pro, mapped, patch))}
        </TextField>
      </BodyCell>
      <BodyCell>
        <TextField
          select
          value={mappedPro === undefined ? undefined : mappedPro.mappingFlow}
          disabled={mappedPro === undefined}>
          <MenuItem
            key={'none'}
            onClick={() => patch(R.dissoc('mappingFlow', mappedPro))}
            value={undefined}>
            None
          </MenuItem>
          <MenuItem
            key={'client-only'}
            onClick={() => patch(R.assoc('mappingFlow', 'ClientsOnly', mappedPro))}
            value={'ClientsOnly'}>
            Clients Only
          </MenuItem>
          <MenuItem
            key={'both'}
            onClick={() => patch(R.assoc('mappingFlow', 'ClientsAndAppointments', mappedPro))}
            value={'ClientsAndAppointments'}>
            Clients & Appointments
          </MenuItem>
        </TextField>
      </BodyCell>
    </TableRow>
  );
};

const proTable = (pros, mapped, patch) => (
  <TableContainer noHover>
    <TableHead>
      <TableRow>
        <HeaderCell>
          Default
        </HeaderCell>
        <HeaderCell>
          Schedule To
        </HeaderCell>
        <HeaderCell>
          Client Mapping Flow
        </HeaderCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {pros.map((pro) => proItems(pro, mapped, pros, patch))}
    </TableBody>
  </TableContainer>
);

const miscTable = ({ aptLength, patch, clinicId }) => {
  const handleChange = e => {
    patch('aptLength', e.target.value);
  };
  return (
    <TableContainer noHover>
      <TableHead>
        <TableRow>
          <HeaderCell>
            Option
          </HeaderCell>
          <HeaderCell>
            Value
          </HeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <BodyCell>
            Platinum Appointment Length
          </BodyCell>
          <BodyCell>
            <div>
              <Select
                labelId="block-label"
                variant='standard'
                id="block"
                value={aptLength}
                onChange={handleChange}
              >
                {appointmentLengthOptions.map(({ label, value }) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
              </Select>
              <FormHelperText>
                If you use 15 minute schedules in Platinum, select 15 minutes.  <br />
                If you use 10 minute schedules in Platinum, select 10 minutes.
              </FormHelperText>
            </div>
          </BodyCell>
        </TableRow>
        {clinicId &&
          <TableRow>
            <BodyCell>
              Platinum Clinic ID
            </BodyCell>
            <BodyCell>
              {clinicId}
            </BodyCell>
          </TableRow>}
      </TableBody>
    </TableContainer>
  );
};

const UpgradeToPlatinumAPI = (props) => {
  const [state, setState] = useState({
    clinicId: ''
  });
  return (
    <Paper style={{ padding: '20px', marginTop: '20px' }}>
      <form onSubmit={(e) => {
        e.preventDefault();
        props.updateToPlatinumAPI({ officeId: props.officeId, clinicId: state.clinicId });
      }}>
        <PageHeader variant="h6">
            Upgrade to Platinum API
        </PageHeader>
        <TextField
          name="message-name"
          label="Platinum ClinicId"
          value={state.clinicId}
          style={{ width: '100%' }}
          disabled={props.busy}
          onChange={(e) => setState({ clinicId: e.target.value.trim().toUpperCase() })} />
        <br />
        <br />
        <Button
          variant='contained'
          onClick={() => props.updateToPlatinumAPI({ officeId: props.officeId, clinicId: state.clinicId })}
          disabled={props.busy}>
            Upgrade
        </Button>
      </form>
    </Paper>
  );
};

export const PlatIntegrationTemplate = ({
  busy,
  integrationMiscPatch,
  saveMisc,
  snackbar,
  pros,
  mappedPros,
  mappedPatch,
  savePros,
  showMaps,
  aptLength,
  office,
  updateToPlatinumAPI,
  admin,
  clinicId,
}) => (
  <>
    <Header title='EHR Settings' />
    <div style={{ margin: '20px' }}>
      <PopupTemplate />
      <Snackbar
        open={snackbar}
        message="Information saved!"
        autoHideDuration={4000}
        onClose={() => integrationMiscPatch('snackbar', false)} />
      {busy && <Loading loading />}
      {!busy &&
        <div>

          <Paper style={{ padding: '20px', marginBottom: '20px', marginTop: 0 }}>
            <PageHeader variant="h6">
              Miscellaneous
            </PageHeader>
            {miscTable({ aptLength, clinicId, patch: integrationMiscPatch })}
            <Button
              variant='contained'
              onClick={() => saveMisc({
                blockLength: aptLength,
              }, 'Platinum')}>
              Save
            </Button>
          </Paper>
          <Paper style={{ padding: '20px', marginBottom: '20px' }}>
            <PageHeader variant="h6">
              Professional Mapping
            </PageHeader>
            <FormControlLabel
              label="Enable"
              control={<Checkbox
                checked={showMaps}
                onChange={() => integrationMiscPatch('showMaps', !showMaps)} />}
            />
            <br />
            {showMaps &&
              proTable(pros, mappedPros, mappedPatch)}
            <Button
              style={{ marginTop: '10px' }}
              variant='contained'
              onClick={() => savePros(showMaps ? mappedPros : [])}>
              Save
            </Button>
          </Paper>
          <Grouping />
          {admin && <UpdateSyncer ehrSystem={'PlatinumApi'} />}
        </div>}
      {admin &&
        <UpgradeToPlatinumAPI
          officeId={office.id}
          busy={busy}
          updateToPlatinumAPI={updateToPlatinumAPI} />}
    </div>
  </>
);
