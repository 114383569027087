import React, { useState, useEffect } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import {
  merge,
  propEq,
  reject,
  map,
  pipe,
  assoc
} from 'ramda';
import { TableContainer, TableRow, TableHead, TableBody, BodyCell, HeaderCell } from '../../../components/CustomTable';

const defaultArray = [];

export function ClientList({
  selectedClients,
  searchResultList = defaultArray,
  searchResults,
  selectClient,
  isSelectOne = false,
  isInDialog = false,
}) {

  const [list, setList] = useState([]);

  useEffect(() => {
    const isSelected = client => selectedClients.some(propEq('id', client.id));
    const isInresults = client => searchResultList.some(propEq('id', client.id));
    const results = searchResultList.map(client => merge(client, { checked: isSelected(client) }));
    const selected = pipe(
      reject(isInresults),
      map(assoc('checked', true))
    )(selectedClients);
    setList(selected.concat(results));

  }, [selectedClients, searchResultList]);

  return <div>
    {searchResults.errorMessage && <p>{searchResults.errorMessage}</p>}
    {searchResults.loading && <CircularProgress />}
    {list.length > 0 &&
      <Grid marginX={isInDialog ? -2 : 0}>
        <TableContainer>
          <TableHead>
            <TableRow>
              <HeaderCell padding="checkbox">&nbsp;</HeaderCell>
              <HeaderCell>Name</HeaderCell>
              <HeaderCell>Birthday</HeaderCell>
              <HeaderCell>Phone</HeaderCell>
              <HeaderCell>Email</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row) => (
              <TableRow
                key={row.id}
                hasSelect
                checked={row.checked}
                setChecked={() => selectClient(row)}
                isSelectOne={isSelectOne}
              >
                <BodyCell> {row.firstName} {row.lastName} </BodyCell>
                <BodyCell>{row.birthday}</BodyCell>
                <BodyCell>{row.phone}</BodyCell>
                <BodyCell>{row.email}</BodyCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      </Grid>
    }
  </div>;
}
