import React, { ComponentProps } from 'react';

const PartialCheckedIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg {...props} id="checkbox-partial-checked-icon" xmlns="http://www.w3.org/2000/svg" width="15.146" height="15.146" viewBox="0 0 15.146 15.146">
      <rect id="Rectangle_9" data-name="Rectangle 9" width="15.146" height="15.146" rx="2" fill="#3696df"/>
      <path id="Path_8759" data-name="Path 8759" d="M11.35,8.733H2.926" transform="translate(0.436 -1.16)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2"/>
    </svg>
  );
};

export default PartialCheckedIcon;
