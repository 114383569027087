import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Popover,
  List,
  ListItem,
  ListItemText
} from '@mui/material';

import {
  LocalDate,
  DateTimeFormatter,
  ZonedDateTime,
  ZoneId
} from '@js-joda/core';
import { Locale } from '@js-joda/locale_en-us';

import { filterNewSchedule } from '../services/calendar.service.js';
import { calcPositionY, calcTimeFromY } from '../services/sortAppointments';

import { AppointmentEntry } from './AppointmentEntries.component';
import { BlockEntry } from './BlockEntries.jsx';
import { ReschedulePopUp } from './reschedule-popup.component.jsx';
import { pathOr } from 'ramda';

const dayFormat = DateTimeFormatter.ofPattern('eee d').withLocale(Locale.US);

const toDayFormat = date => {
  try {
    return LocalDate
      .parse(date)
      .format(dayFormat);
  } catch (e) {
    return 'invalid date';
  }
};

const useStyles = makeStyles(() => ({
  calendarHolder: {
    height: 'calc(100% - 15px)',
    position: 'relative',
  },
  dowLabelContainer: {
    position: 'relative',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    height: 'max-content',
  },
  dowLabel: {
    paddingLeft: '2px',
    boxSizing: 'border-box',
    borderLeft: '1px solid #AAA',
    fontWeight: 'bold',
  },
  dowLabelDate: {
    position: 'sticky',
    left: '1px',
    boxSizing: 'border-box',
    fontSize: 14,
  },
  calendarOuterContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    boxSizing: 'border-box',
    height: '100%',
  },
  calendarContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    overflowY: 'scroll',
    height: '100%',
    userSelect: 'none'
  },
  '@media print': {
    calendarContainer: {
      height: 'auto !important'
    }
  },
  calendarColumn: {
    display: 'flex',
    flexDirection: 'column',
    height: 'max-content',
    borderRight: '1px solid #AAA',
    boxSizing: 'border-box',
    '&>div': {
      borderRight: '1px solid #AAA'
    },
    '&>div:nth-of-type(odd)': {
      backgroundColor: '#cee1ea'
    }
  },
  timeLabelColumn: {
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid #AAA',
    boxSizing: 'border-box',
    position: 'relative',
  },
  timeRows: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    '&>div:nth-of-type(odd)': {
      backgroundColor: 'rgba(0,123,207, 0.2)'
    }
  },
  timeSlot: {
    display: 'flex',
    flexDirection: 'column',
    width: '100px',
    minHeight: '25px',
    height: '25px',
    justifyContent: 'center',
    boxSizing: 'border-box',
    overflow: 'hidden'
  },
  timeLabelSlot: {
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    overflow: 'hidden',
    alignItems: 'center',
    transform: 'translateY(8px)',
  },
  timeRowLabel: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: 13,
    textAlign: 'center',
    width: '28px',
    color: '#5d5252',
    lineHeight: 1.1,
  },
  '60min': {
    borderTop: '3px solid #AAA',
    fontWeight: 'bold',
  },
  '30min': {
    borderTop: '2px solid #AAA',
    fontWeight: 'bold'
  },
  '15min': {
    borderTop: '1px solid #AAA'
  },
  '5min': {
    borderTop: '0.5px solid #AAA'
  },
  currentTimeContainer: {
    position: 'absolute',
    margin: 0
  },
  currentTimeCircle: {
    display: 'block',
    margin: 0,
    width: '20px',
    maxWidth: '20px',
    height: '20px',
    maxHeight: '20px',
    borderRadius: '10px',
    backgroundColor: '#FFA000'
  },
  currentTimeLine: {
    position: 'absolute',
    borderTop: '2px solid #FFA000'
  }
}));

const convertUTCToZoned = (time, timezone) => ZonedDateTime
  .parse(time)
  .withZoneSameInstant(ZoneId.of(timezone));

const TopLabel = ({
  id,
  width,
  classes,
  cellWidth,
  name
}) => {
  return (<div className={classes.dowLabel} style={{ minWidth: width + 'px' }} id={id}>
    <div className={classes.dowLabelDate} style={{ width: cellWidth - 4 }}>
      {name}
    </div>
  </div>);
};

const getTimeRowWidth = (
  positions,
  cellWidth,
  groupByProfessional
) => {
  return groupByProfessional ?
    positions.maxGroupedSlots * cellWidth :
    positions.slots.length * cellWidth;
};

export const CalendarInternal = ({
  state,
  store,
  selectedProfessional,
  selectedLocation,
  possibleAppointments,
  selectedDate,
  viewAppointment,
  skedAppointment,
  reskedAppointment,
  markAppointment,
}) => {
  const classes = useStyles();
  const calendarContainer = React.useRef(null);
  const labelRef = React.useRef(null);
  const topLabelContainer = React.useRef(null);

  const [apt, setApt] = React.useState(null);

  const [positions, setPositions] = React.useState({
    selectedSchedule: { id: -1, name: 'All' },
    groupedAppointments: [],
    sortedAppointments: [],
    times: [],
    specialHours: [],
    scheduleHours: [],
    slots: Array.from(Array(10).keys()),
    dayStart: new Date().toISOString(),
    dayEnd: new Date().toISOString(),
    maxGroupedSlots: 0
  });


  // Dragging code...
  const dragRef = React.useRef(null);
  const [openReskedPopup, setReskedPopup] = React.useState(false);
  const [dragApt, setDragApt] = React.useState(null);
  const [dragData, setDragData] = React.useState({
    time: '',
    topStart: 0,
    leftStart: 0
  });

  const handleDragStart = (apt, startSlot = 0) => (event) => {
    setDragApt(apt);

    const startX = event.clientX;
    const startY = event.clientY;

    dragRef.current = document.querySelector(`#apt-${apt.id}`);
    dragRef.current.style.zIndex = 1;

    const scrollStart = calendarContainer.current.scrollTop;
    const topStart = dragRef.current.style.top;
    const leftStart = dragRef.current.style.left;

    const getAptPos = (event) => {
      const cx = event.clientX;
      const cy = event.clientY;
      const dx = cx - startX;
      const dy = cy - startY;
      const scroll = calendarContainer.current.scrollTop;
      const scrolld = scroll - scrollStart;
      const x = apt.draw.x + dx + startSlot * state.cellWidth;
      const y = apt.draw.y + dy + scrolld;
      return { x, y };
    };

    const dragAptHandler = (event) => {
      if (dragRef.current) {
        const p = getAptPos(event);
        // Skip react updating the DOM for performance reasons
        dragRef.current.style.top = p.y + 'px';
        dragRef.current.style.left = p.x + 'px';
      }
    };

    const finishDragHandler = (event) => {
      const p = getAptPos(event);
      const time = calcTimeFromY({
        startOfDay: new Date(positions.dayStart).getTime(),
        interval: store.timeIntervals,
        cellHeight: state.cellHeight,
        y: p.y
      });

      //If it didn't change the time,
      //then open the context menu
      if (new Date(apt.time).getTime() === new Date(time).getTime()) {
        // Put it back where it was if it moved...
        dragRef.current.style.top = topStart;
        setApt(apt);
        setAnchorEl(dragRef.current);
        setDragApt(null);
        dragRef.current.style.zIndex = 0;
      } else if (apt.status.Preview) {
        // Dragging a preview appointment
        const zonedTime = convertUTCToZoned(time, store.tz);
        skedAppointment({ time: zonedTime });
      } else {
        // Open pop up set data in state to access in pop up handlers
        dragRef.current.style.top = calcPositionY({
          startOfDay: new Date(positions.dayStart).getTime(),
          interval: store.timeIntervals,
          cellHeight: state.cellHeight,
          time: new Date(time).getTime()
        }) + 'px';
        setReskedPopup(true);
        setDragData({
          ...dragData,
          time,
          topStart,
          leftStart
        });
      }

      dragRef.current.style.left = leftStart;
      calendarContainer.current.removeEventListener('mouseup', finishDragHandler);
      calendarContainer.current.removeEventListener('mousemove', dragAptHandler);
    };

    if (apt) {
      // Add listeners
      calendarContainer.current.addEventListener('mousemove', dragAptHandler);
      calendarContainer.current.addEventListener('mouseup', finishDragHandler);
    }
  };

  const closeReskedPopup = () => {
    dragRef.current.style.zIndex = 0;
    dragRef.current.style.top = dragData.topStart;
    dragRef.current.style.left = dragData.leftStart;
    setReskedPopup(false);
    setDragApt(null);
    setDragData({});
  };

  const confirmResked = () => {
    dragRef.current.style.zIndex = 0;
    reskedAppointment({
      ...dragApt,
      time: dragData.time
    });
    setDragApt(null);
    setReskedPopup(false);
  };

  // Current time code...
  const alreadyScrolled = React.useRef(false);

  const [currentTime, setCurrentTime] = React.useState({
    date: new Date().getTime(),
    y: 0,
    show: false
  });

  // Update the current time every minute
  React.useEffect(() => {
    const time = new Date().getTime();
    const y = calcPositionY({
      startOfDay: new Date(positions.dayStart).getTime(),
      time,
      interval: store.timeIntervals,
      cellHeight: state.cellHeight
    });

    if (!alreadyScrolled.current && y > 1) {
      calendarContainer.current.scrollTop = y;
      alreadyScrolled.current = true;
    }

    const show = LocalDate.now().toString() === selectedDate;

    const timeout = setTimeout(() => {
      setCurrentTime(s => ({
        ...s,
        date: new Date().getTime()
      }));
    }, 60e3);

    setCurrentTime(s => ({
      ...s,
      y,
      show
    }));

    return () => {
      clearTimeout(timeout);
    };
  }, [currentTime.date, selectedDate, positions.dayStart, state.cellHeight]);

  const [height, setHeight] = React.useState(500);

  const calendarHeight = React.useMemo(() => {
    let val = 15;
    if (topLabelContainer.current && !state.loading) {
      val = topLabelContainer.current?.clientHeight || 15;
    }
    return `calc(100% - ${val}px)`;
  }, [topLabelContainer, state]);

  const updateHeight = () => {
    const bb = calendarContainer.current.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    setHeight(windowHeight - bb.y);
  };

  const recalculateAppointmentPositions = () => {
    const start = new Date().getTime();
    const date = selectedDate;

    const {
      cellHeight,
      cellWidth,
      groupByProfessional,
      scheduleId,
      appointments,
      officeHours,
      specialOfficeHours,
      schedules
    } = state;

    const timezone = store.tz;
    const interval = store.timeIntervals;
    const colors = store.colors;

    const allAppointments = appointments.concat(possibleAppointments);

    const positionData = filterNewSchedule({
      date, timezone, interval, scheduleId, specialOfficeHours,
      officeHours, schedules, cellHeight, cellWidth, colors,
      groupByProfessional,
      appointments: allAppointments,
      professionals: store.professionals.filter(({ id }) => id !== 0),
      selectedProfessional,
      appointmentTypes: store.proTypes,
      selectedLocation,
    });

    const end = new Date().getTime();
    const aptCt = state.appointments.length;
    const dur = (end - start);
    const rate = (aptCt / dur).toFixed(4);
    console.log(`time to calc new positions: ${dur}ms for ${aptCt} apts. Rate: ${rate} apts/ms`);
    setPositions(positionData);
  };

  React.useEffect(() => {
    recalculateAppointmentPositions();
  }, [
    state.appointments, state.timezone, state.officeHours, state.schedules,
    state.interval, state.scheduleId, possibleAppointments, state.cellWidth,
    state.cellHeight, state.groupByProfessional, selectedProfessional, selectedLocation,
    state.localInterval,
  ]);


  React.useEffect(() => {
    updateHeight();
  }, []);

  React.useEffect(() => {
    function handleResize() {
      updateHeight();
    }

    window.addEventListener('resize', handleResize);
    function scrollHandler() {
      const st = calendarContainer.current.scrollTop;
      const sl = calendarContainer.current.scrollLeft;
      labelRef.current.style.marginTop = String((st + state.cellHeight / 2) * -1) + 'px';
      topLabelContainer.current.scrollLeft = sl;
    }

    calendarContainer.current.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('resize', handleResize);
      calendarContainer.current.removeEventListener('scroll', scrollHandler);
    };
  });



  // Code for context menu when you click on an appointment
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const viewSelectedApt = () => {
    handleClose();
    viewAppointment(apt);
  };

  const toggleSelectedAptArrival = () => {
    handleClose();
    if (apt.status.Arrived) {
      return markAppointment(apt, 'Scheduled');
    }
    return markAppointment(apt, 'Arrived');
  };

  const toggleSelectedAptMissed = () => {
    handleClose();
    if (apt.status.Missed) {
      return markAppointment(apt, 'Scheduled');
    }
    return markAppointment(apt, 'Missed');
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={classes.calendarHolder}>
      {/* Top Provider Labels */}
      <div>
        <div
          ref={topLabelContainer}
          className={classes.dowLabelContainer}
          style={{ marginLeft: '51px' }}
        >
          {state.groupByProfessional && positions.groupedAppointments.map(ga => <TopLabel
            id={'provider-slot-' + ga.professional.id}
            width={state.cellWidth * ga.slotLength}
            classes={classes}
            cellWidth={state.cellWidth}
            name={`${ga.professional.displayFirstName} ${ga.professional.displayLastName}`}
          />)}
          {!state.groupByProfessional && <TopLabel
            id={'date-label-slot'}
            width={state.cellWidth + 'px'}
            classes={classes}
            cellWidth={state.cellWidth}
            name={toDayFormat(selectedDate)}
          />}
          <div style={{ minWidth: '15px' }}></div>
        </div>
      </div>

      <div className={classes.calendarOuterContainer} style={{ height: calendarHeight }}>
        {/* Sticky Left Time Labels */}
        <div style={{
          maxWidth: '52px',
          minWidth: '52px',
          overflow: 'hidden',
          height: '100%'
        }}
        id={'slot-labels'}>
          <div className={classes.timeLabelColumn} id={'slot-labels'} ref={labelRef}>
            {positions.times.map((time, i) => (
              <div
                onClick={() => skedAppointment(time)}
                className={classes.timeLabelSlot}
                style={{ height: `${state.cellHeight}px`, minHeight: `${state.cellHeight}px` }}
                key={`slot-time-labels-${time.time}`}>
                <div className={classes.timeRowLabel}>
                  {(i !== 0 && (time.interval === '60min' || time.interval === '30min')) ?
                    time.display : <span>&nbsp;</span>}
                </div>
              </div>
            ))}

            {/* Current Time Circle */}
            {currentTime.show &&
              <p
                className={classes.currentTimeContainer}
                style={{ marginTop: (currentTime.y - 10 + state.cellHeight / 2), left: 80 }}>
                <span className={classes.currentTimeCircle}></span>
              </p>
            }
          </div>
        </div>

        <div className={classes.calendarContainer} ref={calendarContainer}>
          {/* Time rows that alternate color  */}
          <div className={classes.timeRows} style={{ minHeight: height, minWidth: getTimeRowWidth(positions, state.cellWidth, state.groupByProfessional) }}>
            {positions.times.map((time) => (
              <div
                onClick={() => skedAppointment(time)}
                className={classes.timeSlot + ' ' + classes[time.interval]}
                style={{ width: '100%', minHeight: `${state.cellHeight}px` }}
                key={`slot-time-labels-${time.time}`}>
              </div>
            ))}
          </div>

          {/* Column bars  */}
          {state.groupByProfessional && positions.groupedAppointments.map((ga, j) => ga.slots.map((s, i) => <div
            className="vertical-line"
            key={`slot-bar-${j}-${i}`}
            style={{
              position: 'absolute',
              left: state.cellWidth * (i + ga.startSlot),
              marginLeft: s === 0 ? '-2px' : '-1px',
              minWidth: s === 0 ? '2px' : '1px',
              minHeight: positions.times.length * state.cellHeight + 'px',
              backgroundColor: s === 0 ? '#777' : '#AAA',
            }}></div>
          ))}
          {!state.groupByProfessional && positions.slots.map((s, i) => <div
            className="vertical-line"
            key={`slot-bar-${i}`}
            style={{
              position: 'absolute',
              left: state.cellWidth * i,
              marginLeft: s === 0 ? '-2px' : '-1px',
              minWidth: s === 0 ? '2px' : '1px',
              minHeight: positions.times.length * state.cellHeight + 'px',
              backgroundColor: s === 0 ? '#777' : '#AAA',
            }}></div>
          )}

          {positions.specialHours.map((sh, i) => (
            <BlockEntry
              key={`${i}${sh.display}`}
              id={`sh-${i}${sh.display}`}
              title=''
              x={0}
              y={sh.y}
              width={getTimeRowWidth(positions, state.cellWidth, state.groupByProfessional) + 'px'}
              height={sh.h}
              name={`Special Hours: ${sh.display}`} />
          ))}

          {positions.scheduleHours.map((sh, i) => (
            <BlockEntry
              key={`${i}${sh.display}`}
              id={`sch-${i}${sh.display}`}
              title=''
              x={0}
              y={sh.y}
              width={getTimeRowWidth(positions, state.cellWidth, state.groupByProfessional) + 'px'}
              height={sh.h}
              name={sh.display} />
          ))}

          {state.groupByProfessional && positions.groupedAppointments.map(ga => ga.appointments.map(a =>
            <AppointmentEntry
              key={a.id}
              a={a}
              startSlot={ga.startSlot}
              handleDragStart={handleDragStart(a, ga.startSlot)}
              cellWidth={state.cellWidth}
              currentTimestamp={currentTime.date}
              nameDisplay={state.nameDisplay}
              timezone={store.tz}
            />
          ))}
          {!state.groupByProfessional && positions.sortedAppointments.map(a =>
            <AppointmentEntry
              key={a.id}
              a={a}
              handleDragStart={handleDragStart(a, 0)}
              cellWidth={state.cellWidth}
              currentTimestamp={currentTime.date}
              nameDisplay={state.nameDisplay}
              timezone={store.tz}
            />
          )}

          {/* Current Time Line */}
          {currentTime.show && <div className={classes.currentTimeLine} style={{ top: currentTime.y - 1, width: getTimeRowWidth(positions, state.cellWidth, state.groupByProfessional) }}></div>}

        </div>
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List component="nav" aria-label="primary appointment actions">
          <ListItem button onClick={viewSelectedApt}>
            <ListItemText primary="View Appointment" />
          </ListItem>
          <ListItem button onClick={toggleSelectedAptArrival}>
            <ListItemText primary={apt && apt.status.Arrived ? 'Mark as Not Arrived' : 'Mark as Arrived'} />
          </ListItem>
          {pathOr('', ['office', 'ehrSystem'], store) === 'None' &&
            <ListItem button onClick={toggleSelectedAptMissed}>
              <ListItemText primary={
                apt && apt.status.Missed ? 'Mark as Scheduled' : 'Mark as Missed'
              } />
            </ListItem>}
        </List>
      </Popover>

      <ReschedulePopUp
        openReskedPopup={openReskedPopup}
        closeReskedPopup={closeReskedPopup}
        confirmResked={confirmResked}
        dragApt={dragApt}
        dragData={dragData}
        timezone={store.tz} />

    </div>
  );
};
