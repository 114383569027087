import React from 'react';
import ConditionalIndicator from './conditional-indicator.component';
import { IndexAndConds } from '../intake-types';

const QuestionLabel = ({
  complex, cond
}: { cond: IndexAndConds, complex?: boolean }) => {
  return (
    <div style={{
      display: 'flex',
      margin: complex ? 'unset' : '0px 0px -23px 0px',
    }}
    >
      <i style={{ color: '#808080' }}>
          Question #{cond.index}
      </i>
      <ConditionalIndicator
        type='Question'
        index={cond.index}
        conds={cond.conditionals}/>
    </div>
  );
};

export default QuestionLabel;
