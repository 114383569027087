import React from 'react';

import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TableHead,
  TableBody,
  TableRowProps, Checkbox,
  TableCellProps,
} from '@mui/material';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonChecked from '@mui/icons-material/RadioButtonChecked';
import PartialCheckedIcon from '../../icons/PartialChecked.icon';
import CheckedIcon from '../../icons/Checked.icon';

import { useStyles } from './table.styles';

interface Props extends TableRowProps {
  hasSelect?: boolean;
  checked?: boolean;
  someChecked?: boolean;
  setChecked?: (value: boolean) => void;
  noSelectHover?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  isSelectOne?: boolean;
}

export const TableRow = ({
  hasSelect, checked, someChecked, setChecked, noSelectHover, indeterminate, disabled, isSelectOne, ...props
}: Props) => {
  const classes = useStyles();
  const [isShown, setIsShown] = React.useState(false);

  return (
    <MuiTableRow
      className={`${checked && !noSelectHover ? classes.selected : ''} ${hasSelect ? classes.hasSelect : ''}`}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
      {...props}
    >
      {hasSelect &&
        <MuiTableCell align='center' style={{ width: 30 }}>
          {(noSelectHover || isShown || checked) && (
            isSelectOne ?
              <Checkbox
                checked={checked}
                onChange={(event) => setChecked(event.target.checked)}
                indeterminate={indeterminate}
                disabled={disabled}
                icon={<RadioButtonUnchecked />}
                checkedIcon={<RadioButtonChecked />}
              /> :
              <Checkbox
                checked={checked || someChecked}
                onChange={(event) => setChecked(event.target.checked)}
                indeterminate={indeterminate}
                disabled={disabled}
                checkedIcon={checked ? <CheckedIcon /> : <PartialCheckedIcon />}
              />
          )}
        </MuiTableCell>
      }
      {props.children}
    </MuiTableRow>
  );
};

interface FirstTableCellProps extends TableCellProps {
  minWidth?: number;
  fixed?: boolean;
  nowrap?: boolean;
  headerFixed?: boolean;
}

export const HeaderCell = ({ children, minWidth, fixed, nowrap, style, ...props }: FirstTableCellProps ) => {
  const classes = useStyles();

  return (
    <MuiTableCell className={fixed ? classes.fixed : ''} style={{ minWidth, whiteSpace: nowrap ? 'nowrap' : undefined, ...style }} {...props}>
      <div className={fixed ? classes.border : ''}>
        {children}
      </div>
    </MuiTableCell>
  );
};

export const BodyCell = ({ children, minWidth, fixed, nowrap, style, ...props }: FirstTableCellProps ) => {
  const classes = useStyles();

  return (
    <MuiTableCell className={fixed ? classes.fixed : ''} style={{ minWidth, whiteSpace: nowrap ? 'nowrap' : undefined, ...style }} {...props}>
      {fixed ? (
        <div className={classes.border}>
          {children}
        </div>
      ) : 
        children
      }
    </MuiTableCell>
  );
};

export { TableHead, TableBody };
