import React from 'react';
import { useStyles } from './addButton.styles';
import { Typography } from '@mui/material';
import Modal from '../../../../../components/Modal/Modal.component';
import
WarningRectangle
  from '../../../../../components/WarningRectangle/WarningRectangle.component';

const left = 'https://sked-v2.s3.amazonaws.com/images/hide-prof-name-img-left.png';
const right = 'https://sked-v2.s3.amazonaws.com/images/hide-prof-name-img-right.png';
const arrowUrl = 'https://sked-v2.s3.amazonaws.com/images/hand-drawn-arrow.png';

const ExplainGraphic = () => {
  const classes = useStyles();
  const second = 'Shown';
  const first = 'Hidden';
  return (
    <div className={classes.graphicGrid}>
      <div className={classes.graphicHeader} style={{
        justifyContent: 'space-between',
        marginRight: 20,
        marginTop: 15,
        marginBottom: -10,
      }}>
        <b>{first}</b>
        <img className={classes.arrow} src={arrowUrl} height={25}/>
      </div>
      <div className={classes.graphicHeader} style={{
        marginTop: 15,
        marginBottom: -10,
      }}>
        <b>{second}</b>
      </div>
      <img
        src={left}
        style={{ marginLeft: -10 }}
        className={classes.image}/>
      <img
        src={right}
        style={{ marginLeft: -10 }}
        className={classes.image}/>
    </div>
  );
};

type HideProfessionalProps = {
  open: boolean;
  onClose: () => void;
}

const HideProfessional = ({ open, onClose }: HideProfessionalProps) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Hiding Professional Names'
      className={'sked-test-npp-hiding-professional-names-modal'}
      innerClassName={classes.mobileModalWithRadius}
      maxWidth={452}
    >
      <div>
        <div className={classes.notselectable}>
          <Typography className={classes.proCons}>
              If there are multiple appointment types, use this setting to hide the provider names. Notice the left list doesn't show names, while the right one does.
          </Typography>
          <ExplainGraphic />
          <Typography className={classes.proCons}>
              Some offices with multiple providers use this so patients don't build a provider preference.
          </Typography>
          <WarningRectangle
            open={true}
            title='Make the appointment type names different'
            body={'Patients may get confused by which appointment type to select if the provider names are hidden and the appointment types have identical names.'}
            className={classes.warningMargin}
            type='warning'
          />
        </div>
      </div>
    </Modal>
  );
};

export default HideProfessional;
