import React, { useState } from 'react';
import {
  IconButton,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Delete';
import { LocalDate } from '@js-joda/core';

import { popup } from '../../../../services/Popup.js';
import api from '../../../../services/api.js';
import { format } from '../../../../services/joda.js';
import { caseType } from '../../../../services/utilities.js';
import ConfirmationDialog from '../../../../components/ConfirmationDialog/ConfirmationDialog.component';
import Loading from '../../../../components/Loading/Loading.component';
import { TableContainer, HeaderCell, BodyCell } from '../../../../components/CustomTable';
import { Client } from '../../clients.types';

const parseBirthday = (birthday: string) => {
  try {
    return format(LocalDate.parse(birthday), 'MM/dd/yyyy');
  } catch (e) {
    return '';
  }
};

type RowProps = {
  editClient: (c: Client) => void;
  clearClient: (c: Client) => void;
  ehr: string;
  client: Client
  onCurrent: (c: Client) => void;
  admin?: boolean;
}

const RowContainer = ({
  editClient, clearClient, ehr, client, onCurrent, admin
}: RowProps) => {
  return (
    <TableRow>
      <BodyCell fixed>
        <a onClick={() => onCurrent(client)}>
          {client.firstName} {client.lastName} {caseType(client)}
        </a>
      </BodyCell>
      <BodyCell>
        {client.birthday &&
          parseBirthday(client.birthday)}
      </BodyCell>
      <BodyCell>
        {client.email}
      </BodyCell>
      <BodyCell>
        {client.phone}
      </BodyCell>
      {(ehr === 'None' || admin) && (
        <BodyCell>
          {ehr === 'None' && (
            <>
              <IconButton
                size="small"
                onClick={() => editClient(client)}>
                <EditIcon />
              </IconButton>
              &nbsp;
            </>
          )}
          {admin && (
            <IconButton
              size="small"
              title="Remove client file"
              onClick={() => clearClient(client)}>
              <ClearIcon />
            </IconButton>
          )}
        </BodyCell>
      )}
    </TableRow>
  );
};

type Props = {
  clients: Client[];
  refreshClients: () => void;
  editClient: (c: Client) => void;
  onCurrent: (c: Client) => void;
  busy: boolean;
  headerHeight: number;
  ehr: string;
  admin: boolean;
  page: number;
  perPage?: number;
  setPerPage?: (v: number) => void;
  totalPages: number;
  totalCount: number;
  addPage: (v: number) => void;
  currentLength: number;
  filterHeight: number;
}

const ClientsTable = (props: Props) => {
  const [selected, setSelected] = useState<Client>({} as Client);
  const [loading, setLoading] = useState(false);
  const clearData = async () => {
    const data = {
      allowSMS: false,
      allowEmail: false,
      status: 'Inactive',
      firstName: '',
      lastName: '',
      referrer: '',
      birthday: { Unset: [] as string[] },
      email: { Unset: [] as string[] },
      phone: { Unset: [] as string[] },
    };
    try {
      setLoading(true);
      setSelected({} as Client);
      await api.put(`client/${selected.id}`, data);
      setLoading(false);
      props.refreshClients();
    } catch (error) {
      setLoading(false);
      popup('Error!', 'Failed to remove client data. please try again.');
      console.log(error);
    }
  };

  return (
    <div>
      <Loading loading={loading || props.busy} vh={50} />
      {props.clients &&
        <TableContainer
          fullPage
          checkHasScroll={loading || props.busy}
          headerFixed
          maxHeight={`calc(100vh - ${props.headerHeight}px - 45px)`}
          style={{ marginLeft: -20, marginRight: -20 }}
          pagination
          paginationData={{
            addPage: props.addPage,
            page: props.page,
            perPage: props.perPage,
            setPerPage: props.setPerPage,
            totalPages: props.totalPages,
            totalCount: props.totalCount,
            currentLength: props.currentLength,
          }}
        >
          <TableHead>
            <TableRow>
              <HeaderCell fixed minWidth={180}>Name</HeaderCell>
              <HeaderCell>Birthday</HeaderCell>
              <HeaderCell>Email</HeaderCell>
              <HeaderCell>Phone</HeaderCell>
              {(props.ehr === 'None' || props.admin) &&
                <HeaderCell>Actions</HeaderCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.clients.map((client) =>
              <RowContainer
                key={client.id}
                admin={props.admin}
                client={client}
                ehr={props.ehr}
                editClient={props.editClient}
                onCurrent={props.onCurrent}
                clearClient={setSelected}
              />
            )}
          </TableBody>
        </TableContainer>
      }
      {!props.clients &&
        <div>No Clients</div>
      }
      <ConfirmationDialog
        open={!!selected.id}
        cancel={() => setSelected({} as Client)}
        title="Remove Client File?"
        text={`Are you sure you want to remove the client file for  ${selected.firstName} ${selected.lastName}?`}
        confirm={clearData}
        confirmText="Confirm"
        className='sked-test-client-list-remove-modal'
      />
    </div>
  );
};

export default ClientsTable;
