import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: 30,
    paddingTop: 20,
    minHeight: 100,

    '& > h3': {
      margin: 0,
      padding: 0,
      height: 'auto',
      marginBottom: 15,
      fontWeight: 400,
    },
  },
}));
