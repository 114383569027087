import * as at from '../../../../actionTypes';
import { remoteAction } from '../../../../services/actionService.js';
import api from '../../../../services/api.js';


export const patch = (prop, value) => ({
  type: at.SETTINGS_APP_PATCH,
  data: {
    [prop]: value,
  },
});


export const claPatch = (prop, value) => ({
  type: at.SETTINGS_APP_CLA_PATCH,
  data: {
    [prop]: value,
  },
});

export const saveCLA = (data) => remoteAction({
  type: at.SETTINGS_APP_SAVE_CLA,
  action: () => api.post('cla/settings', data)
});

export const getCLASettings = () =>
  remoteAction({
    type: at.SETTINGS_APP_GET_CLA,
    action: () => api.get('cla/settings'),
  });

export const getCLAMatches = ({ page = 1, perPage = 25 }) => api.get('/cla/patient/match?page=' + page + '&perPage=' + perPage);

export const postCLAMatch = (data) => api.post('/cla/patient/match', data);
