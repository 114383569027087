import React, { useState, useEffect } from 'react';
// import { useSelector } from 'react-redux';
import {
  Button, CircularProgress, IconButton, List, ListItem,
  ListItemText, Menu, MenuItem, ListItemIcon,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import RestoreIcon from '@mui/icons-material/Restore';
import { Row } from '../PageHeader';
import { usePromise } from '../../services/promise.hook';
import api from '../../services/api';
import { ThreadEditor } from '../Editor/editor.component';
import { tzParseFormat } from '../../services/joda.js';
import { prepend, update, pathOr, remove } from 'ramda';

const useStyles = makeStyles((theme) => ({
  listRoot: {
    width: '100%',
    maxWidth: 200,
    backgroundColor: theme.palette.background.paper,
  },
  noteContainer: {
    margin: '10px',
  },
  saveButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  saveButton: {
    marginTop: '5px',
    marginRight: '5px',
  },
  noteItem: {
    borderTop: '1px gray solid',
    width: '100%',
    minHeight: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  noteBody: {
    width: '95%',
    whiteSpace: 'pre-wrap',
  },
}));

// TODO: Use Client type
interface SimpleNotesProps {
  client: any;
  tz: string;
  open: boolean;
  tab?: string;
  setTab?: (value: string) => void;
}

interface GetNotesQuery {
  clientId: number;
  page: number;
  perPage: number;
  archived?: boolean;
}

interface NotesProps {
  open: boolean;
  clientId: number;
  tz: string;
  archived?: boolean;
}

interface Note {
  note: string;
  created: string;
  id: number;
  archived?: string;
  madeBy: number;
}

interface NoteQuery {
  perPage: number;
  totalCount: number;
  totalPages: number;
  data: Note[];
}

interface SaveNote {
  clientId: number;
  note: string;
  id?: number;
}

interface NoteItemProps {
  note: Note;
  tz: string;
  archived?: boolean;
  onEdit: (note: Note) => void;
  onDelete: (note: Note) => void;
  onArchive: (note: Note) => void;
}

interface ArchiveNote {
  id: number;
  archive: boolean;
}

const items = ['Notes', 'Archived Notes'];

const getNotes = ({ clientId, page, perPage, archived }: GetNotesQuery): Promise<NoteQuery> => {
  const query = {
    page,
    perPage,
    query: {
      clientId,
      archived,
    },
  };
  return api.post('office/simpleNotes/query', query);
};

const saveNote = (body: SaveNote): Promise<Note> => {
  if (body.id !== null)
    return api.put(`office/simpleNotes/${body.id}`, body);
  return api.post('office/simpleNotes', body);
};

const deleteNote = (id: number): Promise<void> => {
  return api.delete(`office/simpleNotes/${id}`, { data: {} });
};

const archiveNote = ({ id, archive }: ArchiveNote): Promise<void> => {
  return api.post(`office/simpleNotes/${id}/archive`, { shouldArchive: archive });
};


const defaultQuery = {
  perPage: 25,
  totalCount: 0,
  totalPages: 0,
  data: [] as Note[],
};

const NoteItem = ({ note, tz, archived, onEdit, onDelete, onArchive }: NoteItemProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <div className={classes.noteItem}>
      <div className={classes.noteBody}>
        <i style={{ fontSize: '10px' }}>
          {tzParseFormat(note.created, tz, 'MM/dd/yyyy h:mm a')}
          {note.archived && ('  (Archived: ' + tzParseFormat(note.archived, tz, 'MM/dd/yyyy h:mm a') + ')')}
        </i>
        <br />
        {note.note}
      </div>
      <div>
        <IconButton onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
      </div>
      <Menu
        style={{ width: 'auto' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        id="note-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {!archived &&
          <MenuItem
            onClick={() => {
              onEdit(note);
              setAnchorEl(null);
            }}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary='Edit' />
          </MenuItem>}
        {archived &&
          <MenuItem
            onClick={() => {
              onArchive(note);
              setAnchorEl(null);
            }}>
            <ListItemIcon>
              <RestoreIcon />
            </ListItemIcon>
            <ListItemText primary='Restore' />
          </MenuItem>}
        {!archived &&
          <MenuItem
            onClick={() => {
              onArchive(note);
              setAnchorEl(null);
            }}>
            <ListItemIcon>
              <ArchiveIcon />
            </ListItemIcon>
            <ListItemText primary='Archive' />
          </MenuItem>}
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            onDelete(note);
          }}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary='Delete' />
        </MenuItem>
      </Menu>
    </div>
  );
};

const Notes = ({ open, clientId, tz, archived }: NotesProps) => {
  const classes = useStyles();
  const notesState = usePromise<GetNotesQuery, NoteQuery>(getNotes, defaultQuery);
  const saveNoteState = usePromise<SaveNote, Note>(saveNote, null);
  const deleteNoteState = usePromise<number, void>(deleteNote, null);
  const archiveNoteState = usePromise<ArchiveNote, void>(archiveNote, null);
  const [note, setNote] = useState('');
  const [emoji, setEmoji] = useState<any>(null);
  const [page] = useState(1);
  const [perPage] = useState(500);
  const [isEditting, setIsEditting] = useState<number[]>(null);
  const editorRef = React.useRef(null);

  const handleSave = () => {
    saveNoteState.invoke({
      clientId,
      note,
      id: pathOr(null, [1], isEditting),
    }).then((newNote: Note) => {
      if (isEditting) {
        notesState.setState({
          ...notesState,
          data: {
            ...notesState.data,
            data: update(isEditting[0], newNote, notesState.data.data),
          },
        });
        setIsEditting(null);
      } else {
        notesState.setState({
          ...notesState,
          data: {
            ...notesState.data,
            data: prepend(newNote, notesState.data.data),
          },
        });
      }
      setNote('');
    });
  };

  useEffect(() => {
    if (open) {
      notesState.invoke({
        clientId,
        page,
        perPage,
        archived,
      });
    }
  }, [open]);

  const onEdit = (idx: number) => (n: Note) => {
    setNote(n.note);
    setIsEditting([idx, n.id]);
  };

  const onDelete = (idx: number) => (n: Note) => {
    deleteNoteState.invoke(n.id).then(() => {
      notesState.setState({
        ...notesState,
        data: {
          ...notesState.data,
          data: remove(idx, 1, notesState.data.data),
        },
      });
    });
  };

  const onArchive = (idx: number) => (n: Note) => {
    archiveNoteState.invoke({
      id: n.id,
      archive: !n.archived,
    }).then(() => {
      notesState.setState({
        ...notesState,
        data: {
          ...notesState.data,
          data: remove(idx, 1, notesState.data.data),
        },
      });
    });
  };

  useEffect(() => {
    if (isEditting) {
      editorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isEditting]);

  return (
    <div className={classes.noteContainer}>
      {!archived &&
        <ThreadEditor
          body={note}
          disabled={false}
          messagesPatch={(b: string) => setNote(b)}
          patch={(key: any, value: any) => setEmoji(value)}
          emoji={emoji}
          defaultBody=''
          baseCharacterCount={0}
          nocharacter
          label={isEditting ? 'Edit Note' : 'New Note'}
          emojiDirection='down'
          editorRef={editorRef}
        />}
      <div className={classes.saveButtonContainer}>
        {!archived &&
          <Button
            variant='contained'
            disabled={saveNoteState.loading}
            onClick={handleSave}
            className={classes.saveButton}
          >
            {saveNoteState.loading ? 'Loading...' : 'Save'}
          </Button>}
        {(saveNoteState.loading || deleteNoteState.loading || archiveNoteState.loading) &&
          <CircularProgress />}
      </div>
      {(saveNoteState.errorMessage || deleteNoteState.errorMessage || archiveNoteState.errorMessage) &&
        <>
          <br />
          <p style={{ color: 'red' }}>
            {(saveNoteState.errorMessage || deleteNoteState.errorMessage || archiveNoteState.errorMessage)}
          </p>
        </>}
      <br />
      {notesState.data.data.map((note, idx) => {
        return (
          <NoteItem
            note={note}
            tz={tz}
            archived={archived}
            onEdit={onEdit(idx)}
            onDelete={onDelete(idx)}
            onArchive={onArchive(idx)}
          />
        );
      })}
    </div>
  );
};

export default function SimpleNotes({ client, open, tz, tab, setTab }: SimpleNotesProps) {
  const classes = useStyles();
  const [page, setPage] = React.useState('Notes');

  const currentTab = tab ? tab : page;
  const setCurrentTab = setTab ? setTab : setPage;

  return (
    <Row style={{ alignItems: 'start' }}>
      {!tab && (
        <div className={classes.listRoot}>
          <List component="nav" aria-label="simple notes list">
            {items.map(i => (
              <ListItem
                selected={currentTab === i}
                key={i}
                button
                onClick={() => setCurrentTab(i)}
              >
                <ListItemText primary={i} />
              </ListItem>))}
          </List>
        </div>
      )}
      <div style={{ width: '100%' }}>
        {open && currentTab === 'Notes' &&
          <Notes
            open={currentTab === 'Notes'}
            clientId={client.id}
            tz={tz}
            archived={false}
          />}
        {open && currentTab === 'Archived Notes' &&
          <Notes
            open={currentTab === 'Archived Notes'}
            clientId={client.id}
            tz={tz}
            archived
          />}
      </div>
    </Row>
  );
}
