import * as React from 'react';
import {
  TextField, FormControl, FormLabel, FormGroup, Checkbox, RadioGroup,
  Radio, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  SimpleWidgetShortText, SimpleWidgetLongText, SimpleWidgetRadio,
  SimpleWidgetDropdown, SimpleWidgetNumber, SimpleWidgetDate,
  SimpleWidgetCheckbox, SimpleWidgetCheckboxSingle, SimpleWidgetEmail,
} from '../intake-types';
import { has, isNil } from 'ramda';
import { useAnswerContext, useIntakeContextAnswer } from '../answers.context';
import {
  ShortTextAnswer, LongTextAnswer, EmailAnswer, DateAnswer, RadioAnswer,
  NumberAnswer, CheckboxAnswer, CheckboxSingleAnswer, DropdownAnswer,
} from '../answer-types';
import { format } from '../../../services/joda.js';
import { LocalDate } from '@js-joda/core';

interface TextProps {
  id: number;
  type: SimpleWidgetShortText |
  SimpleWidgetLongText |
  SimpleWidgetRadio |
  SimpleWidgetDate |
  SimpleWidgetDropdown |
  SimpleWidgetEmail;
  label?: string;
  required?: boolean;
  placeholder?: string;
  helperText?: string;
  noCtx?: boolean;
  isPrivate?: boolean;
}

type TextAnswer = ShortTextAnswer | LongTextAnswer | EmailAnswer | RadioAnswer | DropdownAnswer | DateAnswer;

const defaultTextAnswer = (type: 'LongText' | 'ShortText' | 'Email' | 'Dropdown' | 'Radio' | 'Date'): TextAnswer => ({
  id: 0,
  type,
  value: ''
});

const defaultNumberAnswer: NumberAnswer = {
  id: 0,
  type: 'Number',
  value: null,
};

const defaultCheckboxAnswer: CheckboxAnswer = {
  id: 0,
  type: 'Checkbox',
  value: []
};

const defaultCheckboxSingleAnswer: CheckboxSingleAnswer = {
  id: 0,
  type: 'CheckboxSingle',
  value: false,
};


const useStyles = makeStyles(() => ({
  label: {
    '& .MuiInputLabel-root': {
      whiteSpace: 'normal',
      position: 'relative',
      marginBottom: -12,
      transform: 'none',
      fontSize: 'calc(14px * 0.75)',
    }
  }
}));

const timeout: {[id: number]: any} = {};
export const TextInputComponent = (props: TextProps) => {
  const id = props.id;
  const ctx = useAnswerContext();
  const classes = useStyles();
  const [tempValue, setTempValue] = React.useState('');
  const [value, setValue] = props.noCtx ? [''] :
    useIntakeContextAnswer<TextAnswer>(id, defaultTextAnswer(props.type.type));
  let disabled = !props.noCtx || !props.isPrivate;
  if (props.isPrivate) {
    disabled = false;
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
    const v = e.target.value;
    setTempValue(v);
    const t = has('type', e.target) ? e.target.type : null;
    if (isNil(t) || (t !== 'textarea' && t !== 'text' && t !== 'number')) {
      setValue(v);
      //      ctx.autoSave();
    } else {
      if (timeout[id])
        clearTimeout(timeout[id]);
      timeout[id] = setTimeout(() => {
        setValue(v);
      }, 100);
    }
  };

  const handleBlur = () => {
    ctx.autoSave();
  };

  React.useEffect(() => {
    if (props.type.type === 'Date') {
      try {
        setTempValue(
          format(LocalDate.parse(value), 'MMM dd, yyyy')
        );
      } catch (e) {
        console.log(e);
      }
    } else {
      setTempValue(value);
    }
  }, [value]);

  if (props.type.type === 'ShortText') {
    return (
      <TextField
        style={{
          width: '100%',
          pageBreakInside: 'avoid',
        }}
        className={classes.label}
        InputLabelProps={{ shrink: true }}
        required={props.required}
        value={tempValue}
        label={props.label}
        disabled={disabled}
        placeholder={props.placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    );
  }
  if (props.type.type === 'LongText') {
    return (
      <TextField
        style={{
          width: '100%',
          pageBreakInside: 'avoid',
        }}
        className={classes.label}
        multiline
        minRows={props.noCtx ? 2 : 6}
        InputLabelProps={{ shrink: true }}
        required={props.required}
        value={tempValue}
        label={props.label}
        disabled={disabled}
        placeholder={props.placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    );
  }
  if (props.type.type === 'Date') {
    if (value && value.match(/(19|20)[0-9]{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/g)) {
      return (
        <TextField
          style={{
            width: '100%',
            pageBreakInside: 'avoid',
          }}
          className={classes.label}
          type="text"
          InputLabelProps={{ shrink: true }}
          required={props.required}
          value={tempValue}
          label={props.label}
          disabled={disabled}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      );
    }
    return (
      <TextField
        style={{
          width: '100%',
          pageBreakInside: 'avoid',
        }}
        className={classes.label}
        type="text"
        InputLabelProps={{ shrink: true }}
        required={props.required}
        value={tempValue}
        label={props.label}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    );
  }
  if (props.type.type === 'Radio') {
    return (
      <FormControl
        component="fieldset"
        required={props.required}
      >
        <FormLabel
          className="MuiInputLabel-shrink"
          component="legend" >
          {props.label}
        </FormLabel>
        <RadioGroup
          style={{
            flexDirection: props.type.direction || 'row',
          }}
          value={tempValue}
          aria-label={props.label}
          name={String(id)}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {props.type.options.map(o => (
            <FormControlLabel
              key={o}
              value={o}
              control={<Radio disabled={disabled} />}
              label={o}
            />
          ))}
        </RadioGroup>
      </FormControl>
    );
  }
  if (props.type.type === 'Dropdown') {
    return <FormControl style={{
      width: '100%',
      pageBreakInside: 'avoid',
    }}>
      <InputLabel shrink={true} id="1234">{props.label}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={tempValue || ''}
        disabled={disabled}
        displayEmpty={Boolean(props.placeholder)}
        onChange={handleChange}
        onBlur={handleBlur}
      >
        {props.type.options.map(o => (
          <MenuItem
            key={o}
            value={o}>
            {o}
          </MenuItem>))}
      </Select>
    </FormControl>;
  }
  if (props.type.type === 'Email') {
    return (
      <TextField
        type="email"
        style={{
          width: '100%',
          pageBreakInside: 'avoid',
        }}
        className={classes.label}
        InputLabelProps={{ shrink: true }}
        required={props.required}
        value={tempValue || ''}
        label={props.label}
        disabled={disabled}
        placeholder={props.placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    );
  }
};



interface NumberProps {
  id: number;
  type: SimpleWidgetNumber;
  label?: string;
  required?: boolean;
  placeholder?: string;
  helperText?: string;
  noCtx?: boolean;
  isPrivate?: boolean;
}

export const NumberInputComponent = (props: NumberProps) => {
  const id = props.id;
  const ctx = useAnswerContext();
  const classes = useStyles();
  const [tempValue, setTempValue] = React.useState(null);
  const [value, setValue] = props.noCtx ? [''] : useIntakeContextAnswer<NumberAnswer>(id, defaultNumberAnswer);
  let disabled = !props.noCtx || !props.isPrivate;
  if (props.isPrivate) {
    disabled = false;
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    try {
      const v = Number(e.target.value);
      if (timeout[id])
        clearTimeout(timeout[id]);
      timeout[id] = setTimeout(() => {
        setValue(v);
      }, 100);
    } catch (e) {
      alert('Please make sure you only input numbers into number input fields!');
    }
  };

  const handleBlur = () => {
    ctx.autoSave();
  };

  React.useEffect(() => {
    setTempValue(value);
  }, [value]);

  return (
    <TextField
      style={{ width: '100%' }}
      InputLabelProps={{ shrink: true }}
      className={classes.label}
      required={props.required}
      type="number"
      value={tempValue}
      label={props.label}
      disabled={disabled}
      placeholder={props.placeholder}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

interface CheckboxProps {
  id: number;
  type: SimpleWidgetCheckbox;
  label?: string;
  required?: boolean;
  placeholder?: string;
  helperText?: string;
  noCtx?: boolean;
  isPrivate?: boolean;
}

const ea: string[] = [];

export const CheckboxInputComponent = (props: CheckboxProps) => {
  const { id } = props;
  //  const ctx = useAnswerContext();
  const [value, setValue] = props.noCtx ? [ea] : useIntakeContextAnswer<CheckboxAnswer>(id, defaultCheckboxAnswer);
  let disabled = !props.noCtx || !props.isPrivate;
  if (props.isPrivate) {
    disabled = false;
  }
  const onChangeChecked = (label: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setValue((value || ea).concat([label]));
      return;
    }
    setValue((value || ea).filter((v: string) => v !== label));
  };

  const checkIsChecked = (label: string):boolean => !!(value || ea).find((v: string) => v === label);

  return (
    <FormControl
      component="fieldset"
      required={props.required}
      style={{
        pageBreakInside: 'avoid',
      }}
    >
      <FormLabel
        className="MuiInputLabel-shrink"
        component="legend">
        {props.label}
      </FormLabel>
      <FormGroup
        style={{
          flexDirection: props.type.direction || 'row',
          pageBreakInside: 'avoid',
        }}
        aria-label={props.label}>
        {value && props.type.options.map(o => (
          <FormControlLabel
            key={o}
            label={o}
            control={
              <Checkbox
                checked={checkIsChecked(o)}
                disabled={disabled}
                onChange={onChangeChecked(o)}
              />
            } />
        ))}
      </FormGroup>
    </FormControl>
  );
};


interface CheckboxSingleProps {
  id: number;
  type: SimpleWidgetCheckboxSingle;
  label?: string;
  required?: boolean;
  placeholder?: string;
  helperText?: string;
  noCtx?: boolean;
  isPrivate?: boolean;
}

export const CheckboxSingleInputComponent = (props: CheckboxSingleProps) => {
  const { id } = props;
  //  const ctx = useAnswerContext();
  const [value, setValue] = props.noCtx ? [false] : useIntakeContextAnswer<CheckboxSingleAnswer>(id, defaultCheckboxSingleAnswer);
  let disabled = !props.noCtx || !props.isPrivate;
  if (props.isPrivate) {
    disabled = false;
  }
  const onChangeChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.checked);
  };

  const label = props.label + (props.required ? '*' : '');

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          disabled={disabled}
          onChange={onChangeChecked}
        />
      }
      label={label}
    />
  );
};
