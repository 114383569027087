import React from 'react';

type Props = {
  color?: string;
  barColor?: string;
  className?: string;
};

const FormatTextColorIcon = ({ color, barColor, className }: Props) => {
  return (
    <svg className={className} id="formattextcolor" data-name="Layer 1" version="1.1" viewBox="0 0 24 24">
      <rect style={{ strokeWidth: 0 }} fill={barColor || color || '#000'} x="2" y="20" width="20" height="4"/>
      <path style={{ strokeWidth: 0 }} fill={color || '#000'} d="M13.2,3h-2.5l-5.2,14h2.4l1.3-3.6h5.6l1.2,3.6h2.4L13.2,3ZM9.9,11.4l2-5.8h0l2,5.8h-4.2Z"/>
    </svg>
  );
};

export default FormatTextColorIcon;
