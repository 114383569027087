import * as at from '../../../../actionTypes';
import { remoteAction } from '../../../../services/actionService.js';
import api from '../../../../services/api.js';
import * as R from 'ramda';
import { popup } from '../../../../services/Popup.js';

export const patch = (prop, value) => dispatch =>
  dispatch({
    type: at.WEBMODULE_PATCH,
    data: {
      [prop]: value,
    },
  });

export const setHideNameForPro = (pro, nameHidden) => dispatch => {
  const proUpdate = {
    firstName: pro.displayFirstName,
    lastName: pro.displayLastName,
    nameHidden,
  };

  return api.put(`professional/${pro.id}`, proUpdate).then(() => {
    const newPro = R.merge(pro, { nameHidden });
    return dispatch({
      type: at.WEBMODULE_PATCH_PRO,
      data: {
        pro: newPro,
      },
    });
  });
};

export const getTypesApi = (history) =>
  Promise.all([
    api.get('appointmentType'),
    api.get('professional'),
    api.get('appointmentType/app'),
  ]).then(([types, professionals, selected]) => {
    if (R.isEmpty(professionals)) {
      popup('Action Required!', 'You must first create professionals in the Professionals tab to continue!');
      return null;
    } else {
      return {
        types,
        professionals: R.filter(({ isHidden }) => !isHidden)(professionals),
        selectedTypes: R.map(({ id }) => id)(selected),
      };
    }
  }).catch((error) => {
    if (error.response) {
      console.error(error);
    } else {
      history.push('/offline');
    }
  });

export const getTypes = (history) =>
  remoteAction({
    type: at.WEBMODULE_REMOTE_GET_TYPES,
    action: () => getTypesApi(history),
  });

export const addType = ({ type }) => dispatch =>
  dispatch({
    type: at.WEBMODULE_ADD_TYPE,
    data: {
      type,
    },
  });

export const addAllTypes = ({ pros, type }) => dispatch => {
  if (type.name === 'All') {
    return dispatch({
      type: at.WEBMODULE_ADD_TYPES,
      data: {
        selectedTypes: R.pipe(
          R.map(pro => R.tail(pro.types)),
          R.flatten,
        )(R.tail(pros)),
        typeStatus: 'All',
      },
    });
  }
  return dispatch({
    type: at.WEBMODULE_ADD_TYPES,
    data: {
      selectedTypes: R.pipe(
        R.map(({ types }) => R.filter(typ => typ.name === type.name)(types)),
        R.flatten,
      )(R.tail(pros)),
      typeStatus: 'Some',
    },
  });
};

export const selectPro = pro => dispatch =>
  dispatch({
    type: at.WEBMODULE_PATCH,
    data: {
      selectedPro: pro,
    },
  });

export const selectNewAptPro = pro => dispatch =>
  dispatch({
    type: at.WEBMODULE_PATCH,
    data: {
      selectedNewAptPro: pro,
    },
  });

export const selectType = ({ type }) => dispatch =>
  dispatch({
    type: at.WEBMODULE_PATCH,
    data: {
      selectedType: type,
    },
  });

export const removeType = ({ index }) => dispatch => {
  return dispatch({
    type: at.WEBMODULE_REMOVE_TYPE,
    data: {
      index,
    },
  });
};

export const clearSelected = prop => dispatch =>
  dispatch({
    type: at.WEBMODULE_PATCH,
    data: {
      [prop]: [],
    },
  });

export const save = appointmentTypes => {
  return remoteAction({
    type: at.WEBMODULE_SAVE,
    action: () => api.post('appointmentType/app', appointmentTypes),
  });
};

export const getOfficeSettings = () =>
  remoteAction({
    type: at.SETTINGS_APP_GET_LIMITS,
    action: () => api.get('office/settings'),
  });

export const limitsPatch = (prop, value) => dispatch =>
  dispatch({
    type: at.SETTINGS_APP_LIMITS_PATCH,
    data: {
      [prop]: value,
    },
  });

export const saveLimits = (data, missedApptRemainInAppTime) => {
  const officeSettings = {
    allowSameDayScheduling: data.allowSameDayScheduling,
    cutoffContactSetting: data.cutoffContactSetting === 'None' ? 'Neither' : data.cutoffContactSetting,
    rescheduleLimit:
    data.rescheduleLimit ?
      { 'Set': Number(data.rescheduleLimit) }
      :
      { 'Unset': [] },
    futureScheduleLimit:
    data.futureScheduleLimit ?
      { 'Set': Number(data.futureScheduleLimit) }
      :
      { 'Unset': [] },
    missedApptRemainInAppTime:
    missedApptRemainInAppTime ?
      { 'Set': Number(missedApptRemainInAppTime) }
      :
      { 'Unset': [] },
  };
  return remoteAction({
    type: at.WEBMODULE_SAVE,
    action: () => api.put('office/settings', officeSettings),
  });
};

export const claPatch = (prop, value) => dispatch =>
  dispatch({
    type: at.SETTINGS_APP_CLA_PATCH,
    data: {
      [prop]: value,
    },
  });

export const saveCLA = (data) => remoteAction({
  type: at.WEBMODULE_SAVE,
  action: () => api.post('office/cla/settings', data)
});

export const getCLASettings = () =>
  remoteAction({
    type: at.SETTINGS_APP_GET_CLA,
    action: () => api.get('office/cla/settings'),
  });

export const appPatch = (prop, value) => dispatch =>
  dispatch({
    type: at.SETTINGS_APP_PATCH,
    data: {
      [prop]: value,
    },
  });
