import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  header: {
    borderBottom: '1px solid #ebe7e7',
    marginBottom: 10,
  },
  actionContainer: {
    padding: 20,
  },
  containerSelect: {
    display: 'flex',
    justifyContent: 'space-around',
    minHeight: 120,
  },
  card: {
    padding: 15,
    paddingTop: 5,
    minWidth: 240,
  },
  input: {
    marginBottom: 16,
  },
  cardList: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 150,
    maxHeight: 300,
    overflowY: 'auto',
    paddingRight: 5,
  },
  leftCard: {
    maxHeight: 350,
  },
  chip: {
    marginBottom: 8,
    justifyContent: 'flex-start',
    padding: 8,
    paddingLeft: 15,
    fontSize: 16,
    height: 32,
    fontWeight: 500,

    '& > span': {
      minWidth: 30,
    },

    '& :nth-child(2)': {
      flex: 1
    }
  },
  loading: {
    alignSelf: 'center',
  }
}));