import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const ApptStatusIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} id="uuid-9424298e-65e9-4b8a-b210-bb8fc8973bbf" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <g fill={color || '#FFF'}>
        <path d="m15.6442,1.8356c.0084,0,.0174-.0011.0258-.0012v-.0209l-.0258.0221Z" />
        <path d="m34.3978,1.8614c-.0009.0031-.0009.0063-.0017.0094.0055.0004.011.0012.0165.0016l-.0148-.011Z" />
        <path d="m49.7831,9.1094c-1.1119-5.1006-6.148-8.3341-11.2486-7.2222v.6999c0,1.5435.0295,3.0906,0,4.6378.0093.1174.0088.2355-.0015.3528-.1025,1.1673-1.132,2.0305-2.2993,1.928-1.1674-.1025-2.0305-1.1319-1.928-2.2993V2.3661c.0153-.1675.0461-.3331.0904-.4953-.2782-.0226-.5563-.0683-.8345-.0683h-17.1806c-.2453,0-.4797.0298-.711.032v5.3977c.0011.0187.0019.0374.0025.0562.0367,1.1835-.8929,2.1726-2.0764,2.2094-.0291-.0002-.0582-.001-.0873-.0024-1.1929-.057-2.1136-1.0702-2.0566-2.2631-.0295-1.8087-.0295-3.6136-.0295-5.4186v.0184c-1.8269-.1967-3.6747-.0238-5.4334.5083C1.9292,3.8558-.5249,7.9971.0954,12.2862h49.8326c.1315-1.0595.0824-2.1336-.145-3.1768Z" />
      </g>
      <path fill={color || '#FFF'} d="m49.9458,16.6366H.0358v22.8203c.014.7272.1243,1.4493.3279,2.1476,1.1234,4.0632,4.8737,6.8359,9.0875,6.7191,5.1793.0184,10.3623,0,15.5415,0h15.5414c4.214.1168,7.9642-2.6559,9.0875-6.7191.2036-.6982.314-1.4203.3279-2.1476.0197-6.3212.0197-12.6411,0-18.9598v-3.8605h-.0037Zm-11.688,7.6651c-.1727.2443-.3815.4667-.5938.679-5.1147,5.1146-10.2321,10.2267-15.3501,15.338-.188.1877-.376.3791-.5857.5405-.6655.5123-1.3782.5031-1.969-.0836-2.541-2.5234-5.0696-5.0593-7.5945-7.5986-.4718-.4744-.8093-1.0309-.8298-1.8153.032-.129.0505-.3491.1392-.5361.4697-.993,1.1995-1.7606,2.1781-2.2466.8646-.4294,1.7274-.184,2.5419.6378,1.3477,1.3597,2.688,2.7265,4.0334,4.0884.5791.5862.5771.58,1.1409.0165,4.1506-4.1484,8.3008-8.297,12.4558-12.4409,1.1239-1.121,2.2625-1.1287,3.4045-.038.3713.3546.7517.7028,1.0422,1.1326.5253.7769.5297,1.5585-.0132,2.3264Z" />
    </svg>
  );
};

export default ApptStatusIcon;
