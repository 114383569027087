import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    '& .MuiAlert-message': {
      fontSize: 16,
    }
  },
  close: {
    marginLeft: 10,

    '& .MuiSvgIcon-root': {
      fontSize: 20,
    }
  },
}));
