import * as at from '../../actionTypes';
import { merge, pathOr } from 'ramda';
import { LocalDate } from '@js-joda/core';

const calcChange = (value, previous) => {
  return previous > 0 ? Math.round((value - previous) / previous * 100) : 'N/A';
};

const calcPercent = (value, previous) => {
  return previous > 0 ? Math.round(value / previous * 100) : 'N/A';
};

const makeData = data => prop => {
  const value = pathOr('N/A', ['currentPeriod', 'stats', prop], data);
  const previous = pathOr('N/A', ['previousPeriod', 'stats', prop], data);
  const change = calcChange(value, previous);

  return {
    value,
    previous,
    change,
  };
};


const compareTwoStats = getData => (startProp = '', endProp = '') => {
  const start = getData(startProp);
  const end = getData(endProp);
  const value = calcPercent(end.value, start.value) + ' %';
  const previous = calcPercent(end.previous, start.previous) + ' %';
  return {
    value,
    previous,
    change: calcChange(value, previous)
  };
};


const makeStats = (data = {}) => {
  const getData = makeData(data);
  const getCompareStats = compareTwoStats(getData);

  return [
    {
      ...getData('newClients'),
      name: 'New Patients'
    },
    {
      ...getData('newClientsStartedCare'),
      name: 'New Patients that Started Care',
    },
    {
      ...getCompareStats('newClients', 'newClientsStartedCare'),
      name: 'New Patient Conversion',
    },
    {
      ...getData('totalVisitsScheduled'),
      name: 'Total Visits Scheduled'
    },
    {
      ...getData('totalVisitsArrived'),
      name: 'Total Visits Arrived',
    },
    {
      ...getCompareStats('totalVisitsScheduled', 'totalVisitsArrived'),
      name: 'Show up rate',
    },
    {
      ...getData('uniqueClientsSeen'),
      name: 'Unique Patients Seen',
    },
  ];
};

const init = {
  data: [],
  dateRange: { startDay: LocalDate.now().toString(), endDay: LocalDate.now().plusDays(1).toString() },
  loading: false,
  errorMessage: '',
  adjustmentApptTyIds: [],
  newPatientApptTyIds: [],
};

export default (state = init, action) => {
  switch (action.type) {
    case at.REMOTE_GET_REPORT_OVERVIEW: {
      if (action.state === 'REQUEST') {
        return merge(state, {
          loading: true,
          errorMessage: ''
        });
      }
      if (action.state === 'RESPONSE') {
        return merge(state, {
          loading: false,
          data: makeStats(action.data),
          dateRange: pathOr({}, ['data', 'currentPeriod', 'dateRange'], action)
        });
      }
      if (action.state === 'ERROR') {
        return merge(state, {
          loading: false,
          errorMessage: action.error.message
        });
      }
      return state;
    }
    case at.REMOTE_GET_REPORT_OVERVIEW_SETTINGS: {
      if (action.state === 'REQUEST') {
        return merge(state, {
          settingsErrorMessage: ''
        });
      }
      if (action.state === 'RESPONSE') {
        return merge(state, action.data);
      }
      if (action.state === 'ERROR') {
        return merge(state, {
          settingsErrorMessage: 'Error getting the settings:' + action.error.message
        });
      }
      return state;
    }
    default: {
      return state;
    }
  }
};
