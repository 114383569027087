import { AnyAction } from 'redux';
import * as at from '../../../../actionTypes';
import * as R from 'ramda';

export type AttachmentType = {
  attachmentId: number;
  attachmentMimeType?: string;
  attachmentName?: string;
  attachmentUrl?: string;
}
export type Email = {
  subject: string;
  body: string;
  html: string;
  attachments: AttachmentType[];
  shouldFrame: boolean;
  addCalendarEvent?: boolean;
}
export type Push = {
  subject: string;
  body: string;
}
export type Sms = {
  body: string;
  subject?: string;
  attachments: {
    attachmentId: number
  }[];
}

type TemplateEditReducer = {
  state: string;
  id?: number;
  busy: boolean;
  filter: string;
  value: number;
  value2: number;
  unit: { name: string; id: number };
  unit2: { name: string; id: number };
  beforeAfter: string;
  isEmail: boolean;
  isSMS: boolean;
  isPush: boolean;
  isEnabled: boolean;
  messageName: string;
  subject: string;
  body: string;
  email: Email;
  sms: Sms;
  push: Push;
  templateType: string;
  changeType: string[];
  source: string[];
  sendAt: string;
  includeInactive: boolean;
  status: string;
  forFeature: string;
  features: string[];
  creatingPath?: boolean;
  justSentMsgId?: number;
  selectedTypes?: number[];
}

const init: TemplateEditReducer = {
  state: 'CREATE',
  id: null,
  busy: false,
  filter: 'DateRange',
  value: 1,
  value2: 1,
  unit: { name: 'Day', id: 2 },
  unit2: { name: 'Day', id: 2 },
  beforeAfter: 'Before',
  isEmail: false,
  isSMS: false,
  isPush: false,
  isEnabled: false,
  messageName: '',
  subject: '',
  body: '',
  email: {
    subject: '',
    body: '',
    html: null,
    attachments: [],
    shouldFrame: false,
  },
  sms: {
    body: '',
    attachments: [],
  },
  push: {
    subject: '',
    body: '',
  },
  templateType: 'OneTime',
  changeType: [],
  source: [],
  sendAt: '08:00:00',
  includeInactive: false,
  status: 'LOAD',
  /*
    `forFeature` is Either 'HtmlEmail' 'NewMessaging'.
    HtmlEmail means it goes to offices with HTML enabled.
    NewMessaging means otherwise.
 */
  forFeature: 'HtmlEmail',
  features: [
    'SkedApp',
    'HtmlEmail',
    'NewMessaging',
    'HtmlEmail',
    'ApptChangeMessages',
    'ApptChangeMessagesPro',
  ],
  creatingPath: false,
};

export default function reducer(templateEdit = init, action: AnyAction): TemplateEditReducer {
  switch (action.type) {
    case at.TEMPLATE_EDIT_PATCH: {
      return R.merge(templateEdit, action.data);
    }
    case at.TEMPLATE_EDIT_REMOVE_TYPE: {
      return R.evolve({
        selectedTypes: R.remove(action.data.index, 1),
        typeStatus: () => 'Some',
      }, templateEdit) as TemplateEditReducer;
    }
    case at.TEMPLATE_EDIT_RESET:
      return R.merge(templateEdit, init);
    case at.TEMPLATE_EDIT_REMOTE_GET:
      if (action.state === 'REQUEST') {
        return R.merge(templateEdit, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.mergeAll([
          templateEdit,
          action.data,
          {
            busy: false,
            status: 'CHECK_ERRORS',
            features: action.data.forFeature === 'HtmlEmail' ?
              templateEdit.features :
              R.without(['HtmlEmail'], templateEdit.features)
          }
        ]);
      } else if (action.state === 'ERROR') {
        return R.merge(templateEdit, {
          busy: false,
        });
      }
      return templateEdit;
    case at.TEMPLATE_EDIT_SEND:
      if (action.state === 'REQUEST') {
        return R.merge(templateEdit, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        console.log(action.data);
        return R.merge(templateEdit, {
          state: 'SENT',
          busy: false,
          justSentMsgId: action.data.id,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(templateEdit, {
          busy: false,
        });
      }
      return templateEdit;
    case at.TEMPLATE_EDIT_SAVE_INTO_PATH:
      if (action.state === 'REQUEST') {
        return R.merge(templateEdit, {
          busy: true,
          creatingPath: true,
        });
      } else if (action.state === 'RESPONSE') {
        console.log(action.data);
        return R.merge(templateEdit, {
          busy: false,
          creatingPath: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(templateEdit, {
          busy: false,
          creatingPath: false,
        });
      }
      return templateEdit;
    default:
      return templateEdit;
  }
}
