import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 12,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  formControl: {
    width: 200,
  },
  inputLabel: {
    fontSize: 14,
    marginBottom: 5,
    fontWeight: 500,
  },
  date: {
    width: '100%',

    '& .MuiInputBase-root': {
      borderRadius: 10
    }
  },
}));
