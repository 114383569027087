import api from '../../services/api.js';
import * as at from '../../actionTypes';
import { remoteAction } from '../../services/actionService.js';
import axios from 'axios';

export const getTags = () => remoteAction({
  type: at.TAGS_REMOTE_GET,
  action: () => api.get('client/tag'),
});

const CancelToken = axios.CancelToken;
let clientCancel;
let leadsCancel;
let selectedClientCancel;
let selectedLeadsCancel;

export const getClients = ({ query, page, search } = { query: {}, page: 1, search: '' }) => {
  if (clientCancel)
    clientCancel('User aborted request.');
  return remoteAction({
    type: at.TAGS_REMOTE_CLIENTS_GET,
    action: () => api.post('client/query', {
      perPage: 25,
      page,
      query: {
        ...query,
        sortBy: [{
          direction: 'Asc',
          field: 'LastName'
        }, {
          direction: 'Asc',
          field: 'FirstName'
        }],
        isLead: false
      },
    }, {
      cancelToken: new CancelToken((c) => {
        clientCancel = c;
      })
    }).then((clientsPageData) => {
      return {
        ...clientsPageData,
        page,
        search,
      };
    }),
  });
};

export const getLeads = ({ query, page, search } = { query: {}, page: 1, search: '' }) => {
  if (leadsCancel)
    leadsCancel('User aborted request.');
  return remoteAction({
    type: at.TAGS_REMOTE_LEADS_GET,
    action: () => api.post('client/query', {
      perPage: 25,
      page,
      query: {
        ...query,
        sortBy: [{
          direction: 'Desc',
          field: 'Created'
        }],
        isLead: true
      },
    }, {
      cancelToken: new CancelToken((c) => {
        leadsCancel = c;
      })
    }).then((leadsPageData) => {
      return {
        ...leadsPageData,
        page,
        search,
      };
    }),
  });
};

export const getSelectedClients = ({ query, page, search, hide } = { query: {}, page: 1, search: '' }) => {
  if (selectedClientCancel)
    selectedClientCancel('User aborted request.');
  return remoteAction({
    type: at.TAGS_REMOTE_TAGGED_CLIENTS_GET,
    action: () => api.post('client/query', {
      perPage: 25,
      page,
      query: {
        ...query,
        sortBy: [{
          direction: 'Asc',
          field: 'LastName'
        }, {
          direction: 'Asc',
          field: 'FirstName'
        }],
        isLead: false
      },
    }, {
      cancelToken: new CancelToken((c) => {
        selectedClientCancel = c;
      })
    }).then((clientsPageData) => {
      return {
        ...clientsPageData,
        page,
        search,
        hide,
      };
    }),
  });
};
export const getSelectedLeads = ({ query, page, search, hide } = { query: {}, page: 1, search: '' }) => {
  if (selectedLeadsCancel)
    selectedLeadsCancel('User aborted request.');
  return remoteAction({
    type: at.TAGS_REMOTE_TAGGED_LEADS_GET,
    action: () => api.post('client/query', {
      perPage: 25,
      page,
      query: {
        ...query,
        sortBy: [{
          direction: 'Desc',
          field: 'Created'
        }],
        isLead: true
      },
    }, {
      cancelToken: new CancelToken((c) => {
        selectedLeadsCancel = c;
      })
    }).then((leadsPageData) => {
      return {
        ...leadsPageData,
        page,
        search,
        hide,
      };
    }),
  });
};


export const addTags = (tags) => remoteAction({
  type: at.TAGS_REMOTE_ADD_TAGS_POST,
  action: () => api.post('client/tag/add', { tags }),
});


export const update = (data) => ({
  type: at.TAGS_PATCH,
  data,
});

export const deleteTag = (id) => remoteAction({
  type: at.TAGS_REMOTE_TAG_DELETE,
  action: () => api.delete(`client/tag/${id}`, { data: {} }).then(() => {
    return id;
  }),
});

export const addTagsToClient = (client, tagIds, update) => remoteAction({
  type: at.TAGS_REMOTE_ADD_TAG_TO_CLIENT_POST,
  action: () => api.post('client/tag', { clients: [client.id], tagIds }).then(() => {
    update({ snackbar: { isOpen: true, memo: 'Client tagged!' } });
    return client;
  }).catch(() => {
    update({ snackbar: { isOpen: true, memo: 'ERROR: Client already tagged with selected tag(s)!' } });
    // ignore the error
    return null;
  }),
});

export const addTagsToLead = (client, tagIds, update) => remoteAction({
  type: at.TAGS_REMOTE_ADD_TAG_TO_LEAD_POST,
  action: () => api.post('client/tag', { clients: [client.id], tagIds }).then(() => {
    update({ snackbar: { isOpen: true, memo: 'Lead tagged!' } });
    return client;
  }).catch(() => {
    update({ snackbar: { isOpen: true, memo: 'ERROR: Lead already tagged with selected tag(s)!' } });
    // ignore the error
    return null;
  }),
});

export const removeTagsFromClient = (client, tagIds) => remoteAction({
  type: at.TAGS_REMOTE_REMVOE_TAG_TO_CLIENT_DELETE,
  action: () => api.delete('client/tag/client', { data: { clients: [client.id], tagIds } }).then(() => {
    return client;
  }),
});

export const removeTagsFromLead = (client, tagIds) => remoteAction({
  type: at.TAGS_REMOTE_REMVOE_TAG_TO_LEAD_DELETE,
  action: () => api.delete('client/tag/client', { data: { clients: [client.id], tagIds } }).then(() => {
    return client;
  }),
});

export const sortTags = (data) => ({
  type: at.TAGS_SORT_TAGS,
  data,
});

export const resetPage = () => ({
  type: at.TAGS_INIT,
});

export const updateTag = (tag) => remoteAction({
  type: at.TAGS_REMOTE_UPDATE_TAG_PUT,
  action: () => api.put(`client/tag/${tag.tagId}`, { tag: tag.tag }).then(() => {
    return tag;
  }),
});
