import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  selectedTypesContainer: {
    backgroundColor: 'white',
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px',
  },
  proNameContainer: {
    height: '30px',
    display: 'grid',
    gridTemplateColumns: '3fr 1fr',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    paddingLeft: '10px',
    borderBottom: 'lightgray 1px solid',
    borderTop: 'lightgray 1px solid',
    boxShadow: '1px 3px 10px #00000029',
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  nameExpander: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  expand: {
    color: 'black',
    fontSize: '14px',
    marginLeft: '5px',
  },
  collapse: {
    backgroundColor: 'white',
    padding: '10px',
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    '& .MuiCollapse-wrapperInner': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '10px',
    },
  },
  autocompleteClass: {
    marginBottom: 'unset',
    backgroundColor: 'white',
    position: 'absolute',
    top: '-1px',
    left: '-1px',
    width: 'calc(100% + 2px)',
    zIndex: 1000,
  },
  headerGroup: {
    cursor: 'pointer',
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 16,
  },
  selectedHeaderGroup: {
    cursor: 'pointer',
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 16,
  },
  expandButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  autoItem: {
    backgroundColor: 'white',
    cursor: 'pointer',
    padding: '6px 16px',
    color: '#697275',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.04)',
    },
  },
  selectedAutoItem: {
    color: '#37AEFD',
    backgroundColor: '#EEF5FF',
    cursor: 'pointer',
    padding: '6px 16px',
    '&:hover': {
      backgroundColor: '#DFEDFB',
    },
  },
  expandMore: {
    height: 12,
    width: 12,
  },
}));
