import api from '../../../services/api';

import * as R from 'ramda';

import { User, Office } from './users.types';

export const getUsers = async (isOffice: boolean, userId: number): Promise<User[]> => {
  try {
    const url = isOffice ? 'office/users' : 'agency/agents';
    const users: User[] = await api.get(url);
    const filteredUsers = users.filter(user => {
      if (isOffice) {
        return user.enabled;
      }
      return userId !== user.userId && user.enabled;
    });
    return R.sortBy(R.prop('name'), filteredUsers);
  } catch {
    return [];
  }
};

export const checkEmailIsTaken = async (email: string) => {
  try {
    const response: { isTaken: boolean } = await api.post('check/email', { email });
    return response.isTaken;
  } catch {
    return true;
  }
};

export const saveAgencyUser = async (email: string, name: string, agencyId: number) => {
  try {
    const user = await api.post('agency/agents', { email, name, agencyId, agentType: 'AgencyPeon' });
    return user;
  } catch {
    return false;
  }
};

export const saveOfficeUser = async (email: string, name: string) => {
  try {
    const user: User = await api.post('office/users', { email, name });
    return user;
  } catch {
    return null;
  }
};

export const deleteUser = async (id: number, isOffice: boolean) => {
  try {
    const url = isOffice ? 'office/users/peon' : 'agency/agents';
    await api.post(`${url}/${id}/disable`);
    return true;
  } catch {
    return false;
  }
};

export const editUser = async (id: number, name?: string, email?: string) => {
  try {
    await api.put(`user/${id}`, { name: name ? { Set: name } : undefined, email: email || undefined });
    return true;
  } catch {
    return false;
  }
};

type BodyResponse = {
  data: Office[];
  page: number;
  totalPages: number;
  totalCount: number;
}

type GetOfficesProps = {
  page?: number;
  search?: string;
}

export type GetOfficesResponse = {
  offices: Office[];
  page: number;
  totalPages: number;
  totalCount: number;
}

export const getFreeOffices = async ({ page, search }: GetOfficesProps): Promise<GetOfficesResponse> => {
  const body = {
    page: page || 1,
    perPage: 50,
    query: {
      name: search || '',
    }
  };
  const offices: BodyResponse = await api.post('office/query', body);
  const sortOffices = R.sortBy(R.prop('name'), offices.data);
  return {
    offices: sortOffices,
    page: offices.page,
    totalPages: offices.totalPages,
    totalCount: offices.totalCount,
  };
};

export const getUserOffices = async (id: number): Promise<Office[]> => {
  const offices = await api.get(`agency/agents/${id}/office`);
  return offices;
};

export const saveOffices = async (idsToAdd: number[], idsToRemove: number[], userId: number) => {
  const AddPromises = idsToAdd.map(item => {
    return api.post(`agency/agents/${userId}/office/${item}`);
  });
  const RemovePromises = idsToRemove.map(item => {
    return api.delete(`agency/agents/${userId}/office/${item}`);
  });

  try {
    await Promise.all([...AddPromises, ...RemovePromises]);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
