import {
  mergeAttributes, Node
} from '@tiptap/core';

export interface SpanOptions {
  HTMLAttributes: Record<string, any>,
}


declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    span: {
      /**
       * Add an style tag
       */
      setSpanTag: () => ReturnType,
    }
  }
}

export const SpanTag = Node.create<SpanOptions>({
  name: 'span',

  priority: 1000,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: 'block',

  content: 'block+',

  addAttributes() {
    return {
      class: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setSpanTag: () => ({ commands }) => {
        return commands.setNode(this.name);
      },
    };
  },
});

export default SpanTag;
