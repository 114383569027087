import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const GroupIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} id="uuid-ddb1255a-58cd-49c6-ada2-f55d3f2d18a4" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <g fill={color || 'rgba(0, 0, 0, 0.6)'} id="uuid-bc87ae7c-c2f6-4a9e-bb8c-6eb5da58e717" data-name="Group 8843">
        <path id="uuid-0ed49748-5315-44e6-9545-a49038f4a22e" data-name="Path 4743" d="m47.0662,12.5296c.0256.4703-.2324.9103-.6554,1.1174-6.9906,3.6875-13.9812,7.3849-20.9717,11.0921-.243.1725-.5641.1878-.8225.0393-6.9622-3.7072-13.9309-7.4046-20.9062-11.0921-.6749-.3336-.9516-1.1512-.618-1.8261.1051-.2127.2639-.3942.4607-.5267.1391-.1009.2859-.1908.4391-.2687C10.6682,7.4712,17.3388,3.8766,24.0038.2808c.6231-.3744,1.402-.3744,2.0251,0,6.6651,3.6023,13.3367,7.1959,20.0149,10.7808.5884.2563.9834.8209,1.0224,1.4615"/>
        <path id="uuid-1d20551d-f5b3-4bc5-94c3-744ab0dc3a93" data-name="Path 4745" d="m47.0596,25.4239c-.0147.4253-.2684.8059-.6554.983-7.0212,3.7006-14.0347,7.4144-21.0406,11.1412-.2271.1439-.5168.1439-.7438,0-6.9469-3.7006-13.9025-7.3936-20.8669-11.079-.6735-.2655-1.0042-1.0266-.7387-1.7001.1011-.2565.2805-.4745.5126-.6232,1.0601-.6594,2.1539-1.2632,3.2768-1.8088.1081-.0557.3277.036.4686.1114,3.2047,1.6908,6.4073,3.3861,9.6077,5.0856,2.379,1.2616,4.7645,2.5101,7.1369,3.7847.6134.3671,1.3789.3671,1.9923,0,5.5641-2.9601,11.1347-5.9092,16.7119-8.8475.238-.159.5484-.159.7864,0,.8847.5014,1.7892.9667,2.6805,1.4549.5662.2795.9144.8666.888,1.4975"/>
        <path id="uuid-c347a533-9c14-4f40-9a1e-3ef449dc1b3e" data-name="Path 4829" d="m47.0596,37.7678c-.0147.4253-.2684.8059-.6554.983-7.0212,3.7006-14.0347,7.4144-21.0406,11.1412-.2271.1439-.5168.1439-.7438,0-6.9469-3.7006-13.9025-7.3936-20.8669-11.079-.6735-.2655-1.0042-1.0266-.7387-1.7001.1011-.2565.2805-.4745.5126-.6232,1.0601-.6594,2.1539-1.2632,3.2768-1.8088.1081-.0557.3277.036.4686.1114,3.2047,1.6908,6.4073,3.3861,9.6077,5.0856,2.379,1.2616,4.7645,2.5101,7.1369,3.7847.6134.3671,1.3789.3671,1.9923,0,5.5641-2.9601,11.1347-5.9092,16.7119-8.8475.238-.159.5484-.159.7864,0,.8847.5014,1.7892.9667,2.6805,1.4549.5662.2795.9144.8666.888,1.4975"/>
      </g>
    </svg>
  );
};

export default GroupIcon;
