import React, { useRef } from 'react';
import { History } from 'history';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import { Grid } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/NotificationsActive';
import AddIcon from '@mui/icons-material/Add';
import ClientsTable from './components/ClientsTable/ClientsTable.component';
import ClientEditDialog from './components/client-dialog/client-dialog.component.jsx';
import AddClients from './components/AddClients/AddClients.component';
import EditClient from './components/EditClient.component';
import { PopupTemplate } from '../../services/Popup.js';
import { useTitle } from '../../services/useTitle';
import Header from '../../components/PageHeader/PageHeader.component';
import FilterTags from '../../components/FilterTags/FilterTags.component';
import FilterStatus from '../../components/FilterStatus/FilterStatus.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import HeaderSearch from '../../components/HeaderSearch/HeaderSearch.component';
import ClientStatusIcon from '../../icons/ClientStatus.icon';
import * as clientsActions from './clients.actions';
import { useSelector } from '../../reducers';
import { Client } from './clients.types';

const clientStatuses = ['Active', 'Inactive', 'Inert'];

const notifications = ['Email On', 'Email Off', 'SMS On', 'SMS Off'];

const getNotificationStatus = (status = 'Email', selected: string[] = []) => {
  const onStatus = status + ' On';
  const offStatus = status + ' Off';
  const hasOn = R.includes(onStatus, selected);
  const hasOff = R.includes(offStatus, selected);
  if (hasOn && hasOff) {
    return null;
  }
  if (hasOn) {
    return true;
  }
  if (hasOff) {
    return false;
  }
  return null;
};

const searchOptions = [
  { value: 'name', label: 'Name' },
  { value: 'phone', label: 'Phone Number' },
  { value: 'email', label: 'Email' },
];

let timeout: NodeJS.Timeout = null;
// TODO put the paging stuff in this styles thing
// here - line 378-382, 399-402

type CState = {
  isAddingClients?: boolean;
  isEditClients?: boolean;
  client?: Client,
  isSearchingTags?: boolean;
}
const Clients = ({ history }: { history: History }) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [state, setState] = React.useState<CState>({
    isAddingClients: false,
    isEditClients: false,
    client: undefined,
    isSearchingTags: false
  });
  const [filterHeight, setFilterHeight] = React.useState(45);

  const { isAddingClients, isEditClients, client } = state;

  const {
    clients,
    ehrSystem,
    admin,
    office,
    features,
    headerHeight,
  } = useSelector(st => ({
    clients: st.clients,
    ehrSystem: R.pathOr(null, ['login', 'office', 'ehrSystem'])(st),
    office: R.pathOr(null, ['login', 'office'])(st),
    admin: R.pathOr(false, ['login', 'admin'])(st),
    features: R.pathOr([], ['login', 'features'], st),
    headerHeight: st.login.headerHeight,
  }));

  const actions = bindActionCreators(clientsActions, dispatch);

  const toggle = (attr: keyof CState) => setState(s => ({ ...s, [attr]: !s[attr] }));
  const update = (data: object) => setState(s => ({ ...s, ...data }));

  useTitle('Clients');

  const { status, allowNotifications } = clients;
  const allowEmail = getNotificationStatus('Email', allowNotifications);
  const allowSMS = getNotificationStatus('SMS', allowNotifications);

  const refreshClients = (page = 1) => {
    let query = {
      [clients.searchBy]: clients.search.trim(),
      tags: {
        And: clients.selectedTags.map(R.prop('tagId')),
      },
      status,
      allowEmail,
      allowSMS,
    };
    if (clients.searchBy === 'name') {
      const str = clients.search;
      const splitUp = str.split(' ');
      const lastName = splitUp[0] ? splitUp[0].trim() : undefined;
      const firstName = splitUp[1] ? splitUp[1].trim() : undefined;
      const phone = splitUp[2] ? splitUp[2].trim() : undefined;
      query = {
        firstName,
        lastName,
        phone,
        tags: {
          And: clients.selectedTags.map(R.prop('tagId')),
        },
        status,
        allowEmail,
        allowSMS,
      };
    }
    actions.getClients({
      query,
      perPage: clients.perPage,
      page
    }, history);
  };

  React.useEffect(() => {
    refreshClients(1);
    actions.getTags();
  }, [office]);

  React.useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setFilterHeight(ref.current.clientHeight);
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, []);

  React.useEffect(() => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      refreshClients(1);
      actions.searchPatch('page', 1);
    }, 500);
  }, [
    clients.search,
    clients.status,
    clients.allowNotifications,
    clients.perPage,
    clients.selectedTags,
    clients.tagsSearch,
    clients.tags,
  ]);

  const addPage = (num: number) => {
    const page = clients.page + num;
    actions.searchPatch('page', page);
    refreshClients(page);
  };

  const handleReset = () => {
    actions.searchPatch('selectedTags', []);
    actions.searchPatch('status', ['Active']);
    actions.searchPatch('allowNotifications', []);
    actions.searchPatch('search', '');
    actions.searchPatch('searchBy', 'name');
  };

  return (
    <>
      <Header
        title='Clients'
        pageId={ehrSystem === 'None' ? 'clients-tab-sas' : 'clients-tab'}
        leftIcons={[
          R.includes('Campaigns', features) ?
            <FilterTags
              selectedTags={clients.selectedTags}
              allTags={clients.allTags}
              setSelectedTags={(tags) => actions.searchPatch('selectedTags', tags)}
            /> : <div />,
          R.includes(ehrSystem, ['None', 'PlatinumApi', 'ChiroTouch', 'Genesis', 'ChiroHD']) ?
            <FilterStatus
              statuses={clientStatuses}
              selectedStatuses={status}
              defaultStatuses={['Active']}
              setStatuses={(newStatus) => actions.searchPatch('status', newStatus)}
              title='Client Status'
              Icon={ClientStatusIcon}
            /> : <div />,
          <FilterStatus
            statuses={notifications}
            selectedStatuses={allowNotifications}
            setStatuses={(newStatus) => actions.searchPatch('allowNotifications', newStatus)}
            title='Notifications'
            Icon={NotificationsIcon}
          />
        ]}
        rightIcons={[
          <Grid marginRight="5px">
            <HeaderSearch
              placeholder={clients.searchBy === 'name' ? 'LastName FirstName' : clients.searchBy}
              value={clients.search}
              onChange={(value) => actions.searchPatch('search', value)}
              options={searchOptions}
              selectedOption={clients.searchBy}
              setOption={(value) => actions.searchPatch('searchBy', value)}
            />
          </Grid>,
          ehrSystem !== 'Platinum' ?
            <HeaderButton className='sked-test-clients-add-clients' marginRight={5} Icon={AddIcon} title='Add Clients' onClick={() => toggle('isAddingClients')} />
            : <div />,
          <HeaderButton
            onClick={handleReset}
            title='Reset Filters'
            borderSolid
          />,
        ]}
        onlyIconsWidth={1045}
        breakPoints={[
          {
            width: 724,
            mobileItems: [3],
          },
          {
            width: 485,
            mobileItems: [3, 5],
          },
        ]}
      />
      <div
        style={{
          padding: '20px',
          paddingTop: 0,
          overflowY: 'unset',
        }}>
        {clients.state === 'CLIENTS_LIST' &&
          !isAddingClients &&
          !isEditClients &&
          <PopupTemplate />}
        <div>
          <ClientEditDialog
            open={clients.state === 'CLIENT_SELECT'}
            from={'CLIENTS'}
            history={history}
            onClose={actions.backToList}
            back={actions.back}
            gotoClient={actions.back} />
          <AddClients
            open={isAddingClients}
            onClose={() => toggle('isAddingClients')}
            save={refreshClients}
            admin={admin}
          />
          <EditClient
            open={isEditClients}
            client={client}
            admin={admin}
            ehr={ehrSystem}
            onClose={() => toggle('isEditClients')}
            save={refreshClients}
          />
          <div>
            <ClientsTable
              admin={admin}
              clients={clients.clients}
              busy={clients.busy}
              onCurrent={actions.selectClient}
              ehr={ehrSystem}
              refreshClients={() => refreshClients(clients.page)}
              editClient={(c: Client) => update({ client: c, isEditClients: true })}
              filterHeight={filterHeight}
              headerHeight={headerHeight}
              addPage={addPage}
              page={clients.page}
              perPage={clients.perPage}
              setPerPage={(value: number) => actions.searchPatch('perPage', value)}
              totalPages={clients.totalPages}
              totalCount={clients.totalCount}
              currentLength={clients.clients.length}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Clients;
