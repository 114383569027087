import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const ReferralIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} id="referral" xmlns="http://www.w3.org/2000/svg" width="23.82" height="23.82" viewBox="0 0 23.82 23.82">
      <path id="Path_8319" data-name="Path 8319" d="M6.234,119.074H3.768A3.773,3.773,0,0,0,0,122.843v3.768a3.773,3.773,0,0,0,3.768,3.768H6.234Zm0,0" transform="translate(0 -113.534)" fill={color || '#4d4d4d'}/>
      <path id="Path_8320" data-name="Path 8320" d="M172.733,49.182a36.562,36.562,0,0,1,.768-7.891c.121-.522.252-.994.395-1.42a19.994,19.994,0,0,1-4.016,2.462A14.949,14.949,0,0,1,164,43.544v11.31a14.953,14.953,0,0,1,5.88,1.207,20.011,20.011,0,0,1,4.03,2.474c-.148-.437-.284-.922-.409-1.462A36.562,36.562,0,0,1,172.733,49.182Zm0,0" transform="translate(-156.37 -38.016)" fill={color || '#4d4d4d'}/>
      <path id="Path_8321" data-name="Path 8321" d="M383.678,190.445a2.529,2.529,0,0,0-1.9-2.434c-.042.785-.064,1.6-.064,2.419s.022,1.654.066,2.448A2.521,2.521,0,0,0,383.678,190.445Zm0,0" transform="translate(-363.952 -179.265)" fill={color || '#4d4d4d'}/>
      <path id="Path_8322" data-name="Path 8322" d="M390.4,3.59C389.852,1.208,389.078,0,388.1,0s-1.747,1.208-2.3,3.59a27.937,27.937,0,0,0-.568,3.752,3.9,3.9,0,0,1,.092,7.66.671.671,0,0,1-.09.012,27.864,27.864,0,0,0,.566,3.727c.552,2.382,1.325,3.59,2.3,3.59s1.747-1.208,2.3-3.59a35.167,35.167,0,0,0,.732-7.576A35.167,35.167,0,0,0,390.4,3.59Zm0,0" transform="translate(-367.315)" fill={color || '#4d4d4d'}/>
      <path id="Path_8323" data-name="Path 8323" d="M92.009,391.817a2.03,2.03,0,0,1-.217.012H89v3.3a2.282,2.282,0,0,0,2.28,2.28h.067a2.264,2.264,0,0,0,2.162-1.555l1.315-3.824a16.437,16.437,0,0,0-2.713-.212Zm0,0" transform="translate(-84.859 -373.588)" fill={color || '#4d4d4d'}/>
    </svg>
  );
};

export default ReferralIcon;
