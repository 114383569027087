import api from '../../../services/api';

import * as R from 'ramda';

import { Agency, Office, User } from './agencies.types';

export const getAdminUsers = async () => {
  try {
    const users = await api.get('agency/agents');
    return users;
  } catch {
    return [];
  }
};

export const saveAdminUser = async (id: number, email: string, name: string) => {
  try {
    const user = await api.post('agency/agents', { email, agencyId: id, name, agentType: 'AgencyAdmin' });
    return user;
  } catch {
    return false;
  }
};

const getUser = (id: number, users: User[]) => {
  const user = users.find(item => item.agencyId === id);
  if (user) {
    return user;
  }
  return {} as User;
};

export const getAgencies = async (): Promise<Agency[]> => {
  const response = await api.get('agency');
  const agencies: Agency[] = R.sortBy(R.prop('name'), response);

  const users: User[] = await getAdminUsers();

  return agencies.map((agency) => ({ ...agency, user: getUser(agency.agencyId, users) }));
};

export const postAgency = async ({ name, email }: { name: string, email: string }) => {
  const response = await api.post('agency', { name, email });
  return response;
};

export const putAgency = async (id: number, name: string) => {
  try {
    await api.put(`agency/${id}`, { name });
    return true;
  } catch {
    return false;
  }
};

export const putUser = async (userId: number, email: string) => {
  try {
    await api.put(`user/${userId}`, { email });
    return true;
  } catch {
    return false;
  }
};

export const deleteAgency = async (id: number) => {
  try {
    await api.delete(`agency/${id}`);
    return true;
  } catch {
    return false;
  }
};

type BodyResponse = {
  data: Office[];
  page: number;
  totalPages: number;
  totalCount: number;
}

type GetOfficesProps = {
  page?: number;
  search?: string;
}

export type GetOfficesResponse = {
  offices: Office[];
  page: number;
  totalPages: number;
  totalCount: number;
}

export const getFreeOffices = async ({ page, search }: GetOfficesProps): Promise<GetOfficesResponse> => {
  const body = {
    page: page || 1,
    perPage: 50,
    query: {
      inAgency: false,
      name: search || '',
    }
  };
  const offices: BodyResponse = await api.post('office/query', body);
  const sortOffices = R.sortBy(R.prop('name'), offices.data);
  return {
    offices: sortOffices.filter(office => office.plan !== 'SkedUnsubscribed'),
    page: offices.page,
    totalPages: offices.totalPages,
    totalCount: offices.totalCount,
  };
};

export const getAgencyOffices = async (id: number): Promise<Office[]> => {
  const offices = await api.get(`agency/${id}/office`);
  return offices;
};

export const saveOffices = async (idsToAdd: number[], idsToRemove: number[], agencyId: number) => {
  const AddPromises = idsToAdd.map(item => {
    return api.post(`agency/${agencyId}/office/${item}`);
  });
  const RemovePromises = idsToRemove.map(item => {
    return api.delete(`agency/${agencyId}/office/${item}`);
  });

  try {
    await Promise.all([...AddPromises, ...RemovePromises]);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
