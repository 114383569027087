import { Extension } from '@tiptap/core';
import { mapObjIndexed } from 'ramda';

export type StylesOptions = {
  types: string[],
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    styles: {
      /**
       * Set the text color
       */
      setStyles: (styles: {[s: string]: string}) => ReturnType,
      /**
       * Unset the text color
       */
      unsetStyles: () => ReturnType,
    }
  }
}

const Styles = Extension.create<StylesOptions>({
  name: 'styles',

  addOptions() {
    return {
      types: ['textStyle'],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          styles: {
            default: {},
            parseHTML: (element: any) => {
              const stylez = element.style.cssText.split(';')
                .reduce((acc: object, style: string) => {
                  const [key, val] = style.split(':');
                  if (val && key !== 'class') {
                    return {
                      ...acc,
                      [key]: val.trim(),
                    };
                  }
                  return acc;
                }, {});
              return stylez;
            },
            renderHTML: (attributes) => {
              let style = '';
              mapObjIndexed((val: (string | object), key: string) => {
                if (key === 'styles' && typeof val === 'object') {
                  mapObjIndexed((styVal: string, styKey: string) => {
                    style = style + `${styKey}: ${styVal};`;
                  }, val);
                } else if (!['ie', 'class', 'mso', 'gte'].includes(key)) {
                  style = style + `${key}: ${val};`;
                }
              }, attributes);
              return {
                style,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setStyles: (styles) => ({ chain }) => {
        return chain()
          .setMark('textStyle', { ...styles })
          .run();
      },
      unsetStyles: () => ({ chain }) => {
        return chain()
          .setMark('textStyle', {})
          .removeEmptyTextStyle()
          .run();
      },
    };
  },
});

export default Styles;
