import { isNil, pipe, assoc, merge, toPairs, fromPairs, map, assocPath, path, all, filter } from 'ramda';

export const getValue = (prop, formObject) => {
  return path([prop, 'value'], formObject);
};

export const requiredValidation = (message = 'Please enter a value for this field') => (prop, f) => {
  const value = getValue(prop, f);
  if (value) {
    return { valid: true };
  }
  return {
    valid: false,
    message
  };
};

export const minLengthValidation = (message = '', length = 8) => (prop, f) => {
  const value = getValue(prop, f);
  if (value && value.length >= length) {
    return { valid: true };
  }
  return {
    valid: false,
    message
  };
};

export const passwordMatchValidation = (message = '', otherField = 'password') => (prop, f) => {
  const value = getValue(prop, f);
  const otherValue = getValue(otherField, f);
  if (value === otherValue) {
    return { valid: true };
  }
  return {
    valid: false,
    message
  };
};

export const checkEmailValidation = email => {
  //eslint-disable-next-line no-useless-escape
  const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValid = emailRegex.test(email);
  return isValid;
};

export const emailValidation = message => (prop, f) => {
  const value = getValue(prop, f);
  const isValid = checkEmailValidation(value);
  if (isValid) {
    return { valid: true };
  }
  return {
    valid: false,
    message
  };
};

const initMapFunc = initData => ([prop, value]) => {
  const setValue = () => {
    if (!isNil(initData[prop])) {
      return initData[prop];
    }
    if (!isNil(value.default)) {
      return value.default;
    }
    return '';
  };
  const defaults = {
    isTouched: false,
    isValid: true,
    validation: [],
    errors: [],
    value: setValue()
  };
  return [
    prop,
    merge(defaults, value)
  ];
};

const emptyObject = {};
export const initForm = (formStart, initData = emptyObject) => {
  return pipe(
    f => merge(f, { __form: { isValid: true, isTouched: false } }),
    toPairs,
    map(initMapFunc(initData)),
    fromPairs,
  )(formStart);
};


export const updateValue = (prop, value) => formObject => {
  return assocPath([prop, 'value'], value, formObject);
};

export const touchField = prop => formObject => {
  return assocPath([prop, 'isTouched'], true, formObject);
};

export const touchAllFields = formObject => {
  return pipe(
    toPairs,
    map(([prop, obj]) => {
      return [prop, assoc('isTouched', true, obj)];
    }),
    fromPairs,
  )(formObject);
};

export const validateEntry = formObject => ([prop, obj]) => {
  if (!obj.validation) {
    return [prop, obj];
  }
  const validationList = obj.validation.map(func => func(prop, formObject));
  const isValid = !obj.isTouched || validationList.every(v => v.valid);
  const errors = validationList.filter(v => !v.valid).map(v => v.message);
  return [prop, merge(obj, { isValid, errors })];
};

export const validateField = prop => formObject => {
  const obj = formObject[prop];
  const validationList = obj.validation.map(func => func(prop, formObject));
  const isValid = !obj.isTouched || validationList.every(v => v.valid);
  const errors = validationList.filter(v => !v.valid).map(v => v.message);
  return assoc(
    prop,
    merge(obj, { isValid, errors }),
    formObject
  );
};


const testAllFieldsValid = formObject => {
  return pipe(
    toPairs,
    filter(([prop]) => prop !== '__form'),
    all(([, v]) => v.isValid)
  )(formObject);
};

export const validateForm = formObject => {
  const validatedForm = pipe(
    toPairs,
    filter(([prop]) => prop !== '__form'),
    map(validateEntry(formObject)),
    fromPairs,
  )(formObject);

  const isValid = testAllFieldsValid(validatedForm);

  return assocPath(['__form', 'isValid'], isValid, validatedForm);
};

export const submitForm = formObject => {
  const validatedForm = pipe(
    touchAllFields,
    validateForm
  )(formObject);
  const isValid = testAllFieldsValid(validatedForm);
  return assoc('__form', { isValid, isTouched: true }, validatedForm);
};

export const getFormObject = formObject => {
  return pipe(
    toPairs,
    filter(([prop]) => prop !== '__form'),
    map(([prop, obj]) => {
      return [prop, obj.value];
    }),
    fromPairs,
  )(formObject);
};

export const touchFieldAndValidate = (prop, formObject) => {
  return pipe(
    touchField(prop),
    validateForm
  )(formObject);
};

export const getFirstErrorOrDefault = (prop, form, helperText = '') => {
  return form[prop].isValid ? helperText : form[prop].errors[0];
};

