import React from 'react';

type Props = {
  color?: string;
};

const ReviewsIcon = ({ color }: Props) => {
  return (
    <svg id="Group_15319" data-name="Group 15319" xmlns="http://www.w3.org/2000/svg" width="25.434" height="24.842" viewBox="0 0 25.434 24.842">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_11702" data-name="Rectangle 11702" width="25.433" height="24.842" fill={color || '#FFF'}/>
        </clipPath>
      </defs>
      <g id="Group_15310" data-name="Group 15310" transform="translate(0 0)" clipPath="url(#clip-path)">
        <path id="Path_8757" data-name="Path 8757" d="M41.34,5.359a.885.885,0,0,0-.491-1.51l-2.988-.434a.389.389,0,0,1-.294-.213L36.231.493a.885.885,0,0,0-1.588,0L33.308,3.2a.39.39,0,0,1-.294.214l-2.988.434a.884.884,0,0,0-.637,1.321.889.889,0,0,0,.146.188L31.7,7.466a.39.39,0,0,1,.113.346L31.554,9.3,31.3,10.788a.866.866,0,0,0,.193.718.883.883,0,0,0,.13.123c.014.011.029.021.044.031a.942.942,0,0,0,.108.064c.017.008.033.017.051.024a.95.95,0,0,0,.135.045l.031.009a.861.861,0,0,0,.173.018l.013,0c.009,0,.019,0,.029,0a.922.922,0,0,0,.148-.017l.051-.012a.859.859,0,0,0,.179-.068l2.672-1.405a.4.4,0,0,1,.364,0l.549.289,2.124,1.116a.875.875,0,0,0,.412.1.888.888,0,0,0,.679-.319.867.867,0,0,0,.193-.718l-.51-2.976a.389.389,0,0,1,.113-.346Z" transform="translate(-16.175 0)" fill={color || '#FFF'}/>
        <path id="Path_8758" data-name="Path 8758" d="M21.285,19.427l-2.022-1.063-2.022,1.063a2.674,2.674,0,0,1-3.88-2.819l.386-2.251-1.636-1.595a2.655,2.655,0,0,1-.8-1.684H3.43A3.439,3.439,0,0,0,0,14.514Q0,19.1,0,23.689a3.442,3.442,0,0,0,3.442,3.448c.373,0,.745,0,1.145,0,0,1.015,0,1.995,0,2.974a.774.774,0,0,0,.447.777A.755.755,0,0,0,5.9,30.74Q7.976,29,10.064,27.277a.644.644,0,0,1,.379-.134c2.516-.008,5.033,0,7.549-.007a3.446,3.446,0,0,0,3.416-3.425c0-.089,0-.179,0-.269q0-1.979,0-3.959c-.041-.019-.083-.034-.124-.056" transform="translate(0 -6.123)" fill={color || '#FFF'}/>
      </g>
    </svg>
  );
};

export default ReviewsIcon;
