import React, { ComponentProps } from 'react';

const ListViewIcon = ({ color, className, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" {...props} width="14.989" height="13.561" viewBox="0 0 14.989 13.561">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_11876" data-name="Rectangle 11876" width="14.989" height="13.561" transform="translate(0 0)" fill={color || '#4d4d4d'} />
        </clipPath>
      </defs>
      <g id="list-view-icon" transform="translate(0 0)">
        <g id="Group_15468" data-name="Group 15468" clipPath="url(#clip-path)">
          <path id="Path_8820" data-name="Path 8820" d="M13.195,0H1.794a1.794,1.794,0,0,0,0,3.588h11.4a1.794,1.794,0,1,0,0-3.588" fill={color || '#4d4d4d'} />
          <path id="Path_8821" data-name="Path 8821" d="M13.195,9.973H1.794a1.794,1.794,0,0,0,0,3.588h11.4a1.794,1.794,0,1,0,0-3.588" fill={color || '#4d4d4d'} />
          <path id="Path_8822" data-name="Path 8822" d="M13.195,4.987H1.794a1.794,1.794,0,1,0,0,3.588h11.4a1.794,1.794,0,1,0,0-3.588" fill={color || '#4d4d4d'} />
        </g>
      </g>
    </svg>
  );
};

export default ListViewIcon;
