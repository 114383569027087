import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NavLink } from 'react-router-dom';

import {
  Paper,
  TextField as TextFieldSmall,
  Button,
  Typography
} from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';

import { PopupTemplate } from '../../services/Popup.js';

import * as forgotPasswordActions from './forgot-password.actions';

const TextField = withStyles({
  root: {
    width: '100%',
  },
})(TextFieldSmall);

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    maxWidth: '520px',
    margin: '0px auto',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%'
  },
  paper: {
    width: '100%',
    padding: '40px'
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  formControl: {
    marginTop: 8,
    marginBottom: 8
  },
  buttonLabel: {
    textTransform: 'none',
    fontWeight: 'normal'
  }
}));

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const actions = bindActionCreators(forgotPasswordActions, dispatch);
  const [state, setState] = useState({
    email: '',
    loading: false
  });

  const handleEmailChange = (event) => {
    setState({ email: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setState({ loading: true });
    actions.sendForgotPassword(state.email).then(() => {
      setState({ email: '', loading: false });
    }).catch(() => {
      setState({ loading: false });
    });
  };

  return (
    <div className={classes.root}>
      <PopupTemplate />
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit}>
          <div className={classes.logoContainer}>
            <Typography align='center' variant='h4'>Reset Your Password</Typography>
          </div>
          <div className={classes.formControl}>
            <TextField
              autoFocus
              fullWidth
              id="login-email"
              label="Email"
              type="email"
              variant='standard'
              onChange={handleEmailChange}
              value={state.email}
            />
          </div>
          <Button
            variant="contained"
            type="submit"
            classes={{ label: classes.buttonLabel }}
            disabled={state.loading}
            fullWidth
            color="primary">
            {state.loading ? 'Loading...' : 'Reset Password'}
          </Button>
          <Button
            type="button"
            variant="text"
            component={NavLink}
            fullWidth
            to="/login"
            classes={{ label: classes.buttonLabel }}>
              Back
          </Button>
        </form>
      </Paper>
    </div>
  );
};



export default ForgotPassword;
