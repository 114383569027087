import React from 'react';
import {
  TextField as RawTextField,
  FormControl, FormHelperText,
  Autocomplete as RawAutocomplete,
  InputLabel, Select, Input, MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { populateProfessional } from '../services/oranizeTypes.js';
import { withStyles, makeStyles } from '@mui/styles';
import { identity, remove } from 'ramda';
import { Select as SkedSelect } from '../../../components/components.component';
import { AppointmentType, Professional } from '../../Appointments/appointments.types';

const TextField = withStyles({
  root: {
    width: '100%',
  },
})(RawTextField);

const Autocomplete = withStyles({
  option: {
    fontSize: '14px',
  },
  groupLabel: {
    fontSize: '12px',
    fontWeight: 'bolder',
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.74)'
  },
})(RawAutocomplete);

const useStyles = makeStyles(() => ({
  form: {
    minWidth: 150,
    maxWidth: 420
  },
  menuPaper: {
    maxHeight: 500,
  },
  title: {
    fontWeight: 600,
    marginTop: 8,
    marginBottom: 4,
    fontSize: 13,
    whiteSpace: 'normal'
  },
  item: {
    maxWidth: 244,
    paddingLeft: 22,
    whiteSpace: 'normal'
  }
}));


const filterOptions = (list: AppointmentType[], { inputValue = '' }: { inputValue: string }) => {
  const searchInput = inputValue.toLowerCase();
  return list.filter(({ name, professionalName, internalName }) =>
    name.toLowerCase().indexOf(searchInput) !== -1 ||
    internalName.toLowerCase().indexOf(searchInput) !== -1 ||
    professionalName.toLowerCase().indexOf(searchInput) !== -1
  );
};

type ApptAutocompleteProps = {
  value: number | number[];
  onChange: (v: {
    target: {
      ids?: number[];
      value?: number;
    }
  }) => void;
  types: AppointmentType[];
  professionals: Professional[];
  id?: string;
  width?: string;
  maxWidth?: string;
  minWidth?: string;
  label?: boolean;
  error?: boolean;
  disableCloseOnSelect?: boolean;
  multiple?: boolean;
  noneIsAll?: boolean;
  includeErrors?: boolean;
  style?: object,
}
export const AppointmentTypeAutocomplete = ({
  value = {} as number, onChange, types,
  professionals, id, width = '150px', maxWidth, minWidth,
  label = true, error = false,
  disableCloseOnSelect = false, multiple = false,
  noneIsAll = false, includeErrors, style = {},
}: ApptAutocompleteProps) => {
  const list: AppointmentType[] = React.useMemo(() => {
    return populateProfessional(types, professionals, noneIsAll);
  }, [types, professionals]);
  const selectedType = React.useMemo(() => {
    if (multiple) {
      return (value as number[]).map((v) => list.find(l => l.id === v)).filter(identity);
    }
    return list.find(l => l.id === value);
  }, [value, list]);
  return (
    <FormControl error={error} style={style}>
      <Autocomplete
        id={id}
        options={list}
        disableCloseOnSelect={disableCloseOnSelect}
        onChange={(_, t: AppointmentType | AppointmentType[]) => {
          if (multiple) {
            return t && onChange({ target: { ids: (t as AppointmentType[]).map(({ id }) => id) } });
          }
          return t && onChange({ target: { value: (t as AppointmentType).id } });
        }}
        value={selectedType}
        groupBy={(option: AppointmentType) => option.professionalName}
        getOptionLabel={(option: AppointmentType) => `${option.internalName || ''} (${option.name || ''})`}
        isOptionEqualToValue={(o: AppointmentType, v: AppointmentType) => o.id === v.id}
        style={{ width, maxWidth, minWidth }}
        filterOptions={filterOptions}
        autoHighlight
        multiple={multiple}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            label={label ? 'Type' : null}
            variant='standard'
          />
        )}
      />
      {includeErrors &&
        <FormHelperText>{error ? 'Required' : ' '}</FormHelperText>}
    </FormControl>
  );
};

type ApptSelectorProps = {
  value: number,
  types: AppointmentType[];
  professionals: Professional[];
  noneIsAll?: boolean;
  onChange: (e: SelectChangeEvent<number>, c?: React.ReactNode) => void;
  notNone?: boolean;
  newStyle?: boolean;
  selectClassName?: string; 
  formClassName?: string;
  renderValue?: (v: string) => string;
}

export const AppointmentTypeSelector = ({
  value, types, professionals, noneIsAll = false, onChange,
  notNone = false, newStyle = false, selectClassName = '', formClassName = null,
  renderValue = null,
}: ApptSelectorProps) => {
  const classes = useStyles();

  const list: AppointmentType[] = React.useMemo(() => {
    const items = populateProfessional(types, professionals, noneIsAll);
    if (notNone) {
      return remove(0, 1, items);
    }
    return items;
  }, [types, professionals]);

  const selectedType = React.useMemo(() => {
    const result = list.find(l => l?.id === value)?.id;
    if (result) {
      return result;
    }
    return list.length ? list[0].id : 0;
  }, [value, list]);

  const renderItems = (list: AppointmentType[]) => {
    const groupByProfessional = list.reduce((group: AppointmentType, type) => {
      const profName = type.professionalName as keyof AppointmentType;
      group[profName] = (group[profName] ?? []) as never;
      (group[profName] as unknown as AppointmentType[]).push(type);
      return group;
    }, {} as AppointmentType);

    return Object.keys(groupByProfessional).map(key => {
      return ([
        key && <MenuItem disabled value="" className={classes.title} style={{ opacity: 0.9 }}>
          {key}
        </MenuItem>,
        (groupByProfessional[key as keyof AppointmentType] as unknown as AppointmentType[])?.map((option: AppointmentType) => (
          <MenuItem key={option.id} value={option.id} className={classes.item}>
            {`${option.internalName || ''} (${option.name || ''})`}
          </MenuItem>
        ))
      ]);
    });
  };

  return (
    <FormControl variant="standard" className={formClassName || classes.form}>
      {newStyle ?
        <SkedSelect
          labelId="apt-type-label"
          id="apt-type"
          autoWidth
          inputProps={{ className: 'sked-test-scheduler-new-appointment-appt-type' }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            classes: { paper: classes.menuPaper }
          }}
          value={selectedType}
          renderValue={renderValue ? renderValue : undefined}
          onChange={onChange}
          className={selectClassName}
        >
          {renderItems(list)}
        </SkedSelect>
        :
        <>
          <InputLabel id="apt-type-label">Type</InputLabel>
          <Select
            labelId="apt-type-label"
            id="apt-type"
            autoWidth
            inputProps={{ className: 'sked-test-scheduler-new-appointment-appt-type' }}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              classes: { paper: classes.menuPaper }
            }}
            value={selectedType}
            onChange={onChange}
            input={<Input />}
          >
            {renderItems(list)}
          </Select>
        </>}
    </FormControl>
  );
};
