import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 4,
    whiteSpace: 'nowrap',
    display: 'flex',
  },
  customButtom: {
    padding: '4px 14px',
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'none',
    borderRadius: 4,
    color: 'rgba(0,0,0,.87)',
    margin: 0,
    border: '1px solid rgba(0,0,0,.01)',
    background: 'rgba(0,0,0,.05)',
    minWidth: 30,

    '&:hover': {
      border: '1px solid rgba(0,0,0,.05)',
      background: 'rgba(0,0,0,.07)',
    }
  },
  paper: {
    borderRadius: 12,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  grid: {
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  item: {
    padding: '3px 12px',
    cursor: 'pointer',
    width: '100%',
    borderRadius: 4,

    '&:hover': {
      background: 'rgba(0,0,0,0.04)',
    }
  }
}));
