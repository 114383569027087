import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { Chip, Autocomplete, TextField, Button } from '@mui/material';

import api from '../../services/api';

import { useStyles } from './editTags.styles';

type Tag = {
  tagId: number,
  tag: string;
}

type TagEditProps = {
  tags: Tag[];
  addTag: (tag: Tag) => void;
  deleteTag: (tag: Tag) => void;
  onClose: () => void;
}

type StateType = {
  search?: string;
  open?: boolean;
  tags?: Tag[],
  allTags?: Tag[],
  state?: string,
}

const TagEdit = ({ tags, addTag, deleteTag, onClose }: TagEditProps) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState('');
  const [state, setState] = useState<StateType>({
    search: '',
    open: false,
    tags: [],
    allTags: [],
    state: 'INIT',
  });

  const update = (data: StateType) => {
    setState(R.merge(state, data));
  };

  useEffect(() => {
    const getAll = async () => {
      const responseTags = await api.get('client/tag');
      const allTags = R.sortBy(R.prop('tag'))(responseTags) as Tag[];
      update({
        allTags,
        tags: R.without(tags, allTags) as Tag[],
      });
    };
    getAll();
  }, []);

  useEffect(() => {
    update({ tags: R.without(tags, state.allTags) });
  }, [tags]);

  return (
    <div className={`${classes.root} client-dialog-left`}>
      <div>
        <div className={classes.header}>
          <label>
            Edit Tags:
          </label>
          <Button
            variant='outlined'
            size='small'
            onClick={() => onClose()}
          >
            Close
          </Button>
        </div>
        <Autocomplete
          disablePortal
          disableClearable
          id="combo-box-demo"
          options={state.tags}
          clearOnEscape
          getOptionLabel={(option: Tag) => option.tag}
          value={null}
          inputValue={inputValue}
          onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
          renderInput={(params) => <TextField {...params} />}
          onChange={(_e, tag) => {
            setInputValue('');
            if (!R.includes(tag, tags)) {
              addTag(tag);
            }
          }}
        />
        <div className={classes.chipContainer}>
          {tags.map((tag) => (
            <Chip
              className={classes.chip}
              key={`${tag.tagId}-${tag.tag}`}
              label={tag.tag}
              onDelete={() => {
                deleteTag(tag);
              }}
            />
          ))}
        </div>
      </div>
      <br />
    </div>
  );
};

export default TagEdit;
