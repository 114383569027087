import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 12,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  inputLabel: {
    fontSize: 14,
    marginBottom: 5,
  },
  selected: {
    borderRadius: 10
  },
}));
