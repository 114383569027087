import React, { ComponentProps } from 'react';

const CheckedIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg {...props} id="checkbox-checked-icon" xmlns="http://www.w3.org/2000/svg" width="15.146" height="15.146" viewBox="0 0 15.146 15.146">
      <rect id="Rectangle_9" data-name="Rectangle 9" width="15.146" height="15.146" rx="2" fill="#37AEFD"/>
      <path id="Path_28" data-name="Path 28" d="M6.56,11.695l2.317,2.317L14.8,8.09" transform="translate(-3.107 -3.478)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" fillRule="evenodd"/>
    </svg>
  );
};

export default CheckedIcon;
