import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    marginBottom: 20,
    marginTop: 20,

    '& h6': {
      fontSize: 16,
    },

    '& .MuiTextField-root': {
      width: 380,
      minWidth: '60%',
    },
  },
}));
