import React, { useEffect, useState, useRef } from 'react';
import {
  Dialog,
  Typography,
  Grid,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HeaderMore from '../HeaderMore/HeaderMore.component';
import { HelpDialog, useHelpDialog, HelpButton } from '../Help/Help.component';

import { useStyles } from './modal.styles';

type WidthSizes = {
  xs?: number,
  sm?: number,
  md?: number,
  lg?: number,
  xl?: number,
}

const widthSizes: WidthSizes = {
  xs: 500,
  sm: 900,
  md: 1100,
  lg: 1600,
  xl: 1800,
};

type Props = {
  title: string;
  open: boolean;
  onClose: () => void;
  children: React.ReactNode | React.ReactNode[];
  buttons?: React.ReactNode[];
  leftComponents?: React.ReactNode[];
  titleComponent?: React.ReactNode | React.ReactNode[];
  size?: keyof WidthSizes;
  minWidth?: number | string;
  maxWidth?: number;
  className?: string;
  innerClassName?: string;
  pageId?: string;
  getWidth?: (value: number) => void;
  showMoreWidth?: number;
}

let lastCallTime: number;
const Modal = ({
  title, leftComponents, open, onClose, children, buttons, size = 'sm',
  minWidth = 300, maxWidth, className, pageId, titleComponent,
  innerClassName, getWidth, showMoreWidth,
}: Props) => {
  const classes = useStyles();
  const helpState = useHelpDialog();
  const ref = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [windowWidth, setwindowWidth] = useState(900);
  const [showMore, setShowMore] = useState(false);
  const [params, setParams] = useState({ margin: 32, width: 'auto' });

  const updateWidth = () => {
    const now = new Date().getTime();
    if (!lastCallTime || (now - lastCallTime >= 200)) {
      const headerWidth = ref.current?.offsetWidth;
      setWidth(headerWidth);
      getWidth?.(headerWidth);
      setwindowWidth(window.innerWidth);
      lastCallTime = now;
    }
  };

  useEffect(() => {
    function handleResize() {
      updateWidth();
    }
    if (ref.current) {
      window.addEventListener('resize', handleResize);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref.current]);

  useEffect(() => {
    if (open && ref.current) {
      updateWidth();
    }
  }, [open, ref.current]);

  useEffect(() => {
    if (open && !ref.current && showMoreWidth) {
      if (window.innerWidth < 500) {
        setShowMore(true);
      }
    }
  }, [open, ref.current, showMoreWidth]);
  
  useEffect(() => {
    if (showMoreWidth && width > 0) {
      setShowMore(width < showMoreWidth);
    } else {
      setShowMore(false);
    }
  }, [width, showMoreWidth]);

  useEffect(() => {
    if (windowWidth <= 450) {
      setParams({ margin: 15, width: '100%' });
      return;
    }
    if (windowWidth <= 600) {
      setParams({ margin: 20, width: '100%' });
      return;
    }
    if (windowWidth <= 650) {
      setParams({ margin: 30, width: '100%' });
      return;
    }
    if (windowWidth <= 800) {
      setParams({ margin: 30, width: '80%' });
      return;
    }
    setParams({ margin: 32, width: size === 'xs' ? 'auto' : undefined });
  }, [windowWidth]);

  return (
    <Dialog
      fullWidth={true}
      scroll='paper'
      open={open}
      onClose={onClose}
      classes={{ paper: `${classes.paper} ${className || ''}` }}
      PaperProps={{ style: { maxWidth: maxWidth || widthSizes[size], minWidth, ...params } }}
    >
      <Grid paddingX={2} className={classes.header} ref={ref}>
        <Grid display="flex" alignItems="center">
          {titleComponent ? titleComponent : <Typography className={classes.title}>{title}</Typography>}
          {pageId && <HelpButton handleOpen={helpState.handleOpen} />}
          <Grid display="flex" alignItems="center" flexWrap="wrap" marginLeft="10px">
            {leftComponents}
          </Grid>
        </Grid>
        <Grid display="flex" alignItems="center">
          {showMore ? <HeaderMore items={buttons} /> : buttons}
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={`${classes.closeButton} ${className}-button-close`}>
            <CloseIcon className={classes.icon} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        paddingX={2}
        paddingTop={2}
        marginBottom={2}
        overflow="auto"
        className={innerClassName}
      >
        {children}
      </Grid>
      <HelpDialog
        pageId={pageId}
        handleClose={helpState.handleClose}
        open={helpState.isOpen}
      />
    </Dialog>
  );
};

export default Modal;
