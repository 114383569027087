import { skedApi } from './api.js';
let socket = null;
export const getSocket = () => socket;

const emptyArray = [];

export const initSocket = (subs = emptyArray, retry = 1) => (dispatch) => {
  return new Promise((res, rej) => {
    const url = process.env.API_URL.replace('https', 'wss') + '/feed';
    socket = new WebSocket(url);

    socket.onopen = () => {
      console.log('[open] Connection established, ', retry);
      console.log('Sending to server');
      console.log('Subscribe to these events: ', subs);

      const auth = {
        token: skedApi.defaults.headers.common.Authorization.split(' ')[1],
        as_office: skedApi.defaults.headers.common['X-As-Office']
      };
      socket.send(JSON.stringify(auth));

      subs.map(s => ({ tag: 'Subscribe', contents: s })).forEach(sub => {
        socket.send(JSON.stringify(sub));
      });
    };

    socket.onclose = (event) => {
      if (event.wasClean) {
        socket = null;
        console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
      } else {
        // e.g. server process killed or network down
        // event.code is usually 1006 in this case
        const tryIn = retry < 6 ? 2e3 * Math.pow(2, retry) : 64e3;
        console.log('[close] Connection died, retry in ' + tryIn);
        socket = null;
        setTimeout(() => {
          if (!socket)
            dispatch(initSocket(subs, retry + 1));
        }, tryIn);
      }
    };

    socket.onerror = (error) => {
      console.log('[error]', error);
      console.log(`[error message] ${error.message}`);
      rej();
    };

    return res(socket);
  });
};

export const closeSocket = () => {
  if (socket) {
    socket.close();
  }
};
