import React, { useMemo, useEffect } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { pipe, merge } from 'ramda';
import * as at from '../../actionTypes';
import { regPatch, submitForm } from './registration.actions.jsx';

import { Step1 } from './components/step1.component.jsx';
import { Step2 } from './components/step2.component.jsx';
import { Step3, YEARLY_POLICY } from './components/step3.component.jsx';
import { Step4 } from './components/step4.component.jsx';
import { PopupTemplate } from '../../services/Popup.js';

let stripe;
let elements;

const Registration = ({
  state, officeName, phone, email, notificationEmail, password,
  password2, address, isBillingAddressDifferent, billingAddress,
  hasPromo, promo, timezone, cardName, referrer, doctorName,
  doctorPhone, valuables, busy, plan, ehrSystem, errorMessage,
  regPatch, submitForm
}) => {
  const [isPlatinumReach, setIsPlatinumReach] = React.useState(false);

  const gotoStep = (data, step) => regPatch({ type: at.REG_PATCH, data: merge(data, { state: step }) });
  const finishStep1 = (data) => {
    location.href = location.href.split('?')[0];
    gotoStep(data, 'STEP_2');
  };

  const isSpark = location.hash.includes('spark-registration');

  const hrefPlan = location.href.split('plan=')[1];
  const isPro = hrefPlan?.substring(0, 3)?.includes('pro');

  const finishStep3 = (card, data) => {
    return submitForm({
      step3Data: data,
      stripe,
      card,
      isSpark,
    });
  };

  const getPlan = React.useCallback(() => {
    if (isSpark) {
      return '';
    }
    if (!plan && (isPlatinumReach || isPro)) {
      return 'SkedPro';
    }
    return R.cond([
      [R.equals('basic'), R.always('SkedBasic')],
      [R.equals('standard'), R.always('SkedStandard')],
      [R.equals('pro'), R.always('SkedPro')],
      // [R.equals('enterprise'), R.always('SkedEnterprise')],
      [R.equals(''), R.always('SkedStandard')],
      [R.T, R.always(plan)],
    ])(hrefPlan);
  }, [hrefPlan, plan, isPlatinumReach, isSpark]);

  const step1Data = useMemo(() => {
    return {
      ehrSystem: isPlatinumReach ? 'Platinum' : ehrSystem,
      plan: getPlan(),
      officeName,
      phone,
      ...address,
      timezone
    };
  }, [isPlatinumReach, isPro, plan, hrefPlan]);

  React.useEffect(() => {
    const ehr = location.href.split('platinumProduct=')[1];
    if (ehr) {
      const ispr = ehr.includes('reach');
      setIsPlatinumReach(ispr);
    }
  }, [location.href]);

  const step2Data = (plan === 'SkedBasic' || plan === 'SkedAgency' || isPlatinumReach) ?
    {
      email,
      notificationEmail,
      password,
      password2,
      doctorName,
      doctorPhone
    } : {
      email,
      notificationEmail,
      password,
      password2,
      doctorName,
      doctorPhone,
      valuables,
    };

  const step3Data = {
    isBillingAddressDifferent,
    ...billingAddress,
    hasPromo,
    promo,
    cardName,
    referrer: isPlatinumReach ? 'Platinum Reach' : referrer,
    agreement: isPlatinumReach ? YEARLY_POLICY : undefined,
  };

  useEffect(() => {
    stripe = isSpark
      ? window.Stripe(`${process.env.SPARK_STRIPE_API_KEY}`)
      : window.Stripe(`${process.env.STRIPE_API_KEY}`);

    elements = stripe.elements();
  }, [isSpark]);

  return (
    <div style={{ overflow: 'auto', height: '100%', boxSizing: 'border-box' }}>
      <PopupTemplate />
      {R.cond([
        [R.equals('STEP_1'), () => (
          <Step1
            finishStep1={finishStep1}
            step1Data={step1Data}
            errorMessage={errorMessage}
            isPlatinumReach={isPlatinumReach}
            isSpark={isSpark}
          />
        )],
        [R.equals('STEP_2'), () => (
          <Step2
            gotoStep={gotoStep}
            step2Data={step2Data}
            plan={plan}
            errorMessage={errorMessage}
            isPlatinumReach={isPlatinumReach}
            isSpark={isSpark}
          />)],
        [R.equals('STEP_3'), () => (
          <Step3
            elements={elements}
            gotoStep={gotoStep}
            finishStep3={finishStep3}
            busy={busy}
            step3Data={step3Data}
            errorMessage={errorMessage}
            isPlatinumReach={isPlatinumReach}
            isSpark={isSpark}
          />)],
        [R.T, () => (<Step4 isSpark={isSpark} />)]
      ])(state)}
    </div>);
};

const mapStateToProps = R.pathOr({}, ['registration']);

const mapDispatchToProps = dispatch => ({
  regPatch: (data) => dispatch(regPatch(data)),
  submitForm: (data) => dispatch(submitForm(data)),
});

export default pipe(
  connect(mapStateToProps, mapDispatchToProps),
)(Registration);
