import React, { useState } from 'react';
import * as R from 'ramda';

import { populateProfessional } from '../../routes/CalendarV2/services/oranizeTypes';

import Icon from '../../icons/ApptType.icon';
import FilterHeader from '../FilterHeader/FilterHeader.component';
import HeaderButton from '../HeaderButton/HeaderButton.component';

import { useStyles } from './filterApptTypes.styles';

import {
  Popover,
  Grid,
  Autocomplete,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Professional = {
  id: number;
  name?: string;
  firstName: string;
  lastName: string;
  displayName?: string;
}

type ApptType = {
  id: number;
  name?: string;
  internalName?: string;
  professionalName?: string;
  professionalId?: number;
  professional?: Professional;
}

type FilterApptTypesProps = {
  save: ({ types }: { types: ApptType[] }) => void;
  setApptTypes: (types: ApptType[]) => void;
  professionals: Professional[];
  allTypes: ApptType[];
  selectedTypes: ApptType[];
  onlyIcon?: boolean;
  title?: string;
}

const FilterApptTypes = ({ setApptTypes, selectedTypes, professionals, allTypes, title, save, onlyIcon }: FilterApptTypesProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const list: ApptType[] = React.useMemo(() => {
    const items = populateProfessional(allTypes, professionals);
    return R.remove(0, 1, items);
  }, [allTypes, professionals]);

  const handleSelect = (value: ApptType[]) => {
    setApptTypes(value);
    save({ types: value });
  };

  const handleDelete = (i: number) => {
    const newTypes = R.remove(i, 1, selectedTypes);
    setApptTypes(newTypes);
    save({ types: newTypes });
  };

  const filterOptions = (list: ApptType[], { inputValue = '' }) => {
    const searchInput = inputValue.toLowerCase();
    return list.filter(({ name, professionalName, internalName }) =>
      name.toLowerCase().indexOf(searchInput) !== -1 ||
      internalName.toLowerCase().indexOf(searchInput) !== -1 ||
      professionalName.toLowerCase().indexOf(searchInput) !== -1
    );
  };

  const showPro = (professionalId = 0, professionals: Professional[] = []) => {
    const pro = professionals.find(R.propEq('id', professionalId)) || {} as Professional;
    return pro?.firstName + ' ' + pro?.lastName;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    setApptTypes([]);
    save({ types: [] });
  };

  return (
    <Grid>
      <Grid marginRight={0.5}>
        <HeaderButton
          title={`${selectedTypes.length > 0 ? selectedTypes.length : ''} ${title || 'Appt. Types'}`}
          active={selectedTypes.length > 0}
          type='filter'
          Icon={Icon}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          onlyIcon={onlyIcon}
        />
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid width={420}>
          <FilterHeader
            title={title || 'Appointment Type'}
            reset={handleReset}
            resetDisabled={selectedTypes?.length === 0}
          />
          <Grid padding={1} paddingTop={2} paddingBottom={2}>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={list}
              getOptionLabel={(option: ApptType) => `${option.internalName || ''} (${option.name || ''})`}
              ListboxProps={{ className: classes.menuPaper }}
              onChange={(_e, value) => handleSelect(value)}
              value={selectedTypes}
              groupBy={(option) => option.professionalName}
              disableCloseOnSelect
              disableClearable
              autoHighlight
              filterOptions={filterOptions}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderTags={(value: readonly ApptType[], getTagProps) =>
                value.map((option, index: number) => (
                  <Grid key={option.id} {...getTagProps({ index })} className={classes.item}>
                    {`${option.internalName} ${showPro(option.professionalId, professionals)}`}&nbsp;
                    <CloseIcon onClick={() => handleDelete(index)} />
                  </Grid>
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search Appt Type"
                  variant='outlined'
                  size='small'
                  className={classes.input}
                />
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
};

export default FilterApptTypes;
