import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 12,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  icon: {
    width: 17,
    height: 17,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  button: {
    padding: 6,
    border: '1px solid transparent',

    '&:hover': {
      border: '1px solid #F2F2F3',
      background: '#F2F2F3'
    },
  },
  grid: {
    padding: 15,
    paddingTop: 6,
    paddingBottom: 6,
    '& > div': {
      marginTop: 4,
      marginBottom: 4,
    },
    '& > button': {
      marginTop: 4,
      marginBottom: 4,
    }
  }
}));
