import React from 'react';

type Props = {
  color?: string;
};

const PatientPortalIcon = ({ color }: Props) => {
  return (
    <svg id="uuid-52b20272-8c7d-4545-918d-4279ad2da2e6" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <g id="uuid-e1719e9c-be21-4fbc-a1c2-cb8218c7a066" data-name="Group 8403" fill={color || '#FFF'}>
        <path id="uuid-fcc219b7-9364-4530-b62d-4d6ddcc78e94" data-name="Path 6369" d="m44.252,4.5817H5.748C2.5735,4.5817,0,7.1552,0,10.3297h0v29.3405C0,42.8448,2.5735,45.4183,5.748,45.4183h38.5039c3.1746,0,5.748-2.5735,5.748-5.748V10.3297c0-3.1746-2.5735-5.748-5.748-5.748m-11.5282,35.5774H4.7168v-10.3105h28.0069v10.3105Zm0-12.7696H4.7168v-10.4477h28.0069v10.4477Zm12.2207,12.7696h-9.9696v-23.2173h9.9696v23.2173Z" />
      </g>
    </svg>
  );
};

export default PatientPortalIcon;
