import React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import * as rawActions from './recurring.actions.jsx';
import { reset } from './routes/RecurringEdit/recurring-edit.actions.jsx';
import {
  CircularProgress,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import * as R from 'ramda';
import { PopupTemplate } from '../../services/Popup.js';
import { MessageTable, sortOptions } from './components/messages-table.component.jsx';
import { FeatureDisabled } from '../../components/feature-disabled.component';
import { useTitle } from '../../services/useTitle.js';
import { Folders, currentPath } from '../../components/Folders/folders.component';
import Header from '../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import FilterText from '../../components/FilterText/FilterText.component';
import HeaderTabs from '../../components/HeaderTabs/HeaderTabs.component';
import FilterSelect from '../../components/FilterSelect/FilterSelect.component';
import Container from '../../components/Container/Container.component';

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
  },
  headerRow: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  spacer: {
    flexGrow: 1
  },
  buttonLabel: {
    textTransform: 'none',
    fontWeight: 'normal'
  }
}));

const MessagesTemplate = ({ history }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const actions = bindActionCreators(rawActions, dispatch);

  const {
    busy,
    all,
    state,
    tz,
    query,
    filter,
    noFolder,
    office,
    hasFeature,
    recalls,
    recallPage,
    recallTotalCount,
    recallTotalPages,
    tab,
    templates,
    officeTemplates,
    features,
    templatePage,
    totalTemplatePages,
    totalTemplateCount,
    officeTemplatePage,
    totalOfficeTemplatePages,
    totalOfficeTemplateCount,
    headerHeight,
  } = useSelector((state) => ({
    ...state.recurring,
    tz: R.pathOr(null, ['login', 'office', 'timezone'])(state),
    office: R.pathOr(null, ['login', 'office'])(state),
    features: R.pathOr([], ['login', 'features'])(state),
    hasFeature: R.includes('ReactivationCampaigns', R.pathOr([], ['login', 'features'], state)),
    headerHeight: state.login.headerHeight,
  }));

  const [search, setSearch] = React.useState({
    recurring: '',
    templates: '',
    'sked-templates': '',
  });
  const [folderHeight, setFolderHeight] = React.useState(0);
  const [sort, setSort] = React.useState({
    recurring: 'Enabled',
    templates: 'Enabled',
  });

  useTitle('Reactivation Messages');

  React.useEffect(() => {
    actions.getRecallMessages(history, features);
    dispatch(reset());
  }, [office]);

  if (!hasFeature) {
    return (
      <FeatureDisabled title="Reactivation Messages">
        <p>
          Automatically send patients a message if they have not been in for an appointment in awhile.  This reminds them to come back in!
        </p>
      </FeatureDisabled>
    );
  }

  React.useEffect(() => {
    if (state === 'COPY_REACTIVATION') {
      history.push('/reactivation/edit/0');
    }
  }, [state]);

  const handleChange = (value) => {
    if (tab === 'recurring') {
      actions.selectAll({ type: 'recalls', value: false });
      actions.messagesPatch('query', value);
      setSearch({ ...search, [tab]: value });
      actions.search({ type: 'recurring', messageType: 'Recall', query: value, page: recallPage, filter });
      return;
    }
    if (tab === 'templates') {
      actions.selectAll({ type: 'officeTemplates', value: false });
      actions.messagesPatch('query', value);
      setSearch({ ...search, [tab]: value });
      actions.search({ type: 'officeTemplate', messageType: 'Recall', query: value, page: officeTemplatePage, filter });
      return;
    }
    if (tab === 'sked-templates') {
      actions.messagesPatch('query', value);
      setSearch({ ...search, [tab]: value });
      actions.search({ type: 'template', messageType: 'Recall', features, query: value, page: templatePage, filter });
    }
  };

  const handleSort = (value) => {
    setSort({ ...sort, [tab]: value });
    const sortBy = sortOptions.find(({ label }) => value === label).value;
    if (tab === 'templates') {
      actions.messagesPatch('filter', sortBy);
      const path = ['msg', 'rcr', 'tmp', 'rc'];
      const curPath = path.join('.');
      let inPath = currentPath[curPath] ? currentPath[curPath] : undefined;
      if (noFolder) {
        inPath = path;
      }
      actions.selectAll({ type: 'officeTemplates', value: false });
      actions.search({
        type: 'officeTemplate',
        messageType: 'Recall',
        query,
        page: officeTemplatePage,
        filter: sortBy,
        noFolder,
        path: inPath,
      });
    }
  };

  const heightDiff = headerHeight + folderHeight + 45;

  return (
    <>
      <Header
        title='Reactivation Messages'
        pageId="reactivation-messages"
        helpMaxWidth={935}
        leftIcons={[
          <HeaderTabs
            value={tab}
            setTab={(value) => actions.messagesPatch('tab', value)}
            tabs={[
              { label: 'Messages', value: 'recurring', className: 'sked-test-reactivation-messages' },
              { label: 'My Templates', value: 'templates', className: 'sked-test-reactivation-templates' },
              { label: 'SKED Templates', value: 'sked-templates', className: 'sked-test-reactivation-sked-templates' },
            ]}
          />,
        ]}
        rightIcons={[
          <Grid>
            {busy && <CircularProgress size={18} />}
          </Grid>,
          tab === 'templates' ?
            <FilterSelect
              items={sortOptions.map(item => item.label)}
              selected={sort[tab]}
              setSelected={handleSort}
              Icon={SwapVertIcon}
              noActionButton
              title='Sort'
              headerTitle='Sort By'
              noneText='Enabled'
              type='sort'
            /> : <div />,
          <FilterText
            value={search[tab]}
            headerTitle='Search'
            onChange={handleChange}
            helperText='Search all messages'
          />,
          <HeaderButton title='New Message' className='sked-test-reactivation-compose' Icon={AddIcon} onClick={() => history.push('/reactivation/edit/0')} />,
        ]}
        onlyIconsWidth={tab === 'templates' ? 990 : 893}
        breakPoints={tab === 'templates' ? [
          {
            width: 849,
            mobileItems: [2, 4]
          },
          {
            width: 795,
            mobileItems: [0]
          },
        ] : [
          {
            width: 795,
            mobileItems: [0]
          },
        ]}
      />
      <div className={classes.root}>
        <PopupTemplate />
        <Container getHeight={setFolderHeight} paddingX={2}>
          {tab === 'recurring' && (
            <Folders
              path={['msg', 'rcr', 'rc']}
              deselect={Boolean(query)}
              onClick={(path) => {
                actions.selectAll({
                  type: 'recalls',
                  value: false,
                });
                actions.search({
                  type: 'recurring',
                  messageType: 'Recall',
                  filter,
                  path,
                  page: 1,
                });
              }}
            />
          )}
          {tab === 'templates' && (
            <Folders
              path={['msg', 'rcr', 'tmp', 'rc']}
              deselect={Boolean(query)}
              onClick={(path) => {
                actions.selectAll({
                  type: 'officeTemplates',
                  value: false,
                });
                actions.search({
                  type: 'officeTemplate',
                  messageType: 'Recall',
                  filter,
                  path,
                  page: 1,
                });
              }}
            />
          )}
          {tab === 'sked-templates' && (
            <Folders
              template
              path={['tmp', 'msg', 'rcr', 'rc']}
              deselect={Boolean(query)}
              onClick={(path) => actions.search({
                type: 'template',
                messageType: 'Recall',
                features,
                filter,
                path,
                page: 1,
              })}
            />
          )}
        </Container>
        <div hidden={tab !== 'recurring'}>
          <MessageTable
            messageType='recalls'
            busy={busy}
            messages={recalls}
            url={'/reactivation/view/'}
            all={all}
            tz={tz}
            selectAll={R.pipe(R.assoc('type', 'recalls'), actions.selectAll)}
            selectMessage={R.pipe(R.assoc('type', 'recalls'), actions.selectMessage)}
            copyMessage={R.partial(actions.copyMessage, ['COPY_REACTIVATION', false])}
            deleteMessage={actions.deleteMessage}
            deleteSelected={actions.deleteSelected}
            toggle={(m) => actions.toggleEnable(m, 'recalls')}
            page={recallPage}
            query={query}
            filter={filter}
            totalPages={recallTotalPages}
            totalCount={recallTotalCount}
            messagesPatch={actions.messagesPatch}
            hideSearch
            error=''
            path={['msg', 'rcr', 'rc']}
            heightDiff={heightDiff}
          />
        </div>
        <div hidden={tab !== 'templates'}>
          <MessageTable
            messageType='officeTemplates'
            url={'/reactivation/edit/0'}
            busy={busy}
            messages={officeTemplates}
            all={all}
            tz={tz}
            filter={filter}
            selectAll={R.pipe(R.assoc('type', 'officeTemplates'), actions.selectAll)}
            selectMessage={R.pipe(R.assoc('type', 'officeTemplates'), actions.selectMessage)}
            copyMessage={R.partial(actions.copyMessage, ['COPY_REACTIVATION', true])}
            deleteMessage={(d) => actions.deleteMessage(R.merge(d, { type: 'template/office/recurringmessage' }))}
            deleteSelected={(d) => actions.deleteSelected(R.merge(d, { type: 'template/office/recurringmessage' }))}
            messagesPatch={actions.messagesPatch}
            search={R.pipe(
              R.assoc('type', 'officeTemplate'),
              R.assoc('messageType', 'Recall'),
              R.assoc('filter', filter),
              actions.search
            )}
            page={officeTemplatePage}
            totalPages={totalOfficeTemplatePages}
            totalCount={totalOfficeTemplateCount}
            pageName={'templates'}
            error=''
            path={['msg', 'rcr', 'tmp', 'rc']}
            heightDiff={heightDiff}
          />
        </div>
        <div hidden={tab !== 'sked-templates'}>
          <MessageTable
            busy={busy}
            url={'/reactivation/edit/0'}
            messages={templates}
            tz={tz}
            copyMessage={R.partial(actions.copyMessage, ['COPY_REACTIVATION', false])}
            pageName={'sked-templates'}
            error='Error: Failed to get templates'
            page={templatePage}
            totalPages={totalTemplatePages}
            totalCount={totalTemplateCount}
            query={query}
            filter={filter}
            messagesPatch={actions.messagesPatch}
            search={R.pipe(
              R.assoc('type', 'template'),
              R.assoc('messageType', 'Recall'),
              R.assoc('filter', filter),
              R.assoc('features', features),
              actions.search
            )}
            error=''
            heightDiff={heightDiff}
          />
        </div>
      </div>
    </>
  );
};

export default MessagesTemplate;
