import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  TextField,
  Button,
  InputAdornment,
  CircularProgress,
  IconButton,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Search from '@mui/icons-material/Search';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { outputLocation } from '../../services/utilities.js';
import {
  includes, pathOr, map, prop, isEmpty, append,
  without, find, equals, path,
} from 'ramda';
import { PageHeader, Row } from '../../components/PageHeader';
import { useTitle } from '../../services/useTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import * as actions from './clientlists.actions';
import {
  tzParseFormat,
  format,
} from '../../services/joda.js';
import {
  LocalDate,
} from '@js-joda/core';
import { usePromise } from '../../services/promise.hook';
import { FeatureDisabled } from '../../components/feature-disabled.component';
import { SearchClientsDialog } from './components/search-clients-dialog.component';
import { History } from 'history';
import { Client } from '../Clients/clients.types';
import { RowContainerProps, ClientWithList } from './clientlist-types';

const options = [
  { value: 25, label: '25' },
  { value: 50, label: '50' },
  { value: 75, label: '75' },
  { value: 100, label: '100' },
];


const useStyles = makeStyles(() => ({
  pageControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '5px'
  },
  pageArrows: {
    marginLeft: '0px',
    marginRight: '0px',
    display: 'flex',
    alignItems: 'center',
  },
  headerRow: {
    marginBottom: '10px',
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));


const parseBirthday = (birthday: string) => {
  try {
    return format(LocalDate.parse(birthday), 'MM/dd/yyyy');
  } catch (e) {
    return '';
  }
};

const RowContainer = ({
  clientWithList,
  tz,
  select,
  isSelected,
  onDelete,
}: RowContainerProps) => {
  const [selected, setSelected] = useState(isSelected);

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  const {
    client,
    created,
    optout,
  } = clientWithList;

  const {
    firstName,
    lastName,
    birthday,
    phone,
    email,
    id,
  } = client;

  return (
    <TableRow key={id}>
      <TableCell className="TableCell-name">
        <Checkbox
          checked={Boolean(selected)}
          onChange={(e) => {
            const checked = e.target.checked;
            setSelected(checked);
            select(checked);
          }}
        />
      </TableCell>
      <TableCell>
        <Link to={`client-list/${id}`}>
          {firstName} {lastName}
        </Link>
      </TableCell>
      <TableCell>
        {parseBirthday(birthday)}
      </TableCell>
      <TableCell>
        {phone}
      </TableCell>
      <TableCell>
        {email}
      </TableCell>
      <TableCell>
        {tzParseFormat(created, tz, 'MM/dd/yyyy h:mm a')}
      </TableCell>
      <TableCell>
        {optout && tzParseFormat(optout, tz, 'MM/dd/yyyy h:mm a')}
      </TableCell>
      <TableCell>
        <Tooltip arrow title='Delete List' placement='top'>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

interface ClientListProps {
  history: History;
  match: any;
}

interface SearchClients {
  id?: number;
  page?: number;
  pp?: number;
}

let timeout: any = null;
const ClientList = ({ history, match }: ClientListProps) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [listId, setListId] = useState(0);
  const [state, setState] = useState('LIST');
  const [editName, setEditName] = useState('');
  const [time, setTime] = useState(0);

  const editRef = useRef(null);

  const clientListState = usePromise(actions.getClientList, {});
  const clientListClientsState = usePromise(actions.getClientListClients, { data: [] });
  const addClientsState = usePromise(actions.addClients, {});
  const removeClientsState = usePromise(actions.removeClients, []);
  const saveClientListState = usePromise(actions.saveClientList, {});

  useTitle('Client List');

  const {
    data,
    perPage,
    page,
    totalCount,
    totalPages,
  } = clientListClientsState.data;

  const {
    office,
    hasFeature,
  } = useSelector((state: any) => ({
    office: state.login.office,
    hasFeature: includes('Campaigns', pathOr([], ['login', 'features'], state))
  }));

  const searchClients = ({
    id,
    pp = 25,
  }: SearchClients) => {
    const splitUp = search.split(' ');
    const lastName = splitUp[0] ? splitUp[0].trim() : undefined;
    const firstName = splitUp[1] ? splitUp[1].trim() : undefined;
    const phone = splitUp[2] ? splitUp[2].trim() : undefined;
    const query = {
      client: {
        firstName,
        lastName,
        phone,
      },
      listId: id ? id : listId,
    };
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      clientListClientsState.invoke({
        page: 1,
        query,
        perPage: pp,
      });
    }, time);
  };

  const urlId = Number(pathOr('0', ['params', 'id'], match));

  React.useEffect(() => {
    if (urlId !== 0) {
      clearTimeout(timeout);
      if (search === '') {
        clientListState.invoke({ listId: urlId });
      }
      searchClients({ id: urlId, pp: perPage });
      setTime(500);
      setListId(urlId);
    }
  }, [search]);

  useEffect(() => {
    if (state === 'EDIT' && editRef.current) {
      editRef.current.focus();
      editRef.current.select();
    }
  }, [state]);


  if (!hasFeature) {
    return (
      <FeatureDisabled title="Client List">
        <p>Tell me something about Client List</p>
      </FeatureDisabled>
    );
  }

  const { name } = clientListState.data;

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchClients({});
    }
  };

  const saveClientList = () => {
    saveClientListState.invoke({ name: editName, listId }).then(() => {
      setState('LIST');
      clientListState.invoke({ listId });
    });
  };

  const handledEditKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      saveClientList();
    }
  };

  const addPage = (num: number) => () => {
    const p = page + num;
    searchClients({ page: p, pp: perPage });
  };

  const onPerPageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    searchClients({ page, pp: Number(value) });
  };

  return (
    <div
      className="page-root"
      style={{ margin: '20px' }}>
      <div style={{ paddingBottom: '100px' }}>
        {/* <HelpDialog */}
        {/*   pageId={ehrSystem === 'None' ? "clients-tab-sas" : "clients-tab"} */}
        {/*   handleClose={helpState.handleClose} */}
        {/*   open={helpState.isOpen} /> */}
        <SearchClientsDialog
          isOpen={state === 'ADD'}
          close={() => setState('LIST')}
          ehrSystem={office.ehrSystem}
          loading={addClientsState.loading}
          save={(clients: Client[]) => {
            const clientIds = map(prop('id'), clients);
            addClientsState.invoke({ listId, clientIds }).then(() => {
              searchClients({ pp: perPage });
              setState('LIST');
            });
          }}
        />
        <Row className={classes.headerRow} style={{ justifyContent: 'flex-start' }}>
          <PageHeader align="left" variant="h5">
            {(name && state !== 'EDIT') && name}
            {(!name && state !== 'EDIT') && 'Client List'}
          </PageHeader>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}>
            {state === 'EDIT' &&
              <TextField
                inputRef={editRef}
                value={editName}
                label='List Name'
                onChange={(e) => setEditName(e.target.value)}
                onKeyPress={handledEditKeyPress}
              />}
            &nbsp;
            {state === 'EDIT' && !saveClientListState.loading &&
              <Tooltip arrow title='Save'>
                <IconButton onClick={saveClientList}>
                  <SaveIcon fontSize='small' />
                </IconButton>
              </Tooltip>}
            {state === 'EDIT' && !saveClientListState.loading &&
              <Tooltip arrow title='Cancel Edit'>
                <IconButton onClick={() => setState('LIST')}>
                  <CancelIcon fontSize='small' />
                </IconButton>
              </Tooltip>}
            {state === 'LIST' && !saveClientListState.loading &&
              <Tooltip arrow title='Edit List Name'>
                <IconButton onClick={() => {
                  setEditName(name);
                  setState('EDIT');
                }}>
                  <EditIcon fontSize='small' />
                </IconButton>
              </Tooltip>}
            {(saveClientListState.loading || clientListState.loading) &&
              <CircularProgress size={20} />}
          </div>
        </Row>
        <Row className={classes.headerRow}>
          <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}>
            <Button
              variant='outlined'
              onClick={history.goBack}
              startIcon={<ChevronLeftIcon />}
            >
              Back
            </Button>
            <Button
              variant='contained'
              onClick={() => setState('ADD')}
            >
              Add Clients
            </Button>
          </div>
        </Row>
        <Row style={{
          justifyContent: 'flex-end',
        }}>
          <TextField
            name="search"
            label="Search"
            placeholder='LastName FirstName'
            value={search}
            onKeyPress={onKeyPress}
            onChange={onTextChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search style={{ fontSize: '16px' }} />
                </InputAdornment>
              ),
            }}
          />
        </Row>
        {(clientListState.loading || clientListClientsState.loading) &&
          <CircularProgress />}
        {!clientListClientsState.loading &&
          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Checkbox
                      checked={Boolean(selectAll)}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        setSelectAll(checked);
                        if (checked) {
                          setSelected(map(path(['client', 'id']), data));
                        } else {
                          setSelected([]);
                        }
                      }}
                    />
                    {removeClientsState.loading && <CircularProgress />}
                    {!isEmpty(selected) &&
                      <IconButton
                        onClick={() => {
                          removeClientsState.invoke({
                            clientIds: selected,
                            listId,
                          }).then(() => {
                            searchClients({ pp: perPage });
                            setSelected([]);
                            setSelectAll(false);
                          });
                        }}>
                        <DeleteIcon />
                      </IconButton>}
                  </TableCell>
                  <TableCell>
                    Name
                  </TableCell>
                  <TableCell>
                    Birthday
                  </TableCell>
                  <TableCell>
                    Phone
                  </TableCell>
                  <TableCell>
                    Email
                  </TableCell>
                  <TableCell>
                    Add to List
                  </TableCell>
                  <TableCell>
                    Opted Out
                  </TableCell>
                  <TableCell>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((s: ClientWithList) => (
                  <RowContainer
                    key={s.client.id}
                    clientWithList={s}
                    tz={office.timezone}
                    isSelected={find(equals(s.client.id), selected)}
                    select={(isSelected) => {
                      if (isSelected) {
                        setSelected(append(s.client.id, selected));
                      } else {
                        setSelected(without([s.client.id], selected));
                        if (selectAll) {
                          setSelectAll(false);
                        }
                      }
                    }}
                    onDelete={() => {
                      removeClientsState.invoke({
                        clientIds: [s.client.id],
                        listId,
                      }).then(() => {
                        searchClients({ pp: perPage });
                      });
                    }}
                    pageType='ClientList'
                    urlId={urlId}
                    history={history}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
        {!isEmpty(data) &&
          <div className={classes.pageControls}>
            <FormControl style={{ width: '100px' }}>
              <InputLabel id="limit-label">Items per Page</InputLabel>
              <Select
                labelId="limit-label"
                id="limit-search"
                value={perPage}
                onChange={onPerPageChange}
              >
                {options.map(opt =>
                  <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                )}
              </Select>
            </FormControl>
            {clientListClientsState.loading && <CircularProgress size={30} />}
            <div className={classes.pageArrows}>
              <div style={{ marginRight: '5px' }}>
                {outputLocation(
                  page,
                  perPage,
                  totalCount,
                  data.length
                )}
              </div>
              <IconButton
                disabled={page === 1}
                onClick={addPage(-1)} >
                <ChevronLeftIcon />
              </IconButton>
              <IconButton
                disabled={page === totalPages}
                onClick={addPage(1)} >
                <ChevronRightIcon />
              </IconButton>
            </div>
          </div>}
      </div>
    </div>
  );
};

export default ClientList;
