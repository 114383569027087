import * as at from '../../actionTypes';
import api from '../../services/api.js';
import { remoteAction } from '../../services/actionService.js';
// import {} from 'ramda';

export const stopsPatch = (data) => ({
  type: at.STOPMANAGER_PATCH,
  data,
});

export const getStopManagers = () => remoteAction({
  type: at.STOPMANAGER_REMOTE_GET,
  action: () => api.post('stopmanager/template/group/query/', {
    page: 1,
    perPage: 25,
    query: {},
  }).then((groups) => {
    return Promise.all(groups.data.map((group) =>
      api.post('stopmanager/template/query/', {
        page: 1,
        perPage: 50,
        query: {
          groupId: group.groupId,
        },
      }).then((stops) => {
        return ({
          ...group,
          stops: stops.data,
        });
      }))
    ).then((stops) => {
      return ({
        ...groups,
        data: stops,
      });
    });
  }),
});

export const saveStop = (stop) => stop.id ?
  remoteAction({
    type: at.STOPMANAGER_REMOTE_SAVE_PUT,
    action: () => api.put(`stopmanager/template/${stop.id}`, stop),
  }) :
  remoteAction({
    type: at.STOPMANAGER_REMOTE_SAVE_POST,
    action: () => api.post('stopmanager/template', [stop]),
  });

export const newGroup = () => remoteAction({
  type: at.STOPMANAGER_REMOTE_NEW_GROUP_POST,
  action: () => api.post('stopmanager/template/group', { name: 'New Group' }),
});

export const stopsToPromise = (group) => (s) => {
  if (s.msgId) {
    return api.get(`message/${s.msgId}`).then((message) => {
      return api.post('message', message).then((newMessage) => {
        return ({
          ...s,
          groupId: group.groupId,
          enabled: Boolean(s.enabled),
          id: undefined,
          msgId: newMessage.id,
        });
      });
    });
  } else {
    return Promise.resolve(({ ...s, groupId: group.groupId, enabled: false, id: undefined }));
  }
};

export const copyGroup = (group) => remoteAction({
  type: at.STOPMANAGER_REMOTE_NEW_GROUP_POST,
  action: () => api.post('stopmanager/template/group', { name: group.name + ' (Copy)' }).then((newGroup) => {
    const stopsPromises = group.stops.map(stopsToPromise(group));
    return Promise.all(stopsPromises).then((stops) => {
      return api.post('stopmanager/template', stops).then((newStops) => {
        return ({
          ...newGroup,
          stops: newStops,
        });
      });
    });
  }),
});

export const copyStopsToGroup = (group, idx, stops) => {
  const newStopsPromises = stops.map(stopsToPromise(group));
  return remoteAction({
    type: at.STOPMANAGER_REMOTE_COPIED_STOPS_GROUP_POST,
    action: () => Promise.all(newStopsPromises).then((newStops) => {
      return api.post('stopmanager/template', newStops).then((freshStops) => {
        return ({
          stops: freshStops,
          idx,
        });
      });
    }),
  });
};
