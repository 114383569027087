import React, { useEffect, useRef } from 'react';

import { Grid, GridProps } from '@mui/material';

interface Props extends GridProps {
  getWidth?: (value: number) => void;
  getHeight?: (value: number) => void;
}

const Container = ({ getHeight, getWidth, children, ...props }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      getHeight?.(ref.current.clientHeight);
      getWidth?.(ref.current.clientWidth);
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, []);

  return (
    <Grid ref={ref} {...props}>
      {children}
    </Grid>
  );
};

export default Container;
