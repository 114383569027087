import { Dispatch } from 'redux';
import * as R from 'ramda';
import * as at from '../../../../actionTypes';
import { remoteAction } from '../../../../services/actionService.js';
// import { getTypesApi } from '../../../Messages/routes/MessagesNew/messages-new.actions.js';
import api from '../../../../services/api.js';
import { createPath } from '../../../ClientLists/clientlists.actions';
import { Message } from '../../templates.reducer';
import { Email, Sms, Push } from './templates-edit.reducer';
import { RootState } from '../../../../reducers';

const singularUnits = [
  { name: 'Week', id: 1 },
  { name: 'Day', id: 2 },
  { name: 'Hour', id: 3 },
  { name: 'Minute', id: 4 },
];
const pluralUnits = [
  { name: 'Weeks', id: 1 },
  { name: 'Days', id: 2 },
  { name: 'Hours', id: 3 },
  { name: 'Minutes', id: 4 },
];

export type MessageMassPatch = {
  isEmail?: boolean;
  isSMS?: boolean;
  isPush?: boolean;
  status?: string;
  templateId?: number;
  email?: Email;
  sms?: Sms;
  push?: Push;
}

export const messageMassPatch = (data: MessageMassPatch) => (dispatch: Dispatch) =>
  dispatch({
    type: at.TEMPLATE_EDIT_PATCH,
    data,
  });

export const messagesPatch = (prop: string, value: string) => (dispatch: Dispatch) =>
  dispatch({
    type: at.TEMPLATE_EDIT_PATCH,
    data: {
      [prop]: value,
    }
  });


/*
  This is named `saveMessage` and not `saveTemplate` so it works with the
 `CreateMessage` component.
*/
export const saveMessage = (template: Message, id: string | number) => {
  console.log(template);
  return remoteAction({
    type: at.TEMPLATE_EDIT_SEND,
    action: () => id === '0' ?
      api.post('message/template', template) :
      api.put(`message/template/${id}`, template)
  });
};


export const saveRecurring = (message: Message, id: number | string) => remoteAction({
  type: at.TEMPLATE_EDIT_SEND,
  action: () => id === '0' ?
    api.post('template/recurringmessage', R.assoc('isEnabled', false, message)) :
    api.put(`template/recurringmessage/${id}`, R.assoc('isEnabled', false, message))
});

export const saveOneTime = (message: Message, id: number | string) => remoteAction({
  type: at.TEMPLATE_EDIT_SEND,
  action: () => id === '0' ?
    api.post('template/onetimemessage', R.assoc('isEnabled', false, message)) :
    api.put(`template/onetimemessage/${id}`, R.assoc('isEnabled', false, message))
});

export const cancelMessage = () => remoteAction({
  type: at.TEMPLATE_EDIT_SEND,
  action: () => Promise.resolve()
});

export const reset = () => (dispatch: Dispatch) =>
  dispatch({
    type: at.TEMPLATE_EDIT_RESET,
  });

type GetUnitProps = {
  Minute: number;
  Hour: number;
  Day: number;
  Week: number;
}

const getUnit = (time: GetUnitProps) => R.cond([
  [R.has('Minute'), R.always([3, R.prop('Minute')(time)])],
  [R.has('Hour'), R.always([2, R.prop('Hour')(time)])],
  [R.has('Day'), R.always([1, R.prop('Day')(time)])],
  [R.T, R.always([0, R.prop('Week')(time)])],
])(time);

type EmailProps = {
  subject: string;
  body: string;
  html?: string;
  shouldFrame?: boolean;
  attachments?: string[];
}

type SmsProps = {
  subject: string;
  body: string;
  attachmentIds?: string[];
}

export const loadingMessage = async (message: Message) => {
  // getAttachments([
  //   R.pathOr([], ['email', 'attachmentIds'], message),
  //   R.pathOr([], ['sms', 'attachmentIds'], message),
  // ]).then(([emailOfficeAttach, smsOfficeAttach]) => {
  const emailAttachments: string[] = []; // R.propOr([], 'officeAttachments')(emailOfficeAttach);
  const smsAttachments: string[] = []; //R.propOr([], 'officeAttachments')(smsOfficeAttach);
  // const type = R.cond([
  //   [R.has('appointmentSearch'), R.always('recurring')],
  //   [R.has('recievers'), R.always('oneTime')],
  //   [R.T, R.always('')]
  // ])(message);
  const subject = R.cond([
    [R.prop('email'), () => message.email.subject],
    [R.prop('push'), () => message.push.subject],
    [R.prop('sms'), () => ''],
    [R.T, () => R.propOr('', 'subject')(message)],
  ])(message);
  const body = R.cond([
    [R.prop('email'), () => R.ifElse(
      R.is(Object),
      R.propOr('', 'PlainText'),
      R.identity,
    )(message.email.body)],
    [R.prop('push'), () => message.push.body],
    [R.prop('sms'), () => message.sms.body],
    [R.T, () => R.propOr('', 'body')(message)],
  ])(message);
  const def = {
    subject: '',
    body: '',
  };
  const maybeEmail: EmailProps = R.propOr(def, 'email')(message);
  const maybeSms: SmsProps = R.propOr(def, 'sms')(message);
  const email = R.ifElse(
    (props: EmailProps) => !!R.identity(props),
    R.always({
      subject: maybeEmail.subject,
      body: R.pathOr('', ['body', 'PlainText'])(maybeEmail),
      html: R.pathOr(null, ['body', 'HTML', 'body'])(maybeEmail),
      shouldFrame: R.pathOr(false, ['body', 'HTML', 'shouldFrame'])(maybeEmail),
      attachments: emailAttachments,
    }),
    R.always(def)
  )(maybeEmail);
  const sms = R.ifElse(
    (props: SmsProps) => !!R.identity(props),
    () => R.assoc('attachments', smsAttachments)(maybeSms),
    R.always(def)
  )(maybeSms);
  const base = {
    messageName: message.name,
    body,
    subject,
    isEmail: Boolean(message.email),
    isPush: Boolean(message.push),
    isSMS: Boolean(message.sms),
    email,
    sms: R.dissoc('subject', sms),
    push: R.propOr(def, 'push')(message),
    isEnabled: message.isEnabled,
    templateType: R.pipe(R.prop('messageType'), R.keys, R.head)(message),
    forFeature: message.forFeature,
  };
  return R.cond([
    [R.has('OneTime'), () => {
      const OneTime = message.messageType.OneTime;
      return {
        ...base,
        filter: OneTime.receivers,
        includeInactive: OneTime.includeInactive,
      };
    }],
    [R.has('Recurring'), () => {
      const Recurring = message.messageType.Recurring;
      const search = getUnit(Recurring.time);
      return {
        ...base,
        search,
        value: search[1],
        unit: search[1] > 1 ? pluralUnits[search[0]] : singularUnits[search[0]],
      };
    }],
    [R.has('Recall'), () => {
      const {
        days,
        sendAt,
        includeInactive,
      } = message.messageType.Recall;
      return {
        ...base,
        value: days,
        sendAt,
        includeInactive,
      };
    }],
    [R.has('Birthday'), () => {
      const {
        sendAt,
      } = message.messageType.Birthday;
      return {
        ...base,
        sendAt,
      };
    }],
    [R.has('ApptChange'), () => {
      const {
        delay,
        source,
        changeType,
      } = message.messageType.ApptChange;
      return {
        ...base,
        delay,
        changeType,
        source,
      };
    }],
    [R.T, () => {
      return base;
    }]
  ])(message.messageType);
  // });
};

export const loadMessage = (id: string) => remoteAction({
  type: at.TEMPLATE_EDIT_REMOTE_GET,
  action: () => api.get(`message/template/${id}`).then((message) => {
    return loadingMessage(message);
  })
});

type GetStore = () => RootState

export const addAutomationTemplateToCampaign = (automation: { id: number } = null) => (dispatch: Dispatch, getStore: GetStore) => {
  const msgId = R.pathOr(null, ['templateEdit', 'justSentMsgId'], getStore());
  const campaignId = R.pathOr(null, ['templateEdit', 'campaignId'], getStore());
  return remoteAction({
    type: at.TEMPLATE_EDIT_SAVE_INTO_PATH,
    action: () => {
      return createPath({
        id: msgId,
        campaignId,
        resourceType: 'Message'
      }).then(() => {
        if (automation)
          return createPath({
            id: automation.id,
            campaignId,
            resourceType: 'Automation',
            customPath: `tmp.cl.cmp_${campaignId}.msg_${msgId}.auto_${automation.id}`,
          });
        return null;
      });
    }
  });
  // return dispatch(remoteAction({
  //   type: at.TEMPLATE_EDIT_SAVE_INTO_PATH,
  //   action: () => {
  //     return createPath({
  //       id: msgId,
  //       campaignId,
  //       resourceType: 'Message'
  //     }).then(() => {
  //       if (automation)
  //         return createPath({
  //           id: automation.id,
  //           campaignId,
  //           resourceType: 'Automation',
  //           customPath: `tmp.cl.cmp_${campaignId}.msg_${msgId}.auto_${automation.id}`,
  //         });
  //       return null;
  //     });
  //   },
  // }));
};

export const addCampaign = (campaignId: number) => ({
  type: at.TEMPLATE_EDIT_PATCH,
  data: { campaignId },
});

export const addAutomationTemplateToPath = (
  automation: { id: number } = null
) => (_: Dispatch, getStore: GetStore) => {
  const msgId = R.pathOr(null, ['templateEdit', 'justSentMsgId'], getStore());
  return createPath({
    id: msgId,
    customPath: `tmp.au.msg_${msgId}`,
    resourceType: 'Message'
  }).then(() => {
    if (automation)
      return createPath({
        id: automation.id,
        resourceType: 'Automation',
        customPath: `tmp.au.msg_${msgId}.auto_${automation.id}`,
      });
  });
};
