import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const SubmissionDateIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} id="submission-date-icon" xmlns="http://www.w3.org/2000/svg" width="13.55" height="12.625" viewBox="0 0 13.55 12.625">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_11897" data-name="Rectangle 11897" width="13.55" height="12.626" transform="translate(0 0)" fill={color || '#4d4d4d'} />
        </clipPath>
      </defs>
      <g id="Group_15565" data-name="Group 15565" transform="translate(0 0)" clipPath="url(#clip-path)">
        <path id="Path_8879" data-name="Path 8879" d="M13.537,2.852A2.562,2.562,0,0,0,10.448.034V.223c0,.419.006.839,0,1.258A.575.575,0,1,1,9.3,1.475c0-.437,0-.874,0-1.311A.743.743,0,0,1,9.323.03C9.248.023,9.172.011,9.1.011H4.436c-.063,0-.127.006-.19.008,0,.488,0,.975,0,1.463a.575.575,0,1,1-1.148,0C3.093.994,3.093.506,3.091.018A3.7,3.7,0,0,0,1.624.16a2.5,2.5,0,0,0-1.6,2.692Z" transform="translate(0 0)" fill={color || '#4d4d4d'} />
        <path id="Path_8880" data-name="Path 8880" d="M13.542,17.592H.013v1.046h0q0,2.57,0,5.14a2.237,2.237,0,0,0,.089.582,2.484,2.484,0,0,0,2.463,1.821c1.4.005,2.809,0,4.213,0s2.809,0,4.213,0a2.484,2.484,0,0,0,2.463-1.821,2.237,2.237,0,0,0,.089-.582q.008-2.57,0-5.14h0V17.592ZM6.116,24.515a.234.234,0,0,1-.2.072A.236.236,0,0,1,5.8,24.4c-.011-.347-.005-.694-.005-1.065l.957.326c-.219.3-.42.579-.635.85m3.274-5.08c-.135.936-.28,1.87-.421,2.805q-.1.668-.192,1.338c-.044.323-.107.364-.417.259l-2.328-.795,1.151-1.385c.371-.447.74-.895,1.079-1.374q-.6.513-1.2,1.026c-.54.462-1.077.928-1.623,1.382a.336.336,0,0,1-.27.051c-.381-.116-.758-.245-1.13-.387a1.61,1.61,0,0,1-.321-.223,1.439,1.439,0,0,1,.275-.254q2.294-1.209,4.594-2.4c.164-.086.323-.18.493-.254a.3.3,0,0,1,.24-.014.279.279,0,0,1,.074.229" transform="translate(-0.01 -13.559)" fill={color || '#4d4d4d'} />
      </g>
    </svg>
  );
};

export default SubmissionDateIcon;
