import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  resetButton: {
    padding: '2px 8px',
    fontSize: 12,
    textTransform: 'none',
    background: '#FFF',
    borderRadius: 3,
    color: 'rgba(0,0,0,.87)',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    margin: 0,
    minWidth: 50,
  },
  saveButton: {
    padding: '2px 8px',
    fontSize: 12,
    textTransform: 'none',
    borderRadius: 3,
    margin: 0,
    minWidth: 50,

    '&:disabled': {
      opacity: 0.5,
      color: '#FFF',
      background: theme.palette.primary.main
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    background: '#f7f6f6',
  },
}));
