import React, { useState, useEffect } from 'react';

import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { Grid } from '@mui/material';

import { useTitle } from '../../../services/useTitle';
import InboxList from './InboxList/InboxList.component';
import InboxChat from './InboxChat/InboxChat.component';
import { useStyles } from './inbox.styles';

import { useSelector } from '../../../reducers';

type InboxProps = {
  history?: History;
}

let timeout: NodeJS.Timeout = null;
let lastCallTime: number;

const Inbox = ({ history }: InboxProps) => {
  const classes = useStyles();
  const [size, setSize] = useState(0);
  const [minLeft, setMinLeft] = useState(0);
  const [maxWidth, setMaxWidth] = useState(0);
  const [showChat, setShowChat] = useState(true);
  const [open, setOpen] = useState(false);
  const desktopOpen = useSelector(state => state.login.desktopOpen);

  const onResize = (size: number) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      setSize(size);
    }, 200);
  };

  const updateWidth = () => {
    const now = new Date().getTime();
    if (!lastCallTime || (now - lastCallTime >= 200)) {
      const pageWidth = window.innerWidth - (desktopOpen ? 240 : 0);
      setMaxWidth(pageWidth);
      const result = 580 / pageWidth;
      const min = result * 100;
      setMinLeft(Math.round(min));
      setShowChat(pageWidth > 1080);
      setTimeout(() => {
        setSize(pageWidth);
      }, 300);
      lastCallTime = now;
    }
  };

  useTitle('SMS Inbox');

  useEffect(() => {
    updateWidth();
    function handleResize() {
      updateWidth();
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [desktopOpen]);

  useEffect(() => {
    if (open) {
      setSize(size - 1);
    }
  }, [open]);

  if (!showChat || !open) {
    return (
      <div className={classes.root}>
        <InboxList chatInNewPage={!showChat} history={history} onOpen={() => setOpen(true)} />
      </div>
    );
  }

  return (
    <Grid display="flex" className={classes.root} style={{ width: maxWidth }}>
      {minLeft > 0 && (
        <PanelGroup autoSaveId="handleResize" direction="horizontal">
          <Panel defaultSize={50} minSize={minLeft} style={{ position: 'relative' }}>
            <InboxList history={history} resizeNumber={size} />
            <PanelResizeHandle className={classes.resizeHandle} />
          </Panel>
          <Panel minSize={40} onResize={onResize}>
            <InboxChat resizeNumber={size} onClose={() => setOpen(false)} />
          </Panel>
        </PanelGroup>
      )}
    </Grid>
  );
};

export default Inbox;
