import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const TriggerIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} id="uuid-7e89b916-aa0a-4a88-8130-7b2ad4be1f6d" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <g id="uuid-6efb42a0-6cf8-43ed-a6fd-d61b9ae7c10c" data-name="Group 8268" fill={color || 'rgba(0, 0, 0, 0.6)'}>
        <path id="uuid-c020c37f-3044-4772-8a67-e022636e192a" data-name="Path 6253" d="m33.3022,49.5937v-20.5017h-8.1956L41.5278.3417l.1084.0138v20.4533h8.3639l-16.5065,28.8494-.1913-.0622"/>
        <path id="uuid-16987c3c-7c78-4ae4-afc7-3ff95614cda1" data-name="Path 6254" d="m4.1553,33.2947h25.0248c0,1.3025.0115,2.5774-.0207,3.8477,0,.1061-.2974.2836-.4611.2882-.9544.0369-1.9112.0392-2.8679.0392H4.8285c-.5256,0-.7078-.1222-.6916-.6778.0461-1.1527.0138-2.3054.0138-3.4972"/>
        <path id="uuid-25a0d25a-739b-4f96-9c0b-0eab3393bfbf" data-name="Path 6255" d="m25.0144,16.7099v3.631c0,.385-.2098.4242-.5026.4265-.9982,0-1.9941.0277-2.997.0277-5.5536,0-11.1096,0-16.6655.0138-.5302,0-.7539-.0968-.7239-.6916.0576-1.1135.0161-2.2316.0161-3.4004l20.8729-.0069Z"/>
        <path id="uuid-7edc79cf-2857-4120-9b5e-cc4cef7eb608" data-name="Path 6256" d="m.0126,24.9608h20.0106c.8138,0,.8092,0,.8092.7861,0,.9221,0,1.8328.03,2.7457.0184.4611-.1014.6386-.6109.6386-6.5519-.0154-13.1045-.0154-19.6579,0-.4403,0-.6086-.1014-.5925-.5786.0369-1.1734.0115-2.3492.0115-3.5918"/>
        <path id="uuid-af92999c-0126-437e-b87e-233425629f7b" data-name="Path 6257" d="m20.8416,8.3183h7.8037c.3758,0,.5556.0646.5441.4957-.0277,1.0351-.0161,2.0748,0,3.1076,0,.3988-.0945.5648-.551.5625-5.1425-.0154-10.285-.0154-15.4276,0-.5279,0-.7608-.106-.7262-.7124.0576-.9913.0277-1.9918,0-2.9855-.0138-.3642.1153-.4749.4403-.4726.2836,0,.5694-.0231.853-.0231h7.0729v.0277"/>
      </g>
    </svg>
  );
};

export default TriggerIcon;
