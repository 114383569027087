import React from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import * as rawActions from './messages.actions';
import { IconButton, Tooltip, Grid } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import AddIcon from '@mui/icons-material/Add';
import * as R from 'ramda';
import { reset } from './routes/MessagesNew/messages-new.actions.js';
import { PopupTemplate } from '../../services/Popup.js';
import { TemplatesTable, MessagesTable } from './components/message-table.component.jsx';
import { useTitle } from '../../services/useTitle';
import { Folders } from '../../components/Folders/folders.component.tsx';
import { FeatureDisabled } from '../../components/feature-disabled.component';
import Header from '../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import FilterText from '../../components/FilterText/FilterText.component';
import HeaderTabs from '../../components/HeaderTabs/HeaderTabs.component';

const OneTimeMessagesTemplate = ({ history }) => {
  const dispatch = useDispatch();
  const actions = bindActionCreators(rawActions, dispatch);

  const {
    busy,
    all,
    messages,
    messagePage,
    totalMessagePages,
    totalMessageCount,
    pending,
    drafts,
    filter,
    page,
    query,
    state,
    tab,
    tz,
    templates,
    office,
    officeTemplates,
    features,
    templatePage,
    totalTemplatePages,
    totalTemplateCount,
    officeTemplatePage,
    totalOfficeTemplatePages,
    totalOfficeTemplateCount,
    headerHeight,
  } = useSelector((state) => ({
    ...state.messages,
    tz: R.pathOr(null, ['login', 'office', 'timezone'])(state),
    office: R.pathOr(null, ['login', 'office'])(state),
    features: R.pathOr([], ['login', 'features'])(state),
    headerHeight: state.login.headerHeight,
  }));

  const [search, setSearch] = React.useState({
    messages: '',
    pending: '',
    drafts: '',
    template: '',
    officeTemplates: ''
  });
  const [templateTab, setTemplateTab] = React.useState('your');
  const [folderHeight, setFolderHeight] = React.useState(0);
  const ref = React.useRef(null);

  useTitle('One Time Messages');

  if (!R.includes('OneTimeMessages', features)) {
    return (
      <FeatureDisabled title="One Time Messaging">
        <p>
          Blast off messages into the void of the internet with this _epic_ and cool feature!
        </p>
      </FeatureDisabled>
    );
  }

  const handleChange = (value) => {
    const type = (tab === 'template' && templateTab === 'your') ? 'officeTemplates' : tab;
    if (!(tab === 'template' && templateTab === 'sked')) {
      actions.selectAll({ type, value: false });
    }
    actions.messagesPatch('query', value);
    actions.messagesPatch('path', null);
    setSearch({ ...search, [type]: value });
    actions.search({ type, query: value, page: messagePage, filter, features: type === 'template' ? features : undefined });
  };

  const tableMaxHeight = `calc(100vh - ${headerHeight + folderHeight}px - 45px)`;

  React.useEffect(() => {
    actions.getMessages(history, features);
    dispatch(reset());
  }, [office]);

  React.useEffect(() => {
    if (state === 'COPY') {
      history.push('/messages/new/0');
    }
  }, [state]);

  React.useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setFolderHeight?.(ref.current.clientHeight);
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up 
  }, []);

  return (
    <>
      <Header
        title='One Time Messages'
        pageId="one-time-messages"
        leftIcons={[
          <HeaderTabs
            value={tab}
            setTab={(value) => actions.messagesPatch('tab', value)}
            tabs={[
              { label: 'Outbox', value: 'messages', className: 'sked-test-one-time-messages-outbox' },
              { label: 'Pending', value: 'pending', className: 'sked-test-one-time-messages-pending' },
              { label: 'Drafts', value: 'drafts', className: 'sked-test-one-time-messages-drafts' },
              { label: 'Templates', value: 'template', className: 'sked-test-one-time-messages-templates' },
            ]}
          />,
          tab === 'template' ?
            <>
              &nbsp; &nbsp; &nbsp;
              <HeaderTabs
                value={templateTab}
                setTab={(value) => setTemplateTab(value)}
                tabs={[
                  { label: 'My Templates', value: 'your' },
                  { label: 'SKED Templates', value: 'sked' }
                ]}
              />
            </> : <div />
        ]}
        rightIcons={[
          <Tooltip placement="bottom" arrow title="Refresh">
            <IconButton className={busy ? 'sked-spin' : ''} onClick={() => actions.getMessages(history, features)}>
              <CachedIcon style={{ width: 17, height: 17 }} />
            </IconButton>
          </Tooltip>,
          <FilterText
            value={search[(tab === 'template' && templateTab === 'your') ? 'officeTemplates' : tab]}
            headerTitle='Search'
            onChange={handleChange}
            helperText='Search all messages'
          />,
          <HeaderButton title='New Message' className='sked-test-one-time-messages-compose' Icon={AddIcon} onClick={() => history.push('/messages/new/0')} />
        ]}
        onlyIconsWidth={tab === 'template' ? 1138 : 864}
        breakPoints={
          tab === 'template' ?
            [
              {
                width: 1042,
                mobileItems: [1]
              },
              {
                width: 755,
                mobileItems: [0, 1]
              },
            ] : [
              {
                width: 755,
                mobileItems: [0]
              },
            ]
        }
      />
      <div
        style={{
          padding: '20px',
          paddingTop: 0,
          overflow: 'visible'
        }}>
        <PopupTemplate />
        <div style={{
          display: 'flex',
          flexDirection: 'column',
        }}>
          <Grid ref={ref}>
            {tab === 'messages' && (
              <Folders
                path={['msg', 'ot', 'outbox']}
                deselect={Boolean(query)}
                onClick={(path) => {
                  actions.selectAll({
                    type: 'messages',
                    value: false,
                  });
                  actions.search({
                    type: 'messages',
                    filter,
                    path,
                    page: 1,
                  });
                }}
              />
            )}
            {tab === 'template' && templateTab === 'sked' && (
              <Folders
                template
                path={['tmp', 'msg', 'ot']}
                deselect={Boolean(query)}
                onClick={(path) => actions.search({
                  type: 'template',
                  features,
                  filter,
                  path,
                  page: 1,
                })}
              />
            )}
            {tab === 'template' && templateTab === 'your' && (
              <Folders
                path={['msg', 'ot', 'tmp']}
                deselect={Boolean(query)}
                onClick={(path) => {
                  actions.selectAll({
                    type: 'officeTemplates',
                    value: false,
                  });
                  actions.search({
                    type: 'officeTemplates',
                    filter,
                    path,
                    page: 1,
                  });
                }}
              />
            )}
          </Grid>
          <div hidden={tab !== 'messages'}>
            <MessagesTable
              busy={busy}
              messages={messages}
              url={'/messages/view/'}
              all={all}
              tz={tz}
              selectAll={R.pipe(R.assoc('type', 'messages'), actions.selectAll)}
              selectMessage={R.pipe(R.assoc('type', 'messages'), actions.selectMessage)}
              copyMessage={actions.copyMessage}
              deleteMessage={actions.deleteMessage}
              deleteSelected={actions.deleteSelected}
              query={query}
              filter={filter}
              search={R.pipe(R.assoc('type', 'messages'), R.assoc('filter', filter), actions.search)}
              messagesPatch={actions.messagesPatch}
              pageName={'messages'}
              page={messagePage}
              totalPages={totalMessagePages}
              totalCount={totalMessageCount}
              path={['msg', 'ot', 'outbox']}
              maxHeight={tableMaxHeight}
            />
          </div>
          <div hidden={tab !== 'pending'}>
            <MessagesTable
              busy={busy}
              messages={pending}
              url={'/messages/new/'}
              all={all}
              tz={tz}
              selectAll={R.pipe(R.assoc('type', 'pending'), actions.selectAll)}
              selectMessage={R.pipe(R.assoc('type', 'pending'), actions.selectMessage)}
              copyMessage={actions.copyMessage}
              deleteMessage={actions.deleteMessage}
              deleteSelected={actions.deleteSelected}
              query={query}
              filter={filter}
              search={R.pipe(R.assoc('type', 'pending'), R.assoc('filter', filter), R.assoc('page', page), actions.search)}
              messagesPatch={actions.messagesPatch}
              pageName={'pending'}
              maxHeight={tableMaxHeight}
            />
          </div>
          <div hidden={tab !== 'drafts'}>
            <MessagesTable
              busy={busy}
              messages={drafts}
              url={'/messages/new/'}
              all={all}
              tz={tz}
              selectAll={R.pipe(R.assoc('type', 'drafts'), actions.selectAll)}
              selectMessage={R.pipe(R.assoc('type', 'drafts'), actions.selectMessage)}
              copyMessage={actions.copyMessage}
              deleteMessage={actions.deleteMessage}
              deleteSelected={actions.deleteSelected}
              query={query}
              filter={filter}
              search={R.pipe(R.assoc('type', 'drafts'), R.assoc('filter', filter), R.assoc('page', page), actions.search)}
              messagesPatch={actions.messagesPatch}
              pageName={'drafts'}
              error='Error: Failed to get drafts'
              maxHeight={tableMaxHeight}
            />
          </div>
          <div hidden={tab !== 'template'}>
            <TemplatesTable
              tab={templateTab}
              setTab={setTemplateTab}
              SkedTemplatesTable={() =>
                <MessagesTable
                  busy={busy}
                  messages={templates}
                  tz={tz}
                  copyMessage={actions.copyMessage}
                  pageName={'sked-templates'}
                  error='Error: Failed to get templates'
                  page={templatePage}
                  totalPages={totalTemplatePages}
                  totalCount={totalTemplateCount}
                  query={query}
                  filter={filter}
                  messagesPatch={actions.messagesPatch}
                  search={R.pipe(
                    R.assoc('type', 'template'),
                    R.assoc('filter', filter),
                    R.assoc('features', features),
                    actions.search
                  )}
                  maxHeight={tableMaxHeight}
                />
              }
              OfficeTemplateTable={() =>
                <MessagesTable
                  busy={busy}
                  messages={officeTemplates}
                  all={all}
                  tz={tz}
                  selectAll={R.pipe(R.assoc('type', 'officeTemplates'), actions.selectAll)}
                  selectMessage={R.pipe(R.assoc('type', 'officeTemplates'), actions.selectMessage)}
                  copyMessage={(d) => actions.copyMessage({ ...d, wasTemplate: true })}
                  deleteMessage={(d) => actions.deleteMessage(R.merge(d, { type: 'template' }))}
                  deleteSelected={(d) => actions.deleteSelected(R.merge(d, { type: 'template' }))}
                  pageName={'templates'}
                  // search={({ query }) => actions.localSearch('officeTemplates', query)}
                  error='Error: Failed to get templates'
                  page={officeTemplatePage}
                  totalPages={totalOfficeTemplatePages}
                  totalCount={totalOfficeTemplateCount}
                  messagesPatch={actions.messagesPatch}
                  query={query}
                  filter={filter}
                  search={R.pipe(
                    R.assoc('type', 'officeTemplates'),
                    R.assoc('filter', filter),
                    actions.search
                  )}
                  path={['msg', 'ot', 'tmp']}
                  maxHeight={tableMaxHeight}
                />
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OneTimeMessagesTemplate;
