import React, { useMemo, useState } from 'react';
import * as R from 'ramda';
import {
  TextField,
  Button,
  Tooltip,
  Paper
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import BeforeIcon from '@mui/icons-material/NavigateBefore';
import NextIcon from '@mui/icons-material/NavigateNext';
import { caseType, outputLocation, smartSearch } from '../../services/utilities.js';
import { Lead } from '../../routes/Leads/leads.reducer';
import { useStyles } from './client-selector.styles';

type Client = {
  id: number;
  firstName: string;
  lastName: string;
  allowSMS?: boolean;
  phone: string;
  birthday?: string;
  allowEmail?: boolean;
  email: string;
  index?: number;
}

type ClientChipProps = {
  client: Client;
  onClick: (client: Client) => void;
  showDeleteButton: boolean;
}

const ClientChip = ({ client, onClick, showDeleteButton }: ClientChipProps) => {
  const classes = useStyles();
  const firstInit: string = R.propOr(' ', 'firstName')(client);
  const lastInit: string = R.propOr(' ', 'lastName')(client);

  const tooltipTitle = useMemo(() => {
    if (
      !client.birthday &&
      !(client.phone && client.allowSMS) &&
      !(client.email && client.allowEmail)
    ) {
      return null;
    }
    return (
      <>
        {client.birthday && <p style={{ margin: 0 }}>{`Birthday: ${client.birthday}`}</p>}
        {(client.phone && client.allowSMS) && <p style={{ margin: 0 }}>{`Phone: ${client.phone}`}</p>}
        {(client.email && client.allowEmail) && <p style={{ margin: 0 }}>{`Email: ${client.email}`}</p>}
      </>
    );
  }, [client]);

  return (
    <div
      className={classes.chipRoot}
      onClick={() => onClick(client)}>
      <Tooltip
        arrow
        placement='top'
        disableHoverListener={!tooltipTitle}
        title={tooltipTitle}
        disableInteractive
      >
        <div className={classes.chipInit}>
          {firstInit[0] + lastInit[0]}
        </div>
      </Tooltip>
      <Tooltip
        arrow
        placement='top'
        disableHoverListener={!tooltipTitle}
        title={tooltipTitle}
        disableInteractive
      >
        <div
          key={client.id}
          className={classes.chipText}
        >
          {client.firstName + ' ' + client.lastName + ' ' + caseType(client)}
        </div>
      </Tooltip>
      {showDeleteButton && <CancelIcon className={classes.closeIcon} />}
    </div>
  );
};

type Search = {
  firstName: string;
  lastName: string;
  phone?: string;
  isLead?: boolean;
}

type ClientSelectorProps = {
  search?: (value: Search, status?: string[]) => void;
  arrowButtonPress?: ({ page, search }: { page: number, search: Search }, status?: string[]) => void;
  click: (client: Client) => void;
  busy: boolean;
  clients: {
    data: Client[] | Lead[];
    page?: number;
    totalCount?: number;
    totalPages?: number;
  };
  isSearch: boolean;
  isOfflineSearch?: boolean;
  style?: object;
  title: string;
  clearButton?: () => void;
  isLead?: boolean;
}

type ClientSelectorState = {
  state?: string;
  search: string | Search;
  status?: string;
}

const ClientSelector = (props: ClientSelectorProps) => {
  const classes = useStyles();
  const [state, setState] = useState<ClientSelectorState>({
    state: 'INIT',
    search: '',
    status: 'Active',
  });

  const update = (data: ClientSelectorState) => {
    setState(R.merge(state, data));
  };

  const {
    busy,
    clients,
    isSearch,
    isOfflineSearch, // TODO
    style,
    title,
    clearButton,
    isLead,
  } = props;

  return (
    <Paper className={classes.root}>
      {title}
      <Paper
        className={classes.paper}
        style={{ ...style }}
      >
        {isSearch &&
          <div>
            <div className={classes.search}>
              <TextField
                name="search"
                label="Search"
                placeholder='LastName FirstName'
                onChange={(e) => {
                  const search = e.target.value;
                  const output = smartSearch(search) as Search;
                  if (isOfflineSearch) {
                    // TODO
                  } else {
                    props.search(output, [state.status]);
                    update({ search });
                  }
                }}
              />
              &nbsp;
              <Tooltip
                title="Search format: lastName firstName phone (i.e. Smith John 1231231234) <br/> Pro Tip: hover over a client to view email and phone number!"
                placement="top"
                arrow>
                <InfoIcon className={classes.infoIcon} />
              </Tooltip>
            </div>
            {!isLead && (
              <label>
                Inactive:
                <input
                  checked={'Inactive' === state.status}
                  type='checkbox'
                  onChange={(e) => {
                    if (e.target.checked) {
                      props.search(state.search as Search, ['Inactive']);
                      update({ search: state.search, status: 'Inactive' });
                    } else {
                      props.search(state.search as Search, ['Active']);
                      update({ search: state.search, status: 'Active' });
                    }
                  }}
                />
              </label>
            )}
          </div>}
        {busy && <div className='loader' />}
        {!busy &&
          clients.data.map((client: Client, ind: number) => {
            return (
              <ClientChip
                key={ind}
                client={client}
                showDeleteButton={!isSearch}
                onClick={(c) => props.click({ ...c, index: ind })}
              />
            );
          })}
        {!busy && isSearch && !isOfflineSearch &&
          <div className={classes.pagination}>
            <div style={{ marginRight: '5px' }}>
              {outputLocation(clients.page, 25, clients.totalCount, clients.data.length)}
            </div>
            <Button
              style={{ width: '50px', minWidth: 'unset' }}
              disabled={clients.page === 1}
              onClick={() => {
                const output = smartSearch(state.search) as Search;
                console.log(output);
                props.arrowButtonPress({
                  page: clients.page - 1,
                  search: output,
                }, [state.status]);
              }} >
              <BeforeIcon />
            </Button>
            <Button
              style={{ width: '50px', minWidth: 'unset' }}
              disabled={clients.page === clients.totalPages}
              onClick={() => {
                const output = smartSearch(state.search) as Search;
                props.arrowButtonPress({
                  page: clients.page + 1,
                  search: output,
                }, [state.status]);
              }} >
              <NextIcon />
            </Button>
          </div>}
      </Paper>
      {clearButton &&
        <Button size="small" variant='outlined' onClick={() => props.clearButton()}>Clear</Button>
      }
    </Paper>
  );
};

export default ClientSelector;
