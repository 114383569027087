import React, {
  useLayoutEffect, useRef, useEffect, useState
} from 'react';
import { useDispatch } from 'react-redux';
import Header from '../../../../components/PageHeader/PageHeader.component';
import * as R from 'ramda';
import {
  Paper as PaperRaw, TextField, Snackbar, Button, Tooltip, Alert,
  CircularProgress, TableRow, TableBody,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { usePromise } from '../../../../services/promise.hook';
import * as at from '../../../../actionTypes';
import {
  getCardInfo, billingPatch, uploadCard, updateCustomerId
} from './subscription.actions.js';
import { PopupTemplate } from '../../../../services/Popup.js';
import { Line } from 'react-chartjs-2';
import { now } from '../../../../services/joda.js';
import { useSelector } from '../../../../reducers';
import { TableContainer, BodyCell } from '../../../../components/CustomTable';
import SparkLogo from '../../../../icons/SparkLogo.icon';
import { SPARK_FEATURE } from '../../../Login/login.reducer';
import { Chart as ChartJS, registerables } from 'chart.js'; 
ChartJS.register(...registerables);

let stripe;
let elements;

const style = {
  base: {
    color: '#32325d',
    lineHeight: '24px',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
};

const checkFields = ({ street, city, state, zipCode }) => {
  const validName = street !== '';
  const validPhone = city !== '';
  const validEmail = state !== '';
  const validPass = zipCode !== '';

  return validName && validPass && validEmail && validPhone;
};

const submitCard = ({ name, billingAddress, uploadCard, card }) => {
  if (checkFields(billingAddress)) {
    return stripe.createToken(
      card,
      {
        name,
        'address_line1': billingAddress.street,
        'address_line2': '',
        'address_city': billingAddress.city,
        'address_state': billingAddress.state,
        'address_zip': billingAddress.zipCode,
        'address_country': 'US',
      }
    ).then(({ token }) => {
      uploadCard(token.id);
    });
  }
  alert('There were some issues with your billing address. Try entering a new one.');
  return null;
};

const Paper = withStyles({
  root: {
    padding: '20px'
  },
})(PaperRaw);

const CardForm = ({
  update,
  newAddress,
  uploadCard,
  newCard,
  updateCard,
  officeAddress
}) => {
  const elementRef = useRef(null);
  const cardDOM = useRef(null);

  useLayoutEffect(() => {
    if (elementRef.current === null && elements && cardDOM.current !== null) {
      const element = elements.create('card', { style });
      elementRef.current = element;
      element.mount(cardDOM.current);
    }
  });

  useLayoutEffect(() => {
    return () => {
      if (elementRef.current) {
        elementRef.current.destroy();
      }
    };
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (newAddress) {
      submitCard({
        name: newCard.cardName,
        billingAddress: officeAddress,
        uploadCard,
        card: elementRef.current
      });
    }
    submitCard({
      name: newCard.cardName,
      billingAddress: newCard,
      uploadCard,
      card: elementRef.current
    });
  };

  return (
    <form id="payment-form">
      <fieldset>
        <label className="card-element">
        </label>
        <input
          type='checkbox'
          checked={!!newAddress}
          onChange={(e) => update('newAddress', e.target.checked)} />
        <label onClick={() =>
          update('newAddress', !newAddress, at.BILLING_CARD_PATCH)}>
          Same address as office
        </label>
        <br />
        <br />
        {!newAddress &&
          <div>
            <label>Billing Address:</label>
            <br />
            <TextField
              type='text'
              value={newCard.street}
              style={{ width: '100%' }}
              onChange={(e) => updateCard('street', e.target.value)} />
            <br />
            <label>City:</label>
            <br />
            <TextField
              type='text'
              value={newCard.city}
              style={{ width: '100%' }}
              onChange={(e) => updateCard('city', e.target.value)}>
            </TextField>
            <br />
            <label>State:</label>
            <br />
            <TextField
              type='text'
              value={newCard.state}
              style={{ width: '100%' }}
              onChange={(e) => updateCard('state', e.target.value)}>
            </TextField>
            <br />
            <label>Zip Code:</label>
            <br />
            <TextField
              type='text'
              value={newCard.zipCode}
              style={{ width: '100%' }}
              onChange={(e) => updateCard('zipCode', e.target.value)}>
            </TextField>
            <br />
            <br />
          </div>
        }
        <TextField
          name='name'
          label="Name on Card"
          value={newCard.cardName}
          style={{ width: '100%' }}
          onChange={(e) => updateCard('cardName', e.target.value)}>
        </TextField>
        <br />
        <br />
        <div id="card-element" ref={cardDOM} >
        </div>
        <div id="card-errors" role="alert"></div>
        <br />
        <Button
          name='back2'
          type='button'
          onClick={() => {
            update('state', 'INFO');
          }}>
          Cancel
        </Button>
        <Button
          variant='contained'
          name='submit'
          style={{ marginLeft: '5px', marginBottom: '5px', float: 'right' }}
          onClick={onSubmit}>
          Submit
        </Button>
      </fieldset>
    </form>
  );
};

const formatAgreementText = ({ monthlyAgreement, yearlyAgreement }) => {
  if (monthlyAgreement && monthlyAgreement.data.policy) {
    return 'Monthly';
  }
  if (yearlyAgreement && yearlyAgreement.data.policy) {
    return 'Yearly';
  }
  return 'Need to choose monthly or yearly';
};

const Contents = ({
  card, plan, newCard, newAddress, billingPatch, state, officeAddress,
  uploadCard, monthlyAgreement, yearlyAgreement,
}) => {
  const update = (prop, value) => billingPatch(
    {
      type: at.BILLING_PATCH,
      data: {
        [prop]: value,
      }
    }
  );
  const updateCard = (prop, value) => billingPatch(
    {
      type: at.BILLING_CARD_PATCH,
      data: {
        [prop]: value,
      }
    }
  );

  return R.cond([
    [R.equals('INFO'), () => (
      <div>
        <h3 style={{
          color: 'unset',
          marginLeft: '0px'
        }}>
          Card Information
        </h3>
        <TableContainer noHover>
          <TableBody>
            <TableRow>
              <BodyCell style={{ width: '50%' }}>Name on Card:</BodyCell>
              <BodyCell>{R.pathOr(null, ['cardName'], card)}</BodyCell>
            </TableRow>
            <TableRow>
              <BodyCell>Agreement:</BodyCell>
              <BodyCell>{formatAgreementText({ monthlyAgreement, yearlyAgreement })}</BodyCell>
            </TableRow>
            <TableRow>
              <BodyCell>Product:</BodyCell>
              <BodyCell>{plan && plan.name}</BodyCell>
            </TableRow>
            <TableRow>
              <BodyCell>Card Provider:</BodyCell>
              <BodyCell>{R.pathOr(null, ['cardBrand'], card)}</BodyCell>
            </TableRow>
            <TableRow>
              <BodyCell>Last Four of Card Number:</BodyCell>
              <BodyCell>{R.pathOr(null, ['lastFour'], card)}</BodyCell>
            </TableRow>
            <TableRow>
              <BodyCell>Street:</BodyCell>
              <BodyCell>{R.pathOr(null, ['address', 'street'], card)}</BodyCell>
            </TableRow>
            <TableRow>
              <BodyCell>City:</BodyCell>
              <BodyCell>{R.pathOr(null, ['address', 'city'], card)}</BodyCell>
            </TableRow>
            <TableRow>
              <BodyCell>State</BodyCell>
              <BodyCell>{R.pathOr(null, ['address', 'state'], card)}</BodyCell>
            </TableRow>
            <TableRow>
              <BodyCell>Zip</BodyCell>
              <BodyCell>{R.pathOr(null, ['address', 'zipCode'], card)}</BodyCell>
            </TableRow>
          </TableBody>
        </TableContainer>
        <Button
          variant='contained'
          onClick={() => update('state', 'EDIT')}>
          New card
        </Button>
      </div>
    )],
    [R.equals('EDIT'), () => (
      <div>
        <CardForm {...{ update, newAddress, newCard, updateCard, officeAddress, uploadCard }} />
      </div>
    )]
  ])(state);
};

const displayMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

const months = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER'
];

const usageChart = (data, year) => {
  const yearData = R.propOr({}, year, data);
  const chartData = {
    labels: displayMonths,
    datasets: [
      {
        label: 'Credits',
        data: months.map((month) => {
          const noUsage = { usage: 0, amountPaid: 0 };
          return R.prop('usage', R.propOr(noUsage, month, yearData));
        }),
        backgroundColor: 'rgba(59,148,195,0.4)',
        tension: 0.5,
        fill: true,
      },
      {
        label: 'Payment (in dollars)',
        data: months.map((month) => {
          const noUsage = { usage: 0, amountPaid: 0 };
          const raw = R.prop('amountPaid', R.propOr(noUsage, month, yearData));
          return raw / 100;
        }),
        backgroundColor: 'rgba(180, 0, 0, 0.4)',
        tension: 0.5,
        fill: true,
      },
    ]
  };
  return (
    <div className="chart">
      <Line data={chartData} height={420} options={{
        maintainAspectRatio: false,
        animation: false,
      }} />
    </div>
  );
};

const CustomerId = ({ office, customerId, billingPatch }) => {
  const [id, setId] = useState(customerId || '');
  const [snackbar, setSnackbar] = useState(null);
  const saveState = usePromise(updateCustomerId, null);

  useEffect(() => setId(customerId || ''), [customerId]);

  const save = () => {
    saveState.invoke({ officeId: office.id, customerId: id.trim() }).then(() => {
      billingPatch({
        type: at.BILLING_PATCH,
        data: {
          customerId: id.trim(),
        },
      });
      setSnackbar('Saved Customer ID!');
    }).catch(() => {
      setSnackbar('Failed to Save Customer ID!');
    });
  };

  return (
    <div>
      <Snackbar
        open={Boolean(snackbar)}
        message={snackbar}
        autoHideDuration={4000}
        onClose={() => setSnackbar(null)}
      >
        <div>
          <Alert
            style={{ fontSize: '16px' }}
            severity={snackbar?.indexOf('Failed') === 0 ? 'error' : 'success'}>
            {snackbar}
          </Alert>
        </div>
      </Snackbar>
      <h3 style={{ marginLeft: '0px' }}>Stripe Customer ID</h3>
      <TextField
        label='Customer ID'
        value={id}
        onChange={(e) => setId(e.target.value)}
      />
      <br />
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}>
        <Button
          variant='contained'
          style={{ marginTop: '20px', }}
          disabled={saveState.loading || id.trim() === ''}
          onClick={save}
        >
          {saveState.loading ? 'Loading...' : 'Save'}
        </Button>
        {saveState.loading && <CircularProgress />}
        {saveState.errorMessage &&
          <p style={{ color: 'red' }}>{saveState.errorMessage}</p>}
      </div>
    </div>
  );
};

const SubscriptionTemplate = ({
  busy, card, plan, inputNewCard, cardForm, newCard, billingPatch,
  state, newAddress, officeAddress, uploadCard, snackbar, msgPlan,
  usage, earliestYear, upcoming, showLimit, showCredits,
  monthlyAgreement, yearlyAgreement, year, update, admin, office,
  customerId, sparkStripeUrl, isSpark,
}) => (
  <>
    <Header title='Billing Information' fixed />
    <div
      style={{
        padding: '0px 10px 25px',
        overflowY: 'auto',
      }}>
      <PopupTemplate />
      <Snackbar
        open={snackbar}
        message="Business Information Saved"
        autoHideDuration={4000}
        onClose={() => billingPatch({
          type: at.BILLING_PATCH,
          data: { snackbar: false }
        })}
      />

      {busy ?
        <div className="loader"></div>
        :
        <div>
          {sparkStripeUrl &&
            <Paper className="content-inner panel-margin" style={{
              height: 'unset',
              display: 'flex',
              flexDirection: 'column',
            }}>
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                <SparkLogo style={{ width: 90, height: 'fit-content' }}/>
                <h2 style={{ marginLeft: 7 }}>Billing</h2>
              </div>
              <p style={{ marginTop: 'unset' }}>
              Manage your payment method, billing information, and current plan here. You can also see your invoice history.
              </p>
              <a href={sparkStripeUrl} target='_blank'>
                <Button variant='contained'>
                Manage Spark Billing
                </Button>
              </a>
            </Paper>}
          {msgPlan && !R.isEmpty(upcoming) &&
            <Paper className="content-inner panel-margin" style={{
              height: 'unset',
              display: 'flex',
              flexDirection: 'column',
            }}>
              <h3 style={{ marginLeft: '0px' }}>Current Message Credit Usage</h3>
              <label style={{
                fontSize: '16px',
              }}>
                Current Messaging Bill: <b>${R.ifElse(
                  R.lt(0),
                  R.identity,
                  R.always(0)
                )((upcoming.amountDue - plan.cost) / 100)}</b>
              </label>
              <br />
              {showCredits &&
                <label style={{
                  fontSize: '16px',
                }}>
                  Credits: <b>{upcoming.usage}</b>
                </label>
              }
              {showLimit &&
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                  height: '50px',
                }}>
                  <Tooltip
                    arrow
                    placement='top'
                    title={'Credits: ' + upcoming.usage}
                  >
                    <div
                      style={{
                        height: '100%',
                        width: (upcoming.usage / upcoming.creditsAllowed) * 100 + '%',
                        backgroundColor: '#008BCF',
                        border: 'black 1px solid',
                      }}
                    >
                    </div>
                  </Tooltip>
                  <Tooltip
                    arrow
                    placement='top'
                    title={'Credit Limit: ' + upcoming.creditsAllowed}
                  >
                    <div
                      style={{
                        width: 100 - (upcoming.usage / upcoming.creditsAllowed * 100) + '%',
                        height: '100%',
                        backgroundColor: 'lightgray',
                        border: 'black 1px solid',
                        zIndex: 0,
                      }}
                    >
                    </div>
                  </Tooltip>
                </div>}
            </Paper>}
          {msgPlan && !R.isEmpty(usage) &&
            <Paper className="content-inner panel-margin" style={{
              height: 'unset',
              display: 'flex',
              flexDirection: 'column',
            }}>
              <h3 style={{ marginLeft: '0px' }}>Previous Message Credit Usage</h3>
              <div
                style={{
                  alignSelf: 'center',
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  width: '300px',
                  fontSize: '20px',
                }}>
                <Button
                  disabled={year === earliestYear}
                  onClick={() => update({ year: year - 1 })}>
                  <ArrowLeftIcon />
                </Button>
                {year}
                <Button
                  disabled={year === now('date').year()}
                  onClick={() => update({ year: year + 1 })}>
                  <ArrowRightIcon />
                </Button>
              </div>
              {usageChart(usage, year)}
              <i>Pro Tip: Click on the blue credit box to see the past payments easier.</i>
            </Paper>
          }
          {!isSpark &&
            <Paper className="content-inner panel-margin" style={{ height: 'unset' }}>
              <Contents {...{
                card, plan, inputNewCard, cardForm, newCard, newAddress,
                billingPatch, state, officeAddress, uploadCard, monthlyAgreement,
                yearlyAgreement
              }} />
            </Paper>}
          {admin &&
            <Paper className="content-inner panel-margin" style={{ height: 'unset' }}>
              <CustomerId
                office={office}
                customerId={customerId}
                billingPatch={billingPatch}
              />
            </Paper>}
        </div>
      }
      <div style={{ paddingLeft: '15px', paddingRight: '15px' }}>
        {!isSpark &&
          <i>
            <a href="https://www.cognitoforms.com/SKED2/OptoutPaymentEmails" target="_blank">SKED - Opt-Out of Payment Emails</a>
          </i> }
        <br />
        <i>
          {!isSpark && <a href="https://s3.amazonaws.com/sked-v2/licenses+/SKED+-+Subscription+Agreement+-+03-25.pdf" target="_blank">SKED - Subscription Agreement</a>}
          {sparkStripeUrl &&
           <>
             <br/>
             <a href="https://sked-v2.s3.amazonaws.com/documents/2024-06-spark-patients-terms-of-service.pdf" target="_blank">Spark - Terms of Service</a>
           </>}
        </i>
      </div>
    </div>
  </>
);

const Subscription = ({ history }) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    year: now('date').year(),
  });

  const globalProps = useSelector((state) => ({
    ...state.subscription,
    officeAddress: state.login.officeAddress,
    office: state.login.office,
    admin: state.login.admin,
    isSpark: R.includes(SPARK_FEATURE, state.login.features),
  }));

  const update = (data) => {
    setState({ ...state, ...data });
  };

  const props = {
    ...globalProps,
    getCardInfo: (his) => dispatch(getCardInfo(his)),
    billingPatch: (data) => dispatch(billingPatch(data)),
    uploadCard: (id) => dispatch(uploadCard(id)),
    ...state,
    update,
  };

  useEffect(() => {
    props.getCardInfo(history);
  }, []);

  useEffect(() => {
    props.getCardInfo(history);
  }, [props.office]);

  useEffect(() => {
    stripe = globalProps.isSpark
      ? window.Stripe(`${process.env.SPARK_STRIPE_API_KEY}`)
      : window.Stripe(`${process.env.STRIPE_API_KEY}`);

    elements = stripe.elements();
  }, [globalProps.isSpark]);

  return (
    <SubscriptionTemplate {...props} />
  );
};

export default Subscription;
