import * as R from 'ramda';
import * as at from '../../../actionTypes';
import { format } from '../../../services/joda.js';
import {
  LocalTime,
} from '@js-joda/core';

const init = {
  data: [],
  form: [],
  editing: undefined,
  isSaving: false,
  isLoading: false,
  error: {},
  loadError: undefined,
  copiedHours: false,
};

export default (officeHours = init, action) => {
  switch (action.type) {
    case at.OFFICE_HOURS_ADD_PUSHED: {
      if (action.data.isPasted) {
        return R.merge(officeHours, {
          editing: {
            ...officeHours.editing,
            hours: action.data.hours,
          }
        });
      }
      return R.merge(officeHours, {
        editing: action.data
      });
    }
    case at.OFFICE_HOURS_EDIT_PUSHED: {
      const fromTime = (open) => {
        return ({
          hour: format(LocalTime.parse(open), 'h'),
          minute: format(LocalTime.parse(open), 'mm'),
          a: format(LocalTime.parse(open), 'a'),
        });
      };
      const editing = R.pipe(
        R.find(R.propEq('id', action.data)),
        R.evolve({
          hours: R.mapObjIndexed(
            R.map(
              R.mapObjIndexed(fromTime)
            )
          )
        })
      )(officeHours.data);
      return R.merge(officeHours, {
        editing,
      });
    }
    case at.OFFICE_HOURS_REMOVE_PUSHED: {
      if (action.state === 'REQUEST') {
        return R.merge(officeHours, {
          isLoading: true,
          editing: undefined,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(officeHours, {
          form: action.data,
          data: action.data,
          selectedId: action.data.length > 0 ? action.data[0].id : -1,
          isLoading: false,
          editing: undefined,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(officeHours, {
          loadError: action.data,
          isLoading: false,
          editing: undefined,
        });
      }
      return officeHours;
    }
    case at.OFFICE_HOURS_CANCEL_PUSHED: {
      return R.merge(officeHours, {
        editing: undefined,
      });
    }
    case at.OFFICE_HOURS_DATE_CHANGED: {
      return R.evolve({
        editing: R.mergeLeft(action.data),
      }, officeHours);
    }
    case at.OFFICE_HOURS_CLOSED_PUSHED: {
      const hourslens = R.lensProp('hours');
      const dayOfWeekLens = R.lensProp(action.data.dayOfWeek);
      const lens = R.compose(hourslens, dayOfWeekLens);
      const checked = action.data.checked;
      const value = (checked) ? [] : ([{
        close: { hour: '12', minute: '00', a: 'AM' },
        open: { hour: '12', minute: '00', a: 'AM' },
      }]);
      return R.evolve({
        editing: R.set(lens, value),
      })(officeHours);
    }
    case at.OFFICE_HOURS_ADD_OPENING_PUSHED: {
      const hourslens = R.lensProp('hours');
      const dayOfWeekLens = R.lensProp(action.data.dayOfWeek);
      const lens = R.compose(hourslens, dayOfWeekLens);
      const value = {
        close: { hour: '12', minute: '00', a: 'AM' },
        open: { hour: '12', minute: '00', a: 'AM' },
      };
      return R.evolve({
        editing: action.data.hours ?
          R.set(lens, action.data.hours)
          :
          R.over(lens, R.append(value)),
      })(officeHours);
    }
    case at.OFFICE_HOURS_REMOVE_OPEN_PUSHED: {
      const hourslens = R.lensProp('hours');
      const dayOfWeekLens = R.lensProp(action.data.dayOfWeek);
      const index = action.data.index;
      const lens = R.compose(
        hourslens,
        dayOfWeekLens
      );
      return R.evolve({
        editing: R.over(
          lens,
          R.remove(index, 1)
        )
      }, officeHours);
    }
    case at.OFFICE_HOURS_TIME_CHANGED: {
      const hourslens = R.lensProp('hours');
      const openCloseLens = R.lensProp(action.data.openClose);
      const dayOfWeekLens = R.lensProp(action.data.dayOfWeek);
      const indexLens = R.lensIndex(action.data.index);
      const changeLens = R.lensProp(action.data.change);
      const lens = R.compose(
        hourslens,
        dayOfWeekLens,
        indexLens,
        openCloseLens,
        changeLens
      );
      const value = action.data.value;
      const times = officeHours.editing.hours[action.data.dayOfWeek];
      const sortTimes = R.sortBy(R.compose(R.prop('a'), R.prop('open')))(times);
      return R.evolve({
        editing: R.set(
          lens,
          value
        )
      }, { ...officeHours, editing: { ...officeHours.editing, hours: { ...officeHours.editing.hours, [action.data.dayOfWeek]: sortTimes } } });
    }
    case at.OFFICE_HOURS_SAVE_PUSHED: {
      if (action.state === 'RESPONSE') {
        return R.merge(officeHours, {
          editing: undefined,
          data: action.data
        });
      } else if (action.state === 'REQUEST') {
        return R.merge(officeHours, {
          isLoading: true,
        });
      }
      return officeHours;
    }
    case at.OFFICE_HOURS_REMOTE_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(officeHours, {
          isLoading: true,
          editing: undefined,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(officeHours, {
          form: action.data,
          data: action.data,
          selectedId: action.data.length > 0 ? action.data[0].id : -1,
          isLoading: false,
          editing: undefined,
        });
      } else if (action.state === 'ERROR') {
        console.log(action);
        return R.merge(officeHours, {
          loadError: action.error,
          isLoading: false,
          editing: undefined,
        });
      }
      return officeHours;
    }
    case at.OFFICE_HOURS_REMOTE_DELETE:
      if (action.state === 'REQUEST') {
        return R.merge(officeHours, {
          isSaving: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.evolve({
          form: R.reject(R.propEq('id', Number(action.metadata.id))),
          data: R.reject(R.propEq('id', Number(action.metadata.id))),
          selectedId: R.always(R.pipe(
            R.prop('data'),
            R.reject(R.propEq('id', Number(action.metadata.id))),
            R.head,
            R.propOr(-1, 'id')
          )(officeHours)),
          isSaving: R.F
        }, officeHours);
      } else if (action.state === 'ERROR') {
        return R.merge(officeHours, {
          loadError: action.data,
          isLoading: false,
        });
      }
      return officeHours;
    case at.OFFICE_HOURS_COPY_HOURS: {
      const { copiedHours } = action.data;
      return R.merge(officeHours, { copiedHours });
    }
    case 'AUTH_LOGOUT':
      return init;
    default:
      return officeHours;
  }
};
