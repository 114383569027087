import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  MenuItem,
  Select as RawSelect,
} from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';
import { assoc, pick, identity } from 'ramda';

const Select = withStyles({
  standard: {
    width: '200px',
  }
})(RawSelect);

const useStyles = makeStyles({
  formControl: {
    display: 'block',
    marginTop: '20px',
    marginBottom: '20px',
    width: '100%'
  },
});

export const SettingsPopUp = ({
  openSettings,
  handleCloseSettings,
  handleSaveSettings,
  defaultForm,
}) => {
  const classes = useStyles();

  const [settingsForm, setSettingsForm] = useState(
    pick(['cellHeight', 'cellWidth', 'nameDisplay', 'groupByProfessional', 'localInterval'], defaultForm)
  );

  const updateSettingsForm = (key = '', cb = identity) => e => {
    const value = key === 'groupByProfessional' ? e.target.checked : cb(e.target.value);
    setSettingsForm(assoc(key, value));
  };

  return (<Dialog open={openSettings} onClose={handleCloseSettings} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Calendar Settings</DialogTitle>
    <DialogContent>
      <div className={classes.formControl} style={{ marginTop: '-5px' }}>
        <TextField
          autoFocus
          margin="dense"
          id="width"
          label="Cell Width"
          type="number"
          variant="standard"
          onChange={updateSettingsForm('cellWidth', (v) => Math.abs(Number(v)))}
          value={settingsForm.cellWidth}
          fullWidth
        />
      </div>
      <div className={classes.formControl}>
        <TextField
          autoFocus
          margin="dense"
          id="height"
          label="Cell Height"
          variant="standard"
          value={settingsForm.cellHeight}
          onChange={updateSettingsForm('cellHeight', (v) => Math.abs(Number(v)))}
          type="number"
          fullWidth
        />
      </div>
      <div className={classes.formControl}>
        <FormControl variant="standard">
          <InputLabel id="demo-simple-select-label">Name Display</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={settingsForm.nameDisplay}
            onChange={updateSettingsForm('nameDisplay')}
          >
            <MenuItem value='abbr_f'>Abbreviate First Name</MenuItem>
            <MenuItem value='abbr_l'>Abbreviate Last Name</MenuItem>
            <MenuItem value='abbr_fl'>Abbreviate First and Last Name</MenuItem>
            <MenuItem value='abbr_none'>Show First and Last Name</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={classes.formControl}>
        <FormControlLabel
          control={<Checkbox checked={settingsForm.groupByProfessional} onChange={updateSettingsForm('groupByProfessional', Boolean)} name="groupByProfessional" />}
          label="Group By Professional in Day View"
        />
      </div>
      <div className={classes.formControl}>
        <FormControl variant="standard">
          <InputLabel id="local-interval-label">Interval</InputLabel>
          <Select
            labelId="local-interval-label"
            id="local-interval"
            value={settingsForm.localInterval}
            onChange={updateSettingsForm('localInterval', Number)}
          >
            <MenuItem value='5'>5 minutes</MenuItem>
            <MenuItem value='10'>10 minutes</MenuItem>
            <MenuItem value='15'>15 minutes</MenuItem>
            <MenuItem value='20'>20 minutes</MenuItem>
          </Select>
        </FormControl>
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => handleSaveSettings(settingsForm)} color="primary" variant="contained" disableElevation>
        Save
      </Button>

      <Button onClick={handleCloseSettings} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>);
};
