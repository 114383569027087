import React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import { cond, equals, T } from 'ramda';
import { withRouter } from 'react-router'; // to get the `history` prop
import { useSelector } from './reducers';

const Loading = () => (
  <div>
    <div className="loader"></div>
  </div>
);

interface Props extends RouteComponentProps {
  authStatus?: string;
  component?: React.ComponentType<RouteComponentProps>;
}
const PrivateRouteTemplate = ({ component: Component, authStatus, ...rest }: Props) => (
  <Route {...rest} render={props => (
    cond([
      [equals('NOT_CHECKED'), Loading],
      [equals('CHECKING'), Loading],
      [equals('AUTHENTICATED'), () => (
        <Component {...props} />
      )],
      [T, () => (
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
      )]
    ])(authStatus)
  )} />
);

const PrivateRoute = (props: Props) => {
  const authStatus = useSelector(state => state.session.status);
  return PrivateRouteTemplate({ ...props, authStatus });
};

//This will have to be async
export default withRouter(PrivateRoute);
