import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 3,
    whiteSpace: 'nowrap',
  },
  customButtom: {
    padding: '4px 14px',
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'none',
    borderRadius: 0,
    color: 'rgba(0,0,0,.87)',
    margin: 0,
    border: '1px solid rgba(0,0,0,.01)',
    background: 'rgba(0,0,0,.05)',
    minWidth: 30,

    '&:hover': {
      border: '1px solid rgba(0,0,0,.05)',
      background: 'rgba(0,0,0,.07)',
    }
  },
  first: {
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
  },
  last: {
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
  },
  selected: {
    background: '#fff',
    borderRadius: 3,
    boxShadow: '-1px 1px 2px 0px rgba(0, 0, 0, 0.2)',
    border: '1px solid rgba(0, 0, 0, 0.1)',

    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.1)',
      background: '#fff',
    }
  },
}));
