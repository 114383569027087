import {
  now,
} from './joda.js';
import * as R from 'ramda';
import api from './api.js';
//import { normalize } from 'normalizr';
const normalize = (a, b) => {
  console.log('need to install normalizr', b);
  return a;
};
import axios from 'axios';

export const remoteAction = ({ type, action, params = null, body = null, metadata = null }) => dispatch => {
  dispatch({
    type,
    state: 'REQUEST',
    metadata,
    params,
    body
  });

  return action().then( data => {
    dispatch({
      metadata,
      type,
      data,
      state: 'RESPONSE'
    });
    return data === 'rejected' ? Promise.reject('rejected') : data;
  }).catch( e => {
    if (!axios.isCancel(e)) {
      console.error(e);
      console.log(e.response);
    }
    const message = R.path(['response', 'data', 'message'], e);
    dispatch({
      type,
      error: message ? message : e,
      errorMessage: message ? message : e.message,
      state: 'ERROR',
      params,
      isCancel: axios.isCancel(e),
    });
    return Promise.reject(e);
  });
};

export const pageAction = ({
  pageId,
  params,
  schema,
  expiresIn = 10
}) => (dispatch, getStore) => {
  const expires = R.path(['pages', pageId, 'cacheExpires'], getStore() );
  const loading = R.path(['pages', pageId, 'loading'], getStore() );
  if (loading || (expires && expires.isAfter(now('time')) ) ) {
    return;
  }
  return dispatch(remoteAction({
    type: schema ? 'PAGE_REMOTE' : 'PAGE_CUSTOM_REMOTE',
    action: () => api.ajax(params).then( data => schema ? normalize(data, schema) : data ),
    metadata: {
      params,
      id: pageId,
      cacheExpires: now('time').plusMinutes(expiresIn)
    }
  }));
};

export default {
  remoteAction,
  pageAction
};
