import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  FormControl,
  InputLabel,
  Input,
  MenuItem,
  Select,
  IconButton,
  FormHelperText,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  FormControlLabel,
  Checkbox,
  Divider,
  Tooltip,
  Grid,
} from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import UndoIcon from '@mui/icons-material/Undo';
import InfoIcon from '@mui/icons-material/Info';
import { Row, Spacer, PageHeader } from '../../../components/PageHeader';
import { Editor } from '../../../components/Editor/editor.component';
import Modal from '../../../components/Modal/Modal.component';
import HeaderButton from '../../../components/HeaderButton/HeaderButton.component';
import {
  merge, assoc, evolve, append,
  compose, lensProp, lensIndex,
  set, over, remove
} from 'ramda';
import { saveSettings, getSettings } from '../waiting.actions.js';

const geofenceSettings = [
  {
    value: 'Off',
    text: 'Disabled',
  },
  {
    value: 'Barcode',
    text: 'Quick Barcode Notification',
  },
  {
    value: 'CheckIn',
    text: 'Check-In from App'
  },
];

const checkInMethods = [
  {
    value: 'Geofence',
    text: 'Geolocation',
  },
  {
    value: 'TimeBased',
    text: 'Time Based',
  },
];

const clientPlaceholders = [
  { value: '{{client.firstName}} ', name: 'First Name' },
  { value: '{{client.lastName}} ', name: 'Last Name' },
];

const apptPlaceholders = [
  { value: '{{appt.date}} ', name: 'Date' },
  { value: '{{appt.time}} ', name: 'Time Start' },
  { value: '{{appt.type}} ', name: 'Appointment Type' },
];

const officePlaceholders = [
  { value: '{{office.name}}', name: 'Name' },
  { value: '{{office.shortCode}} ', name: 'Provider ID' },
  { value: '{{office.email}} ', name: 'Email' },
  { value: '{{office.phone}} ', name: 'Phone' }
];

const SelectWide = withStyles({
  standard: {
    width: '200px',
  }
})(Select);

const FrontDeskSettings = ({
  update,
  state,
  setState,
  ehrSystem
}) => (
  <div>
    <TextField
      id="input-minutes-till-missed"
      label="Minutes Until Missed"
      variant="standard"
      value={state.minutesUntilMissed}
      onChange={e => update({ minutesUntilMissed: e.target.value })}
      type="number" />
    <FormHelperText>
      The app will wait this amount of time before indicating to the patient their appointment is missed.
    </FormHelperText>
    <br />
    <br />
    { ehrSystem === 'Genesis' &&
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={state.roomsSyncEnabled}
            onChange={e => setState(evolve({ roomsSyncEnabled: () => e.target.checked }))}
            name="roomsSyncEnabled"
            color="primary"
          />
        }
        label="Sync EHR checkout status with SKED"
      />
    </div>
    }
    <Divider />
  </div>);

const RoomSettings = ({
  setState,
  state,
}) => {
  const classes = useStyles();
  const addItem = () => {
    const item = { id: -1 * new Date().getTime(), name: '', index: state.rooms.length };
    setState(evolve({
      rooms: append(item)
    }));
  };

  const updateItem = (i, prop) => e => {
    const itemLens = compose(
      lensProp('rooms'),
      lensIndex(i)
    );
    const value = e.target.value;
    const upRoom = r => ({ ...r, [prop]: value, isTouched: true });
    setState(over(itemLens, upRoom));
  };

  const deleteItem = i => () => {
    const room = state.rooms[i];
    if (room.id > 0) {
      const itemLens = compose(
        lensProp('rooms'),
        lensIndex(i),
        lensProp('isDeleted')
      );
      return setState(set(itemLens, true));
    }
    setState(evolve({
      rooms: remove(i, 1)
    }));
  };

  const undoDeleteItem = i => () => {
    const itemLens = compose(
      lensProp('rooms'),
      lensIndex(i),
      lensProp('isDeleted')
    );
    return setState(set(itemLens, false));
  };

  return (
    <div style={{ maxWidth: '800px', marginTop: '20px' }}>
      <Row>
        <PageHeader variant="h5">Rooms</PageHeader>
        <Spacer />
        <Button variant="contained" onClick={addItem}>Add room</Button>
      </Row>

      <TableContainer className={classes.tableContainer}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Internal Name</TableCell>
              <TableCell>Name on Notification</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.rooms.map((r, i) => (<TableRow key={r.id} style={{ paddingLeft: 0 }}>
              <TableCell>
                {!r.isDeleted && <Input
                  value={r.name}
                  onChange={updateItem(i, 'name')} />}
                {r.isDeleted && <span style={{ textDecoration: 'line-through' }}>{r.name}</span>}
              </TableCell>

              <TableCell>
                {!r.isDeleted && <Input
                  value={r.displayName}
                  onChange={updateItem(i, 'displayName')} />}
                {r.isDeleted && <span style={{ textDecoration: 'line-through' }}>{r.displayName}</span>}
              </TableCell>

              <TableCell>
                {!r.isDeleted && <IconButton onClick={deleteItem(i)}>
                  <DeleteIcon />
                </IconButton>}
                {r.isDeleted && <IconButton onClick={undoDeleteItem(i)}>
                  <UndoIcon />
                </IconButton>}
              </TableCell>
            </TableRow>))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <br />
      <TextField
        id="goto-room-message"
        label="Goto Room Message"
        helperText="Message will send to client when they are moved to a room. This will send via SMS and Push, if enabled."
        style={{ width: 'auto' }}
        multiline
        value={state.gotoRoomMessage}
        onChange={e => setState(evolve({ gotoRoomMessage: () => e.target.value }))}
        rows={4}
        defaultValue="{{client.firstName}}, please go to {{room.name}}."
        variant="outlined"
      />
      <br />
      <FormControlLabel
        control={
          <Checkbox
            checked={state.gotoRoomMessageEnabled}
            onChange={e => setState(evolve({ gotoRoomMessageEnabled: () => e.target.checked }))}
            name="gotoRoomMessageEnabled"
            color="primary"
          />
        }
        label="Enable Goto Room Message"
      />
    </div>
  );
};



export const WaitingRoomSettings = ({
  setState,
  update,
  state,
  hasTimeBased,
  hasFrontdesk,
  ehrSystem
}) => {
  return (
    <div>
      <FormControl variant="standard">
        <InputLabel id="demo-simple-select-label">Geofence Setting</InputLabel>
        <SelectWide
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={state.geofenceSetting}
          onChange={(e) => update({ geofenceSetting: e.target.value })}
        >
          {geofenceSettings.map(({ value, text }) => <MenuItem key={value} value={value}>{text}</MenuItem>)}
        </SelectWide>
      </FormControl>
      <FormHelperText>
        <i>Selecting "Check-In from App" will enable clients to check themselves in.</i>
        <br />
        <i>Selecting "Quick Barcode Notification" will give your clients a notification when they get to your office to open the barcode quickly.</i>
      </FormHelperText>
      <br />
      {hasTimeBased &&
        <>
          <FormControl variant="standard">
            <InputLabel id="demo-simple-select-label">
              Arrival Method Setting
            </InputLabel>
            <SelectWide
              labelId="arrival-label"
              id="arrival-select"
              value={state.checkInMethod}
              onChange={(e) => update({ checkInMethod: e.target.value })}
            >
              {checkInMethods.map(({ value, text }) => <MenuItem key={value} value={value}>{text}</MenuItem>)}
            </SelectWide>
          </FormControl>
          <FormHelperText>
            <i>Selecting "Geolocation" will cause the app to ask users to enable location services in the app. This will only let clients check-in once they've arrived at the office.</i>
            <br />
            <i>Selecting "Time Based" will allow clients to check-in anytime between the selected before and after appointment time settings. This does NOT require location services in the app.</i>
          </FormHelperText>
        </>}
      <br />
      {hasTimeBased && state.checkInMethod === 'TimeBased' &&
        <>
          <TextField
            label='Before Time Setting'
            type='number'
            variant="standard"
            value={state.checkInBeforeMinutes}
            onChange={(e) => setState(assoc('checkInBeforeMinutes', Number(e.target.value)))}
            error={state.checkInBeforeMinutes < 0}
            helperText={state.checkInBeforeMinutes < 0 ? 'This value must not be less than zero (0).' : undefined}
          />
          <FormHelperText>
            <i>How early a client can check-in for an appointment in minutes.</i>
          </FormHelperText>
          <br />
        </>}
      {hasTimeBased && state.checkInMethod === 'TimeBased' &&
        <>
          <TextField
            label='After Time Setting'
            type='number'
            variant="standard"
            value={state.checkInAfterMinutes}
            onChange={(e) => setState(assoc('checkInAfterMinutes', Number(e.target.value)))}
            error={state.checkInAfterMinutes < 0}
            helperText={state.checkInAfterMinutes < 0 ? 'This value must not be less than zero (0).' : undefined}
          />
          <FormHelperText>
            <i>How late a client can check-in for an appointment in minutes. If you don't want clients to check-in past the appointment time, set this to zero (0).</i>
          </FormHelperText>
          <br />
        </>}
      {hasFrontdesk && <FrontDeskSettings setState={setState} update={update} state={state} ehrSystem={ehrSystem} />}
      {hasFrontdesk && <RoomSettings setState={setState} state={state} ehrSystem={ehrSystem} />}
      {!hasFrontdesk && state.geofenceSetting === 'CheckIn' &&
        <div style={{ marginBottom: '50px' }}>
          <Editor
            title={'"Come In" Message:'}
            body={state.messageText}
            isSMS={true}
            patch={(prop, data) => setState(assoc(prop, data))}
            emoji={state.emoji}
            messagesPatch={(b, e) => setState(assoc('messageText', e))}
            placeholders={[
              { title: 'Client', placeholders: clientPlaceholders },
              { title: 'Office', placeholders: officePlaceholders },
              {
                title: 'Appointment',
                placeholders: apptPlaceholders,
                info: () => (
                  <Tooltip
                    title="Applies only to the client's next/previous appointment"
                    placement="top"
                    arrow
                  >
                    <InfoIcon style={{ fontSize: 14 }} />
                  </Tooltip>
                )
              }
            ]}
          />
        </div>
      }
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error.main
  },
  tableContainer: {
    marginTop: '20px',
    marginLeft: '-20px',
    marginRight: '-20px',
    width: 'auto',
    '& table tr td': {
      fontSize: '13px'
    },
    '& table tr th': {
      fontSize: '13px'
    },
    '& .MuiTableCell-sizeSmall:last-child': {
      paddingRight: '20px'
    },
    '& .MuiTableCell-sizeSmall:first-child': {
      paddingLeft: '20px'
    }
  }
}));

export const SettingsPopup = ({
  handleClose,
  open,
  settings,
  hasFrontdesk,
  hasTimeBased,
  ehrSystem,
  title = 'Virtual Check-in Settings'
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    saving,
    saveErrorMessage,
    errorMessage,
    busy
  } = useSelector(state => state.waiting);

  const {
    geofenceSetting,
    messageText,
    checkInMethod,
    checkInBeforeMinutes,
    checkInAfterMinutes,
    gotoRoomMessage,
    gotoRoomMessageEnabled,
    minutesUntilMissed,
    rooms,
    roomCalling,
    roomsSyncEnabled
  } = settings;

  const [state, setState] = useState({
    geofenceSetting,
    checkInMethod,
    messageText,
    emoji: false,
    minutesUntilMissed,
    rooms,
    roomCalling,
    gotoRoomMessageEnabled,
    gotoRoomMessage,
    checkInBeforeMinutes,
    checkInAfterMinutes,
    roomsSyncEnabled
  });

  const update = data => setState(s => merge(s, data));

  const save = () => dispatch(saveSettings({
    geofenceSetting: state.geofenceSetting,
    checkInBeforeMinutes: state.checkInBeforeMinutes,
    checkInAfterMinutes: state.checkInAfterMinutes,
    minutesUntilMissed: Number(state.minutesUntilMissed),
    messageText: state.messageText,
    checkInMethod: state.checkInMethod,
    rooms: state.rooms,
    roomCalling: state.roomCalling,
    gotoRoomMessage: state.gotoRoomMessage,
    gotoRoomMessageEnabled: state.gotoRoomMessageEnabled,
    roomsSyncEnabled: state.roomsSyncEnabled,
    hasTimeBased,
    hasFrontdesk,
  })).then(() => {
    dispatch(getSettings());
  }).then(() => {
    handleClose();
  });

  return (
    <Modal
      title={title}
      open={open}
      onClose={handleClose}
      size='md'
      className='sked-test-virtual-check-in-settings-modal'
      buttons={[
        <HeaderButton
          title='Save'
          color='primary'
          onClick={save}
          disabled={
            saving ||
            busy ||
            state.checkInBeforeMinutes < 0 ||
            state.checkInAfterMinutes < 0
          }
          className='sked-test-virtual-check-in-settings-modal-button-save'
        />
      ]}
    >
      {title !== 'Virtual Check-in Settings' && (
        <Grid marginBottom={2}>
          <PageHeader variant="h5">Virtual Check In</PageHeader>
        </Grid>
      )}
      <WaitingRoomSettings
        classes={classes}
        state={state}
        setState={setState}
        update={update}
        hasFrontdesk={hasFrontdesk}
        hasTimeBased={hasTimeBased}
        ehrSystem={ehrSystem}
      />
      {errorMessage && <p className={classes.errorMessage}>{errorMessage}</p>}
      {saveErrorMessage && <p className={classes.errorMessage}>{saveErrorMessage}</p>}
    </Modal>
  );
};
