import * as at from '../../actionTypes';
import api from '../../services/api.js';
import { remoteAction } from '../../services/actionService.js';
import { cond, equals, always, T, pathOr } from 'ramda';
import { Automation } from './automation-types';

export const automationsPatch = (data: any) => ({
  type: at.AUTOMATIONS_PATCH,
  data,
});

interface GetAutomationsQuery {
  trigger?: string;
}

interface GetAutomationsProps {
  page?: number;
  perPage?: number;
  query?: GetAutomationsQuery;
}

export const getAutomations = ({
  page = 1, perPage = 25, query = {},
}: GetAutomationsProps) => remoteAction({
  type: at.AUTOMATIONS_REMOTE_GET,
  action: () => api.post('automations/query', {
    page,
    perPage,
    query: {
      ...query,
      trigger: {
        trigger: cond([
          [equals('Appointment'), always('ApptUpdated')],
          [equals('Campaign'), always('ClientList')],
          [equals('Message Received'), always('MessageReceived')],
          [equals('Time'), always('Time')],
          [equals('Date & Time'), always('DateTime')],
          [T, always(undefined)]
        ])(query.trigger)
      }
    },
  }).then((data) => ({
    ...data,
    perPage,
    page,
  })),
});

export const getNpps = () => remoteAction({
  type: at.AUTOMATIONS_REMOTE_GET_NPPS,
  action: () => api.get('settings/npp'),
});

export const newAutomation = () => remoteAction({
  type: at.AUTOMATIONS_REMOTE_POST,
  action: () => api.post('automations', {
    actions: [],
    enabled: false,
    trigger: {
      Dummy: [],
    },
    hidden: false,
  }).catch(() => {
    return ({
      actions: [{
        action: {
          Dummy: []
        },
        id: 0
      }],
      enabled: true,
      id: 0,
      hidden: false,
      trigger: {
        Dummy: []
      }
    });
  }),
});

interface SaveAutomation extends Automation {
  isTemplate?: boolean;
  deleteActions: number[];
}

export const saveAutomation = (auto: SaveAutomation) => {
  const url = auto.isTemplate ? 'automations/template' : 'automations';
  if (auto.id === 0) {
    const postData = { ...auto };
    return api.post(url, postData);
  }
  return api.put(`${url}/${auto.id}`, auto);
};

interface DeleteAutomationProps {
  id: number;
  isTemplate: boolean;
}

export const deleteAutomation = ({ id, isTemplate }: DeleteAutomationProps) => {
  const url = isTemplate ? 'automations/template' : 'automations';
  return api.delete(`${url}/${id}`, { data: [] });
};

export const updateAllAutomation = (data: any) => {
  return ({
    type: at.AUTOMATIONS_UPDATE_ALL_AUTOMATIONS,
    data,
  });
};

export const addLocalAutomation = (auto: Automation = null) => ({
  type: at.AUTOMATIONS_ADD_AUTOMATIONS,
  data: auto
});

export const removeAutomation = (index: number) => ({
  type: at.AUTOMATIONS_REMOVE_AUTOMATIONS,
  data: {
    index,
  }
});

export const updateAutomation = (index: number, automation: any) => {
  return ({
    type: at.AUTOMATIONS_UPDATE_AUTOMATIONS,
    data: {
      index,
      automation,
    }
  });
};

export const getAutomationsByMsgId = (msgId: number, template: boolean, wipeIds?: boolean) => (dispatch: any, getStore: any) => {
  if (template) {
    const campaignId = pathOr(null, ['templateEdit', 'campaignId'], getStore());
    const path = campaignId ? `tmp.cl.cmp_${campaignId}.msg_${msgId}` : `tmp.au.msg_${msgId}`;
    return dispatch(remoteAction({
      type: at.AUTOMATIONS_REMOTE_GET,
      action: () => api.post('automations/template/query', {
        page: 1,
        perPage: 100,
        query: {
          path: {
            Exists: {
              isDirectDescendant: path.split('.'),
            },
          },
        }
      }).then((data) => {
        const newData = wipeIds ? data.data.map((a: Automation) => ({ ...a, id: 0 })) : data.data;
        return ({
          ...data,
          data: newData,
          perPage: 100,
          page: 1,
        });
      }),
    }));
  }
  return dispatch(remoteAction({
    type: at.AUTOMATIONS_REMOTE_GET,
    action: () => api.post('automations/query', {
      page: 1,
      perPage: 100,
      query: {
        action: {
          msgId,
        },
      }
    }).then((data) => ({
      ...data,
      perPage: 100,
      page: 1,
    })),
  }));
};

interface ToggleAutomationProps {
  id: number;
  enabled: boolean;
}

export const toggleAutomation = ({ id, enabled }: ToggleAutomationProps) => {
  return api.put(`automations/toggle/${id}`, { enabled });
};
