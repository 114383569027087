import React from 'react';
import {
  TextField,
  CircularProgress,
  FormControl,
  FormHelperText,
  Autocomplete,
} from '@mui/material';
import api from '../../../services/api.js';
import { useTypeahead } from '../../../services/typeahead.hook.js';
import { isNil, pathOr } from 'ramda';
import { Row } from '../../../components/PageHeader';
import { DefaultList, Message, Form } from '../automation-types';


const searchClientList = ({ name = '', isCampaign = false }) => {
  return api.post('list/client/query', {
    query: {
      isCampaign,
      name,
    },
    page: 1,
    perPage: 25
  });
};

type ClientListSearchProps = {
  clientLists: DefaultList;
  setClientLists: (v: DefaultList) => void;
  clearClientLists: () => void;
  style?: object;
  isCampaign?: boolean;
  error?: boolean;
}
export const ClientListSearch = ({
  clientLists,
  setClientLists,
  clearClientLists,
  style,
  isCampaign = false,
  error = false,
}: ClientListSearchProps) => {
  const [open, setOpen] = React.useState(false);
  const [autoVal, setAutoVal] = React.useState(null);
  const [oneTimeAutoSearch, setOneTimeAutoSearch] = React.useState(true);

  const clientState = useTypeahead(searchClientList, { data: [] });

  React.useEffect(() => {
    if (clientLists) {
      setAutoVal({
        name: clientLists.listName
      });
    }
  }, [clientLists]);

  React.useEffect(() => {
    clientState.invoke({ name: '', isCampaign });
  }, [isCampaign]);

  const handleChange = (value: DefaultList) => {
    setAutoVal(value);
    if (value) {
      setClientLists(value);
      setTimeout(() => {
        setAutoVal({
          name: value.name,
        });
      });
      return;
    }
    clearClientLists();
  };

  const handleInputChange = (value: string) => {
    console.log('set input change', value);
    clientState.invoke({ name: value.trim(), isCampaign });
  };

  return (
    <Row style={{ flexWrap: 'wrap' }}>
      <FormControl error={error}>
        <Autocomplete
          id="client-search"
          style={{ ...style }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          filterOptions={(list) => list}
          isOptionEqualToValue={(option: DefaultList, value: DefaultList) => option.name === value.name}
          getOptionLabel={(option) => {
            if (isNil(option)) {
              return '';
            }
            return pathOr(option.name, ['campaign', 'displayName'], option);
          }}
          options={clientState.data.data}
          loading={clientState.loading}
          value={autoVal}
          onChange={(_e, value) => handleChange(value)}
          onInputChange={(_e, value) => handleInputChange(value)}
          onFocus={() => {
            if (oneTimeAutoSearch) {
              handleInputChange(' ');
            }
            setOneTimeAutoSearch(false);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={isCampaign ? 'Search Campaigns' : 'Search Client Lists'}
              placeholder='Name'
              error={error}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {clientState.loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <FormHelperText>{error ? 'Required' : ' '}</FormHelperText>
      </FormControl>
    </Row>
  );
};

const searchApptList = ({ name = '' }) => {
  return api.post('list/appointment/query', {
    query: {
      name,
    },
    page: 1,
    perPage: 25
  });
};

type ApptListSearchProps = {
  apptLists: DefaultList;
  setApptLists: (v: DefaultList) => void;
  clearApptLists: () => void;
  style?: object;
  isCampaign?: boolean;
  error?: boolean;
}
export const ApptListSearch = ({
  apptLists,
  setApptLists,
  clearApptLists,
  style,
  error = false,
}: ApptListSearchProps) => {
  const [open, setOpen] = React.useState(false);
  const [autoVal, setAutoVal] = React.useState(null);
  const [oneTimeAutoSearch, setOneTimeAutoSearch] = React.useState(true);

  const apptState = useTypeahead(searchApptList, { data: [] });

  React.useEffect(() => {
    if (apptLists) {
      setAutoVal({
        name: apptLists.listName || ''
      });
    }
  }, [apptLists]);

  const handleChange = (value: DefaultList) => {
    setAutoVal(value);
    if (value) {
      setApptLists(value);
      setTimeout(() => {
        setAutoVal({
          name: value.name,
        });
      });
      return;
    }
    clearApptLists();
  };

  const handleInputChange = (value: string) => {
    console.log('set input change', value);
    apptState.invoke({ name: value?.trim() });
  };

  return (
    <Row style={{ flexWrap: 'wrap' }}>
      <FormControl error={error}>
        <Autocomplete
          id="appt-list-search"
          style={{ ...style }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          filterOptions={(list) => list}
          isOptionEqualToValue={(option: DefaultList, value: DefaultList) => option.name === value.name}
          getOptionLabel={(option) => {
            if (isNil(option)) {
              return '';
            }
            return pathOr(option.name, ['campaign', 'displayName'], option);
          }}
          options={apptState.data.data}
          loading={apptState.loading}
          value={autoVal}
          onChange={(_e, value) => handleChange(value)}
          onInputChange={(_e, value) => handleInputChange(value)}
          onFocus={() => {
            if (oneTimeAutoSearch) {
              handleInputChange(' ');
            }
            setOneTimeAutoSearch(false);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Search Appointment Lists'
              placeholder='Name'
              error={error}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {apptState.loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <FormHelperText>{error ? 'Required' : ' '}</FormHelperText>
      </FormControl>
    </Row>
  );
};

const searchMessage = (name = '') => {
  return api.post('message/query', {
    query: {
      messageType: 'Automation',
      name: name.trim(),
    },
    page: 1,
    perPage: 25
  });
};

type MessageSearchProps = {
  messages: Message;
  setMessages: (v: DefaultList) => void;
  clearMessages: () => void;
  style?: object;
  error?: boolean;
}
export const MessageSearch = ({
  messages,
  setMessages,
  clearMessages,
  style,
  error,
}: MessageSearchProps) => {
  const [open, setOpen] = React.useState(false);
  const [autoVal, setAutoVal] = React.useState(null);
  const [oneTimeAutoSearch, setOneTimeAutoSearch] = React.useState(true);

  const clientState = useTypeahead(searchMessage, { data: [] });

  React.useEffect(() => {
    if (messages.msgId) {
      setAutoVal({
        name: messages.name || ''
      });
    }
  }, [messages]);

  const handleChange = (value: DefaultList) => {
    setAutoVal({ name: value?.name || '' });
    if (value) {
      setMessages(value);
      setTimeout(() => {
        setAutoVal({
          name: value.name,
        });
      });
      return;
    }
    clearMessages();
  };

  const handleInputChange = (value: string) => {
    console.log('set input change', value);
    clientState.invoke(value ? value : ' ');
  };

  return (
    <Row style={{ flexWrap: 'wrap' }}>
      <FormControl error={error}>
        <Autocomplete
          id="client-search"
          style={{ ...style }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          filterOptions={(list) => list}
          getOptionLabel={(option) => {
            if (isNil(option)) {
              return '';
            }
            return option.name;
          }}
          options={clientState.data.data}
          loading={clientState.loading}
          value={autoVal}
          onChange={(_e, value) => handleChange(value)}
          onInputChange={(_e, value) => handleInputChange(value)}
          isOptionEqualToValue={(option: DefaultList, value: DefaultList) => option?.name === value?.name}
          onFocus={() => {
            if (oneTimeAutoSearch) {
              handleInputChange(' ');
            }
            setOneTimeAutoSearch(false);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Messages"
              placeholder='Name'
              error={error}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {clientState.loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <FormHelperText>{error ? 'Required' : ' '}</FormHelperText>
      </FormControl>
    </Row>
  );
};

type NppType = {
  newPatientPortalSettingsId: number;
  name?: string;
}
type NppSearchProps = {
  value: number | number[];
  onChange: (v: { target: { value: number | number[] } }) => void;
  npps: NppType[];
  id: string;
  label?: boolean;
  style?: object;
  error?: boolean;
  multiple?: boolean;
}
export const NppSearch = ({
  value = [], onChange, npps = [], id, label = true, error = false,
  multiple = false, style = {},
}: NppSearchProps) => {
  const selectedNpp = React.useMemo(() => {
    if (multiple) {
      return (value as number[]).map((v) => npps.find(l => l.newPatientPortalSettingsId === v));
    }
    return npps.find(l => l.newPatientPortalSettingsId === value);
  }, [value, npps]);

  return (
    <FormControl error={error}>
      <Autocomplete
        id={id}
        style={{ ...style }}
        options={npps}
        onChange={(_, t) => {
          if (multiple) {
            return t && onChange({ target: { value: (t as NppType[]).map(({ newPatientPortalSettingsId }) => newPatientPortalSettingsId) } });
          }
          return t && onChange({ target: { value: (t as NppType).newPatientPortalSettingsId } });
        }}
        value={selectedNpp}
        getOptionLabel={(option) => `${(option as NppType).name || ''}`}
        autoHighlight
        multiple={multiple}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            label={label ? 'New Patient Portal' : null}
          />
        )}
      />
      <FormHelperText>{error ? 'Required' : ' '}</FormHelperText>
    </FormControl>
  );
};


const searchForm = (name: string) => {
  const trimmed = name.trim();
  return api.post('form/query', {
    page: 1,
    perPage: 1000,
    query: {
      name: trimmed === '' ? undefined : trimmed,
    },
  });
};

type FormSearchProps = {
  value: Form;
  setForms: (v: DefaultList) => void;
  clearForms: () => void;
  style?: object;
  error?: boolean;
  id?: string;
}
export const FormSearch = ({
  value = {} as Form,
  setForms,
  clearForms,
  style,
  error,
  id,
}: FormSearchProps) => {
  const [open, setOpen] = React.useState(false);
  const [autoVal, setAutoVal] = React.useState(null);
  const [oneTimeAutoSearch, setOneTimeAutoSearch] = React.useState(true);

  const formsState = useTypeahead(searchForm, { data: [] });

  React.useEffect(() => {
    setAutoVal({
      name: value ? value.name : '',
    });
  }, []);

  const handleChange = (v: DefaultList) => {
    setAutoVal(v);
    if (v) {
      setForms(v);
      
      setTimeout(() => {
        setAutoVal({
          name: v.name,
        });
      });
      return;
    }
    clearForms();
    return;
  };

  const handleInputChange = (v: string) => {
    console.log('set input change', v);
    formsState.invoke(v || ' ');
  };

  return (
    <Row style={{ flexWrap: 'wrap' }}>
      <FormControl error={error}>
        <Autocomplete
          id={id}
          style={{ ...style }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          filterOptions={(list) => list}
          isOptionEqualToValue={(option: DefaultList, value: DefaultList) => option?.name === value?.name}
          getOptionLabel={(option) => {
            return option.name;
          }}
          options={formsState.data.data}
          loading={formsState.loading}
          value={autoVal}
          onChange={(_e, v) => handleChange(v)}
          onInputChange={(_e, v) => handleInputChange(v)}
          onFocus={() => {
            if (oneTimeAutoSearch) {
              handleInputChange(' ');
            }
            setOneTimeAutoSearch(false);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Forms"
              placeholder='Name'
              error={error}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {formsState.loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <FormHelperText>{error ? 'Required' : ' '}</FormHelperText>
      </FormControl>
    </Row>
  );
};
