import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
    margin: 20,
    position: 'relative',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,

    '& > h3': {
      margin: 0,
      padding: 0,
      height: 'auto',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    '& .MuiTextField-root': {
      width: '100%',
    },
  },
  date: {
    width: '100%',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
  relative: {
    position: 'relative',
  },
  color: {
    position: 'absolute',
    bottom: 15,
    right: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  labelContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
  },
}));
