import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  TextField,
  InputAdornment,
  Snackbar,
  Alert,
} from '@mui/material';
import Search from '@mui/icons-material/Search';
import * as R from 'ramda';
import api from '../../services/api.js';
import { PopupTemplate, popup } from '../../services/Popup.js';
import { Office } from '../Login/login.reducer';

type OfficeFeaturesProps = {
  open: boolean;
  office: Office;
  onClose: () => void;
}

export default function OfficeFeatures({
  open,
  office,
  onClose,
}: OfficeFeaturesProps) {
  const [status, setStatus] = useState('INIT');
  const [busy, setBusy] = useState(false);
  const [allFeatures, setAllFeatures] = useState<string[]>([]);
  const [features, setFeatures] = useState<string[]>([]);
  const [displayedFeatures, setDisplayedFeatures] = useState<string[]>([]);
  const [toDelete, setToDelete] = useState<string[]>([]);
  const [toAdd, setToAdd] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [snackbar, setSnackbar] = useState(false);

  const getFeatures = () => {
    setStatus('EDIT');
    setBusy(true);
    Promise.all([
      api.get(`feature/office/${office.id}`),
      api.get('feature')
    ]).then(([features, allFeatures]) => {
      const sorted: string[] = R.sortBy(R.identity, allFeatures);
      setFeatures(features);
      setAllFeatures(sorted);
      setDisplayedFeatures(sorted);
      setBusy(false);
    }).catch((error) => {
      setBusy(false);
      if (error.response.status === 404) {
        console.log(error);
        popup('Error!', 'Failed to get office features!');
      }
    });
  };

  const setDefaults = () => {
    setFeatures([]);
    setAllFeatures([]);
    setBusy(false);
    setStatus('INIT');
    setToAdd([]);
    setToDelete([]);
    setSearch('');
    setDisplayedFeatures([]);
  };

  const filter = (searchText: string) => {
    setSearch(searchText);
    const query = searchText.toLowerCase();
    const newDisplay = R.filter((feature: string) => {
      const searchBy = feature.toLowerCase();
      return searchBy.indexOf(query) !== -1;
    })(allFeatures);

    setDisplayedFeatures(newDisplay);
  };

  const onSaveHandler = () => {
    const add = R.filter((a) => !R.find(R.equals(a))(features))(toAdd);
    const delet = R.filter((d) => !!R.find(R.equals(d))(features))(toDelete);
    Promise.all([
      api.post(`feature/office/${office.id}`, add),
      api.delete(`feature/office/${office.id}`, { data: delet })
    ]).then(() => {
      setSnackbar(true);
      setFeatures(R.pipe(
        R.concat(add),
        R.without(delet)
      )(features));
      setToAdd([]);
      setToDelete([]);
    }).catch((error) => {
      console.log(error);
      popup('Error!', 'Failed to set office feature!');
    });
  };

  const onCheck = (feature: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setToDelete(R.without([feature], toDelete));
      setToAdd(R.append(feature, toAdd));
    } else {
      setToAdd(R.without([feature], toAdd));
      setToDelete(R.append(feature, toDelete));
    }
  };

  useEffect(() => {
    if (status === 'INIT' && open)
      getFeatures();
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setDefaults();
        onClose();
      }}>
      <DialogTitle>
        {R.isEmpty(office) ? 'Office Features' : `Features for ${office.name}`}
      </DialogTitle>
      <DialogContent dividers>
        <div style={{
          width: '100%',
          height: '500px',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <PopupTemplate />
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={snackbar}
            autoHideDuration={5e3}
            onClose={() => setSnackbar(false)}>
            <div>
              <Alert
                style={{ fontSize: '16px' }}
                severity="success">
                Office's Feature Updated!
              </Alert>
            </div>
          </Snackbar>

          {busy && <div className="loader"></div>}
          {!busy &&
            <div>
              <i>NOTE: You cannot remove features that are plan specific.</i>
              <br />
              <TextField
                name="client-search"
                label="Search"
                value={search}
                onChange={(e) => {
                  filter(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search style={{ fontSize: '16px' }} />
                    </InputAdornment>
                  ),
                }} />
              <br />
              <div style={{
                display: 'grid',
                gridTemplateColumns: 'auto auto auto auto auto',
              }}>
                {R.map((feature: string) => {
                  const isEnabled = Boolean(R.find(R.equals(feature))(features));
                  const isAdd = Boolean(R.find(R.equals(feature))(toAdd));
                  const isDelete = Boolean(R.find(R.equals(feature))(toDelete));
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          key={feature}
                          checked={(isEnabled && !isDelete) || isAdd}
                          onChange={onCheck(feature)}
                          name="acknowledge-checkbox"
                          color="primary"
                        />
                      }
                      label={feature}
                    />
                  );
                })(displayedFeatures)}
              </div>
            </div>}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ float: 'left' }}
          variant='contained'
          disabled={R.isEmpty(toAdd) && R.isEmpty(toDelete)}
          onClick={onSaveHandler}>
          Save
        </Button>
        <Button
          onClick={() => {
            setDefaults();
            onClose();
          }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
