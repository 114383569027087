import { AnyAction } from 'redux';
import * as at from '../../actionTypes';
import * as R from 'ramda';

export type Receivers = {
  DateRange?: {
    appointmentTypes: {
      Some: string[]
    };
    end: string;
    start: string;
  };
  Clients?: number[];
  ClientTag?: {
    [x: string]: string[];
  };
  Everyone?: string[];
}

export type MessageType = {
  ApptChange?: {
    appointmentTypeIds: number[];
    changeType: string[];
    delay: number;
    source: string;
  };
  Clients?: number[];
  OneTime?: {
    includeInactive: boolean;
    receivers?: Receivers;
    scheduledFor: string;
    wasSent?: boolean;
  };
  Automation?: object[];
  AutomationBased?: object[];
  Recall?: {
    appointmentTypes: number[];
    days: number;
    sendAt: string;
    includeInactive: boolean;
  };
  Recurring?: {
    appointmentTypeIds: {
      Some: []
    };
    time?: {
      Minute: number;
      Hour: number;
      Day: number;
      Week: number;
    };
  }
  name?: string;
  officeId?: number;
  push?: boolean;
  Birthday?: {
    sendAt: string;
  };
}

export type Message = {
  email: {
    subject: string;
    attachmentIds: number[];
    body: {
      PlainText: string;
      HTML?: {
        body: string;
        shouldFrame: boolean;
      }
    }
  },
  subject?: string;
  index?: number;
  forFeature?: string;
  isDeleted: boolean;
  selected?: boolean;
  isTemplate: boolean;
  createdAt: string;
  messageType: MessageType;
  campaignId: number;
  name: string;
  isEnabled: boolean;
  sms: {
    attachmentIds: number[];
    body: string;
  },
  id: number;
  officeId: number;
  push: {
    subject: string;
    body: string;
  }
}

type TemplatesReducer = {
  busy?: boolean;
  autoBusy?: boolean;
  state?: string;
  all?: boolean;
  loadId?: number,
  OneTime: Message[],
  Recurring: Message[],
  Recall: Message[],
  Birthday: Message[],
  ApptChange: Message[],
  Automation: Message[],
  AutomationBased: Message[],
  messages?: Message[],
}

const init: TemplatesReducer = {
  busy: false,
  autoBusy: false,
  state: 'LIST',
  all: false,
  OneTime: [],
  Recurring: [],
  Recall: [],
  Birthday: [],
  ApptChange: [],
  Automation: [],
  AutomationBased: [],
};

const mergeTemplates = (templates: TemplatesReducer, message: object): TemplatesReducer => {
  return R.merge(templates, message);
};

export default function reducer(templates = init, action: AnyAction): TemplatesReducer {
  switch (action.type) {
    case at.TEMPLATES_PATCH:
      return R.merge(templates, action.data);
    case at.TEMPLATES_SELECT_PATCH: {
      const merge = (message: Message): Message => {
        return R.merge(R.__, { selected: action.data.value })(message);
      };
      return R.evolve({
        [action.data.type]: (list: Message[]): Message[] => {
          const message = R.pipe(
            R.nth(action.data.index),
            merge,
          )(list);
          return (R.update(action.data.index, message, list));
        }
      }, templates);
    }
    case at.TEMPLATES_SELECT_ALL_PATCH:
      return R.evolve({
        all: () => action.data.value,
        [action.data.type]: R.map((message: Message) => R.merge(message, { selected: action.data.value }))
      }, templates);
    case at.TEMPLATES_REMOTE_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(templates, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const data: Message[] = action.data;
        const {
          OneTime = [],
          Recurring = [],
          Recall = [],
          Birthday = [],
          ApptChange = [],
          Automation = [],
        } = R.pipe(
          R.filter((message: Message) => !message.isDeleted),
          R.sortBy(R.prop('createdAt')),
          (messages: Message[]): Message[] => R.reverse(messages),
          R.groupBy(
            R.pipe(
              R.prop('messageType'),
              R.keys,
              (props: (keyof TemplatesReducer)[]) => R.head(props)
            )
          ))(data);
        return mergeTemplates(templates, {
          OneTime,
          Recurring,
          Recall,
          Birthday,
          ApptChange,
          Automation,
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(templates, {
          busy: false,
        });
      }
      return templates;
    }
    case at.TEMPLATES_REMOTE_AUTO_BASED_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(templates, {
          autoBusy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const data: Message[] = action.data?.data || [];
        const AutomationBased = R.pipe(
          R.filter((message: Message) => !message.isDeleted),
          R.sortBy(R.prop('createdAt')),
          (messages: Message[]): Message[] => R.reverse(messages),
        )(data);
        return mergeTemplates(templates, {
          AutomationBased,
          autoBusy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(templates, {
          autoBusy: false,
        });
      }
      return templates;
    }
    case at.TEMPLATES_REMOTE_GET_ONE_TYPE: {
      if (action.state === 'REQUEST') {
        return R.merge(templates, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        console.log(action.data);
        return R.merge(templates, action.data);
      } else if (action.state === 'ERROR') {
        return R.merge(templates, {
          busy: false,
        });
      }
      return templates;
    }
    case at.TEMPLATES_REMOTE_DELETE: {
      if (action.state === 'REQUEST') {
        return R.merge(templates, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.evolve({
          busy: R.F,
          all: R.F,
          [action.data.type]: (temps) => {
            if (action.data.isMany) {
              return R.filter(({ selected }) => !selected)(temps);
            } else {
              return R.filter(({ id }) => id !== action.data.id)(temps);
            }
          }
        }, templates);
      } else if (action.state === 'ERROR') {
        return R.merge(templates, {
          busy: false,
        });
      }
      return templates;
    }
    case at.TEMPLATES_TOGGLE:
      return R.evolve({
        messages: R.map((message: Message) => {
          if (message.id === action.data.id) {
            return R.merge(message, { isEnabled: action.data.isEnabled });
          }
          return message;
        })
      }, templates);
    case at.TEMPLATES_REMOTE_GET_SEARCH: {
      if (action.state === 'REQUEST') {
        return R.merge(templates, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.evolve({
          [action.data.messageType]: R.always(action.data.messages),
          busy: R.F,
        }, templates);
      } else if (action.state === 'ERROR') {
        return R.merge(templates, {
          busy: false,
        });
      }
      return templates;
    }
    default:
      return templates;
  }
}
