import React, { useState } from 'react';

import * as R from 'ramda';

import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Chip,
  CircularProgress,
  TextField,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { Office } from '../../routes/Admin/Agencies/agencies.types';

import { useStyles } from './selectOffices.styles';

type SelectOfficesProps = {
  open: boolean;
  title: string;
  save: () => void;
  cancel: () => void;
  onAdd: (office: Office) => void;
  onRemove: (office: Office) => void;
  getMore?: (search?: string) => void;
  searchOffices?: (search: string) => void;
  selectedItems?: Office[];
  offices?: Office[];
  loadingOffices?: boolean;
  loadingMore?: boolean;
  loadingSelected?: boolean;
}

const SelectOffices = ({
  title,
  open,
  save,
  cancel,
  onAdd,
  onRemove,
  getMore,
  searchOffices,
  selectedItems,
  loadingMore,
  offices = [],
  loadingOffices,
  loadingSelected,
}: SelectOfficesProps) => {
  const classes = useStyles();

  const [search, setSearch] = useState('');
  const [time, setTime] = useState<NodeJS.Timeout>();

  const restOffices = R.difference(offices, selectedItems);

  const onScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target as HTMLDivElement;
    if (Math.abs(scrollHeight - clientHeight - scrollTop) < 80) {
      if (!loadingOffices && getMore) {
        getMore(search);
      }
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    clearTimeout(time);
    const result = setTimeout(() => {
      searchOffices(value);
    }, 600);
    setTime(result);
  };

  const handleClose = () => {
    setSearch('');
    cancel();
  };

  const handleSave = () => {
    setSearch('');
    save();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle className={classes.header}>
        {title}
        <IconButton aria-label="close" onClick={handleClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.containerSelect}>
        <Paper className={classes.card}>
          <h4>Available Offices</h4>
          <TextField
            label=''
            value={search}
            className={classes.input}
            required
            placeholder="Search"
            onChange={(event) => handleSearch(event.target.value)}
          />
          <div className={classes.cardList} onScroll={onScroll}>
            {loadingOffices ? (
              <CircularProgress />
            ) : restOffices.map((office) => (
              <Chip
                className={classes.chip}
                icon={<span>{office.id}</span>}
                key={`${office.id}-${office.name}`}
                label={office.name}
                onClick={() => onAdd(office)}
              />
            ))}
            {loadingMore && <CircularProgress size={20} className={classes.loading} />}
          </div>
        </Paper>
        <Paper className={classes.card}>
          <h4>Assigned Offices</h4>
          <div className={`${classes.cardList} ${classes.leftCard}`}>
            {loadingSelected ? (
              <CircularProgress />
            ) : selectedItems.map((item) => (
              <Chip
                className={classes.chip}
                icon={<span>{item.id}</span>}
                key={`${item.id}-${item.name}`}
                label={item.name}
                onDelete={() => onRemove(item)}
              />
            ))}
          </div>

        </Paper>
      </DialogContent>
      <DialogActions className={classes.actionContainer}>
        <Button
          variant='outlined'
          onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={loadingOffices || loadingSelected}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectOffices;
