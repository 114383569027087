import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  chatItemRoot: {
    marginTop: '15px',
    width: '100%',
    justifyContent: 'center'
  },
  chatItemContainer: {
    display: 'flex',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  messageContent: {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word'
  },
  messageTime: {
    display: 'flex',
    marginTop: '4px',
    
    '& .MuiSvgIcon-root': {
      fontSize: 14,
      height: 'auto',
      marginLeft: 3,
    }
  },
  date: {
    fontSize: 11,
    color: 'rgba(0,0,0,.5)',
    cursor: 'pointer',
  },
  leftTail: {
    position: 'absolute',
    bottom: 2,
    transform: 'scaleX(-1)',
    left: -5,
    scale: 1.3,
  },
  tail: {
    position: 'absolute',
    bottom: 2,
    right: -5,
    scale: 1.3,
  },
  mmsContainer: {
    position: 'relative',
    width: 'min-content',
  },
  mmsItem: {
    maxWidth: '290px',
  },
  popup: {
    width: 'min-content',
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));