import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import {
  CircularProgress, TableHead, TableRow, TableBody, IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import * as rawActions from '../../../Clients/clients.actions';
import { outputLocation } from '../../../../services/utilities.js';
import { Row } from '../../../../components/PageHeader';
import Header from '../../../../components/PageHeader/PageHeader.component';
import { TableContainer, BodyCell, HeaderCell } from '../../../../components/CustomTable';
import { fetchClients, fetchSettings } from './NewPatientPortal.actions.js';
import { usePromise } from '../../../../services/promise.hook';
import { path } from 'ramda';
import { tzParseFormat } from '../../../../services/joda.js';
import ClientDialog from '../../../Clients/components/client-dialog/client-dialog.component.jsx';
import EditLeadDialog from '../../../Leads/components/EditLead/EditLead.component';
import ConvertLeadDialog from '../../../Leads/components/Convert/Convert.component';
import { useSelector } from '../../../../reducers';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  buttonLabel: {
    textTransform: 'none',
    fontWeight: 'normal'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  pageControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '5px'
  },
  pageArrows: {
    marginLeft: '0px',
    marginRight: '0px',
    display: 'flex',
    alignItems: 'center',
  }
}));

const NewPatientPortalClients = ({ history, match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const actions = bindActionCreators(rawActions, dispatch);
  const { tz, headerHeight } = useSelector(s => ({
    tz: path(['login', 'office', 'timezone'], s),
    headerHeight: s.login.headerHeight,
  }));
  const [clientOpen, setClientOpen] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [leadToConvert, setLeadToConvert] = useState(null);
  const [params, setParams] = useState({
    query: {
      newPatientPortalSettingsId: Number(match.params.id),
    },
    page: 1,
    perPage: 50
  });

  const settingsState = usePromise(fetchSettings, {
    settings: {}
  });
  const listState = usePromise(fetchClients, {
    totalCount: 0,
    data: [],
    perPage: 50
  });

  const viewClient = (client) => {
    if (client.isLead) {
      setSelectedLead(client);
      return;
    }
    setClientOpen(true);
    actions.selectClient(client);
  };

  React.useEffect(() => {
    settingsState.invoke(match.params.id);
  }, [match.params.id]);
  React.useEffect(() => {
    listState.invoke({
      ...params,
      aListTotalPages: listState.data.aListTotalPages,
    });
  }, [params, match.params.id]);

  const addPage = num => () => {
    setParams(s => ({
      ...s,
      page: s.page + num
    }));
  };

  return (
    <>
      <Header
        title={`Clients from  ${settingsState.data.settings.nppType} Patient Portal - ${settingsState.data.settings.name}`}
        rightIcons={[
          listState.loading && <CircularProgress size={18} />
        ]}
      />
      <div className={classes.root}>
        {listState.errorMessage && <div>{listState.errorMessage}</div>}
        {listState.data.data.length === 0 && !listState.loading && !listState.error &&
          <div>
            Nobody has {settingsState.data.nppType === 'New' ? 'signed up using' : 'scheduled with'} this portal yet.
          </div>}

        {listState.data.data.length > 0 && (
          <TableContainer
            fullPage
            maxHeight={`calc(100vh - ${headerHeight}px - 54px)`}
            scrollPaddingBottom={40}
          >
            <TableHead>
              <TableRow>
                <HeaderCell fixed>
                  Name
                </HeaderCell>
                <HeaderCell>
                  Email
                </HeaderCell>
                <HeaderCell>
                  Phone
                </HeaderCell>
                <HeaderCell>
                  Referrer
                </HeaderCell>
                <HeaderCell>
                  Signed Up
                </HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listState.data.data.map(c => (
                <TableRow key={c.clientId}>
                  <BodyCell fixed>
                    <a onClick={() => viewClient(c.client)}>
                      {c.client.firstName} {c.client.lastName}
                    </a>
                  </BodyCell>
                  <BodyCell>
                    {c.client.email}
                  </BodyCell>
                  <BodyCell>
                    {c.client.phone}
                  </BodyCell>
                  <BodyCell>
                    {c.client.referrer}
                  </BodyCell>
                  <BodyCell nowrap>
                    {tzParseFormat(c.time, tz, 'h:mm a, eee, MMM dd, yyyy')}
                  </BodyCell>
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
        )}
        <Row className={classes.pageControls}>
          <div className={classes.pageArrows}>
            <div style={{ marginRight: '5px' }}>
              {outputLocation(params.page, params.perPage, listState.data.totalCount, listState.data.data.length)}
            </div>
            <IconButton
              disabled={params.page === 1}
              onClick={addPage(-1)} >
              <ChevronLeftIcon />
            </IconButton>
            <IconButton
              disabled={params.page === listState.data.totalPages}
              onClick={addPage(1)} >
              <ChevronRightIcon />
            </IconButton>
          </div>
        </Row>
        <EditLeadDialog
          open={!!selectedLead}
          close={() => setSelectedLead(null)}
          lead={selectedLead}
          history={history}
          convertClick={() => {
            setLeadToConvert(selectedLead);
            setSelectedLead(null);
          }}
          onUpdate={() => listState.invoke(params)}
        />
        <ConvertLeadDialog
          open={!!leadToConvert}
          lead={leadToConvert}
          close={() => setLeadToConvert(null)}
          onConvert={() => {
            setLeadToConvert(null);
            listState.invoke(params);
          }}
        />
        <ClientDialog
          open={clientOpen}
          from={'CLIENTS'}
          history={history}
          onClose={() => setClientOpen(false)}
          back={actions.back}
          gotoClient={actions.back}
        />
      </div>
    </>
  );
};

export default NewPatientPortalClients;
