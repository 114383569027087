import React from 'react';
import { Link } from 'react-router-dom';
import {
  IconButton, TableHead, TableBody, Tooltip, Grid,
} from '@mui/material';
import TrashIcon from '@mui/icons-material/Delete';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import * as R from 'ramda';
import {
  tzParseFormat,
} from '../../../services/joda.js';
import { popupWithCancel } from '../../../services/Popup.js';
import Loading from '../../../components/Loading/Loading.component';
import { MoveToFolder, RemoveFromFolder } from '../../../components/Folders/folders.component.tsx';
import { TableContainer, TableRow, HeaderCell, BodyCell } from '../../../components/CustomTable';

export const searchClientOptions = [
  { value: 'name', label: 'Name' },
  { value: 'phone', label: 'Phone' },
];

const countSelecteds = (messages = []) => R.pipe(
  R.filter((message) => message.selected),
  R.length,
)(messages);

const fixLength = (str) => {
  if (str && str.length > 50) {
    return str.slice(0, 51) + '...';
  }
  return str;
};

const MessageTableItem = ({
  message,
  url,
  tz,
  select,
  copyMessage,
  deleteMessage,
  pageName,
}) => {
  const msgName = message.name && fixLength(message.name);
  return (
    <TableRow
      hasSelect={pageName !== 'sked-templates'}
      checked={R.propOr(false, 'selected')(message)}
      setChecked={(value) => select({ value })}
    >
      <BodyCell fixed>
        {url ?
          <Link to={url + message.id}>{msgName}</Link>
          :
          <a onClick={() => copyMessage({ message, tz })}>
            {msgName}
          </a>}
      </BodyCell>
      <BodyCell>
        {R.cond([
          [R.prop('email'), () => fixLength(message.email.subject)],
          [R.prop('push'), () => fixLength(message.push.subject)],
          [R.prop('sms'), () => ''],
          [R.T, () => message.subject && fixLength(message.subject)],
        ])(message)}
      </BodyCell>
      <BodyCell>
        <span style={{ display: 'flex', width: '100%', height: '100%' }}>
          {message.sms &&
            <span style={{ marginRight: '5px', height: '100%', display: 'flex', alignItems: 'center' }}>
              <ChatBubbleIcon style={{ fontSize: '20px' }} />
            </span>}
          {message.email &&
            <span style={{ marginRight: '5px', display: 'flex', alignItems: 'center' }}>
              <EmailIcon style={{ fontSize: '20px' }} />
            </span>}
          {message.push &&
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <SmartphoneIcon style={{ fontSize: '20px' }} />
            </span>}
        </span>
      </BodyCell>
      {pageName !== 'sked-templates' &&
        <BodyCell minWidth={140}>
          {tzParseFormat(pageName === 'pending' ? message.scheduledFor : message.createdAt, tz, 'MM/dd/yyyy h:mm a')}
        </BodyCell>}
      {pageName !== 'sked-templates' &&
        <BodyCell minWidth={64}>
          <IconButton onClick={() => copyMessage({ message, tz })}>
            <FileCopyIcon />
          </IconButton>
          <IconButton onClick={() => {
            popupWithCancel(
              'Are you sure you want to delete this message?',
              'This will permanently delete it.',
              () => deleteMessage({ id: message.id }),
              'danger'
            );
          }}>
            <TrashIcon />
          </IconButton>
        </BodyCell>}
    </TableRow>
  );
};

export const MessagesTable = ({
  busy,
  messages,
  url,
  tz,
  selectAll,
  selectMessage,
  copyMessage,
  deleteMessage,
  deleteSelected,
  query,
  filter,
  search,
  pageName,
  page,
  totalPages,
  totalCount,
  path,
  maxHeight,
}) => {
  const selecteds = React.useMemo(() => countSelecteds(messages), [messages]);
  return (
    <div>
      <div>
        <TableContainer
          fullPage
          style={{ marginLeft: -20, marginRight: -20 }}
          maxHeight={maxHeight}
          checkHasScroll={!!messages?.length}
          pagination
          paginationData={{
            addPage: (v) => search({ type: pageName, query: query.trim(), filter, page: page + v }),
            currentLength: messages.length,
            page,
            perPage: 50,
            totalCount,
            totalPages,
          }}
        >
          <TableHead>
            <TableRow
              hasSelect={pageName !== 'sked-templates'}
              checked={messages.length && selecteds === messages.length}
              setChecked={(value) => selectAll({ value })}
              noSelectHover
              someChecked={selecteds > 0 && selecteds < messages.length}
            >
              <HeaderCell fixed>
                {!!selecteds &&
                  <Grid position="absolute" display="flex" alignItems="center" top={0} left={8} style={{ background: '#F5F5F5' }}>
                    <MoveToFolder
                      open={selecteds > 0}
                      path={path}
                      messages={messages}
                      onMove={(path) => {
                        search({
                          type: pageName,
                          query: query.trim(),
                          filter,
                          page,
                          path,
                        });
                      }}
                    />
                    <RemoveFromFolder
                      open={selecteds > 0}
                      path={path}
                      messages={messages}
                      onMove={(path) => {
                        search({ type: pageName, query: query.trim(), filter, page, path });
                      }}
                    />
                    <Tooltip title='Delete selected'>
                      <IconButton
                        onClick={() => {
                          popupWithCancel(
                            'Are you sure you want to delete these?',
                            'They will be permanently deleted.',
                            () => deleteSelected({ messages }),
                            'danger'
                          );
                        }}>
                        <TrashIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                }
                Title
              </HeaderCell>
              <HeaderCell>
                Subject
              </HeaderCell>
              <HeaderCell>
                Sent Via
              </HeaderCell>
              {pageName !== 'sked-templates' &&
                <HeaderCell>
                  {pageName === 'pending' ? 'Scheduled for' : 'Created'}
                </HeaderCell>}
              {pageName !== 'sked-templates' &&
                <HeaderCell>
                  Actions
                </HeaderCell>}
            </TableRow>
          </TableHead>
          {busy ?
            <Loading loading vh={77} /> :
            <TableBody>
              {messages && messages.length > 0 && messages.map((message, index) =>
                <MessageTableItem
                  key={message.id}
                  message={message}
                  url={url}
                  tz={tz}
                  pageName={pageName}
                  select={R.pipe(R.assoc('index', index), selectMessage)}
                  copyMessage={copyMessage}
                  deleteMessage={deleteMessage}
                  pageName={pageName}
                />)}
            </TableBody>}
        </TableContainer>
      </div>
    </div>
  );
};

export function TemplatesTable({
  SkedTemplatesTable,
  OfficeTemplateTable,
  tab,
}) {
  return (
    <div style={{ margin: '0' }}>
      <div hidden={tab !== 'your'}>
        {OfficeTemplateTable()}
      </div>
      <div hidden={tab !== 'sked'}>
        {SkedTemplatesTable()}
      </div>
    </div>
  );
}
