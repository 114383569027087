import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Snackbar } from '@mui/material';
import { showHours, editHours } from '../../../components/hours';
import { PopupTemplate } from '../../../services/Popup.js';
import * as action from './officeHours.actions.js';
import * as locationAction from '../../Settings/routes/Business/business.actions';
import Instructions from '../../../components/Instructions/instructions.component';
import Loading from '../../../components/Loading/Loading.component';
import { HelpDialog, useHelpDialog, HelpButton } from '../../../components/Help/Help.component';

import './officeHours.css';


const instructions = 'The hours set on this page do NOT reflect when patients can schedule appointments. These times will be displayed in the Office tab in the SKED app. \n \n Appointment availability in the app will need to be updated on the Schedule tab. \n \n To update office hours in SKED: \n 1. Office Hours tab > click "Edit" > edit days/times > click "Save" \n 2. Schedule tab > click "Set Hours" > click on day > edit times > click "Start Now" > click "Save" > repeat for all necessary schedules ';

const OfficeHours = ({
  data,
  editHoursPushed,
  editing,
  cancelHoursPushed,
  saveHoursPushed,
  closedPushed,
  timeChanged,
  removeOpenPushed,
  addHoursPushed,
  addOpeningPushed,
  dateChanged,
  removePushed,
  loading,
  loadError,
  history,
  get,
  locations,
  hasLocations,
  copiedHours,
  copiedHoursPatch,
  pasteEntireHours,
  getLocations,
  locationId,
}) => {

  useEffect(() => {
    get(history);
  }, []);

  useEffect(() => {
    if (hasLocations) {
      getLocations();
    }
  }, [hasLocations]);

  const [info, setInfo] = useState(false);

  const helpState = useHelpDialog();

  if (loading) {
    return (
      <Loading loading />
    );
  } else if (loadError) {
    if (!loadError.response)
      history.push('/offline');
    return (
      <div style={{
        margin: 20,
      }}>
        <HelpDialog
          pageId="office-hours"
          handleClose={helpState.handleClose}
          open={helpState.isOpen} />
        <div>Error: Failed to get office hours</div>
        <br />
        <Button
          className={'sked-test-office-hours-add-new-hours'}
          variant='contained'
          onClick={() => addHoursPushed()}>
          Add New Hours
        </Button>
        <HelpButton handleOpen={helpState.handleOpen} />
      </div>
    );
  } else if (editing === undefined) {
    return (
      <div className="office-hours">
        <PopupTemplate />
        <HelpDialog
          pageId="office-hours"
          handleClose={helpState.handleClose}
          open={helpState.isOpen} />
        <Snackbar
          open={copiedHours}
          message='Hours Copied!'
          autoHideDuration={4000}
          onClose={() => copiedHoursPatch(false)} />
        <div style={{ marginBottom: 10 }}>
          <div>
            <b style={{ color: '#ff4141' }}> Changing your office hours? </b>
            <a style={{ cursor: 'pointer', color: '#008BCF' }} onClick={() => setInfo(true)}>Click Here!</a>
          </div>
          <br />
        </div>
        <Instructions
          open={info}
          message={instructions}
          onClose={() => setInfo(false)}
        />
        {
          R.pipe(
            R.filter((h) => locationId === 0 || h.locationId === locationId || (locationId === 'main' && R.isNil(h.locationId))),
            R.sortBy(R.prop('start')),
            R.map(showHours({
              edit: editHoursPushed,
              remove: removePushed,
              locations,
              copy: copiedHoursPatch
            }))
          )(data)
        }
      </div>
    );
  } else {
    return (
      <div className="office-hours">
        <PopupTemplate />
        <HelpButton handleOpen={helpState.handleOpen} />
        <Snackbar
          open={copiedHours}
          message='Hours Copied!'
          autoHideDuration={4000}
          onClose={() => copiedHoursPatch(false)} />
        <HelpDialog
          pageId="office-hours"
          handleClose={helpState.handleClose}
          open={helpState.isOpen} />
        {editHours({
          hours: editing,
          save: saveHoursPushed,
          cancel: cancelHoursPushed,
          closed: closedPushed,
          timeChanged,
          removeOpenPushed,
          add: addOpeningPushed,
          dateChanged,
          locations,
          hasLocations,
          copy: copiedHoursPatch,
          addNewHours: pasteEntireHours,
        })}
      </div>
    );
  }
};

const mapStateToProps = state => {
  const locations = R.pathOr([], ['business', 'locations'], state);
  const hasLocations = R.pipe(
    R.pathOr([], ['login', 'features']),
    R.includes('Locations')
  )(state);
  return R.merge(
    R.pathOr({}, ['officeHours'])(state),
    {
      office: R.pathOr(null, ['login', 'office'])(state),
      locations: hasLocations && !R.isEmpty(locations) ?
        R.prepend(
          { id: 0, name: 'All Locations' },
          locations,
        ) : [],
      hasLocations,
    });
};

const mapDispatchToProps = dispatch => ({
  get: (his) => dispatch(action.get(his)),
  editHoursPushed: (e) => dispatch(action.editHoursPushed(e)),
  removePushed: (e) => dispatch(action.removePushed(e)),
  cancelHoursPushed: (e) => dispatch(action.cancelHoursPushed(e)),
  removeOpenPushed: (e) => dispatch(action.removeOpenPushed(e)),
  timeChanged: (e) => dispatch(action.timeChanged(e)),
  saveHoursPushed: (e) => dispatch(action.saveHoursPushed(e)),
  closedPushed: (e) => dispatch(action.closedPushed(e)),
  addHoursPushed: (e) => dispatch(action.addHoursPushed(e)),
  addOpeningPushed: (e) => dispatch(action.addOpeningPushed(e)),
  dateChanged: (e) => dispatch(action.dateChanged(e)),
  copiedHoursPatch: (e) => dispatch(action.copiedHoursPatch(e)),
  pasteEntireHours: (e) => dispatch(action.pasteEntireHours(e)),
  getLocations: () => dispatch(locationAction.getLocations()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfficeHours);
