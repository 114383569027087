import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  mobileModal: {
    padding: 16,
    marginBottom: 0,
    overflowX: 'hidden',
  },
  mobileModalWithRadius: {
    padding: 16,
    marginBottom: 0,
    overflowX: 'hidden',
    borderRadius: 12,
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
  continue: {
    display: 'unset',
  },
  paper: {
    borderRadius: 12,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  inputLabel: {
    fontSize: 14,
    marginBottom: 5,
    backgroundColor: 'white',
    cursor: 'pointer',
    padding: 5,
    paddingLeft: 7,
    borderRadius: 7,
    '&:hover': {
      backgroundColor: '#f3f3f4',
    },
  },
  help: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 0,
    fontSize: 13,
    color: '#949494',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  helpIcon: {
    color: '#949494',
    fontSize: 14,
    marginRight: 7,
    scale: 1.3,
    transform: 'translateY(-1px)',
  },
  selected: {
    borderRadius: 10,
    width: 180,
  },
  headers: {
    fontSize: 17,
    marginBottom: '20px',
  },
  selectable: {
    cursor: 'pointer',
    borderRadius: 12,
    border: 'solid 1px white',
    padding: 16,
    '&:hover': {
      backgroundColor: '#f3f3f4',
    },
  },
  notselectable: {
    borderRadius: 12,
    border: 'solid 1px white',
  },
  chosen: {
    backgroundColor: '#f4f9fd',
    border: 'solid 1px #5ab1e8',
    borderRadius: 12,
    padding: 16,
  },
  macroGrid: {
    display: 'grid',
    gridTemplateColumns: 'calc(50% - 5px) calc(50% - 5px)',
    gridColumnGap: '10px',
    gridTemplateRows: 'auto auto',
    gridRowGap: 'unset',
  },
  proCons: {
    display: 'flex',
    gridColumnGap: '3px',
    marginTop: '5px',
  },
  icons: {
    fontSize: '14px',
    marginTop: '4px',
  },
  mainDesc: {
    marginBottom: '15px',
  },
  graphicGrid: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    gridGap: '10px',
    marginBottom: '15px',
    fontSize: 'unset',
  },
  graphicHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  arrow: {
    marginLeft: 'unset',
  },
  mobileButton: {
    display: 'none',
  },
  graphicPaper: {
    padding: 10,
    width: 'calc(100% - 30px)',
    borderRadius: 15,
  },
  '@media screen and (max-width: 960px)': {
    headers: {
      fontSize: 22,
      marginBottom: 7,
    },
    graphicPaper: {
      display: 'none',
    },
    continue: {
      display: 'none',
    },
    macroGrid: {
      display: 'grid',
      gridTemplateRows: 'calc(50% - 5px) calc(50% - 5px)',
      gridTemplateColumns: 'unset',
      gridColumnGap: 'unset',
    },
    graphicGrid: {
      display: 'flex',
      fontSize: 15,
      marginBottom: 7,
    },
    arrow: {
      marginLeft: 10,
      height: 21,
    },
    mobileButton: {
      display: 'block',
      marginTop: 17,
    },
    mobileModal: {
      padding: 10,
    },
    selectable: {
      pointer: 'default',
      height: 'unset',
      '&:hover': {
        backgroundColor: 'white',
      },
    },
    chosen: {
      backgroundColor: 'unset',
      border: 'solid 1px white',
      height: 'unset',
    },
  },
  image: {
    width: '100%',
  },
  warningMargin: {
    marginTop: 10,
    width: 'calc(100% - 3px)',
    marginLeft: -19,
    marginBottom: -17,
  },
}));
