import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  saveButton: {
    padding: '2px 8px',
    fontSize: 12,
    textTransform: 'none',
    borderRadius: 3,
    margin: 0,
    minWidth: 50,

    '&:disabled': {
      opacity: 0.5,
      color: '#FFF',
      background: theme.palette.primary.main
    },
  },
  saveAsButton: {
    padding: '2px 10px',
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'none',
    borderRadius: 4,
    margin: 0,
    position: 'relative',
    minWidth: 18,
    whiteSpace: 'nowrap',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: 28,

    '&:disabled': {
      opacity: 0.5,
      color: '#FFF',
      background: theme.palette.primary.main
    },
  },
  paper: {
    borderRadius: 12,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  arrowButton: {
    minWidth: 27,
    padding: 2,
    borderRadius: 4,
    height: 28,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: '0.5px solid rgb(255 255 255 / 50%)',

    '& svg': {
      width: 20,
      height: 20,
    }
  },
  selectItem: {
    fontSize: 14,
  },
}));
