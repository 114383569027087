import React from 'react';
import { Card, Grid } from '@mui/material';

const Announcements = () => {
  return (
    <Grid display="flex" alignItems="center" padding={2}>
      <Card style={{ width: '100%', padding: 10 }}>
        <h1>Announcements</h1>
      </Card>
    </Grid>
  );
};

export default Announcements;
