import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const CreatedDateIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} id="created-date-icon" xmlns="http://www.w3.org/2000/svg" width="13.551" height="12.625" viewBox="0 0 13.551 12.625">
      <path id="Path_8875" data-name="Path 8875" d="M88.417,2.853A2.562,2.562,0,0,0,85.328.035V.224c0,.419.006.839,0,1.258a.575.575,0,1,1-1.146-.005c0-.437,0-.874,0-1.311A.743.743,0,0,1,84.2.031c-.075-.007-.151-.019-.226-.019H79.316c-.063,0-.127.006-.19.008,0,.488,0,.975,0,1.463a.575.575,0,1,1-1.148,0c-.006-.488-.006-.976-.008-1.463A3.705,3.705,0,0,0,76.5.161a2.5,2.5,0,0,0-1.6,2.692Z" transform="translate(-74.88 -0.001)" fill={color || '#4d4d4d'} />
      <path id="Path_8876" data-name="Path 8876" d="M88.422,17.592H74.892v1.046h0q0,2.57,0,5.14a2.234,2.234,0,0,0,.089.582,2.485,2.485,0,0,0,2.463,1.822c1.4,0,2.809,0,4.213,0s2.809,0,4.213,0a2.485,2.485,0,0,0,2.463-1.822,2.225,2.225,0,0,0,.089-.582q.008-2.57,0-5.14h0V17.592ZM83.89,22.364H82.137v1.753a.475.475,0,0,1-.95,0V22.364H79.434a.475.475,0,1,1,0-.95h1.753V19.661a.475.475,0,0,1,.95,0v1.753H83.89a.475.475,0,1,1,0,.95" transform="translate(-74.889 -13.56)" fill={color || '#4d4d4d'} />
    </svg>
  );
};

export default CreatedDateIcon;
