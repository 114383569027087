import * as at from '../../actionTypes';
import * as R from 'ramda';
import {
  now,
} from '../../services/joda.js';

const init = {
  busy: false,
  reviews: [],
  totalCount: 0,
  totalPages: 0,
  page: 1,
  perPage: 25,
  newReviews: 0,
  requests: [],
  sentTotalCount: 0,
  sentTotalPages: 0,
  sentPage: 1,
  sentPerPage: 25,
  lastRead: now('datetime'),
};

const sortReviews = R.pipe(R.sortBy(R.prop('created')), R.reverse);

export default (reviews = init, action) => {
  switch (action.type) {
    case at.REVIEWS_PATCH:
      return R.merge(reviews, action.data);
    case at.REVIEWS_REMOTE_GET:
      if (action.state === 'REQUEST') {
        return R.merge(reviews, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(reviews, {
          reviews: sortReviews(action.data.data),
          totalCount: action.data.totalCount,
          totalPages: action.data.totalPages,
          page: action.data.page,
          busy: false,
          perPage: action.data.perPage,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(reviews, {
          busy: false,
        });
      }
      return reviews;
    case at.REVIEWS_REMOTE_GET_SENT:
      if (action.state === 'REQUEST') {
        return R.merge(reviews, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(reviews, {
          requests: R.pipe(
            R.sortBy(R.prop('created')),
            R.reverse
          )(action.data.data),
          sentTotalCount: action.data.totalCount,
          sentTotalPages: action.data.totalPages,
          sentPage: action.data.page,
          busy: false,
          sentPerPage: action.data.perPage,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(reviews, {
          busy: false,
        });
      }
      return reviews;
    case at.NEW_REVIEWS_GET:
      return R.merge(reviews, action.data);
    case at.REVIEWS_LAST_READ_UPDATE:
      return R.merge(reviews, action.data);
    default:
      return reviews;
  }
};
