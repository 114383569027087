import * as React from 'react';
import {
  Radio, TableContainer, Table, TableRow, TableHead, TableBody,
  TableCell, Typography, TextField, Button, IconButton,
  FormControlLabel, Checkbox,
} from '@mui/material';
import { Spacer } from '../../../components/PageHeader';
import {
  ComplexWidgetRadioGrid, SectionChild, IndexAndConds
} from '../intake-types';
import {
  updateSimpleWidget, deleteSectionChild
} from '../intake.context';
import TrashIcon from '@mui/icons-material/Delete';
import { OrderButtons } from './SectionChildOrderButtons.component';
import { useDispatch } from 'react-redux';
import QuestionLabel from './QuestionLabel.component';

interface RadioGridProps {
  id: number;
  data: ComplexWidgetRadioGrid,
  required: boolean
  index: number;
  maxIndex: number;
  forOffice: boolean;
  questionCond: IndexAndConds;
}

interface RadioGridEditProps {
  id: number;
  data: ComplexWidgetRadioGrid;
  required: boolean;
  forOffice: boolean;
  onSave: (
    scales: string[], choices: string[], required: boolean, forOffice: boolean
  ) => void;
  onCancel: () => void;
}

const RadioGridEditComponent = ({
  data, required, onSave, onCancel, forOffice,
}: RadioGridEditProps) => {
  const [choices, setChoices] = React.useState<string>(data.rowLabels.join('\n'));
  const [scales, setScales] = React.useState<string>(data.columnLabels.join('\n'));
  const [localRequired, setLocalRequired] = React.useState<boolean>(required);
  const [localForOffice, setLocalForOffice] = React.useState<boolean>(forOffice);
  const handleSave = () => {
    const scalesArray = scales.split('\n');
    const choicesArray = choices.split('\n');
    onSave(scalesArray, choicesArray, localRequired, localForOffice);
  };

  const handleCheck = (t: 'private' | 'required') => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (t === 'required') {
      setLocalRequired(e.target.checked);
    } else {
      setLocalForOffice(e.target.checked);
    }
  };

  return (
    <div>
      <Typography variant="body1" component="p" gutterBottom>
        Create a table where patients can give a rating for different items using radio buttons. This will allow patients to select one option per line.
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={localRequired || false}
            onChange={handleCheck('required')}
            color="primary"
          />}
        label="Required" />
      <FormControlLabel
        control={
          <Checkbox
            checked={localForOffice || false}
            onChange={handleCheck('private')}
            color="primary"
          /> }
        label="Private" />
      <TextField
        style={{ width: '100%' }}
        InputLabelProps={{ shrink: true }}
        multiline
        minRows={4}
        variant="outlined"
        value={choices}
        onChange={e => setChoices(e.target.value)}
        helperText="One choice per line"
        type="text"
        label="Choices" />
      <br />
      <br />
      <TextField
        style={{ width: '100%' }}
        InputLabelProps={{ shrink: true }}
        multiline
        minRows={4}
        value={scales}
        onChange={e => setScales(e.target.value)}
        helperText="One scale item per line"
        variant="outlined"
        type="text"
        label="Scale" />
      <Button color="primary" variant="outlined" onClick={handleSave}>Save</Button>
      &nbsp;&nbsp;
      <Button color="primary" variant="outlined" onClick={onCancel}>Cancel</Button>
    </div>
  );
};

export const RadioGridComponent = ({
  id, data, required, index, maxIndex, forOffice, questionCond,
}: RadioGridProps) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = React.useState(false);
  const handleCancel = () => {
    setEdit(false);
  };

  const handleSave = (
    scales: string[], choices: string[], isRequired: boolean, forOffice: boolean,
  ) => {
    setEdit(false);
    const updatedRadioGrid: SectionChild = {
      id,
      width: 12,
      type: 'ComplexWidget',
      required: isRequired,
      forOffice,
      typeData: {
        type: 'RadioGrid',
        rowLabels: choices,
        columnLabels: scales
      }
    };
    dispatch(updateSimpleWidget(updatedRadioGrid));
  };

  if (edit) {
    return (
      <RadioGridEditComponent
        data={data}
        id={id}
        onSave={handleSave}
        onCancel={handleCancel}
        required={required}
        forOffice={forOffice}
      />
    );
  }

  return (
    <div>
      {!forOffice &&
          <QuestionLabel cond={questionCond} complex />}
      <div style={{ display: 'flex' }}>
        <div>Radio Grid</div>
        <Spacer />
        <IconButton
          aria-label="delete question"
          onClick={() => dispatch(deleteSectionChild(id, questionCond.index))}
        >
          <TrashIcon fontSize="inherit" />
        </IconButton>
        <OrderButtons
          index={index}
          maxIndex={maxIndex}
          id={id}
          questionIndex={questionCond.index}
        />
      </div>
      <div>Required: {required ? 'yes' : 'no'}</div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              {data.columnLabels.map(c => <TableCell key={c} align="center">{c}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.rowLabels.map(r =>
              <TableRow key={r}>
                <TableCell>{r}</TableCell>
                {data.columnLabels.map(c => <TableCell key={c} align="center">
                  <Radio disabled={true} />
                </TableCell>)}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        style={{ marginTop: '5px' }}
        color="primary"
        onClick={() => setEdit(true)}
        variant="outlined">
        Edit
      </Button>
    </div>
  );
};
