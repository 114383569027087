import React from 'react';

import { CircularProgress, Grid } from '@mui/material';


const Loading = ({ loading, vh = 84 }: { loading: boolean, vh?: number }) => {
  if (!loading) {
    return null;
  }
  return (
    <Grid position="absolute" width="95%" height={`${vh}vh`} display="flex" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Grid>
  );
};

export default Loading;
