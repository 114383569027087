import React, { ComponentProps } from 'react';

const HashtagIcon = ({ color, className, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" {...props} width="25.374" height="26.701" viewBox="0 0 25.374 26.701">
      <path id="Path_9120" data-name="Path 9120" d="M35.368,14.836H31.1l1.1-4.108h3.648a2.237,2.237,0,0,0,0-4.473H33.4l.615-2.293A2.237,2.237,0,1,0,29.7,2.8l-.925,3.451h-3.86l.923-3.445a2.237,2.237,0,0,0-4.321-1.158l-1.233,4.6H15.52a2.237,2.237,0,1,0,0,4.473h3.563l-1.1,4.108h-3.03a2.237,2.237,0,1,0,0,4.473h1.832l-.916,3.417a2.237,2.237,0,1,0,4.321,1.158l1.226-4.575h3.86L24.05,23.878a2.237,2.237,0,0,0,4.321,1.158l1.534-5.726h5.463a2.237,2.237,0,1,0,0-4.473Zm-8.895,0h-3.86l1.1-4.108h3.86Z" transform="translate(-12.715 0.007)" fill={color || '#4d4d4d'} fill-rule="evenodd"/>
    </svg>
  );
};

export default HashtagIcon;
