import React from 'react';
import { DragSource } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { ItemTypes } from './constants.jsx';
import { pickColor } from '../../../services/utilities.js';

import { useStyles } from './type-drag.styles';

export function TypeDrag({
  type, connectDragSource,
  index = null,
  selected,
  handleSeletion,
  activeDrag = false,
  connectDragPreview,
}) {

  const classes = useStyles();

  React.useEffect(() => {
    connectDragPreview(getEmptyImage(), {
      captureDraggingState: true,
    });
  }, []);

  return (
    connectDragSource(
      <div
        onClick={() => handleSeletion(type, index)}
        className={classes.root}
        style={{
          opacity: (activeDrag && selected) ? 0.5 : 1,
          backgroundColor: selected ? 'rgb(0, 139, 207)' : '#CECECE', // pickColor(type.color),
          borderWidth: selected ? 1 : 1,
          borderColor: selected ? 'rgb(0, 139, 207)' : 'rgb(191, 191, 191)',
          cursor: selected ? 'pointer' : 'default',
        }}>
        <div
          className={classes.circle}
          style={{
            backgroundColor: pickColor(type.color),
          }}>
        </div>
        <div
          key={type.id}
          className={classes.text}
        >
          {type.internalName + ' (' + type.name + ')'}
        </div>
      </div>)
  );
}

const typeSource = {
  beginDrag(props) {
    const selecteds = props.selectedTypes;
    props.setActiveDrag(true);
    const exists = selecteds.find(item => item.id === props.type.id);
    if (!exists) {
      props.handleSeletion(props.type, props.index);
      if (props.index !== props.activeIndex) {
        return { selecteds: [props.type], from: props.index };
      }
      selecteds.push(props.type);
    }
    return { selecteds, from: props.index };
  },
  endDrag(props, monitor) {
    const dropResult = monitor.getDropResult();
    props.setActiveDrag(false);
    if (dropResult) {
      props.clearSelection();
    }
  },
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

export default DragSource(ItemTypes.TYPE, typeSource, collect)(TypeDrag);
