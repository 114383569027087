import React from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import {
  Paper,
  MenuItem,
  TextField,
  Snackbar,
  Divider,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Switch,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { PopupTemplate } from '../../../../services/Popup.js';
import * as rawActions from './webmodule.actions.jsx';
import TypeSelector from '../../../../components/TypeSelector/type-selector.component';
import { PageHeader } from '../../../../components/PageHeader';
import Header from '../../../../components/PageHeader/PageHeader.component';
import Loading from '../../../../components/Loading/Loading.component';

const WiderTextField = withStyles({
  root: {
    width: '500px',
  },
})(TextField);

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px',
    paddingBottom: 30,
    overflowY: 'auto',
  },
  paper: {
    padding: '20px',
    marginBottom: '20px'
  }
}));

const genUrl = (key, includeExistingClient, includeNewClient, scheduleNewPatient) => {
  const scheduleUrl = `&scheduleNewPatient=${scheduleNewPatient}`;
  if (includeExistingClient) {
    return includeNewClient ?
      process.env.APP_URL + '/webmodule/?key=' + key + scheduleUrl
      :
      process.env.APP_URL + '/webmodule/?key=' + key + '&newClient=false' + scheduleUrl;
  } else {
    return includeNewClient ?
      process.env.APP_URL + '/webmodule/?key=' + key + '&existingClient=false' + scheduleUrl
      :
      process.env.APP_URL + '/webmodule/?key=' + key + '&existingClient=false&newClient=false' + scheduleUrl;
  }
};

const genIframe = (key, existing, newClient, scheduleNewPatient) =>
  `<iframe sandbox="allow-top-navigation allow-scripts" style="border: none;" src="${genUrl(key, existing, newClient, scheduleNewPatient)}" width="500px" height="510px"></iframe>`;


const TypesSelection = ({
  types, professionals, selectedTypes, selectedNewTypes,
  includeNewClient, scheduleNewPatient, patch, ehr,
  sameDayExistingPatientScheduling, sameDayNewPatientScheduling,
}) => {
  const onChangeNewPro = (type, pro) => e => {
    if (e.target.checked) {
      const defaultType = types.find(R.propEq('professionalId', pro.id));
      if (defaultType) {
        patch('selectedNewTypes', R.append(defaultType.id, selectedNewTypes));
      }
    } else {
      patch('selectedNewTypes', R.without([type], selectedNewTypes));
    }
  };
  const findSelectedProType = pro => {
    return selectedNewTypes.find(typeId => {
      const appointmentType = types.find(t => t.id === typeId);
      return appointmentType && appointmentType.professionalId === pro.id;
    });
  };
  const filterProTypes = pro => {
    return types.filter(t => pro.id === t.professionalId);
  };

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      paddingBottom: '20px',
    }}>
      <p style={{
        width: '500px',
        fontSize: '15px',
        color: 'gray'
      }}>
        Which appointment types can <i>existing patients</i> <b>schedule</b> from your website?
      </p>
      <TypeSelector
        types={types}
        professionals={professionals}
        selected={R.isNil(selectedTypes) ? null : selectedTypes}
        onChange={(ts) => {
          console.log(ts);
          patch('selectedTypes', ts);
        }}
      />
      <label style={{
        display: 'flex',
        alignItems: 'center',
      }}>
        <Checkbox
          name="sameDayExistingPatientScheduling"
          checked={sameDayExistingPatientScheduling}
          onChange={() => patch('sameDayExistingPatientScheduling', !sameDayExistingPatientScheduling)} />
        <p style={{
          width: '50%',
          fontSize: '15px',
          margin: 'unset',
        }}>
          Allow <i>existing patients</i> to schedule an appointment on the day of?
        </p>
      </label>
      <Divider />
      <div style={{
        width: '100%',
        marginTop: '20px',
      }}>
        <PageHeader variant="h6">
          New Patient Settings
          <Switch
            checked={includeNewClient}
            onChange={() => patch('includeNewClient', !includeNewClient)}
            name="new patient settings"
            color="primary"
          />
        </PageHeader>
        <div>
          <div>
            <p style={{ fontSize: '15px', color: 'gray' }}>
              Choose how your new patients book an appointment and which appointment type they can select from your website.
            </p>
          </div>
          <div style={{ padding: '10px' }}>
            <FormControlLabel
              control={<Checkbox disabled={ehr === 'Platinum' || !includeNewClient} checked={scheduleNewPatient} onChange={() => patch('scheduleNewPatient', !scheduleNewPatient)} name="scheduleNewPatient" />}
              label="Schedule appointment directly into schedule"
            />
            <FormHelperText>
              Check this if you want the appointments to save into your calendar and sync directly into your EHR system.<br />
              If it's unchecked, new patients will <em>request</em> an appointment. You'll receive an email notification for the people who want to schedule an appointment.
              {ehr === 'Platinum' && <><br /><br />The old platinum syncer does not support this feature.</>}
            </FormHelperText>
          </div>
          <div style={{ padding: '10px' }}>
            <FormControlLabel
              control={<Checkbox disabled={!includeNewClient} checked={sameDayNewPatientScheduling} onChange={() => patch('sameDayNewPatientScheduling', !sameDayNewPatientScheduling)} name="sameDayScheduling" />}
              label="Allow same day scheduling?"
            />
          </div>
          <br />
          {includeNewClient &&
            <p style={{
              width: '500px',
              fontSize: '15px',
              color: 'gray'
            }}>
              Which appointment types can new patients schedule from your website?
            </p>}
          {includeNewClient &&
            <div style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
            }}>
              {professionals.map((pro) => {
                const selectedTypeId = findSelectedProType(pro);
                return (<div
                  key={pro.id}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: '10px'
                  }}>
                  <label>
                    <Checkbox
                      name="scheduleNewPatient"
                      checked={Boolean(selectedTypeId)}
                      onChange={onChangeNewPro(selectedTypeId, pro)} />
                    {pro.displayFirstName + ' ' + pro.displayLastName}
                  </label>
                  {selectedTypeId &&
                    <TextField
                      select
                      value={selectedTypeId}
                      label="Appointment Type">
                      {filterProTypes(pro).map((apptType) => (
                        <MenuItem
                          key={apptType.id}
                          value={apptType.id}
                          onClick={() => patch(
                            'selectedNewTypes',
                            R.append(apptType.id, R.without([selectedTypeId], selectedNewTypes))
                          )}>
                          {apptType.internalName + ' (' + apptType.name + ')'}
                        </MenuItem>
                      ))}
                    </TextField>}
                </div>
                );
              })}
            </div>}
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

const WebmoduleTemplate = ({
  history,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const actions = bindActionCreators(rawActions, dispatch);

  const {
    busy, types, professionals, selectedTypes, selectedPro, addType,
    addAllTypes, removeType, selectPro, clearSelected, patch, save,
    snackbar, selectedNewTypes, selectType, selectedNewAptPro,
    selectNewAptPro, includeNewClient, includeExistingClient,
    includeNewPatient, officeKey, ehr, scheduleNewPatient,
    bookingRedirectUrl, office, getTypes, sameDayExistingPatientScheduling,
    sameDayNewPatientScheduling,
  } = useSelector((state) => ({
    ...R.propOr({}, 'webmodule')(state),
    ehr: R.pathOr(null, ['login', 'office', 'ehrSystem'])(state),
    office: R.pathOr(null, ['login', 'office'])(state),
    ...actions,
  }));

  const isStandard = R.equals(office.plan, 'SkedStandard');

  React.useEffect(() => {
    getTypes(history);
  }, [office]);

  const iframe = genIframe(officeKey, includeExistingClient, includeNewPatient && includeNewClient, scheduleNewPatient);
  const url = genUrl(officeKey, includeExistingClient, includeNewPatient && includeNewClient, scheduleNewPatient);

  return (
    <>
      <Header title={isStandard ? 'Patient Portal' : 'Existing Patients'} pageId="webmodule" />
      <div className={classes.root} >
        <PopupTemplate />
        <Snackbar
          open={snackbar}
          message="Information saved!"
          autoHideDuration={4000}
          onClose={() => patch('snackbar', false)} />
        {busy && <Loading loading />}
        {!busy &&
          <div>
            <Paper className={classes.paper}>
              <PageHeader variant="h6">
                Existing Patient Settings
              </PageHeader>
              <TypesSelection
                types={types}
                professionals={professionals}
                selectedTypes={selectedTypes}
                selectedPro={selectedPro}
                addType={addType}
                addAllTypes={addAllTypes}
                removeType={removeType}
                selectPro={selectPro}
                clearSelected={clearSelected}
                selectedNewTypes={selectedNewTypes}
                selectType={selectType}
                selectNewAptPro={selectNewAptPro}
                selectedNewAptPro={selectedNewAptPro}
                includeNewClient={includeNewClient}
                patch={patch}
                ehr={ehr}
                scheduleNewPatient={scheduleNewPatient}
                sameDayExistingPatientScheduling={sameDayExistingPatientScheduling}
                sameDayNewPatientScheduling={sameDayNewPatientScheduling}
              />
              <Divider />
              <div style={{
                marginTop: '20px',
                marginBottom: '20px',
                width: '100%'
              }}>
                <PageHeader variant="h6">
                  Other Settings
                </PageHeader>
                <WiderTextField
                  label='Redirection URL'
                  value={bookingRedirectUrl}
                  onChange={(e) => patch('bookingRedirectUrl', e.target.value)}
                />
                <p style={{
                  textAlign: 'left',
                  width: '500px',
                  fontSize: '15px',
                  marginTop: '1em'
                }}>The url your new patients will be sent to after they book their appointment. <br />  In order for redirection to work you must update the iframe code <b>as shown below.</b> </p>
              </div>
              <Button
                variant='contained'
                onClick={() => save({
                  selectedTypes,
                  newClient: selectedNewTypes,
                  include: includeNewClient,
                  scheduleNewPatient,
                  bookingRedirectUrl,
                  sameDayExistingPatientScheduling,
                  sameDayNewPatientScheduling,
                })}>
                Save
              </Button>
            </Paper>
            <Paper className={classes.paper}>
              <PageHeader variant="h5">
                Legacy Webmodule Example
              </PageHeader>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
              }}>
                <p><i>Toggling the following settings will <b>change the iframe link</b>.</i></p>
                {!includeExistingClient && !includeNewPatient &&
                  <div style={{ color: 'red' }}>
                    You have disabled both existing and new patients! This means you'll have a blank NP Portal!
                  </div>
                }
                <label>
                  <Checkbox
                    name="existing"
                    checked={includeExistingClient}
                    onChange={() => patch('includeExistingClient', !includeExistingClient)} />
                  Include Existing Patients?
                </label>
                {includeNewClient &&
                  <label>
                    <Checkbox
                      name="new"
                      checked={includeNewPatient}
                      onChange={() => patch('includeNewPatient', !includeNewPatient)} />
                    Include New Patients?
                  </label>
                }
              </div>
              <br />
              <br />
              <label>Copy and Paste this iframe to your site: </label>
              <input type='text' style={{ width: '500px' }} value={iframe} readOnly>
              </input>
              <br />
              <br />
              <p><i>Below is how the web module will appear on your website.</i></p>
              <iframe
                sandbox="allow-top-navigation allow-scripts"
                style={{ border: 'none' }}
                src={url}
                width="500px"
                height="510px">
              </iframe>
            </Paper>
          </div>}
      </div>
    </>
  );
};

export default WebmoduleTemplate;
