import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    maxWidth: '520px',
    margin: '0px auto',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%'
  },
  paper: {
    width: '100%',
    padding: '40px'
  },
  formControl: {
    marginTop: 8,
    marginBottom: 8
  },
  forgotPassword: {
    width: '100%',
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 5
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  buttonLabel: {
    textTransform: 'none',
    fontWeight: 'normal'
  }
}));
