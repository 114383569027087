import React from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import './templates-edit.css';
import { pathOr, merge } from 'ramda';
import * as rawActions from './templates-edit.actions';
import { resetTemplates } from '../../templates.actions';
import CreateMessage from '../../../../components/create-message.component';
import {
  updateAllAutomation, addLocalAutomation, removeAutomation, updateAutomation,
  getAutomationsByMsgId
} from '../../../Automations/automations.actions';
import { useCheckIsAdmin } from '../../../../services/checkIsAdmin.hook';
import { History } from 'history';
import { RouteProps } from 'react-router';
import { match as Match } from 'react-router-dom';

interface MatchParams {
  id?: string;
}

interface TemplateEditProps {
  history: History;
  location: RouteProps['location'];
  match: Match<MatchParams>;
}

const TemplateEdit = ({ history, location, match }: TemplateEditProps) => {
  const dispatch = useDispatch();
  const actions = bindActionCreators(
    merge(rawActions, {
      updateAllAutomation,
      addLocalAutomation,
      removeAutomation,
      updateAutomation,
      getAutomationsByMsgId,
    }),
    dispatch);
  const props = useSelector((state: any) => ({
    ...state.templateEdit,
    ...state.login.office,
    automations: pathOr([], ['automations', 'automations', 'data'])(state),
    admin: state.login.admin,
    sessionStatus: state.session.status,
  }));
  useCheckIsAdmin(props.admin, props.sessionStatus, history);
  return (
    <CreateMessage
      {...props}
      messageType='Template'
      urlId={match.params.id}
      actions={actions}
      resetMessages={() => {
        dispatch(resetTemplates());
      }}
      history={history}
      location={location}
      skedTemplate
    />
  );
};
export default TemplateEdit;
