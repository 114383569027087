import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: 241,
    marginTop: 10,
    marginBottom: 10,
    border: '1px solid rgb(191, 191, 191)',
    borderRadius: 50,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginTop: 5,
      marginBottom: 8,
    },
  },
  circle: {
    width: 30,
    height: 30,
    borderRadius: 30,

    [theme.breakpoints.down('md')]: {
      width: 22,
      height: 22,
    },
  },
  text: {
    margin: '5px 0px 5px 10px',
    width: 190,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    [theme.breakpoints.down('md')]: {
      margin: '2px 0px 2px 5px',
      width: 195,
    },
  },
}));
