import React, { useState } from 'react';
import { pipe, dissoc } from 'ramda';
import {
  TextField, Button, FormControl, Paper, Typography, FormHelperText,
  Select as MSelect, InputLabel, MenuItem, ListItemText, Tooltip,
  Checkbox,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import {
  requiredValidation, emailValidation, updateValue, getValue,
  submitForm, initForm, touchFieldAndValidate, getFirstErrorOrDefault,
  validateField, getFormObject, minLengthValidation,
  passwordMatchValidation,
} from '../../../services/form-validation.js';

import Logo from '../../../images/logo-blue.png';
import SparkLogo from '../../../icons/SparkLogo.icon';
import { useStyles } from './style.js';

const FullTextField = withStyles({
  root: {
    width: '100%',
  },
})(TextField);

const formObject = (plan, isPlatinumReach, isSpark) => {
  const f = {
    email: {
      default: '',
      validation: [
        requiredValidation('Please enter an email address to log on to SKED.'),
        emailValidation('Please enter a valid email address')
      ]
    },
    notificationEmail: {
      default: '',
      validation: [
        requiredValidation('Please enter the email address you\'d like to receive notifications.'),
        emailValidation('Please enter a valid email address')
      ]
    },
    password: {
      default: '',
      validation: [
        requiredValidation('Please enter a password.'),
        minLengthValidation('Your password needs to be 8 characters or longer', 8),
      ]
    },
    password2: {
      default: '',
      validation: [
        requiredValidation('Please re-enter your password.'),
        passwordMatchValidation('The passwords do not match.', 'password'),
      ]
    },
    doctorName: {
      default: '',
      validation: [
        requiredValidation('Please enter the doctor’s name.')
      ]
    },
    doctorPhone: {
      default: '',
      validation: [
        requiredValidation('Please enter a valid phone number.'),
      ]
    },
    valuables: {
      default: '',
      validation: [
        requiredValidation('Please pick 3 priorities.'),
        minLengthValidation('You must pick your top 3 priorities.', 3),
      ]
    },
  };
  return (plan === 'SkedBasic' || plan === 'SkedAgency' || plan === 'SkedGenesisCash' || isPlatinumReach || isSpark) ? dissoc('valuables', f) : f;
};

const valuableOptions = (plan) => {
  return [
    {
      value: 'Reminders & 2-way communication',
      title: 'Learn everything there is to know about creating appointment reminders and communicating with your patients.'
    },
    {
      value: 'Patient appointment management (app)',
      title: plan === 'SkedStandard' ? 'Learn how existing patients can use the SKED App to book and check-in for appointments and how your staff can seamlessly manage the day\'s appointments' : 'Learn how existing patients can use the SKED App to book and check in for appointments and how your staff can seamlessly manage the day\'s appointments and set up alerts.'
    },
    {
      value: plan === 'SkedStandard' ? 'Online booking for new and existing patients' : 'New patient experience/new patient booking (digital forms)',
      title: plan === 'SkedStandard' ? 'Set up an online booking option through your website for new or existing patients.' : 'Set up an incredible experience for new patients by allowing them to request or schedule an appointment directly from your website, automatically firing off an appointment confirmation with your new patient paperwork immediately after the appointment is scheduled.'
    },
    {
      value: 'Patient education & In-office marketing',
      title: plan === 'SkedStandard' ? 'Easily promote in-office events or send educational material seamlessly to existing patients.' : 'Easily promote in-office events or send educational material seamlessly to existing patients or incoming leads.'
    },
    {
      value: plan === 'SkedStandard' ? 'Maintaining retention & Increasing Reviews' : 'Reactivating your patients & maintaining retention',
      title: plan === 'SkedStandard' ? 'Let SKED help you generate great reviews and learn how to set up care plans to keep patients on track with doctor recommendations.' : 'Learn how to set up care plans to keep patients on track, set up automated reactivation messages for when patients fall off care, and let SKED help you generate great reviews.'
    },
  ];
};

const useStepStyles = makeStyles(useStyles);

const updateCb = e => e.target.value;

export const Step2 = ({
  gotoStep,
  step2Data,
  errorMessage,
  plan,
  isPlatinumReach = false,
  isSpark = false,
}) => {
  const classes = useStepStyles();
  const [form, setForm] = useState(initForm(formObject(plan, isPlatinumReach, isSpark), step2Data));
  React.useEffect(() => {
    setForm(initForm(formObject(plan, isPlatinumReach, isSpark), step2Data));
  }, [plan]);
  const update = (prop = '', cb = updateCb) => e => setForm(pipe(
    updateValue(prop, cb(e)),
    validateField(prop)
  ));

  const touchField = (prop) => () => setForm(touchFieldAndValidate(prop, form));

  const handleOnSubmit = e => {
    e.preventDefault();
    const validatedForm = submitForm(form);
    setForm(validatedForm);
    if (!validatedForm.__form.isValid) {
      console.log('form not valid');
      return;
    }
    const data = getFormObject(validatedForm);
    gotoStep(data, 'STEP_3');
  };

  const goBack = () => {
    const data = getFormObject(form);
    gotoStep(data, 'STEP_1');
  };

  const priorities = React.useMemo(() => {
    return valuableOptions(plan);
  }, [plan]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form onSubmit={handleOnSubmit}>
          <div className={classes.headerContainer}>
            {isSpark ? (
              <SparkLogo className={classes.sparkLogo} />
            ) : (
              <img height="30" src={Logo} />
            )}
          </div>
          <div className={classes.headerContainer}>
            <Typography align='center' variant='h4'>Registration</Typography>
          </div>

          <h5 className={classes.headerTitle}>Admin Login Information</h5>

          <div className={classes.formControl}>
            <FullTextField
              fullWidth
              id="reg-email"
              label="Login Email"
              type="email"
              helperText={getFirstErrorOrDefault('email', form, `This is your login email address to access your ${isSpark ? 'Spark' : 'SKED'} account`)}
              error={!form.email.isValid}
              value={getValue('email', form)}
              onChange={update('email')}
              onBlur={touchField('email')}
            />
          </div>

          <div className={classes.formControl}>
            <FullTextField
              fullWidth
              id="reg-password"
              label="Password"
              type="password"
              helperText={getFirstErrorOrDefault('password', form)}
              error={!form.password.isValid}
              onBlur={touchField('password')}
              value={getValue('password', form)}
              onChange={update('password')}
            />
          </div>

          <div className={classes.formControl}>
            <FullTextField
              fullWidth
              id="reg-password2"
              label="Re-type Password"
              type="password"
              helperText={getFirstErrorOrDefault('password2', form)}
              error={!form.password2.isValid}
              onBlur={touchField('password2')}
              value={getValue('password2', form)}
              onChange={update('password2')}
            />
          </div>

          <h5 className={classes.headerTitle}>Notification Information</h5>

          <div className={classes.formControl}>
            <FullTextField
              fullWidth
              id="reg-notification-email"
              label="Office Notification Email"
              type="email"
              helperText={getFirstErrorOrDefault('notificationEmail', form,
                `This is the where all ${isSpark ? 'Spark' : 'SKED'} related emails and reports will be sent`)}
              error={!form.notificationEmail.isValid}
              value={getValue('notificationEmail', form)}
              onChange={update('notificationEmail')}
              onBlur={touchField('notificationEmail')}
            />
          </div>

          <div className={classes.formControl}>
            <FullTextField
              fullWidth
              id="doctor-name"
              label="Head Doctor Name"
              type="Text"
              helperText={getFirstErrorOrDefault('doctorName', form)}
              error={!form.doctorName.isValid}
              value={getValue('doctorName', form)}
              onChange={update('doctorName')}
              onBlur={touchField('doctorName')}
            />
          </div>
          <div className={classes.formControl}>
            <FullTextField
              fullWidth
              id="doctor-phone"
              label="Doctor Cell Phone Number"
              type="text"
              helperText={getFirstErrorOrDefault('doctorPhone', form,
                `By providing your cell phone number you are opting in to receive insider tips and updates from ${isSpark ? 'Spark’s' : 'SKED’s'} CEO, Dr. Erik Kowalke`)}
              error={!form.doctorPhone.isValid}
              onBlur={touchField('doctorPhone')}
              value={getValue('doctorPhone', form)}
              onChange={update('doctorPhone')}
            />
          </div>
          {plan !== 'SkedBasic' && plan !== 'SkedAgency' && plan !== 'SkedGenesisCash' && form && form.valuables && !isPlatinumReach && !isSpark && 
            <>
              <h5 className={classes.headerTitle}>Onboarding Priorities</h5>
              <FormControl
                className={classes.formControl}
                error={!form.valuables.isValid}
              >
                <InputLabel id="valuables-label">Priorities</InputLabel>
                <MSelect
                  fullWidth
                  labelId="valuables-label"
                  id="valuables-input"
                  value={getValue('valuables', form)}
                  renderValue={(v) => (
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                      {v.join('\n')}
                    </div>
                  )}
                  onChange={(e) => {
                    const event = e;
                    if (event.target.value.length > 3)
                      return;
                    update('valuables')(event);
                  }}
                  onBlur={touchField('valuables')}
                  multiple
                >
                  {priorities.map(s => (
                    <MenuItem key={s.value} value={s.value}>
                      <Checkbox checked={getValue('valuables', form).indexOf(s.value) > -1} />
                      <ListItemText primary={s.value} />
                      <Tooltip arrow placement='top' title={s.title}>
                        <InfoIcon style={{ fontSize: '16px' }} />
                      </Tooltip>
                    </MenuItem>
                  ))}
                </MSelect>
                <FormHelperText>
                  {getFirstErrorOrDefault('valuables', form, 'Your time is valuable to us! We\'ll teach you everything there is to know about SKED during your onboarding calls, but to get started, please choose your top 3 priorities.')}
                </FormHelperText>
              </FormControl>
            </>}

          <div className={classes.formControl} style={{ width: '100%', height: '40px' }}>
            <Button
              classes={{ label: classes.buttonLabel }}
              disableElevation
              name="back"
              variant='outlined'
              style={{ float: 'left' }}
              type="button"
              onClick={goBack}>
              Back
            </Button>
            <Button
              disableElevation
              variant='contained'
              type="submit"
              classes={{ label: classes.buttonLabel }}
              style={{ marginLeft: '5px', float: 'right' }}
              name='next2'>
              Next
            </Button>
          </div>
          {!form.__form.isValid && form.__form.isTouched &&
            <FormControl
              className={classes.formControl}
              error={true}>
              <FormHelperText>The form is not filled out correctly.  Please scroll up to see which fields need corrected.</FormHelperText>
            </FormControl>}
          {errorMessage && <FormControl
            className={classes.formControl}
            error={true}>
            <FormHelperText>{errorMessage}</FormHelperText>
          </FormControl>}
        </form>
      </Paper>
    </div>
  );
};
