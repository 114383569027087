import * as R from 'ramda';
import * as at from '../../actionTypes';

const init = {
  state: 'STEP_1',
  email: '',
  notificationEmail: '',
  password: '',
  password2: '',
  officeName: '',
  stripeToken: '',
  timezone: '',
  phone: '',
  hasPromo: false,
  promo: '',
  errorMessage: '',
  address: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
  },
  billingAddress: {
    isDifferent: false,
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'US',
  },
  cardInput: false,
  cardName: '',
  referrer: '',
  busy: false,
  plan: '',
  ehrSystem: '',
  policy: '',
  doctorName: '',
  doctorPhone: '',
  valuables: [],
};

export default (registration = init, action) => {
  switch (action.type) {
    case at.REG_PATCH: {
      return R.merge(registration, action.data);
    }
    case at.REG_ADDRESS_PATCH: {
      return R.evolve({
        address: R.merge(R.__, action.data),
      }, registration);
    }
    case at.REG_BILLING_PATCH: {
      return R.evolve({
        billingAddress: R.merge(R.__, action.data),
      }, registration);
    }
    case at.REG_INSTALL: {
      return R.pipe(
        R.merge(R.__, init),
        R.evolve({
          state: () => 'INSTALL',
        })
      )(registration);
    }
    case at.REG_REGISTER: {
      if (action.state === 'REQUEST') {
        return R.merge(registration, {
          errorMessage: '',
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        if (R.pathOr(false, ['data', 'error'])(action)) {
          return R.merge(registration, {
            busy: false,
            error: action.data.error,
          });
        } else {
          return R.pipe(
            R.merge(R.__, init),
            R.evolve({
              state: () => 'STEP_4',
            })
          )(registration);
        }
      } else if (action.state === 'ERROR') {
        return R.merge(registration, {
          errorMessage: R.pathOr(action.error.message, ['error', 'response', 'data'], action),
          busy: false,
        });
      }
      return registration;
    }
    case at.REG_CREATE_STRIPE_TOKEN: {
      if (action.state === 'REQUEST') {
        return R.merge(registration, {
          errorMessage: '',
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(registration, {
          stripeToken: action.data.token.id
        });
      } else if (action.state === 'ERROR') {
        return R.merge(registration, {
          errorMessage: 'Failed to submit card information.',
          busy: false,
          error: action.error,
        });
      }
      return registration;
    }
    default:
      return registration;
  }
};
