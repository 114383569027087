import React, { useMemo } from 'react';

import { Grid, IconButton } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import PerPage from '../PerPage/PerPage.component';
import { outputLocation } from '../../services/utilities';

import { useStyles } from './pagination.styles';

export type PaginationData = {
  page: number;
  perPage?: number;
  setPerPage?: (v: number) => void;
  totalPages: number;
  totalCount: number;
  addPage: (v: number) => void;
  currentLength: number; /* length of current data in table */
}

const Pagination = ({
  page, perPage, setPerPage, totalCount, totalPages, addPage, currentLength
}: PaginationData) => {
  const classes = useStyles();

  const bckDisabled = useMemo(() => {
    return !page || page === 1;
  }, [page]);

  const fwdDisabled = useMemo(() => {
    return page === totalPages || totalPages === 0;
  }, [page, totalPages]);

  return (
    <Grid display="flex" alignItems="center" className={classes.pagination}>
      {(perPage && setPerPage) && (
        <PerPage
          perPage={perPage}
          setPerPage={setPerPage}
          border={false}
          gray
        />
      )}
      &nbsp; &nbsp;
      {outputLocation(page, perPage, totalCount, currentLength)}
      &nbsp;
      <Grid display="flex" alignItems="center">
        <IconButton
          disabled={bckDisabled}
          onClick={() => addPage(-1)} >
          <ChevronLeftIcon
            className={`${classes.chevrons} ${bckDisabled ? classes.disabled : ''}`}
          />
        </IconButton>
        <IconButton
          disabled={fwdDisabled}
          onClick={() => addPage(1)} >
          <ChevronRightIcon
            className={`${classes.chevrons} ${fwdDisabled ? classes.disabled : ''}`}
          />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Pagination;
