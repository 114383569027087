import React, { ComponentProps } from 'react';

const WarningIcon = ({ color, className, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg className={className} {...props} width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.14099 25.1341C3.14099 25.3285 3.21822 25.5149 3.35568 25.6524C3.49315 25.7898 3.67959 25.8671 3.87399 25.8671H6.07399V22.9341H3.14099V25.1341Z" fill={color || 'black'}/>
      <path d="M7.53699 22.9351V25.8661H9.73698C9.93139 25.8661 10.1178 25.7889 10.2553 25.6514C10.3928 25.514 10.47 25.3275 10.47 25.1331V22.8081C10.009 22.9721 9.90899 22.9351 7.53699 22.9351Z" fill={color || 'black'}/>
      <path d="M18.374 12.7542C18.4128 13.2535 18.3088 13.7537 18.074 14.1962C18.703 14.4552 18.529 14.3592 18.529 21.5182H14.129V15.2052L12.679 14.6152C12.656 14.8092 12.663 14.0442 12.663 25.1812C12.67 25.3707 12.7502 25.5502 12.8868 25.6819C13.0234 25.8135 13.2058 25.8871 13.3955 25.8871C13.5852 25.8871 13.7675 25.8135 13.9041 25.6819C14.0407 25.5502 14.121 25.3707 14.128 25.1812V22.9812H18.528V25.1812C18.535 25.3707 18.6152 25.5502 18.7518 25.6819C18.8885 25.8135 19.0708 25.8871 19.2605 25.8871C19.4502 25.8871 19.6325 25.8135 19.7692 25.6819C19.9058 25.5502 19.986 25.3707 19.993 25.1812V14.8742C19.9926 14.3922 19.834 13.9238 19.5415 13.5408C19.249 13.1578 18.8388 12.8814 18.374 12.7542Z" fill={color || 'black'}/>
      <path d="M6.909 9.34007C6.83431 9.21841 6.79683 9.07757 6.80117 8.93488C6.80551 8.79219 6.85148 8.65389 6.93341 8.53699C7.01535 8.42009 7.12967 8.3297 7.26232 8.27695C7.39497 8.22419 7.54015 8.21137 7.67999 8.24007C8.10099 8.30807 8.05999 8.40607 10.237 12.0401L15.256 14.0811C15.5444 14.1961 15.8659 14.1963 16.1545 14.0817C16.4431 13.9671 16.6768 13.7464 16.8078 13.4648C16.9387 13.1832 16.9568 12.8622 16.8585 12.5677C16.7602 12.2732 16.5528 12.0275 16.279 11.8811L12.464 9.87707L10.725 6.76407C11.3356 6.76145 11.9302 6.56815 12.4257 6.2112C12.9211 5.85424 13.2927 5.35145 13.4886 4.77304C13.6844 4.19464 13.6947 3.56951 13.518 2.98498C13.3413 2.40045 12.9864 1.88571 12.503 1.51265C12.0195 1.13958 11.4316 0.926841 10.8214 0.904128C10.2112 0.881416 9.60907 1.04987 9.09923 1.38596C8.58938 1.72205 8.19724 2.20899 7.97758 2.77877C7.75791 3.34855 7.7217 3.97271 7.87399 4.56407C5.95364 4.90117 4.2109 5.89725 2.94582 7.38081C1.68074 8.86437 0.972487 10.7426 0.942993 12.6921V20.7371C0.942993 20.9315 1.02022 21.1179 1.15768 21.2554C1.29515 21.3928 1.48159 21.4701 1.67599 21.4701H9.73499C9.9294 21.4701 10.1158 21.3928 10.2533 21.2554C10.3908 21.1179 10.468 20.9315 10.468 20.7371V13.7171C8.82099 13.0471 9.651 13.9101 6.909 9.34107V9.34007Z" fill={color || 'black'}/>
    </svg>
  );
};

export default WarningIcon;
