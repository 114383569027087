import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 12,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  item: {
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(0, 139, 207, 0.1)',
    color: theme.palette.primary.main,
    fontWeight: 500,
    padding: '4px 8px',
    marginRight: 6,
    marginBottom: 3,
    marginTop: 3,
    fontSize: 12,

    '& svg': {
      fontSize: 16,
      cursor: 'pointer'
    }
  },
  menuPaper: {
    fontSize: 14,

    '& .MuiAutocomplete-groupLabel': {
      fontSize: 13,
      fontWeight: 500,
      color: '#000'
    }
  },
  input: {
    width: '100%',

    '& .MuiInputBase-root': {
      borderRadius: 10
    }
  },
  selectFontSize: {
    fontSize: 14,

    '& > span': {
      fontSize: '14px !important',
    }
  },
}));
