import { AnyAction } from 'redux';
import * as R from 'ramda';

import * as actionTypes from './alerts.actionTypes';

type AlertsReducer = {
  id: number;
  message: string;
  severity: 'error' | 'info' | 'success' | 'warning';
}

export default function reducer(state: AlertsReducer[] = [], action: AnyAction): AlertsReducer[] {
  switch (action.type) {
    case actionTypes.ALERT_SUCCESS:
      return [...state, { severity: 'success', message: action.message, id: action.id }];

    case actionTypes.ALERT_DANGER:
      return [...state, { severity: 'error', message: action.message, id: action.id }];

    case actionTypes.ALERT_WARN:
      return [...state, { severity: 'warning', message: action.message, id: action.id }];

    case actionTypes.ALERT_INFO:
      return [...state, { severity: 'info', message: action.message, id: action.id }];

    case actionTypes.CLOSE_ALERT: {
      const index = R.findIndex(R.propEq('id', action.id))(state);
      return [...R.remove(index, 1, state)];
    }

    default:
      return state;
  }
}
