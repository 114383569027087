import React from 'react';
import {
  IconButton,
  TableRow,
} from '@mui/material';

import ArrowIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import { ZonedDateTime } from '@js-joda/core';
import { format } from '../../../../services/joda';
import { BodyCell } from '../../../../components/CustomTable';

import { Lead } from '../../leads.reducer';

import { useStyles } from './tableRow.styles';

type TableRowProps = {
  lead: Lead;
  handleClient: () => void;
  onRemove: () => void;
  onEdit: () => void;
}

const LeadTableRow = ({ lead, onRemove, handleClient, onEdit }: TableRowProps) => {
  const classes = useStyles();

  const getSource = (createdSource: object) => {
    if (!createdSource) {
      return '';
    }
    if (Object.prototype.hasOwnProperty.call(createdSource, 'Admin')) {
      return 'Admin';
    }
    if (Object.prototype.hasOwnProperty.call(createdSource, 'Sms')) {
      return 'SMS';
    }
    if (Object.prototype.hasOwnProperty.call(createdSource, 'Npp')) {
      return 'New Patient Portal';
    }
    return '';
  };

  return (
    <TableRow>
      <BodyCell fixed>
        <a onClick={onEdit}>
          {lead.firstName} {lead.lastName}
        </a>
      </BodyCell>
      <BodyCell>
        {lead.phone}
      </BodyCell>
      <BodyCell>
        {lead.email}
      </BodyCell>
      <BodyCell>
        {lead.referrer}
      </BodyCell>
      <BodyCell>
        {getSource(lead.createdSource)}
      </BodyCell>
      <BodyCell>
        {lead?.created ? format(ZonedDateTime.parse(lead?.created), 'MM/dd/yyyy') : ''}
      </BodyCell>
      <BodyCell align='center' nowrap>
        <IconButton
          className={classes.buttonArrow}
          size="small"
          title="Convert Lead"
          onClick={handleClient}>
          <ArrowIcon className={classes.arrow} />
        </IconButton>
        &nbsp;
        <IconButton
          size="small"
          title="Remove Lead"
          onClick={onRemove}>
          <DeleteIcon />
        </IconButton>
      </BodyCell>
    </TableRow>
  );
};

export default LeadTableRow;
