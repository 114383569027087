import { useEffect } from 'react';
import { History } from 'history';

export const useCheckIsAdmin = (admin: boolean, sessionStatus: string, history: History) => {
  useEffect(() => {
    if (!admin && sessionStatus === 'AUTHENTICATED') {
      history.push('/settings/select-office');
    }
  }, [admin, sessionStatus]);
};
