import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '10px',
    marginBottom: 15,
  },
  paper: {
    backgroundColor: 'white',
    padding: '10px',
    marginBottom: 15,
    borderRadius: '10px',
    height: '400px',
    width: '300px',
    overflowY: 'scroll',
  },
  search: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  infoIcon: {
    fontSize: '14px',
    marginBottom: '15px',
  },
  pagination: {
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  chipRoot: {
    width: '241px',
    backgroundColor: '#CECECE',
    color: 'black',
    marginTop: '10px',
    marginBottom: '10px',
    cursor: 'pointer',
    border: '1px solid rgb(191, 191, 191)',
    borderRadius: '50px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  chipInit: {
    width: '30px',
    height: '30px',
    borderRadius: '100px',
    backgroundColor: '#008BCF',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chipText: {
    margin: '5px 0px 5px 10px',
    width: '190px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  closeIcon: {
    marginRight: 2,
    color: 'rgba(0, 0, 0, 0.26)',

    '&:hover': {
      color: 'rgba(0, 0, 0, 0.4)',
    },
  },
}));
