import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  TextField, Checkbox, MenuItem, TableHead, TableBody,
  IconButton, FormControlLabel, Tooltip, Grid
} from '@mui/material';
import {
  tzParseFormat,
} from '../../../services/joda.js';
import * as R from 'ramda';
import { popupWithCancel } from '../../../services/Popup.js';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import CachedIcon from '@mui/icons-material/Cached';
import EditIcon from '@mui/icons-material/Edit';
import api from '../../../services/api.js';
import { ThreadEditor } from '../../../components/Editor/editor.component';
import { MoveToFolder, RemoveFromFolder } from '../../../components/Folders/folders.component';
import { TableContainer, TableRow, HeaderCell, BodyCell } from '../../../components/CustomTable';

export const sortOptions = [
  {
    value: {
      value: 'Created',
      field: 'Created',
      direction: 'Desc'
    },
    label: 'Created'
  },
  {
    value: {
      value: 'IsEnabledAsc',
      field: 'IsEnabled',
      direction: 'Desc'
    },
    label: 'Enabled'
  },
  {
    value: {
      value: 'IsEnabledDesc',
      field: 'IsEnabled',
      direction: 'Asc'
    },
    label: 'Not Enabled'
  },
  {
    value: {
      value: 'NameAsc',
      field: 'Name',
      direction: 'Asc'
    },
    label: 'Name: A-Z'
  },
  {
    value: {
      value: 'NameDesc',
      field: 'Name',
      direction: 'Desc'
    },
    label: 'Name: Z-A'
  },
];

const emptyArray = [];
const countSelecteds = (messages = emptyArray) => R.pipe(
  R.filter((message) => message.selected),
  R.length,
)(messages);

const fixLength = (str, len = 50) => {
  if (str && str.length > len) {
    return str.slice(0, len + 1) + '...';
  }
  return str;
};


const MessageTableItem = ({
  message,
  url,
  tz,
  select,
  copyMessage,
  deleteMessage,
  toggle,
  messageType,
  pageName,
  isTemplate,
}) => {
  return (
    <TableRow
      hasSelect={pageName !== 'sked-templates'}
      checked={R.propOr(false, 'selected')(message)}
      setChecked={(value) => select({ value })}
    >
      <BodyCell fixed>
        {!isTemplate ? <Link to={url + message.id}>{message.name}</Link> :
          <a href='javascript:void(0);' onClick={() => copyMessage(message)}>
            {message.name}
          </a>}
      </BodyCell>
      <BodyCell>
        {R.cond([
          [R.prop('email'), () => fixLength(message.email.subject)],
          [R.prop('push'), () => fixLength(message.push.subject)],
          [R.prop('sms'), () => ''],
          [R.T, () => message.subject && fixLength(message.subject)],
        ])(message)}
      </BodyCell>
      {!isTemplate &&
        <BodyCell>
          <Checkbox
            name="enabled"
            checked={message.isEnabled}
            disabled={!toggle}
            onChange={() => toggle(R.merge(message, { isEnabled: !message.isEnabled }))}
          />
        </BodyCell>}
      <BodyCell>
        <span style={{ display: 'flex', width: '100%', height: '100%' }}>
          {message.sms && <span style={{ marginRight: '5px', height: '100%', display: 'flex', alignItems: 'center' }}><ChatBubbleIcon style={{ fontSize: '20px' }} /></span>}
          {message.email && <span style={{ marginRight: '5px', marginLeft: '5px', display: 'flex', alignItems: 'center' }}><EmailIcon style={{ fontSize: '20px' }} /></span>}
          {message.push && <span style={{ display: 'flex', alignItems: 'center' }}><SmartphoneIcon style={{ fontSize: '20px' }} /></span>}
        </span>
      </BodyCell>
      {pageName !== 'sked-templates' &&
        <BodyCell nowrap>
          {tzParseFormat(message.createdAt, tz, 'MM/dd/yyyy h:mm a')}
        </BodyCell>}
      {pageName !== 'sked-templates' &&
        <BodyCell minWidth={64}>
          <IconButton
            onClick={() => copyMessage(message)} >
            <FileCopyIcon />
          </IconButton>
          <IconButton
            onClick={() =>
              popupWithCancel(
                'Are you sure you want to delete this message?',
                'This will permanently delete it.',
                () => deleteMessage({ id: message.id, messageType }),
                'danger'
              )}>
            <DeleteIcon />
          </IconButton>
        </BodyCell>}
    </TableRow>
  );
};

/* keep param `a` here for TS reasons */
const noop = (a) => {
  console.log(a);
  return;
};

export const MessageTable = ({
  busy, messages, url, tz, selectAll = null, selectMessage = null, copyMessage = null,
  deleteMessage = null, deleteSelected = null, toggle = null, page, query, filter,
  totalPages, totalCount, search = noop, messagesPatch = null,
  hideSearch = false, error, messageType, pageName, path = null, noFolder = null,
  heightDiff = 140, fullPage = true,
}) => {
  const [filterHeight, setFilterHeight] = React.useState(0);
  const isSkedTemplate = pageName === 'sked-templates';
  const isTemplate = isSkedTemplate || pageName === 'templates';
  const ref = React.useRef(null);
  const selecteds = React.useMemo(() => countSelecteds(messages), [messages]);
  const { hasFolders } = useSelector((state) => ({
    hasFolders: R.includes('Folders', R.pathOr([], ['login', 'features'], state)),
  }));

  React.useEffect(() => {
    if (!ref.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setFilterHeight?.(ref.current.clientHeight);
    });
    resizeObserver.observe(ref.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return (
    <div>
      {error && <div>Recurring error: {error}</div>}
      <Grid ref={ref}>
        {!hideSearch && !isSkedTemplate && (
          <Grid marginX={2}>
            {hasFolders &&
              <FormControlLabel
                control={
                  <Checkbox
                    checked={noFolder}
                    onChange={(e) => {
                      const checked = e.target.checked;
                      messagesPatch('noFolder', checked);
                      selectAll({
                        value: false,
                      });
                      search({
                        query: query.trim(),
                        filter,
                        page,
                        noFolder: checked,
                        path: checked ? path : undefined,
                      });
                    }}
                    name="acknowledge-checkbox"
                    color="primary"
                  />
                }
                label="View all messages not in a folder"
              />
            }
          </Grid>
        )}
      </Grid>
      {messages && messages.error &&
        <div>Error: Could not get messages</div>
      }
      <TableContainer
        maxHeight={`calc(100vh - ${heightDiff}px - ${filterHeight || 0}px)`}
        fullPage={fullPage}
        checkHasScroll={!busy && !!messages?.length}
        pagination
        paginationData={{
          addPage: (v) => search({
            query: query.trim(),
            filter,
            page: page + v,
            noFolder,
          }),
          currentLength: messages?.length || 0,
          page,
          totalPages,
          totalCount,
          perPage: 50,
        }}
      >
        <TableHead>
          <TableRow
            hasSelect={pageName !== 'sked-templates'}
            checked={messages?.length && selecteds === messages?.length}
            setChecked={(value) => selectAll({ value })}
            noSelectHover
            someChecked={selecteds > 0 && selecteds < messages?.length}
          >
            <HeaderCell fixed>
              {selecteds > 0 &&
                <Grid position="absolute" display="flex" alignItems="center" top={0} left={8} style={{ background: '#F5F5F5' }}>
                  <MoveToFolder
                    open={selecteds > 0}
                    path={path}
                    messages={messages}
                    onMove={(newPath) => {
                      let p = newPath || path;
                      if (!noFolder) {
                        p = undefined;
                      }
                      search({
                        query: query.trim(),
                        filter,
                        page,
                        noFolder,
                        path: p,
                      });
                    }}
                  />
                  <RemoveFromFolder
                    open={selecteds > 0}
                    path={path}
                    messages={messages}
                    onMove={(path) => {
                      search({
                        query: query.trim(),
                        filter,
                        page,
                        noFolder,
                        path,
                      });
                    }}
                  />
                  <Tooltip title='Delete selected'>
                    <IconButton
                      onClick={() =>
                        popupWithCancel(
                          'Are you sure you want to delete these?',
                          'They will permanently deleted.',
                          () => deleteSelected({ messages, messageType }),
                          'danger'
                        )}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>}
              Name
            </HeaderCell>
            <HeaderCell>
              Subject
            </HeaderCell>
            {!isTemplate &&
              <HeaderCell>
                Enabled
              </HeaderCell>}
            <HeaderCell>
              Sent Via
            </HeaderCell>
            {pageName !== 'sked-templates' &&
              <HeaderCell>
                Created
              </HeaderCell>}
            {pageName !== 'sked-templates' &&
              <HeaderCell>
                Actions
              </HeaderCell>}
          </TableRow>
        </TableHead>
        {!busy && !!messages?.length &&
          <TableBody>
            {messages?.map((message, index) => (
              <MessageTableItem
                key={message.id}
                select={R.pipe(R.assoc('index', index), selectMessage)}
                {...{
                  message,
                  url,
                  tz,
                  copyMessage,
                  deleteMessage,
                  toggle,
                  messageType,
                  pageName,
                  isTemplate,
                }}
              />
            ))}
          </TableBody>}
      </TableContainer>
    </div>
  );
};

const enableOptions = ['Enabled', 'Enabled When Office Is Closed', 'Disabled'];

const BasicMessageTableItem = ({
  message,
  select,
  copyMessage,
  deleteMessage,
  saveMessage,
}) => {
  const [isEditing, setIsEditing] = useState(message.isEditing);
  const [body, setBody] = useState(message.body);
  const [busy, setBusy] = useState(null);
  const [emoji, setEmoji] = React.useState(false);
  return (
    <TableRow
      key={message.autoId}
      hasSelect
      checked={R.propOr(false, 'selected')(message)}
      setChecked={(value) => select({ value })}
    >
      <BodyCell fixed>
        {isEditing ?
          <ThreadEditor
            body={body}
            messagesPatch={(b) => setBody(b)}
            patch={(_, value) => setEmoji(value)}
            emoji={emoji}
          />
          :
          <p
            style={{ cursor: 'pointer', margin: 'unset' }}
            onClick={() => setIsEditing(true)}>
            {fixLength(message.body, 150)}
          </p>}
      </BodyCell>
      <BodyCell>
        {busy === 'enabling' ?
          <IconButton>
            <CachedIcon className={busy ? 'sked-spin' : ''} />
          </IconButton>
          :
          <TextField
            select
            style={{ marginLeft: '7px' }}
            value={R.cond([
              [R.reduce(R.and, true), R.always('Enabled When Office Is Closed')],
              [R.nth(0), R.always('Enabled')],
              [R.T, R.always('Disabled')]
            ])([message.enabled, message.onlyWhenClosed])}>
            {enableOptions.map((option) => (
              <MenuItem
                key={option}
                onClick={() => {
                  const enabledValue = option !== 'Disabled';
                  const onlyClosed = option === 'Enabled When Office Is Closed';
                  const data = R.merge(message, { enabled: enabledValue, onlyWhenClosed: onlyClosed });
                  setBusy('enabling');
                  api.put(`autoresponder/${message.autoId}`, data)
                    .then((n) => {
                      setBusy(null);
                      saveMessage(n);
                    });
                }}
                value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>}
      </BodyCell>
      <BodyCell nowrap>
        {isEditing ?
          <>
            {busy === 'saving' ?
              <IconButton>
                <CachedIcon className={busy ? 'sked-spin' : ''} />
              </IconButton>
              :
              <IconButton
                onClick={() => {
                  if (!busy && !R.isEmpty(body)) {
                    const data = { body, enabled: message.enabled };
                    setBusy('saving');
                    api.put(`autoresponder/${message.autoId}`, data)
                      .then((newMessage) => {
                        setBusy(null);
                        saveMessage(newMessage);
                        setIsEditing(false);
                      });
                  }
                }} >
                <SaveIcon />
              </IconButton>}
            <IconButton
              onClick={() => {
                if (R.isEmpty(message.body) && R.isEmpty(body)) {
                  deleteMessage(message.autoId);
                } else if ((R.isEmpty(message.body) && !R.isEmpty(body))) {
                  const data = { body, enabled: message.enabled };
                  setBusy('saving');
                  api.put(`autoresponder/${message.autoId}`, data)
                    .then((newMessage) => {
                      setBusy(null);
                      saveMessage(newMessage);
                      setIsEditing(false);
                    });
                } else {
                  setBody(message.body);
                  setIsEditing(false);
                }
              }}>
              {!busy && <CloseIcon />}
            </IconButton>
          </>
          :
          <>
            <IconButton
              onClick={() => setIsEditing(true)} >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => copyMessage(message)} >
              <FileCopyIcon />
            </IconButton>
            <IconButton
              onClick={() =>
                popupWithCancel(
                  'Are you sure you want to delete this message?',
                  'This will permanently delete it.',
                  () => deleteMessage(message.autoId),
                  'danger')
              }>
              <DeleteIcon />
            </IconButton>
          </>}
      </BodyCell>
    </TableRow>
  );
};

export const BasicMessageTable = ({
  busy,
  messages,
  selectAll,
  selectMessage,
  copyMessage,
  deleteMessage,
  deleteSelected,
  toggle,
  error,
  updateAutoresponderMessage,
  headerHeight,
}) => {
  const selecteds = React.useMemo(() => countSelecteds(messages), [messages]);
  return (
    <div>
      {error && <div>Recurring error: {error}</div>}
      {!busy && messages &&
        <div style={{ margin: '0px 0px 10px' }}>
          {messages.error ?
            <div>Error: Could not get messages</div>
            :
            <TableContainer
              fullPage
              style={{ marginLeft: -20, marginRight: -20 }}
              maxHeight={`calc(100vh - ${headerHeight}px - 85px)`}
            >
              <TableHead>
                <TableRow
                  hasSelect
                  checked={messages?.length && selecteds === messages?.length}
                  setChecked={(value) => selectAll({ value })}
                  noSelectHover
                  someChecked={selecteds > 0}
                >
                  <HeaderCell fixed>
                    {selecteds > 0 &&
                      <Grid
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        top={0}
                        left={8}
                        width="90%"
                        style={{ background: '#F5F5F5' }}>
                        <IconButton
                          style={{
                            cursor: 'pointer',
                            color: 'rgba(0, 0, 0, 0.54)',
                          }}
                          onClick={() =>
                            popupWithCancel(
                              'Are you sure you want to delete these?',
                              'They will permanently deleted.',
                              () => deleteSelected(messages),
                              'danger'
                            )}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    }
                    Body
                  </HeaderCell>
                  <HeaderCell>
                    Status
                  </HeaderCell>
                  <HeaderCell>
                    Actions
                  </HeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messages.map((message, index) => (
                  <BasicMessageTableItem
                    key={message.autoId}
                    select={R.pipe(R.assoc('index', index), selectMessage)}
                    {...{
                      message,
                      copyMessage,
                      deleteMessage,
                      toggle,
                      saveMessage: (n) => updateAutoresponderMessage(n, index),
                    }}
                  />
                ))}
              </TableBody>
            </TableContainer>}
        </div>}
    </div>
  );
};
