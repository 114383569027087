/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  TextField,
  CircularProgress,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';
import {
  format,
  now,
} from '../../../services/joda.js';
import {
  LocalDate,
} from '@js-joda/core';
import {
  merge, isEmpty, cond, has, pathOr, propOr, T,
  equals, includes, filter, isNil, assoc, map, prop,
  pipe, dissoc, ifElse, identity, always,
} from 'ramda';
import { PopupTemplate } from '../../../services/Popup.js';
import api from '../../../services/api.js';
import { usePromise } from '../../../services/promise.hook';
import {
  MessageComposer,
  clientPlaceholders,
  officePlaceholders,
  apptPlaceholders,
  removePlaceholders,
  createSMSData,
} from '../../../components/create-message.component';
import { canSend, attachEmailBody } from '../../../services/utilities.js';
import { AppointmentTypeAutocomplete } from '../../CalendarV2/components/AppointmentTypeSelect.component';
import Modal from '../../../components/Modal/Modal.component';
import HeaderButton from '../../../components/HeaderButton/HeaderButton.component';
import StopIcon from '../../../icons/Stop.icon';
import TaskIcon from '../../../icons/Warning.icon';

const getProTypes = () => Promise.all([
  api.get('appointmentType'),
  api.get('professional'),
]).then(([types, pros]) => {
  const withoutHiddenPros = pros.filter(({ isHidden }) => !isHidden);
  const withoutHiddenTypes = types.filter(({ professionalId }) =>
    withoutHiddenPros.find(({ id }) => id === professionalId));
  return [withoutHiddenTypes, withoutHiddenPros];
});

const getAttachments = (attachmentIds) => {
  return Promise.all(map((aIds) => {
    return api.post('attachment', { attachmentIds: aIds });
  })(attachmentIds));
};

const getMessage = (id) => api.get(`message/${id}`).then((message) => {
  return getAttachments([
    pathOr([], ['email', 'attachmentIds'], message),
    pathOr([], ['sms', 'attachmentIds'], message),
  ]).then(([emailOfficeAttach, smsOfficeAttach]) => {
    const emailAttachments = propOr([], 'officeAttachments')(emailOfficeAttach);
    const smsAttachments = propOr([], 'officeAttachments')(smsOfficeAttach);
    const def = {
      subject: '',
      body: '',
    };
    const smsDef = ifElse(
      identity,
      dissoc('subject'),
      identity
    )(def);
    const maybeEmail = propOr(def, 'email')(message);
    const maybeSms = propOr(smsDef, 'sms')(message);
    const email = ifElse(
      identity,
      always({
        subject: maybeEmail.subject,
        body: pathOr('', ['body', 'PlainText'])(maybeEmail),
        html: pathOr(null, ['body', 'HTML', 'body'])(maybeEmail),
        shouldFrame: pathOr(true, ['body', 'HTML', 'shouldFrame'])(maybeEmail),
        attachments: emailAttachments
      }),
      always(merge(def, { shouldFrame: true }))
    )(maybeEmail);
    const sms = ifElse(
      identity,
      assoc('attachments', smsAttachments),
      always(smsDef)
    )(maybeSms);
    return {
      ...message,
      email,
      sms,
    };
  });
});

const SelectButton = withStyles(() => ({
  contained: {
    backgroundColor: 'rgba(0, 0, 0, 0.12);',
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    '&:hover': {
      color: 'white',
      backgroundColor: 'rgba(0, 0, 0, 0.36);',
    },
    '&:disabled': {
      backgroundColor: '#008BCF',
      color: 'white',
      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    }
  },
}))(Button);

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: 8,
    marginBottom: 8
  },
  afterDateRepectOptions: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  checkbox: {
    paddingLeft: 4,
    marginRight: 0,

    '& .MuiCheckbox-root': {
      padding: 2,
      paddingTop: 5,
      paddingBottom: 5,
      marginRight: 4,
    }
  },
}));

const defaultInterval = {
  Days: 1,
};
const defaultAfter = {
  appointmentTypes: [0],
  numberOfAppointments: 1,
};

const defaultState = (trigger) => ({
  isDeleted: false,
  enabled: true,
  stopType: 'Stop', // #{'Stop' 'Task'}
  trigger,
  interval: defaultInterval,
  afterApptTypes: defaultAfter,
  date: now('date'),
  endDate: now('date'),
  repeat: false,
  message: '',
});

const stopTypes = ['Stop', 'Task'];

const makeDate = (obj, path) => {
  const hasDate = pathOr(null, path, obj);
  const isJoda = typeof hasDate === 'object';
  let date = hasDate;
  if (!isJoda) {
    date = LocalDate.parse(hasDate);
  }
  return date;
};

const createDescriptions = (trigger) => cond([
  [equals('Immediately'), () => (
    <i>
      "Immediate" alerts fire immediately after saving and will show in Today's Alerts in the Front Desk promptly.
    </i>
  )],
  [equals('OnDate'), () => (
    <i>
      "On Date" alerts will only show on the selected date. These alerts are not dependant on a scheduled appointment, so they will display regardless if the patient comes into the office or not.
    </i>
  )],
  [equals('AfterDate'), () => (
    <i>
      "After Date" alerts will trigger on or after the selected date once the patient arrives for an appointment. They will only fire one time.
    </i>
  )],
  [equals('AfterDateRepeat'), () => (
    <i>
      "After Date Repeat" alerts allow the alert to be triggered multiple times either Daily, Monthly, or Yearly. These alerts will not fire until the patient arrives for an appointment on or after that date.
    </i>
  )],
  [equals('AfterXAppointments'), () => (
    <i>
      "Upon Arrival" alerts will be triggered only after a patient comes in for a certain appointment type. This alert will only fire once after they arrive for that specific appointment type.
    </i>
  )],
  [equals('AfterXAppointmentsRepeat'), () => (
    <i>
      "Upon Arrival Repeat" alerts will continue to fire every time a patient has an appointment, or every 2nd or 3rd visit. You'll be able to customize that number specifically.
    </i>
  )],
])(trigger);

/*
  `keepMsg` is so that if you want to modify a template's message for a specific
  client, it doesn't override the template but makes a new message rather.
*/
const CreateStopManager = ({
  open,
  onClose,
  isTemplate,
  stopManager,
  save,
  group,
  clientId,
  keepMsg = true,
  isEmbedded = false,
  isError,
  defaultTrigger = 'AfterDate',
  showImmediate = false,
}) => {
  const classes = useStyles();
  const [_SMSError, setSMSError] = React.useState(defaultState(defaultTrigger));
  const [state, setState] = React.useState(defaultState(defaultTrigger));
  const [error, setError] = React.useState(true);
  const {
    id,
    enabled,
    stopType,
    trigger,
    message,
    date,
    endDate,
    interval,
    afterApptTypes,
    includeMessage,
    msgId,
    repeat,
  } = state;

  const update = (data) => setState(merge(state, data));

  const {
    features,
  } = useSelector((state) => ({
    features: pathOr([], ['login', 'features'])(state),
  }));

  const proTypesState = usePromise(getProTypes, {});

  const defaultEmail = {
    subject: '',
    body: '',
    html: includes('HtmlEmail', features) ? '' : null,
    shouldFrame: true,
    attachments: [],
  };
  const defaultSms = {
    body: '',
    attachments: [],
  };
  const defaultPush = {
    subject: '',
    body: '',
    attachments: [],
  };

  const messageData = {
    email: defaultEmail,
    sms: defaultSms,
    push: defaultPush,
  };

  const messageState = usePromise(getMessage, messageData);

  React.useEffect(() => {
    proTypesState.invoke();
    if (open && !isEmpty(stopManager)) {
      const trigger = cond([
        [has('OnDate'), (o) => ({
          trigger: 'OnDate',
          date: isEmpty(o.OnDate) ? now('date') : LocalDate.parse(o.OnDate),
        })],
        [has('AfterDate'), (o) => ({
          trigger: 'AfterDate',
          date: isEmpty(o.AfterDate) ? now('date') : LocalDate.parse(o.AfterDate),
        })],
        [has('AfterDateRepeat'), (o) => {
          const date = makeDate(o, ['AfterDateRepeat', 'startDate']);
          const endDate = makeDate(o, ['AfterDateRepeat', 'endDate']);
          return ({
            trigger: 'AfterDateRepeat',
            interval: pathOr(defaultInterval, ['AfterDateRepeat', 'interval'], o),
            date,
            endDate,
            repeat: true,
          });
        }],
        [has('AfterXAppointments'), (o) => {
          console.log(pipe(
            propOr(defaultAfter, 'AfterXAppointments'),
            ifElse(
              pipe(prop('appointmentTypes'), isEmpty),
              assoc('appointmentTypes', [0]),
              identity
            )
          )(o));
          const date = makeDate(o, ['AfterXAppointments', 'startDate']);
          return ({
            trigger: 'AfterXAppointments',
            afterApptTypes: pipe(
              propOr(defaultAfter, 'AfterXAppointments'),
              ifElse(
                pipe(prop('appointmentTypes'), isEmpty),
                assoc('appointmentTypes', [0]),
                identity
              )
            )(o),
            date,
          });
        }],
        [has('AfterXAppointmentsRepeat'), (o) => {
          const date = makeDate(o, ['AfterXAppointmentsRepeat', 'startDate']);
          const endDate = makeDate(o, ['AfterXAppointmentsRepeat', 'endDate']);
          return ({
            trigger: 'AfterXAppointmentsRepeat',
            afterApptTypes: pipe(
              propOr(defaultAfter, 'AfterXAppointmentsRepeat'),
              ifElse(
                pipe(prop('appointmentTypes'), isEmpty),
                assoc('appointmentTypes', [0]),
                identity
              )
            )(o),
            date,
            endDate,
            repeat: true,
          });
        }],
        [T, () => ({
          trigger: 'AfterDate',
        })]
      ])(stopManager.trigger);
      const msgId = stopManager.msgId;
      if (msgId) {
        messageState.invoke(msgId);
      } else {
        messageState.setState({
          data: {
            email: defaultEmail,
            sms: defaultSms,
            push: defaultPush,
          }
        });
      }
      setState({
        ...stopManager,
        ...trigger,
        msgId: keepMsg ? msgId : null,
        includeMessage: Boolean(msgId)
      });
    } else {
      setState(defaultState(defaultTrigger));
    }
  }, [open]);

  React.useEffect(() => {
    const {
      email,
      sms,
      push,
    } = messageState.data;
    const kindaMessage = {
      email: !propOr(false, 'subject', email) &&
        !propOr(false, 'body', email) &&
        !propOr(false, 'html', email) ?
        undefined
        :
        merge(email, {
          attachmentIds: map(prop('attachmentId'), email.attachments),
        }),
      sms: createSMSData(sms),
      push: propOr(false, 'subject', push) && propOr(false, 'body', push) ?
        merge(push, {
          attachmentIds: [],
        })
        :
        undefined,
    };
    const missingData = isEmpty(message) ||
      (includeMessage && canSend({
        message: kindaMessage,
        messageName: 'gotta name',
      })) ||
      (!isTemplate && (isNil(date) || isEmpty(date))) ||
      (includes('Appointments', trigger) && (
        isEmpty(propOr([], 'appointmentTypes', afterApptTypes))
        ||
        propOr(0, 'numberOfAppointments', afterApptTypes) === 0
      ));
    if (isError) {
      isError(missingData);
    }
    setError(missingData);
  }, [
    messageState.data, message, trigger,
    afterApptTypes, includeMessage
  ]);


  React.useEffect(() => {
    setState(defaultState(defaultTrigger));
  }, [defaultTrigger]);

  const makeStop = () => ({
    id,
    msgId: includeMessage ? msgId : undefined,
    groupId: group ? group.groupId : undefined,
    message,
    stopType,
    enabled: isNil(enabled) ? false : enabled,
    clientId: isTemplate ? undefined : clientId,
    trigger: {
      [trigger]: cond([
        [equals('OnDate'), () => {
          return isTemplate ? [] : format(date, 'yyyy-MM-dd');
        }],
        [equals('AfterDate'), () => {
          return isTemplate ? [] : format(date, 'yyyy-MM-dd');
        }],
        [equals('AfterDateRepeat'), () => ({
          interval,
          startDate: isTemplate ? undefined : date,
          endDate: isTemplate ? undefined : endDate,
        })],
        [equals('AfterXAppointments'), () => ({
          ...afterApptTypes,
          appointmentTypes: includes(0, afterApptTypes.appointmentTypes) ?
            [] : afterApptTypes.appointmentTypes,
          startDate: isTemplate ? undefined : date,
        })],
        [equals('AfterXAppointmentsRepeat'), () => ({
          ...afterApptTypes,
          appointmentTypes: includes(0, afterApptTypes.appointmentTypes) ?
            [] : afterApptTypes.appointmentTypes,
          startDate: isTemplate ? undefined : date,
          endDate: isTemplate ? undefined : endDate,
        })],
      ])(trigger),
    }
  });

  const makeMessage = () => {
    const {
      email,
      sms,
      push,
    } = messageState.data;
    const validEmail = email && ((email.subject !== '' && email.body !== '') ||
      (email.subject !== '' && !isEmpty(email.html?.split('>')[1]?.split('</')[0]?.trim())))
      ?
      pipe(
        assoc('attachmentIds', map(prop('attachmentId'), propOr([], 'attachments', email))),
        dissoc('attachments')
      )(email)
      :
      null;
    const validSms = sms
      ?
      pipe(
        assoc('attachmentIds', map(prop('attachmentId'), propOr([], 'attachments', sms))),
        dissoc('attachments')
      )(sms)
      :
      null;
    const validPush = push && (push.subject !== '' && push.body !== '')
      ?
      pipe(
        assoc('attachmentIds', map(prop('attachmentId'), propOr([], 'attachments', push))),
        dissoc('attachments')
      )(push)
      :
      null;
    return attachEmailBody({
      isDeleted: false,
      isTemplate: false,
      messageType: {
        StopManager: [],
      },
      name: message,
      isEnabled: true,
      email: validEmail,
      sms: validSms,
      push: validPush,
    });
  };

  if (isEmbedded) {
    React.useEffect(() => {
      save({
        stop: makeStop(),
        message: includeMessage && makeMessage(),
      });
    }, [state, messageState.data]);
  }

  const today = React.useMemo(() => {
    return format(now('date'), 'yyyy-MM-dd');
  }, [state.isEnabled]);

  const clientHolders =
    filter((holder) => removePlaceholders(features, holder))(clientPlaceholders);
  const officeHolders =
    filter((holder) => removePlaceholders(features, holder))(officePlaceholders);
  const placeholders = [
    { title: 'Client', placeholders: clientHolders },
    { title: 'Office', placeholders: officeHolders },
    (includes('Appointment', trigger))
    &&
    { title: 'Appointment', placeholders: apptPlaceholders }
  ];
  const handleCheck = (e) => {
    const checked = e.target.checked;
    const options = includes('Date', trigger) ?
      ['AfterDateRepeat', 'AfterDate']
      :
      ['AfterXAppointmentsRepeat', 'AfterXAppointments'];
    update({
      repeat: checked,
      trigger: checked ? options[0] : options[1],
    });
  };
  const title = isTemplate ? ' Template ' : '';
  const stopTypesItem = (item) => (
    <MenuItem
      key={item}
      value={item}
      onClick={() => update({ stopType: item })}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}>
        {item === 'Task' ?
          <TaskIcon size='small' style={{ verticalAlign: 'middle', marginRight: 5 }} />
          :
          <StopIcon size='small' style={{ verticalAlign: 'middle', marginRight: 5 }} />}
        {item}
      </div>
    </MenuItem>
  );
  const afterAppointments = () => (
    <div>
      {!isTemplate &&
        <>
          <TextField
            type='date'
            label='Start Date'
            value={isNil(date) || isEmpty(date) ? '' : format(date, 'yyyy-MM-dd')}
            error={isNil(date) || isEmpty(date)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: today
            }}
            helperText='Required field'
            onChange={(e) => {
              const value = e.target.value;
              if (value) {
                update({ date: LocalDate.parse(value) });
              } else {
                update({ date: value });
              }
            }}
          />
          <br />
        </>}
      <br />
      {!isTemplate && includes('Repeat', trigger) &&
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(endDate)}
                onChange={(e) => update({ endDate: e.target.checked ? now('date') : null })}
                color="primary"
              />
            }
            label="Include End Date?"
          />
          <br />
          {endDate &&
            <TextField
              type='date'
              label='End Date'
              value={isNil(endDate) || isEmpty(endDate) ? '' : format(endDate, 'yyyy-MM-dd')}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: today
              }}
              helperText='Required field'
              onChange={(e) => {
                const value = e.target.value;
                if (value) {
                  update({ endDate: LocalDate.parse(value) });
                } else {
                  update({ endDate: value });
                }
              }} />}
          <br />
          <br />
        </>}
      <TextField
        type='number'
        label='After Number of Appointments'
        value={propOr(0, 'numberOfAppointments', afterApptTypes)}
        helperText='Must be greater than 0'
        error={propOr(0, 'numberOfAppointments', afterApptTypes) === 0}
        onChange={(e) => {
          const v = Number(e.target.value);
          if (v > 0) {
            update({ afterApptTypes: { ...afterApptTypes, numberOfAppointments: v } });
          }
        }}
      />
      <br />
      <br />
      <AppointmentTypeAutocomplete
        value={propOr([], 'appointmentTypes', afterApptTypes)}
        onChange={({ target }) => {
          update({
            afterApptTypes: {
              ...afterApptTypes,
              appointmentTypes: target.ids,
            }
          });
        }}
        noneIsAll={true}
        disableCloseOnSelect
        width={'300px'}
        multiple
        types={pathOr([], ['data', 0], proTypesState)}
        professionals={pathOr([], ['data', 1], proTypesState)}
        id={'apt-type'}
        error={isEmpty(propOr([], 'appointmentTypes', afterApptTypes))}
      />
      {/* <TypeSelector */}
      {/*   selected={} */}
      {/*   onChange={(ts, some) => { */}
      {/*     update({afterApptTypes: {...afterApptTypes, appointmentTypes: ts}}); */}
      {/*   }} */}
      {/* /> */}
    </div>
  );

  const onSave = () => {
    const stop = makeStop();
    if (includeMessage) {
      const data = makeMessage();
      if (msgId) {
        api.put(`message/${msgId}`, data).then(() => {
          save({ ...stop, msgId });
        });
      } else {
        api.post('message', data).then((msg) => {
          save({ ...stop, msgId: msg.id });
        });
      }
    } else {
      save(stop);
    }
  };

  if (isEmbedded) {
    return (
      <form>
        <div className={classes.formControl}>
          <TextField
            select
            value={stopType}
            label='Alert Type'
            helperText='What type of alert is this?'
          >
            {stopTypes.map(stopTypesItem)}
          </TextField>
        </div>
        <div className={classes.formControl}>
          Trigger:
          <br />
          {!isTemplate && showImmediate &&
            <>
              <SelectButton
                variant='contained'
                disabled={trigger === 'Immediately'}
                onClick={() => update({ trigger: 'Immediately' })}>
                Immediately
              </SelectButton>
              &nbsp;
            </>
          }
          <SelectButton
            variant='contained'
            disabled={trigger === 'OnDate'}
            onClick={() => update({ trigger: 'OnDate' })}>
            On date
          </SelectButton>
          &nbsp;
          <SelectButton
            variant='contained'
            disabled={trigger === 'AfterDate' || trigger === 'AfterDateRepeat'}
            onClick={() => repeat ? update({ trigger: 'AfterDateRepeat' }) : update({ trigger: 'AfterDate' })}>
            After date
          </SelectButton>
          &nbsp;
          <SelectButton
            variant='contained'
            disabled={trigger === 'AfterXAppointments' || trigger === 'AfterXAppointmentsRepeat'}
            onClick={() => repeat ? update({ trigger: 'AfterXAppointmentsRepeat' }) : update({ trigger: 'AfterXAppointments' })}>
            Upon arrival
          </SelectButton>
          <br />
          <br />
          {createDescriptions(trigger)}
          {trigger !== 'Immediately' &&
            <>
              <br />
              <br />
              {trigger !== 'OnDate' &&
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(repeat)}
                      onChange={handleCheck}
                      color="primary"
                    />
                  }
                  label="Repeat"
                />}
              <br />
              <br />
              {cond([
                [equals('OnDate'), () => !isTemplate && (
                  <TextField
                    type='date'
                    label='Start Date'
                    value={isNil(date) || isEmpty(date) ? '' : format(date, 'yyyy-MM-dd')}
                    error={isNil(date) || isEmpty(date)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: today
                    }}
                    helperText='Required field'
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value) {
                        update({ date: LocalDate.parse(value) });
                      } else {
                        update({ date: value });
                      }
                    }} />
                )],
                [equals('AfterDate'), () => !isTemplate && (
                  <TextField
                    type='date'
                    inputProps={{
                      min: today
                    }}
                    label='Start Date'
                    value={isNil(date) || isEmpty(date) ? '' : format(date, 'yyyy-MM-dd')}
                    error={isNil(date) || isEmpty(date)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText='Required field'
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value) {
                        update({ date: LocalDate.parse(value) });
                      } else {
                        update({ date: value });
                      }
                    }} />
                )],
                [equals('AfterDateRepeat'), () => (
                  <div>
                    {!isTemplate &&
                      <TextField
                        type='date'
                        inputProps={{
                          min: today
                        }}
                        label='Start Date'
                        value={isNil(date) || isEmpty(date) ? '' : format(date, 'yyyy-MM-dd')}
                        error={isNil(date) || isEmpty(date)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        helperText='Required field'
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value) {
                            update({ date: LocalDate.parse(value) });
                          } else {
                            update({ date: value });
                          }
                        }} />}
                    <br />
                    {!isTemplate &&
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={Boolean(endDate)}
                            onChange={(e) => update({ endDate: e.target.checked ? now('date') : null })}
                            color="primary"
                          />
                        }
                        label="Include End Date?"
                      />
                    }
                    {!isTemplate && !isNil(endDate) &&
                      <>
                        <br />
                        <TextField
                          type='date'
                          inputProps={{
                            min: today
                          }}
                          label='End Date'
                          value={isNil(endDate) || isEmpty(endDate) ? '' : format(endDate, 'yyyy-MM-dd')}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          helperText='Required field'
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value) {
                              update({ endDate: LocalDate.parse(value) });
                            } else {
                              update({ endDate: value });
                            }
                          }} />
                      </>}
                    <br />
                    <br />
                    Interval:
                    <br />
                    <SelectButton
                      variant='contained'
                      disabled={has('Days', interval)}
                      onClick={() => update({ interval: { Days: 1 } })}>
                      Days
                    </SelectButton>
                    &nbsp;
                    <SelectButton
                      variant='contained'
                      disabled={has('Months', interval)}
                      onClick={() => update({ interval: { Months: 1 } })}>
                      Months
                    </SelectButton>
                    &nbsp;
                    <SelectButton
                      variant='contained'
                      disabled={has('Yearly', interval)}
                      onClick={() => update({ interval: { Yearly: [] } })}>
                      Yearly
                    </SelectButton>
                    <br />
                    <br />
                    {has('Days', interval) &&
                      <TextField
                        type='number'
                        label='Days'
                        style={{ marginRight: '30px' }}
                        value={propOr(0, 'Days', interval)}
                        helperText='Required field'
                        onChange={(e) => {
                          const v = Number(e.target.value);
                          if (v > 0) {
                            update({ interval: { ...interval, Days: v } });
                          }
                        }}
                      />}
                    {has('Months', interval) &&
                      <TextField
                        type='number'
                        label='Months'
                        style={{ marginRight: '30px' }}
                        value={propOr(0, 'Months', interval)}
                        helperText='Required field'
                        onChange={(e) => {
                          const v = Number(e.target.value);
                          if (v > 0) {
                            update({ interval: { ...interval, Months: v } });
                          }
                        }}
                      />}
                  </div>
                )],
                [equals('AfterXAppointments'), afterAppointments],
                [equals('AfterXAppointmentsRepeat'), afterAppointments],
              ])(trigger)}
              <br />
            </>}
        </div>
        <div className={classes.formControl}>
          <TextField
            value={message}
            label='Message'
            helperText='What is the message you want the office to see?'
            error={isEmpty(message)}
            onChange={(e) => update({ message: e.target.value })}
            style={{ width: '500px' }}
          />
        </div>
        <br />
        {trigger !== 'Immediately' &&
          <div className={classes.formControl}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(includeMessage)}
                  onChange={(e) => update({ includeMessage: e.target.checked })}
                  color="primary"
                />
              }
              label="Send Message to Client"
            />
            {includeMessage &&
              <div>
                {messageState.loading ?
                  <CircularProgress />
                  :
                  <MessageComposer
                    features={features}
                    isSMS={Boolean(pathOr(false, ['data', 'sms', 'body'], messageState))}
                    isEmail={Boolean(pathOr(false, ['data', 'email', 'subject'], messageState))}
                    isPush={Boolean(pathOr(false, ['data', 'push', 'body'], messageState))}
                    email={pathOr(defaultEmail, ['data', 'email'], messageState)}
                    sms={pathOr(defaultSms, ['data', 'sms'], messageState)}
                    push={pathOr(defaultPush, ['data', 'push'], messageState)}
                    actions={{
                      messageMassPatch: (data) => {
                        messageState.setState({ data: { ...messageState.data, ...data } });
                      },
                      messagesPatch: (prop, data) => {
                        messageState.setState({ data: { ...messageState.data, [prop]: data } });
                      }
                    }}
                    placeholders={placeholders}
                    setSMSError={setSMSError}
                    editorMenuIndex={1301}
                  />}
              </div>}
          </div>}
      </form>
    );
  } else {
    return (
      <Modal
        open={open}
        onClose={onClose}
        title={(id ? 'Edit ' : 'Add ') + 'Alert' + title + (group ? (' for ' + group.name) : '')}
        className={`sked-test-${id ? 'edit' : 'add'}-alert${isTemplate ? '-template' : ''}-modal`}
        buttons={[
          !isTemplate &&
          <HeaderButton
            borderSolid
            className={`sked-test-${id ? 'edit' : 'add'}-alert${isTemplate ? '-template' : ''}-modal-checkbox-enabled`}
          >
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={enabled}
                  onChange={(e) => update({ enabled: e.target.checked })}
                  color="primary"
                />
              }
              label="Enabled"
            />
          </HeaderButton>,
          <HeaderButton
            onClick={onClose}
            borderSolid title='Back'
            className={`sked-test-${id ? 'edit' : 'add'}-alert${isTemplate ? '-template' : ''}-modal-button-back`}
          />,
          <HeaderButton
            color='primary'
            disabled={error}
            onClick={onSave}
            title='Save'
            className={`sked-test-${id ? 'edit' : 'add'}-alert${isTemplate ? '-template' : ''}-modal-button-save`}
          />
        ]}
      >
        <div>
          <PopupTemplate />
          <form>
            <div className={classes.formControl}>
              <TextField
                select
                value={stopType}
                label='Alert Type'
                helperText='What type of alert is this?'
              >
                {stopTypes.map(stopTypesItem)}
              </TextField>
            </div>
            <div className={classes.formControl}>
              Trigger:
              <br />
              {!isTemplate && showImmediate &&
                <>
                  <SelectButton
                    variant='contained'
                    disabled={trigger === 'Immediately'}
                    onClick={() => update({ trigger: 'Immediately' })}>
                    Immediately
                  </SelectButton>
                  &nbsp;
                </>
              }
              <SelectButton
                variant='contained'
                disabled={trigger === 'OnDate'}
                onClick={() => update({ trigger: 'OnDate' })}>
                On Date
              </SelectButton>
              &nbsp;
              <SelectButton
                variant='contained'
                disabled={trigger === 'AfterDate' || trigger === 'AfterDateRepeat'}
                onClick={() => repeat ? update({ trigger: 'AfterDateRepeat' }) : update({ trigger: 'AfterDate' })}>
                After Date
              </SelectButton>
              &nbsp;
              <SelectButton
                variant='contained'
                disabled={trigger === 'AfterXAppointments' || trigger === 'AfterXAppointmentsRepeat'}
                onClick={() => repeat ? update({ trigger: 'AfterXAppointmentsRepeat' }) : update({ trigger: 'AfterXAppointments' })}>
                Upon Arrival
              </SelectButton>
              <br />
              <br />
              {createDescriptions(trigger)}
              {trigger !== 'Immediately' &&
                <>
                  <br />
                  <br />
                  {trigger !== 'OnDate' &&
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(repeat)}
                          onChange={handleCheck}
                          color="primary"
                        />
                      }
                      label="Repeat"
                    />}
                  <br />
                  <br />
                  {cond([
                    [equals('OnDate'), () => !isTemplate && (
                      <TextField
                        type='date'
                        inputProps={{
                          min: today
                        }}
                        label='Start Date'
                        value={isNil(date) || isEmpty(date) ? '' : format(date, 'yyyy-MM-dd')}
                        error={isNil(date) || isEmpty(date)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        helperText='Required field'
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value) {
                            update({ date: LocalDate.parse(value) });
                          } else {
                            update({ date: value });
                          }
                        }} />
                    )],
                    [equals('AfterDate'), () => !isTemplate && (
                      <TextField
                        type='date'
                        inputProps={{
                          min: today
                        }}
                        label='Start Date'
                        value={isNil(date) || isEmpty(date) ? '' : format(date, 'yyyy-MM-dd')}
                        error={isNil(date) || isEmpty(date)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        helperText='Required field'
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value) {
                            update({ date: LocalDate.parse(value) });
                          } else {
                            update({ date: value });
                          }
                        }} />
                    )],
                    [equals('AfterDateRepeat'), () => (
                      <div>
                        {!isTemplate &&
                          <TextField
                            type='date'
                            inputProps={{
                              min: today
                            }}
                            label='Start Date'
                            value={isNil(date) || isEmpty(date) ? '' : format(date, 'yyyy-MM-dd')}
                            error={isNil(date) || isEmpty(date)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            helperText='Required field'
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value) {
                                update({ date: LocalDate.parse(value) });
                              } else {
                                update({ date: value });
                              }
                            }} />}
                        <br />
                        {!isTemplate &&
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={Boolean(endDate)}
                                onChange={(e) => update({ endDate: e.target.checked ? now('date') : null })}
                                color="primary"
                              />
                            }
                            label="Include End Date?"
                          />}
                        {!isTemplate && !isNil(endDate) &&
                          <>
                            <br />
                            <TextField
                              type='date'
                              inputProps={{
                                min: today
                              }}
                              label='End Date'
                              value={isNil(endDate) || isEmpty(endDate) ? '' : format(endDate, 'yyyy-MM-dd')}
                              error={isNil(endDate) || isEmpty(endDate)}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              helperText='Required field'
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value) {
                                  update({ endDate: LocalDate.parse(value) });
                                } else {
                                  update({ endDate: value });
                                }
                              }} />
                          </>}
                        <br />
                        <br />
                        Interval:
                        <br />
                        <SelectButton
                          variant='contained'
                          disabled={has('Days', interval)}
                          onClick={() => update({ interval: { Days: 1 } })}>
                          Days
                        </SelectButton>
                        &nbsp;
                        <SelectButton
                          variant='contained'
                          disabled={has('Months', interval)}
                          onClick={() => update({ interval: { Months: 1 } })}>
                          Months
                        </SelectButton>
                        &nbsp;
                        <SelectButton
                          variant='contained'
                          disabled={has('Yearly', interval)}
                          onClick={() => update({ interval: { Yearly: [] } })}>
                          Yearly
                        </SelectButton>
                        <br />
                        <br />
                        {has('Days', interval) &&
                          <TextField
                            type='number'
                            label='Days'
                            style={{ marginRight: '30px' }}
                            value={propOr(0, 'Days', interval)}
                            helperText='Required field'
                            onChange={(e) => {
                              const v = Number(e.target.value);
                              if (v > 0) {
                                update({ interval: { ...interval, Days: v } });
                              }
                            }}
                          />}
                        {has('Months', interval) &&
                          <TextField
                            type='number'
                            label='Months'
                            style={{ marginRight: '30px' }}
                            value={propOr(0, 'Months', interval)}
                            helperText='Required field'
                            onChange={(e) => {
                              const v = Number(e.target.value);
                              if (v > 0) {
                                update({ interval: { ...interval, Months: v } });
                              }
                            }}
                          />}
                      </div>
                    )],
                    [equals('AfterXAppointments'), afterAppointments],
                    [equals('AfterXAppointmentsRepeat'), afterAppointments],
                  ])(trigger)}
                  <br />
                </>}
            </div>
            <div className={classes.formControl}>
              <TextField
                value={message}
                label='Message'
                helperText='What is the message you want the office to see?'
                error={isEmpty(message)}
                onChange={(e) => update({ message: e.target.value })}
                style={{ width: '500px' }}
              />
            </div>
            {trigger !== 'Immediately' &&
              <div className={classes.formControl}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(includeMessage)}
                      onChange={(e) => update({ includeMessage: e.target.checked })}
                      color="primary"
                    />
                  }
                  label="Send Message to Client"
                />
                {includeMessage &&
                  <div>
                    {messageState.loading ?
                      <CircularProgress />
                      :
                      <MessageComposer
                        features={features}
                        isSMS={Boolean(pathOr(false, ['data', 'sms', 'body'], messageState))}
                        isEmail={Boolean(pathOr(false, ['data', 'email', 'subject'], messageState))}
                        isPush={Boolean(pathOr(false, ['data', 'push', 'body'], messageState))}
                        email={pathOr(defaultEmail, ['data', 'email'], messageState)}
                        sms={pathOr(defaultSms, ['data', 'sms'], messageState)}
                        push={pathOr(defaultPush, ['data', 'push'], messageState)}
                        actions={{
                          messageMassPatch: (data) => {
                            messageState.setState({ data: { ...messageState.data, ...data } });
                          },
                          messagesPatch: (prop, data) => {
                            messageState.setState({ data: { ...messageState.data, [prop]: data } });
                          }
                        }}
                        placeholders={placeholders}
                        setSMSError={setSMSError}
                        editorMenuIndex={1301}
                      />}
                  </div>}
              </div>}
          </form>
        </div>
      </Modal>
    );
  }
};

export default CreateStopManager;
