import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 30,

    '& > h3': {
      margin: 0,
      padding: 0,
      fontWeight: 400,
      marginBottom: 15,
      height: 'auto',
    },
  },
  card: {
    padding: 20,
    width: 400,

    '& h3': {
      margin: 0,
      padding: 0,
      fontWeight: 400,
      marginBottom: 10,
    },
    '& p': {
      margin: 0,
    }
  },
  billing: {
    '& > h3': {
      marginBottom: 8,
    },
    '& > p': {
      marginBottom: 16,
    }
  },
}));
