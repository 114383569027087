import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: 20,
    margin: 20,
    minHeight: 200,
  },
  table: {
    '& .MuiButtonBase-root': {
      padding: 6,
    },
    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },
  firstColumn: {
    paddingLeft: 18,
    minWidth: 100,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,

    '& > h3': {
      margin: 0,
      padding: 0,
      height: 'auto',
    },
  },
  colName: {
    width: '30%',
  },
  smallColumn: {
    width: 130,
  },
  actions: {
    width: 100,
  },
  row: {
    display: 'flex',
    justifyContent: 'center'
  },
}));
