import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SearchClients } from '../../CalendarV2/components/scheduler.component.jsx';
import api from '../../../services/api.js';
import {
  merge, evolve, append,
  findIndex, remove, propEq,
  pipe, prop, split,
  tail, join, __, omit
} from 'ramda';
import { useTypeahead } from '../../../services/typeahead.hook.js';
import {
  LocalDate, DateTimeFormatter,
} from '@js-joda/core';
import { Locale } from '@js-joda/locale_en-us';
import { Client } from '../../Clients/clients.types';
import Modal from '../../../components/Modal/Modal.component';
import HeaderButton from '../../../components/HeaderButton/HeaderButton.component';

const format = DateTimeFormatter.ofPattern('M/d/yyyy').withLocale(Locale.US);

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
}));

interface SearchClientsDialogProps {
  isOpen: boolean;
  close: () => void;
  ehrSystem: string;
  loading: boolean;
  save: (clients: Client[]) => void;
}

export const SearchClientsDialog = ({
  isOpen,
  close,
  loading,
  save,
}: SearchClientsDialogProps) => {
  const classes = useStyles();
  const [_step, setStep] = useState('searchClients');
  const [clearSearch, setClearSearch] = useState(false);
  const [state, setState] = useState({
    nextAttempt: false,
    selectedClients: [],
  });
  const selectClient = (client: Client) => {
    setState(evolve({
      selectedClients: clients => {
        const index = findIndex(propEq('id', client.id), clients);
        return index >= 0 ? remove(index, 1, clients) : append(client, clients);
      }
    }));
  };
  const {
    state: clients,
    onChange,
    setState: setClients
  } = useTypeahead((query: any) => {
    // Wrap in a promise resolve
    // so that any errors from Joda are caught and
    // displayed by promise chain
    return Promise.resolve().then(() => {
      const birthday =
        query.birthday &&
          query.birthday.length >= 8 ?
          LocalDate.parse(query.birthday, format).toString() : undefined;
      const lastName = query.lastName.split(' ')[0];
      const firstName = pipe(
        prop('lastName'),
        split(' '),
        tail,
        join(' '),
        s => !s ? undefined : s
      )(query);
      const q = pipe(
        q => !q.phone ? omit(['phone'], q) : q,
        merge(__, {
          birthday,
          lastName,
          firstName
        })
      )(query);
      return api.post('client/query', {
        page: 1,
        perPage: 25,
        query: {
          ...q,
          sortBy: [{
            direction: 'Asc',
            field: 'LastName'
          }, {
            direction: 'Asc',
            field: 'FirstName'
          }],
          isLead: false
        }
      }).then(result => {
        // Need to wrap it in this promise resolve
        // so rxJs doesn't blow up
        return Promise.resolve(result);
      });
    });
  });

  return (
    <Modal
      open={isOpen}
      onClose={() => close()}
      title='Find Clients'
      className='sked-test-campaign-search-clients-modal'
      buttons={[
        loading && <CircularProgress size={18} style={{ marginRight: 10 }} />,
        <HeaderButton
          title='Save'
          color='primary'
          disabled={loading}
          onClick={() => save(state.selectedClients)}
          className='sked-test-campaign-search-clients-modal-button-save'
        />
      ]}
    >
      <div>
        <SearchClients
          ehrSystem={'Platinum'}
          setStep={setStep}
          classes={classes}
          selectClients={(clis: Client[]) => setState({
            ...state,
            selectedClients: clis
          })}
          selectClient={selectClient}
          onChange={onChange}
          setClients={setClients}
          clients={clients}
          shouldClearSearch={clearSearch}
          setClearSearch={setClearSearch}
          isInDialog
        />
      </div>
    </Modal>
  );
};
