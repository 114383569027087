import React, { useState } from 'react';
import { pipe, pick, dissoc } from 'ramda';
import {
  TextField,
  Button,
  FormControl,
  Paper,
  MenuItem,
  Typography,
  Select as MSelect,
  InputLabel,
  FormHelperText
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import Logo from '../../../images/logo-blue.png';
import SparkLogo from '../../../icons/SparkLogo.icon';
import { useStyles } from './style.js';

import {
  requiredValidation,
  updateValue,
  getValue,
  submitForm,
  initForm,
  touchFieldAndValidate,
  getFirstErrorOrDefault,
  validateField,
  getFormObject
} from '../../../services/form-validation.js';

import { countries, timezones } from '../../../services/globals.js';

const formObject = (isSpark) => {
  const f = {
    ehrSystem: {
      default: '',
      validation: [
        requiredValidation('Please select an EHR System.')
      ]
    },
    plan: {
      default: '',
      validation: [
        requiredValidation('Please select a plan.')
      ]
    },
    officeName: {
      default: '',
      validation: [
        requiredValidation('Please enter the name of your office.')
      ]
    },
    phone: {
      default: '',
      validation: [
        requiredValidation('Please enter your phone number'),
      ]
    },
    country: {
      default: '',
      validation: [
        requiredValidation('Please select the country of your practice.')
      ]
    },
    timezone: {
      default: '',
      validation: [
        requiredValidation('Please select the timezone your office uses.')
      ]
    },
    street: {
      default: '',
      validation: [
        requiredValidation('Please enter your street number and name.')
      ]
    },
    city: {
      default: '',
      validation: [
        requiredValidation('Please enter your city.')
      ]
    },
    state: {
      default: '',
      validation: [
        requiredValidation('Please enter your state or province.')
      ]
    },
    zipCode: {
      default: '',
      validation: [
        requiredValidation('Please enter your zip code.')
      ]
    },
  };
  return isSpark ? dissoc('plan', f) : f;
};


const FullTextField = withStyles({
  root: {
    width: '100%',
  },
})(TextField);

const ehrSystems = [
  'ChiroTouch',
  'Platinum',
  'Genesis',
  'ChiroHD',
  'None/Other',
];

const PLANS = [
  { name: 'SkedBasic', price: '$89', credits: '5,000', display: 'SKED Basic' },
  { name: 'SkedStandard', price: '$299', credits: '10,000', display: 'SKED Standard' },
  { name: 'SkedPro', price: '$379', credits: '15,000', display: 'SKED Pro' },
  { name: 'SkedBestest', price: '', credits: '20,000', display: 'Very Most Bestest' },
  { name: 'SkedAgency', price: '', credits: '', display: 'SKED Agency' },
  { name: 'SkedGenesisCash', price: '', credits: '', display: 'Genesis Cash' },
];

const useStepStyles = makeStyles(useStyles);

const updateCb = e => e.target.value;

export const Step1 = ({ finishStep1, step1Data, errorMessage, isPlatinumReach = false, isSpark = false }) => {
  const classes = useStepStyles();

  const [form, setForm] = useState(initForm(formObject(isSpark), step1Data));

  const update = (prop = '', cb = updateCb) => e => setForm(pipe(
    updateValue(prop, cb(e)),
    validateField(prop)
  ));

  const touchField = (prop) => () => setForm(touchFieldAndValidate(prop, form));

  const getCreditsText = (credits) => {
    return credits ? `includes ${credits} message credits` : '';
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    const validatedForm = submitForm(form);
    setForm(validatedForm);
    if (!validatedForm.__form.isValid) {
      console.log('form not valid');
      return;
    }
    const data = getFormObject(validatedForm);
    const submitData = {
      ...data,
      timezone: data.timezone,
      address: pick(['street', 'city', 'state', 'zipCode', 'country'], data)
    };
    finishStep1(submitData);
  };

  const plans = React.useMemo(() => {
    return PLANS.filter((plan) => {
      if (isPlatinumReach) {
        return plan.name === 'SkedPro';
      }
      if (form.ehrSystem?.value === 'Genesis') {
        return true;
      }
      return plan.name !== 'SkedGenesisCash';
    });
  }, [isPlatinumReach, form.ehrSystem?.value]);

  React.useEffect(() => {
    if (form.ehrSystem?.value !== 'Genesis' && form.plan?.value === 'SkedGenesisCash') {
      update('plan')({ target: { value: '' } });
    }
  }, [form.ehrSystem?.value, form.plan?.value]);
  
  React.useEffect(() => {
    setForm(initForm(formObject(isSpark), step1Data));
  }, [step1Data]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form onSubmit={handleOnSubmit}>
          <div className={classes.headerContainer}>
            {isSpark ? (
              <SparkLogo className={classes.sparkLogo} />
            ) : (
              <img height="30" src={Logo} />
            )}
          </div>
          <div className={classes.headerContainer}>
            <Typography align='center' variant='h4'>Registration</Typography>
          </div>
          {!isPlatinumReach && (
            <FormControl
              className={classes.formControl}
              error={!form.ehrSystem.isValid}
            >
              <InputLabel id="ehr-label">EHR System</InputLabel>
              <MSelect
                fullWidth
                labelId="ehr-label"
                id="ehr-input"
                value={getValue('ehrSystem', form)}
                onChange={update('ehrSystem')}
                onBlur={touchField('ehrSystem')}
              >
                {ehrSystems.map(s => (
                  <MenuItem key={s} value={s}>{s}</MenuItem>
                ))}
              </MSelect>
              <FormHelperText>{getFirstErrorOrDefault('ehrSystem', form)}</FormHelperText>
            </FormControl>
          )}
          {!isSpark && (
            <FormControl
              className={classes.formControl}
              error={!form.plan.isValid}
            >
              <InputLabel id="plan-label">Plan</InputLabel>
              <MSelect
                fullWidth
                labelId="plan-label"
                id="plan-input"
                value={getValue('plan', form)}
                onChange={update('plan')}
                onBlur={touchField('plan')}
              >
                {plans.map(({ name, display, credits }) => (
                  <MenuItem key={name} value={name}>
                    {`${display} ${getCreditsText(credits)}`}
                  </MenuItem>
                ))}
              </MSelect>
              <FormHelperText>{getFirstErrorOrDefault('plan', form)}</FormHelperText>
              <FormHelperText>See <a rel="noopener noreferrer" target="_blank" href="https://sked.life/sked-pricing">pricing page</a> for more details about each plan.</FormHelperText>
            </FormControl>
          )}

          <h5 className={classes.headerTitle}>Office Information</h5>

          <div className={classes.formControl}>
            <FullTextField
              fullWidth
              id="reg-office-name"
              label="Office Name"
              type="text"
              helperText={getFirstErrorOrDefault('officeName', form)}
              error={!form.officeName.isValid}
              value={getValue('officeName', form)}
              onChange={update('officeName')}
              onBlur={touchField('officeName')}
            />
          </div>

          <div className={classes.formControl}>
            <FullTextField
              fullWidth
              id="reg-phone"
              label="Office Phone Number"
              type="text"
              helperText={getFirstErrorOrDefault('phone', form)}
              error={!form.phone.isValid}
              onBlur={touchField('phone')}
              value={getValue('phone', form)}
              onChange={update('phone')}
            />
          </div>

          <div className={classes.formControl}>
            <FullTextField
              fullWidth
              id="reg-street"
              label="Street Address"
              type="text"
              helperText={getFirstErrorOrDefault('street', form)}
              error={!form.street.isValid}
              value={getValue('street', form)}
              onChange={update('street')}
              onBlur={touchField('street')}
            />
          </div>
          <div className={classes.formControl}>
            <FullTextField
              fullWidth
              id="reg-city"
              label="City"
              type="text"
              helperText={getFirstErrorOrDefault('city', form)}
              error={!form.city.isValid}
              value={getValue('city', form)}
              onChange={update('city')}
              onBlur={touchField('city')}
            />
          </div>
          <div className={classes.formControl}>
            <FullTextField
              fullWidth
              id="reg-state"
              label="State / Province"
              type="text"
              helperText={getFirstErrorOrDefault('state', form)}
              error={!form.state.isValid}
              value={getValue('state', form)}
              onChange={update('state')}
              onBlur={touchField('state')}
            />
          </div>
          <div className={classes.formControl}>
            <FullTextField
              fullWidth
              id="reg-zip"
              label="Zip"
              type="text"
              helperText={getFirstErrorOrDefault('zipCode', form)}
              error={!form.zipCode.isValid}
              value={getValue('zipCode', form)}
              onChange={update('zipCode')}
              onBlur={touchField('zipCode')}
            />
          </div>
          <FormControl
            className={classes.formControl}
            error={!form.country.isValid}
          >
            <InputLabel id="country-label">Country</InputLabel>
            <MSelect
              fullWidth
              labelId="country-label"
              id="country-input"
              value={getValue('country', form)}
              onChange={update('country')}
              onBlur={touchField('country')}
            >
              {countries.map(({ value, label }) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </MSelect>
            <FormHelperText>{getFirstErrorOrDefault('country', form)}</FormHelperText>
          </FormControl>

          <FormControl
            className={classes.formControl}
            error={!form.timezone.isValid}
          >
            <InputLabel id="timezone-label">Timezone</InputLabel>
            <MSelect
              fullWidth
              labelId="timezone-label"
              id="timezone-input"
              value={getValue('timezone', form)}
              onChange={update('timezone')}
              onBlur={touchField('timezone')}
            >
              {timezones
                .filter(({ country }) => country === getValue('country', form))
                .map(({ value, label }) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))}
            </MSelect>
            <FormHelperText>{getFirstErrorOrDefault('timezone', form)}</FormHelperText>
          </FormControl>

          <div className={classes.formControl} style={{ width: '100%', height: '40px' }}>
            <Button
              variant='contained'
              type='submit'
              disableElevation
              classes={{ label: classes.buttonLabel }}
              style={{ float: 'right' }}
              name='next'>
              Next
            </Button>
          </div>
          {!form.__form.isValid && form.__form.isTouched &&
            <FormControl
              className={classes.formControl}
              error={true}>
              <FormHelperText>The form is not filled out correctly.  Please scroll up to see which fields need corrected.</FormHelperText>
            </FormControl>}
          {errorMessage && <FormControl
            className={classes.formControl}
            error={true}>
            <FormHelperText>{errorMessage}</FormHelperText>
          </FormControl>}
        </form>
      </Paper>
    </div>
  );
};
