import React from 'react';
import Header from '../../components/PageHeader/PageHeader.component';
import { useTitle } from '../../services/useTitle';

const Help = () => {
  useTitle('Help');
  return (
    <>
      <Header title='Help' />
      <div style={{ margin: '20px' }}>
        <div>
          <p>Have questions about SKED? Please check out our <a href="https://skedlife.zendesk.com/hc/en-us/" target="_blank">help center</a> articles and tutorial videos.</p>
          <br />
          <p>Still have futher questions? Contact support.
            <br />
            Email: <a href='mailto:info@sked.life'>info@sked.life</a>
            <br />
            Phone: <a href='tel:+18155954459'>(815) 595-4459</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Help;
