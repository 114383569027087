import React from 'react';
import { Tooltip } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { tzParseFormat } from '../../../services/joda.js';
import ThumbsUpIcon from '@mui/icons-material/ThumbUp';
import { Client } from '../../Clients/clients.types';
import { Appointment } from '../../Appointments/appointments.types';

const ThumbsUpIconColored = withStyles({
  colorPrimary: {
    color: 'green',
  },
})(ThumbsUpIcon);

const useStyles = makeStyles(() => ({
  appointment: {
    position: 'absolute',
    backgroundColor: 'red',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '2px',
    overflow: 'hidden'
  },
  appointmentStatus: {
    position: 'absolute',
    backgroundColor: 'red',
    bottom: 2,
    right: 2,
    height: 5,
    width: 5,
    borderRadius: 2.5,
  },
  appointmentConfirm: {
    position: 'absolute',
    bottom: 1,
    right: 12,
  },
  appointmentTitle: {
    fontSize: '13px',
    lineHeight: '13px',
    marginLeft: '2px'
  },
  appointmentTime: {
    fontSize: '11px',
    marginLeft: '2px'
  }
}));

const abbr = (str = '') => {
  if (str && str.length > 0) {
    return str.toUpperCase()[0];
  }
  return '';
};

type DisplayClientProps = {
  client: Client;
  nameDisplay: string; 
}

const displayClient = ({ client, nameDisplay }: DisplayClientProps) => {
  switch (nameDisplay) {
    case 'abbr_f': {
      return abbr(client.firstName) + ' ' + (client.lastName || '');
    }
    case 'abbr_l': {
      return (client.firstName || '') + ' ' + abbr(client.lastName);
    }
    case 'abbr_fl': {
      return abbr(client.firstName) + ' ' + abbr(client.lastName);
    }
    case 'abbr_none': {
      return (client.firstName || '') + ' ' + (client.lastName || '');
    }
    default: {
      return abbr(client.firstName) + ' ' + (client.lastName || '');
    }
  }
};

interface IAppointment extends Appointment{
  draw: {
    x: number, y:
    number,
    h: number,
    color?: string
    statusColor?: string
  },
  timestamp: string;
} 

type AppointmentEntryProps = {
  a: IAppointment;
  startSlot?: number;
  handleDragStart: () => void;
  cellWidth: number;
  currentTimestamp: string;
  nameDisplay?: string;
  timezone: string;
}

export const AppointmentEntry = ({
  a, startSlot = 0, handleDragStart, cellWidth,
  currentTimestamp, nameDisplay = 'abbr_f', timezone,
}: AppointmentEntryProps) => {
  const classes = useStyles();
  const confirmed = a.confirmed && 'Confirmed: ' + tzParseFormat(a.confirmed, timezone, 'yyyy-MM-dd h:mm a');
  return (
    <div
      onMouseDown={handleDragStart}
      id={`apt-${a.id}`}
      className={classes.appointment}
      title={`(${a.appointmentType.name || 'unspecified'}) ${a.client.firstName} ${a.client.lastName} ${a.appointmentType.duration}m`}
      style={{
        top: a.draw.y,
        left: a.draw.x + cellWidth * startSlot,
        height: a.draw.h,
        width: (cellWidth - 3) + 'px',
        backgroundColor: a.draw.color,
        color: (a.appointmentType.color && a.appointmentType.color.lightness < 45) ? '#EEE' : '#111'
      }}>
      <div className={classes.appointmentTitle}>
        {displayClient({ client: a.client, nameDisplay })}
      </div>
      <div className={classes.appointmentTime}>
        {a.appointmentType.name || 'unspecified'}
      </div>
      <div
        className={classes.appointmentStatus}
        style={{
          display: a.status.Scheduled && a.timestamp > currentTimestamp ? 'none' : 'block',
          backgroundColor: a.draw.statusColor,
          boxShadow: (a.appointmentType.color && a.appointmentType.color.lightness < 45 && 'rgba(255, 255, 255, 0.5) 0px 0px 1px 1px') || 'rgba(0,0,0, 0.5) 0px 0px 1px 1px'
        }}>
      </div>
      <Tooltip arrow placement="top" title={confirmed}>
        <ThumbsUpIconColored
          className={classes.appointmentConfirm}
          style={{
            display: a.confirmed ? 'block' : 'none',
            fontSize: '8px',
            color: (a.appointmentType.color && a.appointmentType.color.lightness < 45 ? 'white' : 'black'),
            right: a.status.Scheduled && a.timestamp > currentTimestamp ? 2 : 12,
          }}
        /* sx={{ fontSize: 10 }} */
        />
      </Tooltip>
    </div>
  );
};
