import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginRight: 10,
    height: '100%',
  },
  container: {
    paddingBottom: 10,
    padding: 0,
  },
  content: {
    paddingRight: 10,
    paddingLeft: 10,
    paddingBottom: 10,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  scrollContent: {
    padding: 10,
    borderRadius: 10,
    overflowY: 'scroll',

    '&::-webkit-scrollbar': {
      width: '5px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(206, 206, 206)',
      borderRadius: ' 8px',

      '&:hover': {
        backgroundColor: 'gray',
        borderRadius: '8px',
      },
    },
  },
  roomContainer: {
    display: 'flex',
    marginBottom: 10,
    fontSize: 14,

    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'space-between',

    [theme.breakpoints.down('md')]: {
      fontSize: 12,
    },
  },
}));
