import React, { ComponentProps } from 'react';

const PaperClipIcon = ({ color, className, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} {...props} width="9.44" height="18.932" viewBox="0 0 9.44 18.932">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_7346" data-name="Rectangle 7346" width="9.44" height="18.932" transform="translate(0 0)" fill={color || '#4d4d4d'}/>
        </clipPath>
      </defs>
      <g id="Group_8439" data-name="Group 8439" transform="translate(0 0)">
        <g id="Group_8340" data-name="Group 8340" transform="translate(0 0)" clipPath="url(#clip-path)">
          <path id="Path_6292" data-name="Path 6292" d="M.034,10.077c0-1.837-.044-3.675.01-5.51A4.664,4.664,0,0,1,6.9.564,4.372,4.372,0,0,1,9.4,4.52c.065,3.34.03,6.682.036,10.024a2.337,2.337,0,0,1-.015.259.71.71,0,0,1-.739.666.66.66,0,0,1-.655-.659c-.039-.516-.066-1.034-.068-1.551-.007-2.269,0-4.537,0-6.806,0-.64.038-1.282,0-1.92A3.274,3.274,0,0,0,5.641,1.6a3.19,3.19,0,0,0-3.433,1.05,3.327,3.327,0,0,0-.77,2.288q0,5.1.014,10.21a2.13,2.13,0,0,0,.914,2.013,1.959,1.959,0,0,0,2.054.077,1.869,1.869,0,0,0,1.033-1.711c.022-.85.007-1.7.006-2.552q0-3.218-.01-6.437a2.507,2.507,0,0,0-.058-.586.761.761,0,0,0-.8-.561.681.681,0,0,0-.593.692c-.018.283-.013.567-.013.85q.007,3.329.013,6.659a3.625,3.625,0,0,1-.066.769.636.636,0,0,1-.7.524.639.639,0,0,1-.631-.563,3.444,3.444,0,0,1-.056-.662q0-3.57.01-7.139a2.546,2.546,0,0,1,.565-1.825,2.037,2.037,0,0,1,2.214-.683,2.134,2.134,0,0,1,1.51,1.822,4.671,4.671,0,0,1,.05.774q.011,4.273.015,8.545a3.522,3.522,0,0,1-3.236,3.757A3.462,3.462,0,0,1,.2,16.454a7,7,0,0,1-.181-1.606c-.029-.8-.008-1.6-.008-2.4s0-1.578,0-2.367H.034" transform="translate(0 0)" fill={color || '#4d4d4d'}/>
        </g>
      </g>
    </svg>
  );
};

export default PaperClipIcon;
