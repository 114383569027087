export const useStyles = () => ({
  root: {
    display: 'flex',
    maxWidth: '520px',
    margin: '0px auto',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%'
  },
  paper: {
    width: '100%',
    padding: 40,
    paddingBottom: 0,
  },
  formControl: {
    display: 'block',
    marginBottom: 25,
    width: '100%'
  },
  marginTop: {
    marginTop: 25,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '15px'
  },
  buttonLabel: {
    textTransform: 'none',
    fontWeight: 'normal',
  },
  selectRoot: {
    width: '100%'
  },
  hr: {
    marginTop: '-15px',
    backgroundColor: 'rgba(0,0,0,0.42)'
  },
  headerTitle: {
    padding: 0,
    fontSize: 22,
    fontWeight: 300,
  },
  sparkLogo: {
    width: 150,
    height: 46,
    marginBottom: -10,
  }
});

