import React, { useState, useEffect } from 'react';
import {
  Button,
  TableHead,
  TableBody,
  Checkbox,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  FormControlLabel,
  Grid,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import FileIcon from '@mui/icons-material/Assignment';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  isEmpty, ifElse, always,
  pipe, pathOr, cond,
  has, T, append, without,
  equals, map, addIndex,
  adjust, evolve, assoc,
  concat, merge, filter, assocPath,
  keys, propEq, not,
} from 'ramda';
import api from '../../../../../services/api.js';
import {
  tzParseFormat,
  format,
  now,
} from '../../../../../services/joda.js';
import { usePromise } from '../../../../../services/promise.hook';
import CreateStopManager from '../../../../StopManagers/components/create-stop-manager.component.jsx';
import { GroupSection } from '../../../../StopManagers/stop-managers.page.jsx';
import { stopsToPromise } from '../../../../StopManagers/stop-managers.actions.jsx';
import { TableRow, TableContainer, HeaderCell, BodyCell } from '../../../../../components/CustomTable';
import StopIcon from '../../../../../icons/Stop.icon';
import TaskIcon from '../../../../../icons/Warning.icon';

const fixLength = (str) => {
  if (str && str.length > 50) {
    return str.slice(0, 51) + '...';
  }
  return str;
};

const prettyTriggerNames = cond([
  [has('OnDate'), always('On Date')],
  [has('AfterDate'), always('After Date')],
  [has('AfterDateRepeat'), always('Repeat Date')],
  [has('AfterXAppointments'), always('Upon Arrival')],
  [has('AfterXAppointmentsRepeat'), always('Repeat Arrival')],
  [T, always('Unknown')],
]);

const getManagers = ({ clientId, enabled = true, triggered = false }) => api.post('stopmanager/query', {
  page: 1,
  perPage: 50,
  query: {
    clientId,
    enabled,
  }
}).then((stops) => {
  return evolve({
    data: filter(({ trigger, timesTriggered }) => {
      const onlyOne = keys(trigger)[0].indexOf('Repeat') === -1;
      if (triggered) {
        return timesTriggered >= 1;
      } else if (onlyOne) {
        return timesTriggered !== 1;
      } else {
        return true;
      }
    }),
  }, stops);
});
const deleteManagers = (stops) => Promise.all(stops.map(({ id }) => {
  return api.delete(`stopmanager/${id}`, { data: {} });
}));
const saveStop = (stops) => api.post('stopmanager', stops);
const updateStop = (stop) => api.put(`stopmanager/${stop.id}`, stop);
const copyStopManager = (stops) => {
  const stopsPromises = stops.map(stopsToPromise({ groupdId: undefined }));
  return Promise.all(stopsPromises).then((stopies) => {
    return api.post('stopmanager', stopies);
  });
};

const RowContainer = ({
  stopManager,
  select,
  tz,
  deleteStop,
  copyStop,
  editStop,
}) => {
  const {
    message,
    id,
    trigger,
    isSelected,
    enabled,
    created,
    stopType,
    timesTriggered,
  } = stopManager;
  const [selected, setSelected] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  const onlyOne = keys(trigger)[0].indexOf('Repeat') === -1;
  const displayEdit = onlyOne && timesTriggered >= 1;

  return (
    <TableRow
      key={id}
      hasSelect
      checked={selected}
      setChecked={(checked) => {
        setSelected(checked);
        select(checked);
      }}
    >
      <BodyCell style={{
        textAlign: 'center',
        verticalAlign: 'middle',
      }}>
        {stopType === 'Task' ? <TaskIcon size='almost-small' /> : <StopIcon size='almost-small' />}
      </BodyCell>
      <BodyCell>
        {fixLength(message)}
      </BodyCell>
      <BodyCell>
        <Checkbox
          defaultChecked={enabled}
          disabled
        />
      </BodyCell>
      <BodyCell>
        {prettyTriggerNames(trigger)}
      </BodyCell>
      <BodyCell>
        {tzParseFormat(created, tz, 'MM/dd/yyyy h:mm a')}
      </BodyCell>
      <BodyCell>
        <IconButton
          size='small'
          onClick={(e) => setAnchorEl(e.currentTarget)}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          id="long-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}>
          {!displayEdit &&
            <MenuItem onClick={() => {
              setAnchorEl(null);
              editStop(stopManager);
            }}>
              <ListItemIcon>
                <EditIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary='Edit Alert' />
            </MenuItem>}
          <MenuItem onClick={() => {
            setAnchorEl(null);
            copyStop(stopManager);
          }}>
            <ListItemIcon>
              <FileCopyIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary='Copy Alert' />
          </MenuItem>
          <MenuItem onClick={() => {
            deleteStop([stopManager]);
            setAnchorEl(null);
          }}>
            <ListItemIcon>
              <DeleteIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary='Delete Alert' />
          </MenuItem>
        </Menu>
      </BodyCell>
    </TableRow>
  );
};

const getTemplates = () => api.post('stopmanager/template/group/query/', {
  page: 1,
  perPage: 25,
  query: {},
}).then((groups) => {
  const today = format(now('date'), 'yyyy-MM-dd');
  return Promise.all(
    groups.data.map((group) =>
      api.post('stopmanager/template/query/', {
        page: 1,
        perPage: 50,
        query: {
          groupId: group.groupId,
        },
      }).then((stops) => {
        return ({
          ...group,
          stops: map(
            pipe(
              assoc('enabled', true),
              evolve({
                trigger: cond([
                  [has('OnDate'), assoc('OnDate', today)],
                  [has('AfterDate'), assoc('AfterDate', today)],
                  [has('AfterDateRepeat'), assoc('AfterDateRepeat', today)],
                  [has('AfterXAppointments'), assocPath(['AfterXAppointments', 'startDate'], today)],
                  [has('AfterXAppointmentsRepeat'), assocPath(['AfterXAppointmentsRepeat', 'startDate'], today)],
                  [T, assoc('AfterDate', today)],
                ])
              })
            )
          )(stops.data),
        });
      }))
  ).then((stops) => {
    return ({
      ...groups,
      data: stops,
    });
  });
});

const mapIndexed = addIndex(map);

export const TemplatesTable = ({
  tz,
  client,
  onApply,
  cancel,
  applyTemplateState,
  isEmbedded = false,
}) => {
  const [editing, setEditing] = useState(false);
  const [selectedStopManager, setSelectedStopManager] = useState({});
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const templateState = usePromise(getTemplates, []);

  useEffect(() => {
    templateState.invoke();
  }, []);

  const onSaveTemplate = (s) => {
    setEditing(false);
    if (!isEmpty(selectedStopManager)) {
      templateState.setState({
        data: {
          ...templateState.data,
          data: adjust(selectedStopManager.groupIndex, evolve({
            stops: map((stop) => {
              if (s.id === stop.id) {
                const newStop = pipe(
                  assoc('selected', true),
                  merge(stop)
                )(s);
                setSelectedTemplates(pipe(filter(({ id }) => id !== s.id), append(newStop))(selectedTemplates));
                return newStop;
              } else {
                return stop;
              }
            }),
          }), templateState.data.data),
        }
      });
    }
  };

  if (isEmbedded) {
    useEffect(() => {
      onApply(selectedTemplates);
    }, [selectedTemplates]);
  }

  const templateTable = () => (
    <div style={{ margin: '20px' }}>
      <CreateStopManager
        open={editing}
        onClose={() => setEditing(false)}
        isTemplate={false}
        stopManager={selectedStopManager.stopManager}
        clientId={client && client.id}
        save={onSaveTemplate}
        keepMsg={false}
      />
      {!isEmbedded &&
        <div style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}>
          {/* <h2> */}
          {/*   Stop Managers Templates */}
          {/* </h2> */}
          <div style={{
            width: '200px',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}>
            {!isEmpty(selectedTemplates) &&
              <Button
                variant='contained'
                onClick={() => onApply(selectedTemplates)}
                disabled={applyTemplateState.loading}
              >
                {applyTemplateState.loading ? 'Loading...' : 'Apply'}</Button>}
            <Button
              onClick={cancel}
            >
              Cancel
            </Button>
          </div>
        </div>}
      <div>
        Select the templates that you wish to apply to this client. If you need to input more information, you will see a warning on that template.
        {pipe(
          pathOr([], ['data', 'data']),
          mapIndexed((group, idx) => (
            <GroupSection
              key={idx}
              group={group}
              tz={tz}
              busy={templateState.loading}
              isTemplate={false}
              patch={({ state, selectedStopManager }) => {
                setSelectedStopManager({
                  stopManager: selectedStopManager,
                  groupIndex: idx,
                });
                setEditing(state === 'EDIT');
              }}
              selectStopPatch={(i, selected) => {
                const stop = pathOr([], ['data', 'data', idx, 'stops', i], templateState);
                if (selected) {
                  setSelectedTemplates(append(stop, selectedTemplates));
                } else {
                  setSelectedTemplates(without([stop], selectedTemplates));
                }
                templateState.setState({
                  data: {
                    ...templateState.data,
                    data: adjust(idx, evolve({
                      stops: adjust(i, assoc('selected', selected)),
                    }), templateState.data.data),
                  }
                });
              }}
              selectAllStops={(selected) => {
                const stops = pathOr([], ['data', 'data', idx, 'stops'], templateState);
                if (selected) {
                  setSelectedTemplates(concat(stops, selectedTemplates));
                } else {
                  setSelectedTemplates(without(stops, selectedTemplates));
                }
                templateState.setState({
                  data: {
                    ...templateState.data,
                    data: adjust(idx, evolve({
                      stops: map(assoc('selected', selected)),
                    }), templateState.data.data),
                  }
                });
              }}
            />
          ))
        )(templateState)}
      </div>
    </div>
  );

  if (templateState.loading) {
    return (<div className="loader" />);
  } else {
    return pipe(
      pathOr([], ['data', 'data']),
      ifElse(
        isEmpty,
        always(
          <div style={{ marginTop: '10px' }}>
            {!isEmbedded &&
              <div style={{
                width: '100%',
              }}>
                <Button style={{ float: 'right' }} onClick={cancel}>
                  Cancel
                </Button>
              </div>}
            <br />
            <div style={{ textAlign: 'center' }}>
              There are no available templates!
            </div>
          </div>
        ),
        always(templateTable()),
      ))(templateState);
  }
};

const StopManagers = ({
  client,
  tz,
}) => {
  const [enabled, setEnabled] = useState(true);
  const [triggered, setTriggered] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [editing, setEditing] = useState(false);
  const [selectedStopManager, setSelectedStopManager] = useState({});
  const [state, setState] = useState('managers');
  const stopManagerState = usePromise(getManagers, {});
  const deleteStopsState = usePromise(deleteManagers, []);
  const saveStopState = usePromise(saveStop, []);
  const copyStopState = usePromise(copyStopManager, []);
  const applyTemplateState = usePromise(saveStop, []);
  const updateStopState = usePromise(updateStop, {});

  const queryData = {
    clientId: client.id,
    enabled,
    triggered,
  };

  useEffect(() => {
    if (state !== 'templates') {
      stopManagerState.invoke(queryData);
    }
  }, [state, enabled, triggered]);

  const onApply = (selectedTemplates) => {
    const stops = selectedTemplates.map((temp) => {
      return ({
        ...temp,
        groupId: undefined,
        clientId: client.id,
      });
    });
    applyTemplateState.invoke(stops).then(() => {
      stopManagerState.invoke(queryData).then(() => {
        setState('managers');
      });
    });
  };

  const onDeleteStop = (stops) => {
    deleteStopsState.invoke(stops).then(() => {
      stopManagerState.invoke(queryData);
    });
  };

  const onSelectAll = (e) => {
    const checked = e.target.checked;
    setSelectAll(checked);
    if (checked) {
      setSelected(stopManagerState.data.data);
      stopManagerState.setState({
        data: {
          ...stopManagerState.data,
          data: stopManagerState.data.data.map((s) => {
            return ({
              ...s,
              isSelected: true,
            });
          }),
        },
      });
    } else {
      setSelected([]);
      stopManagerState.setState({
        data: {
          ...stopManagerState.data,
          data: stopManagerState.data.data.map((s) => {
            return ({
              ...s,
              isSelected: false,
            });
          }),
        },
      });
    }
  };

  const onCopyStop = (stop) => {
    copyStopState.invoke([stop]).then(() => {
      stopManagerState.invoke(queryData);
    });
  };

  const table = () => {
    return (
      <TableContainer style={{ width: '100%' }}>
        <TableHead>
          <TableRow
            hasSelect
            noSelectHover
            checked={selectAll}
            setChecked={(value) => onSelectAll({ target: { checked: value } })}
          >
            <HeaderCell style={{ position: 'relative' }}>
              {!isEmpty(selected) && (
                <Grid position="absolute" display="flex" alignItems="center" width="80%" top={0} left={8} style={{ background: '#F5F5F5' }}>
                  <IconButton
                    size='small'
                    onClick={() => onDeleteStop(selected)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              )}
              Type
              {deleteStopsState.loading && <CircularProgress size={18} style={{ marginLeft: 5 }} />}
            </HeaderCell>
            <HeaderCell>
              Message
            </HeaderCell>
            <HeaderCell>
              Enabled
            </HeaderCell>
            <HeaderCell>
              Trigger
            </HeaderCell>
            <HeaderCell>
              Created
            </HeaderCell>
            <HeaderCell>
            </HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pathOr([], ['data', 'data'], stopManagerState).map((stop, idx) => (
            <RowContainer
              key={idx}
              tz={tz}
              stopManager={stop}
              select={(isSelected) => {
                if (isSelected) {
                  setSelected(append(stop, selected));
                } else {
                  setSelected(filter(pipe(propEq('id', stop.id), not), selected));
                }
                setSelectAll(false);
              }}
              deleteStop={onDeleteStop}
              copyStop={onCopyStop}
              editStop={(s) => {
                setSelectedStopManager(s);
                setEditing(true);
              }}
            />
          ))}
        </TableBody>
      </TableContainer>
    );
  };

  const onSaveManager = (s) => {
    if (s.id) {
      updateStopState.invoke(s).then(() => {
        setEditing(false);
        stopManagerState.invoke(queryData);
      });
    } else {
      saveStopState.invoke([s]).then(() => {
        setEditing(false);
        stopManagerState.invoke(queryData);
      });
    }
  };

  const templates = () => (
    <TemplatesTable
      tz={tz}
      onApply={onApply}
      cancel={() => setState('managers')}
      client={client}
      applyTemplateState={applyTemplateState}
      isEmbedded={false}
    />
  );

  const managers = () => (
    <div style={{ margin: '20px' }}>
      <CreateStopManager
        open={editing}
        onClose={() => setEditing(false)}
        isTemplate={false}
        stopManager={selectedStopManager}
        clientId={client.id}
        save={onSaveManager}
      />
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={enabled}
                onChange={(e) => {
                  setEnabled(e.target.checked);
                  onSelectAll({ target: { value: false } });
                }}
                name="acknowledge-checkbox"
                color="primary"
              />
            }
            label="Show enabled"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={triggered}
                onChange={(e) => {
                  setTriggered(e.target.checked);
                  onSelectAll({ target: { value: false } });
                }}
                name="acknowledge-checkbox"
                color="primary"
              />
            }
            label="Show Triggered"
          />
        </div>
        {/* <h2> */}
        {/*   Stop Managers */}
        {/* </h2> */}
        <Button
          variant='contained'
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          New alert
        </Button>
        <Menu
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          id="long-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={() => {
            setEditing(true);
            setSelectedStopManager({});
            setAnchorEl(null);
          }}>
            <ListItemIcon>
              <AddIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary='Add new alert' />
          </MenuItem>
          <MenuItem onClick={() => {
            setAnchorEl(null);
            setState('templates');
          }}>
            <ListItemIcon>
              <FileIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary='Select Templates' />
          </MenuItem>
        </Menu>
      </div>
      <div>
        {stopManagerState.loading && <div className="loader" />}
        {!stopManagerState.loading &&
          pipe(
            pathOr([], ['data', 'data']),
            ifElse(
              isEmpty,
              always(<div>This client has no alerts!</div>),
              always(table())
            ))(stopManagerState)}
      </div>
    </div>
  );

  return (
    <div style={{ width: '100%' }}>
      {cond([
        [equals('managers'), managers],
        [equals('templates'), templates],
        [T, always(null)]
      ])(state)}
    </div>
  );
};

export default StopManagers;
