import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  CircularProgress, IconButton,
  Checkbox, TableHead, TableBody, Tooltip, Menu,
  MenuItem, ListItemText,
  ListItemIcon, Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import GearIcon from '@mui/icons-material/Settings';
import { PopupTemplate, popupWithCancel } from '../../services/Popup.js';
import {
  includes, pathOr, map, prop, isEmpty, append,
  without, find, equals, path, cond, has, always,
  identity, prepend, remove, pipe, propOr, T, update,
  filter, not, any,
} from 'ramda';
import { PageHeader, Row } from '../../components/PageHeader';
import { useTitle } from '../../services/useTitle';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import LogoutIcon from '@mui/icons-material/Logout';
import * as actions from './clientlists.actions';
import {
  tzParseFormat,
  localParseFormat,
  format,
} from '../../services/joda.js';
import {
  LocalDate,
} from '@js-joda/core';
import { usePromise } from '../../services/promise.hook';
import { FeatureDisabled } from '../../components/feature-disabled.component';
import { SearchClientsDialog } from './components/search-clients-dialog.component';
import { AutomationsList, AutomationDialog, triggers } from '../Automations/automations.page';
import { automationsPatch } from '../Automations/automations.actions';
import AutomationTemplateDialog from '../Templates/automation-template-dialog.component';
import { RECURRING_EDIT_RESET } from '../../actionTypes';
import { copyMessage } from '../RecurringMessage/recurring.actions.jsx';
import { Client } from '../Clients/clients.types';
import { RowContainerProps, ClientWithList, ClientListHistory } from './clientlist-types';
import { Automation, AutomationsState } from '../Automations/automation-types.js';
import { messagesPatch, reset } from '../Templates/routes/TemplatesEdit/templates-edit.actions';
import ClientEditDialog from '../Clients/components/client-dialog/client-dialog.component.jsx';
import { backToClient, getCurrentClient } from '../Clients/components/client-dialog/client-dialog.actions.jsx';
import Header from '../../components/PageHeader/PageHeader.component';
import Loading from '../../components/Loading/Loading.component';
import Container from '../../components/Container/Container.component';
import { TableContainer, TableRow, HeaderCell, BodyCell } from '../../components/CustomTable';
import Pagination from '../../components/Pagination/Pagination.component';
import HeaderTabs from '../../components/HeaderTabs/HeaderTabs.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import FilterText from '../../components/FilterText/FilterText.component';
import FilterSelect from '../../components/FilterSelect/FilterSelect.component';
import FilterStartEndDate from '../../components/FilterStartEndDate/FilterStartEndDate.component';
import TriggerIcon from '../../icons/Trigger.icon';
import EnabledIcon from '../../icons/Enabled.icon';
import { useSelector } from '../../reducers';
import SparkSettings from './components/SparkSettings.component';
import { patch as loginPatch } from '../Login/login.actions';
import MessageTemplateDialog from './components/MessageTemplateDialog.component';

const enabledOptions = [
  { value: null, name: 'Any' },
  { value: true, name: 'Only Enabled' },
  { value: false, name: 'Only Disabled' },
];

const useStyles = makeStyles(() => ({
  headerRow: {
    marginBottom: '10px',
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tabBodyContainer: {
    marginTop: '10px',
  },
  iconSpan: {
    marginRight: '5px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  errorMessage: {
    color: 'red',
    margin: 'unset',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  noHorizontalMargins: {
    marginLeft: -20,
    marginRight: -20,
  },
  checkbox: {
    paddingLeft: 4,
    marginRight: 0,

    '& .MuiCheckbox-root': {
      padding: 2,
      paddingTop: 5,
      paddingBottom: 5,
      marginRight: 4,
    }
  },
}));

const parseBirthday = (birthday: string) => {
  try {
    return format(LocalDate.parse(birthday), 'MM/dd/yyyy');
  } catch (e) {
    return '';
  }
};

const RowContainer = ({
  clientWithList,
  tz,
  select,
  isSelected,
  onDelete,
  onOpt,
  history,
}: RowContainerProps) => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(isSelected);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  const {
    client,
    created,
    optout,
  } = clientWithList;

  const {
    firstName,
    lastName,
    birthday,
    phone,
    email,
    id,
  } = client;

  return (
    <TableRow
      key={id}
      hasSelect
      checked={Boolean(selected)}
      setChecked={(value) => {
        setSelected(value);
        select(value);
      }}
    >
      <BodyCell>
        <ClientEditDialog
          open={open}
          from={'CAMPAIGNS'}
          history={history}
          onClose={() => setOpen(false)}
          back={() => dispatch(backToClient())}
          gotoClient={() => dispatch(backToClient())}
        />
        <a
          href="javascript:void()"
          onClick={() => {
            setOpen(true);
            dispatch(getCurrentClient(client));
          }}>
          {firstName} {lastName}
        </a>
      </BodyCell>
      <BodyCell>
        {parseBirthday(birthday)}
      </BodyCell>
      <BodyCell>
        {phone}
      </BodyCell>
      <BodyCell>
        {email}
      </BodyCell>
      <BodyCell>
        {tzParseFormat(created, tz, 'MM/dd/yyyy h:mm a')}
      </BodyCell>
      <BodyCell>
        {optout && tzParseFormat(optout, tz, 'MM/dd/yyyy h:mm a')}
      </BodyCell>
      <BodyCell>
        <Tooltip arrow title='Remove Client' placement='top'>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title='Opt-Out Client' placement='top'>
          <IconButton onClick={onOpt}>
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </BodyCell>
    </TableRow>
  );
};

interface MiniAuto {
  id: number;
  name: string;
}

interface MessageRowContainerProps {
  message: any;
  tz: string;
  select: (s: boolean) => void;
  isSelected: boolean;
  onDelete: () => void;
  openAuto: (c: MiniAuto) => void;
  onCopy: () => void;
  template?: boolean;
  campaignId: number;
}

interface TypeUrl {
  type: JSX.Element | string;
  url: string;
}

interface ClientListHistoryRowContainerProps {
  change: ClientListHistory;
  tz: string;
}

const ClientListHistoryRowContainer = ({
  change,
  tz,
}: ClientListHistoryRowContainerProps) => {
  const {
    client,
  } = change;
  const clientName = `${client.firstName} ${client.lastName}`;
  return (
    <TableRow>
      <BodyCell>
        {clientName}
      </BodyCell>
      <BodyCell>
        {change.change}
      </BodyCell>
      <BodyCell>
        {change.source}
      </BodyCell>
      <BodyCell>
        {change.afterTotal}
      </BodyCell>
      <BodyCell>
        {localParseFormat(change.created, tz, 'MM/dd/yyyy h:mm a')}
      </BodyCell>
    </TableRow>
  );
};

const MessageRowContainer = ({
  message,
  tz,
  select,
  isSelected,
  onDelete,
  openAuto,
  onCopy,
  template,
  campaignId,
}: MessageRowContainerProps) => {
  const classes = useStyles({
    numberOfTabs: null,
  });
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(isSelected);

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  const {
    messageType,
    createdAt,
    email,
    sms,
    push,
    name,
    id,
    isEnabled,
  } = message;

  const { type, url } = cond([
    [has<string>('Automation'), (): TypeUrl => ({
      type: (
        <a
          onClick={() => {
            openAuto({ id, name });
          }}>
          Automations
        </a>),
      url: template ? `/template/edit/${id}?campaignId=${campaignId}` : `/automation/view/${id}`
    }
    )],
    [has('OneTime'), () => ({
      type: 'One Time',
      url: `/messages/view/${id}`,
    })],
    [has('Recall'), () => ({
      type: 'Reactivation',
      url: `/reactivation/view/${id}`,
    })],
    [has('Birthday'), () => ({
      type: 'Birthday',
      url: `/birthday/view/${id}`,
    })],
    [has('Recurring'), () => ({
      type: 'Reminder',
      url: `/recurring/view/${id}`,
    })],
    [has('ApptChange'), () => ({
      type: 'Rapid',
      url: `/apptchange/view/${id}`,
    })],
  ])(messageType);
  const nameClick = () => {
    dispatch(messagesPatch('templateType', 'Automation'));
  };
  return (
    <TableRow
      key={id}
      hasSelect
      checked={Boolean(selected)}
      setChecked={(value) => {
        setSelected(value);
        select(value);
      }}
    >
      <BodyCell>
        <Link to={url} onClick={nameClick}>
          {name}
        </Link>
        &nbsp;
        <>
          ({type})
        </>
      </BodyCell>
      <BodyCell>
        <Checkbox
          checked={isEnabled}
          disabled
        />
      </BodyCell>
      <BodyCell>
        <span style={{ display: 'flex', width: '100%', height: '100%' }}>
          {sms &&
            <span className={classes.iconSpan}>
              <ChatBubbleIcon style={{ fontSize: '20px' }} />
            </span>}
          {email &&
            <span className={classes.iconSpan}>
              <EmailIcon style={{ fontSize: '20px' }} />
            </span>}
          {push &&
            <span className={classes.iconSpan}>
              <SmartphoneIcon style={{ fontSize: '20px' }} />
            </span>}
        </span>
      </BodyCell>
      <BodyCell>
        {tzParseFormat(createdAt, tz, 'MM/dd/yyyy h:mm a')}
      </BodyCell>
      <BodyCell>
        <Tooltip arrow title='Copy Message' placement='top'>
          <IconButton onClick={onCopy}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title='Delete Message' placement='top'>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </BodyCell>
    </TableRow>
  );
};

interface CampaignProps {
  history: any;
}

interface SearchClients {
  id?: number;
  perPage?: number;
  page?: number;
}

const defaultQuery = {
  data: [] as any[],
  totalPages: 1,
  totalCount: 0,
  page: 1,
  perPage: 25,
};
let clientTimeout: NodeJS.Timeout = null;
let messageTimeout: NodeJS.Timeout = null;
let historyTimeout: NodeJS.Timeout = null;
const SparkCampaign = ({ history }: CampaignProps) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [messageQuery, setMessageQuery] = useState({
    name: '',
  });
  const [automationsQuery, setAutomationsQuery] = useState({
    enabled: null,
    trigger: '',
    hidden: undefined,
  });
  const today = LocalDate.now();
  const [historyQuery, setHistoryQuery] = useState<actions.Between>({
    start: format(today.minusMonths(1), 'yyyy-MM-dd'),
    end: format(today, 'yyyy-MM-dd'),
  });
  const [topHeight, setTopHeight] = React.useState(0);
  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [listId, setListId] = useState(0);
  const [state, setState] = useState('LIST');
  const [tab, setTab] = useState('messages');
  const [time, setTime] = useState(0);
  const [auto, setAuto] = useState(null);
  const [makeNewAutomation] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openMessageTemplateDialog, setOpenMessageTemplateDialog] = useState(false);

  const editRef = useRef(null);

  const sparkState = usePromise(actions.getSparkCampaign, null);
  const clientListState = usePromise(actions.getClientList, {});
  const clientListClientsState = usePromise(actions.getClientListClients, { data: [] });
  const addClientsState = usePromise(actions.addClients, {});
  const removeClientsState = usePromise(actions.removeClients, []);
  const optOutClientState = usePromise(actions.optoutClient, []);
  const messagesState = usePromise(actions.getMessages, {});
  const automationsState = usePromise(actions.getAutomations, defaultQuery);
  const historyState = usePromise(actions.getHistory, defaultQuery);
  const deleteMessagesState = usePromise(actions.deleteMessages, [[]]);
  const createPathState = usePromise(actions.createPath, null);
  const exclusionListState = usePromise(actions.getExclusionList, null);

  useTitle('Spark Campaign');

  const {
    office, hasFeature, isAdmin, hasVital, creatingPath, sparkTab, headerHeight,
  } = useSelector((state) => ({
    office: state.login.office,
    hasFeature: includes('SparkEdit', pathOr([], ['login', 'features'], state)),
    isAdmin: state.login.admin,
    hasVital: includes('VitalSigns', pathOr([], ['login', 'features'], state)),
    creatingPath: state.templateEdit.creatingPath,
    sparkTab: state.login.sparkTab,
    headerHeight: state.login.headerHeight,
  }));

  const classes = useStyles({ numberOfTabs: hasVital ? 4 : 3 });

  const setSparkTab = (s: string) => {
    dispatch(loginPatch({ sparkTab: s }));
  };

  const clientListData = clientListClientsState.data.data;
  const messagesData = messagesState.data.data;
  const automationsData = automationsState.data.data;
  const historyData = historyState.data.data;

  const currentTabData = cond([
    [equals('messages'), () => messagesState.data],
    [equals('clients'), () => clientListClientsState.data],
    [equals('automations'), () => automationsState.data],
    [equals('history'), () => historyState.data],
  ])(tab);

  const currentPerPage: number = useMemo(() => {
    return propOr(25, 'perPage', currentTabData);
  }, [currentTabData]);

  const { page } = currentTabData;

  const searchClients = ({ id, perPage = currentPerPage, page = 1 }: SearchClients) => {
    const splitUp = search.split(' ');
    const lastName = splitUp[0] ? splitUp[0].trim() : undefined;
    const firstName = splitUp[1] ? splitUp[1].trim() : undefined;
    const phone = splitUp[2] ? splitUp[2].trim() : undefined;
    const query = {
      client: {
        firstName,
        lastName,
        phone,
      },
      listId: id ? id : listId,
    };
    clearTimeout(clientTimeout);
    clientTimeout = setTimeout(() => {
      clientListClientsState.invoke({
        page,
        perPage,
        query,
      }).then((client) => {
        return client;
      });
    }, time);
  };

  const searchMessages = ({ perPage = currentPerPage, page = 1 }) => {
    if (clientListState.data.campaign) {
      clearTimeout(messageTimeout);
      messageTimeout = setTimeout(() => {
        messagesState.invoke({
          page,
          perPage,
          query: {
            campaignId: clientListState.data.campaign?.id,
            ...messageQuery,
          },
        });
      }, time);
    } else {
      messagesState.setState({
        ...messagesState,
        data: {},
      });
    }
  };

  const searchHistory = ({ perPage = currentPerPage }) => {
    clearTimeout(historyTimeout);
    historyTimeout = setTimeout(() => {
      historyState.invoke({
        page: 1,
        perPage,
        query: {
          listId: clientListState.data.campaign?.listId,
          between: {
            ...historyQuery,
          },
        }
      });
    }, time);
  };

  const exportHistory = () => {
    actions.exportHistory({
      page: 1,
      perPage: 1000,
      query: {
        listId: clientListState.data.campaign?.listId,
        between: {
          ...historyQuery,
        },
      }
    }, clientListState.data.name + ' - export');
  };

  const searchAutomations = ({ id = false, perPage = currentPerPage }) => {
    const clientListId = id ? id : clientListState.data.id;
    automationsState.invoke({
      page,
      perPage,
      query: {
        ...automationsQuery,
        campaignId: clientListState.data.campaign?.id,
        action: {
          clientListId,
        },
        filter: {
          client: {
            clientListId,
          },
        },
        trigger: {
          clientListId,
          trigger: cond([
            [equals('Appointment'), always('ApptUpdated')],
            [equals('Campaign'), always('ClientList')],
            [equals('Message Received'), always('MessageReceived')],
            [equals('Time'), always('Time')],
            [equals('Date & Time'), always('DateTime')],
            [T, always(undefined)]
          ])(automationsQuery.trigger)
        },
      },
    }).then((auto) => {
      return auto;
    });
  };

  const searchFunction = (props: { perPage?: number, page?: number }): void => {
    switch (tab) {
      case 'messages':
        return searchMessages(props);
      case 'clients':
        return searchClients(props);
      case 'automations':
        return searchAutomations(props);
      default:
        return;
    }
  };


  useEffect(() => {
    dispatch({
      type: RECURRING_EDIT_RESET,
    });
    dispatch(reset());
    exclusionListState.invoke({});
  }, []);

  const exclusionListId = useMemo(() => {
    return exclusionListState.data;
  }, [exclusionListState.data]);

  useEffect(() => {
    sparkState.invoke({
      campaignPath: `spark.0.${sparkTab}`,
    }).then((list) => {
      setListId(list.id);
      clientListState.setState({
        ...clientListState,
        data: list,
      });
    });
  }, [sparkTab]);

  useEffect(() => {
    if (state === 'EDIT' && editRef.current) {
      editRef.current.focus();
      editRef.current.select();
    }
  }, [state]);

  useEffect(() => {
    console.log(clientListState.data);
    if (clientListState.data.id) {
      searchClients({ id: clientListState.data.id });
      searchMessages({});
      searchAutomations({ id: clientListState.data.id });
      searchHistory({});
      setTime(500);
    }
  }, [clientListState.data]);

  useEffect(() => {
    if (clientListState.data.campaign) {
      searchClients({});
    }
  }, [search]);

  useEffect(() => {
    if (clientListState.data.campaign) {
      searchMessages({});
    }
  }, [messageQuery]);

  useEffect(() => {
    if (!creatingPath && clientListState.data.campaign)
      searchMessages({});
  }, [creatingPath]);

  useEffect(() => {
    if (clientListState.data.campaign) {
      searchAutomations({});
    }
  }, [automationsQuery]);

  useEffect(() => {
    if (clientListState.data.campaign) {
      searchHistory({});
    }
  }, [historyQuery]);

  if (!hasFeature && !isAdmin) {
    return (
      <FeatureDisabled title="Spark">
        <p>
          Tell me what I want what I really really want
        </p>
      </FeatureDisabled>
    );
  }

  const { campaign, name } = clientListState.data;

  const {
    displayName,
    description,
  } = campaign ? campaign : {
    displayName: name,
    description: undefined,
  };

  const handleTextChange = (value: string, type: string) => {
    cond([
      [equals('client'), () => {
        setSearch(value);
      }],
      [equals('message'), () => {
        setMessageQuery({ ...messageQuery, name: value });
      }],
    ])(type);
  };

  const addPage = (num: number) => () => {
    searchFunction({ page: page + num });
  };

  const onPerPageChange = (value: number) => {
    searchFunction({ perPage: Number(value) });
  };

  const cantSave = () => (innerState: AutomationsState) => {
    const compareFunction =
      pipe(
        pathOr(0, ['campaign', 'listId']),
        equals(clientListState.data.id)
      );
    const {
      trigger,
      triggerData,
      filters,
      actions,
    } = innerState;
    const triggerHasList = trigger === 'Campaign' &&
      compareFunction(triggerData);
    const filterHasList = pipe(
      filter(({ filter, filterData }) => {
        return filter === 'client' &&
          (filterData.verb === 'is in' || filterData.verb === 'was added to') &&
          compareFunction(filterData);
      }),
      isEmpty,
      not
    )(filters);
    const actionHasList = pipe(
      filter(({ action, actionData }) => {
        return action === 'Campaign' &&
          has('change', actionData) &&
          compareFunction(actionData);
      }),
      isEmpty,
      not
    )(actions);
    return !any(identity, [triggerHasList, filterHasList, actionHasList]) &&
      'It is required that you use the current campaign in this automation!';
  };

  const saveAutomation = (idx: number) => (a: Automation) => {
    automationsState.setState({
      ...automationsState,
      data: {
        ...automationsState.data,
        data: update(idx, a, automationsData)
      }
    });
    createPathState.invoke({
      id: a.id,
      campaignId: campaign?.id,
      resourceType: 'Automation',
      customPath: `spark.0.autos.auto_${a.id}`,
      isTemplate: false,
    });
  };

  const clickCompose = () => {
    dispatch(automationsPatch({ automations: { data: [] } }));
    history.push(`/automation/edit/0?campaignId=${propOr(0, 'id', campaign)}&spark=true`);
  };

  const reload = async (close = false) => {
    try {
      await sparkState.invoke({
        campaignPath: `spark.0.${sparkTab}`,
      }).then((list) => {
        setListId(list.id);
        clientListState.setState({
          ...clientListState,
          data: list,
        });
        if (close) {
          setOpenSettings(false);
        }
      });
    } catch (e) {
      searchMessages({});
      searchAutomations({});
      if (close) {
        setOpenSettings(false);
      }
    }
  };

  const tabs = [
    { label: 'Messages', value: 'messages' },
    { label: 'Clients', value: 'clients' },
    { label: 'Automations', value: 'automations' }
  ];

  const sparkTabs = [
    { label: 'First year', value: 'first_year' },
    { label: 'Seasonal', value: 'seasonal' },
    { label: 'Master', value: 'master_list' },
    { label: 'Exclusion', value: 'exclusion' },
  ];

  if (hasVital) {
    tabs.push({ label: 'Client History', value: 'history' });
  }

  return (
    <>
      <Header title='Spark Campaign'
        leftIcons={[
          <HeaderTabs
            value={tab}
            setTab={setTab}
            tabs={tabs}
          />,
          <Grid marginRight="6px" />,
          tab === 'automations' ?
            <FilterSelect
              title='Enabled'
              Icon={EnabledIcon}
              items={enabledOptions.map(item => item.name)}
              selected={enabledOptions.find(item => item.value === automationsQuery.enabled).name}
              setSelected={(value) => {
                const item = enabledOptions.find(item => item.name === value);
                setAutomationsQuery({
                  ...automationsQuery,
                  enabled: item.value,
                });
              }}
            /> : <div />,
          tab === 'automations' ?
            <FilterSelect
              title='Trigger'
              noneText='All'
              Icon={TriggerIcon}
              items={triggers.map(item => item === '' ? 'All' : item)}
              selected={automationsQuery.trigger || 'All'}
              setSelected={(value) => {
                setAutomationsQuery({
                  ...automationsQuery,
                  trigger: value === 'All' ? '' : value
                });
              }}
            /> : <div />
        ]}
        rightIcons={[
          <HeaderTabs
            value={sparkTab}
            setTab={setSparkTab}
            tabs={sparkTabs}
          />,
          <HeaderButton
            Icon={GearIcon}
            onlyIcon
            onClick={() => setOpenSettings(true)}
          />
        ].concat(
          cond([
            [equals('messages'), () => ([
              <FilterText
                value={messageQuery.name}
                headerTitle='Search'
                placeholder='Message Name'
                onChange={(value) => handleTextChange(value, 'message')}
              />,
              <HeaderButton
                onClick={(e) => setAnchorEl(e.currentTarget)}
                Icon={AddIcon}
                title='New Message'
              />
            ])],
            [equals('clients'), () => ([
              <FilterText
                value={search}
                headerTitle='Search'
                placeholder='LastName FirstName'
                onChange={(value) => handleTextChange(value, 'client')}
              />,
              <HeaderButton
                Icon={AddIcon}
                onClick={() => setState('ADD')}
                title='Add Clients'
              />
            ])],
            [equals('automations'), () => ([
              <HeaderButton
                disabled={Boolean(automationsData.find(({ id }) => id === 0))}
                Icon={AddIcon}
                onClick={(e) => {
                  if (isAdmin) {
                    setMenuAnchorEl(e.currentTarget);
                  } else {
                    setOpenTemplateDialog(true);
                  }
                }}
                title='Add Automation'
              />
            ])],
            [equals('history'), () => ([
              <FilterStartEndDate dateRange={historyQuery} setDateRange={setHistoryQuery} />,
              <HeaderButton title='Export History' Icon={ImportExportIcon} onClick={exportHistory} />
            ])]
          ])(tab))
        }
        onlyIconsWidth={(tab === 'automations' || hasVital) ? 1160 : 997}
        breakPoints={[
          { width: hasVital ? 1010 : 890, mobileItems: [2, 3, 4] },
          { width: hasVital ? 776 : 664, mobileItems: [0, 2, 3, 4] }
        ]}
      />
      <div style={{ padding: '20px', paddingTop: 0 }}>
        <div>
          <PopupTemplate />
          <SearchClientsDialog
            isOpen={state === 'ADD'}
            close={() => setState('LIST')}
            ehrSystem={office.ehrSystem}
            loading={addClientsState.loading}
            save={(clients: Client[]) => {
              const clientIds = clients.map(({ id }) => id);
              addClientsState.invoke({ listId, clientIds }).then(() => {
                searchClients({});
                setState('LIST');
              });
            }}
          />
          <AutomationDialog
            isOpen={state === 'VIEW_AUTO'}
            onClose={() => setState('LIST')}
            defaultCampaign={clientListState.data}
            message={auto}
            makeNewAutomation={makeNewAutomation}
            template={false}
            campaignId={clientListState.data?.campaign?.id}
            hideAutomations
          />
          <AutomationTemplateDialog
            open={openTemplateDialog}
            onClose={() => setOpenTemplateDialog(false)}
            initFolder='automations.Campaigns'
            onSelect={(auto) => {
              setOpenTemplateDialog(false);
              if (automationsData)
                automationsState.setState({
                  ...automationsState,
                  data: {
                    ...automationsState.data,
                    data: prepend({ ...auto, isEdit: true, id: 0, enabled: false }, automationsData),
                  },
                });
            }}
          />
          <MessageTemplateDialog
            open={openMessageTemplateDialog}
            onClose={() => setOpenMessageTemplateDialog(false)}
            selectTemplate={() => clickCompose()}
          />
          <SparkSettings
            open={openSettings}
            onClose={() => setOpenSettings(false)}
            reload={reload}
            loading={sparkState.loading}
          />
          <Container getHeight={setTopHeight}>
            <Row
              className={classes.headerRow}
              style={{
                justifyContent: 'flex-start',
                marginBottom: 'unset',
              }}>
              <PageHeader align="left" variant="h5">
                {(displayName && state !== 'EDIT') && <b>{displayName}</b>}
                {(!displayName && state !== 'EDIT') && <b>Campaign</b>}
              </PageHeader>
            </Row >
            <Row className={classes.headerRow} style={{ alignItems: 'flex-start' }}>
              {(description && state !== 'EDIT') && description}
            </Row>
          </Container>
          <div className={classes.tabBodyContainer} hidden={tab !== 'history'}>
            {(clientListState.loading || historyState.loading) &&
            <CircularProgress />}
            {!historyState.loading &&
            <TableContainer
              className={classes.noHorizontalMargins}
              fullPage
              maxHeight={`calc(100vh - ${headerHeight}px - ${topHeight}px - 45px)`}
              pagination
              paginationData={{
                addPage: (value) => addPage(value)(),
                currentLength: currentTabData.data?.length,
                page: currentTabData.page,
                perPage: currentPerPage,
                setPerPage: onPerPageChange,
                totalCount: currentTabData.totalCount,
                totalPages: currentTabData.totalPages,
              }}
            >
              <TableHead>
                <TableRow>
                  <HeaderCell>
                    Client Name
                  </HeaderCell>
                  <HeaderCell>
                    Change
                  </HeaderCell>
                  <HeaderCell>
                    Source
                  </HeaderCell>
                  <HeaderCell>
                    Total
                  </HeaderCell>
                  <HeaderCell>
                    Created
                  </HeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historyData &&
                    historyData.map((h: ClientListHistory) => (
                      <ClientListHistoryRowContainer
                        key={h.client?.id + h.change + h.source}
                        change={h}
                        tz={office.timezone}
                      />
                    ))}
              </TableBody>
            </TableContainer>
            }
          </div>
          <div className={classes.tabBodyContainer} hidden={tab !== 'messages'}>
            {(clientListState.loading || messagesState.loading) &&
              <Loading loading />}
            {!messagesState.loading &&
              <TableContainer
                className={classes.noHorizontalMargins}
                fullPage
                maxHeight={`calc(100vh - ${headerHeight}px - ${topHeight}px - 45px)`}
                pagination
                paginationData={{
                  addPage: (value) => addPage(value)(),
                  currentLength: currentTabData.data?.length,
                  page: currentTabData.page,
                  perPage: currentPerPage,
                  setPerPage: onPerPageChange,
                  totalCount: currentTabData.totalCount,
                  totalPages: currentTabData.totalPages,
                }}
              >
                <TableHead>
                  <TableRow
                    hasSelect
                    noSelectHover
                    checked={messagesData?.length && selected.length === messagesData?.length}
                    someChecked={selected.length > 0 && selected.length < messagesData?.length}
                    setChecked={(checked) => {
                      setSelectAll(checked);
                      if (checked) {
                        setSelected(map(prop('id'), messagesData));
                      } else {
                        setSelected([]);
                      }
                    }}
                  >
                    <HeaderCell style={{ position: 'relative' }}>
                      {!isEmpty(selected) && (
                        <Grid position="absolute" display="flex" alignItems="center" width="80%" top={0} left={8} style={{ background: '#F5F5F5' }}>
                          <IconButton
                            onClick={() => {
                              popupWithCancel(
                                'Are you sure you want to delete?',
                                'This action cannot be undone!',
                                () => deleteMessagesState.invoke({
                                  ids: selected,
                                  template: false,
                                }).then(() => {
                                  searchMessages({});
                                  setSelected([]);
                                  setSelectAll(false);
                                }),
                                'danger'
                              );
                            }}>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      )}
                        Name
                    </HeaderCell>
                    <HeaderCell>
                        Enabled
                    </HeaderCell>
                    <HeaderCell>
                        Send As
                    </HeaderCell>
                    <HeaderCell>
                        Created
                    </HeaderCell>
                    <HeaderCell>
                        Actions
                    </HeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {messagesData &&
                      messagesData.map((s: any) => (
                        <MessageRowContainer
                          key={s.id}
                          message={s}
                          tz={office.timezone || 'America/New_York'}
                          isSelected={find(equals(s.id), selected)}
                          template={false}
                          campaignId={clientListState.data.id}
                          select={(isSelected) => {
                            if (isSelected) {
                              setSelected(append(s.id, selected));
                            } else {
                              setSelected(without([s.id], selected));
                              if (selectAll) {
                                setSelectAll(false);
                              }
                            }
                          }}
                          onDelete={() => {
                            popupWithCancel(
                              'Are you sure you want to delete?',
                              'This action cannot be undone!',
                              () => deleteMessagesState.invoke({
                                ids: [s.id],
                                template: false,
                              }).then(() => {
                                searchMessages({});
                              }),
                              'danger'
                            );
                          }}
                          onCopy={() => {
                            dispatch(copyMessage('COPY_AUTOMATION', false, s));
                            history.push('/automation/edit/0');
                          }}
                          openAuto={(msg) => {
                            setAuto(msg);
                            setState('VIEW_AUTO');
                          }}
                        />
                      ))}
                </TableBody>
              </TableContainer>
            }
          </div>
          <div className={classes.tabBodyContainer} hidden={tab !== 'clients'}>
            {(clientListState.loading || clientListClientsState.loading) &&
                <CircularProgress />}
            {!clientListClientsState.loading &&
                <TableContainer
                  className={classes.noHorizontalMargins}
                  fullPage
                  maxHeight={`calc(100vh - ${headerHeight}px - ${topHeight}px - 45px)`}
                  pagination
                  paginationData={{
                    addPage: (value) => addPage(value)(),
                    currentLength: currentTabData.data?.length,
                    page: currentTabData.page,
                    perPage: currentPerPage,
                    setPerPage: onPerPageChange,
                    totalCount: currentTabData.totalCount,
                    totalPages: currentTabData.totalPages,
                  }}
                >
                  <TableHead>
                    <TableRow
                      hasSelect
                      noSelectHover
                      checked={clientListData.length && selected.length === clientListData?.length}
                      someChecked={selected.length > 0 && selected.length < clientListData?.length}
                      setChecked={(checked) => {
                        setSelectAll(checked);
                        if (checked) {
                          setSelected(map(path(['client', 'id']), clientListData));
                        } else {
                          setSelected([]);
                        }
                      }}
                    >
                      <HeaderCell style={{ position: 'relative' }}>
                        {!isEmpty(selected) && (
                          <Grid position="absolute" display="flex" alignItems="center" width="80%" top={0} left={8} style={{ background: '#F5F5F5' }}>
                            <IconButton
                              onClick={() => {
                                removeClientsState.invoke({
                                  clientIds: selected,
                                  listId: exclusionListId,
                                  spark: sparkTab !== 'exclusion',
                                }).then(() => {
                                  searchClients({});
                                  setSelected([]);
                                  setSelectAll(false);
                                });
                              }}>
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        )}
                          Name
                      </HeaderCell>
                      <HeaderCell>
                          Birthday
                      </HeaderCell>
                      <HeaderCell>
                          Phone
                      </HeaderCell>
                      <HeaderCell>
                          Email
                      </HeaderCell>
                      <HeaderCell>
                          Added to List
                      </HeaderCell>
                      <HeaderCell>
                          Opted Out
                      </HeaderCell>
                      <HeaderCell>
                          Actions
                      </HeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      clientListData.map((s: ClientWithList) => (
                        <RowContainer
                          key={s.client.id}
                          clientWithList={s}
                          tz={office.timezone}
                          isSelected={find(equals(s.client.id), selected)}
                          history={history}
                          pageType={'Campaign'}
                          select={(isSelected) => {
                            if (isSelected) {
                              setSelected(append(s.client.id, selected));
                            } else {
                              setSelected(without([s.client.id], selected));
                              if (selectAll) {
                                setSelectAll(false);
                              }
                            }
                          }}
                          onDelete={() => {
                            removeClientsState.invoke({
                              clientIds: [s.client.id],
                              listId: exclusionListId,
                              spark: sparkTab !== 'exclusion',
                            }).then(() => {
                              searchClients({});
                            });
                          }}
                          onOpt={() => {
                            optOutClientState.invoke({
                              clientId: s.client.id,
                              listId,
                            }).then(() => {
                              searchClients({});
                            });
                          }}
                        />
                      ))
                    }
                  </TableBody>
                </TableContainer>
            }
          </div >
          <div
            className={`${classes.tabBodyContainer} ${classes.noHorizontalMargins}`}
            hidden={tab !== 'automations'}
            style={{
              overflowY: 'auto',
              maxHeight: `calc(100vh - ${headerHeight}px - ${topHeight}px - 90px)`,
            }}
          >
            {(clientListState.loading || automationsState.loading) &&
              <CircularProgress />}
            {!automationsState.loading &&
              <AutomationsList
                automations={automationsData}
                defaultCampaign={clientListState.data}
                isTemplate={false}
                onSave={saveAutomation}
                cantSave={cantSave}
                hideAutomations
                removeAutomation={(idx) => {
                  automationsState.setState({
                    ...automationsState,
                    data: {
                      ...automationsState.data,
                      data: remove(idx, 1, automationsData)
                    }
                  });
                }}
              />
            }
          </div>
        </div>
        <Menu
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          id="long-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(null)}>
          <MenuItem onClick={() => {
            setMenuAnchorEl(null);
            automationsState.setState({
              ...automationsState,
              data: {
                ...automationsState.data,
                data: prepend({
                  id: 0,
                  actions: [],
                  trigger: {
                    Dummy: []
                  },
                  enabled: false,
                  isEdit: true,
                  hidden: false,
                }, automationsData)
              }
            });
          }}>
            <ListItemIcon>
              <AddIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary='Create New' />
          </MenuItem>
          <MenuItem onClick={() => {
            setMenuAnchorEl(null);
            setOpenTemplateDialog(true);
          }}>
            <ListItemIcon>
              <FileCopyIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary='From Template' />
          </MenuItem>
        </Menu>
        <Menu
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={() => {
            setAnchorEl(null);
            clickCompose();
          }}>
            <ListItemIcon>
              <AddIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary='Create New' />
          </MenuItem>
          <MenuItem onClick={() => {
            setAnchorEl(null);
            setOpenMessageTemplateDialog(true);
          }}>
            <ListItemIcon>
              <FileCopyIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary='From Template' />
          </MenuItem>
        </Menu>
      </div>
      {(tab === 'automations' && !clientListState.loading && !messagesState.loading) && (
        <Pagination
          addPage={(value) => addPage(value)()}
          currentLength={currentTabData.data?.length}
          page={currentTabData.page}
          perPage={currentPerPage}
          setPerPage={onPerPageChange}
          totalCount={currentTabData.totalCount}
          totalPages={currentTabData.totalPages}
        />
      )}
    </>
  );
};

export default SparkCampaign;
