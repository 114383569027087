import React, { useState } from 'react';

import Icon from '../../icons/Professional.icon';
import EditIcon from '@mui/icons-material/Edit';
import FilterHeader from '../FilterHeader/FilterHeader.component';
import HeaderButton from '../HeaderButton/HeaderButton.component';
import { Professional } from '../../routes/Appointments/appointments.types';
import { useStyles } from './selectProfessional.styles';

import {
  MenuItem,
  Popover,
  Grid,
  ListItemText,
  Radio,
  IconButton
} from '@mui/material';

type SelectProfessionalProps = {
  setPropId: (id: number) => void;
  allProfessionals: Professional[];
  selectedProId: number;
  title?: string;
  headerTitle?: string;
  showNameAndDisplayName?: boolean;
  addAll?: boolean;
  editAction?: () => void;
  onlyIcon?: boolean;
  className?: string;
  saveClassName?: string;
  resetClassName?: string;
}

const SelectProfessional = ({
  setPropId,
  selectedProId,
  allProfessionals,
  title,
  headerTitle,
  showNameAndDisplayName,
  editAction,
  addAll,
  onlyIcon,
  className,
  saveClassName,
  resetClassName,
}: SelectProfessionalProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    setPropId(0);
    handleClose();
  };

  const handleSelected = (value: number) => {
    setPropId(value);
    handleClose();
  };

  const showProName = (pro: Professional) => {
    if (showNameAndDisplayName) {
      const showName = pro.firstName || pro.lastName;
      const name = ` (${pro.firstName || ''} ${pro.lastName || ''})`;
      const displayName = `${pro.displayFirstName || ''} ${pro.displayLastName || ''}`;
      return displayName + (showName ? name : '');
    }
    return `${pro.displayFirstName} ${pro.displayLastName}`;
  };

  return (
    <Grid>
      <Grid marginRight={0.5} display="flex" alignItems="center" style={{ whiteSpace: 'nowrap' }}>
        <Grid className={onlyIcon && editAction && selectedProId > 0 ? classes.onlyIconEdit : ''}>
          <HeaderButton
            title={title || 'Professional'}
            Icon={Icon}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            active={selectedProId > 0}
            type='filter'
            onlyIcon={onlyIcon}
            className={className}
          />
        </Grid>
        {(editAction && selectedProId > 0) && (
          <>
            {onlyIcon ? (
              <Grid className={classes.onlyIconEditButton}>
                <div className={classes.divide} />
                <HeaderButton
                  title={'Edit'}
                  Icon={EditIcon}
                  onClick={editAction}
                  active={selectedProId > 0}
                  type='filter'
                  onlyIcon
                />
              </Grid>
            ) : (
              <IconButton
                className={classes.editButton}
                style={{ background: selectedProId > 0 ? '#D6F3D5' : '#FFF' }}
                onClick={editAction}>
                <EditIcon className={classes.icon} />
              </IconButton>
            )}
          </>
        )}
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid minWidth={280}>
          <FilterHeader
            title={headerTitle || title || 'Professional'}
            reset={handleReset}
            resetDisabled={selectedProId === 0}
            resetClassName={resetClassName}
            saveClassName={saveClassName}
          />
          <Grid padding={1} paddingBottom={1}>
            {addAll && (
              <MenuItem
                value={0}
                onClick={() => handleSelected(0)}
                className={selectedProId === 0 ? classes.selected : classes.selectItem}
              >
                <Radio checked={selectedProId === 0} />
                <ListItemText className={classes.selectFontSize} primary="All Professionals" />
              </MenuItem>
            )}
            {allProfessionals.map((pro) => (
              <MenuItem
                key={pro.id}
                onClick={() => handleSelected(pro.id)}
                className={pro.id === (selectedProId || 0) ? classes.selected : classes.selectItem}
                value={pro.id}
              >
                <Radio checked={pro.id === (selectedProId || 0)} />
                <ListItemText className={`${classes.selectFontSize} ${pro.isHidden ? classes.hidden : ''}`} primary={showProName(pro)} />
              </MenuItem>
            ))}
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
};

export default SelectProfessional;
