import * as at from '../../actionTypes';
import api from '../../services/api.js';
import {
  reset,
  backToClient,
  getCurrentClient,
} from '../Clients/components/client-dialog/client-dialog.actions.jsx';
import { remoteAction } from '../../services/actionService.js';
import * as R from 'ramda';
import {
  now,
  toISOUTCString,
  tzParseFormat,
  untilVerbose,
} from '../../services/joda.js';
import { ZonedDateTime } from '@js-joda/core';
import { popup } from '../../services/Popup.js';
import { etd, caseType } from '../../services/utilities.js';

const decipherTime = (time) => {
  return untilVerbose(now('tz'), ZonedDateTime.parse(time));
};

const getStatus = (status) => {
  if (R.has('Confirmed', status)) {
    return 'Confirmed';
  }
  if (R.has('Pending', status)) {
    return 'Pending';
  }
  if (R.has('OverScheduled', status)) {
    return 'OverScheduled';
  }
  return 'Error';
};

const setApptDisplay =
  (tz, apt) =>
    (timePrefix = '') => ({
      client: `${apt.appointment.client.firstName || ''} ${apt.appointment.client.lastName || ''} ${caseType(apt.appointment.client)}`,
      timeAt:
        timePrefix +
        tzParseFormat(apt.appointment.time, tz, 'MM/dd/yyyy @ hh:mm a'),
      changedAgo: decipherTime(apt.changedAt),
      status: getStatus(apt.appointment.bookingStatus),
      data: apt,
    });

const organizeAppData = (tz, data = []) => {
  return data.reduce(
    (acc, apt) => {
      const applyPrefix = setApptDisplay(tz, apt);
      if (apt.change.Added) {
        acc.newApts.push(applyPrefix('Scheduled for: '));
      } else if (apt.change.OldAdded) {
        acc.newApts.push(applyPrefix('Scheduled for: '));
      } else if (apt.change.TimeUpdated) {
        acc.resked.push(applyPrefix('Updated to: '));
      } else if (apt.change.Canceled) {
        acc.canceled.push(applyPrefix(''));
      }
      return acc;
    },
    { newApts: [], resked: [], canceled: [] }
  );
};

const mapWebModuleData = (tz, data = []) => {
  return data.map((apt) => {
    return setApptDisplay(tz, apt)('Scheduled for: ');
  });
};

const setUserDisplay = (tz, user) => ({
  client: `${user.firstName || ''} ${user.lastName || ''} ${caseType(user)}`,
  timeAt: tzParseFormat(user.createdAt, tz, 'MM/dd/yyyy @ hh:mm a'),
  changedAgo: decipherTime(user.createdAt),
  status: null,
  data: user,
});

export const getApt = (aptId) =>
  remoteAction({
    type: at.DASH_REMOTE_APT_GET,
    action: () =>
      api
        .get(`appointment/${aptId}`)
        .then((appointment) =>
          api
            .get(`client/${appointment.clientId}`)
            .then((client) => getType(R.merge(appointment, { client })))
        ),
  });

export const getLogs = (date = '2021-03-01', tz, locationId = 0) => (dispatch, getStore) => {
  const d = toISOUTCString(date, '00:00', tz);
  dispatch({
    type: at.DASH_GET_STUFF,
    data: {
      logsBusy: true,
    }
  });

  const data = (source) => ({
    page: 1,
    perPage: 100000,
    query: {
      source,
      createdAfter: d,
      appointment: locationId === 0 ? null : { locationId },
    }
  });
  return Promise.all([
    api.post('appointment/log/query', data('App')),
    api.post('appointment/log/query', data('Webmodule')),
    api.post('appointment/log/query', data('Npp')),
    api.post('user/client/query', {
      page: 1,
      perPage: 1000,
      query: {
        created: {
          '>': d,
        }
      }
    })
  ].map(etd)).then(([app, webmodule, npp, users]) => {
    const tz = getStore().login.office.timezone;
    const { newApts, resked, canceled } = organizeAppData(tz, app.data && app.data.data);

    const webData = webmodule.data ? webmodule.data.data : [];
    const nppData = npp.data ? npp.data.data : [];
    const allWebAndNpp = R.reverse(R.sortBy(R.prop('changedAt'), webData.concat(nppData)));
    const webmoduleList = mapWebModuleData(tz, allWebAndNpp);

    const userList = R.pipe(
      R.pathOr([], ['data']),
      R.sortBy(R.prop('createdAt')),
      R.reverse,
      R.map(u => setUserDisplay(tz, u))
    )(users.data);

    return dispatch({
      type: at.DASH_GET_STUFF,
      data: {
        logsBusy: false,
        newApts: app.error ? app : newApts,
        resked: app.error ? app : resked,
        canceled: app.error ? app : canceled,
        webmodule: webmodule.data ? { data: webmoduleList } : webmodule,
        newUsers: users.data ? userList : users
      }
    });
  }).catch((error) => {
    console.error(error);
    popup('Error!', 'Failed to get data. Please refresh your browser and try again.');
    return dispatch({
      type: at.DASH_GET_STUFF,
      data: {
        logsBusy: false
      }
    });
  });
};


export const getGraph = (year) => dispatch => {
  return api.get(`appointment/log/graph/${year}`).then(graph => {
    return dispatch({
      type: at.DASH_GET_STUFF,
      data: {
        busy: false,
        graph: { data: graph }
      }
    });
  })
    .catch((error) => {
      console.error(error);
      popup(
        'Error!',
        'Failed to get data. Please refresh your browser and try again.'
      );
      return dispatch({
        type: at.DASH_GET_STUFF,
        data: {
          busy: false,
        },
      });
    });
};

export const selectClient = (client) => (dispatch) => {
  dispatch(getCurrentClient(client));
  return dispatch({
    type: at.DASH_CLIENT_SELECT,
    data: {
      busy: false,
      state: 'CLIENT_SELECT',
    },
  });
};

export const getType = (appointment) =>
  api
    .get(`appointmentType/${appointment.appointmentTypeId}`)
    .then((data) => {
      const aptWithType = R.assoc('type', data.name)(appointment);
      return api.get(`appointment/${appointment.id}/log`).then((data) => {
        return {
          appointment: R.assoc(
            'aptData',
            R.reverse(R.sortBy(R.prop('changedAt'))(data)),
            aptWithType
          ),
        };
      });
    })
    .catch((error) => {
      console.error(error);
    });

export const selectAppointment = (appointment) =>
  remoteAction({
    type: at.DASH_REMOTE_APT_GET,
    action: () => getType(appointment),
  });

export const backToDash = () => (dispatch) => {
  dispatch(reset());
  return dispatch({
    type: at.DASH_SELECT_APT,
    data: {
      state: 'DASH',
    },
  });
};

export const selectClientDialog = (client) => (dispatch) =>
  dispatch(getCurrentClient(client));

export const back = () => (dispatch) => dispatch(backToClient());
