import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 8,
    width: 'auto',
    overflow: 'visible',
    boxSizing: 'border-box',

    '& .MuiCheckbox-root': {
      borderRadius: 0,
    },
    height: '100%',
  },
  border: {
    '& tr td > div, tr th > div': {
      borderRight: '1px solid rgba(0, 0, 0, 0.1)',
    },
    '& tr th:first-child': {
      borderRight: 'none !important',
    },
  },
  scrollableContainer: {
    overflow: 'auto',
    boxSizing: 'border-box',

    '& th:first-child': {
      backgroundColor: '#F5F5F5',
    },

    '&::-webkit-scrollbar': {
      height: '10px',
      width: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(206, 206, 206)',
      borderRadius: '6px',

      '&:hover': {
        backgroundColor: 'gray',
        borderRadius: '6px',
      },
    },
  },
  shadow: {
    '& thead tr': {
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
      borderBottom: 'none',
    },
  },
  table: {
    '& tr .MuiCheckbox-root': {
      padding: 0,
    },
    '& tr .MuiIconButton-root': {
      padding: 6,
      marginRight: 2,
    },
    '& tr svg': {
      width: 17,
      height: 17,
    },
    '& thead': {
      backgroundColor: '#F5F5F5',

      '& tr': {
        height: 32,
        position: 'sticky',
        top: 0,
        zIndex: 100,
        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',

        [theme.breakpoints.down('sm')]: {
          top: '-1px',
        },

        '& th': {
          backgroundColor: '#F5F5F5',

          padding: '0 !important',
          border: 'none !important',

          '& > div': {
            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
            padding: '6px 16px 6px 11px',
            whiteSpace: 'nowrap',
            lineHeight: '16px',
            fontWeight: 400,
            fontSize: 14,
          },
          '&:last-child > div': {
            borderRight: 'none',
          }
        },
      },
      '& tr th:last-child': {
        borderRight: 'none',
      },
    },
    '& tbody': {
      '& tr': {
        height: 42,
        '& td': {
          fontSize: 14,
          lineHeight: '16px',
          padding: '6px 16px 6px 11px',

          '& a': {
            textDecoration: 'none',
            color: theme.palette.primary.main,
          },
          '& p, a, span': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },
        },
      }
    },
  },
  hover: {
    '& tbody': {
      '& tr:hover td': {
        backgroundColor: '#f9f9f9',
      }
    },
  },
}));
