import * as R from 'ramda';
import * as at from '../../../../actionTypes';

const defaultNoPage = {
  body: '',
  header: '',
};

const defaultEndPage = {
  body: '',
  header: '',
};

const defaultEmailPage = {
  body: '',
  header: '',
  subject: '',
};

const init = {
  facebookUrl: '',
  googleUrl: '',
  forwardSetting: 'Other',
  imageUrl: null,
  daysToResend: 60,
  minutesToWait: 15,
  isLoading: true,
  isSnackbarOpen: false,
  isSaving: false,
  isEnabled: false,
  lastRead: '',
  types: [],
  professionals: [],
  selectedTypes: null,
  some: 'All',
  mainPage: '',
  smsMsg: '',
  noPage: defaultNoPage,
  endPage: defaultEndPage,
  emailPage: defaultEmailPage,
  busy: false,
};

export default (state = init, action) => {
  switch (action.type) {
    case at.SETTINGS_REVIEWS_INITIALIZE:
      if (action.state === 'RESPONSE') {
        const [
          settings,
          rawTypes,
          rawProfessionals,
          custom,
        ] = action.data;
        const settingsData = R.evolve({
          forwardSetting: R.ifElse(
            R.equals('Facebook'),
            R.always('Other'),
            R.identity
          ),
        }, settings.data);
        const professionals = R.pipe(
          R.filter(type => !type.isHidden),
          R.sortBy(R.prop('firstName'))
        )(rawProfessionals.data);
        const types = R.pipe(
          R.filter(({ professionalId }) =>
            R.find(R.propEq('id', professionalId))(professionals)),
          R.sortBy(R.prop('internalName'))
        )(rawTypes.data);
        const isAll = settings.data ? R.hasPath(['appointmentTypeIds', 'All'], settings.data) : true;
        const selectedTypes = isAll ?
          R.map(({ id }) => id)(types) :
          R.pathOr([], ['appointmentTypeIds', 'Some'])(settings.data);
        return {
          ...state,
          settingsExist: true,
          ...settingsData,
          types,
          professionals,
          selectedTypes,
          some: isAll ? 'All' : 'Some',
          mainPage: R.pathOr('', ['data', 'mainPage'], custom),
          noPage: R.pathOr(defaultNoPage, ['data', 'noPage'], custom),
          emailPage: R.pathOr(defaultEmailPage, ['data', 'emailPage'], custom),
          smsMsg: R.pathOr('', ['data', 'smsMsg'], custom),
          endPage: R.pathOr(defaultEndPage, ['data', 'endPage'], custom),
        };
      }
      return state;
    case at.SETTINGS_REVIEWS_EDIT_FORM:
      return { ...state, [action.data.field]: action.data.value };
    case at.SETTINGS_REVIEWS_CLOSE_SNACKBAR:
      return { ...state, isSnackbarOpen: false };
    case at.SETTINGS_REVIEWS_SAVE: {
      if (action.state === 'REQUEST') {
        return R.merge(state, {
          isSaving: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(state, {
          isSaving: false,
          isSnackbarOpen: true
        });
      } else if (action.state === 'ERROR') {
        return R.merge(state, {
          isSaving: false,
        });
      }
    }
      return state;
    case at.GET_SETTINGS_REVIEWS:
      return { ...state, ...action.data, settingsExist: true };
    case at.SETTINGS_REVIEWS_UNMOUNT:
      return init;
    default:
      return state;
  }
};
