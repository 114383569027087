import React from 'react';
import * as R from 'ramda';
import { LocalDate, ZonedDateTime } from '@js-joda/core';
import { Grid, Checkbox, Typography, Tooltip } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import MmsIcon from '@mui/icons-material/Mms';

import { tzParseFormat } from '../../../../services/joda';
import { caseType } from '../../../../services/utilities';
import { usePromise } from '../../../../services/promise.hook';
import api from '../../../../services/api';

import Avatar from '../../../../components/Avatar/Avatar.component';
import { Message } from '../../message-types';
import { Client } from '../../../Clients/clients.types';

import { useStyles } from './inboxListItem.styles';

type InboxItemProps = {
  message: Message;
  tz: string;
  selectMessage: (value: boolean) => void;
  goto: (client: Client) => void;
}

const getMessage = ({ id }: { id: number }) => api.get(`sentmsg/${id}`);

const InboxItem = ({ message, tz, selectMessage, goto }: InboxItemProps) => {
  const classes = useStyles();
  const messageState = usePromise(getMessage, null);

  const getName = (client: Client) => {
    const name = `${client?.firstName} ${client?.lastName}`;
    if (name.trim().length === 0) {
      return client.metadata.caseType;
    }
    return name;
  };

  const getDateTime = (date: string) => {
    if (date && tz) {
      const msgDate = ZonedDateTime.parse(date)?.toLocalDate();
      if (LocalDate.now().equals(msgDate)) {
        return tzParseFormat(date, tz, 'h:mm a');
      }
      return tzParseFormat(date, tz, 'MM/dd/yyyy');
    }
    return '';
  };

  React.useEffect(() => {
    if (message.message === '' || R.isNil(message.message)) {
      messageState.invoke({ id: message.msgId });
    }
  }, [message]);

  const hasMMS = React.useMemo(() => {
    return R.pathOr(false, ['data', 'smsData', 'phi', 0], messageState)
      ||
      R.pathOr(false, ['data', 'smsData', 'attachmentIds', 0], messageState);
  }, [messageState.data]);

  const unread = React.useMemo(() => {
    return (message.unread || ((message?.unreadCount || 0) > 0));
  }, [message]);

  return (
    <Grid
      className={`${classes.root} ${message.selected ? classes.selected : ''}`}
      display="flex"
      alignItems="center"
      paddingY={1}
      paddingLeft={0}
      paddingRight="11px"
      marginBottom={0}
    >
      <Checkbox
        className={classes.checkbox}
        checked={Boolean(message.selected)}
        onChange={(event) => selectMessage(event.target.checked)}
      />
      <Avatar name={getName(message.client)} className={classes.avatar} isLead={message.client?.isLead} />
      <Grid flex={1} className={classes.textContainer} onClick={() => goto(message.client)}>
        <Grid display="flex" alignItems="center">
          <Typography className={classes.title} style={{
            fontWeight: unread ? 600 : 'unset',
          }}>
            {message.from === 'Off' &&
              <Tooltip title="You sent this message" placement="top" arrow>
                <ReplyIcon
                  className={classes.replyIcon}
                  style={{ color: unread ? 'unset' : '#7C7C80' }}
                />
              </Tooltip>}
            {R.propOr(null, 'firstName')(message.client) + ' '}
            {R.propOr(null, 'lastName')(message.client) + ' '}
            {caseType(message.client)}
          </Typography>
          {unread &&
            <span className={classes.new} />}
        </Grid>
        {hasMMS && !messageState.loading ?
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <MmsIcon style={{ fontSize: '14px', opacity: 0.8, marginRight: '5px' }} />
            <i className={classes.message}>Multimedia Message</i>
          </div>
          : (
            <Typography className={classes.message}>
              {message.message}
            </Typography>
          )
        }
      </Grid>
      <Typography marginLeft={0.5} className={classes.date} onClick={() => goto(message.client)}>
        {getDateTime(message.updatedAt)}
      </Typography>
    </Grid>
  );

};

export default InboxItem;
