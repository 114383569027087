import * as R from 'ramda';
import * as at from '../../../../actionTypes';
import { formatPhone } from '../../../../services/utilities.js';

const init = {
  busy: false,
  officeForm: {},
  officeAddressForm: {
    street: '',
    city: '',
    zipCode: '',
    state: '',
    country: 'US',
  },
  isSaving: false,
  isEditData: false,
  email: '',
  snackbar: false,
  message: 'Business Information Saved',
  locations: [],
};

export default function reducer(business = init, action) {
  switch (action.type) {
    case at.BUSINESS_PATCH:
      return R.merge(business, action.data);
    case at.BUSINESS_PATCH_OFFICE:
      return R.merge(business, {
        officeForm: R.merge(business.officeForm, action.data)
      });
    case at.BUSINESS_PATCH_OFFICE_ADDRESS:
      return R.merge(business, {
        officeAddressForm: R.merge(business.officeAddressForm, action.data)
      });
    case at.BUSINESS_REMOTE_GET:
      if (action.state === 'REQUEST') {
        return R.merge(business, { busy: true });
      } else if (action.state === 'RESPONSE') {
        return R.pipe(
          R.merge(R.__, {
            officeForm: action.data.office,
            officeAddressForm: action.data.address,
          }),
          R.evolve({ officeForm: action.data.address.country === 'US' ? { phone: formatPhone } : R.identity })
        )(business);
      } else if (action.state === 'ERROR') {
        return R.merge(business, { busy: false });
      }
      return business;
    case at.OFFICE_REMOTE_PUT:
      if (action.state === 'REQUEST') {
        return R.merge(business, { isSaving: true });
      } else if (action.state === 'RESPONSE') {
        const emailObj = { email: action.data.email };
        return R.pipe(
          R.merge(R.__, {
            isEditData: false,
            snackbar: true,
            isSaving: false,
            officeForm: R.merge(action.data.office, emailObj),
            officeAddressForm: R.merge(action.data.officeAddress, emailObj),
            email: action.data.email,
            message: 'Business Information Saved',
          }),
          R.evolve({ officeForm: { phone: formatPhone } })
        )(business);
      } else if (action.state === 'ERROR') {
        return R.merge(business, { isSaving: false });
      }
      return business;
    case at.BUSINESS_REMOTE_GET_LOCATIONS: {
      if (action.state === 'REQUEST') {
        return R.merge(business, { busy: true });
      } else if (action.state === 'RESPONSE') {
        return R.merge(business, {
          busy: false,
          locations: R.sortBy(R.prop('name'), action.data),
        });
      } else if (action.state === 'ERROR') {
        return R.merge(business, { busy: false });
      }
    }
      return business;
    case at.BUSINESS_REMOTE_POST_LOCATIONS:
      if (action.state === 'REQUEST') {
        return R.merge(business, { isSaving: true });
      } else if (action.state === 'RESPONSE') {
        const { idx, location } = action.data;
        return R.evolve({
          locations: R.update(idx, location),
          snackbar: R.T,
        }, business);
      } else if (action.state === 'ERROR') {
        return R.merge(business, { isSaving: false });
      }
      return business;
    case at.BUSINESS_REMOTE_DELETE_LOCATIONS:
      if (action.state === 'REQUEST') {
        return R.merge(business, { isSaving: true });
      } else if (action.state === 'RESPONSE') {
        const { idx } = action.data;
        return R.evolve({
          locations: R.remove(idx, 1),
        }, business);
      } else if (action.state === 'ERROR') {
        return R.merge(business, { isSaving: false });
      }
      return business;
    case at.BUSINESS_DELETE_LOCATIONS: {
      const { idx } = action.data;
      return R.evolve({
        locations: R.remove(idx, 1),
      }, business);
    }
    default:
      return business;
  }
}
