import { Dispatch } from 'redux';
import * as R from 'ramda';
import axios from 'axios';
import { History } from 'history';
import * as at from '../../actionTypes';
import * as actionTypes from './clients.actionTypes';
import api from '../../services/api.js';
import { remoteAction } from '../../services/actionService.js';
import {
  reset,
  getCurrentClient,
  backToClient,
} from './components/client-dialog/client-dialog.actions.jsx';
import { popup } from '../../services/Popup.js';
import { Tag } from '../Tags/tags.types';
import { Client } from './clients.types';
import { GetStore } from '../../reducers';

type SearchValue = string | number | string[] | Tag[];

export const searchPatch = (prop: string, value: SearchValue) => (dispatch: Dispatch) =>
  dispatch({
    type: at.CLIENTS_SEARCH_PATCH,
    data: {
      [prop]: value,
    }
  });

export const backToList = () => (dispatch: Dispatch) => {
  reset()(dispatch);
  return dispatch({
    type: at.CLIENTS_BACK_TO_LIST,
    data: {
      state: 'CLIENT_LIST',
      currentClient: {},
      appointments: [],
      group: [],
    }
  });
};

export const selectClient = (client: Client) => (dispatch: Dispatch, getStore: GetStore) => {
  getCurrentClient(client)(dispatch, getStore);
  return dispatch({
    type: actionTypes.CLIENTS_SELECT,
    data: {
      busy: false,
      state: 'CLIENT_SELECT',
    }
  });
};

type Data = {
  page?: number;
  perPage?: number;
  query: {
    status?: string[];
    firstName?: string;
    lastName?: string;
    sortBy?: {
      direction: string;
      field: string
    }[];
    isLead?: boolean;
  }
}

const CancelToken = axios.CancelToken;
let cancel: (v?: string) => void;
export const getClients = (data: Data, history?: History) => {
  let params = data;
  if (data.query?.status?.length === 0) {
    params = {
      ...data,
      query: {
        ...data.query,
        status: undefined
      }
    };
  }
  if (params.query) {
    params.query = {
      ...params.query,
      sortBy: [{
        direction: 'Asc',
        field: 'LastName'
      }, {
        direction: 'Asc',
        field: 'FirstName'
      }],
      isLead: false
    };
  }
  if (cancel)
    cancel('User aborted request.');
  return remoteAction({
    type: at.CLIENTS_REMOTE_GET,
    action: () => api.post('client/query', params, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
      })
    }).then((clients) => {
      return clients;
    }).catch((error) => {
      if (error.response) {
        if (R.pathOr(null, ['query', 'phone'])(data) === undefined && error.response.status)
          popup('Error!', 'Failed to get clients.');
        console.error(error);
      } else if (axios.isCancel(error)) {
        throw error;
      } else {
        history?.push('/offline');
      }
    }),
  });
};

export const back = () => (dispatch: Dispatch) =>
  backToClient()(dispatch);

export const getTags = () => remoteAction({
  type: at.CLIENTS_REMOTE_GET_TAGS,
  action: () => api.get('client/tag'),
});
