import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 12,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  icon: {
    width: 17,
    height: 17,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  formControl: {
    width: '100%',
  },
  button: {
    padding: 6,
    border: '1px solid transparent',

    '&:hover': {
      border: '1px solid #F2F2F3',
      background: '#F2F2F3'
    },
  },
  active: {
    background: '#D6F3D5',

    '&:hover': {
      border: '1.5px solid rgba(0,0,0,0.1)',
      background: '#D6F3D5'
    },
  },
  input: {
    padding: 0,
    marginRight: 5,
    width: '100%',

    '& .MuiInput-input': {
      padding: 0,
    },
    '& .MuiInputBase-root': {
      borderRadius: 10,
      width: 320,
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 5,
    }
  },
  closeButton: {
    padding: 4,
    marginRight: -5,

    '& svg': {
      fontSize: 14,
    }
  },
}));
