import React from 'react';
import { useDispatch } from 'react-redux';

import { Snackbar, IconButton, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from '../../reducers';

import { closeAlert } from './alerts.actions';
import { useStyles } from './alerts.styles';

const Alerts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const alerts = useSelector((state) => state.alerts);

  const handleClose = (id: number) => {
    dispatch(closeAlert(id));
  };

  return (
    <section className={classes.root}>
      {alerts.map((alert, index) => (
        <Snackbar
          key={`${alert.message}-${index}`}
          style={{ marginBottom: index * 60 }}
          open
          autoHideDuration={3000}
        >
          <div>
            <Alert severity={alert.severity} elevation={6} variant="filled">
              {alert.message}
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                className={classes.close}
                onClick={() => handleClose(alert.id)}
              >
                <CloseIcon />
              </IconButton>
            </Alert>
          </div>
        </Snackbar>
      ))}
    </section>
  );
};

export default Alerts;
