import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Paper, Button, FormControlLabel, Checkbox, InputLabel, FormControl,
  Select as RawSelect, MenuItem, IconButton, InputAdornment,
  Popover, Divider, FormHelperText, ListSubheader,
  Snackbar, Alert as MuiAlert, Autocomplete, DialogContentText, Chip,
  createTheme, ThemeProvider
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { TextField } from '../../../../components/components.component';
import { HexColorPicker } from 'react-colorful';
import { HTMLEditor } from './HTMLEditor.jsx';
import {
  ascend, assocPath, assoc, evolve, merge, map, join, pipe, defaultTo,
  toPairs, filter, pathOr, sort, prop, sortBy, remove, insert,
  includes, isEmpty, last,
} from 'ramda';
import AddIcon from '@mui/icons-material/Add';
import { PageHeader } from '../../../../components/PageHeader';
import Header from '../../../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import Loading from '../../../../components/Loading/Loading.component';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import InfoIcon from '@mui/icons-material/Info';
import OpenInNewTabIcon from '../../../../icons/OpenInNewTab.icon';
import {
  fetchSettings, saveSettings, saveImage, deleteImage, fetchLink,
  linkPortal, unlinkPortal, fetchSettingsList, createSettings,
  defaultEmailBodyLandingPageDescription, defaultConfirmationDescription,
  defaultCalendarDirections, defaultContactDirection,
} from './NewPatientPortal.actions.js';
import { ApptTypeCard } from './ApptTypeCard.js';
import { usePromise } from '../../../../services/promise.hook';
import WarningRectangle from '../../../../components/WarningRectangle/WarningRectangle.component';
import Modal from '../../../../components/Modal/Modal.component';
import CheckedIcon from '../../../../icons/Checked.icon';
import UncheckedIcon from '../../../../icons/Unchecked.icon';
import HideProfessionalModal from './components/HideProfessionalModal.component';

export const sparkCheckboxTheme = createTheme({
  palette: {
    primary: {
      main: '#fa531b',
    },
  },
  components: {
    MuiCheckbox: {
      defaultProps: {
        icon: <UncheckedIcon />,
        checkedIcon: <CheckedIcon />,
      },
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            width: 21,
            height: 21,
          }
        },
      }
    },
  },
});


const PreAlert = withStyles({
  root: {
    fontSize: '16px'
  },
})(MuiAlert);

function Alert(props) {
  return <PreAlert elevation={6} variant="filled" {...props} />;
}

const Select = withStyles({
  root: {
    width: '300px',
  },
})(RawSelect);

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px',
    paddingTop: 10,
    overflowY: 'auto',
  },
  paper: {
    padding: '20px',
    marginBottom: '20px'
  },
  formControl: {
    margin: '10px 0'
  },
  minWidth: {
    minWidth: 256
  },
  addApptCodeContainer: {
    display: 'flex',
    alignItems: 'end'
  },
  input: {
    display: 'none',
  },
  wideFields: {
    '& .MuiTextField-root': {
      width: '600px'
    }
  },
  divider: {
    marginTop: '40px',
    marginBottom: '40px'
  },
  warningMargin: {
    marginTop: 10,
  },
  code: {
    overflowX: 'auto',
    backgroundColor: 'rgba(0,0,0,0.1)',
    width: 'calc(100vw - 100px - 240px)',
    maxHeight: 420,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  iconred: {
    fontSize: 50,
  },
  infoIconButton: {
    marginLeft: -20,
  },
}));

const makeEmbedCode = (urlBase = '', key = '') => `
<style>
  .sked-portal-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 60%;
  }

  @media screen and (max-width: 900px) {
    .sked-portal-container {
      padding-top: 100%;
    }
  }

  @media screen and (max-width: 750px) {
    .sked-portal-container {
      padding-top: 310%;
    }
  }
</style>

<div class="sked-portal-container" style="">
  <iframe id="sked-portal" sandbox="allow-top-navigation allow-scripts allow-forms allow-same-origin allow-popups" style="border: none; position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height: 100%;" src="${urlBase}new-patient?key=${key}">
  </iframe>
</div>
`;

const HLCode = `
<script>
  var listenFunction = (event)  => {
    var data = JSON.parse(event.data);
    if(data.actionType === 'get-user') {
      var contact = localStorage._ud;
      document
        .getElementById('sked-portal')
        .contentWindow
        .postMessage(contact, event.origin);
    }
    else if(data.actionType === 'confirm') {
      const inputs = document.querySelectorAll('#_builder-form input');
      const nameInput = inputs[0];
      const emailInput = inputs[1];
      const scheduledInput = inputs[2];
      const scheduledNiceInput = inputs[3];
      if(data.payload) {
        nameInput.value = data.payload.name;
        emailInput.value = data.payload.email;
        scheduledInput.value = data.payload.systemDate;
        scheduledNiceInput.value = data.payload.date;

        nameInput.dispatchEvent(new Event('input', { bubbles: true }));
        emailInput.dispatchEvent(new Event('input', { bubbles: true }));
        scheduledInput.dispatchEvent(new Event('input', { bubbles: true }));
        scheduledNiceInput.dispatchEvent(new Event('input', { bubbles: true }));
      }
      setTimeout(() => {
        document.querySelector('#_builder-form button').click();
      });
    }
  };

  window.addEventListener("message", listenFunction, false);
</script>
`;

const mapSettings = ({ settings, appointmentTypes }) => {
  const apptTypes = appointmentTypes;
  return evolve({
    newClient: pipe(
      map(s => {
        const appointmentType = apptTypes.find(at => at.id === s.appointmentTypeId);
        return assoc('appointmentType', appointmentType, s);
      }),
      sortBy(a => a.index)
    ),
    referrals: pipe(defaultTo(''), join('\n'))
  }, settings);
};

const urlBase = process.env.PORTAL_URL + '/';

const LinkingSection = ({
  linkedPortal, nppType, setLinkedPortal, save, history,
}) => {
  const [localPortal, setLocalPortal] = useState(linkedPortal);
  const [open, setOpen] = useState(false);
  const [busy, setBusy] = useState(false);
  const portalsState = usePromise(fetchSettingsList, []);
  const creationState = usePromise(createSettings, null);

  const openModal = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    if (nppType)
      portalsState.invoke(nppType);
    //    setLocalPortal(linkedPortal);
  }, [nppType]);

  React.useEffect(() => {
    setLocalPortal(linkedPortal);
  }, [linkedPortal]);

  const handleChange = (_, t) => {
    setLocalPortal(last(t));
    setLinkedPortal(last(t));
  };

  const handleDelete = () => {
    setLocalPortal(null);
    setLinkedPortal(null);
  };

  const saveAndRedirect = async () => {
    setBusy(true);
    try {
      await save();
    } catch (e) {
      console.log('failed: ', e);
    }
    setBusy(false);
    setOpen(false);
    creationState.invoke({
      name: 'New Portal', nppType: nppType === 'New' ? 'Existing' : 'New',
    }).then((data) => {
      history.push('/settings/new-patient-portal/' + data.newPatientPortalSettingsId);
    });
  };

  return (
    <>
      <FormControl>
        <Autocomplete
          id='portal-selector'
          disabled={isEmpty(portalsState.data)}
          getOptionKey={(option) => {
            return option?.newPatientPortalSettingsId;
          }}
          getOptionSelected={(option, value) => {
            return option?.newPatientPortalSettingsId === value.newPatientPortalSettingsId;
          }}
          getOptionLabel={(option) => {
            return option?.name + '<>' + option?.newPatientPortalSettingsId;
          }}
          options={portalsState.data}
          renderOption={(props, option) => {
            return (
              <MenuItem {...props} style={{
                backgroundColor:
                  localPortal?.newPatientPortalSettingsId === option?.newPatientPortalSettingsId ? '#ebf6fb' : 'unset',
              }}>
                {option?.name}
              </MenuItem>
            );
          }}
          multiple
          value={localPortal ? [localPortal] : []}
          onChange={handleChange}
          renderTags={(value, tagProps) => {
            return (
              <Chip
                {...tagProps}
                label={value[0].name.split('<>')[0]}
                onDelete={handleDelete}
                style={{ marginBottom: 3, marginRight: 3 }}
              />
            );
          }}
          renderInput={(params) => {
            const newValue = params.inputProps.value.split('<>')[0];
            const newparams = {
              ...params,
              inputProps: {
                ...params.inputProps,
                value: newValue,
              },
            };
            return (
              <TextField
                {...newparams}
                placeholder={isEmpty(portalsState.data) ? `No ${nppType === 'New' ? 'Existing' : 'New'} Patient Portals` : 'Search for a portal'}
                style={{
                  width: '100%',
                }}
              />
            );
          }}
        />
        <FormHelperText>
          {nppType === 'New' ?
            'If patients come to this New Patient Portal and are existing patients, you can give them an option to sign in with a different Existing Patient Portal'
            :
            'If patients come to this Existing Patient Portal and are new patients, you can give them an option to sign up with a different New Patient Portal'}
        </FormHelperText>
      </FormControl>
      {isEmpty(portalsState.data) &&
        <Button
          variant='text'
          onClick={openModal}
        >
          Create {nppType === 'New' ? 'Existing' : 'New'} Patient Portal
        </Button>}
      <Modal
        title="Are you sure?"
        size="xs"
        open={open}
        onClose={() => setOpen(false)}
        className='sked-test-npp-save-and-continue-modal'
        buttons={[
          <HeaderButton
            title='Save & Continue'
            onClick={saveAndRedirect}
            disabled={busy}
            color='primary'
            className='sked-test-npp-save-and-continue-modal-button-save-and-continue'
          />
        ]}
      >
        <div>
          <DialogContentText>
              Are you sure you want to save this portal and continue onward to make a new one?
          </DialogContentText>
        </div>
      </Modal>
    </>
  );
};

const NewPatientPortalSettings = ({ match, history }) => {
  const classes = useStyles();
  const [_image, setImage] = useState(null);
  const [display, setDisplay] = useState(null);
  const [state, setState] = useState({
    isEnabled: false,
    name: '',
    appointmentTypeId: '',
    newClient: [],
    showLogo: false,
    logoUrl: '',
    showName: false,
    hidePro: true,
    scheduleNewPatient: true,
    primaryColor: '',
    secondaryColor: '',
    backgroundColor: '',
    landingPageDescription: '',
    confirmationDescription: '',
    calendarDirections: '',
    contactDirection: '',
    referrals: '',
    key: '',
    allowSameDayScheduling: false,
    birthdayOption: 'Required',
    isSpark: false,
  });

  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [proApptTypes, setProApptTypes] = useState({});
  const [pros, setPros] = useState([]);
  const [busy, setBusy] = useState(false);
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [linkedPortal, setLinkedPortal] = useState(null);
  const [copied, setCopied] = useState(null);

  const linkState = usePromise(linkPortal, null);
  const fetchState = usePromise(fetchLink, null);
  const unlinkState = usePromise(unlinkPortal, null);

  const handleLink = async () => {
    const newId = linkedPortal && linkedPortal.newPatientPortalSettingsId;
    const oLinkedPortal = fetchState.data || {};
    if (oLinkedPortal && newId &&
        oLinkedPortal.newPatientPortalSettingsId &&
        newId !== oLinkedPortal.newPatientPortalSettingsId) {
      await unlinkState.invoke({
        currentPortalId: state.newPatientPortalSettingsId,
        linkedPortalId: oLinkedPortal.newPatientPortalSettingsId,
      });
    }
    if (!newId) {
      try {
        await unlinkState.invoke({
          currentPortalId: state.newPatientPortalSettingsId,
          linkedPortalId: oLinkedPortal.newPatientPortalSettingsId,
        });
      } catch (e) {
        console.log('probably don\'t care: ', e);
      }
      return;
    }
    /*  TODO: remove this if it isn't necessary!
    let newPortalHasLink = null;

    if (newId) {
      try {
        newPortalHasLink = await fetchState.invoke(newId);
      } catch (e) {
        newPortalHasLink = null;
      }
    }
    if (newPortalHasLink) {
      await unlinkState.invoke({
        currentPortalId: newId,
        linkedPortalId: newPortalHasLink.newPatientPortalSettingsId,
      });
    } */
    if (newId !== oLinkedPortal.newPatientPortalSettingsId) {
      await linkState.invoke({
        currentPortalId: state.newPatientPortalSettingsId,
        linkedPortalId: newId,
      });
    }
  };

  const {
    ehrSystem, hasLead, hasEpp, sparkOnly, isAdmin, hasSpark,
  } = useSelector(s => ({
    ehrSystem: s.login.office.ehrSystem,
    hasLead: includes('Leads', pathOr([], ['login', 'features'], s)),
    hasEpp: includes('EPPortal', pathOr([], ['login', 'features'], s)),
    sparkOnly: includes('SparkUI', pathOr([], ['login', 'features'], s)),
    hasSpark: includes('Spark', pathOr([], ['login', 'features'], s)),
    isAdmin: s.login.admin,
  }));

  const uploadRef = React.useRef();

  React.useEffect(() => {
    if (sparkOnly && !state.isSpark) {
      patchStateCheckbox('isSpark');
    }
  }, [sparkOnly]);

  React.useEffect(() => {
    setBusy(true);
    if (hasEpp) {
      fetchState.invoke(match.params.id).then((npp) => {
        setLinkedPortal(npp);
      }).catch(() => {
        setLinkedPortal(undefined);
      });
    }
    fetchSettings(match.params.id).then(({
      apptTypesGroupped, apptTypes, settings, professionals
    }) => {
      setBusy(false);
      const mappedSettings = mapSettings({ settings, appointmentTypes: apptTypes });
      const filteredNewClients = evolve({
        newClient: filter(({ appointmentType }) => appointmentType),
      }, mappedSettings);
      setState(s => merge(s, filteredNewClients));
      setAppointmentTypes(apptTypes);
      setProApptTypes(apptTypesGroupped);
      setPros(professionals);
    }).catch(e => {
      setBusy(false);
      setErrorMessage(e.message);
    });
  }, [match.params.id]);

  const availableAppointmentTypes = React.useMemo(() => {
    return pipe(
      toPairs,
      map(([proId, apptTypes]) => {
        const pro = pros.find(({ id }) => Number(proId) === Number(id));
        return {
          ...pro,
          appointmentTypes: pipe(
            filter(apt => !state.newClient.some(sat => sat.appointmentTypeId === apt.id)),
            sort(ascend(prop('internalName')))
          )(apptTypes)
        };
      }),
      filter(({ isHidden, appointmentTypes }) => !isHidden && appointmentTypes.length > 0),
      sort(ascend(prop('displayLastName')))
    )(proApptTypes);
  }, [proApptTypes, state.newClient, pros]);

  const removeApptType = (id) => {
    const settingAppointmentTypes = state.newClient.filter(sat => sat.appointmentTypeId !== id);
    setState(s => ({
      ...s,
      newClient: settingAppointmentTypes
    }));
  };

  const addApptType = () => {
    const id = Number(state.appointmentTypeId);
    const appointmentType = appointmentTypes.find(at => at.id === id);

    if (!appointmentType) {
      return;
    }
    const settingAppointmentTypes = state.newClient.concat([{
      appointmentType,
      appointmentTypeId: appointmentType.id,
      index: state.newClient.length,
      apptTypeDescription: '',
    }]);

    setState(s => ({
      ...s,
      newClient: settingAppointmentTypes,
      appointmentTypeId: ''
    }));
  };

  const moveCard = (dragIndex, hoverIndex) => {
    console.log(dragIndex, hoverIndex);
    setState(evolve({
      newClient: a => pipe(
        remove(dragIndex, 1),
        insert(hoverIndex, a[dragIndex]),
        aa => aa.map((item, index) => assoc('index', index, item))
      )(a)
    }));
  };


  const patchStateInput = (prop = '') => e => {
    const value = e.target.value;
    const path = prop.split('.');
    setState(assocPath(path, value));
  };

  const patchStateCheckbox = prop => e => {
    const value = e.target.checked;
    setState(s => ({
      ...s,
      [prop]: value
    }));
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [colorProp, setColorProp] = useState(null);
  const [showHLCode, setShowHLCode] = useState(false);

  const handleClick = prop => (event) => {
    setColorProp(prop);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popOverId = open ? 'simple-popover' : undefined;

  const tinyOptions = {
    height: 500,
    menubar: true,
    plugins: ['advlist autolink lists link charmap anchor',
      'searchreplace visualblocks code ',
      'insertdatetime paste emoticons'
    ],
    toolbar: 'undo redo | formatselect | bold italic | bullist numlist | link | emoticons',
  };

  const getMessage = e => {
    return pathOr(e.message, ['response', 'data'], e);
  };

  const save = async () => {
    setSaving(true);
    setErrorMessage('');
    await handleLink();
    await saveSettings(state).then(settings => {
      setSaving(false);
      const mappedSettings = mapSettings({ settings, appointmentTypes });
      showSaveSucess();
      setState(s => merge(s, mappedSettings));
    }).catch(e => {
      setSaving(false);
      setErrorMessage(getMessage(e));
    });
  };

  const handleFile = (e) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setDisplay(e.target.result);
    };
    reader.readAsDataURL(e.target.files[0]);
    const img = e.target.files[0];
    setImage(img);
    setErrorMessage('');
    saveImage(match.params.id, img).then(({ data }) => {
      showSaveSucess();
      const logoUrl = data.location;
      setDisplay(null);
      setState(s => ({ ...s, logoUrl }));
    }).catch(e => {
      setErrorMessage(e.message);
    });
    uploadRef.current.value = null; // prevent chrome from blocking uploading the same file many times
  };

  const handleImageRemove = () => {
    setErrorMessage('');
    deleteImage(match.params.id).then(() => {
      showSaveSucess();
      setState(s => ({
        ...s,
        logoUrl: ''
      }));
    }).catch(e => {
      setErrorMessage(e.message);
    });
  };

  const [isSaveSnackbarOpen, setIsSaveSnackbarOpen] = useState(false);
  const [hidePro, setHidePro] = useState(false);

  const closeSaveSnackBar = () => setIsSaveSnackbarOpen(false);
  const showSaveSucess = () => setIsSaveSnackbarOpen(true);

  const [warningTitle, warningBody] = React.useMemo(() => {
    const word = state.nppType === 'New' ? 'New' : 'Existing';
    const opposite = state.nppType === 'New' ? 'Existing' : 'New';
    const grammer = `a${opposite === 'New' ? '' : 'n'}`;
    return [
      `${opposite} Patients won't be able to schedule`,
      `If ${opposite.toLowerCase()} patients come to your ${word} Patient Portal, they won't be able to schedule. Fix this by linking ${grammer} ${opposite} Patient Portal here.`
    ];
  }, [state.nppType]);

  const portalUrl = React.useMemo(() => {
    return `${urlBase}new-patient?key=${state.key}`;
  }, [state.key]);

  const openPortal = () => {
    window.open(portalUrl, '_target');
  };

  const copyLink = () => {
    const input = document.createElement('textarea');
    input.innerHTML = portalUrl;
    document.body.appendChild(input);
    input.select();
    const result = document.execCommand('copy');
    document.body.removeChild(input);
    if (!isEmpty(result))
      setCopied(true);
  };

  const openHideProInfo = () => {
    setHidePro(true);
  };

  const closeHideProInfo = () => {
    setHidePro(false);
  };

  if (busy) {
    return <div className={classes.root}>
      {busy && <Loading loading />}
    </div>;
  }

  return (
    <>
      <Header
        title={`Edit ${state.nppType} Patient Portal`}
        rightIcons={[
          <HeaderButton
            title='View Portal'
            onClick={openPortal}
            Icon={() => <OpenInNewTabIcon color='#4d4d4d'/>}
            className='sked-test-npp-view-portal-button-save-and-continue'
          />,
          <HeaderButton
            title='Save'
            color='primary'
            disabled={saving}
            onClick={save}
          />
        ]}
      />
      <div className={classes.root}>
        <HideProfessionalModal
          open={hidePro}
          onClose={closeHideProInfo}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={isSaveSnackbarOpen}
          autoHideDuration={5e3}
          onClose={closeSaveSnackBar}>
          <div>
            <Alert severity="success">Saved settings!</Alert>
          </div>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={copied}
          autoHideDuration={5e3}
          onClose={() => setCopied(false)}>
          <div>
            <Alert severity="success">Copied Portal Link!</Alert>
          </div>
        </Snackbar>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          message={errorMessage}
          open={!!errorMessage}
          autoHideDuration={5e3}
          onClose={() => setErrorMessage('')}>
          <div>
            <Alert severity="error">{errorMessage}</Alert>
          </div>
        </Snackbar>
        <Paper className={classes.paper}>
          <PageHeader variant="h6">
            General
          </PageHeader>

          <div className={classes.formControl + ' ' + classes.wideFields}>
            <TextField
              id='name'
              name='name'
              label='Name'
              onFocus={(e) => {
                if (e.target.value === 'Untitled Portal') {
                  e.target.select();
                }
              }}
              InputLabelProps={{ shrink: true }}
              onChange={patchStateInput('name')}
              value={state.name || ''} />
          </div>

          {state.nppType === 'New' && isAdmin && hasSpark &&
          <div className={classes.formControl}>
            <FormControlLabel
              control={
                <ThemeProvider theme={sparkCheckboxTheme}>
                  <Checkbox
                    checked={state.isSpark}
                    onChange={patchStateCheckbox('isSpark')}
                    name="isSpark"
                  />
                </ThemeProvider>
              }
              label="Check this box to track patients that schedule with this portal. Spark billing applies to these patients."
            />
            <FormHelperText>
              Check this if you want this portal to support Spark features.
            </FormHelperText>
          </div>}

          <div className={classes.formControl}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.allowSameDayScheduling}
                  onChange={patchStateCheckbox('allowSameDayScheduling')}
                  name="allowSameDayScheduling"
                />
              }
              label="Allow same day scheduling"
            />
            <FormHelperText>
              Check this if you want to allow {state.nppType === 'New' ? 'new' : ''} patients to schedule an appointment on the same day they are making the appointment.
            </FormHelperText>
          </div>

          {state.nppType === 'New' &&
           <div className={classes.formControl}>
             <FormControlLabel
               control={<Checkbox disabled={ehrSystem === 'Platinum'} checked={state.scheduleNewPatient} onChange={patchStateCheckbox('scheduleNewPatient')} name="scheduleNewPatient" />}
               label="Schedule appointment directly into schedule"
             />
             <FormHelperText>
              Check this if you want the appointments to save into your calendar and sync directly into your EHR system.<br />
               <>
                If it&#39;s unchecked, new patients will <em>request</em> an appointment. {`${hasLead ? 'They will be added as a lead and you will receive an email notification' : 'You\'ll receive an email notification for the people who want to schedule an appointment'}`}.
                 {ehrSystem === 'Platinum' && <><br /><br />The old platinum syncer does not support this feature.</>}
               </>
             </FormHelperText>
           </div>}


          {state.nppType === 'New' &&
            <div className={classes.formControl}>
              <FormControl className={`${classes.formControl} ${classes.minWidth}`}>
                <InputLabel id="birthday-option">Birthday Field Options</InputLabel>
                <Select
                  labelId="birthday-option-label"
                  id="birthday-option"
                  value={state.birthdayOption}
                  onChange={patchStateInput('birthdayOption')}
                >
                  <MenuItem value="Required">Required</MenuItem>
                  <MenuItem value="Optional">Optional</MenuItem>
                  <MenuItem value="Hidden">Hidden</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText>Select how you want the birthday input to be handled in the portal.</FormHelperText>
            </div>}

          <Divider className={classes.divider} />

          <PageHeader variant="h6">
            Available Appointment Types on the Portal
          </PageHeader>

          <div className={classes.formControl + ' ' + classes.addApptCodeContainer}>
            <FormControl className={classes.minWidth}>
              <InputLabel id="demo-mutiple-checkbox-label">Appointment Types</InputLabel>
              <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                value={state.appointmentTypeId || ''}
                onChange={patchStateInput('appointmentTypeId')} >
                <MenuItem disabled value=''>
                  Select an appointment type
                </MenuItem>
                {availableAppointmentTypes.map((pro) => ([
                  <ListSubheader key={pro.id}>{pro.displayFirstName} {pro.displayLastName}</ListSubheader>,
                  pro.appointmentTypes.map(apt => <MenuItem key={apt.id} value={apt.id}>{apt.internalName} - {apt.name}</MenuItem>)
                ]))}
              </Select>
              {state.nppType === 'Existing' ?
                <FormHelperText className={classes.minWidth} style={{ maxWidth: '400px' }}>
                  Use this setting to restrict available appointment types.
                  If no appointment types are selected here, the scheduling
                  availability uses the patient's default appointment type.
                  This is specific to <b>Existing</b> Patient Portals.
                </FormHelperText> :
                <FormHelperText className={classes.minWidth} style={{ maxWidth: '400px' }}>
                  Use this setting to restrict available appointment types.
                  If no appointment types are selected here, patients will
                  not be able to schedule.
                  This is specific to <b>New</b> Patient Portals.
                </FormHelperText>}
            </FormControl>
            <Button
              style={state.nppType === 'New' ? {
                marginLeft: '10px'
              } : {
                marginLeft: '10px',
                alignSelf: 'flex-start',
                marginTop: '15px',
              }}
              disabled={!state.appointmentTypeId}
              onClick={addApptType}
              endIcon={<AddIcon />}
              variant='contained'
              aria-label="add appointment type">
              Add
            </Button>
          </div>

          {state.newClient.map((sat, i) =>
            <ApptTypeCard key={sat.appointmentTypeId} sat={sat} pros={pros} removeApptType={removeApptType} moveCard={moveCard} index={i} />
          )}

          <div className={classes.formControl}>
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                control={<Checkbox checked={state.hidePro} onChange={patchStateCheckbox('hidePro')} name="hidePro" />}
                label="Hide professional name"
              />
              <IconButton className={classes.infoIconButton} onClick={openHideProInfo}>
                <InfoIcon style={{ fontSize: 16 }} />
              </IconButton>
            </div>
            <FormHelperText>Check this if you want to hide the professional name from the appointment selection list.</FormHelperText>
          </div>

          <Divider className={classes.divider} />

          <PageHeader variant="h6">
            Branding
          </PageHeader>

          {display && <div className={classes.formControl}>
            Image to upload
            <br />
            <img width='400' src={display} />
          </div>}

          {state.logoUrl && <div className={classes.formControl}>
            Current Image
            <br />
            <img width='400' src={state.logoUrl} />
          </div>}

          <div className={classes.formControl} style={{ marginBottom: 20 }}>
            <input
              accept="image/*"
              onChange={handleFile}
              className={classes.input}
              id="contained-button-file"
              type="file"
              ref={uploadRef}
            />
            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span">
                Upload Logo
              </Button>
            </label>

            {state.logoUrl && <Button onClick={handleImageRemove} variant="contained" color="error" style={{ marginLeft: '10px' }}>
              Delete Logo
            </Button>}
          </div>

          <div className={classes.formControl}>
            <TextField
              id='primary-color'
              name='primary-color'
              label='Primary Color'
              onChange={patchStateInput('primaryColor')}
              style={{ width: 205, marginBottom: 10 }}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClick('primaryColor')}>
                      <ColorLensIcon style={{ color: state.primaryColor }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={state.primaryColor || ''} />
          </div>

          <div className={classes.formControl}>
            <TextField
              id='background-color'
              name='background-color'
              label='Background Color'
              onChange={patchStateInput('backgroundColor')}
              value={state.backgroundColor || ''}
              style={{ width: 205 }}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClick('backgroundColor')}>
                      <ColorLensIcon style={{ color: state.backgroundColor }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <Divider className={classes.divider} />

          {state.nppType === 'New' &&
            <>
              <PageHeader variant="h6">
                Referrals
              </PageHeader>

              <div className={classes.formControl}>
                <TextField
                  multiline
                  id='referrals'
                  name='referrals'
                  variant="outlined"
                  rows={3}
                  onChange={patchStateInput('referrals')}
                  value={state.referrals || ''}
                  InputLabelProps={{ shrink: true }}
                />
                <FormHelperText>
              Add one per line. Enter "Other" to allow patients to
              add in their own referral! Leave it blank to hide this
              field on the portal.
                </FormHelperText>
              </div>

              <Divider className={classes.divider} />
            </>}

          <PageHeader variant="h6">
            Custom Directions
          </PageHeader>

          <div className={classes.formControl + ' ' + classes.wideFields}>
            <div>Time Picker Directions</div>
            <TextField
              id='time-directions'
              name='time-directions'
              multiline
              minRows={1}
              placeholder='"Select appointment type then date and time"'
              onChange={patchStateInput('calendarDirections')}
              value={state.calendarDirections || ''}
              InputLabelProps={{ shrink: true }}
              onFocus={(e) => {
                if (e.target.value === defaultCalendarDirections) {
                  e.target.select();
                }
              }}
            />
            <FormHelperText>Directions for the first page of the portal where the user picks their appointment type and date and time</FormHelperText>
          </div>

          <div className={classes.formControl + ' ' + classes.wideFields} style={{ marginTop: '20px' }}>
            <div>Contact Page Directions</div>
            <TextField
              id='contact-directions'
              name='contact-directions'
              multiline
              minRows={1}
              placeholder='"Enter in your contact details"'
              onChange={patchStateInput('contactDirection')}
              value={state.contactDirection || ''}
              InputLabelProps={{ shrink: true }}
              onFocus={(e) => {
                if (e.target.value === defaultContactDirection) {
                  e.target.select();
                }
              }}
            />
            <FormHelperText>Directions for the second page of the portal where the user enters contact details</FormHelperText>
          </div>

          <Divider className={classes.divider} />

          <PageHeader variant="h6">
            Landing Page Description
          </PageHeader>

          <FormHelperText style={{ marginBottom: '10px' }}>
            This text will be displayed on the portal underneath your office logo. <br />
            Example: <em>"Welcome! We're so glad you're here. We look forward to meeting you!"</em>
          </FormHelperText>

          <HTMLEditor
            id="landing-page-description"
            value={state.landingPageDescription}
            onChange={(b) => setState(assoc('landingPageDescription', b))}
            init={tinyOptions}
            placeholders={[]}
            onFocus={(editor) => {
              const value = editor.getHTML();
              console.log(value, defaultEmailBodyLandingPageDescription);
              if (value === defaultEmailBodyLandingPageDescription) {
                editor.commands.selectAll();
              }
            }}
          />

          <PageHeader variant="h6" style={{ marginTop: '20px' }}>
            Confirmation Page Description
          </PageHeader>

          <FormHelperText>
            This text will be displayed on the appointment confirmation page after the new patient schedules their appointment.
            Example: <br /> <em>"Thank you for booking your appointment! We are excited to meet you. "<br />
              "Thank you for requesting an appointment! We will reach out to you within 12 hours to confirm your appointment time."</em>
          </FormHelperText>

          <HTMLEditor
            id="confirmation-page-description"
            value={state.confirmationDescription}
            onChange={b => setState(assoc('confirmationDescription', b))}
            init={tinyOptions}
            placeholders={[
              { name: 'First Name', value: '{firstName}' },
              { name: 'Last Name', value: '{lastName}' },
              { name: 'Email', value: '{email}' },
              { name: 'Phone', value: '{phone}' },
            ]}
            onFocus={(editor) => {
              const value = editor.getHTML();
              console.log(value, defaultConfirmationDescription);
              if (value === defaultConfirmationDescription) {
                editor.commands.selectAll();
              }
            }}
          />

          <Divider className={classes.divider} />

          {hasEpp &&
            <>
              <PageHeader variant="h6">
                Link to {state.nppType === 'New' ? 'Existing' : 'New'} Patient Portal
              </PageHeader>

              <LinkingSection
                currentPortalId={state.newPatientPortalSettingsId}
                linkedPortal={linkedPortal}
                nppType={state.nppType}
                setLinkedPortal={setLinkedPortal}
                save={save}
                history={history}
              />
              {console.log(linkedPortal)}
              <WarningRectangle
                open={!linkedPortal}
                title={warningTitle}
                body={warningBody}
                className={classes.warningMargin}
                type='warning'
              />
              <Divider className={classes.divider} />
            </>}

          <PageHeader variant="h6">
            URL to Portal
          </PageHeader>

          <div className={classes.formControl + ' ' + classes.wideFields}>
            <TextField
              value={portalUrl}
              disabled
              style={{
                marginBottom: '10px',
              }}
            />
            <br/>
            <Button
              variant='outlined'
              onClick={copyLink}
              color="secondary"
              style={{

              }}
            >
              Copy Portal Link
            </Button>
          </div>

          <PageHeader variant="h6">
            Embedding Code
          </PageHeader>

          {state.nppType === 'New' &&
            <div className={classes.formControl}>
              <FormControlLabel
                control={<Checkbox checked={showHLCode} onChange={() => setShowHLCode(s => !s)} name="showHLCode" />}
                label="Embedding in Highlevel Page"
              />
              <FormHelperText>Check this if you are embedding this code on a Highlevel landing page.</FormHelperText>
            </div>}

          <div className={classes.formControl}>
            <pre className={classes.code}>
              {makeEmbedCode(urlBase, state.key)}
              {showHLCode ? HLCode : ''}
            </pre>
            <FormHelperText>
              Copy this code into your website to embed it.
            </FormHelperText>
          </div>

          <Divider />

          <Popover
            id={popOverId}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }} >
            <div style={{ padding: '20px' }}>
              <HexColorPicker color={state[colorProp] || ''} onChange={c => setState(assoc(colorProp, c))} />
            </div>
          </Popover>
        </Paper>
      </div>
    </>
  );
};

export default NewPatientPortalSettings;
