import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  TextField, Snackbar, Checkbox, Select, InputAdornment, FormControl,
  InputLabel, IconButton, Menu, MenuItem, Tooltip, Paper, TableBody,
  TableRow, TableHead, Box
} from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import CachedIcon from '@mui/icons-material/Cached';
import Search from '@mui/icons-material/Search';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import DeleteIcon from '@mui/icons-material/Delete';
import BuildIcon from '@mui/icons-material/Build';
import * as R from 'ramda';
import OfficeGroups from './officeGroups.component';
import { History } from 'history';
import * as loginActions from '../../../Login/login.actions';
import { Office } from '../../../Login/login.reducer';
import { useSelector } from '../../../../reducers';
import OfficeFeatures from '../../../Admin/office-features.component';
import { TableContainer, HeaderCell, BodyCell } from '../../../../components/CustomTable';
import { useStyles } from './selectOffice.styes';
import SparkIcon from '../../../../icons/Spark.icon';

const searchOptions = [
  { value: '', label: 'All' },
  { value: 'ChiroTouch', label: 'ChiroTouch' },
  { value: 'ChiroHD', label: 'ChiroHD' },
  { value: 'Genesis', label: 'Genesis' },
  { value: 'None', label: 'None' },
  { value: 'Platinum', label: 'Platinum' },
  { value: 'PlatinumApi', label: 'Platinum API' },
];

type OfficeItemProps = {
  office: Office;
  admin: boolean;
  selectOffice: (office: Office) => void;
  resync: (event: React.MouseEvent<HTMLButtonElement>) => void;
  checkDeleted: (obj: object) => void;
  openGroupDialog: (obj: object) => void;
  openFeaturesDialog: (obj: object) => void;
  destroyServer: (obj: object) => void;
  rebuildServer: (obj: object) => void;
}

const OfficeItem = ({
  office,
  admin,
  selectOffice,
  resync,
  checkDeleted,
  openGroupDialog,
  openFeaturesDialog,
  destroyServer,
  rebuildServer,
}: OfficeItemProps) => {
  return (
    <TableRow key={office.id}>
      <BodyCell className="td-name">
        {office.id}
      </BodyCell>
      <BodyCell className="td-name">
        <a
          style={office.plan === 'SkedUnsubscribed' ? { color: 'gray' } : {}}
          onClick={() => selectOffice(office)}>
          {office.name}
        </a>
      </BodyCell>
      <BodyCell className="td-name">
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
        }}>
          <div style={{
            marginRight: 3,
          }}>
            {office.plan}
          </div>
          {office.isSpark && <SparkIcon />}
        </div>
      </BodyCell>
      <BodyCell className="td-name">
        {office.ehrSystem}
      </BodyCell>
      {admin && (
        <>
          <BodyCell className="td-name">
            <IconButton size="small" disabled={office.ehrSystem === 'None'} onClick={resync}>
              <CachedIcon fontSize='large' />
            </IconButton>
          </BodyCell>
          <BodyCell className="td-name">
            <IconButton size="small" disabled={office.ehrSystem === 'None'} onClick={() => checkDeleted({})}>
              <CachedIcon fontSize='large' />
            </IconButton>
          </BodyCell>
          <BodyCell className="td-name">
            <Tooltip arrow title='Destroy Server'>
              <IconButton
                size="small"
                disabled={office.ehrSystem !== 'PlatinumApi'}
                onClick={() => destroyServer({})}>
                <DeleteIcon fontSize='large' />
              </IconButton>
            </Tooltip>
            &nbsp;
            &nbsp;
            <Tooltip arrow title='Rebuild Server'>
              <IconButton
                size="small"
                disabled={office.ehrSystem !== 'PlatinumApi'}
                onClick={() => rebuildServer({})}>
                <BuildIcon fontSize='large' />
              </IconButton>
            </Tooltip>
          </BodyCell>
          <BodyCell className="td-name">
            <IconButton size="small" onClick={() => openGroupDialog({})}>
              <GroupIcon fontSize='large' />
            </IconButton>
          </BodyCell>
          <BodyCell className="td-name">
            <IconButton size="small" onClick={() => openFeaturesDialog({})}>
              <ShoppingCart fontSize='large' />
            </IconButton>
          </BodyCell>
        </>
      )}
    </TableRow>
  );
};

let timeout: NodeJS.Timeout;
function SelectOffice({ history }: { history: History }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [onlyActive, setOnlyActive] = useState(true);
  const [localState, setState] = useState('LIST');
  const [office, setOffice] = useState<Office>({} as Office);
  const searchEl = useRef(null);

  const {
    offices,
    allOffices,
    admin,
    agency,
    selected,
    state,
    system,
    query,
    snackbar,
    message,
    resyncLoading,
  } = useSelector((state) => ({
    offices: R.sortBy(R.prop('name'))(state.login.shownOffices),
    allOffices: state.login.offices,
    admin: state.login.admin,
    agency: state.login.agency,
    selected: state.login.selected,
    state: state.session.status,
    system: state.login.system,
    query: state.login.query,
    snackbar: state.login.snackbar,
    message: state.login.message,
    resyncLoading: state.login.resyncLoading
  }));

  const [localQuery, setLocalQuery] = useState(query);

  const getOffices = () => dispatch(loginActions.getOffices(agency));
  const selectOffice = (office: Office, his: History) => dispatch(loginActions.selectOffice(office, his));
  const filterOfficesByName = (e: loginActions.FilterOfficesByNameData) => dispatch(loginActions.filterOfficesByName(e));
  const resync = (e: loginActions.ResyncType) => dispatch(loginActions.resync(e));
  const checkDeleted = (e: loginActions.IdNameType) => dispatch(loginActions.checkDeleted(e));
  const patch = (e: loginActions.PatchData) => dispatch(loginActions.patch(e));
  const destroyServer = (e: loginActions.IdNameType) => dispatch(loginActions.destroyServer(e));
  const rebuildServer = (e: loginActions.IdNameType) => dispatch(loginActions.rebuildServer(e));

  useEffect(() => {
    patch({ office: {} as Office, query: '', system: '' });
    getOffices();
    searchEl.current.focus();
    setLocalQuery('');
  }, []);

  const filteredOffices = React.useMemo(() => {
    let o = offices;
    if (offices.length > 50) {
      o = R.take(50, offices);
    }
    return o.filter(({ plan }) => {
      return onlyActive ? plan !== 'SkedUnsubscribed' : plan === 'SkedUnsubscribed';
    });
  }, [offices, onlyActive]);

  const [menu, setMenu] = React.useState({
    anchorEl: null,
    id: null,
    name: null
  });

  const handleResyncMenuOpen = ({ id, name, event }: { id: number, name: string, event: React.MouseEvent<HTMLButtonElement> }) => {
    setMenu({ anchorEl: event.currentTarget, id, name });
  };

  const handleClose = () => {
    setMenu({ anchorEl: null, id: null, name: null });
  };

  const resyncWithOptions = ({ resyncClient = false, resyncAppts = false, resyncAll = false }) => {
    const resyncAllBody = { resyncClient: true, resyncAppts: true, resyncFromDays };
    const body = resyncAll ? resyncAllBody : { resyncClient, resyncAppts, resyncFromDays };
    return resync({ id: menu.id, name: menu.name, body });
  };

  const [resyncFromDays, setResyncFromDays] = React.useState(30);

  React.useEffect(() => {
    if (!resyncLoading)
      setMenu({ anchorEl: null, id: null, name: null });
  }, [resyncLoading]);

  useEffect(() => {
    filterOfficesByName({ query, system });
  }, [query, system]);

  return (
    <Paper className={`${classes.root} ${!admin ? classes.shortCard : ''}`}>
      {state === 'NOT_AUTHENTICATED' && <Redirect to="/login" />}
      {(!admin && !agency) && <Redirect to='/' />}
      <Snackbar
        open={snackbar}
        message={message}
        autoHideDuration={4000}
        onClose={() => patch({ snackbar: false })} />
      <OfficeGroups
        open={localState === 'OFFICE_GROUP'}
        office={office}
        offices={allOffices}
        onClose={() => {
          setState('LIST');
          setOffice({} as Office);
        }}
      />
      <OfficeFeatures
        open={localState === 'OFFICE_FEATURES'}
        office={office}
        onClose={() => {
          setState('LIST');
          setOffice({} as Office);
        }}
      />
      <Menu
        id="simple-menu"
        anchorEl={menu.anchorEl}
        keepMounted
        open={Boolean(menu.anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled={resyncLoading} onClick={() => resyncWithOptions({ resyncAll: true })}>Resync All</MenuItem>
        <MenuItem disabled={resyncLoading} onClick={() => resyncWithOptions({ resyncAppts: true })}>Resync Appointments</MenuItem>
        <MenuItem disabled={resyncLoading} onClick={() => resyncWithOptions({ resyncClient: true })}>Resync Clients</MenuItem>
        {admin &&
            <Box>
              <TextField
                label="Resync from X days ago"
                value={resyncFromDays}
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                onChange={(e) => {
                  setResyncFromDays(Number(e.target.value));
                }
                }
              />
            </Box>
        }
      </Menu>
      {admin && <h2 className={classes.title}>Offices</h2>}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '10px 0'
        }}>
        {admin ? (
          <div>
            <FormControl style={{ width: '300px' }}>
              <InputLabel id="filter-label">EHR System</InputLabel>
              <Select
                labelId="filter-label"
                id="filter-select"
                value={system}
                onChange={(e) => patch({ system: e.target.value as string })}
              >
                {searchOptions.map(({ value, label }) => <MenuItem key={value} value={value}>{label}</MenuItem>)}
              </Select>
            </FormControl>
            <label style={{
              display: 'flex',
              alignItems: 'center',
            }}>
              <Checkbox
                checked={!onlyActive}
                onChange={(e) => {
                  setOnlyActive(!e.target.checked);
                }}
              />
              Show Only Unsubscribed
            </label>
          </div>
        ) : (
          <h2 className={classes.title}>Offices</h2>
        )}

        <div className="search page">
          <TextField
            inputRef={searchEl}
            name="client-search"
            label="Search"
            variant='standard'
            value={localQuery}
            onChange={(e) => {
              const value = e.target.value;
              setLocalQuery(value);
              if (timeout) {
                clearTimeout(timeout);
              }
              timeout = setTimeout(() => {
                patch({ query: value });
              }, 200);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search style={{ fontSize: '16px' }} />
                </InputAdornment>
              ),
            }} />
        </div>
      </div>
      {!selected ?
        <TableContainer>
          <TableHead>
            <TableRow>
              <HeaderCell className={!admin ? classes.tdId : ''}>
                  ID
              </HeaderCell>
              <HeaderCell className={!admin ? classes.tdName : ''}>
                  Name
              </HeaderCell>
              <HeaderCell>
                  Plan
              </HeaderCell>
              <HeaderCell>
                  EHR System
              </HeaderCell>
              {admin && (
                <>
                  <HeaderCell>
                      Re-Sync
                  </HeaderCell>
                  <HeaderCell>
                      CheckDeleted
                  </HeaderCell>
                  <HeaderCell>
                      Platinum Syncer
                  </HeaderCell>
                  <HeaderCell>
                      Groups
                  </HeaderCell>
                  <HeaderCell>
                      Features
                  </HeaderCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredOffices.map((office) => {
              return (
                <OfficeItem
                  key={office.id}
                  office={office}
                  admin={admin}
                  selectOffice={(off) => selectOffice(off, history)}
                  // openMenu={handleResyncMenuOpen}
                  resync={(event) =>
                    ['PlatinumApi', 'ChiroHD'].some(ehr => office.ehrSystem === ehr) ?
                      handleResyncMenuOpen({ id: office.id, name: office.name, event }) :
                      resync({ id: office.id, name: office.name })
                  }
                  checkDeleted={R.pipe(
                    R.assoc('id', office.id),
                    R.assoc('name', office.name),
                    checkDeleted
                  )}
                  openGroupDialog={() => {
                    setOffice(office);
                    setState('OFFICE_GROUP');
                  }}
                  openFeaturesDialog={() => {
                    setOffice(office);
                    setState('OFFICE_FEATURES');
                  }}
                  destroyServer={R.pipe(
                    R.assoc('id', office.id),
                    R.assoc('name', office.name),
                    destroyServer
                  )}
                  rebuildServer={R.pipe(
                    R.assoc('id', office.id),
                    R.assoc('name', office.name),
                    rebuildServer
                  )}
                />
              );
            })}
          </TableBody>
        </TableContainer> :
        <Redirect to="/" />
      }
    </Paper>
  );
}

export default SelectOffice;
