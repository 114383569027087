import {
  LocalDateTime,
  LocalTime,
  ZoneId,
  ZoneOffset,
  ZonedDateTime,
  DateTimeFormatter,
  ChronoUnit
} from '@js-joda/core';
import { Locale } from '@js-joda/locale_en-us';
import '@js-joda/timezone';
import { calcColor, calcDefaultColor, getStatusColor } from '../services/calendar.service';
import {
  pathOr, pipe, map, range,
  cond, equals, always, either,
  filter, into, compose, sort,
  includes, T, isNil
} from 'ramda';

const minutesToInterval = cond([
  [equals(0), always('60min')],
  [equals(30), always('60min')],
  [either(equals(15), equals(45)), always('15min')],
  [() => true, always('5min')],
]);

const getEndTime = a => new Date(a.time).getTime() + a.appointmentType.duration * 60 * 1000;

const getStartTime = a => new Date(a.time).getTime();

export const doAppointmentsOverlap = (a, b) => {
  return a.startTime < b.endTime && a.endTime > b.startTime;
};

export const checkAvailability = (slotId = 0, appointment = {}, sortedAppointments = []) => {
  if (sortedAppointments.length === 0) {
    return true;
  }

  const slottedAppointments = sortedAppointments.filter(a => a.slotId === slotId);

  if (slottedAppointments.length === 0) {
    return true;
  }

  const conflictingAppointments = slottedAppointments.filter(a => {
    return doAppointmentsOverlap(appointment, a);
  });

  return conflictingAppointments.length === 0;
};

export const getNextAvailableSlot = (appointment = {}, sortedAppointments = []) => {
  let slotIdCheck = 0;
  while (!checkAvailability(slotIdCheck, appointment, sortedAppointments)) {
    slotIdCheck = slotIdCheck + 1;
  }
  return slotIdCheck;
};

export const getMinMaxTimes = (apts = []) => apts.reduce((acc, cur) => {
  const curTime = cur.localDateTime.toLocalTime().toString();
  const maxTime = cur.localDateTime.toLocalTime().plusMinutes(cur.appointmentType.duration || 0).toString();
  return {
    min: (!acc.min || curTime < acc.min) ? curTime : acc.min,
    max: (!acc.max || maxTime > acc.max) ? maxTime : acc.max
  };
}, { min: null, max: null });


export const withLocalTime = timezone => a => ({
  ...a,
  localDateTime: ZonedDateTime
    .parse(a.time)
    .withZoneSameInstant(ZoneId.of(timezone))
    .toLocalDateTime()
});

export const withStartAndEndTimestamps = a => {
  const startTime = getStartTime(a);
  const endTime = getEndTime(a);
  return {
    ...a,
    startTime,
    endTime
  };
};

export const filterAppointmentsByDayAndTypes = ({
  types = [],
  professionalId = 0,
  date = '2020-10-01',
  timezone = 'America/New_York',
  appointments = [],
  locationId = 0,
  professionals = [],
}) => {
  const start = LocalDateTime
    .parse(`${date}T00:00`)
    .atZone(ZoneId.of(timezone));

  const startOfDay = start
    .withZoneSameInstant(ZoneOffset.UTC)
    .toString();

  const nextDay = start
    .plusDays(1)
    .withZoneSameInstant(ZoneOffset.UTC)
    .toString();

  const locationProfessionals = professionals
    .filter((pro) => cond([
      [equals(0), T],
      [equals('main'), () => isNil(pro.locationId)],
      [T, () => pro.locationId === locationId]
    ])(locationId))
    .map((pro) => pro.id);

  const iterator = compose(
    filter(appointment => locationId === 0 ? true : includes(appointment.appointmentType.professionalId, locationProfessionals)),
    filter(appointment => professionalId === 0 ? true : appointment.appointmentType.professionalId === professionalId),
    filter(appointment => types.length === 0 ? true : types.some(ac => ac === appointment.appointmentType.id)),
    filter(appointment => appointment && appointment.time >= startOfDay && appointment.time < nextDay),
    map(pipe(
      withLocalTime(timezone),
      withStartAndEndTimestamps
    ))
  );

  return pipe(
    into([], iterator), // Transducers FTW
    sort((a, b) => b.id - a.id),
    sort((a, b) => b.appointmentType.id - a.appointmentType.id),
    sort((a, b) => b.appointmentType.duration - a.appointmentType.duration)
  )(appointments);
};


export const sortAppointmentsBySlot = appointments => {
  const newApts = appointments;
  const sortedAppointments = [];
  let maxSlotId = 0;
  // Use forEach here because we also set maxSlotId in each loop
  // If you wanted it purely functional you could use a reduce function
  // I found this a little easier to code though.
  newApts.forEach(a => {
    const slotId = getNextAvailableSlot(a, sortedAppointments);
    a.slotId = slotId;
    maxSlotId = slotId > maxSlotId ? slotId : maxSlotId;
    sortedAppointments.push(a);
  });
  return {
    sortedAppointments,
    maxSlotId
  };
};

const timeFormat = DateTimeFormatter.ofPattern('h:mm a').withLocale(Locale.US);

const createInterval = ({ startOfDay, interval }) => num => {
  const time = startOfDay.plusMinutes(interval * num);
  const minutes = time.minute();
  return {
    interval: minutesToInterval(minutes),
    minutes,
    time,
    display: time.format(timeFormat)
  };
};

export const getTimeStructuresLocal = ({
  start = '00:00',
  end = '23:59',
  interval = 15,
}) => {
  const startOfDay = LocalTime.parse(start);
  const endOfDay = LocalTime.parse(end);

  const createIntervalInner = createInterval({ startOfDay, interval });

  const times = pipe(
    s => s.until(endOfDay, ChronoUnit.MINUTES),
    minutes => Math.ceil(minutes / interval),
    range(0),
    map(createIntervalInner)
  )(startOfDay);

  return times;
};

export const getTimeStructures = ({
  start = '2020-10-01T00:00',
  end = '2020-10-01T23:59',
  timezone = 'America/New_York',
  interval = 15,
}) => {
  let time = ZonedDateTime.parse(start).withZoneSameInstant(ZoneId.of(timezone));
  const endOfDay = ZonedDateTime.parse(end);

  const times = [];

  while (time.isBefore(endOfDay)) {
    const minutes = time.minute();

    const obj = {
      interval: minutesToInterval(minutes),
      minutes,
      time,
      display: time.format(timeFormat)
    };

    times.push(obj);
    time = time.plusMinutes(interval);
  }
  return times;
};

// Mainly use this for current time
// Don't use it below to optimize for
// code time
export const calcPositionY = ({
  startOfDay,
  cellHeight,
  interval,
  time
}) => {
  const min = (time - startOfDay) / (1000 * 60);
  const y = cellHeight / interval * min;
  return y;
};

export const calcTimeFromY = ({
  startOfDay, // in milliseconds
  cellHeight,
  interval,
  y
}) => {
  //Convert y to minutes
  const min = y * interval / cellHeight;

  //Convert minutes to millaseconds
  const ms = min * 60 * 1000;
  const time = startOfDay + ms;

  //Need to rounds to nearest interval
  const coeff = 1000 * 60 * interval;
  const date = new Date(time);
  const rounded = new Date(Math.round(date.getTime() / coeff) * coeff);

  return rounded.toISOString();
};

export const getAppointmentPositions = ({
  start = '2020-10-01',
  interval = 15, // min
  appointments = [],
  cellHeight = 25, // px
  cellWidth = 100, // px
  colors = []
} = {}) => {
  const minToY = min => cellHeight / interval * min;
  const startOfDay = new Date(start).getTime();

  return appointments.map(a => {
    const x = a.slotId * cellWidth + 0.5;
    const timestamp = new Date(a.time).getTime();
    const y = minToY((timestamp - startOfDay) / (1000 * 60)) + 1;
    const h = minToY(a.appointmentType.duration || 10) - 2;
    const colorStr = a.appointmentType.color ? calcColor(a.appointmentType) : calcDefaultColor(a.appointmentType, colors);
    const nameLabel = pathOr('', ['client', 'firstName', 0], a) + ' ' + a.client.lastName;
    const draw = {
      x,
      y,
      h,
      color: colorStr,
      statusColor: getStatusColor(a),
      nameLabel
    };
    a.draw = draw;
    a.timestamp = timestamp;
    return a;
  });
};
