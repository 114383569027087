import * as React from 'react';
import {
  TableContainer, Table, TableRow, TableHead, TableBody, TableCell,
  Input, Checkbox, FormGroup, FormControlLabel, TextField, Button,
} from '@mui/material';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import {
  ComplexWidgetFlexibleTable, ComplexWidgetFixedTable, SimpleWidgetType,
  SimpleWidget
} from '../intake-types';
import { TableValue, TableMap, TableAnswer } from '../answer-types';
import { useAnswerContext, useIntakeContextAnswer } from '../answers.context';
import {
  includes, type, prepend, without, dissoc, isEmpty, isNil,
} from 'ramda';

interface RenderInputProps {
  data: SimpleWidgetType;
  value: TableValue;
  noCtx?: boolean;
  isPrivate?: boolean;
  setValue: (v: TableValue) => void;
  onBlur: () => void;
}

const defaultAnswer: TableAnswer = {
  id: 0,
  type: 'FixedTable',
  value: {},
};

export const defaultAnswerTable = (data: SimpleWidgetType): string | boolean => {
  if (data.type === 'CheckboxSingle') {
    return false;
  }
  return '';
};

export const TableInput = ({
  data, value, noCtx, isPrivate, setValue, onBlur
}: RenderInputProps) => {
  let disabled = !noCtx;
  if (isPrivate) {
    disabled = false;
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setValue(e.target.value);
  };

  const handleChangeChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.checked);
  };

  const handleChecked = (option: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const localValue = type(value) !== 'Array' ? [] : value;
    if (checked) {
      setValue(prepend(option, localValue as readonly string[]));
    } else {
      setValue(without([option], localValue as readonly string[]));
    }
  };

  if (data.type === 'Dropdown') {
    return (
      <Input
        multiline
        minRows={1}
        disabled={disabled}
        value={value || ''}
        onBlur={onBlur}
        onChange={handleChange}
        endAdornment={
          <ArrowDropDown style={{ color: 'rgba(0, 0, 0, 0.26)' }}/>
        }
      />
    );
  }
  if (data.type === 'ShortText') {
    return (
      <Input
        value={value || ''}
        disabled={disabled}
        onChange={handleChange}
        onBlur={onBlur}
        multiline
        minRows={1}
      />
    );
  }
  if (data.type === 'Email') {
    return (
      <TextField
        type="email"
        value={value || ''}
        disabled={disabled}
        onChange={handleChange}
        onBlur={onBlur}
      />
    );
  }
  if (data.type === 'LongText') {
    return (
      <TextField
        multiline
        minRows={1}
        value={value || ''}
        disabled={disabled}
        onChange={handleChange}
        onBlur={onBlur}
      />
    );
  }
  if (data.type === 'Date') {
    if (value &&
      typeof value === 'string' &&
      value.match(/(19|20)[0-9]{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])/g)) {
      return (
        <TextField
          type="date"
          value={value || ''}
          disabled={disabled}
          onChange={handleChange}
          onBlur={onBlur}
        />
      );
    }
    return (
      <TextField
        type="text"
        value={value || ''}
        disabled={disabled}
        onChange={handleChange}
        onBlur={onBlur}
      />
    );
  }
  if (data.type === 'Number') {
    return (
      <TextField
        style={{ width: '100px' }}
        type="number"
        value={value || ''}
        disabled={disabled}
        onChange={handleChange}
        onBlur={onBlur}
      />
    );
  }
  if (data.type === 'Checkbox') {
    return (<FormGroup style={{ flexDirection: 'row', justifyContent: 'center' }}>
      {data.options.map(o => {
        const isChecked = type(value) === 'Array' ? includes(o, value as readonly string[]) : false;
        return (
          <FormControlLabel
            key={o}
            label={o}
            control={
              <Checkbox
                disabled={disabled}
                checked={isChecked}
                onChange={handleChecked(o)}
                onBlur={onBlur}
              />}
          />
        );
      }
      )}
    </FormGroup>);
  }
  if (data.type === 'CheckboxSingle') {
    return (
      <Checkbox
        checked={value as boolean || false}
        disabled={disabled}
        onChange={handleChangeChecked}
        onBlur={onBlur}
      />
    );
  }
  return <div>{data.type} is unsupported.</div>;
};


interface TableProps {
  id: number;
  data: ComplexWidgetFixedTable | ComplexWidgetFlexibleTable,
  required: boolean;
  noCtx?: boolean;
  isPrivate?: boolean;
}

export const TableComponent = ({ id, data, noCtx, isPrivate }: TableProps) => {
  const ctx = useAnswerContext();
  const [value, setValue] = noCtx ? [{} as TableMap] : useIntakeContextAnswer<TableAnswer>(id, defaultAnswer);
  const [localValue, setLocalValue] = React.useState<TableMap>(value);
  const [rows, setRows] = React.useState(noCtx ? [1, 2, 3] : [0]);

  const onBlur = () => {
    ctx.autoSave();
  };

  if (data.type === 'FixedTable' && !noCtx) {
    const handleChange = (r: string, c: string) => (xyValue: TableValue) => {
      const row = localValue[r] || {
        id: r,
        value: {}
      };
      const rowValue = row.value || {};

      const newLocal = {
        ...localValue,
        [r]: {
          id: r,
          value: {
            ...rowValue,
            [c]: xyValue
          }
        }
      };
      setValue(newLocal);
      setLocalValue(newLocal);
    };

    const getValue = (r: string, c: string): TableValue => {
      const row = value[r] || {
        id: r,
        value: {}
      };
      const rowValue = row.value || {};
      return rowValue[c];
    };

    React.useEffect(() => {
      if (isEmpty(value) || isNil(value)) {
        const defaultValues = data.rows.labels.slice(1).reduce((acc, cur, idx) => {
          return {
            ...acc,
            [idx]: {
              id: String(idx),
              value: data.inputs.reduce((iAcc, iCur, iIdx) => {
                return {
                  ...iAcc,
                  [iIdx]: defaultAnswerTable(iCur.questionType),
                };
              }, {}),
            },
          };
        }, {});
        setLocalValue(defaultValues);
      } else if (isEmpty(localValue) || localValue.length === value.length) {
        setLocalValue(value);
      }
    }, [value]);

    return (
      <div>
        <h3>{data.rows.header}</h3>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {data.inputs.map((i: SimpleWidget) =>
                  <TableCell key={i.label} align="center">{i.label}</TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.rows.labels.map((label: string, rnum: number) =>
                <TableRow key={label}>
                  <TableCell>{label}</TableCell>
                  {data.inputs.map((i: SimpleWidget, cnum: number) => (
                    <TableCell key={i.label} align="center">
                      <TableInput
                        setValue={handleChange(String(rnum), String(cnum))}
                        value={getValue(String(rnum), String(cnum))}
                        data={i.questionType}
                        noCtx={noCtx}
                        isPrivate={isPrivate}
                        onBlur={onBlur}
                      />
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }

  const addRow = () => {
    const newRows = rows.concat([rows.length]);
    setRows(newRows);
  };
  const removeRow = () => {
    if (rows.length > 1) {
      const lastIndex = rows.length - 1;
      setRows((r: number[]) => r.slice(0, -1));
      setValue(dissoc(lastIndex, value));
    }
  };

  React.useEffect(() => {
    const rowLength = Object.values(value).length;
    if (rowLength && rowLength > 1 && rowLength > rows.length) {
      const newRows = Array.from(Array(rowLength).keys(), n => n);
      setRows(newRows);
    }
  }, [value]);

  const handleChange = (r: string, c: string) => (xyValue: TableValue) => {
    const row = value[r] || {
      id: r,
      value: {}
    };
    /* eslint prefer-spread: 1  */
    const rowValue = isEmpty(row.value) ? Object.assign({}, Array.apply(null, Array(5)).map(() => '')) : row.value;

    console.log(rowValue, xyValue);

    setValue({
      ...value,
      [r]: {
        id: r,
        value: {
          ...rowValue,
          [c]: xyValue
        }
      }
    });
  };

  const getValue = (r: string, c: string): TableValue => {
    const row = value[r] || {
      id: r,
      value: {}
    };
    const rowValue = row.value || {};
    return rowValue[c];
  };


  return (
    <div style={{ marginBottom: '15px' }}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>&nbsp;</TableCell>
              {data.inputs.map((i: SimpleWidget) => (
                <TableCell
                  key={i.label}
                  style={{ minWidth: 80, padding: 8 }}
                  align="center"
                >
                  {i.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((num: number) =>
              <TableRow key={num}>
                <TableCell>{num + 1}</TableCell>
                {data.inputs.map((c, i) =>
                  <TableCell key={c.label} style={{ padding: 8 }} align="center">
                    <TableInput
                      value={getValue(String(num), String(i))}
                      data={c.questionType}
                      noCtx={noCtx}
                      isPrivate={isPrivate}
                      setValue={handleChange(String(num), String(i))}
                      onBlur={onBlur}
                    />
                  </TableCell>)}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {noCtx || isPrivate &&
        <div style={{ display: 'flex', marginTop: '10px' }}>
          <Button variant="contained" onClick={addRow}>Add Row</Button>
          <div style={{ flexGrow: 1 }}></div>
          <Button variant="contained" onClick={removeRow}>Remove Row</Button>
        </div>}
    </div>
  );
};
