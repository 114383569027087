import Icon from '../../icons/OnboardingGuide.icon';
import StampIcon from '../../icons/Stamp.icon';
import CalendarIcon from '../../icons/Calendar.icon';
import ThreePeopleIcon from '../../icons/ThreePeople.icon';
import ConversationSmsIcon from '../../icons/ConversationSms.icon';
import CampaignsIcon from '../../icons/Campaigns.icon';
import SingleSmsIcon from '../../icons/SingleSms.icon';
import AutomatedMessagesIcon from '../../icons/AutomatedMessages.icon';
import ReviewsIcon from '../../icons/Reviews.icon';
import EditDocumentIcon from '../../icons/EditDocument.icon';
import ReportsIcon from '../../icons/Reports.icon';
import PatientPortalIcon from '../../icons/PatientPortal.icon';
import AutomationCenterIcon from '../../icons/AutomationCenter.icon';
import LeadsIcon from '../../icons/Leads.icon';
import SparkIcon from '../../icons/Spark.icon';
import SparkLogo from '../../icons/SparkLogo.icon';

export type NavItem = {
  path?: string;
  name: string;
  ImageName?: ({ className, color }: { className?: string, color?: string }) => JSX.Element;
  subName?: string;
  invertedName?: boolean;
  Icon?: ({ color, className }: { color?: string, className?: string }) => JSX.Element;
  header?: string;
  features?: string[];
  onlyShowFeatures?: string[];
  hideFeatures?: string[];
  id?: string;
  isNew?: boolean;
  isBeta?: boolean;
  isActive?: boolean;
  isEnabled?: boolean;
  badge?: number;
  display?: string;
  items?: NavItem[];
  hideSpark?: boolean;
  isAdmin?: boolean;
}

export type Header = {
  id: string;
  display: string;
  items: NavItem[];
}

export const headers: Header[] = [
  { id: 'guide', display: '', items: [] },
  { id: 'quick-access', display: 'QUICK ACCESS', items: [] },
  { id: 'messaging', display: 'MESSAGING', items: [] },
  { id: 'tools', display: 'TOOLS', items: [] },
  { id: 'marketing', display: 'MARKETING', items: [] },
  { id: 'settings', display: 'SETTINGS', items: [] },
];


export const navItems: NavItem[] = [
  {
    path: '/getting-started',
    name: 'Guide',
    subName: 'Onboarding',
    id: 'onboarding',
    Icon,
    header: 'guide',
    onlyShowFeatures: ['NewOfficeTutorial'],
    hideSpark: true,
  },
  {
    path: '/front-desk',
    subName: 'Front Desk',
    name: '& Alerts',
    invertedName: true,
    Icon: StampIcon,
    onlyShowFeatures: ['FrontDesk'],
    header: 'quick-access',
    isNew: false,
  },
  {
    path: '/waiting',
    name: 'Virtual Check-in',
    id: 'waitingRoom',
    Icon: StampIcon,
    features: ['VWRoom'],
    hideFeatures: ['FrontDesk'],
    header: 'quick-access'
  },
  {
    path: '/calendar-v2',
    name: 'Calendar',
    Icon: CalendarIcon,
    features: ['NewCalendar'],
    header: 'quick-access'
  },
  {
    path: '/clients',
    name: 'Clients',
    Icon: ThreePeopleIcon,
    header: 'quick-access'
  },
  {
    path: '/leads',
    name: 'Leads',
    Icon: LeadsIcon,
    header: 'quick-access',
    features: ['Leads'],
    onlyShowFeatures: ['Leads'],
  },
  {
    path: '/inbox',
    name: 'SMS Inbox',
    id: 'inbox',
    Icon: ConversationSmsIcon,
    header: 'messaging',
    badge: 10
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    Icon: CampaignsIcon,
    onlyShowFeatures: ['BasicAutomatedCampaigns', 'AdvancedAutomatedCampaigns'],
    header: 'messaging',
    hideSpark: true,
  },
  {
    id: 'spark',
    name: 'Spark',
    path: '/sign-up',
    Icon: SparkIcon,
    ImageName: SparkLogo,
    header: 'messaging',
    hideFeatures: ['Spark'],
    onlyShowFeatures: ['SparkSKEDSignUp'],
    hideSpark: true,
  },
  {
    path: '/messages',
    subName: 'One Time',
    name: 'Messages',
    invertedName: true,
    Icon: SingleSmsIcon,
    header: 'messaging',
    features: ['OneTimeMessages'],
    hideSpark: true,
  },
  {
    subName: 'Automated',
    name: 'Messages',
    invertedName: true,
    Icon: AutomatedMessagesIcon,
    header: 'messaging',
    items: [
      {
        path: '/reminder-messages',
        name: 'Reminders',
        header: 'messaging',
        hideFeatures: ['AutomationBasedMessages']
      },
      {
        path: '/reminder-messages',
        name: 'Appt-Time Reminders',
        header: 'messaging',
        onlyShowFeatures: ['AutomationBasedMessages']
      },
      {
        path: '/time-based/list',
        name: 'Set-Time Reminders',
        header: 'messaging',
        onlyShowFeatures: ['AutomationBasedMessages'],
        hideSpark: true,
      },
      {
        path: '/birthday-messages',
        name: 'Birthdays',
        header: 'messaging',
        features: ['Birthday'],
        hideSpark: true,
      },
      {
        path: '/reactivation-messages',
        name: 'Reactivations',
        header: 'messaging',
        features: ['ReactivationCampaigns']
      },
      {
        path: '/change-messages',
        name: 'Rapid',
        header: 'messaging',
        features: ['ApptChangeMessages']
      },
      /*      {
        path: '/smart-reply-messages',
        name: 'Smart Replies',
        header: 'messaging',
        onlyShowFeatures: ['SmartReply'],
        hideFeatures: ['AutomationBasedMessages'],
        hideSpark: true,
      },*/
      {
        path: '/smart-reply/list',
        name: 'Smart Replies',
        header: 'messaging',
        features: ['SmartReply'],
        hideSpark: true,
      },
      {
        path: '/autoresponder-messages',
        name: 'Auto Responders',
        header: 'messaging',
        features: ['Autoresponder']
      },
    ]
  },
  {
    name: 'Spark',
    Icon: SparkIcon,
    ImageName: SparkLogo,
    header: 'messaging',
    id: 'spark',
    onlyShowFeatures: ['Spark'],
    items: [
      {
        path: '/spark-campaign',
        name: 'Campaigns',
        header: 'messaging',
        onlyShowFeatures: ['SparkEdit'],
      },
      {
        path: '/spark-campaign',
        name: 'Campaigns',
        header: 'messaging',
        isAdmin: true,
        hideFeatures: ['SparkEdit'],
      },
      {
        path: '/spark-reporting',
        name: 'Reporting',
        header: 'messaging',
        onlyShowFeatures: ['Spark'],
      }
    ],
  },
  {
    path: '/reviews',
    name: 'Reviews',
    Icon: ReviewsIcon,
    features: ['Reviews'],
    header: 'tools',
    id: 'reviews',
    hideSpark: true,
  },
  {
    name: 'Forms',
    Icon: EditDocumentIcon,
    header: 'tools',
    onlyShowFeatures: ['Forms'],
    hideSpark: true,
    items: [
      {
        path: '/forms-settings',
        name: 'Form Builder',
        header: 'tools',
      },
      {
        path: '/forms-submissions',
        name: 'Submissions',
        header: 'tools',
      }
    ],
  },
  {
    name: 'Patient Portals',
    Icon: PatientPortalIcon,
    header: 'tools',
    onlyShowFeatures: ['EPPortal'],
    path: '/settings/new-patient-portal',
  },
  {
    path: '/settings/webmodule',
    name: 'Patient Portal',
    Icon: PatientPortalIcon,
    header: 'tools',
    onlyShowFeatures: ['NPPortal'],
    hideFeatures: ['NPPortal2'],
  },
  {
    path: '/settings/new-patient-portal',
    name: 'Patient Portals',
    Icon: PatientPortalIcon,
    header: 'tools',
    onlyShowFeatures: ['NPPortal2'],
    hideFeatures: ['NPPortal', 'EPPortal'],
  },
  {
    name: 'Patient Portal',
    Icon: PatientPortalIcon,
    header: 'tools',
    onlyShowFeatures: ['NPPortal', 'NPPortal2'],
    hideFeatures: ['EPPortal'],
    items: [
      {
        path: '/settings/new-patient-portal',
        name: 'New Patients',
        features: ['NPPortal2'],
        header: 'tools',
      },
      {
        path: '/settings/webmodule',
        name: 'Existing Patients',
        onlyShowFeatures: ['NPPortal'],
        header: 'tools',
      },
    ],
  },
  {
    name: 'Reports',
    Icon: ReportsIcon,
    features: ['SkedApp', 'NPPortal2', 'Analytics', 'AppointmentsReport', 'StopManager'],
    header: 'tools',
    hideSpark: true,
    items: [
      {
        path: '/dashboard',
        name: 'App & Portal Usage',
        features: ['SkedApp', 'NPPortal2'],
        header: 'tools'
      },
      {
        path: '/reports/overview',
        name: 'Office Stats',
        onlyShowFeatures: ['Analytics'],
        header: 'tools',
      },
      {
        path: '/reports/appointments',
        name: 'Appointment Reports',
        onlyShowFeatures: ['AppointmentsReport'],
        header: 'tools',
      },
      {
        path: '/reports/stops',
        name: 'Alert Reports',
        onlyShowFeatures: ['StopManager'],
        header: 'tools',
      }
    ],
  },
  {
    subName: 'Automation',
    name: 'Center',
    invertedName: true,
    Icon: AutomationCenterIcon,
    onlyShowFeatures: ['AdvancedAutomatedCampaigns'],
    header: 'tools',
    items: [
      {
        path: '/automations',
        name: 'Automations',
        onlyShowFeatures: ['Automations'],
        header: 'automations'
      },
      {
        path: '/automation-messages',
        name: 'Messages',
        header: 'messaging',
        onlyShowFeatures: ['AdvancedAutomatedCampaigns']
      },
    ],
  },
];
