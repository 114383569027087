import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: 20,
    margin: 20,
    minHeight: 200,
    width: '75%',
    minWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  settingsRoot: {
    background: 'none',
    boxShadow: 'none',

    '& .MuiTableCell-sizeSmall': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& table tr th': {
      background: 'none',
      height: 28,
    },
  },
  boxShadow: {
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
  },
  firstColumn: {
    paddingLeft: 18,
    minWidth: 100,
  },
  columnName: {
    width: '20%',
    minWidth: 200,
  },
  width30: {
    width: '30%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,

    '& > h3': {
      margin: 0,
      padding: 0,
      height: 'auto',
    },
  },
  smallColumn: {
    width: 125,
  },
  columnOffices: {
    width: 150,
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
  },
}));
