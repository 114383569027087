import React, { useEffect, useState } from 'react';
import { Client } from '../../../clients.types';
import api from '../../../../../services/api.js';
import { usePromise } from '../../../../../services/promise.hook';
import {
  LocalDate,
} from '@js-joda/core';
import { format, tzParseFormat } from '../../../../../services/joda.js';
import {
  isEmpty, hasPath, pathOr, propEq, find, cond, has, always, T
} from 'ramda';
import { CircularProgress, TextField } from '@mui/material';
import { Row, Spacer } from '../../../../../components/PageHeader';

const parseBirthday = (birthday: string) => {
  try {
    return format(LocalDate.parse(birthday), 'MM/dd/yyyy');
  } catch (e) {
    return '';
  }
};

interface TimelineProps {
  client: Client;
  open: boolean;
  tz: string;
}

interface GetTimelineProps {
  clientId: number;
  afterTime: string;
  beforeTime?: string;
}

interface TimelineItemProps {
  tz: string;
  item: any;
  types: any[];
  pros: any[];
}

const getTimeline = ({ clientId, afterTime, beforeTime }: GetTimelineProps) => {
  return api.post(`client/${clientId}/timeline?time=${afterTime}${beforeTime ? '&beforeTime=' + beforeTime : ''}`);
};

const getPros = () => api.get('professional');
const getTypes = () => api.get('appointmentType');

const displayAction = (apt: any) => {
  if (apt.change) {
    return cond([
      [has('Added'), (change: any) => ({ action: 'Scheduled', update: change.Added })],
      [has('Scheduled'), () => ({ action: 'Scheduled', update: '' })],
      [has('OldAdded'), always({ action: 'Scheduled', update: '' })],
      [has('TimeUpdated'), (change: any) => ({ action: 'Rescheduled', update: change.TimeUpdated })],
      [has('MarkedAsMissed'), always({ action: 'Missed', update: '' })],
      [has('MarkedAsArrived'), always({ action: 'Arrived', update: '' })],
      [has('Deleted'), always({ action: 'Deleted', update: '' })],
      [T, always({ action: 'Canceled', update: '' })],
    ])(apt.change as any);
  }
  return { action: 'Event', update: '' };
};

const TimelineItem = ({ tz, item, types, pros }: TimelineItemProps) => {
  const value = item.value;
  let header = 'Unknown Event';
  let body = (<div></div>);
  const aptType = (typeId: number) => {
    const type: any = find(propEq('id', typeId))(types);
    if (type) {
      return type.internalName + ' (' + type.name + ')';
    }
    return '';
  };
  const pro = (typeId: number) => {
    const type: any = find(propEq('id', typeId))(types);
    if (type) {
      const pro: any = find(propEq('id', type.professionalId))(pros);
      if (pro) {
        return pro.firstName + ' ' + pro.lastName;
      }
      return '';
    }
    return '';
  };
  if (value.ClientUpdate) {
    header = value.ClientUpdate.createData ? 'Client Created' : 'Client Updated';
    if (value.ClientUpdate.updateData) {
      const updateData = value.ClientUpdate.updateData;
      body = (<>
        {hasPath(['firstName', 'Update'], updateData) &&
                ['First: ' + updateData.firstName.Update,
                  <br />]}
        {hasPath(['lastName', 'Update'], updateData) &&
                ['Last: ' + updateData.lastName.Update,
                  <br />]}
        {hasPath(['email', 'Update'], updateData) &&
                ['Email: ' + updateData.email.Update,
                  <br />]}
        {hasPath(['allowEmail', 'Update'], updateData) &&
                ['Allow Email: ' + updateData.allowEmail.Update,
                  <br />]}
        {hasPath(['phone', 'Update'], updateData) &&
                ['Phone: ' + updateData.phone.Update,
                  <br />]}
        {hasPath(['allowSMS', 'Update'], updateData) &&
                ['Allow SMS: ' + updateData.allowSMS.Update,
                  <br />]}
        {hasPath(['birthday', 'Update'], updateData) &&
                pathOr(false, ['birthday', 'Update'], updateData) &&
                ['Birthday: ' + parseBirthday(updateData.birthday.Update),
                  <br />]}
        {hasPath(['status', 'Update'], updateData) &&
                ['Status: ' + updateData.status.Update,
                  <br />]}
        {hasPath(['referrer', 'Update'], updateData) &&
                ['Referrer: ' + updateData.referrer.Update,
                  <br />]}
        {hasPath(['referrer', 'Update'], updateData) &&
                ['Referrer: ' + updateData.referrer.Update,
                  <br />]}
        {updateData.defaultAppiontmentType &&
                ['Appointment Type: '
                  + aptType(updateData.defaultAppiontmentType),
                <br />]}
        {updateData.defaultAppiontmentType &&
                ['Professional: '
                  + pro(updateData.defaultAppiontmentType),
                <br />]}
      </>);
    } else {
      const createData = value.ClientUpdate.createData;
      body = (
        <>
          {'First: ' + createData.firstName}
          <br />
          {'Last: ' + createData.lastName}
          <br />
          {createData.email &&
            ['Email: ' + createData.email, <br />]}
          {createData.phone &&
            ['Phone: ' + createData.phone, <br />]}
          {createData.birthday &&
            ['Birthday: ' + parseBirthday(createData.birthday), <br />]}
          {createData.status &&
            ['Status: ' + createData.status, <br />]}
          {createData.referrer &&
            ['Referrer: ' + createData.referrer, <br />]}
          {createData.referrer &&
            ['Referrer: ' + createData.referrer, <br />]}
          {createData.defaultAppiontmentType &&
            ['Appointment Type: '
              + aptType(createData.defaultAppiontmentType),
            <br />]}
          {createData.defaultAppiontmentType &&
            ['Professional: '
              + pro(createData.defaultAppiontmentType),
            <br />]}
        </>);
    }
  } else if (value.AppointmentEvent) {
    const { action, update } = displayAction(value.AppointmentEvent);
    header = 'Appointment ' + action;
    const aptT = aptType(value.AppointmentEvent.appointment?.appointmentTypeId);
    const p = pro(value.AppointmentEvent.appointment?.appointmentTypeId);
    body = (
      <>
        {value.AppointmentEvent.appointment &&
          tzParseFormat(value.AppointmentEvent.appointment.time, tz, '\'Scheduled Time: \'M/d/yyyy h:mm a\',\'')}
        {update === '' ? '' : tzParseFormat(update, tz, '\'Updated to: \'M/d/yyyy h:mm a\', \'')}
        {value.AppointmentEvent.appointment &&
          value.AppointmentEvent.appointment.status &&
          value.AppointmentEvent.appointment.status.Canceled &&
          'Cancel reason: ' + value.AppointmentEvent.appointment.status.Canceled}
        {tzParseFormat(value.AppointmentEvent.changedAt, tz, '\'Action Time: \'M/d/yyyy h:mm a')}
        <br/>
        Via: {value.AppointmentEvent.source}
        <br/>
        {p}{p && ','}{aptT}
      </>
    );
  } else if (value.SentMsg) {
    const dir = value.SentMsg.to.ToClient;
    header = dir ? 'Sent Message' : 'Received Message';
    body = (
      <>
        {value.SentMsg.smsData &&
         <>{value.SentMsg.smsData.body}<br/></>}
        {value.SentMsg.emailData &&
         <>${value.SentMsg.emailData.subject}, ${value.SentMsg.emailData.body}<br/></>}
        {value.SentMsg.pushData &&
         `${value.SentMsg.pushData.subject}, ${value.SentMsg.pushData.body}`}
      </>
    );
  }
  return (
    <div style={{
      width: '100%',
      height: 'auto',
      display: 'flex',
      flexDirection: 'column',
      borderBottom: '1px solid gray',
    }}>
      <Row>
        <b>{header}</b>
        <Spacer/>
        <i>{tzParseFormat(item.time, tz, 'M/dd/yyyy h:mm:ss a')}</i>
      </Row>
      {body}
    </div>
  );
};

const Timeline = ({ client, open, tz }: TimelineProps) => {
  const { id } = client;
  const now = LocalDate.now();
  const twoMonthsAgo = now.minusMonths(2);
  const [afterTime, setAfterTime] = useState<string>(format(twoMonthsAgo, 'yyyy-MM-dd') + 'T00:00:00Z');
  const [beforeTime, setBeforeTime] = useState<string>(format(now.plusDays(1), 'yyyy-MM-dd') + 'T00:00:00Z');
  const timelineState = usePromise(getTimeline, []);
  const prosState = usePromise(getPros, []);
  const typesState = usePromise(getTypes, []);
  const mainRef = React.useRef(null);
  useEffect(() => {
    if (open) {
      timelineState.invoke({
        clientId: id,
        afterTime,
        beforeTime,
      });
      prosState.invoke({});
      typesState.invoke({});
    }
  }, [open, beforeTime, afterTime]);
  useEffect(() => {
    if (!isEmpty(timelineState.data)) {
      const elem = mainRef.current;
      elem.scroll(0, elem.scrollTopMax);
    }
  }, [timelineState.data]);
  const updateBeforeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBeforeTime(e.target.value + 'T00:00:0Z');
  };
  const updateAfterTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAfterTime(e.target.value + 'T00:00:0Z');
  };
  return (
    <div
      ref={mainRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px',
        height: '424px',
        overflowY: 'scroll',
      }}>
      <Row>
        <TextField
          type='date'
          label='Before Date'
          value={beforeTime.split('T')[0]}
          onChange={updateBeforeTime}
        />
        &nbsp;
        <TextField
          type='date'
          label='After Date'
          value={afterTime.split('T')[0]}
          onChange={updateAfterTime}
        />
        <Spacer/>
      </Row>
      {timelineState.loading &&
      <CircularProgress />}
      {!timelineState.loading && timelineState.errorMessage &&
        <p style={{ color: 'red' }}>{timelineState.errorMessage}</p>}
      {!timelineState.loading && !isEmpty(timelineState.data) &&
      timelineState.data.map((item: any) => {
        return (
          <TimelineItem
            key={item.time}
            tz={tz}
            item={item}
            types={typesState.data}
            pros={prosState.data}
          />
        );
      })}
    </div>
  );
};

export default Timeline;