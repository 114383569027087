import React, { ReactElement } from 'react';

import Header from './PageHeader/PageHeader.component';

type FeatureDisabledProps = {
  title: string;
  children: Element | ReactElement;
}

export const FeatureDisabled = ({ title, children }: FeatureDisabledProps) => {
  return (
    <div>
      <Header title={title} />
      <div style={{ margin: '20px' }}>
        {children}
        <p>
          Talk to the SKED support team about upgrading to a new product to get access to this feature.  See the <a target="_blank" href="https://sked.life/sked-pricing">pricing page</a> for more details.
        </p>
      </div>
    </div>
  );
};

export const ContactSupport = () => (<p>
  Talk to the SKED support team about upgrading to a new product to get access to this feature.  See the <a target="_blank" href="https://sked.life/sked-pricing">pricing page</a> for more details.
</p>);
