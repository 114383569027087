import { reset } from '../routes/Clients/components/client-dialog/client-dialog.actions.jsx';
import { backToDash } from '../routes/Dashboard/dashboard.actions.js';
import { backToSked } from '../routes/Calendar/calendar.actions';
import { backToList } from '../routes/Clients/clients.actions';
import { backToMessage } from '../routes/Messages/routes/MessagesView/messages-view.actions.jsx';
import { backToMessage as backToRecurring } from '../routes/RecurringMessage/routes/RecurringView/recurring-view.actions.jsx';
import * as R from 'ramda';
// import icon from '../../sked-icon.png';
import queryString from 'query-string';

export const formatPhone = R.pipe(
  String,
  R.replace(/\D/g, ''),
  s => s.length > 10 ? R.slice(1, 11, s) : R.slice(0, 10, s),
  R.match(/^(\d{3})(\d{3})(\d{4})$/),
  s => s && s.length > 0 ? `(${s[1]}) ${s[2]}-${s[3]}` : ''
);

export const goto = ({ history, url, data, action, location }) => (dispatch) => {
  dispatch(reset());
  if (data) {
    action({ message: data });
  }
  R.cond([
    [R.equals('DASH'), () => dispatch(backToDash())],
    [R.equals('SKED'), () => dispatch(backToSked())],
    [R.equals('CLIENTS'), () => dispatch(backToList())],
    [R.equals('MESSAGE'), () => dispatch(backToMessage())],
    [R.equals('RECURRING'), () => dispatch(backToRecurring())],
    [R.T, R.always(null)]
  ])(location);
  history?.push(url);
  return null;
};

export const outputLocation = (page, perPage, totalCount, current) => {
  if (!(page >= 1) || (!perPage >= 1) || !(current >= 1)) {
    return '';
  }
  const tc = R.isNil(totalCount) ? '' : ` of ${totalCount}`;
  if (page === 1) {
    return '1 - ' + current + tc;
  } else if (current < perPage) {
    return ((page - 1) * perPage + 1) + ' - ' + (current + ((page - 1) * perPage)) + tc;
  } else {
    return ((page - 1) * perPage + 1) + ' - ' + (current + (page - 1) * perPage) + tc;
  }
};

export const etd = (action) =>
  action ?
    action.then((data) => {
      return {
        data,
      };
    }).catch(() => {
      // If the error returns an XMLresponse (say from nginx),
      // Then it blows up redux for some weird reason
      // Just returning a string seems to make everything happy
      return {
        error: 'error getting the data',
      };
    }) : undefined;



// Most of this code was taken from here:
// https://web-push-book.gauntface.com/subscribing-a-user/
export const registerSW = () => {
  return Promise.resolve().then(() => {
    if (!('serviceWorker' in navigator)) {
      // Service Worker isn't supported on this browser, disable or hide UI.
      return;
    }
    return navigator.serviceWorker.register('/sw.js');
  }).catch(e => {
    console.log('Failed to register SW: ' + e.message);
  });
};

const getSW = () => {
  return navigator.serviceWorker.getRegistration('/sw.js');
};

const askPermission = () => {
  return new Promise((resolve, reject) => {
    const permissionResult = Notification.requestPermission((result) => {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  }).then((permissionResult) => {
    if (permissionResult !== 'granted') {
      throw new Error('We weren\'t granted permission.');
    }
    return registerSW();
  });
};

const getNotificationPermissionState = () => {
  if (navigator.permissions) {
    return navigator.permissions.query({ name: 'notifications' }).then((result) => {
      return result.state;
    });
  }

  return new Promise((resolve) => {
    resolve(Notification.permission);
  });
};

const sendNotification = (title, options) => {
  return getSW().then(sw => {
    if (!sw) {
      return registerSW();
    }
    return sw;
  }).then(sw => {
    sw.showNotification(title, options);
  });
};

export const notification = (str, options, location) => {
  if (!('Notification' in window)) {
    // Bail here
    return;
  }

  const optionsWithIcon = R.merge(options, {
    icon: '/big-sked-icon.png',
    data: R.merge(R.propOr({}, 'data', options), { location })
  });

  return getNotificationPermissionState().then(state => {
    if (state !== 'granted') {
      return askPermission();
    }
    return;
  }).then(() => {
    return sendNotification(str, optionsWithIcon);
  }).catch(e => {
    console.error(e);
  });
};

let search = '';

export const setQueryParam = (key, value) => {
  const url = window.location.href.split('?')[0];
  const parsed = queryString.parse(search);
  const newParams = value === undefined ? R.dissoc(key, parsed) : R.merge(parsed, { [key]: value });
  if (R.isEmpty(newParams)) {
    window.location.href = url;
    search = '';
  } else {
    const query = queryString.stringify(newParams);
    window.location.href = url + '?' + query;
    search = query;
  }
};

export const birthdayFormatter = (birthday) => {
  if (birthday) {
    const b = birthday.split('-');
    return b[1] + '-' + b[2] + '-' + b[0];
  } else {
    return null;
  }
};

// ChiroTouch specific feature
// The office assigns a caseType to patients to differentiate accounts with the same name
export const caseType = (client) => {
  const caseType = R.pathOr(false, ['metadata', 'caseType'])(client);
  if (caseType) {
    return '[' + caseType + ']';
  } else {
    return '';
  }
};

export const pickColor = (color) => {
  if (color !== null && color !== undefined) {
    return 'hsl(' + color.hue + ',' + color.saturation + '%,' + color.lightness + '%)';
  } else {
    return 'hsl(200, 100%, 75%)';
  }
};

export const canSend = ({
  messageName,
  message,
  isEnabled = true,
  body,
}) => {
  if (isEnabled) {
    const isOldBodyEmpty = typeof body === 'string' ? R.isEmpty(body.trim()) : true;
    if (isOldBodyEmpty) {
      if (messageName.trim()) {
        /*
          If all 3 of the new types are `undefined`, then we can't send, for sure.
          `areAllBodiesGood` takes all the bodies and checks to see if they're empty
          (or basically empty for the HTML editor). It's really confusing...
        */
        const email = message.email &&
          R.ifElse(
            R.prop('html'),
            R.dissoc('body'),
            R.dissoc('html')
          )(message.email);

        const smsWithout = R.pipe(
          R.dissoc('attachmentIds'),
          R.dissoc('hasAttachments'),
        )(message.sms);

        const usedTypesList = [
          email,
          message.sms && smsWithout,
          message.push && message.push,
        ];

        const areAllBodiesGood = R.pipe(
          R.filter((v) => !R.equals(v, undefined)),
          R.ifElse(
            R.isEmpty,
            R.T,
            R.pipe(
              R.map(R.values),
              R.reduce((a, c) => {
                console.log(c);
                return a && R.reduce(
                  (b, d) => {
                    if (typeof d === 'boolean') {
                      return b;
                    }
                    console.log(d);
                    return R.xor(
                      b,
                      typeof d === 'string' ?
                        R.isEmpty(d.split('>')[1]?.split('</')[0].trim() || d) :
                        R.isEmpty(d)
                    );
                  },
                  true
                )(c);
              },
              true),
              R.not,
            ),
          ),
        )(usedTypesList);
        console.log('the end', areAllBodiesGood);
        return areAllBodiesGood;
      } else {
        return true;
      }
    } else {
      return !messageName.trim() && !body.trim();
    }
  }
  return !messageName.trim();
};

export const smartSearch = (str, isLead = false, isPhone = false, leadsAndClients) => {
  const splitUp = str.split(' ');
  const lastName = splitUp[0] ? splitUp[0].trim() : undefined;
  const firstName = splitUp[1] ? splitUp[1].trim() : undefined;
  const phone = splitUp[2] ? splitUp[2].trim() : undefined;
  if (isPhone) {
    return {
      phone: str,
      isLead,
      leadsAndClients,
    };
  }
  return {
    firstName,
    lastName,
    phone,
    isLead,
    leadsAndClients,
  };
};

export const attachEmailBody = R.ifElse(
  R.prop('email'),
  R.evolve({
    email: ({
      body = '',
      html = null,
      subject = '',
      attachmentIds = [],
      shouldFrame = true,
      addCalendarEvent,
    }) => {
      return {
        subject,
        attachmentIds,
        addCalendarEvent,
        body: {
          HTML: html ? {
            body: html,
            shouldFrame,
          } : undefined,
          PlainText: body ? body : undefined,
        },
      };
    },
  }),
  R.identity
);

export const createEmailData = ({
  body = '',
  html = null,
  subject = '',
  attachmentIds = [],
  attachments = undefined,
  shouldFrame = true,
}) => {
  const normalBody = {
    HTML: html && !R.isEmpty(html?.split('>')[1]?.split('</')[0].trim() || '') ? {
      body: html,
      shouldFrame,
    } : undefined,
    PlainText: body ? body : undefined,
  };

  const freshBody = R.reject(R.isNil, normalBody);
  if (R.isEmpty(freshBody) &&
    (R.isEmpty(attachments) || R.isEmpty(attachmentIds)) &&
    R.isEmpty(subject)) {
    return null;
  }
  return ({
    subject,
    attachmentIds: attachments ?
      attachments.map(({ attachmentId }) => attachmentId) :
      attachmentIds,
    body: normalBody,
  });
};

export const convertEmail = (message, features) =>
  R.includes('HtmlEmail', features) ? R.evolve({
    email: (d) => d ? R.ifElse(
      R.path(['body', 'HTML']),
      R.mergeLeft({
        body: {
          HTML: R.pathOr(null, ['body', 'HTML'], d),
          PlainText: undefined,
        }
      }),
      R.mergeLeft({
        body: {
          HTML: R.ifElse(
            R.pathOr(null, ['body', 'PlainText']),
            R.pipe(
              R.path(['body', 'PlainText']),
              R.replace(/\n/g, '<br/>'),
              R.assoc('body', R.__, { shouldFrame: true })
            ),
            R.always(null)
          )(d),
          PlainText: undefined,
        }
      }),
    )(d) : null,
  }, message)
    :
    R.evolve({
      email: (d) => d ? R.ifElse(
        R.path(['body', 'HTML']),
        R.mergeLeft({
          body: {
            HTML: undefined,
            PlainText: R.pathOr('', ['body', 'PlainText'], d),
          }
        }),
        R.identity,
      )(d) : null,
    }, message);

export let delayTimer;
export const stopDelay = () => {
  if (delayTimer) {
    clearTimeout(delayTimer);
    delayTimer = null;
  }
};
export const delay = (ms = 0) =>
  new Promise(res => {
    if (delayTimer) {
      clearTimeout(delayTimer);
    }
    delayTimer = setTimeout(res, ms);
    return delayTimer;
  });

export const getFeatureForTemplate = R.cond([
  [R.includes('HtmlEmail'), R.always(['HtmlEmail', 'NewMessaging'])],
  [R.includes('NewMessaging'), R.always(['NewMessaging'])],
  [R.T, R.always(['OldMessaging'])],
]);

/*
  The next two functions are based on https://developer.chrome.com/blog/how-to-convert-arraybuffer-to-and-from-string/
 */
// convert from ArrayBuffer to String
export const ab2str = (buf) => {
  return String.fromCharCode.apply(null, new Uint8Array(buf));
};

// convert from String to ArrayBuffer
export const str2ab = (str) => {
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i = 1 + i) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
};

export const encryptKey = async (locked) => {
  const encodedPass = new Uint8Array(16);
  if (locked.length < 16) {
    const salt = self.crypto.getRandomValues(new Uint8Array(16 - locked.length));
    let i = 0;
    for (const n of new TextEncoder().encode(locked)) {
      encodedPass[i] = n;
      i = i + 1;
    }
    for (const n of salt) {
      encodedPass[i] = n;
      i = i + 1;
    }
    localStorage.setItem('ss', ab2str(salt));
  }
  const key = await window.crypto.subtle.importKey(
    'raw',
    encodedPass,
    'AES-GCM',
    true,
    [
      'encrypt',
      'decrypt',
    ]);
  const session = localStorage.getItem('sked-session');
  const encrypted = await window.crypto.subtle.encrypt(
    { name: 'AES-GCM', iv: new Uint8Array(12) /* don't reuse key! */ },
    key,
    new TextEncoder().encode(session),
  );
  localStorage.setItem('sked-session', ab2str(encrypted));
};

// capitalize :: string -> string
export const capitalize = (s) => {
  return s[0].toUpperCase() + s.slice(1);
};

/*
  When converting a PlainText email to an HTML, you must include
  some tag for the admin to know that there is valid data in there.
  This is because the admin will check for there to be data in the
  tag to see if it's empty. TipTap's normal state will contain:
  <p ...></p>
  So we must have something in there for it to understand.
*/
export const bodyToHtml = (body) => {
  return `<p>${body}</p>`;
};
