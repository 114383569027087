import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: '240px',
    overflowY: 'auto',
  },
  relative: {
    position: 'relative',
    minHeight: 300,
  },
  row: {
    display: 'flex',
    alignItems: 'center',

    '& > h4': {
      fontSize: 22,
      margin: 0,
    },

    '& > .MuiIconButton-root': {
      padding: 8,
      marginLeft: 8,
    },
  },
  icon: {
    fontSize: 20,
  },
  tabs: {
    borderTop: '1px solid lightgray',
    marginTop: 10
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '33%',

    '& .MuiTextField-root': {
      marginBottom: 14,
      marginRight: 10,
    },
  },
  pMargin: {
    '& p': {
      margin: 0,
      marginBottom: 8,
    }
  },
  label: {
    fontSize: 14,
  },
  sectionContainer: {
    display: 'flex',
    minHeight: 200,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',

    '& > span': {
      marginBottom: 15,
    },
  },
  small: {
    marginTop: 8,
  },
  full: {
    paddingLeft: 14,
    borderLeftStyle: 'solid',
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.12)',
    width: '33%',
  },
  input: {
    width: 164,

    '& input': {
      fontSize: 16,
    }
  },
  footer: {
    padding: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  leadInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  seeButton: {
    cursor: 'pointer',
    position: 'absolute',
    right: 20,
    top: 10,
  },
  tagContainer: {
    display: 'flex',
    marginTop: 8,
    flexWrap: 'wrap',
    width: '100%',
    maxHeight: '240px',

    '& .MuiChip-root': {
      fontSize: 12,
      marginRight: 5,
      marginTop: 5,
    }
  },
  badge: {
    '& > span': {
      fontSize: 10,
      right: -5,
    }
  }
}));
