import * as R from 'ramda';
import React from 'react';
import {
  format,
  timeConvert,
} from '../services/joda.js';
import {
  LocalTime,
  LocalDate,
} from '@js-joda/core';
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Input,
  IconButton,
  FormHelperText,
  Select,
  MenuItem,
  Snackbar,
  Tooltip,
  FormControl,
  InputLabel,
  Paper,
  Grid
} from '@mui/material';
import { withStyles } from '@mui/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import CopyPasteHours, { CopyPasteMenu } from '../components/CopyPaste';

const Panel = withStyles({
  root: {
    padding: 15,
    marginBottom: 20,
  }
})(Paper);

type Location = {
  id: number;
  name?: string;
}

const showTime = (time: string) => {
  return format(LocalTime.parse(time), 'h:mm a');
};

type HourType = {
  open: string;
  close: string;
}

const showHour = (hours: HourType[]) => {
  if (hours.length === 0) {
    return (<div className="show-hour">Closed</div>);
  } else {
    return R.sortBy(R.prop('open'), hours).map((hour, index) => (
      <div
        key={index}
        className="show-hour">
        {showTime(hour.open)} - {showTime(hour.close)}
      </div>
    ));
  }
};

type Time = {
  open: ValueType;
  close: ValueType;
}
type DayOfWeek = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';
type Hours1 = {
  sunday?: HourType[];
  monday?: HourType[];
  tuesday?: HourType[];
  wednesday?: HourType[];
  thursday?: HourType[];
  friday?: HourType[];
  saturday?: HourType[];
}
type Hours2 = {
  sunday?: Time[];
  monday?: Time[];
  tuesday?: Time[];
  wednesday?: Time[];
  thursday?: Time[];
  friday?: Time[];
  saturday?: Time[];
}
type HoursType = {
  id: number;
  locationId: number;
  start: string;
  hours: Hours1 | Hours2;
}

type ShowHoursProps = {
  edit: (hourId: number) => void;
  remove: (proId: number) => void;
  proId: number;
  copy: (value: boolean) => void;
  locations: Location[]
}

export const showHours = ({
  edit,
  remove,
  proId,
  copy,
  locations = [],
}: ShowHoursProps) => (hours: HoursType) => {
  const location = R.find(
    R.pipe(
      R.ifElse(
        R.propEq('id', 'main'),
        R.assoc('id', null),
        R.identity
      ),
      R.propEq('id', hours.locationId),
    ),
    locations
  );
  return (
    <Panel key={hours.id}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        <div className="hours-header">
          Hours starting: {format(LocalDate.parse(hours.start), 'MMM d yyyy')}
        </div>
        {R.propOr(null, 'name', location)}
      </div>
      <Grid container justifyContent="space-around" display="flex">
        <Grid item>
          <div className="dayofweek-header">Sunday</div>
          {showHour(hours.hours.sunday as HourType[])}
        </Grid>
        <Grid item>
          <div className="dayofweek-header">Monday</div>
          {showHour(hours.hours.monday as HourType[])}
        </Grid>
        <Grid item>
          <div className="dayofweek-header">Tuesday</div>
          {showHour(hours.hours.tuesday as HourType[])}
        </Grid>
        <Grid item>
          <div className="dayofweek-header">Wednesday</div>
          {showHour(hours.hours.wednesday as HourType[])}
        </Grid>
        <Grid item>
          <div className="dayofweek-header">Thursday</div>
          {showHour(hours.hours.thursday as HourType[])}
        </Grid>
        <Grid item>
          <div className="dayofweek-header">Friday</div>
          {showHour(hours.hours.friday as HourType[])}
        </Grid>
        <Grid item>
          <div className="dayofweek-header">Saturday</div>
          {showHour(hours.hours.saturday as HourType[])}
        </Grid>
      </Grid>
      <br />
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}>
        <Tooltip
          title='Edit Hours'
          placement='top'
          arrow
        >
          <IconButton
            className="padding"
            style={{ cursor: 'pointer' }}
            onClick={() => edit(hours.id)} >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <CopyPasteHours
          onCopy={() => {
            sessionStorage.setItem('hours', JSON.stringify(hours.hours));
            copy(true);
          }}
          hoursType='hours'
          noPaste
        />
        <Tooltip
          title='Delete Hours'
          placement='top'
          arrow
        >
          <IconButton
            className="padding"
            style={{ cursor: 'pointer' }}
            onClick={() => proId === undefined ? remove(hours.id) : remove(hours.id)} >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </div>
    </Panel>
  );
};

type ValueType = {
  hour: string;
  minute: string;
  a: string;
}

type TimeChanged = ({ change, value }: { change: string; value: string }) => void;
type timeInputProps = {
  value: ValueType,
  timeChanged: TimeChanged;
}

const timeInput = ({
  value: {
    hour,
    minute,
    a,
  },
  timeChanged
}: timeInputProps) => {
  return (
    <div className="edit-hours-time-container">
      <input
        className="edit-hours-time"
        value={hour}
        onChange={(e) =>
          timeChanged({
            change: 'hour',
            value: e.target.value
          })
        } />
      <input
        className="edit-hours-time"
        value={minute}
        onChange={(e) =>
          timeChanged({
            change: 'minute',
            value: e.target.value
          })
        } />
      <div
        className="edit-hours-time edit-hours-time-a"
        style={{ cursor: 'pointer' }}
        onClick={() =>
          timeChanged({
            change: 'a',
            value: a === 'AM' ? 'PM' : 'AM'
          })
        }>
        {a}
      </div>
    </div>
  );
};

type editOpeningProps = {
  index: number;
  removeOpenPushed: (value: object) => void;
  timeChanged: TimeChanged;
}

const editOpening = ({ index, removeOpenPushed, timeChanged }: editOpeningProps) => ({
  open,
  close,
}: { open: ValueType; close: ValueType }) => (
  <div key={index} className="edit-hours-openings">
    <div
      onClick={() => removeOpenPushed({})}
      className="edit-hours-openings-close">
      x
    </div>
    open:
    {
      timeInput({
        value: open,
        timeChanged:
          R.pipe(
            R.assoc('openClose', 'open'),
            timeChanged
          )
      })
    }
    close:
    {
      timeInput({
        value: close,
        timeChanged:
          R.pipe(
            R.assoc('openClose', 'close'),
            timeChanged
          )
      })
    }
    <br />
  </div>
);

type editTimesProps = {
  times: Time[];
  timeChanged: TimeChanged;
  removeOpenPushed: (value: object) => void;
  closed: ({ checked }: { checked: boolean }) => void;
  add: (value: object) => void;
}

const editTimes = ({
  times,
  timeChanged,
  removeOpenPushed,
  closed,
  add,
}: editTimesProps) => {
  return (
    <div>
      <label
        style={{ cursor: 'pointer' }}>
        <input
          checked={times.length === 0}
          type='checkbox'
          onChange={(e) => closed({ checked: e.target.checked })}
        />
        &nbsp;Closed
        <br />
      </label>
      {
        (times.length === 0) ? '' :
          (
            <>
              <br />
              <a
                className="padding"
                style={{ cursor: 'pointer' }}
                onClick={() => add({})}>
                Add Open Times
              </a>
              <br />
              <br />
              {
                times.map((time, index) => editOpening({
                  index,
                  removeOpenPushed:
                    R.pipe(
                      R.assoc('index', index),
                      removeOpenPushed
                    ),
                  timeChanged:
                    R.pipe(
                      R.assoc('index', index),
                      timeChanged
                    ),
                })(time))
              }
            </>
          )
      }
    </div>
  );
};

const dayOfWeekTitle = (dayOfWeek: string) => {
  switch (dayOfWeek) {
    case 'sunday':
      return 'Sunday';
    case 'monday':
      return 'Monday';
    case 'tuesday':
      return 'Tuesday';
    case 'wednesday':
      return 'Wednesday';
    case 'thursday':
      return 'Thursday';
    case 'friday':
      return 'Friday';
    case 'saturday':
      return 'Saturday';
    default:
      return 'foo';
  }
};

type DayOfWeekViewProps = {
  hours: HoursType;
  timeChanged: TimeChanged;
  removeOpenPushed: (value: object) => void;
  closed: (value: object) => void;
  add: (value: object) => void;
  copy: (value: boolean) => void;
  dayOfWeek: DayOfWeek;
}

const DayOfWeekView = ({
  hours,
  timeChanged,
  removeOpenPushed,
  closed,
  add,
  copy,
  dayOfWeek,
}: DayOfWeekViewProps) => {
  const [_anchorEl, setAnchorEl] = React.useState(null);
  const times = R.sortBy(
    R.compose(
      (props: ValueType) => R.prop('a', props),
      (props: Time) => R.prop('open', props)
    )
  )(hours.hours[dayOfWeek] as Time[]);
  const pipedAdd = R.pipe(R.assoc('dayOfWeek', dayOfWeek), add);
  return (
    <Grid item>
      <div
        className="dayofweek-header"
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {dayOfWeekTitle(dayOfWeek)}
        <CopyPasteMenu
          onCopy={() => {
            setAnchorEl(null);
            const toTime = ({ hour, minute, a }: ValueType) => (
              format(LocalTime.parse(timeConvert([hour, minute, a])), 'HH:mm:ss')
            );
            const properTimes = R.map(R.mapObjIndexed(toTime))(times);
            sessionStorage.setItem('single-day-hours', JSON.stringify(properTimes));
            copy(true);
          }}
          onPaste={() => {
            setAnchorEl(null);
            const improperHours = JSON.parse(sessionStorage.getItem('single-day-hours'));
            const fromTime = (open: string) => {
              return ({
                hour: format(LocalTime.parse(open), 'h'),
                minute: format(LocalTime.parse(open), 'mm'),
                a: format(LocalTime.parse(open), 'a'),
              });
            };
            const hours = R.map(R.mapObjIndexed(fromTime))(improperHours);
            pipedAdd({ hours });
          }}
          hoursType='single-day-hours'
          style={{ fontSize: 16 }}
        />
      </div>
      {
        editTimes({
          times,
          add: pipedAdd,
          closed:
            R.pipe(R.assoc('dayOfWeek', dayOfWeek), closed),
          timeChanged:
            R.pipe(R.assoc('dayOfWeek', dayOfWeek), timeChanged),
          removeOpenPushed:
            R.pipe(R.assoc('dayOfWeek', dayOfWeek), removeOpenPushed),
        })
      }
    </Grid>
  );
};

type editHoursProps = {
  hours: HoursType;
  cancel: () => void;
  save: (hour: HoursType, id?: number) => void;
  timeChanged: TimeChanged;
  removeOpenPushed: (value: object) => void;
  closed: (value: object) => void;
  add: (value: object) => void;
  dateChanged: ({ start, locationId }: { start?: string; locationId?: string }) => void;
  proId: number;
  locations?: Location[];
  copy: (value: boolean) => void;
  addNewHours: (value: Hours1) => void;
  hasLocations?: boolean;
}

export const editHours = ({
  hours,
  cancel,
  save,
  timeChanged,
  removeOpenPushed,
  closed,
  add,
  dateChanged,
  proId = undefined,
  locations = [],
  copy,
  addNewHours,
  hasLocations = false,
}: editHoursProps) => {
  return (
    <Panel>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
        className="hours-header">
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <TextField
            type='date'
            label="Hours Starting"
            value={hours.start}
            onChange={(e) => dateChanged({ start: e.target.value })} />
          <Tooltip
            title={
              <>
                Office hours are intended to display the general office hours in the app.
                <br />
                If you want to set special office hours to restrict scheduling, remember to set the special schedule hours in the schedule tab.
              </>
            }
            placement="top"
            arrow>
            <InfoIcon style={{ fontSize: '16px', marginLeft: '20px' }} />
          </Tooltip>
        </div>
        {hasLocations && !R.isEmpty(location) &&
          <FormControl style={{ minWidth: 200 }}>
            <InputLabel id="location-label">Location</InputLabel>
            <Select
              labelId='location-select-label'
              id="provider-simple-select"
              value={hours.locationId ? hours.locationId : 'main'}
              onChange={e => {
                const v = e.target.value as string;
                dateChanged({ locationId: v === 'main' ? undefined : v });
              }}
            >
              {R.tail(locations).map(({ id, name }) =>
                <MenuItem key={id} value={id}>{name}</MenuItem>
              )}
            </Select>
          </FormControl>}
      </div>
      <Grid container justifyContent="space-between" display="flex">
        {
          [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday'
          ].map((day: DayOfWeek) => (
            <DayOfWeekView
              dayOfWeek={day}
              hours={hours}
              // cancel={cancel}
              // save={save}
              timeChanged={timeChanged}
              removeOpenPushed={removeOpenPushed}
              closed={closed}
              add={add}
              copy={copy}
            />
          ))
        }
      </Grid>
      <br />
      <div style={{
        height: '37px',
        marginBottom: '5px',
      }}>
        <Button
          variant='contained'
          onClick={() => proId === undefined ? save(hours) : save(hours, proId)}>
          Save
        </Button>
        &nbsp;
        <Button
          variant='outlined'
          style={{ cursor: 'pointer' }}
          onClick={() => cancel()}>
          Cancel
        </Button>
        <CopyPasteMenu
          onCopy={() => {
            const toTime = ({ hour, minute, a }: ValueType): Hours2[] => (
              format(LocalTime.parse(timeConvert([hour, minute, a])), 'HH:mm:ss')
            );
            const properTimes = R.mapObjIndexed(R.map(R.mapObjIndexed(toTime)))(hours.hours as Hours2);
            sessionStorage.setItem('hours', JSON.stringify(properTimes));
            copy(true);
          }}
          onPaste={() => {
            const improperHours = JSON.parse(sessionStorage.getItem('hours'));
            const fromTime = (open: string): ValueType => {
              return ({
                hour: format(LocalTime.parse(open), 'h'),
                minute: format(LocalTime.parse(open), 'mm'),
                a: format(LocalTime.parse(open), 'a'),
              });
            };
            const hours = R.mapObjIndexed(R.map(R.mapObjIndexed(fromTime)))(improperHours);
            addNewHours(hours);
          }}
          hoursType='hours'
        />
      </div>
    </Panel>
  );
};
type TimeParsedProp = {
  a: string;
  hour?: string;
  time?: string;
  error?: string;
  inputTime: string;
  minute: string;
  parsed: string;
}
type TimeParsed = {
  open: TimeParsedProp;
  close: TimeParsedProp
}
type EditTimesProps = {
  add: ({ hours }: { hours?: TimeParsedProp[] }) => void;
  times: TimeParsed[];
  timeChanged: TimeChanged;
  removeOpenPushed: (value: object) => void;
  closed: (value: object) => void;
  isEvent: boolean;
}
export const EditTimes = ({
  add,
  times,
  timeChanged,
  removeOpenPushed,
  closed,
  isEvent
}: EditTimesProps) => {
  const [isCopied, setIsCopied] = React.useState(false);
  return (
    <div>
      <Snackbar
        open={isCopied}
        message='Hours Copied!'
        autoHideDuration={4000}
        onClose={() => setIsCopied(false)} />
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={times.length === 0}
              onChange={(e) => closed({ checked: e.target.checked })}
              name="closed" />}
          label={isEvent ? 'All day' : 'Closed'}
        />
        <CopyPasteMenu
          onCopy={() => {
            const hours = R.map(R.mapObjIndexed(R.prop('parsed')))(times);
            console.log(times, hours);
            sessionStorage.setItem('single-day-hours', JSON.stringify(hours));
            setIsCopied(true);
          }}
          onPaste={() => {
            const improperHours: TimeParsedProp[] = JSON.parse(sessionStorage.getItem('single-day-hours'));
            const fromTime = (open: string): TimeParsedProp => {
              return ({
                hour: format(LocalTime.parse(open), 'h'),
                minute: format(LocalTime.parse(open), 'mm'),
                a: format(LocalTime.parse(open), 'a'),
                time: open,
                parsed: open,
                inputTime: format(LocalTime.parse(open), 'h:mm a')
              });
            };
            const hours = (R.map(R.mapObjIndexed(fromTime))(improperHours)) as unknown as TimeParsedProp[];
            add({ hours });
          }}
          hoursType='single-day-hours'
        />
      </div>
      {times.length > 0 &&
        <div>
          <FormHelperText>Times like 8 am, 9:15pm, 15:00, 10pm are all valid.</FormHelperText>
          <TableContainer>
            <Table aria-label="hours entry table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell>Open</TableCell>
                  <TableCell>Closed</TableCell>
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {times.map((time, index) =>
                  <EditOpening
                    key={index}
                    showAdd={index === times.length - 1}
                    add={add}
                    // index={index}
                    removeOpenPushed={R.pipe(R.assoc('index', index), removeOpenPushed)}
                    timeChanged={R.pipe(R.assoc('index', index), timeChanged)}
                    open={time.open}
                    close={time.close}
                  />)}
              </TableBody>
            </Table>
          </TableContainer>
        </div>}
    </div>
  );
};

type AddProps = ({ hours }: { hours?: TimeParsedProp[] }) => void;

type EditOpeningProps = {
  removeOpenPushed: (value: object) => void;
  timeChanged: TimeChanged;
  open: TimeParsedProp;
  close: TimeParsedProp;
  showAdd: boolean;
  add: AddProps;
}
const EditOpening = ({ removeOpenPushed, timeChanged, open, close, showAdd, add }: EditOpeningProps) => (
  <TableRow>
    <TableCell>
      <IconButton onClick={() => removeOpenPushed({})} aria-label="delete">
        <DeleteIcon />
      </IconButton>
    </TableCell>
    <TableCell>
      <TimeInput
        value={open}
        name='open'
        timeChanged={R.pipe(
          R.assoc('openClose', 'open'),
          timeChanged
        )} />
    </TableCell>

    <TableCell>
      <TimeInput
        name='closed'
        value={close}
        timeChanged={R.pipe(
          R.assoc('openClose', 'close'),
          timeChanged
        )} />
    </TableCell>

    <TableCell>
      {showAdd &&
        <IconButton type="button" onClick={() => add({})} aria-label="add hours">
          <AddIcon />
        </IconButton>}
    </TableCell>
  </TableRow>
);

type TimeInputProps = {
  name: string;
  value: TimeParsedProp,
  timeChanged: TimeChanged;
}
const TimeInput = ({
  name,
  value: {
    inputTime,
    error
  },
  timeChanged
}: TimeInputProps) => {
  const [v, setV] = React.useState(inputTime || '');
  const to = React.useRef<NodeJS.Timeout>();
  React.useEffect(() => {
    setV(inputTime);
  }, [inputTime]);
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setV(value);
    clearTimeout(to.current);
    to.current = setTimeout(() => {
      timeChanged({
        change: 'inputTime',
        value
      });
    }, 250);
  };
  return (
    <Input
      autoFocus={name === 'open'}
      value={v}
      error={!!error}
      onChange={handleOnChange} />
  );
};
