import React, { useState, useEffect } from 'react';
import { useSelector } from '../../reducers';
import { useDispatch } from 'react-redux';
import {
  CircularProgress, TableHead, TableBody, IconButton,
} from '@mui/material';
import Header from '../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import HeaderTabs from '../../components/HeaderTabs/HeaderTabs.component';
import FilterDate from '../../components/FilterDate/FilterDate.component';
import
FilterApptTypes
  from '../../components/FilterApptTypes/FilterApptTypes.component';
import
FilterProfessional
  from '../../components/FilterProfessional/FilterProfessional.component';
import
FilterClients
  from '../../components/FilterClients/FilterClients.component';
import {
  TableContainer, HeaderCell, BodyCell, TableRow,
} from '../../components/CustomTable';
import { usePromise } from '../../services/promise.hook';
import {
  LocalDateTime, LocalDate,
} from '@js-joda/core';
import {
  format, startOf, endOf, tzParseFormat,
} from '../../services/joda';
import { useTitle } from '../../services/useTitle';
import {
  getAppts, getList, AppointmentWithArrival, removeAppt,
} from './spark-reporting.actions';
import {
  getPros, getApptTypes,
} from '../../components/AutomationBasedMessage/automation-message.actions';
import {
  AppointmentType as ApptType, Professional,
} from '../../routes/Appointments/appointments.types';
import { Client } from '../Clients/clients.types';
import {
  isEmpty,
} from 'ramda';
import { History } from 'history';
import {
  reset, getCurrentClient, backToClient,
} from '../Clients/components/client-dialog/client-dialog.actions.jsx';
import ClientEditDialog from '../Clients/components/client-dialog/client-dialog.component.jsx';
import DeleteIcon from '../../icons/Delete.icon';

interface RowContainerProps {
  appt: AppointmentWithArrival;
  tz: string;
  selectClient: (cli: Client) => void;
  onDelete: (apptId: number) => void;
  admin: boolean;
}

const RowContainer = ({
  appt, tz, selectClient, onDelete, admin,
}: RowContainerProps) => {
  return (
    <TableRow key={appt.id}>
      <BodyCell fixed>
        <a onClick={() => selectClient(appt.client)}>
          {appt.client.firstName} {appt.client.lastName}
        </a>
      </BodyCell>
      <BodyCell>
        {tzParseFormat(appt.time, tz, 'MM/dd/yyyy h:mm a')}
      </BodyCell>
      <BodyCell>
        {appt.appointmentType.internalName}
      </BodyCell>
      <BodyCell>
        {appt.professional?.firstName} {appt.professional?.lastName}
      </BodyCell>
      <BodyCell>
        {tzParseFormat(appt.arrived, tz, 'MM/dd/yyyy h:mm a')}
      </BodyCell>
      {admin &&
        <BodyCell>
          <IconButton onClick={() => onDelete(appt.id)}>
            <DeleteIcon />
          </IconButton>
        </BodyCell>}
    </TableRow>
  );
};

const qFormat = 'yyyy-MM-dd\'T\'HH:mm:ss\'Z\'';

type LoginState = {
  login: {
    office?: {
      timezone: string;
    };
    admin?: boolean;
  }
}

const now = () => {
  return format(LocalDateTime.now(), 'yyyy-MM-dd');
};

interface SparkReportingProps {
  history: History;
}

const SparkReporting = ({ history }: SparkReportingProps) => {
  const dispatch = useDispatch();
  const [state, setState] = useState('LIST');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(1);
  const [month, setMonth] = useState(now());
  const [pros, setPros] = useState<Professional[]>([]);
  const [types, setTypes] = useState<ApptType[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [tab, setTab] = useState<string>('reactivations');
  const sparkState = usePromise(getAppts, {
    page, totalPages: 1, totalCount: 1, data: []
  });
  const listState = usePromise(getList, null);
  const proState = usePromise(getPros, []);
  const typeState = usePromise(getApptTypes, []);
  const deleteApptState = usePromise(removeAppt, null);

  const {
    tz, admin,
  }: {
    tz: string, admin: boolean
  } = useSelector((state: LoginState) => ({
    tz: state.login.office.timezone,
    admin: state.login.admin,
  }));

  const tackOnPros = (ps: Professional[]) => () => {
    sparkState.setState((s) => ({
      ...s,
      data: {
        ...s.data,
        data: s.data.data.map((a: AppointmentWithArrival) => {
          const professional = ps.find(({ id }) => id === a.appointmentType.professionalId);
          return {
            ...a,
            professional,
          };
        }),
      }
    }));
  };

  useTitle('Spark Reporting');

  useEffect(() => {
    typeState.invoke({});
    proState.invoke({});
  }, []);

  useEffect(() => {
    if (proState.data) {
      const lt = `${month}T00:00:00`;
      listState.invoke({ listPath: tab }).then((listId) => {
        sparkState.invoke({
          listId,
          page,
          perPage,
          query: {
            after: format(startOf(LocalDateTime.parse(lt), 'month'), qFormat),
            before: format(endOf(LocalDateTime.parse(lt), 'month'), qFormat),
            appointmentTypeIds: isEmpty(types) ? null : types.map(({ id }) => id),
            professionalIds: isEmpty(pros) ? null : pros.map(({ id }) => id),
            clientIds: isEmpty(clients) ? null : clients.map(({ id }) => id),
            order: 'Ascending',
          },
        }).then(tackOnPros(proState.data));
      });
      setPage(1);
      setPerPage(25);
    }
  }, [proState.data, types, clients, pros, month, page, perPage, tab]);

  const resetFilters = () => {
    setMonth(now());
    setTypes([]);
    setClients([]);
    setPros([]);
  };

  const backToList = () => {
    dispatch(reset());
    setState('LIST');
  };

  const back = () => {
    dispatch(backToClient());
  };

  const selectClient = (cli: Client) => {
    dispatch(getCurrentClient(cli));
    setState('CLIENT');
  };

  const onDelete = (apptId: number) => {
    deleteApptState.invoke({
      apptId,
      listId: listState.data
    }).then(() => {
      sparkState.setState({
        ...sparkState,
        data: {
          ...sparkState.data,
          totalCount: sparkState.data.totalCount - 1,
          data: sparkState.data.data.filter(({ id }: AppointmentWithArrival) => {
            return id !== apptId;
          }),
        }
      });
    });
  };

  return (
    <>
      <Header
        title='Spark Reporting'
        pageId='spark_reporting'
        leftIcons={[
          <FilterDate
            date={month}
            title={format(LocalDate.parse(month), 'MMM yyyy')}
            setDate={setMonth}
            monthOnly
          />,
          <FilterApptTypes
            allTypes={typeState.data}
            professionals={proState.data}
            selectedTypes={types}
            save={(types) => console.log(types)}
            setApptTypes={(t: ApptType[]) => setTypes(t)}
          />,
          <FilterProfessional
            allProfessionals={proState.data}
            selectedPros={pros}
            save={(pros) => console.log(pros)}
            setProps={(p: Professional[]) => setPros(p)}
          />,
          <FilterClients
            save={(cli) => console.log(cli)}
            setSelectedClients={(cli: Client[]) => setClients(cli)}
            selectedClients={clients}
          />,
          <HeaderTabs
            value={tab}
            setTab={setTab}
            tabs={[
              { label: 'Reactivations', value: 'reactivations' },
              { label: 'New Patients', value: 'new_patients' },
            ]}
          />,
        ]}
        rightIcons={[
          sparkState.loading && <CircularProgress size={20} />,
          <HeaderButton
            onClick={resetFilters}
            title='Reset Filters'
          />
        ]}
        onlyIconsWidth={790}
        breakPoints={[
          {
            width: 490,
            mobileItems: [5],
          },
          {
            width: 418,
            mobileItems: [1, 5],
          },
        ]}
      />
      <div className={'page-root '} style={{
        overflow: 'unset',
        maxHeight: 'calc(100% - 61px)',
      }}>
        <ClientEditDialog
          open={state === 'CLIENT'}
          from={'CLIENTS'}
          history={history}
          onClose={backToList}
          back={back}
          gotoClient={back} />
        <TableContainer
          pagination
          paginationData={{
            page,
            perPage,
            setPerPage,
            totalPages: sparkState.data.totalPages,
            totalCount: sparkState.data.totalCount,
            addPage: (n: number) => setPage(page + n),
            currentLength: sparkState.data.data.length,
          }}
          fullPage
          headerFixed
        >
          <TableHead>
            <TableRow>
              <HeaderCell fixed minWidth={180}>
                Client
              </HeaderCell>
              <HeaderCell>
                Appt Date
              </HeaderCell>
              <HeaderCell>
                Appt Type
              </HeaderCell>
              <HeaderCell>
                Professional
              </HeaderCell>
              <HeaderCell>
                Arrival Time
              </HeaderCell>
              {admin &&
                <HeaderCell>
                  Actions
                </HeaderCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {sparkState.data.data.map((appt: AppointmentWithArrival) => {
              return (
                <RowContainer
                  tz={tz}
                  appt={appt}
                  selectClient={selectClient}
                  onDelete={onDelete}
                  admin={admin}
                />
              );
            })}
          </TableBody>
        </TableContainer>
      </div>
    </>
  );
};

export default SparkReporting;
