import React, { useState } from 'react';

import {
  IconButton,
  Popover,
  Grid,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useStyles } from './headerMore.styles';

type HeaderMoreProps = {
  items?: Element[] | JSX.Element[] | React.ReactNode[];
}

const HeaderMore = ({ items }: HeaderMoreProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid>
      <Grid marginLeft={0.5}>
        <IconButton
          onClick={(event) => setAnchorEl(event.currentTarget)}
          className={classes.button}
        >
          <MoreVertIcon className={classes.icon} />
        </IconButton>
      </Grid>
      <Grid id="more-header-button" onClick={handleClose} />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid className={classes.grid} display="flex" alignItems="center" maxWidth={550} flexWrap="wrap">
          {items}
        </Grid>
      </Popover>
    </Grid>
  );
};

export default HeaderMore;
