import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {},
  officeName: {
    fontSize: 14,
    fontWeight: 500,
    color: '#FFF',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  bigName: {
    fontSize: 12,
    lineHeight: 1.2,
    '-webkit-line-clamp': 3,
  },
  officeId: {
    fontSize: 14,
    fontWeight: 400,
    color: '#FFF'
  },
  cursor: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    fontSize: 24,
    color: '#FFF'
  },
  menuItem: {
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)'
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 240,
    minWidth: 160,
    whiteSpace: 'normal',

    '& > p': {
      fontSize: 14,
      fontWeight: 500,
      color: '#51515a'
    },
    '& > span': {
      fontSize: 14,
      fontWeight: 400,
      color: '#888888'
    },
  }
}));
