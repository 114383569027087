export type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U];
//Cr = create - doesn't include the ids

export const defaultRevision: Revision = {
  formId: 0,
  formName: '',
  formDescription: '',
  formSendNotification: false,
  number: 0,
  revisionId: 0,
  description: '',
  pages: [],
  logoUrl: null,
  logoAlignment: null,
  info: null,
  conditionals: [],
};

export interface Form {
  id: number;
  name: string;
  description: string;
  sendNotification?: boolean;
  pdfEhr: 'OnGeneration' | 'Manual';
  ehrFolder: string;
  pdfGeneration: 'ClientSubmit' | 'Manual';
}

export interface CrForm {
  id?: number;
  name: string;
  description: string;
  sendNotification?: boolean;
  pdfEhr?: 'OnGeneration' | 'Manual';
  ehrFolder?: string;
  pdfGeneration?: 'ClientSubmit' | 'Manual';
}

export interface Revision {
  formId: number;
  formName: string;
  formDescription: string;
  formSendNotification: boolean;
  number: number;
  revisionId: number;
  description: string;
  pages: Page[];
  logoUrl: string;
  pdf?: number;
  logoAlignment: 'Left' | 'Right' | 'Center';
  info: string;
  conditionals: Conditional[];
}

export interface CrRevision {
  pages: CrPage[];
  description: string;
  logoUrl: string;
}

export interface Page {
  items: PageItem[];
  pageId: number;
  pageNumber: number;
}

export type NumberFilter = AtLeastOne<INumberFilter>;

export interface INumberFilter {
  EqualTo: number;
  LessThan: number;
  IsBetween: number[];
  LessThanOrEqualTo: number;
  GreaterThanOrEqualTo: number;
  GreaterThan: number;
  NotEqualTo: number;
}

export type TextFilter = AtLeastOne<ITextFilter>;

export interface ITextFilter {
  EqualTo: string;
  NotEqualTo: string;
  Contains: string;
  IsOneOf: string[];
  IsEmpty: [];
  IsNotEmpty: [];
}

export type DateFilter = AtLeastOne<IDateFilter>;

export interface TimeUnit {
  months: number;
  days: number;
  years: number;
  hidden?: number;
}

export const defaultTimeUnit: TimeUnit = {
  months: 0,
  days: 0,
  years: 0,
};

export interface IDateFilter {
  EqualTo: string;
  NotEqualTo: string;
//  IsBefore: string;
//  IsAfter: string;
//  IsBetween: string[];
  IsEmpty: [];
  IsNotEmpty: [];
//  IsExactlyAgo: TimeUnit;
  IsBefore: TimeUnit;
  IsAfter: TimeUnit;
  IsBetween?: TimeUnit[];
//  IsMoreThanAndLessThanAgo: TimeUnit[];
//  IsMoreThanInFuture: TimeUnit;
//  IsLessThanInFuture: TimeUnit;
//  IsExactlyInFuture: TimeUnit;
//  IsMoreThanAndLessThanInFuture?: TimeUnit[];
}

export type MultiFilter = AtLeastOne<IMultiFilter>;

export interface IMultiFilter {
  HasAnyOf: string[];
  HasAllOf: string[];
  HasNoneOf: string[];
  EqualTo: string;
  IsEmpty: [];
  IsNotEmpty: [];
}

export type Value = AtLeastOne<IValue>;

export interface IValue {
  TextFilter: TextFilter;
  BoolFilter: boolean;
  DateFilter: DateFilter;
  NumberFilter: NumberFilter;
  MultiFilter: MultiFilter;
}

export type Target = AtLeastOne<ITarget>;

interface ITarget {
  Page: number;
  Question: number;
  Section: number;
}

export interface Conditional {
  conditionalId: number;
  revId: number;
  questionIndex: number;
  value: Value;
  target: Target[];
}

export interface CrPage {
  items: CrPageItem[];
  pageNumber: number;
}

export interface PageItem {
  child: PageChild;
  pageItemId: number;
  order: number;
}

export interface CrPageItem {
  child: CrPageChild;
  order: number;
}

export type PageChild = AtLeastOne<IPageChild>;

interface IPageChild {
  Text: string;
  Header: string;
  Section: Section;
}

export type CrPageChild = AtLeastOne<ICrPageChild>;

interface ICrPageChild {
  Text: string;
  Header: string;
  Section: CrSection;
}


export interface Section {
  sectionNumber: number;
  name: string;
  questions: Question[];
  description: string;
}

export interface CrSection {
  sectionNumber: number;
  name: string;
  questions: CrQuestion[];
  description: string;
}

export interface Question {
  questionId: number;
  width: number;
  height: number;
  isRequired: boolean;
  order: number
  question: QuestionThingy;
  metadata?: any;
  forOffice: boolean;
  officeNote: string;
}

export interface CrQuestion {
  width: number;
  height: number;
  isRequired: boolean;
  order: number
  question: QuestionThingy;
  metadata?: any;
  forOffice: boolean;
}

export type QuestionThingy = AtLeastOne<IQuestionThingy>;

interface IQuestionThingy {
  DocumentUpload: DocumentUploadData;
  Picture: PictureData;
  FixedTable: FixedTableData;
  FlexibleTable: FlexibleTableData;
  SimpleQuestion: SimpleQuestion;
  DocumentAgreement: DocumentAgreementData;
  RadioGrid: RadioGridData;
  Signature: number[];
}

interface RadioGridData {
  optionType: ScaleDataType;
  labels: string[];
  options: OptionData[];
}

interface PictureData {
  image: string;
  colors: PictureColorData[];
}

interface PictureColorData {
  color: string;
  label: string;
}

interface FixedTableData {
  columns: SimpleQuestion[];
  labels: string[];
}

interface FlexibleTableData {
  columns: SimpleQuestion[];
}

export enum ScaleDataType {
  CheckBox = 'CheckBox',
  Radio = 'Radio',
  Dropdown = 'Dropdown'
}

interface DocumentUploadData {
  name: string;
  description: string;
}

interface DocumentAgreementData {
  name: string;
  body: string;
}

export interface SimpleQuestion {
  questionType: SimpleQuestionType;
  label: string
}

export type SimpleQuestionType = AtLeastOne<ISimpleQuestionType>;

interface ISimpleQuestionType {
  LongText: number[];
  EmailQuestion: number[];
  None: number[];
  DropdownOptions: Options;
  RadioOptions: Options;
  ShortText: number[];
  DateQuestion: number[];
  NumberQuestion: {
    minNumber: number;
    maxNumber: number;
  },
  CheckboxOptions: Options;
  SingleCheckbox: boolean;
  SectionInstruction: number[];
}

export interface Options {
  options: OptionData[];
}

interface OptionData {
  label: string;
}

export interface Submission {
  revId: number;
  appointmentId?: number;
  created: string;
  updated: string;
  isComplete: boolean;
  token: string;
  form: Form;
  submitted?: string;
  subId: number;
  client: any; // TODO: add `Client` type
  uploaded: string;
  archived?: string;
  started?: boolean;
}
