import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  customButtom: {
    padding: '2px 10px',
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'none',
    borderRadius: 4,
    color: 'rgba(0,0,0,.87)',
    border: '1.5px solid transparent !important',
    margin: 0,
    position: 'relative',
    minWidth: 18,
    whiteSpace: 'nowrap',

    '&:hover': {
      border: '1.5px solid transparent !important',
      background: '#F2F2F3'
    },
  },
  onlyIcon: {
    borderRadius: '50%',
    padding: 8,
  },
  buttonIcon: {
    width: 17,
    height: 17,
    color: 'rgba(0, 0, 0, 0.6)',
  },
  btnDisabled: {
    color: 'rgba(0, 0, 0, 0.3)'
  },
  borderSolid: {
    border: '1.5px solid rgba(0, 0, 0, 0.2) !important',
    padding: '2px 10px',
    marginRight: 5,

    '&:hover': {
      border: '1.5px solid rgba(0, 0, 0, 0.2) !important',
    },
  },
  hide: {
    background: '#CBEBFD',

    '&:hover': {
      border: '1.5px solid rgba(0,0,0,0.1) !important',
      background: '#CBEBFD'
    },
  },
  filter: {
    background: '#D6F3D5',

    '&:hover': {
      border: '1.5px solid rgba(0,0,0,0.1) !important',
      background: '#D6F3D5'
    },
  },
  sort: {
    background: '#FAE0CF',

    '&:hover': {
      border: '1.5px solid rgba(0,0,0,0.1) !important',
      background: '#FAE0CF'
    },
  },
  group: {
    background: '#DFDBFA',

    '&:hover': {
      border: '1.5px solid rgba(0,0,0,0.1) !important',
      background: '#DFDBFA'
    },
  },
  archiveChat: {
    background: '#3299E5',
    color: '#FFF',

    '&:hover': {
      background: '#3299E5'
    },
  },
  primary: {
    background: theme.palette.primary.main,
    color: '#FFF',

    '&:hover': {
      background: theme.palette.primary.main,
      opacity: 0.9,
    },
    '&:disabled': {
      opacity: 0.5,
      color: '#FFF',
      background: theme.palette.primary.main
    },
  },
  danger: {
    background: theme.palette.error.main,
    color: '#FFF',

    '&:hover': {
      background: theme.palette.error.main,
      opacity: 0.9,
    },
  },
  lightgray: {
    background: '#f4f4f4',
    color: 'rgba(0,0,0,.87)',

    '&:hover': {
      background: '#f4f4f4',
      opacity: 0.9,
    },
  },
  warningIcon: {
    width: '10px',
    height: '10px',
    backgroundColor: 'orange',
    borderRadius: '20px',
    position: 'absolute',
    right: -5,
    top: -5,
  }
}));
