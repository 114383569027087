import React from 'react';
import { match as Match } from 'react-router-dom';
import
CreateAutomationMessage
  from '../../components/AutomationBasedMessage/create-automation-message.component';
import { includes } from 'ramda';
import { History } from 'history';
import queryString from 'query-string';

interface MatchParams {
  id?: string;
}

interface TimeBasedMessageEditProps {
  match: Match<MatchParams>;
  history: History;
  location: {
    search: string,
    pathname: string;
    state?: {
      from: {
        search: string,
        pathname: string;
      };
    };
  };
}

const TimeBasedMessageEdit = ({
  match, history, location
}: TimeBasedMessageEditProps) => {
  const msgId = match?.params?.id ? Number(match.params.id) : 0;
  const type = includes('template', match.path) ? 'template' : 'message';
  const query = queryString.parse(location.search);
  const copying = Number(query?.copying || 0);
  const fromTemplate = Number(query?.fromSkedTemplate || 0);
  return (
    <>
      <CreateAutomationMessage
        messageId={msgId}
        copying={copying}
        filter={'Clients'}
        messageType={type}
        automationMessageType={'TimeBasedReminder'}
        includeHeader
        goBack={history.goBack}
        fromTemplate={fromTemplate}
      />
    </>
  );
};

export default TimeBasedMessageEdit;
