import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { History } from 'history';
import { merge } from 'ramda';
import { PopupTemplate, popup, popupWithCancel } from '../../services/Popup.js';
import { Snackbar, Button, Grid, Box } from '@mui/material';
import api from '../../services/api.js';
import { useCheckIsAdmin } from '../../services/checkIsAdmin.hook';

import { useSelector } from '../../reducers';

type UpdateProps = {
  busy?: boolean;
  snackbar?: {
    open: boolean;
    msg: string;
  },
}

const CheckUnsynced = ({ history }: { history: History }) => {
  const lState = useSelector((state) => state.session.status);
  const admin = useSelector((state) => state.login.admin);

  const [state, setState] = useState({
    busy: false,
    snackbar: {
      open: false,
      msg: '',
    }
  });

  const update = (data: UpdateProps) => {
    setState(merge(state, data));
  };

  const checkUnsynced = () => {
    update({ busy: true });
    api.post('admin/genesis/sendUnsent').then(() => {
      update({
        busy: false,
        snackbar: {
          open: true,
          msg: 'Started checking for unsynced Genesis patients!'
        },
      });
    }).catch((error) => {
      update({ busy: false });
      if (error.response.status === 401) {
        popup('Error!', 'Unauthorized! Go to Select Office page and return.');
      } else {
        popup('Error!', 'Unable to check for unsynced patients!');
      }
      console.log(error);
    });
  };

  useCheckIsAdmin(admin, lState, history);

  return (
    <div>
      <Snackbar
        open={state.snackbar.open}
        message={state.snackbar.msg}
        autoHideDuration={4000}
        onClose={() => update({ snackbar: { open: false, msg: '' } })} />
      <h3 style={{ marginBottom: 'unset', height: '20px' }}>
        <div className="title">Check Unsynced</div>
      </h3>
      <Grid>
        {lState === 'NOT_AUTHENTICATED' && <Redirect to="/login" />}
        <Box style={{ marginTop: '30px' }} display="flex" justifyContent='center'>
          <PopupTemplate />
          {state.busy ?
            <div className="loader"></div>
            :
            <Button
              type="button"
              style={{ background: '#F44336', color: '#FFF' }}
              onClick={() =>
                popupWithCancel(
                  'Verification',
                  'Are you sure you want to check for unsynced Genesis patients?',
                  () => checkUnsynced())}
            >
              Check 'Em!
            </Button>
          }
        </Box>
      </Grid>
    </div>
  );
};

export default CheckUnsynced;
