import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material';

export const PageHeader = withStyles({
  root: {
    marginBottom: '15px'
  },
  h5: {
    fontSize: '18px',
    marginBottom: 0
  }
})(Typography);
