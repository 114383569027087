import { Dispatch } from 'redux';
import * as at from '../../actionTypes';
import { remoteAction } from '../../services/actionService.js';
import api from '../../services/api.js';
import { loadingMessage } from './routes/TemplatesEdit/templates-edit.actions';
import * as R from 'ramda';
import { Message } from './templates.reducer';
import { etd } from '../../services/utilities.js';

export const getMessages = () => remoteAction({
  type: at.TEMPLATES_REMOTE_GET,
  action: () => api.get('message/template').then((data: Message[]) => {
    /*
        XXX: this disaster code can be safely removed once everyone has been moved
        over to the new automation-based smart replies. This prevents showing automation
        based smart reply templates. Since there is no other way to determine it.
      */
    const Automation: Message[] = [];
    const TheRest: Message[] = [];
    data.forEach((msg) => {
      if (R.has('Automation', msg.messageType)) {
        Automation.push(msg);
      } else {
        TheRest.push(msg);
      }
    });
    return Promise.all(Automation.map(({ id }) => {
      return Promise.all([
        api.get(`templates/paths/path/tmp.msg.rcr.time_based.msg_${id}`),
        api.get(`templates/paths/path/tmp.msg.rcr.smart_reply.msg_${id}`),
      ].map(etd));
    })).then((paths) => {
      paths.forEach(([tb, sm], idx) => {
        if (tb.error && sm.error) {
          TheRest.push(Automation[idx]);
        }
      });
      return TheRest;
    });
  }),
});

export const getAutomationBasedMessages = (path: string) => remoteAction({
  type: at.TEMPLATES_REMOTE_AUTO_BASED_GET,
  action: () => api.post('message/template/query', {
    page: 1,
    perPage: 1000,
    query: {
      path: {
        Exists: {
          isDirectDescendant: path.split('.'),
        },
      },
    },
  }),
});

export const deleteMessage = ({ type, id }: { type: string; id: number }) => remoteAction({
  type: at.TEMPLATES_REMOTE_DELETE,
  action: () => api.delete(`message/template/${id}`)
    .then(() => {
      return {
        isMany: false,
        type,
        id,
      };
    }),
});

export const deleteSelected = ({ type, messages }: { type: string; messages: Message[] }) => {
  const requests = R.pipe(
    R.map((message: Message) => message.selected ?
      api.delete(`message/template/${message.id}`)
      : null),
    R.without([null]),
  )(messages);
  return remoteAction({
    type: at.TEMPLATES_REMOTE_DELETE,
    action: () => Promise.all(requests)
      .then(() => {
        return {
          isMany: true,
          type,
          messages,
        };
      }),
  });
};

export const loadMessage = (message: Message) => (dispatch: Dispatch) => {
  dispatch({
    type: at.TEMPLATE_EDIT_PATCH,
    data: loadingMessage(message),
  });
  return dispatch({
    type: at.TEMPLATES_PATCH,
    data: {
      state: 'LOAD',
      loadId: message.id,
    }
  });
};

export const copyMessage = (message: Message) => (dispatch: Dispatch) => {
  console.log(message);
  dispatch({
    type: at.TEMPLATE_EDIT_PATCH,
    data: loadingMessage(message),
  });
  return dispatch({
    type: at.TEMPLATES_PATCH,
    data: {
      state: 'COPY',
    }
  });
};

export const toggleEnable = (message: Message) => (dispatch: Dispatch) => {
  api.put(`recurringmessage/${message.id}`, message);
  return dispatch({
    type: at.TEMPLATES_TOGGLE,
    data: {
      id: message.id,
      isEnabled: message.isEnabled,
    }
  });
};


export const resetTemplates = () => ({
  type: at.TEMPLATES_PATCH,
  data: {
    state: 'LIST',
  }
});

export const messagesPatch = (prop: string, value: string | number) => (dispatch: Dispatch) =>
  dispatch({
    type: at.TEMPLATES_PATCH,
    data: {
      [prop]: value,
    }
  });

export const selectMessage = ({ type, index, value }: { type?: string; index?: number; value?: boolean }) => (dispatch: Dispatch) =>
  dispatch({
    type: at.TEMPLATES_SELECT_PATCH,
    data: {
      type,
      index,
      value,
    }
  });

export const selectAll = ({ type, value }: { type?: string; value: boolean }) => (dispatch: Dispatch) =>
  dispatch({
    type: at.TEMPLATES_SELECT_ALL_PATCH,
    data: {
      type,
      value,
    }
  });

type SearchType = {
  page: number;
  filter?: string;
  query?: string;
  path?: string[];
  forFeature?: string;
  messageType?: string;
}

export const search = ({
  page, filter, query, path, forFeature, messageType,
}: SearchType) => remoteAction({
  type: at.TEMPLATES_REMOTE_GET_SEARCH,
  action: () => api.post('message/template/query',
    {
      page,
      perPage: 50,
      query: {
        [filter]: query,
        path: path ? {
          Exists: {
            isDirectDescendant: path,
          },
        } : undefined,
        messageType,
        forFeature,
      }
    }).then((data) => {
    return {
      messageType,
      page,
      totalPages: data.totalPages,
      totalCount: data.totalCount,
      messages: data.data,
    };
  }),
});
