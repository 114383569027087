import React from 'react';
import Profile from '../../../../components/Profile/profile.component';
import Header from '../../../../components/PageHeader/PageHeader.component';
import { LogoutButton } from '../Business/business.component.jsx';
import { localGetUser } from '../Business/business.actions';
import { usePromise } from '../../../../services/promise.hook';
import {
  PopupTemplate
} from '../../../../services/Popup.js';
import { useStyles } from './profile.styles';

const ProfilePage = () => {
  const classes = useStyles();

  const userState = usePromise(localGetUser, {});

  React.useEffect(() => {
    userState.invoke({});
  }, []);

  return (
    <>
      <PopupTemplate />
      <Header
        title='Update Profile'
        rightIcons={[
          <LogoutButton
            admin={false}
            hasMultipleOfficeusers={false}
            user={userState.data}
          />
        ]}
      />
      <div className={classes.root}>
        <Profile />
      </div>
    </>
  );
};

export default ProfilePage;
