import * as at from '../../actionTypes';
import * as R from 'ramda';

const init = {
  busy: false,
  state: 'LIST',
  all: false,
  messages: [],
  templates: [],
  officeTemplates: [],
  page: 1,
  filter: {
    value: 'IsEnabledAsc',
    field: 'IsEnabled',
    direction: 'Desc'
  },
  query: '',
  noFolder: false,
  totalPages: 1,
  totalCount: 1,
  tab: 'recurring',
  mainTab: 'apt',
  recalls: [],
  recallTotalCount: 1,
  recallTotalPages: 1,
  recallPage: 1,
  birthdays: [],
  birthdayTotalCount: 1,
  birthdayTotalPages: 1,
  birthdayPage: 1,
  apptChanges: [],
  apptChangesTotalCount: 1,
  apptChangesTotalPages: 1,
  apptChangesPage: 1,
  templatePage: 1,
  totalTemplatePages: 1,
  totalTemplateCount: 1,
  officeTemplatePage: 1,
  totalOfficeTemplatePages: 1,
  totalOfficeTemplateCount: 1,
  autoresponder: [],
};

export default function reducer(recurring = init, action) {
  switch (action.type) {
    case at.RECURRING_PATCH:
      return R.merge(recurring, action.data);
    case at.RECURRING_SELECT_PATCH:
      return R.evolve({
        [action.data.type]: (list) => {
          const message = R.pipe(
            R.nth(action.data.index),
            R.merge(R.__, { selected: action.data.value })
          )(list);
          return (R.update(action.data.index, message, list));
        }
      }, recurring);
    case at.RECURRING_SELECT_ALL_PATCH:
      return R.evolve({
        all: () => action.data.value,
        [action.data.type]: R.map((message) => R.merge(message, { selected: action.data.value }))
      }, recurring);
    case at.RECURRING_REMOTE_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(recurring, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const {
          recurrings,
          templates,
          officeTemplates,
          recalls,
          birthdays,
          apptChanges,
        } = action.data;
        return R.merge(recurring, {
          messages: R.propOr(false, 'data')(recurrings) ?
            R.pipe(
              R.filter((message) => !message.isDeleted),
              R.map((message) => R.merge(message, message.messageType.Recurring)),
            )(recurrings.data.data) : recurrings,
          totalPages: R.propOr(false, 'data')(recurrings) ? recurrings.data.totalPages : 1,
          totalCount: R.propOr(false, 'data')(recurrings) ? recurrings.data.totalCount : 1,
          page: R.propOr(false, 'data')(recurrings) ? recurrings.data.page : 1,
          recalls: R.propOr(false, 'data')(recalls) ?
            R.pipe(
              R.filter((message) => !message.isDeleted),
              R.map((message) => R.merge(message, message.messageType.Recurring)),
            )(recalls.data.data) : recalls,
          recallTotalPages: R.propOr(false, 'data')(recalls) ? recalls.data.totalPages : 1,
          recallTotalCount: R.propOr(false, 'data')(recalls) ? recalls.data.totalCount : 1,
          recallPage: R.propOr(false, 'data')(recalls) ? recalls.data.page : 1,
          birthdays: R.propOr(false, 'data')(birthdays) ?
            R.pipe(
              R.filter((message) => !message.isDeleted),
              R.map((message) => R.merge(message, message.messageType.Recurring)),
            )(birthdays.data.data) : birthdays,
          birthdayTotalPages: R.propOr(false, 'data')(birthdays) ? birthdays.data.totalPages : 1,
          birthdayTotalCount: R.propOr(false, 'data')(birthdays) ? birthdays.data.totalCount : 1,
          birthdayPage: R.propOr(false, 'data')(birthdays) ? birthdays.data.page : 1,
          apptChanges: R.propOr(false, 'data')(apptChanges) ?
            R.pipe(
              R.filter((message) => !message.isDeleted),
              R.map((message) => R.merge(message, message.messageType.Recurring)),
            )(apptChanges.data.data) : apptChanges,
          apptChangesTotalPages: R.propOr(false, 'data')(apptChanges) ? apptChanges.data.totalPages : 1,
          apptChangesTotalCount: R.propOr(false, 'data')(apptChanges) ? apptChanges.data.totalCount : 1,
          apptChangesPage: R.propOr(false, 'data')(apptChanges) ? apptChanges.data.page : 1,
          templates: R.propOr(false, 'data')(templates) ?
            R.pipe(
              R.sortBy(R.prop('createdAt')),
              R.reverse,
            )(templates.data.data) : templates,
          templatePage: templates.data ? templates.data.page : 1,
          totalTemplatePages: templates.data ? templates.data.totalPages : 1,
          totalTemplateCount: templates.data ? templates.data.totalCount : 1,
          officeTemplates: R.propOr(false, 'data')(officeTemplates) ?
            officeTemplates.data.data : officeTemplates,
          officeTemplatePage: officeTemplates.data ? officeTemplates.data.page : 1,
          totalOfficeTemplatePages: officeTemplates.data ? officeTemplates.data.totalPages : 1,
          totalOfficeTemplateCount: officeTemplates.data ? officeTemplates.data.totalCount : 1,
          busy: false,
          all: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(recurring, {
          busy: false,
        });
      }
      return recurring;
    }
    case at.RECURRING_REMOTE_DELETE: {
      if (action.state === 'REQUEST') {
        return R.merge(recurring, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const type = R.cond([
          [R.equals('recurring'), R.always('messages')],
          [R.T, R.always(action.data.messageType)]
        ])(action.data.messageType);
        return R.merge(recurring, {
          busy: false,
          [type]: R.pipe(
            R.prop(type),
            R.filter(({ id }) => id !== action.data.id)
          )(recurring),
        });
      } else if (action.state === 'ERROR') {
        return R.merge(recurring, {
          busy: false,
        });
      }
      return recurring;
    }
    case at.RECURRING_REMOTE_DELETE_MANY: {
      if (action.state === 'REQUEST') {
        return R.merge(recurring, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const type = R.cond([
          [R.equals('recurring'), R.always('messages')],
          [R.T, R.always(action.data.messageType)]
        ])(action.data.messageType);
        return R.merge(recurring, {
          busy: false,
          [type]: R.pipe(
            R.prop(type),
            R.filter(({ id }) => !R.includes(id, action.data.ids))
          )(recurring),
        });
      } else if (action.state === 'ERROR') {
        return R.merge(recurring, {
          busy: false,
        });
      }
      return recurring;
    }
    case at.RECURRING_REMOTE_GET_AUTORESPONDER: {
      if (action.state === 'REQUEST') {
        return R.merge(recurring, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(recurring, {
          busy: false,
          autoresponder: R.pipe(
            R.sortBy(R.prop('autoId')),
            R.reverse
          )(action.data)
        });
      } else if (action.state === 'ERROR') {
        return R.merge(recurring, {
          busy: false,
        });
      }
      return recurring;
    }
    case at.RECURRING_REMOTE_SAVE_AUTORESPONDER: {
      const {
        index,
        newMessage
      } = action.data;
      if (index === undefined) {
        return R.evolve({
          autoresponder: R.prepend(newMessage),
        }, recurring);
      } else {
        return R.evolve({
          autoresponder: R.pipe(
            R.map(R.mergeLeft({ enabled: false, onlyWhenClosed: false })),
            R.update(index, newMessage)
          ),
        }, recurring);
      }
    }
    case at.RECURRING_REMOTE_DELETE_AUTO: {
      if (action.state === 'REQUEST') {
        return R.merge(recurring, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(recurring, {
          busy: false,
          autoresponder: R.pipe(
            R.prop('autoresponder'),
            R.filter(({ autoId }) => autoId !== action.data.id)
          )(recurring),
        });
      } else if (action.state === 'ERROR') {
        return R.merge(recurring, {
          busy: false,
        });
      }
      return recurring;
    }
    case at.RECURRING_REMOTE_DELETE_AUTO_MANY: {
      if (action.state === 'REQUEST') {
        return R.merge(recurring, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(recurring, {
          busy: false,
          autoresponder: R.pipe(
            R.prop('autoresponder'),
            R.filter(({ autoId }) => !R.includes(autoId, action.data.autoIds))
          )(recurring),
        });
      } else if (action.state === 'ERROR') {
        return R.merge(recurring, {
          busy: false,
        });
      }
      return recurring;
    }
    case at.RECURRING_TOGGLE:
      return R.evolve({
        [action.data.prop]: R.map((message) => {
          if (message.id === action.data.id) {
            return R.merge(message, { isEnabled: action.data.isEnabled });
          }
          return message;
        })
      }, recurring);
    case at.RECURRING_REMOTE_GET_SEARCH: {
      if (action.state === 'REQUEST') {
        return R.merge(recurring, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(recurring, action.data);
      } else if (action.state === 'ERROR') {
        return R.merge(recurring, {
          busy: false,
        });
      }
      return recurring;
    }
    default:
      return recurring;
  }
}
