import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, isNil } from 'ramda';

import CloseIcon from '@mui/icons-material/Close';

import api from '../../services/api';
import { Banner } from 'routes/Admin/Banners/banners.types';
import { patch } from '../../routes/Login/login.actions';
import { useSelector } from '../../reducers';
import { useStyles } from './banners.styles';

const BannerComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const banners = useSelector(state => state.login.banners);

  const compare = (itemA: Banner, itemB: Banner) => {
    if (itemA.priority < itemB.priority) {
      return 1;
    }
    if (itemA.priority > itemB.priority) {
      return -1;
    }
    return 0;
  };

  const setBanners = (value: Banner[]) => {
    dispatch(patch({ banners: value }));
  };

  const handleClose = (id: number) => {
    setBanners(banners.filter((banner) => banner.bannerId !== id));
    const storageBanners = JSON.parse(localStorage.getItem('sked-banners'));
    if (!storageBanners) {
      localStorage.setItem('sked-banners', JSON.stringify({ [id]: 'close' }));
    } else {
      localStorage.setItem(
        'sked-banners',
        JSON.stringify({ ...storageBanners, [id]: 'close' })
      );
    }
  };

  const parseDescription = (bannerDescripton: string) => {
    try {
      const response = JSON.parse(bannerDescripton);
      return {
        description: response.description || '',
        align: response.align || 'left',
      };
    } catch {
      return {
        description: bannerDescripton || '',
        align: 'left',
      };
    }
  };

  useEffect(() => {
    const getBanners = async () => {
      try {
        const data: Banner[] = await api.get('banner/current');
        const storageBanners = JSON.parse(localStorage.getItem('sked-banners'));
        const filtered = data.filter((banner) => {
          return !storageBanners || !storageBanners[banner.bannerId];
        });
        if (!isEmpty(filtered) && !isNil(filtered)) {
          setBanners(filtered.sort(compare).slice(0, 2));
        }
      } catch (error) {
        console.error(error);
      }
    };
    getBanners();
  }, []);

  return (
    <div className={classes.container}>
      {banners.map((banner) => {
        const response = parseDescription(banner.description);
        return (
          <div
            key={banner.bannerId}
            className={classes.contentBanner}
            style={{
              color: banner.color,
              backgroundColor: banner.backgroundColor,
            }}
          >
            <div
              style={{ textAlign: response.align }}
              className={classes.contentText}
            >
              {response.description}&nbsp;
              {banner.link && (
                <a target='_blank' href={banner.link.url}>
                  {banner.link.text}
                </a>
              )}
            </div>
            <CloseIcon
              className={classes.closeIcon}
              onClick={() => handleClose(banner.bannerId)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default BannerComponent;
