import api from '../../../services/api.js';
import { chain } from 'ramda';
import { LocalDateTime, ZoneId, ZoneOffset } from '@js-joda/core';
import '@js-joda/timezone';

const cancelApts = (canceledApts = []) => {
  return Promise.all(canceledApts.map(({ id, reason = 'Canceled from SKED' }) => {
    return api.delete(`appointment/${id}`, {
      data: {
        reason: reason || 'Canceled from SKED',
        source: 'Admin'
      }
    });
  })).catch(e => {
    throw new Error('Error canceling appointments: ' + e.message);
  });
};

const rescheduleApts = ({
  rescheduledApts = [],
  timezone
}) => {
  const apts = rescheduledApts.map(apt => {
    const dateTime = LocalDateTime
      .parse(`${apt.date}T${apt.time}`)
      .atZone(ZoneId.of(timezone))
      .withZoneSameInstant(ZoneOffset.UTC)
      .toString();
    return {
      ...apt,
      appointmentTypeId: apt.typeId,
      time: dateTime
    };
  });
  return Promise.all(apts.map(apt => {
    return api.put(`appointment/${apt.id}`, apt);
  })).catch(e => {
    throw new Error('Error rescheduling appointments: ' + e.message);
  });
};

export const createAppointments = ({
  selectedClients,
  appointmentsToSchedule,
  timezone
}) => {
  const appointments = chain(client => (
    appointmentsToSchedule.map(({ typeId, time, date }) => ({
      source: 'Admin',
      clientId: client.id,
      status: { Scheduled: [] },
      appointmentTypeId: typeId,
      time: LocalDateTime
        .parse(date + 'T' + time)
        .atZone(ZoneId.of(timezone))
        .withZoneSameInstant(ZoneOffset.UTC)
        .toString()
    }))
  ), selectedClients);
  return Promise.all(appointments.map(apt => {
    return api.post('appointment', apt);
  })).catch(e => {
    throw new Error('Error rescheduling appointments: ' + e.message);
  });
};

export const saveApts = ({
  selectedClients,
  appointmentsToSchedule,
  canceledApts,
  rescheduledApts,
  timezone
}) => {
  return Promise.resolve().then(() => {
    return cancelApts(canceledApts);
  }).then(() => {
    return rescheduleApts({ rescheduledApts, timezone });
  }).then(() => {
    return createAppointments({ timezone, appointmentsToSchedule, selectedClients });
  });
};

