import { notification } from '../../services/utilities.js';
import { getSocket } from '../../services/websockets';

let events = null;

export const getFrontDeskEvents = () => events;

export const checkedInHandler = (event, hasFrontDeskFeature) => {
  const data = event.data ? JSON.parse(event.data) : event;
  const { firstName, lastName } = data.client;
  const title = `${firstName} ${lastName} just checked-in with the app`;
  const body = { body: '', tag: data.appointment.id };
  if (data.source === 'App') {
    const url = hasFrontDeskFeature ? '/#/front-desk' : '/#/waiting';
    notification(title, body, url);
  }
};

export const initFrontDeskEvents = (hasFrontDeskFeature) => {
  const socket = getSocket();
  if (!socket) {
    return;
  }
  socket.addEventListener('message', (event) => {
    const parsed = JSON.parse(event.data);
    if (parsed.tag === 'WaitingRoom' && parsed.contents.tag === 'CheckedIn') {
      checkedInHandler(parsed.contents.contents, hasFrontDeskFeature);
    }
  });
};

let removeListener;
export const initFrontDeskListener = (socket, hasFrontDeskFeature) => {
  if (!socket) {
    removeListener = () => null;
  } else {
    const messageHandler = (event) => {
      const parsed = JSON.parse(event.data);
      if (parsed.tag === 'WaitingRoom' && parsed.contents.tag === 'CheckedIn') {
        checkedInHandler(parsed.contents.contents, hasFrontDeskFeature);
      }
    };
    socket.addEventListener('message', messageHandler);

    removeListener = () => {
      socket.removeEventListener('message', messageHandler);
    };
  }
};

export const stopManagerUpdate = event => {
  const {
    client,
    message,
    id,
    stopType,
    done,
  } = event;
  const {
    firstName = '',
    lastName = '',
  } = client ? client : {};
  if (done.Open) {
    const title = `${stopType === 'Stop' ? '🛑' : '⚠️'} ${firstName} ${lastName}`;
    const body = { body: message };
    notification(title, body, `/#/front-desk?stopId=${id}`);
  }
};

let removeStopListener;
export const initStopMangerListener = (socket) => {
  if (!socket) {
    removeListener = () => null;
  } else {
    const messageHandler = (event) => {
      const parsed = JSON.parse(event.data);
      if (parsed.tag === 'Stops') {
        stopManagerUpdate(parsed.contents);
      }
    };
    socket.addEventListener('message', messageHandler);

    removeStopListener = () => {
      socket.removeEventListener('message', messageHandler);
    };
  }
};

export const closeFrontDeskEvents = () => {
  if (events) {
    events.close();
    events = null;
  } else if (removeListener) {
    removeListener();
  }
  if (removeStopListener) {
    removeStopListener();
  }
};
