import React, { useEffect, useState } from 'react';
import {
  pathOr, isEmpty, append, without, cond,
  always, T, prop, pathEq,
  pipe, map, compose, split,
  not, filter, propOr,
} from 'ramda';
import { History } from 'history';
import api, { skedApi } from '../../services/api.js';
import axios from 'axios';
import { PopupTemplate, popup } from '../../services/Popup.js';
import {
  Checkbox, Button, Paper, Snackbar, TableContainer, Table, TableHead,
  TableRow, TableCell, TableBody, Alert,
} from '@mui/material';
import {
  ArrowForward,
} from '@mui/icons-material';
import { usePromise } from '../../services/promise.hook';
import { useCheckIsAdmin } from '../../services/checkIsAdmin.hook';
import { Form, Revision } from 'routes/IntakeForms/ServerTypes.js';
import { useSelector } from '../../reducers';
import { Office } from '../Login/login.reducer';


let selectedForms: Form[] = [];

const getOffice = (ids: number[]) => Promise.all(ids.map((id) => api.get(`office/${id}`)));
const getOfficeWithForms = (id: number): Promise<[Office, Form[]]> => {
  skedApi.defaults.headers.common['X-As-Office'] = id;
  return Promise.all([
    api.get('office/me'),
    api.get('form'),
  ]).then(([office, forms]) => {
    delete skedApi.defaults.headers.common['X-As-Office'];
    selectedForms = forms;
    return [office, forms];
  });
};


interface MessageItemProps {
  form: Form;
  check: (c: boolean, f: Form) => void;
  all: boolean;
}

const MessageItem = ({
  form,
  check,
  all,
}: MessageItemProps) => {
  const [selected, setSelect] = useState(true);
  useEffect(() => {
    setSelect(all);
  }, [all]);
  return (
    <TableRow key={form.id}>
      <TableCell>
        <Checkbox
          checked={selected}
          onChange={(e) => {
            const c = e.target.checked;
            setSelect(c);
            check(c, form);
          }}
        />
      </TableCell>
      <TableCell>
        {form.name}
      </TableCell>
      <TableCell>
        {form.description}
      </TableCell>
    </TableRow>
  );
};

let oldTimer: NodeJS.Timeout;
let newTimer: NodeJS.Timeout;
const TransferForms = ({ history }: { history: History }) => {
  const [oldOfficeId, setOldOfficeId] = useState('');
  const [newOfficeId, setNewOfficeId] = useState('');
  const [all, setAll] = useState(true);
  const [isSaveSnackbarOpen, setIsSaveSnackbarOpen] = useState(false);
  const [busy, setBusy] = useState(false);

  const newOfficeState = usePromise(getOffice, []);
  const oldOfficeState = usePromise(getOfficeWithForms, [null, null]);

  const admin = useSelector(state => state.login.admin);
  const sessionStatus = useSelector(state => state.session.status);

  const getOfficeData = (isOld: boolean) => {
    if (isOld) {
      oldOfficeState.invoke(Number(oldOfficeId));
    } else {
      const ids = pipe(
        split(' '),
        filter(compose(not, isEmpty)),
        map((s) => Number(s))
      )(newOfficeId);
      newOfficeState.invoke(ids);
    }
  };

  useCheckIsAdmin(admin, sessionStatus, history);

  useEffect(() => {
    if (oldTimer) {
      clearTimeout(oldTimer);
    }
    if (oldOfficeId !== '') {
      oldTimer = setTimeout(() => getOfficeData(true), 200);
    }
  }, [oldOfficeId]);

  useEffect(() => {
    if (newTimer) {
      clearTimeout(newTimer);
    }
    if (newOfficeId !== '') {
      newTimer = setTimeout(() => getOfficeData(false), 200);
    }
  }, [newOfficeId]);

  useEffect(() => {
    if (all) {
      selectedForms = oldOfficeState.data[1];
    } else {
      selectedForms = [];
    }
  }, [all]);

  const checkMessage = (isChecked: boolean, form: Form) => {
    if (isChecked) {
      selectedForms = append(form, selectedForms);
    } else {
      selectedForms = without([form], selectedForms);
    }
    console.log(selectedForms);
  };

  const transfer = () => {
    const ids = pipe(
      split(' '),
      filter(compose(not, isEmpty)),
      map((s) => Number(s))
    )(newOfficeId);

    setBusy(true);
    const oldRevisions = selectedForms.map((form) => {
      return axios.get(
        `${process.env.AUTH_URL}/form/${form.id}/revisions/latest`,
        {
          headers: {
            Authorization: skedApi.defaults.headers.common.Authorization,
            ['Content-Type']: skedApi.defaults.headers.common['Content-Type'],
            'X-As-Office': oldOfficeId,
          }
        }
      );
    });
    return Promise.all(oldRevisions).then((revisions) => {
      const makeForms = revisions.map((revision) => {
        const rev: Revision = revision.data;
        return ids.map((id) => {
          return axios.post(
            `${process.env.AUTH_URL}/form`,
            {
              name: rev.formName,
              description: rev.formDescription,
              sendNotification: !!rev.formSendNotification,
            },
            {
              headers: {
                Authorization: skedApi.defaults.headers.common.Authorization,
                ['Content-Type']: skedApi.defaults.headers.common['Content-Type'],
                'X-As-Office': id,
              }
            }
          ).then((newForm) => {
            const f: Form = newForm.data;
            return axios.post(
              `${process.env.AUTH_URL}/form/${f.id}`,
              rev,
              {
                headers: {
                  Authorization: skedApi.defaults.headers.common.Authorization,
                  ['Content-Type']: skedApi.defaults.headers.common['Content-Type'],
                  'X-As-Office': id,
                }
              }
            );
          });
        });
      });
      return Promise.all(makeForms);
    }).then(() => {
      setIsSaveSnackbarOpen(true);
      setBusy(false);
    }).catch((e) => {
      console.log(e);
      popup('Error!', 'Failed to transfer messages. Check network monitor for more information.');
      setBusy(false);
    });
  };

  return (
    <div style={{
      padding: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <PopupTemplate />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isSaveSnackbarOpen}
        autoHideDuration={5e3}
        onClose={() => setIsSaveSnackbarOpen(false)}
      >
        <div>
          <Alert style={{ fontSize: '16px' }} severity="success">
            Transferred Forms
          </Alert>
        </div>
      </Snackbar>
      <div style={{
        display: 'flex',
        width: '80%',
        justifyContent: 'space-evenly',
      }}>
        <Paper
          style={{
            padding: '10px',
            height: 'min-content',
          }}
        >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px'
          }}>
            <div>
              Old Office:
              &nbsp;
              <input
                value={oldOfficeId}
                placeholder='ID'
                type='text'
                style={{ width: '30px' }}
                onChange={(e) => {
                  setOldOfficeId(e.target.value);
                }}
                onKeyPress={(e) => e.key === 'Enter' ? getOfficeData(true) : null}
              />
              <br />
              {cond([
                [prop('loading'), always('Loading...')],
                [prop('errorMessage'), cond([
                  [pathEq(['error', 'response', 'status'], 401), () => 'Error 401: Go back to \'Select Office\' and try again.'],
                  [pathEq(['error', 'response', 'status'], 403), () => 'Error 403: Why are you here?'],
                  [pathEq(['error', 'response', 'status'], 404), () => 'Office not found.'],
                  [T, (o: any) => `Error ${pathOr('unknown', ['error', 'response', 'status'], o)}, you better do something about this...`]
                ])],
                [T, pathOr(null, ['data', 0, 'name'])]
              ])(oldOfficeState)}
            </div>
            &nbsp;
            <ArrowForward />
            &nbsp;
            <div>
              <div style={{
                display: 'flex',
                alignItems: 'center',
              }}>
                New Office(s):
                &nbsp;
                <textarea
                  rows={1}
                  value={newOfficeId}
                  placeholder='ID'
                  style={{
                    height: '18px',
                  }}
                  onChange={(e) => {
                    setNewOfficeId(e.target.value);
                  }}
                  onKeyPress={(e) => e.key === 'Enter' ? getOfficeData(false) : null}
                />
              </div>
              <div>
                {cond([
                  [prop('loading'), () => [<div>Loading...</div>]],
                  [prop('errorMessage'), (e) => [
                    <div>
                      {cond([
                        [pathEq(['error', 'response', 'status'], 401), () => 'Error 401: Go back to \'Select Office\' and try again.'],
                        [pathEq(['error', 'response', 'status'], 403), () => 'Error 403: Why are you here?'],
                        [pathEq(['error', 'response', 'status'], 404), () => 'Office not found.'],
                        [T, (o) => `Error ${pathOr('unknown', ['error', 'response', 'status'], o)}, you better do something about this...`]
                      ])(e)}
                    </div>
                  ]],
                  [T, pipe(propOr([], 'data'), map(({ name }) => (
                    <div>
                      {name}
                    </div>
                  )))]
                ])(newOfficeState)}
              </div>
            </div>
          </div>
          {!isEmpty(pathOr([], ['data', 1], oldOfficeState)) &&
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell >
                      <Checkbox
                        checked={all}
                        onChange={(e) => {
                          setAll(e.target.checked);
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      Name
                    </TableCell>
                    <TableCell>
                      Description
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pathOr([], ['data', 1], oldOfficeState).map((form) => {
                    return (
                      <MessageItem
                        key={form.id}
                        form={form}
                        check={checkMessage}
                        all={all}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>}
          <br />
          {!isEmpty(pathOr([], ['data', 1], oldOfficeState)) &&
            <div>
              <i>NOTE: Open the network monitor to see how it's going. It may take some time.</i>
              <br />
              <Button
                variant="contained"
                color="error"
                disabled={busy}
                onClick={transfer}
              >
                Transfer
              </Button>
            </div>
          }
        </Paper>
      </div>
    </div>
  );
};

export default TransferForms;
