/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import {
  FormControl, Button, Grid, TextField, IconButton, Typography,
  InputAdornment, CircularProgress
} from '@mui/material';
import {
  Close as CloseIcon,
  HighlightOff as ClearIcon,
  AddCircleOutlineOutlined as AddIcon,
  Delete as DeleteIcon,
  Add as PlusIcon
} from '@mui/icons-material';
import {
  merge, evolve, append, findIndex, remove, propEq, path, update,
  pipe, map, adjust, assoc, chain, pathOr, prop, split, tail, join,
  __, omit, view, compose, lensIndex, lensProp, concat, not, isEmpty,
} from 'ramda';
import { useTypeahead } from '../../../services/typeahead.hook.js';
import {
  LocalDate, DateTimeFormatter,
  ZoneId, ZoneOffset, LocalDateTime, ZonedDateTime
} from '@js-joda/core';
import { Locale } from '@js-joda/locale_en-us';

import api from '../../../services/api.js';
import { saveApts } from '../services/scheduler.service.js';
import { AddClientsInline } from './AddClientsInline.component';
import { ClientList } from './client-list.component.jsx';
import { ExistingAppointments } from './existing-appointments.component.jsx';
import { TimeSelectBusyness } from './time-select.jsx';
import { AppointmentTypeSelector } from './AppointmentTypeSelect.component';
import { Row } from '../../../components/PageHeader';
import Modal from '../../../components/Modal/Modal.component';
import HeaderButton from '../../../components/HeaderButton/HeaderButton.component';
import { getFormObject, submitForm } from '../../../services/form-validation.js';

const MyTextField = withStyles({
  root: {
    width: 'auto',
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& h5': {
      marginTop: theme.spacing(1),
      fontWeight: 'bold',
      fontSize: '18px'
    }
  },
  mySubListHeader: {
    fontSize: '14px',
    fontWeight: 'bolder',
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.74)'
  },
  header: {
    backgroundColor: '#F5F5F5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 8,
    paddingLeft: 15,
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
    color: 'rgb(0 0 0 / 77%)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical'
  },
  formControl: {
    marginBottom: theme.spacing(1),
    width: 200,
    maxWidth: '100%'
  },
  table: {
    width: '100%',
    fontSize: '16px'
  },
  tableContainer: {
    marginTop: '20px',
    marginLeft: '-15px',
    marginRight: '-15px',
    width: 'auto',
    '& table tr td': {
      fontSize: '13px'
    },
    '& table tr th': {
      fontSize: '13px'
    },
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: '3px',
      paddingRight: '3px'
    },
    '& .MuiTableCell-sizeSmall:last-child': {
      paddingRight: '20px'
    },
    '& .MuiTableCell-sizeSmall:first-child': {
      paddingLeft: '20px'
    }
  },
  date: {
    width: 130
  },
}));

const format = DateTimeFormatter.ofPattern('M/d/yyyy').withLocale(Locale.US);

const dayTimeFormat = DateTimeFormatter.ofPattern('eee, MMM d yyyy h:mm a').withLocale(Locale.US);

const isClientEmpty = (c) => {
  const props = ['firstName', 'lastName', 'birthday', 'phone', 'email'];
  return props.every(p => !c[p]);
};

const emptyArray = [];

export const SearchClients = ({
  ehrSystem,
  setStep,
  classes,
  selectClients,
  selectClient,
  onChange,
  setClients,
  clients,
  shouldClearSearch,
  setClearSearch,
  defaultClients = [],
  isSelectOne = false,
  isInDialog = false,
}) => {
  const [state, setState] = useState({
    nextAttempt: false,
    selectedClients: defaultClients,
    searchClients: {
      lastName: '',
      birthday: undefined,
      phone: '',
      status: ['Active']
    },
  });

  const updateSearchData = (field = '') => e => {
    const value = e.target.value;

    const searchClients = {
      ...state.searchClients,
      [field]: value
    };
    if (value && value.length > 0) {
      onChange(searchClients);
    }
    setState(s => merge(s, { searchClients }));
  };

  const clearSearch = () => {
    setState(s => merge(s, {
      searchClients: {
        lastName: '',
        birthday: undefined,
        phone: '',
        status: ['Active']
      }
    }));
    setClients(s => merge(s, {
      data: undefined
    }));
  };

  useEffect(() => {
    selectClients(state.selectedClients);
  }, [state.selectedClients]);

  useEffect(() => {
    if (shouldClearSearch) {
      clearSearch();
      setClearSearch(false);
    }
  }, [shouldClearSearch]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Typography fontWeight={500} fontSize={18} style={{ marginTop: '0', marginRight: '10px' }}>Search Contacts</Typography>
        {ehrSystem !== 'Platinum' && !isInDialog &&
          <Button style={{ fontSize: 'x-small' }} size="small" variant="contained" onClick={() => setStep('addClients')}>
            Add Clients
          </Button>
        }
      </Grid>

      <Grid item xs={4}>
        <FormControl variant="standard" className={classes.formControl}>
          <MyTextField
            id="last-name"
            label="Name"
            variant="standard"
            placeholder='LastName FirstName'
            value={state.searchClients.lastName}
            onChange={updateSearchData('lastName')}
            inputProps={{ className: 'sked-test-scheduler-client-name' }}
            InputProps={{
              endAdornment: (state.searchClients.lastName || path(['data', 'data', 'length'], clients) > 0) && (
                <InputAdornment position="end">
                  <IconButton aria-label="close" onClick={clearSearch}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <FormControl variant="standard" className={classes.formControl}>
          <MyTextField
            id="birthday"
            label="Birthday"
            variant="standard"
            helperText="M/d/yyyy"
            value={state.searchClients.birthday}
            onChange={updateSearchData('birthday')}
            inputProps={{ className: 'sked-test-scheduler-client-birthday' }}
            InputProps={{
              endAdornment: state.searchClients.birthday && (
                <InputAdornment position="end">
                  <IconButton aria-label="close" onClick={clearSearch}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Grid>

      <Grid item xs={4}>
        <FormControl variant="standard" className={classes.formControl}>
          <MyTextField
            id="phone"
            label="Phone"
            variant="standard"
            value={state.searchClients.phone}
            onChange={updateSearchData('phone')}
            inputProps={{ className: 'sked-test-scheduler-client-phone-number' }}
            InputProps={{
              endAdornment: state.searchClients.phone && (
                <InputAdornment position="end">
                  <IconButton aria-label="close" onClick={clearSearch}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12}>
        <ClientList
          isSelectOne={isSelectOne}
          searchResults={clients}
          searchResultList={path(['data', 'data'], clients)}
          isInDialog={isInDialog}
          selectClient={(cli) => {
            if (isSelectOne) {
              setState(evolve({
                selectedClients: () => {
                  return [cli];
                }
              }));
            } else {
              setState(evolve({
                selectedClients: clients => {
                  const index = findIndex(propEq('id', cli.id), clients);
                  return index >= 0 ? remove(index, 1, clients) : append(cli, clients);
                }
              }));
            }
            selectClient(cli);
          }}
          selectedClients={state.selectedClients} />
      </Grid>
      {state.selectedClients.length === 0 && state.nextAttempt &&
        <Grid item xs={12}> <div>At least one client needs to be selected.</div></Grid>
      }
    </Grid>
  );
};

export function Scheduler({
  isOpen,
  close,
  isInDialog = false,
  setPossibleAppointments,
  selectedTime,
  interval = 10,
  types,
  professionals,
  timezone,
  selectDate,
  refreshToday,
  ehrSystem,
  firstStep = 'searchClients',
  defaultClients = emptyArray,
}) {
  const classes = useStyles();
  const [step, setStep] = useState(firstStep);
  const [addNewLine, setAddNewLine] = useState(false);
  const [clientsToAdd, setClientsToAdd] = React.useState([]);
  const [clientsToAddResult, setClientsToAddResult] = React.useState({ errorMessage: '', loading: false });

  const [existingApts, setExistingApts] = useState({
    data: [],
    loading: false,
    errorMessage: ''
  });

  const [canceledApts, setCanceledApts] = useState([]);
  const [rescheduledApts, setRescheduledApts] = useState([]);

  const [state, setState] = useState({
    nextAttempt: false,
    selectedClients: [],
    appointmentsToSchedule: [],
  });

  const [saveState, setSaveState] = useState({ loading: false, data: '', errorMessage: '' });
  const [clearSearch, setClearSearch] = useState(false);

  const parseBirthday = (b = '') => {
    const s = b.split('/');
    if (s.length === 3) {
      return `${s[2]}-${s[0]}-${s[1]}`;
    }
    return undefined;
  };

  const selectClient = (client, isNew) => {
    setState(evolve({
      selectedClients: clients => {
        const index = findIndex(propEq('id', client.id), clients);
        return index >= 0 ? remove(index, 1, clients) : append(client, clients);
      }
    }));

    // Update to default appointment type when
    // first client is added to list
    if (state.selectedClients.length === 0) {
      console.log('first client - update default appointment type', client);
      setState(evolve({
        appointmentsToSchedule: map(assoc('typeId', client.defaultApptiontmentType))
      }));

      if (isNew) {
        setClearSearch(true);
        setStep('reviewAppointments');
        getExistingApts().then((apts) => {
          if (apts && apts.length === 0) {
            setStep('addAppointments');
          }
        });
      }
    }
  };

  const { state: clients, onChange, setState: setClients } = useTypeahead(query => {
    // Wrap in a promise resolve
    // so that any errors from Joda are caught and
    // displayed by promise chain
    return Promise.resolve().then(() => {
      const birthday = query.birthday && query.birthday.length >= 8 ? LocalDate.parse(query.birthday, format).toString() : undefined;
      const lastName = query.lastName.split(' ')[0];
      const firstName = pipe(
        prop('lastName'),
        split(' '),
        tail,
        join(' '),
        s => !s ? undefined : s
      )(query);
      const q = pipe(
        q => !q.phone ? omit(['phone'], q) : q,
        merge(__, {
          birthday,
          lastName,
          firstName
        })
      )(query);
      return api.post('client/query', {
        page: 1,
        perPage: 25,
        query: {
          ...q,
          sortBy: [{
            direction: 'Asc',
            field: 'LastName'
          }, {
            direction: 'Asc',
            field: 'FirstName'
          }],
          isLead: false
        }
      }).then(result => {
        // Need to wrap it in this promise resolve
        // so rxJs doesn't blow up
        return Promise.resolve(result);
      });
    });
  });

  const addClients = () => {
    const clients = clientsToAdd
      .map(pipe(
        getFormObject,
        c => ({
          ...c,
          birthday: parseBirthday(c.birthday),
          defaultAppiontmentType: c.defaultAppointmentTypeId,
          status: 'Active',
          metadata: {}
        }),
        omit(['defaultAppointmentTypeId'])
      ))
      .filter(c => !isClientEmpty(c));

    const validatedClients = clientsToAdd
      .map(c => {
        const validatedForm = submitForm(c);
        const data = getFormObject(c);
        return !isClientEmpty(data) ? validatedForm : c;
      });


    const isValid = validatedClients.every(c => {
      return c.__form.isValid;
    });

    if (!isValid) {
      setClientsToAdd(validatedClients);
      setClientsToAddResult(assoc('errorMessage', 'The form is not valid'));
      return Promise.reject(new Error('not valid'));
    }

    setClientsToAddResult({ loading: true, errorMessage: '' });
    return clients.reduce((prom, c, i) => {
      return prom.then(() => {
        return api.post('client', c).then(savedClient => {
          setClientsToAdd(remove(i, 1));
          selectClient(savedClient, true);
        });
      }).catch(e => {
        setClientsToAddResult(() => ({
          loading: false,
          errorMessage: 'Issue saving client ' + c.firstName + '. ' + e.message
        }));
        throw e;
      });
    }, Promise.resolve()).then(() => {
      setClientsToAddResult(r => ({ ...r, loading: false }));
    });
  };


  const saveAllData = () => {
    setSaveState(merge(__, { loading: true, errorMessage: '' }));
    return saveApts({
      timezone,
      selectedClients: state.selectedClients,
      canceledApts,
      rescheduledApts,
      appointmentsToSchedule: state.appointmentsToSchedule
    }).then(() => {
      setSaveState(merge(__, { loading: false, data: 'Saved all changes.' }));
      setCanceledApts([]);
      setRescheduledApts([]);
      setState(merge(__, {
        selectedClients: [],
        appointmentsToSchedule: []
      }));
      setStep('finished');
      refreshToday();
    }).catch(e => {
      setSaveState(merge(__, { loading: false, errorMessage: e.message }));
      setStep('finished');
    });
  };

  const getExistingApts = () => {
    const now = LocalDate.now().atStartOfDay().toString();


    setExistingApts(d => ({
      ...d,
      loading: true,
      errorMessage: ''
    }));

    const data = {
      page: 1,
      perPage: 25,
      query: {
        status: ['Scheduled'],
        after: now,
        clientIds: state.selectedClients.map(prop('id'))
      }
    };
    return api.post('appointment/query', data).then((apts) => {
      setExistingApts({
        loading: false,
        data: apts.data.map(apt => {
          let localDateTime = '';
          try {
            const jodaDate = ZonedDateTime
              .parse(apt.time)
              .withZoneSameInstant(ZoneId.of(timezone))
              .toLocalDateTime();
            localDateTime = jodaDate.format(dayTimeFormat);
          } catch (e) {
            localDateTime = 'Invalid Date';
          }
          return merge(apt, { localDateTime });

        }),
        errorMessage: ''
      });
      return apts.data;
    }).catch((e) => {
      setExistingApts({
        loading: false,
        data: [],
        errorMessage: 'error getting existing apts: ' + e.message
      });
    });
  };

  // Update time in scheduler when a new time on the calendar
  // is selected
  useEffect(() => {
    if (!selectedTime || !selectedTime.time) {
      // Set to an empty array when leaving
      setState(evolve({
        appointmentsToSchedule: () => []
      }));
      return;
    }
    // If there's already selected clients then do nothing
    // Other wise select the defaultClients and use their appointment types
    const nonEmptyClients = defaultClients.filter(compose(not, isEmpty));
    const clientsToSelect = state.selectedClients.length > 0 || isEmpty(nonEmptyClients) ?
      emptyArray : defaultClients;
    const time = selectedTime.time;
    const firstAppointment = state.appointmentsToSchedule[0] || { typeId: 0 };
    const defaultType = { id: 0 };
    const activeType = types.find(({ professionalId }) => professionals.some(({ id }) => id === professionalId)) || defaultType;
    const defaultClientType = view(
      compose(lensIndex(0), lensProp('defaultApptiontmentType')),
      clientsToSelect
    );
    const typeId = firstAppointment.typeId || defaultClientType || activeType.id;

    const appointment = merge(firstAppointment, {
      date: time.toLocalDate().toString(),
      time: time.toLocalTime().toString(),
      typeId
    });
    setState(evolve({
      selectedClients: concat(clientsToSelect),
      appointmentsToSchedule: a => a.length > 0 ?
        update(0, appointment, a) :
        append(appointment, a)
    }));
  }, [selectedTime, defaultClients]);


  const componentContainer = React.useRef(null);

  React.useEffect(() => {
    // Create the preview appointments for the calendar view
    const clients = state.selectedClients.length > 0 ?
      state.selectedClients :
      [{ id: -1, firstName: '', lastName: 'No client' }];
    const appointments = chain(client => (
      state.appointmentsToSchedule.map(({ typeId, time, date }, i) => ({
        id: (client.id + typeId + i) * -1,
        clientId: client.id,
        client,
        status: { Preview: [] },
        appointmentTypeId: typeId,
        appointmentType: types.find(propEq('id', typeId)) || {},
        time: LocalDateTime
          .parse(date + 'T' + time)
          .atZone(ZoneId.of(timezone))
          .withZoneSameInstant(ZoneOffset.UTC)
          .toString()
      }))
    ), clients);
    setPossibleAppointments(appointments);
  }, [state.selectedClients, state.appointmentsToSchedule]);



  const updateAppointment = (index = 0, field = '') => (e) => {
    let value = '';
    if (field === 'date') {
      if (!e || e instanceof Error) {
        return;
      }
      value = e.toString();
      selectDate(e);
    } else if (field === 'time') {
      value = e;
    } else {
      value = e.target.value;
    }

    setState(evolve({
      appointmentsToSchedule: adjust(index, assoc(field, value))
    }));
  };

  const addAppointment = () => {
    const typeId = pathOr(
      pathOr(-1, [0, 'id'], types),
      ['selectedClients', 0, 'defaultApptiontmentType'],
      state
    );
    const appointment = {
      time: (selectedTime && selectedTime.time) ? selectedTime.time.toLocalTime().toString() : '08:00',
      date: LocalDate.now().toString(),
      typeId
    };
    setState(evolve({
      appointmentsToSchedule: append(appointment)
    }));
  };

  const removeAppointment = (i = 0) => {
    setState(evolve({
      appointmentsToSchedule: remove(i, 1)
    }));
  };

  const nextStep = () => {
    if (step === 'addClients') {
      return addClients().then(() => {
        return setStep('searchClients');
      }).catch((e) => {
        console.log('error adding clients', e.message);
      });
    }
    if (step === 'searchClients' && state.selectedClients.length === 0) {
      return setState(assoc('nextAttempt', true));
    }
    if (step === 'searchClients' && state.selectedClients.length > 0) {
      setClearSearch(true);
      setStep('reviewAppointments');
      getExistingApts().then((apts) => {
        if (apts && apts.length === 0) {
          setStep('addAppointments');
        }
      });
    }
    if (step === 'reviewAppointments') {
      return setStep('addAppointments');
    }
    if (step === 'addAppointments') {
      return saveAllData().then(() => {
        if (isInDialog) {
          setStep('searchClients');
          close();
        }
      });
    }
    if (step === 'finished') {
      return close();
    }
  };

  const backStep = () => {
    if (step === 'addClients') {
      return setStep('searchClients');
    }
    if (step === 'addAppointments') {
      return setStep('reviewAppointments');
    }
    if (step === 'reviewAppointments') {
      return setStep('searchClients');
    }
  };

  const rootStyle = {
    display: isOpen ? 'flex' : 'none',
    width: isInDialog ? '100%' : '50%',
    overflowX: isInDialog ? 'visible' : 'hidden',
    overflowY: isInDialog ? 'visible' : 'hidden',
    padding: 0,
  };

  const contentStyle = {
    maxHeight: isInDialog ? 'auto' : 'calc(100vh - 215px)',
    overflowY: isInDialog ? 'visible' : 'auto',
    paddingBottom: isInDialog ? undefined : 20,
  };

  const content = (
    <div ref={componentContainer} className={classes.root} style={rootStyle}>
      {!isInDialog && (
        <Grid width="100%" paddingX={1} className={classes.header}>
          <Typography className={classes.title}>Scheduler</Typography>
          <Grid display="flex" alignItems="center">
            {step === 'searchClients' && ehrSystem !== 'Platinum' && (
              <HeaderButton
                onClick={() => setStep('addClients')}
                borderSolid
                title="Add clients"
                className='sked-test-scheduler-modal-button-add-clients'
              />
            )}
            {step === 'addClients' && (
              <HeaderButton
                borderSolid
                title='Add'
                Icon={PlusIcon}
                iconStyle={{ marginRight: -6 }}
                onClick={() => setAddNewLine(true)}
                className='sked-test-scheduler-modal-button-add-line'
              />
            )}
            {step !== 'searchClients' && (
              <HeaderButton
                borderSolid
                title='Back'
                disabled={step === 'finished'}
                onClick={backStep}
                className='sked-test-scheduler-modal-button-back'
              />
            )}
            <HeaderButton
              title={(step === 'addAppointments' || step === 'addClients') ? 'Save' : step === 'finished' ? 'Close' : 'Next'}
              color='primary'
              onClick={nextStep}
              disabled={saveState.loading ||
                clientsToAddResult.loading ||
                (step === 'searchClients' && !state.selectedClients.length) ||
                (step === 'addClients' && (!clientsToAdd[0]?.firstName.value || !clientsToAdd[0]?.lastName.value || (!clientsToAdd[0]?.email.value || !clientsToAdd[0]?.email.isValid) || !clientsToAdd[0]?.birthday.value || !clientsToAdd[0]?.defaultAppointmentTypeId.value))
              }
              className='sked-test-scheduler-modal-button-next'
            />
            &nbsp;
            <IconButton
              size='small'
              onClick={() => close(false)}
            >
              <CloseIcon style={{ fontSize: 20 }} />
            </IconButton>
          </Grid>
        </Grid>
      )}
      <Grid paddingX={isInDialog ? 0 : 2} paddingTop={isInDialog ? 0 : 1} style={contentStyle}>
        {step === 'addClients' &&
          <AddClientsInline
            classes={classes}
            clients={clientsToAdd}
            setClients={setClientsToAdd}
            types={types}
            professionals={professionals}
            addNewLine={addNewLine}
            setAddNewLine={setAddNewLine}
          />
        }
        {step === 'searchClients' &&
          <SearchClients
            ehrSystem={ehrSystem}
            setStep={setStep}
            classes={classes}
            selectClients={(clis) => setState({ ...state, selectedClients: clis })}
            selectClient={selectClient}
            onChange={onChange}
            setClients={setClients}
            clients={clients}
            shouldClearSearch={clearSearch}
            setClearSearch={setClearSearch}
            defaultClients={state.selectedClients}
            isInDialog
          />
        }

        {step !== 'addClients' && step !== 'searchClients' && step !== 'finished' && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography fontWeight={500} fontSize={18}>Selected Contacts</Typography>
            </Grid>
            <Grid item xs={12}>
              <ClientList
                searchResults={clients}
                searchResultList={[]}
                selectClient={selectClient}
                selectedClients={state.selectedClients}
                isInDialog
              />
            </Grid>
          </Grid>
        )}

        {(step === 'reviewAppointments' || step === 'addAppointments') && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography fontWeight={500} fontSize={18}>Review Existing Appointments</Typography>
            </Grid>
            <Grid item xs={12}>
              <ExistingAppointments
                interval={interval}
                timezone={timezone}
                types={types}
                professionals={professionals}
                selectDate={selectDate}
                onlyChanges={step !== 'reviewAppointments'}
                canceledApts={canceledApts}
                setCanceledApts={setCanceledApts}
                setRescheduledApts={setRescheduledApts}
                rescheduledApts={rescheduledApts}
                existingApts={existingApts} />
            </Grid>
          </Grid>
        )}

        {step === 'addAppointments' && (
          <Grid>
            <Row>
              <Typography fontWeight={500} fontSize={18}>New Appointments</Typography>
              <IconButton aria-label="add appointment" onClick={addAppointment} className='sked-test-scheduler-new-appointment'>
                <AddIcon />
              </IconButton>
            </Row>

            {state.appointmentsToSchedule.map(({ date, time, typeId }, i) => (
              <Row key={i} style={{ marginTop: 8 }}>
                <div style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                  <TextField
                    className={classes.date}
                    label="Choose Day"
                    value={LocalDate.parse(date)}
                    onChange={(event) => updateAppointment(i, 'date')(event.target.value)}
                    type='date'
                    variant='standard'
                    inputProps={{ className: 'sked-test-scheduler-new-appointment-date-picker' }}
                  />
                </div>

                <div style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                  <TimeSelectBusyness
                    appointmentTypeId={typeId}
                    date={date}
                    interval={interval}
                    id={i}
                    value={time}
                    onChange={updateAppointment(i, 'time')} />
                </div>

                <div style={{ paddingLeft: '5px', paddingRight: '5px' }}>
                  <AppointmentTypeSelector
                    value={typeId}
                    onChange={updateAppointment(i, 'typeId')}
                    types={types}
                    professionals={professionals}
                    id={`apt-type-${i}`}
                    notNone
                  />
                </div>

                <IconButton aria-label="close" onClick={() => removeAppointment(i)} className='sked-test-scheduler-trash'>
                  <DeleteIcon />
                </IconButton>
              </Row>
            ))}

          </Grid>
        )}

        {step === 'finished' && (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {saveState.errorMessage ?
                <div>{saveState.errorMessage}</div> :
                <div>Saved all appointments.</div>
              }
            </Grid>
          </Grid>
        )}

        {clientsToAddResult.errorMessage && <Grid container spacing={1}>
          <Grid item xs={12}> <div>{clientsToAddResult.errorMessage}</div> </Grid>
        </Grid>}
        <Grid display="flex" alignItems="center" justifyContent="center" marginTop={2}>
          {saveState.loading && <CircularProgress size={20} />}
        </Grid>
        <Grid display="flex" alignItems="center" justifyContent="center" marginTop={2}>
          {clientsToAddResult.loading && <CircularProgress size={20} />}
        </Grid>
      </Grid>
    </div>
  );

  if (isInDialog) {
    return (
      <Modal
        title='Scheduler'
        className='sked-test-scheduler-modal'
        open={isOpen}
        onClose={close}
        buttons={[
          step === 'searchClients' && ehrSystem !== 'Platinum' &&
          <HeaderButton
            onClick={() => setStep('addClients')}
            borderSolid
            title="Add clients"
            className='sked-test-scheduler-modal-button-add-clients'
          />,
          step === 'addClients' &&
          <HeaderButton
            borderSolid
            title='Add'
            Icon={PlusIcon}
            iconStyle={{ marginRight: -6 }}
            onClick={() => setAddNewLine(true)}
            className='sked-test-scheduler-modal-button-add-line'
          />,
          step !== 'searchClients' &&
          <HeaderButton
            borderSolid
            title='Back'
            disabled={step === 'finished'}
            onClick={backStep}
            className='sked-test-scheduler-modal-button-back'
          />,
          <HeaderButton
            title={(step === 'addAppointments' || step === 'addClients') ? 'Save' : step === 'finished' ? 'Close' : 'Next'}
            color='primary'
            onClick={nextStep}
            disabled={saveState.loading ||
              clientsToAddResult.loading ||
              (step === 'searchClients' && !state.selectedClients.length) ||
              (step === 'addClients' && (!clientsToAdd[0]?.firstName.value || !clientsToAdd[0]?.lastName.value || (!clientsToAdd[0]?.email.value || !clientsToAdd[0]?.email.isValid) || !clientsToAdd[0]?.birthday.value || !clientsToAdd[0]?.defaultAppointmentTypeId.value))
            }
            className='sked-test-scheduler-modal-button-next'
          />
        ]}
      >
        <Grid marginBottom={1}>
          {content}
        </Grid>
      </Modal>
    );
  }
  return content;
}
 
