import React from 'react';
import TemplateAutomations from './automation-templates.page.jsx';
import { Automation } from '../Automations/automation-types';
import Modal from '../../components/Modal/Modal.component';

interface AutomationTemplateDialogProps {
  open: boolean;
  onClose: () => void;
  onSelect: (auto: Automation) => void;
  initFolder?: string;
}

const AutomationTemplateDialog = ({
  open, onClose, onSelect, initFolder,
}: AutomationTemplateDialogProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Automation Templates'
      className='sked-test-automation-templates-modal'
    >
      <div>
        {open &&
          <TemplateAutomations
            inDialog
            onSelect={onSelect}
            initFolder={initFolder}
          />}
      </div>
    </Modal>
  );
};

export default AutomationTemplateDialog;
