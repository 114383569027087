import * as at from '../../actionTypes';
import api from '../../services/api.js';
import { remoteAction } from '../../services/actionService.js';

export const fetchOverviewData = ({
  compareTo = 'PreviousPeriod',
  period = 'ThisWeek',
  startDay,
  endDay,
  newApptTys,
  adjustmentApptTys
}) => remoteAction({
  type: at.REMOTE_GET_REPORT_OVERVIEW,
  action: () => api.post('officeStats', {
    adjustmentApptTys,
    newApptTys,
    compareTo,
    period: period === 'Custom' ? 'ThisWeek' : period,
    startDay: period === 'Custom' ? startDay : undefined,
    endDay: period === 'Custom' ? endDay : undefined
  })
});

export const saveOverviewSettings = ({
  newApptTys = [],
  adjustmentApptTys = []
}) => {
  if (newApptTys.length > 0 && adjustmentApptTys.length > 0) {
    return remoteAction({
      type: at.REMOTE_SAVE_REPORT_OVERVIEW_SETTINGS,
      action: () => api.put('officeStats/stat-settings', {
        adjustmentApptTyIds: adjustmentApptTys,
        newPatientApptTyIds: newApptTys,
      })
    });
  }
  return {
    type: at.REMOTE_SAVE_REPORT_OVERVIEW_SETTINGS,
  };
};

export const getOverviewSettings = () => {
  return remoteAction({
    type: at.REMOTE_GET_REPORT_OVERVIEW_SETTINGS,
    action: () => api.get('officeStats/stat-settings')
  });
};
