const LOCAL_STORAGE_SESSION_KEY = 'sked-session';

class LocalSession {

  static getSession() {
    const session = localStorage.getItem(LOCAL_STORAGE_SESSION_KEY);
    try {
      return JSON.parse(session);
    } catch (e) {
      if (session) {
        console.log('Admin locked!');
        return 'LOCKED';
      }
      console.warn(e);
      return null;
    }
  }

  static setSession(session) {
    localStorage.setItem(LOCAL_STORAGE_SESSION_KEY, JSON.stringify(session));
  }

  static destroySession() {
    localStorage.removeItem(LOCAL_STORAGE_SESSION_KEY);
  }
}
export default LocalSession;
