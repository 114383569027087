import { remoteAction } from '../../../services/actionService.js';
import api from '../../../services/api.js';
import * as at from '../../../actionTypes';
import {
  LocalDate,
  ChronoUnit,
} from '@js-joda/core';
import {
  now,
  format,
} from '../../../services/joda.js';
import * as R from 'ramda';
import { popup } from '../../../services/Popup.js';


export const get = () => remoteAction({
  type: at.SPECIAL_OFFICE_HOURS_REMOTE_GET,
  action: () => api.get('specialOfficeHours')
});

export const deleteHours = (id) => {
  if (id === undefined) {
    return null;
  }
  return remoteAction({
    type: at.SPECIAL_OFFICE_HOURS_REMOTE_GET,
    action: () => api.delete(`specialOfficeHours/${id}`)
      .then(() => api.get('specialOfficeHours'))
      .catch((error) => {
        console.error(error);
        return error;
      }),
  });
};

export const editHoursPushed = (id) => {
  return ({
    type: at.SPECIAL_OFFICE_HOURS_EDIT_PUSHED,
    data: Number(id),
  });
};

export const cancelHoursPushed = () => {
  return ({
    type: at.SPECIAL_OFFICE_HOURS_CANCEL_PUSHED,
  });
};

export const dateChanged = (e) => {
  return ({
    type: at.SPECIAL_OFFICE_HOURS_DATE_CHANGED,
    data: {
      date: e,
    }
  });
};

export const dateToChanged = (e) => {
  return ({
    type: at.SPECIAL_OFFICE_HOURS_DATE_TO_CHANGED,
    data: {
      date: e,
    }
  });
};

export const nameChanged = (e) => {
  return ({
    type: at.SPECIAL_OFFICE_HOURS_NAME_CHANGED,
    data: {
      name: e,
    }
  });
};

export const internalNameChanged = (e) => {
  return ({
    type: at.SPECIAL_OFFICE_HOURS_INTERNAL_NAME_CHANGED,
    data: {
      internalName: e,
    }
  });
};

export const locationChanged = (e) => {
  return ({
    type: at.SPECIAL_OFFICE_HOURS_LOCATION_CHANGED,
    data: {
      locationId: e,
    }
  });
};


export const eventPatch = (e) => {
  console.log(e);
  return ({
    type: at.SPECIAL_OFFICE_HOURS_EVENT_CHANGED,
    data: {
      isEvent: e,
    }
  });
};

export const closedPushed = (data) => ({
  type: at.SPECIAL_OFFICE_HOURS_CLOSED_PUSHED,
  data,
});

export const addOpeningPushed = (data) => ({
  type: at.SPECIAL_OFFICE_HOURS_ADD_OPENING_PUSHED,
  data,
});

export const removeOpenPushed = (data) => {
  return ({
    type: at.SPECIAL_OFFICE_HOURS_REMOVE_OPEN_PUSHED,
    data,
  });
};

export const timeChanged = (data) => {
  return ({
    type: at.SPECIAL_OFFICE_HOURS_TIME_CHANGED,
    data,
  });
};

export const saveHoursPushed = (editing) => dispatch => {
  const toTime = ({ parsed }) => (parsed);
  const saving = R.evolve({
    hours: R.map(
      R.mapObjIndexed(toTime)
    ),
    date: (date) => format(LocalDate.parse(date), 'yyyy-MM-dd'),
    locationId: (l) => l ? l : null,
  })(editing);
  if (saving.id !== undefined) {
    return dispatch(remoteAction({
      type: at.SPECIAL_OFFICE_HOURS_SAVE_PUSHED,
      action: () => api.put(`specialOfficeHours/${editing.id}`, saving).catch((error) => {
        popup('Error!', 'Failed to save hours.');
        console.error(error);
      }),
    })).then(() => {
      return dispatch(get());
    });
  }
  const extras = {
    scheduleOnClosing: true,
    scheduleOnOpening: true,
  };
  return dispatch(remoteAction({
    type: at.SPECIAL_OFFICE_HOURS_SAVE_PUSHED,
    action: () => {
      const dateTo = LocalDate.parse(saving.dateTo);
      const hoursWithoutEnd = R.dissoc('dateTo', saving);
      const date = LocalDate.parse(saving.date);
      const length = date.until(dateTo, ChronoUnit.DAYS) + 1;
      const arr = Array.from({ length }, (v, i) => i);
      const promises = arr.map((v, i) =>
        api.post('specialOfficeHours',
          Object.assign(
            {},
            R.merge(hoursWithoutEnd, extras),
            { date: format(date.plusDays(i), 'yyyy-MM-dd') }
          )));
      return Promise.all(promises).catch((error) => {
        console.log(error);
        console.log('error time');
        if (editing.internalName === '' || !R.has('internalName', editing)) {
          popup('Error!', 'An internal name is required!');
        } else if (editing.name === '' || !R.has('name', editing)) {
          popup('Error!', 'A brief reason is required!');
        } else {
          popup('Error!', 'Failed to save hours! Make sure you don\'t already have hours saved for the date(s).');
        }
        return error;
      });
    },
  })).then(() => {
    return dispatch(get());
  });
};

export const addHoursPushed = () => ({
  type: at.SPECIAL_OFFICE_HOURS_ADD_PUSHED,
  data: {
    id: undefined,
    date: format(now('date'), 'yyyy-MM-dd'),
    hours: [],
    isEvent: false,
    dateTo: format(now('date'), 'yyyy-MM-dd'),
    locationId: null,
  }
});
