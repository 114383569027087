import React from 'react';

import { CircularProgress, Backdrop } from '@mui/material';

import { useStyles } from './backdropLoading.styles';

type BackdropLoadingProps = {
  loading?: boolean;
};

const BackdropLoading = ({ loading = false }: BackdropLoadingProps) => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress color='inherit' />
    </Backdrop>
  );
};

export default BackdropLoading;
