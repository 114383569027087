import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  Paper,
  Button
} from '@mui/material';

const ItemTypes = {
  CARD: 'card',
};

export const ApptTypeCard = ({ sat, moveCard, pros, removeApptType, index }) => {
  const showProName = id => {
    const p = pros.find(p => p.id === id) || {};
    return `${p.displayFirstName} ${p.displayLastName}`;
  };
  const ref = useRef(null);
  const id = sat.appointmentTypeId;
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      console.log('collect!');
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: { id, index, type: 'card' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Paper
      data-handler-id={handlerId}
      ref={ref}
      style={{
        opacity,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '400px',
        backgroundColor: '#f7f9fc',
        padding: '5px',
        marginBottom: '10px'
      }}>
      <span style={{ fontSize: '12px', marginBottom: '-7px' }}>
        {showProName(sat.appointmentType.professionalId)}
      </span>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <strong>{sat.appointmentType.name}</strong>
        <div style={{ flexGrow: 1 }}></div>
        <Button onClick={() => removeApptType(sat.appointmentTypeId)}>Remove</Button>
      </div>
    </Paper>
  );
};
