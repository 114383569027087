import React, { useEffect, useState } from 'react';
import * as R from 'ramda';

import ApptIcon from '../../icons/ApptStatus.icon';

import FilterHeader from '../FilterHeader/FilterHeader.component';
import HeaderButton from '../HeaderButton/HeaderButton.component';

import { useStyles } from './filterStatus.styles';

import {
  MenuItem,
  Popover,
  Grid,
  Checkbox,
  ListItemText
} from '@mui/material';

let searchTimeout: NodeJS.Timeout = null;

type FilterStatusProps = {
  save?: ({ status }: { status: string[] }) => void;
  setStatuses: (status: string[]) => void;
  statuses: string[],
  selectedStatuses: string[];
  defaultStatuses?: string[];
  title?: string;
  Icon?: ({ color, className }: { color?: string, className?: string }) => JSX.Element;
  onlyIcon?: boolean;
  timeOut?: number;
}

const FilterStatus = ({ setStatuses, save, statuses, selectedStatuses, title, Icon, defaultStatuses = [], onlyIcon, timeOut = 500 }: FilterStatusProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selecteds, setSelecteds] = useState<string[]>(selectedStatuses);

  const StatusIcon = Icon ? Icon : ApptIcon;

  const handleClose = () => {
    setAnchorEl(null);
    setSelecteds(selectedStatuses);
  };

  const handleReset = () => {
    setSelecteds(defaultStatuses);
    setStatuses(defaultStatuses);
    save?.({ status: defaultStatuses });
  };

  const handleSelected = (status: string) => {
    let newStatuses: string[] = [];

    if (selecteds.indexOf(status) > -1) {
      newStatuses = R.without([status], selecteds);
    } else {
      newStatuses = [...selecteds, status];
    }
    setSelecteds(newStatuses);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      setStatuses(newStatuses);
      save?.({ status: newStatuses });
    }, timeOut);
  };

  useEffect(() => {
    setSelecteds(selectedStatuses || []);
  }, [selectedStatuses]);

  return (
    <Grid>
      <Grid marginRight={0.5}>
        <HeaderButton
          Icon={StatusIcon}
          title={`${selectedStatuses?.length > 0 ? selectedStatuses?.length : ''} ${title || 'Appt. Status'}`}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          active={selectedStatuses?.length > 0}
          type='filter'
          onlyIcon={onlyIcon}
        />
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid minWidth={260}>
          <FilterHeader
            title={title || 'Appt. Status'}
            reset={handleReset}
            resetDisabled={R.equals(selecteds?.sort(), defaultStatuses?.sort())}
          />
          <Grid padding={1} paddingBottom={1} className={classes.list}>
            {statuses.map((name) => (
              <MenuItem
                key={name}
                onClick={() => handleSelected(name)}
                className={selecteds.indexOf(name) > -1 ? classes.selected : classes.selectItem}
                value={name}
              >
                <Checkbox checked={selecteds.indexOf(name) > -1} />
                <ListItemText className={classes.selectFontSize} primary={name} />
              </MenuItem>
            ))}
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
};

export default FilterStatus;
