import React from 'react';

const Tutorials = () => (
  <div style={{ height: '100%', overflow: 'hidden' }}>
    <h3 style={{ marginBottom: 'unset' }}>
      <div className="title">Tutorials</div>
    </h3>
    <div style={{ height: '100%', margin: '10px' }}>
      <iframe
        src="https://www.sked.life/office-tutorials-app/"
        width="100%"
        height="630"
        style={{ height: '90%', border: 'none', overflow: 'hidden' }}
        frameBorder="0"
        scrolling="yes">
      </iframe>
    </div>
  </div>
);

export default Tutorials;
