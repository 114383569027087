import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  DialogContentText,
  FormControlLabel,
  Checkbox,
  IconButton,
  Typography,
  TextField,
} from '@mui/material';

import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { LocalDate } from '@js-joda/core';
import * as R from 'ramda';

import { TimeSelectBusyness } from '../../../CalendarV2/components/time-select';
import { AppointmentTypeAutocomplete } from '../../../CalendarV2/components/AppointmentTypeSelect.component';
import { getFilterData } from '../../../Calendar/calendar.actions';
import { createAppointments } from '../../../CalendarV2/services/scheduler.service';
import * as actions from '../../leads.actions';
import { useSelector } from '../../../../reducers';
import Modal from '../../../../components/Modal/Modal.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';

import { Lead } from '../../leads.reducer';
import { useStyles } from './convert.styles';

type Appointment = {
  time: string;
  date: string;
  typeId: number;
}

type ConvertLeadProps = {
  lead: Lead;
  open: boolean;
  close: () => void;
  onConvert?: () => void;
}

const ConvertLead = ({ open, lead, close, onConvert }: ConvertLeadProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checked, setChecked] = useState<boolean>(false);
  const [first, setFirst] = useState<boolean>(false);
  const [appointments, setAppointments] = useState<Appointment[]>([]);

  const {
    interval,
    professionals,
    types,
    timezone,
  } = useSelector(state => ({
    interval: state.calendarv2.localInterval || R.pathOr(15, ['login', 'ehrSettings', 'blockLength'])(state),
    professionals: state.calendar.professionals,
    types: state.calendar.types,
    timezone: state.login.office.timezone || 'America/New_York',
  }));

  const handleClose = () => {
    setChecked(false);
    close();
  };

  const handleConvert = async () => {
    if (!checked) {
      actions.convertLead(lead.id)(dispatch).then(() => {
        onConvert?.();
      });
      handleClose();
      return;
    }
    try {
      await createAppointments({ selectedClients: [lead], appointmentsToSchedule: appointments, timezone });
      actions.convertLead(lead.id)(dispatch).then(() => {
        onConvert?.();
      });
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const addAppointment = () => {
    setAppointments([...appointments, { time: '08:00', date: LocalDate.now().toString(), typeId: types[0].id }]);
  };

  const handleUpdate = (index: number, label: string, value: string | number) => {
    const newItems = R.update(index, { ...appointments[index], [label]: value }, appointments);
    setAppointments(newItems);
  };

  const handleRemove = (index: number) => {
    const newItems = R.remove(index, 1, appointments);
    setAppointments(newItems);
  };

  useEffect(() => {
    if (checked && appointments.length === 0 && types.length) {
      setAppointments([{ time: '08:00', date: LocalDate.now().toString(), typeId: types[0].id }]);
    }
  }, [checked, appointments, types]);

  useEffect(() => {
    if (!types.length && !first) {
      dispatch(getFilterData());
      setFirst(true);
    }
  }, [types, first]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title='Convert Lead'
      maxWidth={700}
      className='sked-test-covert-lead-modal'
      buttons={[
        <HeaderButton
          title='Save & convert'
          color='primary'
          onClick={handleConvert}
          className='sked-test-covert-lead-modal-button-save'
        />
      ]}
    >
      <div>
        <DialogContentText>
          {`Would you like to convert ${lead?.firstName} ${lead?.lastName} to a client? This action cannot be undone!`}
        </DialogContentText>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
              color="primary"
            />
          }
          label="Schedule Appointment"
        />
        {checked && (
          <>
            <hr />
            <div className={classes.row}>
              <Typography component="h3">New Appointments</Typography>
              <IconButton aria-label="add appointment" onClick={addAppointment}>
                <AddIcon />
              </IconButton>
            </div>
            {appointments.map(({ date, time, typeId }, i) => (
              <div className={classes.row} key={i}>
                <div>
                  <TextField
                    className={classes.date}
                    label="Choose Day"
                    value={LocalDate.parse(date)}
                    onChange={(event) => handleUpdate(i, 'date', event.target.value)}
                    type='date'
                    variant='standard'
                  />
                </div>
                <div className={classes.margins}>
                  <TimeSelectBusyness
                    appointmentTypeId={typeId}
                    date={date}
                    interval={interval}
                    id={i}
                    value={time}
                    onChange={(value: string) => handleUpdate(i, 'time', value)} />
                </div>
                <div>
                  <AppointmentTypeAutocomplete
                    value={typeId}
                    minWidth="250px"
                    maxWidth="300px"
                    onChange={(event: { target: { value: number } }) => handleUpdate(i, 'typeId', event.target.value)}
                    types={types}
                    professionals={professionals}
                    id={`apt-type-${i}`}
                    includeErrors={undefined}
                  />
                </div>
                <IconButton
                  aria-label="close"
                  onClick={() => handleRemove(i)}
                  style={{ marginLeft: 8 }}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}
            <br />
          </>
        )}
      </div>
    </Modal>
  );
};

export default ConvertLead;
