import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { PageHeader, Row } from '../../components/PageHeader';
import {
  Button, CircularProgress,
} from '@mui/material';
import {
  pathOr, pipe, map, head, update, isEmpty, remove,
  includes, propOr, prepend, append, isNil, filter,
  not, nth, hasPath, identity,
} from 'ramda';
import {
  AutomationsTemplateList,
} from '../Automations/automations.page';
import { usePromise } from '../../services/promise.hook';
import api from '../../services/api.js';
import { FoldersList } from '../../components/Folders/folders.component';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  pageControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '5px'
  },
  pageArrows: {
    marginLeft: '0px',
    marginRight: '0px',
    display: 'flex',
    alignItems: 'center',
  },
  headerRow: {
    marginBottom: '10px',
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterRow: {
    marginBottom: '10px',
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  templateContainer: {
    display: 'flex',
  },
  templatePathsContainer: {
    width: '25%',
    overflowY: 'auto',
    height: 'fit-content',
  },
  templateListConatiner: {
    width: '75%',
    overflowY: 'auto',
    marginTop: '13px',
  },
}));

const getAutomations = ({ page = 1, perPage = 1000, query }) => {
  return api.post('templates/paths/query', {
    page,
    perPage,
    query: { hasSubpath: 'tmp.auto' },
  }).then(({ data }) => {
    return api.post('automations/template/query', {
      page: 1,
      perPage: 1000,
      query
    }).then((a) => {
      const autos = a.data;
      const usedList = [];
      const folders = data.filter(hasPath(['resource', 'PathLabel']));
      const pathList = folders.map((folder) => {
        const autosInFolder = data.filter(({ path }) => {
          return path[0] === folder.path[0] && path[1] === folder.path[1] && path[2] === folder.path[2];
        }).map(({ resource }) => {
          const a = autos.find(({ id }) => resource.Automation === id);
          if (a) {
            usedList.push(a.id);
            return a;
          }
          return null;
        }).filter(identity);
        return [
          folder,
          autosInFolder,
        ];
      });
      const unusedList = autos.filter(({ id }) => {
        return !includes(id, usedList);
      });
      const noFolder = { path: '', pathId: 0, resource: { PathLabel: { label: 'No Folder' } } };
      return append([noFolder, unusedList])(pathList);
    });
  });
};

const newAutomation = () => {
  return api.post('automations/template', {
    actions: [],
    enabled: false,
    trigger: {
      Dummy: [],
    },
    hidden: false,
    filters: [],
  });
};


let timeout;
const TemplateAutomations = ({
  inDialog,
  onSelect,
  initFolder,
}) => {
  const classes = useStyles();
  const {
    admin,
  } = useSelector((state) => ({
    admin: pathOr(null, ['login', 'admin'])(state),
  }));
  const [query] = useState({
    enabled: null,
    trigger: '',
    hidden: false,
  });
  const [selectedFolder, setSelectedFolder] = useState(null);
  const automationsState = usePromise(getAutomations, []);
  const newAutomationState = usePromise(newAutomation, {});
  const automationsData = propOr([], 'data', automationsState);
  const templateListRef = React.useRef();
  const initFolderRef = React.useRef();

  const searchAutomations = ({
    perPage = pathOr(25, ['data', 'perPage'], automationsState),
  }) => {
    automationsState.invoke({
      page: 1,
      perPage,
      query,
    });
  };

  const newAutomationHandler = () => {
    return newAutomationState.invoke({});
  };

  useEffect(() => {
    searchAutomations({});
  }, [query, newAutomationState.data]);

  const paths = useMemo(() => map(head, automationsData), [automationsData]);

  useEffect(() => {
    if (!selectedFolder) {
      setSelectedFolder(pathOr(null, [0, 0], automationsData));
    }
  }, [automationsData]);

  const handleScroll = () => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      let searching = true;
      document.querySelectorAll('.path-header').forEach((e) => {
        if (templateListRef.current.scrollTop <= e.offsetTop && searching) {
          const nextIndex = paths.findIndex((p) => 'path-' + p.pathId === e.id);
          setSelectedFolder(paths[nextIndex].pathId);
          searching = false;
        }
      });
      if (searching) {
        setSelectedFolder(paths[paths.length - 1].pathId);
      }
    }, 50);
  };

  templateListRef &&
    templateListRef.current &&
    templateListRef.current.addEventListener('scroll', handleScroll);

  useEffect(() => {
    return () => {
      console.log(templateListRef);
      templateListRef &&
        templateListRef.current &&
        templateListRef.current.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (initFolder && initFolderRef && initFolderRef.current) {
      initFolderRef.current.scrollIntoView({ behavior: 'smooth' });
      setSelectedFolder(initFolder);
    }
  }, [initFolderRef.current]);

  // [Folder, [Automations]] -> Maybe Int -> Maybe Automation -> void ()
  const updateAutomations = (pathAutosPair, idx, addToNoFolder = null) => {
    const updatedIds = pathAutosPair[1].map(({ id }) => id);
    const updateFunction = isNil(idx) ?
      prepend(pathAutosPair) :
      update(idx, pathAutosPair);
    const data = pipe(
      updateFunction,
      map((pair) => {
        const [path, autos] = pair;
        if (path.pathId === 0) {
          if (addToNoFolder) {
            return [
              path,
              append(addToNoFolder, autos)
            ];
          }
          return [
            path,
            autos.filter((a) => {
              return !includes(a.id, updatedIds);
            })
          ];
        }
        return pair;
      }),
      filter(pipe(nth(1), isEmpty, not))
    )(automationsData);
    automationsState.setState({
      data,
    });
  };

  const replaceAutomations = (pathAutosPair, idx) => {
    automationsState.setState({
      data: update(idx, pathAutosPair, automationsData),
    });
  };

  const updatePathList = (idx, path) => {
    const current = automationsData[idx];
    const updateFunction = path ?
      update(idx, [path, current[1]]) :
      remove(idx, 1);
    const data = updateFunction(automationsData);
    automationsState.setState({
      data,
    });
  };

  return (
    <div style={{ overflow: 'unset', margin: '20px' }}>
      {!admin && !inDialog && <Redirect to='/dashboard' />}
      <div>
        {!inDialog &&
          <Row className={classes.headerRow}>
            {!inDialog &&
              <PageHeader align="left" variant="h5">
                Automations
                {/* <HelpButton handleOpen={helpState.handleOpen} /> */}
              </PageHeader>}
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
              {newAutomationState.loading &&
                <CircularProgress size={30} />}
              <Button
                variant='contained'
                disabled={newAutomationState.loading}
                onClick={newAutomationHandler}
              >
                New Automation Template
              </Button>
            </div>
          </Row>}
        <div className={classes.templateContainer}>
          <div className={classes.templatePathsContainer}>
            <FoldersList
              paths={paths}
              selected={selectedFolder}
              allowMutation
              updatePathList={updatePathList}
              onClick={(pathId) => {
                const element = document.getElementById(`path-${pathId}`);
                element.scrollIntoView({ behavior: 'smooth' });
                setSelectedFolder(pathId);
              }}
              getPaths={() => newAutomationState.invoke({})}
            />
          </div>
          {automationsState.loading && <CircularProgress />}
          {!automationsState.loading &&
            <div id='template-list'
              ref={templateListRef}
              className={classes.templateListConatiner}>
              <AutomationsTemplateList
                automations={automationsData}
                inDialog={inDialog}
                updateAutomations={updateAutomations}
                replaceAutomations={replaceAutomations}
                onSelect={onSelect}
                initFolder={initFolder}
                initFolderRef={initFolderRef}
              />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default TemplateAutomations;
