import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as rawActions from './SpecialOfficeHours/specialOfficeHours.actions.js';
import { pathOr, includes, isEmpty, pipe, prepend } from 'ramda';
import { PopupTemplate } from '../../services/Popup.js';
import { LocalDate } from '@js-joda/core';
import { ShowGroupedSpecialHours, EditSpecialHours } from './components/Hours.jsx';
import AddIcon from '@mui/icons-material/Add';
import { useTitle } from '../../services/useTitle.js';
import { ContactSupport } from '../../components/feature-disabled.component';
import { getLocations } from '../Settings/routes/Business/business.actions.js';
import Header from '../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import FilterLocations from '../../components/FilterLocations/FilterLocations.component';
import Modal from '../../components/Modal/Modal.component';

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px',
    paddingTop: 0,
    paddingBottom: 30,
    overflowY: 'auto',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  grow: {
    flexGrow: 1
  },
  editHoursRoot: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  formControl: {
    display: 'block',
    marginTop: '10px',
    marginBottom: '10px',
  },
  checkbox: {
    paddingLeft: 4,
    marginRight: 0,

    '& .MuiCheckbox-root': {
      padding: 2,
      paddingTop: 5,
      paddingBottom: 5,
      marginRight: 4,
    }
  }
}));

const isAfterNow = (date = '2021-02-01') => {
  return LocalDate.now().minusDays(1).isBefore(LocalDate.parse(date));
};

const compare = (a, b) => {
  if (a.group[0]?.date < b.group[0]?.date) {
    return -1;
  }
  if (a.group[0]?.date > b.group[0]?.date) {
    return 1;
  }
  return 0;
};

function EventHoursPage() {
  useTitle('Office Events');
  const [show, setShow] = useState(false);
  const [locationId, setLocationId] = useState(0);
  const handleToggle = (e = true) => setShow(e);

  const classes = useStyles();

  const {
    hours, error, filteredHours, isEnabled, hasLocations, locations,
  } = useSelector(state => {
    const hours = pathOr({ group: [] }, ['specialOfficeHours'])(state);
    const filteredHours = hours.group.filter(({ group }) =>
      !isEmpty(group.filter(({ isEvent }) => isEvent)) &&
      (show || group.some(({ date }) => isAfterNow(date)))
    ).sort(compare);
    const locations = pathOr([], ['business', 'locations'], state);
    const hasLocations = pipe(
      pathOr([], ['login', 'features'])
      , includes('Locations')
    )(state);
    return {
      isEnabled: includes('SkedApp', pathOr([], ['login', 'features'], state)),
      hours,
      filteredHours,
      error: pathOr({}, ['specialOfficeHours', 'error'])(state),
      locations: hasLocations && !isEmpty(locations) ? prepend(
        { id: 0, name: 'All Locations' },
        locations,
      ) : [],
      hasLocations,
    };
  });

  const [hoursList, setHoursList] = useState(filteredHours);

  const dispatch = useDispatch();
  const actions = bindActionCreators(rawActions, dispatch);

  useEffect(() => {
    if (locationId === 0) {
      setHoursList(filteredHours);
    } else {
      setHoursList(filteredHours.filter(hour => hour.locationId === locationId));
    }
  }, [locationId]);

  useEffect(() => {
    actions.get();
    if (hasLocations)
      dispatch(getLocations());
  }, []);

  if (!isEnabled) {
    return (
      <>
        <Header title='Office Events' pageId="office-events" />
        <div className={classes.root}>
          <div style={{ width: '600px' }}>
            <p>
              Put your office scheduler into the pocket of your patients with a very user friendly simple app. This page lets you list your office events in the app.
            </p>
            <ContactSupport />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Header
        title='Office Events'
        pageId="office-events"
        leftIcons={[
          <FilterLocations
            hasLocations={hasLocations}
            allLocations={locations}
            selectedLocationId={locationId}
            setSelectedLocationId={setLocationId}
          />,
        ]}
        rightIcons={[
          <HeaderButton borderSolid active={show} type='hide'>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={show}
                  onChange={(e) => handleToggle(e.target.checked)}
                  name="checked-show-past-events"
                />
              }
              label="Show past events" />
          </HeaderButton>,
          <HeaderButton title='Add Event' className='sked-test-office-events-add-new-event' Icon={AddIcon} onClick={() => actions.addHoursPushed()} />
        ]}
        onlyIconsWidth={610}
        breakPoints={[
          {
            width: 480,
            mobileItems: [1]
          },
        ]}
      />
      <div className={classes.root}>
        <div className={classes.headerContainer}>
          <p style={{ width: '500px' }}>If your office is having an event and you want your members to know about it in the app, list it here! Events do not change the times app users can schedule.</p>
        </div>

        {hours.isLoading && <CircularProgress />}
        {error && <p>Error: Failed to get events.</p>}

        {(locationId === 0 ? filteredHours : hoursList).map((hoursGroup) =>
          <ShowGroupedSpecialHours
            key={hoursGroup.id}
            edit={actions.editHoursPushed}
            remove={actions.deleteHours}
            hoursGroup={hoursGroup}
            locations={locations}
          />
        )}

        {hours.editing && <Modal
          open={!!hours.editing}
          onClose={actions.cancelHoursPushed}
          title={`${hours.editing.id ? 'Edit' : 'Add'} Event`}
          maxWidth={700}
          className={`sked-test-office-events-${hours.editing.id ? 'edit' : 'add'}-modal`}
          buttons={[
            <HeaderButton
              disabled={hours.isLoading || !hours.editing?.internalName || !hours.editing?.name}
              onClick={() => actions.saveHoursPushed(hours.editing)}
              color="primary"
              title='Save'
              className={`sked-test-office-events-${hours.editing.id ? 'edit' : 'add'}-modal-button-save`}
            />
          ]}
        >
          <div>
            <EditSpecialHours {...{
              classes,
              isEvent: true,
              hours: hours.editing,
              closed: actions.closedPushed,
              timeChanged: actions.timeChanged,
              eventPatch: actions.eventPatch,
              removeOpenPushed: actions.removeOpenPushed,
              add: actions.addOpeningPushed,
              dateChanged: actions.dateChanged,
              dateToChanged: actions.dateToChanged,
              nameChanged: actions.nameChanged,
              internalNameChanged: actions.internalNameChanged,
              locations,
              locationChanged: actions.locationChanged,
              hasLocations,
            }} />
          </div>
        </Modal>}
        <PopupTemplate />
      </div>
    </>
  );
}

export default EventHoursPage;
