import React from 'react';
import {
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import CopyIcon from '@mui/icons-material/FileCopy';
import PasteIcon from '@mui/icons-material/Assignment';
import MoreVertIcon from '@mui/icons-material/MoreVert';

type CopyPasteHoursProps = {
  onCopy: () => void;
  onPaste?: () => void;
  hoursType: string;
  noPaste?: boolean;
  size?: 'small' | 'inherit' | 'large' | 'medium';
}

const CopyPasteHours = ({
  onCopy,
  onPaste,
  hoursType,
  noPaste = false,
  size = undefined,
}: CopyPasteHoursProps) => {
  const isDay = hoursType === 'single-day-hours';
  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
    }}>
      <Tooltip
        title={isDay ? 'Copy hours' : 'Copy entire week of hours'}
        placement='top'
        arrow
      >
        <IconButton onClick={onCopy}>
          <CopyIcon />
        </IconButton>
      </Tooltip>
      {!noPaste && sessionStorage.getItem(hoursType) &&
        <Tooltip
          title={isDay ? 'Paste hours' : 'Paste entire week of hours'}
          placement='top'
          arrow
        >
          <IconButton onClick={onPaste}>
            <PasteIcon fontSize={size} />
          </IconButton>
        </Tooltip>}
    </div>
  );
};

type CopyPasteMenuProps = {
  onCopy: () => void;
  onPaste: () => void;
  hoursType: string;
  noPaste?: boolean;
  size?: 'small' | 'inherit' | 'large' | 'medium';
  style?: object;
}

export const CopyPasteMenu = ({
  onCopy,
  onPaste,
  hoursType,
  noPaste = false,
  size,
  style,
}: CopyPasteMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const isDay = hoursType === 'single-day-hours';
  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        className='sked-test-copy-past-menu-button'
        onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon fontSize={size} style={style} />
      </IconButton>
      <Menu
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}>
        <MenuItem onClick={() => {
          setAnchorEl(null);
          onCopy();
        }}>
          <ListItemIcon>
            <CopyIcon fontSize="large" />
          </ListItemIcon>
          <ListItemText primary={isDay ? 'Copy hours' : 'Copy entire week of hours'} />
        </MenuItem>
        {!noPaste && sessionStorage.getItem(hoursType) &&
          <MenuItem onClick={() => {
            setAnchorEl(null);
            onPaste();
          }}>
            <ListItemIcon>
              <PasteIcon fontSize="large" />
            </ListItemIcon>
            <ListItemText primary={isDay ? 'Paste hours' : 'Paste entire week of hours'} />
          </MenuItem>}
      </Menu>
    </>
  );
};

export default CopyPasteHours;
