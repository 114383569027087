import React from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import * as rawActions from './recurring-view.actions.jsx';
import { pipe, pathOr, dissoc, includes, always, cond } from 'ramda';

import ViewMessage from '../../../../components/view-message.component';

function AutomatedMessagesView({ match, history, location }) {
  const dispatch = useDispatch();
  const actions = bindActionCreators(rawActions, dispatch);
  const props = useSelector((state) => ({
    ...state.viewRecurring,
    ...pipe(pathOr({}, ['login', 'office']), dissoc('email'))(state),
    office: pathOr({}, ['login', 'office'])(state),
    features: pathOr([], ['login', 'features'])(state),
    tz: pathOr({}, ['login', 'office', 'timezone'])(state),
    plan: pathOr('Sked', ['subscription', 'plan', 'plan'])(state),
    automations: pathOr([], ['automations', 'automations', 'data'])(state),
    headerHeight: state.login.headerHeight,
  }));
  const messageType = cond([
    [includes('apptchange'), always('ApptChange')],
    [includes('automation'), always('Automation')],
    [includes('smart-reply'), always('Automation')],
    [includes('birthday'), always('Birthday')],
    [includes('recurring'), always('Recurring')],
    [includes('reactivation'), always('Recall')],
  ])(match.path);
  return (
    <ViewMessage
      {...props}
      messageType={messageType}
      urlId={match.params.id}
      actions={actions}
      history={history}
      location={location}
    />
  );
}
export default AutomatedMessagesView;
