import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    zIndex: 101,
    '@media print': {
      display: 'none'
    },
  },
  arrowIcon: {
    width: 17,
    height: 17,
  },
  navigateIcon: {
    width: 20,
    height: 20,
  }
}));
