import React from 'react';
import { propOr } from 'ramda';
import { Paper, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Line } from 'react-chartjs-2';
import { now } from '../../../services/joda.js';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Chart as ChartJS, registerables } from 'chart.js'; 
ChartJS.register(...registerables);

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const displayMonths = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];


const useStyles = makeStyles(() => ({
  dashboardHeader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginBottom: '20px',
    padding: '10px'
  },
  dashboardListContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '-5px',
    marginRight: '-5px'
  },
  chart: {
    '& .chart-header': {
      height: '40px'
    }
  },
  chartControlContainer: {
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '300px',
    fontSize: '20px',
  },
  chartContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  }
}));

export const Chart = ({
  graph,
  year,
  update
}) => {
  const classes = useStyles();

  const newAptData = [],
    reskedData = [],
    canceledData = [];

  if (graph.data) {
    months.map((month) => {
      const monthProp = propOr([], month.toLowerCase())(graph.data);
      newAptData.push(monthProp.added);
      reskedData.push(monthProp.updated);
      canceledData.push(monthProp.canceled);
    });
  }

  const chartData = {
    labels: displayMonths,
    datasets: [
      {
        label: 'New Appts.',
        data: newAptData,
        borderColor: 'rgba(00,140,223)',
        tension: 0.5,
        fill: true,
      },
      {
        label: 'Rescheduled',
        data: reskedData,
        borderColor: 'rgba(255,152,0)',
        tension: 0.5,
        fill: true,
      },
      {
        label: 'Canceled',
        data: canceledData,
        borderColor: 'rgba(244, 67, 54)',
        tension: 0.5,
        fill: true,
      },
    ]
  };

  if (graph.error) {
    return (
      <Paper className={classes.dashboardHeader}>
        <div>
          Error: Failed to get graph data
        </div>
      </Paper>
    );
  }
  return (
    <Paper className={classes.dashboardHeader}>
      <div className={classes.chartContainer}>
        <div className={classes.chartControlContainer}>
          <Button
            disabled={year === 2016}
            onClick={() => update({ year: year - 1 })}>
            <ChevronLeftIcon fontSize='large' />
          </Button>
          {year}
          <Button
            disabled={year === now('date').year()}
            onClick={() => update({ year: year + 1 })}>
            <ChevronRightIcon fontSize='large' />
          </Button>
        </div>
        <div className={classes.chart}>
          <Line data={chartData} height={420} options={{
            maintainAspectRatio: false,
            animation: false,
          }} />
        </div>
      </div>
    </Paper>
  );
};
