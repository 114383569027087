import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '2px',
    overflow: 'hidden',
    backgroundColor: 'rgba(50, 50, 50, 0.5)',
    color: '#EEE'
  },
  blockName: {
    fontSize: '12px',
    marginLeft: '2px'
  },
}));

export const BlockEntry = ({ id, title, y, x, height, width, name }) => {
  const classes = useStyles();
  return (<div
    id={id}
    className={classes.root}
    title={title}
    style={{
      pointerEvents: 'none',
      top: y,
      left: x,
      height,
      width,
    }}>
    <div className={classes.blockName}>
      {name}
    </div>
  </div>);
};
