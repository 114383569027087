import { AnyAction } from 'redux';
import * as actionTypes from './login.actionTypes';
import * as R from 'ramda';
import * as at from '../../actionTypes';
import { formatPhone } from '../../services/utilities.js';
import { Banner } from '../Admin/Banners/banners.types';

export const USER_TYPES = {
  ADMIN: 'Admin',
  OFFICE: 'Office',
  OFFICE_PEON: 'OfficePeon',
  AGENCY: 'Agency',
  AGENCY_ADMIN: 'AgencyAdmin',
};

export const SPARK_FEATURE = 'SparkUI';

export type User = {
  id: number;
  email: string;
  name?: string;
  userType: {
    UserAgency: {
      agencyId: number;
    }
  }
}

export type Office = {
  id: number;
  created: string;
  ehrSystem: string;
  email: string;
  name: string;
  notificationEmail: string;
  phone: string;
  plan: string;
  shortId: number;
  timezone: string;
  isSpark?: boolean; // this is added on in the login.actions sometimes
}

type LoginReducer = {
  office?: Office;
  officeAddress?: {
    state: string;
    country: string;
    zipCode: string;
    street: string;
    city: string;
  };
  offices: Office[];
  shownOffices: Office[];
  query: string;
  features: string[];
  settings: object;
  system: string;
  user: User;
  selected: boolean;
  busy: boolean;
  admin: boolean;
  status: string;
  agencyAdmin: boolean;
  officeAdmin: boolean;
  agency: boolean;
  message: string;
  snackbar: boolean;
  ehrSettings: object;
  group: Office[];
  isLoggedIn?: boolean;
  resyncLoading?: boolean;
  locked?: boolean;
  desktopOpen?: boolean;
  banners?: Banner[];
  theme?: 'sked' | 'spark';
  headerHeight?: number;
  sparkTab?: string;
}

const init: LoginReducer = {
  office: {} as Office,
  officeAddress: {
    state: '',
    country: '',
    zipCode: '',
    street: '',
    city: ''
  },
  offices: [],
  shownOffices: [],
  query: '',
  features: [],
  settings: {},
  system: '',
  user: {} as User,
  selected: false,
  busy: false,
  admin: false,
  agencyAdmin: false,
  officeAdmin: false,
  agency: false,
  status: 'Loading...',
  message: '',
  snackbar: false,
  ehrSettings: {},
  group: [],
  locked: null,
  desktopOpen: true,
  banners: [],
  theme: 'sked',
  headerHeight: 54,
  sparkTab: 'first_year',
};

export default (login = init, action: AnyAction): LoginReducer => {
  switch (action.type) {
    case actionTypes.SESSION_CHECKING: {
      return {
        ...login,
        isLoggedIn: true
      };
    }
    case actionTypes.LOGIN:
      return {
        ...login,
        isLoggedIn: true
      };
    case at.LOGIN_GET:
      return R.merge(login, action.data);
    case at.LOGIN_PATCH:
      return R.merge(login, action.data);
    case at.LOGIN_RESET:
      return R.merge(login, init);
    case at.USER_REMOTE_GET:
      if (action.state === 'RESPONSE') {
        return R.merge(login, { user: action.data });
      }
      return login;
    case at.OFFICE_REMOTE_PUT:
    case at.OFFICE_REMOTE_GET:
      if (action.state === 'RESPONSE') {
        const features = action.data?.features ? action.data.features : login.features;
        const isSpark = R.includes(SPARK_FEATURE, features || []);
        return R.pipe(
          R.merge(R.__, action.data),
          R.evolve({
            office: { phone: formatPhone },
            theme: () => isSpark ? 'spark' : 'sked',
          })
        )(login) as LoginReducer;
      }
      return login;
    case at.STATUS_UPDATE: {
      return R.merge(login, action.data);
    }
    case at.OFFICE_FILTER_NAME: {
      const query = action.data.query.toLowerCase();
      const shownOffices = R.filter((office: Office) => {
        const searchBy = office.name.toLowerCase() + ' ' + String(office.id);
        const ehrSystem = R.prop('ehrSystem')(office);
        if (searchBy.indexOf(query) !== -1) {
          if (action.data.system) {
            return ehrSystem === action.data.system;
          }
          return true;
        }
        return false;
      })(login.offices);

      return R.merge(login, { shownOffices, query });
    }
    case at.OFFICE_RESYNC:
      if (action.state === 'REQUEST') {
        return R.merge(login, {
          resyncLoading: true,
          snackbar: false,
          message: action.data,
        });
      }
      if (action.state === 'RESPONSE') {
        return R.merge(login, {
          resyncLoading: false,
          snackbar: true,
          message: action.data,
        });
      }
      return login;
    case at.OFFICE_FILTER_SYSTEM: {
      const shownOffices = R.filter((office: Office) => {
        const searchBy = R.prop('ehrSystem')(office);
        if (searchBy.split(action.data.query).length > 1) {
          return true;
        }
        return false;
      })(login.offices);

      return R.merge(login, { shownOffices, system: action.data.query });
    }
    case at.OFFICE_REMOTE_GET_FEATURES: {
      if (action.state === 'RESPONSE') {
        const features = action.data;
        const isSpark = R.includes(SPARK_FEATURE, features);
        return R.merge(login, {
          features,
          theme: isSpark ? 'spark' : 'sked',
        });
      }
      return login;
    }
    case at.OFFICE_REMOTE_GET_SETTINGS: {
      if (action.state === 'RESPONSE') {
        return R.merge(login, {
          settings: action.data,
        });
      }
      return login;
    }
    case at.OFFICE_REMOTE_GET_EHR_SETTINGS: {
      if (action.state === 'RESPONSE') {
        console.log(action.data);
        return R.merge(login, {
          ehrSettings: action.data,
        });
      }
      return login;
    }
    case at.OFFICE_REMOTE_GET_OFFICE_GROUP: {
      if (action.state === 'RESPONSE') {
        console.log(action.data);
        return R.merge(login, {
          group: action.data,
        });
      }
      return login;
    }
    case at.BUSINESS_REMOTE_GET:
      if (action.state === 'REQUEST') {
        return R.merge(login, { busy: true });
      } else if (action.state === 'RESPONSE') {
        return R.merge(login, {
          officeAddress: action.data.address,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(login, { busy: false });
      }
      return login;
    default:
      return login;
  }
};
