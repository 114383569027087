import * as React from 'react';
import {
  Typography, Menu, MenuItem, Grid,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Office } from '../../routes/Login/login.reducer';
import { useStyles } from './changeOffice.styles';
import { dropLast } from 'ramda';

type ChangeOfficeProps = {
  office: Office;
  group: Office[];
  hasMenu: boolean;
  selectOffice: (office: Office) => void;
}

const ChangeOffice = ({ office, group, hasMenu, selectOffice }: ChangeOfficeProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleSelect = (office: Office) => {
    selectOffice(office);
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (hasMenu) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [officeName, bigName] = React.useMemo(() => {
    const name = office.name || '';
    const bigName = name.length > 40;
    if (bigName) {
      /* We can figure out approaximately how big the office name is by placing it
         into a element, applying css, and getting the client width. If that is
         bigger than about 490, we need to truncate.
      */
      const elem = document.createElement('p');
      elem.id = 'namely';
      elem.innerText = name;
      elem.style.fontSize = '12px';
      elem.style.whiteSpace = 'nowrap';
      elem.style.width = 'min-content';
      document.body.appendChild(elem);
      const width = elem.clientWidth + 1;
      elem.remove();
      if (width > 490) {
        /* If there are really big words, stuff doesn't wrap good and makes us have
           to adjust the calculation so that we truncate correctly.
        */
        const bigwords = name.split(' ').reduce((acc, cur) => {
          if (cur.length > 25) {
            return acc + 1;
          }
          return acc;
        }, 0);
        const max = 490 - (bigwords * 30);
        /* The characters to be truncated can be determined by basically doing:
           pixels / (pixels/characters) and we take the ceiling because it is better
           to truncate too much than too little.
           Drop the uneeded chars, add the ellipsis and return
        */
        const excess = Math.ceil((width - max) / (width / name.length));
        return [dropLast(excess + 3, name) + '...', true];
      }
    }
    return [name, bigName];
  }, [office.name]);

  return (
    <Grid flex={1}>
      <div
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={
          `${hasMenu ? classes.cursor : classes.row} sked-test-switch-office-button`
        }
      >
        <Typography className={`${classes.officeName} ${bigName ? classes.bigName : ''}`}>
          {officeName} <span className={classes.officeId}>#{office.id}</span>
        </Typography>
        {hasMenu && <ArrowDropDownIcon className={classes.icon} />}
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {group.map((office, index) => (
          <MenuItem
            key={office.id}
            className={
              `${index < group?.length - 1 ? classes.menuItem : ''} ${'sked-test-switch-office-' + office.name.toLowerCase().replace(/ /g, '-')}`
            }
            onClick={() => handleSelect(office)}
          >
            <div className={classes.item}>
              <Typography>
                {office.name}
              </Typography>
              <span>Office ID: #{office.id}</span>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

export default ChangeOffice;
