import React from 'react';
import {
  pathOr, includes, isEmpty, map, pipe, filter, prop, not, compose,
  forEach, length, update, assoc, adjust, T, cond, isNil, nth,
} from 'ramda';
import { useDispatch } from 'react-redux';
import { Row, Section, Spacer } from '../../../../components/PageHeader';
import {
  Paper, LinearProgress, IconButton, Divider, Typography, Button,
  Checkbox,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckboxIcon from '@mui/icons-material/CheckBox';
import CheckboxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import {
  PopupTemplate,
  popup,
} from '../../../../services/Popup.js';
import { useTitle } from '../../../../services/useTitle';
import * as actions from '../../tutorial.actions.js';
import Header from '../../../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import Modal from '../../../../components/Modal/Modal.component';
import { useSelector } from '../../../../reducers';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#008BCF',
  },
}))(LinearProgress);

const useStyles = makeStyles(() => ({
  sectionContainer: {
    maxWidth: '920px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  progessSection: {
    marginTop: '20px',
    marginBottom: '20px',
    padding: '20px'
  },
  progessHeader: {
    display: 'flex',
    flexDirection: 'row'
  },
  stepsContainer: {
    maxHeight: 0,
    overflow: 'hidden',
    transition: 'max-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  stepsContainerOpen: {
    height: 'auto',
    maxHeight: '300px'
  }
}));

const Title = (props) => <Typography component="div" style={{ fontSize: '16px', fontWeight: 'bold' }} {...props} />;
const Body = (props) => <Typography component="p" {...props} />;

const StepTitle = (props) => <Typography component="div" style={{ fontSize: '16px' }} {...props} />;
const StepBody = (props) => <Typography component="p" style={{ fontWeight: 'lighter' }} {...props} />;

const queryParam = '?gettingStarted=true';
const Step = ({ title, body, link, isComplete, markStep, redirectStep }) => {
  const queryLink = '#/' + link + queryParam;
  const [disabled, setDisabled] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => setDisabled(false), 500);
  }, [disabled]);
  return <>
    <Divider />
    <Row style={{ paddingTop: '10px', paddingBottom: '10px', marginLeft: '30px' }}>
      <Checkbox
        icon={<RadioButtonUncheckedIcon color="disabled" style={{ padding: '10px' }} />}
        checkedIcon={<CheckCircleIcon color={'primary'} style={{ padding: '10px' }} />}
        checked={isComplete === undefined ? false : isComplete}
        disabled={disabled}
        onChange={(e) => {
          markStep(e.target.checked);
          setDisabled(true);
        }}
      />
      <div>
        <StepTitle color={isComplete ? 'textSecondary' : 'textPrimary'}>{title.trim()}</StepTitle>
        <StepBody color={isComplete ? 'textSecondary' : 'textPrimary'}>{body.trim()}</StepBody>
      </div>
      <Spacer />
      {cond([
        [compose(isNil, nth(1)), () => <></>],
        [nth(0), () => (
          <Button
            color="inherit"
            component="a"
            href={queryLink}>
            Review
          </Button>
        )],
        [T, () => (
          <Button
            color="primary"
            component="a"
            href={queryLink}
            onClick={() => {
              redirectStep(title);
            }}
          >
            Start
          </Button>
        )]
      ])([isComplete, link])}
    </Row>
  </>;
};

const OverviewSection = ({ title, body, steps, classes, markStep, opened, redirectStep }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(opened);
  const [disabled, setDisabled] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => setDisabled(false), 500);
  }, [disabled]);
  const isComplete = pipe(
    filter(compose(not, prop('isComplete'))),
    isEmpty
  )(steps);
  const numberOfComplete = pipe(
    filter(prop('isComplete')),
    length,
  )(steps);
  return <Paper className={classes.progessSection}>
    <Row style={{ alignItems: 'center' }}>
      <IconButton
        onClick={() => {
          setOpen(!open);
          if (!open) {
            dispatch(actions.expandSection(title));
          } else {
            dispatch(actions.compressSection(title));
          }
        }}
        style={{ height: '50px', width: '50px', marginLeft: '-20px' }}>
        {open ? <ExpandMoreIcon /> : <ChevronRightIcon />}
      </IconButton>
      {isComplete &&
        <Checkbox
          icon={<CheckboxOutlineBlankIcon color="disabled" style={{ padding: '10px' }} />}
          checkedIcon={<CheckboxIcon color={'primary'} style={{ padding: '10px' }} />}
          indeterminateIcon={<IndeterminateCheckBoxIcon color={'primary'} style={{ padding: '10px' }} />}
          indeterminate={numberOfComplete !== 0 && numberOfComplete !== steps.length}
          checked={isComplete}
          disabled={disabled}
          onChange={(e) => {
            markStep('All', e.target.checked);
            setDisabled(true);
          }}
        />}
      <div>
        <Title color={isComplete ? 'textSecondary' : 'textPrimary'}>{title.trim()}</Title>
        <Body color={isComplete ? 'textSecondary' : 'textPrimary'}>{body.trim()}</Body>
      </div>
    </Row>
    <div style={{ maxHeight: (open ? (steps.length * 118) + 'px' : 0) }} className={classes.stepsContainer}>
      {steps.map((s, index) =>
        <Step
          key={s.title}
          title={s.title}
          body={s.body}
          link={s.link}
          isComplete={s.isComplete}
          index={index}
          markStep={(mark) => markStep(index, mark)}
          redirectStep={(title) => redirectStep(index, title)}
        />
      )}
    </div>
  </Paper>;
};

export const OverviewPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    plan, ehrSystem, opened, redirected, filterData, busy, office,
  } = useSelector(state => ({
    office: pathOr('None', ['login', 'office'], state),
    plan: pathOr('SkedBasic', ['login', 'office', 'plan'], state),
    ehrSystem: pathOr('None', ['login', 'office', 'ehrSystem'], state),
    opened: pathOr([], ['tutorial', 'opened'], state),
    redirected: pathOr(null, ['tutorial', 'redirected'], state),
    data: pathOr([], ['tutorial', 'data'], state),
    filterData: pathOr([], ['tutorial', 'filterData'], state),
    busy: pathOr([], ['tutorial', 'busy'], state),
  }));
  const [current, setCurrent] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [modal, setModal] = React.useState({ text: '', action: null });

  useTitle('Onboarding Guide');

  React.useEffect(() => {
    dispatch(actions.getFilterData(plan, ehrSystem));
  }, [office]);

  React.useEffect(() => {
    if (isEmpty(filterData)) {
      dispatch(actions.getFilterData(plan, ehrSystem));
    }
  }, []);

  React.useEffect(() => {
    let c = 0, t = 0;
    forEach(({ steps }) => {
      t = t + steps.length;
      c = steps.reduce((a, b) => {
        if (b.isComplete) {
          return a + 1;
        }
        return a;
      }, c);
    }, filterData);
    if (!isEmpty(filterData) && current !== total && c === t) {
      window.confetti();
      setTimeout(
        () => popup('Congratulations!', 'You\'ve finished the entire SKED Tutorial! You should celebrate by drinking a tall glass of water.'), 1000);
    }
    setCurrent(c);
    setTotal(t);
  }, [filterData]);

  React.useEffect(() => {
    if (redirected) {
      const {
        index,
        stepIndex,
        title,
      } = redirected;
      markStep(index, stepIndex, true, title);
    }
  }, [redirected]);

  const markStep = (overviewIndex, stepIndex, isComplete, title = 'this item') => {
    const doIt = () => {
      const oldOne = filterData[overviewIndex];
      const newSteps = stepIndex === 'All' ? map(assoc('isComplete', isComplete), oldOne.steps) :
        adjust(stepIndex, assoc('isComplete', isComplete), oldOne.steps);
      const newOne = assoc('steps', newSteps, oldOne);
      const fd = update(overviewIndex, newOne, filterData);
      dispatch(actions.setFilterData(fd));
      dispatch(actions.redirect(null));
      const items = stepIndex === 'All' ? map(prop('id'))(oldOne.steps) : [pipe(nth(stepIndex), prop('id'))(oldOne.steps)];
      dispatch(actions.updateTutorialItems(isComplete, items));
      setModal({ text: '', action: null });
    };
    const complete = isComplete ? 'complete' : 'incomplete';
    const msg = stepIndex === 'All' ?
      `Do you want to mark all items in this section as ${complete}?` :
      `Do you want to mark ${title} as ${complete}?`;
    setModal({ text: msg, action: doIt });
  };
  const redirectStep = (index, stepIndex, title) => {
    setTimeout(() => dispatch(actions.redirect({ index, stepIndex, title })), 1000);
  };
  return (
    <>
      <Header
        title="Welcome to SKED. Let's get started!"
        pageId="office-tutorial"
        helpMaxWidth={1100}
      />
      <div style={{ overflowY: 'auto' }}>
        <Section style={{ margin: '20px', paddingTop: 0 }}>
          <PopupTemplate />
          <div className={classes.sectionContainer}>
            <Paper className={classes.progessSection}>
              <Title>{busy ? 'Calculating progress...' : `${current}/${total} Complete`}</Title>
              <BorderLinearProgress variant={busy ? 'indeterminate' : 'determinate'} value={total === 0 ? 0 : current / total * 100} />
            </Paper>

            {filterData.map((d, index) =>
              <OverviewSection
                key={d.title}
                title={d.title}
                body={d.body}
                steps={d.steps}
                classes={classes}
                markStep={(stepIndex, mark) => markStep(index, stepIndex, mark)}
                opened={includes(d.title, opened)}
                redirectStep={(stepIndex, title) => redirectStep(index, stepIndex, title)}
              />
            )}
          </div>
        </Section>
      </div>
      <Modal
        title='Verification'
        open={!!modal.text}
        size='xs'
        buttons={[<HeaderButton title='Yes' onClick={modal.action} color='primary' className='sked-test-overview-modal-button-yes' />]}
        onClose={() => setModal({ text: '', action: null })}
        className='sked-test-overview-modal'
      >
        <Typography>{modal.text}</Typography>
      </Modal>
    </>
  );
};
