import React from 'react';

import { useStyles } from './filterHeader.styles';

import {
  Button,
  Grid,
  Typography,
} from '@mui/material';


type FilterHeaderProps = {
  save?: () => void;
  reset?: () => void;
  title: string,
  resetTitle?: string;
  saveTitle?: string;
  saveDisabled?: boolean;
  resetDisabled?: boolean;
  noReset?: boolean;
  saveClassName?: string;
  resetClassName?: string;
}

const FilterHeader = ({ save, reset, title, saveDisabled, noReset, resetDisabled, resetTitle, saveTitle, saveClassName, resetClassName }: FilterHeaderProps) => {
  const classes = useStyles();

  return (
    <Grid padding={2} paddingBottom={1} paddingTop={1} className={classes.header}>
      <Typography marginRight={1} fontWeight={500}>{title}</Typography>
      {(save || reset) && (
        <div>
          {(!noReset && !!reset) && (
            <Button
              onClick={reset}
              variant='outlined'
              className={`${classes.resetButton} ${resetClassName}`}
              disabled={resetDisabled}
              color="inherit">
              {resetTitle || 'Reset'}
            </Button>
          )}
          {save && (
            <>
              &nbsp;&nbsp;
              <Button
                onClick={save}
                variant='contained'
                className={`${classes.saveButton} ${saveClassName}`}
                disabled={saveDisabled}
                color="primary">
                {saveTitle || 'Save'}
              </Button>
            </>
          )}
        </div>
      )}
    </Grid>
  );
};

export default FilterHeader;
