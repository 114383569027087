import React from 'react';
import { Grid, Card, Button } from '@mui/material';
import { withStyles } from '@mui/styles';
import { PopupTemplate, popup } from '../../services/Popup.js';
import queryString from 'query-string';
import api from '../../services/api.js';

const Panel = withStyles({
  root: {
    padding: 15,

    '& legend': {
      fontSize: 21,
      marginBottom: 10
    },
  },
})(Card);

const Unsubscribe = (props) => {
  const handleSubmit = (event, token) => {
    event.preventDefault();
    const data = {
      token,
      allowEmail: false,
    };
    return api.post('/unsubscribe', data).then(() => {
      return popup('Success!', 'You have been unsubscribed. You can close this browser tab.');
    }).catch((error) => {
      popup('Error!', 'You could not be unsubscribed! Try again in a little while or contact us (info@sked.life).');
      console.error(error);
    });
  };

  const { token } = queryString.parse(props.location.search);

  return (
    <Grid container justifyContent="center" padding={2}>
      <PopupTemplate />
      <Grid item md={4}>
        <Panel>
          <legend>
            Unsubscribe from Email Notifications
          </legend>
          <label>
            To unsubscribe from email notifications, click the button below.
          </label>
          <br />
          <br />
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => handleSubmit(event, token)}>
              Unsubscribe
          </Button>
        </Panel>
      </Grid>
    </Grid>
  );
};

export default Unsubscribe;
