import React from 'react';
import { Grid, Card } from '@mui/material';

const Support = () => {
  return (
    <Grid container display="flex" justifyContent="center">
      <Grid item md={8} padding={2}>
        <Card style={{ padding: 15 }}>
          <h1>Support</h1>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Support;
