import React, { useState, useEffect } from 'react';
import { DialogContentText } from '@mui/material';

import { merge, isNil } from 'ramda';
import Modal from '../components/Modal/Modal.component';
import HeaderButton from '../components/HeaderButton/HeaderButton.component';

export let popup;
export let popupWithCancel;
export let popupWithCustoms;

const def = {
  open: false,
  title: '',
  msg: '',
  action: null,
  customContent: null,
  customButtons: null,
  className: '',
  size: 'xs',
  confirmColor: 'primary' | 'danger',
  confirmText: 'Yes',
};

export const PopupTemplate = () => {
  const [state, setState] = useState(def);

  useEffect(() => {
    popup = (title = null, msg) => {
      setState({
        ...state,
        title,
        msg,
        open: true,
        customButtons: null,
        customContent: null,
      });
    };
    popupWithCancel = (
      title = null, msg, act, confirmColor = 'primary', confirmText = 'Yes'
    ) => {
      setState({
        ...state,
        title,
        msg,
        open: true,
        action: act,
        customButtons: null,
        customContent: null,
        confirmColor,
        confirmText,
      });
    };
    popupWithCustoms = ({
      title = null,
      customButtons,
      customContent,
      size,
      className,
    }) => {
      setState({
        ...state,
        open: true,
        customButtons,
        customContent,
        title,
        action: null,
        size,
        className,
      });
    };
  }, []);

  const {
    open,
    title,
    msg,
    action,
    customButtons,
    customContent,
    className,
    size,
    confirmColor,
    confirmText,
  } = state;

  return (
    <Modal
      open={open}
      onClose={() => setState(def)}
      title={title}
      className={className || 'sked-test-custom-modal'}
      size={size || 'xs'}
      maxWidth={size ? undefined : 1100}
      buttons={[
        !isNil(action) && !customButtons &&
            <HeaderButton
              color={confirmColor || 'primary'}
              onClick={() => {
                console.log('execute');
                action();
                setState(def);
              }}
              title={confirmText}
              className='sked-test-custom-modal-button-yes'
            />,
        isNil(action) && customButtons &&
            customButtons({
              state,
              setState: (v) => setState(merge(state, v)),
              close: () => setState(def),
            })
      ]}
    >
      <div>
        {customContent ?
          customContent({
            state,
            setState: (v) => setState(merge(state, v)),
            close: () => setState(def),
          })
          :
          <DialogContentText id="alert-dialog-description">
            {msg.split('\n').map((m, i) => {
              return (
                <div key={i}>
                  {m}
                </div>
              );
            })}
          </DialogContentText>}
      </div>
    </Modal>
  );
};
