import React, { useMemo } from 'react';
import { Conditional } from '../ServerTypes';
import { isEmpty, flatten, keys, } from 'ramda';
import { ConditionalIcon } from '../../../icons/CustomIcons';
import { Tooltip } from '@mui/material';

interface ConditionalIndicatorProps {
  conds: Conditional[];
  index: number;
  type: 'Section' | 'Page' | 'Question';
}

const ConditionalIndicator = ({
  index, conds, type
}: ConditionalIndicatorProps) => {
  const [targetOf, targets] = useMemo(() => {
    let targetOf: Conditional = null;
    const targets: Conditional[] = [];
    conds.forEach((cond) => {
      if (type === 'Question' && cond.questionIndex === index) {
        targets.push(cond);
      } else {
        cond.target.forEach((t) => {
          const tar = t[type] === index;
          if (tar) {
            targetOf = cond;
          }
        });
      }
    });
    return [
      targetOf,
      targets,
    ];
  }, [conds]);

  const targetOfTool = useMemo(() => {
    if (targetOf) {
      return `Conditionally shown by Question #${targetOf.questionIndex}`;
    }
    return '';
  }, [targetOf]);

  const targetTool = useMemo(() => {
    if (targets && !isEmpty(targets)) {
      return flatten(targets.map(({ target }) => target)).reduce((acc, cur) => {
        const t = keys(cur)[0];
        const v = cur[t] + (t === 'Page' ? 1 : 0);
        if (acc === '') {
          return `Conditionally shows ${t} #${v}\n`;
        } else {
          return acc + `${t} #${v}\n`;
        }
      }, '');
    }
    return '';
  }, [targets]);

  if (isEmpty(conds)) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}>
      {[targetTool, targetOfTool].map((title) => {
        const isTarget = title.indexOf('shown') > 0;
        if (!title) {
          return;
        }
        return (
          <Tooltip title={title} placement='top'>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#cfedfa',
              borderRadius: '5px',
              padding: '0px 5px',
              marginLeft: '5px',
              cursor: 'default',
            }}>
              <ConditionalIcon style={{ fontSize: '16px' }}/>
              {isTarget && 'Logic'}
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default ConditionalIndicator;
