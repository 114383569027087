import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Checkbox, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddIcon from '@mui/icons-material/Add';
import * as rawActions from './SpecialOfficeHours/specialOfficeHours.actions.js';
import {
  pathOr, evolve, sort, ascend, prop, intersection, isEmpty, pipe,
  includes, isNil, prepend
} from 'ramda';
import { PopupTemplate } from '../../services/Popup.js';
import { LocalDate } from '@js-joda/core';
import { ShowGroupedSpecialHours, EditSpecialHours } from './components/Hours.jsx';
import { useTitle } from '../../services/useTitle.js';
import { ContactSupport } from '../../components/feature-disabled.component';
import { HelpDialog, useHelpDialog } from '../../components/Help/Help.component';
import { getLocations } from '../Settings/routes/Business/business.actions.js';

import Header from '../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import FilterLocations from '../../components/FilterLocations/FilterLocations.component';
import Loading from '../../components/Loading/Loading.component';
import Modal from '../../components/Modal/Modal.component';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',
    paddingBottom: 30,
    paddingTop: 0,
    overflowY: 'auto',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  grow: {
    flexGrow: 1
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  editHoursRoot: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
  },
  formControl: {
    display: 'block',
    marginTop: '10px',
    marginBottom: '10px',
  },
  checkbox: {
    paddingLeft: 4,
    marginRight: 0,

    '& .MuiCheckbox-root': {
      padding: 2,
      paddingTop: 5,
      paddingBottom: 5,
      marginRight: 4,
    }
  }
}));

const isAfterNow = (date = '2021-02-01') => {
  return LocalDate.now().minusDays(1).isBefore(LocalDate.parse(date));
};

const compare = (a, b) => {
  if (a.group[0]?.date < b.group[0]?.date) {
    return -1;
  }
  if (a.group[0]?.date > b.group[0]?.date) {
    return 1;
  }
  return 0;
};

function SpecialHoursPage() {
  useTitle('Special Office Hours');
  const [showHelp, setShowHelp] = useState(false);
  const [show, setShow] = useState(false);
  const [locationId, setLocationId] = useState(0);

  const handleToggle = (e = true) => setShow(e);

  const classes = useStyles();
  const {
    hours, error, filteredHours, isEnabled, locations, hasLocations,
  } = useSelector(state => {
    const hours = pathOr({ group: [] }, ['specialOfficeHours'])(state);
    const filteredHours = hours.group
      .filter(({ group }) =>
        !isEmpty(group.filter(({ isEvent }) => !isEvent))
        &&
        (show || group.some(({ date }) => isAfterNow(date)))
        &&
        (locationId === 0 || group.some((h) =>
          h.locationId === locationId ||
          (locationId === 'main' && isNil(h.locationId)))))
      .map(evolve({
        hours: sort(ascend(prop('open')))
      }))
      .sort(compare);
    const locations = pathOr([], ['business', 'locations'], state);
    const hasLocations = pipe(
      pathOr([], ['login', 'features'])
      , includes('Locations')
    )(state);
    return {
      hours,
      filteredHours,
      isEnabled: intersection(
        ['NPPortal', 'Autoresponder', 'NPPortal2'],
        pathOr([], ['login', 'features'], state)
      ).length > 0,
      error: pathOr({}, ['specialOfficeHours', 'error'])(state),
      locations: hasLocations && !isEmpty(locations) ?
        prepend(
          { id: 0, name: 'All Locations' },
          locations,
        ) : [],
      hasLocations,
    };
  });
  const dispatch = useDispatch();
  const actions = bindActionCreators(rawActions, dispatch);

  useEffect(() => {
    actions.get();
    if (hasLocations)
      dispatch(getLocations());
  }, []);

  const helpState = useHelpDialog();
  const disabled = hours.isLoading || !hours.editing?.internalName ||
    !hours.editing?.name ||
    (hasLocations && !isEmpty(locations) && !hours.editing.locationId);

  if (!isEnabled) {
    return (
      <>
        <Header title='Special Office Hours' />
        <div className={classes.root}>
          <div style={{ width: '600px' }}>
            <p>
              Put your office scheduler into the pocket of your patients with a very user friendly simple app.
            </p>
            <ContactSupport />
          </div>
        </div>
      </>
    );
  }


  return (
    <>
      <Header
        title='Special Office Hours'
        pageId="special-office-hours"
        leftIcons={[
          <FilterLocations
            hasLocations={hasLocations}
            allLocations={locations}
            selectedLocationId={locationId}
            setSelectedLocationId={setLocationId}
          />,
        ]}
        rightIcons={[
          <HeaderButton active={show} type='hide' borderSolid>
            <FormControlLabel
              className={classes.checkbox}
              control={
                <Checkbox
                  checked={show}
                  onChange={(e) => handleToggle(e.target.checked)}
                  name="checked-show-past-hours"
                />
              }
              label="Show past hours" />
          </HeaderButton>,
          <HeaderButton title='Add Hours'
            className='sked-test-special-office-hours-add-new-hour'
            Icon={AddIcon}
            onClick={() => actions.addHoursPushed()}
          />
        ]}
        onlyIconsWidth={715}
        breakPoints={[
          {
            width: 545,
            mobileItems: [1],
          },
        ]}
      />
      <div className={classes.root}>
        <div className={classes.headerContainer}>
          <p style={{ width: '500px' }}>
            Select 'Add New Hours' to add in a special closing or opening! <a style={{ color: '#008BCF' }} onClick={() => setShowHelp(true)}>Click HERE</a> to learn how!</p>
        </div>

        <Loading loading={hours.isLoading} />
        {error && <p>Error: Failed to get special office hours</p>}

        {filteredHours.map((hoursGroup) =>
          <ShowGroupedSpecialHours
            key={hoursGroup.id}
            edit={actions.editHoursPushed}
            remove={actions.deleteHours}
            hoursGroup={hoursGroup}
            locations={locations}
          />
        )}

        <HelpDialog
          pageId="special-office-hours"
          handleClose={helpState.handleClose}
          open={helpState.isOpen} />

        {hours.editing && <Modal
          open={!!hours.editing}
          maxWidth={700}
          onClose={actions.cancelHoursPushed}
          title={`${hours.editing.id ? 'Edit' : 'Add'} Hours`}
          className={`sked-test-special-office-hours-${hours.editing.id ? 'edit' : 'add'}-modal`}
          buttons={[
            <HeaderButton
              title='Save'
              color='primary'
              disabled={disabled}
              onClick={() => actions.saveHoursPushed(hours.editing)}
              className={`sked-test-special-office-hours-${hours.editing.id ? 'edit' : 'add'}-modal-button-save`}
            />
          ]}
        >
          <div>
            <EditSpecialHours {...{
              classes,
              isEvent: false,
              hours: hours.editing,
              closed: actions.closedPushed,
              timeChanged: actions.timeChanged,
              eventPatch: actions.eventPatch,
              removeOpenPushed: actions.removeOpenPushed,
              add: actions.addOpeningPushed,
              dateChanged: actions.dateChanged,
              dateToChanged: actions.dateToChanged,
              nameChanged: actions.nameChanged,
              internalNameChanged: actions.internalNameChanged,
              locationChanged: actions.locationChanged,
              locations,
              hasLocations,
            }} />
          </div>
        </Modal>}

        <Modal
          open={showHelp}
          onClose={() => setShowHelp(false)}
          title='Add Special Closings'
          className='sked-test-special-office-hours-help-modal'
        >
          <div>
            <p>It is important to add special closings in SKED to prevent patients from scheduling on a day when your office will be closed. Follow the steps below!</p>

            <ol>
              <li>Select 'Add New Hours'</li>
              <li>Create an Internal Name (for office use only)</li>
              <li>Create a Brief Reason (your practice members will see this in the SKED App)</li>
              <li>Select date(s) that office will be closed</li>
              <li>If you are closing your office for the entire day, keep the box checked for closed. If you are closing or opening your office for part of the day, un-check the box and enter
                in the hours you will be available that day.</li>
              <li>Click 'Save'</li>
            </ol>

            <p>
              ** The Special Office Hours affect every provider’s schedule.  If you have multiple providers, and one provider will be gone for part of the day but the other docs will be
              adjusting as normal, you will need to add these special hours directly to your provider’s schedules instead.**
            </p>
          </div>
        </Modal>

        <PopupTemplate />
      </div>
    </>
  );
}

export default SpecialHoursPage;
