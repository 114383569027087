import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const DoneIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} id="uuid-56bdb53a-7d01-4885-9dc0-8979eaa7d1e3" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <g fill={color || '#FFF'} id="uuid-7adad26d-e114-4d36-9ca7-6aeed6bd0d8b" data-name="Group 8102">
        <path id="uuid-40608017-b37a-4821-b2ed-1fa62a4faed8" data-name="Path 6206" d="m24.9674,49.9998C11.1604,49.9819-.0179,38.7746,0,24.9675.0179,11.1605,11.2253-.0178,25.0323.0001c13.6879.0178,24.8164,11.0397,24.9659,24.7268.1632,13.7936-10.8864,25.1079-24.68,25.2712-.1169.0014-.2339.0019-.3508.0017m-2.2374-22.7005c-1.4916-1.4241-2.9213-2.7946-4.3566-4.1624-.4286-.4452-.8835-.8643-1.3621-1.2552-.8628-.6099-2.0291-.5578-2.834.1266-1.0126.8534-1.1416,2.3662-.2882,3.3788.108.1281.2291.2446.3613.3474,2.2008,2.2233,4.4195,4.4335,6.6559,6.6306.8442,1.0001,2.3393,1.1264,3.3393.2822.1563-.1319.2948-.2835.4122-.451,2.5986-2.8894,5.2037-5.7619,7.8154-8.6175,1.1726-1.2946,2.3368-2.5976,3.4926-3.9091.7135-.6766.8946-1.743.4447-2.6173-.4164-.9072-1.3621-1.4507-2.3556-1.3537-.5906.0371-1.1387.3199-1.5113.7796-.8809.9681-1.7336,1.97-2.6173,2.9325-2.1689,2.3791-4.3407,4.7544-6.5152,7.1259-.2533.2814-.4981.5629-.6698.7627" />
      </g>
    </svg>
  );
};

export default DoneIcon;
