import React from 'react';

type Props = {
  color?: string;
};

const LogoIcon = ({ color }: Props) => {
  return (
    <svg width="83" height="21" viewBox="0 0 83 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.35457 20.4502C5.73999 20.4502 2.45618 19.269 0.09375 17.0955L3.23578 13.9298C4.79503 15.4418 7.55911 16.4104 9.75618 16.4104C12.5439 16.4104 13.4889 15.7016 13.4889 14.733C13.4889 14.355 13.1345 13.4573 10.6776 12.8667C10.2523 12.7722 7.55911 12.1343 7.13384 12.0162C3.7319 11.1894 0.448084 9.91364 0.448084 6.53531C0.448084 3.32235 3.59016 0.841797 9.11831 0.841797C12.7801 0.841797 15.7805 2.09386 17.8122 4.17284L14.6229 7.31492C13.4889 5.99196 11.15 4.85798 9.04742 4.85798C6.33062 4.83432 5.12575 5.59031 5.12575 6.41719C5.12575 7.14956 6.02348 7.48028 8.88206 8.18903C9.30733 8.30715 11.5044 8.85055 11.9533 8.94502C16.0167 9.89002 18.1665 12.0871 18.1665 14.733C18.1665 17.757 15.3788 20.4502 9.35457 20.4502Z" fill={color || '#008BCF'} />
      <path d="M26.0804 20.214H21.4736V1.07812H26.0804V20.214ZM35.105 20.214L26.293 10.5515L34.7506 1.1017H41.1057L32.1519 10.5988C33.4513 11.6383 41.3891 20.214 41.3891 20.214H35.105Z" fill={color || '#008BCF'} />
      <path d="M59.9341 20.214H43.5151V1.07812H59.9341V5.11788H47.6967L48.1218 5.54315V8.18906L47.6967 8.61433H58.8001V12.6777H47.6967L48.1218 13.103V15.7489L47.6967 16.1742H59.9341V20.214Z" fill={color || '#008BCF'} />
      <path d="M72.2423 20.214H63.7139V1.07812H72.1243C80.6055 1.07812 82.0936 6.37003 82.0936 10.6461C82.0936 14.9221 80.6292 20.214 72.2423 20.214ZM71.8881 5.11793H67.9191L68.3207 5.54315V15.749L67.9191 16.1742H72.0062C76.7073 16.1742 77.4161 13.9299 77.4161 10.6461C77.4161 7.40954 76.6837 5.11793 71.8881 5.11793Z" fill={color || '#008BCF'} />
    </svg>
  );
};

export default LogoIcon;
