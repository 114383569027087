import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 12,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  selectItem: {
    marginBottom: 4,
    padding: 0,
  },
  selected: {
    background: 'rgba(0, 139, 207, 0.1)',
    marginBottom: 4,
    padding: 0,
  },
  selectFontSize: {
    fontSize: 14,

    '& > span': {
      fontSize: '14px !important',
    }
  },
}));
