import * as at from '../../../../actionTypes';
import api from '../../../../services/api.js';

export const getInstaller = () => (dispatch) =>
  api.get('version')
    .then((data) => {
      console.log(data);
      return dispatch({
        type: at.INSTALLER_PATCH,
        data: {
          installer: data,
        }
      });
    })
    .catch((error) => {
      if (error.response.status === 403) {
        console.log('Failed to get version. (not generally a real error)');
      } else {
        console.error(error);
      }
      return null;
    });

export const getEmail = () => (dispatch) =>
  api.get('user')
    .then((data) => {
      return dispatch({
        type: at.INSTALLER_PATCH,
        data: {
          email: data.email
        }
      });
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
