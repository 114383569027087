import React from 'react';

import { Button, Grid } from '@mui/material';

import { useStyles } from './headerTabs.styles';

type Item = {
  value: string;
  label: string;
  className?: string;
}

type Props = {
  tabs: Item[];
  value: string;
  setTab: (value: string) => void;
}

const HeaderTabs = ({ tabs, value, setTab }: Props) => {
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      {tabs.map((tab, index) => {
        if (tab) {
          return (
            <Button
              key={tab.value}
              onClick={() => setTab(tab.value)}
              variant={'outlined'}
              className={`${value === tab.value ? classes.selected : ''}
                ${classes.customButtom} ${index === 0 ? classes.first : ''}
                ${index === tabs.length - 1 ? classes.last : ''} ${tab.className}`}
              color="primary"
            >
              {tab.label}
            </Button>
          );
        }
        return null;
      })}
    </Grid>
  );
};

export default HeaderTabs;
