import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const ProfessionalIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} id="uuid-168ad44a-76b1-4592-9cbe-9981d0d12233" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <g id="uuid-7ae989b4-c19c-4cb7-b321-0c0ca68b7374" data-name="Group 6603" fill={color || '#FFF'}>
        <path id="uuid-6a41e15e-cab3-45eb-99e3-910a86f8e22f" data-name="Path 5174" d="m24.9687,50C11.1616,49.9599.0013,38.7345.0414,24.9274.0816,11.1203,11.307-.04,25.114.0001c13.6961-.0252,24.8194,11.0572,24.8446,24.7533.0002.1296-.0005.2593-.0023.3889.0414,13.6858-11.0195,24.8139-24.7052,24.8553-.0931.0003-.1862,0-.2792-.0007m17.953-12.8045c6.7759-9.896,4.2465-23.4112-5.6495-30.1871C27.3793.2294,13.864,2.7588,7.0882,12.6548c-5.0599,7.3898-5.0641,17.128-.0106,24.5221,9.6124-9.8948,25.4261-10.1237,35.321-.5113.1791.174.3555.3506.5293.5299" />
        <path id="uuid-f97da94f-970b-4b05-82e3-ae958a5ae2f5" data-name="Path 5175" d="m34.9872,16.7379c-.0611,5.476-4.5267,9.8782-10.0031,9.8608-5.4989-.0188-9.9415-4.4918-9.9227-9.9907.0188-5.4989,4.4918-9.9415,9.9907-9.9227h0c5.5108.0238,9.959,4.5104,9.9352,10.0213,0,.0104-.0001.0209-.0002.0313" />
      </g>
    </svg>
  );
};

export default ProfessionalIcon;
