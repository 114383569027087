import React from 'react';

import { Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import HeaderButton from '../HeaderButton/HeaderButton.component';

const ITEMS = [
  25,
  50,
  75,
  100,
  500,
  1000,
  5000,
];

type PerPageProps = {
  perPage: number;
  setPerPage: (value: number) => void;
  max?: number;
  border?: boolean;
  gray?: boolean;
}

const PerPage = ({
  perPage, setPerPage, max = 100, border = true, gray = false
}: PerPageProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value: number) => {
    handleClose();
    setPerPage(value);
  };

  const items = max ? ITEMS.filter(item => item <= max) : ITEMS;

  return (
    <div>
      <HeaderButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        borderSolid={border}
        title={`Show ${perPage} items`}
        EndIcon={KeyboardArrowDownIcon}
        color={gray ? 'lightgray' : null}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item) => (
          <MenuItem
            key={item}
            onClick={() => handleSelect(item)}
            value={item}>
            Show {item} items
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default PerPage;
