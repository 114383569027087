import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const NotificationTypeIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} fill={color || '#FFF'} id="uuid-e76b36ad-aff4-4038-8db6-98f149aceb98" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path d="m47.9276,19.5719c-.023.4611-.0223.8692-.0675,1.2721-.1056.9408-.9178,1.6967-1.9065,1.8008-.79.0832-1.7335-.5272-2.0101-1.3454-.1126-.3332-.1806-.6968-.1877-1.0482-.1104-5.4763-1.9711-10.2607-5.6227-14.3455-.3573-.3997-.7361-.7893-1.0334-1.2318-.588-.8751-.4475-1.874.3033-2.5572.7471-.6799,1.7755-.7401,2.5886-.1194.321.2451.6144.5341.8879.8329,3.6106,3.9431,5.8962,8.5316,6.6893,13.8381.1466.9806.2445,1.9686.3588,2.9038Z" />
      <path d="m2.0822,19.7532c.3311-6.0043,2.263-11.2515,6.0355-15.7841.5196-.6243,1.0718-1.2265,1.6553-1.7909.5755-.5566,1.2786-.766,2.0655-.4731.7857.2924,1.3.8748,1.3502,1.6932.0283.461-.1542.997-.4041,1.3978-.3397.5449-.838.99-1.2623,1.483-2.8457,3.3063-4.5701,7.1157-5.0941,11.4535-.1052.8705-.1534,1.7499-.1867,2.6268-.0478,1.2561-.9121,2.2567-2.0225,2.2906-1.115.034-2.0137-.8461-2.1326-2.1087-.0288-.3062-.0043-.6175-.0043-.7881Z" />
      <path d="m17.4271,43.7995h15.1075c-.3386,2.482-2.8537,6.0366-7.3518,6.1959-3.7621.1333-7.0658-2.6501-7.7557-6.1959Z" />
      <path d="m45.6996,37.2751c-.2259-.9895-.8614-1.6897-1.5729-2.354-3.1423-2.934-4.6302-6.5599-4.5617-10.856.0321-2.0191.0614-4.0439-.0474-6.0585-.2332-4.3149-2.1254-7.8467-5.4114-10.5931-1.8604-1.555-4.0533-2.5167-6.4536-2.9443-.4717-.084-.6569-.2538-.6047-.7621.0562-.5471.02-1.1074-.016-1.6594C26.9555.8723,26.1031.0252,25.0045.0005c-1.0578-.0239-1.9651.8519-2.0503,2.0234-.0412.5673-.0464,1.1425-.0019,1.7089.037.4706-.1209.6445-.5848.7297-6.7411,1.2369-11.7647,7.1107-11.9262,13.96-.0491,2.0841-.0013,4.1703-.025,6.2553-.0385,3.3807-1.1273,6.3859-3.3233,8.9621-.6215.7291-1.3636,1.354-2.0022,2.0699-1.1639,1.305-1.1787,3.2136-.0814,4.5728.86,1.0652,2.0211,1.4197,3.3321,1.4199,5.5997.0009,11.1994.0003,16.7992.0003,4.7369.0002,9.4738.0005,14.2109,0,.9115,0,1.824.0212,2.7346-.0085,2.4189-.0787,4.1242-2.1814,3.6133-4.4192Zm-9.7076-15.8017l-.3908.5076c-1.4351,1.8628-2.8695,3.7241-4.3315,5.5685-.8446.9974-2.1047,1.528-3.3793,1.4351-.9168-.0146-1.8383-.367-2.56-.9882-1.0474-.8254-2.1224-1.6709-3.1958-2.5124-.0177-.0138-.0338-.0269-.0491-.0384-.0108.0138-.0223.0284-.0346.0438l-4.3906,5.6806c-.3809.6128-1.0888.9921-1.8505.9921-.1421,0-.2856-.0131-.43-.0407-.9268-.1052-1.6931-.8715-1.8068-1.8544-.0607-.5889.0799-1.1779.3939-1.6693,1.5595-2.0932,3.1421-4.1487,4.7362-6.1559.0323-.043.076-.0975.1221-.1513,1.4674-1.7599,4.1034-1.9972,5.8695-.5229.6834.5198,1.3829,1.0742,2.0824,1.6279l1.1226.8861c.0384.0299.0722.0561.1021.0783.023-.0284.0491-.0614.0776-.0991,1.3852-1.7991,2.7773-3.5951,4.1756-5.3873.2672-.4154.668-.7348,1.1341-.9022h.0008c.5674-.2043,1.1779-.172,1.7215.0845.5436.258.9544.7118,1.1572,1.2777.3025.6673.205,1.5088-.2764,2.14Z" />
    </svg>
  );
};

export default NotificationTypeIcon;
