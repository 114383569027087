import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const EnabledIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} id="uuid-22560dfe-1c29-4e66-a252-4e74cf05b413" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill={color || 'rgba(0, 0, 0, 0.6)'}>
      <path id="uuid-c29df7ec-c1d4-4272-888b-c0a1c25f29fd" data-name="Path 6260" d="m24.681,49.9639C10.9117,49.8248-.1377,38.55.0013,24.7808c.001-.0957.0025-.1914.0046-.287C.1627,10.8734,11.2979-.0592,24.9189.0342c13.7883-.0634,25.0174,11.0628,25.0808,24.8511.0634,13.7883-11.0628,25.0174-24.8511,25.0808-.1568.0007-.3137,0-.4705-.0023m16.1711-25.193c.0135-2.4748-.5856-4.9145-1.7439-7.1016-.7319-1.4631-1.7046-2.7926-2.8776-3.9329-.8733-.8993-2.3077-.928-3.2163-.0644-.9189.8241-.9978,2.2363-.1764,3.1575.1959.2519.4115.4899.6186.7334,2.5421,2.8117,3.4439,6.7407,2.3821,10.3795-1.6056,5.9894-7.7626,9.5431-13.752,7.9374-.6236-.1672-1.2316-.3878-1.8172-.6595-4.1918-1.8192-6.8272-6.0355-6.6258-10.6006.0209-2.5509.9702-5.0068,2.6705-6.9085.2995-.3527.5934-.7054.879-1.0693.3998-.5149.5326-1.1885.3583-1.8167-.1674-.8206-.7895-1.4731-1.6012-1.6795-.8735-.2756-1.8269.0042-2.4129.7082-3.5661,3.5775-5.1734,8.6635-4.3108,13.6406,1.3458,8.6836,9.4762,14.6321,18.1598,13.2863,7.8625-1.2185,13.6162-8.0577,13.4713-16.0127m-18.1501-8.9491h-.0252v4.0981c-.0066.4245.0309.8485.112,1.2652.2718,1.0015,1.237,1.6521,2.2674,1.5284,1.1009.0363,2.0356-.7998,2.1218-1.8979.0308-.4955.014-.9937.014-1.4892v-7.877c.0792-.7774-.2636-1.5377-.8985-1.993-.748-.4935-1.7059-.5386-2.4969-.1176-.7377.3359-1.1855,1.0984-1.1197,1.9063v4.5767"/>
    </svg>
  );
};

export default EnabledIcon;
