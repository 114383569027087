import React from 'react';
import { makeStyles } from '@mui/styles';

import {
  ZoneId,
  ZonedDateTime,
  DateTimeFormatter
} from '@js-joda/core';
import { Locale } from '@js-joda/locale_en-us';
import { TableContainer, BodyCell, TableBody, TableRow } from '../../../components/CustomTable';
import Modal from '../../../components/Modal/Modal.component';
import HeaderButton from '../../../components/HeaderButton/HeaderButton.component';

const dateFormat = DateTimeFormatter.ofPattern('eee, MMM d, yyyy').withLocale(Locale.US);
const timeFormat = DateTimeFormatter.ofPattern('hh:mm a').withLocale(Locale.US);

const toLocalDate = (time = '', zone = '') => {
  try {
    return ZonedDateTime
      .parse(time)
      .withZoneSameInstant(ZoneId.of(zone))
      .format(dateFormat);
  } catch (e) {
    return 'invalid date';
  }
};

const toLocalTime = (time = '', zone = '') => {
  try {
    return ZonedDateTime
      .parse(time)
      .withZoneSameInstant(ZoneId.of(zone))
      .format(timeFormat);
  } catch (e) {
    return 'invalid date';
  }
};

const useStyles = makeStyles(() => ({
  table: {
    marginBottom: '10px',
    '& tbody tr td': {
      borderBottom: 'none'
    },
  },
  firstColumn: {
    width: 170
  },
  secondColumn: {
    width: 60,
  },
}));

export const ReschedulePopUp = ({
  openReskedPopup,
  closeReskedPopup,
  confirmResked,
  dragApt,
  dragData,
  timezone
}) => {
  const classes = useStyles();

  const curDate = dragApt && toLocalDate(dragApt.time, timezone);
  const newDate = dragData && toLocalDate(dragData.time, timezone);

  const curTime = dragApt && toLocalTime(dragApt.time, timezone);
  const newTime = dragData && toLocalTime(dragData.time, timezone);

  return (
    <Modal
      open={openReskedPopup}
      onClose={closeReskedPopup}
      size='xs'
      maxWidth={700}
      title={dragApt ? `Reschedule ${dragApt.client.firstName} ${dragApt.client.lastName}'s appointment?` : ''}
      className='sked-test-calendar-reschedule-drag-modal'
      buttons={[
        <HeaderButton
          title='Save'
          onClick={confirmResked}
          color="primary"
          className='sked-test-calendar-reschedule-drag-modal-button-save'
        />
      ]}
    >
      {dragApt && dragData.time && <div>
        <TableContainer className={classes.table} noHover>
          <TableBody>
            {curDate !== newDate && <TableRow>
              <BodyCell className={classes.firstColumn}>{curDate}</BodyCell>
              <BodyCell className={classes.secondColumn}>→</BodyCell>
              <BodyCell>{newDate}</BodyCell>
            </TableRow>}
            {curTime !== newTime && <TableRow>
              <BodyCell className={classes.firstColumn}>{curTime}</BodyCell>
              <BodyCell className={classes.secondColumn}>→</BodyCell>
              <BodyCell>{newTime}</BodyCell>
            </TableRow>}
          </TableBody>
        </TableContainer>
      </div>}
    </Modal>
  );
};
