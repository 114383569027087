import * as React from 'react';
import {
  Button, IconButton
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import TrashIcon from '@mui/icons-material/Delete';
import { ComplexWidgetFileUpload } from '../intake-types';
import { useIntakeContextAnswer } from '../answers.context';
import { DocumentUploadAnswer } from '../answer-types';
import { last, isEmpty, remove } from 'ramda';

interface FileUploadProps {
  id: number;
  data: ComplexWidgetFileUpload;
  required: boolean;
  isPrivate?: boolean;
}

const defaultAnswer: DocumentUploadAnswer = {
  id: 0,
  type: 'DocumentUpload',
  value: [],
};

export const FileUploadComponent = ({ id, data, isPrivate }: FileUploadProps) => {
  const [files, setFiles] = useIntakeContextAnswer<DocumentUploadAnswer>(id, defaultAnswer);
  const inputRef = React.useRef<HTMLInputElement>();
  const text = data.name || 'Select File to Upload';
  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fs = Array.from(e.target.files) || [];
    setFiles([...files, ...fs]);
  };
  const removeFile = (idx: number) => () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setFiles(remove<any>(idx, 1, files));
  };

  if (isPrivate) {
    return (
      <div style={{ paddingBottom: '20px' }}>
        {data.description}
        <br />
        <input
          ref={inputRef}
          accept="image/*,audio/*,video/*,.pdf,.docx,.doc,.vcf"
          style={{ display: 'none' }}
          id={`contained-button-file-${id}`}
          type="file"
          multiple
          onChange={handleUpload}
        />
        <label htmlFor={`contained-button-file-${id}`}>
          <Button
            variant="contained"
            color="primary"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            {text}
          </Button>
        </label>
        <br />
        {!isEmpty(files) &&
          files.map((file, idx) => {
            const name =
              file?.url ?
                <a href={file.url} target="_blank">
                  {decodeURIComponent(
                    decodeURIComponent(
                      decodeURI(
                        last(file.url.split('?')[0].split('/')).split(/_(.*)/s)[1])))}
                </a>
                :
                file?.name || '<failed-to-get-file-name>';
            return (
              <div id={file?.name || file?.url || String(idx)} key={idx}>
                {name}
                <IconButton onClick={removeFile(idx)}>
                  <TrashIcon fontSize="small" />
                </IconButton>
              </div>
            );
          })}
      </div>
    );
  }

  return (
    <div style={{ paddingBottom: '20px' }}>
      {data.name || 'Select File to Upload'}:
      {data.description}
      {files && !isEmpty(files) &&
        files.map(({ url }) => {
          return (
            <div id={url} key={url} style={{
              width: 'fit-content',
            }}>
              <a href={url} target="_blank">
                {decodeURIComponent(
                  decodeURIComponent(
                    decodeURI(
                      last(url.split('?')[0].split('/')).split(/_(.*)/s)[1])))}
              </a>
            </div>
          );
        })}
    </div>
  );
};
