import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
  TextField,
  IconButton,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  CircularProgress,
} from '@mui/material';
import {
  Undo as UndoIcon,
  Edit as EditIcon,
  EventBusy as CalCancelIcon,
} from '@mui/icons-material';
import { TimeSelect } from './time-select.jsx';

import {
  pipe, append, findIndex, remove, adjust,
  assoc, prop, uniqBy
} from 'ramda';

import { ZoneId, ZonedDateTime, LocalDate } from '@js-joda/core';
import { AppointmentTypeAutocomplete } from './AppointmentTypeSelect.component';

const useStyles = makeStyles(() => ({
  table: {
    width: '100%',
    fontSize: '16px'
  },
  mySubListHeader: {
    fontSize: '14px',
    fontWeight: 'bolder',
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.74)'
  },
}));

export function ExistingAppointments({
  existingApts,
  canceledApts,
  rescheduledApts,
  setCanceledApts,
  setRescheduledApts,
  types,
  interval,
  onlyChanges = false,
  timezone,
  selectDate,
  professionals
}) {
  const classes = useStyles();

  const [list, setList] = useState([]);

  const cancelApt = apt => {
    let date;
    let jodaDate;

    try {
      jodaDate = ZonedDateTime
        .parse(apt.time)
        .withZoneSameInstant(ZoneId.of(timezone));
      date = jodaDate.toLocalDate().toString();
    } catch (e) {
      jodaDate = ZonedDateTime
        .now()
        .withZoneSameInstant(ZoneId.of(timezone));
      date = jodaDate.toLocalDate().toString();
    }

    selectDate(date);
    setCanceledApts(pipe(
      append({ id: apt.id, reason: '' }),
      uniqBy(prop('id'))
    ));
  };

  const reskedApt = apt => {
    let date;
    let time;
    let jodaDate;
    try {
      jodaDate = ZonedDateTime
        .parse(apt.time)
        .withZoneSameInstant(ZoneId.of(timezone));
      date = jodaDate.toLocalDate().toString();
      time = jodaDate.toLocalTime().toString();
    } catch (e) {
      console.warn(e);
      jodaDate = ZonedDateTime
        .now()
        .withZoneSameInstant(ZoneId.of(timezone));
      date = jodaDate.toLocalDate().toString();
      time = '08:00';
    }
    const resked = {
      id: apt.id,
      time,
      date,
      typeId: apt.appointmentTypeId,
      clientId: apt.clientId,
      status: apt.status,
      bookingStatus: apt.bookingStatus,
      cancelReason: apt.cancelReason,
      source: 'Admin'
    };
    setRescheduledApts(pipe(
      append(resked),
      uniqBy(prop('id'))
    ));
    selectDate(date);
  };

  const unReskedApt = apt => {
    const idx = findIndex(({ id }) => id === apt.id, rescheduledApts);
    if (idx > -1) {
      setRescheduledApts(remove(idx, 1));
    }
  };

  const updateResked = (apt, field) => e => {
    let value = '';
    if (field === 'date') {
      if (!e || e instanceof Error) {
        return;
      }
      value = e.toString();
      selectDate(value);
    } else if (field === 'time') {
      value = e;
    } else {
      value = e.target.value;
    }
    const idx = findIndex(({ id }) => id === apt.id, rescheduledApts);
    setRescheduledApts(adjust(idx, assoc(field, value)));
  };

  const uncancelApt = apt => {
    const idx = findIndex(({ id }) => id === apt.id, canceledApts);
    if (idx > -1) {
      setCanceledApts(remove(idx, 1));
    }
  };

  const updateReason = apt => e => {
    const idx = findIndex(({ id }) => id === apt.id, canceledApts);
    setCanceledApts(adjust(idx, assoc('reason', e.target.value)));
  };

  useEffect(() => {
    const list = existingApts.data.map(apt => {
      const canceled = canceledApts.find(({ id }) => id === apt.id);
      const resked = rescheduledApts.find(({ id }) => id === apt.id);
      return {
        ...apt,
        isResked: !!resked,
        resked,
        isCanceled: !!canceled,
        cancelReason: prop('reason', canceled)
      };
    }).filter(({ isCanceled, isResked }) => {
      return !onlyChanges || isCanceled || isResked;
    });
    setList(list);
  }, [canceledApts, rescheduledApts, existingApts.data, onlyChanges]);

  return (
    <div>
      {existingApts.loading && <CircularProgress />}
      {existingApts.errorMessage && <div>{existingApts.errorMessage}</div>}
      {existingApts.data.length === 0 && !existingApts.loading && <div>No existing appointments</div>}
      {list.length === 0 && onlyChanges && <div>No changes to make to existing appointments.</div>}
      {list.length > 0 &&
        <TableContainer>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Date & Time</TableCell>
                <TableCell>Client</TableCell>
                <TableCell>Apt Type</TableCell>
                <TableCell padding="checkbox">&nbsp;</TableCell>
                <TableCell padding="checkbox">&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((apt, i) => (
                <TableRow key={apt.id}>
                  <TableCell style={{ textDecoration: apt.isCanceled ? 'line-through' : '' }}>
                    {!apt.isResked ?
                      apt.localDateTime :
                      <span>
                        <TextField
                          label="Choose Day"
                          value={LocalDate.parse(apt.resked.date)}
                          onChange={(event) => updateResked(apt, 'date')(event.target.value)}
                          type='date'
                          variant='standard'
                        />
                        <TimeSelect interval={interval} i={i} value={apt.resked.time} onChange={updateResked(apt, 'time')} />
                      </span>
                    }
                  </TableCell>
                  <TableCell style={{ textDecoration: apt.isCanceled ? 'line-through' : '' }}>{apt.client.firstName} {apt.client.lastName} </TableCell>
                  <TableCell>
                    {(!apt.isCanceled && !apt.isResked) &&
                      <span>{apt.appointmentType.name}</span>}
                    {apt.isResked &&
                      <AppointmentTypeAutocomplete
                        value={apt.resked.typeId}
                        onChange={updateResked(apt, 'typeId')}
                        types={types}
                        professionals={professionals}
                        id={`apt-resked-type-${i}`}
                      />
                    }
                    {apt.isCanceled &&
                      <TextField
                        label="Reason"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={apt.cancelReason}
                        onChange={updateReason(apt)}
                        placeholder='SKED Admin' />}
                  </TableCell>
                  <TableCell padding="checkbox">
                    {!apt.isCanceled && !apt.isResked && (
                      <IconButton aria-label="edit apt" onClick={() => reskedApt(apt)}>
                        <EditIcon />
                      </IconButton>
                    )}
                    {apt.isCanceled && (
                      <IconButton aria-label="edit apt" onClick={() => uncancelApt(apt)}>
                        <UndoIcon />
                      </IconButton>
                    )}
                    {apt.isResked && (
                      <IconButton aria-label="edit apt" onClick={() => unReskedApt(apt)}>
                        <UndoIcon />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell padding="checkbox">
                    {!apt.isCanceled && !apt.isResked && (
                      <IconButton aria-label="cancel apt" onClick={() => cancelApt(apt)}>
                        <CalCancelIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>}
    </div>
  );
}
