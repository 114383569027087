import React from 'react';
import Modal from '../../components/Modal/Modal.component';

type InstructionsProps = {
  open: boolean;
  onClose: () => void;
  message: string;
}

export default function Instructions({
  open,
  onClose,
  message,
}: InstructionsProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title='Instructions'
      className='sked-test-office-hours-instructions-modal'
    >
      <div>
        {message.split('\n').map((part, ind) => <div key={ind} style={{ marginBottom: '20px' }}>{part}</div>)}
      </div>
    </Modal>
  );
}
