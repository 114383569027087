import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    width: '241px',
    backgroundColor: '#CECECE',
    color: 'black',
    marginTop: '10px',
    marginBottom: '10px',
    cursor: 'pointer',
    border: '1px solid rgb(191, 191, 191)',
    borderRadius: '50px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  circle: {
    width: '30px',
    height: '30px',
    borderRadius: '100px',
    backgroundColor: '#008BCF',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  name: {
    margin: '5px 0px 5px 10px',
    width: '190px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  }
}));
