import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const SchedulerIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="14.87" height="13.685" viewBox="0 0 14.87 13.685">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_13294" data-name="Rectangle 13294" width="14.87" height="13.685" fill={color || '#FFF'}/>
        </clipPath>
      </defs>
      <g fill={color || '#FFF'} id="Group_17078" data-name="Group 17078" transform="translate(0 0)">
        <g fill={color || '#FFF'} id="Group_17077" data-name="Group 17077" transform="translate(0 0)" clip-path="url(#clip-path)">
          <path id="Path_9496" data-name="Path 9496" d="M14.473,24.267a1.088,1.088,0,0,1,.259-1.1l.007-.007.007-.007a1.084,1.084,0,0,1,.759-.31,1.121,1.121,0,0,1,.344.055l1.965.655.439.147V22.532a.592.592,0,0,0-.592-.592H9.368a.592.592,0,0,0-.592.592v3.554a.592.592,0,0,0,.592.593h5.909Z" transform="translate(-6.407 -16.017)" fill={color || '#FFF'}/>
          <path id="Path_9497" data-name="Path 9497" d="M9.625,12.926l-.3-.892-.062-.187H1.777a.592.592,0,0,1-.592-.592V4.738H13.031V8.081l1.08.36a1.082,1.082,0,0,1,.105.043V1.777A1.777,1.777,0,0,0,12.438,0H1.777A1.777,1.777,0,0,0,0,1.777v9.477a1.777,1.777,0,0,0,1.777,1.777H9.668c-.015-.035-.031-.069-.043-.105M6.515,1.777a.592.592,0,1,1-.592.592.592.592,0,0,1,.592-.592m-1.777,0a.592.592,0,1,1-.592.592.592.592,0,0,1,.592-.592m-1.777,0a.592.592,0,1,1-.592.592.592.592,0,0,1,.592-.592" transform="translate(0 0)" fill={color || '#FFF'} fill-rule="evenodd"/>
          <path id="Path_9498" data-name="Path 9498" d="M38.648,33.094l-1.167,1.167a.276.276,0,0,1-.194.08.273.273,0,0,1-.194-.08l-1.606-1.606-.779.779a.27.27,0,0,1-.194.08.3.3,0,0,1-.064-.006.278.278,0,0,1-.2-.182l-.3-.892L32.7,28.658a.276.276,0,0,1,.261-.363.294.294,0,0,1,.088.013l1.965.656,2.7.9a.273.273,0,0,1,.108.455l-.779.779,1.606,1.606a.274.274,0,0,1,0,.388" transform="translate(-23.859 -20.656)" fill={color || '#FFF'}/>
        </g>
      </g>
    </svg>
  );
};

export default SchedulerIcon;
