import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: 10,
    width: '100%',

    [theme.breakpoints.down('md')]: {
      maxWidth: 320,
    }
  },
  badge: {
    width: '7px',
    height: '7px',
    borderRadius: '10px',
    backgroundColor: 'red',
    position: 'absolute',
    top: 2,
    right: '-9px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '10px',
  },
  title: {
    backgroundColor: '#E1EDF7',
    color: '#141414cf',
    fontWeight: 'bold',
    fontSize: 13,
    padding: '5px 20px',
    marginBottom: 5,
  },
  item: {
    marginLeft: 10,
    marginRight: 10,
    padding: '6px 10px',
    borderRadius: 8,

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    }
  },
  onlyAdmin: {
    paddingTop: 4,
    paddingBottom: 4,

    '& > p': {
      marginBottom: 0
    }
  },
  itemTitle: {
    color: '#151517',
    fontWeight: 400,
    fontSize: 15,
    position: 'relative',
    width: 'fit-content',
  },
  description: {
    fontSize: 12,
    fontWeight: 500,
    color: '#888888'
  },
  switch: {
    border: '1px solid rgba(0, 0, 0, 0.2)',
    padding: '8px 14px',
    fontSize: 14,
    borderRadius: 4,
    marginTop: 10,
    width: 'fit-content',
    color: '#888888',
    textTransform: 'none',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    }
  }
}));
