import React, { useState, useEffect } from 'react';

import { Button, Menu, MenuItem, InputAdornment, TextField, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './headerSearch.styles';

type HeaderSearchProps = {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  options?: { value: string, label: string }[];
  selectedOption?: string;
  setOption?: (value: string) => void;
  timeOut?: number;
}

let searchTimeout: NodeJS.Timeout = null;

const HeaderSearch = ({ value, placeholder, onChange, options, selectedOption, setOption, timeOut }: HeaderSearchProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [localValue, setLocalValue] = useState(value);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnChange = (value: string) => {
    setLocalValue(value);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      onChange(value);
    }, timeOut || 0);
  };

  const handleReset = () => {
    setLocalValue('');
    onChange('');
  };

  const handleSelect = (value: string) => {
    setOption(value);
    handleClose();
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <div className={classes.root}>
      <TextField
        value={localValue}
        onChange={event => handleOnChange(event.target.value)}
        size='small'
        onKeyDown={event => {
          if (event.key === 'Enter') {
            onChange(localValue);
          }
        }}
        placeholder={placeholder}
        className={classes.input}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start" className={classes.startIcon}>
              <SearchIcon style={{ fontSize: 16 }} />
            </InputAdornment>
          ),
          endAdornment: localValue.length ? <InputAdornment position='end'>
            <IconButton className={classes.closeButton} onClick={handleReset}>
              <CloseIcon />
            </IconButton>
          </InputAdornment> : null,
        }}
      />
      {options?.length && (
        <Button
          endIcon={<KeyboardArrowDownIcon />}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          variant='outlined'
          className={classes.button}
          color="primary">
          {options.find(item => item.value === selectedOption)?.label}
        </Button>
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options?.map((item) => (
          <MenuItem
            key={item.value}
            onClick={() => handleSelect(item.value)}
            value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default HeaderSearch;
