import React from 'react';
import {
  Paper,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import Logo from '../../../images/logo-blue.png';
import SparkLogo from '../../../icons/SparkLogo.icon';
import { useStyles } from './style.js';

const useStepStyles = makeStyles(useStyles);

export const Step4 = ({ isSpark = false }) => {
  const classes = useStepStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.headerContainer}>
          {isSpark ? (
            <SparkLogo className={classes.sparkLogo} />
          ) : (
            <img height="30" src={Logo} />
          )}
        </div>
        <div className={classes.headerContainer}>
          <Typography align='center' variant='h4'>Registration Complete!</Typography>
        </div>
        <div className={classes.formControl}>
          <p>Thank you for registering for {isSpark ? 'Spark' : 'SKED'}. Our team will be reaching out to you soon with next steps.</p>
        </div>
      </Paper>
    </div>
  );
};
