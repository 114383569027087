import React, { ComponentProps } from 'react';

const StopIcon = ({ color, className, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg className={className} {...props} data-name="Group 16404" xmlns="http://www.w3.org/2000/svg" width="17.76" height="17.743" viewBox="0 0 17.76 17.743">
      <path id="Path_6695" data-name="Path 6695" d="M115.627,19.012c-1.095,0-2.19-.005-3.284,0a.827.827,0,0,1-.646-.254q-2.351-2.365-4.718-4.714a.725.725,0,0,1-.225-.553q.006-3.35,0-6.7a.736.736,0,0,1,.228-.555q2.386-2.378,4.77-4.76a.74.74,0,0,1,.565-.206q3.336.005,6.673,0a.7.7,0,0,1,.525.21c1.611,1.581,3.233,3.152,4.781,4.8a.748.748,0,0,1,.218.555q-.008,3.3,0,6.6a.9.9,0,0,1-.284.675c-1.557,1.571-3.11,3.145-4.7,4.685a.729.729,0,0,1-.551.225c-1.118-.008-2.236,0-3.354,0m1.448-6.8a2.019,2.019,0,0,0,2.085-2.063,2.071,2.071,0,0,0-4.14-.067,2.032,2.032,0,0,0,2.055,2.13m-7,.061a1.4,1.4,0,0,0,1.458-1.026,1.308,1.308,0,0,0-1-1.522c-.206-.073-.418-.128-.625-.2a.221.221,0,0,1-.18-.257.267.267,0,0,1,.251-.243.78.78,0,0,1,.611.188c.228.184.391.131.537-.1.069-.107.15-.2.216-.312a.262.262,0,0,0-.059-.368,1.83,1.83,0,0,0-1.978-.186,1.145,1.145,0,0,0-.564,1.142,1.105,1.105,0,0,0,.732,1.036c.249.089.506.156.755.246.149.054.343.117.307.314s-.224.28-.426.275a.591.591,0,0,1-.3-.053c-.426-.294-.693-.05-.974.242-.173.18-.178.3.036.432a2.29,2.29,0,0,0,1.2.386m9.8-2.166h0c0,.563.018,1.126-.007,1.688-.015.338.11.464.435.427a1.89,1.89,0,0,1,.276,0c.2.007.3-.083.289-.289s.015-.415,0-.62c-.032-.332.08-.508.436-.48a.913.913,0,0,0,.172-.012,1.343,1.343,0,0,0,1.206-1.43,1.321,1.321,0,0,0-1.293-1.307c-.39-.014-.782,0-1.173-.01-.257-.009-.344.1-.339.347.012.562,0,1.126,0,1.688m-6.985.489c0,.334,0,.667,0,1,.006.775-.068.6.65.619.254.007.344-.083.341-.34-.009-.77,0-1.541.006-2.312,0-.538,0-.549.55-.546.248,0,.371-.069.342-.333a2.326,2.326,0,0,1,0-.345c.008-.195-.089-.263-.274-.262-.748,0-1.5,0-2.245,0-.107,0-.2.013-.249.137-.184.47.017.8.511.794.29-.006.384.1.372.381-.018.4,0,.805,0,1.208" transform="translate(-106.754 -1.273)" fill={color || '#f08c73'}/>
      <path id="Path_6696" data-name="Path 6696" d="M141.961,30.129a1.2,1.2,0,0,1-1.154,1.107A1.121,1.121,0,0,1,140.835,29a1.177,1.177,0,0,1,1.126,1.133" transform="translate(-130.508 -21.243)" fill={color || '#f08c73'}/>
      <path id="Path_6697" data-name="Path 6697" d="M157.223,29.411c.01-.169-.026-.415.271-.39.237.02.5.086.54.386.032.227-.266.457-.556.454-.214,0-.258-.074-.255-.45" transform="translate(-143.109 -21.26)" fill={color || '#f08c73'}/>
    </svg>
  );
};

export default StopIcon;
