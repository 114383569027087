import { makeStyles } from '@mui/styles';

export const style = {
  base: {
    color: '#282E38',
    fontFamily: 'Poppins, "Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#afafaf'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
};

export const useStyles = makeStyles(() => ({
  cardContainer: {
    borderRadius: 10,
    padding: '8px 14px',
    border: '1px solid #c4c4c4',
    minHeight: 19,
  },
}));
