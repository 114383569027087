import * as at from '../../../../actionTypes';
import { remoteAction } from '../../../../services/actionService.js';
import { getTypesApi } from '../../../Messages/routes/MessagesNew/messages-new.actions.js';
import api from '../../../../services/api.js';
import * as R from 'ramda';
import { convertEmail } from '../../../../services/utilities.js';
import { getCampaign } from '../../../ClientLists/clientlists.actions';

export const singularUnits = [
  { name: 'Week', id: 1 },
  { name: 'Day', id: 2 },
  { name: 'Hour', id: 3 },
  { name: 'Minute', id: 4 },
];
export const pluralUnits = [
  { name: 'Weeks', id: 1 },
  { name: 'Days', id: 2 },
  { name: 'Hours', id: 3 },
  { name: 'Minutes', id: 4 },
];

export const messagesPatch = (prop, value) => (dispatch) =>
  dispatch({
    type: at.RECURRING_EDIT_PATCH,
    data: {
      [prop]: value,
    }
  });

export const messageMassPatch = (data) => (dispatch) =>
  dispatch({
    type: at.RECURRING_EDIT_PATCH,
    data,
  });

export const getTypes = () => remoteAction({
  type: at.RECURRING_EDIT_REMOTE_GET_TYPES,
  action: () => getTypesApi(),
});

export const addType = ({ type }) => (dispatch) =>
  dispatch({
    type: at.RECURRING_EDIT_ADD_TYPE,
    data: {
      type
    }
  });

export const addAllTypes = ({ pros, type }) => (dispatch) => {
  if (type.name === 'All') {
    return dispatch({
      type: at.RECURRING_EDIT_ADD_TYPES,
      data: {
        selectedTypes: R.pipe(R.map((pro) => R.tail(pro.types)), R.flatten)(R.tail(pros)),
        typeStatus: 'All',
      }
    });
  }
  const selectedTypes = R.map(
    ({ types }) =>
      R.filter(
        (typ) =>
          typ.name === type.name)(types))(R.tail(pros));
  return dispatch({
    type: at.RECURRING_EDIT_ADD_TYPES,
    data: {
      selectedTypes: R.flatten(selectedTypes),
      typeStatus: 'Some',
    }
  });
};

export const selectPro = (pro) => (dispatch) =>
  dispatch({
    type: at.RECURRING_EDIT_PATCH,
    data: {
      selectedPro: pro,
    }
  });

export const removeType = ({ index }) => (dispatch) => {
  return dispatch({
    type: at.RECURRING_EDIT_REMOVE_TYPE,
    data: {
      index
    }
  });
};

export const clearSelected = (prop) => (dispatch) =>
  dispatch({
    type: at.RECURRING_EDIT_PATCH,
    data: {
      [prop]: []
    }
  });

export const getClients = (data) => {
  if (data.query) {
    data.query = {
      ...data.query,
      sortBy: [{
        direction: 'Asc',
        field: 'LastName'
      }, {
        direction: 'Asc',
        field: 'FirstName'
      }],
      isLead: false
    };
  }
  return remoteAction({
    type: at.RECURRING_EDIT_REMOTE_GET_CLIENTS,
    action: () => api.post('client/query', data)
  });
};

export const addClient = (client) => (dispatch) =>
  dispatch({
    type: at.RECURRING_EDIT_ADD_CLIENT,
    data: {
      client,
    }
  });

export const removeClient = ({ index }) => (dispatch) =>
  dispatch({
    type: at.RECURRING_EDIT_REMOVE_CLIENT,
    data: {
      index
    }
  });

const removeHtmlIfPlainEmail = message => {
  const isPlainEmail = !!R.pathOr(false, ['email', 'body', 'PlainText'], message);
  if (isPlainEmail) {
    const emailBodyPath = R.lensPath(['email', 'body']);
    return R.over(emailBodyPath, R.omit(['HTML']), message);
  }
  return message;
};

const enableMessage = message => {
  return R.assoc('isEnabled', true, message);
};

const disableMessage = message => {
  return R.assoc('isEnabled', false, message);
};

const makeTemplate = message => {
  return R.assoc('isTemplate', true, message);
};

export const sendMessage = ({ message, id, isSpark = false }) => {
  const newMessage = R.pipe(enableMessage, removeHtmlIfPlainEmail)(message);
  return remoteAction({
    type: at.RECURRING_EDIT_SEND,
    action: () => id === '0' ?
      api.post('message', newMessage).then((msg) => {
        if (isSpark && R.has('Automation', message.messageType)) {
          api.post('paths', {
            path: `spark.0.msgs.msg_${msg.id}`.split('.'),
            resource: { Message: msg.id }
          });
        }
        return msg;
      }) :
      api.put(`message/${id}`, newMessage)
  });
};

export const saveMessage = (message, id, isSpark = false) => {
  const newMessage = R.pipe(disableMessage, removeHtmlIfPlainEmail)(message);
  return remoteAction({
    type: at.RECURRING_EDIT_SEND,
    action: () => id === '0' ?
      api.post('message', newMessage).then((msg) => {
        console.log(isSpark, message, message.messageType);
        if (isSpark && R.has('Automation', message.messageType)) {
          api.post('paths', {
            path: `spark.0.msgs.msg_${msg.id}`.split('.'),
            resource: { Message: msg.id }
          });
        }
        return msg;
      }) :
      api.put(`message/${id}`, newMessage)
  });
};

export const saveTemplate = (message, id, isTemplate) => {
  const newMessage = R.pipe(
    removeHtmlIfPlainEmail,
    disableMessage,
    makeTemplate
  )(message);

  return remoteAction({
    type: at.RECURRING_EDIT_SEND,
    action: () => id === '0' || !isTemplate ?
      api.post('message', typeof newMessage.campaignId === 'object' ? R.dissoc('campaignId', newMessage) : newMessage) :
      api.put(`message/${id}`, newMessage).catch(() => {
        return api.post('message', newMessage);
      })
  });
};

export const cancelMessage = () => remoteAction({
  type: at.RECURRING_EDIT_SEND,
  action: () => Promise.resolve()
});

export const reset = () => (dispatch) =>
  dispatch({
    type: at.RECURRING_EDIT_RESET,
  });

const getUnit = (time) => R.cond([
  [R.has('Minute'), R.always([3, R.prop('Minute')(time)])],
  [R.has('Hour'), R.always([2, R.prop('Hour')(time)])],
  [R.has('Day'), R.always([1, R.prop('Day')(time)])],
  [R.has('Week'), R.always([0, R.prop('Week')(time)])],
  [R.T, R.always(null)],
])(time);

const getAttachments = (attachmentIds) => {
  return Promise.all(R.map((aIds) => {
    return api.post('attachment', { attachmentIds: aIds });
  })(attachmentIds));
};

const getAutomations = (msgId) => {
  return api.post('automations/query', {
    page: 1,
    perPage: 100,
    query: {
      action: {
        msgId,
      },
    }
  }).then(({ data }) => data);
};

export const loadingMessage = (message, type = 'edit') => {
  const {
    appointmentTypeIds,
    searchParam,
    time,
    days,
    sendAt,
    appointmentTypes,
    delay,
    source,
    changeType,
    includeInactive,
  } = R.cond([
    [
      R.hasPath(['messageType', 'Recurring']),
      R.always(R.pathOr({}, ['messageType', 'Recurring'])(message))
    ],
    [
      R.hasPath(['messageType', 'Recall']),
      R.always(R.pathOr({}, ['messageType', 'Recall'])(message))
    ],
    [
      R.hasPath(['messageType', 'Birthday']),
      R.always(R.pathOr({}, ['messageType', 'Birthday'])(message))
    ],
    [
      R.hasPath(['messageType', 'ApptChange']),
      R.always(R.pathOr({}, ['messageType', 'ApptChange'])(message))
    ],
    [
      R.T,
      R.always({})
    ],
  ])(message);
  const search = getUnit(time);
  return Promise.all([
    getTypesApi(),
    getAttachments([
      R.pathOr([], ['email', 'attachmentIds'], message),
      R.pathOr([], ['sms', 'attachmentIds'], message),
    ]),
    getCampaign(message.campaignId),
    getAutomations(message.id)
  ]).then(([{ types, professionals }, [emailOfficeAttach, smsOfficeAttach], list, automations]) => {
    const emailAttachments = R.propOr([], 'officeAttachments')(emailOfficeAttach);
    const smsAttachments = R.propOr([], 'officeAttachments')(smsOfficeAttach);
    const subject = R.cond([
      [R.prop('email'), () => message.email.subject],
      [R.prop('push'), () => message.push.subject],
      [R.prop('sms'), () => ''],
      [R.T, () => R.propOr('', 'subject')(message)],
    ])(message);
    const body = R.cond([
      [R.prop('email'), () => R.ifElse(
        R.is(Object),
        R.propOr('', 'PlainText'),
        R.identity,
      )(message.email.body)],
      [R.prop('push'), () => message.push.body],
      [R.prop('sms'), () => message.sms.body],
      [R.T, () => R.propOr('', 'body')(message)],
    ])(message);
    const def = type === 'edit' ? {
      subject: '',
      body: '',
    } : false;
    const smsDef = R.ifElse(
      R.identity,
      R.dissoc('subject'),
      R.identity
    )(def);
    const maybeEmail = R.propOr(def, 'email')(message);
    const maybeSms = R.propOr(smsDef, 'sms')(message);
    const email = R.ifElse(
      R.identity,
      R.always({
        subject: maybeEmail.subject,
        body: R.pathOr('', ['body', 'PlainText'])(maybeEmail),
        html: R.pathOr(null, ['body', 'HTML', 'body'])(maybeEmail),
        shouldFrame: R.pathOr(true, ['body', 'HTML', 'shouldFrame'])(maybeEmail),
        attachments: emailAttachments,
        addCalendarEvent: R.propOr(null, 'addCalendarEvent', maybeEmail),
      }),
      R.always(R.merge(def, { shouldFrame: true }))
    )(maybeEmail);
    const sms = R.ifElse(
      R.identity,
      R.assoc('attachments', smsAttachments),
      R.always(smsDef)
    )(maybeSms);
    const isEnabled = R.propOr(false, 'isEnabled', message);
    const campaign = list ? {
      id: list.campaign.id,
      listName: list.campaign.displayName,
      listId: list.id,
    } : undefined;
    if (R.has('All', appointmentTypeIds)) {
      return {
        messageName: message.name,
        body,
        subject,
        isEmail: Boolean(message.email),
        isPush: Boolean(message.push),
        isSMS: Boolean(message.sms),
        email,
        sms,
        push: R.propOr(def, 'push')(message),
        // scheduledFor: message.scheduledFor,
        selectedTypes: R.map(R.prop('id'))(types),
        beforeAfter: searchParam,
        value: search[1],
        unit: search[1] > 1 ? pluralUnits[search[0]] : singularUnits[search[0]],
        isEnabled,
        types,
        professionals,
        isTemplate: message.isTemplate,
        templateId: undefined,
        campaign,
        automations,
        folder: message.folder,
      };
    }
    let unit = { name: 'Day', id: 2 };
    if (search) {
      unit = search[1] > 1 ? pluralUnits[search[0]] : singularUnits[search[0]];
    }
    /*
      Recurring messages have `appointmentTypeIds` set to `{Some: [...]}` or `{All: []}`,
      whereas Recall messages have `appointmentTypes` set to a list of appointment type ids
      and Rapid Message (ApptChange) have `appointmentTypeIds` set to a list of appointment
      type ids... So we need to check everything...
     */
    const selectedTypes = appointmentTypes ?
      appointmentTypes :
      R.ifElse(
        R.has('Some'),
        R.propOr([], 'Some'),
        R.identity
      )(appointmentTypeIds);
    return {
      messageName: message.name,
      body,
      subject,
      isEmail: Boolean(message.email),
      isPush: Boolean(message.push),
      isSMS: Boolean(message.sms),
      email,
      sms,
      push: R.propOr(def, 'push')(message),
      scheduledFor: message.scheduledFor,
      selectedTypes: selectedTypes ? selectedTypes : [],
      beforeAfter: searchParam,
      value: search ? search[1] : days,
      unit,
      isEnabled,
      types,
      professionals,
      sendAt,
      delay,
      source, //: R.map((v) => R.find(R.propEq('value', v))(sourceList))(source),
      changeType, //: R.map((v) => R.find(R.propEq('value', v))(changeTypesList))(changeType),
      isTemplate: message.isTemplate,
      includeInactive,
      templateId: undefined,
      campaign,
      automations,
      folder: message.folder,
    };
  });
};

export const loadMessage = (id, timezone, type = 'edit') => (dispatch, getStore) => {
  dispatch(
    remoteAction({
      type: at.RECURRING_EDIT_REMOTE_GET,
      action: () => api.get(`message/${id}`).then((message) => {
        const features = R.path(['login', 'features'], getStore());
        const m = convertEmail(message, features);
        return loadingMessage(m, type);
      })
    }));
};


export const addCampaign = (id) => remoteAction({
  type: at.RECURRING_EDIT_ADD_CAMPAIGN,
  action: () => api.get(`list/client/campaign/${id}`).then((campaign) => {
    return ({
      campaign,
    });
  }),
});
