import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    background: '#f7f6f6',
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
  },
  paper: {
    borderRadius: 12,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
}));
