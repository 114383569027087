import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    width: 264,
    marginBottom: 3,
    marginTop: 4,

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  root: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 10,
    paddingBottom: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
  },
  size18: {
    fontSize: 16,
    marginBottom: 3,
    marginTop: 4,

    [theme.breakpoints.down('md')]: {
      fontSize: 14,
    },
  },
  icon: {
    color: 'black',
    cursor: 'pointer',
    marginRight: 10,
    border: '1px solid rgba(0, 139, 207, 0.5)',
    padding: '0 2px',
    borderRadius: 4,
    transition: '0.2s',

    '&:hover': {
      borderColor: 'rgb(0, 139, 207)',
    },

    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
  noMargin: {
    margin: 0,
  },
  closeIcon: {
    color: 'black',
    cursor: 'pointer',

    [theme.breakpoints.down('md')]: {
      fontSize: 22,
    },
  },
  arrowContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
}));
