import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';

import {
  Paper,
  TextField as TextFieldSmall,
  Button,
  Typography
} from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles';

import { bindActionCreators } from 'redux';
import { PopupTemplate, popup } from '../../services/Popup.js';
import queryString from 'query-string';
import * as R from 'ramda';

import * as forgotPasswordActions from './forgot-password.actions';

const TextField = withStyles({
  root: {
    width: '100%',
  },
})(TextFieldSmall);

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    maxWidth: '520px',
    margin: '0px auto',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%'
  },
  paper: {
    width: '100%',
    padding: '40px'
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  formControl: {
    marginTop: 8,
    marginBottom: 8
  },
  buttonLabel: {
    textTransform: 'none',
    fontWeight: 'normal'
  }
}));

const PasswordReset = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const actions = bindActionCreators(forgotPasswordActions, dispatch);
  const [state, setState] = useState({
    password: '',
    password2: '',
    redirect: false,
  });

  const passwordChange = (prop, event) => {
    setState({ ...state, [prop]: event.target.value });
  };

  const handleSubmit = ({ event, token }) => {
    event.preventDefault();
    if (state.password !== state.password2) {
      popup('Warning!', 'Your passwords don\'t match!');
      return null;
    } else if (state.password.length < 8) {
      popup('Warning!', 'Your password must be longer than 8 characters!');
      return null;
    }
    actions.resetPassword({ token, password: state.password }).then((result) => {
      if (result) {
        setTimeout(() => {
          setState({ password: '', password2: '', redirect: true });
        }, 1000);
      }
    });
    this.setState({ password: '', password2: '' });
    return null;
  };

  if (state.redirect) {
    return <Redirect to='login' />;
  }

  return (
    <div className={classes.root}>
      <PopupTemplate />
      <Paper className={classes.paper}>
        <form onSubmit={(e) => handleSubmit({
          event: e,
          token: R.propOr('', 'token')(queryString.parse(props.location.search)),
        })}>
          <div className={classes.logoContainer}>
            <Typography align='center' variant='h4'>Reset Your Password</Typography>
          </div>
          <div className={classes.formControl}>
            <TextField
              autoFocus
              fullWidth
              id="reset-password"
              label="Password"
              type="password"
              variant='standard'
              onChange={(e) => passwordChange('password', e)}
              value={state.password} />
          </div>
          <div className={classes.formControl}>
            <TextField
              autoFocus
              fullWidth
              id="reset-password-check"
              label="Re-type Password"
              type="password"
              variant='standard'
              onChange={(e) => passwordChange('password2', e)}
              value={state.password2} />
          </div>
          <Button
            variant="contained"
            type="submit"
            classes={{ label: classes.buttonLabel }}
            disabled={(!state.password && !state.password2) ||
                state.password !== state.password2}
            fullWidth
            color="primary">
            {state.loading ? 'Loading...' : 'Reset Password'}
          </Button>
          <Button
            type="button"
            variant="text"
            component={NavLink}
            fullWidth
            to="/login"
            classes={{ label: classes.buttonLabel }}>
              Login
          </Button>
        </form>
      </Paper>
    </div>
  );
};

export default PasswordReset;
