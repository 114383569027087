import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import {
  Snackbar, IconButton, MenuItem, TableHead, TableBody,
  CircularProgress, FormControlLabel, Checkbox, Menu, Divider,
  DialogContentText, Grid,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import * as actions from './professionals.actions.jsx';
import * as R from 'ramda';
import DeleteIcon from '@mui/icons-material/Delete';
import Info from '@mui/icons-material/Info';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EditType from './components/type-edit.component.jsx';
import AddProfessional from './components/add-professional.component.jsx';
import EditProfessional from './components/edit-professional.component.jsx';
import { AddTypes } from './components/add-types.component.jsx';
import { PopupTemplate } from '../../services/Popup.js';
import './professionals.css';
import '../OfficeHours/NormalOfficeHours/officeHours.css';
import { useTitle } from '../../services/useTitle.js';
import { getLocations } from '../Settings/routes/Business/business.actions.js';
import { getAutomations, saveAutomation } from '../Automations/automations.actions';
import { usePromise } from '../../services/promise.hook';
import Header from '../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import SelectProfessional from '../../components/SelectProfessional/SelectProfessional.component';
import { TableContainer, TableRow, HeaderCell, BodyCell } from '../../components/CustomTable';
import Modal from '../../components/Modal/Modal.component';
import { setQueryParam } from '../../services/utilities.js';

const biggieApptConfirmText = 'When a patient replies ‘C’ via SMS their appointment will be marked as confirmed in SKED. You’ll need to include a phrase in your appointment reminders such as “Reply ‘C’ to confirm your appointment”, so patients know how to confirm their appointment.';

const FormControlLabelWithoutMargin = withStyles({
  root: {
    marginRight: 'unset',
  },
})(FormControlLabel);

const LittleSmallerInfo = withStyles({
  fontSizeSmall: {
    fontSize: '2rem',
  },
})(Info);

const styles = {
  popup: {
    position: 'fixed',
    left: '60%',
    zIndex: '2',
    top: '7.5%'
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
  },
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  },
  margin: {
    margin: '0px'
  },
};

const InformationalDialog = ({
  open,
  onClose,
  message,
}) => (
  <Modal
    open={open}
    onClose={onClose}
    title='Information'
    className='sked-test-appt-types-information-modal'
  >
    <div>
      <DialogContentText>
        {message}
      </DialogContentText>
    </div>
  </Modal>
);

const useStyles = makeStyles(() => styles);

const TypeItem = ({
  classes,
  type,
  slotWidth,
  tableType,
  selectToggleApptType,
  professionalId,
  patch,
  admin,
  isLoading
}) => {
  const colorBackground = R.prop('color')(type) !== null ?
    'hsl(' + type.color.hue + ',' + type.color.saturation + '%,' + type.color.lightness + '%)' :
    'hsl(0,0,0)';
  const editType = () => patch({ state: 'EDIT_TYPE', selectedType: type });
  const deleteType = () => {
    patch({ state: undefined, selectedType: {} });
    admin.disable(type.id);
  };
  return (
    <TableRow
      style={{ cursor: 'pointer' }}
      hasSelect={admin.isAdmin}
      disabled={isLoading}
      checked={type.isSelected}
      setChecked={() => selectToggleApptType(type.id)}
    >
      <BodyCell
        fixed
        onClick={editType}
      >
        {type.internalName}
      </BodyCell>
      <BodyCell
        onClick={editType}
        className="td-name">
        {type.name}
      </BodyCell>
      {professionalId === -1 && <BodyCell
        onClick={editType}
        className="td-name">
        {type.professional.firstName} {type.professional.lastName}
      </BodyCell>}
      <BodyCell
        onClick={editType}
        style={{ textAlign: 'center' }} className="td-notifications-id">
        {type.duration}
      </BodyCell>
      { /* !isApptTySked */ false && R.cond([
        [R.equals('justPie'), () => (
          <BodyCell
            onClick={editType}
            style={{ textAlign: 'center' }} className="td-group">
            {type.appointmentWidth + ' / ' + slotWidth}
          </BodyCell>
        )],
        [R.equals('justSim'), () => (
          <BodyCell
            onClick={editType}
            style={{ textAlign: 'center' }} className="td-group">
            {type.perBlock}
          </BodyCell>
        )],
        [R.T, () => {
          if (type.useMaxPerBlock) {
            return [
              <BodyCell
                onClick={editType}
                className="td-group">

              </BodyCell>,
              <BodyCell
                onClick={editType}
                style={{ textAlign: 'center' }} className="td-group">
                {type.perBlock}
              </BodyCell>
            ];
          }
          return [
            <BodyCell
              onClick={editType}
              style={{ textAlign: 'center' }} className="td-group">
              {type.appointmentWidth + ' / ' + slotWidth}
            </BodyCell>,
            <BodyCell className="td-group">
            </BodyCell>
          ];
        }]
      ])(tableType)}
      <BodyCell
        onClick={editType}
        style={{ textAlign: 'center' }} className="td-notifications-id">
        {type.notAllowedMinutesBeforeClosing !== null ? type.notAllowedMinutesBeforeClosing : ''}
      </BodyCell>
      <BodyCell
        onClick={editType}>
        <input
          type='checkbox'
          style={{ marginLeft: '20%' }}
          checked={!!type.canCancel}
          disabled={true} />
      </BodyCell>
      <BodyCell
        onClick={editType}>
        <input
          type='checkbox'
          style={{ marginLeft: '20%' }}
          checked={!!type.canChange}
          disabled={true} />
      </BodyCell>
      <BodyCell
        onClick={editType}>
        <input
          type='checkbox'
          style={{ marginLeft: '20%' }}
          checked={!!type.canSee}
          disabled={true} />
      </BodyCell>
      <BodyCell
        onClick={editType}>
        <input
          type='checkbox'
          style={{ marginLeft: '20%' }}
          checked={!!type.partOfCarePlan}
          disabled={true} />
      </BodyCell>
      <BodyCell
        onClick={editType}>
        <div style={styles.swatch} >
          <div style={{ width: '36px', height: '14px', borderRadius: '2px', background: colorBackground }} />
        </div>
      </BodyCell>
      {admin.isAdmin && <BodyCell>
        <IconButton className={classes.margin} onClick={deleteType} aria-label="delete" >
          <DeleteIcon />
        </IconButton>
      </BodyCell>}
    </TableRow>
  );
};

const betterName = (pie, types) => {
  const type = R.pipe(
    R.find(R.propEq('useMaxPerBlock', true)),
    R.propOr(false, 'useMaxPerBlock')
  )(types);
  if (pie && type) {
    return 'both';
  } else if (pie) {
    return 'justPie';
  } else {
    return 'justSim';
  }
};

const HeaderWithLabels = ({ tableType, isLoading, selectedTypes, types, isAdmin, selectToggleApptTypeAll, professionalId }) => (
  <TableRow
    hasSelect={isAdmin}
    checked={selectedTypes.length > 0}
    setChecked={() => selectToggleApptTypeAll()}
    disabled={isLoading}
    indeterminate={selectedTypes.length > 0 && selectedTypes.length !== types.length}
    noSelectHover
  >
    <HeaderCell fixed>
      Internal Name
    </HeaderCell>
    <HeaderCell className="td-name">
      Display Name
    </HeaderCell>
    {Number(professionalId) === -1 && <HeaderCell className="td-name">
      Professional
    </HeaderCell>}
    <HeaderCell style={{ textAlign: 'center' }} className="td-notifications">
      Duration
    </HeaderCell>
    {/* !isApptTySked */ false && R.cond([
      [R.equals('justPie'), () => [
        <HeaderCell style={{ textAlign: 'center' }} className="td-platinum-id">
          Appointment WidTableCell
        </HeaderCell>
      ]],
      [R.equals('justSim'), () => (
        <HeaderCell style={{ textAlign: 'center' }} className="td-platinum-id">
          Independent WidTableCell
        </HeaderCell>
      )],
      [R.T, () => [
        <HeaderCell style={{ textAlign: 'center' }} className="td-platinum-id">
          Appointment WidTableCell
        </HeaderCell>,
        <HeaderCell style={{ textAlign: 'center' }} className="td-platinum-id">
          Independent WidTableCell
        </HeaderCell>
      ]]
    ])(tableType)}
    <HeaderCell style={{ textAlign: 'center' }} className="td-notifications">
      Closing Limit
    </HeaderCell>
    <HeaderCell className="td-platinum-id">
      Can Cancel
    </HeaderCell>
    <HeaderCell className="td-platinum-id">
      Can Reschedule
    </HeaderCell>
    <HeaderCell className="td-platinum-id">
      Can See
    </HeaderCell>
    <HeaderCell className="td-platinum-id">
      On Care Plan
    </HeaderCell>
    <HeaderCell className="td-platinum-id">
      Color
    </HeaderCell>
    {isAdmin &&
      <HeaderCell className="td-platinum-id">
        Delete
      </HeaderCell>}
  </TableRow>
);

const TypesTable = ({
  types,
  ehrSystem,
  edit,
  colorActions,
  pie,
  slotWidth,
  tableType,
  patch,
  isApptTySked,
  admin,
  bulkUpdate,
  selectToggleApptType,
  selectToggleApptTypeAll,
  selectedTypes,
  professionalId,
  headerHeight,
}) => {
  const classes = useStyles();
  return (
    <TableContainer
      fullPage
      maxHeight={`calc(100vh - ${headerHeight}px - 45px)`}
      scrollPaddingBottom={60}
    >
      <TableHead>
        <HeaderWithLabels
          isLoading={bulkUpdate.loading}
          types={types}
          professionalId={professionalId}
          selectedTypes={selectedTypes}
          selectToggleApptTypeAll={selectToggleApptTypeAll}
          tableType={tableType}
          isAdmin={admin.isAdmin} />
      </TableHead>
      <TableBody>
        {types.map((type, index) => <TypeItem key={type.id} {...{
          type,
          isLoading: bulkUpdate.loading,
          classes,
          professionalId,
          ehrSystem,
          edit,
          index,
          colorActions,
          pie,
          slotWidth,
          tableType,
          patch,
          isApptTySked,
          selectToggleApptType,
          admin
        }} />
        )}
      </TableBody>
    </TableContainer>
  );
};

const ProfessionalsTemplate = ({
  professionals,
  professional,
  types,
  edit,
  save,
  selectPro,
  busy,
  ehrSystem,
  snackbar,
  patch,
  pie,
  state,
  selectedType,
  bulkUpdate,
  savePro,
  isApptTySked,
  admin,
  disableType,
  addProfessional,
  selectToggleApptType,
  selectToggleApptTypeAll,
  updateBulk,
  getTypes,
  extraActions,
  isShowingHiddenProfessional,
  hasCutoff,
  allTypes,
  saveFallback,
  deleteFallback,
  fallbacks,
  selectedTypes,
  duplicateSelected,
  locations,
  hasLocations,
  automations,
  getAutomations,
  hasScheduleCutoff,
  hasConfirm,
  hasApp,
  headerHeight,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    setQueryParam('pro', professional.id > 0 ? professional.id : undefined);
    return () => {
      setQueryParam('pro', undefined);
    };
  }, [professional]);

  const tableType = betterName(pie, types);

  const filteredProfessionals = professionals
    .filter(p => isShowingHiddenProfessional || !p.isHidden);


  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddApptType = () => {
    handleClose();
    extraActions.toggle('openAddType');
  };

  const updateBulkFromMenu = (body) => {
    handleClose();
    updateBulk(body);
  };

  const duplicateFromMenu = () => {
    handleClose();
    duplicateSelected();
  };

  const openProFromMenu = () => {
    extraActions.toggle('openAddPro');
    handleClose();
  };

  const confirmAutomation = useMemo(() => {
    return automations.find((auto) => {
      const { trigger, actions } = auto;
      const hasCorrectMessageReceived = R.pipe(
        R.propOr([], 'MessageReceived'),
        R.find(R.equals('c')),
      )(trigger);
      const hasConfirmAction = R.pipe(
        R.filter(({ action }) => {
          return R.pathOr(false, ['UpdateAppt', 'updateConfirmed'], action);
        }),
        R.isEmpty,
        R.not
      )(actions);
      if (hasCorrectMessageReceived && hasConfirmAction) {
        return auto;
      }
      return null;
    });
  }, [automations]);

  const automationState = usePromise(saveAutomation, {});

  const handleCToConfirmToggle = (e) => {
    const checked = e.target.checked;
    const auto = confirmAutomation ? {
      ...confirmAutomation,
      enabled: checked,
      filters: [],
      actions: confirmAutomation.actions.map((a) => {
        return ({
          actionId: a.id,
          ...a,
        });
      })
    } : {
      enabled: checked,
      actions: [{ action: { UpdateAppt: { scope: 'Next', updateConfirmed: true } } }],
      trigger: {
        MessageReceived: ['c'],
      },
      filters: [],
      id: 0,
      hidden: false,
    };
    automationState.invoke(auto).then(() => {
      getAutomations();
    });
  };

  return (
    <>
      <Header
        title='Appointment Types'
        pageId={ehrSystem === 'None' ? 'appointment-types-standalone' : 'appointment-types'}
        leftIcons={[
          <SelectProfessional
            showNameAndDisplayName
            noActionButton
            title={professional.id > 0 ? `${professional.displayFirstName} ${professional.displayLastName}` : 'Professional'}
            allProfessionals={filteredProfessionals}
            selectedProId={professional.id}
            editAction={() => extraActions.toggle('openEditPro')}
            setPropId={id => {
              const pro = professionals.find(p => p.id === id);
              selectPro(pro);
            }}
          />
        ]}
        rightIcons={[
          <Grid marginX="5px">
            {(busy || automationState.loading) && <CircularProgress size={18} />}
          </Grid>,
          <Grid>
            {hasConfirm &&
              <HeaderButton borderSolid active={R.propOr(false, 'enabled', confirmAutomation)} type='hide'>
                <FormControlLabelWithoutMargin
                  control={
                    <Checkbox
                      style={{ padding: '2px 8px', fontSize: 14, fontWeigh: 400 }}
                      checked={R.propOr(false, 'enabled', confirmAutomation)}
                      onChange={handleCToConfirmToggle}
                      name="acknowledge-checkbox"
                      color="primary"
                    />
                  }
                  label="Enable Appointment Confirmation"
                />
                <IconButton onClick={() => setIsOpen(true)} style={{ padding: '2px 4px', borderRadius: 2, marginLeft: 4, marginRight: -4 }}>
                  <LittleSmallerInfo style={{ width: 17, height: 17 }} />
                </IconButton>
              </HeaderButton>}
          </Grid>,
          <HeaderButton borderSolid active={isShowingHiddenProfessional} type='hide'>
            <FormControlLabel style={{ marginRight: 0 }} control={
              <Checkbox
                style={{ padding: '2px 8px', fontSize: 14, fontWeigh: 400 }}
                checked={isShowingHiddenProfessional}
                onChange={e => extraActions.setShowHiddenProfessionalFlag(e.target.checked)}
                name="checkedC" />
            } label="Show Hidden" />
          </HeaderButton>,
          <Grid>
            {(ehrSystem === 'None' || admin) &&
              <HeaderButton
                title='Actions'
                className='sked-test-appointment-types-actions'
                borderSolid
                onClick={handleClick}
                EndIcon={ArrowDropDownIcon}
              />}
          </Grid>,
        ]}
        onlyIconsWidth={hasConfirm ? 1042 : 754}
        breakPoints={hasConfirm ? [
          {
            width: 965,
            mobileItems: [2]
          },
          {
            width: 675,
            mobileItems: [2, 3]
          },
          {
            width: 528,
            mobileItems: [2, 3, 4]
          },
        ] : [
          {
            width: 632,
            mobileItems: [2, 3]
          },
          {
            width: 525,
            mobileItems: [2, 3, 4]
          },
        ]}
      />
      <div style={{ padding: 0 }} >
        {!state &&
          !extraActions.openAddPro &&
          !extraActions.openEditPro &&
          !extraActions.openEditPro &&
          <PopupTemplate />}
        <Snackbar
          open={snackbar.isOpen}
          message={snackbar.body}
          autoHideDuration={4000}
          onClose={() => patch({ snackbar: { isOpen: false, body: '' } })} />
        <EditType
          open={state === 'EDIT_TYPE'}
          type={R.merge(
            selectedType,
            {
              scheduleCutoff: selectedType.scheduleCutoff && {
                days: Math.floor(selectedType.scheduleCutoff / 1440), // minutes to days
                hours: Math.floor((selectedType.scheduleCutoff % 1440) / 60), // minutes to hours
                minutes: ((selectedType.scheduleCutoff % 1440) % 60) % 60, // minutes to minutes
              },
              rescheduleCutoff: selectedType.rescheduleCutoff && {
                days: Math.floor(selectedType.rescheduleCutoff / 1440), // minutes to days
                hours: Math.floor((selectedType.rescheduleCutoff % 1440) / 60), // minutes to hours
                minutes: ((selectedType.rescheduleCutoff % 1440) % 60) % 60, // minutes to minutes
              },
              cancelCutoff: selectedType.cancelCutoff && {
                days: Math.floor(selectedType.cancelCutoff / 1440), // minutes to days
                hours: Math.floor((selectedType.cancelCutoff % 1440) / 60), // minutes to hours
                minutes: ((selectedType.cancelCutoff % 1440) % 60) % 60, // minutes to minutes
              },
            }
          )}
          busy={busy}
          onClose={() => patch({ state: undefined, selectedType: {} })}
          save={(type) => save(type)}
          doctor={professional.displayFirstName + ' ' + professional.displayLastName}
          ehr={ehrSystem}
          hasCutoff={hasCutoff}
          hasScheduleCutoff={hasScheduleCutoff}
        />
        <AddProfessional
          open={extraActions.openAddPro}
          onClose={() => extraActions.toggle('openAddPro')}
          save={(data) => {
            addProfessional(data);
          }}
        />
        <EditProfessional
          open={extraActions.openEditPro}
          professional={professional}
          ehr={ehrSystem}
          onClose={() => extraActions.toggle('openEditPro')}
          save={(data) => {
            savePro(data);
          }}
          professionals={professionals}
          allTypes={allTypes}
          fallbacks={fallbacks}
          patch={patch}
          deleteFallback={deleteFallback}
          saveFallback={saveFallback}
          locations={locations}
          hasLocations={hasLocations}
          hasApp={hasApp}
        />
        <AddTypes
          open={extraActions.openAddType}
          onClose={() => extraActions.toggle('openAddType')}
          professionals={professionals}
          professionalId={professional.id}
          save={() => {
            getTypes();
          }}
        />
        <InformationalDialog
          open={isOpen}
          message={biggieApptConfirmText}
          onClose={() => setIsOpen(false)} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            getContentAnchorEl={null}
            keepMounted
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {ehrSystem === 'None' && <MenuItem onClick={handleAddApptType}>Add Appointment Types</MenuItem>}
            {ehrSystem === 'None' && <MenuItem onClick={openProFromMenu}>Add Professional</MenuItem>}
            {ehrSystem === 'None' && admin && <Divider />}
            {admin && <div>
              <MenuItem
                disabled={selectedTypes.length === 0}
                className={'sked-test-appointment-types-duplicate-selected'}
                onClick={duplicateFromMenu}>
                Duplicate Selected
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => updateBulkFromMenu({ canSee: true })}
                className={'sked-test-appointment-types-check-can-see'}>
                Check "Can See"
              </MenuItem>
              <MenuItem
                onClick={() => updateBulkFromMenu({ canSee: false })}
                className={'sked-test-appointment-types-uncheck-can-see'}>
                Uncheck "Can See"
              </MenuItem>
              <MenuItem
                onClick={() => updateBulkFromMenu({ canCancel: true })}
                className={'sked-test-appointment-types-check-can-cancel'}>
                Check "Can Cancel"
              </MenuItem>
              <MenuItem
                onClick={() => updateBulkFromMenu({ canCancel: false })}
                className={'sked-test-appointment-types-uncheck-can-cancel'}>
                Uncheck "Can Cancel"
              </MenuItem>
              <MenuItem
                onClick={() => updateBulkFromMenu({ canChange: true })}
                className={'sked-test-appointment-types-check-can-reschedule'}>
                Check "Can Reschedule"
              </MenuItem>
              <MenuItem
                onClick={() => updateBulkFromMenu({ canChange: false })}
                className={'sked-test-appointment-types-uncheck-can-reschedule'}>
                Uncheck "Can Reschedule"
              </MenuItem>
              <MenuItem
                onClick={() => updateBulkFromMenu({ partOfCarePlan: true })}
                className={'sked-test-appointment-types-check-on-careplan'}>
                Check "On Care Plan"
              </MenuItem>
              <MenuItem
                onClick={() => updateBulkFromMenu({ partOfCarePlan: false })}
                className={'sked-test-appointment-types-uncheck-on-careplan'}>
                Uncheck "On Care Plan"
              </MenuItem>
            </div>}
          </Menu>

          <div hidden={extraActions.tab !== 'types'}>
            <TypesTable {...{
              types,
              selectedTypes,
              selectToggleApptType,
              selectToggleApptTypeAll,
              ehrSystem,
              professionalId: professional.id,
              bulkUpdate,
              edit,
              colorActions: extraActions,
              pie,
              slotWidth: professional.slotWidth,
              tableType,
              patch,
              isApptTySked,
              isShowingHiddenProfessional,
              admin: { isAdmin: admin, disable: disableType },
              headerHeight,
            }} />
          </div>
        </div>
      </div>
    </>
  );
};

const ProfessionalsFunctional = props => {
  useTitle('Appointment Types');

  const [state, setState] = React.useState({
    opened: false,
    color: {},
    checkForWidth: true,
    openAddPro: false,
    openAddType: false,
    openEditPro: false,
    tab: 'types',
  });

  const update = data => setState(s => R.merge(s, data));

  const setShowHiddenProfessionalFlag = (flag = false) => {
    props.patch({ isShowingHiddenProfessional: flag });
  };

  const selectTemp = (color) => {
    update({ color });
  };

  const open = (index, color) => {
    selectTemp(color);
    update({ opened: true });
    props.toggleOpen(index, true);
  };

  const close = (index) => {
    update({ opened: false });
    props.toggleOpen(index, false);
  };

  const toggle = (prop) => {
    update({ [prop]: !R.prop(prop, state) });
  };

  const extraActions = {
    temp: state.color,
    selectTemp,
    opened: state.opened,
    open,
    close,
    updateType: props.updateTypeColor,
    toggle,
    openAddPro: state.openAddPro,
    openAddType: state.openAddType,
    openEditPro: state.openEditPro,
    tab: state.tab,
    setShowHiddenProfessionalFlag,
  };

  React.useEffect(() => {
    props.getProfessionals(props.history, props.ehrSystem);
    if (props.hasLocations)
      props.getLocations();
    props.getAutomations();
  }, [props.office]);

  return (<ProfessionalsTemplate {...props}
    extraActions={extraActions}
  />);
};


const getSelectedPro = s => s.professionals.professional;

const getPros = R.pipe(
  R.pathOr([], ['professionals', 'professionals']),
  R.sortWith([
    R.ascend(R.prop('isHidden')),
    R.ascend(R.prop('lastName'))
  ]),
  R.prepend({ id: -1, professionalId: -1, displayFirstName: 'All Professionals', })
);

const getAllTypes = s => s.professionals.types;

const getSelectedTypes = s => s.professionals.selectedTypes;

const getShowHidden = s => s.professionals.isShowingHiddenProfessional;

const getProTypes = createSelector(
  getPros,
  getSelectedPro,
  getAllTypes,
  getSelectedTypes,
  getShowHidden,
  (pros, { id }, types, selectedTypes, showHidden) => R.pipe(
    R.map(t => {
      const professional = pros.find(R.propEq('id', t.professionalId));
      const isSelected = selectedTypes.some(R.equals(t.id));
      return {
        ...t,
        professional,
        isSelected
      };
    }),
    R.filter(R.either(
      (t) => id === -1 && (showHidden || !t.professional.isHidden),
      R.propEq('professionalId', id)
    )),
    R.sortWith([
      R.ascend(R.path(['professional', 'lastName'])),
      R.ascend(R.prop('internalName'))
    ])
  )(types)
);

// TODO use reselect to get this working.
const mapStateToProps = state => ({
  isShowingHiddenProfessional: state.professionals.isShowingHiddenProfessional,
  busy: state.professionals.busy,
  professionals: getPros(state),
  professional: getSelectedPro(state),
  types: getProTypes(state),
  bulkUpdate: state.professionals.bulkUpdate,
  selectedTypes: getSelectedTypes(state),
  isSaving: state.professionals.isSaving,
  editedTypes: R.pathOr(false, ['professionals', 'editedTypes'])(state),
  ehrSystem: R.pathOr(null, ['login', 'office', 'ehrSystem'])(state),
  office: R.pathOr(null, ['login', 'office'])(state),
  snackbar: R.pathOr(false, ['professionals', 'snackbar'])(state),
  pie: R.pathOr(false, ['professionals', 'pie'])(state),
  oldPie: R.pathOr(false, ['professionals', 'oldPie'])(state),
  state: R.pathOr(false, ['professionals', 'state'])(state),
  selectedType: R.pathOr(false, ['professionals', 'selectedType'])(state),
  editedSelectedType: R.pathOr(false, ['professionals', 'editedSelectedType'])(state),
  checkForWidth: R.pathOr(true, ['professionals', 'checkForWidth'])(state),
  setShowHiddenOn: R.pathOr(false, ['professionals', 'setShowHiddenOn'])(state),
  isApptTySked: R.pipe(
    R.pathOr([], ['login', 'features']),
    R.includes('ApptTySchedule')
  )(state),
  hasCutoff: R.pipe(
    R.pathOr([], ['login', 'features']),
    R.includes('RescheduleCancelCutoff')
  )(state),
  hasScheduleCutoff: R.pipe(
    R.pathOr([], ['login', 'features']),
    R.includes('ScheduleCutoff')
  )(state),
  hasApp: R.pipe(
    R.pathOr([], ['login', 'features']),
    R.includes('SkedApp')
  )(state),
  admin: R.pathOr(false, ['login', 'admin'])(state),
  allTypes: state.professionals.types,
  fallbacks: state.professionals.fallbacks,
  locations: R.pathOr([], ['business', 'locations'], state),
  hasLocations: R.pipe(R.pathOr([], ['login', 'features']), R.includes('Locations'))(state),
  hasConfirm: R.pipe(R.pathOr([], ['login', 'features']), R.includes('CToConfirm'))(state)
    || R.pipe(R.pathOr([], ['login', 'features']), R.includes('BasicAutomatedCampaigns'))(state),
  automations: R.pathOr([], ['automations', 'automations', 'data'], state),
  headerHeight: state.login.headerHeight,
});

const mapDispatchToProps = dispatch => ({
  getProfessionals: (his, sys) => dispatch(actions.getProfessionals(his, sys)),
  edit: (id, prop, value) => dispatch(actions.edit(id, prop, value)),
  save: (type) => dispatch(actions.save(type)),
  selectPro: (data) => dispatch(actions.selectPro(data)),
  toggleOpen: (e, t) => dispatch(actions.toggleOpen(e, t)),
  updateTypeColor: (e, t) => dispatch(actions.updateTypeColor(e, t)),
  patch: (e, t) => dispatch(actions.patch(e, t)),
  savePro: (e) => dispatch(actions.savePro(e)),
  disableType: (id) => dispatch(actions.disableType(id)),
  addProfessional: (d) => dispatch(actions.addProfessional(d)),
  selectToggleApptType: (d) => dispatch(actions.selectToggleApptType(d)),
  selectToggleApptTypeAll: () => dispatch(actions.selectToggleApptTypeAll()),
  updateBulk: (body) => dispatch(actions.updateBulk(body)),
  duplicateSelected: () => dispatch(actions.duplicateSelected()),
  // addTypes: (d) => dispatch(actions.addTypes(d)),
  getTypes: () => dispatch(actions.getTypes()),
  deleteFallback: (p) => dispatch(actions.deleteFallback(p)),
  saveFallback: (d) => dispatch(actions.saveFallback(d)),
  getLocations: () => dispatch(getLocations()),
  getAutomations: () => dispatch(getAutomations({ perPage: 1000, query: { trigger: 'MessageReceived' } })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfessionalsFunctional);
