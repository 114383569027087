import { Extension } from '@tiptap/core';

export type TabOptions = {
  types: string[],
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    tab: {
      /**
       * Set the tab depth
       */
      setTab: (padding: number) => ReturnType,
    }
  }
}

const Tab = Extension.create<TabOptions>({
  name: 'tab',

  addOptions() {
    return {
      types: [],
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          tab: {
            default: 0,
            parseHTML: (element: any) => {
              return element.style['padding-left']?.replace(/['"]+/g, '');
            },
            renderHTML: attributes => {
              if (!attributes.tab) {
                return {};
              }

              return {
                style: `padding-left: ${attributes.tab}px`,
              };
            },
          },
        },
      },
    ];
  },

  addCommands() {
    return {
      setTab: (padding) => ({ commands }) => {
        return this.options.types.every(type => commands.updateAttributes(type, { tab: padding }));
      },
    };
  },
});

export default Tab;
