import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { pathOr } from 'ramda';
import * as actions from '../../Calendar/calendar.actions';

import { Scheduler } from './scheduler.component.jsx';

const noop = () => null;

const emptyArray = [];

export const SchedulerDialog = ({
  isOpen,
  close,
  selectedTime,
  firstStep = 'searchClients',
  defaultClients = emptyArray
}) => {
  const dispatch = useDispatch();

  const store = useSelector(state => ({
    ...state.calendar,
    tz: state.login.office.timezone || 'America/New_York',
    ehrSystem: state.login.office.ehrSystem,
    timeIntervals: state.calendarv2.localInterval || pathOr(15, ['login', 'ehrSettings', 'blockLength'])(state),
    office: state.login.office,
    admin: pathOr(false, ['login', 'admin'])(state),
  }));

  React.useEffect(() => {
    dispatch(actions.getFilterData());
  }, [store.office.id]);

  if (!isOpen) {
    return null;
  }

  return (
    <Scheduler
      isOpen={isOpen}
      isInDialog={true}
      firstStep={firstStep}
      defaultClients={defaultClients}
      refreshToday={noop}
      setPossibleAppointments={noop}
      timezone={store.tz}
      selectedTime={selectedTime}
      interval={store.timeIntervals}
      selectDate={noop}
      types={store.types}
      professionals={store.professionals}
      close={close}
      ehrSystem={store.ehrSystem}
    />
  );
};

