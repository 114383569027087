import React from 'react';

import { useStyles } from './saveButton.styles';

import { Button } from '@mui/material';

type SaveButtonProps = {
  onClick?: () => void;
  reset?: () => void;
  title: string;
  disabled?: boolean;
  className?: string;
}

const SaveButton = ({
  onClick, disabled, title, className
}: SaveButtonProps) => {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      variant='contained'
      className={classes.saveButton + ' ' + (className || '')}
      disabled={disabled}
      color="primary">
      {title || 'Save'}
    </Button>
  );
};

export default SaveButton;
