import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  card: {
    padding: 20,
    width: 400,
    flex: 1,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  input: {
    '& span': {
      fontSize: 11,
    },
    '& h6': {
      fontSize: 14,
    },
    '& .MuiTextField-root': {
      width: '95%'
    }
  },
  inputName: {
    marginBottom: 8,
  },
  action: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
  }
}));
