import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select as RawSelect,
  FormControlLabel,
  Checkbox,
  Tooltip,
  MenuItem,
  FormControl,
  InputLabel,
  TableBody,
  TableRow,
} from '@mui/material';
import * as R from 'ramda';
// import { PopupTemplate, popup } from '../../services/Popup.js';
import { withStyles } from '@mui/styles';
import Info from '@mui/icons-material/Info';
import { TableContainer, BodyCell } from '../../../components/CustomTable';
import Modal from '../../../components/Modal/Modal.component';
import HeaderButton from '../../../components/HeaderButton/HeaderButton.component';

const Select = withStyles({
  root: {
    width: '200px'
  }
})(RawSelect);

const defaultPro = {
  firstName: '',
  lastName: '',
  displayFirstName: '',
  displayLastName: '',
  isHidden: false
};


const FallbackTypes = ({
  currentProfessional,
  professionals,
  types,
  fallback,
  setFallback,
}) => {
  const [_prev, setPrev] = React.useState(undefined);

  const {
    proId,
    days,
    apptTypeId,
  } = fallback ? fallback : {};

  const proTypes = R.pipe(
    R.filter(({ isHidden }) => !isHidden),
    R.sortBy(R.prop('lastName')),
    R.map((pro) => {
      const ts = R.pipe(
        R.filter(({ professionalId }) => pro.id === professionalId),
        R.sortBy(R.prop('internalName'))
      )(types);
      const newPro = R.merge(pro, {
        id: undefined, // to prevent mis-selection when a type has the same id.
        professionalId: pro.id
      });
      return [newPro, ts];
    }),
    R.flatten
  )(professionals);

  React.useEffect(() => {
    setPrev(fallback);
  }, [fallback]);

  const showSelectedType = (typeId) => {
    const appointmentType = R.find(R.propEq('id', Number(typeId)), types);
    let pro;
    if (appointmentType) {
      pro = R.find(R.propEq('id', appointmentType.professionalId), professionals);
      let proStr = '';
      if (pro) {
        proStr = ' with ' + pro.firstName + ' ' + pro.lastName;
      }
      return appointmentType.internalName + ' (' + appointmentType.name + ')' + proStr;
    }
    return null;
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginRight: '190px',
      }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!R.propOr(false, 'isDeleted', fallback)}
              onChange={(e) => {
                if (e.target.checked) {
                  const stuff = R.propOr(false, 'apptTypeId', fallback) ?
                    R.dissoc('isDeleted', fallback)
                    :
                    {
                      proId: currentProfessional.id,
                      days: 30,
                      apptTypeId: undefined,
                    };

                  setFallback({
                    ...stuff
                  });
                } else {
                  setFallback({
                    isDeleted: true,
                    ...fallback,
                  });
                }
              }}
              name="fallback-checkbox" />
          }
          label="Enable Fallback Appt. Type"
        />
        <Tooltip
          title={'If a client hasn\'t arrived for an appointment after X number of days, they\'ll be forced to schedule the selected appointment type with their default provider in the SKED App.'}
          position='top'
          arrow
        >
          <Info style={{ fontSize: 16 }} />
        </Tooltip>
      </div>
      {(fallback && !fallback.isDeleted) &&
        <TableContainer>
          <TableBody style={{ background: 'unset' }}>
            <TableRow style={{ background: 'unset' }}>
              <BodyCell style={{ textAlign: 'start' }}>
                <TextField
                  label='Days without a visit'
                  type='number'
                  value={days}
                  onChange={(e) => {
                    const v = Number(e.target.value);
                    if (v >= 0) {
                      setFallback({
                        proId,
                        apptTypeId,
                        days: v
                      });
                    }
                  }}
                  style={{
                    marginBottom: '5px',
                    width: '200px',
                  }}
                />
              </BodyCell>
            </TableRow>
            <TableRow style={{ background: 'unset' }}>
              <BodyCell style={{ textAlign: 'start' }}>
                <FormControl>
                  <InputLabel>Fallback Appt. Type</InputLabel>
                  <RawSelect
                    autoWidth={true}
                    style={{ minWidth: '100px' }}
                    id="pro-select"
                    value={String(apptTypeId)}
                    renderValue={showSelectedType}
                    style={{ minWidth: '200px' }}
                    disabled={proTypes.length < 2}
                    onChange={(e) => {
                      setFallback({
                        proId,
                        days,
                        apptTypeId: Number(e.target.value),
                      });
                    }}>
                    {R.map((proOrType) => {
                      if (proOrType.firstName) {
                        return (
                          <MenuItem
                            key={proOrType.professionalId}
                            value={proOrType.professionalId}
                            disabled={true}>
                            {proOrType.displayFirstName + ' ' + proOrType.displayLastName}
                          </MenuItem>
                        );
                      } else {
                        return (
                          <MenuItem key={proOrType.id} value={proOrType.id}>
                            {proOrType.internalName + ' (' + proOrType.name + ')'}
                          </MenuItem>
                        );
                      }
                    })(proTypes)}
                  </RawSelect>
                </FormControl>
              </BodyCell>
            </TableRow>
          </TableBody>
        </TableContainer>}
    </div>
  );
};

const EditProfessional = (props) => {
  const [state, setState] = useState({
    pro: defaultPro,
    status: 'INIT',
    locations: [],
    hasLocations: false,
  });

  const update = (prop, data) => {
    if (prop !== null) {
      setState(
        R.merge(
          state,
          {
            [prop]: R.merge(
              R.prop(prop, state), data)
          }));
    } else {
      setState(R.merge(state, data));
    }
  };

  useEffect(() => {
    if (state.status === 'INIT' && props.open) {
      update(null, {
        pro: props.professional,
        status: 'EDIT',
        locations: props.locations,
        hasLocations: props.hasLocations,
      });
    }
  }, [state.status, props.open]);

  const table = ({
    pro,
    locations,
    hasLocations,
  }) => {
    const {
      firstName,
      lastName,
      displayFirstName,
      displayLastName,
      isHidden,
      locationId,
    } = pro;
  
    return (
      <TableContainer noHover style={{ overflowY: 'auto' }}>
        <TableBody>
          {props.ehr === 'None' &&
            <TableRow key={0}>
              <BodyCell><b>First Name:</b></BodyCell>
              <BodyCell>
                <TextField
                  value={firstName}
                  style={{ width: '100%' }}
                  onChange={(e) => update('pro', { firstName: e.target.value })}
                  label="First Name"
                />
              </BodyCell>
            </TableRow>}
          {props.ehr === 'None' &&
            <TableRow key={1}>
              <BodyCell><b>Last Name:</b></BodyCell>
              <BodyCell>
                <TextField
                  value={lastName}
                  style={{ width: '100%' }}
                  onChange={(e) => update('pro', { lastName: e.target.value })}
                  label="Last Name"
                />
              </BodyCell>
            </TableRow>}
          <TableRow key={2}>
            <BodyCell><b>Display First Name:</b></BodyCell>
            <BodyCell>
              <TextField
                value={displayFirstName}
                style={{ width: '100%' }}
                onChange={(e) => update('pro', { displayFirstName: e.target.value })}
                label="Display First Name"
              />
            </BodyCell>
          </TableRow>
          <TableRow key={3}>
            <BodyCell><b>Display Last Name:</b></BodyCell>
            <BodyCell>
              <TextField
                value={displayLastName}
                style={{ width: '100%' }}
                onChange={(e) => update('pro', { displayLastName: e.target.value })}
                label="Display Last Name"
              />
            </BodyCell>
          </TableRow>
          <TableRow key={4}>
            <BodyCell><b>Hide Professional:</b></BodyCell>
            <BodyCell>
              <input
                checked={isHidden}
                type='checkbox'
                onChange={(e) => update('pro', { isHidden: e.target.checked })}
              />
            </BodyCell>
          </TableRow>
          {hasLocations && !R.isEmpty(locations) &&
            <TableRow key={5}>
              <BodyCell><b>Location:</b></BodyCell>
              <BodyCell>
                <Select
                  variant='standard'
                  style={{ minWidth: 200 }}
                  value={locationId ? locationId : 'main'}
                  onChange={e => {
                    const v = e.target.value;
                    update('pro', { locationId: v === 'main' ? undefined : v });
                  }}
                >
                  {locations.map(({ id, name }) =>
                    <MenuItem key={id} value={id}>{name}</MenuItem>
                  )}
                </Select>
              </BodyCell>
            </TableRow>}
        </TableBody>
      </TableContainer>
    );
  };

  const pro = state.pro;
  const disabled = !pro.displayFirstName?.trim();
  const hasFallback = R.find(R.propEq('proId', props.professional.id))(props.fallbacks);
  const currentFallback = hasFallback ? hasFallback : {
    isDeleted: true,
    proId: null,
    days: null,
    apptTypeId: null,
  };
  
  return (
    <Modal
      title='Edit Professional'
      open={props.open}
      maxWidth={700}
      className='sked-test-appt-types-edit-professional-modal'
      onClose={() => {
        props.onClose();
        update(null, { status: 'INIT', pro: defaultPro });
      }}
      buttons={[
        <HeaderButton
          disabled={disabled}
          title='Save'
          color='primary'
          className='sked-test-appt-types-edit-professional-modal-button-save'
          onClick={() => {
            if (!R.any(R.isNil, R.values(currentFallback))) {
              if (R.propOr(false, 'isDeleted', currentFallback)) {
                props.deleteFallback(currentFallback.proId);
              } else {
                props.saveFallback(currentFallback);
              }
            }
            props.save(state.pro);
            props.onClose();
            update(null, { status: 'INIT', pro: defaultPro });
          }}
        />
      ]}
    >
      <div>
        {table(state)}
        {props.hasApp &&
            <FallbackTypes
              currentProfessional={props.professional}
              professionals={R.filter(R.pipe(R.prop('isHidden'), R.not))(props.professionals)}
              types={props.allTypes}
              saveFallback={props.saveFallback}
              fallback={currentFallback}
              setFallback={(body) => {
                const without = R.filter(R.pipe(R.propEq('proId', props.professional.id), R.not))(props.fallbacks);
                const withIt = R.without([null], R.append(body, without));
                props.patch({ fallbacks: withIt });
              }}
            />}
      </div>
    </Modal>
  );
};

export default EditProfessional;
