import React from 'react';

type Props = {
  color?: string;
};

const AutomatedMessagesIcon = ({ color }: Props) => {
  return (
    <svg id="uuid-51aca7fa-6d09-4b2b-8190-f5e87b29f367" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <g id="uuid-05e670a1-12e1-4acf-b9b6-83b85de2c906" data-name="Group 8406" fill={color || '#FFF'}>
        <path id="uuid-0934eb48-e7a5-4653-9c50-4f45a46576f1" data-name="Path 6370" d="m29.9944,16.7131c-5.4525,0-10.9044,0-16.3556,0h-7.916C2.5622,16.7209.0033,19.2834,0,22.4439v15.3065c-.0045,3.1717,2.5631,5.7466,5.7349,5.751.002,0,.004,0,.0061,0h1.9103v4.9606c-.0514.5464.2476,1.0658.7459,1.2958.4827.2324,1.0596.1334,1.4373-.2466,2.3152-1.9298,4.6339-3.8522,6.9558-5.7672.1819-.1395.4035-.2174.6327-.2224,4.1986-.0121,8.3951,0,12.5937-.0121,3.1412-.0276,5.6787-2.5714,5.6985-5.7126v-15.3469c-.0033-3.1602-2.5626-5.7221-5.7227-5.7288m-4.4573,10.8411c1.41.0112,2.5439,1.1633,2.5328,2.5733s-1.1633,2.5439-2.5733,2.5328c-1.4076-.0112-2.5406-1.1595-2.5328-2.5671.0122-1.4106,1.1627-2.5457,2.5733-2.5389m-7.7078,0c1.4099-.0179,2.5674,1.1106,2.5852,2.5206s-1.1106,2.5674-2.5206,2.5852-2.5674-1.1106-2.5852-2.5206c-.0001-.0107-.0002-.0214-.0002-.0321-.0012-1.4001,1.1228-2.5413,2.5228-2.5612m-7.6472,5.1042c-1.41-.0134-2.5421-1.1673-2.5287-2.5773s1.1673-2.5421,2.5773-2.5287c1.406.0134,2.5365,1.161,2.5288,2.567-.0122,1.4113-1.164,2.5468-2.5753,2.5389" />
        <path id="uuid-31e2f442-916d-4cad-8d43-e9edf91979ae" data-name="Path 6371" d="m29.7498,9.3085c2.638-.0404,5.28-.0121,7.918-.0121.0526,0,.1031,0,.1557.0121l.0708.095c-.4569.4366-.9198.8672-1.3665,1.3139-.5461.5117-.7599,1.2859-.5539,2.0053.2802,1.1106,1.4077,1.7839,2.5183,1.5037.3975-.1003.7564-.3161,1.0313-.6203,1.6172-1.5889,3.204-3.1939,4.801-4.7969.8896-.7444,1.0073-2.069.2629-2.9586-.082-.0979-.1727-.1882-.271-.2697-1.5754-1.574-3.1508-3.1454-4.7262-4.714-.1181-.1225-.2451-.236-.38-.3396-.9137-.6815-2.2069-.4931-2.8883.4206-.1381.1851-.2441.3921-.3136.6124-.2622.7598-.0522,1.6028.5357,2.1508.4407.4467.8854.8874,1.4009,1.4029h-.5357c-2.3934,0-4.7868-.0445-7.1782.0182-1.1668.0117-2.3287.1521-3.4648.4184-4.7553,1.1902-8.4641,4.9099-9.6403,9.6686h4.334c1.3039-3.4721,4.5805-5.8088,8.288-5.9107" />
        <path id="uuid-155a9e29-b8d2-45dc-9479-75b1443bca32" data-name="Path 6372" d="m49.0587,13.4969c-.3198-.9195-1.3245-1.4056-2.2439-1.0858-.1719.0598-.3334.1458-.479.255-.9789.6335-1.3686,1.8738-.9278,2.9533.4536,1.4549.55,2.9977.281,4.4977-.7641,4.1473-4.2784,7.2256-8.4901,7.4369v4.1642c.6527-.0241,1.303-.0916,1.9467-.2021,7.2279-1.3615,11.9835-8.3246,10.6219-15.5524-.1589-.8435-.3989-1.6696-.7168-2.4668" />
      </g>
    </svg>
  );
};

export default AutomatedMessagesIcon;
