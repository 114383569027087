import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',

    '& > h3': {
      fontSize: 18,
      fontWeight: 'bold',
    }
  },
  margins: {
    marginLeft: 18,
    marginRight: 18,
  },
  date: {
    width: '160px',
  }
}));
