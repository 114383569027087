import React from 'react';
import * as R from 'ramda';

import {
  MenuItem,
  TextField,
  Snackbar,
  Button,
  Paper,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Tooltip,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { PopupTemplate } from '../../../../services/Popup.js';
import { PageHeader, Section } from '../../../../components/PageHeader';
import Header from '../../../../components/PageHeader/PageHeader.component';
import Loading from '../../../../components/Loading/Loading.component';
import UpdateSyncer from '../../../../components/UpdateSyncer/updateSyncer.component';
import { TableContainer, HeaderCell, BodyCell } from '../../../../components/CustomTable';

import { Grouping } from './Grouping.component.jsx';

const groupByOptions = [
  'Linked Accounts',
  'Pin'
];

const arrivalOptions = [
  'Arrived',
  'Checked In'
];

const miscMenu = (option, prop, patch) => (
  <MenuItem
    key={option}
    onClick={() => patch(prop, option)}
    value={option}>
    {option}
  </MenuItem>
);

const miscTable = ({ arriveType, groupBy, patch, hasCTArrivalOptions }) => (
  <TableContainer noHover>
    <TableHead>
      <TableRow>
        <HeaderCell>
          Option
        </HeaderCell>
        <HeaderCell>
          Value
        </HeaderCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableRow>
        <BodyCell>
          Group Client By
        </BodyCell>
        <BodyCell>
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <TextField
              select
              value={groupBy}>
              {groupByOptions.map((option) => miscMenu(option, 'groupBy', patch))}
            </TextField>
            &nbsp;
            <Tooltip
              title={
                <div style={{ whiteSpace: 'pre-line' }}>
                  {'Linked Accounts: Standard method located under Self Check-In Tab. \n Pin: Required client to each have the same pin.'}
                </div>
              }
              placement="top"
              arrow>
              <InfoIcon style={{ fontSize: '14px' }} />
            </Tooltip>
          </div>
        </BodyCell>
      </TableRow>
      {hasCTArrivalOptions &&
        <TableRow>
          <BodyCell>
            Arrival Type
          </BodyCell>
          <BodyCell>
            <div style={{
              display: 'flex',
              alignItems: 'center',
            }}>
              <TextField
                select
                value={R.isNil(arriveType) ? '' : arriveType}>
                {arrivalOptions.map((option) => miscMenu(option, 'arriveType', patch))}
              </TextField>
              &nbsp;
              <Tooltip
                title="How arrivals from SKED Virtual Waiting Room or Front Desk get synced to ChiroTouch."
                placement="top"
                arrow>
                <InfoIcon style={{ fontSize: '14px' }} />
              </Tooltip>
            </div>
          </BodyCell>
        </TableRow>}
    </TableBody>
  </TableContainer>
);

const SyncColors = ({
  syncColors,
  save,
  patch
}) => {
  const [state, setState] = React.useState(syncColors);
  const saveHandler = () => {
    save({ syncColors: state }, 'ChiroTouch');
    patch('syncColors', state);
  };
  return (
    <Paper style={{ padding: '20px', marginTop: '10px' }}>
      <Section>
        <PageHeader variant='h6'>
          Sync SKED Colors to ChiroTouch
        </PageHeader>
        <FormControlLabel
          control={<Checkbox
            checked={state}
            onChange={(e) => setState(e.target.checked)}
            name="syncColors" />}
          label="Enabled"
        />
        <FormHelperText>
          By enabling this, SKED will set the appointment color in ChiroTouch.
        </FormHelperText>
      </Section>
      <Button
        variant='contained'
        onClick={saveHandler}>
        Save
      </Button>
    </Paper>
  );

};

export const ChiroIntegrationTemplate = ({
  busy,
  proTypes,
  groupBy,
  originalGroupBy,
  integrationMiscPatch,
  integrationPatch,
  save,
  saveMisc,
  snackbar,
  syncColors,
  arriveType,
  hasCTArrivalOptions,
  admin,
}, table) => (
  <>
    <Header title='EHR Settings' />
    <div style={{ margin: '20px' }}>
      <PopupTemplate />
      <Snackbar
        open={snackbar}
        message="Information saved!"
        autoHideDuration={4000}
        onClose={() => integrationMiscPatch('snackbar', false)} />
      {busy && <Loading loading />}
      {!busy &&
        <div>
          <Paper style={{ padding: '20px', marginBottom: '20px', marginTop: 0 }}>
            <PageHeader variant="h6">
              Default Appointment Types
            </PageHeader>
            {proTypes && table(proTypes, integrationPatch)}
            <Button
              variant='contained'
              onClick={() => save(proTypes, 'chirotouch')}>
              Save
            </Button>
          </Paper>
          <Paper style={{ padding: '20px', marginBottom: '20px' }}>
            <PageHeader variant="h6">
              Miscellaneous
            </PageHeader>
            {miscTable({
              groupBy,
              patch: integrationMiscPatch,
              arriveType,
              hasCTArrivalOptions,
            })}
            <Button
              variant='contained'
              onClick={() => {
                const gBy = groupBy === 'Linked Accounts' ? 'Native' : groupBy;
                const aType = R.cond([
                  [R.equals('Checked In'), R.always('CheckedIn')],
                  [R.isNil, R.always(undefined)],
                  [R.T, R.identity]
                ])(arriveType);
                saveMisc({ groupBy: originalGroupBy === gBy ? undefined : gBy, arriveType: aType }, 'ChiroTouch');
              }}>
              Save
            </Button>
          </Paper>
          <SyncColors syncColors={syncColors} save={saveMisc} patch={integrationMiscPatch} />
          {admin && <UpdateSyncer ehrSystem={'ChiroTouch'} />}
        </div>}
      <Grouping />
    </div>
  </>
);
