import React, { useState } from 'react';
import * as R from 'ramda';

import api from '../../services/api';
import { useTypeahead } from '../../services/typeahead.hook';

import Icon from '../../icons/ThreePeople.icon';
import FilterHeader from '../FilterHeader/FilterHeader.component';
import HeaderButton from '../HeaderButton/HeaderButton.component';

import { useStyles } from './filterClients.styles';

import {
  Popover,
  Grid,
  Autocomplete,
  CircularProgress,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Client = {
  id: number;
  firstName: string;
  lastName: string;
  phone?: string;
  birthday?: string;
}

const searchClient = (term = '') => {
  const [lastName, firstName] = term.split(' ');
  return api.post('client/query', {
    query: {
      firstName,
      lastName
    },
    page: 0,
    perPage: 25
  });
};

const defaultData = {
  data: [] as Client[]
};

type FilterClientsProps = {
  save: ({ clients }: { clients: Client[] }) => void;
  setSelectedClients: (clients: Client[]) => void;
  selectedClients: Client[];
  onlyIcon?: boolean;
}

const FilterClients = ({ setSelectedClients, selectedClients, save, onlyIcon }: FilterClientsProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const clientState = useTypeahead(searchClient, defaultData);

  const handleSelect = (value: Client[]) => {
    setSelectedClients(value);
    save({ clients: value });
  };

  const handleDelete = (i: number) => {
    const newClients = R.remove(i, 1, selectedClients);
    setSelectedClients(newClients);
    save?.({ clients: newClients });
  };

  const handleInputChange = (value: string) => {
    if (value) {
      clientState.invoke(value);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    setSelectedClients([]);
    save?.({ clients: [] });
  };

  return (
    <Grid>
      <Grid marginRight={0.5}>
        <HeaderButton
          title={`${selectedClients.length > 0 ? selectedClients.length : ''} Clients`}
          Icon={Icon}
          iconClass={classes.iconScale}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          active={selectedClients.length > 0}
          type='filter'
          onlyIcon={onlyIcon}
        />
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid width={420}>
          <FilterHeader
            title='Clients'
            reset={handleReset}
            resetDisabled={selectedClients?.length === 0}
          />
          <Grid padding={1} paddingTop={2} paddingBottom={2}>
            <Autocomplete
              multiple
              id="tags-outlined"
              filterOptions={(list) => list}
              options={clientState.data.data}
              getOptionLabel={(option: Client) => {
                const name =
                  option.firstName +
                  ' ' +
                  option.lastName +
                  [option.birthday].reduce((a, c) => {
                    if (a === '' && c) {
                      return ' | ' + c;
                    } else if (c) {
                      return a + ', ' + c;
                    } else {
                      return a;
                    }
                  }, '');
                return name;
              }}
              ListboxProps={{ className: classes.menuPaper }}
              onChange={(_e, value) => handleSelect(value)}
              value={selectedClients}
              filterSelectedOptions
              disableCloseOnSelect
              disableClearable
              renderTags={(value: readonly Client[], getTagProps) =>
                value.map((option, index: number) => (
                  <Grid key={option.id} {...getTagProps({ index })} className={classes.item}>
                    {`${option.firstName} ${option.lastName}`}&nbsp;
                    <CloseIcon onClick={() => handleDelete(index)} />
                  </Grid>
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search Clients"
                  variant='outlined'
                  size='small'
                  className={classes.input}
                  onChange={(event) => handleInputChange(event.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {clientState.loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
};

export default FilterClients;
