import * as at from '../../../../actionTypes';
import * as R from 'ramda';

const init = {
  busy: false,
  state: 'VIEW',
  message: {},
  messages: [],
  selectedMessage: {},
  // query: '', // TODO: Maybe someday
  totalCount: 0,
  totalPages: 0,
  perPage: 25,
  page: 1,
  types: [],
  professionals: [],
  partTab: 'email',
};

export default function reducer(viewMessage = init, action) {
  switch (action.type) {
    case at.VIEW_MESSAGE_PATCH:
      return R.merge(viewMessage, action.data);
    case at.VIEW_MESSAGE_REMOTE_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(viewMessage, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const {
          types,
          professionals,
        } = action.data;
        return R.merge(viewMessage, {
          message: R.omit(['types', 'professionals'], action.data),
          types,
          professionals,
          busy: false,
          partTab: R.cond([
            [R.prop('isEmail'), R.always('email')],
            [R.prop('isSMS'), R.always('sms')],
            [R.prop('isPush'), R.always('push')],
            [R.T, R.always('email')]
          ])(action.data),
        });
      } else if (action.state === 'ERROR') {
        return R.merge(viewMessage, {
          busy: false,
        });
      }
      return viewMessage;
    }
    case at.VIEW_MESSAGES_REMOTE_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(viewMessage, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(viewMessage, {
          messages: action.data.data,
          totalCount: action.data.totalCount,
          totalPages: action.data.totalPages,
          perPage: action.data.perPage,
          page: action.data.page,
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(viewMessage, init);
      }
      return viewMessage;
    }
    default:
      return viewMessage;
  }
}
