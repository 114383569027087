
import { Tooltip } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

export const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid rgb(224, 227, 231)',
    boxShadow: 'rgb(170 180 190 / 30%) 0px 4px 20px',
    fontSize: '16px',
  },
  arrow: {
    '&:before': {
      border: '1px solid rgb(224, 227, 231)',
      backgroundColor: 'white',
      boxShadow: 'rgb(170 180 190 / 30%) 0px 4px 20px',
    },
  },
})(Tooltip);

export const useStyles = makeStyles(() => ({
  helpButton: {
    '-webkit-animation': '$glowing 1500ms infinite',
    '-moz-animation': '$glowing 1500ms infinite',
    '-o-animation': '$glowing 1500ms infinite',
    animation: '$glowing 1500ms infinite',
    borderRadius: '50%',
    width: '17px',
    height: '17px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '12px',
    border: 0,
    cursor: 'pointer',
    display: 'inline-flex',
    outline: 0,
    position: 'relative',
    userSelect: 'none',
    verticalAlign: 'middle',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    WebkitTapHighlightColor: 'transparent',
    
    flex: '0 0 auto',
    color: 'rgba(0, 0, 0, 0.54)',
    overflow: 'visible',
    fontSize: '1.5rem',
    textAlign: 'center',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  '@keyframes glowing': {
    '0%': { boxShadow: '0 0 3px black', /* background-color: #E4540C; */ },
    '50%': { boxShadow: '0 0 40px gray', /* background-color: #FF7B39; */ },
    '100%': { boxShadow: '0 0 3px black', /* background-color: #E4540C; */ },
  },
  icon: {
    width: 17,
    height: 17,
  }
}));