import React, { useState, useMemo, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from '../../reducers';
import {
  TextField, Button, InputAdornment, CircularProgress, IconButton,
  TableHead, TableBody, Tooltip, DialogContentText, Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Search from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { PopupTemplate, popupWithCancel } from '../../services/Popup.js';
import {
  includes, pathOr, map, prop, isEmpty, append,
  without, find, equals,
} from 'ramda';
import { PageHeader, Row } from '../../components/PageHeader';
import { useTitle } from '../../services/useTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import * as actions from './clientlists.actions';
import {
  tzParseFormat,
} from '../../services/joda.js';
import { usePromise } from '../../services/promise.hook';
import { FeatureDisabled } from '../../components/feature-disabled.component';
import { MakeCampaign, List, Campaign } from './clientlist-types';
import { History } from 'history';
import Header from '../../components/PageHeader/PageHeader.component';
import Loading from '../../components/Loading/Loading.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import FilterText from '../../components/FilterText/FilterText.component';
import { TableRow, BodyCell, HeaderCell, TableContainer } from '../../components/CustomTable';
import HeaderTabs from '../../components/HeaderTabs/HeaderTabs.component';
import Modal from '../../components/Modal/Modal.component';
import CampaignWizard from './components/campaign-wizard.component';

const useStyles = makeStyles(theme => ({
  headerRow: {
    marginBottom: '10px',
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonHeader: {
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));


const fixLength = (str: string) => {
  if (str && str.length > 50) {
    return str.slice(0, 51) + '...';
  }
  return str;
};

interface CreateClientListDialogProps {
  open: boolean;
  onClose: () => void;
  classes: { closeButton: string };
  state: { errorMessage?: string, loading?: boolean };
  save: (newCampaign: MakeCampaign) => void;
  isCampaign?: boolean;
  template?: boolean;
}

const CreateClientListDialog = ({
  open,
  onClose,
  state,
  save,
  isCampaign,
  template,
}: CreateClientListDialogProps) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const handleSave = () => {
    save({ name, description });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`Create new ${isCampaign ? 'campaign' : 'client list'} ${template ? 'template' : ''}`}
      className='sked-test-campaign-create-modal'
      buttons={[
        <HeaderButton
          onClick={handleSave}
          disabled={state.loading || name.trim() === ''}
          color="primary"
          title='Create'
          className='sked-test-campaign-create-modal-button-create'
        />
      ]}
    >
      <div>
        <DialogContentText>
          Send out an automated messaging series using Campaigns. To get started, give your campaign a name and description.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          value={name}
          onChange={e => setName(e.target.value)}
          label="Name"
          type="text"
          required
          error={name.trim() === ''}
          helperText={name.trim() === '' && 'Required Field'}
        />
        {isCampaign &&
          <>
            <br />
            <TextField
              margin="dense"
              id="description"
              value={description}
              onChange={e => setDescription(e.target.value)}
              label="Description"
              type="text"
            />
          </>}
        {state.errorMessage}
      </div>
    </Modal>
  );
};

interface RowContainerProps {
  list: List;
  tz: string;
  select: (checked: boolean) => void;
  isSelected: boolean;
  onDelete?: () => void;
  isCampaign?: boolean;
  template?: boolean;
  openWizard?: () => void;
}

const RowContainer = ({
  list,
  tz,
  select,
  isSelected,
  onDelete,
  isCampaign = false,
  template = false,
  openWizard,
}: RowContainerProps) => {
  const [selected, setSelected] = useState(isSelected);

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  const {
    clientCount,
    created,
    name,
    id,
    campaign,
  } = list;

  const url = useMemo(() => {
    let u = `${isCampaign ? 'campaign' : 'client-list'}/${id}`;
    if (template && openWizard) {
      u = 'campaigns';
    } else if (template) {
      u = 'campaign-template/' + id;
    }
    return u;
  }, [template, isCampaign, openWizard]);

  return (
    <TableRow
      key={id}
      hasSelect={!!onDelete}
      checked={Boolean(selected)}
      setChecked={(checked) => {
        setSelected(checked);
        select(checked);
      }}
    >
      <BodyCell fixed>
        <Link to={url}
          onClick={() => template && openWizard && openWizard()}>
          {isCampaign ? campaign.displayName : name}
        </Link>
      </BodyCell>
      {isCampaign &&
        <BodyCell>
          {fixLength(campaign.description)}
        </BodyCell>
      }
      <BodyCell>
        {clientCount ? clientCount : 0}
      </BodyCell>
      <BodyCell>
        {tzParseFormat(created, tz, 'MM/dd/yyyy h:mm a')}
      </BodyCell>
      {onDelete &&
        <BodyCell>
          {/* <Tooltip arrow title='Duplicate List' placement='top'> */}
          {/*   <IconButton> */}
          {/*     <FileCopyIcon/> */}
          {/*   </IconButton> */}
          {/* </Tooltip> */}
          <Tooltip arrow title={isCampaign ? 'Delete Campaign' : 'Delete List'} placement='top'>
            <IconButton onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </BodyCell>}
    </TableRow>
  );
};

const defaultClientListQueryResponse = {
  page: 1,
  perPage: 25,
  totalPages: 1,
  totalCount: 0,
  data: [] as List[],
};

interface ClientListsProps {
  history: History;
  template?: boolean;
}

let timeout: NodeJS.Timeout = null;
export const ClientLists = ({ history, template }: ClientListsProps) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useTitle('Client Lists');

  const addClientListState = usePromise(actions.addClientList, {});
  const getClientListsState = usePromise(actions.getClientLists, defaultClientListQueryResponse);
  const deleteClientListState = usePromise(actions.removeClientLists, []);

  const {
    office,
    hasFeature,
  } = useSelector((state) => ({
    office: state.login.office,
    hasFeature: includes('Campaigns', pathOr([], ['login', 'features'], state))
  }));

  useEffect(() => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      getClientListsState.invoke({
        page: 1,
        name: search,
        isCampaign: false,
        template,
      });
    }, 500);
  }, [search]);

  if (!hasFeature) {
    return (
      <FeatureDisabled title="Client Lists">
        <p>
          Tell me something about Client Lists
        </p>
      </FeatureDisabled>
    );
  }

  const { data } = getClientListsState.data;

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      actions.getClientLists({ page: 1, name: search });
    }
  };

  const onSave = ({ name }: MakeCampaign) => {
    addClientListState.invoke({ name }).then(({ listId }) => {
      setIsOpen(false);
      history.push(`client-list/${listId}`);
    });
  };

  return (
    <div style={{ overflow: 'unset', margin: '20px' }}>
      <div style={{ paddingBottom: '100px' }}>
        {/* <HelpDialog */}
        {/*   pageId={ehrSystem === 'None' ? "clients-tab-sas" : "clients-tab"} */}
        {/*   handleClose={helpState.handleClose} */}
        {/*   open={helpState.isOpen} /> */}
        <CreateClientListDialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          classes={classes}
          state={addClientListState}
          save={onSave}
        />
        <Row className={classes.headerRow}>
          <PageHeader align="left" variant="h5">
            Client Lists
            {/* <HelpButton handleOpen={helpState.handleOpen} /> */}
          </PageHeader>
          <div className={classes.buttonHeader}>
            {addClientListState.loading && <CircularProgress size={20} />}
            &nbsp;
            <Button
              variant='contained'
              disabled={addClientListState.loading}
              onClick={() => setIsOpen(true)}
            >
              New Client List
            </Button>
          </div>
        </Row>
        <Row style={{
          justifyContent: 'flex-end',
        }}>
          <TextField
            name="search"
            label="Search"
            placeholder='List Name'
            value={search}
            onKeyPress={onKeyPress}
            onChange={onTextChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search style={{ fontSize: '16px' }} />
                </InputAdornment>
              ),
            }}
          />
        </Row>
        {getClientListsState.loading && <CircularProgress />}
        {!getClientListsState.loading &&
          <TableContainer>
            <TableHead>
              <TableRow
                hasSelect
                noSelectHover
                checked={data.length && selected.length === data?.length}
                someChecked={selected.length > 0 && selected.length < data?.length}
                setChecked={(checked) => {
                  setSelectAll(checked);
                  if (checked) {
                    setSelected(map(prop('id'), data));
                  } else {
                    setSelected([]);
                  }
                }}
              >
                <HeaderCell style={{ position: 'relative' }}>
                  {!isEmpty(selected) && (
                    <Grid position="absolute" display="flex" width="80%" alignItems="center" top={0} left={8} style={{ background: '#F5F5F5' }}>
                      <IconButton
                        onClick={() => {
                          deleteClientListState.invoke({
                            listIds: selected,
                          }).then(() => {
                            getClientListsState.invoke({
                              page: 1,
                              name: search,
                              isCampaign: false,
                              template,
                            });
                            setSelected([]);
                            setSelectAll(false);
                          });
                        }}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                  Name
                  {deleteClientListState.loading && <CircularProgress size={20} style={{ marginLeft: 10 }} />}
                </HeaderCell>
                <HeaderCell>
                  Number of Clients
                </HeaderCell>
                <HeaderCell>
                  Created
                </HeaderCell>
                <HeaderCell>
                  Actions
                </HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((s: List) => (
                <RowContainer
                  key={s.id}
                  list={s}
                  tz={office.timezone || 'America/New_York'}
                  template={template}
                  isSelected={find(equals(s.id), selected)}
                  select={(isSelected) => {
                    if (isSelected) {
                      setSelected(append(s.id, selected));
                    } else {
                      setSelected(without([s.id], selected));
                      if (selectAll) {
                        setSelectAll(false);
                      }
                    }
                  }}
                  onDelete={() => {
                    popupWithCancel(
                      'Are you sure you want to delete?',
                      'This action cannot be undone!',
                      () => deleteClientListState.invoke({
                        listIds: [s.id],
                      }).then(() => {
                        getClientListsState.invoke({
                          page: 1,
                          name: search,
                          isCampaign: false,
                          template
                        });
                      }),
                      'danger'
                    );
                  }}
                />
              ))}
            </TableBody>
          </TableContainer>
        }
      </div>
    </div>
  );
};

interface CampaignsProps {
  history: History;
  template?: boolean;
}

export const Campaigns = ({ history, template }: CampaignsProps) => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [selected, setSelected] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [tab, setTab] = useState('campaigns');
  const [wizard, setWizard] = useState(null);

  useTitle('Campaigns');

  const addClientListState = usePromise(actions.addClientList, {});
  const getClientListsState = usePromise(actions.getClientLists, defaultClientListQueryResponse);
  const getClientListTemplatesState = usePromise(actions.getClientLists, defaultClientListQueryResponse);
  const deleteClientListState = usePromise(actions.removeClientLists, []);

  const {
    office,
    hasFeature,
    headerHeight,
  } = useSelector((state) => ({
    office: state.login.office,
    hasFeature: includes('BasicAutomatedCampaigns', pathOr([], ['login', 'features'], state)),
    headerHeight: state.login.headerHeight,
  }));

  const {
    data,
    perPage,
    page,
    totalCount,
    totalPages,
  } = getClientListsState.data;

  const {
    data: templates,
    perPage: tPerPage,
    page: tPage,
    totalCount: tTotalCount,
    totalPages: tTotalPages,
  } = getClientListTemplatesState.data;

  useEffect(() => {
    if (!template)
      getClientListTemplatesState.invoke({
        page: 1,
        isCampaign: true,
        template: true,
        name: '',
      });
  }, []);

  useEffect(() => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      getClientListsState.invoke({
        page: 1,
        name: search,
        isCampaign: true,
        template,
      });
    }, 500);
  }, [search]);

  if (!hasFeature && !template) {
    return (
      <FeatureDisabled title="Campaigns">
        <p>
          Tell me something about Campaigns
        </p>
      </FeatureDisabled>
    );
  }

  const addPage = (type: 'template' | 'campaign', num: number) => {
    const nextPage = page + num;
    if (type === 'template')
      return getClientListTemplatesState.invoke({
        page: nextPage,
        perPage,
        name: '',
        isCampaign: true,
        template: true,
      });

    getClientListsState.invoke({
      page: nextPage,
      name: search,
      isCampaign: true,
      perPage,
      template,
    });
  };
  
  const onPerPageChange = (type: 'template' | 'campaign') => (value: number) => {
    if (type === 'template')
      return getClientListTemplatesState.invoke({
        perPage: Number(value),
        name: '',
        isCampaign: true,
        template: true,
      });

    return getClientListsState.invoke({
      perPage: Number(value),
      name: search,
      isCampaign: true,
      template,
    });
  };

  const onSave = ({ name, description }: MakeCampaign) => {
    addClientListState.invoke({
      name,
      description,
      isCampaign: true,
      template,
    }).then(({ listId }) => {
      setIsOpen(false);
      history.push(template ? `campaign-template/${listId}` : `campaign/${listId}`);
    });
  };

  const openWizard = (campaign: Campaign) => {
    setWizard(campaign);
  };

  const onSaveTemplate = (listId: number) => {
    history.push(`campaign/${listId}`);
    setWizard(null);
  };

  return (
    <>
      <Header
        title={template ? 'Campaign Templates' : 'Campaigns'}
        leftIcons={[
          <HeaderTabs
            tabs={[
              { label: 'Your Campaigns', value: 'campaigns', className: 'sked-test-campaigns-your-campaigns' },
              { label: 'Templates', value: 'templates', className: 'sked-test-campaigns-templates' },
            ]}
            value={tab}
            setTab={(val) => setTab(val)}
          />
        ]}
        rightIcons={[
          (addClientListState.loading || getClientListsState.loading) ? <CircularProgress size={18} /> : <div />,
          tab === 'campaigns' ?
            <FilterText
              value={search}
              headerTitle='Search'
              placeholder='Campaign Name'
              onChange={setSearch}
            /> : <div />,
          <HeaderButton
            className='sked-test-campaigns-new-campaign'
            title={`New Campaign ${template ? 'Template' : ''}`}
            Icon={AddIcon}
            onClick={() => setIsOpen(true)}
          />
        ]}
        onlyIconsWidth={642}
        breakPoints={[
          {
            width: 520,
            mobileItems: [0]
          }
        ]}
      />
      <div
        style={{
          padding: '20px',
          paddingTop: 0,
          overflowY: 'unset',
        }}>
        <div style={{ paddingBottom: '100px' }}>
          {/* <HelpDialog */}
          {/*   pageId={ehrSystem === 'None' ? "clients-tab-sas" : "clients-tab"} */}
          {/*   handleClose={helpState.handleClose} */}
          {/*   open={helpState.isOpen} /> */}
          <PopupTemplate />
          <CreateClientListDialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            classes={classes}
            state={addClientListState}
            save={onSave}
            isCampaign
            template={template}
          />
          <CampaignWizard
            open={Boolean(wizard)}
            onClose={() => setWizard(null)}
            save={onSaveTemplate}
            template={wizard}
          />

          <div hidden={tab !== 'campaigns'}>
            {(getClientListsState.loading || deleteClientListState.loading) && <Loading loading />}
            {!getClientListsState.loading &&
            <TableContainer
              fullPage
              style={{ marginLeft: -20, marginRight: -20 }}
              maxHeight={`calc(100vh - ${headerHeight}px - 45px)`}
              pagination
              paginationData={{
                addPage: (v) => addPage('campaign', v),
                page,
                perPage,
                setPerPage: onPerPageChange('campaign'),
                totalCount,
                totalPages,
                currentLength: data.length,
              }}
            >
              <TableHead>
                <TableRow
                  hasSelect
                  noSelectHover
                  checked={data.length && selected.length === data?.length}
                  someChecked={selected.length > 0 && selected.length < data?.length}
                  setChecked={(checked) => {
                    setSelectAll(checked);
                    if (checked) {
                      setSelected(map(prop('id'), data));
                    } else {
                      setSelected([]);
                    }
                  }}
                >
                  <HeaderCell fixed>
                    {!isEmpty(selected) && (
                      <Grid position="absolute" display="flex" width="80%" alignItems="center" top={0} left={8} style={{ background: '#F5F5F5' }}>
                        <IconButton
                          onClick={() => {
                            deleteClientListState.invoke({
                              listIds: selected,
                              template,
                            }).then(() => {
                              getClientListsState.invoke({
                                page: 1,
                                name: search,
                                isCampaign: true,
                                template,
                              });
                              setSelected([]);
                              setSelectAll(false);
                            });
                          }}>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    )}
                    Display Name
                  </HeaderCell>
                  <HeaderCell>
                    Description
                  </HeaderCell>
                  <HeaderCell>
                    Number of Clients
                  </HeaderCell>
                  <HeaderCell minWidth={142}>
                    Created
                  </HeaderCell>
                  <HeaderCell>
                    Actions
                  </HeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((s) => (
                  <RowContainer
                    key={s.id}
                    list={s}
                    tz={office.timezone || 'America/New_York'}
                    isSelected={find(equals(s.id), selected)}
                    isCampaign
                    template={template}
                    select={(isSelected) => {
                      if (isSelected) {
                        setSelected(append(s.id, selected));
                      } else {
                        setSelected(without([s.id], selected));
                        if (selectAll) {
                          setSelectAll(false);
                        }
                      }
                    }}
                    onDelete={() => {
                      popupWithCancel(
                        'Are you sure you want to delete?',
                        'This action cannot be undone!',
                        () => deleteClientListState.invoke({
                          listIds: [s.id],
                          template,
                        }).then(() => {
                          getClientListsState.invoke({
                            page: 1,
                            name: search,
                            isCampaign: true,
                            template,
                          });
                        }),
                        'danger'
                      );
                    }}
                  />
                ))}
              </TableBody>
            </TableContainer>
            }
          </div>
          <div hidden={tab !== 'templates'}>
            {getClientListTemplatesState.loading && <Loading loading />}
            {!getClientListTemplatesState.loading &&
            <TableContainer
              fullPage
              style={{ marginLeft: -20, marginRight: -20, marginTop: 0 }}
              maxHeight={`calc(100vh - ${headerHeight}px - 45px)`}
              pagination
              paginationData={{
                addPage: (v) => addPage('template', v),
                page: tPage,
                perPage: tPerPage,
                setPerPage: onPerPageChange('template'),
                totalCount: tTotalCount,
                totalPages: tTotalPages,
                currentLength: templates.length,
              }}
            >
              <TableHead>
                <TableRow>
                  <HeaderCell fixed>
                      Display Name
                  </HeaderCell>
                  <HeaderCell>
                      Description
                  </HeaderCell>
                  <HeaderCell>
                      Number of Clients
                  </HeaderCell>
                  <HeaderCell minWidth={142}>
                      Created
                  </HeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {templates.map((s) => (
                  <RowContainer
                    key={s.id}
                    list={s}
                    tz={office.timezone || 'America/New_York'}
                    isSelected={find(equals(s.id), selected)}
                    isCampaign
                    template
                    select={(isSelected) => {
                      if (isSelected) {
                        setSelected(append(s.id, selected));
                      } else {
                        setSelected(without([s.id], selected));
                        if (selectAll) {
                          setSelectAll(false);
                        }
                      }
                    }}
                    openWizard={() => openWizard(s.campaign)}
                  />
                ))}
              </TableBody>
            </TableContainer>
            }
          </div>
        </div>
      </div>
    </>
  );
};
