import * as at from '../../../../actionTypes';
import * as R from 'ramda';

const init = {
  busy: false,
  state: 'VIEW',
  message: {},
  messages: [],
  selectedMessage: {},
  // query: '', // TODO: Maybe someday
  totalCount: 0,
  totalPages: 0,
  perPage: 25,
  page: 1,
  types: [],
  professionals: [],
  partTab: 'email',
};

export default function reducer(viewRecurring = init, action) {
  switch (action.type) {
    case at.VIEW_RECURRING_PATCH:
      return R.merge(viewRecurring, action.data);
    case at.VIEW_RECURRING_REMOTE_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(viewRecurring, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const {
          types,
          professionals,
        } = action.data;
        return R.merge(viewRecurring, {
          message: R.omit(['types', 'professionals'], action.data),
          types,
          professionals,
          busy: false,
          partTab: R.cond([
            [R.propOr(false, 'isEmail'), R.always('email')],
            [R.propOr(false, 'isSMS'), R.always('sms')],
            [R.propOr(false, 'isPush'), R.always('push')],
            [R.T, R.always('email')]
          ])(action.data)
        });
      } else if (action.state === 'ERROR') {
        return R.merge(viewRecurring, {
          busy: false,
        });
      }
      return viewRecurring;
    }
    case at.VIEW_RECURRINGS_REMOTE_GET: {
      if (action.state === 'REQUEST') {
        return R.merge(viewRecurring, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const {
          data,
          totalCount,
          totalPages,
          perPage,
          page,
          reactivationSearch,
        } = action.data;
        const messages = R.filter((msg) => {
          const {
            emailData,
            smsData,
            pushData
          } = reactivationSearch ? msg.msg : msg;
          return !R.all(R.isNil, [emailData, smsData, pushData]);
        })(data);
        return R.merge(viewRecurring, {
          messages,
          totalCount,
          totalPages,
          perPage,
          page,
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(viewRecurring, init);
      }
      return viewRecurring;
    }
    case at.VIEW_RECURRINGS_UPDATE_AUTOMATIONS: {
      const msgLens = R.lensPath(['message', 'automations']);
      return R.over(msgLens, R.update(action.data.index, action.data.automation), viewRecurring);
    }
    case at.VIEW_RECURRINGS_REMOVE_AUTOMATIONS: {
      const msgLens = R.lensPath(['message', 'automations']);
      return R.over(msgLens, R.remove(action.data.index, 1), viewRecurring);
    }
    default:
      return viewRecurring;
  }
}
