import React, { useEffect, useState } from 'react';
import {
  MenuItem,
  FormControl,
  Select,
} from '@mui/material';

import * as R from 'ramda';

type Day = {
  open: string;
  close: string;
  numberOfAppointments?: number;
}

type Hour = {
  friday: Day[];
  monday: Day[];
  saturday: Day[];
  sunday: Day[];
  thursday: Day[];
  tuesday: Day[];
  wednesday: Day[];
}

type SelectLocationProps = {
  update: ({ currentHours, edited }: { currentHours: object, edited: boolean }) => void;
  hasLocations: boolean;
  defaultMax: number;
  basic: object;
  officeHours: object;
  office: { id: number, name: string };
  currentHours: { hours: Hour, date: string }[];
  locations: {
    id: number;
    hours: Hour;
    name: string;
  }[]
}

const SelectLocation = ({ hasLocations, locations, update, basic, office, defaultMax, officeHours, currentHours }: SelectLocationProps) => {
  const [selected, setSelected] = useState<number>(0);

  useEffect(() => {
    const current: Hour = R.clone(currentHours[0]?.hours);
    for (const property in current) {
      current[property as keyof Hour].forEach(item => {
        delete item.numberOfAppointments;
      });
    }
    if (locations.length < 1) {
      setSelected(office.id);
      return;
    }
    locations.forEach(location => {
      const isEqual = R.equals(current, location.hours);
      if (isEqual) {
        setSelected(location.id);
      }
    });
  }, [currentHours, locations, office]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span>Set to Current Office Hours:</span>&nbsp; &nbsp;
      {(!hasLocations || !locations?.length) && (
        <FormControl variant="standard" style={{ minWidth: 120 }}>
          <Select value={selected}>
            <MenuItem
              onClick={() => {
                const addNumber = (num: object[]) =>
                  R.map(R.assoc('numberOfAppointments', defaultMax))(num);
                const currentHours = R.merge(
                  basic,
                  { hours: R.mapObjIndexed(addNumber, officeHours) });
                update({ currentHours, edited: true });
                setSelected(office.id);
              }}
              value={office.id}>
              {office?.name}
            </MenuItem>
          </Select>
        </FormControl>
      )}
      {(hasLocations && (locations?.length || 0) > 0) && (
        <FormControl variant="standard" style={{ minWidth: 120 }}>
          <Select value={selected}>
            {locations.map(location => (
              <MenuItem
                key={location.id}
                onClick={() => {
                  const addNumber = (num: object[]) =>
                    R.map(R.assoc('numberOfAppointments', defaultMax))(num);
                  const currentHours = R.merge(
                    basic,
                    { hours: R.mapObjIndexed(addNumber, location.hours) });
                  update({ currentHours, edited: true });
                  setSelected(location.id);
                }}
                value={location.id}>
                {location.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

export default SelectLocation;
