import React, { useState } from 'react';

import Icon from '../../../../icons/Group.icon';
import FilterHeader from '../../../../components/FilterHeader/FilterHeader.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import { useStyles } from './filterGroup.styles';

import {
  Switch,
  Popover,
  Grid,
  Typography,
  FormControl,
} from '@mui/material';

type FilterGroupProps = {
  setIsGroupBy: (value: boolean) => void;
  isGroupBy: boolean,
  onlyIcon?: boolean,
}

const FilterGroup = ({ isGroupBy, setIsGroupBy, onlyIcon }: FilterGroupProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleReset = () => {
    setIsGroupBy(false);
    handleClose();
  };

  const handleSelect = (value: boolean) => {
    setIsGroupBy(value);
    handleClose();
  };

  return (
    <Grid>
      <Grid marginRight={0.5}>
        <HeaderButton
          title='Group'
          onClick={(event) => setAnchorEl(event.currentTarget)}
          Icon={Icon}
          active={isGroupBy}
          type='group'
          onlyIcon={onlyIcon}
          className='sked-test-calendar-group-by'
        />
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid minWidth={300}>
          <FilterHeader
            title='Group By'
            reset={handleReset}
            resetDisabled={!isGroupBy}
            resetClassName='sked-test-calendar-group-reset-button'
            saveClassName='sked-test-calendar-group-save-button'
          />
          <Grid padding={2}>
            <FormControl variant="outlined" fullWidth>
              <Typography className={classes.inputLabel}>Group By Professional in Day View</Typography>
              <Grid display="flex" alignItems="center">
                <Typography>Off</Typography>
                <Switch
                  checked={isGroupBy}
                  onChange={(event) => handleSelect(event.target.checked)}
                  inputProps={{ 'aria-label': 'controlled', className: 'sked-test-calendar-group-on-off-toggle' }}
                />
                <Typography>On</Typography>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
};

export default FilterGroup;
