import React from 'react';
import {
  Button,
  FormControl,
  Paper,
  IconButton,
  IconButtonProps,
  CircularProgress,
  Select,
  MenuItem,
  ListItemText,
  Input,
  InputLabel,
  Collapse,
  styled,
  Theme
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchIcon from '@mui/icons-material/Launch';
import { PageHeader } from '../../../../components/PageHeader';
import Header from '../../../../components/PageHeader/PageHeader.component';

import {
  pathOr, isEmpty,
  pipe, propOr, has,
  zip, replace,
} from 'ramda';
import api from '../../../../services/api.js';
import { usePromise } from '../../../../services/promise.hook';
import { useTitle } from '../../../../services/useTitle';

interface Props extends IconButtonProps {
  expand?: boolean
}

const ExpandMore = styled((props: Props) => {
  const { ...other } = props;
  delete other.expand;
  return <IconButton {...other} />;
})(({ theme, expand }: { expand: boolean, theme: Theme }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: '10px',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const SectionTitle = ({ children }: { children: string }) => <PageHeader variant="h6">{children}</PageHeader>;

type keys = {
  'client_id': string;
  'redirect_uri': string;
  'response_type': string;
  'scope': string;
  'state': string;
  'access_type': string;
}

const oauthSignIn = () => {
  const w = window.open(window.location as unknown as string, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes,popup=yes');
  api.post('office/google/oauth', {}).then(({ token }) => {
    const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth?';

    const params = {
      'client_id': '564816287254-q2g54summ0p5209354afqlalfuh4733r.apps.googleusercontent.com',
      'redirect_uri': `${process.env.API_URL}/google/callback`,
      'response_type': 'code',
      'scope': 'https://www.googleapis.com/auth/business.manage',
      'state': 'token=' + token,
      'access_type': 'offline'
    };

    let url = oauth2Endpoint;
    for (const p in params) {
      url = url + p + '=' + params[p as keyof keys] + '&';
    }
    w.location = url;
    w.focus();
  });
};

const getGoogleReviewStuff = () => api.get('officeReviewSettings/google/accounts').then((accounts) => {
  return Promise.all(accounts.map(({ id }: { id: number }) => {
    return api.get(`officeReviewSettings/google/${id}/locations`);
  })).then((locations) => {
    return zip(accounts, locations);
  });
});

type generateGoogleLinkProps = {
  isGet?: boolean;
  accountId: number;
  locationId: number;
}

type Review = {
  accountId: number;
  locationId: number;
}

const generateGoogleLink = ({ isGet = false, accountId, locationId }: generateGoogleLinkProps) => isGet ?
  api.get('/officeReviewSettings/google/review').then((reviews) => {
    const review = reviews.find((r: Review) => r.accountId === accountId && r.locationId === locationId);
    if (review) {
      return review;
    }
    return {};
  })
  :
  api.post('/officeReviewSettings/google/review', {
    accountId,
    locationId,
  });

type Account = {
  id: number;
  accountName: string;
}

const GoogleReviewTabs = ({ accounts }: { accounts: [Account[]] }) => {
  const [accountId, setAccountId] = React.useState(0);
  const [locations, setLocations] = React.useState([]);
  const [locationId, setLocationId] = React.useState(0);
  const [open, setOpen] = React.useState({
    list: true,
    popup: true,
  });

  const generationState = usePromise(generateGoogleLink, {});

  React.useEffect(() => {
    if (accounts && !isEmpty(accounts)) {
      const acc: Account[] = accounts[0];
      const accId = pathOr(0, [0, 'id'], acc);
      setAccountId(accId);
      setLocations(acc[1] as unknown as number[]);
      const locId = pathOr(0, [1, 0, 'id'], acc);
      setLocationId(locId);
      generationState.invoke({ isGet: true, accountId: accId, locationId: locId });
    }
  }, [accounts]);

  const apiUrl = replace(/root-api/, 'api2', process.env.API_URL);
  const srcUrl = replace(/internal/, 'app2', (process.env.APP_URL + '/misc/loader.js'));

  const popupCode = `
<script src="${srcUrl}"
  data-review-token="${generationState.data.token}"
  data-review-api="${apiUrl}"
  data-review-type="popup"></script>
`;

  return (
    <div>
      <FormControl>
        <InputLabel shrink={true}>
          Account
        </InputLabel>
        <Select
          labelId="accounts"
          input={<Input />}
          value={accountId}
          onChange={e => setAccountId(e.target.value as number)}>
          {accounts.map(([{ id, accountName }]) => (
            <MenuItem key={id} value={id}>
              <ListItemText primary={accountName} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <br />
      <br />
      <FormControl>
        <InputLabel shrink={true}>
          Location
        </InputLabel>
        <Select
          labelId="locations"
          input={<Input />}
          value={locationId}
          onChange={e => setLocationId(e.target.value as number)}>
          {locations.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <br />
      <br />
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}>
        {generationState.loading &&
          <CircularProgress />
        }
        {accountId !== 0 && locationId !== 0 &&
          <Button
            variant='contained'
            disabled={generationState.loading}
            onClick={() => generationState.invoke({
              accountId,
              locationId,
            })}
          >
            {generationState.loading ? 'Loading...' : 'Generate'}
          </Button>}
      </div>
      {!generationState.loading && !isEmpty(generationState.data) &&
        <div>
          <br />
          <br />
          <div style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}>
            <SectionTitle>Review List</SectionTitle>
            <ExpandMore
              expand={open.list}
              onClick={() => {
                setOpen({
                  ...open,
                  list: !open.list,
                });
              }}
              aria-expanded={open.list}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </div>
          <Collapse in={open.list}>
            <p>
              Place this script tag anywhere you'd like to put the Google review list:
            </p>
            <pre style={{ overflowX: 'scroll', backgroundColor: 'rgba(0,0,0,0.1)' }}>{`
<script src="${srcUrl}"
    data-review-token="${generationState.data.token}"
    data-review-api="${apiUrl}"
    data-review-type="list"></script>

`}
            </pre>
            <br />
            <p>You can also customize the list by using the <code>::part</code> CSS pseudo-element.</p>
            <pre style={{ overflowX: 'scroll', backgroundColor: 'rgba(0,0,0,0.1)' }}>{`/*
  You can add css to change the layout
*/
sked-review-list {
  height: 400px;
  width: 50%;
  overflow: scroll;
}
/*
You can change the styling inside the list too.
Use the developer tools of your browser to figure out
which parts are available for styling.
*/
sked-review-list::part(review-name) {
  font-size: large;
}`}
            </pre>
          </Collapse>

          <div style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}>
            <SectionTitle>Review Popup</SectionTitle>
            <ExpandMore
              expand={open.popup}
              onClick={() => {
                setOpen({
                  ...open,
                  popup: !open.popup,
                });
              }}
              aria-expanded={open.popup}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </div>
          <Collapse in={open.popup}>
            <p>
              Place this tag in the html body tag on the pages you'd like your the pop up to appear
            </p>
            <pre style={{ overflowX: 'scroll', backgroundColor: 'rgba(0,0,0,0.1)' }}>{popupCode}
            </pre>
            <br />
            <p>You can also customize the list by using the <code>::part</code> CSS pseudo-element.</p>
            <pre style={{ overflowX: 'scroll', backgroundColor: 'rgba(0,0,0,0.1)' }}>{`/*
You can change the styling inside the list too.
Use the developer tools of your browser to figure out
which parts are available for styling.
*/
sked-review-popup::part(review-name) {
  color: red;
}`}
            </pre>
          </Collapse>
        </div>}
    </div>
  );
};


export const GoogleIntegration = () => {
  const googleReviewState = usePromise(getGoogleReviewStuff, []);

  React.useEffect(() => {
    googleReviewState.invoke({});
  }, []);

  useTitle('Google reviews integration');

  return (
    <>
      <Header title='Google Reviews Integration' pageId="google-reviews" helpMaxWidth={1100} />
      <div style={{
        overflow: 'auto',
        padding: 20,
        paddingBottom: 30,
      }}>
        <Paper style={{ padding: '20px' }}>
          <p>
            Click the link below to connect your google account with SKED. Then click Generate and add the scripts to your website to display your google reviews.
          </p>
          {googleReviewState.loading &&
            <>
              <CircularProgress />
              <br />
            </>}
          <Button
            onClick={oauthSignIn}
            disabled={
              pipe(propOr([], 'data'), has('token'))(googleReviewState)
              ||
              googleReviewState.loading
            }
            startIcon={<LaunchIcon />}
            style={{ marginBottom: '15px' }}
          >
            Enable Google reviews
          </Button>
          {!googleReviewState.loading && !isEmpty(googleReviewState.data) &&
            <GoogleReviewTabs
              accounts={googleReviewState.data as unknown as [Account[]]}
            />}
        </Paper>
      </div>
    </>
  );
};

export default GoogleIntegration;
