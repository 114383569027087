import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import CopyIcon from '@mui/icons-material/FileCopy';
import { getInstaller, getEmail } from './installer.actions.jsx';
import { Snackbar, Card, Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import { merge } from 'ramda';
import Header from '../../../../components/PageHeader/PageHeader.component';
import { useSelector } from '../../../../reducers';

const Container = withStyles({
  root: {
    margin: 20
  },
})(Grid);

const Panel = withStyles({
  root: {
    padding: 15,

    '& h2': {
      fontSize: 16
    },
  },
})(Card);

const copy = (patch) => {
  const copyText = document.getElementById('link');
  copyText.select();
  document.execCommand('link');
  patch({ copied: true });
};

const InstallerTemplate = ({
  url,
  email,
  admin,
  copied,
}, patch) => (
  <>
    <Header title='Install SKED' />
    <Container>
      {!admin && <Redirect to='/dashboard' />}
      <Snackbar
        open={copied}
        message='Copied to clipboard'
        autoHideDuration={4000}
        onClose={() => patch({ copied: false })} />
      <Panel>
        <h2>Install SKED</h2>
        <br />
        <div>
          To get started with SKED, please follow the link in your welcome email to schedule an Installation/ Set up Appointment with SKED Support. Or contact SKED support directly at
          <a href={'mailto:info@sked.lilfe'}> info@sked.life.</a>
          <br />
          <br />
          <b>*When prompted for an email and password, use your login email ({email}) and password.</b>
          <br />
          <br />
          <b>**If you have heavily armored anti-virus software or firewalls, you may see a warning as your SKED Installer begins to download and install. Please click through them and allow the installer to download and follow the prompts through the installer.</b>
        </div>
        <br />
        <br />
        {/* <a href={url}>Download here!</a> */}
        <input id='link' value={url} style={{ width: '60%', marginRight: 5 }} />
        <button onClick={() => copy(patch)} style={{ cursor: 'pointer' }}>
          <CopyIcon style={{ fontSize: 14 }} />
        </button>
      </Panel>
    </Container>
  </>
);

const Installer = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    copied: false,
  });

  const props = useSelector(s => ({
    url: s.installer.installer.url,
    email: s.installer.email,
    admin: s.login.admin,
  }));

  const getInit = () => {
    dispatch(getInstaller());
    dispatch(getEmail());
  };

  const patch = (data) => {
    setState(merge(state, data));
  };

  useEffect(() => {
    getInit();
  }, []);
  
  return (InstallerTemplate(merge(props, state), patch));
};

export default Installer;
