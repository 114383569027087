import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  gridContainer: {
    display: 'grid',
    gridGap: '6px', /* adjust the gap to your preference */
    gridTemplateColumns: 'repeat(auto-fit, minmax(170px, 1fr))'
  },
  gridItem: {
    maxWidth: '250px'
  },
  input: {
    width: '100%',

    '& .MuiInputBase-root': {
      borderRadius: 10
    }
  },
  importLabel: {
    fontSize: 16,
    marginRight: 12,
    fontWeight: 500,
    color: '#555555',
  },
  label: {
    fontSize: 14,
    marginBottom: 5,
    fontWeight: 500,
  },
  formControl: {
    width: '100%',
  },
  select: {
    borderRadius: 10,
    minWidth: 210,

    '& .MuiSelect-select': {
      width: '100%',
      minWidth: 42,
      paddingLeft: 10,
      borderRadius: 10,
    },
    '& .MuiInputBase-root': {
      borderRadius: 10,
    }
  }
}));
