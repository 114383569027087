import * as React from 'react';
import { Button } from '@mui/material';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { ComplexWidgetDrawablePicture } from '../intake-types';
import { useIntakeContextAnswer } from '../answers.context';
import SignaturePad from 'signature_pad';
import { type, isEmpty, path, pathOr, has } from 'ramda';
import { DrawAnswer, DrawData } from '../answer-types';

const canvasContainer = {
  position: 'relative',
  flex: 1,
  marginBottom: '15px',
  height: '100%'
} as React.CSSProperties;

const canvasStyle = {
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  borderRadius: '4px',
} as React.CSSProperties;

const bodyStyle = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
} as React.CSSProperties;

interface DrawablePictureProps {
  id: number;
  data: ComplexWidgetDrawablePicture;
  required: boolean;
  printMode: boolean;
  noCtx?: boolean;
  isPrivate?: boolean;
}

const displayImageStyle = {
  position: 'absolute',
  left: 0,
  top: 0,
  borderRadius: '4px',
  width: '100%',
  height: '100%',
} as React.CSSProperties;

const defaultAnswer: DrawAnswer = {
  id: 0,
  type: 'Draw',
  value: null
};

export const DrawablePictureComponent = ({
  id, data, noCtx, isPrivate
}: DrawablePictureProps) => {
  const disabled = !isPrivate;
  const [value, setValue] = noCtx ? [null] : useIntakeContextAnswer<DrawAnswer>(id, defaultAnswer);
  const [imgHeight, setImgHeight] = React.useState(800);
  const [selectedColor, setSelectedColor] = React.useState(pathOr(null, ['options', 0, 'color'], data));
  const [newData, setNewData] = React.useState<DrawData>(null);
  const [displayImage, setDisplayImage] = React.useState<boolean>(false);
  const drawData = React.useRef([]);
  const sigRef = React.useRef(null);
  const imgRef = React.useRef(null);
  const sigPad = React.useRef(null);

  function setContainerHeight() {
    const img = imgRef.current;
    const height = img && (img.naturalHeight / img.naturalWidth * img.width);
    setImgHeight(height);
  }

  function resizeCanvas() {
    setContainerHeight();
    setTimeout(() => {
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      const canvas = sigRef.current;
      if (canvas && imgRef.current) {
        sigRef.current.width = (canvas.offsetWidth * ratio);
        sigRef.current.height = (canvas.offsetHeight * ratio);
        sigRef.current.getContext('2d').scale(ratio, ratio);


        const currentImgWidth = imgRef.current.width;

        const nd = (isEmpty(drawData.current) ? value?.data || [] : drawData.current).map((e: any) => {
          return {
            ...e,
            points: e.points.map((p: any) => ({
              ...p,
              x: p.x * currentImgWidth / e.width,
              y: p.y * currentImgWidth / e.width
            }))
          };
        });
        if (isEmpty(drawData.current) && value?.data) {
          drawData.current = value.data;
        }
        sigPad.current.fromData(nd);
      }
    }, 50);
  }

  React.useEffect(() => {
    if (sigPad.current) {
      resizeCanvas();
    }
    if (type(value) === 'Object' && has('url', value)) {
      setDisplayImage(true);
    }
  }, [value]);


  React.useEffect(() => {
    sigPad.current = new SignaturePad(sigRef.current, {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      penColor: selectedColor
    });

    sigPad.current.addEventListener('endStroke', () => {
      const img = imgRef.current;
      const data = sigPad.current.toData();
      const last = {
        ...data[data.length - 1],
        width: img.width
      };
      if (!drawData.current || typeof drawData.current !== 'object') {
        drawData.current = [last];
      } else {
        drawData.current = drawData.current.concat([last]);
      }
      const height = img && (img.naturalHeight / img.naturalWidth * img.width);
      const width = img && (img.naturalWidth / img.naturalHeight * img.height);
      setNewData({
        data: drawData.current,
        height,
        width,
      });
    });

    imgRef.current.onload = () => {
      setContainerHeight();
    };


    //Need to wait until after
    //render to resize the canvas
    //after form data loads
    if (value && typeof value === 'object' && value.data) {
      sigPad.current.fromData(value.data);
    }
    setTimeout(() => {
      resizeCanvas();
    });
    window.addEventListener('resize', resizeCanvas);
    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, [data]);

  React.useEffect(() => {
    if (newData) {
      setValue(newData);
    }
  }, [newData]);

  const setColor = (color: string) => {
    sigPad.current.penColor = color;
    setSelectedColor(color);
  };

  const clearData = () => {
    sigPad.current.clear();
    drawData.current = [];
    if (type(value) === 'Array') {
      setValue({ data: [] });
      setDisplayImage(false);
    }
    if (typeof value === 'object') {
      setDisplayImage(false);
      setValue({
        ...value,
        url: undefined,
        data: [],
      });
    }
  };

  const sty = {
    ...bodyStyle,
  };

  React.useEffect(() => {
    if (sigPad?.current?.penColor) {
      setSelectedColor(sigPad.current.penColor);
    }
  }, []);

  return (
    <div style={sty}>
      <div style={{
        display: 'flex',
        marginBottom: '15px',
        flexWrap: 'wrap',
      }}>
        {data.options.map(({ color, label }) =>
          <Button
            key={label + color}
            startIcon={selectedColor === color ?
              <RadioButtonCheckedIcon style={{ color }} /> :
              <RadioButtonUncheckedIcon style={{ color }} />}
            style={{ marginRight: '10px', marginTop: '10px' }}
            onClick={() => setColor(color)}
            variant="outlined">
            {label}
          </Button>
        )}
      </div>

      <div style={{ height: imgHeight + 'px' }}>
        <div style={canvasContainer}>
          {data.pictureUrl &&
            <img ref={imgRef} src={data.pictureUrl} style={canvasStyle} />}
          {displayImage && type(value) === 'Object' && has('url', value) &&
            <img src={path<string>(['url'], value)} style={displayImageStyle} />}
          <canvas
            id={`canvas-${id}`}
            style={{
              ...canvasStyle,
              display: disabled ? 'none' : 'unset',
            }}
            ref={sigRef}
          />
        </div>
      </div>

      {!disabled &&
        <div style={{ display: 'flex' }}>
          <div style={{ flexGrow: 1 }}></div>
          <Button
            onClick={clearData}
            variant="contained">
            Clear
          </Button>
        </div>}
    </div>
  );
};
