import * as at from '../../actionTypes';
import {
  merge, evolve, prepend, F,
  map, append, always, pipe,
  assoc, ifElse, has, identity,
  adjust, concat,
} from 'ramda';

const init = {
  busy: false,
  stopManagers: {
    data: [],
    perPage: 50,
    page: 1,
    totalCount: 1,
    totalPage: 1,
  },
  state: 'LIST',
  selectedStopManager: {},
  selectedGroup: {},
  copiedStops: [],
  previousCopy: [],
};

export default (stopmanager = init, action) => {
  switch (action.type) {
    case at.STOPMANAGER_PATCH:
      return merge(stopmanager, action.data);
    case at.STOPMANAGER_REMOTE_GET: {
      if (action.state === 'REQUEST') {
        return merge(stopmanager, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return merge(stopmanager, {
          stopManagers: action.data,
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return merge(stopmanager, {
          busy: false,
        });
      }
      return stopmanager;
    }
    case at.STOPMANAGER_REMOTE_NEW_GROUP_POST: {
      if (action.state === 'REQUEST') {
        return merge(stopmanager, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const newGroup = pipe(
          assoc('isEdit', true),
          ifElse(
            has('stops'),
            identity,
            assoc('stops', []))
        )(action.data);
        return evolve({
          stopManagers: evolve({
            data: prepend(newGroup),
          }),
          busy: F,
        }, stopmanager);
      } else if (action.state === 'ERROR') {
        return merge(stopmanager, {
          busy: false,
        });
      }
      return stopmanager;
    }
    case at.STOPMANAGER_REMOTE_SAVE_POST: {
      if (action.state === 'REQUEST') {
        return merge(stopmanager, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const newStop = action.data[0];
        return evolve({
          busy: F,
          state: always('LIST'),
          stopManagers: evolve({
            data: map((group) => {
              if (group.groupId === newStop.groupId) {
                return ({
                  ...group,
                  stops: append(newStop, group.stops),
                });
              } else {
                return group;
              }
            })
          }),
          selectedStopManager: always({}),
          selectedGroup: always({}),
        }, stopmanager);
      } else if (action.state === 'ERROR') {
        return merge(stopmanager, {
          busy: false,
        });
      }
      return stopmanager;
    }
    case at.STOPMANAGER_REMOTE_SAVE_PUT: {
      if (action.state === 'REQUEST') {
        return merge(stopmanager, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const newStop = action.data;
        return evolve({
          busy: F,
          state: always('LIST'),
          stopManagers: evolve({
            data: map((group) => {
              if (group.groupId === newStop.groupId) {
                return evolve({
                  stops: map((stop) => {
                    if (stop.id === newStop.id) {
                      return newStop;
                    } else {
                      return stop;
                    }
                  }),
                }, group);
              } else {
                return group;
              }
            })
          }),
          selectedStopManager: always({}),
          selectedGroup: always({}),
        }, stopmanager);
      } else if (action.state === 'ERROR') {
        return merge(stopmanager, {
          busy: false,
        });
      }
      return stopmanager;
    }
    case at.STOPMANAGER_REMOTE_COPIED_STOPS_GROUP_POST: {
      if (action.state === 'REQUEST') {
        return merge(stopmanager, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const {
          idx,
          stops,
        } = action.data;
        return evolve({
          busy: F,
          state: always('LIST'),
          stopManagers: evolve({
            data: adjust(idx, evolve({
              stops: concat(stops),
            }))
          }),
          copiedStops: always([]),
        }, stopmanager);
      } else if (action.state === 'ERROR') {
        return merge(stopmanager, {
          busy: false,
        });
      }
      return stopmanager;
    }
    default:
      return stopmanager;
  }
};
