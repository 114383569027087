import React, { useState } from 'react';

import {
  Typography,
  Grid,
  FormLabel,
  FormControl,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import Header from '../../../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import Modal from '../../../../components/Modal/Modal.component';
import SparkImage from '../../../../images/spark-logofull-color.png';
import SkedImage from '../../../../images/settings-illustration.png';
import OpenIcon from '../../../../icons/OpenInNewTab.icon';
import { countries } from '../../../../services/globals';

import { useSelector } from '../../../../reducers';

import { useStyles } from './form.styles';

const PLANS = [
  { name: 'SkedBasic', price: '$89', credits: '5,000', display: 'SKED Basic' },
  { name: 'SkedStandard', price: '$299', credits: '10,000', display: 'SKED Standard' },
  { name: 'SkedPro', price: '$379', credits: '15,000', display: 'SKED Pro' },
  { name: 'SkedBestest', price: '', credits: '20,000', display: 'Very Most Bestest' },
  { name: 'SkedAgency', price: '', credits: '', display: 'SKED Agency' },
];

const MONTHLY_POLICY = '2023-product-contract-monthly';
const YEARLY_POLICY = '2023-product-contract-yearly';

const FORM = {
  plan: '',
  agreement: YEARLY_POLICY,
  promoCode: '',
  addressLine1: '',
  addressLine2: '',
  street: '',
  city: '',
  state: '',
  zipCode: '',
  country: '',
  cardName: '',
  cardNumber: '',
  date: '',
  cvc: '',
};

export type FormType = typeof FORM;

export type StripeData = {
  name: string;
  address_line1: string;
  address_line2: string;
  address_city: string;
  address_state: string;
  address_zip: string;
  address_country: string;
}

export type Body = {
  promo: string;
  stripeToken?: string;
  policy: string;
  plan?: string;
}

type Props = {
  card: Element | React.ReactElement;
  loading: boolean;
  onSubmit: (stripeData: StripeData, body: Body) => void;
  isSpark: boolean;
  finished?: boolean;
  open: boolean;
  setOpen: (value: boolean) => void;
}

const SignUp = ({ card, onSubmit, loading, isSpark, open, setOpen, finished }: Props) => {
  const classes = useStyles();
  const [form, setForm] = useState(FORM);
  const [newAddress, setNewAddress] = useState(false);
  const [hasPromo, setHaspromo] = useState(false);

  const { headerHeight, officeAddress } = useSelector((state) => ({
    headerHeight: state.login.headerHeight,
    officeAddress: state.login.officeAddress,
  }));

  const update = (field: string, value: string) => {
    setForm({ ... form, [field]: value });
  };

  const handleSubmit = async () => {
    const stripeData = {
      'name': form.cardName,
      'address_line1': newAddress ? form.addressLine1 : officeAddress.street,
      'address_line2': newAddress ? form.addressLine2 : '',
      'address_city': newAddress ? form.city : officeAddress.city,
      'address_state': newAddress ? form.state : officeAddress.state,
      'address_zip': newAddress ? form.zipCode : officeAddress.zipCode,
      'address_country': newAddress ? form.country : officeAddress.country,
    };
    const body = {
      promo: form.promoCode,
      policy: form.agreement,
      plan: form.plan,
    };
    onSubmit(stripeData, body);
  };

  return (
    <>
      <Header
        title={isSpark ? 'Upgrade to SKED' : 'Let’s Spark Something'}
        rightIcons={[
          finished && 
            <a target='_blank' href='https://j8rnd0mvt9n.typeform.com/to/AJwSBwcH'>
              <HeaderButton
                title={'Start Onboarding'}
                Icon={OpenIcon}
                color='primary'
                iconClass={classes.icon}
                marginRight={5}
                onClick={() => {
                  setOpen(false);
                }}
              />
            </a>,
          <a rel="noopener noreferrer" target="_blank" href={isSpark ? 'https://sked.life' : 'https://sparkpatients.com'}>
            <HeaderButton
              title={`View ${isSpark ? 'SKED' : 'Spark'} Website`}
              Icon={OpenIcon}
              borderSolid
            />
          </a>
        ]}
      />
      <div className={classes.root} style={{ height: `calc(100vh - ${headerHeight - 45 }px)` }}>
        {finished ? (
          <Typography className={classes.modalText}>
            Welcome aboard! Thank you for choosing {isSpark ? 'SKED' : 'Spark'}. Our team is now setting up your service, and this usually takes between 24 to 48 hours. We're getting everything customized for your office to start generating more new and reactivated patients. We'll be reaching out to you soon with next steps.
          </Typography>
        ) : (
          <>
            {isSpark ? (
              <Typography className={classes.skedText}>
              Liberate your staff, supercharge potential interactions, and revolutionize your practice’s efficiency.
              </Typography>
            ) : (
              <Grid>
                <Typography className={classes.info}>
                  Partnering with <span>The Smart Chiropractor</span>
                </Typography>
                <Typography className={classes.sparkText}>
                  Pay $50 if They Show.
                </Typography>
                <Typography className={classes.info}>
                  <span>54,900+</span> New Patients and <span>26,300+</span> Reactivations
                </Typography>
              </Grid>
            )}
            <Typography className={classes.title}>Sign up for {isSpark ? 'SKED' : 'Spark'}!</Typography>
            <Typography className={classes.text}>
              We automatically link your {isSpark ? 'SKED' : 'Spark'} and {isSpark ? 'Spark' : 'SKED'} accounts to access all your data with one login. Please enter your billing information again, as {isSpark ? 'Spark' : 'SKED'} charges you separately from {isSpark ? 'SKED' : 'Spark'}.
            </Typography>
          
            <Grid className={classes.content}>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <Grid container rowGap={1.5} spacing={1}>
                  {isSpark && (
                    <>
                      <Grid item xs={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <FormLabel className={classes.inputLabel}>Plan</FormLabel>
                          <Select
                            size='small'
                            value={form.plan}
                            required
                            className={classes.selected}
                            onChange={(event) => update('plan', event.target.value)}
                            inputProps={{ className: 'sked-test-spark-sign-up-plan-input' }}
                          >
                            {PLANS.map(({ name, display, credits }) => (
                              <MenuItem key={name} value={name}>
                                {`${display} ${credits ? `includes ${credits} message credits` : ''}`}
                              </MenuItem>
                            ))}
                          </Select>
                          <Typography className={classes.link}>
                            See <a rel="noopener noreferrer" target="_blank" href="https://sked.life/sked-pricing">pricing page</a> for more details about each plan.
                          </Typography>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <FormLabel className={classes.inputLabel}>Agreement Type</FormLabel>
                          <Select
                            size='small'
                            value={form.agreement}
                            required
                            className={classes.selected}
                            onChange={(event) => update('agreement', event.target.value)}
                            inputProps={{ className: 'sked-test-spark-sign-up-agreement-input' }}
                          >
                            <MenuItem value={MONTHLY_POLICY}>Month-to-month</MenuItem>
                            <MenuItem value={YEARLY_POLICY}>Annual Agreement</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <FormLabel className={classes.inputLabel}>Cardholder Name</FormLabel>
                      <TextField
                        className={classes.input}
                        size='small'
                        required
                        placeholder='Cardholder Name'
                        variant='outlined'
                        value={form.cardName}
                        onChange={(event) => update('cardName', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <FormLabel className={classes.inputLabel}>Card Number</FormLabel>
                      {card}
                      <div id="card-errors" role="alert" className={classes.formControl}></div>
                    </FormControl>
                  </Grid>
                </Grid>
                {isSpark && (
                  <Grid marginTop={1} marginBottom={hasPromo ? 0.5 : '-10px'}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={hasPromo}
                          onChange={(e) => setHaspromo(e.target.checked)}
                          name="acknowledge-checkbox-promo"
                        />
                      }
                      label="I have a promo code"
                    />
                  </Grid>
                )}
                {hasPromo && (
                  <Grid item xs={12}>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <FormLabel className={classes.inputLabel}>Promo code</FormLabel>
                      <TextField
                        className={classes.input}
                        size='small'
                        required
                        placeholder='Promo code'
                        variant='outlined'
                        value={form.promoCode}
                        onChange={(event) => update('promoCode', event.target.value)}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid marginY={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        className={(newAddress && !isSpark) ? classes.check : ''}
                        checked={newAddress}
                        onChange={(e) => setNewAddress(e.target.checked)}
                        name="acknowledge-checkbox"
                      />
                    }
                    label="Billing address is different than your office address"
                  />
                </Grid>
                <Grid container rowGap={1.5} spacing={1}>
                  {newAddress && (
                    <>
                      <Grid item xs={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <FormLabel className={classes.inputLabel}>Address Line 1</FormLabel>
                          <TextField
                            className={classes.input}
                            size='small'
                            required
                            placeholder='Address'
                            variant='outlined'
                            value={form.addressLine1}
                            onChange={(event) => update('addressLine1', event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <FormLabel className={classes.inputLabel}>Address Line 2</FormLabel>
                          <TextField
                            className={classes.input}
                            size='small'
                            placeholder='Address'
                            variant='outlined'
                            value={form.addressLine2}
                            onChange={(event) => update('addressLine2', event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={8} md={8}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <FormLabel className={classes.inputLabel}>City</FormLabel>
                          <TextField
                            className={classes.input}
                            size='small'
                            required
                            placeholder='City'
                            variant='outlined'
                            value={form.city}
                            onChange={(event) => update('city', event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={2} md={2}>
                        <FormControl variant="outlined">
                          <FormLabel className={classes.inputLabel}>State</FormLabel>
                          <TextField
                            className={classes.input}
                            size='small'
                            required
                            placeholder='State'
                            variant='outlined'
                            value={form.state}
                            onChange={(event) => update('state', event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={2} md={2}>
                        <FormControl variant="outlined">
                          <FormLabel className={classes.inputLabel}>Zip</FormLabel>
                          <TextField
                            className={classes.input}
                            size='small'
                            required
                            placeholder='Zip'
                            variant='outlined'
                            value={form.zipCode}
                            onChange={(event) => update('zipCode', event.target.value)}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                          <FormLabel className={classes.inputLabel}>Country</FormLabel>
                          <Select
                            size='small'
                            value={form.country}
                            className={classes.selected}
                            onChange={(event) => update('country', event.target.value)}
                            inputProps={{ className: 'sked-test-spark-sign-up-country-input' }}
                          >
                            {countries.map(({ value, label }) => (
                              <MenuItem key={value} value={value}>{label}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </>
                  )}
                </Grid>         
                <Grid marginTop={2} paddingBottom={3}>
                  {isSpark ? (
                    <Typography className={classes.link}>
                    Clicking 'Sign Up' means that you have read and agreed to our <a href="https://sked-v2.s3.amazonaws.com/licenses+/SKED+-+Subscription+Agreement+-+03-25.pdf" target="_blank">Subscription Agreement</a>!
                    </Typography>
                  ) : (
                    <Typography className={classes.link}>
                    Clicking 'Sign Up' means that you have read and agreed to our <a href="https://sked-v2.s3.amazonaws.com/documents/2024-05-Spark+Patients-Terms-of-Service+.pdf" target="_blank">Subscription Agreement</a>!
                    </Typography>
                  )}
                  <Button
                    variant='contained'
                    type='submit'
                    className={isSpark ? classes.skedSubmit : classes.sparkSubmit}
                    disabled={loading}
                  >
                    Sign Up
                  </Button>
                </Grid>
              </form>
            </Grid>
          </>
        )}

        <Modal
          open={open}
          onClose={() => setOpen(false)}
          title='Start Onboarding'
          size='xs'
          maxWidth={800}
        >
          {isSpark ? (
            <Grid display="flex" flexDirection="column">
              <img className={classes.image} src={SkedImage} />
              <Typography className={classes.modalText}>
                Welcome aboard! You are almost ready to start generating more new and reactivated patients. <span>Schedule a call</span> so we can customize {isSpark ? 'SKED' : 'Spark'} for your offices appointment types, availability, etc.
              </Typography>
            </Grid>
          ) : (
            <Grid display="flex" className={classes.sparkContent}>
              <img className={classes.sparkImage} src={SparkImage} />
              <Grid maxWidth="400px">
                <Typography className={classes.modalText}>
                  Welcome to SPARK! You’re <span>one step away</span> from more new patients and patient reactivations. Click the link below to begin onboarding.
                </Typography>
                <a target='_blank' href='https://j8rnd0mvt9n.typeform.com/to/AJwSBwcH'>
                  <HeaderButton
                    title={'Start Onboarding'}
                    Icon={OpenIcon}
                    color='primary'
                    iconClass={classes.icon}
                    className={classes.button}
                    onClick={() => {
                      setOpen(false);
                    }}
                  />
                </a>
              </Grid>
            </Grid>
          )}
        </Modal>
      </div>
    </>
  );
};

export default SignUp;


