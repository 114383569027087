import React, { useState, useEffect, useMemo } from 'react';
import {
  Typography, Stack, Divider, SvgIcon, SvgIconProps, MenuItem,
  SelectChangeEvent, CircularProgress, Snackbar, Alert
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Switch, Select, Autocomplete, TextField,
} from '../../../../components/components.component';
import PageHeader from '../../../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import { AppointmentTypeSelector } from '../../../CalendarV2/components/AppointmentTypeSelect.component';
import TypeSelector from '../../../../components/TypeSelector/type-selector.component';
import { AppointmentType, Professional } from '../../../Appointments/appointments.types';
import { usePromise } from '../../../../services/promise.hook';
import api from '../../../../services/api.js';
import { fetchSettingsList } from '../NewPatientPortal/NewPatientPortal.actions.js';
import { Automation, ChangedSource } from '../../../Automations/automation-types';
import {
  has, cond, equals, T, isEmpty, any, isNil,
} from 'ramda';
import { etd } from '../../../../services/utilities.js';

export const GHLIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg id="Group_16537" data-name="Group 16537" width="24.938" height="24.812" viewBox="0 0 24.938 24.812">
        <path id="Path_9223" data-name="Path 9223" d="M71.051,0,65.807,5.735H76.3Z" transform="translate(-51.358)" fill="#00d300"/>
        <path id="Path_9224" data-name="Path 9224" d="M5.244,0,0,5.735H10.488Z" fill="#ffc300"/>
        <path id="Path_9225" data-name="Path 9225" d="M43.469,41.334H32.981L38.225,35.6Z" transform="translate(-25.739 -27.783)" fill="#0097ff"/>
        <path id="Path_9226" data-name="Path 9226" d="M82.245,26.123V45.2h3.27V29.359l-3.236-3.236Z" transform="translate(-64.186 -20.388)" fill="#00d300"/>
        <path id="Path_9227" data-name="Path 9227" d="M16.437,45.2h3.27V29.393l-3.27-3.27Z" transform="translate(-12.827 -20.388)" fill="#ffc300"/>
        <path id="Path_9228" data-name="Path 9228" d="M48.42,72.981h3.708V65.43L48.42,61.722Z" transform="translate(-37.788 -48.17)" fill="#0097ff"/>
        <path id="Path_9229" data-name="Path 9229" d="M16.437,26.123l3.27,3.27v-3.27Z" transform="translate(-12.828 -20.388)" fill="#e5a700"/>
        <path id="Path_9230" data-name="Path 9230" d="M82.4,26.123l3.236,3.236V26.123Z" transform="translate(-64.305 -20.388)" fill="#00b82a"/>
        <path id="Path_9231" data-name="Path 9231" d="M48.42,61.722l3.708,3.708V61.722Z" transform="translate(-37.788 -48.17)" fill="#0080e3"/>
      </svg>
    </SvgIcon>
  );
};

const getAllTypesPros = () => {
  return api.get('appointmentType').then((types) => {
    return api.get('professional').then((pros) => {
      return { types, pros };
    });
  });
};

const checkGHL = () => {
  return api.get('hl');
};

const updateGHL = (data: Settings) => {
  return api.put('hl/calendars', data);
};

const getCalendars = () => {
  return api.get('hl/calendars');
};

const signIn = () => {
  return api.get('hl/signin');
};

const setupPaths = () => {
  return api.post('hl/setup');
};

interface AutomationWithPathIds {
  pathId: number;
  clientListId: number;
  apptListId: number;
  apptTypeId: number;
  automation: Automation;
}

const getPathAndAutomation = (): Promise<AutomationWithPathIds> => {
  return Promise.all([
    api.get('paths/path/gohighlevel.appt.automation'),
    api.get('paths/path/gohighlevel.appt'),
    api.get('paths/path/gohighlevel.client'),
    api.get('paths/path/gohighlevel.defaultApptTyId'),
  ].map(etd)).then(([autoPath, apptPath, clientPath, apptTypePath]) => {
    return {
      pathId: autoPath.data?.id,
      apptListId: apptPath.data?.resource.AppointmentList.id,
      clientListId: clientPath.data?.resource.ClientList.id,
      apptTypeId: apptTypePath.data?.resource.ApptType.id,
      automation: autoPath.data?.resource.Automation,
    };
  });
};

const savePathAndAutomation = ({
  pathId, automation
}: AutomationWithPathIds): Promise<void> => {
  return (automation.id === 0 ?
    api.post('automations', {
      ...automation,
      actions: automation.actions.map((action) => {
        if (has('AppointmentList', action)) {
          return ({
            AppointmentList: {
              ...action.AppointmentList,
              change: 'Add',
            },
          });
        }
      }),
    }) :
    api.put(`automations/${automation.id}`, {
      ...automation,
      actions: automation.actions.map((action) => {
        if (has('AppointmentList', action)) {
          return ({
            AppointmentList: {
              ...action.AppointmentList,
              change: 'Add',
            },
          });
        }
      }),
    })
  ).then(({ id }) => {
    if (!pathId) {
      return api.post('paths', {
        path: 'gohighlevel.appt.automation'.split('.'),
        resource: {
          Automation: id,
        },
      });
    }
  });
};

const savePathAndApptType = ({
  inboundType, apptListId,
}: AutomationWithPathIdsWithType) => {
  return (apptListId ?
    api.delete('paths/path/gohighlevel.defaultApptTyId', { data: {} })
    :
    Promise.resolve()).then(() => {
    return api.post('paths', {
      path: 'gohighlevel.defaultApptTyId'.split('.'),
      resource: {
        AppointmentType: inboundType.id,
      },
    });
  });
};

const useStyles = makeStyles(() => ({
  headers: {
    fontWeight: 401,
    fontSize: '19px',
  },
  subheaders: {
    fontWeight: 401,
    color: '#464849',
    fontSize: '1.1rem',
    marginBottom: '3px',
  },
  switchLabel: {
    marginTop: '4px !important',
  },
  loginButton: {
    border: 'gray 1px solid',
    borderRadius: '5px',
    padding: '7px 10px',
    width: 'fit-content',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '10px',
    '&:hover': {
      backgroundColor: '#F8F8F8'
    },
  },
  calendarSelect: {
    width: '100%',
    marginTop: '10px',
    marginBottom: '10px',
    backgroundColor: 'white',
  },
  typeSelectorContainer: {
    width: '100%',
  },
  contentContainerJohnson: {
    maxWidth: '700px',
  },
  ghlButtonText: {
    margin: '0px 6px',
    fontSize: '14px',
    fontWeight: '500',
  },
}));

interface Settings {
  calendarId?: string;
  locationId: string;
  enabled: boolean;
}

interface CheckedStatus {
  Settings?: Settings;
  NotSetup?: [];
}

interface Calendar {
  name: string,
  id: string,
  description?: string;
}

const defaultCalendar: Calendar = {
  id: 'undefined',
  name: 'Pick a calendar',
  description: '',
};

interface SyncOption {
  id: number;
  name: string;
}

const syncOptions = [{
  id: 0,
  name: 'Specific Appointment Types',
}, {
  id: 1,
  name: 'Specific New Patient Portals',
}, {
  id: 2,
  name: 'All New Patient Portals',
}, {
  id: 3,
  name: 'All Appointments',
}];

interface Npp {
 newPatientPortalSettingsId: number;
 name?: string;
}

const mkAutomation = (
  autoId: number,
  method: number,
  outbound: AppointmentType[],
  listId: number,
  npps: Npp[],
): Automation => {
  const source = cond([
    [equals(1), () => npps.map(({ newPatientPortalSettingsId }) => {
      return {
        Npp: newPatientPortalSettingsId,
      } as ChangedSource;
    })],
    [equals(2), () => ([{
      AnyNpp: [],
    } as ChangedSource])],
    [T, () => ([] as ChangedSource[])]
  ])(method) as unknown as ChangedSource[];
  const filters = method !== 0 ? [] :
    [{
      isTrue: true,
      predicate: {
        Appointment: {
          AppointmentTypeIds: outbound.map(({ id }) => id),
        },
      },
    }];
  return {
    id: autoId || 0,
    actions: [{
      AppointmentList: {
        change: 'Add',
        listId: {
          Id: listId,
        },
      },
    }],
    enabled: true,
    delay: undefined,
    isEdit: true,
    hidden: true,
    filters,
    trigger: {
      ApptUpdated: {
        changeType: ['Added', 'Rescheduled'],
        source,
      },
    },
    comment: 'Used for syncing with GoHighLevel.',
  };
};

interface AutomationWithPathIdsWithType extends AutomationWithPathIds {
  inboundType: AppointmentType;
}

interface TypePro {
  pros: Professional[];
  types: AppointmentType[];
}

const GoHighLevel = () => {
  const classes = useStyles();
  const [calendar, setCalendar] = useState<Calendar>(null);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [outboundTypes, setOutboundTypes] = useState<AppointmentType[]>(null);
  const [inboundType, setInboundType] = useState<AppointmentType>(null);
  const [syncMethod, setSyncMethod] = useState<SyncOption>(syncOptions[0]);
  const [npps, setNpps] = useState<Npp[]>([]);
  const [snackbar, setSnackbar] = useState<boolean>(false);
  const proTypesState = usePromise<void, TypePro>(getAllTypesPros, { types: [], pros: [] });
  const nppState = usePromise<void, Npp[]>(fetchSettingsList, []);
  const setupState = usePromise<void, CheckedStatus>(checkGHL, { NotSetup: [] });
  const updateState = usePromise<Settings, CheckedStatus>(updateGHL, { NotSetup: [] });
  const pathAutoState = usePromise<void, AutomationWithPathIds>(getPathAndAutomation, null);
  const savePathAutoState = usePromise<AutomationWithPathIds, void>(savePathAndAutomation, null);
  const savePathTypeState = usePromise<AutomationWithPathIdsWithType, void>(savePathAndApptType, null);
  const calendarState = usePromise<void, Calendar[]>(getCalendars, []);
  const signInState = usePromise<void, string>(signIn, '');
  const setupPathsState = usePromise<void, void>(setupPaths, null);

  useEffect(() => {
    proTypesState.invoke();
    nppState.invoke();
    setupState.invoke();
  }, []);

  useEffect(() => {
    const { Settings } = setupState.data;
    if (Settings) {
      calendarState.invoke().then((cals) => {
        const cal = cals.find(({ id }) => id === Settings.calendarId);
        setCalendar(cal);
      });
      pathAutoState.invoke();
      setEnabled(Settings.enabled);
    }
  }, [setupState.data]);

  const toggleFeature = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enabled = e.target.checked;
    if (setupState.data.Settings) {
      updateState.invoke({
        ...setupState.data?.Settings || {},
        locationId: '',
        enabled,
      }).then(({ Settings }) => {
        if (Settings) {
          calendarState.invoke();
          setupPathsState.invoke().then(() => {
            pathAutoState.invoke();
          });
          setEnabled(Settings.enabled);
        }
      });
    } else {
      setEnabled(enabled);
    }
  };

  const calendarList = useMemo(() => {
    return [defaultCalendar].concat(calendarState.data);
  }, [calendarState.data]);

  useEffect(() => {
    /* when they setting up they are to refresh the admin, this makes the
       paths and automation (if able to) when the refresh.
    */
    if (any(isNil, Object.values(pathAutoState.data || {}))) {
      setupPathsState.invoke();
      setOutboundTypes([]);
      return;
    }
    console.log(pathAutoState.data);
    if (pathAutoState.data) {
      const { automation, apptTypeId } = pathAutoState.data;
      if (has('ApptUpdated', automation?.trigger)) {
        const source = automation.trigger.ApptUpdated.source;
        const filters = automation.filters;
        if (!isEmpty(filters)) {
          setSyncMethod(syncOptions[0]);
          if (has('Appointment', filters[0].predicate) &&
              has('AppointmentTypeIds', filters[0].predicate.Appointment)) {
            setOutboundTypes(filters[0].predicate.Appointment.AppointmentTypeIds
              .map((id: number) => {
                const t = proTypesState.data.types.find((type) => type.id === id);
                return t;
              })
              .filter((a) => a)
            );
          } else {
            setOutboundTypes([]);
          }
        } else if (source && has('AnyNpp', source[0])) {
          setSyncMethod(syncOptions[2]);
          setOutboundTypes([]);
        } else if (source) {
          setSyncMethod(syncOptions[1]);
          setNpps(source.map((s) => {
            if (has('Npp', s)) {
              const n = nppState.data.find((npp) => npp.newPatientPortalSettingsId === s.Npp);
              return n;
            }
            return null;
          }));
          setOutboundTypes([]);
        } else {
          setSyncMethod(syncOptions[3]);
          setOutboundTypes([]);
        }
      }
      if (apptTypeId) {
        const t = proTypesState.data.types.find((type) => type.id === apptTypeId);
        setInboundType(t);
      }
    }
  }, [pathAutoState.data]);

  const handleCalendarChange = (e: SelectChangeEvent<string>) => {
    const newId = e.target.value;
    const calendar = calendarList.find(({ id }) => id === newId);
    setCalendar(calendar);
  };

  const handleSyncMethodChange = (e: SelectChangeEvent<string>) => {
    const newId = Number(e.target.value);
    const syncBoi = syncOptions.find(({ id }) => id === newId);
    setSyncMethod(syncBoi);
  };

  const handleNppChange = (event: React.SyntheticEvent<Element, Event>, options: Npp[]) => {
    setNpps(options);
  };


  const handleSignIn = () => {
    const w = window.open(window.location as unknown as string, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes,popup=yes');
    signInState.invoke().then((token: string) => {
      w.location = `${process.env.API_URL}/hl/auth/redirect?token=${token}`;
      w.focus();
    });
  };

  const loggedIn = useMemo(() => {
    return Boolean(setupState.data.Settings);
  }, [setupState.data]);

  useEffect(() => {
    if (setupState.data.Settings?.locationId) {
      setEnabled(true);
    }
  }, [setupState.data]);

  const save = () => {
    updateState.invoke({
      locationId: '',
      enabled,
      calendarId: calendar?.id || setupState.data?.Settings.calendarId,
    });
    const automation = mkAutomation(
      pathAutoState.data?.automation?.id,
      syncMethod.id,
      outboundTypes,
      pathAutoState.data.apptListId,
      npps,
    );
    savePathAutoState.invoke({
      ...pathAutoState.data,
      automation,
    });
    savePathTypeState.invoke({
      ...pathAutoState.data,
      inboundType,
    });
    setSnackbar(true);
  };

  console.log(outboundTypes, proTypesState.data);

  return (
    <>
      <PageHeader
        title='Go High Level Integration'
        pageId='go-high-level'
        rightIcons={[
          (updateState.loading ||
           savePathAutoState.loading ||
           savePathTypeState.loading) &&
           <CircularProgress size={20} style={{ marginRight: '5px' }} />,
          <HeaderButton
            title='Save'
            color='primary'
            onClick={save}
            disabled={updateState.loading || !inboundType || !calendar}
          />
        ]}
      />
      <Snackbar
        open={snackbar}
        autoHideDuration={5000}
        onClose={() => {
          setSnackbar(false);
        }}
        sx={{
          '& .MuiAlert-message': {
            fontSize: '15px',
          },
        }}
      >
        <Alert onClose={() => setSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          Updated GoHighLevel Settings!
        </Alert>
      </Snackbar>
      <div className='page-root' style={{ display: 'flex' }}>
        <div className={classes.contentContainerJohnson}>
          <div style={{ margin: '15px' }}>
            <Typography
              variant="h4"
              gutterBottom
              className={classes.headers}
            >
          General
            </Typography>
            <div style={{
              margin: '15px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
              <div>
                <Typography
                  variant="h5"
                  className={classes.subheaders}
                >
              Enable Integration
                </Typography>
                <Typography variant='body1'>
              Enable this feature to sync appointments two-way, or disable it to pause synchronization.
                </Typography>
              </div>
              {setupState.loading ?
                <CircularProgress size={20} />
                :
                <Stack direction="row" spacing={1} alignItems="center">
                  <Switch
                    checked={enabled}
                    onChange={toggleFeature}/>
                </Stack>}
            </div>
            {enabled &&
          <div style={{ margin: '15px' }}>
            <Typography
              variant="h5"
              className={classes.subheaders}
            >
              Account Linking
            </Typography>
            <Typography variant='body1'>
              Link your GoHighLevel account for two-way appointment
              syncing and enjoy real-time updates. Say goodbye to double
              bookings and scheduling conflicts!
            </Typography>
            <div className={classes.loginButton} onClick={handleSignIn}>
              <GHLIcon style={{ fontSize: '18px' }}/>
              {loggedIn ?
                <b className={classes.ghlButtonText}>GoHighLevel Account Linked</b>
                : <b className={classes.ghlButtonText}>Link GoHighLevel Account</b>}
            </div>
          </div>}
          </div>
          {enabled && loggedIn && <Divider variant='middle' />}
          {enabled && loggedIn &&
        <div style={{ margin: '15px', marginBottom: '100px' }}>
          <Typography
            variant="h4"
            gutterBottom
            className={classes.headers}
          >
          Sync Settings
          </Typography>
          <div style={{ margin: '15px' }}>
            <Typography
              variant="h5"
              className={classes.subheaders}
            >
            GoHighLevel Calendar
            </Typography>
            <Typography variant='body1'>
            Choose which GoHighLevel calendar to integrate for two-way
            appointment synchronization. This list of calendars comes
            from your GoHighLevel account. Add or modify these calendars
            from there.
            </Typography>
            {calendarState.loading ?
              <CircularProgress size={20} />
              :
              <Select
                value={calendar?.id || 'undefined'}
                placeholder='Pick a calendar'
                onChange={handleCalendarChange}
                className={classes.calendarSelect}
              >
                {calendarList.map(({ id, name }) => {
                  return (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>}
            <Typography
              variant="h5"
              className={classes.subheaders}
            >
              Outbound Appointment Syncing Settings
            </Typography>
            <Typography variant='body1'>
              Control how appointments from SKED are sent to GoHighLevel.
              Choose to sync specific types of appointments, certain new
              patient portals, any new patient portal, or opt for syncing
              all appointments to maintain a fully updated schedule.
            </Typography>
            <Select
              value={syncMethod.id}
              onChange={handleSyncMethodChange}
              className={classes.calendarSelect}
            >
              {syncOptions.map(({ id, name }) => {
                return (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
            {syncMethod.id === 0 &&
            <TypeSelector
              types={proTypesState.data.types}
              selected={isNil(outboundTypes) ? null : outboundTypes.map(({ id }) => id)}
              professionals={proTypesState.data.pros}
              onChange={(ids: number[]) => {
                const types = ids.map((i) => {
                  const t = proTypesState.data.types.find(({ id }) => id === i);
                  return t;
                });
                setOutboundTypes(types);
              }}
            />}
            {/*style={{
                width: '100%',
                marginBottom: '10px',
                backgroundColor: 'white',
              }}*/}
            {syncMethod.id === 1 &&
             <Autocomplete
               options={nppState.data}
               value={npps}
               getOptionLabel={(option: Npp) => option.name}
               autoHighlight
               disableCloseOnSelect
               multiple
               onChange={handleNppChange}
               style={{
                 backgroundColor: 'white',
               }}
               renderInput={(params) => (
                 <TextField
                   {...params}
                   placeholder='New Patient Portal'
                 />
               )}
             />}
            <Typography
              variant="h5"
              className={classes.subheaders}
              style={{ marginTop: 10 }}
            >
              Inbound Appointment Type Selector
            </Typography>
            <Typography variant='body1'>
              Control how appointments from GoHighLevel are imported
              to SKED. Select a single appointment type that imported
              appointments will be assigned. This can be used later to
              differentiate patients from GoHighLevel.
            </Typography>
            <AppointmentTypeSelector
              types={proTypesState.data.types || []}
              value={inboundType?.id}
              professionals={proTypesState.data.pros || []}
              onChange={(e: SelectChangeEvent<number>) => {
                const tId = Number(e.target.value);
                const type = proTypesState.data.types.find(({ id }) => id === tId);
                setInboundType(type);
              }}
              newStyle
              selectClassName={classes.calendarSelect}
              formClassName={classes.typeSelectorContainer}
              renderValue={(value: string) => {
                const tId = Number(value);
                const type = proTypesState.data.types.find(({ id }) => id === tId);
                if (!type) {
                  return '(None)';
                }
                const pro = proTypesState.data.pros.find(({ id }) => type?.professionalId === id);
                const withPro = pro ? `with ${pro.firstName} ${pro.lastName}` : '';
                return `${type.name} (${type.internalName}) ${withPro}`;
              }}
            />
          </div>
        </div>}
        </div>
      </div>
    </>
  );
};

export default GoHighLevel;
