import React from 'react';
import { makeStyles } from '@mui/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Row } from '../../../../../components/PageHeader';
import CLAClient from '../../ClientCla.component';
import SKEDJail from './app-settings.component.jsx';
import ClientFormList from './client-forms-list.component';
import ClientUpdates from './client-updates.component.jsx';
import Timeline from './timeline.component';
import { includes, intersection, isEmpty, find, propEq } from 'ramda';

const useStyles = makeStyles((theme) => ({
  listRoot: {
    width: '100%',
    maxWidth: 200,
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    fontSize: '14px !important',
  },
}));

export default function More({ client, features, tz, items, tab, setTab }) {
  const classes = useStyles();
  const [page, setPage] = React.useState('');

  const currentTab = tab ? tab : page;
  const setCurrentTab = setTab ? setTab : setPage;

  const isCheckIn = includes('VWRoom', features);

  React.useEffect(() => {
    const firstItem = items[0];
    if (firstItem) {
      setCurrentTab(firstItem.name);
    }
  }, [items]);

  return (
    <Row style={{ alignItems: 'start' }}>
      {!tab && (
        <div className={classes.listRoot}>
          <List component="nav" aria-label="main mailbox folders">
            {items.map(i => (
              <ListItem
                selected={currentTab === i.name}
                key={i.name}
                button
                className={`sked-test-client-dialog-inner-tab-${i.name.toLowerCase().replace(/ /g, '-')}`}
                onClick={() => setCurrentTab(i.name)}
              >
                <ListItemText primary={i.name} />
              </ListItem>))}
          </List>
        </div>
      )}
      <div style={{ width: '100%' }}>
        {currentTab === 'Client Updates' &&
          <ClientUpdates client={client} tz={tz} open={currentTab === 'Client Updates'} />}
        {currentTab === 'CLA Reports' &&
          <CLAClient client={client} />}
        {currentTab === 'SKED Jail' &&
          <SKEDJail client={client} isCheckIn={isCheckIn} />}
        {currentTab === 'Forms' &&
          <ClientFormList client={client} tz={tz} />}
        {currentTab === 'Timeline' &&
          <Timeline
            client={client}
            tz={tz}
            open={currentTab === 'Timeline'}
          />}
      </div>
    </Row>
  );
}

export const OtherMore = ({
  features,
  items,
  setTab,
  tab,
  showTab = true,
}) => {
  const classes = useStyles();

  const filteredItems = React.useMemo(() => {
    return items.filter(i =>
      intersection(i.features, features).length > 0 || isEmpty(i.features));
  }, [features]);

  React.useEffect(() => {
    const firstItem = filteredItems[0];
    if (firstItem) {
      setTab(firstItem.name);
    }
  }, [filteredItems]);

  const item = find(propEq('name', tab), items);
  return (
    <Row style={{ alignItems: 'start' }}>
      {showTab && (
        <div className={classes.listRoot}>
          <List component="nav" aria-label="main mailbox folders">
            {filteredItems.map(i => (
              <ListItem
                selected={tab === i.name}
                key={i.name}
                button
                onClick={() => setTab(i.name)}
                className={`sked-test-client-dialog-inner-tab-${i.name.toLowerCase().replace(/ /g, '-')}`}
              >
                <ListItemText primary={i.name} classes={{ primary: classes.title }} />
              </ListItem>))}
          </List>
        </div>
      )}
      <div style={{ width: '100%' }}>
        {item && item.component(item.name === tab)}
      </div>
    </Row>
  );
};
