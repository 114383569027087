import React from 'react';
import {
  IconButton
} from '@mui/material';
import UpIcon from '@mui/icons-material/ExpandLess';
import DownIcon from '@mui/icons-material/ExpandMore';
import { swapSectionChild } from '../intake.context';
import { useDispatch } from 'react-redux';

/*
  `questionIndex` is the index of that question for conditionals.
*/

interface OrderButtonType {
  index: number;
  maxIndex: number;
  id: number;
  questionIndex?: number;
}


export const OrderButtons = ({
  index, maxIndex, id, questionIndex
}: OrderButtonType) => {
  const dispatch = useDispatch();
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: index === 0 ? 82 : 'auto',
    }}>
      {index > 0 &&
        <IconButton
          aria-label="move up"
          onClick={() => dispatch(swapSectionChild(id, -1, questionIndex))}>
          <UpIcon fontSize="inherit" />
        </IconButton>}
      {index < maxIndex &&
        <IconButton
          aria-label="move down"
          onClick={() => dispatch(swapSectionChild(id, 1, questionIndex))}>
          <DownIcon fontSize="inherit" />
        </IconButton>}
    </div>
  );
};
