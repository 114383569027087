import React, { useEffect, useState } from 'react';
import * as R from 'ramda';

import TagIcon from '@mui/icons-material/LocalOffer';

import FilterHeader from '../FilterHeader/FilterHeader.component';
import HeaderButton from '../HeaderButton/HeaderButton.component';

import { useStyles } from './filterTags.styles';

import {
  MenuItem,
  Popover,
  Grid,
  Checkbox,
  ListItemText,
  Typography
} from '@mui/material';

type Tag = {
  tagId: number;
  tag: string;
}

type FilterTagsProps = {
  save?: ({ tags }: { tags: Tag[] }) => void;
  setSelectedTags: (tags: Tag[]) => void;
  allTags: Tag[],
  selectedTags: Tag[];
  title?: string;
  onlyIcon?: boolean;
  timeOut?: number;
}

let searchTimeout: NodeJS.Timeout = null;

const FilterTags = ({ setSelectedTags, save, allTags, selectedTags, title, onlyIcon, timeOut = 500 }: FilterTagsProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [tags, setTags] = useState<Tag[]>(selectedTags);

  const handleClose = () => {
    setAnchorEl(null);
    setTags(selectedTags);
  };

  const handleReset = () => {
    setTags([]);
    save?.({ tags: [] });
    setSelectedTags([]);
  };

  const handleSelected = (tag: Tag) => {
    let newTags: Tag[] = [];
    
    if (tags.indexOf(tag) > -1) {
      newTags = R.without([tag], tags);
    } else {
      newTags = [...tags, tag];
    }
    setTags(newTags);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      setSelectedTags(newTags);
      save?.({ tags: newTags });
    }, timeOut);
  };

  useEffect(() => {
    setTags(selectedTags || []);
  }, [selectedTags]);

  return (
    <Grid>
      <Grid marginRight={0.5}>
        <HeaderButton
          title={`${selectedTags?.length > 0 ? selectedTags?.length : ''} ${title || 'Tags'}`}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          Icon={TagIcon}
          active={selectedTags?.length > 0}
          type='filter'
          onlyIcon={onlyIcon}
        />
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid minWidth={260}>
          <FilterHeader
            title={title || 'Tags'}
            reset={handleReset}
            resetDisabled={tags?.length === 0}
          />
          <Grid padding={1} paddingBottom={1} className={classes.list}>
            {allTags.map((tag) => (
              <MenuItem
                key={tag.tagId}
                onClick={() => handleSelected(tag)}
                className={tags.indexOf(tag) > -1 ? classes.selected : classes.selectItem}
                value={tag.tagId}
              >
                <Checkbox checked={tags.indexOf(tag) > -1} />
                <ListItemText className={classes.selectFontSize} primary={tag.tag} />
              </MenuItem>
            ))}
            {allTags.length === 0 && (
              <Typography padding={1}>No Tags</Typography>
            )}
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
};

export default FilterTags;
