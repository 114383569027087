import React from 'react';
import { TipTap } from '../../../../components/Editor/editor.component';
import { Button, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Row } from '../../../../components/PageHeader';

const useStyles = makeStyles(() => ({
  buttonLabel: {
    textTransform: 'none',
    fontWeight: 'normal'
  }
}));

export const HTMLEditor = ({
  value, onChange, placeholders = [], onFocus = null,
}) => {
  const classes = useStyles();

  const editorRef = React.useRef(null);

  const insertPlaceholder = (value) => {
    const range = {
      from: editorRef.current.state.selection.ranges[0].$from.pos,
      to: editorRef.current.state.selection.ranges[0].$to.pos,
    };
    editorRef.current.commands.insertContentAt(range, value, { updateSelection: true });
  };

  return (<div>
    <TipTap
      content={value}
      onChange={onChange}
      setEditor={(editor) => editorRef.current = editor}
      onFocus={onFocus}
    />

    {placeholders.length > 0 &&
      <FormHelperText>Insert Placeholders:</FormHelperText>
    }
    {placeholders.length > 0 && <Row>
      {placeholders.map(({ name, value }) =>
        <Button
          classes={{ label: classes.buttonLabel }}
          style={{ marginRight: '5px' }}
          onClick={() => insertPlaceholder(value)}
          variant="outlined"
          color="primary"
          size="small">
          {name}
        </Button>
      )}
    </Row>}
  </div>);
};
