import React, { useState, useRef } from 'react';
import Modal from '../../Modal/Modal.component';
import HeaderButton from '../../HeaderButton/HeaderButton.component';
import {
  Tab, Tabs, Button, IconButton,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import UnlockIcon from '@mui/icons-material/LockOpen';
import { makeStyles } from '@mui/styles';
import { TextField } from '../../components.component';
import axios from 'axios';
import { skedApi } from '../../../services/api.js';
import { isEmpty, dropLast } from 'ramda';

export interface Size {
  width: number;
  height: number;
}

export interface Image {
  textAlign?: string;
  tab?: number,
  src: string,
  alt?: string,
  title?: string,
  width: string;
  height: string;
}

interface ImageUploadProps {
  open: boolean;
  onClose: () => void;
  save: (url: string, alt: string, size: Size) => void;
  defaultImage?: Image
}

const useStyles = makeStyles(() => ({
  root: {
    width: 400,
    marginTop: -17,
  },
  size: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  uploadBox: {
    height: 164,
    border: 'dashed 2px lightgray',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  sizeBox: {
    width: 150
  },
}));

const uploadAttachment = ({ file }: {file: File }): Promise<string> => {
  const config = {
    headers: {
      'Authorization': skedApi.defaults.headers.common.Authorization,
      'X-As-Office': skedApi.defaults.headers.common['X-As-Office'],
    },
  };
  const formData = new FormData();
  formData.append('attachment', file as Blob, file.name);
  return axios.post(
    `${process.env.API_URL}/attachment/upload`,
    formData,
    config
  ).then(({ data }) => {
    return data.officeAttachments[0]?.attachmentUrl;
  });
};

const ImageUpload = ({ open, onClose, save, defaultImage }: ImageUploadProps) => {
  const classes = useStyles();
  const [url, setUrl] = useState('');
  const [alt, setAlt] = useState('');
  const [size, setSize] = useState<Size>(null);
  const [ratio, setRatio] = useState(1);
  const [lock, setLock] = useState(true);
  const [tab, setTab] = useState('url');
  const [busy, setBusy] = useState(false);
  const buttonRef = useRef(null);

  React.useEffect(() => {
    if (defaultImage && !isEmpty(defaultImage)) {
      setUrl(defaultImage.src);
      setAlt(defaultImage.alt || '');
      try {
        const width = Number(dropLast(2, defaultImage.width));
        const height = Number(dropLast(2, defaultImage.height));
        const r = height / width;
        setSize({ width, height });
        setRatio(r);
      } catch (e) {
        console.log('Failed to parse size of image.', e);
      }
    }
  }, [defaultImage]);

  const handleClose = () => {
    setUrl('');
    setAlt('');
    setSize(null);
    setRatio(1);
    setLock(true);
    onClose();
  };

  const onSave = () => {
    save(url, alt, size);
    handleClose();
  };

  const determineSize = (file: ArrayBuffer | string) => {
    const img = new Image();
    img.id = 'sked_upload_img';
    img.src = file as unknown as string;
    img.style.display = 'none';
    document.body.appendChild(img);
    img.onload = function() {
      setSize({
        width: img.naturalWidth, height: img.naturalHeight,
      });
      setRatio(img.naturalHeight / img.naturalWidth);
      img.remove();
    };
  };

  const upload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      files
    } = e.target;
    const file = files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      determineSize(reader.result);
    };
    reader.readAsDataURL(file);
    setBusy(true);
    uploadAttachment({ file }).then((d) => {
      setTab('url');
      setUrl(d);
      setBusy(false);
    }).catch((e) => {
      console.log(e);
      setBusy(false);
    });
    buttonRef.current.value = null;
  };

  const dropFile = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const {
      files
    } = e.dataTransfer;
    const file = files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      determineSize(reader.result);
    };
    reader.readAsDataURL(file);
    setBusy(true);
    uploadAttachment({ file }).then((d) => {
      setTab('url');
      setUrl(d);
      setBusy(false);
    }).catch((e) => {
      console.log(e);
      setBusy(false);
    });
  };

  const adjustSize = (prop: keyof Size) => (e: React.ChangeEvent<HTMLInputElement>) => {
    let num;
    try {
      num = Number(e.target.value);
    } catch (e) {
      return;
    }
    if (lock) {
      const value = prop === 'width' ? ratio * num : num / ratio;
      const other = (prop === 'width' ? 'height' : 'width') as keyof Size;
      setSize({
        [other]: value,
        [prop]: num,
      } as unknown as Size);
    } else {
      setSize({
        ...size,
        [prop]: num,
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title='Insert Image'
      className='sked-test-html-editor-image-insert-modal'
      size='xs'
      buttons={[
        <HeaderButton
          onClick={onSave}
          title='Insert'
          color='primary'
          disabled={busy}
          className='sked-test-html-editor-image-insert-button-delete'
        />
      ]}
    >
      <div className={classes.root}>
        <Tabs
          indicatorColor="primary"
          scrollButtons="auto"
          variant="scrollable"
          textColor="primary"
          value={tab}
          onChange={(e, val) => setTab(val)}
        >
          <Tab value='url' label='URL' className='sked-test-html-editor-image-url-tab'/>
          <Tab value='upload' label='Upload' className='sked-test-html-editor-image-upload-tab'/>
        </Tabs>
        <br/>
        <div hidden={tab !== 'url'}>
          <TextField
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            fullWidth
            label='URL'
            placeholder='https://mywebsite.com/niceimage.png'
            InputLabelProps={{ shrink: true }}
          />
          <br/>
          <br/>
          <TextField
            value={alt}
            onChange={(e) => setAlt(e.target.value)}
            fullWidth
            label='Alternate Text'
            placeholder='Image of friends laughing'
            InputLabelProps={{ shrink: true }}
          />
          <br/>
          <br/>
          <div className={classes.size}>
            <TextField
              value={size?.height || ''}
              onChange={adjustSize('height')}
              label='Height'
              InputLabelProps={{ shrink: true }}
              className={classes.sizeBox}
            />
            <TextField
              value={size?.width || ''}
              onChange={adjustSize('width')}
              label='Width'
              InputLabelProps={{ shrink: true }}
              className={classes.sizeBox}
            />
            <IconButton onClick={() => setLock(!lock)}>
              {lock ? <LockIcon /> : <UnlockIcon />}
            </IconButton>
          </div>
        </div>
        <div hidden={tab !== 'upload'}>
          <div className={classes.uploadBox} onDrop={dropFile} onDragOver={(e) => e.preventDefault()}>
            <div>Drop an image here</div>
            <input
              accept="image/*,audio/*,video/*,.pdf,.docx,.doc,.vcf"
              style={{ display: 'none' }}
              id="sked-html-editor-image-upload"
              type="file"
              ref={buttonRef}
              onChange={upload}
            />
            <label htmlFor="sked-html-editor-image-upload">
              <Button
                variant='contained'
                color="primary"
                component="span"
                aria-label="Upload Image"
                disabled={busy}
                className='sked-test-html-editor-image-insert-modal-upload'>
                {busy ? 'Loading...' : 'Browse for an image'}
              </Button>
            </label>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ImageUpload;
