import React from 'react';
import {
  Button,
  MenuItem,
  TextField,
  Snackbar,
  Paper,
  Divider,
  TableHead,
  TableBody,
  TableRow,
} from '@mui/material';
import { PopupTemplate } from '../../../../services/Popup.js';
import { PageHeader } from '../../../../components/PageHeader';
import Header from '../../../../components/PageHeader/PageHeader.component';
import Loading from '../../../../components/Loading/Loading.component';
import { TableContainer, HeaderCell, BodyCell } from '../../../../components/CustomTable';
import { Grouping } from './Grouping.component.jsx';
import { cond, map, propOr, includes, equals, T } from 'ramda';

const proItem = (item, select) => (
  <MenuItem
    key={item.id}
    onClick={() => select('defaultPro', item)}
    value={item.id}>
    {item.name}
  </MenuItem>
);

export const GenIntegrationTemplate = ({
  busy,
  integrationPatch,
  integrationMiscPatch,
  status,
  offices,
  office,
  selectOffice,
  proTypes,
  pros,
  defaultPro,
  snackbar,
  save,
  apiKey,
  features,
}, table) => (
  <>
    <Header title='EHR Settings' />
    <div>
      <PopupTemplate />
      {busy && <Loading loading />}
      <Snackbar
        open={snackbar}
        message="Information saved!"
        autoHideDuration={4000}
        onClose={() => integrationMiscPatch('snackbar', false)} />
      {!busy &&
        <div style={{ margin: '20px', marginBottom: '100px' }}>
          {cond([
            [equals('OFFICES'), () => {
              return (
                <Paper style={{ padding: '20px', marginTop: '10px' }}>
                  <PageHeader variant='h6'>Select Office</PageHeader>
                  <TableContainer>
                    <TableHead>
                      <TableRow>
                        <HeaderCell>
                          Name
                        </HeaderCell>
                        <HeaderCell>
                          Address
                        </HeaderCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {map((off) => {
                        const { state, zipCode, street, city } = off.address;
                        return (
                          <TableRow key={off.providerId}>
                            <BodyCell>
                              i<a onClick={() => selectOffice({
                                practiceId: off.providerId,
                                officeId: office.id
                              })}>
                                {off.name}
                              </a>
                            </BodyCell>
                            <BodyCell>
                              {street + ' ' + city + ', ' + state + ' ' + zipCode}
                            </BodyCell>
                          </TableRow>
                        );
                      })(offices)}
                    </TableBody>
                  </TableContainer>
                </Paper>
              );
            }],
            [T, () => {
              return (
                <Paper style={{ padding: '20px', marginTop: '10px' }}>
                  <PageHeader variant='h6'>Defaults</PageHeader>
                  {pros &&
                    <div>
                      <PageHeader variant='h6'>Professional</PageHeader>
                      <TextField
                        select
                        value={propOr(null, 'id')(defaultPro)}>
                        {map(
                          (pro) =>
                            proItem(pro, integrationMiscPatch))(pros)}
                      </TextField>
                    </div>}
                  <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
                  <PageHeader variant='h6'>
                    Appointment Types
                  </PageHeader>
                  {proTypes && table(proTypes, integrationPatch)}
                  {includes('FormsPdf', features) &&
                    <>
                      <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
                      <PageHeader variant='h6'>
                        API Key
                      </PageHeader>
                      <div>
                        <i>This is a one time operation. Once you've input and saved this setting it will remain blank.</i>
                      </div>
                      <TextField
                        label='Genesis API Key'
                        value={apiKey}
                        onChange={(e) => integrationMiscPatch('apiKey', e.target.value)}
                      />
                      <br />
                      <br />
                    </>}
                  <Button
                    variant='contained'
                    onClick={() => {
                      save(proTypes, 'genesis', defaultPro, apiKey);
                    }}>
                    Save
                  </Button>
                </Paper>
              );
            }]])(status)}
          <Grouping />
        </div>}
    </div>
  </>
);
