import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import * as R from 'ramda';

let textTimeout: NodeJS.Timeout;

type OneLineEditorProps = {
  name: string;
  title: string;
  required: boolean;
  messagesPatch: (name: string) => void;
}

const OneLineEditor = (props: OneLineEditorProps) => {
  const [name, setName] = useState(props.name);

  useEffect(() => {
    if (props.name !== '')
      setName(props.name);
  }, [props.name]);

  const error = props.required && R.isEmpty(name);
  return (
    <TextField
      name="message-name"
      label={props.title}
      value={name}
      style={{ width: '100%' }}
      error={error}
      helperText={error ? 'Required' : undefined}
      onChange={(e) => {
        const value = e.target.value;
        setName(value);
        clearTimeout(textTimeout);
        textTimeout = setTimeout(() => {
          props.messagesPatch(value);
        }, 500);
      }}
    /* onChange={(e) => this.setState({name: e.target.value})} */
    />
  );
};

export default OneLineEditor;
