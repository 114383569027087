import * as at from '../../actionTypes';
import * as R from 'ramda';

const init = {
  busy: false,
  aptBusy: false,
  logsBusy: false,
  newApts: [],
  resked: [],
  canceled: [],
  newUsers: [],
  webmodule: { data: [] },
  appointment: {},
  client: {},
  state: 'DASH',
  aptData: [],
  graph: {},
};

export default (dashboard = init, action) => {
  switch (action.type) {
    case at.DASH_GET_STUFF:
      return R.merge(dashboard, action.data);
    case at.DASH_SELECT_APT:
      return R.merge(dashboard, action.data);
    case at.DASH_CLIENT_SELECT:
      return R.merge(dashboard, action.data);
    case at.DASH_REMOTE_APT_GET:
      if (action.state === 'REQUEST') {
        return R.merge(dashboard, {
          state: 'DASH_APT',
          aptBusy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(dashboard, {
          selectedAppointment: action.data.appointment,
          aptBusy: false,
        });
      }
      return dashboard;
    default:
      return dashboard;
  }
};
