import React, { ComponentProps } from 'react';

const ThreePeopleIcon = ({ color, className, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg className={className} id="uuid-361f9602-1cf3-4603-a00d-cdb4c0cc8679" {...props} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <g id="uuid-49a31b13-ca2f-461d-9b09-04903dc20be1" data-name="Group 8822" fill={color || '#FFF'}>
        <path id="uuid-04c5e890-7012-4c2e-8c7f-e136bb646f46" data-name="Path 4674" d="m18.8927,25.9355c-5.8231,2.2497-8.1988,6.697-7.7208,12.9401-.5999-.042-1.1178-.066-1.6418-.12-3.1133-.3385-6.186-.9802-9.1746-1.9157-.2252-.032-.3819-.2406-.3499-.4658.0006-.004.0012-.0081.0019-.0121,0-2.5216-.024-5.0432.014-7.5629.0993-4.6064,3.54-8.4537,8.1068-9.0646.4642-.0608.9317-.0922,1.3998-.094,1.9837-.01,3.9694,0,5.9531,0h.6379c.2485,1.1451.5759,2.2716.9799,3.3715.5245,1.0158,1.1199,1.9934,1.7817,2.9256" />
        <path id="uuid-9a8593cb-f258-47b3-93a4-fa9ada2680a0" data-name="Path 4675" d="m39.6295,39.0196v-2.0557c.0156-1.2526-.1638-2.5-.5319-3.6974-1.0404-3.3488-3.6042-6.0075-6.913-7.1689-.146-.056-.296-.106-.5139-.182,1.6949-1.6594,2.6847-3.9084,2.7636-6.2791h1.3998c1.6917,0,3.3835-.012,5.0732,0,4.5861.1317,8.3917,3.5867,8.9647,8.1388.164,1.6438.092,3.3115.114,4.9693.014,1.1538,0,2.3097.01,3.4635.03.2409-.1113.4704-.3399.5519-1.5851.7537-3.2678,1.282-4.9993,1.5698-1.6418.282-3.3035.4499-5.0352.6799" />
        <path id="uuid-322f0329-96f3-4027-abc5-eee28310384e" data-name="Path 4677" d="m12.5336,4.4868c4.0167-.0067,7.2783,3.2441,7.285,7.2608,0,.0167,0,.0334,0,.0501-.0127.2469-.1184.4798-.296.6519-1.5734,1.3396-2.6475,3.1719-3.0475,5.1992-.065.2237-.2131.414-.4139.5319-3.5253,1.9686-7.979.7066-9.9475-2.8187-1.9686-3.5253-.7066-7.979,2.8187-9.9475,1.1004-.6145,2.341-.934,3.6013-.9277" />
        <path id="uuid-225a1d1e-43d0-4d34-9918-baa4de14616f" data-name="Path 4678" d="m37.5219,4.4528c4.0498.0075,7.3268,3.2967,7.3192,7.3465-.007,3.7413-2.8294,6.8775-6.5494,7.2773-1.2142.1314-2.4418-.0482-3.5675-.5219-.1737-.086-.3019-.2427-.3519-.4299-.3819-2.5574-1.8189-4.8374-3.9614-6.2851-.1352-.1199-.2136-.2912-.216-.4719.2061-3.3394,2.643-6.1175,5.9271-6.757.4639-.074.9339-.106,1.3998-.158" />
        <path id="uuid-5836670c-7eff-4361-b9ac-4b3bd9c27f35" data-name="Path 4676" d="m18.8627,19.3265c.0123-3.6025,2.9428-6.513,6.5453-6.5007,3.6026.0123,6.513,2.9428,6.5007,6.5453-.0123,3.6025-2.9428,6.513-6.5453,6.5007-.0145,0-.0291-.0001-.0436-.0003-3.5854-.0363-6.4693-2.9595-6.457-6.545" />
        <path id="uuid-37ad3f33-e546-4570-a705-f2e01520390a" data-name="Path 4673" d="m36.61,39.7495c0,1.0958-.012,2.1997,0,3.2875.029.2482-.1219.4821-.3599.5579-1.7019.7674-3.5033,1.2918-5.3512,1.5578-2.8864.4585-5.8222.5177-8.7247.176-2.62-.3139-5.2049-.8722-7.7208-1.6678-.2024-.0352-.3379-.2279-.3026-.4302.0002-.0012.0004-.0025.0007-.0037.012-2.3996-.1-4.7993.044-7.1889.3029-4.441,4.0054-7.8818,8.4567-7.8588,2.0457,0,4.0994-.08,6.1351.07,3.2454.1572,6.0842,2.2351,7.2149,5.2812.4179,1.0461.6219,2.1653.5999,3.2915.012.9779,0,1.9577,0,2.9376" />
      </g>
    </svg>
  );
};

export default ThreePeopleIcon;
