/* eslint-disable no-control-regex */
import React, { CSSProperties, useState, useEffect, useRef } from 'react';
import * as R from 'ramda';
import api, { skedApi } from '../../services/api.js';
import axios, { Canceler } from 'axios';
import {
  TextField, Button, Checkbox, FormControlLabel, Tooltip,
  InputAdornment, Popover, Popper, Grow
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import {
  Attachment, Info, Search, Close, Undo, Redo,
  FormatBold, FormatItalic, FormatUnderlined, StrikethroughS, Superscript, Subscript,
  FormatClear, FormatListBulleted, FormatListNumbered, HorizontalRule, KeyboardReturn,
  FormatAlignLeft, FormatAlignCenter, FormatAlignRight, Image, Mood, FormatIndentIncrease,
  FormatIndentDecrease, Link, LinkOff, Code, Preview, MoreVert,
} from '@mui/icons-material';
import FormatTextColorIcon from '../../icons/FormatTextColor.icon';
import FormatHighlightIcon from '../../icons/FormatHighlight.icon';
import Picker from 'emoji-picker-react';
import { popup } from '../../services/Popup.js';
import { AttachmentType } from '../../routes/Templates/routes/TemplatesEdit/templates-edit.reducer';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import ImageEx from './components/Image.extension';
import CenterEx from './components/Center.extension';
import Tab from './components/Tab.extension';
import Styles from './components/Styles.extension';
import FontTag from './components/Font.extension';
import SpanTag from './components/Span.extension';
import DivTag from './components/Div.extension';
import Repeater from './components/Repeater.extension';
import Layout from './components/Layout.extension';
// import Comment from './components/Comment.extension';
import BackgroundColor from './components/BackgroundColor.extension';
import FontSize from './components/FontSize.extension';
import Underline from '@tiptap/extension-underline';
import Dropcursor from '@tiptap/extension-dropcursor';
import SuperscriptEx from '@tiptap/extension-superscript';
import SubscriptEx from '@tiptap/extension-subscript';
import TextAlign from '@tiptap/extension-text-align';
import LinkEx from '@tiptap/extension-link';
import Strike from '@tiptap/extension-strike';
import { Level } from '@tiptap/extension-heading';
import { useEditor, EditorContent, Editor as TEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableHeader from '@tiptap/extension-table-header';
import TableCell from '@tiptap/extension-table-cell';
import { PhotoshopPicker } from 'react-color';
import Modal from '../Modal/Modal.component';
import HeaderButton from '../HeaderButton/HeaderButton.component';
import ImageUpload, { Size, Image as DefaultImage } from './components/ImageUpload.component';
import Linker from './components/Linker.component';
import './editor.css';

const btnWidth = 34;

const useMenuStyles = makeStyles(() => ({
  menu: {
    display: 'flex',
    border: 'solid 1px lightgray',
    borderBottom: 'unset',
    backgroundColor: 'white',
  },
  btn: {
    alignItems: 'center',
    background: '0 0',
    border: 0,
    borderRadius: 3,
    boxShadow: 'none',
    color: '#222f3e',
    display: 'flex',
    flex: '0 0 auto',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    height: 34,
    justifyContent: 'center',
    margin: '2px 0 3px 0',
    outline: 0,
    overflow: 'hidden',
    padding: 0,
    textTransform: 'none',
    width: btnWidth,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#ebebeb',
    },
    '&:disabled': {
      backgroundColor: 'white',
    },
  },
  select: {
    alignItems: 'center',
    background: '0 0',
    border: 0,
    borderRadius: 3,
    boxShadow: 'none',
    color: '#222f3e',
    display: 'flex',
    flex: '0 0 auto',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
    height: 34,
    justifyContent: 'center',
    margin: '2px 0 3px 0',
    outline: 0,
    overflow: 'hidden',
    padding: 0,
    paddingLeft: 5,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#ebebeb',
    },
    '&:disabled': {
      backgroundColor: 'white',
    },
  },
  enabled: {
    color: '#222f3e',
    fontSize: 20,
  },
  disabled: {
    color: 'rgba(34,47,62,.5)',
    fontSize: 20,
  },
  active: {
    background: '#c8cbcf',
  },
  iconScale: {
    scale: 0.65,
  },
  divider: {
    borderColor: 'rgba(0,0,0,0.4)',
    marginRight: 3,
    marginLeft: 3,
  },
  buttonSection: {
    display: 'flex',
    border: 'solid 0.5px lightgray',
    padding: '0px 3px',
    '&:last-child': {
      flexGrow: 1,
    },
  },
  colorPicker: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1301,
  },
}));

interface MenuBarProps {
  editor: TEditor;
  defaultWidth?: number;
  onChange: (s: string) => void;
  zIndex?: number;
}

const bodyOptions = [
  'Paragraph', 'Heading 1', 'Heading 2', 'Heading 3', 'Heading 4',
  'Heading 5', 'Heading 6'
];

const fontSizeOptions = [
  '8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt',
];

const getBody = (editor: TEditor, bodyType: string) => {
  if (bodyType) {
    const [tag, level] = bodyType.split(' ');
    if (level) {
      editor.chain().focus().toggleHeading({ level: Number(level) as Level }).run();
    } else {
      if (tag === 'Paragraph')
        editor.chain().focus().setParagraph().run();
    }
    if (editor.getAttributes('textStyle').fontSize) {
      editor.chain().focus().unsetFontSize().run();
    }
  } else {
    let activeT;
    bodyOptions.every((t) => {
      const [tag, level] = t.split(' ');
      let notActive = true;
      if (level) {
        notActive = !editor.isActive(tag.toLowerCase(), { level: Number(level) });
      } else {
        notActive = !editor.isActive(tag.toLowerCase());
      }
      if (!notActive)
        activeT = t;
      return notActive;
    });
    return activeT;
  }
};

interface ColorPickerProps {
  header: string;
  defaultColor: string;
  onSave: (color: string) => void;
  onClose: () => void;
}

const ColorPicker = ({ header, defaultColor, onSave, onClose }: ColorPickerProps) => {
  const [color, setColor] = React.useState(defaultColor);
  React.useEffect(() => {
    setColor(defaultColor);
  }, [defaultColor]);
  return (
    <PhotoshopPicker
      header={header}
      color={color || 'black'}
      onChange={(c) => {
        setColor(c.hex);
      }}
      onCancel={() => onClose()}
      onAccept={() => onSave(color)}
    />
  );
};

interface ButtonSectionProps {
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
  width: number;
  style?: object;
}

const ButtonSection = ({ children, style = {} }: ButtonSectionProps) => {
  const classes = useMenuStyles();
  return (
    <div className={classes.buttonSection} style={style}>
      {children}
    </div>
  );
};

const MenuBar = ({
  editor, defaultWidth = 600, onChange, zIndex = 100,
}: MenuBarProps) => {
  const classes = useMenuStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMenuEl, setAnchorMenuEl] = React.useState(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [state, setState] = React.useState<string>(null);
  //  const [shown, setShown] = React.useState<JSX.Element[]>([]);
  //  const [menued, setMenued] = React.useState<JSX.Element[]>([]);
  const [currentWidth, setCurrentWidth] = React.useState<number>(0);
  const [index, setIndex] = React.useState<number>(0);
  const menuRef = React.useRef(null);

  const handleSave = (newHtml: string) => {
    setState(null);
    editor.commands.setContent(newHtml);
    onChange(newHtml);
  };

  const saveImage = (url: string, alt: string, size: Size) => {
    editor.chain().focus().setImage({
      src: url, alt,
      width: size?.width ? `${size?.width}px` : undefined,
      height: size?.height ? `${size?.height}px` : undefined,
    }).run();
  };

  const saveLink = (url: string, text: string) => {
    const from = editor.state.selection.ranges[0].$from.pos;
    const properUrl = url.includes('http://') || url.includes('https://') ? url : `https://${url}`;
    if (text) {
      editor.commands.insertContentAt(
        editor.state.selection.ranges[0].$from.pos,
        text,
        { updateSelection: false }
      );
      editor.commands.setTextSelection({ from, to: from + text.length });
    }
    editor.chain().focus().extendMarkRange('link').setLink({ href: properUrl }).run();
  };

  const allButtons = [
    <ButtonSection width={75} key='undo'>
      <button
        title='Undo'
        className={classes.btn}
        type='button'
        onClick={() => {
          editor.chain().focus().undo().run();
        }}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .undo()
            .run()
        }
      >
        <Undo className={editor.can()
          .chain()
          .focus()
          .undo()
          .run() ? classes.enabled : classes.disabled}/>
      </button>
      <button
        title='Redo'
        type='button'
        className={classes.btn}
        onClick={() => {
          editor.chain().focus().redo().run();
        }}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .redo()
            .run()
        }
      >
        <Redo className={editor.can()
          .chain()
          .focus()
          .redo()
          .run() ? classes.enabled : classes.disabled}/>
      </button>
    </ButtonSection>,
    <ButtonSection width={193} key='text-options'>
      <select
        title='Text Type'
        className={classes.select}
        value={getBody(editor, null)}
        onChange={(e) => {
          getBody(editor, e.target.value);
        }}>
        {bodyOptions.map((v) => (
          <option value={v}>{v}</option>
        ))}
      </select>
      <button
        title='Text Color'
        type='button'
        onClick={() => {
          setState('COLOR');
        }}
        className={classes.btn}
      >
        <FormatTextColorIcon
          className={classes.iconScale}
          barColor={editor.getAttributes('textStyle').color || 'black'}
        />
      </button>
      <select
        title='Font Size'
        className={classes.select}
        value={editor.getAttributes('textStyle').fontSize || '10pt'}
        onChange={(e) => {
          editor.chain().focus().setFontSize(e.target.value).run();
        }}
      >
        {fontSizeOptions.map((f) => {
          return (
            <option value={f}>{f}</option>
          );
        })}
      </select>
    </ButtonSection>,
    <ButtonSection width={143} key='text-styles'>
      <button
        title='Bold'
        type='button'
        onClick={() => {
          editor.chain().focus().toggleBold().run();
        }}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleBold()
            .run()
        }
        className={classes.btn + ' ' + (editor.isActive('bold') ? classes.active : '')}
      >
        <FormatBold className={editor.can()
          .chain()
          .focus()
          .toggleBold()
          .run() ? classes.enabled : classes.disabled}/>
      </button>
      <button
        title='Italic'
        type='button'
        onClick={() => {
          editor.chain().focus().toggleItalic().run();
        }}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleItalic()
            .run()
        }
        className={classes.btn + ' ' + (editor.isActive('italic') ? classes.active : '')}
      >
        <FormatItalic className={editor.can()
          .chain()
          .focus()
          .toggleItalic()
          .run() ? classes.enabled : classes.disabled}/>
      </button>
      <button
        title='Underline'
        type='button'
        onClick={() => {
          editor.chain().focus().toggleUnderline().run();
        }}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleUnderline()
            .run()
        }
        className={classes.btn + ' ' + (editor.isActive('underline') ? classes.active : '')}
      >
        <FormatUnderlined className={editor.can()
          .chain()
          .focus()
          .toggleUnderline()
          .run() ? classes.enabled : classes.disabled}/>
      </button>
      <button
        title='Clear Format'
        type='button'
        className={classes.btn}
        onClick={() => {
          /* editor.chain().focus().clearNodes().run(); */
          editor.chain().focus().unsetAllMarks().run();
        }}>
        <FormatClear className={classes.enabled}/>
      </button>
    </ButtonSection>,
    <ButtonSection width={109} key='text-align'>
      <button
        title='Align Left'
        type='button'
        onClick={() => {
          editor.chain().focus().setTextAlign('left').run();
        }}
        className={classes.btn + ' ' + (editor.isActive({ textAlign: 'left' }) ? classes.active : '')}
      >
        <FormatAlignLeft className={classes.enabled}/>
      </button>
      <button
        title='Align Center'
        type='button'
        onClick={() => {
          editor.chain().focus().setTextAlign('center').run();
        }}
        className={classes.btn + ' ' + (editor.isActive({ textAlign: 'center' }) ? classes.active : '')}
      >
        <FormatAlignCenter className={classes.enabled}/>
      </button>
      <button
        title='Align Right'
        type='button'
        onClick={() => {
          editor.chain().focus().setTextAlign('right').run();
        }}
        className={classes.btn + ' ' + (editor.isActive({ textAlign: 'right' }) ? classes.active : '')}
      >
        <FormatAlignRight className={classes.enabled}/>
      </button>
    </ButtonSection>,
    <ButtonSection width={41} key='emoji'>
      <button
        title='Insert Emoji'
        type='button'
        className={classes.btn}
        onClick={(e) => {
          setState('EMOJI');
          setAnchorEl(e.currentTarget);
        }}
      >
        <Mood className={classes.enabled}/>
      </button>
    </ButtonSection>,
    <ButtonSection width={75} key='lists'>
      <button
        title='Bulleted List'
        type='button'
        onClick={() => {
          editor.chain().focus().toggleBulletList().run();
        }}
        className={classes.btn + ' ' + (editor.isActive('bulletlist') ? classes.active : '')}
      >
        <FormatListBulleted className={editor.can()
          .chain()
          .focus()
          .toggleBulletList()
          .run() ? classes.enabled : classes.disabled}/>
      </button>
      <button
        title='Numbered List'
        type='button'
        onClick={() => {
          editor.chain().focus().toggleOrderedList().run();
        }}
        className={classes.btn + ' ' + (editor.isActive('orderedlist') ? classes.active : '')}
      >
        <FormatListNumbered className={editor.can()
          .chain()
          .focus()
          .toggleOrderedList()
          .run() ? classes.enabled : classes.disabled}/>

      </button>
    </ButtonSection>,
    <ButtonSection width={75} key='indent'>
      <button
        title='Indent'
        type='button'
        onClick={() => {
          const padding = (editor.state.selection.$head.parent.attrs.tab || 0) + 40;
          editor.chain().focus().setTab(padding).run();
        }}
        className={classes.btn + ' ' + (editor.state.selection.$head.parent.attrs.tab > 0 ? 'is-active' : '')}
      >
        <FormatIndentIncrease className={classes.enabled}/>
      </button>
      <button
        title='Un-Indent'
        type='button'
        className={classes.btn}
        onClick={() => {
          const tab = (editor.state.selection.$head.parent.attrs.tab || 0);
          const padding = tab > 0 ? tab - 40 : 0;
          editor.chain().focus().setTab(padding).run();
        }}
        disabled={editor.isActive({ tab: 0 })}
      >
        <FormatIndentDecrease className={editor.isActive({ tab: 0 }) ? classes.disabled : classes.enabled}/>
      </button>
    </ButtonSection>,
    <ButtonSection width={143} key='insert-crap'>
      <button
        title='Insert Image'
        type='button'
        className={classes.btn}
        onClick={() => {
          setState('IMAGE');
        }}
      >
        <Image className={classes.enabled}/>
      </button>
      <button
        title='Insert Link'
        type='button'
        onClick={() => {
          setState('LINK');
        }}
        className={classes.btn + ' ' + (editor.isActive('link') ? classes.active : '')}
      >
        <Link />
      </button>
      <button
        title='Remove Link'
        type='button'
        className={classes.btn}
        onClick={() => {
          editor.chain().focus().unsetLink().run();
        }}
        disabled={!editor.isActive('link')}
      >
        <LinkOff
          className={editor.isActive('link') ? classes.enabled : classes.disabled}
        />
      </button>
    </ButtonSection>,
    <ButtonSection width={75} key='random'>
      <button
        title='Horizontal Line'
        type='button'
        className={classes.btn}
        onClick={() => {
          editor.chain().focus().setHorizontalRule().run();
        }}>
        <HorizontalRule className={classes.enabled} />
      </button>
      <button
        title='Hard Return'
        type='button'
        className={classes.btn}
        onClick={() => {
          editor.chain().focus().setHardBreak().run();
        }}>
        <KeyboardReturn className={classes.enabled} />
      </button>
    </ButtonSection>,
    <ButtonSection width={64} key='extra-text-styles-1'>
      <button
        title='Highlight Color'
        type='button'
        onClick={() => {
          setState('BCOLOR');
        }}
        className={classes.btn}
      >
        <FormatHighlightIcon
          className={classes.iconScale}
          barColor={editor.getAttributes('textStyle').backgroundColor || 'white'}
        />
      </button>
      <button
        title='Strikethrough'
        type='button'
        onClick={() => {
          editor.chain().focus().toggleStrike().run();
        }}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleStrike()
            .run()
        }
        className={classes.btn + ' ' + (editor.isActive('strike') ? classes.active : '')}
      >
        <StrikethroughS className={editor.can()
          .chain()
          .focus()
          .toggleStrike()
          .run() ? classes.enabled : classes.disabled}/>
      </button>
    </ButtonSection>,
    <ButtonSection width={64} key='extra-text-styles-2'>
      <button
        title='Superscript'
        type='button'
        onClick={() => {
          editor.chain().focus().toggleSuperscript().run();
        }}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleSuperscript()
            .run()
        }
        className={classes.btn + ' ' + (editor.isActive('superscript') ? classes.active : '')}
      >
        <Superscript className={editor.can()
          .chain()
          .focus()
          .toggleSuperscript()
          .run() ? classes.enabled : classes.disabled}/>
      </button>
      <button
        title='Subscript'
        type='button'
        onClick={() => {
          editor.chain().focus().toggleSubscript().run();
        }}
        disabled={
          !editor.can()
            .chain()
            .focus()
            .toggleSubscript()
            .run()
        }
        className={classes.btn + ' ' + (editor.isActive('subscript') ? classes.active : '')}
      >
        <Subscript className={editor.can()
          .chain()
          .focus()
          .toggleSubscript()
          .run() ? classes.enabled : classes.disabled}/>
      </button>
      {/*<button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={classes.btn + ' ' + (editor.isActive('codeBlock') ? classes.active : '')}
      >
        code block
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={classes.btn + ' ' + (editor.isActive('blockquote') ? classes.active : '')}
      >
        blockquote
      </button>*/}
    </ButtonSection>,
    <ButtonSection width={64} key='utility'>
      <button
        title='Edit HTML'
        className={classes.btn}
        type='button'
        onClick={() => {
          setState('HTML');
        }}
      >
        <Code className={classes.enabled} />
      </button>
      <button
        title='Preview'
        className={classes.btn}
        type='button'
        onClick={() => {
          setState('VHTML');
        }}
      >
        <Preview className={classes.enabled} />
      </button>
    </ButtonSection>,
  ];

  const callback = (node: any) => {
    if (!menuRef?.current) {
      menuRef.current = node;
    }
    let total = 0;
    const lastIdx = allButtons.length - 1;
    allButtons.every((butt: JSX.Element, idx: number) => {
      total += butt.props.width;
      /* 42 is the size of the menu button */
      if (total + (idx === lastIdx ? 0 : 42) >= (node?.offsetWidth || defaultWidth)) {
        return false;
      } else {
        setIndex(idx);
        return true;
      }
    });
    setCurrentWidth(node?.offsetWidth);
  };

  const menuCallback = React.useCallback(callback, [defaultWidth]);

  if (menuCallback && menuRef?.current && currentWidth !== menuRef.current.offsetWidth) {
    menuCallback(menuRef.current);
  }

  if (!editor) {
    return null;
  }

  return (
    <div className={classes.menu} ref={menuCallback} style={{ maxWidth: defaultWidth }}>
      <HtmlModal
        open={(state === 'HTML' || state === 'VHTML')}
        onClose={() => setState(null)}
        onSave={handleSave}
        body={editor.getHTML()}
        edit={state === 'HTML'}
      />
      <Popover
        id='emoji-popover'
        open={state === 'EMOJI'}
        anchorEl={anchorEl}
        onClose={() => setState(null)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Picker onEmojiClick={(event, { emoji }) => {
          const range = {
            from: editor.state.selection.ranges[0].$from.pos,
            to: editor.state.selection.ranges[0].$to.pos,
          };
          editor.commands.insertContentAt(range, emoji, { updateSelection: true });
          setState(null);
        }}
        />
      </Popover>
      <Popper
        id='extra-menu'
        open={openMenu}
        anchorEl={anchorMenuEl}
        placement='top-end'
        sx={{
          zIndex,
        }}
        modifiers={[
          {
            name: 'offset',
            enabled: true,
            options: {
              offset: [0, 3],
            },
          },
        ]}
        transition
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={350} style={{
            transformOrigin: '100%',
          }}>
            <div className={classes.menu} style={{ flexWrap: 'wrap' }}>
              {R.drop(index + 1, allButtons).map((butt) => {
                const modButt = {
                  ...butt,
                  props: {
                    ...butt.props,
                    style: {
                      ...butt.props.style,
                      flexGrow: 1,
                    }
                  }
                };
                return modButt;
              })}
            </div>
          </Grow>
        )}
      </Popper>
      <ImageUpload
        open={state === 'IMAGE'}
        onClose={() => setState(null)}
        defaultImage={editor.getAttributes('image') as DefaultImage}
        save={saveImage}
      />
      <Linker
        open={state === 'LINK'}
        onClose={() => setState(null)}
        promptText={
          editor.state.selection.ranges[0].$from.pos
           === editor.state.selection.ranges[0].$to.pos && !editor.isActive('link')}
        defaultUrl={editor.getAttributes('link').href}
        save={saveLink}
      />
      {(state === 'COLOR' || state === 'BCOLOR') &&
        <div className={classes.colorPicker}>
          <ColorPicker
            header={state === 'COLOR' ? 'Select Text Color' : 'Select Background Color'}
            defaultColor={state === 'COLOR' ? editor.getAttributes('textStyle').color :
              editor.getAttributes('textStyle').backgroundColor}
            onSave={(color) => {
              if (state === 'COLOR')
                editor.chain().focus().setColor(color).run();
              else
                editor.chain().focus().setBackgroundColor(color).run();
              setState(null);
            }}
            onClose={() => setState(null)}
          />
        </div>}
      {index > 0 ? R.take(index + 1, allButtons) : allButtons}
      {!R.isEmpty(R.drop(index + 1, allButtons)) &&
        <ButtonSection width={75} key='more'>
          <button
            title='More'
            className={classes.btn}
            type='button'
            onClick={(e) => {
              if (openMenu) {
                setOpenMenu(false);
                setAnchorMenuEl(null);
              } else {
                setAnchorMenuEl(e.currentTarget);
                setOpenMenu(true);
              }
            }}
          >
            <MoreVert/>
          </button>
        </ButtonSection>}
    </div>
  );
};

const CustomTable = Table.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      class: null,
      role: null,
    };
  },
});
const CustomTableRow = TableRow.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      class: null,
    };
  },
});
const CustomTableHeader = TableHeader.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      class: null,
    };
  },
});
const CustomTableCell = TableCell.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      class: null,
      width: null,
      height: null,
      bgcolor: null,
      align: null,
    };
  },
});
const CustomImage = ImageEx.extend({
  addAttributes() {
    return {
      ...this.parent?.(),
      class: null,
    };
  },
});

const extensions = [
  StarterKit,
  TextStyle,
  Color,
  Dropcursor,
  CustomImage.configure({
    inline: true,
  }),
  Underline,
  SuperscriptEx,
  SubscriptEx,
  TextAlign.configure({
    types: ['heading', 'paragraph', 'image', 'table', 'tableRow', 'tableCell']
  }),
  Tab.configure({
    types: ['heading', 'paragraph', 'image']
  }),
  FontSize,
  LinkEx.configure({
    openOnClick: false,
    autolink: false,
  }),
  BackgroundColor,
  Strike,
  Styles.configure({
    types: [
      'heading', 'paragraph', 'image', 'orderedList', 'bulletList',
      'link', 'table', 'tableRow', 'tableCell', 'center', 'div',
    ]
  }),
  CustomTable,
  CustomTableRow,
  CustomTableHeader,
  CustomTableCell,
  CenterEx,
  DivTag,
  SpanTag,
  FontTag,
  Repeater,
  Layout,
//   Comment,
];

interface HtmlModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (b: string) => void;
  body: string;
  edit: boolean;
}

const HtmlModal = ({
  open, onClose, onSave, body, edit = true
}: HtmlModalProps) => {
  const [lBody, setLbody] = React.useState(body);

  React.useEffect(() => {
    setLbody(body);
  }, [body]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={edit ? 'Edit HTML Source' : 'Preview'}
      className='sked-test-html-editor-source-edit-modal'
      size='md'
      buttons={[
        edit &&
          <HeaderButton
            onClick={() => onSave(lBody)}
            title='Save'
            color='primary'
            className='sked-test-html-editor-source-edit-modal-button-delete'
          />
      ]}
    >
      {edit &&
      <TextField
        label='HTML'
        variant='outlined'
        value={lBody}
        onChange={(e) => setLbody(e.target.value)}
        fullWidth
        multiline
        minRows={20}
        style={{ width: '100%' }}
      />}
      {!edit &&
      <div dangerouslySetInnerHTML={{ __html: body }}></div>}
    </Modal>
  );
};

interface TipTapProps {
  content: string;
  onChange: (s: string) => void;
  setEditor?: (e: TEditor) => void;
  className?: string;
  width?: number | string; // For best results when a string, use px or %
  height?: number | string;
  minHeight?: number | string;
  onFocus?: (e: object) => void;
  editorMenuIndex?: number; // for when the editor is in a modal set to 1301
}

export const TipTap = ({
  content, onChange, setEditor, className, width = '100%',
  height = 500, minHeight = 'unset', onFocus = null,
  editorMenuIndex = 100,
}: TipTapProps) => {
  const [bodyTag, setBodyTag] = React.useState('');
  const [maxWidth, setMaxWidth] = React.useState(null);
  const [localWidth, setLocalWidth] = React.useState(width);
  const [ref, setRef] = React.useState(null);
  const editor = useEditor({
    extensions,
    content,
    onUpdate: ({ editor }) => {
      let ct = editor.getHTML();
      if (bodyTag !== '' && !content.includes('<body')) {
        const html = bodyTag.includes('<html') ? '</html>' : '';
        ct = `${bodyTag}${ct}</body>${html}`;
      }
      onChange(ct);
    },
    onFocus: ({ editor }) => {
      if (onFocus) {
        onFocus(editor);
      }
    },
  });

  React.useEffect(() => {
    if (setEditor)
      setEditor(editor);
    /* To make it so that the editor resizing surrounding components
       we must assign the height to the inner content.
    */
    if (editor) {
      const val = typeof height === 'string' ? height : height - 42;
      editor.view.dom.style.height = `${val}px`;
    }
  }, [editor]);

  React.useEffect(() => {
    if (editor && !R.isNil(content) && editor.getHTML() !== content) {
      const b = content?.split('<body');
      if (b?.length > 1) {
        const c = b[1]?.split('>');
        const bodyAttrs = c[0];
        setBodyTag(`${b[0]}<body${bodyAttrs}>`);
      }
      /*
      const parsed = content
         .replace(/!--\[if mso \| IE\]/g, 'div comment mso="true" ie="true"')
         .replace(/!--\[if !mso\]/g, 'div comment mso="false"')
         .replace(/!--\[if gte mso 9\]/g, 'div comment mso="false" gte="9"')
         .replace(/!--\[if mso\]/g, 'div comment mso="true"')
         .replace(/!\[endif\]--/g, '/div');
     */
      editor.commands.setContent(content);
    }
  }, [content]);

  /* If there is a local width set, that overrides everything.
     That means that a resize was done (browser resize, etc.)

     If there is a width set to a pixel amount we take that at
     face value. If a percent, we calculate that from the
     maxWidth, which was determined by the parent element in
     the ref callback.
  */
  const defaultWidth = React.useMemo(() => {
    if (typeof localWidth === 'number') {
      return localWidth;
    }
    if (typeof width === 'string') {
      return R.cond([
        [R.includes('%'), () => {
          try {
            const percent = Number(R.dropLast(1, width)) / 100;
            return percent * maxWidth;
          } catch (e) {
            console.log('Failed to parse width percent, ', e);
            return maxWidth;
          }
        }],
        [R.includes('px'), () => {
          try {
            const pixels = Number(R.dropLast(2, width));
            return pixels;
          } catch (e) {
            console.log('Failed to parse width percent, ', e);
            return maxWidth;
          }
        }],
        [R.T, R.always(maxWidth)],
      ])(width);
    } else {
      return width;
    }
  }, [width, maxWidth, localWidth]);

  /* The obvserver is so that we can change the size of the menu bar
     and have it stay the full size and show all buttons possible if
     the size of the editor changes (broswer resizing, etc.)
  */
  useEffect(() => {
    if (!ref) return;
    setLocalWidth(width);
    const resizeObserver = new ResizeObserver(() => {
      setLocalWidth(ref.offsetWidth);
    });
    resizeObserver.observe(ref);
    return () => resizeObserver.disconnect();
  }, [ref]);

  /* use a callback so that it immediately updates when the element's ref
     is loaded.
     If the width was set to a percent, use the parent div to determine
     the size. This is so that the menu bar autofills to the maximum size
     with as many buttons showing as possible _without having to resize to
     see them all._
  */
  const refF = React.useCallback((elem) => {
    if (elem) {
      setRef(elem);
      if (typeof width === 'string' && width.includes('%')) {
        const percent = Number(R.dropLast(1, width)) / 100;
        setMaxWidth(elem.parentElement.offsetWidth * percent);
      }
    }
  }, []);

  if (!editor) {
    return null;
  }

  return (
    <div style={{
      width, minHeight
    }} className={className} ref={refF}>
      <MenuBar
        editor={editor}
        defaultWidth={defaultWidth}
        onChange={onChange}
        zIndex={editorMenuIndex}
      />
      <EditorContent editor={editor} style={{ height: 'calc(100% - 42px)' }}/>
    </div>
  );
};

const characterLimit = 160;
const mmsCharacterLimit = 1000;
const unicodeCharacterLimit = 70;

const CancelToken = axios.CancelToken;
let cancel: Canceler;

const EditorFormControlLabel = withStyles({
  root: {
    marginRight: '5px',
  },
})(FormControlLabel);

export const getFileSize = (size: number, isFractional = false) => {
  const fixedPoint = isFractional ? 2 : 0;
  return R.cond([
    [R.gt(1000), (s: number) => `${s}B`],
    [R.gt(1000000), (s: number) => `${(s / 1e3).toFixed(fixedPoint)}KB`],
    [R.gt(1000000000), (s: number) => `${(s / 1e6).toFixed(fixedPoint)}MB`],
    [R.gt(1000000000000), (s: number) => `${(s / 1e9).toFixed(fixedPoint)}GB`],
    [R.T, (s: number) => `Are you seriously trying to upload a file that is ${(s / 1e12).toFixed(fixedPoint)}TB?`],
  ])(size);
};

type bodySelectionType = {
  selectionStart: number;
  selectionEnd: number;
  focus?: () => void;
}
const insertPlaceholder = (body: string, placeholder: string, id = 'body', bodySelection: bodySelectionType = null) => {
  const splitAt = (index: number, index2: number) => (x: string) => [x.slice(0, index), x.slice(index2)];
  const ctl = document.getElementById(id) as unknown as bodySelectionType; // TODO: Use react DOM
  const startPos = bodySelection ? bodySelection.selectionStart : ctl.selectionStart;
  const endPos = bodySelection ? bodySelection.selectionEnd : ctl.selectionEnd;
  const splitBody = splitAt(startPos, endPos)(body);
  ctl.focus();
  return splitBody[0] + placeholder + splitBody[1];
};

type updateType = (value: string | void) => void
type insertType = (value: string) => string | void
const placeholderItem = (placeholder: Placeholder, update: updateType, insert: insertType) => (
  <div key={placeholder.value}>
    <button type='button' onClick={() => update(insert(placeholder.value))}>Insert</button>
    &nbsp;
    {placeholder.name}
  </div>
);
type chacacterCountProps = { str: string; isMMS?: boolean; baseCount?: number }
const characterCount = ({ str, isMMS = false, baseCount = 0 }: chacacterCountProps, isRender = true) => {
  const hasUnicode = /[^\u0000-\u00ff]/.test(str);
  let limit;
  if (hasUnicode) {
    limit = unicodeCharacterLimit;
  } else {
    limit = characterLimit;
  }
  const strLength = (str ? str.length : 0) + baseCount;
  const assumedCredits = Math.ceil(strLength / limit);
  const reallyMMS = isMMS || assumedCredits > 10;
  const segment = reallyMMS ? 3 : assumedCredits;
  if (reallyMMS) {
    limit = mmsCharacterLimit;
  }
  if (isRender) {
    return (
      <div>
        {'Characters: ' + strLength + '/' + limit + ' | ' + 'Credits: ' + segment}
      </div>
    );
  } else {
    return segment;
  }
};

type ThreadEditorProps = {
  patch: (emoji: string, value: boolean) => void;
  emoji: boolean;
  messagesPatch: (path: string, body?: string | boolean, func?: number | JSX.Element) => void;
  disabled: boolean;
  body: string;
  emojiDirection: string;
  label: string;
  width?: string;
  nocharacter?: boolean;
  placeholder?: string;
  keyId?: string;
  baseCharacterCount: number;
  defaultBody: string;
  editorRef?: any;
}

interface emojiStyleType extends CSSProperties {
  bottom: string;
  left?: string;
  right?: string;
  zIndex: number;
}

let timeout: NodeJS.Timeout;
export const ThreadEditor = ({
  patch,
  emoji,
  messagesPatch,
  disabled,
  body,
  emojiDirection = 'down',
  label = 'Message Body',
  width = '100%',
  nocharacter = false,
  placeholder = '',
  keyId = '',
  baseCharacterCount,
  defaultBody,
  editorRef,
}: ThreadEditorProps) => {
  const [innerBody, setInnerBody] = React.useState(body);
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (innerBody !== body)
      setInnerBody(body);
  }, [body]);

  const emojiStyle = R.cond<string[], emojiStyleType>([
    [R.equals('down'), R.always({
      position: 'absolute',
      bottom: '-350px',
      right: '-20px',
      zIndex: 1
    })],
    [R.equals('up'), R.always({
      position: 'absolute',
      bottom: '0px',
      right: '-20px',
      zIndex: 100000
    })],
    [R.equals('up-reviews'), R.always({
      position: 'absolute',
      bottom: '-20px',
      left: '20px',
      zIndex: 100000
    })]
  ])(emojiDirection);
  return (
    <div ref={editorRef}>
      <div style={{
        fontSize: '12px',
        width,
      }}>
        <div>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            {!nocharacter && characterCount({
              str: innerBody === '' ? defaultBody : innerBody,
              baseCount: baseCharacterCount
            })}
            <div style={{
              marginLeft: '10px',
              zIndex: 1
            }}>
              <Mood
                style={{ cursor: 'pointer', fontSize: '16px', float: 'left', zIndex: 2 }}
                onClick={() => patch('emoji', !emoji)}
              />
              {emoji &&
                <div style={emojiDirection === 'up-reviews' ?
                  { position: 'relative' }
                  :
                  { position: 'absolute', float: 'right' }}>
                  <div
                    style={emojiStyle}>
                    <Picker onEmojiClick={(event, { emoji }) => {
                      const newBody = insertPlaceholder(innerBody, emoji, 'body' + keyId);
                      messagesPatch(newBody);
                    }} />
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
      <TextField
        id={'body' + keyId}
        ref={ref}
        key={keyId}
        style={{ width }}
        variant='outlined'
        rows={2}
        value={(defaultBody && innerBody === '') ? undefined : innerBody}
        defaultValue={defaultBody}
        disabled={disabled}
        onChange={(e) => {
          const value = e.target.value;
          setInnerBody(value);
          clearTimeout(timeout);
          timeout = setTimeout(() => messagesPatch(value), 500);
        }}
        label={disabled ? 'This client has SMS disabled.' : label}
        multiline
        placeholder={placeholder} />
    </div>
  );
};


/*
  `this.state.files` is a list of Files that look like this:
  {
   file: Maybe JSFile,
   progress: Number,
   attachment: Maybe {
     attachmentId: Number,
     attachmentName: String,
     attachmentUrl: String,
     attachmentMimeType: String,
   }
  }
  If `file` is undefined, then `attachment` needs to be there.
  When uploading, `file` will have data until the file is uploaded, then
  `attachment` will have data; but when editing a message that already
  has attachments, `attachment` will have data.
*/
export type Placeholder = {
  title?: string,
  value?: string;
  name?: string;
  placeholders?: Placeholder[]
  feature?: string;
  isHTML?: boolean;
  info?: () => void;
}
type EditorProps = {
  html?: string;
  defaultBody?: string;
  subject?: string;
  body: string;
  isSMS?: boolean;
  emoji: boolean;
  placeholders: Placeholder[];
  isUpload?: boolean;
  uploadSizeLimit?: number;
  isHTML?: boolean;
  shouldFrame?: boolean;
  keyId?: string;
  hideFrameToggle?: boolean;
  hideErrors?: boolean;
  messagesPatch: (path: string, body: string | boolean, func?: number | JSX.Element) => void;
  onError?: (error: boolean) => void;
  uploadPatch?: (arr: AttachmentType | AttachmentType[], str: string) => void;
  patch: (emoji: string, val: boolean) => void;
  attachments?: AttachmentType[];
  editorMenuIndex?: number;
}
type File = {
  name?: string;
  size?: number;
  type?: string;
  attachment?: AttachmentType;
}
type FileType = {
  file: File;
  progress: number;
  attachment?: AttachmentType;
}
type EditorState = {
  html: string;
  initialHtml: string;
  body: string;
  subject: string;
  files: FileType[];
  isRaw?: boolean;
}

let bodyTimeout: NodeJS.Timeout;
let subjectTimeout: NodeJS.Timeout;
let htmlBodyTimeout: NodeJS.Timeout;

export const Editor = (props: EditorProps) => {
  const [editorRef, setEditorRef] = useState<TEditor>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const attachmentButtonRef = useRef<HTMLInputElement>(null);
  const [bodySelection, setBodySelection] = useState(null);

  const [state, setState] = useState<EditorState>({
    html: R.propOr(undefined, 'html', props) as unknown as string,
    initialHtml: R.propOr(undefined, 'defaultBody', props) ? null : R.propOr(undefined, 'html', props) as unknown as string,
    body: R.propOr(undefined, 'body', props) as unknown as string,
    subject: (R.propOr(undefined, 'subject', props)) as unknown as string,
    files: [],
    isRaw: false,
  });

  const onEditorChange = (body: string) => {
    const value = body;
    clearTimeout(htmlBodyTimeout);
    htmlBodyTimeout = setTimeout(() => {
      console.log('patch html message');
      props.messagesPatch('html', value);
      props.onError && props.onError(R.xor(R.isEmpty(value), R.isEmpty(state.subject)));
    }, 500);
  };

  const onPlainTextEditorChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const {
      messagesPatch,
      onError = () => null,
      isSMS,
    } = props;
    const { subject } = state;
    const value = e.target.value;
    setState(R.merge(state, { body: value }));
    clearTimeout(bodyTimeout);
    if (cancel)
      cancel('User aborted request.');
    bodyTimeout = setTimeout(() => {
      api.post(
        'utilities/filter',
        { body: value },
        {
          cancelToken: new CancelToken((c) => {
            cancel = c;
          })
        })
        .then((verified) => {
          const isSame = verified.body === value;
          const newBody = isSame ? value : verified.body;
          if (isSMS) {
            messagesPatch('body', newBody, characterCount({ str: newBody }, false));
          } else {
            messagesPatch('body', newBody);
            onError(R.xor(R.isEmpty(newBody), R.isEmpty(subject)));
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            return;
          } else if (isSMS) {
            messagesPatch('body', value, characterCount({ str: value }, false));
            /* onError(!R.isEmpty(value)); */
          } else {
            messagesPatch('body', value);
            onError(R.xor(R.isEmpty(value), R.isEmpty(subject)));
          }
        });
    }, 500);
  };

  useEffect(() => {
    setState({
      ...state,
      html: props.html,
      body: props.body,
      initialHtml: props.html ? props.html :
        state.initialHtml || props.html || props.defaultBody,
      subject: props.subject,
      files: R.pipe(
        R.propOr([], 'attachments'),
        R.map((attachment: AttachmentType) => ({
          file: null,
          progress: 100,
          attachment,
        })))(props),
    });
  }, [props.html, props.body, props.subject, props.attachments]);

  const htmlInsert = (placeholder: string) => {
    const range = {
      from: editorRef.state?.selection.ranges[0].$from.pos,
      to: editorRef.state?.selection.ranges[0].$to.pos,
    };
    editorRef?.commands?.insertContentAt(range, placeholder, { updateSelection: true });
  };

  const uploadAttachments = (files: FileType[], fromTinyEditor = false) => {
    if (!fromTinyEditor) {
      const merge = (files: FileType[], stateFiles: FileType[]) => {
        return R.concat(files, stateFiles);
      };
      setState(R.merge(state, {
        files: merge(files, state.files)
      }));
    }

    const requests = files.map(({ file }, ind) => {
      const config = {
        headers: {
          'Authorization': skedApi.defaults.headers.common.Authorization,
          'X-As-Office': skedApi.defaults.headers.common['X-As-Office'],
        },
        onUploadProgress: fromTinyEditor ? undefined : (progressEvent: { loaded: number; total: number }) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          const update = (ind: number, stateFiles: FileType[], percent: number) => {
            return R.update(ind, { file, progress: percent }, stateFiles);
          };
          setState(
            R.merge(
              state,
              {
                files: update(ind, state.files, percentCompleted),
              }
            )
          );
        }
      };
      const formData = new FormData();
      formData.append('attachment', file as Blob, file.name);
      return axios.post(
        `${process.env.API_URL}/attachment/upload`,
        formData,
        config
      ).catch((e) => {
        console.error(e);
        popup('Error!', 'Failed to upload attachment. Make sure your attachment is not over the size limit and is one of file types; most image, video, & document formats are allowed.');
      });
    });

    return Promise.all(requests).then((data) => {
      const attachmentDatas = R.pipe(
        R.map(R.pathOr([], ['data', 'officeAttachments'])),
        R.flatten,
      )(data);
      if (fromTinyEditor) {
        return R.head(attachmentDatas);
      } else {
        setState(
          R.merge(
            state,
            {
              files: state.files.map((file, ind) => {
                const hasData = attachmentDatas[ind];
                if (hasData) {
                  return {
                    ...file,
                    attachment: hasData,
                  };
                } else {
                  return file;
                }
              }),
            }
          )
        );
        props.uploadPatch(attachmentDatas, 'add');
        return null;
      }
    });
  };

  const insert = (d: string) => {
    const newBody = insertPlaceholder(state.body, d, 'body', bodySelection);
    setState({ ...state, body: newBody });
    props.messagesPatch('body', newBody);
  };
  /*
    type Placeholders = {value: String, name: String}
  */
  const {
    isSMS,
    patch,
    emoji,
    messagesPatch,
    placeholders, // [{title: String, placeholders: [Placeholders], info: Either Function Undefined}]
    isUpload,
    uploadSizeLimit,
    isHTML,
    onError,
    shouldFrame,
    hideFrameToggle = false,
    hideErrors = false,
  } = props;

  const {
    body,
    subject,
    files,
    html = '',
    initialHtml,
  } = state;

  const insertFunction = isHTML ? htmlInsert : insert;
  const updateState = (e: string) => setState(R.merge(state, { body: e }));
  const bodyEmpty = R.isEmpty(body);
  const htmlEmpty = R.isEmpty(R.isNil(html) ? '' : html?.split('>')[1]?.split('</')[0]?.trim() || '');
  const subjectEmpty = R.isNil(subject) ? bodyEmpty : R.isEmpty(subject);
  const subjectError = (subjectEmpty && bodyEmpty) ? false : (!bodyEmpty && subjectEmpty);
  const bodyError = (subjectEmpty && bodyEmpty) ? false : (bodyEmpty && !subjectEmpty);
  const htmlSubjectError = (subjectEmpty && htmlEmpty) ? false : (!htmlEmpty && subjectEmpty);
  const htmlError = (subjectEmpty && htmlEmpty) ? false : htmlEmpty && !subjectEmpty;
  const innerFiles = R.isEmpty(files) ? R.pipe(
    R.propOr([], 'attachments'),
    R.map((attachment) => ({
      file: null,
      progress: 100,
      attachment,
    })))(props) : files;
  return (
    <div>
      {subject !== undefined &&
        <TextField
          name="subject"
          label="Message Subject"
          value={subject}
          style={{ width: '100%' }}
          error={htmlSubjectError || subjectError}
          helperText={(subjectError || htmlSubjectError) ? 'Subject is required' : undefined}
          onChange={(e) => {
            const value = e.target.value;
            setState(R.merge(state, { subject: value }));
            clearTimeout(subjectTimeout);
            subjectTimeout = setTimeout(() => {
              messagesPatch('subject', value);
              isHTML ? onError(R.xor(R.isEmpty(value), R.isEmpty(html))) :
                onError(R.xor(R.isEmpty(value), R.isEmpty(body)));
            }, 500);
          }}
        /* onBlur={(e) => messagesPatch('subject', e.target.value)} */
        />}
      {subject !== undefined && <br />}
      {subject !== undefined && <br />}
      <br />
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
      }}>
        <div style={{

        }}>
          <div style={{
            width: '100%',
            border: !hideErrors && htmlError && isHTML ? 'red 2px solid' : undefined
          }}>
            {body !== undefined && !isHTML &&
              <TextField
                id="body"
                ref={bodyRef}
                style={{ minWidth: '600px' }}
                variant="outlined"
                rows={15}
                value={body}
                error={bodyError}
                helperText={bodyError ? 'Body is required' : undefined}
                onChange={onPlainTextEditorChange}
                onBlur={(e) => {
                  const b = e.target;
                  setBodySelection(b);
                }}
                label="Message Body"
                multiline />}
            {html !== undefined && isHTML &&
              <div className="text-editor">
                <TipTap
                  content={initialHtml}
                  onChange={onEditorChange}
                  setEditor={setEditorRef}
                  editorMenuIndex={props.editorMenuIndex}
                />
                {!hideFrameToggle &&
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                    <EditorFormControlLabel
                      control={
                        <Checkbox
                          checked={shouldFrame}
                          onChange={(e) => messagesPatch('shouldFrame', e.target.checked)}
                          name="acknowledge-checkbox"
                          color="primary"
                        />
                      }
                      label="Use SKED Frame"
                    />
                    <Tooltip
                      title="The SKED Frame contains a border, opt out link, & 'Powered by SKED' logo at the bottom. If you want totally custom emails, uncheck this box."
                      placement="top"
                      arrow>
                      <Info style={{ fontSize: '12px' }} />
                    </Tooltip>
                  </div>}
                {!hideErrors && htmlError &&
                  <p style={{ color: 'red' }}>Body is required!</p>}
              </div>}
            <div style={{
              display: 'flex',
              justifyContent: isSMS ? 'space-between' : 'flex-end',
              marginTop: '5px',
            }}>
              {isSMS ?
                characterCount(
                  {
                    str: R.ifElse(
                      (props: string) => !!R.identity(props),
                      R.always(subject + body),
                      R.always(body)
                    )(subject),
                    isMMS: !R.isEmpty(files)
                  }) : null}
              {!isHTML &&
                <div style={{ float: 'right' }}>
                  <Mood
                    style={{ cursor: 'pointer', fontSize: '16px', float: 'left' }}
                    onClick={() => patch('emoji', !emoji)}
                  />
                  {emoji &&
                    <div style={{ position: 'relative', float: 'right' }}>
                      <div style={{ position: 'absolute', bottom: '0px', zIndex: 1 }}>
                        <Picker onEmojiClick={(event, { emoji }) => {
                          const newBody = insertPlaceholder(body, emoji, 'body', bodySelection);
                          setState({ ...state, body: newBody });
                          clearTimeout(bodyTimeout);
                          bodyTimeout = setTimeout(() => {
                            messagesPatch('body', newBody);
                          }, 500);
                        }} />
                      </div>
                    </div>}
                </div>}
            </div>
          </div>
          {isUpload &&
            <div>
              Attachment:
              <i>{' (cannot exceed ' + uploadSizeLimit / 1e6 + 'MB)'}</i>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '10px',
              }}>
                <input
                  accept="image/*,audio/*,video/*,.pdf,.docx,.doc,.vcf"
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  multiple
                  type="file"
                  ref={attachmentButtonRef}
                  onChange={(e) => {
                    const {
                      files
                    } = e.target;
                    const size = R.reduce<File, number>((a, c) => {
                      return a + c.size;
                    }, 0, files as unknown as File[]);
                    if (size < uploadSizeLimit) {
                      R.forEach((f: Blob) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(f);
                      }, files as unknown as Blob[]);
                      const filesArray = R.map((f) => ({
                        file: f,
                        progress: 0,
                      }))(files as unknown as File[]);
                      uploadAttachments(filesArray);
                      attachmentButtonRef.current.value = null; // prevent chrome from blocking uploading the same file many times
                    } else {
                      console.log('too big');
                      alert('Error! Attachments are too large!');
                    }
                  }}
                />
                <label htmlFor="contained-button-file" style={{ width: '112px' }}>
                  <Button
                    variant='contained'
                    color="primary"
                    component="span"
                    aria-label="Upload Attachment"
                    className='sked-test-create-message-upload-button'
                    startIcon={<Attachment />}>
                    Upload
                  </Button>
                </label>
                <div style={{
                  marginTop: '10px',
                }}>
                  {innerFiles.map((fileObj, ind) => {
                    const {
                      file,
                      progress,
                      attachment,
                    } = fileObj as FileType;
                    const url: string = R.propOr(false, 'attachmentUrl')(attachment);
                    return (
                      <div
                        key={ind}
                        style={{
                          backgroundColor: 'lightgray',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          padding: '5px',
                          borderRadius: '5px',
                          marginBottom: '5px',
                        }}>
                        <div style={{
                          display: 'flex',
                        }}>
                          {url ?
                            <a href={url} target='_blank'>
                              {
                                file ?
                                  file.name
                                  :
                                  attachment.attachmentName}
                            </a>
                            :
                            <p style={{
                              margin: 'unset',
                            }}>
                              {
                                file ?
                                  file.name
                                  :
                                  attachment.attachmentName}
                            </p>}
                          &nbsp;
                          {file &&
                            <p style={{
                              margin: 'unset',
                            }}>{
                                '(' +
                                getFileSize(file.size, true)
                                +
                                ')'
                              }</p>}
                        </div>
                        {progress !== 100 ?
                          <div style={{
                            width: '100px',
                            height: '10px',
                            backgroundColor: '#b3b3b3',
                          }}>
                            <div style={{
                              width: progress + 'px',
                              height: '100%',
                              backgroundColor: '#008BCF',
                            }}>
                            </div>
                          </div>
                          : <Close
                            className='sked-test-create-message-delete-attachment-icon'
                            style={{
                              cursor: 'pointer',
                              fontSize: 18,
                            }}
                            onClick={() => {
                              const without = (fileObj: FileType, stateFiles: FileType[]) => {
                                return R.without([fileObj], stateFiles);
                              };
                              setState(
                                R.merge(
                                  state,
                                  {
                                    files: without(fileObj as FileType, state.files),
                                  }));
                              props.uploadPatch(attachment, 'remove');
                            }}
                          />
                        }
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>}
        </div>
        {placeholders &&
          <div>
            <h4 style={{ paddingLeft: '25px' }}>Placeholders</h4>
            {R.map(({ title = '', placeholders = null, info }: Placeholder) => {
              return placeholders ? (
                <div key={title} style={{ paddingLeft: '25px', float: 'left' }}>
                  <h5>{title + ':'} {info ? info() : null}</h5>
                  <div
                    style={{
                      maxHeight: '175px',
                      overflowY: placeholders.length > 6 ? 'scroll' : 'unset'
                    }}>
                    {R.pipe(
                      R.filter((p) =>
                        R.cond([
                          [R.has('isHTML'), R.always(isHTML)],
                          [R.has('isSMS'), R.always(isSMS)],
                          [R.T, R.T]
                        ])(p))
                    )(placeholders).map((placeholder) =>
                      placeholderItem(placeholder, updateState, insertFunction))}
                  </div>
                </div>
              ) : null;
            })(placeholders)}
          </div>}
      </div>
    </div>
  );
};

type SearchBarProps = {
  query: string;
  onChange: (value: string) => void;
  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder: string;
  helperText?: string;
}

let searchBarTimeout: NodeJS.Timeout = null;
export const SearchBar = ({
  query,
  onKeyPress,
  onChange,
  placeholder,
  helperText = '',
}: SearchBarProps) => {
  const [body, setBody] = React.useState(query);
  return (
    <TextField
      name="client-search"
      label="Search"
      placeholder={placeholder}
      value={body}
      onKeyPress={onKeyPress}
      helperText={helperText}
      onChange={(e) => {
        const v = e.target.value;
        setBody(v);
        if (searchBarTimeout)
          clearTimeout(searchBarTimeout);
        searchBarTimeout = setTimeout(() => {
          onChange(v);
        }, 500);
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search style={{ fontSize: '16px' }} />
          </InputAdornment>
        ),
      }}
    />
  );
};
