import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  paper: {
    borderRadius: 12,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  inputLabel: {
    fontSize: 14,
    marginBottom: 5,
  },
  input: {
    borderRadius: 10,
    width: 180,
    marginRight: 10,

    '& .MuiInputBase-root': {
      borderRadius: 10
    }
  },
  selected: {
    borderRadius: 10,
    width: 180,
  },
}));
