import {
  mergeAttributes, Node
} from '@tiptap/core';

export interface RepeaterOptions {
  HTMLAttributes: Record<string, any>,
}


declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    repeater: {
      /**
       * Add an style tag
       */
      setRepeaterTag: () => ReturnType,
    }
  }
}

export const RepeaterTag = Node.create<RepeaterOptions>({
  name: 'repeater',

  priority: 1000,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: 'block',

  content: 'block+',

  addAttributes() {
    return {
      class: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'repeater',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['repeater', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setRepeaterTag: () => ({ commands }) => {
        return commands.setNode(this.name);
      },
    };
  },
});

export default RepeaterTag;
