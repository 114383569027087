import React from 'react';

interface AsyncData<T> {
  data: T;
  invoked: number;
  loading: boolean;
  errorMessage: string;
}

export function usePromise<A, T>(promise: (arg: A) => Promise<T>, data: T) {
  const [state, setState] = React.useState<AsyncData<T>>({
    data,
    invoked: 0,
    loading: false,
    errorMessage: ''
  });

  function invoke(arg: A): Promise<T> {
    setState(s => ({
      ...s,
      loading: true,
      errorMessage: '',
      invoked: s.invoked + 1
    }));

    return promise(arg).then(data => {
      setState(s => ({
        ...s,
        loading: false,
        data
      }));
      return data;
    }).catch(e => {
      setState(s => ({
        ...s,
        loading: false,
        errorMessage: e.message
      }));
      throw e;
    });
  }

  return {
    ...state,
    invoke,
    setState
  };
}
