import React, { useState } from 'react';
import { LocalDate } from '@js-joda/core';
import * as R from 'ramda';

import CalendarIcon from '../../icons/CalendarDate.icon';

import FilterHeader from '../FilterHeader/FilterHeader.component';
import HeaderButton from '../HeaderButton/HeaderButton.component';

import { useStyles } from './filterStartEndDate.styles';

import {
  FormControl,
  Popover,
  Grid,
  TextField,
  FormLabel
} from '@mui/material';

type DateRange = {
  start: string; end: string
}

type FilterDateProps = {
  setDateRange: (range: DateRange) => void;
  dateRange: DateRange,
  save?: ({ dateRange }: { dateRange: DateRange }) => void;
  startEmpty?: boolean;
  onlyIcon?: boolean;
}

const FilterStartEndDate = ({ dateRange, setDateRange, save, startEmpty, onlyIcon }: FilterDateProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const today = {
    start: startEmpty ? '' : LocalDate.now().toString(),
    end: startEmpty ? '' : LocalDate.now().toString()
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (value: DateRange) => {
    setDateRange(value);
    save?.({ dateRange: value });
  };

  const handleReset = () => {
    setDateRange(today);
    save?.({ dateRange: today });
  };

  return (
    <Grid>
      <Grid marginRight={0.5}>
        <HeaderButton
          title='Date'
          Icon={CalendarIcon}
          iconClass={classes.iconScale}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          active={!R.equals(dateRange, today)}
          type='filter'
          onlyIcon={onlyIcon}
        />
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid>
          <FilterHeader
            title='Date'
            reset={handleReset}
            resetDisabled={R.equals(dateRange, today)}
          />
          <Grid padding={2}>
            <FormControl variant="outlined" className={classes.formControl}>
              <FormLabel className={classes.inputLabel} htmlFor='filter-start-date'>Start Date</FormLabel>
              <TextField
                className={classes.date}
                size='small'
                value={startEmpty ? dateRange.start : LocalDate.parse(dateRange.start)}
                onChange={(event) => {
                  if (event.target.value) {
                    handleChange({ ...dateRange, start: event.target.value });
                  }
                }}
                type='date'
                variant='outlined'
              />
            </FormControl>
            &nbsp;&nbsp;&nbsp;
            <FormControl variant="outlined" className={classes.formControl}>
              <FormLabel className={classes.inputLabel} htmlFor='filter-end-date'>End Date</FormLabel>
              <TextField
                id='filter-end-date'
                className={classes.date}
                size='small'
                value={startEmpty ? dateRange.end : LocalDate.parse(dateRange.end)}
                onChange={(event) => {
                  if (event.target.value) {
                    handleChange({ ...dateRange, end: event.target.value });
                  }
                }}
                type='date'
                variant='outlined'
              />
            </FormControl>
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
};

export default FilterStartEndDate;
