import { CircularProgress, IconButton } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import React from 'react';
import { usePromise } from '../../services/promise.hook';
import api from '../../services/api.js';
import { includes } from 'ramda';
import Modal from '../Modal/Modal.component';
import { CustomTooltip, useStyles } from './help.styles';

const getHelpText = (id: string) => api.get(`help/${id}`);

export function useHelpDialog() {
  const [state, setState] = React.useState(false);

  return {
    isOpen: state,
    handleClose: () => setState(false),
    handleOpen: () => setState(true)
  };
}

type HelpButtonProps = {
  handleOpen: () => void;
}

export const HelpButton = ({ handleOpen }: HelpButtonProps) => {
  const [_page, query] = location.href.split('/#/')[1]?.split('?') || [null, null];
  const seenit = query && includes('gettingStarted=true', query);
  const [flashing, setFlashing] = React.useState(seenit);
  const className = 'sked-test-help-button-' + _page;
  const classes = useStyles();
  return (flashing ?
    <div className={classes.helpButton}>
      <CustomTooltip
        title={'⭐ Click here to learn about this feature! Once you\'ve finished setting up this page, go back to the \'Getting Started\' tab to continue.'}
        placement='right'
        arrow
        open
      >
        <IconButton
          className={className}
          onClick={() => {
            handleOpen();
            setFlashing(false);
          }}>
          <HelpIcon className={classes.icon} />
        </IconButton>
      </CustomTooltip>
    </div>
    :
    <IconButton
      className={className}
      onClick={handleOpen}>
      <HelpIcon className={classes.icon} />
    </IconButton>
  );
};

type HelpDialogProps = {
  pageId: string;
  handleClose: () => void;
  open: boolean;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  maxWidth?: number;
}

export const HelpDialog = ({
  pageId,
  handleClose,
  open,
  size,
  maxWidth,
}: HelpDialogProps) => {
  const helpState = usePromise(getHelpText, {});

  React.useEffect(() => {
    if (pageId) {
      helpState.invoke(pageId);
    }
  }, []);

  return (
    <Modal
      onClose={handleClose}
      size={size || 'xs'}
      maxWidth={maxWidth || 2000}
      open={open}
      title={helpState.data.title}
      className='sked-test-help-componente-modal'
    >
      <div>
        {helpState.loading && <CircularProgress />}
        {!helpState.loading && <div dangerouslySetInnerHTML={{ __html: helpState.data.body }} />}
      </div>
    </Modal>
  );
};
