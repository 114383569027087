import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '4px 15px',
    borderRadius: 0,
    display: 'flex',
    minHeight: 34.5,
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)',
    marginBottom: 2,
    zIndex: 101,
    '@media print': {
      boxShadow: 'none'
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  icons: {
    '@media print': {
      display: 'none'
    },
  },
}));
