import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const ChevronIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} width="11.769" height="6.635" viewBox="0 0 11.769 6.635">
      <path id="Path_9814" data-name="Path 9814" d="M2610.1,199.181,2605.272,204l-4.824-4.824" transform="translate(-2599.388 -198.12)" fill="none" stroke={color || '#4d4d4d'} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
    </svg>
  );
};

export default ChevronIcon;
