import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import AddIcon from '@mui/icons-material/Add';
import NormalOfficeHours from './NormalOfficeHours/officeHours.component.jsx';
import { useTitle } from '../../services/useTitle.js';
import Header from '../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import FilterLocations from '../../components/FilterLocations/FilterLocations.component';
import { useSelector } from '../../reducers';
import * as action from './NormalOfficeHours/officeHours.actions';

function OfficeHourTabs({ history }) {
  const [locationId, setLocationId] = useState(0);
  const dispatch = useDispatch();
  const {
    locations,
    hasLocations
  } = useSelector(state => {
    const locations = R.pathOr([], ['business', 'locations'], state);
    const hasLocations = R.pipe(
      R.pathOr([], ['login', 'features']),
      R.includes('Locations')
    )(state);
    return {
      locations: hasLocations && !R.isEmpty(locations) ?
        R.prepend(
          { id: 0, name: 'All Locations' },
          locations,
        ) : [],
      hasLocations,
    };
  });
  useTitle('Office Hours');

  return (
    <>
      <Header
        title='Office Hours'
        pageId='office-hours'
        leftIcons={[
          <FilterLocations
            hasLocations={hasLocations}
            allLocations={locations}
            selectedLocationId={locationId}
            setSelectedLocationId={setLocationId}
          />,
        ]}
        rightIcons={[
          <HeaderButton title='Add Hours' Icon={AddIcon} onClick={() => dispatch(action.addHoursPushed())} />
        ]}
        onlyIconsWidth={440}
      />
      <div
        style={{
          padding: 20,
          paddingTop: 0,
          paddingBottom: 25,
          overflowY: 'auto',
        }}>
        <NormalOfficeHours history={history} locationId={locationId} setLocationId={setLocationId} />
      </div>
    </>
  );
}

export default OfficeHourTabs;
