import api from '../../../services/api';

export const editEmail = async (email?: string, name?: string) => {
  try {
    await api.put('user', { email: email ? email : undefined, name: name ? { Set: name } : undefined });
    return true;
  } catch {
    return false;
  }
};

export const createCredtCard = async (id: number) => {
  try {
    const result: { url: string } = await api.post(`agency/${id}/stripe`);
    return result.url;
  } catch {
    return '';
  }
};