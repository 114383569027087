import { Dispatch } from 'redux';
import { LocalDate } from '@js-joda/core';
import axios from 'axios';
import * as ACTION_TYPES from './leads.actionTypes';
import api, { skedApi } from '../../services/api';
import { remoteAction } from '../../services/actionService';
import {
  now,
  formatStandard,
  startOf,
  endOf,
} from '../../services/joda';
import { popup } from '../../services/Popup.js';
import { alertSuccess, alertDanger } from '../../components/Alerts/alerts.actions';
import { RootState } from '../../reducers';
import { Lead, Message, Otpout } from './leads.reducer';
import { Appointment } from '../Appointments/appointments.types';
import * as R from 'ramda';

type queryData = {
  page: number;
  perPage?: number;
  query?: {
    isLead?: boolean;
    firstName?: string;
    lastName?: string;
    clientSource?: object;
    allowSMS?: boolean;
    allowEmail?: boolean;
    created?: {
      start: string;
      end: string;
    };
    sortBy?: {
      field: string,
      direction: string,
    }[]
  }
}

export const getLeads = (data: queryData) => {
  const query: queryData = {
    page: data.page || 1,
    perPage: data.perPage || 25,
    query: {
      isLead: true,
      sortBy: [{
        direction: 'Desc',
        field: 'Created'
      }],
      ...data.query,
    }
  };
  return remoteAction({
    type: ACTION_TYPES.LEADS_REMOTE_GET,
    action: () => api.post('client/query', query)
      .then((leads) => {
        return leads;
      }).catch((error) => {
        popup('Error!', 'Failed to get Leads.');
        console.error(error);
      }),
  });
};

export const getLeadMessages = (id: number, page = 1) => {
  return remoteAction({
    type: ACTION_TYPES.LEADS_REMOTE_MESSAGES,
    action: () => api.post('sentmsg/query', { page, perPage: 50, query: { toClient: id } })
      .then((messages) => messages)
      .catch((error) => {
        popup('Error!', 'Failed to get Lead messages.');
        console.error(error);
      }),
  });
};

export const getOptoutList = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_TYPES.LEADS_LOADING, data: true });
    const optoutList = await api.get('client/optout/description');
    const list = optoutList.filter((opt: { service: string }) => opt.service === 'OneTimeMessages' || opt.service === 'TwoWayMessages');
    dispatch({ type: ACTION_TYPES.LEADS_REMOTE_OPTOUT_LIST, data: { optoutList: list } });
  } catch {
    alertDanger('error to get optout description')(dispatch);
    dispatch({ type: ACTION_TYPES.LEADS_LOADING, data: false });
  }
};

export const getOptout = (id: number) => async (dispatch: Dispatch) => {
  try {
    const optout = await api.get(`client/optout/${id}`);
    dispatch({ type: ACTION_TYPES.LEADS_REMOTE_OPTOUT, data: { optout } });
  } catch {
    alertDanger('error to get optout')(dispatch);
  }
};

export const updateCategory = (category: Otpout, leadId: number) => async (dispatch: Dispatch, getSate: () => RootState) => {
  const { leads } = getSate();

  const categories = leads.categories.map(categ => categ.service === category.service ? category : categ);

  const equals = R.equals(leads.optoutList, categories);
  if (!equals) {
    const items = categories.filter((item, index) => item.checked !== leads.optoutList[index].checked);
    await updateOptout(leadId, items)(dispatch);
    dispatch({ type: ACTION_TYPES.LEADS_CATEGORIES_UPDATE, data: { categories } });
  }
};

export const selectMessage = (message: Message) => (dispatch: Dispatch) => {
  dispatch({ type: ACTION_TYPES.LEADS_PATCH, data: { selectedMessage: message } });
};

export const getReferrers = () => async (dispatch: Dispatch) => {
  try {
    const referrals = await api.get('office/referrers');
    dispatch({ type: ACTION_TYPES.LEADS_REFERRALS_SET, data: { referrals } });
  } catch {
    alertDanger('error to get Referrers')(dispatch);
  }
};

export const updateOptout = (id: number, items: Otpout[]) => (dispatch: Dispatch) => {
  return api.put(`client/optout/${id}`, items.map(item => ({ service: item.service, optout: !item.checked })))
    .then(() => {
      return getOptout(id)(dispatch);
    }).catch((error) => {
      alertDanger('Failed to update lead categories.');
      console.error(error);
    });
};

const handleBirthday = (value: string) => {
  if (value) {
    const [month, day, year] = value.split('/');
    return year + '-' + month + '-' + day;
  }
};

export const saveLeads = (leads: Lead[], defaultAppiontmentType = 1) => async (dispatch: Dispatch) => {
  try {
    let hasReferrer = 0;
    dispatch({ type: ACTION_TYPES.LEADS_LOADING, data: true });
    for (const lead of leads) {
      const birthday = handleBirthday(lead.birthday);
      await api.post('client/createlead', {
        ...lead,
        birthday,
        metadata: {},
        defaultAppiontmentType,
        email: lead.email ? lead.email : undefined,
        phone: lead.phone ? lead.phone.replace(/[{()}]|-|\s/g, '') : undefined,
      });
      if (lead.referrer) {
        hasReferrer += 1;
      }
    }

    const message = leads.length > 1 ? 'Leads Created' : 'Lead Created';
    alertSuccess(message)(dispatch);
    getLeads({ page: 1 })(dispatch);
    if (hasReferrer) {
      getReferrers()(dispatch);
    }
    return true;
  } catch {
    alertDanger('Error to create lead')(dispatch);
    dispatch({ type: ACTION_TYPES.LEADS_LOADING, data: false });
    return false;
  }
};

export const removeLead = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_TYPES.LEADS_LOADING, data: true });
    await api.put(`client/${id}`, {
      status: 'Inactive',
      firstName: '',
      lastName: '',
      referrer: '',
      allowEmail: false,
      allowSMS: false,
      birthday: { Unset: [] },
      email: { Unset: [] },
      phone: { Unset: [] },
    });
    dispatch({ type: ACTION_TYPES.LEADS_REMOTE_REMOVE, data: { id } });
    alertSuccess('Lead Removed')(dispatch);
    return true;
  } catch {
    alertDanger('Error removing lead')(dispatch);
    dispatch({ type: ACTION_TYPES.LEADS_LOADING, data: false });
    return false;
  }
};

export const convertLead = (id: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_TYPES.LEADS_LOADING, data: true });
    await api.put(`client/${id}`, {
      isLead: false,
    });
    alertSuccess('Successfully converted')(dispatch);
    dispatch({ type: ACTION_TYPES.LEADS_REMOTE_REMOVE, data: { id } });
    return true;
  } catch {
    alertDanger('error converting')(dispatch);
    dispatch({ type: ACTION_TYPES.LEADS_LOADING, data: false });
    return false;
  }
};

export const updateBlockedNumber = (leadId: number, blockSMS: boolean) => async (dispatch: Dispatch) => {
  dispatch({ type: ACTION_TYPES.LEADS_BLOCK_NUMBER_SET, data: { id: leadId, blockSMS } });
};

export const handleUpdateBlockedNumber = (lead: Lead) => async (dispatch: Dispatch) => {
  try {
    const { phone, blockSMS } = lead;
    if (blockSMS) {
      await api.post('office/block-number', { phone: phone || '' });
    } else {
      await axios.delete(`${process.env.API_URL}/office/unblock-number`, {
        headers: {
          Authorization: skedApi.defaults.headers.common.Authorization,
          'X-As-Office': skedApi.defaults.headers.common['X-As-Office'],
        },
        data: { phone: phone || '' }
      });
    }
    dispatch({ type: ACTION_TYPES.LEADS_BLOCK_NUMBER_SET, data: { id: lead.id, blockSMS } });
    dispatch({ type: ACTION_TYPES.LEADS_PATCH, data: { toEdit: lead } });
  } catch {
    alertDanger('error to update block number')(dispatch);
  }
};

export const updateEmailAndSms = (lead: Lead) => async (dispatch: Dispatch) => {
  const body = { ...lead };
  delete body.birthday;
  try {
    const response = await api.put(`client/${lead.id}`, body);
    dispatch({ type: ACTION_TYPES.LEADS_REMOTE_UPDATE, data: { lead: response } });
    dispatch({ type: ACTION_TYPES.LEADS_PATCH, data: { toEdit: lead } });
  } catch {
    alertDanger('error updating')(dispatch);
  }
};

export const updateLead = (lead: Lead, removeBirthday?: boolean) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ACTION_TYPES.LEADS_LOADING, data: true });

    const birthday = handleBirthday(lead.birthday);

    const birthdayData = birthday ? { Set: birthday } : undefined;
    const birthdayDelete = { Unset: [] as string[] };

    const body = {
      ...lead,
      birthday: removeBirthday ? birthdayDelete : birthdayData,
      metadata: {},
      email: lead.email ? lead.email : undefined,
      phone: lead.phone ? lead.phone.replace(/[{()}]|-|\s/g, '') : '',
    };
    const response = await api.put(`client/${lead.id}`, body);
    alertSuccess('Successfully updated')(dispatch);
    dispatch({ type: ACTION_TYPES.LEADS_REMOTE_UPDATE, data: { lead: response } });

    return true;
  } catch {
    alertDanger('error updating')(dispatch);
    dispatch({ type: ACTION_TYPES.LEADS_LOADING, data: false });
    return false;
  }
};

export const getAppointments = (
  leadId: number,
  after = now('tz'),
  before = (now('tz') as LocalDate).plusMonths(1),
  page = 1,
  filter = 'All',
) => {
  let data = {};
  if (filter === 'All')
    data = {
      page,
      perPage: 100,
      query: {
        clientId: leadId,
        after: formatStandard(startOf(after, 'day'), false),
        before: formatStandard(endOf(before, 'day'), false),
      }
    };
  else
    data = {
      page,
      perPage: 100,
      query: {
        clientId: leadId,
        after: formatStandard(startOf(after, 'day'), false),
        before: formatStandard(endOf(before, 'day'), false),
        status: [
          filter
        ]
      }
    };
  return remoteAction({
    type: ACTION_TYPES.LEADS_REMOTE_APTS_GET,
    action: () =>
      api.post('appointment/query', data)
        .then(({ data }) => {
          return {
            appointments: R.sortBy(R.prop('time'))(data),
          };
        }),
  });
};

export const viewApt = (appointment: Appointment) => remoteAction({
  type: ACTION_TYPES.LEADS_REMOTE_APT_GET,
  action: () => api.get(`appointmentType/${appointment.appointmentTypeId}`)
    .then((data) => {
      const aptWithType = R.assoc('type', data.name)(appointment);
      return api.get(`appointment/${appointment.id}/log`)
        .then((data) => {
          return {
            appointment: R.assoc('aptData', R.reverse(R.sortBy(R.prop('changedAt'))(data)), aptWithType),
          };
        });
    })
    .catch((error) => {
      console.error(error);
    }),
});

export const closeSelectedAppointment = () => (dispatch: Dispatch) => {
  dispatch({ type: ACTION_TYPES.LEADS_PATCH, data: { selectedAppointment: null } });
};

export const handleToEdit = (lead: Lead) => (dispatch: Dispatch) => {
  dispatch({ type: ACTION_TYPES.LEADS_PATCH, data: { toEdit: lead } });
};
