import * as at from '../../../../actionTypes';
import * as R from 'ramda';

const init = {
  busy: false,
  selectedApts: [],
  types: [],
  professionals: [],
  selectedTypes: null,
  selectedPro: {},
  selectedNewTypes: [],
  snackbar: false,
  inlcludeNewClient: true,
  scheduleNewPatient: false,
  officeKey: '',
  includeExistingClient: true,
  includeNewPatient: false,
  bookingRedirectUrl: '',
  sameDayExistingPatientScheduling: false,
  sameDayNewPatientScheduling: false,
};

export default function reducer(webmodule = init, action) {
  switch (action.type) {
    case at.WEBMODULE_PATCH: {
      return R.merge(webmodule, action.data);
    }
    case at.WEBMODULE_ADD_TYPE: {
      if (action.data.type.name === 'All') {
        return R.evolve(
          {
            selectedTypes: R.pipe(
              R.pathOr([], ['selectedPro', 'types']),
              R.tail,
              R.filter(
                val => !R.find(R.propEq('id', val.id))(webmodule.selectedTypes),
              ),
              R.concat,
            )(webmodule),
          },
          webmodule,
        );
      } else if (
        R.pipe(
          R.propOr([], 'selectedTypes'),
          R.find(R.propEq('id', action.data.type.id)),
        )(webmodule)
      ) {
        return webmodule;
      }
      return R.evolve(
        {
          selectedTypes: R.concat([action.data.type]),
        },
        webmodule,
      );
    }
    case at.WEBMODULE_ADD_TYPES: {
      return R.evolve(
        {
          selectedTypes: R.pipe(
            R.filter(
              val => !R.find(R.propEq('id', val.id))(webmodule.selectedTypes),
            ),
            R.concat,
          )(action.data.selectedTypes),
          typeStatus: () => action.data.typeStatus,
        },
        webmodule,
      );
    }
    case at.WEBMODULE_REMOVE_TYPE: {
      return R.evolve(
        {
          selectedTypes: R.remove(action.data.index, 1),
          typeStatus: () => 'Some',
        },
        webmodule,
      );
    }
    case at.WEBMODULE_REMOTE_GET_TYPES:
      if (action.state === 'REQUEST') {
        return R.merge(webmodule, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const professionals = R.pipe(
          R.filter(type => !type.isHidden),
          R.sortBy(R.prop('firstName'))
        )(action.data.professionals);
        const types = R.pipe(
          R.filter(({ professionalId }) =>
            R.find(R.propEq('id', professionalId))(professionals)),
          R.sortBy(R.prop('internalName'))
        )(action.data.types);
        return R.mergeAll([
          webmodule,
          action.data,
          {
            types,
            professionals,
            selectedPro: R.head(professionals),
            state: 'CREATE',
            busy: false,
          }]);
      } else if (action.state === 'ERROR') {
        return R.merge(webmodule, {
          busy: false,
        });
      }
      return webmodule;
    case at.WEBMODULE_SAVE:
      if (action.state === 'REQUEST') {
        return R.merge(webmodule, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(webmodule, {
          state: 'SENT',
          snackbar: true,
          busy: false,
          includeNewPatient: action.data.newClient,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(webmodule, {
          busy: false,
        });
      }
      return webmodule;
    case at.WEBMODULE_PATCH_PRO:
      return R.evolve({
        professionals: R.map((pro) => {
          if (pro.id === action.data.pro.id) {
            return action.data.pro;
          } else {
            return pro;
          }
        })
      }, webmodule);
    default:
      return webmodule;
  }
}
