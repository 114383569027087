import React, { ComponentProps } from 'react';

const ClearRoomIcon = ({ color, className, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg className={className} {...props} xmlns="http://www.w3.org/2000/svg" width="15.642" height="15.642" viewBox="0 0 15.642 15.642">
      <g id="Group_15976" data-name="Group 15976" transform="translate(0 -0.004)">
        <path id="Path_8974" data-name="Path 8974" d="M9.776,8.477a.652.652,0,0,0-.652.652v2.607a.652.652,0,0,1-.652.652H6.518V2.611A1.314,1.314,0,0,0,5.63,1.372l-.193-.065H8.473a.652.652,0,0,1,.652.652V3.914a.652.652,0,1,0,1.3,0V1.959A1.958,1.958,0,0,0,8.473,0H1.466A.512.512,0,0,0,1.4.018C1.365.016,1.335,0,1.3,0A1.3,1.3,0,0,0,0,1.307V13.039a1.314,1.314,0,0,0,.888,1.239L4.81,15.585a1.349,1.349,0,0,0,.4.061,1.3,1.3,0,0,0,1.3-1.3v-.652H8.473a1.958,1.958,0,0,0,1.955-1.955V9.128A.652.652,0,0,0,9.776,8.477Zm0,0" fill={color || '#4d4d4d'}/>
        <path id="Path_8975" data-name="Path 8975" d="M284.31,109.464l-2.607-2.607a.651.651,0,0,0-1.112.461v1.955h-2.607a.652.652,0,1,0,0,1.3h2.607v1.955a.652.652,0,0,0,1.112.461l2.607-2.607A.651.651,0,0,0,284.31,109.464Zm0,0" transform="translate(-268.859 -103.403)" fill={color || '#4d4d4d'}/>
      </g>
    </svg>
  );
};

export default ClearRoomIcon;
