import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const DeleteIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="13.602" height="16.744" viewBox="0 0 13.602 16.744">
      <defs>
        <clipPath id="clip-path-trash">
          <rect id="Rectangle_8997" data-name="Rectangle 8997" width="13.602" height="16.744" transform="translate(0 0)" fill="#474a54"/>
        </clipPath>
      </defs>
      <g id="Group_10323" data-name="Group 10323" transform="translate(0 0)" fill={color || '#fff'}>
        <g id="Group_10323-2" data-name="Group 10323" transform="translate(0 0)" clip-path="url(#clip-path-trash)">
          <path id="Path_7868" data-name="Path 7868" d="M9.186,22.857h5.653c.272,0,.309.028.3.3-.059,1.345-.124,2.689-.186,4.034q-.056,1.208-.109,2.417-.052,1.159-.107,2.319a1.6,1.6,0,0,1-1.744,1.66h-6.5c-.4,0-.8.006-1.193,0A1.617,1.617,0,0,1,3.681,32.3a2.976,2.976,0,0,1-.046-.438q-.1-1.976-.193-3.952-.108-2.36-.207-4.72c-.013-.3.016-.329.314-.329H9.186M6.049,27.569q0,1.7,0,3.4a.511.511,0,0,0,.536.53.537.537,0,0,0,.51-.6q0-3.317,0-6.634c0-.033,0-.066,0-.1a.523.523,0,1,0-1.045.035q0,1.683,0,3.366m2.614,0q0,1.7,0,3.4a.513.513,0,0,0,.583.53.528.528,0,0,0,.462-.544q0-3.366,0-6.732c0-.022,0-.044,0-.065a.523.523,0,1,0-1.044.047q0,1.683,0,3.366m2.614,0q0,1.7,0,3.4a.513.513,0,0,0,.583.53.529.529,0,0,0,.462-.544q0-3.366,0-6.732c0-.022,0-.044,0-.065a.523.523,0,1,0-1.044.047q0,1.683,0,3.366" transform="translate(-2.382 -16.843)" fill="#474a54"/>
          <path id="Path_7869" data-name="Path 7869" d="M4.173,2.09c.017-.258.02-.5.052-.744A1.474,1.474,0,0,1,5.518.043,22.84,22.84,0,0,1,7.964.024,1.561,1.561,0,0,1,9.417,1.659c0,.136,0,.272,0,.43H9.6c.86,0,1.72,0,2.581,0a1.326,1.326,0,0,1,1.205.619,1.484,1.484,0,0,1,.215.777c.006.278,0,.556,0,.834a.583.583,0,0,1-.648.647H.7c-.487,0-.7-.209-.694-.7,0-.332-.023-.667.01-1A1.308,1.308,0,0,1,1.434,2.091c.855-.005,1.71,0,2.564,0h.175m4.195-.008a5.071,5.071,0,0,0,0-.526.535.535,0,0,0-.581-.513H5.759a.487.487,0,0,0-.5.382,4.564,4.564,0,0,0-.007.657Z" transform="translate(0 0)" fill="#474a54"/>
        </g>
      </g>
    </svg>
  );
};

export default DeleteIcon;
