import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const OpenInNewTabIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} id="Group_17088" data-name="Group 17088" xmlns="http://www.w3.org/2000/svg" width="13.359" height="13.359" viewBox="0 0 13.359 13.359">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_11870" data-name="Rectangle 11870" width="13.359" height="13.359" transform="translate(0 0)" fill={color || '#FFF'}/>
        </clipPath>
      </defs>
      <g id="Group_15461" data-name="Group 15461" transform="translate(0 0)" clip-path="url(#clip-path)" fill={color || '#FFF'}>
        <path id="Path_8811" data-name="Path 8811" d="M6.067,17.458c-1.389,0-2.778-.011-4.167,0a1.883,1.883,0,0,1-1.83-1.4A2.857,2.857,0,0,1,0,15.469Q0,11.375,0,7.281A1.865,1.865,0,0,1,1.357,5.4a2.324,2.324,0,0,1,.575-.075q2.325-.009,4.65,0a.907.907,0,0,1,.451.135.521.521,0,0,1,.229.571.58.58,0,0,1-.483.479,1.355,1.355,0,0,1-.282.025H2.06c-.095,0-.19,0-.284.006a.567.567,0,0,0-.558.6c0,.484,0,.967,0,1.45v6.952a.632.632,0,0,0,.706.7h8.362a.591.591,0,0,0,.643-.673q0-2.332-.006-4.663a.682.682,0,0,1,.294-.626.6.6,0,0,1,.923.519c0,.033,0,.066,0,.1q0,2.317,0,4.635a1.871,1.871,0,0,1-1.892,1.922c-1.394.005-2.787,0-4.181,0" transform="translate(0 -4.103)" fill={color || '#FFF'}/>
        <path id="Path_8812" data-name="Path 8812" d="M29.371,3.279,25.049,7.6a.763.763,0,0,1-.507.283.544.544,0,0,1-.53-.266A.564.564,0,0,1,24,7a1.328,1.328,0,0,1,.2-.24c1.434-1.441,2.869-2.879,4.318-4.333-.035-.04-.078-.094-.126-.142Q27.8,1.7,27.206,1.1a.642.642,0,0,1-.238-.578.6.6,0,0,1,.494-.5A2.161,2.161,0,0,1,27.8,0q1.628,0,3.256,0h.071A.625.625,0,0,1,31.8.69q0,1.3,0,2.6c0,.327,0,.654-.014.98a.572.572,0,0,1-.393.539.545.545,0,0,1-.633-.149L29.486,3.384c-.036-.036-.075-.068-.115-.1" transform="translate(-18.445 0)" fill={color || '#FFF'}/>
      </g>
    </svg>
  );
};

export default OpenInNewTabIcon;
