export const FORMS_PATCH = 'FORMS_PATCH';
export const FORMS_REMOTE_GET_FORM = 'FORMS_REMOTE_GET_FORM';
export const FORMS_REMOTE_GET_LATEST_REVISION = 'FORMS_REMOTE_GET_LATEST_REVISION';
export const FORMS_APPEND_FORM_CHILD = 'FORMS_APPEND_FORM_CHILD';
export const FORMS_UNDO = 'FORMS_UNDO';
export const FORMS_REDO = 'FORMS_REDO';
export const FORMS_EDIT_REVISION = 'FORMS_EDIT_REVISION';
export const FORMS_REMOTE_PUT_FORM = 'FORMS_REMOTE_PUT_FORM';
export const FORMS_REMOTE_PUT_SAVE = 'FORMS_REMOTE_PUT_SAVE';
export const FORMS_SAVED = 'FORMS_SAVED';
export const FORMS_EDIT_FORM_CHILD = 'FORMS_EDIT_FORM_CHILD';
export const FORMS_APPEND_SECTION_CHILD = 'FORMS_APPEND_SECTION_CHILD';
export const FORMS_APPEND_COMPLEX_WIDGET = 'FORMS_APPEND_APPEND_COMPLEX_WIDGET';
export const FORMS_ADD_CHILD_AFTER = 'FORMS_ADD_CHILD_AFTER';
export const FORMS_SWAP_FORM_CHILD = 'FORMS_SWAP_FORM_CHILD';
export const FORMS_DELETE_FORM_CHILD = 'FORMS_DELETE_FORM_CHILD';
export const FORMS_UPDATE_SIMPLE_WIDGET = 'FORMS_UPDATE_SIMPLE_WIDGET';
export const FORMS_ADD_SIMPLE_WIDGET_AFTER = 'FORMS_ADD_SIMPLE_WIDGET_AFTER';
export const FORMS_DELETE_SECTION_CHILD = 'FORMS_DELETE_SECTION_CHILD';
export const FORMS_SWAP_SECTION_CHILD = 'FORMS_SWAP_SECTION_CHILD';
export const FORMS_EDIT_REVISION_WITHOUT_UNDO = 'FORMS_EDIT_REVISION_WITHOUT_UNDO';
export const FORMS_SET_CONDITIONAL = 'FORMS_SET_CONDITIONAL';
export const FORMS_REMOVE_CONDITIONAL = 'FORMS_REMOVE_CONDITIONAL';
