import React from 'react';

type Props = {
  color?: string;
};

const OnboardingGuid = ({ color }: Props) => {
  return (
    <svg id="uuid-68b6e885-13e0-4967-8a9a-00a2a69f4974" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <g id="uuid-130fd68f-8fb0-4bec-bfb2-e8c3d3d71b65" data-name="Group 8273" fill={color || '#FFF'}>
        <path id="uuid-7c0c7c54-d269-4724-be77-a25c06303984" data-name="Path 6263" d="m25.0434,47.7531H6.0919c-2.9128.3785-5.581-1.676-5.9595-4.5888-.0371-.2857-.051-.5739-.0414-.8618C.013,41.5096,0,40.7167,0,39.9194V10.058C0,9.2283,0,8.3943.0845,7.571.035,4.6557,2.3583,2.2522,5.2736,2.2028c.1921-.0033.3843.004.5756.0217,3.4662-.0238,6.9324,0,10.4116,0h27.3809c4.3328,0,6.3193,1.9497,6.3367,6.3497.0368,9.088.0195,18.1738,0,27.2617,0,2.2509.0412,4.5082-.1062,6.7504.0191,2.8391-2.2669,5.1562-5.1061,5.1754-.1432.001-.2863-.004-.429-.015-6.4341.013-12.8683,0-19.3025,0m.2643-22.2791l6.8978,6.8414,11.0745-11.1504-3.3492-3.6438c-.3401.3271-.6499.6174-.9467.9164-2.0472,2.0537-4.1161,4.0923-6.1287,6.1807-.5438.5611-.9294.5828-1.4255,0-.8666-1.0074-1.7483-1.9974-2.7491-3.1369l-3.3666,3.9948m-5.2968,2.1014c0-1.4645-.0347-2.8271.013-4.1898.026-.7301-.1928-.938-.9294-.9294-3.4662.0433-6.9194.0173-10.3813.0173h-1.2478c0,1.5771-.0325,2.9874.0325,4.3956.0918.3271.3469.5831.6737.6759,3.88.0433,7.7621.0303,11.8393.0303m.0498-10.2405c0-1.3215-.0433-2.5802.0152-3.8323.0433-.899-.2968-1.1438-1.1547-1.133-3.4662.0433-6.9324.0195-10.4138.0282-.3244.0185-.6477.0539-.9684.1062v4.831h12.5217Zm-12.604,15.2946c0,1.5165-.039,2.9311.0368,4.3328.122.3449.4138.6022.7712.6802,3.6554.0202,7.3108-.0043,10.9662-.0737.2665,0,.7474-.3661.7582-.5806.0693-1.4363.0347-2.8791.0347-4.3631l-12.5672.0043Z" />
      </g>
    </svg>
  );
};

export default OnboardingGuid;
