import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: 5,
  },
  largeText: {
    fontWeight: 400,
    fontSize: 14,
    padding: 5,
    paddingLeft: 8,
  },
  link: {
    '& a': {
      textDecoration: 'underline',
      color: 'currentColor',
    },
  },
  disabled: {
    pointerEvents: 'none',
  },
}));
