import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    background: '#f7f6f6',
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    paddingTop: 6,
    paddingBottom: 6,
  },
  title: {
    fontWeight: 500,
    fontSize: 16,
    color: 'rgb(0 0 0 / 77%)',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 1,
    '-webkit-box-orient': 'vertical'
  },
  paper: {
    borderRadius: 12,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  icon: {
    width: 20,
    height: 20,
  },
  closeButton: {
    padding: 4,
    marginLeft: 5,
    marginRight: -5,
  },
}));
