import React from 'react';
import { createTheme } from '@mui/material/styles';

import CheckedIcon from './icons/Checked.icon';
import UncheckedIcon from './icons/Unchecked.icon';

declare module '@mui/material/styles' {
  interface Palette {
    drawer?: {
      main?: string;
      header?: string;
      background?: string;
      hover?: string;
      border?: string;
      badgeNew?: string;
    };
  }
  interface PaletteOptions {
    drawer?: {
      main?: string;
      header?: string;
      background?: string;
      hover?: string;
      border?: string;
      badgeNew?: string;
    };
  }
}

export const skedBlue = '#008BCF';

export const skedTheme = createTheme({
  palette: {
    primary: {
      main: skedBlue,
    },
    secondary: {
      main: 'rgba(0,0,0,.87)',
    },
    error: {
      main: '#E6404D'
    },
    background: {
      default: 'rgb(35, 48, 68)',
      paper: '#fff'
    },
    drawer: {
      main: 'linear-gradient(45deg, hsla(200, 100%, 41%, 1) 0%, hsla(200, 100%, 45%, 1) 72%, hsla(200, 100%, 50%, 1) 100%)',
      header: 'linear-gradient(45deg, hsla(200, 100%, 45%, 1) 0%, hsla(200, 100%, 48%, 1) 72%, hsla(200, 100%, 50%, 1) 100%)',
      background: skedBlue,
      hover: '#ffffff2b',
      border: '#89c3f1',
      badgeNew: '#65B67F',
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      'Arial',
    ].join(','),
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '13px'
        }
      },
      defaultProps: {
        disableInteractive: true,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 'unset',
          // padding: '0px 16px'
        },
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: 'rgba(0, 0, 0, 0.87)',
          fontSize: '16px',
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.87)',
          minWidth: '40px',
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '25px',
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '16px'
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '24px',
        },
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '12px'
        }
      }
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'standard'
      },
      styleOverrides: {
        marginNormal: {
          marginTop: 0,
          marginBottom: 0,
        }
      }
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: skedBlue,
          fontSize: '14px',
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '12px',
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paperWidthSm: {
          maxWidth: 'unset',
          width: '75%',
        },
        paper: {
          overflowY: 'unset',
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiAutocomplete-clearIndicator': {
            '& .MuiSvgIcon-root': {
              fontSize: 12
            }
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontSize: '14px',
        },
        body2: {
          fontSize: '12px',
        },
        h4: {
          fontSize: '22px',
        },
        h6: {
          fontWeight: 'unset',
          fontSize: '22px',
        },
        caption: {
          fontSize: '12px',
        },
        subtitle1: {
          fontSize: '12px',
        }
      }
    },
    MuiButton: {
      defaultProps: {
        color: 'primary'
      },
      styleOverrides: {
        root: {
          // minWidth: '88px',
          textTransform: 'none',
          lineHeight: 1.5,
        },
        outlinedSizeSmall: {
          fontSize: '12px'
        }
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid lightgray',
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          textTransform: 'none',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        dividers: {
          padding: '0px 16px 24px 16px',
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      },
      styleOverrides: {
        root: {
          width: '256px',
        },
      }
    },
    MuiCheckbox: {
      defaultProps: {
        icon: <UncheckedIcon />,
        checkedIcon: <CheckedIcon />,
      },
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            width: 21,
            height: 21,
          }
        },
      }
    },
    MuiRadio: {
      defaultProps: {
        color: 'primary'
      }
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      }
    }
  },
});

export const sparkTheme = createTheme({
  palette: {
    primary: {
      main: skedBlue // let material-ui calculate the others
    },
    error: {
      main: '#d32f2f'
    },
    secondary: {
      main: 'rgba(0,0,0,.87)',
    },
    background: {
      default: 'rgb(35, 48, 68)',
      paper: '#fff'
    },
    drawer: {
      main: 'linear-gradient(45deg, hsla(0, 100%, 50%, 1) 0%, hsl(33deg 100% 50.34%) 87%)',
      header: 'linear-gradient(45deg, rgb(255 124 0) 0%, rgb(255 128 0 / 89%) 50%, rgb(254 128 0 / 76%) 100%)',
      background: 'hsla(0, 100%, 50%, 1)',
      hover: '#ffffff2b',
      border: 'hsla(0, 72%, 83%, 1)',
      badgeNew: '#65B67F',
    },
  },
  typography: {
    ...skedTheme.typography,
  },
  components: {
    ...skedTheme.components,
  },
});
