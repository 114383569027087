import { useState, useEffect } from 'react';
import { getSocket } from './websockets.js';

export function useSocket() {
  const [socket, setSocket] = useState(getSocket());

  useEffect(() => {
    const tryGetNewSocket = () => {
      setTimeout(() => {
        const newSocket = getSocket();
        if (newSocket) {
          console.log('reconnected to websocket server!');
          return setSocket(newSocket);
        }
        tryGetNewSocket();
      }, 5e3);
    };

    if (!socket) {
      return tryGetNewSocket();
    }

    const handleClose = () => {
      console.log('websockets disconnected - trying to reconnect');
      tryGetNewSocket();
    };
    socket.addEventListener('close', handleClose);
    return () => {
      socket.removeEventListener('close', handleClose);
    };
  }, [socket]);
  return socket;
}
