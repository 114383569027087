import { merge } from 'ramda';

import api from '../../../services/api';
import { getDateRange } from './calendar.service.js';

const defaultStatus = [
  'Scheduled',
  'Arrived',
  'Missed',
];

export const fetchAppointments = ({ date, view, timezone, status = defaultStatus }) => {
  const range = getDateRange(date, view, timezone);
  const body = {
    page: 1,
    perPage: 10000,
    query: merge({
      status,
      professionalId: null,
      appointmentTypeId: null
    }, range)
  };
  return api.post('appointment/query', body).then(({ data }) => data);
};

export const fetchApptEvents = ({
  date, view, timezone, status = defaultStatus, changeType = 'Canceled',
}) => {
  const range = getDateRange(date, view, timezone);
  const body = {
    page: 1,
    perPage: 1000,
    query: {
      appointment: {
        status,
        //        professionalId: null,
        //        appointmentTypeId: null,
        ...range,
      },
      changeType,
      createdAfter: range.after + 'Z',
    },
  };
  return api.post('appointment/log/query', body).then(({ data }) => data);
};

const fetchOfficeHours = () => {
  return api.get('officeHours');
};

const fetchSpecialOfficeHours = () => {
  return api.get('specialOfficeHours');
};

const fetchSchedules = () => {
  return api.get('schedule').then(items => {
    return Promise.all(items.map(s => {
      return Promise.all([
        api.get(`schedule/${s.id}/hours`),
        api.get(`schedule/${s.id}/hours/special`),
        api.get(`schedule/${s.id}/block`),
      ]).then(([hours, special, blocks]) => {
        s.hoursList = hours;
        s.specialHoursList = special;
        s.blocksList = blocks;
        return s;
      });
    })).then(items => {
      const all = { id: -1, name: 'All Schedules' };
      return [all].concat(items);
    });
  });
};

export const fetchCalendarData = ({ date, view, timezone }) => Promise.all([
  fetchAppointments({ date, view, timezone }),
  fetchOfficeHours(),
  fetchSchedules(),
  fetchSpecialOfficeHours(),
]).then(([appointments, officeHours, schedules, specialOfficeHours]) => ({ appointments, officeHours, schedules, specialOfficeHours }));

