import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const RoundExclamationIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} id="Group_17340" data-name="Group 17340" xmlns="http://www.w3.org/2000/svg" width="19.049" height="19.015" viewBox="0 0 19.049 19.015">
      <defs>
        <clipPath id="clip-path-exclamation">
          <rect id="Rectangle_8060" data-name="Rectangle 8060" width="19.049" height="19.015" fill={color || '#bf2828'}/>
        </clipPath>
      </defs>
      <g id="Group_8889" data-name="Group 8889" clipPath="url(#clip-path-exclamation)">
        <path id="Path_6815" data-name="Path 6815" d="M19.049,9.574a9.428,9.428,0,0,1-9.5,9.441A9.349,9.349,0,0,1,0,9.2,9.423,9.423,0,0,1,9.545,0a9.535,9.535,0,0,1,9.5,9.574m-11-2.261c.046.822-.063,1.665.059,2.5a1.353,1.353,0,0,0,1.474,1.254,1.4,1.4,0,0,0,1.337-1.414,44.138,44.138,0,0,0,0-4.856,1.405,1.405,0,0,0-2.8-.1C8,5.564,8.1,6.431,8.051,7.313m1.506,5.411a1.425,1.425,0,0,0-1.494,1.413,1.488,1.488,0,0,0,1.419,1.437,1.5,1.5,0,0,0,1.469-1.43,1.38,1.38,0,0,0-1.394-1.421" transform="translate(0 0)" fill={color || '#bf2828'}/>
      </g>
    </svg>
  );
};

export default RoundExclamationIcon;
