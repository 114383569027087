import React from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import './recurring-edit.css';
import { pipe, pathOr, dissoc, cond, includes, always, merge } from 'ramda';
import * as rawActions from './recurring-edit.actions.jsx';
import { resetRecurring } from '../../recurring.actions.jsx';
import CreateMessage from '../../../../components/create-message.component';
import {
  updateAllAutomation, addLocalAutomation, removeAutomation, updateAutomation,
  getAutomationsByMsgId, automationsPatch,
} from '../../../Automations/automations.actions';

const AutomatedMessageEdit = ({ match, history, location }) => {
  const dispatch = useDispatch();
  const actions = bindActionCreators(
    merge(rawActions, {
      updateAllAutomation,
      addLocalAutomation,
      removeAutomation,
      updateAutomation,
      getAutomationsByMsgId,
      automationsPatch,
    }),
    dispatch);
  const props = useSelector((state) => {
    return ({
      ...state.editRecurring,
      ...pipe(pathOr({}, ['login', 'office']), dissoc('email'))(state),
      office: pathOr({}, ['login', 'office'])(state),
      features: pathOr([], ['login', 'features'])(state),
      isCopied: pathOr(false, ['messages', 'state'])(state) === 'COPY',
      automations: pathOr([], ['automations', 'automations', 'data'])(state),
    });
  });
  const messageType = cond([
    [includes('apptchange'), always('ApptChange')],
    [includes('automation'), always('Automation')],
    [includes('smart-reply'), always('Automation')],
    [includes('birthday'), always('Birthday')],
    [includes('recurring'), always('Recurring')],
    [includes('reactivation'), always('Recall')],
  ])(match.path);
  const isSpark = location.search.split('spark=')[1] === 'true';
  return (
    <CreateMessage
      {...props}
      messageType={messageType}
      urlId={match.params.id}
      actions={actions}
      resetMessages={() => dispatch(resetRecurring())}
      history={history}
      location={location}
      isSpark={isSpark}
    />
  );
};

export default AutomatedMessageEdit;
