import React, { HTMLAttributes } from 'react';
import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';

export const PageHeader = withStyles({
  root: {
    marginBottom: '15px'
  },
  h5: {
    fontSize: '18px',
    marginBottom: 0
  },
  h6: {
    fontSize: '16px',
    marginBottom: '5px'
  }
})(Typography);

const Component = 'div';

interface DivProps extends HTMLAttributes<HTMLDivElement> {
  classes?: {
    root: string;
  };
}

export const Div = ({ classes, className, ...props }: DivProps) => <Component className={classes.root + ' ' + className} {...props} />;

export const Row = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
})(Div);

export const Spacer = withStyles({
  root: {
    flexGrow: 1
  }
})(Div);

export const Section = withStyles({
  root: {
    marginTop: '10px',
    marginBottom: '10px',
    paddingBottom: '10px',
  }
})(Div);
