import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    display: 'grid',
    gridTemplateRows: 'auto auto',
    borderBottomLeftRadius: 12,
    borderBottomRightRadius: 12,
    maxWidth: 600,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 7,
  },
  warning: {
    backgroundColor: '#fffae6',
  },
  error: {
    backgroundColor: '#faeef0',
  },
  info: {
    backgroundColor: '#fffae6',
  },
  titleBody: {
    marginRight: '5px',
    marginLeft: '5px',
  },
  body: {
    fontSize: 14,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 16,
  },
}));
