import React, { useState, useEffect } from 'react';
import { Card, Grid } from '@mui/material';
import api from '../../services/api.js';

let timeout;

const Offline = (props) => {
  const [state, setState] = useState({
    timer: false,
  });

  useEffect(() => {
    if (!state.timer) {
      setState({ timer: true });
      timeout = window.setTimeout(() => {
        api.get('office/me')
          .then(() => props.history.goBack())
          .catch(() => setState({ timer: false }));
      }, 10000);
    }
    return () => clearTimeout(timeout);
  }, [state.timer]);

  return (
    <Grid container display="flex" justifyContent="center">
      <Grid item alignSelf="center" md={4} paddingY={2}>
        <Card style={{ padding: 15 }}>
          <legend
            style={{
              fontWeight: 'lighter',
              fontSize: 21,
              height: 40,
              verticalAlign: 'middle',
            }}
          >Uh-oh! SKED is down! 😭</legend>
          <div>
              Looks like SKED is having an issue. We'll get it back up in a jiffy! 👌

            <br />
            <br />

              We will attempt to connect to SKED every 10 seconds. Once we make the connection, we'll redirect you back to where you were!  🎊
            <br />
            <br />

              If you have concerns, questions, or queries send the SKED Support Team a email at: <a href="mailto:info@sked.life">info@sked.life</a>

          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Offline;
