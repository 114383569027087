import React from 'react';
import * as R from 'ramda';

import { tzParseFormat } from '../../../../services/joda';
import { Spacer } from '../../../../components/PageHeader';

import { Message, MmsAttachment, AvailableFiles, Urls } from '../../message-types';

import { useStyles } from './chatItem.styles';

import {
  Paper,
  Tooltip,
  Box,
  IconButton
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ErrorIcon from '@mui/icons-material/Error';
import OpenInIcon from '@mui/icons-material/OpenInNew';

export const MmsItem = ({ mms, fileName }: { mms: Urls, fileName: string }) => {
  const classes = useStyles();
  const { mimeType, phiId, url } = mms;
  const [anchorEl, setAnchorEl] = React.useState(false);
  const openPopup = () => {
    setAnchorEl(true);
  };
  const closePopup = () => {
    setAnchorEl(false);
  };
  const linkOnly = React.useMemo(() => {
    return (mimeType.indexOf('image') === -1 && mimeType.indexOf('video/ogg') === -1);
  }, [mimeType]);

  return (
    <div className={classes.mmsContainer} style={linkOnly ? { width: 'unset' } : {}} onMouseLeave={closePopup}>
      {(mimeType.indexOf('image') > -1 || mimeType.indexOf('video/ogg') > -1) &&
        <Box
          aria-haspopup="true"
          onMouseEnter={openPopup}>
          {mimeType.indexOf('image') > -1 &&
            <img id={url} key={phiId} src={url} className={classes.mmsItem} />}
          {mimeType.indexOf('video/ogg') > -1 &&
            <video id={url} key={phiId} src={url} className={classes.mmsItem} />}
        </Box>}
      {linkOnly &&
        <a key={phiId} href={url}>
          {fileName}
        </a>}
      <Paper
        id="mouse-over-popover"
        style={{ display: anchorEl ? 'block' : 'none' }}
        className={classes.popup}>
        <IconButton component='a' href={url} target='_blank'>
          <OpenInIcon style={{ color: 'black' }} />
        </IconButton>
      </Paper>
    </div>
  );
};

type ChatItemProps ={
  message: Message;
  tz: string;
  phis: Urls[];
}

const ChatItem = ({ message, tz, phis }: ChatItemProps) => {
  const classes = useStyles();
  const toOffice = R.pathOr(false, ['to', 'ToOffice'])(message);
  const body = R.pathOr('', ['smsData', 'body'], message);
  const attachments = R.pathOr([], ['smsData', 'attachments'], message);

  const mmsAttachments: MmsAttachment[] = React.useMemo(() => {
    const msgPhi = R.pathOr([], ['smsData', 'phi'], message);
    return msgPhi.map((mp) => {
      const ms = phis.find((p) => p.phiId === mp.phiId);
      const f = mp.availableFiles.find(({ phiId }: AvailableFiles) => {
        return mp.phiId === phiId;
      });
      /* decode twice because sometimes the file names have things like %2520 in
         them and a single decodeURI won't do it all.
      */
      const fileName = f ? decodeURI(decodeURI(R.last(f.path.split('/'))))
        : decodeURI(mp.fileName);
      if (ms) {
        return {
          mms: ms,
          fileName,
        };
      }
      return {
        mms: null,
        fileName,
      };
    }).filter((p: MmsAttachment) => p.mms) || [];
  }, [message, phis]);

  const onlyOneEmoji = (str: string) => {
    // eslint-disable-next-line no-control-regex
    const hasUnicode = /[^\u0000-\u00ff]/.test(str);
    return hasUnicode && str.length === 2;
  };

  const ShowStatus = ({ status }: { status: string }) => {
    if (status === 'Pending' || status === 'Sent') {
      return <MoreHorizIcon fontSize="small" />;
    }
    if (status === 'Failed') {
      return <ErrorIcon color="warning" fontSize="small" />;
    }
    return null;
  };

  const getDateTime = (date: string) => {
    if (date && tz) {
      return tzParseFormat(date, tz, 'h:mm a')?.toLowerCase();
    }
    return '';
  };

  if (!body && attachments.length === 0 && mmsAttachments.length === 0) {
    return null;
  }

  return (
    <div className={classes.chatItemRoot + ' ' + `msg-${message.msgId}`}>
      <div className={classes.chatItemContainer}>
        {!toOffice && <Spacer />}
        <div className={classes.messageContainer} style={{ alignItems: toOffice ? 'flex-start' : 'flex-end' }}>
          <Paper
            key={message.msgId}
            elevation={0}
            style={{
              padding: '8px 17px',
              fontSize: 14,
              color: toOffice ? 'black' : 'white',
              background: toOffice ? 'linear-gradient(45deg, rgba(238,238,238,1) 23%, rgba(241,241,244,1) 100%)' : 'linear-gradient(90deg, rgb(0, 139, 209) 0%, rgb(0, 153, 230) 72%, rgb(0, 170, 255) 100%)',
              maxWidth: 450,
              position: 'relative',
              borderRadius: 10,
            }}
          >
            <div className={classes.messageContent} style={{ fontSize: onlyOneEmoji(body) ? '32px' : 'auto' }}>
              {body}
            </div>
            {!R.isEmpty(attachments) &&
              attachments.map(({ attachmentName, attachmentUrl }) => (
                <>
                  <a
                    style={{
                      color: toOffice ? 'unset' : 'white',
                      textDecoration: 'underline',
                    }}
                    href={attachmentUrl}
                    target='_blank'>
                    {attachmentName}
                  </a>
                  <br />
                </>
              ))}
            {mmsAttachments.map(({ mms, fileName }: MmsAttachment) => (
              <MmsItem mms={mms} fileName={fileName} />
            ))}
            <svg className={toOffice ? classes.leftTail : classes.tail} xmlns="http://www.w3.org/2000/svg" width="8.941" height="10.121" viewBox="0 0 8.941 10.121">
              <path d="M8.941,9.877C4.664,7.018,3.654,3.614,3.443,0V1.025A9.966,9.966,0,0,1,0,8.563,14.14,14.14,0,0,0,8.941,9.877" fill={toOffice ? '#f0f0f0' : 'rgb(0, 170, 255)'} />
            </svg>
          </Paper>
          <Tooltip title={toOffice ? '' : message.smsStatus} arrow placement="left-end">
            <div className={classes.messageTime}>
              <span className={classes.date}>{getDateTime(message.created)}</span>
              {!toOffice && <ShowStatus status={message.smsStatus} />}
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default ChatItem;
