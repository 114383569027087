import { LocalTime } from '@js-joda/core';

const re = /(\d{0,2})(:\d{0,2})?(:\d{0,2})?\s*(am|pm)?/i;

const pad2 = n => ('00' + Math.abs(n)).slice(-2);

// A very forgiving text parser to standard time string
// 10am, 10 am, 10:00 AM, 10 will all parse to 10 am
// 15:30, 15:30 pm, 3:30 pm will parse to 3:30 pm
export const parseTime = (value = '') => {
  const matches = value.trim().match(re);
  if (matches) {
    try {
      const [, _hours, _minutes = '', _seconds = '', merdian = ''] = matches;
      if (!_hours && !_minutes) {
        return {
          parsed: '',
          error: 'Not formatted correctly'
        };
      }
      const minutes = pad2(_minutes ? _minutes.replace(':', '') : '00');
      const seconds = pad2(_seconds ? _seconds.replace(':', '') : '00');
      const hours = pad2(merdian.toLowerCase() === 'pm' && Number(_hours) < 12 ? Number(_hours) + 12 : Number(_hours));

      const parsed = LocalTime.parse(`${hours}:${minutes}:${seconds}`).toString();
      return {
        parsed,
        error: ''

      };
    } catch (e) {
      return {
        error: 'Not formatted correctly',
        parsed: ''
      };
    }
  }
  return {
    error: 'Not formatted correctly.',
    parsed: ''
  };
};
