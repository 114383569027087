import React, { useRef, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';

import { alertDanger } from '../../../../components/Alerts/alerts.actions';
import { useStyles, style } from './skedForm.styles';

import Form, { StripeData, Body } from '../Form/Form.component';

interface IWindow extends Window {
  Stripe?: (value: string) => IStripe;
}

type IStripe = {
  createToken?: (element: Element, data?: StripeData) => Promise<{ token: { id: string } }>;
  elements?: () => IElement;
}

type IElement = {
  create?: (value: string, value2: object) => {
    mount?: (value: HTMLDivElement) => Element;
  }; 
}

const stripe = (window as IWindow).Stripe(`${process.env.STRIPE_API_KEY}`);
const elements = stripe.elements();

type Props = {
  onSubmit: (body: Body) => void;
  setLoading: (value: boolean) => void;
  open: boolean;
  setOpen: (value: boolean) => void;
  loading: boolean;
  finished: boolean;
}

const SkedForm = ({ onSubmit, setLoading, loading, open, setOpen, finished }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const elementRef = useRef(null);
  const cardDOM = useRef(null);

  const handleSubmit = async (stripeData: StripeData, formBody: Body) => {
    try {
      setLoading(true);
      const result = await stripe.createToken(elementRef.current, stripeData);
      const body = {
        ...formBody,
        stripeToken: result.token?.id,
      };
      onSubmit(body);
    } catch (e) {
      setLoading(false);
      console.log('error ', e);
      dispatch(alertDanger('Failed to submit card information.'));
    }
  };

  useLayoutEffect(() => {
    if (elementRef.current === null && elements && cardDOM.current !== null) {
      const element = elements.create('card', { style });
      elementRef.current = element;
      element.mount(cardDOM.current);
    }
  }, []);

  useLayoutEffect(() => {
    return () => {
      if (elementRef.current) {
        elementRef.current.destroy();
      }
    };
  }, []);

  return (
    <Form
      card={
        <div id="card-element" ref={cardDOM} className={classes.cardContainer} />
      }
      isSpark
      open={open}
      setOpen={setOpen}
      loading={loading}
      onSubmit={handleSubmit}
      finished={finished}
    />
    
  );
};

export default SkedForm;
