import { remoteAction } from '../../services/actionService.js';
import api from '../../services/api.js';
import * as at from '../../actionTypes';
import {
  pipe, map, includes,
  isEmpty, merge, without,
  not, filter, compose, length,
  equals, uniq, identity,
  ifElse, find, drop, trim,
  head, always, split, path,
} from 'ramda';

export const expandSection = (title) => ({
  type: at.TUTORIALS_EXPAND_SECTION,
  data: title,
});

export const compressSection = (title) => ({
  type: at.TUTORIALS_COMPRESS_SECTION,
  data: title,
});

export const redirect = (title) => ({
  type: at.TUTORIALS_REDIRECT_STEP,
  data: title,
});

export const setFilterData = (title) => ({
  type: at.TUTORIALS_PATCH_FILTER_DATA,
  data: title,
});

const convertTutorials = (tutorialHTML) => {
  const el = document.createElement( 'html' );
  el.innerHTML = tutorialHTML;
  const sectionsHtmlList = el.innerHTML.split('<p>&nbsp;</p>');
  return sectionsHtmlList.map((str) => {
    const e = document.createElement( 'html' );
    e.innerHTML = str;
    const elems = e.getElementsByTagName('li');
    const steps = filter(
      pipe(length, equals(1), not)
    )(uniq(Array.from(elems).map(e => filter(identity)(e.innerText.split('\n')))));
    const header = e.getElementsByTagName('p');
    const [sectionTitle, sectionBody] = head(header).innerText.split(':');
    return ({
      title: sectionTitle.trim(),
      body: sectionBody.trim(),
      steps: map((s) => {
        const [t] = s;
        const body = ifElse(
          identity,
          compose(trim, drop(5)),
          always('')
        )(find(includes('body:'), s));
        const link = ifElse(
          identity,
          compose(trim, drop(5)),
          always(null)
        )(find(includes('link:'), s));
        const plans = ifElse(
          identity,
          compose(map(trim), split(','), drop(6)),
          always([])
        )(find(includes('plans:'), s));
        const excludeEHRs = ifElse(
          identity,
          compose(trim, drop(12)),
          always([])
        )(find(includes('excludeehrs:'), s));
        const id = drop(3, find(includes('id:'), s)).trim();
        return {
          title: t,
          body,
          plans,
          link,
          id,
          excludeEHRs,
        };
      }, steps),
    });
  });
};

export const getFilterData = (plan, ehrSystem) => remoteAction({
  type: at.TUTORIALS_GET_DATA,
  action: () => Promise.all([
    api.get('help/admin-tutorials'),
    api.get('office/tutorial'),
  ]).then(([allTutorials, { items }]) => {
    const data = convertTutorials(allTutorials.body);
    const mappedTutorials = pipe(
      map((d) => {
        const steps = d.steps.filter((s) => {
          return includes(plan, s.plans) && !(s.excludeEHRs && includes(ehrSystem, s.excludeEHRs));
        }).map((s) => {
          return includes(s.id, items) ? {
            ...s,
            isComplete: true,
          } : s;
        });
        return isEmpty(steps) ? null : merge(d, { steps });
      }),
      without([null])
    )(data);
    return ({
      data,
      filterData: mappedTutorials,
    });
  }).catch((e) => {
    console.log(e);
  }),
});

export const getFilterDataElse = () => (dispatch, getStore) => {
  const office = path(['login', 'office'], getStore());
  return dispatch(getFilterData(office.plan, office.ehrSystem));
};

export const updateTutorialItems = (isComplete, items) => remoteAction({
  type: at.TUTORIALS_UPDATE_TUTORIAL,
  action: () => isComplete ?
    api.post('office/tutorial', { items })
    :
    api.delete('office/tutorial', { data: { items } }),
});
