import React from 'react';

type Props = {
  color?: string;
};

const SingleSmsIcon = ({ color }: Props) => {
  return (
    <svg id="uuid-374d2b59-9e51-419a-a828-cb3f31afa7ea" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill={color || '#FFF'}>
      <path id="uuid-e9cbb8da-2981-4c97-b779-6e3f7d06ab26" data-name="Path 6794" d="m41.997,1.7754H8.0141C3.5857,1.7864.0012,5.3788,0,9.8072v21.432c-.0061,4.4419,3.5898,8.0478,8.0318,8.0539.003,0,.0059,0,.0089,0h2.6743v6.9457c-.0724.765.3462,1.4922,1.0441,1.8138.6758.3218,1.4809.1836,2.0107-.3451,3.2443-2.7045,6.4885-5.401,9.7328-8.0893.2543-.1954.5642-.3046.8848-.3119,5.8773-.0177,11.7546,0,17.634-.0155,4.399-.0386,7.9523-3.6017,7.9787-8.0008v-.6282c0-6.953,0-13.9061,0-20.8591-.0049-4.4242-3.5876-8.011-8.0119-8.0207m-6.2423,15.1809c1.9741.0159,3.5616,1.6292,3.5457,3.6033-.0159,1.9741-1.6292,3.5616-3.6033,3.5457-1.9706-.0159-3.5566-1.6236-3.5457-3.5943.0182-1.9746,1.6287-3.5633,3.6033-3.5547m-10.7901,0c1.9741-.0195,3.5903,1.5649,3.6098,3.539.0195,1.9741-1.5649,3.5903-3.539,3.6098s-3.5903-1.5649-3.6098-3.539c-.0001-.0117-.0002-.0235-.0002-.0352.0047-1.9585,1.5809-3.5504,3.5392-3.5746m-10.6994,7.1403c-1.9741-.0183-3.5596-1.6336-3.5412-3.6077.0183-1.9741,1.6336-3.5596,3.6077-3.5412,1.968.0183,3.551,1.6239,3.5413,3.592-.017,1.9772-1.6305,3.5679-3.6078,3.5569" />
    </svg>
  );
};

export default SingleSmsIcon;
