import React from 'react';
import {
  TextField,
  CircularProgress,
  Chip,
  Autocomplete
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import api from '../../services/api.js';
import { useTypeahead } from '../../services/typeahead.hook.js';
import { append, remove, uniqBy, prop, pipe } from 'ramda';
import { Row } from '../../components/PageHeader';

const useStyles = makeStyles(() => ({
  menuPaper: {
    fontSize: 14,
  },
}));

const searchClient = (term = '') => {
  const [lastName, firstName] = term.split(' ');
  return api.post('client/query', {
    query: {
      firstName,
      lastName,
      sortBy: [{
        direction: 'Asc',
        field: 'LastName'
      }, {
        direction: 'Asc',
        field: 'FirstName'
      }],
      isLead: false,
    },
    page: 0,
    perPage: 25
  });
};

const defaultData = {
  data: []
};

export default function ClientSearch({ clients, setClients, showEmail, showPhone }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [autoVal, setAutoVal] = React.useState(null);
  const [inputVal, setInputVal] = React.useState('');


  const clientState = useTypeahead(searchClient, defaultData);

  const handleChange = (e, value) => {
    setAutoVal(value);
    if (value) {
      setClients(pipe(
        append(value),
        uniqBy(prop('id'))
      ));
      setTimeout(() => {
        setAutoVal({
          firstName: inputVal,
          lastName: ''
        });
      });
    }
  };

  const handleDelete = (i) => {
    setClients(remove(i, 1));
  };

  const handleInputChange = e => {
    if (e && e.target.value) {
      console.log('set input change', e.target.value);
      setInputVal(e.target.value);
      clientState.invoke(e.target.value);
    }
  };


  return (
    <Row style={{ flexWrap: 'wrap' }}>
      <Autocomplete
        id="client-search"
        style={{ width: 255 }}
        disableCloseOnSelect
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        filterOptions={(list) => list}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => {
          const name =
            option.firstName +
            ' ' +
            option.lastName +
            [showEmail && option.email, showPhone && option.phone, option.birthday].reduce((a, c) => {
              if (a === '' && c) {
                return ' | ' + c;
              } else if (c) {
                return a + ', ' + c;
              } else {
                return a;
              }
            }, '');
          return name;
        }}
        ListboxProps={{ className: classes.menuPaper }}
        options={clientState.data.data}
        loading={clientState.loading}
        value={autoVal}
        onChange={handleChange}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Clients"
            placeholder='LastName FirstName'
            variant='standard'
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {clientState.loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      {clients.map((c, i) => {
        return (
          <Chip
            key={c.id}
            label={`${c.firstName} ${c.lastName}`}
            onDelete={() => handleDelete(i)}
          />
        );
      })}

    </Row>
  );
}
