import React, { useState } from 'react';
import * as R from 'ramda';

import Icon from '../../icons/Professional.icon';
import FilterHeader from '../FilterHeader/FilterHeader.component';
import HeaderButton from '../HeaderButton/HeaderButton.component';

import { useStyles } from './filterProfessional.styles';

import {
  Popover,
  Grid,
  Autocomplete,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Professional = {
  id: number;
  name?: string;
  firstName: string;
  lastName: string;
  displayName?: string;
  professionalName?: string;
  displayFirstName: string;
  displayLastName: string;
  isHidden?: boolean;
}

type FilterProfessionalProps = {
  save: ({ professionals }: { professionals: Professional[] }) => void;
  setProps: (professionals: Professional[]) => void;
  allProfessionals: Professional[];
  selectedPros: Professional[];
  isShowingHiddenProfessional?: boolean;
  setIsShowingHiddenProfessional?: (value: boolean) => void;
  onlyIcon?: boolean;
}

const FilterProfessional = ({ setProps, selectedPros, allProfessionals, save, setIsShowingHiddenProfessional, isShowingHiddenProfessional, onlyIcon }: FilterProfessionalProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleProfessionalChange = (pro: Professional, state: { selected: boolean }) => {
    if (state.selected) {
      const value = selectedPros.filter(item => item.id !== pro.id);
      setProps(value);
      save({ professionals: value });
    } else {
      const value = R.pipe(
        R.append(pro),
        R.uniqBy(R.prop('id'))
      )(selectedPros);
      setProps(value);
      save({ professionals: value });
    }
  };

  const handleDelete = (i: number) => {
    const value = R.remove(i, 1, selectedPros);
    setProps(value);
    save({ professionals: value });
  };

  const showProObj = (pro: Professional) => {
    const showName = pro.firstName || pro.lastName;
    const name = ` (${pro.firstName || ''} ${pro.lastName || ''})`;
    const displayName = `${pro.displayFirstName || ''} ${pro.displayLastName || ''}`;
    return displayName + (showName ? name : '');
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleReset = () => {
    setProps([]);
    save({ professionals: [] });
  };


  return (
    <Grid>
      <Grid marginRight={0.5}>
        <HeaderButton
          title={`${selectedPros.length > 0 ? selectedPros.length : ''} Professional`}
          Icon={Icon}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          active={selectedPros.length > 0}
          type='filter'
          onlyIcon={onlyIcon}
        />
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid width={420}>
          <FilterHeader
            title='Professional'
            reset={handleReset}
            resetDisabled={selectedPros?.length === 0}
          />
          <Grid padding={1} paddingTop={2} paddingBottom={2}>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={allProfessionals}
              getOptionLabel={(option) => `${option.firstName || ''} ${option.lastName || ''}`}
              renderOption={(_, pro, state) => {
                console.log(state);
                return (
                  <MenuItem className={state.selected ? classes.selected : ''} onClick={() => handleProfessionalChange(pro, state)} key={pro.id}>
                    <p className={classes.selectFontSize} style={{ margin: 0, whiteSpace: 'normal', width: 255, color: pro.isHidden ? '#AAA' : '#000' }}>{showProObj(pro)}</p>
                  </MenuItem>
                );
              }}
              ListboxProps={{ className: classes.menuPaper }}
              value={selectedPros}
              groupBy={(option) => option.professionalName}
              disableCloseOnSelect
              disableClearable
              autoHighlight
              renderTags={(value: readonly Professional[], getTagProps) =>
                value.map((option, index: number) => (
                  <Grid key={option.id} {...getTagProps({ index })} className={classes.item}>
                    {`${showProObj(option)}`}&nbsp;
                    <CloseIcon onClick={() => handleDelete(index)} />
                  </Grid>
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search Professional"
                  variant='outlined'
                  size='small'
                  className={classes.input}
                />
              )}
            />
            <Grid paddingLeft={1}>
              <FormControlLabel control={
                <Checkbox
                  checked={isShowingHiddenProfessional}
                  onChange={e => setIsShowingHiddenProfessional(e.target.checked)}
                  name="checkedC" />
              } label="Show Hidden Professionals" />
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
};

export default FilterProfessional;
