import React, { ComponentProps } from 'react';

const UncheckedIcon = (props: ComponentProps<'svg'>) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
      <g id="checkbox-unchecked-icon" fill="#fff" stroke="#97a0a5" strokeWidth="1">
        <rect width="14" height="14" rx="2" stroke="none"/>
        <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" fill="none"/>
      </g>
    </svg>
  );
};

export default UncheckedIcon;
