import React, { useRef, useMemo } from 'react';
import { DropTarget } from 'react-dnd';
import * as R from 'ramda';

import { Button, Paper, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

import { popup } from '../../../services/Popup.js';

import TypeHeader from './type-header.component';

import { ItemTypes } from './constants.jsx';
import { useStyles } from './type-container.styles';

const options = [
  { value: 5, label: 5 },
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 20, label: 20 },
  { value: 30, label: 30 },
  { value: 45, label: 45 },
  { value: 60, label: 60 },
  { value: 75, label: 75 },
  { value: 90, label: 90 }
];

const optionItem = (option) => (
  <option key={option.value} value={option.value}>{option.label}</option>
);

export function TypeContainer({
  sked,
  index,
  nameEdit,
  nameRef,
  localPatch,
  schedules,
  busy,
  save,
  patch,
  getHours,
  skedPatch,
  removeWithoutId,
  remove,
  changeOrder,
  connectDropTarget,
  isOver,
  children,
  displayRoom,
  parentHeight,
  updatedNumberOfAppointments,
  hasLinkedScheduleFeature
}) {
  const classes = useStyles();
  const headerRef = useRef();
  const footerRef = useRef();

  const height = useMemo(() => {
    let headerSize = 80;
    let footerSize = 0;
    if (headerRef.current) {
      headerSize = headerRef.current.getBoundingClientRect().height;
    }
    if (footerRef.current) {
      footerSize = footerRef.current.getBoundingClientRect().height;
    }
    const padding = 64;
    return (parentHeight - padding) - headerSize - footerSize;
  }, [parentHeight, headerRef, footerRef]);

  return connectDropTarget(
    <div className={classes.root}>
      <div
        key={sked.id}
        className={classes.container}
        style={{
          marginRight: index === 0 ? 0 : 8,
          marginLeft: index === 1 ? 10 : 0,
        }}
      >
        <TypeHeader
          id={sked.id}
          name={sked.name}
          nameEdit={nameEdit}
          index={index}
          nameRef={nameRef}
          localPatch={localPatch}
          skedPatch={skedPatch}
          removeWithoutId={removeWithoutId}
          remove={remove}
          changeOrder={changeOrder}
          ref={headerRef}
          hasLinkedScheduleFeature={hasLinkedScheduleFeature}
          getHours={() => getHours(sked, 'other')}
        />
        <Paper className={classes.content}>
          <Paper
            key={sked.id}
            className={classes.scrollContent}
            style={{
              maxHeight: height,
              backgroundColor: isOver ? '#008BCF33' : 'white',
            }}>
            {R.pipe(
              R.length,
              R.equals(0)
            )(sked.professionals) &&
              <div>
                Empty
              </div>}
            {children}
          </Paper>
          {sked.id !== 0 &&
            <div ref={footerRef}>
              <div style={{ marginBottom: '20px', marginTop: '10px', display: 'flex' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <input
                    type='number'
                    placeholder='5'
                    value={sked.numberOfAppointments}
                    style={{ width: '30px', marginRight: '5px' }}
                    onChange={(e) => skedPatch('numberOfAppointments', e.target.value, index)}
                  />
                  <div>
                    appointments per
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      marginLeft: '5px'
                    }}>
                    <select
                      value={sked.slotLength}
                      onChange={(e) => skedPatch('slotLength', e.target.value, index)}
                      style={{ marginRight: '5px' }}>
                      {options.map(optionItem)}
                    </select>
                    <div>
                      minutes
                    </div>
                  </div>
                </div>
              </div>
              {displayRoom &&
                <label className={classes.roomContainer}>
                  Room:
                  <input
                    value={R.isNil(sked.room) ? undefined : sked.room}
                    style={{
                      marginLeft: '5px',
                      marginRight: '5px',
                    }}
                    type='text'
                    onChange={(e) => skedPatch('room', e.target.value, index)}
                  />
                  <Tooltip
                    title="For Room View: This must match the room name in ChiroTouch EXACTLY!"
                    placement="top"
                    arrow>
                    <InfoIcon style={{ fontSize: '14px' }} />
                  </Tooltip>
                </label>}
              <div className={classes.actionContainer}>
                <Button
                  variant='contained'
                  onClick={() => {
                    if (!busy) {
                      save(schedules, updatedNumberOfAppointments); // save everything so people don't forget to save any schedule
                      patch({ success: true });
                    }
                  }}>
                  Save
                </Button>
                <Button
                  variant='outlined'
                  onClick={() => {
                    sked.id !== undefined ?
                      getHours(sked) :
                      popup('Information!', 'You must save all schedules before you can edit hours!');
                  }}>
                  Set hours
                </Button>
              </div>
            </div>}
        </Paper>
      </div>
    </div>
  );
}

const squareTarget = {
  drop(props, monitor) {
    const { selecteds, from } = monitor.getItem();
    if (from !== props.index)
      props.move(selecteds, from, props.index);
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

export default DropTarget(ItemTypes.TYPE, squareTarget, collect)(TypeContainer);
