import React from 'react';

type Props = {
  color?: string;
};

const ConversationSmsIcon = ({ color }: Props) => {
  return (
    <svg id="uuid-55340fb5-611f-486b-a3c4-069a62a5aa01" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <g id="uuid-59ad9d19-f061-4a5c-aa07-5c2afb955375" data-name="Group 8828" fill={color || '#FFF'}>
        <path id="uuid-8b68b80f-8daa-4300-854e-a919a35735e8" data-name="Path 5991" d="m29.3105,12.6912H5.5934C2.5043,12.698.0029,15.2025,0,18.2916v14.9586c-.0039,3.0998,2.5059,5.6159,5.6057,5.6198.0018,0,.0035,0,.0053,0h1.8674v4.8475c-.0502.5337.2416,1.0411.7283,1.2661.4733.2286,1.0395.1323,1.4107-.2398,2.2618-1.888,4.5277-3.7689,6.7977-5.6427.1779-.1367.3947-.2132.6189-.2187,4.1016-.0123,8.2049,0,12.3065-.0106,3.0698-.0269,5.5495-2.513,5.5687-5.5828v-14.9991c-.0029-3.0881-2.5035-5.5919-5.5916-5.5987m-4.3555,10.596c1.378.0107,2.4864,1.1365,2.4756,2.5145-.0107,1.378-1.1365,2.4864-2.5145,2.4756-1.3759-.0107-2.4834-1.1332-2.4757-2.5091.0126-1.3781,1.1364-2.4869,2.5145-2.481m-7.5313,0c1.378-.0146,2.5069,1.0906,2.5215,2.4686.0146,1.378-1.0906,2.5069-2.4686,2.5215-1.378.0146-2.5069-1.0906-2.5215-2.4686,0-.0094-.0001-.0187-.0001-.0281.0047-1.3655,1.1033-2.4751,2.4687-2.4934m-7.4802,4.9868c-1.377-.0137-2.4822-1.141-2.4685-2.518.0137-1.377,1.141-2.4822,2.518-2.4685,1.3728.0136,2.4763,1.1344,2.4686,2.5072-.0126,1.3795-1.1386,2.4889-2.5181,2.481" />
        <path id="uuid-ce3fb469-cda0-4cee-9b2c-c41f697e49e0" data-name="Path 5992" d="m44.3961,4.8936H14.8739c-2.6901.0091-4.9942,1.9283-5.4893,4.5724h23.1476c3.0877.0078,5.5877,2.511,5.5916,5.5987v5.8191h0v10.2522c1.078.8958,2.1566,1.7916,3.2358,2.6874.3712.3721.9374.4684,1.4107.2398.4866-.2249.7785-.7323.7283-1.2661v-1.7281h.9311c3.0728-.0203,5.557-2.5099,5.5704-5.5828v-14.9885c0-3.095-2.509-5.6039-5.6039-5.6039" />
      </g>
    </svg>
  );
};

export default ConversationSmsIcon;
