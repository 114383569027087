import React, { useState } from 'react';
import CalendarIcon from '../../icons/CalendarDate.icon';
import FilterHeader from '../FilterHeader/FilterHeader.component';
import HeaderButton from '../HeaderButton/HeaderButton.component';
import { useStyles } from './filterDate.styles';
import MonthPicker from '../month-picker.component';
import { LocalDate } from '@js-joda/core';
import { format } from '../../services/joda.js';

import {
  FormControl,
  Popover,
  Grid,
  TextField,
} from '@mui/material';

type FilterDateProps = {
  setDate: (value: string) => void;
  date: string,
  title?: string;
  onlyIcon?: boolean;
  Icon?: ({ color, className }: { color?: string, className?: string }) => JSX.Element;
  monthOnly?: boolean;
}

const newd = format(LocalDate.now(), 'yyyy-MM-dd');

const FilterDate = ({
  date, setDate, title, onlyIcon, Icon, monthOnly = false,
}: FilterDateProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [localDate, setLocalDate] = useState(date);

  const handleClose = (e?: boolean) => {
    setAnchorEl(null);
    if (monthOnly && Boolean(e))
      setLocalDate(date);
  };

  const handleSelect = (value: string) => {
    setDate(value);
    handleClose();
  };

  const handleReset = () => {
    if (monthOnly) {
      setDate(newd);
      setLocalDate(newd);
    } else
      setDate('');
    handleClose();
  };


  return (
    <Grid>
      <Grid marginRight={0.5}>
        <HeaderButton
          title={title || 'Date'}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          Icon={Icon || CalendarIcon}
          active={!!date?.length}
          type='filter'
          onlyIcon={onlyIcon}
        />
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid>
          <FilterHeader
            title={title || 'Date'}
            reset={handleReset}
            resetDisabled={'' === date || (monthOnly && date === newd)}
            saveTitle={monthOnly ? 'Save' : undefined}
            save={monthOnly ? () => handleSelect(localDate) : undefined}
            saveDisabled={date === localDate}
          />
          <Grid padding={2}>
            {!monthOnly &&
              <FormControl variant="outlined" className={classes.formControl}>
                <TextField
                  className={classes.date}
                  size='small'
                  value={date}
                  onChange={(event) => {
                    const v = event.target.value;
                    try {
                      LocalDate.parse(v);
                      setDate(v);
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  type='date'
                  variant='outlined'
                />
              </FormControl>}
            {monthOnly &&
              <MonthPicker
                value={localDate}
                onChange={(value: string) => {
                  if (value) {
                    setLocalDate(value);
                  }
                }}
              />}
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
};

export default FilterDate;
