import React, { useState } from 'react';
import * as R from 'ramda';

import Icon from '@mui/icons-material/FormatLineSpacing';
import FilterHeader from '../../../../components/FilterHeader/FilterHeader.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import { useStyles } from './filterSpacing.styles';

import {
  Popover,
  Grid,
  Typography,
  FormControl,
  TextField,
  Select,
  MenuItem,
  InputAdornment
} from '@mui/material';

type SettingsType = {
  localInterval: number;
  cellHeight: number;
  cellWidth: number;
}

const defaultSettings = {
  cellHeight: 35,
  cellWidth: 100,
  localInterval: 15,
};

type FilterSpacingProps = {
  save: (value: SettingsType) => void;
  value: SettingsType,
  onlyIcon?: boolean,
}

const FilterSpacing = ({ value, save, onlyIcon }: FilterSpacingProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleReset = () => {
    save(defaultSettings);
    handleClose();
  };

  const handleChange = (key: string, data: string | number) => {
    save({ ...value, [key]: Number(data) });
  };

  return (
    <Grid>
      <Grid marginRight={0.5}>
        <HeaderButton
          title='Spacing'
          Icon={Icon}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          onlyIcon={onlyIcon}
          type='hide'
          className='sked-test-calendar-spacing'
          active={!R.equals(value, defaultSettings)}
        />
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid minWidth={200}>
          <FilterHeader
            title='Calendar Spacing'
            reset={handleReset}
            resetDisabled={R.equals(value, defaultSettings)}
            resetClassName='sked-test-calendar-spacing-reset-button'
            saveClassName='sked-test-calendar-spacing-save-button'
          />
          <Grid padding={2}>
            <FormControl variant="outlined" fullWidth>
              <Grid display="flex" alignItems="center" marginBottom={1}>
                <Grid>
                  <Typography className={classes.inputLabel}>Cell Width</Typography>
                  <TextField
                    value={value.cellWidth}
                    className={classes.input}
                    size='small'
                    variant='outlined'
                    type='number'
                    inputProps={{ className: 'sked-test-calendar-spacing-cell-width' }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">px</InputAdornment>,
                      inputProps: {
                        min: 0
                      }
                    }}
                    onChange={(event) => handleChange('cellWidth', event.target.value) }
                  />
                </Grid>
                <Grid>
                  <Typography className={classes.inputLabel}>Cell Height</Typography>
                  <TextField
                    value={value.cellHeight}
                    className={classes.input}
                    size='small'
                    variant='outlined'
                    type='number'
                    inputProps={{ className: 'sked-test-calendar-spacing-cell-height' }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">px</InputAdornment>,
                      inputProps: {
                        min: 0
                      }
                    }}
                    onChange={(event) => handleChange('cellHeight', event.target.value) }
                  />
                </Grid>
              </Grid>
              <Grid>
                <FormControl variant="outlined" fullWidth>
                  <Typography className={classes.inputLabel}>Interval</Typography>
                  <Select
                    size='small'
                    value={value.localInterval || ''}
                    className={classes.selected}
                    onChange={(event) => handleChange('localInterval', event.target.value)}
                    inputProps={{ className: 'sked-test-calendar-spacing-interval' }}
                  >
                    <MenuItem className='sked-test-calendar-spacing-interval-5-minutes' value={5}>5 minutes</MenuItem>
                    <MenuItem className='sked-test-calendar-spacing-interval-10-minutes' value={10}>10 minutes</MenuItem>
                    <MenuItem className='sked-test-calendar-spacing-interval-15-minutes' value={15}>15 minutes</MenuItem>
                    <MenuItem className='sked-test-calendar-spacing-interval-20-minutes' value={20}>20 minutes</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
};

export default FilterSpacing;
