import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 2,
    borderBottom: '1px solid #dadada',

    '&:hover': {
      background: 'rgba(0, 0, 0, 0.05)',
    }
  },
  selected: {
    background: 'rgba(0, 139, 207, 0.1)',

    '&:hover': {
      background: 'rgba(0, 139, 207, 0.15)',
    }
  },
  avatar: {
    width: 32,
    height: 32,
    color: '#FFF',
    fontWeight: 500,
    fontSize: 12,
  },
  textContainer: {
    paddingLeft: 8,
    paddingRight: 8,
    flex: 1,
    cursor: 'pointer',
  },
  title: {
    fontSize: 14,
    color: 'rgba(0,0,0,.87)',
  },
  new: {
    marginLeft: 7,
    marginBottom: 2,
    width: 10,
    height: 10,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%'
  },
  message: {
    fontSize: 12,
    color: 'rgba(0,0,0,.5)',
    overflow: 'hidden',
    height: '18px',
    textAlign: 'start',
    textOverflow: 'ellipsis',
  },
  date: {
    fontSize: 11,
    color: '#7B8793',
    cursor: 'pointer',
    fontWeight: 500,
  },
  checkbox: {
    marginLeft: 8,
    marginRight: 4,

    '& svg': {
      width: 17,
      height: 17,
    }
  },
  replyIcon: {
    transform: 'scale(-1, 1) translateY(3px)',
    width: 17,
    height: 17,
  }
}));
