import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { LocalDate, ZoneId } from '@js-joda/core';
import {
  CircularProgress, IconButton, TableHead,
  TableRow, TableBody, Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  has, set, lensPath, pipe, over, prop, propEq, assoc,
  cond, always, T, F, equals, includes, pathOr, prepend, isEmpty,
} from 'ramda';
import qs from 'query-string';

import { Print as PrintIcon } from '@mui/icons-material';

import { getProfessionals } from '../Professionals/professionals.actions.jsx';
import * as clientActions from '../Clients/clients.actions';
import { Row } from '../../components/PageHeader';
import { useTitle } from '../../services/useTitle';
import { usePromise } from '../../services/promise.hook';
import { tzParseFormat, formatStandard } from '../../services/joda.js';
import api from '../../services/api.js';

import { getLocations } from '../Settings/routes/Business/business.actions.js';
import ClientEditDialog from '../Clients/components/client-dialog/client-dialog.component';
import Header from '../../components/PageHeader/PageHeader.component';
import FilterStartEndDate from '../../components/FilterStartEndDate/FilterStartEndDate.component';
import FilterApptStatus from '../../components/FilterStatus/FilterStatus.component';
import FilterSelect from '../../components/FilterSelect/FilterSelect.component';
import FilterClients from '../../components/FilterClients/FilterClients.component';
import FilterApptTypes from '../../components/FilterApptTypes/FilterApptTypes.component';
import FilterProfessional from '../../components/FilterProfessional/FilterProfessional.component';
import FilterLocations from '../../components/FilterLocations/FilterLocations.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import { TableContainer, BodyCell, HeaderCell } from '../../components/CustomTable';

const searchClients = clientIds => {
  return api.post('client/query', {
    query: {
      clientIds,
      sortBy: [{
        direction: 'Asc',
        field: 'LastName'
      }, {
        direction: 'Asc',
        field: 'FirstName'
      }],
      isLead: false
    },
    page: 0,
    perPage: 25
  });
};

const useStyles = makeStyles(() => ({
  table: {
    '& > div': {
      '@media print': {
        overflow: 'visible !important',
        width: '100% !important',

        '& tr td div': {
          whiteSpace: 'normal',

          '& a': {
            whiteSpace: 'normal'
          },
        },
      },
    },
  },
  icon: {
    width: 17,
    height: 17,
  },
}));

const getAppointments = q => api.post('appointment/query', q);

const ehrsWithoutMissed = ['Platinum', 'Genesis'];

const confirmedOptions = [
  'Any',
  'Is Confirmed',
  'Not Confirmed',
];

const statuses = [
  'Scheduled',
  'Canceled',
  'Arrived',
  'Missed',
  'Rescheduled',
  'Deleted'
];

const showStatus = (status) => {
  return statuses.find(s => has(s, status));
};

const emptyArray = [];
const emptyObject = {};

// TODO
// Same as showPro in professionals components
// Should abstract this somewhere
const showPro = (professionalId = 0, professionals = emptyArray) => {
  const pro = professionals.find(propEq('id', professionalId)) || emptyObject;
  return pro.firstName + ' ' + pro.lastName;
};

const Appointments = ({ history, location }) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const {
    tz, proState, officeId, ehrSystem, locations, hasLocations, headerHeight, clientsState
  } = useSelector((state) => {
    const locations = pathOr([], ['business', 'locations'], state);
    const hasLocations = pipe(
      pathOr([], ['login', 'features']),
      includes('Locations')
    )(state);
    return ({
      tz: state.login.office.timezone,
      officeId: state.login.office.id,
      ehrSystem: state.login.office.ehrSystem,
      proState: state.professionals,
      locations: hasLocations && !isEmpty(locations) ?
        prepend(
          { id: 0, name: 'All Locations' },
          locations,
        ) : [],
      hasLocations,
      headerHeight: state.login.headerHeight,
      clientsState: state.clients.state,
    });
  });

  const filterByEhr = (ehrSystem) => (s) => {
    return cond([
      [equals('Rescheduled'), () => ehrSystem === 'ChiroTouch' || ehrSystem === 'ChiroHD'],
      [equals('Missed'), () => !includes(ehrSystem, ehrsWithoutMissed)],
      [T, T]
    ])(s);
  };

  const ehrStatuses = React.useMemo(() => {
    return statuses.filter(filterByEhr(ehrSystem));
  }, [ehrSystem]);

  const defaultStatuses = ehrStatuses.filter(item => item !== 'Deleted');

  React.useEffect(() => {
    dispatch(getProfessionals());
    dispatch(getLocations());
  }, [officeId]);

  const [dateRange, setDateRange] = React.useState({
    start: LocalDate.now().toString(),
    end: LocalDate.now().toString()
  });
  const [isConfirmed, setIsConfirmed] = React.useState(confirmedOptions[0]);


  const [query, setQuery] = React.useState({
    query: {
      order: 'Descending',
      status: defaultStatuses,
    },
    perPage: 25,
    page: 1
  });

  const apptsState = usePromise(getAppointments, {
    data: [],
    page: 1,
    totalCount: 0,
    totalPages: 0,
  });

  const [clients, setClients] = React.useState([]);
  const [perPage, setPerPage] = React.useState(25);
  const [isShowingHiddenProfessional, setIsShowingHiddenProfessional] = React.useState(false);

  const [apptTypes, setApptTypes] = React.useState([]);

  const [pros, setPros] = React.useState([]);

  const [locationId, setLocationId] = React.useState('');

  const filteredProfessionals = proState
    .professionals
    .filter(p => isShowingHiddenProfessional || !p.isHidden);

  const getReport = (params = {}) => {
    const page = params.page || 1;
    const selectedClients = params.clients || clients;
    const selectedTypes = params.types || apptTypes;
    const selectedProps = params.professionals || pros;
    const newQuery = pipe(
      set(lensPath(['page']), Number(page)),
      set(lensPath(['perPage']), params.perPage || Number(perPage)),
      set(lensPath(['query', 'after']), params.dateRange?.start || dateRange.start),
      set(lensPath(['query', 'before']), params.dateRange?.end || dateRange.end),
      over(lensPath(['query', 'status']), s => (s || emptyArray).length === 0 ? undefined : s),
      set(lensPath(['query', 'clientIds']), selectedClients.length > 0 ? selectedClients.map(prop('id')) : undefined),
      set(lensPath(['query', 'appointmentTypeIds']), selectedTypes.length > 0 ? selectedTypes.map(prop('id')) : undefined),
      set(lensPath(['query', 'professionalIds']), selectedProps.length > 0 ? selectedProps.map(prop('id')) : undefined),
      set(lensPath(['query', 'isConfirmed']), params.isConfirmed || isConfirmed),
      set(lensPath(['query', 'locationId']), params.locationId !== undefined ? params.locationId : locationId),
    )(query);

    if (params.status) {
      newQuery.query.status = params.status;
    }

    const search = qs.stringify({ ...newQuery.query, perPage, page });

    history.push({ search });
  };

  const handleReset = () => {
    history.push({ search: '' });
    setClients([]);
    setPros([]);
    setApptTypes([]);
    setIsConfirmed(confirmedOptions[0]);
    setDateRange({
      start: LocalDate.now().toString(),
      end: LocalDate.now().toString()
    });
    setLocationId('');
    setQuery({
      query: {
        order: 'Descending',
        status: defaultStatuses
      },
      perPage: 25,
      page: 1
    });
    const inputs = document.getElementsByClassName('MuiAutocomplete-clearIndicator');
    if (inputs.length) {
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].click();
      }
      const inputPro = document.getElementById('pro-search');
      inputPro?.blur();
    }
  };

  const setStatuses = (statuses) => {
    setQuery({
      ...query,
      query: {
        order: query.query.order,
        status: statuses
      },
    });
  };

  const parseArray = (value, cb) => {
    if (!value) {
      return undefined;
    }
    if (Array.isArray(value)) {
      return value.map(n => cb(n));
    }
    return [cb(value)];
  };

  React.useEffect(() => {
    const s = qs.parse(location.search);
    if (!location.search) {
      s.status = defaultStatuses.filter(filterByEhr(ehrSystem));
    }
    const start = s.after || LocalDate.now().toString();
    const end = s.before || LocalDate.now().toString();
    const after = formatStandard(LocalDate.parse(start).atStartOfDay(ZoneId.of(tz)), false);
    const before = formatStandard(LocalDate.parse(end).atStartOfDay(ZoneId.of(tz)).plusDays(1), false);
    const query = {
      after,
      before,
      clientIds: parseArray(s.clientIds, Number),
      status: parseArray(s.status, String),
      appointmentTypeIds: parseArray(s.appointmentTypeIds, Number),
      professionalIds: parseArray(s.professionalIds, Number),
      isConfirmed: cond([
        [equals(confirmedOptions[0]), always(undefined)],
        [equals(confirmedOptions[1]), T],
        [equals(confirmedOptions[2]), F],
      ])(isConfirmed),
      locationId: (locationId === 0 || locationId === '') ? undefined : locationId,
    };
    const perPage = Number(s.perPage || 25);
    const page = Number(s.page || 1);
    const body = {
      query,
      perPage,
      page
    };
    setQuery(pipe(
      assoc('query', query),
      assoc('perPage', perPage),
      assoc('page', page)
    ));
    setDateRange({
      start,
      end
    });
    setApptTypes((query.appointmentTypeIds || emptyArray).map(id => {
      const defaultType = { id, internalName: 'loading' };
      const type = proState.types.find(propEq('id', id)) || defaultType;
      return type;
    }));

    setLocationId(s.locationId ? Number(s.locationId) : '');

    const newClients = (query.clientIds || emptyArray).map(id => {
      const defaultClient = { id, firstName: 'loading', lastName: '', defaultClient: true };
      const client = clients.find(propEq('id', id)) || defaultClient;
      return client;
    });
    setClients(newClients);

    const needToGetClients = newClients.every(c => c.defaultClient);
    if (needToGetClients && newClients.length > 0) {
      //TODO: Use api to get clients
      console.log('need to get new clients!');
      searchClients(query.clientIds).then(({ data }) => setClients(data));
    }

    apptsState.invoke(body);
  }, [location.search, ehrSystem]);

  React.useEffect(() => {
    if (proState.types.length > 0) {
      setApptTypes(t => t.map(({ id }) => {
        const defaultType = { id, internalName: 'loading' };
        const type = proState.types.find(propEq('id', id)) || defaultType;
        return type;
      }));
    }
  }, [proState.types]);

  useTitle('Appointment Reports');

  const addPage = (dir) => {
    const page = query.page + dir;
    getReport({ page });
  };

  const changePerPage = (perPage) => {
    setPerPage(perPage);
    getReport({ perPage });
  };

  const handlePrint = () => {
    const total = apptsState.data.totalCount;
    const fetched = apptsState.data.data.length;
    if (total > fetched) {
      const newQuery = {
        ...query,
        perPage: total,
        page: 1
      };
      setPerPage(total);
      return apptsState.invoke(newQuery).then(() => {
        setTimeout(() => {
          window.print();
        }, 100);
      });
    } else {
      window.print();
    }
  };

  React.useEffect(() => {
    function afterPrint() {
      const fetched = apptsState.data.data.length;
      if (perPage === fetched) {
        setTimeout(() => {
          setPerPage(query.perPage);
          apptsState.invoke(query);
        }, 100);
      }
    }
    window.addEventListener('afterprint', afterPrint);
    return () => {
      window.removeEventListener('afterprint', afterPrint);
    };
  }, [query, apptsState, perPage]);

  return (
    <>
      <Header
        title='Appointment Reports'
        helpMaxWidth={835}
        pageId='appointment-reports'
        leftIcons={[
          <FilterClients
            selectedClients={clients}
            setSelectedClients={setClients}
            save={(getReport)}
          />,
          <FilterStartEndDate dateRange={dateRange} setDateRange={setDateRange} save={getReport} />,
          <FilterSelect
            selected={isConfirmed}
            setSelected={setIsConfirmed}
            save={getReport}
            items={confirmedOptions}
          />,
          <FilterApptTypes
            allTypes={proState.types}
            setApptTypes={setApptTypes}
            professionals={filteredProfessionals}
            selectedTypes={apptTypes}
            save={getReport}
          />,
          <FilterProfessional
            allProfessionals={filteredProfessionals}
            selectedPros={pros}
            setProps={setPros}
            isShowingHiddenProfessional={isShowingHiddenProfessional}
            setIsShowingHiddenProfessional={setIsShowingHiddenProfessional}
            save={getReport}
          />,
          <FilterApptStatus
            statuses={ehrStatuses}
            selectedStatuses={query.query.status || emptyArray}
            setStatuses={setStatuses}
            defaultStatuses={defaultStatuses}
            save={getReport}
          />,
          <FilterLocations
            allLocations={locations}
            selectedLocationId={locationId}
            setSelectedLocationId={setLocationId}
            hasLocations={hasLocations}
            save={getReport}
          />
        ]}
        rightIcons={[
          <Grid>{apptsState.loading && <CircularProgress size={18} />}</Grid>,
          <IconButton aria-label="settings" onClick={handlePrint}>
            <PrintIcon className={classes.icon} />
          </IconButton>,
          <HeaderButton
            onClick={handleReset}
            title='Reset Filters'
            borderSolid
          />,
        ]}
        onlyIconsWidth={1310}
        breakPoints={[
          {
            width: 770,
            mobileItems: [6, 9]
          },
          {
            width: 650,
            mobileItems: [3, 4, 5, 6, 9]
          },
          {
            width: 515,
            mobileItems: [0, 1, 2, 3, 4, 5, 6, 9]
          },
        ]}
      />
      <div>
        <div>
          <Row style={{ display: 'block' }}>
            <TableContainer
              fullPage
              className={classes.table}
              maxHeight={`calc(100vh - ${headerHeight}px - 45px)`}
              pagination
              paginationData={{
                addPage,
                currentLength: apptsState.data.data?.length,
                page: apptsState.data?.page,
                totalCount: apptsState.data?.totalCount,
                totalPages: apptsState.data?.totalPages,
                perPage,
                setPerPage: changePerPage,
              }}
            >
              <TableHead>
                <TableRow>
                  <HeaderCell fixed>Name</HeaderCell>
                  <HeaderCell>Phone</HeaderCell>
                  <HeaderCell>Date & Time</HeaderCell>
                  <HeaderCell>Appt. Type</HeaderCell>
                  <HeaderCell>Professional</HeaderCell>
                  <HeaderCell>Status</HeaderCell>
                  <HeaderCell>Reason</HeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {apptsState.data.data.map((appt) =>
                  <TableRow key={appt.id}>
                    <BodyCell fixed nowrap>
                      <a onClick={() => dispatch(clientActions.selectClient(appt.client))}>
                        {appt.client.firstName} {appt.client.lastName}
                      </a>
                    </BodyCell>
                    <BodyCell>
                      {appt.client.phone}
                    </BodyCell>
                    <BodyCell nowrap>
                      {tzParseFormat(appt.time, tz, 'EE, MM/dd/yyyy h:mm a')}
                    </BodyCell>
                    <BodyCell>
                      {appt.appointmentType.internalName}
                    </BodyCell>
                    <BodyCell>
                      {showPro(appt.appointmentType.professionalId, proState.professionals)}
                    </BodyCell>
                    <BodyCell>
                      {showStatus(appt.status)}
                    </BodyCell>
                    <BodyCell>
                      {appt.status.Canceled}
                    </BodyCell>
                  </TableRow>
                )}
              </TableBody>
            </TableContainer>
          </Row>
        </div>
        <ClientEditDialog
          open={clientsState === 'CLIENT_SELECT'}
          from={'CLIENTS'}
          history={history}
          onClose={() => dispatch(clientActions.backToList())}
          back={() => dispatch(clientActions.back())}
          gotoClient={() => dispatch(clientActions.back())}
        />
      </div>
    </>
  );
};

export default Appointments;
