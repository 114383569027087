import React, { ComponentProps } from 'react';

const LeadsIcon = ({ color, className, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg className={className} {...props} xmlns="http://www.w3.org/2000/svg" width="45.843" height="45.857" viewBox="0 0 45.843 45.857">
      <g id="Group_15277" data-name="Group 15277" transform="translate(8920 -7367)">
        <path id="Path_8737" data-name="Path 8737" d="M11.754,9.44l7.573,7.573c-.077.085-.183.2-.3.312-2.077,2.077-4.163,4.15-6.232,6.236a6.715,6.715,0,0,0,9.431,9.561c2.078-2.073,4.171-4.175,6.261-6.269a2.684,2.684,0,0,0,.271-.381L36.4,34.106v.032c-2.6,2.568-5.127,5.22-7.824,7.695a15.521,15.521,0,0,1-8.1,3.737,17.007,17.007,0,0,1-13.1-2.96A16.792,16.792,0,0,1,.278,31.332,17.1,17.1,0,0,1,3.8,17.519a15.533,15.533,0,0,1,1.279-1.434q3.216-3.238,6.455-6.479c.073-.073.154-.138.211-.19" transform="translate(-8920 7367)" fill={color || '#FFF' }/>
        <path id="Path_8738" data-name="Path 8738" d="M40.656,6.986c.984.98,1.9,1.875,2.794,2.786a1.352,1.352,0,0,1-.474,2.385l-.125.049-8.277,3.551L30.022,17.7A1.36,1.36,0,0,1,28.5,15.454l4.6-4.584c-.118-.126-.215-.231-.312-.333l-2.43-2.429a1.349,1.349,0,0,1,.429-2.325q6.52-2.831,13.04-5.612a1.356,1.356,0,0,1,1.5,2.251q-2.114,2.126-4.24,4.24a3.95,3.95,0,0,1-.429.32" transform="translate(-8920 7367)" fill={color || '#FFF' }/>
        <path id="Path_8739" data-name="Path 8739" d="M21.307,15.013,13.718,7.44l2.43-2.429c.729-.729,1.454-1.458,2.183-2.183a1.319,1.319,0,0,1,1.857-.191,1.345,1.345,0,0,1,.167.162q2.816,2.787,5.6,5.6a1.3,1.3,0,0,1,.154,1.826,1.36,1.36,0,0,1-.118.122c-1.53,1.551-3.073,3.082-4.616,4.62a.211.211,0,0,1-.069.045" transform="translate(-8920 7367)" fill={color || '#FFF' }/>
        <path id="Path_8740" data-name="Path 8740" d="M38.4,32.146l-7.585-7.581c.065-.073.146-.174.239-.267Q33.2,22.144,35.359,20a1.375,1.375,0,0,1,2.211,0q2.693,2.685,5.378,5.378a1.377,1.377,0,0,1,0,2.211L38.4,32.142" transform="translate(-8920 7367)" fill={color || '#FFF' }/>
      </g>
    </svg>
  );
};

export default LeadsIcon;
