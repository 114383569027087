import React, { useState, useEffect } from 'react';
import {
  TextField,
  TableRow,
  TableBody,
} from '@mui/material';
import * as R from 'ramda';
import api from '../../../services/api.js';
import { PopupTemplate, popup } from '../../../services/Popup.js';
import {
  now,
  format
} from '../../../services/joda.js';
import { TableContainer, BodyCell } from '../../../components/CustomTable';
import Modal from '../../../components/Modal/Modal.component';
import HeaderButton from '../../../components/HeaderButton/HeaderButton.component';
import { Client } from '../clients.types';
import { Professional, AppointmentType, ProType } from '../../Appointments/appointments.types';

const defaultClient = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  defaultApptiontmentType: undefined, // typo thanks to Jonny :)
  allowSMS: true,
  allowEmail: true,
} as Client;

const clientStatuses = [
  'Active',
  'Inactive',
  'Inert',
];

type Props = {
  ehr: string;
  admin: boolean;
  open: boolean;
  onClose: () => void;
  save: () => void;
  client: Client;
}

type State = {
  status: string;
  proTypes: ProType[];
  disabled: boolean;
}

const EditClient = (props: Props) => {
  const [state, setState] = useState<State>({
    status: 'INIT',
    proTypes: [],
    disabled: false,
  });
  const [client, setClient] = useState(defaultClient);

  const getProsAndTypes = () => {
    return Promise.all<[Promise<Professional[]>, Promise<AppointmentType[]>]>(
      [api.get('professional'), api.get('appointmentType')]
    ).then(([pros, types]) => {
      const proTypes = R.pipe(
        R.filter(({ isHidden }) => !isHidden),
        (params: Professional[]) => R.sortBy(R.prop('lastName'))(params),
        R.map((pro) => {
          const ts = R.pipe(
            R.filter(({ professionalId }) => pro.id === professionalId),
            (params: AppointmentType[]) => R.sortBy(R.prop('internalName'))(params)
          )(types);
          const newPro = R.merge(pro, {
            id: undefined, // to prevent mis-selection when a type has the same id.
            professionalId: pro.id
          });
          return [newPro, ts];
        }),
        R.flatten
      )(pros) as ProType[];
      setState({ ...state, proTypes, status: 'EDIT' });
    }).catch((error) => {
      console.log(error);
    });
  };

  useEffect(() => {
    if (state.status === 'INIT' && props.open) {
      getProsAndTypes();
      setState({ ...state, status: 'EDIT' });
      setClient(props.client);
    }
  }, [state.status, props.open]);

  const updateClient = (prop: keyof Client, data: string | number | boolean) => {
    setClient({ ...client, [prop]: data });
  };

  const table = ({ client, proTypes }: { client: Client, proTypes: ProType[] }) => {
    const {
      firstName,
      lastName,
      birthday,
      email,
      phone,
      defaultApptiontmentType,
      allowSMS,
      allowEmail,
      status,
    } = client;
    return (
      <TableContainer noHover style={{ overflowY: 'auto' }}>
        <TableBody>
          {(props.ehr === 'None' || props.admin) &&
            <TableRow key={0}>
              <BodyCell><b>First Name:</b></BodyCell>
              <BodyCell>
                <TextField
                  value={firstName}
                  style={{ width: '100%' }}
                  onChange={(e) => updateClient('firstName', e.target.value)}
                  label="First Name"
                />
              </BodyCell>
            </TableRow>}
          {(props.ehr === 'None' || props.admin) &&
            <TableRow key={1}>
              <BodyCell><b>Last Name:</b></BodyCell>
              <BodyCell>
                <TextField
                  value={lastName}
                  style={{ width: '100%' }}
                  onChange={(e) => updateClient('lastName', e.target.value)}
                  label="Last Name"
                />
              </BodyCell>
            </TableRow>}
          {(props.ehr === 'None' || props.admin) &&
            <TableRow key={2}>
              <BodyCell><b>Birthday:</b></BodyCell>
              <BodyCell>
                {birthday ?
                  <TextField
                    type="date"
                    label="Birthday"
                    value={birthday}
                    onChange={(d) => updateClient('birthday', d.target.value)}
                  />
                  :
                  <button
                    onClick={() => updateClient('birthday', format(now('date'), 'yyyy-MM-dd'))}>
                    Add Birthday
                  </button>}
              </BodyCell>
            </TableRow>}
          <TableRow key={3}>
            <BodyCell><b>Email:</b></BodyCell>
            <BodyCell>
              <TextField
                value={email}
                style={{ width: '100%' }}
                onChange={(e) => updateClient('email', e.target.value)}
                label="Email"
              />
            </BodyCell>
          </TableRow>
          <TableRow key={4}>
            <BodyCell><b>Phone:</b></BodyCell>
            <BodyCell>
              <TextField
                value={phone}
                style={{ width: '100%' }}
                onChange={(e) => updateClient('phone', e.target.value)}
                label="Phone"
              />
            </BodyCell>
          </TableRow>
          <TableRow>
            <BodyCell><b>Default Appointment Type:</b></BodyCell>
            {!R.isEmpty(proTypes) &&
              <BodyCell>
                <select
                  value={defaultApptiontmentType}
                  onChange={(e) => updateClient('defaultApptiontmentType', Number(e.target.value))}
                >
                  {R.map((proOrType: ProType) => {
                    if (proOrType.firstName) {
                      return (
                        <option
                          key={proOrType.professionalId}
                          value={proOrType.professionalId}
                          disabled={true}>
                          {proOrType.displayFirstName + ' ' + proOrType.displayLastName}
                        </option>
                      );
                    } else {
                      return (
                        <option key={proOrType.id} value={proOrType.id}>
                          {proOrType.internalName + ' (' + proOrType.name + ')'}
                        </option>
                      );
                    }
                  })(proTypes)}
                </select>
              </BodyCell>}
          </TableRow>
          <TableRow key={6}>
            <BodyCell><b>Allow SMS Notifications:</b></BodyCell>
            <BodyCell>
              <input
                checked={allowSMS}
                type='checkbox'
                onChange={(e) => updateClient('allowSMS', e.target.checked)}
              />
            </BodyCell>
          </TableRow>
          <TableRow key={7}>
            <BodyCell><b>Allow Email Notifications:</b></BodyCell>
            <BodyCell>
              <input
                checked={allowEmail}
                type='checkbox'
                onChange={(e) => updateClient('allowEmail', e.target.checked)}
              />
            </BodyCell>
          </TableRow>
          <TableRow key={8}>
            <BodyCell><b>Status:</b></BodyCell>
            <BodyCell>
              <select
                value={status}
                onChange={(e) => {
                  updateClient('status', e.target.value);
                }}>
                {R.map((s: string) => {
                  return (
                    <option key={s} value={s} label={s}>
                      {s}
                    </option>
                  );
                })(clientStatuses)}
              </select>
            </BodyCell>
          </TableRow>
        </TableBody>
      </TableContainer>
    );
  };

  const disabled = client.firstName === '' || client.lastName === '';

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.onClose();
        setState({ ...state, status: 'INIT' });
        setClient(defaultClient);
      }}
      title='Edit Client'
      className='sked-test-clients-edit-modal'
      buttons={[
        <HeaderButton
          title='Save'
          color='primary'
          disabled={state.disabled || disabled}
          className='sked-test-clients-edit-modal-button-save'
          onClick={() => {
            setState({ ...state, disabled: true });
            const data = R.merge(
              client,
              {
                defaultAppointmentType: client.defaultApptiontmentType,
                birthday: client.birthday ? { Set: client.birthday } : { Unset: [] },
                /* We need backend support for this. */
                email: R.isEmpty(client.email) ? null : client.email,
                phone: R.or(R.isEmpty(client.phone), R.isNil(client.phone)) ? null : client.phone.replace(/[{()}]|-|\s/g, ''),
              });
            api.put(`client/${client.id}`, data)
              .then(() => {
                props.save(); // update page
                props.onClose();
                setState({ ...state, status: 'INIT', disabled: false });
                setClient(defaultClient);
              }).catch((error) => {
                popup('Error!', 'Failed to update client. Check all fields and try again.');
                console.log(error);
              });
          }}
        />
      ]}
    >
      <div>
        <PopupTemplate />
        {table({ client, proTypes: state.proTypes })}
      </div>
    </Modal>
  );
};

export default EditClient;
