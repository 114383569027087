export const LEADS_REMOTE_GET = 'LEADS_REMOTE_GET';
export const LEADS_REMOTE_SAVE = 'LEADS_REMOTE_SAVE';
export const LEADS_REMOTE_REMOVE = 'LEADS_REMOTE_REMOVE';
export const LEADS_REMOTE_UPDATE = 'LEADS_REMOTE_UPDATE';
export const LEADS_REMOTE_OPTOUT_LIST = 'LEADS_REMOTE_OPTOUT_LIST';
export const LEADS_REMOTE_OPTOUT = 'LEADS_REMOTE_OPTOUT';
export const LEADS_REMOTE_MESSAGES = 'LEADS_REMOTE_MESSAGES';
export const LEADS_PATCH = 'LEADS_PATCH';
export const LEADS_CATEGORIES_UPDATE = 'LEADS_CATEGORIES_UPDATE';
export const LEADS_LOADING = 'LEADS_LOADING';
export const LEADS_REMOTE_APTS_GET = 'LEADS_REMOTE_APTS_GET';
export const LEADS_REMOTE_APT_GET = 'LEADS_REMOTE_APT_GET';
export const LEADS_REFERRALS_SET = 'LEADS_REFERRALS_SET';
export const LEADS_BLOCK_NUMBER_SET = 'LEADS_BLOCK_NUMBER_SET';