import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  CircularProgress, TableHead, TableRow, TableBody, Button,
  Typography, Tooltip, IconButton,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import {
  fetchSettingsList, createSettings, fetchLink, deleteSettings, fetchSparkPortals,
  fetchClients,
} from './NewPatientPortal.actions.js';
import AddIcon from '@mui/icons-material/Add';
import SchedulerIcon from '../../../../icons/Scheduler.icon';
import OpenInNewTabIcon from '../../../../icons/OpenInNewTab.icon';
import DeleteIcon from '../../../../icons/Delete.icon';
import RoundExclamationIcon from '../../../../icons/RoundExclamation.icon';
import SparkIcon from '../../../../icons/Spark.icon';
import Modal from '../../../../components/Modal/Modal.component';
import { includes, pathOr } from 'ramda';
import { usePromise } from '../../../../services/promise.hook';
import { useTitle } from '../../../../services/useTitle';
import Header from '../../../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import FilterSelect from '../../../../components/FilterSelect/FilterSelect.component';
import AddButton from './components/AddButton.component';
import WarningRectangle from '../../../../components/WarningRectangle/WarningRectangle.component';
import { TableContainer, BodyCell, HeaderCell } from '../../../../components/CustomTable';

const urlBase = process.env.PORTAL_URL + '/';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  maybeWarning: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  warningTag: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: 2,
    alignItems: 'center',
    color: '#868686',
    marginLeft: 10,
    cursor: 'default',
  },
  warningTooltip: {
    backgroundColor: 'white',
    padding: 10,
  },
  warning: {
    backgroundColor: 'white',
    padding: 'unset',
  },
  cutoff: {
    maxWidth: 264,
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingRight: 2,
  },
}));

const WhiteTooltip = withStyles({
  tooltip: {
    color: 'rgba(0, 0, 0, 0.54)',
    backgroundColor: 'white',
    maxWidth: 430,
    boxShadow: '1px 1px 25px',
    borderRadius: 15
  },
  arrow: {
    color: 'white',
    '& :before': {
      backgroundColor: 'white',
    }
  },
})(Tooltip);

const WarningTag = ({
  classes, newPatientPortalSettingsId, nppType
}) => {
  const linkState = usePromise(fetchLink, null);
  useEffect(() => {
    linkState.invoke(newPatientPortalSettingsId);
  }, [newPatientPortalSettingsId]);
  const [warningTitle, warningBody] = React.useMemo(() => {
    const word = nppType === 'New' ? 'New' : 'Existing';
    const opposite = nppType === 'New' ? 'Existing' : 'New';
    const grammer = `a${opposite === 'New' ? '' : 'n'}`;
    return [
      `${opposite} Patients won't be able to schedule`,
      `If ${opposite.toLowerCase()} patients come to your ${word} Patient Portal, they won't be able to schedule. Fix this by linking ${grammer} ${opposite} Patient Portal here.`
    ];
  }, [nppType]);
  if (linkState.data || linkState.loading) {
    return null;
  }
  return (
    <WhiteTooltip
      arrow
      placement='top'
      title={
        <React.Fragment>
          <WarningRectangle
            open={true}
            title={warningTitle}
            body={warningBody}
            className={classes.warningTooltip}
            type='warning'
          />
        </React.Fragment>
      }>
      <Typography component="i" className={classes.warningTag}>
        (Unlinked)
      </Typography>
    </WhiteTooltip>
  );
};

const filterOptions = [{
  name: 'New Patient Portal',
  value: 'New',
}, {
  name: 'Existing Patient Portal',
  value: 'Existing',
}];

const PortalItem = ({
  config, openDeleteDialog, hasEpp, classes, isSpark,
}) => {
  const listState = usePromise(fetchClients, null);
  useEffect(() => {
    if (config) {
      listState.invoke({
        query: {
          newPatientPortalSettingsId:
            config.newPatientPortalSettingsId
        },
        page: 1,
        perPage: 1
      });
    }
  }, [config]);

  const appts = React.useMemo(() => {
    if (!listState.data) {
      return 'Loading...';
    }
    const total = listState.data.totalCount;
    return (
      <span style={{ color: total === 0 ? 'gray' : '#008BCF' }}>
        {total} appointment{total === 1 ? '' : 's'}
      </span>
    );
  }, [listState.data]);

  return (
    <TableRow key={config.newPatientPortalSettingsId}>
      <BodyCell style={{
        overflowX: 'clip', textOverflow: 'ellipsis', maxWidth: 300
      }}>
        <a href={`#/settings/new-patient-portal/${config.newPatientPortalSettingsId}`}>
          {config.name}
        </a>
      </BodyCell>
      {hasEpp &&
        <BodyCell>
          <div className={classes.maybeWarning}>
            {config.nppType}
            <WarningTag
              classes={classes}
              {...config}
            />
            {isSpark &&
              <Tooltip arrow title='Spark Enabled'>
                <div>
                  <SparkIcon />
                </div>
              </Tooltip>}
          </div>
        </BodyCell>}
      <BodyCell>
        <Button
          component="a"
          disabled={appts[0] === '0'}
          href={`#/settings/new-patient-portal-clients/${config.newPatientPortalSettingsId}`}>
          {appts}
        </Button>
      </BodyCell>
      <BodyCell>
        <Tooltip arrow title='View Portal'>
          <IconButton
            component="a" target="_blank" href={`${urlBase}new-patient?key=${config.key}`}>
            <OpenInNewTabIcon color='#474a54' />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title='Delete Portal'>
          <IconButton onClick={() => {
            openDeleteDialog(config);
          }}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </BodyCell>
    </TableRow>
  );
};

const NewPatientPortalSettingsList = ({ history }) => {
  const classes = useStyles();
  const listState = usePromise(fetchSettingsList, []);
  const sparkPortalsState = usePromise(fetchSparkPortals, []);
  const [query, setQuery] = useState(null);
  const [list, setList] = useState([]);
  const [deletePP, setDeletePP] = useState(null);

  const store = useSelector(state => ({
    isEnabled: includes('NPPortal2', pathOr([], ['login', 'features'], state)),
    hasEpp: includes('EPPortal', pathOr([], ['login', 'features'], state)),
    headerHeight: pathOr(54, ['login', 'headerHeight'], state),
  }));

  const creationState = usePromise(createSettings, null);
  const deletionState = usePromise(deleteSettings, null);

  const handleCreate = (nppType) => {
    creationState.invoke({
      name: 'Untitled Portal', nppType
    }).then((data) => {
      history.push('/settings/new-patient-portal/' + data.newPatientPortalSettingsId);
    });
  };

  useTitle('Patient Portals');

  useEffect(() => {
    sparkPortalsState.invoke({});
    listState.invoke(store.hasEpp ? null : 'Existing').then((l) => {
      setList(l);
    });
  }, []);

  const selected = React.useMemo(() => {
    if (query) {
      const opt = filterOptions.find((item) => item.value === query.value);
      return opt ? opt.name : '';
    }
    return '';
  }, [query]);

  useEffect(() => {
    if (query) {
      const l = listState.data.filter(({ nppType }) => nppType === query.value);
      setList(l);
    } else {
      setList(listState.data);
    }
  }, [query, listState.data]);

  if (!store.isEnabled) {
    return (
      <>
        <Header title='Patient Portals' />
        <div className={classes.root}>
          <div style={{ padding: '10px 20px' }}>
            <p>
              The patient portal has been completely rewritten to be fully customizable and easier to use!
            </p>
          </div>
        </div>
      </>
    );
  }

  const openDeleteDialog = (config) => {
    setDeletePP(config);
  };

  const handleModalDeleteClose = () => {
    setDeletePP(null);
  };

  const confirmDelete = () => {
    deletionState.invoke(deletePP.newPatientPortalSettingsId).then(() => {
      handleModalDeleteClose();
      listState.invoke();
      setDeletePP(null);
    });
  };

  return (
    <>
      <Header
        title='Patient Portals'
        pageId="new-patient-portal"
        leftIcons={[
          store.hasEpp &&
            <FilterSelect
              title='Portal Type'
              Icon={SchedulerIcon}
              items={filterOptions.map(item => item.name)}
              selected={selected}
              setSelected={(value) => {
                const item = filterOptions.find(item => item.name === value);
                setQuery(item);
              }}
            />
        ]}
        rightIcons={[
          listState.loading && <CircularProgress size={18} />,
          store.hasEpp ?
            <AddButton
              save={handleCreate}
            />
            :
            <HeaderButton
              title='Add'
              Icon={AddIcon}
              onClick={() => handleCreate('New')}
              onlyIcon={false}
              type='hide'
              className='sked-test-npp-add-button'
            />
        ]}
      />
      <div
        className={classes.root}
        style={{ paddingTop: 0 }}>

        {listState.errorMessage && <div>{listState.errorMessage}</div>}
        {listState.data.length === 0 && !listState.loading && !listState.error && <div>Get started setting up the patient portal!</div>}

        {listState.data.length > 0 &&
          <TableContainer
            fullPage
            maxHeight={`calc(100vh - ${store.headerHeight}px - 60px)`}
            scrollPaddingBottom={20}
          >
            <TableHead>
              <TableRow>
                <HeaderCell>
                Name
                </HeaderCell>
                {store.hasEpp &&
                  <HeaderCell>
                    Type
                  </HeaderCell>
                }
                <HeaderCell>
                  Total Scheduled Appts
                </HeaderCell>
                <HeaderCell>
                  Actions
                </HeaderCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map(config => {
                const isSpark = sparkPortalsState.data.find((id) => id === config.newPatientPortalSettingsId);
                return (
                  <PortalItem
                    config={config}
                    openDeleteDialog={openDeleteDialog}
                    hasEpp={store.hasEpp}
                    classes={classes}
                    isSpark={isSpark}
                  />
                );
              })}
            </TableBody>
          </TableContainer>}
      </div>
      <Modal
        title="Delete Portal?"
        size="xs"
        open={Boolean(deletePP)}
        onClose={handleModalDeleteClose}
        className='sked-test-npp-delete-modal'
        buttons={[
          <HeaderButton
            title='Delete'
            onClick={confirmDelete}
            disabled={deletionState.loading}
            color='danger'
            className='sked-test-npp-delete-modal-button-delete'
          />
        ]}
      >
        <div>
          <WarningRectangle
            open
            title={<>
              <div className={classes.cutoff}>
                <i>"{deletePP?.name}"</i>
              </div>&nbsp;will be deleted.</>}
            body={'Before deletion, ensure that all links to this portal, including those on websites, social media, and QR codes, are removed. The portal will still function, but you won\'t be able to edit the settings.'}
            type='error'
            tag='Warning'
            className={classes.warning}
            Icon={() => <RoundExclamationIcon />}
          />
        </div>
      </Modal>
    </>
  );
};

export default NewPatientPortalSettingsList;
