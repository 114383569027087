import {
  LocalDateTime, ZoneId, ZoneOffset, LocalDate,
  LocalTime, ZonedDateTime
} from '@js-joda/core';
import '@js-joda/timezone';
import {
  propOr, chain, unnest, values, pipe,
  reduce, sortBy, prop, uniqBy,
  map, max, mapObjIndexed, evolve,
  without,
} from 'ramda';

export const getCurrentOfficeHours = ({ officeHours = [], date = '2020-10-01' }) => {
  return officeHours.reduce((currentSet, selectedSet) => {
    if (date >= currentSet.start) {
      return currentSet;
    }
    return selectedSet;
  }, { hours: {} });
};

const getHoursByDate = officeHours => date => {
  return pipe(
    sortBy(prop('start')),
    reduce((acc, cur) => {
      return date >= cur.start ? cur : acc;
    }, defaultHoursEntry)
  )(officeHours);
};

export const filterHoursListByDates = ({ officeHoursList = [], dates = ['2020-10-01'] }) => {
  return pipe(
    map(getHoursByDate(officeHoursList)),
    uniqBy(prop('start'))
  )(dates);
};

const getStart = (start, open) => !start || open < start ? open : start;

const getEnd = (end, close) => !end || close > end ? close : end;

const setStartEndDefaultIfNone = ({ start, end }) => {
  return {
    start: start || '08:00',
    end: end || '18:00'
  };
};


const convertToUTCDateTime = ({ startEnd, timezone, date, interval }) => {
  const timeToZonedMoment = (time, buffer) => {
    const localDate = LocalDateTime
      .parse(`${date}T${time}`)
      .plusMinutes(buffer);

    const m = localDate.minute();
    const roundedMinute = Math.ceil(m / interval) * interval;
    const minutesToAdd = roundedMinute - m;
    const roundedDate = localDate.plusMinutes(minutesToAdd);

    const jdaStr = roundedDate
      .atZone(ZoneId.of(timezone))
      .withZoneSameInstant(ZoneOffset.UTC)
      .toString();
    return jdaStr;
  };
  const start = timeToZonedMoment(startEnd.start, -30);
  const end = timeToZonedMoment(startEnd.end, 30);

  return {
    start,
    end
  };
};

export const getDateStartClosedLocalTime = ({ officeHours = [], date = '2020-10-01' }) => {
  const currentOfficeHours = getCurrentOfficeHours({ officeHours, date });
  const startEndTimes = getDayStartEnd({
    officeHours: currentOfficeHours,
    date
  });
  return startEndTimes;
};

const getDayOfWeek = date => {
  return LocalDate.parse(date).dayOfWeek().toString().toLowerCase();
};

const getDayStartEnd = ({ officeHours = { hours: {} }, date = '2020-10-01', minAptTime, maxAptTime, notDefaultIfNone = false }) => {
  const dayOfWeek = getDayOfWeek(date);

  const dayHours = officeHours.hours[dayOfWeek] || [];

  const maybeStartEnd = dayHours.reduce(({ start, end }, { open, close }) => {
    return {
      start: getStart(start, open),
      end: getEnd(end, close)
    };
  }, { start: minAptTime, end: maxAptTime });

  if (notDefaultIfNone) {
    return maybeStartEnd;
  }
  return setStartEndDefaultIfNone(maybeStartEnd);

};

export const getDayStartEndFromList = ({ officeHoursList = [{ hours: {} }], minAptTime, maxAptTime }) => {
  const dayHours = chain(({ hours }) => {
    return unnest(values(hours));
  }, officeHoursList);

  const maybeStartEnd = dayHours.reduce(({ start, end }, { open, close }) => {
    return {
      start: getStart(start, open),
      end: getEnd(end, close)
    };
  }, { start: minAptTime, end: maxAptTime });

  return setStartEndDefaultIfNone(maybeStartEnd);
};

export const getDateStartClosed = ({
  interval,
  officeHours = [],
  selectedLocation,
  timezone,
  date = '2020-10-01',
  minAptTime,
  maxAptTime
}) => {
  if (selectedLocation) {
    const item = officeHours.find(office => office.locationId === selectedLocation);
    const currentOfficeHours = getCurrentOfficeHours({ officeHours: item ? [item] : officeHours, date });
    const startEndTimes = getDayStartEnd({
      officeHours: currentOfficeHours,
      date,
      minAptTime,
      maxAptTime
    });
    return convertToUTCDateTime({ startEnd: startEndTimes, timezone, date, interval });
  }

  const startsAndEnds = officeHours.map(officeHour => {
    const currentOfficeHours = getCurrentOfficeHours({ officeHours: [officeHour], date });
    return getDayStartEnd({
      officeHours: currentOfficeHours,
      date,
      minAptTime,
      maxAptTime,
      notDefaultIfNone: true,
    });
  });

  const start = startsAndEnds.reduce((min, value) => {
    return min > value.start ? value.start : min;
  }, startsAndEnds[0]?.start);
  const end = startsAndEnds.reduce((max, value) => {
    return max < value.end ? value.end : max;
  }, startsAndEnds[0]?.end);

  return convertToUTCDateTime({ startEnd: setStartEndDefaultIfNone({ start, end }), timezone, date, interval });
};

export const roundDownToNearestHour = time => {
  // Rounds down to nearest hour if minute part is > 0
  // if minute part = 0 then go down to the next hour
  // if the hour is 0 then don't change the hour
  const minutes = time.minute();
  const hours = time.hour();
  if (minutes > 0 || hours === 0) {
    return LocalTime.of(hours);
  }
  return LocalTime.of(hours - 1);
};

export const roundUpToNearestHour = time => {
  const hours = time.hour();
  if (hours < 23) {
    return LocalTime.of(hours + 1);
  }
  return LocalTime.of(hours, 30);
};

// For week view
export const getDateStartClosedByDates = ({ officeHours = [], dates = ['2020-10-01'], minAptTime, maxAptTime }) => {
  // TODO: I don't like this function anymore.  It's doing too much.
  // Ideally there'd be a function to get the start time for the current day's hours
  // then another to get the end time for the current day's hours
  // then a function to pick the min time
  // then another to pick the max
  // then one to round down to the nearest hours for min
  // then another to round up for the nearest max
  const officeHoursList = filterHoursListByDates({ officeHoursList: officeHours, dates });
  const { start, end } = getDayStartEndFromList({ officeHoursList, minAptTime, maxAptTime });
  const startBuf = roundDownToNearestHour(LocalTime.parse(start)).toString();
  const endBuf = roundUpToNearestHour(LocalTime.parse(end)).toString();
  const bufferedTimes = {
    // Need to do these test because local time will wrap the time around
    // - so if the earliest appointment is at midnight, then minus 30 minues will be 23:30
    // which we don't want
    start: start < startBuf ? start : startBuf,
    end: end > endBuf ? end : endBuf
  };
  return bufferedTimes;
};


//Special office hours

const filterSpecialHours = ({ locationId, date, specialHours }) => {
  const location = locationId === 'main' ? null : locationId;
  return specialHours.filter((sp) => {
    return (
      sp.date === date
      &&
      (locationId === 0 ? true : sp.locationId === location)
    );
  });
};

const getDayTimes = ({ specialHours, timezone, date, dayStartTime, dayEndTime }) => {
  return chain(({ hours = [], name }) => {
    if (hours.length === 0) {
      return [{ start: dayStartTime, end: dayEndTime, display: name }];
    }
    return hours
      .map(({ open, close }) => {
        // Convert special office hours to UTC time
        const start = ZonedDateTime.of(
          LocalDate.parse(date),
          LocalTime.parse(open),
          ZoneId.of(timezone)
        )
          .withZoneSameInstant(ZoneOffset.UTC)
          .toString();
        const end = ZonedDateTime.of(
          LocalDate.parse(date),
          LocalTime.parse(close),
          ZoneId.of(timezone)
        )
          .withZoneSameInstant(ZoneOffset.UTC)
          .toString();
        return {
          start,
          end
        };
      })
      .concat([{ start: dayEndTime, end: null }])
      .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
      .reduce((acc, cur) => {
        // Invert the hours so we display the closed times not the open times
        const list = acc.list.concat({
          display: name,
          start: acc.start,
          end: cur.start
        });
        return {
          list,
          start: cur.end
        };
      }, { start: dayStartTime, list: [] })
      .list;
  }, specialHours);
};

const getBlockPositions = ({ blocks, dayStartTime, cellHeight, interval }) => {
  const minToY = min => cellHeight / interval * min;
  const startOfDay = new Date(dayStartTime).getTime();

  return blocks.map(({ start, end, display }) => {
    const y = minToY((new Date(start).getTime() - startOfDay) / (1000 * 60)) + 1;
    const yEnd = minToY((new Date(end).getTime() - startOfDay) / (1000 * 60)) + 1;
    const h = yEnd - y;
    return {
      start,
      end,
      display,
      y,
      h,
    };
  });
};

export const getSpecialHoursPositions = ({
  date = '2021-01-10',
  specialHours = [],
  timezone = 'America/New_York',
  dayStartTime,
  dayEndTime,
  cellHeight,
  interval,
  locationId = 0,
}) => {
  const filterHours = filterSpecialHours({ locationId, date, specialHours });
  console.log(filterHours);
  const blocks = getDayTimes({ specialHours: filterHours, timezone, date, dayStartTime, dayEndTime });
  const blockPositions = getBlockPositions({ blocks, dayStartTime, cellHeight, interval });
  return blockPositions;
};

//Special office hours
const openHoursToBlocks = ({ hours, name, timezone, date, dayStartTime, dayEndTime }) => {
  return hours
    .map(({ open, close }) => {
      // Convert special office hours to UTC time
      const start = ZonedDateTime.of3(
        LocalDate.parse(date),
        LocalTime.parse(open),
        ZoneId.of(timezone)
      )
        .withZoneSameInstant(ZoneOffset.UTC)
        .toString();
      const end = ZonedDateTime.of3(
        LocalDate.parse(date),
        LocalTime.parse(close),
        ZoneId.of(timezone)
      )
        .withZoneSameInstant(ZoneOffset.UTC)
        .toString();
      return {
        start,
        end
      };
    })
    .concat([{ start: dayEndTime, end: null }])
    .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
    .reduce((acc, cur) => {
      // Invert the hours so we display the closed times not the open times
      const list = acc.list.concat({
        display: name,
        start: acc.start,
        end: cur.start
      });
      return {
        list,
        start: cur.end
      };
    }, { start: dayStartTime, list: [] })
    .list;
};


// Schedule Hours Code
//
//

const defaultHoursEntry = {
  hours: {
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: []
  }
};

const filterScheduleHours = ({ hoursList = [], date = '2021-01-14' }) => {
  //Find the hours entry that applies to the date
  const hoursEntry = pipe(
    sortBy(prop('date')),
    reduce((acc, cur) => {
      return date >= cur.date ? cur : acc;
    }, defaultHoursEntry)
  )(hoursList);

  //Get the day of the week of the current date
  const dow = getDayOfWeek(date);

  //pull that day of the week our of the hours entry
  const dayHours = hoursEntry.hours[dow];

  return dayHours;
};

const processSpecialScheduleHours = ({
  schedule,
  start,
  end,
  date,
  timezone,
  interval,
  cellHeight
}) => {
  if (schedule.id === -1) {
    return [];
  }

  const hoursEntry = pipe(
    sortBy(prop('date')),
    reduce((acc, cur) => {
      return date >= cur.start && date <= cur.end ? cur : acc;
    }, defaultHoursEntry)
  )(schedule.specialHoursList);

  if (!hoursEntry.start) {
    return [];
  }

  //Get the day of the week of the current date
  const dow = getDayOfWeek(date);

  //pull that day of the week our of the hours entry
  const hours = hoursEntry.hours[dow];

  const blocks = openHoursToBlocks({
    hours,
    name: 'Schedule',
    date,
    timezone,
    dayStartTime: start,
    dayEndTime: end
  });
  const blockPositions = getBlockPositions({
    blocks,
    dayStartTime: start,
    cellHeight,
    interval
  });
  return blockPositions;
};

const processScheduleBlocks = ({
  schedule,
  start,
  date,
  timezone,
  interval,
  cellHeight
}) => {
  if (schedule.id === -1) {
    return [];
  }

  const dayBlocks = schedule.blocksList.filter(b => b.date === date);

  const blocks = dayBlocks.map(b => {
    const start = ZonedDateTime.of3(
      LocalDate.parse(b.date),
      LocalTime.parse(b.startTime.Include || b.startTime.Exclude),
      ZoneId.of(timezone)
    )
      .withZoneSameInstant(ZoneOffset.UTC)
      .toString();
    const end = ZonedDateTime.of3(
      LocalDate.parse(b.date),
      LocalTime.parse(b.endTime.Include || b.endTime.Exclude),
      ZoneId.of(timezone)
    )
      .withZoneSameInstant(ZoneOffset.UTC)
      .toString();
    return {
      display: 'Block',
      start,
      end
    };
  });
  const blockPositions = getBlockPositions({
    blocks,
    dayStartTime: start,
    cellHeight,
    interval
  });
  return blockPositions;
};

const processNormalScheduleHours = ({
  schedule,
  start,
  end,
  date,
  timezone,
  interval,
  cellHeight
}) => {
  if (schedule.id === -1) {
    return [];
  }
  const hours = filterScheduleHours({ hoursList: schedule.hoursList, date });
  const blocks = openHoursToBlocks({
    hours,
    name: 'Schedule',
    date,
    timezone,
    dayStartTime: start,
    dayEndTime: end
  });
  const blockPositions = getBlockPositions({
    blocks,
    dayStartTime: start,
    cellHeight,
    interval
  });
  return blockPositions;
};

const maxList = l => reduce(max, 0, l);

const addToTime = m => t => {
  return LocalTime.parse(t).plusMinutes(m).toString();
};

const modifyScheduleWithClosingHours = ({
  schedule,
  appointmentTypes
}) => {
  if (schedule.id === -1) {
    return schedule;
  }

  const typesWithCloseLimits = pipe(
    propOr([], 'appointmentType'),
    map(typeId => appointmentTypes.find(t => t.id === typeId)),
    without([undefined]),
    map(({ duration, notAllowedMinutesBeforeClosing }) => duration - (notAllowedMinutesBeforeClosing || 0)),
    maxList
  )(schedule);

  const newSchedule = evolve({
    hoursList: map(evolve({
      hours: mapObjIndexed(map(evolve({
        close: addToTime(typesWithCloseLimits)
      })))
    }))
  })(schedule);

  return newSchedule;
};

export const getScheduleHoursPositions = ({
  schedule,
  start,
  end,
  date,
  timezone,
  interval,
  cellHeight,
  appointmentTypes = []
}) => {
  const newSchedule = modifyScheduleWithClosingHours({ schedule, appointmentTypes });

  const normalHoursBlocks = processNormalScheduleHours({
    schedule: newSchedule,
    start,
    end,
    date,
    timezone,
    interval,
    cellHeight
  });

  const specialHoursBlocks = processSpecialScheduleHours({
    schedule: newSchedule,
    start,
    end,
    date,
    timezone,
    interval,
    cellHeight
  });

  const theBlocks = processScheduleBlocks({
    schedule: newSchedule,
    start,
    end,
    date,
    timezone,
    interval,
    cellHeight
  });


  //TODO process blocks - should be in the schedule

  return normalHoursBlocks
    .concat(specialHoursBlocks)
    .concat(theBlocks);
};
