import React from 'react';
import { makeStyles } from '@mui/styles';
import Logo from '../../images/logo-blue.png';
import { Paper } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    maxWidth: '520px',
    margin: '10px auto',
    justifyContent: 'center',
    alignItems: 'flex-start',
    minHeight: '100%'
  },
  paper: {
    width: '100%',
    padding: '40px'
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  header: {
    textAlign: 'center',
    marginBottom: '30px',
    fontSize: '24px',
  },
}));

const AccountDeletion = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.logoContainer}>
          <img height="30" src={Logo} />
        </div>
        <h2 className={classes.header}>Account Deletion</h2>
        <p>There are two ways to delete your SKED account:</p>
        <ul>
          <li>Requesting deletion by emailing <a href="emailto:info@sked.life">info@sked.life</a></li>
          <li>Within the SKED app</li>
        </ul>
        <h3>Deleting an Account from the SKED App</h3>
        <p>Deleting your account from the SKED app is very easy. Follow the steps below to do so. </p>
        <ul>
          <li>Login to your existing SKED account.</li>
          <li>Once logged in, tap on the hamburger menu in the top left.</li>
          <li>Go to Settings.</li>
          <li>Then go to Account Settings.</li>
          <li>Finally tap Delete Account.</li>
          <li>A popup will show up verifying your decision and explaining
        that we must keep health data for 7 years to to meet state
        requirements. If you wish to continue, tap YES.</li>
          <li><b>You have successfully deleted your SKED account.</b></li>
        </ul>
      </Paper>
    </div>
  );
};

export default AccountDeletion;
