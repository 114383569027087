import React, { useEffect, useState } from 'react';
import * as R from 'ramda';
import api from '../../../../services/api';
import { PopupTemplate, popup } from '../../../../services/Popup';
import SelectOffices from '../../../../components/SelectOffices/selectOffices.component';

import { Office } from '../../../Admin/Agencies/agencies.types';

type OfficeGroupsProps = {
  open: boolean;
  office: Office;
  offices: Office[];
  onClose: () => void;
}

const OfficeGroups = ({ office, open, offices = [], onClose }: OfficeGroupsProps) => {
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState<Office[]>([]);
  const [originalGroup, setOriginalGroup] = useState<Office[]>([]);
  const [allOffices, setAllOffices] = useState(offices);

  const limitedOffices = R.splitAt(25, allOffices)[0];

  const getGroup = (id: number) => {
    setLoading(true);
    api.get(`office/group/${id}`).then((group) => {
      setGroup(group);
      setOriginalGroup(group);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      if (error.response.status === 404) {
        console.log(error);
        popup('Error!', 'Failed to get office group!');
      }
    });
  };

  const searchOffices = (value: string) => {
    const v = value.toLowerCase();
    const filteredOffices = R.filter(({ name }) => {
      return R.includes(v, name.toLowerCase());
    })(offices);
    setAllOffices(R.sortBy(R.prop('name'))(filteredOffices));
  };

  const addOffice = (selected: Office) => {
    setGroup(R.append(selected, group));
  };

  const removeOffice = (selected: Office) => {
    setGroup(R.without([selected], group));
  };

  const handleClose = () => {
    setGroup([]);
    setOriginalGroup([]);
    onClose();
    setAllOffices(R.sortBy(R.prop('name'))(offices));
  };

  const save = () => {
    const removed = R.difference(originalGroup, group);
    const added = R.difference(group, originalGroup);
    const addedData = {
      offices: R.map(({ id }) => id)(added),
      officeId: office.id,
    };
    const removedData = {
      offices: R.map(({ id }) => id)(removed),
      officeId: office.id,
    };
    setLoading(true);
    Promise.all([
      api.post('office/group', addedData),
      api.delete('office/group', { data: removedData }),
    ]).then(() => {
      setLoading(false);
      handleClose();
    }).catch((error) => {
      setLoading(false);
      console.log(error);
    });
  };

  useEffect(() => {
    if (office.id) {
      getGroup(office.id);
    }
  }, [office.id]);

  useEffect(() => {
    setAllOffices(R.sortBy(R.prop('name'))(offices));
  }, [offices]);

  return (
    <>
      <PopupTemplate />
      <SelectOffices
        open={open}
        cancel={handleClose}
        offices={limitedOffices}
        selectedItems={group}
        searchOffices={searchOffices}
        onAdd={addOffice}
        onRemove={removeOffice}
        title='Office Grouping'
        save={save}
        loadingSelected={loading}
      />
    </>
  );
};

export default OfficeGroups;
