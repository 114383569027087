import React from 'react';

type Props = {
  color?: string;
};

const PhoneIcon = ({ color }: Props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.362448 4.27073C0.351042 3.72641 0.590569 3.0506 1.18876 2.50406C1.65482 2.07855 2.09427 1.62263 2.53119 1.1667C3.23234 0.435768 4.33049 0.507372 5.00155 1.16259C5.78002 1.92362 6.54581 2.69765 7.31223 3.47072C7.94337 4.10756 8.01339 5.00325 7.49283 5.67969C7.32015 5.90401 7.09837 6.06401 6.83191 6.15906C6.34842 6.33142 5.97582 6.62703 5.7702 7.1083C5.63744 7.41911 5.61748 7.73816 5.70461 8.06577C5.95776 9.01691 6.49828 9.79917 7.1497 10.5139C7.81568 11.2443 8.56817 11.8684 9.46259 12.3028C9.88462 12.5078 10.3187 12.6839 10.8072 12.5955C11.3826 12.4916 11.824 12.2008 12.0537 11.6507C12.2108 11.2747 12.3917 10.9281 12.7532 10.7059C13.4142 10.2998 14.2177 10.3885 14.7991 10.9597C15.5959 11.7426 16.3816 12.5363 17.1696 13.3278C17.8575 14.0181 17.7745 15.0913 17.1528 15.7366C16.6361 16.2724 16.1009 16.7904 15.5753 17.318C15.1229 17.7723 14.5557 17.9453 13.9369 17.9681C13.0897 17.9992 12.2875 17.7802 11.5046 17.4818C10.2167 16.99 9.04881 16.2838 7.94464 15.4677C5.89281 13.951 4.11695 12.1599 2.64049 10.0774C1.8465 8.95734 1.17957 7.76763 0.727126 6.46449C0.526885 5.88722 0.365616 5.06472 0.362448 4.27073Z" fill={color || '#4D4D4D'} />
    </svg>
  );
};

export default PhoneIcon;
