import React from 'react';
import * as R from 'ramda';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export default function ProContainer({
  pro,
  sked,
  index,
  skedPatch,
  children,
}) {
  return (
    <div
      id={pro.id}
      key={pro.id}
      style={{ width: '100%' }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => {
          if (R.includes(pro.id, R.propOr([], 'hiddenPros', sked))) {
            const list = R.filter((id) => id !== pro.id)(sked.hiddenPros);
            skedPatch('hiddenPros', list, index);
          } else {
            const list = R.isNil(sked.hiddenPros) ? [pro.id] : R.append(pro.id, sked.hiddenPros);
            skedPatch('hiddenPros', list, index);
          }
        }}
      >
        {pro.displayFirstName + ' ' + pro.displayLastName}
        {R.includes(pro.id, R.propOr([], 'hiddenPros', sked)) ?
          <ArrowRightIcon style={{ fontSize: '24px' }} />
          :
          <ArrowDropDownIcon style={{ fontSize: '24px' }} />
        }
      </div>
      {!R.includes(pro.id, R.propOr([], 'hiddenPros', sked)) && children}
    </div>
  );
}
