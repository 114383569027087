import * as at from '../../actionTypes';
import {
  merge, F, evolve, prepend, always, update, remove, map, filter
} from 'ramda';

const init = {
  busy: false,
  automations: {
    data: [],
    perPage: 50,
    page: 1,
    totalCount: 1,
    totalPage: 1,
  },
  state: 'LIST',
  npps: [],
};

export default (automations = init, action) => {
  switch (action.type) {
    case at.AUTOMATIONS_PATCH:
      return merge(automations, action.data);
    case at.AUTOMATIONS_REMOTE_GET: {
      if (action.state === 'REQUEST') {
        return merge(automations, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return merge(automations, {
          automations: action.data,
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return merge(automations, {
          busy: false,
        });
      }
      return automations;
    }
    case at.AUTOMATIONS_REMOTE_POST: {
      if (action.state === 'REQUEST') {
        return merge(automations, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        const newAuto = {
          ...action.data,
          isEdit: true,
        };
        return evolve({
          busy: F,
          automations: evolve({
            data: prepend(newAuto),
          }),
        }, automations);
      } else if (action.state === 'ERROR') {
        return merge(automations, {
          busy: false,
        });
      }
      return automations;
    }
    case at.AUTOMATIONS_REMOTE_GET_NPPS: {
      if (action.state === 'REQUEST') {
        return merge(automations, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return evolve({
          busy: F,
          npps: always(action.data),
        }, automations);
      } else if (action.state === 'ERROR') {
        return merge(automations, {
          busy: false,
        });
      }
      return automations;
    }
    case at.AUTOMATIONS_UPDATE_ALL_AUTOMATIONS: {
      return evolve({
        automations: evolve({
          data: map(merge(action.data)),
        }),
      }, automations);
    }
    case at.AUTOMATIONS_ADD_AUTOMATIONS: {
      const trigger = {
        ...action.data.trigger,
        ClientList: action.data?.trigger?.ClientList ? {
          ...action.data.trigger.ClientList,
          ...action.data.campaign,
          listId: action.data.campaign.id,
        } : undefined,
      };
      const auto = action.data.trigger ?
        {
          ...action.data,
          trigger: filter((i) => i !== undefined, trigger),
          filter: {
            ...action.data.filter,
            appointment: null,
            client: action.data.campaign ? {
              ...action.data.client,
              clientList: {
                ...action.data.campaign,
                listId: action.data.campaign.id,
              },
            } : action.data.client,
          }
        }
        :
        {
          actions: [{
            action: {
              SendMessage: null,
            },
            id: 0
          }],
          enabled: false,
          id: 0,
          delay: null,
          isEdit: true,
          hidden: true,
          filter: {
            appointment: null,
            client: action.data.campaign ? {
              clientList: {
                ...action.data.campaign,
                listId: action.data.campaign.id,
              },
            } : null,
          },
          trigger: {
            Dummy: [],
          }
        };
      return evolve({
        automations: evolve({
          data: prepend(auto),
        }),
      }, automations);
    }
    case at.AUTOMATIONS_REMOVE_AUTOMATIONS: {
      return evolve({
        automations: evolve({
          data: remove(action.data.index, 1),
        })
      }, automations);
    }
    case at.AUTOMATIONS_UPDATE_AUTOMATIONS: {
      return evolve({
        automations: evolve({
          data: update(action.data.index, action.data.automation),
        })
      }, automations);
    }
    default:
      return automations;
  }
};
