import React, { useState, useEffect } from 'react';
import {
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import * as R from 'ramda';
import api from '../../../../../services/api.js';
import {
  tzParseFormat,
  format,
} from '../../../../../services/joda.js';
import {
  LocalDate,
} from '@js-joda/core';
import { TableContainer, HeaderCell, BodyCell } from '../../../../../components/CustomTable';

const parseBirthday = (birthday) => {
  try {
    return format(LocalDate.parse(birthday), 'MM/dd/yyyy');
  } catch (e) {
    return '';
  }
};

const mapIndexed = R.addIndex(R.map);

const def = {
  updates: [],
  status: 'INIT',
  busy: false,
  page: 1,
  perPage: 25,
  pros: [],
  types: [],
};

export default function ClientUpdates({
  client,
  tz,
  open,
}) {
  const [updates, setUpdates] = useState(def.updates);
  const [status, setStatus] = useState(def.status);
  const [busy, setBusy] = useState(def.busy);
  const [page, setPage] = useState(def.page);
  const [perPage, setPerPage] = useState(def.perPage);
  const [pros, setPros] = useState(def.pros);
  const [types, setTypes] = useState(def.types);

  // getUpdates :: {page, perPage, query: {clientId}} -> Promise ()
  const getUpdates = (body) => {
    setBusy(true);
    return Promise.all([
      api.post('client/updates/query', body),
      api.get('professional'),
      api.get('appointmentType'),
    ]).then(([data, pros, types]) => {
      setBusy(false);
      setUpdates(data.data);
      setStatus('VIEW');
      setPros(pros);
      setTypes(types);
    }).catch((error) => {
      setBusy(false);
      console.log(error);
    });
  };

  useEffect(() => {
    if (status === 'INIT' && open) {
      getUpdates({
        page,
        perPage,
        query: {
          clientId: client.id,
        },
      });
    } else if (status === 'VIEW' && !open) {
      setUpdates(def.updates);
      setStatus(def.status);
      setBusy(def.busy);
      setPage(def.page);
      setPerPage(def.perPage);
      setPros(def.pros);
      setTypes(def.types);
    }
  }, [status, open]);


  const updateItem = (update, idx, tz) => {
    const {
      time,
      createData,
      updateData,
    } = update;
    const aptType = (typeId) => {
      const type = R.find(R.propEq('id', typeId))(types);
      if (type) {
        return type.internalName + ' (' + type.name + ')';
      }
      return '';
    };
    const pro = (typeId) => {
      const type = R.find(R.propEq('id', typeId))(types);
      if (type) {
        const pro = R.find(R.propEq('id', type.professionalId))(pros);
        if (pro) {
          return pro.firstName + ' ' + pro.lastName;
        }
        return '';
      }
      return '';
    };
    return (
      <TableRow key={idx}>
        <BodyCell>
          {tzParseFormat(time, tz, 'MM/dd/yyyy hh:mm a')}
        </BodyCell>
        <BodyCell>
          {createData ?
            <div>
              <b>Client Created:</b>
              <br />
              {'First: ' + createData.firstName}
              <br />
              {'Last: ' + createData.lastName}
              <br />
              {createData.email &&
                ['Email: ' + createData.email,
                  <br />]}
              {createData.phone &&
                ['Phone: ' + createData.phone,
                  <br />]}
              {createData.birthday &&
                ['Birthday: ' + parseBirthday(createData.birthday),
                  <br />]}
              {createData.status &&
                ['Status: ' + createData.status,
                  <br />]}
              {createData.referrer &&
                ['Referrer: ' + createData.referrer,
                  <br />]}
              {createData.referrer &&
                ['Referrer: ' + createData.referrer,
                  <br />]}
              {createData.defaultAppiontmentType &&
                ['Appointment Type: '
                  + aptType(createData.defaultAppiontmentType),
                <br />]}
              {createData.defaultAppiontmentType &&
                ['Professional: '
                  + pro(createData.defaultAppiontmentType),
                <br />]}
            </div>
            :
            <div>
              <b>Client Updated:</b>
              <br />
              {R.hasPath(['firstName', 'Update'], updateData) &&
                ['First: ' + updateData.firstName.Update, <br />]}
              {R.hasPath(['lastName', 'Update'], updateData) &&
                ['Last: ' + updateData.lastName.Update, <br />]}
              {R.hasPath(['email', 'Update'], updateData) &&
                ['Email: ' + updateData.email.Update, <br />]}
              {R.hasPath(['allowEmail', 'Update'], updateData) &&
                ['Allow Email: ' + updateData.allowEmail.Update, <br />]}
              {R.hasPath(['phone', 'Update'], updateData) &&
                ['Phone: ' + updateData.phone.Update, <br />]}
              {R.hasPath(['allowSMS', 'Update'], updateData) &&
                ['Allow SMS: ' + updateData.allowSMS.Update, <br />]}
              {R.hasPath(['birthday', 'Update'], updateData) &&
                R.pathOr(false, ['birthday', 'Update'], updateData) &&
                ['Birthday: ' + parseBirthday(updateData.birthday.Update), <br />]}
              {R.hasPath(['status', 'Update'], updateData) &&
                ['Status: ' + updateData.status.Update, <br />]}
              {R.hasPath(['referrer', 'Update'], updateData) &&
                ['Referrer: ' + updateData.referrer.Update, <br />]}
              {R.hasPath(['referrer', 'Update'], updateData) &&
                ['Referrer: ' + updateData.referrer.Update, <br />]}
              {R.hasPath(['defaultAppointmentType', 'Update'], updateData) &&
                ['Appointment Type: '
                  + aptType(updateData.defaultAppointmentType.Update),
                <br />]}
              {R.hasPath(['defaultAppointmentType', 'Update'], updateData) &&
                ['Professional: '
                  + pro(updateData.defaultAppointmentType.Update),
                <br />]}
            </div>
          }
        </BodyCell>
      </TableRow>
    );
  };

  const table = (updates, tz) => {
    return (
      <TableContainer noHover fullPage={false} style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <HeaderCell>
              Time
            </HeaderCell>
            <HeaderCell>
              Update
            </HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mapIndexed((d, idx) => updateItem(d, idx, tz), updates)}
        </TableBody>
      </TableContainer>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ margin: '20px' }}>
        <div>
          {busy && <div className="loader" />}
          {!busy &&
            R.ifElse(
              R.isEmpty,
              R.always(<div>This client hasn't been updated ever!</div>),
              R.always(table(updates, tz))
            )(updates)}
        </div>
      </div>
    </div>
  );
}
