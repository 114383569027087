import api from '../../services/api.js';
import {
  Automation, DateTimeFilter, SendMsgGetAction,
} from '../../routes/Automations/automation-types';
import {
  has, hasPath, cond, T, dropLast, isEmpty, equals, always
} from 'ramda';
import { Email, Push, Sms } from '../../routes/Templates/routes/TemplatesEdit/templates-edit.reducer';
import { AppointmentType, Professional } from '../../routes/Appointments/appointments.types';
import { Message as ApiMessage } from '../../routes/Templates/templates.reducer';
import {
  Message as SentMessage
} from '../view-message.component';

/* TO ALL WHOM ENTER:
   automation templates won't save if there are any filters that
   contain `appointmentType: []`. You must make sure to filter out
   that filter before saving! Hence the undefineds for TimeBased.
*/


export interface Message {
  email: Email,
  forFeature: string;
  isDeleted: boolean;
  createdAt: string;
  messageType: MessageType;
  name: string;
  sms: Sms,
  id: number;
  push: Push
  isEnabled: boolean;
  isTemplate: boolean;
}

export type MessageWithAutomation = {
  email: Email,
  forFeature: string;
  isDeleted: boolean;
  createdAt: string;
  name: string;
  sms: Sms,
  id: number;
  push: Push
  isEnabled: boolean;
  isTemplate: boolean;
  messageType: {
    Automation: Automation[];
  };
}

export type MessageType = 'message' | 'template' | 'sked-template';

export type MessageLogicType = SmartReplyType | TimeBasedReminderType;

export type AutomationMessageTypes = 'SmartReply' | 'TimeBasedReminder' | 'Spark';

export interface SmartReplyType {
  type: 'SmartReply';
  keywords: string[];
  autoId?: number;
}

export type Tense = 'before' | 'after';
export type ClientStatus = 'Active' | 'Inactive' | 'Inert';

export interface TimeBasedReminderType {
  type: 'TimeBasedReminder';
  time: string;
  status: string;
  tense: Tense;
  amount: number;
  appointmentTypes: number[];
  clientStatus: ClientStatus;
  autoId?: number;
}

export const getMessage =
  (messageType: MessageType) => async (id: number): Promise<Message> => {
    const uri = messageType === 'sked-template' ? 'message/template' : 'message';
    const msg = await api.get(`${uri}/${id}`);
    const email = msg.email && {
      subject: msg.email.subject,
      html: msg.email.body.HTML?.body || '',
      shouldFrame: msg.email.body.HTML?.shouldFrame,
      body: msg.email.body.PlainText || '',
    };
    if (messageType !== 'sked-template') {
      const ea = await api.post('attachment', {
        attachmentIds: msg.email?.attachmentIds || [],
      });
      const sa = await api.post('attachment', {
        attachmentIds: msg.sms?.attachmentIds || [],
      });
      const pa = await api.post('attachment', {
        attachmentIds: msg.push?.attachmentIds || [],
      });
      return Promise.resolve({
        ...msg,
        email: email && {
          ...email,
          attachments: ea.officeAttachments,
        },
        sms: msg.sms && {
          ...msg.sms,
          attachments: sa.officeAttachments,
        },
        push: msg.push && {
          ...msg.push,
          attachments: pa.officeAttachments,
        },
      });
    }
    return {
      ...msg,
      email,
    };
  };

export interface SaveMessageType {
  message: ApiMessage;
  path: string;
}

export const saveMessage = ({
  message, path,
}: SaveMessageType): Promise<ApiMessage> => {
  if (message.id) {
    return api.put(`message/${message.id}`, message);
  }
  return api.post('message', message).then((msg) => {
    return api.post('paths', {
      path: (path + `.msg_${msg.id}`).split('.').filter((s) => !isEmpty(s)),
      resource: {
        Message: msg.id,
      },
    }).then(() => {
      return msg;
    });
  });
};

export interface SaveAutomationType {
  automation: Automation;
  path?: string;
}

export const saveAutomation = ({
  automation
}: SaveAutomationType): Promise<Automation> => {
  if (automation.id) {
    return api.put(`automations/${automation.id}`, automation);
  }
  return api.post('automations', automation);
};

export const saveTemplate = ({
  message, path,
}: SaveMessageType): Promise<ApiMessage> => {
  if (message.id) {
    return api.put(`message/template/${message.id}`, message);
  }
  return api.post('message/template', message).then((msg) => {
    return api.post('templates/paths', {
      path: (path + `.msg_${msg.id}`).split('.').filter((s) => !isEmpty(s)),
      resource: {
        Message: msg.id,
      },
    }).then(() => {
      return msg;
    });
  });
};

export const saveAutomationTemplate = ({
  automation, path
}: SaveAutomationType): Promise<Automation> => {
  if (!automation) {
    return Promise.resolve(null);
  }

  if (automation.id) {
    return api.put(`automations/template/${automation.id}`, {
      ...automation,
      actions: automation.actions.map((a) => ({
        ...a,
        action: {
          SendMessage: [],
        },
      })),
    });
  }
  return api.post('automations/template', {
    ...automation,
    actions: automation.actions.map(() => ({
      SendMessage: []
    })),
  }).then(({ id }) => {
    return api.post('templates/paths', {
      path: (path + `.auto_${id}`).split('.'),
      resource: {
        Automation: id,
      },
    });
  });
};

export const makeDefaultMessageLogic = (
  messageType: MessageType, type: AutomationMessageTypes
): MessageLogicType => {
  if (type === 'SmartReply') {
    return {
      type,
      keywords: [] as string[],
    };
  }
  if (type === 'TimeBasedReminder') {
    return {
      type,
      time: '',
      status: 'Scheduled',
      tense: 'Future' as Tense,
      amount: 0,
      appointmentTypes: messageType === 'sked-template' ?
        undefined : [] as number[],
      clientStatus: 'Active',
    };
  }
};

export const automationParser = (
  auto: Automation
): MessageLogicType => {
  const {
    filters,
    trigger,
    id,
  } = auto;

  // smart reply
  if (has('MessageReceived', trigger)) {
    return {
      type: 'SmartReply',
      keywords: trigger.MessageReceived,
      autoId: id,
    };
  }
  // time-based reminder
  if (has('Time', trigger) && has('Appointments', trigger.Time.query)) {
    const statusA = filters.find((filt) =>
      has('Appointment', filt.predicate) && has('Status', filt.predicate.Appointment)
    );
    const status = has('Appointment', statusA.predicate) &&
      has('Status', statusA.predicate.Appointment) ?
      statusA.predicate.Appointment.Status[0] : 'Scheduled';
    const timeA = filters.find((filt) =>
      has('Appointment', filt.predicate) && has('Time', filt.predicate.Appointment)
    );
    const time: DateTimeFilter = has('Appointment', timeA.predicate) &&
      has('Time', timeA.predicate.Appointment) ?
      timeA.predicate.Appointment.Time : { EqualTo: { InFuture: { days: 0 } } };
    const typesA = filters.find((filt) =>
      has('Appointment', filt.predicate) && has('AppointmentTypeIds', filt.predicate.Appointment)
    );
    const appointmentTypes = has('Appointment', typesA?.predicate) &&
      has('AppointmentTypeIds', typesA.predicate.Appointment) ?
      typesA.predicate.Appointment.AppointmentTypeIds : [];
    const clientStatusA = filters.find((filt) =>
      has('Client', filt.predicate) && has('Status', filt.predicate.Client)
    );
    const clientStatus = has('Client', clientStatusA.predicate) &&
      has('Status', clientStatusA.predicate.Client) ?
      clientStatusA.predicate.Client.Status : 'Active';
    const properTime = trigger.Time.time.length > 5 ?
      dropLast(3, trigger.Time.time) : trigger.Time.time;
    return {
      type: 'TimeBasedReminder',
      time: properTime,
      status,
      tense: hasPath(['EqualTo', 'InFuture'], time) ? 'before' : 'after',
      amount: cond([
        [hasPath(['EqualTo', 'InFuture']), () => time.EqualTo.InFuture.days],
        [hasPath(['EqualTo', 'Ago']), () => time.EqualTo.Ago.days],
        [T, () => 0]
      ])(time),
      appointmentTypes,
      clientStatus: (typeof clientStatus === 'object' && has('status', clientStatus) ?
        clientStatus.status : clientStatus) as ClientStatus,
      autoId: id,
    };
  }
};

export const automationConverter = (
  messageLogic: MessageLogicType, message: ApiMessage,
): Automation => {
  if (!messageLogic) {
    return;
  }
  const action = {
    SendMessage: { Id: message.id } as SendMsgGetAction,
  };
  const defaultStuff = {
    id: messageLogic.autoId,
    actions: [action],
    enabled: message.isEnabled,
    hidden: true,
    delay: 0,
  };
  // smart reply
  if (messageLogic.type === 'SmartReply') {
    return {
      ...defaultStuff,
      filters: [],
      trigger: {
        MessageReceived: messageLogic.keywords,
      },
      comment: 'a smart reply message',
    };
  }
  // time-based reminder
  if (messageLogic.type === 'TimeBasedReminder') {
    const {
      time, tense, status, appointmentTypes, amount, clientStatus
    } = messageLogic;
    const timeProp = tense === 'before' ?
      'InFuture' : 'Ago';
    return {
      ...defaultStuff,
      filters: [{
        isTrue: true,
        predicate: {
          Appointment: {
            Status: [status],
          },
        },
      }, appointmentTypes ? {
        isTrue: true,
        predicate: {
          Appointment: {
            AppointmentTypeIds: appointmentTypes,
          },
        },
      } : undefined, {
        isTrue: true,
        predicate: {
          Appointment: {
            Time: {
              EqualTo: {
                [timeProp]: {
                  days: amount,
                  months: 0,
                  years: 0,
                },
              },
            },
          },
        },
      }, {
        isTrue: true,
        predicate: {
          Client: {
            Status: clientStatus,
          },
        },
      }].filter((a) => a),
      trigger: {
        Time: {
          time: time.length === 8 ? time : time + ':00',
          query: {
            Appointments: [],
          },
        },
      },
      comment: 'a time-based reminder message',
    };
  }
};

export interface GetAutomationType {
  msgId: number;
  type: MessageType;
  path?: string;
}

export const getAutomation = ({
  msgId, type, path,
}: GetAutomationType): Promise<Automation> => {
  if (type === 'template' || type === 'message') {
    return api.post('automations/query', {
      page: 1,
      perPage: 1,
      query: {
        action: {
          msgId,
        },
      },
    }).then(({ data }) => data[0]);
  }
  const realPath = `${path}.msg_${msgId}`.split('.');
  /* TODO: should use a GET on `templates/paths/${realPath}
     but there seems to be a bug with this and I get a 400.
  */
  return api.post('templates/paths/query', {
    page: 1,
    perPage: 1,
    query: {
      isDirectDescendant: realPath
    },
  }).then(({ data }) => {
    if (data[0]) {
      return api.get(`automations/template/${data[0].resource.Automation}`);
    }
    return null;
  });
};

export const getProApptTypes = (): Promise<(AppointmentType[] | Professional[])[]> => {
  return api.get('appointmentType').then((apptTypes) => {
    return api.get('professional').then((pros) => {
      return [apptTypes, pros.filter(({ isHidden }: Professional) => !isHidden)];
    });
  });
};

export const getApptTypes = (): Promise<AppointmentType[]> => {
  return api.get('appointmentType');
};

export const getPros = (): Promise<Professional[]> => {
  return api.get('professional').then((pros) => {
    return pros.filter(({ isHidden }: Professional) => !isHidden);
  });
};

export interface MessageQueryType {
  perPage: number;
  page: number;
  path: string;
  isTemplate: boolean;
  isSkedTemplate?: boolean;
  forFeature: string[];
}

export interface MessageQueryOuput {
  data: MessageWithAutomation[];
  page: number;
  totalPages: number;
  totalCount: number;
}

export const getMessagesByPath = ({
  path, perPage, page, isTemplate, isSkedTemplate, forFeature
}: MessageQueryType) => {
  const uri = isSkedTemplate ? 'message/template/query' : 'message/query';
  return api.post(uri, {
    perPage,
    page,
    query: {
      isTemplate,
      forFeature,
      path: {
        Exists: {
          isDirectDescendant: path.split('.'),
        },
      },
    },
  });
};

export const pathFromMessageType = (
  messageType: MessageType,
  automationMessageType: AutomationMessageTypes
): string => {
  const base = messageType === 'sked-template' ?
    'tmp.msg.rcr.'
    :
    'msg.rcr.';
  if (automationMessageType === 'SmartReply')
    return base + 'smart_reply';
  if (automationMessageType === 'TimeBasedReminder')
    return base + 'time_based';
  return base + automationMessageType.toLowerCase();
};

export const toggleMessage = (message: MessageWithAutomation): Promise<(Message | Automation)[]> => {
  const autos = message.messageType.Automation.map((a: Automation) => {
    return api.put(`automations/toggle/${a.id}`, {
      enabled: message.isEnabled,
    });
  }) || [];
  return Promise.all([
    api.put(`message/${message.id}`, {
      ...message,
      messageType: { Automation: [] },
    }),
  ].concat(autos));
};

export const deleteMessage = async ({
  msgId, path
}: { msgId: number, path: string }) => {
  const pathWithId = path + '.msg_' + msgId;
  await api.delete(`paths/path/${pathWithId}`, { data: {} });
  const autos = await api.post('automations/query', {
    page: 1,
    perPage: 1,
    query: {
      action: {
        msgId,
      },
    },
  });
  if (autos.data[0]) {
    await api.delete(`automations/${autos.data[0].id}`);
  }
  return await api.delete(`message/${msgId}`);
};

export interface GetSentMessageType {
  msgId: number;
  page: number;
  perPage: number;
}

export interface SentMessageData {
  data: SentMessage[];
  totalPages: number;
  totalCount: number;
}

export const getSentMessages = ({
  msgId, page, perPage
}: GetSentMessageType): Promise<SentMessageData> => {
  return api.post('sentmsg/query', {
    page,
    perPage,
    query: {
      forMessage: msgId,
    }
  });
};

export const getPath =
  cond([
    [equals('SmartReply'), always('msg.rcr.smart_reply')],
    [equals('TimeBasedReminder'), always('msg.rcr.time_based')],
    [equals('Spark'), always('spark.0.msgs')],
    [T, always('')]
  ]);

/* Defaulting to spark is not technically correct but that's just
   how it'll be for now. :)
*/
export const guessMessageType = (
  automation: Automation
): AutomationMessageTypes => {
  if (has('MessageReceived', automation.trigger)) {
    return 'SmartReply';
  }
  if (has('Time', automation.trigger)) {
    return 'TimeBasedReminder';
  }
  return 'Spark';
};

export const niceName = (feature: string): string => cond([
  [equals('SmartReply'), always('Smart Reply Message')],
  [equals('TimeBasedReminder'), always('Set-Time Reminder Message')],
  [equals('Spark'), always('Spark Message')],
  [T, always('Automation-Based Message')],
])(feature);

