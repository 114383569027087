import {
  assoc, pipe, range, map, merge, reduce, sort, prop, ascend, propEq, find,
} from 'ramda';

import {
  filterAppointmentsByDayAndTypes,
  sortAppointmentsBySlot,
  getTimeStructures,
  getAppointmentPositions,
  getMinMaxTimes, withLocalTime,
  getTimeStructuresLocal
} from './sortAppointments';

import {
  getDateStartClosed, getDateStartClosedByDates,
  getSpecialHoursPositions, getScheduleHoursPositions
} from './officeHours';

import {
  LocalDate, LocalTime, ZoneId,
  ZoneOffset, DateTimeFormatter
} from '@js-joda/core';
import { Locale } from '@js-joda/locale_en-us';

const format = DateTimeFormatter.ofPattern('eee, MMM d').withLocale(Locale.US);

export const getDow = date => {
  let dow = LocalDate.parse(date).dayOfWeek().value();
  // Sunday be first day of week instead of last
  dow = dow === 7 ? 0 : dow;
  return dow;
};

export const getWeekRange = (date, timezone) => {
  const dow = getDow(date);

  const start = LocalDate
    .parse(date)
    .minusDays(dow)
    .atStartOfDayWithZone(ZoneId.of(timezone))
    .format(DateTimeFormatter.ISO_LOCAL_DATE_TIME);

  const end = LocalDate
    .parse(date)
    .minusDays(dow)
    .plusDays(7)
    .atStartOfDayWithZone(ZoneId.of(timezone))
    .format(DateTimeFormatter.ISO_LOCAL_DATE_TIME);

  return {
    after: start,
    before: end
  };
};

const getDayRange = (date, timezone) => {
  const start = LocalDate
    .parse(date)
    .atStartOfDayWithZone(ZoneId.of(timezone))
    .format(DateTimeFormatter.ISO_LOCAL_DATE_TIME);

  const end = LocalDate
    .parse(date)
    .plusDays(1)
    .atStartOfDayWithZone(ZoneId.of(timezone))
    .format(DateTimeFormatter.ISO_LOCAL_DATE_TIME);

  return {
    after: start,
    before: end
  };
};

export const getDateRange = (date, view, timezone) => {
  if (view === 'week') {
    return getWeekRange(date, timezone);
  }
  return getDayRange(date, timezone);
};

const groupAptsByProfessional = ({
  professionals,
  filteredAppointments,
  start,
  interval,
  cellHeight,
  cellWidth,
  colors
}) => {
  return pipe(
    sort(ascend(prop('lastName'))),
    map(pro => {
      const proApts = filteredAppointments.filter(apt =>
        apt.appointmentType.professionalId === pro.id);

      const { maxSlotId, sortedAppointments } = sortAppointmentsBySlot(proApts);

      const positionedAppointments = getAppointmentPositions({
        start,
        interval,
        appointments: sortedAppointments,
        cellHeight,
        cellWidth,
        colors,
      });

      const slotLength = sortedAppointments.length === 0 ? 1 : maxSlotId + 2;

      return {
        professional: pro,
        maxSlotId,
        slotLength,
        appointments: positionedAppointments,
        slots: Array.from(Array(slotLength).keys()),
      };
    }),
    reduce(({ data, slots }, p) => {
      p.startSlot = slots;
      return {
        data: data.concat([p]),
        slots: slots + Number(p.slots.length)
      };
    }, { data: [], slots: 0 })
  )(professionals);
};

const positionDayViewAppointments = ({
  filteredAppointments,
  start,
  interval,
  cellHeight,
  cellWidth,
  colors
}) => {
  const { maxSlotId, sortedAppointments } = sortAppointmentsBySlot(filteredAppointments);

  const positionedAppointments = getAppointmentPositions({
    start,
    interval,
    appointments: sortedAppointments,
    cellHeight,
    cellWidth,
    colors,
  });

  return {
    maxSlotId,
    positionedAppointments
  };
};

export const filterNewSchedule = ({
  date = '2020-10-20',
  timezone = 'America/New_York',
  interval = 15,
  scheduleId = 0,
  selectedProfessional = 0,
  selectedLocation = 0,
  groupByProfessional = false,
  appointmentTypes = [],
  professionals = [],
  appointments = [],
  specialOfficeHours = [],
  officeHours = [],
  schedules = [],
  colors = [],
  cellHeight = 25,
  cellWidth = 100,
}) => {
  const selectedSchedule = schedules.find(({ id }) => id === scheduleId) || {};

  const filteredAppointments = filterAppointmentsByDayAndTypes({
    types: selectedSchedule.appointmentType,
    professionalId: selectedProfessional,
    locationId: selectedLocation,
    date,
    timezone,
    appointments,
    professionals,
  });

  const { min, max } = getMinMaxTimes(filteredAppointments);

  const hours = scheduleId === -1 ? officeHours : selectedSchedule.hoursList.map(a => assoc('start', a.date, a));

  const { start, end } = getDateStartClosed({
    interval,
    selectedSchedule,
    selectedLocation,
    officeHours: hours,
    timezone,
    date,
    minAptTime: min,
    maxAptTime: max
  });

  const specialHours = getSpecialHoursPositions({
    specialHours: specialOfficeHours,
    dayStartTime: start,
    dayEndTime: end,
    date,
    timezone,
    interval,
    cellHeight,
    locationId: selectedLocation,
  });

  const scheduleHours = getScheduleHoursPositions({
    schedule: selectedSchedule,
    appointmentTypes,
    start,
    end,
    date,
    timezone,
    interval,
    cellHeight
  });

  const times = getTimeStructures({ start, end, interval, timezone });

  const defaultGroup = { data: [], slots: 0 };

  const groupedAptData = groupByProfessional ? groupAptsByProfessional({
    professionals,
    filteredAppointments,
    start,
    interval,
    cellHeight,
    cellWidth,
    colors
  }) : defaultGroup;

  const defaultSort = { maxSlotId: 0, positionedAppointments: [] };
  const { maxSlotId, positionedAppointments } = groupByProfessional ? defaultSort :
    positionDayViewAppointments({
      filteredAppointments,
      start,
      interval,
      cellHeight,
      cellWidth,
      colors
    });

  return {
    times,
    selectedSchedule,
    specialHours,
    scheduleHours,
    sortedAppointments: positionedAppointments,
    slots: Array.from(Array(maxSlotId + 2).keys()),
    groupedAppointments: groupedAptData.data,
    maxGroupedSlots: groupedAptData.slots,
    dayStart: start,
    dayEnd: end
  };
};

export const filterWeekSchedule = ({
  date,
  start,
  end,
  timezone = 'America/New_York',
  interval = 15,
  scheduleId = 0,
  locationId = 0,
  appointments = [],
  schedules = [],
  colors = [],
  cellHeight = 25,
  cellWidth = 100,
  professionalId = 0,
  professionals = []
}) => {
  const selectedSchedule = schedules.find(({ id }) => id === scheduleId) || {};

  const filteredAppointments = filterAppointmentsByDayAndTypes({
    professionalId,
    locationId,
    types: selectedSchedule.appointmentType,
    date,
    timezone,
    appointments,
    professionals
  });

  const { maxSlotId, sortedAppointments } = sortAppointmentsBySlot(filteredAppointments);
  const positionedAppointments = getAppointmentPositions({
    start,
    interval,
    appointments: sortedAppointments,
    cellHeight,
    cellWidth,
    colors
  });

  const slotLength = sortedAppointments.length === 0 ? 1 : maxSlotId + 2;

  return {
    date,
    selectedSchedule,
    sortedAppointments: positionedAppointments,
    slots: Array.from(Array(slotLength).keys()),
    dayStart: start,
    dayEnd: end
  };
};

export const calculateWeekPositions = ({
  cellHeight, cellWidth, scheduleId,
  officeHours, schedules, date, timezone,
  interval, colors, allAppointments, specialOfficeHours,
  professionalId, appointmentTypes, locationId, professionals
}) => {
  // Optimization timer
  const start = new Date().getTime();

  const dow = getDow(date);

  const localStartDate = LocalDate
    .parse(date)
    .minusDays(dow);

  const localDates = pipe(
    range(0),
    map(i => localStartDate.plusDays(i).toString())
  )(7);

  const { min, max } = getMinMaxTimes(allAppointments.map(withLocalTime(timezone)));

  const selectedSchedule = schedules.find(({ id }) => id === scheduleId) || {};

  // If a schedule is selected use the hours on the
  // schedule to set the limits to show the start and
  // end of the calendar - if no schedule is selected
  // just use the office hours
  const hours = scheduleId === -1 ? officeHours : selectedSchedule.hoursList.map(a => assoc('start', a.date, a));

  const startEnd = getDateStartClosedByDates({
    officeHours: hours,
    dates: localDates,
    minAptTime: min,
    maxAptTime: max
  });

  const utcStart = localStartDate
    .atTime(LocalTime.parse(startEnd.start))
    .atZone(ZoneId.of(timezone))
    .withZoneSameInstant(ZoneOffset.UTC);

  const utcEnd = localStartDate
    .atTime(LocalTime.parse(startEnd.end))
    .atZone(ZoneId.of(timezone))
    .withZoneSameInstant(ZoneOffset.UTC);

  const timeLabels = getTimeStructuresLocal({
    start: startEnd.start,
    end: startEnd.end,
    interval
  });

  const days = pipe(
    range(0),
    map((day = 0) => {
      const dayStart = utcStart.plusDays(day).toString();
      const dayEnd = utcEnd.plusDays(day).toString();
      const localDate = localStartDate.plusDays(day);
      const dayAppointments = allAppointments.filter(({ time }) => time >= dayStart && time < dayEnd);
      const dateString = localDate.format(format);

      const specialHours = getSpecialHoursPositions({
        date: localDate.toString(),
        specialHours: specialOfficeHours,
        dayStartTime: dayStart,
        dayEndTime: dayEnd,
        timezone, interval, cellHeight,
        locationId
      });

      const scheduleHours = getScheduleHoursPositions({
        schedule: selectedSchedule,
        start: dayStart,
        end: dayEnd,
        date: localDate.toString(),
        timezone, interval, cellHeight,
        appointmentTypes
      });

      const positionData = filterWeekSchedule({
        professionalId,
        professionals,
        date: localDate.toString(),
        start: dayStart,
        end: dayEnd,
        appointments: dayAppointments,
        timezone, interval, scheduleId,
        schedules, cellHeight, cellWidth, colors,
        locationId
      });

      return merge(positionData, { dateString, specialHours, scheduleHours });
    }),
    reduce(({ data, slots }, p) => {
      p.startSlot = slots;
      return {
        data: data.concat([p]),
        slots: slots + Number(p.slots.length)
      };
    }, { data: [], slots: 0 })
  )(7);

  // Optimization timer code - how long does it take?
  const end = new Date().getTime();
  const aptCt = allAppointments.length;
  const dur = (end - start);
  const rate = (aptCt / dur).toFixed(4);
  console.log(`time to calc new positions: ${dur}ms for ${aptCt} apts. Rate: ${rate} apts/ms`);

  return {
    days: days.data,
    timeLabels,
    maxSlots: days.slots,
    width: days.slots * cellWidth,
    height: timeLabels.length * cellHeight
  };
};

export const calcColor = type => 'hsl(' + type.color.hue + ',' + type.color.saturation + '%,' + type.color.lightness + '%)';

export const findDefaultColor = (type, colors) => pipe(
  find(propEq('name', type.internalName)),
  c => ({ ...c, name: type.name, internalName: type.internalName })
)(colors);

export const calcDefaultColor = (type, colors) => {
  const defaultColor = findDefaultColor(type, colors);
  const colorStr = `hsl(${defaultColor.color} 80%)`;
  return colorStr;
};

const textColors = [
  {
    key: 'Scheduled',
    name: 'Scheduled',
    color: 'black'
  },
  {
    key: 'Scheduled',
    name: 'Scheduled (on dark type colors)',
    color: 'white'
  },
  {
    key: 'Arrived',
    name: 'Arrived',
    color: '#81c784'
  },
  {
    key: 'Missed',
    name: 'Missed',
    color: '#e33371'
  },
  {
    key: 'Preview',
    name: 'Preview',
    color: '#4791db'
  }
];

export const getStatusColor = apt => {
  const tc = textColors.find(({ key }) => apt.status[key]);
  return tc ? tc.color : 'black';
};
