import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  dashboardListContainer: {
    display: 'flex',
    flexDirection: 'row',

    '& svg.success': {
      color: theme.palette.success.main
    },
    '& svg.pending': {
      color: theme.palette.warning.main
    },
    '& svg.error': {
      color: theme.palette.error.main
    },
  },
  resizeHandle: {
    top: 0,
    left: 0,
    width: 3,
    height: '100%',
    position: 'absolute',
    borderRadius: 3,
    zIndex: 10,
    
    '&:hover': {
      background: '#dce3e7'
    }
  },
  listRoot: {
    paddingTop: '0px',
    background: '#FFF',
    overflowY: 'auto',
    borderRight: '1px solid rgba(0, 0, 0, 0.2)',
    scrollbarWidth: 'none',
    paddingBottom: 8,
    boxSizing: 'border-box',
    
    '&::-webkit-scrollbar': {
      width: '0px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderRadius: '8px',
    },
  },
  onHover: {
    scrollbarWidth: 'thin',

    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(206, 206, 206)',
      borderRadius: ' 8px',

      '&:hover': {
        backgroundColor: 'gray',
        borderRadius: '8px',
      },
    },
  },
  listContainer: {
    width: '100%',
    minWidth: 220,
  },
  gridHeader: {
    backgroundColor: '#F5F5F5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRight: '1px solid rgba(0, 0, 0, 0.2)',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    background: '#FFF',
    borderRadius: 6,
    marginTop: 8,
    marginLeft: 5,
    marginRight: 6,
    padding: '6px 10px',
    width: 'auto',
    border: '0.5px solid rgba(0,0,0,0.1)',
    boxShadow: '1px 1px 3px 1px rgba(0,0,0,0.1)',
  },
  cardText: {
    color: '#555555',
    fontSize: 12,
    lineHeight: '16x',
  },
  cardText2: {
    color: '#2c2c2c',
    fontSize: 12,
    lineHeight: '16px',
  },
  cardTitle: {
    color: '#1f1f1f',
    fontSize: 14,
    fontWeight: 450,
    lineHeight: '18px',
    margin: 0,
    marginBottom: 3,
  },
  iconContainer: {
    '& svg': {
      fontSize: 18,
      marginRight: 5,
    }
  },
  icon: {
    width: 17,
    height: 17,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  sectionContainer: {
    cursor: 'pointer',
    paddingBottom: 5,
    paddingTop: 5,
    background: '#FFF',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',

    '& svg': {
      fontSize: 20,
    },
  },
  sectionChip: {
    background: 'linear-gradient(45deg, hsla(200, 100%, 41%, 1) 0%, hsla(200, 100%, 45%, 1) 72%, hsla(200, 100%, 50%, 1) 100%)',
    marginLeft: 5,
    color: '#FFF',
    padding: 2,
  },
  chip: {
    marginLeft: 5,
    padding: 2,
    color: '#FFF',
    background: '#bdbdbd',
  },
  roomTitle: {
    padding: '6px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#F5F5F5',
  },
  emptyContainer: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  time: {
    fontSize: 12,
    fontWeight: 450,
    color: 'gray',
  },
  nextAppt: {
    paddingLeft: 10,
    textIndent: -10,
  }
}));
