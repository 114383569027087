import React from 'react';
import {
  Dialog,
  Typography,
  Grid,
} from '@mui/material';

import HeaderButton from '../HeaderButton/HeaderButton.component';

import { useStyles } from './discardDialog.styles';

type Props = {
  title: string;
  text: string;
  open: boolean;
  onConfirm?: () => void;
  onCancel: () => void;
  onDiscard: () => void;
}

const DiscardDialog = ({ title, text, open, onConfirm, onCancel, onDiscard }: Props) => {
  const classes = useStyles();
  return (
    <Dialog
      fullWidth={true}
      maxWidth="xs"
      open={open}
      onClose={onCancel}
      classes={{ paper: classes.paper }}
    >
      <Grid paddingX={2} className={classes.header}>
        <Typography className={classes.title}>{title}</Typography>
        <Grid display="flex" alignItems="center">
          <HeaderButton title='Discard' borderSolid onClick={onDiscard} />
          {onConfirm && (
            <>
              &nbsp;
              <HeaderButton color='primary' title='Save' onClick={onConfirm} />
            </>
          )}
        </Grid>
      </Grid>
      <Grid paddingX={2} paddingY={1} marginBottom={1}>
        <Typography>{text}</Typography>
      </Grid>
    </Dialog>
  );
};

export default DiscardDialog;
