import React from 'react';

import { Tooltip, Paper } from '@mui/material';

import {
  Close as CloseIcon,
  Link as LinkIcon,
  KeyboardArrowLeft as ArrowLeft,
  KeyboardArrowRight as ArrowRight,
} from '@mui/icons-material';

import { popupWithCancel } from '../../../services/Popup';

import { useStyles } from './type-header.styles';

type TypeHeaderProps = {
  id: number;
  name: string;
  nameEdit: number;
  index: number;
  nameRef: React.RefObject<HTMLInputElement>;
  localPatch: (param: string, index?: number) => void;
  skedPatch: (param: string, value: string, index: number) => void;
  removeWithoutId: (index: number) => void;
  remove: (id: number) => void;
  changeOrder: (index: number, to: number) => void;
  getHours: () => void;
  hasLinkedScheduleFeature: boolean;
};

const TypeHeader = React.forwardRef(({
  id,
  name,
  nameEdit,
  index,
  nameRef,
  localPatch,
  skedPatch,
  removeWithoutId,
  remove,
  changeOrder,
  getHours,
  hasLinkedScheduleFeature,
}: TypeHeaderProps, ref: React.RefObject<HTMLDivElement>) => {
  const classes = useStyles();

  const moveTo = (destiny: number) => {
    changeOrder(index, destiny);
  };

  if (id === 0) {
    return (
      <Paper className={classes.root} ref={ref}>
        <h4 className={classes.title}>{name}</h4>
      </Paper>
    );
  }
  return (
    <Paper className={classes.root} ref={ref}>
      <div className={classes.container}>
        {nameEdit === index ? (
          <input
            type='text'
            ref={nameRef}
            value={name}
            className={classes.size18}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                localPatch('nameEdit', undefined);
              }
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.preventDefault();
              skedPatch('name', e.target.value, index);
            }}
            onBlur={() => localPatch('nameEdit', undefined)}
          />
        ) : (
          <h4
            className={classes.size18}
            onClick={() => localPatch('nameEdit', index)}
          >
            {name}
          </h4>
        )}
        <div>
          <Tooltip title='Delete Schedule' placement='top' arrow>
            <CloseIcon
              className={classes.closeIcon}
              onClick={() =>
                popupWithCancel(
                  'Verification',
                  'Are you sure you want to delete this schedule?',
                  id === undefined
                    ? () => removeWithoutId(index)
                    : () => remove(id),
                  'danger'
                )
              }
            />
          </Tooltip>
        </div>
      </div>
      <div className={classes.arrowContainer}>
        {index !== 1 ? (
          <Tooltip title='Move left' placement='bottom' arrow>
            <ArrowLeft
              className={classes.icon}
              onClick={() => moveTo(index - 1)}
            />
          </Tooltip>
        ) : <div />}
        <div>
          {hasLinkedScheduleFeature && (
            <Tooltip title='Link Schedules' placement='top' arrow>
              <LinkIcon className={classes.icon} onClick={getHours} />
            </Tooltip>
          )}
          <Tooltip title='Move right' placement='bottom' arrow>
            <ArrowRight
              className={`${classes.icon} ${classes.noMargin}`}
              onClick={() => moveTo(index + 1)}
            />
          </Tooltip>
        </div>
      </div>
    </Paper>
  );
});

export default TypeHeader;
