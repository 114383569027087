import React from 'react';
import {
  Input,
  Checkbox,
  IconButton,
  Select,
  MenuItem,
  TableHead,
  TableRow,
  TableBody,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import * as R from 'ramda';
import api from '../../../services/api.js';
import { PopupTemplate, popup } from '../../../services/Popup.js';
import { TableContainer, HeaderCell, BodyCell } from '../../../components/CustomTable';
import Modal from '../../../components/Modal/Modal.component';
import HeaderButton from '../../../components/HeaderButton/HeaderButton.component';

const mapIndexed = R.addIndex(R.map);

const showPro = pro => {
  const showName = pro.firstName || pro.lastName;
  const name = ` (${pro.firstName || ''} ${pro.lastName || ''})`;
  const displayName = `${pro.displayFirstName || ''} ${pro.displayLastName || ''}`;
  return displayName + (showName ? name : '');
};

export const AddTypes = props => {
  const filteredProfessionals = props.professionals
    .filter(p => !p.isHidden && p.id > 0);

  const defaultProfessionalId = props.professionalId === -1 && filteredProfessionals && filteredProfessionals.length > 0 ? filteredProfessionals[0].id : props.professionalId;

  const defaultType = {
    name: '',
    internalName: '',
    duration: 15,
    notAllowedMinutesBeforeClosing: undefined,
    professionalId: defaultProfessionalId,
    canCancel: true,
    canChange: true,
    canSee: true,
    partOfCarePlan: false,
  };

  React.useEffect(() => {
    setState({ types: [defaultType] });
  }, [defaultProfessionalId]);


  const [state, setState] = React.useState({
    types: [defaultType]
  });

  const defaultCb = e => e.target.value;

  const updateItem = (index, prop, cb = defaultCb) => e => {
    const value = cb(e);
    const path = R.compose(
      R.lensProp('types'),
      R.lensIndex(index),
      R.lensProp(prop)
    );
    setState(R.set(path, value));
  };

  const updateChecked = (index, prop) => {
    const getValue = e => e.target.checked;
    return updateItem(index, prop, getValue);
  };

  const updateNumber = (index, prop) => {
    const getValue = e => Number(e.target.value);
    return updateItem(index, prop, getValue);
  };

  const removeItem = (index) => {
    setState(R.evolve({
      types: R.remove(index, 1)
    }));
  };

  const handleAddApptType = () => {
    setState(R.evolve({
      types: R.append(defaultType)
    }));
  };

  const addIfLast = idx => {
    if (idx === state.types.length - 1) {
      handleAddApptType();
    }
  };

  const disabled = !R.reduce((acc, item) => {
    return acc && item.duration > 0;
  }, true, state.types);

  const handleOnSave = () => {
    const data = R.filter(({ internalName, name }) => !R.isEmpty(internalName) && !R.isEmpty(name), state.types);
    api.post('appointmentType/', data).then(() => {
      props.save();
      props.onClose();
      setState({ types: [defaultType] });
    }).catch((error) => {
      console.log(error);
      popup('Error!', 'Failed to create appointment types! Verify all fields and try again!');
    });
  };

  const handleOnClose = () => {
    props.onClose();
    setState({ types: [defaultType] });
  };


  const typeItem = (type, idx) => {
    const {
      name,
      internalName,
      duration,
      notAllowedMinutesBeforeClosing,
      canCancel,
      canChange,
      canSee,
      partOfCarePlan,
      professionalId
    } = type;
    return (
      <TableRow key={idx}>
        <BodyCell>
          <Input
            value={internalName}
            onChange={updateItem(idx, 'internalName')}
            onBlur={(e) => name === '' ? updateItem(idx, 'name')(e) : null}
            required
          />
        </BodyCell>
        <BodyCell>
          <Input
            value={name}
            onChange={updateItem(idx, 'name')}
            required
          />
        </BodyCell>
        <BodyCell>
          <Select
            value={professionalId}
            variant='standard'
            onChange={updateItem(idx, 'professionalId')}>
            {filteredProfessionals.map(pro => (
              <MenuItem
                key={pro.id} value={pro.id} style={{ color: pro.isHidden ? '#AAA' : '#000' }}>
                {showPro(pro)}
              </MenuItem>
            ))}
          </Select>
        </BodyCell>
        <BodyCell>
          <Input
            value={duration}
            type='number'
            onChange={updateNumber(idx, 'duration')}
            required
          />
        </BodyCell>
        <BodyCell>
          <Input
            value={notAllowedMinutesBeforeClosing}
            type='number'
            onChange={updateNumber(idx, 'notAllowedMinutesBeforeClosing')}
          />
        </BodyCell>
        <BodyCell>
          <Checkbox
            checked={canCancel}
            type='checkbox'
            onChange={updateChecked(idx, 'canCancel')}
          />
        </BodyCell>
        <BodyCell>
          <Checkbox
            checked={canChange}
            type='checkbox'
            onChange={updateChecked(idx, 'canChange')}
          />
        </BodyCell>
        <BodyCell>
          <Checkbox
            checked={canSee}
            type='checkbox'
            onChange={updateChecked(idx, 'canSee')}
          />
        </BodyCell>
        <BodyCell>
          <Checkbox
            checked={partOfCarePlan}
            type='checkbox'
            onChange={updateChecked(idx, 'partOfCarePlan')}
            onFocus={() => addIfLast(idx)}
          />
        </BodyCell>
        <BodyCell>
          <IconButton
            tabIndex='-1'
            onClick={() => removeItem(idx)}>
            <DeleteIcon />
          </IconButton>
        </BodyCell>
      </TableRow>
    );
  };

  const table = ({
    types,
  }) => {
    return (
      <TableContainer style={{ overflowY: 'auto' }}>
        <TableHead>
          <TableRow>
            <HeaderCell>
              Internal Name
            </HeaderCell>
            <HeaderCell>
              Display Name
            </HeaderCell>
            <HeaderCell>
              Professional
            </HeaderCell>
            <HeaderCell>
              Duration
            </HeaderCell>
            <HeaderCell>
              Closing Limit
            </HeaderCell>
            <HeaderCell>
              Can Cancel
            </HeaderCell>
            <HeaderCell>
              Can Reschedule
            </HeaderCell>
            <HeaderCell>
              Can See
            </HeaderCell>
            <HeaderCell>
              On Care Plan
            </HeaderCell>
            <HeaderCell>

            </HeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mapIndexed((d, idx) => typeItem(d, idx), types)}
        </TableBody>
      </TableContainer>
    );
  };

  return (
    <Modal
      open={props.open}
      onClose={handleOnClose}
      title='Add appointments types'
      size='xl'
      className='sked-test-appt-types-add-appt-type-modal'
      buttons={[
        <HeaderButton
          onClick={handleAddApptType}
          borderSolid
          Icon={AddIcon}
          iconStyle={{ marginRight: -6 }}
          title='Add'
          className='sked-test-appt-types-add-appt-type-modal-button-add'
        />,
        <HeaderButton
          title='Save'
          disabled={disabled}
          type="submit"
          onClick={handleOnSave}
          color='primary'
          className='sked-test-appt-types-add-appt-type-modal-button-save'
        />
      ]}
    >
      <div>
        <PopupTemplate />
        <p style={{ marginTop: '10px' }}>
          To make process as fast as possible, use the "Tab" key to jump from input field to input field. To toggle a checkbox, use the "Space" key. Once you reach the last field (On Care Plan), a new row will appear and you can use the "Tab" key to jump down to it. If you do <b>NOT</b> want an appointment type to have a closing limit <i>leave the input field blank</i>.
        </p>
        {table(state)}
      </div>
    </Modal>
  );
};
