import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { match as Match } from 'react-router-dom';
import {
  CircularProgress, Typography, IconButton, Snackbar,
  Tooltip, TableHead, TableRow, TableBody, Alert, Grid
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PrintIcon from '@mui/icons-material/Print';
import { UploadIcon } from '../../icons/CustomIcons';
import HistoryIcon from '@mui/icons-material/History';
import CheckIcon from '@mui/icons-material/Check';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { Row } from '../../components/PageHeader';
import Header from '../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import Loading from '../../components/Loading/Loading.component';
import Modal from '../../components/Modal/Modal.component';
import { TableContainer, HeaderCell, BodyCell } from '../../components/CustomTable';
import { HelpDialog, useHelpDialog } from '../../components/Help/Help.component';
import { History } from 'history';
import { usePromise } from './../../services/promise.hook';
import {
  fetchSubmission, SubmissionResponse, PdfGen, getPdfs,
  generatePdf, pushPdfToEhr, initPdfListener, closePdfEvents, getSettingsForm,
  saveSettings, GlobalSettings, makeNewOneTimeToken, revisionToForm,
} from './intake.service';
import { FormChild } from './intake-types';
import { isEmpty, pathOr, includes, } from 'ramda';
import { AnswerFormChild } from './components/SubmissionFormChild.component';
import { AnswerContextProvider, useAnswerContext } from './answers.context';
import { useTitle } from '../../services/useTitle';
import {
  tzParseFormat,
  now,
} from '../../services/joda.js';
import { useSelector } from '../../reducers';
import { patch } from '../Login/login.actions';
import { encryptKey } from '../../services/utilities';
import { checkPinAndLock } from './lock.component';
import { PopupTemplate } from '../../services/Popup.js';
import '../../styles/print.css';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '20px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& .MuiInputLabel-shrink': {
      lineHeight: '16px',
    },
    '& .Mui-disabled': {
      '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.87)'
    },
    '@media print': {
      '& .MuiInputLabel-shrink': {
        lineHeight: '14px',
      }
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  content: {
    width: '100%',
    maxWidth: '932px',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: '50px',
  },
  uploadWarningRow: {
    justifyContent: 'flex-end',
  },
  infoDiv: {
    '& p': {
      all: 'revert',
    },
  },
  imageContainer: {},
  logoContainer: {
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },
  },
  '@media screen and (max-width: 600px)': {
    logoContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center !important',
      flexDirection: 'column !important',
    },
    imageContainer: { width: '100% !important' },
    infoDiv: {
      width: '100% !important',
      '& p': {
        all: 'revert',
      },
    }
  },
}));

interface PdfHistoryDialogProps {
  open: boolean;
  onClose: () => void;
  name: string;
  id: number;
  upload?: boolean;
  timezone: string;
}

const PdfHistoryDialog = ({ open, onClose, name, id, upload, timezone }: PdfHistoryDialogProps) => {
  const [snackbar, setSnackbar] = useState(null);
  const pdfState = usePromise<number, PdfGen[]>(getPdfs, []);
  const ehrState = usePromise<number, void>(pushPdfToEhr, null);
  useEffect(() => {
    if (open) {
      pdfState.invoke(id);
    }
  }, [open]);
  const updatePhis = (pdf: PdfGen) => () => {
    const t = now('tz', timezone);
    pdfState.setState({
      ...pdfState,
      data: pdfState.data.map((p) => {
        if (p.phiId === pdf.phiId) {
          return {
            ...p,
            uploaded: t.toString(),
          };
        }
        return p;
      }),
    });
    setSnackbar('Successfully pushed PDF to EHR!');
  };
  const downloadPdf = (url: string) => {
    window.open(url, '_blank');
  };
  const uploadToEhr = (pdf: PdfGen) => {
    ehrState.invoke(pdf.phiId).then(updatePhis(pdf));
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      title={`PDF History for ${name}`}
      buttons={[(ehrState.loading || pdfState.loading) && <CircularProgress size={18} />]}
      className='sked-test-intake-submission-pdf-history-modal'
    >
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={Boolean(snackbar)}
          autoHideDuration={5e3}
          onClose={() => setSnackbar(null)}
        >
          <div>
            <Alert
              style={{ fontSize: '16px' }}
              severity="success">
              {snackbar}
            </Alert>
          </div>
        </Snackbar>
        {!pdfState.loading && isEmpty(pdfState.data) ?
          <div>No PDFs have been generated for this submission.</div>
          :
          <Grid marginX={-2} marginTop={-2}>
            <TableContainer>
              <TableHead>
                <TableRow>
                  <HeaderCell>
                      PDF Created
                  </HeaderCell>
                  {upload &&
                      <HeaderCell align='center'>
                        Upload to EHR
                      </HeaderCell>}
                  <HeaderCell align='center'>
                      View PDF
                  </HeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pdfState.data.map((pdf: PdfGen) => {
                  const { created, phiId, url, uploaded } = pdf;
                  return (
                    <TableRow key={phiId}>
                      <BodyCell component="th" scope="row" style={{
                        width: '50%',
                      }}>
                        <div style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}>
                          {tzParseFormat(created, timezone, 'MM-dd-yyyy h:mm a')}
                            &nbsp;
                          {uploaded &&
                              <Tooltip
                                arrow
                                title={'Uploaded: ' + tzParseFormat(uploaded, timezone, 'MM-dd-yyyy h:mm a')}
                                placement='top'
                              >
                                <CheckIcon style={{ color: 'green' }} />
                              </Tooltip>}
                        </div>
                      </BodyCell>
                      {upload &&
                          <BodyCell align='center'>
                            <IconButton onClick={() => uploadToEhr(pdf)}>
                              <UploadIcon />
                            </IconButton>
                          </BodyCell>}
                      <BodyCell align='center'>
                        <IconButton onClick={() => downloadPdf(url)}>
                          <OpenInNew />
                        </IconButton>
                      </BodyCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </TableContainer>
          </Grid>
        }
      </div>
    </Modal>
  );
};

interface MatchParams {
  id?: string;
}

interface IntakeSubmissionsProps {
  history: History,
  match: Match<MatchParams>,
  isUpdated: boolean,
}


let showingPage = true;
const IntakeSubmissionInner = ({
  history, match, isUpdated
}: IntakeSubmissionsProps) => {
  useTitle('Form Submission');
  const dispatch = useDispatch();
  const ctx = useAnswerContext();
  const classes = useStyles();
  const helpState = useHelpDialog();
  const subState = usePromise<number, SubmissionResponse>(fetchSubmission, {
    revId: 0, answers: [], isComplete: false, uploaded: null,
    updated: null, officeNotes: [], revision: null, submitted: null,
  });
  const pdfState = usePromise<number, PdfGen[]>(getPdfs, []);
  const generateState = usePromise<number, void>(generatePdf, null);
  const ehrState = usePromise<number, void>(pushPdfToEhr, null);
  const getSettingsState = usePromise<void, GlobalSettings>(getSettingsForm, null);
  const newOneTimeState = usePromise<number, string>(makeNewOneTimeToken, null);
  const saveState = usePromise<GlobalSettings, GlobalSettings>(saveSettings, null);
  const [printMode, setPrintMode] = useState(false);
  const [action, setAction] = useState<'download' | 'upload'>(null);
  const [phi, setPhi] = useState<PdfGen>(null);
  const [snackbar, setSnackbar] = useState<string>(null);
  const [oneTimeToken, setOneTimeToken] = useState(null);
  const [locked, setLocked] = useState<string>(null);
  const [w, setWindow] = useState<Window>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [pdfHistory, setPdfHistory] = useState<boolean>(false);
  const [subBypass, setSubBypass] = useState(false);
  const [dInd, setDInd] = React.useState(0);
  const [form, setForm] = React.useState(null);

  const {
    timezone,
    canUpload,
    hasPdf,
  } = useSelector((state) => ({
    timezone: state.login.office.timezone,
    canUpload: !includes(state.login.office.ehrSystem, ['None', 'ChiroHD']),
    hasPdf: includes(
      'FormsPdf',
      pathOr([], ['login', 'features'], state))
  }));

  const unlisten = history.listen(() => {
    ctx.autoSave();
  });

  useEffect(() => {
    if (match.params.id) {
      subState.invoke(Number(match.params.id)).then((subling) => {
        const {
          answers, officeNotes, revision
        } = subling;
        const f = revisionToForm(revision);
        setForm(f);
        ctx.setAll(answers, f, subling);
        ctx.setNotes(officeNotes);
        ctx.setSubId(Number(match.params.id));
      });
      // pdfState.invoke(Number(match.params.id));
      getSettingsState.invoke();
      if (hasPdf) {
        if (!initPdfListener(setPhi, 'FormPdfCreated')) {
          setTimeout(() => {
            initPdfListener(setPhi, 'FormPdfCreated');
          }, 500);
        }
      }
    }
    return () => {
      ctx.wipeRenderData();
      closePdfEvents();
      unlisten();
    };
  }, []);

  const data = form;

  const hasNewRevision = useMemo(() => {
    if (!subState.data?.uploaded) {
      return true;
    }
    return subState.data?.uploaded < subState.data?.updated || isUpdated;
  }, [subState.data, isUpdated]);

  const handlePrint = () => {
    setPrintMode(true);
    setTimeout(() => {
      new Promise((res) => {
        res(window.print());
      }).then(() => setPrintMode(false));
    }, 200);
  };

  const getLatestPdf = () => {
    setSubBypass(true);
    pdfState.invoke(Number(match.params.id)).then((pdfs) => {
      if (isEmpty(pdfs)) {
        generateState.invoke(Number(match.params.id)).then(() => {
          setTimeout(() => {
            fetchSubmission(Number(match.params.id)).then((data) => {
              subState.setState({
                ...subState,
                data,
              });
            });
          }, 5000);
        });
      } else {
        setPhi(pdfs[0]);
      }
    });
  };

  const handleDownloadNew = () => {
    ctx.autoSave(true);
    setWindow(window.open(`${process.env.APP_URL}/#/loading`, '_blank'));
    setAction('download');
    setLoading(true);
    if (hasNewRevision) {
      generateState.invoke(Number(match.params.id)).then(() => {
        setTimeout(() => {
          fetchSubmission(Number(match.params.id)).then((data) => {
            subState.setState({
              ...subState,
              data,
            });
          });
        }, 5000);
      });
    } else {
      getLatestPdf();
    }
  };

  const updatePhis = () => {
    setLoading(false);
    setSnackbar('Successfully pushed PDF to EHR!');
  };

  useEffect(() => {
    if (phi && (phi.submissionId === Number(match.params.id) || subBypass)) {
      setSubBypass(false);
      ctx.setIsUpdated(false);
      if (action === 'download') {
        w.location = phi.url;
        w.focus();
        setLoading(false);
      } else {
        ehrState.invoke(phi.phiId).then(updatePhis).catch(() => {
          setLoading(false);
        });
      }
    }
  }, [phi]);

  useEffect(() => {
    if (dInd < data?.children.length || 0)
      setTimeout(() => {
        setDInd(dInd + 2);
      }, 50);
  }, [dInd, data]);

  const handleEhr = () => {
    setAction('upload');
    setLoading(true);
    if (hasNewRevision) {
      generateState.invoke(Number(match.params.id));
    } else {
      getLatestPdf();
    }
  };

  const {
    logoContainerStyle,
    infoStyle,
  } = React.useMemo(() => {
    switch (data?.logoAlignment) {
      case 'Left': {
        return ({
          logoContainerStyle: {
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
          },
          imageDiv: {
            width: '50%'
          },
          imageStyle: {
            marginRight: '5px',
            marginBottom: '5px',
          },
          infoStyle: {
            width: '50%',
            marginLeft: '5px',
            marginBottom: '5px',
          }
        });
      }
      case 'Right': {
        return ({
          logoContainerStyle: {
            justifyContent: 'space-between',
            flexDirection: 'row-reverse' as const,
            alignItems: 'flex-end',
          },
          imageDiv: {
            width: '50%'
          },
          imageStyle: {
            marginLeft: '5px',
            marginBottom: '5px',
          },
          infoStyle: {
            width: '50%',
            marginRight: '5px',
            marginBottom: '5px',
          }
        });
      }
      case 'Center':
      default: {
        return ({
          logoContainerStyle: {
            alignItems: 'center',
            flexDirection: 'column' as const,
          },
          imageDiv: {
            width: '75%',
            textAlign: 'center' as const,
          },
          imageStyle: {
            marginBottom: '5px',
          },
          infoStyle: {
            width: '100%',
            marginTop: '5px',
          }
        });
      }
    }
  }, [data?.logoAlignment]);

  useEffect(() => {
    if (oneTimeToken) {
      const url = `${process.env.PORTAL_URL}/intake-office?token=${oneTimeToken}&id=${Number(match.params.id)}`;
      console.log(url);
      w.location = url;
      w.focus();
    }
  }, [oneTimeToken]);

  useEffect(() => {
    if (locked) {
      encryptKey(locked);
      dispatch(patch({ locked: true }));
    }
  }, [locked]);

  const finishInOffice = () => {
    checkPinAndLock({
      subId: Number(match.params.id),
      openWindow: true,
      saveState,
      newOneTimeState,
      setOneTimeToken,
      setLocked,
      w,
      setWindow,
      getSettingsState,
    });
  };

  showingPage = true;

  return (
    <>
      <Header
        title='Form Submission'
        // pageId="form-submission"
        rightIcons={[
          (ehrState.loading || loading) && <CircularProgress style={{ marginRight: 10 }} size={18} />,
          !subState.data?.isComplete &&
          <HeaderButton title='Finish In-Office' onClick={finishInOffice} Icon={OpenInNew} borderSolid />,
          hasPdf && canUpload &&
          <HeaderButton title='Upload to EHR' onClick={handleEhr} Icon={UploadIcon} borderSolid disabled={loading || ehrState.loading} />,
          hasPdf &&
          <HeaderButton title='View PDF' onClick={handleDownloadNew} Icon={OpenInNew} borderSolid disabled={loading} />,
          !hasPdf &&
          <Tooltip arrow placement='bottom' title='Print'>
            <IconButton onClick={handlePrint} style={{ marginRight: 5 }}>
              <PrintIcon style={{ width: 17, height: 17 }} />
            </IconButton>
          </Tooltip>,
          hasPdf &&
          <Tooltip arrow placement='bottom' title='PDF History'>
            <IconButton
              disabled={loading}
              onClick={() => setPdfHistory(true)}>
              <HistoryIcon style={{ width: 17, height: 17 }} />
            </IconButton>
          </Tooltip>,
        ]}
        onlyIconsWidth={670}
      />
      <div className={classes.root}>
        <PopupTemplate />
        <HelpDialog
          pageId="form-submission"
          handleClose={helpState.handleClose}
          open={helpState.isOpen} />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={Boolean(snackbar)}
          autoHideDuration={5e3}
          onClose={() => setSnackbar(null)}
        >
          <div>
            <Alert
              style={{ fontSize: '16px' }}
              severity="success">
              {snackbar}
            </Alert>
          </div>
        </Snackbar>
        <PdfHistoryDialog
          open={pdfHistory}
          onClose={() => setPdfHistory(false)}
          name={data?.name}
          id={Number(match.params.id)}
          upload={canUpload}
          timezone={timezone}
        />

        {data?.pdfEhr === 'OnGeneration' && hasNewRevision &&
          <Row className={classes.uploadWarningRow}>
            <b>
              ⚠️ Form updated! Viewing this PDF will upload the updated form to your EHR.
            </b>
          </Row>
        }
        {subState.loading && <Loading loading />}
        {!subState.loading && !isEmpty(subState.data) && data !== null &&
          <div className={!printMode ? classes.content : ''}>
            <Typography variant='h3' component='h2' gutterBottom>
              {data.name}
            </Typography>
            {data.logoUrl &&
              <div
                className={classes.logoContainer}
                style={{
                  width: '100%',
                  display: 'flex',
                  ...logoContainerStyle,
                }}>
                <img src={data.logoUrl}/>
                <div
                  style={{
                    ...infoStyle,
                  }}
                  className={classes.infoDiv}
                  dangerouslySetInnerHTML={{ __html: data.info }} />
              </div>}
            {data
              .children
              .map((child: FormChild, i: number) => {
                if (i > dInd)
                  return null;
                if (child.type === 'PageBreak') {
                  const show = ctx.checkCondition('Page', child.id);
                  showingPage = show;
                }
                if (!showingPage) {
                  return (<div></div>);
                }
                return (
                  <AnswerFormChild
                    key={i}
                    child={child}
                    printMode={printMode}
                    subId={Number(match.params.id)}
                  />
                );
              })}
          </div>}
      </div>
    </>
  );
};

const IntakeSubmission = ({ history, match }: IntakeSubmissionsProps) => {
  const [isUpdated, setIsUpdated] = useState(false);

  return (
    <AnswerContextProvider setIsUpdated={setIsUpdated}>
      <IntakeSubmissionInner
        match={match}
        history={history}
        isUpdated={isUpdated}
      />
    </AnswerContextProvider>
  );
};

export default IntakeSubmission;
