import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    padding: 20,
    margin: 20,
    minHeight: 200,
  },
  shortCard: {
    width: '75%',
    minWidth: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    margin: 0,
    padding: 0,
    fontWeight: 400,
    fontSize: 20,
  },
  tdName: {
    width: '55%'
  },
  tdId: {
    width: 50,
  }
}));
