import { makeStyles } from '@mui/styles';

export const style = {
  base: {
    color: '#282E38',
    fontFamily: 'Poppins, "Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#afafaf'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
};

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: 40,
    paddingTop: 30,
    overflowY: 'auto',
    background: '#FFF',
  },
  content: {
    width: '100%',
    maxWidth: 1000,
  },
  info: {
    fontSize: 16,
    color: '#566063',

    '& span': {
      fontWeight: 600,
    }
  },
  sparkText: {
    color: '#FA531B',
    fontWeight: 700,
    fontSize: 47,
    marginTop: 5,
    marginBottom: 8,
  },
  skedText: {
    color: '#3099E5',
    fontWeight: 700,
    fontSize: 42,
    lineHeight: '48px',
    marginBottom: 8,
  },
  link: {
    fontSize: 13,
    color: '#566063',
    marginTop: 5,
    marginBottom: 15,

    '& a': {
      textDecoration: 'none',
      color: '#3099E5',
    }
  },
  title: {
    fontWeight: 600,
    color: '#282E38',
    fontSize: 27,
    marginTop: 15,
    marginBottom: 15,
  },
  modalText: {
    fontWeight: 500,
    color: '#566063',
    fontSize: 16,
    marginTop: 10,
    marginBottom: 10,
    '& span': {
      fontWeight: 600,
    }
  },
  text: {
    fontSize: 16,
    color: '#566063',
    marginBottom: 15,
  },
  formControl: {
    width: '100%',
  },
  inputLabel: {
    fontSize: 14,
    marginBottom: 5,
    fontWeight: 500,
    color: '#444F53',
  },
  input: {
    width: '100%',

    '& .MuiInputBase-root': {
      borderRadius: 10
    }
  },
  skedSubmit: {
    background: '#3099E5', 
    borderRadius: 4,
    fontSize: 16,
  },
  sparkSubmit: {
    background: 'linear-gradient(13deg, #FA5F19 0%, #FB8A28 100%)',
    borderRadius: 4,
    fontSize: 16,
  },
  selected: {
    borderRadius: 10,
    width: '100%',
  },
  check: {
    '& rect': {
      fill: '#FA5F19'
    }
  },
  icon: {
    '& path': {
      fill: '#FFF'
    }
  },
  image: {
    maxWidth: '100%',
    maxHeight: '560px',
    objectFit: 'contain',
  },
  sparkImage: {
    maxWidth: '290px',
    maxHeight: '100%',
    objectFit: 'contain',
    marginRight: 15,
  },
  button: {
    padding: '4px 16px',
    fontSize: 16,
    marginTop: 5,
    fontWeight: 500,
  },
  sparkContent: {
    paddingBottom: 10,
    
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',

      '& img': {
        marginBottom: 15,
      }
    },
  },
}));
