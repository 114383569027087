import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';

import Loading from '../../components/Loading/Loading.component';
import { alertDanger } from '../../components/Alerts/alerts.actions';

import api from '../../services/api';
import { useTitle } from '../../services/useTitle';
import { SPARK_FEATURE } from '../Login/login.reducer';
import { useSelector } from '../../reducers';

import { Body } from './components/Form/Form.component';
import SkedForm from './components/SkedForm/SkedForm.component';
import SparkForm from './components/SparkForm/SparkForm.component';

const SignUp = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [busy, setBusy] = useState(true);
  const [open, setOpen] = useState(false);
  const [finished, setFinished] = useState(false);

  const { features } = useSelector((state) => ({
    features: state.login.features,
  }));

  const isSpark = R.includes(SPARK_FEATURE, features);

  const handleSubmit = async (body: Body) => {
    try {
      setLoading(true);
      await api.post(`subscription/v2/${isSpark ? 'sked' : 'spark'}/upgrade`, body);
      setOpen(true);
      setLoading(false);
      setFinished(true);
    } catch (e) {
      setLoading(false);
      console.log('error ', e);
      dispatch(alertDanger('Oops! Something went wrong. Please check your details and try again.'));
    }
  };

  useTitle('sked/spark sign up');

  const checkSubscription = async () => {
    try {
      setBusy(true);
      const result = await api.get(`subscription/v2/${isSpark ? 'sked' : 'spark'}`);
      setFinished(!!result?.customerId);
    } catch (error) {
      console.error(error);
      setFinished(false);
    } finally {
      setBusy(false);
    }
  };

  useEffect(() => {
    checkSubscription();
  }, []);

  if (busy) {
    return <Loading loading={busy} />;
  }

  if (isSpark) {
    return (
      <SkedForm
        onSubmit={handleSubmit}
        loading={loading}
        open={open}
        setOpen={setOpen}
        setLoading={setLoading}
        finished={finished}
      />
    );
  }
  return (
    <SparkForm
      onSubmit={handleSubmit}
      loading={loading}
      open={open}
      setOpen={setOpen}
      setLoading={setLoading}
      finished={finished}
    />
  );
};

export default SignUp;
