import React, { useState } from 'react';
import * as R from 'ramda';
import api, { skedApi } from '../../services/api.js';
import { PopupTemplate, popup, popupWithCancel } from '../../services/Popup.js';
import { Button, Card } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { pickColor } from '../../services/utilities.js';

const TypeDrag = ({ type, clicked, onClick }) => {
  return (
    <div
      style={{
        width: '241px',
        backgroundColor: clicked ? '#008BCF' : '#CECECE',
        color: clicked ? 'white' : 'black',
        marginTop: '10px',
        marginBottom: '10px',
        cursor: 'pointer',
        border: '1px solid rgb(191, 191, 191)',
        borderRadius: '50px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
      onClick={() => onClick(type)}
    >
      <div
        style={{
          width: '30px',
          height: '30px',
          borderRadius: '100px',
          backgroundColor: pickColor(type.color),
        }}>
      </div>
      <div
        key={type.id}
        style={{
          margin: '5px 0px 5px 10px',
          width: '190px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}>
        {type.internalName + ' (' + type.id + ')'}
      </div>
    </div>
  );
};

const TransTypesDrag = ({ types, onClick }) => {
  const {
    oldType,
    newType,
  } = types;
  return (
    <div
      style={{
        width: '483px',
        backgroundColor: '#CECECE',
        color: 'black',
        marginTop: '10px',
        marginBottom: '10px',
        cursor: 'pointer',
        border: '1px solid rgb(191, 191, 191)',
        borderRadius: '50px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
      onClick={() => onClick(types)}
    >
      <div
        style={{
          width: '30px',
          height: '30px',
          borderRadius: '100px',
          backgroundColor: pickColor(newType.color),
        }}>
      </div>
      <div
        key={oldType.id + '.' + newType.id}
        style={{
          margin: '5px 0px 5px 10px',
          width: '432px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}>
        {oldType.internalName + ' (' + oldType.id + ')' + ' → ' + newType.internalName + ' (' + newType.id + ')'}
      </div>
    </div>
  );
};

const ProContainer = ({
  pro,
  update,
  children,
}) => {
  return (
    <div
      id={pro.id}
      key={pro.id}
      style={{ width: '100%' }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => {
          update(R.merge(pro, { hidden: !pro.hidden }));
        }}
      >
        {pro.displayFirstName + ' ' + pro.displayLastName}
        {pro.hidden ?
          <ArrowRightIcon style={{ fontSize: '24px' }} />
          :
          <ArrowDropDownIcon style={{ fontSize: '24px' }} />
        }
      </div>
      {!pro.hidden && children}
    </div>
  );
};

const TransferOffice = () => {
  const [state, setState] = useState({
    busy: false, // Either (Bool Prop)
    bigBusy: false,
    oldOfficeId: '',
    newOfficeId: '',
    oldOffice: {},
    newOffice: {},
    oldProTypes: [],
    newProTypes: [],
    transTypes: [],
    selectedOld: null,
    selectedNew: null,
  });

  // getOfficeData :: Prop
  const getOfficeData = (prop) => {
    update({ busy: prop });
    skedApi.defaults.headers.common['X-As-Office'] = R.prop(prop, state);
    return api.get('office/me').then((office) => {
      return Promise.all([
        api.get('professional'),
        api.get('appointmentType')
      ]).then(([pros, types]) => {
        delete skedApi.defaults.headers.common['X-As-Office'];
        const proTypes = R.pipe(
          R.groupBy(R.prop('professionalId')),
          R.values,
          R.map((ts) => {
            const type = R.head(ts);
            if (type) {
              const { professionalId } = type;
              const pro = R.find(R.propEq('id', professionalId))(pros);
              if (pro) {
                return R.merge(pro, { types: R.sortBy(R.prop('internalName'))(ts) });
              }
            }
            return null;
          }),
          R.filter((v) => v !== null),
          R.sortBy(R.prop('firstName'))
        )(types);
        if (prop === 'oldOfficeId') {
          update({
            busy: false,
            oldOffice: office,
            oldProTypes: proTypes,
          });
        } else {
          update({
            busy: false,
            newOffice: office,
            newProTypes: proTypes,
          });
        }
      });
    }).catch((error) => {
      console.log(error);
      if (error.response.status === 401) {
        popup('Error!', 'Auth token lost. Go back to select office page!');
      } else {
        popup('Error!', error.message);
      }
    });
  };

  const transferOffice = () => {
    const data = {
      fromOffice: Number(state.oldOfficeId),
      toOffice: Number(state.newOfficeId),
      apptTys: R.map(({ newType, oldType }) => ({
        from: oldType.id,
        to: newType.id
      }))(state.transTypes),
    };
    update({ bigBusy: true });
    api.post('admin/genesis/mergeOffice', data).then(() => {
      update({ bigBusy: false });
      popup('Success!', 'Successfully transferred office!');
    }).catch((error) => {
      popup('Error!', error.message);
    });
  };

  const update = (data) => {
    setState(R.merge(state, data));
  };

  const {
    busy,
    oldOfficeId,
    newOfficeId,
    oldOffice,
    newOffice,
    oldProTypes,
    newProTypes,
    transTypes,
    selectedOld,
    selectedNew,
  } = state;
  
  return (
    <div style={{
      height: '100%',
      padding: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <PopupTemplate />
      <div style={{
        display: 'flex',
        width: '50%',
        justifyContent: 'space-evenly',
      }}>
        <Card
          style={{
            padding: '10px',
            height: 'min-content',
          }}
        >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px'
          }}>
              Old Office:
              &nbsp;
            <input
              value={oldOfficeId}
              placeholder='ID'
              type='text'
              style={{ width: '30px' }}
              onChange={(e) => update({ oldOfficeId: e.target.value })}
              onKeyPress={(e) => e.key === 'Enter' ? getOfficeData('oldOfficeId') : null}
            />
          </div>
          {R.propOr(null, 'name')(oldOffice)}
          <Card
            style={{
              backgroundColor: 'white',
              padding: '10px',
              borderRadius: '10px',
              height: '600px',
              width: '258px',
              overflowY: 'scroll',
              marginBottom: '10px',
            }}>
            {busy === 'oldOfficeId' && <div className="loader"></div>}
            {!busy && R.isEmpty(oldProTypes) &&
                <div>
                  Empty
                </div>}
            {!busy && oldProTypes.map((pro, ind) => {
              return (
                <ProContainer
                  key={pro.id}
                  pro={pro}
                  index={ind}
                  update={(newPro) => {
                    update({
                      oldProTypes: R.update(ind, newPro, oldProTypes),
                    });
                  }}
                >
                  {R.pipe(
                    R.filter(({ id }) => !R.find(R.pathEq(['oldType', 'id'], id))(transTypes)),
                    R.map((type) => {
                      return (
                        <TypeDrag
                          type={type}
                          clicked={type.id === R.propOr(null, 'id')(selectedOld)}
                          onClick={(t) => {
                            if (selectedNew) {
                              const tt = {
                                oldType: t,
                                newType: selectedNew,
                              };
                              update({
                                selectedOld: null,
                                selectedNew: null,
                                transTypes: R.prepend(tt, transTypes)
                              });
                            } else {
                              update({ selectedOld: t });
                            }
                          }}
                        >
                        </TypeDrag>
                      );
                    })
                  )(pro.types)}
                </ProContainer>
              );
            })}
          </Card>
        </Card>
        <Card
          style={{
            padding: '10px',
            height: 'min-content',
          }}
        >
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px'
          }}>
              New Office:
              &nbsp;
            <input
              value={newOfficeId}
              placeholder='ID'
              type='text'
              style={{ width: '30px' }}
              onChange={(e) => update({ newOfficeId: e.target.value })}
              onKeyPress={(e) => e.key === 'Enter' ? getOfficeData('newOfficeId') : null}
            />
          </div>
          {R.propOr(null, 'name')(newOffice)}
          <Card
            style={{
              backgroundColor: 'white',
              padding: '10px',
              borderRadius: '10px',
              height: '600px',
              width: '258px',
              overflowY: 'scroll',
              marginBottom: '10px',
            }}>
            {busy === 'newOfficeId' && <div className="loader"></div>}
            {!busy && R.isEmpty(newProTypes) &&
                <div>
                  Empty
                </div>}
            {!busy && newProTypes.map((pro, ind) => {
              return (
                <ProContainer
                  key={pro.id}
                  pro={pro}
                  index={ind}
                  update={(newPro) => {
                    update({
                      newProTypes: R.update(ind, newPro, newProTypes),
                    });
                  }}
                >
                  {R.pipe(
                    R.filter(({ id }) => !R.find(R.pathEq(['newType', 'id'], id))(transTypes)),
                    R.map((type) => {
                      return (
                        <TypeDrag
                          type={type}
                          clicked={type.id === R.propOr(null, 'id')(selectedNew)}
                          onClick={(t) => {
                            if (selectedOld) {
                              const tt = {
                                oldType: selectedOld,
                                newType: type,
                              };
                              update({
                                selectedOld: null,
                                selectedNew: null,
                                transTypes: R.prepend(tt, transTypes)
                              });
                            } else {
                              update({ selectedNew: t });
                            }
                          }}
                        >
                        </TypeDrag>
                      );
                    })
                  )(pro.types)}
                </ProContainer>
              );
            })}
          </Card>
        </Card>
      </div>
      <Card
        style={{
          padding: '10px',
          height: 'min-content',
        }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px'
        }}>
            Transferred Types:
        </div>
        {null}
        <Card
          style={{
            backgroundColor: 'white',
            padding: '10px',
            borderRadius: '10px',
            height: '600px',
            width: '500px',
            overflowY: 'scroll',
            marginBottom: '10px',
          }}>
          {!busy && R.isEmpty(transTypes) &&
              <div>
                Empty
              </div>}
          {transTypes.map((types) => {
            return (
              <TransTypesDrag
                types={types}
                onClick={(tt) => update({
                  transTypes: R.without([tt], transTypes),
                })}
              >
              </TransTypesDrag>
            );
          })}
        </Card>
        <Button
          style={{ float: 'right' }}
          type="button"
          variant="contained"
          color="error"
          onClick={() =>
            popupWithCancel(
              'Verification',
              'Are you sure you want to transfer office '
                + oldOfficeId
                + ' to office '
                + newOfficeId
                + '? This action cannot be undone!',
              () => transferOffice())}>
            Transfer
        </Button>
      </Card>
    </div>
  );
};

export default TransferOffice;
