import React from 'react';
import * as R from 'ramda';

import { Tooltip, TableRow, TableHead, TableBody, Grid } from '@mui/material';
import Info from '@mui/icons-material/Info';

import { Appointment } from '../../routes/Appointments/appointments.types';

import { TableContainer, HeaderCell } from '../../components/CustomTable';

import AptItem from './AppointmentItem.component';

const displayError = (error: { message?: string }) => (
  <div>
    {'Unable to display data: ' + error.message}
  </div>
);

export type Professional = {
  id: number;
  firstName: string;
  lastName: string;
}

type AptsTableProps = {
  apts: Appointment[];
  tz: string;
  viewApt?: (appt: Appointment) => void;
  professionals: Professional[];
  isLead?: boolean;
  loading?: boolean;
  confirmLead?: () => void;
}

const AptsTable = ({ apts, tz, viewApt, professionals, isLead, confirmLead, loading }: AptsTableProps) => {
  const orderedApts = R.sortBy(R.prop('time'))(apts);

  if (loading) {
    return <div style={{ paddingTop: '60px' }}><div className="loader"></div></div>;
  }
  return (
    <Grid marginX={isLead ? -2 : 0}>
      <TableContainer>
        <TableHead>
          <TableRow>
            <HeaderCell className="td-name">
            Appointment
            </HeaderCell>
            <HeaderCell className="td-notifications">Status</HeaderCell>
            <HeaderCell className="td-platinum-id">Type</HeaderCell>
            {isLead && <HeaderCell className="td-platinum-id">Actions &nbsp;<Tooltip
              title="Selecting confirm will convert the lead to a client and add the appointment to your schedule."
              placement="top"
              arrow>
              <Info style={{ fontSize: '12px' }} />
            </Tooltip></HeaderCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {R.has('error')(apts) ? displayError(R.prop('error')(apts)) :
            R.map((apt: Appointment) =>
              <AptItem apt={apt} tz={tz} viewApt={viewApt} professionals={professionals} isLead={isLead} confirm={confirmLead} />
            )(orderedApts)}
        </TableBody>
      </TableContainer>
    </Grid>
  );
};

export default AptsTable;
