import * as React from 'react';
import { IconButton } from '@mui/material';
import TrashIcon from '@mui/icons-material/Delete';
import { ComplexWidgetSignature, IndexAndConds, } from '../intake-types';
import { Spacer } from '../../../components/PageHeader';
import {
  deleteSectionChild
} from '../intake.context';
import { OrderButtons } from './SectionChildOrderButtons.component';
import { useDispatch } from 'react-redux';
import QuestionLabel from './QuestionLabel.component';

interface SignatureProps {
  data: ComplexWidgetSignature,
  required: boolean,
  id: number
  index?: number;
  maxIndex?: number;
  hasClose?: boolean;
  forOffice: boolean;
  questionCond: IndexAndConds;
}

export const SignatureComponent = ({
  id, index, maxIndex, hasClose = true, forOffice, questionCond,
}: SignatureProps) => {
  const dispatch = useDispatch();
  return (
    <div>
      {!forOffice &&
          <QuestionLabel cond={questionCond} complex />}
      <div><strong>Signature Widget</strong></div>
      <Spacer />
      {hasClose && (
        <IconButton aria-label="delete question"
          onClick={() => dispatch(deleteSectionChild(id, questionCond.index))}>
          <TrashIcon fontSize="inherit" />
        </IconButton>
      )}
      <OrderButtons
        index={index}
        maxIndex={maxIndex}
        id={id}
      />
    </div>
  );
};
