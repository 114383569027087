import * as React from 'react';
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material';
import { ComplexWidgetConsentPapers } from '../intake-types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WarningIcon from '@mui/icons-material/Warning';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { SignatureComponent } from './AnswerSignature.component';
import { useAnswerContext, useIntakeContextAnswer } from '../answers.context';
import { SignatureAnswer } from '../answer-types';
import { isEmpty, range, dropLast, has } from 'ramda';

interface ConsentProps {
  id: number;
  name: string;
  body: string;
  open: boolean;
  handleClose: () => void
  save: () => void;
  noCtx?: boolean;
}

const defaultAnswer: SignatureAnswer = {
  id: 0,
  type: 'Signature',
  value: null,
  metadata: {},
};

let i = -1;
const EditConsentDialog = ({ id, name = '', body, open = false, handleClose }: ConsentProps) => {
  const ctx = useAnswerContext();
  const values = ctx.getValue(id);
  const parsedBody = React.useMemo(() => {
    if (open) {
      return body.replace(/{{(.*?)}}/g, (s: string): string => {
        const placeholder = dropLast(2, s.split(':')[1]);
        i = i + 1;
        const value = values?.metadata?.inputs[String(i)] || '';
        return `<input id="${'input-' + i}" type="text" placeholder="${placeholder}" value="${value}" />`;
      });
    } else {
      i = -1;
      return '';
    }
  }, [body, open]);
  const onClose = () => {
    const inputs = range(0, i + 1);
    if (!isEmpty(inputs)) {
      const values = inputs.map((i) => {
        const el: any = document.getElementById(`input-${i}`);
        return { [i]: el.value };
      }).reduce((c, acc) => ({
        ...acc,
        ...c,
      }));
      ctx.setMetadata(id, {
        inputs: values,
      });
    }
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll='paper'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">
        <Typography variant="h6">
          {name}
        </Typography>
        <IconButton
          aria-label="close"
          style={{ position: 'absolute', right: '8px', top: '8px' }}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <div dangerouslySetInnerHTML={{ __html: parsedBody }}></div>
        <SignatureComponent
          id={id}
          required={true}
          data={{ type: 'Signature' }}
          isPrivate
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ConsentDialog = ({ id, name = '', body, open = false, handleClose, noCtx }: ConsentProps) => {
  const ctx = useAnswerContext();
  const metadata = noCtx ? {} : ctx.getMetadata(id);
  const parsedBody = React.useMemo(() => {
    i = -1;
    return body.replace(/{{(.*?)}}/g, (): string => {
      i += 1;
      const value = (metadata && metadata.inputs[String(i)]) || '__________';
      return `<b style="text-decoration: underline;">${value}</b>`;
    });
  }, [body, metadata]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll='paper'
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle id="scroll-dialog-title">
        <Typography variant="h6">
          {name}
        </Typography>
        <IconButton aria-label="close" style={{ position: 'absolute', right: '8px', top: '8px' }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers={true}>
        <div dangerouslySetInnerHTML={{ __html: parsedBody }}></div>
        <SignatureComponent
          id={id}
          required={true}
          data={{ type: 'Signature' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface ConsentPapersProps {
  data: ComplexWidgetConsentPapers;
  required: boolean;
  id: number;
  noCtx?: boolean;
  isPrivate?: boolean;
}

export const ConsentPapersComponent = ({ id, data, noCtx, isPrivate }: ConsentPapersProps) => {
  const [open, setOpen] = React.useState(false);
  const [sig] = noCtx ? [''] : useIntakeContextAnswer<SignatureAnswer>(id, defaultAnswer);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const t = typeof sig === 'string';
  const isCompleted = t ? sig !== '' : sig && (!isEmpty(sig.data) || has('url', sig));

  return (
    <div style={{ paddingBottom: '20px' }}>
      {isPrivate ?
        <EditConsentDialog
          id={id}
          open={open}
          handleClose={handleClose}
          name={data.name}
          body={data.body}
          save={() => null}
          noCtx={noCtx}
        />
        :
        <ConsentDialog
          id={id}
          open={open}
          handleClose={handleClose}
          name={data.name}
          body={data.body}
          save={() => null}
          noCtx={noCtx}
        />}
      <div key={data.name} style={{
        display: 'flex',
        paddingTop: '7.5px',
        paddingBottom: '7.5px',
        borderTop: noCtx ? 'unset' : '1px solid #ccc',
        alignItems: 'center',
      }}>
        <div style={{ marginLeft: '20px', marginRight: '20px' }}>
          {isCompleted ?
            <CheckIcon style={{ color: 'green' }} /> :
            <WarningIcon style={{ color: '#ff9800' }} />
          }
        </div>
        <div>{data.name}</div>
        <div style={noCtx ? { width: '200px' } : { flexGrow: 1 }}></div>
        <div>
          <Button
            id='page-buttons'
            onClick={handleClickOpen}
            variant='contained'
            color="primary"
            endIcon={<ArrowForwardIcon />}
          >
            View
          </Button>
        </div>
      </div>
    </div>
  );
};
