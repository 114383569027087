/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { useSelector } from '../../../../reducers';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as R from 'ramda';
import {
  MenuItem, TextField, Snackbar, Divider, Checkbox, Paper,
  FormControlLabel, Button, Typography, Grid, Switch, Tooltip,
  TableHead, TableRow, TableBody, TableCell
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { PopupTemplate } from '../../../../services/Popup.js';
import * as actions from './general.actions.jsx';
import api from '../../../../services/api.js';
import {
  format, now,
} from '../../../../services/joda.js';
import { PageHeader, Div } from '../../../../components/PageHeader';
import Header from '../../../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import { TableContainer, HeaderCell } from '../../../../components/CustomTable';
import OldLadyIcon from '../../../../icons/OldLady.icon';
import WarningIcon from '../../../../icons/Warning.icon';

const Section = withStyles({
  root: {
    marginTop: '10px',
    marginBottom: '10px',
    paddingBottom: '10px',
  }
})(Div);

const GrayContainer = withStyles({
  root: {
    background: '#F4F4F4',
    padding: 10,
    borderRadius: 6,
    marginTop: 12,
  }
})(Grid);

const Title = withStyles({
  root: {
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: 5,
  }
})(PageHeader);

const Label = withStyles({
  root: {
    fontWeight: 'bold',
    fontSize: 16,
  }
})(PageHeader);

const WarningContainer = withStyles({
  root: {
    background: '#FFF8E0',
    padding: 12,
    borderRadius: 6,
    marginTop: 15,
  }
})(Grid);

const Text = withStyles({
  root: {
    fontSize: 13,

    '& span': {
      fontWeight: 600,
      color: '#51535B',
    }
  }
})(Typography);

const StatusLabel = withStyles({
  root: {
    fontSize: 14,
    fontWeight: 600,
    color: '#51535B',
    width: 150,
  }
})(Typography);

const SmallerTextField = withStyles({
  root: {
    width: '130px',
  },
})(TextField);


const timeSettings = [
  'Never',
  'Minute',
  'Hour',
  'Day',
  'Week',
  'Month'
];


const miscMenu = (option, prop, patch) => {
  if (typeof option === 'string') {
    return (
      <MenuItem
        key={option}
        onClick={() => patch(prop, option)}
        value={option}>
        {option}
      </MenuItem>
    );
  } else {
    return (
      <MenuItem
        key={option.value}
        onClick={() => patch(prop, option.value)}
        value={option.value}>
        {option.text}
      </MenuItem>
    );
  }
};

export const GeneralTemplate = ({
  busy, error, timeSetting, patch, save, snackbar,
  sendTextNotifications, allowGrouping, undeliveredSetting,
  undeliveredSettingTime, office, verifiedEmail, getSettings, history,
  confirmOfficeEmail, features, emailStatus,
}) => {
  const [enabled, setEnabled] = React.useState(emailStatus === 'Success');
  const [fromEmail, setFromEmail] = React.useState('');
  const [originalFromEmail, setOriginalFromEmail] = React.useState('');
  const [domain, setDomain] = React.useState('');
  const [domainStatus, setDomainStatus] = React.useState(''); // dkin status
  const [fromDomainStatus, setFromDomainStatus] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const { admin } = useSelector((state) => ({
    admin: state.login.admin,
  }));

  React.useEffect(() => {
    getSettings(history).then(() => {
      const queryParams = window.location.href.split('?')[1];
      if (queryParams) {
        const confirmEmail = queryParams.split('=')[1];
        if (confirmEmail === 'true') {
          confirmOfficeEmail();
        }
      }
    });
  }, [office]);

  const handleUndeliveredChange = () => {
    patch('undeliveredSetting', !undeliveredSetting);
    if (!undeliveredSetting) {
      patch('undeliveredSettingTime', '17:00');
    } else {
      patch('undeliveredSettingTime', null);
    }
  };

  const handleStart = async () => {
    const newDomain = getDomain(fromEmail);
    const data = {
      enabled,
      domain: newDomain,
      fromEmail,
    };
    try {
      if (!enabled) {
        setDomain('');
        setFromEmail('');
        setDomainStatus('');
        setFromDomainStatus('');
      }
      await api.post('email/email/domain', data);
      setOriginalFromEmail(fromEmail);
      setDomain(newDomain);
      getDomainInfo();
    } catch (e) {
      console.error(e);
    }
  };

  const getStatusColors = (status) => {
    switch (status) {
      case 'Pending': {
        return { icon: '#2E66FF', background: '#EEF5FF', text: 'Pending verification' };
      }
      case 'Success': {
        return { icon: '#65B67F', background: '#E6F6F0', text: 'Verified' };
      }
      case 'Failed': {
        return { icon: '#b66f65', background: '#f6e6e6', text: 'Verification failed' };
      }
      case 'TemporaryFailure': {
        return { icon: '#e7de57', background: '#f6f4e6', text: 'Verification Temporarily failed' };
      }
      case 'NotStarted': {
        return { icon: '#666666', background: '#bfc2c1', text: 'Not Started' };
      }
      default: {
        return null;
      }
    }
  };

  const getDomainInfo = async () => {
    try {
      const response = await api.get('email/email/domain');
      if (response.enabled) {
        setDomain(response.domain);
        setFromEmail(response.fromEmail);
        setOriginalFromEmail(response.fromEmail);
        setEnabled(response.enabled);
        setDomainStatus(response.domainStatus);
        setFromDomainStatus(response.fromDomainStatus);
      }
    } catch (e) {
      console.error(e);
    }
  };

  // tryAgain :: 'from-mail' | 'dmik' -> Promise<void>
  const tryAgain = (thing) => async () => {
    try {
      setLoading(true);
      await api.post(`email/email/domain/${thing}/retry`);
      await getDomainInfo();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const getDomain = (value) => {
    const email = value.split('@');
    if (email.length > 1) {
      return email[1];
    }
    return '';
  };

  const dkinStatus = React.useMemo(() => {
    return getStatusColors(domainStatus);
  }, [domainStatus]);

  const spfStatus = React.useMemo(() => {
    return getStatusColors(fromDomainStatus);
  }, [fromDomainStatus]);

  React.useEffect(() => {
    getDomainInfo();
  }, []);

  const emailValid = R.isNil(fromEmail) || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(fromEmail);
  return (
    <>
      <Header
        title='Notification Settings'
        rightIcons={[
          <HeaderButton
            title='Save'
            color='primary'
            disabled={enabled && !emailValid}
            onClick={() => {
              save(
                timeSetting,
                sendTextNotifications,
                allowGrouping,
                undeliveredSetting ? undeliveredSettingTime : null,
              );
              handleStart();
            }}
          />
        ]}
      />
      <div
        style={{
          padding: '20px',
          overflowY: 'auto',
        }}>
        <PopupTemplate />
        <Snackbar
          open={snackbar}
          message="Information saved!"
          autoHideDuration={4000}
          onClose={() => patch('snackbar', false)} />
        {busy && <div className="loader"></div>}
        {!R.isEmpty(error) &&
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '100px',
          }}>
            <div style={{ fontSize: '30px' }}>😢</div>
            <div>
              {error}
            </div>
          </div>}
        {!busy && R.isEmpty(error) &&
          <div>
            <Paper style={{ padding: '20px', marginTop: 0 }}>
              {office.plan !== 'SkedBasic' && (
                <>
                  <Section style={{ marginTop: 0 }}>
                    <PageHeader variant='h6'>Appointment Changes Notifications</PageHeader>
                    <div style={{ marginBottom: '20px' }}>
                      <i>Get aggregated notifications about appointments that have changed. The email will be sent to the notification email listed in the business settings. No email will be sent if there are no updates.</i>
                    </div>
                    <TextField
                      select
                      style={{ marginLeft: '7px' }}
                      value={timeSetting}>
                      {timeSettings.map((option) => miscMenu(option, 'timeSetting', patch))}
                    </TextField>
                  </Section>
                  <Divider />
                </>
              )}
              <Section>
                <PageHeader variant='h6'>SMS Notification Settings</PageHeader>
                <div style={{ marginBottom: '20px' }}>
                  <i>Get emails with message threads of clients replying via SMS.</i>
                </div>
                <FormControlLabel
                  control={<Checkbox checked={sendTextNotifications} onChange={() => patch('sendTextNotifications', !sendTextNotifications)} name="gilad" />}
                  label="Enabled"
                />
              </Section>
              <Divider />
              <Section>
                <PageHeader variant='h6'>
                  Bounced Email & Missing Phone Number and Email Address Notification
                </PageHeader>
                <div style={{ marginBottom: '20px' }}>
                  <i>Get emails with lists of which clients had an email bounce and which lack a phone number or email address.</i>
                </div>
                <FormControlLabel
                  control={<Checkbox checked={undeliveredSetting} onChange={handleUndeliveredChange} name="undeliveredSetting" />}
                  label="Enabled"
                />
                <br />
                {undeliveredSetting &&
                  <div>
                    What time would you like to receive this email?
                    <br />
                    <SmallerTextField
                      type='time'
                      defaultValue={undeliveredSetting ? undeliveredSettingTime : format(now('time'), 'HH:mm')}
                      onChange={(e) => {
                        patch('undeliveredSettingTime', e.target.value);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }} />
                  </div>}
              </Section>
              <Divider />
              {R.includes('VerifiedEmail', features) && (
                <Section>
                  <Title variant='h5'>Use Your Custom Email</Title>
                  <Label style={{ marginTop: 10 }} variant='h6'>Enable verification</Label>
                  <Grid display="flex" alignItems="center" justifyContent="space-between">
                    <Typography fontSize="15px">
                      Want to use your own email with a custom domain like “office@mostbestestchiro.com”? Turn this setting on in order to send all SKED related emails from your office’s email.
                    </Typography>
                    <Switch
                      checked={enabled}
                      onChange={(event) => setEnabled(event.target.checked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Grid>
                  {enabled && (
                    <Grid paddingLeft="15px" marginTop={'15px'} style={{ borderLeft: '3px solid #35A6D8' }}>
                      {emailStatus === 'Success' && !!verifiedEmail && (fromDomainStatus !== 'Success' || domainStatus !== 'Success') && (
                        <>
                          <Label variant='h6'>Verified email</Label>
                          <Typography>
                            Previously, you verified your emails the old-fashioned way. In order to update the email below, upgrade to Domain verification by clicking the button below.
                          </Typography>
                          <TextField
                            disabled
                            style={{ marginTop: '7px' }}
                            value={verifiedEmail}
                            onChange={(e) => {
                              patch('verifiedEmail', e.target.value);
                            }}
                          />
                          <GrayContainer>
                            <Grid display="flex" alignItems="flex-end" marginBottom="3px">
                              <OldLadyIcon style={{ width: 18, height: 23 }} />
                              <Text style={{ fontWeight: 'bold', marginLeft: 5 }}>This is outdated: Upgrade to make sure your emails keep sending</Text>
                            </Grid>
                            <Text>
                              Upgrade to domain verification <span>so your emails don't go to spam.</span> More information below.
                            </Text>
                          </GrayContainer>
                        </>
                      )}
                      <Label style={{ marginTop: 15 }} variant='h6'>Verified domain</Label>
                      <Text>
                        Verify your business’ custom email and domain addresses. <span>Don’t use email services such as  <i>gmail.com</i> or <i>outlook.com</i>.</span> Upgrading can be a breeze IF you know how to update your DNS records. Your IT staff should be able to update this info.
                      </Text>
                      <TextField
                        // label='Email Address'
                        style={{ marginTop: '7px' }}
                        value={fromEmail}
                        error={!emailValid}
                        // helperText={!emailValid && 'Please enter a valid email address.'}
                        onChange={(e) => setFromEmail(e.target.value)}
                        placeholder='example@MY-DOMAIN.com'
                      />
                      <Grid marginTop={1.5}>
                        <Tooltip arrow title={(!emailValid || !fromEmail || (originalFromEmail === fromEmail)) ? 'Enter a new email with a custom domain to begin. (Ex: erik@mydomain.com)' : ''}>
                          <span>
                            <Button
                              variant='contained'
                              color='primary'
                              disabled={!emailValid || !fromEmail || (originalFromEmail === fromEmail)}
                              onClick={() => handleStart()}>
                              Start the Domain Verification Process
                            </Button>
                          </span>
                        </Tooltip>
                      </Grid>

                      <Grid marginTop="15px">
                        {!!dkinStatus && domain && (fromDomainStatus !== 'Success' || domainStatus !== 'Success') && (
                          <>
                            <Label variant='h6'>How to update DNS records?</Label>
                            <Text>
                              Managing your DNS can be tricky for non-IT people, because it depends on what you’re using. Update your DNS records with the below information to finish verification.
                            </Text>
                          </>
                        )}
                        {spfStatus && (
                          <Grid display="flex" marginTop="10px">
                            <StatusLabel>
                            SPF and MX status:
                            </StatusLabel>
                            <Grid display="flex" alignItems="center" style={{ background: spfStatus.background, padding: '5px 10px', borderRadius: 6 }}>
                              <div style={{ width: 8, height: 8, borderRadius: '50%', marginRight: 8, background: spfStatus.icon }} />
                              <Typography>{spfStatus.text}</Typography>
                            </Grid>
                            {admin &&
                                <Button disabled={loading} onClick={tryAgain('from-mail')}>
                                  Try again
                                </Button>}
                          </Grid>
                        )}
                        {dkinStatus && (
                          <Grid display="flex" marginTop="10px">
                            <StatusLabel>
                          DKIM status:
                            </StatusLabel>
                            <Grid display="flex" alignItems="center" style={{ background: dkinStatus.background, padding: '5px 10px', borderRadius: 6 }}>
                              <div style={{ width: 8, height: 8, borderRadius: '50%', marginRight: 8, background: dkinStatus.icon }} />
                              <Typography>{dkinStatus.text}</Typography>
                            </Grid>
                            {admin &&
                                <Button disabled={loading} onClick={tryAgain('dkim')}>
                                  Try again
                                </Button>}
                          </Grid>
                        )}
                      </Grid>
                      {(fromDomainStatus !== 'Success' || domainStatus !== 'Success') && (
                        <WarningContainer>
                          <Grid display="flex" alignItems="flex-end" marginBottom="3px">
                            <WarningIcon style={{ width: 20, height: 19 }} />
                            <Text style={{ fontWeight: 'bold', marginLeft: 5 }}>Warning: Must complete verification within 72 hours</Text>
                          </Grid>
                          <Text>
                            If DNS verification is not authorized within 72 hours, you will have to start over. Propagation may take up to 48 hours after updating your DNS records.
                          </Text>
                        </WarningContainer>
                      )}

                      {!!dkinStatus && domain && (
                        <TableContainer style={{ marginTop: 15 }}>
                          <TableHead>
                            <TableRow>
                              <HeaderCell>Type</HeaderCell>
                              <HeaderCell>Name</HeaderCell>
                              <HeaderCell>Value</HeaderCell>
                              <HeaderCell>TTL (Seconds)</HeaderCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>MX</TableCell>
                              <TableCell>sked-feedback.{domain}</TableCell>
                              <TableCell>10 feedback-smtp.us-east-1.amazonses.com</TableCell>
                              <TableCell>600</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>TXT</TableCell>
                              <TableCell>sked-feedback.{domain}</TableCell>
                              <TableCell>“v=spf1 include:mail.sked.life ~all”</TableCell>
                              <TableCell>600</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>TXT</TableCell>
                              <TableCell>_dmarc.{domain}</TableCell>
                              <TableCell>v=DMARC1; p=none; rua=mailto:dmarc@sked.life;</TableCell>
                              <TableCell>600</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>CNAME</TableCell>
                              <TableCell>{`skeddkim1._domainkey.${domain}`}</TableCell>
                              <TableCell>dkim1.dkim.sked.life</TableCell>
                              <TableCell>600</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>CNAME</TableCell>
                              <TableCell>{`skeddkim2._domainkey.${domain}`}</TableCell>
                              <TableCell>dkim2.dkim.sked.life</TableCell>
                              <TableCell>600</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>CNAME</TableCell>
                              <TableCell>{`skeddkim3._domainkey.${domain}`}</TableCell>
                              <TableCell>dkim3.dkim.sked.life</TableCell>
                              <TableCell>600</TableCell>
                            </TableRow>
                          </TableBody>
                        </TableContainer>
                      )}
                    </Grid>
                  )}
                </Section>
              )}
            </Paper>
          </div>}
      </div>
    </>
  );
};


const mapStateToProps = state => R.merge(
  R.propOr({}, 'general')(state),
  {
    office: R.pathOr(null, ['login', 'office'])(state),
    features: R.pathOr([], ['login', 'features'])(state),
  }
);

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(GeneralTemplate);
