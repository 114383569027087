import {
  IconButton,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import TrashIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import CachedIcon from '@mui/icons-material/Cached';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import React, { useState } from 'react';
import { usePromise } from '../services/promise.hook';
import { useSelector } from 'react-redux';
import { skedApi } from '../services/api.js';
import axios from 'axios';
import {
  pathOr,
  isEmpty,
  prepend,
  sortBy,
  prop,
  reverse,
  find,
  propEq,
  isNil,
} from 'ramda';
import { ZonedDateTime } from '@js-joda/core';
import {
  format,
} from '../services/joda.js';
import { ThreadEditor } from './Editor/editor.component';

const config = {
  headers: {
    'Authorization': skedApi.defaults.headers.common.Authorization,
  },
};

type NoteType = {
  note: string;
  created: string;
  encrypted: string;
  madeBy?: string;
  noteId: number;
  officeId?: number;
  isEdit?: boolean;
}

type Response = {
  data: NoteType[]
}


const getOfficeNotes = (id: number): Promise<Response> => axios.get(`${process.env.API_URL}/office/notes/${id}`, config);

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  notesList: {
    height: '400px',
    width: '85%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    overflow: 'hidden',
    position: 'relative',
    marginLeft: '20px',
  },
  notesMain: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'column',
  },
  notesTabLabel: {
    position: 'absolute',
    top: '18px',
    left: '7px',
    fontSize: '12px',
    '-webkit-transform': 'rotate(-90deg)',
    '-moz-transform': 'rotate(-90deg)',
    '-o-transform': 'rotate(-90deg)',
    'transform': 'rotate(-90deg)',
  },
  floatingButtons: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  notesListContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  notesContainer: {
    height: '400px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column-reverse',
    overflowY: 'scroll',
    position: 'relative',
  },
  noteContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    border: '1px solid black',
    borderRadius: '2px',
    margin: '10px',
    width: '450px',
    flexDirection: 'column',
  },
  noteInner: {
    display: 'flex',
    justifyContent: 'space-between',

    '& .MuiSvgIcon-fontSizeSmall': {
      fontSize: 16
    }
  },
  noteBody: {
    fontSize: '14px',
    margin: '5px',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
}));

const saveNote = (officeId: number, setNotes: (note: NoteType, id: number) => void) => (b: string, e: string, noteId: number = null) => {
  const data = {
    note: b,
    encrypted: e,
    officeId,
  };
  if (noteId) {
    return axios.put(`${process.env.API_URL}/office/notes/${noteId}`, data, config).then(({ data }) => {
      setNotes(data, noteId);
    }).catch((e) => {
      console.error(e);
    });
  } else {
    return axios.post(`${process.env.API_URL}/office/notes`, data, config).then(({ data }) => {
      setNotes(data, null);
    }).catch((e) => {
      console.error(e);
    });
  }
};

type NoteProps = {
  note: NoteType;
  officeId: number;
  deleteNote: (id: number) => void;
  setNotes: (note: NoteType, id: number) => void
}

const Note = ({
  note,
  officeId,
  deleteNote,
  setNotes,
}: NoteProps) => {
  const classes = useStyles();
  const isEncrypted = !isEmpty(note.encrypted) && !isNil(note.encrypted);
  const [show, setShow] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timer>(null);
  const [isEdit, setIsEdit] = useState(note.isEdit);
  const [isDelete, setIsDelete] = useState(false);
  const [body, setBody] = useState(note.note);
  const [encryptedBody, setEncryptedBody] = useState(isEncrypted ? note.encrypted : '');
  const [emoji, setEmoji] = useState(false);
  const [encrypted, setEncrypted] = useState(isEncrypted);
  const displayNote = () => {
    if (isEncrypted) {
      if (show) {
        return encryptedBody;
      } else if (isEmpty(body)) {
        return '***************';
      } else {
        return body;
      }
    }
    return body;
  };
  const save = saveNote(officeId, setNotes);
  return (
    <div
      key={note.noteId}
      className={classes.noteContainer}>
      <div className={classes.noteInner}>
        <p style={{
          fontSize: '10px',
          marginLeft: '5px',
        }}>
          {note.created ? format(ZonedDateTime.parse(note.created), 'MM/dd/yyyy hh:mm a') : 'UNSAVED'}
          {note.madeBy &&
            ` | ${note.madeBy}`}
        </p>
        {(isEdit && !isDelete) &&
          <div>
            <IconButton onClick={() => {
              const e = !isEmpty(encryptedBody) && !isNil(encryptedBody) ? encryptedBody : null;
              save(body, e, note.noteId).then(() => {
                setIsEdit(false);
              }).catch((e) => {
                console.error(e);
              });
            }}>
              <SaveIcon fontSize='small' />
            </IconButton>
            <IconButton onClick={() => {
              setEncrypted(!encrypted);
            }}>
              {encrypted ?
                <LockIcon fontSize='small' />
                :
                <LockOpenIcon fontSize='small' />}
            </IconButton>
            <IconButton onClick={() => {
              if (!note.created) {
                deleteNote(note.noteId);
              }
              setIsEdit(false);
            }}>
              <CancelIcon fontSize='small' />
            </IconButton>
          </div>}
        {(!isEdit && !isDelete) &&
          <div>
            <IconButton onClick={() => {
              setIsEdit(!isEdit);
            }}>
              <EditIcon fontSize='small' />
            </IconButton>
            {isEncrypted &&
              <IconButton onClick={() => {
                setShow(!show);
                if (timer) {
                  clearTimeout(timer);
                }
                setTimer(setTimeout(() => setShow(false), 60000));
              }}>
                {show ?
                  <LockOpenIcon fontSize='small' />
                  :
                  <LockIcon fontSize='small' />}
              </IconButton>}
            <IconButton onClick={() => setIsDelete(true)}>
              <TrashIcon fontSize='small' />
            </IconButton>
          </div>}
        {isDelete &&
          <div style={{ fontSize: '12px' }}>
            Really delete?
            <IconButton onClick={() => deleteNote(note.noteId)}>
              <CheckIcon fontSize='small' />
            </IconButton>
            <IconButton onClick={() => setIsDelete(false)}>
              <CloseIcon fontSize='small' />
            </IconButton>
          </div>}
      </div>
      {isEdit ?
        <ThreadEditor
          body={encrypted ? encryptedBody : body}
          messagesPatch={(b: string) => encrypted ? setEncryptedBody(b) : setBody(b)}
          patch={(key: string, value: boolean) => setEmoji(value)}
          emoji={emoji}
          emojiDirection={'up'}
          label={encrypted ? 'Edit Encrypted Note' : 'Edit Note'}
          disabled={undefined}
          baseCharacterCount={undefined}
          defaultBody={undefined}
        />
        :
        <pre className={classes.noteBody}>
          {displayNote()}
        </pre>
      }
    </div>
  );
};

type OfficeNotesProps = {
  isOpen: boolean;
  close: () => void;
}

const OfficeNotes = ({ isOpen, close }: OfficeNotesProps) => {
  const classes = useStyles();
  const notesState = usePromise<number, Response>(getOfficeNotes, { data: [] });
  const [allNotes, setAllNotes] = useState<NoteType[]>([]);
  const [notes, setNotes] = useState<NoteType[]>([]);
  const [query, setQuery] = useState('');

  const { officeId } = useSelector((state) => ({
    officeId: pathOr(0, ['login', 'office', 'id'], state),
  }));

  const getNotes = () => {
    notesState.invoke(officeId).then(({ data }) => {
      const all: NoteType[] = reverse(sortBy(prop('created'), data));
      setNotes(all);
      setAllNotes(all);
    });
  };

  React.useEffect(() => {
    getNotes();
  }, []);

  React.useEffect(() => {
    const remaining = allNotes.filter((n) =>
      (n.encrypted && n.encrypted.toLowerCase().indexOf(query) !== -1)
      ||
      (n.note && n.note.toLowerCase().indexOf(query) !== -1)
    );
    setNotes(remaining);
  }, [query]);


  const deleteNote = (id: number) => {
    if (id === 0) {
      setNotes(notes.filter(({ noteId }) => id !== noteId));
      setAllNotes(allNotes.filter(({ noteId }) => id !== noteId));
    } else {
      axios.delete(`${process.env.API_URL}/office/notes/${id}`, config).then(() => {
        setNotes(notes.filter(({ noteId }) => id !== noteId));
        setAllNotes(allNotes.filter(({ noteId }) => id !== noteId));
      }).catch((e) => {
        console.error(e);
      });
    }
  };

  const addNote = () => {
    const data: NoteType = {
      note: '',
      encrypted: '',
      created: '',
      noteId: 0,
      isEdit: true,
    };
    setNotes(prepend(data, notes));
  };

  const unsavedNote = find(propEq('noteId', 0), notes);

  return (
    <div className={classes.notesMain}>
      {!isOpen &&
        <div className={classes.notesTabLabel}>
          NOTES
        </div>}
      {isOpen &&
        <div className={classes.floatingButtons}>
          <div>
            <IconButton onClick={() => close()}>
              <CloseIcon />
            </IconButton>
            <br />
            <IconButton
              className={notesState.loading ? 'sked-spin' : ''}
              onClick={() => getNotes()}
            >
              <CachedIcon />
            </IconButton>
          </div>
          <IconButton
            style={{ marginBottom: '20px' }}
            disabled={!!unsavedNote}
            onClick={() => addNote()}>
            <AddIcon />
          </IconButton>
        </div>}
      {isOpen &&
        <TextField
          autoFocus
          fullWidth
          id="login-email"
          label="Search"
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          style={{
            width: '480px',
            marginLeft: '30px',
            marginBottom: '10px',
            marginTop: '5px',
          }}
        />}
      <div className={classes.notesListContainer}>
        <div className={classes.notesList}>
          <div className={classes.notesContainer}>
            {!isEmpty(notes) &&
              notes.map((n) =>
                <Note
                  key={n.noteId}
                  note={n}
                  officeId={officeId}
                  deleteNote={deleteNote}
                  setNotes={(data: NoteType, noteId: number) => {
                    if (noteId) {
                      const n = notes.map((n) => {
                        if (noteId === n.noteId) {
                          return data;
                        } else {
                          return n;
                        }
                      });
                      const allN = allNotes.map((n) => {
                        if (noteId === n.noteId) {
                          return data;
                        } else {
                          return n;
                        }
                      });
                      setNotes(n);
                      setAllNotes(allN);
                    } else {
                      setNotes(prepend(data, notes.filter(({ created }) => created)));
                      setAllNotes(prepend(data, allNotes.filter(({ created }) => created)));
                    }
                  }}
                />)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfficeNotes;
