import React, { useState } from 'react';

import DoneIcon from '../../icons/Done.icon';

import FilterHeader from '../FilterHeader/FilterHeader.component';
import HeaderButton from '../HeaderButton/HeaderButton.component';

import { useStyles } from './filterSelect.styles';

import {
  MenuItem,
  Popover,
  Grid,
  ListItemText,
  Radio
} from '@mui/material';

type FilterSelectProps = {
  save?: ({ isConfirmed }: { isConfirmed: string }) => void;
  setSelected: (value: string) => void;
  selected: string;
  items: string[];
  title?: string;
  headerTitle?: string;
  Icon?: ({ color, className }: { color?: string, className?: string }) => JSX.Element;
  noneText?: string;
  type?: 'hide' |'filter' |'sort' | 'group' | 'archiveChat';
  horizontalMenu?: 'right' | 'left' | 'center',
  onlyIcon?: boolean;
  neverOnlyIcon?: boolean;
  simpleList?: boolean;
  minWidth?: number;
  className?: string;
  nameForTest?: string;
  saveClassName?: string;
  resetClassName?: string;
}

const FilterSelect = ({
  setSelected, save, selected, items, title, onlyIcon, neverOnlyIcon, Icon,
  simpleList, headerTitle, type, minWidth, noneText = 'Any',
  horizontalMenu = 'left', saveClassName, resetClassName, className, nameForTest,
}: FilterSelectProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReset = () => {
    save?.({ isConfirmed: noneText });
    setSelected(noneText);
    handleClose();
  };

  const handleSelected = (value: string) => {
    setSelected(value);
    save?.({ isConfirmed: value });
    handleClose();
  };

  const getLowerCase = (text: string) => {
    return text ? text.toLowerCase().replace(/ /g, '-') : '';
  };

  const getClassTestName = (text: string) => {
    return `sked-test-${nameForTest || 'filter'}-${getLowerCase(text)}`;
  };

  const SelectedIcon = Icon || DoneIcon;

  return (
    <Grid>
      <Grid marginRight={0.5}>
        <HeaderButton
          title={title || 'Appt. Confirmed'}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          Icon={SelectedIcon}
          active={selected !== noneText}
          type={type}
          onlyIcon={onlyIcon && !neverOnlyIcon}
          className={className}
        />
      </Grid>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paper }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: horizontalMenu,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: horizontalMenu,
        }}
      >
        <Grid minWidth={minWidth || 280}>
          {!simpleList && (
            <FilterHeader
              title={headerTitle || title || 'Appt. Confirmed'}
              reset={handleReset}
              resetDisabled={selected === noneText}
              saveClassName={saveClassName}
              resetClassName={resetClassName}
            />
          )}
          <Grid padding={1} paddingBottom={1}>
            {items?.map(item => (
              <MenuItem
                key={item}
                onClick={() => handleSelected(item)}
                className={`${selected === item ? classes.selected : classes.selectItem} ${getClassTestName(item)}`}
                value={item}
              >
                {!simpleList && <Radio checked={selected === item} />}
                <ListItemText className={`${classes.selectFontSize} ${simpleList ? classes.simpleList : ''}`} primary={item} />
              </MenuItem>
            ))}
          </Grid>
        </Grid>
      </Popover>
    </Grid>
  );
};

export default FilterSelect;
