import React from 'react';

type BannerPreviewProps = {
  color: string;
  text: string;
  textColor: string;
  headerTitle?: string;
  marginTop?: number;
  linkText?: string;
  link?: string;
  align?: string;
};

import { useStyles } from './bannerPreview.styles';

const BannerPreview = ({
  color,
  text,
  textColor,
  headerTitle,
  marginTop,
  linkText,
  link,
  align = 'left',
}: BannerPreviewProps) => {
  const classes = useStyles();

  if (!text) {
    return null;
  }

  return (
    <div style={{ marginTop: marginTop || 0 }}>
      {!!headerTitle && <span>{headerTitle}</span>}
      <div
        style={{ background: color, textAlign: align as 'center' | 'left' }}
        className={headerTitle ? classes.container : classes.largeText}
      >
        <div className={classes.link} style={{ color: textColor }}>
          {text}&nbsp;
          {!!linkText && (
            <a
              className={link ? '' : classes.disabled}
              target='_blank'
              href={link}
              style={{ color: textColor, textDecoration: 'underline' }}
            >
              {linkText}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default BannerPreview;
