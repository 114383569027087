import React from 'react';
import { Button } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { outputLocation } from '../../../../services/utilities';
import ClientChip, { Client } from '../ClientChip/ClientChip.component';

type ClientListProps = {
  clients: {
    data: Client[];
    page: number;
    totalCount: number;
    totalPages: number;
    search: string;
  },
  onClick: (client: Client) => void;
  queryTags?: object;
  getClients: ({ query, search, page }: {
    query: {
      firstName: string,
      lastName: string,
      phone: string,
      tags?: object
    },
    search: string,
    page: number
  }) => void;
}

const ClientList = ({ clients, getClients, onClick, queryTags }: ClientListProps) => {
  return (
    <div>
      {clients.data.map((client, ind) => {
        return (
          <ClientChip
            key={ind}
            client={client}
            onClick={onClick}
          />
        );
      })}

      <div
        style={{
          marginLeft: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
        }}>
        <div style={{ marginRight: '5px' }}>
          {outputLocation(clients.page, 25, clients.totalCount, clients.data.length)}
        </div>
        <Button
          style={{ width: '50px', minWidth: 'unset' }}
          disabled={clients.page === 1}
          onClick={() => {
            const splitUp = clients.search.split(' ');
            const lastName = splitUp[0] ? splitUp[0].trim() : undefined;
            const firstName = splitUp[1] ? splitUp[1].trim() : undefined;
            const phone = splitUp[2] ? splitUp[2].trim() : undefined;
            getClients({
              query: {
                firstName,
                lastName,
                phone,
                tags: queryTags,
              },
              search: clients.search,
              page: clients.page - 1,
            });
          }} >
          <ArrowLeftIcon />
        </Button>
        <Button
          style={{ width: '50px', minWidth: 'unset' }}
          disabled={clients.page === clients.totalPages}
          onClick={() => {
            const splitUp = clients.search.split(' ');
            const lastName = splitUp[0] ? splitUp[0].trim() : undefined;
            const firstName = splitUp[1] ? splitUp[1].trim() : undefined;
            const phone = splitUp[2] ? splitUp[2].trim() : undefined;
            getClients({
              query: {
                firstName,
                lastName,
                phone,
                tags: queryTags,
              },
              search: clients.search,
              page: clients.page + 1,
            });
          }} >
          <ArrowRightIcon />
        </Button>
      </div>
    </div>
  );
};

export default ClientList;
