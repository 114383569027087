import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import * as R from 'ramda';
import {
  Button, Snackbar, Divider, Paper, IconButton,
  FormControl, InputLabel, MenuItem, Select, TextField, Grid,
  TableBody, TableRow,
} from '@mui/material';
import { Menu, Tooltip, } from '../../../../components/components.component';
import { makeStyles, withStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';
import * as businessActions from './business.actions.js';
import {
  PopupTemplate, popup, popupWithCancel,
} from '../../../../services/Popup.js';
import { countries, timezones } from '../../../../services/globals.js';
import Header from '../../../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../../../components/HeaderButton/HeaderButton.component';
import { TableContainer, BodyCell } from '../../../../components/CustomTable';
import HeaderTabs from '../../../../components/HeaderTabs/HeaderTabs.component';
import { useSelector } from '../../../../reducers';
import UsersPage from '../../../Admin/Users/users.page';
import './business.css';
import { usePromise } from '../../../../services/promise.hook';

const Input = withStyles({
  root: {
    width: '100%',
    marginBottom: 15
  },
})(TextField);


const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
    overflowY: 'auto',
  },
  paper: {
    padding: '10px',
    marginTop: '10px',
  },
  tab: {
    marginTop: 0,
  },
  cancel: {
    marginLeft: 10
  },
  editTitle: {
    fontSize: 21,
    marginBottom: 10
  },
  logoutButton: {
    color: '#7c7c80',
    fontSize: 16,
    marginLeft: 5,
  },
}));


const DisplayEditBusiness = ({
  officeForm,
  officeAddressForm,
  admin,
  handleClickSave,
  edit = false,
  locations,
  hasLocations,
  hasUsers,
}) => {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(edit,);
  const [_cancel, setCancel] = useState(false);
  const [office, setOffice] = useState({});
  const [address, setAddress] = useState({});
  useEffect(() => {
    if (isEdit) {
      setOffice(officeForm);
      setAddress(officeAddressForm);
    }
  }, [isEdit]);
  const editOffice = (data) => {
    setOffice(R.merge(office, data));
  };
  const editOfficeAddress = (data) => {
    setAddress(R.merge(address, data));
  };
  return (
    <>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}>
        {(!hasLocations || !locations.length) ? (
          <p style={{ marginLeft: '10px', marginBottom: '20px', color: 'gray' }}>
            The organization name, phone number, and address are displayed in the app.
          </p>
        ) : <div />}
        <div className="search">
          {!isEdit &&
            <IconButton
              style={{ marginRight: '10px' }}
              variant='contained'

              onClick={() => setIsEdit(true)}>
              <EditIcon />
            </IconButton>
          }
        </div>
      </div>
      <Paper className={classes.paper}>
        {isEdit ?
          <FormControl fullWidth>
            <legend className={classes.editTitle}>Edit Business Information</legend>

            <Input
              value={office.name}
              onChange={e => editOffice({ name: e.target.value })}
              placeholder="Organization Name"
              label="Organization Name"
            />
            {(!hasUsers || admin) &&
              <Input
                value={office.email}
                onChange={e => editOffice({ email: e.target.value })}
                placeholder="Email"
                label='Login Email'
              />}
            <Input
              value={office.notificationEmail}
              onChange={e => editOffice({ notificationEmail: e.target.value })}
              placeholder="Notification Email"
              label="Notification Email" />
            <Input
              value={office.phone}
              onChange={e => editOffice({ phone: e.target.value })}
              placeholder="Phone Number"
              label="Phone Number" />
            {!R.isNil(address.country) &&
              <FormControl fullWidth>
                <InputLabel id="select-country">Country:</InputLabel>
                <Select
                  labelId="select-country"
                  label="Country:"
                  value={address.country}
                  onChange={(event) => editOfficeAddress({ country: event.target.value })}
                >
                  {countries.map(opt =>
                    <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                  )}
                </Select>
                <br />
              </FormControl>
            }
            {admin && !R.isNil(office.timezone) &&
              <FormControl fullWidth>
                <InputLabel id="select-timezone">Timezone:</InputLabel>
                <Select
                  labelId="select-timezone"
                  label="Timezone:"
                  value={office.timezone}
                  onChange={(event) => editOffice({ timezone: event.target.value })}
                >
                  {R.filter(({ country }) => country === address.country)(timezones).map(opt =>
                    <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                  )}
                </Select>
                <br />
              </FormControl>
            }
            <Input
              onChange={e => editOfficeAddress({ street: e.target.value })}
              value={address.street}
              placeholder="Mailing Address"
              label="Mailing Address" />
            <Input
              onChange={e => editOfficeAddress({ city: e.target.value })}
              value={address.city}
              placeholder="City"
              label="City" />
            <Input
              onChange={e => editOfficeAddress({ state: e.target.value })}
              value={address.state}
              placeholder="State"
              label="State" />
            <Input
              onChange={e => editOfficeAddress({ zipCode: e.target.value })}
              value={address.zipCode}
              placeholder="Zip"
              label="Zip" />
            <Divider />
            <br />
            <Grid>
              <Button
                variant='contained'
                disabled={R.pipe(R.merge(R.dissoc('street2', address)), R.values, R.any(R.isEmpty))(office)}
                onClick={() => {
                  handleClickSave(office, address);
                  setIsEdit(false);
                }}>
                Save
              </Button>
              <Button
                onClick={() => {
                  setIsEdit(false);
                  setCancel(true);
                }}
                variant="outlined"
                className={classes.cancel}
              >
                Cancel
              </Button>
            </Grid>
          </FormControl>
          :
          <TableContainer>
            <TableBody>
              <TableRow>
                <BodyCell>Organization Name</BodyCell>
                <BodyCell>{officeForm.name}</BodyCell>
              </TableRow>
              {(!hasUsers || admin) &&
                <TableRow>
                  <BodyCell>Login Email</BodyCell>
                  <BodyCell>{officeForm.email}</BodyCell>
                </TableRow>
              }
              <TableRow>
                <BodyCell>Notification Email</BodyCell>
                <BodyCell>{officeForm.notificationEmail}</BodyCell>
              </TableRow>
              <TableRow>
                <BodyCell>Phone Number</BodyCell>
                <BodyCell>{officeForm.phone}</BodyCell>
              </TableRow>
              {!R.isNil(officeAddressForm.country) &&
                <TableRow>
                  <BodyCell>Country</BodyCell>
                  <BodyCell>{officeAddressForm.country}</BodyCell>
                </TableRow>}
              <TableRow>
                <BodyCell>Mailing Address</BodyCell>
                <BodyCell>{officeAddressForm.street}</BodyCell>
              </TableRow>
              <TableRow>
                <BodyCell>City</BodyCell>
                <BodyCell>{officeAddressForm.city}</BodyCell>
              </TableRow>
              <TableRow>
                <BodyCell>State</BodyCell>
                <BodyCell>{officeAddressForm.state}</BodyCell>
              </TableRow>
              <TableRow>
                <BodyCell>Zip</BodyCell>
                <BodyCell>{officeAddressForm.zipCode}</BodyCell>
              </TableRow>
              {!R.isNil(officeForm.timezone) &&
                <TableRow>
                  <BodyCell>Timezone</BodyCell>
                  <BodyCell>{R.pipe(
                    R.find(R.propEq('value', officeForm.timezone)),
                    R.propOr(officeForm.timezone, 'label')
                  )(timezones)}</BodyCell>
                </TableRow>}
            </TableBody>
          </TableContainer>
        }
      </Paper>
    </>
  );
};

const warningBody = 'This setting will log the user out of all devices. User login credentials and privileges will remain unchanged. Please note this action may take up to 15 minutes.';

export const LogoutButton = ({
  admin, hasMultipleOfficeusers, user
}) => {
  const classes = useStyles();
  const [logoutAnchor, setLogoutAnchor] = useState(null);

  const logoutState = usePromise(businessActions.logoutUsers, null);

  const openLogoutOptions = (e) => {
    setLogoutAnchor(e.currentTarget);
  };

  const closeLogout = () => setLogoutAnchor(null);

  const logoutUsers = (value) => () => {
    popupWithCancel(
      'Are you sure?',
      warningBody,
      () => {
        logoutState.invoke({ value, user });
        setLogoutAnchor(null);
      },
      'danger',
      `Log out${value === 'me' ? '' : ' users'}`
    );
  };

  const menuItems = React.useMemo(() => {
    const me = {
      label: 'Log out my account',
      value: 'me',
    };
    if (admin) {
      return [{
        label: 'Log out all office users (Including office admin)',
        value: 'all',
      }, {
        label: 'Log out office users (Excluding office admin)',
        value: 'all-but-admin',
      }];
    }
    if (hasMultipleOfficeusers) {
      return [me, {
        label: 'Log out all other users',
        value: 'not-me',
      }];
    }
    return [me];
  }, [admin, hasMultipleOfficeusers]);

  const onlyOne = menuItems.length === 1;

  const title = onlyOne ? 'Log out my account' : 'Log out users';

  return (
    <>
      <HeaderButton
        title={title}
        children={
          <>
            <span>{title}</span>
            <Tooltip arrow title={warningBody}>
              <HelpIcon className={classes.logoutButton} />
            </Tooltip>
          </>
        }
        EndIcon={!onlyOne && ExpandMore}
        type='sort'
        onClick={onlyOne ? logoutUsers(menuItems[0].value) : openLogoutOptions}
      />
      <Menu
        open={Boolean(logoutAnchor)}
        anchorEl={logoutAnchor}
        onClose={closeLogout}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {(menuItems || []).map((item) => {
          return (
            <MenuItem onClick={logoutUsers(item.value)}>{item.label}</MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const Business = ({ history }) => {
  const classes = useStyles();
  const [tab, setTab] = useState('main');
  const [showNew, setShowNew] = useState(false);

  const dispatch = useDispatch();
  const {
    getUser,
    getOffice,
    patch,
    saveForms,
    getLocations,
  } = bindActionCreators(businessActions, dispatch);

  const {
    office, admin, officeForm, officeAddressForm, snackbar,
    hasLocations, locations, hasMultipleOfficeusers, user,
    hasReset,
  } = useSelector((state) => ({
    ...R.prop('login', state),
    ...R.prop('business', state),
    hasLocations: R.pipe(
      R.pathOr([], ['login', 'features'])
      , R.includes('Locations'),
    )(state),
    hasMultipleOfficeusers: R.pipe(
      R.pathOr([], ['login', 'features'])
      , R.includes('MultipleOfficeUsers')
    )(state),
    hasReset: R.pipe(
      R.pathOr([], ['login', 'features'])
      , R.includes('ResetOfficeUsers'),
    )(state),
  }));

  useEffect(() => {
    getUser(history);
    getOffice(office.id, history);
  }, []);

  useEffect((newProps) => {
    if (newProps !== office) {
      getUser(history);
      getOffice(office.id, history);
    }
  }, [office]);

  useEffect(() => {
    if (hasLocations) {
      getLocations();
    }
  }, [hasLocations]);

  const handleClickSave = (officeForm, officeAddressForm) => {
    const id = office.id;
    const withOutTimezone =
      admin ?
        officeForm :
        R.merge(officeForm, { timezone: undefined });
    saveForms({
      id,
      officeAddress: officeAddressForm,
      office: withOutTimezone,
      email: officeForm.email,
      admin,
    }, popup);
  };

  return (
    <>
      <Header
        title='Business'
        leftIcons={[
          <HeaderTabs
            tabs={[
              { label: officeForm.name, value: 'main' },
              hasMultipleOfficeusers && { label: 'Manage Users', value: 'users' }
            ]}
            value={tab}
            setTab={setTab}
          />
        ]}
        rightIcons={[
          hasReset && tab === 'users' &&
            <LogoutButton
              admin={admin}
              hasMultipleOfficeusers={hasMultipleOfficeusers}
              user={user}
            />,
          tab === 'users' &&
            <HeaderButton
              title='Add user'
              Icon={AddIcon}
              onClick={() => setShowNew(true)}
            />
        ]}
      />
      <div
        className={classes.root}
        style={{
          paddingTop: tab === 'users' ? 0 : 10,
        }}>
        <PopupTemplate />
        <Snackbar
          open={snackbar}
          message="Business Information Saved"
          autoHideDuration={4000}
          onClose={() => patch({ snackbar: false })}
        />
        <div hidden={tab !== 'main'} className={classes.tab}>
          <DisplayEditBusiness
            key='main'
            officeForm={officeForm}
            officeAddressForm={officeAddressForm}
            admin={admin}
            handleClickSave={handleClickSave}
            hasLocations={hasLocations}
            locations={locations}
            hasUsers={hasMultipleOfficeusers}
          />
        </div>
        <div hidden={tab !== 'users'} className={classes.tab}>
          <UsersPage isAdminSettings showNew={showNew} setShowNew={setShowNew} />
        </div>
      </div>
    </>
  );
};

export default Business;
