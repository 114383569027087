import { AnyAction } from 'redux';
import * as R from 'ramda';
import * as at from '../../actionTypes';
import { Office } from '../Login/login.reducer';

export type UserClient = {
  email: string;
  userId: number;
  facebook?: boolean;
  google?: boolean;
  apple?: boolean;
}

export type Affiliate = {
  id: number;
  name: string;
  description: string;
  isHidden: boolean;
  created?: string;
}

type AdminReducer = {
  busy: boolean;
  state: string;
  client: UserClient;
  affiliates: Affiliate[],
  affiliate: Affiliate,
  selectedOffices: Office[],
}

const init: AdminReducer = {
  busy: false,
  state: 'INPUT',
  client: {} as UserClient,
  affiliates: [],
  affiliate: {} as Affiliate,
  selectedOffices: [],
};

export default function reducer(admin = init, action: AnyAction): AdminReducer {
  switch (action.type) {
    case at.ADMIN_PATCH:
      return R.merge(admin, action.data);
    case at.ADMIN_GET_CLIENT:
      if (action.state === 'REQUEST') {
        return R.merge(admin, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        if (action.data.state === 'ERROR') {
          return R.merge(admin, {
            busy: false,
            state: 'INPUT',
          });
        }
        return R.merge(admin, {
          client: action.data,
          busy: false,
          state: 'SHOW',
        });
      } else if (action.state === 'ERROR') {
        return R.merge(admin, {
          busy: false,
          state: 'INPUT',
        });
      }
      return admin;
    case at.ADMIN_DELETE_CLIENT:
      if (action.state === 'REQUEST') {
        return R.merge(admin, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        if (action.data.state === 'ERROR') {
          return R.merge(admin, {
            busy: false,
            state: 'SHOW',
          });
        }
        return R.merge(admin, {
          busy: false,
          state: 'INPUT',
          client: {} as UserClient,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(admin, {
          busy: false,
          state: 'SHOW',
        });
      }
      return admin;
    case at.ADMIN_GET_AFFILIATES: {
      if (action.state === 'REQUEST') {
        return R.merge(admin, {
          busy: true,
          state: 'SHOW',
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(admin, {
          busy: false,
          affiliates: action.data,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(admin, {
          busy: false,
        });
      }
      break;
    }
    case at.ADMIN_SELECT_OFFICES_FOR_AFFILIATE: {
      return R.evolve({
        selectedOffices: (s) => {
          const sing = R.without(s, action.data);
          return R.concat(sing, s);
        }
      }, admin);
    }
    case at.ADMIN_REMOVE_OFFICES_FOR_AFFILIATE: {
      const offices: Office[] = action.data;
      return R.evolve({
        selectedOffices: R.without(offices),
      }, admin);
    }
    case at.ADMIN_SAVE_AFFILIATE: {
      if (action.state === 'REQUEST') {
        return R.merge(admin, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(admin, {
          affiliates: action.data,
          affiliate: {} as Affiliate,
          selectedOffices: [],
          state: 'SHOW',
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(admin, {
          busy: false,
        });
      }
      break;
    }
    case at.ADMIN_DELETE_AFFILIATE: {
      if (action.state === 'REQUEST') {
        return R.merge(admin, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(admin, {
          busy: false,
          affiliates: R.filter(({ id }) => id !== action.data)(admin.affiliates)
        });
      } else if (action.state === 'ERROR') {
        return R.merge(admin, {
          busy: false,
        });
      }
      break;
    }
    case at.ADMIN_GET_AFFILIATE_OFFICES: {
      if (action.state === 'REQUEST') {
        return R.merge(admin, {
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return R.merge(admin, {
          busy: false,
          selectedOffices: action.data,
        });
      } else if (action.state === 'ERROR') {
        return R.merge(admin, {
          busy: false,
        });
      }
      break;
    }
    default:
      return admin;
  }
}
