import * as R from 'ramda';
import axios from 'axios';
import queryString from 'query-string';
import LocalSession from './local-session';

export const skedApi = axios.create({
  baseURL: process.env.API_URL,
  responseType: 'text'
});

let accessTokenExpireDate = undefined;
let userType = null;

export const setSessionHeaders = (access_token) => {
  const session = R.pipe(
    R.split('.'),
    R.nth(1),
    atob,
    s => JSON.parse(s),
  )(access_token);
  accessTokenExpireDate = R.pipe(
    R.prop('exp'),
    d => new Date(d * 1000)
  )(session);
  userType = session.userType;
  console.log('Token expires at: ', accessTokenExpireDate);
  skedApi.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  skedApi.defaults.headers.common['Content-Type'] = 'application/json';
  skedApi.defaults.headers.common.Accept = 'application/json;charset=utf-8';
};

export const setOfficeHeader = (officeId) => {
  skedApi.defaults.headers.common['X-As-Office'] = officeId;
  const session = R.assoc('officeId', officeId, JSON.parse(localStorage.getItem('sked-session')));
  localStorage.setItem('sked-session', JSON.stringify(session));
};

export const clearOfficeHeader = () => {
  delete skedApi.defaults.headers.common['X-As-Office'];
  const session = R.assoc('officeId', undefined, JSON.parse(localStorage.getItem('sked-session')));
  localStorage.setItem('sked-session', JSON.stringify(session));
};

export const clearSessionHeaders = () => {
  // accessTokenExpireDate = undefined;
  delete skedApi.defaults.headers.common.Authorization;
  clearOfficeHeader();
};

export const postAuth = body => axios.post(
  `${process.env.AUTH_URL}/auth/token`,
  queryString.stringify(body),
  {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic YWRtaW46dGVzdA=='
    }
  }
).then((data) => {
  return data;
});


export const getNewToken = refresh_token => postAuth({
  refresh_token,
  grant_type: 'refresh_token'
}).then( ({ data: session }) => {
  setSessionHeaders(session.access_token);
  LocalSession.setSession(session);
  const raw = R.pipe(
    R.split('.'),
    R.nth(1),
    atob,
    s => JSON.parse(s),
  )(session.access_token);
  accessTokenExpireDate = R.pipe(
    R.prop('exp'),
    d => new Date(d * 1000)
  )(raw);
  userType = raw.userType;
  return session;
});

let timeout = null;
const checkRefreshToken = action => Promise.resolve().then( () => {
  if (userType === 'Admin' && process.env.NODE_ENV !== 'development') {
    Object.keys(window).forEach(key => {
      if (/^on(blur|focus|load|resize|scroll|click|dblclick|change|select|submit|keypress|error)/.test(key)) {
        window.addEventListener(key.slice(2), () => {
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            console.log('Inactive for too long... Bye-Bye!');
            LocalSession.destroySession();
            clearSessionHeaders();
            window.location.reload();
          }, 60 * 1000 * 10); // 10 minutes
        });
      }
    });
  }
  if (accessTokenExpireDate < new Date()) {
    console.log('access token is is expired!');
    const refresh_token = R.propOr('', 'refresh_token', LocalSession.getSession());
    if (!refresh_token) {
      throw new Error('No refresh token!');
    }
    return getNewToken(refresh_token);
  }
  return;
}).then( () => action() );

const api = {
  get: (url, params, options) => checkRefreshToken(() =>
    skedApi.get(url, { params }, options).then((response) => response.data)),
  post: (url, body, options) => checkRefreshToken(() =>
    skedApi.post(url, body, options).then((response) => response.data)),
  patch: (url, body, options) => checkRefreshToken(() =>
    skedApi.patch(url, body, options).then((response) => response.data)),
  put: (url, body, options) => checkRefreshToken(() =>
    skedApi.put(url, body, options).then((response) => response.data)),
  delete: (url, body) => checkRefreshToken(() =>
    skedApi.delete(url, body).then((response) => response.data)),
  ajax: (params) => checkRefreshToken(() =>
    skedApi(params).then((response) => response.data)),
};

export const oldApi = {
  get: (url, params) =>
    skedApi.get(url, { params }).then((response) => response.data),
  post: (url, body, options) =>
    skedApi.post(url, body, options).then((response) => response.data),
  patch: (url, body, options) =>
    skedApi.patch(url, body, options).then((response) => response.data),
  put: (url, body, options) =>
    skedApi.put(url, body, options).then((response) => response.data),
  delete: (url, body, options) =>
    skedApi.delete(url, body, options).then((response) => response.data),
  ajax: (params) =>
    skedApi(params).then((response) => response.data),
};

export default api;
