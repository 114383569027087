import React, { useEffect, useState } from 'react';
import { LocalDateTime, ZoneId, ChronoUnit } from '@js-joda/core';
import {
  Typography,
  Grid,
  Button,
} from '@mui/material';

import Header from '../../components/PageHeader/PageHeader.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import OpenIcon from '../../icons/OpenInNewTab.icon';

import { useSelector } from '../../reducers';

import { useStyles } from './sparkWebinar.styles';

type DateType = {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
}

const SignUp = () => {
  const classes = useStyles();

  const calculateTimeLeft = () => {
    const now = LocalDateTime.now(ZoneId.of('America/New_York'));
    const targetDate = LocalDateTime.parse('2024-05-23T13:00:00').atZone(ZoneId.of('America/New_York')).toLocalDateTime();
    if (now.isAfter(targetDate)) {
      return {
        days: '0',
        hours: '0',
        minutes: '0',
        seconds: '0',
      };
    }
    const difference = now.until(targetDate, ChronoUnit.SECONDS);

    let timeLeft: DateType;

    if (difference > 0) {
      const days = Math.floor(difference / (24 * 60 * 60));
      const hours = Math.floor((difference / (60 * 60)) % 24);
      const minutes = Math.floor((difference / 60) % 60);
      const seconds = Math.floor(difference % 60);

      timeLeft = {
        days: days > 9 ? days.toString() : `0${days}`,
        hours: hours > 9 ? hours.toString() : `0${hours}`,
        minutes: minutes > 9 ? minutes.toString() : `0${minutes}`,
        seconds: seconds > 9 ? seconds.toString() : `0${seconds}`,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const { headerHeight } = useSelector((state) => ({
    headerHeight: state.login.headerHeight,
  }));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <Grid>
      <Header
        title={'Let’s Spark Something'}
        rightIcons={[
          <a target='_blank' href='https://us02web.zoom.us/meeting/register/tZwpdO6srj4vHtGnOvLhp4GVUzsJTaCx2NDy#/registration'>
            <HeaderButton
              title={'Sign up for the Webinar'}
              Icon={OpenIcon}
              color='primary'
              iconClass={classes.icon}
              marginRight={5}
            />
          </a>
        ]}
      />
      <div className={classes.root} style={{ height: `calc(100vh - ${headerHeight + 45 }px)` }}>
        <Grid>
          <Typography className={classes.info}>
            Partnering with <span>The Smart Chiropractor</span>
          </Typography>

          <Typography className={classes.sparkText}>
          SPARK: the fastest, easiest way to grow your practice
          </Typography>
        
          <Typography className={classes.info} style={{ marginTop: 15 }}>
            Join us on Thursday, May 23rd, for the official release of SPARK! It’s time for you to help more people and generate more revenue. SPARK helps you automatically attract more new patients and reactivate more of your inactive patients. The best part is that you only pay if they show up.
          </Typography>
        </Grid>

        <Typography className={classes.title}>
          Why You’ll Love Spark
        </Typography>

        <ul className={classes.list}>
          <li className={classes.info}>
            <span>More New Patients:</span> New patients can easily and seamlessly schedule a visit anywhere they find you online. Whether it’s through search engines (like Google), paid ads, or your website, you can skyrocket your new patients by easily scheduling 24/7/365.
          </li>
          <li className={classes.info}>
            <span>More Patient Reactivations:</span> Automated email and text campaigns reach out to your patients when they are most likely to reactivate, making it easy for them to simply click a link and come back into your practice.
          </li>
          <li className={classes.info}>
            <span>Only Pay if They Show:</span> You can bring in more new patients and keep them coming back, knowing you only pay if they show.
          </li>
        </ul>

        <Button
          LinkComponent={'a'}
          target='_blank'
          href='https://us02web.zoom.us/meeting/register/tZwpdO6srj4vHtGnOvLhp4GVUzsJTaCx2NDy#/registration'
          className={classes.button}
          variant='contained'
        >
          <OpenIcon />
            Sign up for the Webinar
        </Button>

        <Typography className={classes.title} fontSize={18}>
          Event Details
        </Typography>

        <Grid display="flex">
          <Grid className={classes.dateContent}>
            <Typography className={classes.date}>{timeLeft.days}</Typography>
            <Typography className={classes.info}>Days</Typography>
          </Grid>
          <Grid>
            <Typography className={classes.date}>&nbsp;:&nbsp;</Typography>
          </Grid>
          <Grid className={classes.dateContent}>
            <Typography className={classes.date}>{timeLeft.hours}</Typography>
            <Typography className={classes.info}>Hours</Typography>
          </Grid>
          <Grid>
            <Typography className={classes.date}>&nbsp;:&nbsp;</Typography>
          </Grid>
          <Grid className={classes.dateContent}>
            <Typography className={classes.date}>{timeLeft.minutes}</Typography>
            <Typography className={classes.info}>Minutes</Typography>
          </Grid>
          <Grid>
            <Typography className={classes.date}>&nbsp;:&nbsp;</Typography>
          </Grid>
          <Grid className={classes.dateContent}>
            <Typography className={classes.date}>{timeLeft.seconds}</Typography>
            <Typography className={classes.info}>Seconds</Typography>
          </Grid>
        </Grid>

        <ul className={classes.list}>
          <li className={classes.info}>
            <span>Date:</span> May 23, 2024
          </li>
          <li className={classes.info}>
            <span>Time:</span> 01:00 PM EST (US and Canada)
          </li>
          <li className={classes.info}>
            <span>Location:</span> Your Computer - Access Anywhere!
          </li>
        </ul>
      </div>
    </Grid>
  );
};

export default SignUp;
