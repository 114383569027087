import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Chip,
  Tooltip,
  Typography,
  IconButton,
  Grid,
} from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import * as R from 'ramda';

import { Lead, Otpout } from '../../leads.reducer';
import * as actions from '../../leads.actions';
import { useSelector } from '../../../../reducers';
import EditTags from '../../../../components/EditTags/EditTags.component';

import { useStyles } from './editLead.styles';

type EditFieldsProps = {
  lead?: Lead;
  disabled?: boolean;
  handleEdit: (key: string, value: string | boolean) => void;
  setSeeMore: (value: boolean) => void;
  tags: { tagId: number, tag: string }[];
  updateAllowEmail?: () => void;
  updateAllowSms?: () => void;
  addTag?: (tag: { tagId: number, tag: string }) => void;
  deleteTag?: (tag: { tagId: number, tag: string }) => void;
  smallModal?: boolean;
  handleSetEdit?: () => void;
  setLeadEdit?: (value: Lead) => void;
  handleSave?: () => void;
}

const EditFields = ({
  lead,
  handleEdit,
  setSeeMore,
  disabled,
  tags,
  addTag,
  deleteTag,
  updateAllowSms,
  updateAllowEmail,
  smallModal,
  handleSetEdit,
  setLeadEdit,
  handleSave
}: EditFieldsProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [editTags, setEditTags] = useState(false);

  const { features, blockSms } = useSelector((state) => ({
    features: state.login.features,
    blockSms: state.leads.leads.find((l) => l.id === lead?.id)?.blockSMS
  }));

  const { categories } = useSelector(state => state.leads);

  const handleUpdate = (category: Otpout, checked: boolean) => {
    dispatch(actions.updateCategory({ ...category, checked }, lead?.id));
  };

  const updateBlockSMS = () => {
    dispatch(actions.handleUpdateBlockedNumber({ ...lead, blockSMS: !blockSms }));
  };

  const updateBirthday = (value: string) => {
    const newLength = R.length(value);
    const originLength = R.length(lead.birthday);
    if (newLength < originLength) {
      return handleEdit('birthday', value);
    }
    const last = R.takeLast(1, value);
    const lastNumber = Number(last);
    if (newLength > 10 || Object.is(NaN, lastNumber)) {
      return;
    }
    if (newLength === 3 || newLength === 6) {
      const withoutLast = R.dropLast(1, value);
      return handleEdit('birthday', `${withoutLast}/${last}`);
    }
    handleEdit('birthday', value);
  };

  const getBirthday = (birthday: string) => {
    if (birthday?.includes('-')) {
      const [year, month, day] = birthday.split('-');
      return `${month}/${day}/${year}`;
    }
    return birthday;
  };

  const getDisplayName = (displayName: string) => {
    if (displayName === 'Appointment Reminders') {
      return 'Automated Messages';
    }
    if (displayName === 'Office Notifications') {
      return 'One Time Messages';
    }
    return displayName;
  };

  if (!lead?.id) {
    return null;
  }

  return (
    <div className={classes.sectionContainer} style={{ flexDirection: smallModal ? 'column' : 'row' }}>
      {disabled ? (
        <div className={`${classes.column} ${classes.pMargin}`}>
          {smallModal && (
            <Grid marginBottom={1}>
              <div className={classes.row}>
                <Typography variant='h4' fontWeight={400}>{`${lead?.firstName} ${lead?.lastName}`}</Typography>
                <IconButton
                  title='Edit'
                  color='default'
                  size='medium'
                  onClick={handleSetEdit}
                  className='sked-test-view-lead-modal-button-edit'
                >
                  <EditIcon className={classes.icon} />
                </IconButton>
              </div>
            </Grid>
          )}
          <span className={classes.label}>Birthday:</span>
          <p>{getBirthday(lead.birthday)}</p>
          <span className={classes.label}>Phone:</span>
          <p>{lead.phone}</p>
          <span className={classes.label}>Email:</span>
          <p>{lead.email}</p>
          <span className={classes.label}>Referral:</span>
          <p>{lead.referrer}</p>
        </div>
      ) : (
        <div className={classes.column} style={{ width: smallModal ? '100%' : '33%' }}>
          <Grid display="flex" alignItems="center">
            <TextField
              label='First Name'
              value={lead.firstName}
              required
              placeholder="First Name"
              onChange={(event) => handleEdit('firstName', event.target.value)}
            />
            &nbsp; &nbsp; &nbsp; &nbsp;
            <IconButton
              title='save'
              color='default'
              size='medium'
              onClick={handleSave}
              className='sked-test-view-lead-modal-edit-button-save'
            >
              <SaveIcon className={classes.icon} />
            </IconButton>
            <IconButton
              title='Cancel'
              size='medium'
              onClick={() => setLeadEdit(null)}
              className='sked-test-view-lead-modal-edit-button-cancel'
            >
              <CloseIcon className={classes.icon} />
            </IconButton>
          </Grid>
          <TextField
            label='Last Name'
            value={lead.lastName}
            required
            placeholder="First Name"
            onChange={(event) => handleEdit('lastName', event.target.value)}
          />
          <TextField
            label="Birthday:"
            value={getBirthday(lead.birthday)}
            placeholder='MM/DD/YYYY'
            disabled={disabled}
            onChange={(event) => updateBirthday(event.target.value)}
          />
          <TextField
            label="Phone:"
            value={lead.phone}
            placeholder="Phone"
            disabled={disabled}
            onChange={(event) => handleEdit('phone', event.target.value)}
          />
          <TextField
            label="Email:"
            value={lead.email}
            placeholder="Email"
            disabled={disabled}
            onChange={(event) => handleEdit('email', event.target.value)}
          />
          <TextField
            label="Referral:"
            value={lead.referrer}
            placeholder="referral"
            disabled={disabled}
            onChange={(event) => handleEdit('referrer', event.target.value)}
          />
        </div>
      )}
      <section className={`${classes.section} ${smallModal ? classes.small : classes.full}`}>
        {editTags ? (
          <EditTags
            addTag={addTag}
            deleteTag={deleteTag}
            tags={tags}
            onClose={() => setEditTags(false)}
          />
        ) : (
          <>
            <span>Notifications:</span>
            <FormControlLabel
              control={
                <Checkbox
                  checked={lead.allowEmail}
                  onChange={updateAllowEmail}
                  color="primary"
                />
              }
              label="Email"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={lead.allowSMS}
                  onChange={updateAllowSms}
                  color="primary"
                />
              }
              label="SMS"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={blockSms}
                  disabled={!lead.phone}
                  onChange={updateBlockSMS}
                  color="primary"
                />
              }
              label="Block SMS Number"
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: smallModal ? 8 : 0 }}>
              <span>Tags:</span>
              {!R.includes('Automations', features) &&
                <Tooltip title="Edit Lead's Tags">
                  <BorderColorIcon
                    style={{
                      marginRight: '15px',
                      color: '#008BCF',
                      fontSize: '20px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setEditTags(true)}
                  />
                </Tooltip>
              }
            </div>
            <div className={classes.tagContainer}>
              {tags.map(tag => (
                <Chip key={tag.tag} label={tag.tag} />
              ))}
            </div>
          </>
        )}
      </section>
      <section className={`${classes.section} ${smallModal ? classes.small : classes.full}`}>
        <span>Categories:</span>
        {categories.map((category) => (
          <FormControlLabel
            key={category.service}
            control={
              <Checkbox
                checked={category.checked}
                onChange={(e) => handleUpdate(category, e.target.checked)}
                color="primary"
              />
            }
            label={getDisplayName(category.displayName)}
          />
        ))}
      </section>
      {!smallModal && (
        <div className={classes.seeButton}>
          <div onClick={() => setSeeMore(false)}>
            <u>See less...</u>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditFields;
