import * as React from 'react';
import {
  Button, IconButton, TextField, ClickAwayListener, Checkbox, FormControlLabel,
} from '@mui/material';
import TrashIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {
  ComplexWidgetFileUpload, ComplexWidget, IndexAndConds,
} from '../intake-types';
import { Spacer } from '../../../components/PageHeader';
import {
  updateSimpleWidget, deleteSectionChild
} from '../intake.context';
import { OrderButtons } from './SectionChildOrderButtons.component';
import { useDispatch } from 'react-redux';
import QuestionLabel from './QuestionLabel.component';

interface FileUploadProps {
  data: ComplexWidgetFileUpload,
  required: boolean,
  id: number
  index: number;
  maxIndex: number;
  forOffice: boolean;
  questionCond: IndexAndConds;
}

interface FileUploadEditProps {
  data: ComplexWidgetFileUpload;
  onSave: (name: string, description: string, forOffice: boolean) => void;
  forOffice: boolean;
}

const FileUploadEditComponent = ({ data, onSave, forOffice }: FileUploadEditProps) => {
  const [state, setState] = React.useState({
    name: data.name,
    description: data.description,
    forOffice,
  });
  const handleUpdate = (prop: string) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    setState(s => ({ ...s, [prop]: value }));
  };
  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    setState(s => ({ ...s, forOffice: value }));
  };
  return <div>
    <ClickAwayListener onClickAway={() => {
      onSave(state.name, state.description, state.forOffice);
    }}>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.forOffice || false}
              onChange={handleCheck}
              color="primary"
            />}
          label="Private"
        />
        <br />
        <TextField
          style={{ width: '100%' }}
          InputLabelProps={{ shrink: true }}
          value={state.name}
          onChange={handleUpdate('name')}
          type="text"
          label="File Upload Button Text" />
        <TextField
          style={{ width: '100%', marginTop: '10px' }}
          InputLabelProps={{ shrink: true }}
          value={state.description}
          onChange={handleUpdate('description')}
          type="text"
          label="File Upload Description" />
      </div>
    </ClickAwayListener>
  </div>;
};

export const FileUploadComponent = ({
  id, data, index, maxIndex, forOffice, questionCond,
}: FileUploadProps) => {
  const dispatch = useDispatch();
  const [edit, setEdit] = React.useState(false);
  const saveHeader = (name: string, description: string, forOffice: boolean) => {
    setEdit(false);
    const up: ComplexWidget = {
      id,
      width: 12,
      type: 'ComplexWidget',
      forOffice,
      typeData: {
        ...data,
        name,
        description
      }
    };

    dispatch(updateSimpleWidget(up));
  };

  return (
    <div>
      {!forOffice &&
          <QuestionLabel cond={questionCond} complex />}
      <div style={{ display: 'flex' }}>
        <div><strong>File Upload</strong></div>
        <Spacer />
        <IconButton aria-label="delete question"
          onClick={() => dispatch(deleteSectionChild(id, questionCond.index))}>
          <TrashIcon fontSize="inherit" />
        </IconButton>
        <OrderButtons
          index={index}
          maxIndex={maxIndex}
          id={id}
          questionIndex={questionCond.index}
        />
      </div>
      {edit ?
        <FileUploadEditComponent
          data={data}
          onSave={saveHeader}
          forOffice={forOffice}
        /> :
        <div onClick={() => setEdit(true)}>
          <div>{data.description}</div>
          <Button
            variant="contained"
            color="primary"
            startIcon={<CloudUploadIcon />}
          >
            {data.name}
          </Button>
        </div>}
    </div>
  );
};
