import * as R from 'ramda';

export default (pages = {}, action) => {
  switch (action.type) {
    case 'PAGE_CUSTOM_REMOTE':
    case 'PAGE_REMOTE':
      if (action.state === 'REQUEST') {
        return R.mergeDeepRight(pages, {
          [action.metadata.id]: {
            loading: true
          }
        });
      }
      if (action.state === 'RESPONSE') {
        return R.mergeDeepRight(pages, {
          [action.metadata.id]: {
            loading: false,
            result: action.type === 'PAGE_REMOTE' ? action.data.result : action.data,
            cacheExpires: action.metadata.cacheExpires
          }
        });
      }
      if (action.state === 'ERROR') {
        return R.mergeDeepRight(pages, {
          [action.metadata.id]: {
            loading: false,
            error: action.error
          }
        });
      }
      return pages;
    /*
      Due to some issues with `react-router` and `redux`, you have to do
      something with with redux inside of `componentDidMount` for react-router
      to mark the page as "active." This will cause a bug in the nav bar where
      it won't highlight the active page.
    */
    case 'PAGE_DO_NOTHING': {
      return R.merge(pages, action.data);
    }
    case 'AUTH_LOGOUT':
      return {};
    default:
      return pages;
  }
};
