import {
  mergeAttributes, Node
} from '@tiptap/core';

export interface LayoutOptions {
  HTMLAttributes: Record<string, any>,
}


declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    layout: {
      /**
       * Add an style tag
       */
      setLayoutTag: () => ReturnType,
    }
  }
}

export const LayoutTag = Node.create<LayoutOptions>({
  name: 'layout',

  priority: 1000,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: 'block',

  content: 'block+',

  addAttributes() {
    return {
      class: {
        default: null,
        label: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'layout',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['layout', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setLayoutTag: () => ({ commands }) => {
        return commands.setNode(this.name);
      },
    };
  },
});

export default LayoutTag;
