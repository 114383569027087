import {
  mergeAttributes, Node
} from '@tiptap/core';

export interface CenterOptions {
  HTMLAttributes: Record<string, any>,
}


declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    center: {
      /**
       * Add an center tag
       */
      setCenter: () => ReturnType,
    }
  }
}

export const Center = Node.create<CenterOptions>({
  name: 'center',

  priority: 1000,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  group: 'block',

  content: 'block+',

  parseHTML() {
    return [
      {
        tag: 'center',
      },
    ];
  },

  addAttributes() {
    return {
      class: {
        default: null,
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ['center', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setCenter: () => ({ commands }) => {
        return commands.setNode(this.name);
      },
    };
  },
});

export default Center;
