import React, { useState, useEffect, useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';
import { Paper, Chip, Grid } from '@mui/material';
import { History } from 'history';
import EditIcon from '@mui/icons-material/Edit';
import * as R from 'ramda';
import ClientEditDialog from '../../routes/Clients/components/client-dialog/client-dialog.component.jsx';
import { selectClient } from '../../routes/Messages/routes/MessagesView/messages-view.actions';
import MessageDialog from '../../routes/Messages/components/messageDialog.component.jsx';
import { usePromise } from '../../services/promise.hook';
import { PopupTemplate } from '../../services/Popup.js';
import * as MessagesViewActions from '../../routes/Messages/routes/MessagesView/messages-view.actions';
import Header from '../PageHeader/PageHeader.component';

import HeaderButton from '../HeaderButton/HeaderButton.component';
import HeaderTabs from '../HeaderTabs/HeaderTabs.component';
import { MessageList, MessageContent, MessageInfo, Message, Client } from '../view-message.component';
import { useSelector } from '../../reducers';
import {
  getSentMessages, getAutomation, automationParser,
  SentMessageData, GetSentMessageType, MessageLogicType
} from './automation-message.actions';

const SentMessage = ({ msgId }: {msgId: number}) => {
  const dispatch = useDispatch();
  const [perPage, setPerPage] = useState(25);
  const [page, setPage] = useState(1);

  const sentMessageState = usePromise<GetSentMessageType, SentMessageData>(
    getSentMessages,
    { data: [], totalCount: 0, totalPages: 0 }
  );

  const {
    tz,
    headerHeight,
  } = useSelector((state) => ({
    tz: state.login.office.timezone,
    headerHeight: state.login.headerHeight,
  }));

  useEffect(() => {
    if (msgId) {
      sentMessageState.invoke({ msgId, page, perPage });
    }
  }, [msgId, page, perPage]);

  const actions = {
    selectMessage: (m: Message) => console.log(m),
    selectClient: (c: Client) => dispatch(selectClient(c))
  };

  const {
    data, totalCount, totalPages,
  } = useMemo(() => {
    return sentMessageState.data;
  }, [sentMessageState.data]);

  return (
    <MessageList
      messages={data}
      tz={tz}
      actions={actions}
      reactivationSearch={false}
      maxHeight={`calc(100vh - ${headerHeight}px - 54px)`}
      paginationData={{
        addPage: (v) => setPage(page + v),
        currentLength: data.length,
        page,
        totalCount,
        totalPages,
        perPage,
        setPerPage,
      }}
    />
  );
};

type ViewMessageProps = {
  messageType: string;
  history: History;
  messageId?: number;
  type: string;
}
const ViewMessage = ({
  messageType, messageId, history, type,
}: ViewMessageProps) => {
  const dispatch = useDispatch();
  const {
    tz,
    features,
    message,
    busy,
    state,
    selectedMessage,
    partTab,
    totalCount,
    types,
    professionals,
  } = useSelector((state) => ({
    ...state.viewMessage,
    tz: state.login.office.timezone,
    features: state.login.features,
  }));

  const actions = bindActionCreators(MessagesViewActions, dispatch);
  const [automationData, setAutomationData] = useState<MessageLogicType>({} as MessageLogicType); 

  const isSmartReply = location &&
    location.pathname.indexOf('smart-reply') !== -1;

  const [tab, setTab] = useState('messages');

  const editUrl = `/${type}/edit/message/${messageId}`;

  const navigate = () => {
    history.push(editUrl);
  };

  const getMessageInfo = async () => {
    const auto = await getAutomation({ msgId: messageId, type: 'message' });
    const data = automationParser(auto);
    setAutomationData(data);
  };

  useEffect(() => {
    actions.loadMessage(messageId, tz);
    getMessageInfo();
  }, []);

  return (
    <>
      <Header title='Message'
        leftIcons={[
          <Grid marginLeft="5px" />,
          <HeaderTabs
            value={tab}
            setTab={setTab}
            tabs={[
              { label: 'Message Info', value: 'messages' },
              { label: 'Sent Messages', value: 'sent' }
            ]}
          />,
          <Grid marginLeft="10px" />,
        ]}
        rightIcons={[
          messageType !== 'OneTime' ?
            <HeaderButton
              title='Edit'
              Icon={EditIcon}
              onClick={navigate}
            /> : <div />
        ]}
        onlyIconsWidth={605}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: 0,
          overflowY: 'auto',
          paddingBottom: 0,
        }}>
        <PopupTemplate />
        <ClientEditDialog
          open={state === 'CLIENT_SELECT'}
          onClose={actions.backToMessage}
          back={actions.back}
          from={'MESSAGE'}
          history={history}
          gotoClient={actions.back} />
        <MessageDialog
          open={state === 'MESSAGE_SELECT'}
          message={selectedMessage}
          onClose={actions.backToMessage}
          from={'MESSAGE'}
          history={history}
          tz={tz}
          back={() => actions.back()}
          gotoClient={actions.selectClientDialog} />
        <div hidden={tab !== 'messages'} style={{
          paddingBottom: '60px',
        }}>
          {busy && <div className="loader"></div>}
          {!busy && R.includes('NewMessaging', features) &&
            <MessageContent
              message={message}
              partTab={partTab}
              setPartTab={actions.patch}
            />}
          {!busy && automationData?.type === 'SmartReply' &&
          <Paper style={{
            margin: '10px 20px',
            padding: 15,
          }}>
            <h4>Keywords</h4>
            {R.isEmpty(automationData.keywords) || R.isNil(automationData.keywords) ?
              'There are no keywords to trigger this message.'
              :
              <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                flexDirection: 'row',
              }}>
                {automationData.keywords?.map((keyword) => (
                  <Chip key={keyword} style={{ fontSize: '14px', margin: '5px', }} label={keyword} />
                ))}
              </div>
            }
          </Paper>}
          {!busy && <MessageInfo
            message={message}
            // state
            tz={tz}
            urlId={messageId.toString()}
            totalCount={totalCount}
            actions={actions}
            types={types}
            professionals={professionals}
            features={features}
            messageType={messageType}
            isSmartReply={isSmartReply}
            automationData={automationData}
          />}
        </div>
        <div hidden={tab !== 'sent'}>
          <SentMessage msgId={messageId} />
        </div>
      </div>
    </>
  );
};

export default ViewMessage;
