import React from 'react';

type Props = {
  color?: string;
};

const EmailIcon = ({ color }: Props) => {
  return (
    <svg width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.2373 0.818703C1.5646 0.66948 1.89822 0.649834 2.23058 0.647934C3.20834 0.641915 4.18641 0.645714 5.16416 0.645714H18.2564C18.5844 0.645714 18.9044 0.686905 19.2415 0.813317C19.1556 0.906784 19.0926 0.97966 19.0248 1.04778C16.3538 3.72724 13.6823 6.40607 11.0107 9.0846C10.9274 9.16824 10.8441 9.25188 10.7563 9.33077C10.3957 9.65458 10.0406 9.64856 9.70029 9.30923C9.35209 8.96198 9.00959 8.60903 8.66171 8.26147C6.22239 5.82374 3.78213 3.38633 1.34249 0.948609C1.30701 0.912804 1.27881 0.870034 1.2373 0.818703Z" fill={color || '#4D4D4D'} />
      <path d="M13.1574 8.61719C15.1937 10.638 17.2021 12.6309 19.2207 14.6336C18.9191 14.7654 18.6022 14.7977 18.2813 14.7977C14.3563 14.7989 10.4314 14.7986 6.50671 14.7986C5.06257 14.7986 3.61876 14.8053 2.17494 14.7932C1.86634 14.7907 1.55869 14.7204 1.20605 14.6757C3.26707 12.6331 5.27644 10.6418 7.3026 8.63366C7.62102 8.94511 7.97746 9.29078 8.3301 9.63993C8.4977 9.80595 8.66531 9.97292 8.82119 10.1497C9.54992 10.9751 10.8486 10.9662 11.5821 10.2235C12.0935 9.70583 12.6102 9.1935 13.1232 8.67739C13.146 8.65458 13.1561 8.61941 13.1574 8.61719Z" fill={color || '#4D4D4D'} />
      <path d="M6.37001 7.77156C4.37204 9.74194 2.3538 11.7323 0.301977 13.7559C0.250966 13.486 0.159084 13.2227 0.158134 12.9591C0.148628 9.46852 0.148945 5.97795 0.158134 2.4874C0.158767 2.22823 0.246531 1.96937 0.294373 1.70703C2.33099 3.73952 4.33434 5.73938 6.37001 7.77156Z" fill={color || '#4D4D4D'} />
      <path d="M20.1712 13.7582C18.1095 11.7399 16.0834 9.75656 14.0356 7.75163C16.1071 5.72009 18.1295 3.73703 20.1737 1.73242C20.2171 1.96719 20.308 2.23143 20.3087 2.49631C20.3182 5.9875 20.3182 9.47838 20.3087 12.9696C20.308 13.2354 20.2181 13.5009 20.1712 13.7582Z" fill={color || '#4D4D4D'} />
    </svg>
  );
};

export default EmailIcon;
