import React, { ComponentProps } from 'react';

const SwitchIcon = ({ color, ...props }: ComponentProps<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} width="18.753" height="18.417" viewBox="0 0 18.753 18.417">
      <g id="Group_16948" data-name="Group 16948" transform="translate(-427.26 -13716.966)">
        <g id="Group_16949" data-name="Group 16949" transform="translate(427.26 13719.596)">
          <g id="Group_16948-2" data-name="Group 16948" transform="translate(0)">
            <path id="Path_9454" data-name="Path 9454" d="M20.517,107.324l-3.947-3.289a.658.658,0,0,0-1.079.505v1.974h-.335a2.619,2.619,0,0,1-2.231-1.237l-4.26-6.815A5.231,5.231,0,0,0,4.2,95.988H2v2.631H4.2a2.619,2.619,0,0,1,2.231,1.237l4.26,6.815a5.234,5.234,0,0,0,4.463,2.474h.335v1.974a.659.659,0,0,0,.379.6.667.667,0,0,0,.279.062.65.65,0,0,0,.421-.153l3.947-3.289a.657.657,0,0,0,0-1.01Z" transform="translate(-2 -95.988)" fill={color || '#fafafa'}/>
          </g>
        </g>
        <g id="Group_16951" data-name="Group 16951" transform="translate(435.713 13716.966)">
          <g id="Group_16950" data-name="Group 16950">
            <path id="Path_9455" data-name="Path 9455" d="M252.942,35.453,249,32.163a.658.658,0,0,0-1.079.505v1.974h-.335a5.231,5.231,0,0,0-4.463,2.474l-.238.382,1.551,2.483.918-1.47a2.619,2.619,0,0,1,2.231-1.237h.335v1.974a.657.657,0,0,0,.379.6.667.667,0,0,0,.279.062.658.658,0,0,0,.421-.153l3.947-3.289a.657.657,0,0,0,0-1.01Z" transform="translate(-242.88 -32.012)" fill={color || '#fafafa'}/>
          </g>
        </g>
        <g id="Group_16953" data-name="Group 16953" transform="translate(427.26 13727.416)">
          <g id="Group_16952" data-name="Group 16952" transform="translate(0)">
            <path id="Path_9456" data-name="Path 9456" d="M7.35,286.164l-.917,1.47A2.621,2.621,0,0,1,4.2,288.87H2V291.5H4.2a5.234,5.234,0,0,0,4.463-2.474l.238-.382Z" transform="translate(-2 -286.164)" fill={color || '#fafafa'}/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SwitchIcon;
