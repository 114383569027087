import React from 'react';
import { makeStyles } from '@mui/styles';
import { Paper } from '@mui/material';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '15px',
  },
  label: {
    margin: 0,
    fontSize: '14px',
    fontWeight: 400,
    color: '#555'
  },
  value: {
    margin: '24px auto',
    fontSize: '28px',
    color: '#111'
  },
  postiveChange: {
    color: 'rgb(76, 175, 80)',
    fontWeight: 600,
    background: 'rgba(76, 175, 80, 0.1) none repeat scroll 0% 0%',
    padding: '2px',
    borderRadius: '3px',
    marginRight: '8px'
  },
  negativeChange: {
    color: 'rgb(244, 67, 54)',
    fontWeight: 600,
    background: 'rgba(244, 67, 54, 0.1) none repeat scroll 0% 0%',
    padding: '2px',
    borderRadius: '3px',
    marginRight: '8px'
  },
  changes: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '12px'
  }
}));

export const StatBox = ({ name, value, change, previous, changeAs = 'percentage' }) => {
  const classes = useStyles();
  return (<Paper className={classes.root}>
    <h6 className={classes.label}>{name}</h6>
    <h2 className={classes.value}>{value}</h2>
    <div className={classes.changes}>
      <span className={change > 0 ? classes.postiveChange : classes.negativeChange}>{change > 0 ? '+' : ''}{changeAs === 'percentage' ? change : previous} {changeAs === 'percentage' ? '%' : ''}</span>
      <span>{changeAs === 'percentage' ? 'Since' : ''} last period</span>
    </div>
  </Paper>);
};

