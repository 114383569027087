import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  buttonArrow: {
    border: '2px solid',
    borderRadius: '50%',
    padding: '2px !important',
  },
  arrow: {
    width: 16,
    height: 16,
    transform: 'rotate(270deg)',
  },
}));
