import React, { useState, useRef, useLayoutEffect } from 'react';
import { pipe, pick, dissoc } from 'ramda';
import {
  TextField,
  Button,
  FormControl,
  FormLabel,
  Paper,
  MenuItem,
  Typography,
  Select as MSelect,
  InputLabel,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';

import {
  requiredValidation,
  updateValue,
  getValue,
  submitForm,
  initForm,
  touchFieldAndValidate,
  getFirstErrorOrDefault,
  validateField,
  getFormObject
} from '../../../services/form-validation.js';

import Logo from '../../../images/logo-blue.png';
import SparkLogo from '../../../icons/SparkLogo.icon';
import { useStyles } from './style.js';
import { countries } from '../../../services/globals.js';

export const YEARLY_POLICY = '2023-product-contract-yearly';
const MONTHLY_POLICY = '2023-product-contract-monthly';

const style = {
  base: {
    color: '#32325d',
    lineHeight: '24px',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#757575'
    }
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a'
  }
};

// Create an instance of the card Element

const FullTextField = withStyles({
  root: {
    width: '100%',
  },
})(TextField);

export const requiredOrValidation = (otherField, message = 'Please enter a value for this field') => (prop, f) => {
  const value = getValue(prop, f);
  const otherValue = getValue(otherField, f);
  if (!otherValue) {
    return { valid: true };
  }
  if (value) {
    return { valid: true };
  }
  return {
    valid: false,
    message
  };
};

const formObject = (isSpark) => {
  const f = {
    hasPromo: {
      default: false
    },
    promo: {
      default: '',
    },
    isBillingAddressDifferent: {
      default: false
    },
    referrer: {
      default: '',
      validation: []
    },
    country: {
      default: '',
      validation: [
        requiredOrValidation('isBillingAddressDifferent', 'Please select country for billing')
      ]
    },
    street: {
      default: '',
      validation: [
        requiredOrValidation('isBillingAddressDifferent', 'Please enter your street number and name.')
      ]
    },
    city: {
      default: '',
      validation: [
        requiredOrValidation('isBillingAddressDifferent', 'Please enter your city.')
      ]
    },
    state: {
      default: '',
      validation: [
        requiredOrValidation('isBillingAddressDifferent', 'Please enter your state or province.')
      ]
    },
    zipCode: {
      default: '',
      validation: [
        requiredOrValidation('isBillingAddressDifferent', 'Please enter your zip code.')
      ]
    },
    cardName: {
      default: '',
      validation: [
        requiredValidation('Please enter the name on your card.')
      ]
    },
    agreement: {
      default: '',
      validation: [
        requiredValidation('Please select the yearly or monthly agreement')
      ]
    },
  };
  return isSpark ? dissoc('agreement', f) : f;
};

const useStepStyles = makeStyles(useStyles);

const updateCb = e => e.target.value;

export const Step3 = ({
  busy,
  elements,
  gotoStep,
  step3Data,
  finishStep3,
  errorMessage,
  isPlatinumReach = false,
  isSpark = false,
}) => {
  const elementRef = useRef(null);
  const cardDOM = useRef(null);

  useLayoutEffect(() => {
    if (elementRef.current === null && elements && cardDOM.current !== null) {
      const element = elements.create('card', { style });
      elementRef.current = element;
      element.mount(cardDOM.current);
    }
  });
  useLayoutEffect(() => {
    return () => {
      if (elementRef.current) {
        elementRef.current.destroy();
      }
    };
  }, []);

  const classes = useStepStyles();

  const [form, setForm] = useState(initForm(formObject(isSpark), step3Data));

  const updateProp = (prop = '', cb = updateCb) => e => setForm(pipe(
    updateValue(prop, cb(e)),
    validateField(prop)
  ));

  const touchField = (prop) => () => setForm(touchFieldAndValidate(prop, form));

  const getData = () => {
    const data = getFormObject(form);
    return {
      isBillingAddressDifferent: data.isBillingAddressDifferent,
      billingAddress: pick(['street', 'city', 'state', 'zipCode', 'country'], data),
      promo: data.promo,
      hasPromo: data.hasPromo,
      cardName: data.cardName,
      policy: data.agreement,
      referrer: data.referrer,
    };
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    const validatedForm = submitForm(form);
    setForm(validatedForm);
    if (!validatedForm.__form.isValid) {
      console.log('form not valid');
      return;
    }
    const saveData = getData();
    finishStep3(
      elementRef.current,
      saveData
    );
  };

  const goBack = () => {
    const saveData = getData();
    gotoStep(saveData, 'STEP_2');
  };
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <form onSubmit={handleOnSubmit}>
          <div className={classes.headerContainer}>
            {isSpark ? (
              <SparkLogo className={classes.sparkLogo} />
            ) : (
              <img height="30" src={Logo} />
            )}
          </div>
          <div className={classes.headerContainer}>
            <Typography align='center' variant='h4'>Registration</Typography>
          </div>
          {!isPlatinumReach && (
            <div className={classes.formControl}>
              <FullTextField
                fullWidth
                id="reg-referrer"
                label={`Who referred you to ${isSpark ? 'Spark' : 'SKED'}?`}
                type="text"
                helperText="This can be an office or a person. (Not required)"
                value={getValue('referrer', form)}
                onChange={updateProp('referrer')}
              />
            </div>
          )}

          <h5 className={classes.headerTitle}>Billing Information</h5>

          <div>
            <FormControlLabel
              control={<Checkbox
                checked={!!getValue('isBillingAddressDifferent', form)}
                onChange={updateProp('isBillingAddressDifferent', e => e.target.checked)}
                name="billingAddressIsDifferent" />}
              label="Use different billing address"
            />
          </div>
          {getValue('isBillingAddressDifferent', form) &&
            <>
              <FormControl
                className={classes.formControl}
                error={!form.country.isValid}
              >
                <InputLabel id="country-label">Country</InputLabel>
                <MSelect
                  fullWidth
                  labelId="country-label"
                  id="country-input"
                  value={getValue('country', form)}
                  onChange={updateProp('country')}
                  onBlur={touchField('country')}
                >
                  {countries.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>{label}</MenuItem>
                  ))}
                </MSelect>
                <FormHelperText>{getFirstErrorOrDefault('country', form)}</FormHelperText>
              </FormControl>
              <div className={classes.formControl}>
                <FullTextField
                  fullWidth
                  id="reg-street"
                  label="Street Address"
                  type="text"
                  helperText={getFirstErrorOrDefault('street', form)}
                  error={!form.street.isValid}
                  value={getValue('street', form)}
                  onChange={updateProp('street')}
                  onBlur={touchField('street')}
                />
              </div>
              <div className={classes.formControl}>
                <FullTextField
                  fullWidth
                  id="reg-city"
                  label="City"
                  type="text"
                  helperText={getFirstErrorOrDefault('city', form)}
                  error={!form.city.isValid}
                  value={getValue('city', form)}
                  onChange={updateProp('city')}
                  onBlur={touchField('city')}
                />
              </div>
              <div className={classes.formControl}>
                <FullTextField
                  fullWidth
                  id="reg-state"
                  label="State / Province"
                  type="text"
                  helperText={getFirstErrorOrDefault('state', form)}
                  error={!form.state.isValid}
                  value={getValue('state', form)}
                  onChange={updateProp('state')}
                  onBlur={touchField('state')}
                />
              </div>
              <div className={classes.formControl}>
                <FullTextField
                  fullWidth
                  id="reg-zip"
                  label="Zip"
                  type="text"
                  helperText={getFirstErrorOrDefault('zipCode', form)}
                  error={!form.zipCode.isValid}
                  value={getValue('zipCode', form)}
                  onChange={updateProp('zipCode')}
                  onBlur={touchField('zipCode')}
                />
              </div>
            </>
          }
          <div>
            <FormControlLabel
              control={<Checkbox
                checked={!!getValue('hasPromo', form)}
                onChange={updateProp('hasPromo', e => e.target.checked)}
                name="hasPromo" />}
              label="I have a promo code"
            />
          </div>
          {getValue('hasPromo', form) &&
            <div>
              <FullTextField
                fullWidth
                id="reg-promo"
                label="Promo Code"
                type="text"
                value={getValue('promo', form)}
                onChange={updateProp('promo')}
                onBlur={touchField('promo')}
              />
            </div>
          }
          <div className={`${classes.formControl} ${classes.marginTop}`}>
            <FullTextField
              fullWidth
              id="reg-car-dname"
              label="Card Name"
              type="text"
              value={getValue('cardName', form)}
              onChange={updateProp('cardName')}
              onBlur={touchField('cardName')}
            />
          </div>
          <div id="card-element" ref={cardDOM} className={classes.formControl}> </div>
          <div id="card-errors" role="alert" className={classes.formControl}></div>

          <hr className={classes.hr} />
          {!isSpark && (
            <div className={classes.formControl}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Agreement Type</FormLabel>
                <RadioGroup aria-label="agreement-type" name="agreement" value={getValue('agreement', form)} onChange={updateProp('agreement')}>
                  <FormControlLabel value={MONTHLY_POLICY} control={<Radio />} label="Month-to-month" />
                  <FormControlLabel value={YEARLY_POLICY} control={<Radio />} label="Annual Agreement" />
                </RadioGroup>
              </FormControl>
            </div>
          )}

          <div className={classes.formControl} style={{ width: '100%', height: '36px', paddingTop: isSpark ? 14 : 0 }}>
            <Button
              variant='outlined'
              name='back2'
              disableElevation
              classes={{ label: classes.buttonLabel }}
              style={{ float: 'left' }}
              onClick={goBack}>
              Back
            </Button>
            <Button
              variant='contained'
              type='submit'
              disableElevation
              classes={{ label: classes.buttonLabel }}
              disabled={busy}
              style={{ marginLeft: '5px', marginBottom: '5px', float: 'right' }}>
              Submit
            </Button>
          </div>
          <div className={classes.formControl}>
            <i>
              Clicking 'Submit' means that you have read and agreed to our <a href="https://sked-v2.s3.amazonaws.com/licenses+/SKED+-+Subscription+Agreement+-+03-25.pdf" target="_blank">Subscription Agreement</a>
              {isSpark ? <> and our <a href="https://sked-v2.s3.amazonaws.com/documents/2024-06-spark-patients-terms-of-service.pdf" target="_blank">Terms of Service</a>!</> : '!'}
            </i>
          </div>
          {!form.__form.isValid && form.__form.isTouched &&
            <FormControl
              className={classes.formControl}
              error={true}>
              <FormHelperText>The form is not filled out correctly.  Please scroll up to see which fields need corrected.</FormHelperText>
            </FormControl>}
          {errorMessage && <FormControl
            className={classes.formControl}
            error={true}>
            <FormHelperText>{errorMessage}</FormHelperText>
          </FormControl>}
        </form>
      </Paper>
    </div>
  );
};
