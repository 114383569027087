import * as at from '../../actionTypes';
import {
  merge, evolve, append, adjust, compose, pipe, lensProp, lensIndex,
  over, propEq, always, mergeLeft, assoc,
} from 'ramda';

const init = {
  busy: false,
  errorMessage: '',
  saveErrorMessage: '',
  settings: {
    geofenceSetting: '',
    messageText: '',
    checkInBeforeMinutes: null,
    checkInAfterMinutes: null,
    minutesUntilMissed: 5,
    rooms: [],
    roomCalling: false
  },
  state: {
    loading: false,
    leaving: false,
    errorMessage: '',
    status: null,
    appointments: [],
    settingsOpen: false,
    professionalIds: [],
    roomIds: [],
    searchText: '',
    search: {
      lastName: '',
      firstName: '',
      phone: '',
    },
    tab: 'in',
    events: null,
    locationIds: [],
    stops: [],
    blockId: null,
  }
};

export const SCHEDULED = 'scheduled';

export default (state = init, action) => {
  switch (action.type) {
    case 'WAITING_INIT': {
      console.log(action.data);
      return evolve({
        state: mergeLeft(action.data),
      }, init);
    }
    case 'WAITING_LEAVE_PAGE': {
      return merge(state, {
        leaving: true
      });
    }
    case 'WAITING_APPT_OPT': {
      const data = action.data;
      const apptIdx = state.state.appointments.findIndex(propEq('id', data.id));

      const apptLens = compose(
        lensProp('state'),
        lensProp('appointments'),
        lensIndex(apptIdx)
      );

      const up = a => merge(a, {
        loading: true,
        status: data.status === SCHEDULED ? { Scheduled: [] } : a.status,
        waitingRoomStatus: data.status,
        roomNumber: data.roomNumber
      });

      //Optimistic Update
      return pipe(
        evolve({
          state: assoc('blockId', data.id)
        }),
        over(apptLens, up)
      )(state);
    }
    // Created a seperate waiting update next appt
    // to track when an update happens from getting next appt
    case 'WAITING_UPDATE_NEXT_APPT':
    case at.WAITING_UPDATE_APPT_SCHEDULED: {
      const appt = action.data;
      const index = state.state.appointments.findIndex(a => a.id === appt.id);

      if (index >= 0 ) {
        const newState = evolve({
          appointments: adjust(index, old => merge(old, appt))
        }, state.state);
        return merge(state, { state: newState });
      }

      const newState = evolve({
        appointments: append(appt)
      }, state.state);
      return merge(state, { state: newState });
    }
    case 'WAITING_REMOTE_GET_STATE':{
      if (action.state === 'REQUEST') {
        return evolve({
          state: s => merge(s, {
            errorMessage: '',
            loading: true,
          })
        }, state);
      } else if (action.state === 'RESPONSE') {
        return evolve({
          leaving: always(false),
          state: s => merge(s, {
            errorMessage: '',
            loading: false,
            stops: action.data.stops,
            appointments: action.data.appointments
          })
        }, state);
      } else if (action.state === 'ERROR') {
        return evolve({
          state: s => merge(s, {
            errorMessage: action.errorMessage,
            loading: false,
          })
        }, state);
      }
      return state;
    }
    case at.WAITING_SET_STATE:
      return merge(state, { state: action.data });
    case at.REMOTE_WAITING_GET_SETTINGS:
      if (action.state === 'REQUEST') {
        return merge(state, {
          errorMessage: '',
          busy: true,
        });
      } else if (action.state === 'RESPONSE') {
        return merge(state, {
          settings: action.data,
          busy: false,
        });
      } else if (action.state === 'ERROR') {
        return merge(state, {
          errorMessage: action.errorMessage,
          busy: false,
        });
      }
      return state;
    case at.REMOTE_WAITING_SAVE_SETTINGS:
      if (action.state === 'REQUEST') {
        return merge(state, {
          saveErrorMessage: '',
          saving: true,
        });
      } else if (action.state === 'RESPONSE') {
        return merge(state, {
          saving: false,
        });
      } else if (action.state === 'ERROR') {
        return merge(state, {
          saveErrorMessage: action.errorMessage,
          saving: false,
        });
      }
      return state;
    default:
      return state;
  }
};
