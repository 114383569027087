import React from 'react';
import {
  DialogContentText,
} from '@mui/material';

import Modal from '../Modal/Modal.component';
import HeaderButton from '../HeaderButton/HeaderButton.component';

type Props = {
  title: string;
  text: string;
  open: boolean;
  confirmText?: string;
  confirmColor?: 'primary' | 'danger';
  confirm: () => void;
  cancel: () => void;
  className?: string;
}
const ConfirmationDialog = ({ title, text, open, confirmText, confirm, cancel, confirmColor, className }: Props) => {
  return (
    <Modal
      size="xs"
      open={open}
      onClose={cancel}
      title={title}
      className={className}
      buttons={[
        <HeaderButton
          title={confirmText || 'Confirm'}
          onClick={confirm}
          color={confirmColor || 'danger'}
          className={`${className}-button-confirm`}
        />
      ]}
    >
      <div>
        <DialogContentText>
          {text}
        </DialogContentText>
      </div>
    </Modal>
  );
};

export default ConfirmationDialog;
