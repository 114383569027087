import React from 'react';

import { caseType } from '../../../../services/utilities';

import { useStyles } from './clientChip.styles';

export type Client = {
  id: number;
  firstName: string;
  lastName: string;
}

type ClientChipProps = {
  client: Client;
  onClick: (client: Client) => void;
}

const ClientChip = ({ client, onClick }: ClientChipProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root} onClick={() => onClick(client)}>
      <div className={classes.circle}>
        {client.firstName[0] + client.lastName[0]}
      </div>
      <div key={client.id} className={classes.name}>
        {client.firstName + ' ' + client.lastName + ' ' + caseType(client)}
      </div>
    </div>
  );
};

export default ClientChip;
