import api from '../../services/api.js';
import { popup } from '../../services/Popup.js';
import { remoteAction } from '../../services/actionService.js';

export const sendForgotPassword = (email) => remoteAction({
  type: 'nothing',
  action: () => api.post('passwordreset', { email })
    .then(() => {
      popup('Email sent!', 'An email containing a link to the password reset has been sent.');
    }).catch((error) => {
      popup('Error!', 'Failed to send email.');
      console.error(error);
      throw error;
    }),
});

export const resetPassword = (data) => remoteAction({
  type: 'nothing',
  action: () => api.post('passwordreset/reset', data)
    .then(() => {
      popup('Success!', 'Your password has been updated.');
      return true;
    })
    .catch((error) => {
      popup('Error!', 'Failed to update password');
      console.error(error);
      return null;
    }),
});

export const resetPasswordClient = (data) => remoteAction({
  type: 'nothing',
  action: () => api.post('passwordreset/reset', data)
    .then(() => {
      popup('Success!', 'Your password has been updated. Please close this tab and login to the app.');
      return null;
    })
    .catch((error) => {
      popup('Error!', 'Failed to update password');
      console.error(error);
      return null;
    }),
});
