/**
 * Login action types
 * @type {string}
 */
export const LOGIN = 'LOGIN';
export const SESSION_CHECKING = 'SESSION_CHECKING';
export const SESSION_CREATE_SUCCESS = 'SESSION_CREATE_SUCCESS';
export const SESSION_CREATE_ERROR = 'SESSION_CREATE_ERROR';
export const SESSION_DESTROY = 'SESSION_DESTROY';
export const SESSION_LOAD_SUCCESS = 'SESSION_LOAD_SUCCESS';
export const SESSION_LOAD_ERROR = 'SESSION_LOAD_ERROR';
