import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  papper: {
    borderRadius: 12,
    overflowY: 'visible',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#FAFAFA',
    padding: '7px 15px 7px 20px',
  },
  content: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
    borderRight: '1px solid rgba(0, 0, 0, 0.2)',
  },
  mobile: {
    display: 'none',

    [theme.breakpoints.down('md')]: {
      display: 'flex',
      maxWidth: 320
    }
  },
  desktop: {
    display: 'flex',
    overflowY: 'auto',
    maxWidth: 680,

    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  },
  footer: {
    marginTop: 15,
    display: 'flex',
    borderTop: '1px solid rgba(0, 0, 0, 0.2)',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    }
  },
  footerTitle: {
    color: '#151517',
    fontWeight: 500,
    fontSize: 17,
  },
  footerText: {
    fontSize: 13,
    fontWeight: 500,
    color: '#888888',
    textDecoration: 'none',

    '& > span': {
      color: '#3696DF',
      cursor: 'pointer',
    }
  },
  footerContent: {
    flex: 1,
    padding: 15,
    paddingBottom: 12,

    [theme.breakpoints.down('md')]: {
      maxWidth: 320
    },

    '& > div': {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      }
    }
  },
  lastDiv: {
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    }
  },
  footerBorder: {
    borderRight: '1px solid rgba(0, 0, 0, 0.2)',
  },
  arrow: {
    position: 'fixed',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderRight: '7px solid transparent',
    borderLeft: '7px solid transparent',
    borderBottom: '9px solid #FFF',
    borderTop: 0,
    transform: 'rotate(264deg)',
    marginTop: -15,
    marginLeft: -10,
  }
}));
