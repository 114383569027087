import React from 'react';

type Props = {
  color?: string;
};

const ReportsIcon = ({ color }: Props) => {
  return (
    <svg id="uuid-76266bf4-d29a-4e76-914f-20389231c7c8" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <g id="uuid-716a3bdf-27cf-4c66-819d-085831647bec" data-name="Group 8826" fill={color || '#FFF'}>
        <path id="uuid-588bb726-3201-4ea2-aa87-cf28f01cce49" data-name="Path 4694" d="m49.1727,33.3305v12.6926c.1158,1.8808-1.1942,3.5506-3.0485,3.8857-.2748.0482-.5539.0684-.8328.0601h-6.1415c-2.0795.1035-3.8491-1.4984-3.9526-3.5779-.0063-.1255-.0062-.2513.0001-.3768v-25.403c-.0828-1.8594,1.1984-3.5031,3.0217-3.8768.3093-.055.6235-.0774.9375-.0668,2.0041,0,4.026.0579,6.0346-.02,2.1296-.0833,3.9236,1.5755,4.0069,3.7052.0034.0884.0039.1768.0013.2652-.0512,4.2309-.0156,8.4617-.0156,12.6926" />
        <path id="uuid-65d599ff-c3fb-407d-b442-f7028a75d5e6" data-name="Path 4695" d="m.8049,37.0805v-8.9761c-.1009-1.7831,1.089-3.3827,2.8258-3.7989.391-.0925.7918-.1374,1.1936-.1336,1.9774-.0134,3.9548-.0134,5.9321,0,2.099-.1294,3.9055,1.4672,4.0349,3.5662.0103.1672.0096.335-.0022.5021,0,5.8965-.0178,11.8019,0,17.6895.097,2.1488-1.5662,3.9693-3.7149,4.0664-.1192.0054-.2386.0053-.3578-.0003-2.0041-.0846-4.0238-.0156-6.0368-.0178-2.0442.0953-3.7786-1.4846-3.8739-3.5287-.0051-.11-.0054-.2202-.0007-.3303v-9.0385" />
        <path id="uuid-f0828ad4-c970-4500-b23e-1505a8be90cd" data-name="Path 4696" d="m31.9374,40.8525c0,1.9395.0757,3.8835-.0223,5.8186-.1561,1.8639-1.7125,3.2984-3.5829,3.3023-2.1332.0156-4.2665,0-6.3997,0-2.0427.1226-3.7981-1.434-3.9207-3.4767-.0066-.1095-.0083-.2192-.0051-.3289-.046-3.5495-.046-7.0989,0-10.6484-.0487-2.0631,1.5843-3.7749,3.6474-3.8236.1107-.0026.2214-.0003.3319.0069h5.9811c2.0869-.1251,3.8802,1.4652,4.0053,3.5522.008.1326.0089.2655.0029.3981.0223,1.7302,0,3.4626,0,5.1928h-.0512" />
        <path id="uuid-0492028c-eb04-4d69-bc5d-04f4dd31c6bb" data-name="Path 4697" d="m24.9275,24.7931c-3.4113-.0395-6.1447-2.8368-6.1052-6.2481.0395-3.4113,2.8369-6.1447,6.2481-6.1052,3.4032.0394,6.1334,2.8241,6.1054,6.2275-.0655,3.4034-2.8443,6.1277-6.2483,6.1259" />
        <path id="uuid-3b71b962-a85e-4241-9f5b-c3766059cc9d" data-name="Path 4698" d="m42.2051.0002c2.7854.0203,5.0438,2.2629,5.0837,5.0481.0061,2.8169-2.2724,5.1054-5.0893,5.1116s-5.1054-2.2724-5.1116-5.0893h0C37.0903,2.2678,39.3643-.0024,42.167,0c.0127,0,.0254,0,.038.0002" />
        <path id="uuid-3c7f6364-b850-49bd-9bda-efd907cd6a10" data-name="Path 4699" d="m7.8259,17.0707c-3.3107-.0025-5.9924-2.6884-5.9899-5.999.0025-3.3107,2.6884-5.9924,5.999-5.9899,3.2958.0025,5.9715,2.6653,5.9898,5.9611-.006,3.3153-2.6837,6.0059-5.9989,6.0279" />
        <path id="uuid-72e701fc-c1fe-4e69-b005-d9137ed332d2" data-name="Path 4700" d="m38.2704,10.7377c-3.3402,2.9238-3.1175,2.6231-6.4398,5.5358-.4765-.5456-1.4853-1.7391-1.9685-2.2913,3.329-2.9148,3.1019-2.6142,6.4398-5.5358.4743.5456,1.4808,1.7347,1.9662,2.2936" />
        <path id="uuid-bf0b77c2-a429-449a-8628-879e36a14380" data-name="Path 4701" d="m13.8249,15.2202c.3006-.668,1.1134-2.111,1.4162-2.7812,2.7968,1.218.6524.3674,3.4626,1.5899-.3028.668-1.1134,2.1132-1.4162,2.779-2.8057-1.2225-.668-.3719-3.4604-1.5899" />
      </g>
    </svg>
  );
};

export default ReportsIcon;
