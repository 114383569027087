import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const ClientStatusIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} id="uuid-5a7d5da9-220f-47cd-980f-7c1a0f73f9f8" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" fill={color || '#FFF'}>
      <path id="uuid-18b19ec0-0dd9-4bda-875b-b7db6d57d2b8" data-name="Path 4676" d="m14.8266,10.1386C14.8459,4.52,19.4162-.0192,25.0348,0c5.6186.0192,10.1578,4.5896,10.1385,10.2082-.0192,5.6186-4.5896,10.1578-10.2082,10.1385-.0227,0-.0454-.0002-.068-.0005-5.5918-.0565-10.0896-4.6157-10.0705-10.2077"/>
      <path d="m42.5217,40.9733c0-1.5282.0187-3.0564,0-4.5815.0344-1.7565-.2839-3.502-.9357-5.1335-1.7635-4.7507-6.1909-7.9915-11.2525-8.2366-3.1749-.2339-6.3779-.1092-9.5683-.1092-6.9423-.0358-12.7169,5.3304-13.1892,12.2568-.2246,3.7269-.05,7.4694-.0687,11.2119-.0004.002-.0008.0038-.0011.0058-.0549.3156.1564.616.4719.671,3.924,1.2407,7.9554,2.1115,12.0416,2.601,4.5269.533,9.1056.4406,13.6072-.2744,2.882-.4149,5.6914-1.2327,8.3458-2.4296.3713-.1183.6067-.4831.5615-.8701-.0189-1.6967,0-3.4182,0-5.1273l-.0125.0156Zm-6.3047-10.012c-.1557.2202-.3439.4208-.5354.6122-4.6122,4.6121-9.2267,9.222-13.8419,13.831-.1694.1693-.3388.3418-.528.4873-.6003.462-1.2429.4537-1.7756-.0754-2.2912-2.2755-4.5713-4.5622-6.8483-6.8519-.4254-.4278-.7297-.9297-.7482-1.637.0289-.1163.0456-.3147.1253-.4834.4237-.8954,1.0818-1.5876,1.9642-2.0259.7797-.3871,1.5577-.1659,2.2922.5751,1.2152,1.2261,2.4239,2.4586,3.6371,3.6867.5222.5286.5205.523,1.0288.0149,3.7426-3.7408,7.4851-7.4818,11.2318-11.2185,1.0135-1.0109,2.0403-1.0178,3.0701-.0342.3348.3198.6779.6337.9399,1.0214.4736.7004.4775,1.4053-.012,2.0978Z"/>
    </svg>
  );
};

export default ClientStatusIcon;
