import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const CalendarIcon = ({ color, className }: Props) => {
  return (
    <svg className={className} id="uuid-8480425f-6a68-4a8e-adb2-c81f2bc65f2b" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <g id="uuid-74a9cd26-82d1-4312-b755-ef61e6448c83" data-name="Group 8823" fill={color || '#FFF'}>
        <path id="uuid-daa1c018-fbc4-48c7-888d-3431cf9acf20" data-name="Path 4679" d="m9.3953,2.9061h8.8063l-.0137-.0157v5.8395h2.9472V2.8904l-.0137.0157h8.8063l.002-.0157v5.8395h2.9472V2.8904l-.0137.0157h5.8708v.5421c0,3.0313-.0235,6.0665,0,9.092-.0857,7.7682,3.0173,15.2319,8.5851,20.6497l2.1389,2.1233c-.1957.0215-.3033.045-.4188.045H10.6106c-.2013.0019-.3967-.0674-.5519-.1957C4.0324,29.2536.6264,21.1756.6027,12.7358c-.0117-3.0959,0-6.1918,0-9.2877v-.5421h5.8708l-.0137-.0157v5.8395h2.9491V2.8904l-.0137.0157Zm-1.4286,13.2544h5.8121v-2.8748h-5.8121v2.8748Zm8.7906,0h5.8082v-2.8728h-5.8082v2.8728Zm14.6282-2.8591h-5.8082v2.8748h5.8082v-2.8748Zm-21.9628,8.728h5.8082v-2.8748h-5.8082v2.8748Zm14.6145-2.8571h-5.8082v2.8748h5.8063l.002-2.8748Zm8.8063,0h-5.8004v2.8748h5.8082l-.0078-2.8748Zm-16.137,5.8708h-5.7984v2.8748h5.8063l-.0078-2.8748Zm2.9843,2.8669h5.8004v-2.9041h-5.7906l-.0098,2.9041Zm14.6204-2.865h-5.816v2.8708h5.8258l-.0098-2.8708Z" />
        <path id="uuid-2110c042-1379-4f78-a7de-df3b3c484cde" data-name="Path 4680" d="m46.5616,42.6947H7.8219c-.3212.0186-.634-.1069-.8532-.3425-3.3249-3.3113-5.4818-7.6148-6.1448-12.2603-.1683-1.1292-.1957-2.2798-.2818-3.4207l.137-.0235c.2309.4736.4599.9511.6947,1.4247,1.8684,3.7614,4.4017,7.1539,7.4775,10.0137.1876.1366.4159.2056.6478.1957,4.9863.0117,9.9733.0117,14.9609,0h17.6497c.2304-.004.4544.076.6301.2251,1.1918,1.2681,2.3601,2.5597,3.5342,3.8434l.2896.3209" />
        <path id="uuid-e5c354b1-3918-465a-8376-98eafd0018ee" data-name="Path 4681" d="m.6184,49.998v-11.182c.4826.8238,1.0105,1.6202,1.5812,2.3855,1.2035,1.4501,2.4814,2.8395,3.7495,4.2348.1718.1367.3874.2063.6067.1957,3.5838.0104,7.1683.0104,10.7534,0h21.3914v4.3679l-38.0822-.002Z" />
        <path id="uuid-351db718-98fb-48ea-95a2-4406b6f3f72a" data-name="Path 4682" d="m6.4736,2.9061V0h2.9198v2.9061l.0137-.0157h-2.9472l.0137.0157Z" />
        <path id="uuid-014721f6-0744-40f3-b71b-6def1031c4d5" data-name="Path 4683" d="m18.2094,2.9061V0h2.9198v2.9061l.0137-.0157h-2.9472l.0137.0157Z" />
        <path id="uuid-0e14269b-f732-42c7-8d52-2508e04eb22a" data-name="Path 4684" d="m29.9452,2.9061V0h2.9158v2.9061l.0137-.0157h-2.9491l.0196.0157Z" />
      </g>
    </svg>
  );
};

export default CalendarIcon;
