import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  avatar: {
    position: 'relative',
    overflow: 'visible',
    background: 'linear-gradient(45deg, hsla(200, 100%, 41%, 1) 0%, hsla(200, 100%, 45%, 1) 72%, hsla(200, 100%, 50%, 1) 100%)'
  },
  container: {
    background: '#636363',
    position: 'absolute',
    bottom: -3,
    right: -5,
    borderRadius: '50%',
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      width: 11,
      height: 11,
    }
  },
}));