import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as actions from './messages-view.actions.jsx';
import * as R from 'ramda';

import ViewMessage from '../../../../components/view-message.component';

function MessagesView({
  busy,
  message,
  state,
  tz,
  messages,
  page,
  perPage,
  urlId,
  totalCount,
  totalPages,
  selectedMessage,
  actions,
  history,
  types,
  professionals,
  plan,
  features,
  partTab,
  headerHeight,
}) {
  return (
    <ViewMessage
      messageType='OneTime'
      busy={busy}
      message={message}
      state={state}
      tz={tz}
      messages={messages}
      page={page}
      perPage={perPage}
      urlId={urlId}
      totalCount={totalCount}
      totalPages={totalPages}
      selectedMessage={selectedMessage}
      actions={actions}
      history={history}
      types={types}
      professionals={professionals}
      plan={plan}
      features={features}
      partTab={partTab}
      headerHeight={headerHeight}
    />
  );
}

const mapStateToProps = (state, ownProps) => R.merge(
  R.propOr({}, 'viewMessage')(state),
  {
    urlId: ownProps.match.params.id,
    tz: R.pathOr({}, ['login', 'office', 'timezone'])(state),
    features: R.pathOr([], ['login', 'features'])(state),
    headerHeight: state.login.headerHeight,
  });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessagesView);
