import React from 'react';
import { useTitle } from '../../services/useTitle.js';
import { makeStyles } from '@mui/styles';
import Blocks from './components/blocks.component.jsx';

const useStyles = makeStyles(() => ({
  root: {
    padding: '20px',
    paddingBottom: '50px'
  },
}));

function BlocksPage({ match }) {
  useTitle('Schedule Blocks Page');

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Blocks id={match.params.id} />
    </div>
  );
}

export default BlocksPage;
