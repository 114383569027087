import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Menu,
  MenuItem,
  Button,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import HeaderButton from '../../../components/HeaderButton/HeaderButton.component';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#f5f5f5'
  },
  pageHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '18px',
    marginBottom: '10px'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    minWidth: 120,
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  buttonLabel: {
    textTransform: 'none',
    fontWeight: 'normal'
  },
  customButtom: {
    padding: '2px 10px',
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'none',
    borderRadius: 3,
    color: 'rgba(0,0,0,.87)',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    margin: 0,
  }
}));

export const timeRanges = [
  ['Custom', 'Custom'],
  ['Today', 'Today'],
  ['This week', 'ThisWeek'],
  ['This week-to-date', 'ThisWeekToDate'],
  ['This month', 'ThisMonth'],
  ['This month-to-date', 'ThisMonthToDate'],
  ['This quarter', 'ThisQuarter'],
  ['This quarter-to-date', 'ThisQuarterToDate'],
  ['This year', 'ThisYear'],
  ['This year to date', 'ThisYearToDate'],
  ['This year-to-last-month', 'ThisYearToLastMonth'],
  ['Yesterday', 'Yesterday'],
  ['Last week', 'LastWeek'],
  ['Last week-to-date', 'LastWeekToDate'],
  ['Last month', 'LastMonth'],
  ['Last month-to-date', 'LastMonthToDate'],
  ['Last quarter', 'LastQuarter'],
  ['Last quarter-to-date', 'LastQuarterToDate'],
  ['Last year', 'LastYear'],
  ['Last year to date', 'LastYearToDate'],
  ['Last year-to-last-month', 'LastYearToLastMonth'],
  ['Since 30 days ago', 'Since30DaysAgo'],
  ['Since 60 days ago', 'Since60DaysAgo'],
  ['Since 90 days ago', 'Since90DaysAgo'],
  ['Since 365 days ago', 'Since365DaysAgo'],
];

const findLabel = value => (timeRanges.find(([_label, key]) => key === value) || [])[0];

export const TimeRangeSelector = ({ onChange, value, isNew }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (id) => {
    handleClose();
    onChange(id);
  };

  return (
    <div>
      {isNew ? (
        <HeaderButton onClick={handleClick} EndIcon={KeyboardArrowDownIcon} title={findLabel(value)} borderSolid />
      ) : (
        <Button
          endIcon={<ArrowDropDownIcon />}
          classes={{ label: classes.buttonLabel }}
          onClick={handleClick}
          variant='contained'
          color="primary">
          {findLabel(value)}
        </Button>
      )}
      <Menu
        id="time-range-selector"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {timeRanges.map(([value, key]) => (<MenuItem onClick={() => handleSelect(key)} key={key} value={key}>{value}</MenuItem>))}
      </Menu>
    </div>
  );
};
