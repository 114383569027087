import {
  pipe, map, ascend,
  assoc, filter, prop,
  sortWith, prepend
} from 'ramda';

export const populateProfessional = (types = [], professionals = [], noneIsAll = false) => pipe(
  map(pipe(
    t => assoc('professional', professionals.find(p => p.id === t.professionalId), t),
    t => assoc('professionalName', t.professional ? `${t.professional.displayFirstName} ${t.professional.displayLastName}` : 'None', t)
  )),
  filter(t => !t.disabled && t.professional),
  sortWith([
    ascend(prop('professionalName')),
    ascend(prop('name'))
  ]),
  prepend({
    id: 0,
    name: noneIsAll ? 'All' : 'None',
    professionalName: '',
    internalName: '',
  })
)(types);
