import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { History } from 'history';

import {
  Paper,
  Button,
  TextField,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
} from '@mui/material';

import * as R from 'ramda';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import ApartmentIcon from '@mui/icons-material/Apartment';

import { usePromise } from '../../../services/promise.hook';
import { useCheckIsAdmin } from '../../../services/checkIsAdmin.hook';

import { TableContainer, HeaderCell, BodyCell } from '../../../components/CustomTable';
import ConfirmationDialog from '../../../components/ConfirmationDialog/ConfirmationDialog.component';
import SelectOffices from '../../../components/SelectOffices/selectOffices.component';
import BackdropLoading from '../../../components/BackdropLoading/backdropLoading.component';

import { checkEmailIsTaken } from '../Users/users.service';

import { alertSuccess, alertDanger, alertWarn } from '../../../components/Alerts/alerts.actions';

import { useSelector } from '../../../reducers';

import { useStyles } from './agencies.styles';

import {
  getAgencies,
  postAgency,
  putAgency,
  putUser,
  deleteAgency,
  getAgencyOffices,
  getFreeOffices,
  saveOffices,
  saveAdminUser,
} from './agencies.service';

import { Agency, Office, User } from './agencies.types';

const AgenciesPage = ({ history }: { history: History }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const agencies = usePromise(getAgencies, []);
  const selectedOffices = usePromise(getAgencyOffices, []);
  const offices = usePromise(getFreeOffices, { page: 1, offices: [], totalCount: 15, totalPages: 15 });

  const [isNew, setIsNew] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [openOffices, setOpenOffices] = useState<Agency>({} as Agency);
  const [idToRemove, setIdToRemove] = useState(0);
  const [agencyToEdit, setAgencyToEdit] = useState({} as Agency);
  const [name, setName] = useState('');
  const [NewName, setNewName] = useState('');
  const [NewEmail, setNewEmail] = useState('');
  const [email, setEmail] = useState('');
  const [idsToAdd, setIdsToAdd] = useState<number[]>([]);
  const [officesToRemove, setOfficesToRemove] = useState<Office[]>([]);

  const sessionStatus = useSelector(state => state.session.status);
  const admin = useSelector(state => state.login.admin);

  const handleCancel = () => {
    setIsNew(false);
    setName('');
    setEmail('');
  };

  const handleCreate = () => {
    setIsNew(true);
  };

  const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const emailIsTaken = await checkEmailIsTaken(email);
    if (emailIsTaken) {
      dispatch(alertWarn('Email is already being used'));
      setLoading(false);
      return;
    }
    try {
      const newAgency: Agency = await postAgency({ name, email });
      const user: User = await saveAdminUser(newAgency.agencyId, email, name);
      if (!user) {
        dispatch(alertDanger('Error saving agency admin'));
      } else {
        newAgency.user = user;
        dispatch(alertSuccess('Agency Created!'));
        agencies.setState({
          ...agencies,
          data: R.sortBy(R.prop('name'), [...agencies.data, newAgency]),
        });
      }
      setLoading(false);
      handleCancel();
    } catch {
      setLoading(false);
      dispatch(alertDanger('Error saving agency'));
    }
  };

  const handleMessage = (result: boolean, message?: string) => {
    if (result) {
      dispatch(alertSuccess('Successful operation!'));
    } else {
      dispatch(alertDanger(message || 'Unable to perform the operation, please try again'));
    }
  };

  const handleRemove = (id: number) => {
    setIdToRemove(id);
  };

  const confirmRemove = async () => {
    setIdToRemove(0);
    setLoading(true);
    const result = await deleteAgency(idToRemove);
    setLoading(false);
    handleMessage(result);
    agencies.invoke({});
  };

  const cancelRemove = () => {
    setIdToRemove(0);
  };

  const handleEdit = (agency: Agency) => {
    setAgencyToEdit(agency);
    setNewName(agency.name);
    if (agency.user?.email) {
      setNewEmail(agency.user.email);
    }
  };

  const handleConfirmEdit = async () => {
    setLoading(true);

    if (agencyToEdit.user.email !== NewEmail) {
      const validEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(NewEmail);
      if (!validEmail || NewEmail === '') {
        dispatch(alertWarn('Email is Invalid'));
        setLoading(false);
        return;
      }
      const emailIsTaken = await checkEmailIsTaken(NewEmail);
      if (emailIsTaken) {
        setLoading(false);
        dispatch(alertWarn('Email is already being used'));
        return;
      }
    }

    let nameUpdated = false;
    if (agencyToEdit.name !== NewName) {
      nameUpdated = await putAgency(agencyToEdit.agencyId, NewName);
      handleMessage(nameUpdated);
    }

    if (agencyToEdit.user.email !== NewEmail) {
      const result = await putUser(agencyToEdit.user.userId, NewEmail);
      if (!result) {
        handleMessage(result, 'Error updating Agency Admin email');
      }
      if (result && !nameUpdated) {
        handleMessage(result);
      }
    }
    setLoading(false);
    await agencies.invoke({});
    handleCancelEdit();
  };

  const handleCancelEdit = () => {
    setAgencyToEdit({} as Agency);
    setNewName('');
    setNewEmail('');
  };

  const cancelOpenOffices = () => {
    setOpenOffices({} as Agency);
    setIdsToAdd([]);
    setOfficesToRemove([]);
  };

  const handleSaveOffices = async () => {
    setOpenOffices({} as Agency);
    setLoading(true);
    const idsToRemove = officesToRemove.map(office => office.id);
    const result = await saveOffices(idsToAdd, idsToRemove, openOffices.agencyId);
    setLoading(false);
    if (result) {
      dispatch(alertSuccess('Successful operation!'));
    } else {
      dispatch(alertDanger('Unable to perform the operation, please try again'));
    }
    cancelOpenOffices();
  };

  const handleOpenOffices = (agency: Agency) => {
    selectedOffices.invoke(agency.agencyId);
    offices.invoke({});
    setOpenOffices(agency);
  };

  const searchOffices = async (search?: string) => {
    offices.invoke({ search }).then((response) => {
      const filteredOffices = officesToRemove.filter(item => R.includes(search, item.name));
      const newOffices = R.sortBy(R.prop('name'), [...response.offices, ...filteredOffices]);
      offices.setState({
        ...offices,
        data: {
          ...offices.data,
          offices: R.uniq(newOffices),
        }
      });
    });
  };

  const getMoreOffices = async (search?: string) => {
    const page = offices.data.page + 1;
    if (page > offices.data.totalPages || loadingMore) {
      return;
    }
    setLoadingMore(true);
    const result = await getFreeOffices({ page, search });
    offices.setState({
      ...offices,
      data: {
        offices: [...offices.data.offices, ...result.offices],
        page: result.page,
        totalCount: result.totalCount,
        totalPages: result.totalPages
      }
    });
    setLoadingMore(false);
  };

  const handleAddOffice = (office: Office) => {
    selectedOffices.setState({
      ...selectedOffices,
      data: [...selectedOffices.data, office],
    });
    if (!R.includes(office.id, idsToAdd) && !R.includes(office, officesToRemove)) {
      setIdsToAdd([...idsToAdd, office.id]);
    }
    setOfficesToRemove(officesToRemove.filter(item => item.id !== office.id));
  };

  const handleRemoveOffice = (office: Office) => {
    const newData = selectedOffices.data.filter(item => item.id !== office.id);
    selectedOffices.setState({
      ...selectedOffices,
      data: newData,
    });
    if (!R.includes(office, officesToRemove) && !R.includes(office.id, idsToAdd)) {
      setOfficesToRemove([...officesToRemove, office]);
      offices.setState({
        ...offices,
        data: {
          ...offices.data,
          offices: R.sortBy(R.prop('name'), [...offices.data.offices, office]),
        }
      });
    }
    setIdsToAdd(idsToAdd.filter(item => item !== office.id));
  };

  useCheckIsAdmin(admin, sessionStatus, history);

  useEffect(() => {
    if (sessionStatus === 'AUTHENTICATED') {
      agencies.invoke({});
    }
  }, [sessionStatus]);

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <h3>Agencies</h3>
        <div>
          <Button
            variant='contained'
            startIcon={<AddIcon />}
            onClick={handleCreate}
          >
            Create
          </Button>
        </div>
      </div>

      <form role='form' onSubmit={handleSave}>
        <TableContainer>
          <TableHead>
            <TableRow>
              <HeaderCell className={classes.colName}>Name</HeaderCell>
              <HeaderCell>Agency Email</HeaderCell>
              <HeaderCell className={classes.smallColumn} align="center">Offices</HeaderCell>
              <HeaderCell className={classes.actions} align="center">Actions</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isNew && (
              <TableRow>
                <BodyCell className={classes.firstColumn}>
                  <TextField
                    label=''
                    value={name}
                    required
                    placeholder="Name"
                    onChange={(event) => setName(event.target.value)}
                  />
                </BodyCell>
                <BodyCell>
                  <TextField
                    label=''
                    value={email}
                    required
                    type="email"
                    placeholder="Email"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </BodyCell>
                <BodyCell align="center">
                  <IconButton title='offices' >
                    <ApartmentIcon />
                  </IconButton>
                </BodyCell>
                <BodyCell>
                  <div className={classes.row}>
                    <IconButton
                      title='save'
                      color='default'
                      type="submit"
                    >
                      <SaveIcon />
                    </IconButton>
                    <IconButton
                      title='Cancel'
                      onClick={handleCancel}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </BodyCell>
              </TableRow>
            )}
            {
              agencies.data.map((agency) => (
                <TableRow key={agency.agencyId}>
                  <BodyCell className={classes.firstColumn}>
                    {agency.agencyId === agencyToEdit.agencyId ? (
                      <TextField
                        label=''
                        value={NewName}
                        required
                        placeholder="Name"
                        onChange={(event) => setNewName(event.target.value)}
                      />
                    ) : (
                      <span>{agency.name}</span>
                    )}
                  </BodyCell>
                  <BodyCell>
                    {agency.agencyId === agencyToEdit.agencyId ? (
                      <TextField
                        label=''
                        value={NewEmail}
                        type='email'
                        required
                        placeholder="Email"
                        onChange={(event) => setNewEmail(event.target.value)}
                      />
                    ) : (
                      <span>{agency.user?.email}</span>
                    )}
                  </BodyCell>
                  <BodyCell align="center">
                    <IconButton
                      title='offices'
                      onClick={() => handleOpenOffices(agency)}
                    >
                      <ApartmentIcon />
                    </IconButton>
                  </BodyCell>
                  <BodyCell align='center' style={{ paddingRight: 5 }}>
                    {agencyToEdit.agencyId === agency.agencyId ? (
                      <div className={classes.row}>
                        <IconButton
                          title='save'
                          color='default'
                          onClick={handleConfirmEdit}
                        >
                          <SaveIcon />
                        </IconButton>
                          &nbsp;
                        <IconButton
                          title='Cancel'
                          onClick={handleCancelEdit}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    ) : (
                      <div className={classes.row}>
                        <IconButton
                          title='Edit'
                          color='default'
                          onClick={() => handleEdit(agency)}
                        >
                          <EditIcon />
                        </IconButton>
                          &nbsp;
                        <IconButton
                          title='Remove'
                          color='error'
                          onClick={() => handleRemove(agency.agencyId)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>

                    )}
                  </BodyCell>
                </TableRow>
              ))
            }
          </TableBody>
        </TableContainer>
      </form>
      <ConfirmationDialog
        title="Remove Agency"
        text="Are you sure you want to remove this agency?"
        open={!!idToRemove}
        cancel={cancelRemove}
        confirm={confirmRemove}
      />

      <SelectOffices
        open={!!openOffices.agencyId}
        title={`Offices Assigned to: ${openOffices.name}`}
        save={handleSaveOffices}
        cancel={cancelOpenOffices}
        onAdd={handleAddOffice}
        onRemove={handleRemoveOffice}
        selectedItems={selectedOffices.data}
        offices={offices.data.offices}
        getMore={getMoreOffices}
        searchOffices={searchOffices}
        loadingOffices={offices.loading}
        loadingMore={!offices.loading && loadingMore}
        loadingSelected={selectedOffices.loading}
      />
      <BackdropLoading loading={agencies.loading || loading} />
    </Paper>
  );
};

export default AgenciesPage;
