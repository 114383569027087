import { Dispatch } from 'redux';
import * as actionTypes from './alerts.actionTypes';

export function alertDanger(message: string, timer = 5000) {
  return createAlert(actionTypes.ALERT_DANGER, message, timer);
}
export function alertInfo(message: string, timer = 5000) {
  return createAlert(actionTypes.ALERT_INFO, message, timer);
}
export function alertWarn(message: string, timer = 5000) {
  return createAlert(actionTypes.ALERT_WARN, message, timer);
}
export function alertSuccess(message: string, timer = 5000) {
  return createAlert(actionTypes.ALERT_SUCCESS, message, timer);
}

function createAlert(type: string, message: string, timer: number) {
  return (dispatch: Dispatch) => {
    const id = new Date().getTime();
    dispatch({ type, message, id });

    setTimeout(() => {
      dispatch({ type: actionTypes.CLOSE_ALERT, id });
    }, timer);
  };
}

export function closeAlert(id: number) {
  return (dispatch: Dispatch) => {
    dispatch({ type: actionTypes.CLOSE_ALERT, id });
  };
}
