import React from 'react';
import { match as Match } from 'react-router-dom';
import
CreateAutomationMessage
  from '../../components/AutomationBasedMessage/create-automation-message.component';
import
{ AutomationMessageTypes }
  from '../../components/AutomationBasedMessage/automation-message.actions';
import { cond, always, equals, T } from 'ramda';
import { History } from 'history';
import queryString from 'query-string';

interface MatchParams {
  id?: string;
}

interface TimeBasedMessageEditProps {
  match: Match<MatchParams>;
  history: History;
  location: {
    search: string,
    pathname: string;
    state?: {
      from: {
        search: string,
        pathname: string;
      };
    };
  };
}

const AutomationBasedTemplateEdit = ({
  match, history, location
}: TimeBasedMessageEditProps) => {
  const msgId = match?.params?.id ? Number(match.params.id) : 0;
  const queries = queryString.parse(location.search);
  const copying = Number(queries?.copying || 0);
  const automationMessageType: AutomationMessageTypes = cond([
    [equals('tmp.msg.rcr.smart_reply'),
      always('SmartReply' as AutomationMessageTypes)],
    [equals('tmp.msg.rcr.time_based'),
      always('TimeBasedReminder' as AutomationMessageTypes)],
    [equals('tmp.msg.rcr.spark'),
      always('Spark' as AutomationMessageTypes)],
    [T, always(null)]
  ])(queries?.path);
  const fromTemplate = queries?.fromTemplate ? Number(queries.fromTemplate) : 0;
  return (
    <>
      <CreateAutomationMessage
        messageId={msgId}
        copying={copying}
        filter={'Clients'}
        messageType='sked-template'
        automationMessageType={automationMessageType}
        goBack={history.goBack}
        includeHeader
        fromTemplate={fromTemplate}
      />
    </>
  );
};

export default AutomationBasedTemplateEdit;
