/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import {
  CircularProgress,
  Chip,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import {
  head, keys, cond, equals, evolve, findIndex,
  propEq, remove, append, pipe, prop, split, tail, join,
  merge, __, omit, assoc, flatten, isEmpty, has,
} from 'ramda';
import CreateStopManager from '../StopManagers/components/create-stop-manager.component.jsx';
import { TemplatesTable } from '../Clients/components/client-dialog/components/stop-managers.component.jsx';
import { ClientList } from '../CalendarV2/components/client-list.component.jsx';
import api from '../../services/api.js';
import {
  LocalDate, DateTimeFormatter,
} from '@js-joda/core';
import { Locale } from '@js-joda/locale_en-us';
import { useTypeahead } from '../../services/typeahead.hook.js';
import { SearchClients } from '../CalendarV2/components/scheduler.component.jsx';
import Modal from '../../components/Modal/Modal.component';
import HeaderButton from '../../components/HeaderButton/HeaderButton.component';
import StopIcon from '../../icons/Stop.icon';
import TaskIcon from '../../icons/Warning.icon';

const format = DateTimeFormatter.ofPattern('M/d/yyyy').withLocale(Locale.US);

const NiceChip = withStyles({
  root: {
    fontSize: '14px',
  },
})(Chip);

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  stopTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& h5': {
      marginTop: theme.spacing(1),
      fontWeight: 'bold',
      fontSize: '18px'
    }
  },
  mySubListHeader: {
    fontSize: '14px',
    fontWeight: 'bolder',
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.74)'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontWeight: 'bold',
      fontSize: '20px'
    }
  },
  formControl: {
    marginBottom: theme.spacing(1),
    width: 200,
    maxWidth: '100%'
  },
  table: {
    width: '100%',
    fontSize: '16px'
  },
  tableContainer: {
    marginTop: '20px',
    marginLeft: '-15px',
    marginRight: '-15px',
    width: 'auto',
    '& table tr td': {
      fontSize: '13px'
    },
    '& table tr th': {
      fontSize: '13px'
    },
    '& .MuiTableCell-sizeSmall': {
      paddingLeft: '3px',
      paddingRight: '3px'
    },
    '& .MuiTableCell-sizeSmall:last-child': {
      paddingRight: '20px'
    },
    '& .MuiTableCell-sizeSmall:first-child': {
      paddingLeft: '20px'
    }
  },
}));

export const CreateStopDialog = ({
  open,
  onClose,
  tz,
  defaultClients = [],
  aptId,
}) => {
  const classes = useStyles();
  const [step, setStep] = useState('searchClients');
  const [clearSearch, setClearSearch] = useState(false);
  const [error, setError] = useState(false);
  const [state, setState] = useState({
    nextAttempt: false,
    selectedClients: defaultClients,
    stop: {},
    message: undefined,
    creationMethod: 'new',
    templates: [],
  });
  const [saveState, setSaveState] = useState({ loading: false, data: '', errorMessage: '' });

  useEffect(() => {
    setState({
      ...state,
      selectedClients: defaultClients,
    });
    if (!isEmpty(defaultClients)) {
      setStep('stopCreationMethod');
    }
  }, [defaultClients]);

  useEffect(() => {
    if (step === 'stopCreationMethod' && isEmpty(state.selectedClients)) {
      setError(true);
    }
  }, [step, state.selectedClients]);

  const selectClient = client => {
    setState(evolve({
      selectedClients: clients => {
        const index = findIndex(propEq('id', client.id), clients);
        return index >= 0 ? remove(index, 1, clients) : append(client, clients);
      }
    }));
  };

  const updateCreationMethod = (e) => {
    setState({
      ...state,
      creationMethod: e.target.value,
    });
  };

  const updateStopData = ({ stop, message }) => {
    setState({
      ...state,
      stop,
      message,
    });
  };

  const updateTemplates = (templates) => {
    setState({
      ...state,
      templates,
    });
  };

  const { state: clients, onChange, setState: setClients } = useTypeahead(query => {
    // Wrap in a promise resolve
    // so that any errors from Joda are caught and
    // displayed by promise chain
    return Promise.resolve().then(() => {
      const birthday = query.birthday && query.birthday.length >= 8 ? LocalDate.parse(query.birthday, format).toString() : undefined;
      const lastName = query.lastName.split(' ')[0];
      const firstName = pipe(
        prop('lastName'),
        split(' '),
        tail,
        join(' '),
        s => !s ? undefined : s
      )(query);
      const q = pipe(
        q => !q.phone ? omit(['phone'], q) : q,
        merge(__, {
          birthday,
          lastName,
          firstName
        })
      )(query);
      return api.post('client/query', {
        page: 1,
        perPage: 25,
        query: {
          ...q,
          sortBy: [{
            direction: 'Asc',
            field: 'LastName'
          }, {
            direction: 'Asc',
            field: 'FirstName'
          }],
          isLead: false
        }
      }).then(result => {
        // Need to wrap it in this promise resolve
        // so rxJs doesn't blow up
        return Promise.resolve(result);
      });
    });
  });

  const close = () => {
    setState({
      nextAttempt: false,
      selectedClients: [],
      appointmentsToSchedule: [],
    });
    setStep('searchClients');
    setClearSearch(true);
    setError(false);
    onClose();
  };

  const saveAllData = () => {
    setSaveState({ loading: true });
    if (state.creationMethod === 'new') {
      return new Promise((res) => {
        if (state.message) {
          return api.post('message', state.message).then((msg) => {
            const msgId = msg.id;
            return res(msgId);
          });
        }
        return res(undefined);
      }).then((msgId) => {
        const stops = state.selectedClients?.map(({ id }) => {
          return ({
            ...state.stop,
            msgId,
            clientId: id,
          });
        });
        if (has('Immediately', state.stop.trigger)) {
          return Promise.all(stops?.map((stop) => {
            const s = {
              ...stop,
              appointmentId: aptId,
              done: {
                Open: []
              },
            };
            return api.post('stops', s);
          })).then(() => {
            setSaveState({ loading: false });
            setStep('finished');
          });
        } else {
          return api.post('stopmanager', stops).then(() => {
            setSaveState({ loading: false });
            setStep('finished');
          });
        }
      });
    } else {
      const stops = flatten(
        state.selectedClients?.map(({ id }) => {
          return state.templates?.map((template) => ({
            ...template,
            clientId: id,
          }));
        })
      );
      return api.post('stopmanager', stops).then(() => {
        setSaveState({ loading: false });
        setStep('finished');
      });
    }
  };

  const nextStep = () => {
    if (step === 'searchClients' && state.selectedClients.length === 0) {
      return setState(assoc('nextAttempt', true));
    }
    if (step === 'searchClients' && state.selectedClients.length > 0) {
      // setClearSearch(true);
      // setStep('reviewAppointments');
      return setStep('stopCreationMethod');
    }
    if (step === 'stopCreationMethod') {
      return setStep('addStop');
    }
    if (step === 'addStop') {
      return saveAllData().then(() => {
        close();
      });
    }
    if (step === 'finished') {
      return close();
    }
    return null;
  };

  const backStep = () => {
    setError(false);
    if (step === 'addStop') {
      return setStep('stopCreationMethod');
    }
    if (step === 'stopCreationMethod') {
      return setStep('searchClients');
    }
    return null;
  };

  return (
    <Modal
      open={open}
      onClose={close}
      title='Add Alert'
      className='sked-test-front-desk-add-alert-modal'
      buttons={[
        saveState.loading && <CircularProgress size={18} />,
        <>&nbsp;</>,
        step !== 'searchClients' && <HeaderButton
          disabled={step === 'searchClients' || step === 'finished'}
          borderSolid
          onClick={backStep}
          title="Back"
          className='sked-test-front-desk-add-alert-modal-button-back'
        />,
        <HeaderButton
          disabled={saveState.loading ||
            error ||
            !state.selectedClients.length ||
            (step === 'searchClients' && !state.selectedClients.length)
          }
          color='primary'
          onClick={nextStep}
          className='sked-test-front-desk-add-alert-modal-button-next'
          title={(step === 'addStop' || step === 'addClients') ? 'Save' :
            step === 'finished' ? 'Close' :
              'Next'
          }
        />,
      ]}
    >
      <div>
        <div
          className={classes.root}
          style={{
            display: open ? 'flex' : 'none',
            height: 'auto',
            width: '100%',
            overflowX: 'visible',
            overflowY: 'visible',
            padding: '0',
          }}>
          {step === 'searchClients' &&
            <SearchClients
              isInDialog
              setStep={setStep}
              classes={classes}
              selectClients={(clis) => setState({ ...state, selectedClients: clis })}
              selectClient={selectClient}
              onChange={onChange}
              setClients={setClients}
              clients={clients}
              shouldClearSearch={clearSearch}
              setClearSearch={setClearSearch}
              defaultClients={state.selectedClients}
            />
          }
          {step !== 'searchClients' && step !== 'finished' && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography component="h5">Selected Contacts</Typography>
              </Grid>
              <Grid item xs={12}>
                <ClientList
                  searchResults={clients}
                  searchResultList={[]}
                  selectClient={selectClient}
                  selectedClients={state.selectedClients}
                  isInDialog
                />
              </Grid>
            </Grid>
          )}
          {step === 'stopCreationMethod' && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography component="h5">How would you like to make this alert?</Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby='stop-radios'
                    name="stop-method-radios"
                    value={state.creationMethod}
                    onChange={updateCreationMethod}
                  >
                    <FormControlLabel value="new" control={<Radio />} label="Create New" />
                    <FormControlLabel value="template" control={<Radio />} label="From Template" />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
          )}
          {step === 'addStop' && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography component="h5">
                  {state.creationMethod === 'template' ?
                    'Select Template(s)'
                    :
                    'New Alert'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {state.creationMethod === 'template' ?
                  <TemplatesTable
                    tz={tz}
                    isEmbedded={true}
                    onApply={(templates) => updateTemplates(templates)}
                  />
                  :
                  <CreateStopManager
                    isEmbedded={true}
                    isTemplate={false}
                    stopManager={{}}
                    save={(stopAndMessage) => updateStopData(stopAndMessage)}
                    clientIds={undefined}
                    isError={setError}
                    defaultTrigger={aptId ? 'Immediately' : 'AfterDate'}
                    showImmediate={Boolean(aptId)}
                  />}
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </Modal>
  );
};

const StopDialog = ({
  isOpen,
  stop = {},
  close,
  patch,
  busy,
}) => {
  const classes = useStyles();
  const {
    message,
    done,
    stopType,
    client,
  } = stop;

  const status = head(keys(done));

  const titleStyle = cond([
    [equals('Done'), () => 'lightgray'],
    [equals('Open'), () => '#4CAF50'],
    [equals('Waiting'), () => '#FF9900'],
    [equals('Postponed'), () => '#0089D2'],
  ])(status);
  return (
    <Modal
      open={isOpen}
      onClose={close}
      title='view alert modal'
      size=''
      maxWidth={560}
      showMoreWidth={480}
      className='sked-test-view-alert-modal'
      titleComponent={[
        stopType === 'Task' ?
          <div className={classes.stopTitle}>
            <TaskIcon
              size='small'
              style={{ verticalAlign: 'middle' }}
            />
            &nbsp;Task
          </div>
          :
          <div className={classes.stopTitle}>
            <StopIcon
              size='small'
              style={{ verticalAlign: 'middle' }}
            />
            &nbsp;Stop
          </div>
      ]}
      buttons={[
        busy && <CircularProgress size={18} />,
        <>&nbsp;</>,
        <NiceChip
          label={status}
          size="small"
          style={{
            backgroundColor: titleStyle,
          }}
        />,
        <>&nbsp;</>,
        status !== 'Done' &&
        <>
          <HeaderButton
            title='Mark as done'
            color='primary'
            onClick={patch('Done')}
            className='sked-test-view-alert-modal-button-mark-done'
          />
          &nbsp;
        </>,
        status !== 'Open' &&
        <HeaderButton
          title='Mark as open'
          borderSolid
          onClick={patch('Open')}
          className='sked-test-view-alert-modal-button-mark-open'
        />,
        status !== 'Postponed' &&
        <HeaderButton
          title='Mark as postponed'
          borderSolid
          onClick={patch('Postponed')}
          className='sked-test-view-alert-modal-button-mark-postponed'
        />,
      ]}
    >
      <div style={{ fontSize: '16px', marginBottom: 5 }}>
        {client && (client.firstName + ' ' + client.lastName)}
      </div>
      <p>{message}</p>
    </Modal>
  );
};

export default StopDialog;
