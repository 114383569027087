import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
    backgroundColor: '#FAFAFA',
  },
  button: {
    padding: '2px 8px',
    fontSize: 14,
    textTransform: 'none',
    borderRadius: 3,
    color: 'rgba(0,0,0,.87)',
    border: 'none',
    margin: 0,
    minWidth: 50,

    '&:hover': {
      border: 'none',
    }
  },
  buttonIcon: {
    width: 17,
    height: 17,
  },
  headerIcon: {
    width: 17,
    height: 17,
  },
  content: {
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: '6px',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgb(206, 206, 206)',
      borderRadius: '6px',

      '&:hover': {
        backgroundColor: 'gray',
        borderRadius: '6px',
      },
    },
  },
  checkbox: {
    '& svg': {
      width: 17,
      height: 17,
    }
  },
  pageControls: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '5px',
    marginLeft: 15,
  },
  pageArrows: {
    marginLeft: '5px',
    marginRight: '0px',
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      fontSize: 21,
    }
  }
}));
